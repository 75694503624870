import React, {useRef, useState, useEffect} from "react";
import styled from "styled-components";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import CircleInformationIcon from "../icons/circle-information.svg?react";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import ErrorInfoIcon from "../icons/errorInfo.svg?react";
import {zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {InfoBubbleV5} from "./InfoBubbleV5";

export const InfoButtonStyled = styled.div`
	position: relative;
	margin: 10px;
	top: 3px;
	left: 4px;

	&.isError {
		svg {
			color: ${colorPalette.negative.c500Primary};
		}
	}
`;

interface IInfoButtonV5Props {
	readonly bubbleText: string | React.ReactNode;
	readonly isError?: boolean;
	readonly className?: "onPopup" | "leftAlign" | (string & {});
	readonly icon?: "info" | "question-mark" | "error";
	readonly infoBubbleStyle?: React.CSSProperties;
	readonly verticalOpen?: VerticalAlignment.bottomOuter;
	readonly onClick?: () => void;
}

export const InfoButtonV5 = (props: IInfoButtonV5Props) => {
	const {infoBubbleStyle = {}, icon = "error"} = props;
	const _parentElement = useRef<HTMLDivElement>(null);
	const _floatingElement = useRef<HTMLDivElement>(null);
	const prevIsInfoBubbleOpen = useRef(null);
	const [isInfoBubbleOpen, setIsInfoBubbleOpen] = useState<boolean>(false);
	const [isFocus, setIsFocus] = useState<boolean>(false);
	const [transform, setTransform] = useState<TransformObj>(null);

	const openBubble = () => {
		setIsInfoBubbleOpen(true);
		setIsFocus(true);
	};

	const closeBubble = () => {
		setIsInfoBubbleOpen(false);
		setIsFocus(false);
	};

	useEffect(() => {
		if (!prevIsInfoBubbleOpen.current && isInfoBubbleOpen && _parentElement.current && _floatingElement.current) {
			const transformValue = DomUtils.getFixedFloatingElementPosition(
				_parentElement.current,
				_floatingElement.current,
				props.verticalOpen || VerticalAlignment.top,
				HorizontalAlignment.center,
				10,
				0,
			);

			setTransform(transformValue);
		}

		prevIsInfoBubbleOpen.current = isInfoBubbleOpen;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInfoBubbleOpen, _parentElement.current, _floatingElement.current, props.verticalOpen]);

	const element = _parentElement.current;
	const inlineStyle: React.CSSProperties = element && {
		zIndex: zIndex.popup,
		transform: transform?.translate,
		position: "fixed",
		left: "5px",
		top: "-20px",
		...infoBubbleStyle,
	};

	const icons = {
		info: <CircleInformationIcon style={{height: "14px", width: "14px"}} />,
		"question-mark": <CircleQuestionIcon />,
		error: <ErrorInfoIcon style={{height: "14px", width: "14px"}} />,
	};

	return (
		<InfoButtonStyled
			ref={_parentElement}
			onMouseEnter={openBubble}
			onMouseLeave={closeBubble}
			onClick={props.onClick}
			className={ReactUtils.cls("InfoButtonV5", {isError: props.isError})}
		>
			{icons[icon]}
			{isInfoBubbleOpen && (
				<InfoBubbleV5
					content={props.bubbleText}
					isErrorMessage={props.isError}
					divRef={_floatingElement}
					style={inlineStyle}
					className={ReactUtils.cls(`${props.className || ""} infobutton`, {
						left: transform?.horizontal === HorizontalAlignment.right,
						right: transform?.horizontal === HorizontalAlignment.left,
						[VerticalAlignment[props.verticalOpen]]: !!props.verticalOpen,
					})}
					hide={!isFocus}
				/>
			)}
		</InfoButtonStyled>
	);
};

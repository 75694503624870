import * as React from "react";
import {Button} from "../../../widgets/button/Button";
import {IconButton} from "../../../widgets/button/IconButton";
import type {PortTemplateDto} from "../../../../generated/api/base";
import {XyiconFeature} from "../../../../generated/api/base";
import {PortComponent} from "./PortComponent";
import {Port} from "./Port";

interface IPortTemplateEditorProps {
	readonly portTemplate: PortTemplateDto[];
	readonly onSaveClick?: (portData: PortTemplateDto[]) => void;
	readonly onBackClick?: () => void;
}

interface IPortState {
	selectedId: string;
}

export class PortTemplateEditor extends React.Component<IPortTemplateEditorProps, IPortState> {
	private _ports: Port[] = []; // we need to have it outside the state, so Ports can read their actual ID
	constructor(props: IPortTemplateEditorProps) {
		super(props);

		this.state = {
			selectedId: null,
		};
	}

	private getParentPort(port: Port): Port {
		const id = port.id;
		const arrayOfIndices = id.split(".");

		if (arrayOfIndices.length > 1) {
			arrayOfIndices.pop();
			const parentID = arrayOfIndices.join(".");

			return Port.getPortById(this._ports, parentID) as Port;
		} else {
			return null;
		}
	}

	private onAddPortClick = () => {
		const selectedId = this.state.selectedId;

		let newPort: Port = null;

		if (!selectedId) {
			newPort = new Port(this._ports, null);
			this._ports.push(newPort);
		} else {
			const selectedPort = Port.getPortById(this._ports, selectedId) as Port;
			const parentPort = this.getParentPort(selectedPort);
			const arrayOfPorts = parentPort ? parentPort.children : this._ports;
			const index = arrayOfPorts.indexOf(selectedPort);

			newPort = new Port(this._ports, parentPort);
			arrayOfPorts.splice(index + 1, 0, newPort);
		}

		this.setState({
			selectedId: newPort.id,
		});
	};

	private onPortSelect = (id: string) => {
		this.setState({
			selectedId: id,
		});
	};

	private onAddChildPortClick = (id: string = this.state.selectedId) => {
		const parentPort = Port.getPortById(this._ports, id) as Port;
		const newPort = new Port(this._ports, parentPort);

		parentPort.children.push(newPort);

		this.setState({
			selectedId: newPort.id,
		});
	};

	private onDeleteClick = (id: string) => {
		const port = Port.getPortById(this._ports, id) as Port;
		const parentPort = this.getParentPort(port);
		const arrayOfPorts = parentPort ? parentPort.children : this._ports;
		const index = arrayOfPorts.indexOf(port);

		arrayOfPorts.splice(index, 1);

		this.setState({
			selectedId: null,
		});
	};

	private onLabelChange = (newLabel: string, portID: string) => {
		const port = Port.getPortById(this._ports, portID) as Port;

		port?.setLabel(newLabel);

		this.forceUpdate();
	};

	private onReadonlyChange = (value: boolean) => {
		const port = Port.getPortById(this._ports, this.state.selectedId) as Port;

		port.setReadOnly(value);

		this.forceUpdate();
	};

	private getNecessaryDataFromPort(port: Port): PortTemplateDto {
		return {
			id: port.id,
			label: port.label,
			isReadOnly: port.isReadOnly,
			children: port.children.map((p: Port) => this.getNecessaryDataFromPort(p)),
		};
	}

	private addChildren(parentPort: Port, children: PortTemplateDto[]) {
		for (const child of children) {
			const newPort = new Port(this._ports, parentPort);

			newPort.setLabel(child.label);
			newPort.setReadOnly(child.isReadOnly);
			this.addChildren(newPort, child.children);
			parentPort.children.push(newPort);
		}
	}

	public onSaveClick = () => {
		this.props.onSaveClick?.(this.getCurrentData());
	};

	private getCurrentData() {
		const data: PortTemplateDto[] = [];

		for (const port of this._ports) {
			data.push(this.getNecessaryDataFromPort(port));
		}

		return data;
	}

	public override componentDidMount() {
		if (this.props.portTemplate) {
			this._ports.length = 0;
			for (const portConfig of this.props.portTemplate) {
				const newPort = new Port(this._ports, null);

				newPort.setLabel(portConfig.label);
				newPort.setReadOnly(portConfig.isReadOnly);
				this.addChildren(newPort, portConfig.children);
				this._ports.push(newPort);
			}

			this.forceUpdate();
		}
	}

	public override render() {
		const ports = [...this._ports];
		const lastChildHasChild = Port.lastChildHasChild(ports);

		return (
			<div className="PortContainer">
				<div className="hbox">
					<IconButton
						className="viewButton add"
						title="Add Port"
						icon="add"
						onClick={this.onAddPortClick}
					/>
					{this.props.onBackClick && this.props.onSaveClick && (
						<>
							<Button
								label="Back"
								title="Back"
								onClick={this.props.onBackClick}
							/>
							<Button
								label="Save"
								title="Save"
								onClick={this.onSaveClick}
								className="primary"
							/>
						</>
					)}
				</div>
				<div className="childrenContainer">
					{ports.map((port: Port, index: number) => {
						const id = port.id;

						const lastChild = index === ports.length - 1;

						return (
							<PortComponent
								key={id}
								id={id}
								feature={XyiconFeature.XyiconCatalog}
								selectedId={this.state.selectedId}
								children={[...port.children]}
								label={port.label}
								isReadOnly={port.isReadOnly}
								onClick={this.onPortSelect}
								onAddChildClick={this.onAddChildPortClick}
								onDeleteClick={this.onDeleteClick}
								onLabelChange={this.onLabelChange}
								onReadonlyChange={this.onReadonlyChange}
								lastChildHasChild={lastChild && lastChildHasChild}
								onEnterKeyPress={this.onSaveClick}
							/>
						);
					})}
				</div>
			</div>
		);
	}
}

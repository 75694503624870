import * as React from "react";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {FillOpacity} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {Markup} from "../../../../../../data/models/Markup";
import {MarkupsWithCustomizableFillOpacity} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import {FillOptions} from "./FillOptions";

interface IFillButtonProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

interface IFillButtonState {
	isOpen: boolean;
}

export class FillButton extends React.Component<IFillButtonProps, IFillButtonState> {
	private _fillOptionsRef = React.createRef<HTMLDivElement>();

	constructor(props: IFillButtonProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	private get _selectedItems() {
		return this.props.spaceViewRenderer.spaceItemController.selectedItems;
	}

	private onFillClick = () => {
		if (this.state.isOpen) {
			FocusLoss.stopListen(this._fillOptionsRef.current as unknown as HTMLDivElement, this.onBlur);
		} else {
			FocusLoss.listen(this._fillOptionsRef.current as unknown as HTMLDivElement, this.onBlur);
		}
		this.setState((prevState) => ({isOpen: !prevState.isOpen}));
	};

	private onBlur = () => {
		if (this.state.isOpen) {
			this.setState({isOpen: false});
		}
	};

	private onFillChange = (fillOpacity: FillOpacity) => {
		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = this._selectedItems as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.fillTransparency !== 1 - fillOpacity;

			if (hasChanged) {
				markup.setFillTransparency?.(1 - fillOpacity);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		this.props.spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);
	};

	private getActiveIcon() {
		const firstSelectedObject = this._selectedItems[0]?.modelData as Markup;

		if (!firstSelectedObject) {
			console.warn("There's no data for selected object. Please report this to the developers.");
		}

		switch (firstSelectedObject?.fillTransparency) {
			case 0:
				return "fill";
			case 1:
				return "noFill";
			default:
				return "semiFill";
		}
	}

	public override render() {
		const areFillOptionsAvailable =
			this._selectedItems.length > 0 &&
			this._selectedItems.every((item) => item.spaceItemType === "markup" && MarkupsWithCustomizableFillOpacity.includes((item as Markup3D).type));

		return (
			areFillOptionsAvailable && (
				<span
					onClick={this.onFillClick}
					className="button flexCenter FillButton"
					title="Fill Transparency..."
					ref={this._fillOptionsRef}
				>
					<div className={`fillOption ${this.getActiveIcon()}`} />
					<div className="arrowWrapper">
						<i className="arrowDown corner"></i>
					</div>
					{this.state.isOpen && <FillOptions onChange={this.onFillChange} />}
				</span>
			)
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Space} from "../../../../data/models/Space";
import {XyiconFeature} from "../../../../generated/api/base";
import type {SpaceFile} from "../../../../data/models/SpaceFile";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {AppState} from "../../../../data/state/AppState";

interface ISpaceVersionSelectorProps {
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly item: Space;
	readonly onChoose?: (spaceFile: SpaceFile) => void;
}

@inject("transport")
@inject("appState")
@observer
export class SpaceVersionSelector extends React.Component<ISpaceVersionSelectorProps> {
	private getOptions() {
		const space = this.props.item;
		// filter out temporary spacefiles

		return space.spaceFiles.filter((spaceFile: SpaceFile) => spaceFile.id != null);
	}

	private onChoose = (spaceFile: SpaceFile) => {
		const space = this.props.item;

		if (space.ownFeature === XyiconFeature.Space) {
			space.setSelectedSpaceFile(spaceFile);
			this.forceUpdate();

			this.props.onChoose?.(spaceFile);
		}
	};

	public override render() {
		const {item} = this.props;

		const props = {
			selected: item.selectedSpaceFile,
			options: this.getOptions(),
			render: (spaceFile: SpaceFile) => spaceFile.spaceVersion?.name,
			onChange: this.onChoose,
			sort: false,
		};

		if (item?.ownFeature === XyiconFeature.Space) {
			return (
				<div className="SpaceVersionSelector">
					<SelectInput {...props} />
				</div>
			);
		} else {
			return null;
		}
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {flushSync} from "react-dom";
import {SVGIcon} from "../../button/SVGIcon";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import type {AppState} from "../../../../data/state/AppState";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";

export interface ITextInputProps {
	readonly isTextArea?: boolean;
	readonly defaultValue?: string;
	readonly value?: string;
	readonly placeholder?: string;
	readonly className?: string;
	readonly isTextInfo?: boolean;
	readonly style?: React.CSSProperties;
	readonly autoFocus?: boolean;
	readonly disabled?: boolean;
	readonly inputType?: IInputType;
	readonly errorMessage?: string;
	readonly isTooltip?: boolean;
	readonly trimText?: boolean; // trim the whitespace from the beginning and the end of the string if true
	readonly selectAll?: boolean;
	readonly borderColor?: string;
	readonly viewBoxSize?: number;
	readonly inline?: boolean;
	readonly noButtons?: boolean;
	readonly viewItem?: boolean;
	readonly errorMessageTop?: number;
	readonly caretPosition?: number;
	readonly getErrorMessage?: (value: string) => string;
	readonly onChange?: (value: string) => void;
	readonly onBlur?: (isEnterPressed?: boolean) => void;
	readonly onInput?: (value: string, event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	readonly onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
	readonly onKeyDown?: (event: React.KeyboardEvent, value: string) => void;
	readonly appState?: AppState;
	readonly divRef?: React.RefObject<HTMLDivElement>;
}

export type IInputType = "number" | "date" | "text" | "password";

export interface ITextInputState {
	value: string;
	propsValue: string;
	toolTipTransform: TransformObj;
	errorMessage: string;
}

@inject("appState")
export class TextInput extends React.Component<ITextInputProps, ITextInputState> {
	private _requestAnimationFrameId: number = -1;
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _toolTip = React.createRef<HTMLDivElement>();

	private _valueBeforeEditing: string = this.props.value;
	private _lastValidValue = this.props.value;
	private _isEscClicked: boolean = false;

	public static readonly defaultProps: ITextInputProps = {
		defaultValue: "",
		value: "",
		placeholder: "",
		trimText: true,
	};

	public static getDerivedStateFromProps(props: ITextInputProps, state: ITextInputState) {
		// if props.value changed from its previous value -> update state.value
		if (props.value !== state.propsValue) {
			return {
				value: props.value,
				propsValue: props.value,
				errorMessage: props.errorMessage,
			};
		}
		return null;
	}

	constructor(props: ITextInputProps) {
		super(props);

		const value = this.props.defaultValue || this.props.value;

		this.state = {
			value: value,
			propsValue: value,
			toolTipTransform: null,
			errorMessage: "",
		};
	}

	private onInput = (event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;
		const errorMessage = this.props.getErrorMessage?.(value) || "";

		this.setState({value, errorMessage});

		if (!errorMessage) {
			this.props.onInput?.(value, event);
			this._lastValidValue = value;
		}
	};

	private onKeyUp = (event: React.KeyboardEvent<HTMLInputElement> | React.KeyboardEvent<HTMLTextAreaElement>) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event);
				this.props.onBlur?.(true);
				break;
		}
	};

	private onBlur = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
		this.triggerChange(event);
		this.props.onBlur?.();

		return false;
	};

	private triggerChange = (
		event:
			| React.KeyboardEvent<HTMLInputElement>
			| React.KeyboardEvent<HTMLTextAreaElement>
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>,
	) => {
		const {onChange, inline} = this.props;
		const {errorMessage} = this.state;

		if (onChange) {
			const eventTargetValue = this._ref.current?.value ?? event.currentTarget.value;

			if (eventTargetValue != null && !errorMessage) {
				const value = this.validateValue(eventTargetValue);

				if (value !== this.props.value) {
					this.props.onChange(value);
				}
			} else if (errorMessage && inline) {
				this.props.onChange(this._lastValidValue);
			}
		}
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this._isEscClicked = true;
		}
		this.props.onKeyDown?.(event, this.state.value);
	};

	protected validateValue(value: string) {
		return this.props.trimText ? value.trim() : value;
	}

	private updateTooltipPosition = () => {
		const input = this._ref.current;
		const infoBubble = this._floating.current;

		if (this.state.errorMessage && input && infoBubble) {
			const newToolTipTransform = DomUtils.getFixedFloatingElementPosition(input, infoBubble, VerticalAlignment.bottom, HorizontalAlignment.right);

			if (!ObjectUtils.compare(this.state.toolTipTransform, newToolTipTransform)) {
				flushSync(() =>
					this.setState({
						toolTipTransform: newToolTipTransform,
					}),
				);
			}
		}

		this._requestAnimationFrameId = requestAnimationFrame(this.updateTooltipPosition);
	};

	private getTranslateY = (toolTipTransform: string) => {
		if (!toolTipTransform) {
			return null;
		}
		const match = toolTipTransform.match(/translate\([^,]+,\s*([^\)]+)\)/);
		return match ? parseFloat(match[1]) : null;
	};

	public override componentDidMount() {
		const {caretPosition, inputType, autoFocus, selectAll} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			input.focus();
		}

		if (selectAll) {
			input.select();
		}

		// number input has no setSelectionRange
		if (caretPosition !== undefined && inputType !== "number" && input?.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		this.updateTooltipPosition();
		FocusLoss.listen(input, this.onBlur as any);
	}

	public override componentDidUpdate = (prevProps: ITextInputProps, prevState: ITextInputState) => {
		const input = this._ref.current;
		const infoBubble = this._floating.current;

		if ((prevProps.value !== this.props.value || prevState.value !== this.state.value) && this.state.errorMessage && input && infoBubble) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(input, infoBubble, VerticalAlignment.bottom, HorizontalAlignment.right),
			});
		}

		if (input && !prevProps.autoFocus && this.props.autoFocus) {
			input.focus();
		}
	};

	public override componentWillUnmount() {
		const {onChange} = this.props;
		const input = this._ref.current;

		if (input && onChange) {
			const currentValue = input.value;

			const value = this.validateValue(currentValue);

			if (value !== this.props.value && !this.state.errorMessage && !this._isEscClicked) {
				onChange?.(value);
			} else if (this._isEscClicked) {
				onChange?.(this._valueBeforeEditing);
			}
		}

		cancelAnimationFrame(this._requestAnimationFrameId);
		FocusLoss.stopListen(input, this.onBlur as any);
	}

	public override render() {
		const {
			disabled,
			placeholder,
			className,
			style,
			autoFocus,
			onClick,
			errorMessageTop,
			inputType,
			borderColor,
			viewBoxSize,
			isTextArea,
			inline,
			appState,
			noButtons,
			isTooltip,
			isTextInfo,
			viewItem,
		} = this.props;
		const {value, toolTipTransform, errorMessage} = this.state;
		const inputStyle: React.CSSProperties = {
			...style,
			width: viewBoxSize ?? style?.width,
			borderColor: borderColor ?? style?.borderColor,
		};

		if (isTextArea) {
			return (
				<textarea
					value={value}
					placeholder={placeholder}
					onClick={onClick}
					className={className || ""}
					style={inputStyle}
					autoFocus={autoFocus}
					onInput={this.onInput}
					onBlur={this.onBlur}
					disabled={!!disabled}
					ref={this._ref as React.RefObject<HTMLTextAreaElement>}
				/>
			);
		} else {
			const translateY = this.getTranslateY(toolTipTransform?.translate);

			const inlineStyle: React.CSSProperties = this._floating.current &&
				this._toolTip.current && {
					position: "absolute",
					top: `${(errorMessageTop || isTextInfo) && inline ? "-45" : errorMessageTop || isTextInfo ? "-135" : "-45"}px`,
					left: inline ? "-175px" : isTextInfo ? "-110px" : viewItem ? "-105px" : "-180px",
					width: "14px",
					zIndex: "999999",
					transform: toolTipTransform?.translate,
					visibility: (!viewItem && translateY <= 231) || (viewItem && translateY <= 150) || (viewItem && translateY >= 500) ? "hidden" : "visible",
				};

			return (
				<>
					<input
						value={value}
						placeholder={placeholder}
						className={className || ""}
						style={inputStyle || null}
						autoFocus={autoFocus}
						onInput={this.onInput}
						onBlur={this.onBlur}
						onClick={onClick}
						disabled={!!disabled}
						ref={(this.props.divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
						onKeyUp={this.onKeyUp}
						onKeyDown={this.onKeyDown}
						type={inputType || ""}
					/>
					{errorMessage && !disabled && (
						<div
							ref={this._floating}
							className={ReactUtils.cls("infoIcon", {[className]: true, left: noButtons, inline})}
						>
							<div ref={this._toolTip}>
								<SVGIcon icon="info" />
							</div>
							<DomPortal destination={appState.app.modalContainer}>
								<InfoBubble
									content={errorMessage}
									isErrorMessage={true}
									className={className || ""}
									divRef={this._toolTip}
									style={inlineStyle}
								/>
							</DomPortal>
						</div>
					)}
				</>
			);
		}
	}
}

import styled from "styled-components";
import {PopupHeaderStyled, PopupV5} from "../popup/PopupV5";
import {FieldStyled} from "../details/Field.styled";
import {SingleLineLabelStyled} from "../details/datatypes/SingleLineLabelV5";
import {colorPalette} from "../styles/colorPalette";
import {SingleLineInputStyled} from "../details/datatypes/SingleLineInputV5";
import {SelectInputStyled} from "../input/select/SelectInputV5";

export const CreatePopupStyled = styled(PopupV5)`
	${PopupHeaderStyled} {
		padding-bottom: 24px;
	}

	${FieldStyled} {
		margin-bottom: 0;

		.element {
			min-height: 32px;
			width: 200px;
		}
	}

	${SingleLineLabelStyled}, ${SingleLineInputStyled}, ${SelectInputStyled} {
		color: ${colorPalette.gray.c950};
	}
`;

import styled from "styled-components";
import {useState} from "react";
import {useAppStore} from "../../../../../StateManager";
import {FieldV5} from "../../FieldV5";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {IModel} from "../../../../../data/models/Model";
import {colorPalette} from "../../../styles/colorPalette";
import {FlexCenterStyle} from "../../../styles/styles";
import type {Xyicon3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {DistanceUnitName} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {FieldDataType, Permission} from "../../../../../generated/api/base";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import type {INumericFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/Numeric";
import type {PropertyName, spaceItemForProperties} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {OptionSelectorInputStyled} from "./MassFieldInputV5";
import type {IOptionId} from "./MassFieldInputV5";
import {MassUpdatePopupStyled} from "./MassUpdatePopupV5";

interface IOption {
	id: "all" | "blank" | string;
	label: string | number;
	value?: number;
}

interface IMassProperyInputV5Props {
	readonly disabled: boolean;
	readonly key: string;
	readonly propertyName: string;
	readonly propertyPartName: PropertyName;
	readonly propertyRef: string;
	readonly propertyLabel: string;
	readonly items: spaceItemForProperties[];
	readonly distanceUnitName: DistanceUnitName;
	readonly getSpaceItemValue: (partName: string, partRef: string, spaceItem3D: Xyicon3D | BoundarySpaceMap3D) => number;
}

export const MassPropertyInputV5 = ({
	disabled,
	key,
	propertyName,
	propertyPartName,
	propertyRef,
	propertyLabel,
	items,
	distanceUnitName,
	getSpaceItemValue,
}: IMassProperyInputV5Props) => {
	const appState = useAppStore((store) => store.appState);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isUpdating, setIsUpdating] = useState<boolean>(false);
	const [inputValue, setInputValue] = useState<string>("");
	const [selectedOptionId, setSelectedOptionId] = useState<IOptionId>("all");

	const onFieldClick = () => {
		setIsOpen(!isOpen);
	};

	const getCorrectionMultiplier = () => {
		return appState.app.spaceViewRenderer.correctionMultiplier.current;
	};

	const onInputChange = (value: string) => {
		setInputValue(value);
	};

	const onOptionChange = (option: IOption) => {
		setSelectedOptionId(option.id);
	};

	const onApply = async () => {
		const newValue = parseFloat(inputValue) * getCorrectionMultiplier();
		const itemsWithPermission = items.filter((item) => appState.actions.someSpaceItemsHaveGivenPermission([item], Permission.Update));

		const itemsToUpdate = itemsWithPermission.filter((item: Xyicon3D) => {
			const value = item.modelData[propertyRef as keyof IModel];

			if (selectedOptionId === "all") {
				return value !== newValue;
			} else if (selectedOptionId === "blank") {
				return StringUtils.isBlank(value);
			} else {
				return item.modelData.refId === selectedOptionId && value !== newValue;
			}
		});

		const confirmed = await ConfirmWindowV5.open(`${itemsToUpdate.length} of ${items.length} items will be updated. Continue?`, "Confirm Update", {
			ok: "Yes",
			cancel: "Cancel",
		});

		if (confirmed) {
			setIsOpen(false);
			if (itemsToUpdate.length > 0) {
				setIsUpdating(true);

				await appState.actions.updateProperties(parseFloat(inputValue), propertyPartName, itemsToUpdate, distanceUnitName);
			}
			setIsUpdating(false);
			setInputValue("");
		}
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const options: IOption[] = [
		{
			id: "all",
			label: "(All Values)",
		},
	];

	let allValuesMatch = true;
	let blankAdded = false;

	let first = true;
	let firstValue: number;

	for (const item of items as Xyicon3D[]) {
		const spaceItemValue = getSpaceItemValue(propertyName, propertyRef, item);
		const value = appState.actions.formatPropValue(spaceItemValue, propertyPartName, item, distanceUnitName);

		if (!MathUtils.isValidNumber(value)) {
			continue;
		}

		// Note: if this is changed to getValue, then firstValue and option.label needs to be formatted to string
		// to avoid crashes from React.
		if (first) {
			firstValue = value;
			first = false;
		}
		if (value !== firstValue) {
			allValuesMatch = false;
		}

		if (StringUtils.isBlank(value)) {
			if (!blankAdded) {
				options.push({
					id: "blank",
					label: "(Blank Values)",
				});
				blankAdded = true;
			}
		} else {
			const id = item.modelData.refId;

			if (!options.some((option) => option.value === value)) {
				options.push({
					id: id,
					label: value,
					value: value,
				});
			}
		}
	}

	return (
		<>
			<FieldV5
				disabled={disabled}
				key={key}
				label={propertyLabel || propertyPartName}
				noWrap={true}
			>
				<MassUpdateFieldValueStyled
					className={ReactUtils.cls("unfocused", {disabled})}
					onClick={onFieldClick}
				>
					{allValuesMatch ? firstValue : <i>Multiple values</i>}
				</MassUpdateFieldValueStyled>
			</FieldV5>
			{isOpen && (
				<MassUpdatePopupStyled
					onClose={onClose}
					label={propertyLabel}
					buttonProps={{
						onClick: onApply,
						disabled: false,
						label: "Confirm",
						hideIcon: true,
					}}
					centerOnScreen={true}
					isSmallPopup={true}
					freezeRoot={true}
				>
					<div className="MassFieldInput">
						<div className="container">
							<OptionSelectorInputStyled>
								<SelectInputV5
									options={options}
									render={(option) => option.label}
									selected={options.find((option) => option.id === selectedOptionId)}
									onChange={onOptionChange}
								/>
							</OptionSelectorInputStyled>
							<ClickToEditInputV5
								dataType={FieldDataType.Numeric}
								dataTypeSettings={
									{
										decimals: 2,
										formatting: "general",
									} as INumericFieldSettingsDefinition
								}
								onLiveChange={onInputChange}
								value={inputValue}
								onChange={onInputChange}
								updating={isUpdating}
								onFocusLossForceBlur={true}
							/>
						</div>
					</div>
				</MassUpdatePopupStyled>
			)}
		</>
	);
};

const MassUpdateFieldValueStyled = styled.div`
	height: 32px;
	width: 100%;
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: 4px;
	${FlexCenterStyle}
	padding-left: 8px;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

import * as React from "react";
import {observer} from "mobx-react";
import type {View} from "../../../../../data/models/View";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {ColumnEditor} from "../../../../widgets/column/ColumnEditor";
import type {IFieldColumn} from "../../../../../data/models/Report";

interface IEditViewColumnsProps {
	readonly feature: XyiconFeature;
	readonly view: View;
	readonly title?: string;
}

@observer
export class EditViewColumns extends React.Component<IEditViewColumnsProps> {
	private addColumns = (fields: IFieldColumn[], index?: number) => {
		this.props.view.addColumns(
			fields.map((f) => f.field.refId),
			index,
			this.props.feature,
		);
	};

	private removeColumns = (fields: IFieldColumn[]) => {
		this.props.view.removeColumnsByRefId(
			fields.map((f) => f.field.refId),
			this.props.feature,
		);
	};

	private reorderColumn = (fromIndex: number | number[], toIndex: number) => {
		this.props.view.reorderColumn(fromIndex, toIndex, this.props.feature);
	};

	private getColumnRefIds = () => {
		return this.props.view.getValidViewColumns(this.props.feature).map((c) => c.field);
	};

	public override render() {
		const {title, feature} = this.props;

		return (
			<ColumnEditor
				title={title ?? "Manage Columns"}
				leftHeader="Hidden Columns"
				rightHeader="Visible Columns"
				feature={feature}
				addColumns={this.addColumns}
				removeColumns={this.removeColumns}
				reorderColumn={this.reorderColumn}
				getColumnRefIds={this.getColumnRefIds}
				defaultOpenFeature={feature}
			/>
		);
	}
}

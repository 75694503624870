import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IModel} from "../../../data/models/Model";
import {getModelId} from "../../../data/models/Model";
import type {User} from "../../../data/models/User";
import type {AppState} from "../../../data/state/AppState";
import {XyiconFeature} from "../../../generated/api/base";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {Report} from "../../../data/models/Report";
import type {View} from "../../../data/models/View";
import {PopupUtilsV5} from "../popup/PopupUtilsV5";
import {DateFormatter} from "../../../utils/format/DateFormatter";
import type {UserGroup} from "../../../data/models/UserGroup";
import {ReactUtils} from "../../utils/ReactUtils";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {ButtonV5} from "../button/ButtonV5";
import {EmptyListViewV5} from "../details/EmptyListViewV5";
import {TabViewV5} from "../widgets/tab/TabViewV5";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import {DeleteUserAndTransfrOwnerShipPopup} from "../../modules/abstract/popups/DeleteUserAndTransfrOwnerShipPopup";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import {PopupV5} from "../popup/PopupV5";
import {colorPalette} from "../styles/colorPalette";
import {CreateUserGroupPanelV5} from "../settings/permissions/usergroups/CreateUserGroupPanelV5";
import {CreatePortfolioGroupPanelV5} from "../settings/permissions/portfoliogroups/CreatePortfolioGroupPanelV5";
import {IconButtonStyled} from "../interaction/IconButtonV5";
import {SpaceActionBarStyled} from "../modules/spaceeditor/spaceactionbar/SpaceActionBar.styled";
import {CreatePopupFieldStyled} from "../popup/CreatePopupField.styled";
import {GridActionBarV5} from "./GridActionBarV5";
import type {IActionBarItem} from "./ModuleViewV5";
import {SimpleTableV5} from "./table/SimpleTableV5";
import {TabChildV5} from "./table/TabChildV5";

const TabViewStyled = styled.div`
	width: 100%;
	padding-bottom: 16px;

	.TableView {
		flex: 1 1 0px;

		.Table .body .tr .TableCell {
			margin-top: 10px;
		}

		.Table .body .tr .td {
			margin-top: auto;
			margin-bottom: auto;
			padding-left: 6px;
		}

		.header {
			padding: 16px;
		}

		.head {
			padding-left: 10px;
		}

		${IconButtonStyled}::after {
			display: none;
		}

		${SpaceActionBarStyled} {
			bottom: 150px;
		}
	}
`;

const AddUserContainerStyled = styled.div`
	position: absolute;
	z-index: 9;
	background: ${colorPalette.white};
	right: 0;
	width: 416px;
	height: calc(100vh - 65px);
	box-sizing: border-box;
	transform: translateX(100%);
	transition: ease-in-out 0.2s transform;
	border-left: 1px solid ${colorPalette.gray.c300};
	overflow-y: auto;

	&.open {
		transform: translateX(0%);
	}
`;

const TableViewSplitterStyled = styled.div`
	&.blurred {
		.overlay {
			position: absolute;
			width: 100%;
			height: 100vh;
			background-color: black;
			opacity: 0.3;
			left: 200px;
			z-index: 8;
		}
	}

	.fulltableview {
		width: 100%;
		height: 100vh;
		display: flex;
		flex-direction: row;
	}

	.tableViewBody {
		width: calc(100% - 416px);
	}

	.SidePanel {
		position: absolute;
		top: 0;
		right: 0;
		width: 416px;
		border-left: 1px solid ${colorPalette.gray.c300};
		height: 100vh;
		overflow-y: auto;
	}
`;

interface ITableViewPropsV5<T extends IModel> {
	readonly ownFeature: XyiconFeature;
	readonly headers: {
		id: string;
		title: string;
	}[];
	readonly create: (onCreated: (id: string) => void, onCancel: () => void, creating: boolean) => React.ReactElement;
	readonly details: (focusedItems: T[]) => React.ReactElement;

	readonly appState?: AppState;
	readonly transport?: TransportLayer;

	readonly actionBar: IActionBarItem<T>[];
	readonly canDeleteUser?: (selectedItems: T[]) => boolean;
}

interface ITableViewStateV5<T extends IModel> {
	creating: boolean;
	selectedIds: string[];
	focusedIds: string[];
	searchString: string;
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	userDeleteWindowOpen: boolean;
	selectedItems: T[];
}

@inject("appState")
@inject("transport")
@observer
export class TableViewV5<T extends IModel> extends React.Component<ITableViewPropsV5<T>, ITableViewStateV5<T>> {
	private _isDeletePopupWindowOpen = false;
	private _table = React.createRef<SimpleTableV5<T>>();
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private parentRef = React.createRef<HTMLDivElement>();

	private readonly _actionBar: {
		handlers: {
			[action: string]: () => void;
		};
		enabled?: {
			[action: string]: (items: T[]) => boolean;
		};
	};

	constructor(props: ITableViewPropsV5<T>) {
		super(props);
		this.state = {
			creating: false,
			selectedIds: [],
			focusedIds: [],
			searchString: "",
			isToolTipOpen: false,
			toolTipTransform: null,
			userDeleteWindowOpen: false,
			selectedItems: [],
		};

		this._actionBar = {
			handlers: {
				delete: this.onDeleteClick,
			},
			enabled: {
				delete: (items) => items.length > 0,
			},
		};
	}

	public selectItem(item: T) {
		this.setState({
			selectedIds: [item.id],
		});

		this._table.current?.goToItem(item);
	}

	public focusItem(item: T) {
		this.setState({
			focusedIds: [item.id],
		});

		this._table.current?.goToItem(item);
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.setState({creating: false});
				break;
		}
	};

	private onAddClick = () => {
		this.setState({creating: true});
	};

	private onDeleteClick = async () => {
		const {appState, canDeleteUser, transport, ownFeature} = this.props;
		const {selectedIds, focusedIds, selectedItems} = this.state;

		await transport.services.feature.refreshList(XyiconFeature.Report);

		const reportsOwnedBySelectedUsers =
			appState.actions.getList(XyiconFeature.Report).filter((report: Report) => selectedIds.find((id) => id === report.ownerUserId)) || [];
		const viewsOwnedBySelectedUsers: View[] = [];

		for (const viewId in appState.viewsMap) {
			const view = appState.actions.getViewById(viewId);

			if (selectedIds.find((id) => id === view.ownedBy) || focusedIds.find((id) => id === view.ownedBy)) {
				viewsOwnedBySelectedUsers.push(view);
			}
		}

		if (
			!this._isDeletePopupWindowOpen &&
			ownFeature === XyiconFeature.User &&
			!canDeleteUser(selectedItems) &&
			(reportsOwnedBySelectedUsers.length !== 0 || viewsOwnedBySelectedUsers.length !== 0)
		) {
			this.setState({
				userDeleteWindowOpen: true,
			});
		} else if (!this._isDeletePopupWindowOpen) {
			const {selectedIds} = this.state;
			const count = selectedIds.length;
			let confirmed = false;

			this._isDeletePopupWindowOpen = true;

			if (ownFeature === XyiconFeature.User) {
				confirmed = await PopupUtilsV5.getDeleteUserPopupV5(count);
			} else {
				confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(ownFeature, count);
			}

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const items = selectedIds.map((id) => appState.actions.getFeatureItemById<T>(id, ownFeature));

				if (ownFeature === XyiconFeature.User) {
					appState.actions.deleteUsers(items as unknown as User[], null);
				} else {
					this.deleteItems(items);
				}
			}
		}
	};

	private deleteItems = async (items: T[]) => {
		const {appState, ownFeature} = this.props;

		const res = await appState.actions.deleteItems(items, ownFeature);

		this.setState({
			selectedIds: [],
		});
	};

	private onCancelCreate = () => {
		this.setState({creating: false});
	};

	private onCreated = (createdId: string) => {
		this.setState({creating: false, selectedIds: [createdId]});
		// TODO scrollto there
	};

	private getFields = (item: T): (string | number)[] => {
		const {headers, appState} = this.props;

		return headers.map((header) => {
			// TODO
			const id = header.id;

			if (id === "lastModifiedAt") {
				return DateFormatter.format(item.lastModifiedAt);
			}
			if (id === "lastModifiedBy") {
				return appState.actions.renderName(item.lastModifiedBy);
			}
			if (["userIds", "portfolioIds"].includes(id)) {
				const ownFeature = id === "userIds" ? XyiconFeature.User : XyiconFeature.Portfolio;

				return (item[id as keyof IModel] as Array<string>).filter((id) => appState.actions.getFeatureItemById(id, ownFeature)).length;
			}
			return item[id as "name"];
		});
	};

	private onTableSearch = (value: string) => {
		this.setState({searchString: value.toLowerCase()});
	};

	private get modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		this.props.transport.services.feature.refreshList(this.props.ownFeature);
	}

	public override componentDidUpdate = (prevProps: ITableViewPropsV5<T>, prevState: ITableViewStateV5<T>) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.center,
				),
			});
		}
	};

	private closeUserDeletePopup = () => {
		this.setState({userDeleteWindowOpen: false});
	};

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	private onActionBarClick = (item: IActionBarItem<T>) => {
		if (item.onClick) {
			item.onClick(this.state.selectedItems);
		} else {
			const handler = this._actionBar.handlers[item.id];

			if (handler) {
				handler();
			} else {
				console.warn("No handler and default onClick for actionBar", item);
			}
		}
	};

	private onCloseGridActionBar = () => {
		this.setState({selectedIds: []});
	};

	public override render() {
		const {headers, create, details, appState, ownFeature} = this.props;
		const {creating, searchString, isToolTipOpen, toolTipTransform, selectedIds, userDeleteWindowOpen} = this.state;

		const items = appState.actions.getList<T>(ownFeature);

		// TODO
		const selectedItems = this.state.selectedIds.map((id) => items.find((item) => item.id === id)).filter((item) => !!item);

		const focusedItems = this.state.focusedIds.map((id) => items.find((item) => item.id === id)).filter((item) => !!item);

		const ownAccountSelected = focusedItems.includes(this.props.appState.user as unknown as T);
		const areThereAnySystemItemsInSelectedItems = focusedItems.some((item) => (item as unknown as UserGroup).isSystem);

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		return (
			<TabViewStyled>
				<div className="TableView">
					{ownFeature === XyiconFeature.User ? (
						<AddUserContainerStyled className={ReactUtils.cls(`createPanel`, {open: creating})}>
							{create(this.onCreated, this.onCancelCreate, creating)}
						</AddUserContainerStyled>
					) : creating && ownFeature === XyiconFeature.UserGroup ? (
						<PopupV5
							onClose={this.onCancelCreate}
							label={"New User Group"}
							parentRef={this.parentRef.current}
							className="PermissionSetCreation"
							width="400px"
							height="480px"
							freezeRoot={true}
							verticalAlignment={VerticalAlignment.bottomOuter}
							horizontalAlignment={HorizontalAlignment.right}
							noButtons={true}
							isSmallPopup={true}
						>
							<CreatePopupFieldStyled>
								<CreateUserGroupPanelV5
									onCreated={this.onCreated}
									creating={creating}
								/>
							</CreatePopupFieldStyled>
						</PopupV5>
					) : (
						creating &&
						ownFeature === XyiconFeature.PortfolioGroup && (
							<PopupV5
								onClose={this.onCancelCreate}
								label={"New Portfolio Group"}
								parentRef={this.parentRef.current}
								className="PermissionSetCreation"
								width="400px"
								height="480px"
								freezeRoot={true}
								verticalAlignment={VerticalAlignment.bottomOuter}
								horizontalAlignment={HorizontalAlignment.right}
								noButtons={true}
								isSmallPopup={true}
							>
								<CreatePopupFieldStyled>
									<CreatePortfolioGroupPanelV5
										onCreated={this.onCreated}
										creating={creating}
									/>
								</CreatePopupFieldStyled>
							</PopupV5>
						)
					)}

					<TableViewSplitterStyled className={ReactUtils.cls({blurred: creating})}>
						<div className="overlay"></div>
						<div className="fulltableview">
							<div className="tableViewBody">
								<div className="hbox alignCenter justifySpaceBetween aboveTable header">
									<SearchFieldV5
										value={searchString}
										placeholder={"Find ..."}
										onInput={this.onTableSearch}
									/>
									<ButtonV5
										onClick={this.onAddClick}
										label="CREATE"
										title="Create"
										ref={this.parentRef}
									>
										<CirclePlusIcon />
									</ButtonV5>
								</div>
								{items.length > 0 ? (
									<SimpleTableV5
										ref={this._table}
										data={items}
										headers={headers}
										getKey={getModelId}
										getFields={this.getFields}
										selected={selectedItems}
										focused={focusedItems}
										onSelect={(selected) => {
											this.setState({selectedIds: selected.map((item) => item.id)});
										}}
										onFocus={(focused) => {
											this.setState({focusedIds: focused.map((item) => item.id)});
										}}
										tableSearchQuery={searchString}
										isItemUnfocused={false}
									/>
								) : (
									<EmptyListViewV5 feature={ownFeature} />
								)}
							</div>

							<div className="vbox SidePanel right">
								<TabViewV5
									className="SidePanel"
									selectedTabId="details"
								>
									<TabChildV5
										id="details"
										icon="details"
										title="Details"
										label="Details"
									>
										{details(focusedItems)}
									</TabChildV5>
								</TabViewV5>
							</div>
						</div>
					</TableViewSplitterStyled>

					{isToolTipOpen && ownAccountSelected && (
						<InfoBubbleV5
							className="Delete"
							content="You cannot remove yourself from the organization."
							style={inlineStyle}
							divRef={this._floating}
						/>
					)}
					{userDeleteWindowOpen && (
						<DeleteUserAndTransfrOwnerShipPopup
							selectedIds={selectedIds}
							deleteItems={this.deleteItems}
							closeUserDeletePopup={this.closeUserDeletePopup}
						/>
					)}
					{selectedItems.length > 0 && this.props.actionBar?.length > 0 && (
						<GridActionBarV5
							items={selectedItems}
							feature={this.props.ownFeature}
							buttons={this.props.actionBar}
							defaultClick={this.onActionBarClick}
							onClose={this.onCloseGridActionBar}
						/>
					)}
				</div>
			</TabViewStyled>
		);
	}
}

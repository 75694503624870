import {css, styled} from "styled-components";
import {fontSize, fontWeight, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

const SearchFieldStyle = css`
	border-radius: ${radius.sm};
	background-color: ${colorPalette.white};
	border: 1px solid ${colorPalette.gray.c300};
	display: flex;
	align-items: center;
	position: relative;
	font-size: 14px;

	&:hover {
		input {
			background-color: ${colorPalette.gray.c200Light};
		}
	}

	input {
		height: 32px;
		border: none;
		background-color: transparent;
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
		position: relative;
		padding: 0;
		padding-left: 32px;
		width: 100%;

		&:focus-visible {
			outline: solid 1px ${colorPalette.blueGray.c500Primary};
			background-color: ${colorPalette.white};
		}

		&::placeholder {
			color: ${colorPalette.gray.c950};
			opacity: 1;
		}
	}

	svg {
		position: absolute;
		width: 16px;
		height: 16px;
		min-width: 16px;
		min-height: 16px;
		left: 8px;
		top: 8px;

		&.cancel {
			left: auto;
			right: 8px;
			cursor: pointer;
		}

		path {
			width: 12px;
			height: 12px;
		}
	}
`;

export const SearchFieldStyled = styled.div<{$minimized?: boolean; $extended?: boolean; $fullWidth?: boolean; $multiselectDropdown?: boolean}>`
	${SearchFieldStyle};

	${(props) => {
		if (props.$extended) {
			return css`
				width: ${props.$fullWidth ? "100%" : "auto"};
				input {
					padding-right: 32px;
					&:focus-visible {
						outline: solid 1px ${colorPalette.blueGray.c500Primary};
					}
				}
			`;
		}

		if (props.$minimized) {
			return css`
				font-size: 14px;
				height: ${props.$multiselectDropdown ? "auto" : "32px"};
				background-color: ${props.$extended ? "initial" : "transparent"};
				cursor: pointer;
				width: ${props.$fullWidth ? "100%" : "auto"};

				svg {
					top: 8px;
				}

				input {
					cursor: pointer;
					height: ${props.$multiselectDropdown ? "auto" : "32px"};
					width: ${props.$extended ? "auto" : "80px"};

					&::placeholder {
						font-size: 14px;
					}
				}
			`;
		}

		if (props.$fullWidth) {
			return css`
				width: 100%;
			`;
		}
		if (props.$multiselectDropdown) {
			return css`
				height: auto;
			`;
		}
	}}
`;

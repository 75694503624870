import * as React from "react";
import {inject} from "mobx-react";
import styled, {css} from "styled-components";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {FieldDataType} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import type {AppState} from "../../../../data/state/AppState";
import {type IInputType} from "../../details/datatypes/TextInputV5";
import {FieldInputLabelsV5, FieldInputsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {SingleLineLabelV5} from "../../details/datatypes/SingleLineLabelV5";
import {SingleLineInputV5} from "../../details/datatypes/SingleLineInputV5";
import {InfoBubbleV5} from "../../button/InfoBubbleV5";
import ErrorInfoIcon from "../../icons/errorInfo.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {FlexCenterStyle, InfoIconStyled, radius} from "../../styles/styles";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";

interface IClickToEditInputProps {
	readonly className?: string;
	readonly dataTypeSettings?: any; // {decimals: number, formatting: "currency", maximum: number, minimum: number}
	readonly dataType?: FieldDataType;
	readonly value: any;
	readonly placeholder: any;
	readonly trimText?: boolean;
	readonly disabled?: boolean;
	readonly updating?: boolean;
	readonly hasValidation?: boolean;
	readonly focused?: boolean;
	readonly dateFormat?: string;
	readonly onFocusLossForceBlur?: boolean;
	readonly inputType?: IInputType;
	readonly caretPosition?: number;
	readonly appState?: AppState;
	readonly inline?: boolean;
	readonly onLiveChange?: (value: any) => void;
	readonly onCancelEdit?: () => void;
	readonly onChange: (value: any) => void;
	readonly getErrorMessage?: (value: any) => string; // returns an errormessage (or empty string, if no errors)
	readonly showErrorOnlyIfEdited?: boolean;
	readonly onClick?: (event?: React.MouseEvent) => void;
	readonly onHover?: (over?: boolean) => void;
	readonly onBlur?: () => void;
}

interface IClickToEditInputState {
	editing: boolean;
	editingValue: any;
	inputHeight: number;
	inputScrollHeight: number;
	isLabelInSecondaryState: boolean;
	isFocusingInfoIcon: boolean;
	hasBeenEditedSinceSave: boolean;
	infoBubbleStyle: React.CSSProperties;
	isErrorMessageRightAlign: boolean;
}

@inject("appState")
export class ClickToEditInputV5 extends React.Component<IClickToEditInputProps, IClickToEditInputState> {
	public static readonly defaultProps: Partial<IClickToEditInputProps> = {
		dataType: FieldDataType.SingleLineText,
		value: null, // without this, this.state.editingValue !== this.props.value is false, and we're updating the backend with the same nullish value unnecessarily
		trimText: true,
		getErrorMessage: (value: any) => "",
		showErrorOnlyIfEdited: true,
	};
	private _infoIconRef = React.createRef<HTMLDivElement>();
	private _infoBubbleRef = React.createRef<HTMLDivElement>();
	public _element = React.createRef<HTMLDivElement>();
	private _input = React.createRef<HTMLDivElement>();
	private _labelContent = React.createRef<HTMLDivElement>();
	private _intervalId: number = -1;

	constructor(props: IClickToEditInputProps) {
		super(props);
		this.state = {
			editing: false,
			editingValue: this.props.value,
			inputHeight: null,
			isLabelInSecondaryState: false,
			inputScrollHeight: null,
			isFocusingInfoIcon: false,
			hasBeenEditedSinceSave: false,
			infoBubbleStyle: {},
			isErrorMessageRightAlign: false,
		};
	}

	private onClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.refreshEditState();

		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.props.onClick?.(event);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.onCancelEdit();
				break;
			case KeyboardListener.KEY_ENTER:
				if (!(this.props.dataType === FieldDataType.MultiLineText && KeyboardListener.isShiftDown)) {
					this.onApplyEdit();
				}
				break;
		}
	};

	private onFocusLoss = (e?: MouseEvent) => {
		let eventTargetInModalContainer = false;

		if (e?.target instanceof Element) {
			eventTargetInModalContainer = this.props.appState.app.modalContainer?.contains(e?.target);

			if (!eventTargetInModalContainer) {
				// the eventTarget is not in modalContainer
				if (!this.onApplyEdit()) {
					this.onCancelEdit();
				}
			} else {
				if (this.props.appState.app.modalContainer?.contains(this._element.current)) {
					// the eventTarget and the field is in modalContainer (Popups)
					if (!this.onApplyEdit()) {
						this.onCancelEdit();
					}
				}
			}
		} else {
			if (!this.onApplyEdit()) {
				this.onCancelEdit();
			}
		}
	};

	private onCancelEdit = () => {
		this.cancelLocalEdit();
		this.props.onCancelEdit?.();
	};

	public cancelLocalEdit() {
		if (this.state.editing) {
			this.setState({
				editing: false,
				editingValue: this.props.value,
			});

			this.props.onLiveChange?.(this.props.value);
		}
	}

	private onApplyEdit = () => {
		const editing = this.state.editing;

		this.setState({
			editing: false,
		});

		const editingValue = this.editingValue;

		if (editing && !this.props.getErrorMessage(editingValue) && editingValue !== this.props.value) {
			this.props.onChange?.(editingValue);
			return true;
		}

		return false;
	};

	public get editingValue() {
		return this.trimValueIfNeeded(this.state.editingValue);
	}

	private onEditingInput = (value: any) => {
		this.setState({editingValue: value, hasBeenEditedSinceSave: true});
		this.props.onLiveChange?.(value);
	};

	private trimValueIfNeeded(editingValue: string | number) {
		if (this.props.trimText && typeof editingValue === "string") {
			editingValue = editingValue.trim();
		}

		return editingValue;
	}

	private onChange = (value: any) => {
		const newValue = this.trimValueIfNeeded(value);
		this.setState({editingValue: newValue});
		this.props.onChange(newValue);
	};

	private onBlur = () => {
		const {onBlur, value} = this.props;
		if (onBlur) {
			if (this.props.getErrorMessage(value)) {
				this.setState({editingValue: value});
			} else {
				onBlur?.();
			}
		}

		this.setState({
			isFocusingInfoIcon: false,
		});
	};

	private handleFocus = () => {
		this.setState({isFocusingInfoIcon: true});
	};

	private onLabelStateChange = (value: boolean) => {
		this.setState({isLabelInSecondaryState: value});
	};

	private getLabel() {
		const {placeholder, dataType, dataTypeSettings, appState} = this.props;
		const {editingValue} = this.state;
		const errorMessage = this.props.getErrorMessage(editingValue);
		let {value} = this.props;

		let LabelComponent = FieldInputLabelsV5[dataType];

		LabelComponent = LabelComponent || SingleLineLabelV5;

		if (!value && placeholder) {
			value = placeholder;
		}

		const final = appState.actions.getURLsInTextInputs(value);

		return (
			<LabelComponent
				value={errorMessage ? editingValue : final}
				dataTypeSettings={dataTypeSettings}
				onLabelStateChange={this.onLabelStateChange}
				divRef={this._labelContent}
				height={this._labelContent.current?.scrollHeight || 0}
			/>
		);
	}

	private getEditInput() {
		const {dataType, dataTypeSettings, onFocusLossForceBlur, inputType, caretPosition, focused} = this.props;
		const {editingValue, inputHeight, isLabelInSecondaryState, inputScrollHeight} = this.state;

		let InputComponent = FieldInputsV5[dataType];

		InputComponent = InputComponent || SingleLineInputV5;
		return (
			<InputComponent
				value={editingValue ?? this.props.dateFormat}
				dataTypeSettings={dataTypeSettings}
				onInput={this.onEditingInput}
				onCancel={this.onCancelEdit}
				onApply={this.onApplyEdit}
				onChange={this.onChange}
				onBlur={this.onBlur}
				height={inputHeight}
				scrollHeight={inputScrollHeight}
				onFocusLossForceBlur={onFocusLossForceBlur}
				noButtons={true}
				inputType={inputType}
				secondaryState={isLabelInSecondaryState}
				caretPosition={caretPosition}
				expand={isLabelInSecondaryState}
				focused={focused}
			/>
		);
	}

	private refreshEditState() {
		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.addListeners();
	}

	private refreshInputHeight() {
		const inputRef = this._input.current;
		const labelRef = this._labelContent.current;

		if (labelRef && inputRef && inputRef.offsetHeight !== this.state.inputHeight && labelRef.offsetHeight !== this.state.inputScrollHeight) {
			this.setState({
				inputHeight: inputRef.offsetHeight,
				inputScrollHeight: labelRef.scrollHeight,
			});
		}
	}

	private onMouseOver = () => {
		this.props.onHover?.(true);
	};

	private onMouseLeave = () => {
		this.props.onHover?.(false);
	};

	private removeListeners() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		FocusLoss.stopListen(this._element.current, this.onFocusLoss);
	}

	private addListeners() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		FocusLoss.listen(this._element.current, this.onFocusLoss, null, "up");
	}

	private turnFocusOn = () => {
		this.setState({isFocusingInfoIcon: true});
	};

	private turnFocusOff = () => {
		this.setState({isFocusingInfoIcon: false});
	};

	private updateInfoBubbleStyle = () => {
		const parentElement = this._infoIconRef.current;
		const floatingElement = this._infoBubbleRef.current;

		if (floatingElement && parentElement) {
			const parent = parentElement.getBoundingClientRect();
			const floating = floatingElement.getBoundingClientRect();
			const x = parent.left + parent.width / 2 - floating.width / 2 + 5;

			let horizontalAlignment: HorizontalAlignment;

			if (x + floating.width > window.innerWidth - 5) {
				horizontalAlignment = HorizontalAlignment.right;
				if (!this.state.isErrorMessageRightAlign) {
					this.setState({isErrorMessageRightAlign: true});
				}
			} else {
				horizontalAlignment = HorizontalAlignment.center;
				if (this.state.isErrorMessageRightAlign) {
					this.setState({isErrorMessageRightAlign: false});
				}
			}

			const infoBubbleStyle: React.CSSProperties = {};
			const transformObj: TransformObj = DomUtils.getFixedFloatingElementPosition(
				parentElement,
				floatingElement,
				VerticalAlignment.topOuter,
				horizontalAlignment,
				5,
				0,
				true,
			);

			infoBubbleStyle.transform = transformObj.translate;

			if (!ObjectUtils.compare(this.state.infoBubbleStyle, infoBubbleStyle)) {
				this.setState({
					infoBubbleStyle,
				});
			}
		}
	};

	public override componentDidMount() {
		this.refreshInputHeight();
		this.addListeners();

		this._intervalId = window.setInterval(this.updateInfoBubbleStyle, 0);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IClickToEditInputProps>, nextContext: any): void {
		if (this.props.value !== nextProps.value) {
			this.setState({
				editingValue: nextProps.value,
			});

			if (!nextProps.onLiveChange) {
				this.setState({
					hasBeenEditedSinceSave: false,
				});
			}
		}
	}

	public override componentDidUpdate(prevProps: IClickToEditInputProps, prevState: IClickToEditInputState) {
		this.refreshInputHeight();

		if (prevProps.focused !== this.props.focused) {
			this.refreshEditState();
		}

		if (prevProps.focused && !this.props.focused) {
			this.onApplyEdit();
		}
	}

	public override componentWillUnmount() {
		this.onFocusLoss();
		this.removeListeners();
		clearInterval(this._intervalId);
	}

	public override render() {
		const {className, disabled, updating, hasValidation, inline, showErrorOnlyIfEdited, value} = this.props;
		const {editing, hasBeenEditedSinceSave, isFocusingInfoIcon, infoBubbleStyle, isErrorMessageRightAlign} = this.state;
		const editingValue = this.editingValue;
		const errorMessage = this.props.getErrorMessage(editingValue);
		const showErrorMessage = (!showErrorOnlyIfEdited || (showErrorOnlyIfEdited && hasBeenEditedSinceSave)) && errorMessage && !disabled;

		return (
			<ClickToEditInputStyled
				className={className || ""}
				ref={this._element}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
				$error={showErrorMessage}
				$editing={editing}
				$empty={!editingValue}
				$inline={inline}
				onFocus={this.handleFocus}
				onBlur={this.onBlur}
				title={value}
			>
				{showErrorMessage && (
					<InfoIconStyled
						ref={this._infoIconRef}
						className={ReactUtils.cls("infoIcon", {isFocusingInfoIcon})}
					>
						<ErrorInfoIcon
							onMouseEnter={this.turnFocusOn}
							onMouseLeave={this.turnFocusOff}
						/>
						<InfoBubbleV5
							divRef={this._infoBubbleRef}
							style={infoBubbleStyle}
							content={errorMessage}
							isErrorMessage={true}
							hide={!editing && !isFocusingInfoIcon}
							isErrorMessageRightAlign={isErrorMessageRightAlign}
						/>
					</InfoIconStyled>
				)}
				{editing && !disabled ? (
					this.getEditInput()
				) : (
					<div
						className={ReactUtils.cls("unfocused", {disabled, updating})}
						onClick={this.onClick}
						ref={this._input}
					>
						{this.getLabel()}
					</div>
				)}
				{updating && <span className={ReactUtils.cls("spinner", {hasValidation})} />}
			</ClickToEditInputStyled>
		);
	}
}

export const ClickToEditInputStyled = styled.div<{$error: boolean; $editing: boolean; $empty: boolean; $inline: boolean}>`
	width: 100%;
	position: relative;

	> div,
	input {
		height: 100%;
	}

	.infoIcon {
		position: absolute;
		top: 0;
		right: 5px;

		svg {
			height: 16px;
			width: 16px;
			color: #ff4136;
		}
	}
	${(props) => {
		if (props.$editing) {
			return css`
				.field-input-container {
					input {
						padding-right: ${props.$error ? "8px" : "4px"};
						border: solid 1px ${colorPalette.blueGray.c500Primary};
					}
				}

				.multiline {
					textarea {
						border: solid 1px var(--blue);
					}

					&:has(.field-input-container) {
						textarea {
							border-bottom: none;
						}
					}
				}
			`;
		}
	}}

	${(props) => {
		if (props.$error) {
			return css`
				.field-input-container {
					input {
						border: 1px solid red;
					}
				}

				.unfocused {
					border: 1px solid red;
				}

				border-radius: ${radius.sm};
			`;
		}
	}}

	.SingleLineLabel {
		overflow-x: hidden;
		text-overflow: ellipsis;
	}

	.spinner {
		position: absolute;
		top: 8px;
		right: 7px;
		visibility: visible;
		border-radius: 50%;
		width: 16px;
		height: 16px;

		border: 2px solid transparent;
		border-top: 2px solid #3495f0;
		border-left: 2px solid #3495f0;
		border-bottom: 2px solid #3495f0;
		animation: spin 2s linear infinite;

		&.hasValidation {
			border-top: 2px solid #ef9647;
			border-left: 2px solid #ef9647;
			border-bottom: 2px solid #ef9647;
		}
	}

	.field-input-container {
		align-items: stretch;
		width: 100%;

		input {
			background: transparent;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.multiline {
		textarea {
			max-height: 94px;
			overflow-y: auto;
			line-height: 16px;
			resize: none;
			margin-bottom: 0;
			padding: 10px;
			height: inherit;

			&:focus {
				background: ${colorPalette.white};
			}
		}

		&.extended,
		&.expand {
			textarea {
				max-height: 400px;
				overflow-y: auto;
			}
		}
	}

	.unfocused,
	input {
		border: ${(props) => (props.$inline ? "none" : `1px solid ${colorPalette.gray.c300}`)};
		border-radius: ${radius.sm};
		padding: 7px;
		width: 100%;
		font-size: 14px;
		outline: none;
	}

	.unfocused {
		display: flex;
		align-items: center;
		cursor: text;
		align-items: center;
		word-break: break-all;
		min-height: 32px;

		&:hover:not(.disabled) {
			background-color: ${colorPalette.gray.c200Light};
		}

		&.disabled {
			border-color: transparent;
			cursor: auto;

			.MultiLineLabel {
				pointer-events: all;
			}
		}

		p {
			margin: 0;
			line-height: 18px;
			white-space: break-spaces;
		}

		&.updating {
			.SingleLineLabel {
				width: calc(100% - 10px);
			}
		}

		&:empty {
			${FlexCenterStyle};
			height: 32px;
		}
	}

	&.noButtons {
		.infoIcon {
			right: 10px;
			width: 10px;
			height: 10px;
			position: absolute;
		}
	}

	&.infoBubbleAlignLeft {
		.InfoBubble {
			transform: translate(calc(-100% + 15px), -44px);

			&::after {
				right: 8px;
			}
		}
	}

	&.portfolionameInput {
		.error {
			left: -60px;
			&::after {
				left: auto;
				right: 5px;
			}
		}
	}
`;

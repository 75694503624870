import {HTMLTableGenerator} from "../../ui/actionbar/HTMLTableGenerator";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {ItemSelectionBox} from "../elements3d/ItemSelectionBox";
import type {Markup3D} from "../elements3d/markups/abstract/Markup3D";
import type {BoundarySpaceMap3D} from "../elements3d/BoundarySpaceMap3D";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import {MarkupUtils} from "../elements3d/markups/abstract/MarkupUtils";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import type {Xyicon, IXyiconMinimumData} from "../../../../../../data/models/Xyicon";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {IMarkupMinimumData, IMarkupSettingsData} from "../../../../../../data/models/Markup";
import {Markup} from "../../../../../../data/models/Markup";
import type {AppActions} from "../../../../../../data/state/AppActions";
import {MarkupType, XyiconFeature, FieldDataType} from "../../../../../../generated/api/base";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import type {PointDouble, XyiconLinkDetail} from "../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {IBoundaryMinimumData} from "../../../../../../data/models/Boundary";
import type {IBooleanFieldSettingsDefinition} from "../../../../settings/modules/field/datatypes/BooleanFieldSettings";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {Signal} from "../../../../../../utils/signal/Signal";
import {convertColumnNameToPropertyName} from "./ClipboardUtils";

export type ClipboardActionType = "COPY" | "STAMP" | "CUT";

interface IXyiconHeaderIndices {
	guid?: number; // for cut-paste
	id: number;
	model: number;
	// type: number;
	portData: number;
	parentXyiconId: number;
	iconX: number;
	iconY: number;
	iconZ: number;
	orientation: number;
	settings: number;
}

interface IBoundaryHeaderIndices {
	guid?: number; // for cut-paste
	id: number;
	type: number;
	geometryData: number;
	orientation: number;
}

interface IMarkupHeaderIndices {
	guid?: number; // for cut-paste
	type: number;
	geometryData: number;
	orientation: number;
	text: number;
	fillTransparency: number;
	lineThickness: number;
	settings: number;
	color: number;
}

interface IDataForSpaceItemCreation {
	actions: AppActions;
	header: string[];
	items: IXyiconMinimumData[] | IBoundaryMinimumData[] | IMarkupMinimumData[];
	fields: IFieldAdapter[];
	additionalFieldIndices: number[];
	bbox: ItemSelectionBox;
	headerIndices: IXyiconHeaderIndices | IBoundaryHeaderIndices | IMarkupHeaderIndices;
}

export class ClipboardManager {
	private static _pasteInstances: number = 0;
	public static signals = {
		pasteDone: Signal.create(),
	};
	public static get isPasting(): boolean {
		return this._pasteInstances > 0;
	}

	// COPY is the default. CUT is changed to COPY when it's not guaranteed that the last user action was CUT
	// To do achieve this, we change it to COPY when the window loses focus
	private static _action: ClipboardActionType = "COPY";
	private static readonly _defaultGeometrySize: number = 2;
	public static onBlur = () => {
		ClipboardManager._action = "COPY";
		return false;
	};

	public static get action() {
		return this._action;
	}

	private static _storedHTML: string = "";
	public static get storedHTML() {
		return this._storedHTML;
	}

	public static getSelectedText() {
		return window.getSelection().anchorNode?.textContent || "";
	}

	public static clearClipboard() {
		const div = document.createElement("div");

		div.textContent = "SpaceRunner V4"; // Completely empty divs are not copied to the clipboard for some reason
		this.copyHTMLElementToClipboard(div);
	}

	// https://stackoverflow.com/questions/26053004/copy-whole-html-table-to-clipboard-javascript/26053690
	// https://stackoverflow.com/questions/34045777/copy-to-clipboard-using-javascript-in-ios
	private static copyHTMLElementToClipboard(element: HTMLElement) {
		element.innerHTML = element.innerHTML.replace(/ {2,}/g, (match: string) => {
			const spacesCount = match.length;
			const nbspCount = "&nbsp;".repeat(spacesCount - 1);

			return ` ${nbspCount}`;
		});

		const isElementPartOfDom = HTMLUtils.isDescendant(document.body, element);

		if (!isElementPartOfDom) {
			document.body.appendChild(element); // add temporarily, otherwise it won't work
		}

		if (document.createRange && window.getSelection) {
			const el = element as any;

			const old = {
				contentEditable: el.contentEditable,
				readOnly: el.readOnly,
			};

			el.contentEditable = true;
			el.readOnly = false;

			const range = document.createRange();
			const sel = window.getSelection();

			sel.removeAllRanges();

			try {
				range.selectNodeContents(element);
			} catch (error) {
				range.selectNode(element);
			}

			sel.addRange(range);

			if (el.setSelectionRange) {
				el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
			}
			el.contentEditable = old.contentEditable;
			el.readOnly = old.readOnly;
		}

		document.execCommand("copy");

		if (!isElementPartOfDom) {
			document.body.removeChild(element);
		}
	}

	public static copyItems(
		xyicons: Xyicon[],
		boundaries: BoundarySpaceMap[],
		markups: Markup[],
		actions: AppActions,
		clipboardAction: ClipboardActionType,
	) {
		this._action = clipboardAction;

		const xyiconTable = HTMLTableGenerator.createTableForSpaceItems(xyicons, actions);
		const boundaryTable = HTMLTableGenerator.createTableForSpaceItems(boundaries, actions);
		const markupTable = HTMLTableGenerator.createTableForSpaceItems(markups, actions);

		const filteredTables = [xyiconTable, boundaryTable, markupTable].filter((table) => !!table);

		if (filteredTables.length > 0) {
			const div = document.createElement("div");

			for (let i = 0; i < filteredTables.length; ++i) {
				const table = filteredTables[i];

				div.appendChild(table);
				if (i + 1 < filteredTables.length) {
					div.appendChild(document.createElement("br"));
				}
			}

			this.copyHTMLElementToClipboard(div);
			ClipboardManager._storedHTML = div.outerHTML;
		}
	}

	private static createMarkupFromLine(lineAsString: string, dataForMarkupCreation: IDataForSpaceItemCreation) {
		const line = lineAsString.split("\t");
		const {bbox} = dataForMarkupCreation;
		const headerIndices = dataForMarkupCreation.headerIndices as IMarkupHeaderIndices;
		const items = dataForMarkupCreation.items as IMarkupMinimumData[];

		if (headerIndices.type > -1) {
			const type = MarkupType[line[headerIndices.type] as keyof typeof MarkupType] as MarkupType;
			const color = line[headerIndices.color] ?? "000000";
			const geometryData = JSON.parse(line[headerIndices.geometryData] || "[]") as PointDouble[];

			bbox.expandByGeometryData(geometryData);

			const markupSettingsMaybe = JSON.parse(line[headerIndices.settings] || null) as IMarkupSettingsData;

			if (markupSettingsMaybe?.target) {
				bbox.expandByGeometryData([markupSettingsMaybe.target]);
			}

			items.push({
				id: null,
				guid: line[headerIndices.guid] || null,
				type: type,
				color: color,
				geometryData: geometryData,
				lineThickness: MathUtils.isValidNumber(parseFloat(line[headerIndices.lineThickness]))
					? parseFloat(line[headerIndices.lineThickness])
					: MarkupUtils.defaultLineThickness,
				orientation: MathUtils.isValidNumber(parseFloat(line[headerIndices.orientation])) ? parseFloat(line[headerIndices.orientation]) : 0,
				text: line[headerIndices.text] ? JSON.parse(line[headerIndices.text]) : Markup.defaultText,
				fillTransparency: MathUtils.isValidNumber(parseFloat(line[headerIndices.fillTransparency]))
					? parseFloat(line[headerIndices.fillTransparency])
					: 1 - MarkupUtils.getDefaultFillOpacityForType(type),
				settings: line[headerIndices.settings] ?? "",
			});
		}
	}

	private static createMarkupsFromBlockOfText(
		block: string,
		spaceViewRenderer: SpaceViewRenderer,
		bbox: ItemSelectionBox,
		currentPointerWorld: PointDouble,
	) {
		const markups: IMarkupMinimumData[] = [];

		if (block && currentPointerWorld) {
			const actions = spaceViewRenderer.actions;
			const lines = block.split("\n");
			const header = lines[0].split("\t");

			// gets excluded from fields
			const emphasizedHeaderNames = [
				"guid",
				"markup type",
				"markup color",
				"markup geometry",
				"markup fill transparency",
				"markup line thickness",
				"markup settings",
				"markup text",
				"markup orientation",
			].map((str) => convertColumnNameToPropertyName(str));
			const headerIndices: IMarkupHeaderIndices = {
				guid: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "guid"),
				type: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "markup type"),
				color: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "markup color"),
				geometryData: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "markup geometry"),
				fillTransparency: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "markup fill transparency"),
				lineThickness: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "markup line thickness"),
				settings: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "markup settings"),
				text: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "markup text"),
				orientation: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "markup orientation"),
			};

			const additionalFieldIndices: number[] = this.getAdditionalFieldIndices(header, emphasizedHeaderNames);

			const dataForMarkupCreation: IDataForSpaceItemCreation = {
				actions: actions,
				header: header,
				items: markups,
				fields: [],
				additionalFieldIndices: additionalFieldIndices,
				bbox: bbox,
				headerIndices: headerIndices,
			};

			for (let i = 1; i < lines.length; ++i) {
				this.createMarkupFromLine(lines[i], dataForMarkupCreation);
			}
		}

		return markups;
	}

	private static getDefaultGeometry() {
		const size = this._defaultGeometrySize / 2;

		return [
			{
				x: -size,
				y: -size,
			},
			{
				x: size,
				y: -size,
			},
			{
				x: size,
				y: size,
			},
			{
				x: -size,
				y: size,
			},
		];
	}

	private static createBoundaryFromLine(lineAsString: string, dataForBoundaryCreation: IDataForSpaceItemCreation) {
		const line = lineAsString.split("\t");
		const {actions, additionalFieldIndices, header, fields, bbox} = dataForBoundaryCreation;
		const headerIndices = dataForBoundaryCreation.headerIndices as IBoundaryHeaderIndices;
		const items = dataForBoundaryCreation.items as IBoundaryMinimumData[];

		if (headerIndices.type > -1) {
			const boundaryTypeList = actions.getTypesByFeature(XyiconFeature.Boundary);
			const typeName = StringUtils.removeWhiteSpaces(line[headerIndices.type].toLowerCase());
			const type = boundaryTypeList.find((type) => StringUtils.removeWhiteSpaces(type.name.toLowerCase()) === typeName);

			if (type) {
				const geometryData = JSON.parse(line[headerIndices.geometryData] || "[]") as PointDouble[];

				bbox.expandByGeometryData(geometryData);

				items.push({
					id: line[headerIndices.id] || null,
					guid: line[headerIndices.guid] || null,
					boundaryTypeId: type.id,
					geometryData: geometryData,
					orientation: parseFloat(line[headerIndices.orientation]),
					fieldData: this.getFieldData(header, line, additionalFieldIndices, fields),
				});
			} else {
				console.log(`Type not found with name: ${typeName}`);
				console.log("List of types:");
				console.log(boundaryTypeList);
			}
		}
	}

	private static createBoundariesFromBlockOfText(
		block: string,
		spaceViewRenderer: SpaceViewRenderer,
		bbox: ItemSelectionBox,
		currentPointerWorld: PointDouble,
	) {
		const boundaries: IBoundaryMinimumData[] = [];

		if (block && currentPointerWorld) {
			const actions = spaceViewRenderer.actions;
			const lines = block.split("\n");
			const header = lines[0].split("\t");

			// gets excluded from fields
			const emphasizedHeaderNames = ["guid", "boundary id", "boundary type", "boundary geometry", "boundary orientation"].map((str) =>
				convertColumnNameToPropertyName(str),
			);
			const headerIndices: IBoundaryHeaderIndices = {
				guid: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "guid"),
				id: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "boundary id"),
				type: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "boundary type"),
				geometryData: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "boundary geometry"),
				orientation: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "boundary orientation"),
			};

			const additionalFieldIndices: number[] = this.getAdditionalFieldIndices(header, emphasizedHeaderNames);
			const boundaryFields = actions.getFieldsByFeature(XyiconFeature.Boundary);

			const dataForBoundaryCreation: IDataForSpaceItemCreation = {
				actions: actions,
				header: header,
				items: boundaries,
				fields: boundaryFields,
				additionalFieldIndices: additionalFieldIndices,
				bbox: bbox,
				headerIndices: headerIndices,
			};

			for (let i = 1; i < lines.length; ++i) {
				this.createBoundaryFromLine(lines[i], dataForBoundaryCreation);
			}
		}

		return boundaries;
	}

	private static getFieldData(header: string[], line: string[], additionalFieldIndices: number[], fields: IFieldAdapter[]) {
		const fieldData: {[key: string]: boolean | string | string[] | number} = {};

		for (const index of additionalFieldIndices) {
			const headerColumn = header[index];
			const originalFieldValue = line[index];
			let fieldValue = originalFieldValue;

			try {
				fieldValue = JSON.parse(fieldValue || null);

				// JSON.parse converts "3" to 3
				if (fieldValue == originalFieldValue) {
					// eg.: 3 == "3"
					fieldValue = originalFieldValue;
				}
			} catch (e) {
				fieldValue = originalFieldValue;
			}

			if (fieldValue != null) {
				// can be 0
				const field = fields.find((field) => field.name === headerColumn);

				if (field?.refId.substring(0, 1) === "f") {
					// default fields start with <Feature>/, for example 80/type
					switch (field.dataType) {
						case FieldDataType.Boolean:
							const dataTypeSettings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

							fieldData[field.refId] = dataTypeSettings.displayLabelForTrue === fieldValue;
							break;
						case FieldDataType.MultipleChoiceList:
							fieldData[field.refId] = fieldValue.split(/\<br\/\>/);
							break;
						case FieldDataType.MultiLineText:
							fieldData[field.refId] = fieldValue.replace(/\<br\/\>/g, "\n");
							break;
						case FieldDataType.Numeric:
							const fieldValueAsNumber = parseFloat(fieldValue);

							if (MathUtils.isValidNumber(fieldValueAsNumber)) {
								fieldData[field.refId] = fieldValueAsNumber;
							}
							break;
						default:
							fieldData[field.refId] = fieldValue;
							break;
					}
				}
			}
		}

		return fieldData;
	}

	private static createXyiconFromLine(lineAsString: string, dataForXyiconCreation: IDataForSpaceItemCreation) {
		const line = lineAsString.split("\t");
		const {actions, additionalFieldIndices, header, fields, bbox} = dataForXyiconCreation;
		const headerIndices = dataForXyiconCreation.headerIndices as IXyiconHeaderIndices;
		const items = dataForXyiconCreation.items as IXyiconMinimumData[];

		if (headerIndices.model > -1) {
			const modelName = StringUtils.removeWhiteSpaces(line[headerIndices.model].toLowerCase());
			const catalog = actions
				.getList(XyiconFeature.XyiconCatalog)
				.find((catalog: Catalog) => StringUtils.removeWhiteSpaces(catalog.model.toLowerCase()) === modelName);

			if (catalog) {
				const iconX = parseFloat(line[headerIndices.iconX]);
				const iconY = parseFloat(line[headerIndices.iconY]);
				const iconZ = parseFloat(line[headerIndices.iconZ]);

				const parentXyiconId = line[headerIndices.parentXyiconId];
				const orientation = parseFloat(line[headerIndices.orientation]);
				const portData = JSON.parse(line[headerIndices.portData] || "[]");
				const settings = JSON.parse(line[headerIndices.settings] || "{}");
				const xyiconId = line[headerIndices.id];

				const xyiconModel: IXyiconMinimumData = {
					guid: line[headerIndices.guid] || null,
					tempId: xyiconId,
					tempParentXyiconId: parentXyiconId,
					iconX: iconX,
					iconY: iconY,
					iconZ: iconZ,
					orientation: orientation,
					catalogId: catalog.id,
					embeddedXyicons: [],
					parentXyicon: null,
					fieldData: this.getFieldData(header, line, additionalFieldIndices, fields),
					portData: portData,
					settings: settings,
				};

				(items as IXyiconMinimumData[]).push(xyiconModel);

				if (MathUtils.isValidNumber(iconX) && MathUtils.isValidNumber(iconY) && !parentXyiconId) {
					bbox.expandByBoundingBox({
						min: {
							x: iconX,
							y: iconY,
						},
						max: {
							x: iconX,
							y: iconY,
						},
					});
				}
			}
		}
	}

	private static getAdditionalFieldIndices(header: string[], emphasizedHeaderNames: string[]) {
		const additionalFieldIndices: number[] = [];

		for (let i = 0; i < header.length; ++i) {
			if (!emphasizedHeaderNames.includes(header[i].toLowerCase())) {
				additionalFieldIndices.push(i);
			}
		}

		return additionalFieldIndices;
	}

	private static createXyiconsFromBlockOfText(
		block: string,
		spaceViewRenderer: SpaceViewRenderer,
		bbox: ItemSelectionBox,
		currentPointerWorld: PointDouble,
	) {
		const xyicons: IXyiconMinimumData[] = [];
		const embeddedXyicons: IXyiconMinimumData[] = [];
		const nonEmbeddedXyicons: IXyiconMinimumData[] = [];

		if (block && currentPointerWorld) {
			const actions = spaceViewRenderer.actions;
			const lines = block.split("\n");
			const header = lines[0].split("\t");

			// gets excluded from fields
			const emphasizedHeaderNames = [
				"guid",
				"xyicon model",
				"xyicon type",
				"xyicon x",
				"xyicon y",
				"xyicon z",
				"xyicon orientation",
				"xyicon id",
				"parent xyicon id",
				"xyicon port data",
				"xyicon settings",
			].map((str) => convertColumnNameToPropertyName(str));
			const headerIndices = {
				guid: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "guid"),
				id: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon id"),
				model: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon model"),
				// type: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon type"),
				portData: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon port data"),
				parentXyiconId: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "parent xyicon id"),
				iconX: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon x"),
				iconY: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon y"),
				iconZ: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon z"),
				orientation: header.findIndex((headerColumn: string) => headerColumn.toLowerCase() === "xyicon orientation"),
				settings: header.findIndex((headerColumn: string) => headerColumn.toLocaleLowerCase() === "xyicon settings"),
			};

			const additionalFieldIndices: number[] = this.getAdditionalFieldIndices(header, emphasizedHeaderNames);
			const xyiconFields = actions.getFieldsByFeature(XyiconFeature.Xyicon);

			const dataForXyiconCreation: IDataForSpaceItemCreation = {
				actions: actions,
				header: header,
				items: xyicons,
				fields: xyiconFields,
				additionalFieldIndices: additionalFieldIndices,
				bbox: bbox,
				headerIndices: headerIndices,
			};

			for (let i = 1; i < lines.length; ++i) {
				this.createXyiconFromLine(lines[i], dataForXyiconCreation);
			}

			for (const xyicon of xyicons) {
				const parentXyiconId = xyicon.tempParentXyiconId;

				if (parentXyiconId) {
					const parentXyicon = xyicons.find((xyicon) => xyicon.tempId === parentXyiconId);

					xyicon.parentXyicon = parentXyicon;
				}

				if (xyicon.parentXyicon) {
					embeddedXyicons.push(xyicon);
				} else {
					nonEmbeddedXyicons.push(xyicon);
				}
			}
		}

		return {
			nonEmbeddedXyicons: nonEmbeddedXyicons,
			embeddedXyicons: embeddedXyicons,
		};
	}

	private static correctPositions(
		nonEmbeddedXyicons: IXyiconMinimumData[],
		embeddedXyicons: IXyiconMinimumData[],
		boundaries: IBoundaryMinimumData[],
		markups: IMarkupMinimumData[],
		bbox: ItemSelectionBox,
		spaceViewRenderer: SpaceViewRenderer,
		currentPointerWorld: PointDouble,
	) {
		if (currentPointerWorld) {
			if (bbox.boundingBox.min.x == null) {
				bbox.expandByBoundingBox({
					min: {
						x: currentPointerWorld.x,
						y: currentPointerWorld.y,
					},
					max: {
						x: currentPointerWorld.x,
						y: currentPointerWorld.y,
					},
				});
			}

			const bboxPos = bbox.position;
			const bboxPosToCursor = THREEUtils.subVec2fromVec2(currentPointerWorld, bboxPos);

			//
			// Xyicons
			//
			const xyicons = [...nonEmbeddedXyicons, ...embeddedXyicons];
			let counterOfXyiconsWithoutPositions = 0;
			const xyiconSize = spaceViewRenderer.xyiconManager.xyiconSize;

			for (const xyicon of xyicons) {
				if (!MathUtils.isValidNumber(xyicon.iconZ)) {
					xyicon.iconZ = 0;
				}

				if (!MathUtils.isValidNumber(xyicon.iconX) || !MathUtils.isValidNumber(xyicon.iconY)) {
					const spiralCoordinate = THREEUtils.getSpiralCoordinate(counterOfXyiconsWithoutPositions++);

					xyicon.iconX = currentPointerWorld.x + spiralCoordinate.x * xyiconSize;
					xyicon.iconY = currentPointerWorld.y + spiralCoordinate.y * xyiconSize;
				} else {
					xyicon.iconX += bboxPosToCursor.x;
					xyicon.iconY += bboxPosToCursor.y;
				}

				if (!MathUtils.isValidNumber(xyicon.orientation)) {
					xyicon.orientation = 0;
				}
			}

			//
			// Boundaries and Markups
			//
			let counterOfEditablesWithoutPositions = 0;
			const boundariesAndMarkups = [...boundaries, ...markups];

			for (const boundaryOrMarkup of boundariesAndMarkups) {
				const isMarkup = markups.includes(boundaryOrMarkup as Markup);
				const markupSettings = JSON.parse((boundaryOrMarkup as IMarkupMinimumData).settings || null) as IMarkupSettingsData;

				if (boundaryOrMarkup.geometryData.length > 0) {
					THREEUtils.applyOffsetToGeometryData(boundaryOrMarkup.geometryData, bboxPosToCursor);

					if (markupSettings?.target) {
						markupSettings.target.x += bboxPosToCursor.x;
						markupSettings.target.y += bboxPosToCursor.y;

						(boundaryOrMarkup as IMarkupMinimumData).settings = JSON.stringify(markupSettings);
					}
				} else {
					const spiralCoordinate = THREEUtils.getSpiralCoordinate(counterOfEditablesWithoutPositions++);

					let geometryData = this.getDefaultGeometry();

					if (isMarkup) {
						geometryData[0] = geometryData[3];
						geometryData.length = 2;
					}
					const spiralOffset = THREEUtils.multiplyByScalar(spiralCoordinate, this._defaultGeometrySize);
					const offset = {
						x: currentPointerWorld.x + spiralOffset.x,
						y: currentPointerWorld.y + spiralOffset.y,
					};

					if (markupSettings?.target) {
						markupSettings.target.x += offset.x;
						markupSettings.target.y += offset.y;

						(boundaryOrMarkup as IMarkupMinimumData).settings = JSON.stringify(markupSettings);
					}

					boundaryOrMarkup.geometryData.push(...THREEUtils.applyOffsetToGeometryData(geometryData, offset));
				}

				if (!MathUtils.isValidNumber(boundaryOrMarkup.orientation)) {
					boundaryOrMarkup.orientation = 0;
				}
			}
		}
	}

	private static getBlocksOfItemsFromPlainText(text: string) {
		const blocks = text.split("\n\n");

		const blockOfXyicons = blocks.find((block: string) => block.toLowerCase().includes("xyicon model"));
		const blockOfBoundaries = blocks.find((block: string) => block.toLowerCase().includes("boundary type"));
		const blockOfMarkups = blocks.find((block: string) => block.toLowerCase().includes("markup type"));

		return {
			blockOfXyicons,
			blockOfBoundaries,
			blockOfMarkups,
		};
	}

	private static alignCenterOfBoundingBoxToPointerWorld(
		xyicon3DArrayForNonEmbedded: Xyicon3D[],
		boundarySpaceMap3DArray: BoundarySpaceMap3D[],
		markup3DArray: Markup3D[],
		currentPointerWorld: PointDouble,
	) {
		const visibleSpaceItems = [...xyicon3DArrayForNonEmbedded, ...boundarySpaceMap3DArray, ...markup3DArray];
		const bbox = new ItemSelectionBox();

		for (const item of visibleSpaceItems) {
			bbox.expandByBoundingBox(item.boundingBox);

			if (item.spaceItemType === "markup") {
				const markupSettingsMaybe = JSON.parse(((item as Markup3D).modelData as Markup).settings || null) as IMarkupSettingsData;

				if (markupSettingsMaybe?.target) {
					bbox.expandByGeometryData([markupSettingsMaybe.target]);
				}
			}
		}
		const bboxPosToCursor = THREEUtils.subVec2fromVec2(currentPointerWorld, bbox.position);

		for (const item of visibleSpaceItems) {
			item.startTranslating();
			item.translate(bboxPosToCursor.x, bboxPosToCursor.y, 0);
			item.stopTranslating();
		}
	}

	public static parseItemsFromPlainText(text: string, spaceViewRenderer: SpaceViewRenderer, currentPointerWorld: PointDouble) {
		const {blockOfXyicons, blockOfBoundaries, blockOfMarkups} = this.getBlocksOfItemsFromPlainText(text);

		const bbox: ItemSelectionBox = new ItemSelectionBox();

		const {nonEmbeddedXyicons, embeddedXyicons} = this.createXyiconsFromBlockOfText(blockOfXyicons, spaceViewRenderer, bbox, currentPointerWorld);
		const boundaries: IBoundaryMinimumData[] = this.createBoundariesFromBlockOfText(blockOfBoundaries, spaceViewRenderer, bbox, currentPointerWorld);
		const markups: IMarkupMinimumData[] = this.createMarkupsFromBlockOfText(blockOfMarkups, spaceViewRenderer, bbox, currentPointerWorld);

		this.correctPositions(nonEmbeddedXyicons, embeddedXyicons, boundaries, markups, bbox, spaceViewRenderer, currentPointerWorld);

		return {
			nonEmbeddedXyicons: nonEmbeddedXyicons,
			embeddedXyicons: embeddedXyicons,
			boundaries: boundaries,
			markups: markups,
			currentPointerWorld: currentPointerWorld,
		};
	}

	private static async addEmbeddedXyicons(
		spaceViewRenderer: SpaceViewRenderer,
		embeddedXyicons: IXyiconMinimumData[],
		xyicon3DArrayForNonEmbedded: Xyicon3D[],
		xyicon3DArrayForEmbedded: Xyicon3D[],
	) {
		for (const xyicon of embeddedXyicons) {
			if (xyicon.tempParentXyiconId) {
				const parentXyicon = xyicon3DArrayForNonEmbedded.find((xy) => (xy.modelData as Xyicon).tempId === xyicon.tempParentXyiconId);

				if (parentXyicon) {
					xyicon.parentXyicon = parentXyicon.modelData as Xyicon;
					xyicon.iconX = xyicon.parentXyicon.iconX;
					xyicon.iconY = xyicon.parentXyicon.iconY;
				}
			}
			xyicon3DArrayForEmbedded.push(await spaceViewRenderer.xyiconManager.createSpaceItem3DFromModel(xyicon));
		}
	}

	private static updateCounters(spaceViewRenderer: SpaceViewRenderer, xyicon3DArrayForEmbedded: Xyicon3D[]) {
		const parents: Xyicon[] = [];

		for (const xyicon of xyicon3DArrayForEmbedded) {
			const parentXyicon = (xyicon.modelData as Xyicon).parentXyicon;

			if (parentXyicon) {
				if (!parents.includes(parentXyicon)) {
					parents.push(parentXyicon);
					const xyicon3D = spaceViewRenderer.xyiconManager.items.getById(parentXyicon.id) as Xyicon3D;

					if (xyicon3D) {
						xyicon3D.updateCounter();
					}
				}
			}
		}
	}

	private static updateLinks(spaceViewRenderer: SpaceViewRenderer, xyicon3DArray: Xyicon3D[]) {
		const linkManager = spaceViewRenderer.toolManager.linkManager;
		const linksIds: string[] = [];
		const newLinks: {
			fromObjectId: string;
			toObjectId: string;
			fromPortId: string;
			toPortId: string;
		}[] = [];

		for (const xyicon of xyicon3DArray) {
			const modelData = xyicon.modelData as Xyicon;
			const links = spaceViewRenderer.actions.getLinksXyiconXyicon((xyicon.modelData as Xyicon).tempGuid).filter((l) => !l.link.isEmbedded);

			for (const link of links) {
				if (!linksIds.includes(link.link.id)) {
					linksIds.push(link.link.id);

					const fromObjectId: string = modelData.id;
					const toObjectId: string = xyicon3DArray.find((x) => (x.modelData as Xyicon).tempGuid === link.object.id)?.modelData.id || "";
					let fromPortId: string = link.link.fromPortId;
					let toPortId: string = link.link.toPortId;

					if (modelData.tempGuid !== link.link.fromObjectId) {
						[fromPortId, toPortId] = [toPortId, fromPortId];
					}

					if (fromObjectId && toObjectId) {
						newLinks.push({
							fromObjectId: fromObjectId,
							toObjectId: toObjectId,
							fromPortId: fromPortId,
							toPortId: toPortId,
						});
					}
				}
			}
		}

		const actions = spaceViewRenderer.actions;
		const linkParams: XyiconLinkDetail[] = [];

		for (const newLink of newLinks) {
			const fromXyicon = actions.getFeatureItemById(newLink.fromObjectId, XyiconFeature.Xyicon);
			const fromPortId = newLink.fromPortId;
			const toXyicon = actions.getFeatureItemById(newLink.toObjectId, XyiconFeature.Xyicon);
			const toPortId = newLink.toPortId;

			if (fromXyicon && toXyicon) {
				linkParams.push({
					fromXyiconID: fromXyicon.id,
					fromPortID: fromPortId,
					toXyiconID: toXyicon.id,
					toPortID: toPortId,
					isEmbedded: false,
				});
			}
		}

		return linkManager.sendCreateRequest({
			fromPortfolioID: spaceViewRenderer.transport.appState.portfolioId,
			toPortfolioID: spaceViewRenderer.transport.appState.portfolioId,
			xyiconLinkDetails: linkParams,
		});
	}

	public static async paste(pastedText: string, spaceViewRenderer: SpaceViewRenderer, actionType: ClipboardActionType) {
		this._pasteInstances++;

		const currentPointerWorld = spaceViewRenderer.ghostModeManager.isActive
			? spaceViewRenderer.ghostModeManager.bboxPos
			: spaceViewRenderer.toolManager.activeTool.currentPointerWorld;

		if (actionType !== "STAMP") {
			spaceViewRenderer.ghostModeManager.stop(true);
		}

		if (pastedText.length > 0) {
			const markup3DArray: Markup3D[] = [];
			const boundarySpaceMap3DArray: BoundarySpaceMap3D[] = [];
			const xyicon3DArrayForNonEmbedded: Xyicon3D[] = [];
			const xyicon3DArrayForEmbedded: Xyicon3D[] = [];

			const {nonEmbeddedXyicons, embeddedXyicons, boundaries, markups} = ClipboardManager.parseItemsFromPlainText(
				pastedText,
				spaceViewRenderer,
				currentPointerWorld,
			);
			const xyicons = [...nonEmbeddedXyicons, ...embeddedXyicons];

			if (actionType === "CUT") {
				const {actions} = spaceViewRenderer.transport.appState;
				const currentSpaceId = spaceViewRenderer.transport.appState.space.id;

				for (const markup of markups) {
					const markup3D = spaceViewRenderer.markupManager.getItemById(markup.guid) as Markup3D;

					if (markup3D) {
						const modelData = markup3D.modelData as Markup;

						modelData.setGeometryData(markup.geometryData);
						modelData.settings = markup.settings || null;
						markup3D.updateByModel(modelData, false);
						markup3DArray.push(markup3D);
					} else {
						const modelData = actions.getFeatureItemById(markup.guid, XyiconFeature.Markup) as Markup;

						if (modelData) {
							modelData.setSpaceId(currentSpaceId);
							modelData.setGeometryData(markup.geometryData);
							const markup3D = spaceViewRenderer.markupManager.addItemsByModel([modelData]);

							markup3DArray.push(...markup3D);
						}
					}
				}
				if (markups.length > 0) {
					spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
				}

				for (const boundary of boundaries) {
					const boundarySpaceMap3D = spaceViewRenderer.boundaryManager.getItemById(boundary.guid) as BoundarySpaceMap3D;

					if (boundarySpaceMap3D) {
						const modelData = boundarySpaceMap3D.modelData as BoundarySpaceMap;

						modelData.setGeometryData(boundary.geometryData);
						boundarySpaceMap3D.updateByModel(modelData);
						boundarySpaceMap3DArray.push(boundarySpaceMap3D);
					} else {
						const modelData = actions.getBoundarySpaceMapById(boundary.guid);

						if (modelData) {
							modelData.setSpaceId(currentSpaceId);
							modelData.setGeometryData(boundary.geometryData);
							const boundarySpaceMap3D = spaceViewRenderer.boundaryManager.addItemsByModel([modelData]);

							boundarySpaceMap3DArray.push(...boundarySpaceMap3D);
						}
					}
				}

				for (const xyicon of xyicons) {
					const xyicon3D = spaceViewRenderer.xyiconManager.getItemById(xyicon.guid) as Xyicon3D;

					if (xyicon3D) {
						const modelData = xyicon3D.modelData as Xyicon;

						if (modelData.isEmbedded) {
							xyicon3DArrayForEmbedded.push(xyicon3D);
						} else {
							modelData.setSpaceId(currentSpaceId);
							modelData.setPosition(xyicon.iconX, xyicon.iconY, xyicon.iconZ);
							xyicon3D.updateByModel(modelData);
							xyicon3DArrayForNonEmbedded.push(xyicon3D);
						}
					} else {
						const modelData = actions.getFeatureItemById(xyicon.guid, XyiconFeature.Xyicon) as Xyicon;

						if (modelData) {
							modelData.setPosition(xyicon.iconX, xyicon.iconY, xyicon.iconZ);
							const xyicon3D = await spaceViewRenderer.xyiconManager.addItemsByModel([modelData]);

							if (modelData.isEmbedded) {
								xyicon3DArrayForEmbedded.push(...xyicon3D);
							} else {
								xyicon3DArrayForNonEmbedded.push(...xyicon3D);
							}
						}
					}
				}

				//
				// Offset
				//
				this.alignCenterOfBoundingBoxToPointerWorld(xyicon3DArrayForNonEmbedded, boundarySpaceMap3DArray, markup3DArray, currentPointerWorld);

				await spaceViewRenderer.markupManager.updateItems(markup3DArray, true);
				await spaceViewRenderer.boundaryManager.updateItems(boundarySpaceMap3DArray, true);
				await spaceViewRenderer.xyiconManager.updateItems(xyicon3DArrayForNonEmbedded, true);
			} // copy
			else {
				//
				// Markups
				//
				for (const markup of markups) {
					const markup3D = spaceViewRenderer.markupManager.createSpaceItem3DFromModel(markup);

					markup3DArray.push(markup3D);
				}

				//
				// Boundaries
				//
				const boundaryAssociations: {
					[refId: string]: BoundarySpaceMap3D[];
				} = {};

				for (const boundary of boundaries) {
					const boundarySpaceMap = spaceViewRenderer.boundaryManager.createSpaceItem3DFromModel(boundary);

					boundarySpaceMap3DArray.push(boundarySpaceMap);

					if (boundary.id) {
						if (!boundaryAssociations[boundary.id]) {
							boundaryAssociations[boundary.id] = [];
						}
						boundaryAssociations[boundary.id].push(boundarySpaceMap);
					}
				}

				//
				// Xyicons
				//

				// Non-embedded
				for (const xyicon of nonEmbeddedXyicons) {
					xyicon3DArrayForNonEmbedded.push(await spaceViewRenderer.xyiconManager.createSpaceItem3DFromModel(xyicon));
				}

				//
				// Offset
				//
				this.alignCenterOfBoundingBoxToPointerWorld(xyicon3DArrayForNonEmbedded, boundarySpaceMap3DArray, markup3DArray, currentPointerWorld);

				//
				// Send to server
				//

				// Boundaries
				await spaceViewRenderer.boundaryManager.add(boundarySpaceMap3DArray, true, false);

				// Merge boundaries that belong to each other
				for (const refId in boundaryAssociations) {
					if (boundaryAssociations[refId].length > 1) {
						const boundaryModelDataArray = boundaryAssociations[refId].map(
							(boundarySpaceMap: BoundarySpaceMap3D) => (boundarySpaceMap.modelData as BoundarySpaceMap).parent,
						);
						const parentBoundary = boundaryModelDataArray[0];
						const childBoundaries = boundaryModelDataArray.slice(1);

						await spaceViewRenderer.actions.mergeBoundaries(parentBoundary, childBoundaries, false);

						for (const child of childBoundaries) {
							for (const boundarySpaceMap of child.boundarySpaceMaps) {
								const newBoundarySpaceMap = spaceViewRenderer.boundaryManager.getItemById(boundarySpaceMap.id) as BoundarySpaceMap3D;

								if (newBoundarySpaceMap) {
									boundarySpaceMap3DArray.push(newBoundarySpaceMap);
								}
							}
						}
					}
				}

				// Xyicons
				await spaceViewRenderer.xyiconManager.add(xyicon3DArrayForNonEmbedded, true, false);

				// Embedded xyicons
				await this.addEmbeddedXyicons(spaceViewRenderer, embeddedXyicons, xyicon3DArrayForNonEmbedded, xyicon3DArrayForEmbedded);

				// Send to server
				await spaceViewRenderer.xyiconManager.add(xyicon3DArrayForEmbedded, true, false);

				// Markups
				await spaceViewRenderer.markupManager.add(markup3DArray, true, false);

				//
				// Update counters for parent xyicons
				//
				this.updateCounters(spaceViewRenderer, xyicon3DArrayForEmbedded);

				//
				// Update links
				//
				await this.updateLinks(spaceViewRenderer, [...xyicon3DArrayForNonEmbedded, ...xyicon3DArrayForEmbedded]);
			}

			////////////////////////////////
			//
			// Select newly created items + update UI
			//
			const {spaceItemController} = spaceViewRenderer;

			spaceItemController.updateFilterState();
			spaceItemController.deselectAll();

			for (const item of [...xyicon3DArrayForNonEmbedded, ...boundarySpaceMap3DArray, ...markup3DArray]) {
				// If we merge some boundaries, they are technically recreated
				// Or there might be other reasons why they are destroyed, and we don't want to select those
				if (!item.isDestroyed) {
					item.select();
				}
			}

			if (xyicon3DArrayForNonEmbedded.length > 0) {
				spaceViewRenderer.xyiconManager.captionManager.updateCaptions();
			}

			spaceItemController.updateActionBar();
			spaceItemController.updateDetailsPanel(true);
			spaceItemController.linkIconManager.update();
		}

		this._pasteInstances--;

		if (this._pasteInstances === 0) {
			this.signals.pasteDone.dispatch();
		}
	}
}

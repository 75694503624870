import {styled} from "styled-components";
import type {MouseEvent, RefObject} from "react";
import {FlexCenterStyle} from "./styles/styles";
import {ProjectNameDropDownStyled, ProjectNameIconsStyled} from "./topbar/ProjectName.styled";
import ChevronDownIcon from "./icons/chevron-down.svg?react";
import {pseudoLabelTooltipStyle} from "./Root.styled";

const PortfolioAvatarStyled = styled.div`
	min-width: 24px;
	height: 24px;
	background-color: black;
	color: white;
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.05px;
	text-align: center;
	line-height: 25px;
	border-radius: 4px;
	cursor: pointer;
	padding: 0 2px;
`;

const PortfolioAvatarContainer = styled.div<{$label: string}>`
	${FlexCenterStyle}
	gap: 8px;
	flex: 1;
	position: relative;
	width: 100%;

	${pseudoLabelTooltipStyle}
`;

type TPortfolioAvatar = {
	readonly name: string;
	readonly thumbnailOnly?: boolean;
	readonly onClick?: (event: MouseEvent) => void;
	readonly onMouseEnter?: (event: MouseEvent) => void;
	readonly onMouseLeave?: (event: MouseEvent) => void;
	readonly divRef?: RefObject<HTMLDivElement>;
};

export const PortfolioAvatar = (props: TPortfolioAvatar) => {
	const {name, thumbnailOnly, onClick, onMouseEnter, onMouseLeave, divRef} = props;
	const initial = name?.includes(" ")
		? name
				.split(" ")
				.map((word) => word.charAt(0).toUpperCase())
				.splice(0, 2)
				.join("")
		: name?.slice(0, 2);

	return (
		<PortfolioAvatarContainer
			$label={thumbnailOnly && name.trim()}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			ref={divRef}
		>
			<ProjectNameDropDownStyled>
				<PortfolioAvatarStyled>{initial}</PortfolioAvatarStyled>
				{!thumbnailOnly && (
					<div className="projectNavBar">
						<span
							className="projectNameNavBar"
							title={name}
						>
							{name}
						</span>
						<ProjectNameIconsStyled>
							<ChevronDownIcon />
						</ProjectNameIconsStyled>
					</div>
				)}
			</ProjectNameDropDownStyled>
		</PortfolioAvatarContainer>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import type {PortfolioPermission} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {Portfolio} from "../../../../../data/models/Portfolio";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import DeleteIcon from "../../../icons/delete.svg?react";
import {colorPalette} from "../../../styles/colorPalette";

const PortfolioPermissionViewStyled = styled.div`
	.PortfolioPermissionView {
		display: flex;
		justify-content: space-between;
		gap: 8px;

		.selectinput {
			width: 100%;
			margin-right: 5px;
		}

		.delete svg path {
			stroke: ${colorPalette.white};
		}
	}

	.PortfolioPermissionView:hover {
		.delete svg path {
			stroke: black;
		}
	}
`;

interface IPortfolioPermissionFieldProps {
	readonly permission: PortfolioPermission;
	readonly exceptions: string[];
	readonly onRemove: (permission: PortfolioPermission) => void;
	readonly onChange?: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioPermissionViewV5 extends React.PureComponent<IPortfolioPermissionFieldProps> {
	private onRemoveClick = () => {
		this.props.onRemove(this.props.permission);
	};

	public override componentDidMount(): void {
		this.props.transport.services.feature.refreshList(XyiconFeature.PermissionSet);
	}

	private onChangePortfolio = (portfolio: Portfolio) => {
		this.props.permission.portfolioID = portfolio.id;
		this.props.onChange?.();
	};

	private onChangePermissionSet = (permissionSet: PermissionSet) => {
		this.props.permission.portfolioPermissionSetID = permissionSet.id;
		this.props.onChange?.();
	};

	public override render() {
		const {permission, exceptions, appState} = this.props;

		const permissionSets = appState.actions.getList<PermissionSet>(XyiconFeature.PermissionSet);
		const portfolios = appState.actions
			.getList<Portfolio>(XyiconFeature.Portfolio)
			// Only list portfolios that haven't been added yet
			.filter((p) => p.id === permission.portfolioID || !exceptions.includes(p.id));

		return (
			<PortfolioPermissionViewStyled>
				<div className="PortfolioPermissionView AdvancedFilterField hbox">
					<SelectInputV5
						className="selectinput"
						options={portfolios}
						selected={appState.actions.getPortfolioById(permission.portfolioID)}
						onChange={this.onChangePortfolio}
						render={(p) => p.name}
						placeholder="Portfolio"
						isSameWidth={true}
					/>

					<SelectInputV5
						className="selectinput"
						options={permissionSets}
						selected={permissionSets.find((ps) => ps.id === permission.portfolioPermissionSetID)}
						onChange={this.onChangePermissionSet}
						render={(ps) => ps.name}
						placeholder="Permission Set"
						isSameWidth={true}
					/>
					<IconButtonV5
						title="Delete"
						IconComponent={DeleteIcon}
						className="delete"
						onClick={this.onRemoveClick}
					/>
				</div>
			</PortfolioPermissionViewStyled>
		);
	}
}

import * as React from "react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {Constants} from "../../logic3d/Constants";
import type {ThemeType} from "../../../../../ThemeType";
import type {PointDouble} from "../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {ColorUtils} from "../../../../../../utils/ColorUtils";

interface INavigationCameraBoxProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly domWidth: number;
	readonly domHeight: number;
	readonly bgWidth: number;
	readonly bgHeight: number;
	readonly themeType: ThemeType;
}

export class NavigationCameraBox extends React.Component<INavigationCameraBoxProps> {
	private _canvasRef = React.createRef<HTMLCanvasElement>();
	private _ctx: CanvasRenderingContext2D;

	private update = () => {
		const canvas = this._canvasRef.current;

		if (canvas) {
			const domWidth = this.props.domWidth;
			const domHeight = this.props.domHeight;

			this._ctx = canvas.getContext("2d");
			this._ctx.clearRect(0, 0, domWidth, domHeight);
			this._ctx.beginPath();

			this._ctx.strokeStyle = `#${Constants.COLORS.PICTON_BLUE}`;
			this._ctx.fillStyle = ColorUtils.hex2rgb(this._ctx.strokeStyle, 0.1) as string;

			// with 0 camerarotation in mind
			const arrayOfNDCCoords = [
				{x: 1, y: 1},
				{x: -1, y: 1},
				{x: -1, y: -1},
				{x: 1, y: -1},
			];

			const arrayOfDomCoords: PointDouble[] = [];
			const spaceSize = this.props.spaceViewRenderer.spaceSize;

			if (spaceSize) {
				const bgElement = {
					offsetWidth: this.props.bgWidth,
					offsetHeight: this.props.bgHeight,
				};

				const offset = {
					x: (this.props.domWidth - this.props.bgWidth) / 2,
					y: (this.props.domHeight - this.props.bgHeight) / 2,
				};

				const spaceOffset = this.props.spaceViewRenderer.spaceOffset;

				for (const coord of arrayOfNDCCoords) {
					const worldZ = this.props.spaceViewRenderer.spaceOffset.z;
					let worldCoord = THREEUtils.NDCtoWorldCoordinates(coord.x, coord.y, worldZ, this.props.spaceViewRenderer.activeCamera);

					if (!worldCoord) {
						worldCoord = THREEUtils.NDCtoWorldCoordinates(coord.x, 0, worldZ, this.props.spaceViewRenderer.activeCamera);
					}
					if (!worldCoord) {
						return;
					}
					// Bottom left corner is spaceOffset.xy by default (which is (0, 0) by default)
					arrayOfDomCoords.push(
						THREEUtils.worldCoordinatesToDomCoordinates(worldCoord.x - spaceOffset.x, worldCoord.y - spaceOffset.y, worldZ, bgElement, spaceSize),
					);
					arrayOfDomCoords[arrayOfDomCoords.length - 1].x += offset.x;
					arrayOfDomCoords[arrayOfDomCoords.length - 1].y += offset.y;
				}

				this._ctx.moveTo(arrayOfDomCoords[0].x, arrayOfDomCoords[0].y);
				for (let i = 1; i < arrayOfDomCoords.length; ++i) {
					this._ctx.lineTo(arrayOfDomCoords[i].x, arrayOfDomCoords[i].y);
				}
			}
			this._ctx.closePath();
			this._ctx.stroke();
			this._ctx.fill();
		}
	};

	public override componentDidUpdate() {
		this.update();
	}

	public override componentDidMount() {
		this.update();
	}

	public override render() {
		const style = {
			filter: this.props.themeType === "light" ? "none" : "invert(1)",
		};

		return (
			<canvas
				ref={this._canvasRef}
				style={style}
				width={this.props.domWidth}
				height={this.props.domHeight}
			></canvas>
		);
	}
}

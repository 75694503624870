import styled from "styled-components";
import type {FillOpacity} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import FillTransparentIcon from "../../../icons/fill-transparent.svg?react";
import FillSemiTransparentIcon from "../../../icons/fill-semi-transparent.svg?react";
import FillOpaqueIcon from "../../../icons/fill-opaque.svg?react";

interface IFillOptionsProps {
	readonly onChange: (value: FillOpacity) => void;
}

export const FillOptionsV5 = (props: IFillOptionsProps) => {
	const onFillClick = () => {
		props.onChange(1);
	};

	const onSemiFillClick = () => {
		props.onChange(0.1);
	};

	const onNoFillClick = () => {
		props.onChange(0);
	};

	return (
		<FillOptionsStyled className="FillOptions vbox">
			<IconButtonV5
				IconComponent={FillOpaqueIcon}
				onClick={onFillClick}
				title="Fill"
			/>
			<IconButtonV5
				IconComponent={FillSemiTransparentIcon}
				onClick={onSemiFillClick}
				title="Semi Fill"
			/>
			<IconButtonV5
				IconComponent={FillTransparentIcon}
				onClick={onNoFillClick}
				title="No Fill"
			/>
		</FillOptionsStyled>
	);
};

export const FillOptionsStyled = styled.div`
	position: absolute;
	top: calc(100% + 8px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8px;
	border-radius: ${radius.md};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	color: ${colorPalette.gray.c950};
`;

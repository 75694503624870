import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {IModulePanelProps} from "../ModuleViewV5";
import type {View} from "../../../../data/models/View";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IViewColumn} from "../../../../data/models/ViewUtils";
import {SaveToViewButtonV5} from "../view/SaveToViewButtonV5";
import {PopupV5} from "../../popup/PopupV5";
import {ButtonV5} from "../../button/ButtonV5";
import {ButtonContainerStyled} from "../../modules/report/create/wizard/ReportWizardV5.style";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {EditViewColumnsV5} from "./EditViewColumnsV5";

interface IManageColumnsPanelProps extends IModulePanelProps {
	readonly view: View;
	readonly feature: XyiconFeature;
	readonly onClose: () => void;
	readonly parentRef?: React.RefObject<HTMLDivElement>;
}

@observer
export class ManageColumnsPanelV5 extends React.PureComponent<IManageColumnsPanelProps> {
	private _columns = this.props.view.getColumnsToCompareWithWidth();

	private isResetButtonEnabled() {
		const savedColumns = this.props.view.getColumnsToCompareWithWidth();

		return JSON.stringify(this._columns) !== JSON.stringify(savedColumns);
	}

	private onResetClick = () => {
		const {feature, view} = this.props;

		if (view.itemFeature === XyiconFeature.SpaceEditor) {
			view.setColumns(this._columns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary] as IViewColumn[], feature);
		} else {
			view.setColumns(this._columns as IViewColumn[], feature);
		}
	};

	public override render() {
		const {view, feature, onClose, parentRef} = this.props;

		return (
			<PopupV5
				onClose={onClose}
				label="Manage Columns"
				className="ManageColumns"
				width="616px"
				height="524px"
				parentRef={parentRef.current}
				noButtons={true}
				verticalAlignment={VerticalAlignment.bottomOuter}
				horizontalAlignment={HorizontalAlignment.left}
				centerOnScreen={parentRef.current === null}
				style={parentRef.current !== null ? {transform: "translateX(-30px)"} : {}}
			>
				<ManageColumnsPanelStyled className="ManageColumnsPanel CreatePortfolioPanel SidePanel">
					<div className="sidePanelButtons">
						<EditViewColumnsV5
							title=""
							view={view}
							feature={feature}
						/>
						<ButtonContainerStyled>
							<ButtonV5
								className="secondary"
								type="secondary"
								label="Reset"
								title="Reset"
								onClick={this.onResetClick}
								disabled={!this.isResetButtonEnabled()}
							/>
							<SaveToViewButtonV5
								feature={view.itemFeature}
								viewChangeType="columns"
								manageColumns={true}
							/>
						</ButtonContainerStyled>
					</div>
				</ManageColumnsPanelStyled>
			</PopupV5>
		);
	}
}

const ManageColumnsPanelStyled = styled.div`
	.manage-cols-box {
		grid-template-columns: 248px 28px 248px 28px;
		display: grid;
		grid-gap: 10px;
	}

	.ColumnEditor {
		max-height: calc(100% - 130px);

		.manage-cols-box {
			height: inherit;

			.col {
				max-height: 450px;

				.border {
					.views {
						height: calc(100% - 50px);

						.DetailsTab {
							padding: 0;
						}
					}

					.buttons {
						padding: 0;

						.button {
							width: 100%;
						}

						.tabLabel {
							font-size: 16px;
							font-weight: 400;
						}
					}
				}
			}
		}
	}
`;

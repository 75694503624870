import type {RefObject} from "react";
import {useState} from "react";
import {useAppStore} from "../../../../../StateManager";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import type {CreateSpaceVersionRequest} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import {DateUtils} from "../../../../../utils/DateUtils";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {DateTimeInputV5} from "../../../input/datetime/DateTimeInputV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {FieldV5} from "../../../details/FieldV5";
import {CreatePopupFieldStyled} from "../../../popup/CreatePopupField.styled";
import {CreatePopupStyled} from "../../CreatePopupStyled";

interface IVersionSetCreatePopupV5Props {
	readonly onClose: () => void;
	readonly parentRef: RefObject<HTMLDivElement>;
}

export const VersionSetCreatePopupV5 = (props: IVersionSetCreatePopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const [name, setName] = useState<string>("");
	const [issuanceDate, setIssuanceDate] = useState<string>(DateFormatter.format(DateUtils.today(), "date"));
	const [createClicked, setCreateClicked] = useState<boolean>(false);

	const onCreateClicked = async () => {
		setCreateClicked(true);

		const params: CreateSpaceVersionRequest = {
			organizationID: appState.organizationId,
			name: name,
			issuanceDate: new Date(issuanceDate).toISOString(),
			portfolioID: appState.portfolioId,
		};

		await appState.app.transport.services.feature.create(params, XyiconFeature.SpaceVersion);

		setCreateClicked(false);
		props.onClose();
	};

	const isFormValid = name && appState.actions.isVersionSetNameValid(name, null);

	return (
		<CreatePopupStyled
			onClose={props.onClose}
			label="Create Version Set"
			parentRef={props.parentRef.current}
			buttonProps={{
				onClick: onCreateClicked,
				disabled: createClicked || !isFormValid,
				label: "Create",
			}}
			verticalAlignment={VerticalAlignment.bottomOuter}
			horizontalAlignment={HorizontalAlignment.right}
		>
			<CreatePopupFieldStyled>
				<FieldV5
					label="New Version Name"
					className="focused"
				>
					<ClickToEditInputV5
						value={name}
						onLiveChange={(name) => setName(name)}
						onChange={(name) => setName(name)}
						getErrorMessage={(name) => (appState.actions.isVersionSetNameValid(name, null) ? "" : "Value needs to be unique!")}
						placeholder="Version Name"
					/>
				</FieldV5>
				<FieldV5
					label="Issuance Date"
					className="focused"
				>
					<DateTimeInputV5
						value={issuanceDate}
						onChange={(name) => setIssuanceDate(name)}
						format="date"
					/>
				</FieldV5>
			</CreatePopupFieldStyled>
		</CreatePopupStyled>
	);
};

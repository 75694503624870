import * as React from "react";
import {inject, observer} from "mobx-react";
import * as ReactDOM from "react-dom";
import type {ILookupFieldOption} from "../../../DetailsTab";
import type {App} from "../../../../../../../../../App";
import type {AppState} from "../../../../../../../../../data/state/AppState";
import {Field} from "../../../../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../../../../widgets/input/select/SelectInput";
import {ReactUtils} from "../../../../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../../../../widgets/button/IconButton";
import {ConfirmWindow} from "../../../../../../popups/ConfirmWindow";
import type {TransportLayer} from "../../../../../../../../../data/TransportLayer";
import type {IMassInputProps} from "../IMassInput";
import {massUpdatePressButton} from "../IMassInput";
import {DomPortal} from "../../../../../../portal/DomPortal";
import type {TransformObj} from "../../../../../../../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../../../../../../../utils/dom/DomUtils";
import {MultiSelectInput} from "../../../../../../../../widgets/input/multiselect/MultiSelectInput";
import {FocusLoss} from "../../../../../../../../../utils/ui/focus/FocusLoss";
import {AppUtils} from "../../../../../../../../../utils/AppUtils";
import {TimeUtils} from "../../../../../../../../../utils/TimeUtils";
import {XHRLoader} from "../../../../../../../../../utils/loader/XHRLoader";
import {ArrayUtils} from "../../../../../../../../../utils/data/array/ArrayUtils";
import {ObjectUtils} from "../../../../../../../../../utils/data/ObjectUtils";
import {KeyboardListener} from "../../../../../../../../../utils/interaction/key/KeyboardListener";
import {Permission, type LinkXyiconRequest, type XyiconLinkDetail} from "../../../../../../../../../generated/api/base";

interface IMassFieldLookupInputProps extends IMassInputProps {
	readonly selectableOptions: ILookupFieldOption[];
	readonly selectedOptions: ILookupFieldOption[];
	readonly prefixedRefId: string;
	readonly noFixedPosition?: boolean;
	readonly app?: App;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IMassFieldLookupInputState {
	selectedAction: string;
	selectedOptions: ILookupFieldOption[];
	transform: TransformObj;
	open: boolean;
}

interface ISelectOption {
	id: string;
	label: string;
}

@inject("app")
@inject("appState")
@inject("transport")
@observer
export class MassFieldLookupInput extends React.Component<IMassFieldLookupInputProps, IMassFieldLookupInputState> {
	private readonly _options: ISelectOption[];
	private _element = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: IMassFieldLookupInputProps) {
		super(props);
		this.state = {
			selectedAction: "add",
			selectedOptions: [],
			transform: null,
			open: this.props.open,
		};

		this._options = [
			{
				id: "add",
				label: "Add to List",
			},
			{
				id: "overwrite",
				label: "Overwrite List",
			},
			{
				id: "remove",
				label: "Remove from List",
			},
		];
	}

	static getDerivedStateFromProps(props: IMassFieldLookupInputProps, state: IMassFieldLookupInputState) {
		if (props.open !== state.open) {
			return {open: props.open};
		}

		return null;
	}

	private onOpenClick = async () => {
		const {onOpen, prefixedRefId} = this.props;

		onOpen(prefixedRefId);
		await TimeUtils.wait(100);
		this.addListeners();
	};

	private onApply = async () => {
		const {items, transport, appState, field} = this.props;
		const {selectedAction, selectedOptions} = this.state;
		const selectedOptionIds = selectedOptions.map((option) => option.xyicon.id);

		KeyboardListener.getInstance().signals.up.remove(this.pressButton);

		const itemsToUpdate = items.filter((item) => {
			const hasPermission = appState.actions.getFieldPermission(field, [item]) > Permission.View;

			if (!hasPermission) {
				return false;
			}

			const existingLinks = this.props.appState.actions.getLinksXyiconXyicon(item.id);
			const existingLinkObjectIds = existingLinks.map((l) => (l.link.toObjectId === item.id ? l.link.fromObjectId : l.link.toObjectId));

			if (selectedAction === "add") {
				return selectedOptionIds.some((id) => !existingLinkObjectIds.includes(id));
			} else if (selectedAction === "remove") {
				return selectedOptionIds.some((id) => existingLinkObjectIds.includes(id));
			} else if (selectedAction === "overwrite") {
				return !ObjectUtils.compare(selectedOptionIds, existingLinkObjectIds);
			}
		});

		const confirmed = await ConfirmWindow.open(`${itemsToUpdate.length} of ${items.length} items will be updated. Continue?`, "Confirm Update");

		if (confirmed) {
			this.props.onOpen("");
			this.removeListeners();

			const linksToAdd: XyiconLinkDetail[] = [];
			const linkIdsToRemove: string[] = [];

			if (selectedAction === "add") {
				for (const item of itemsToUpdate) {
					for (const link of selectedOptions) {
						linksToAdd.push({
							fromXyiconID: item.id,
							toXyiconID: link.xyicon.id,
							toPortID: null,
							fromPortID: null,
							isEmbedded: false,
						});
					}
				}
			} else if (selectedAction === "remove") {
				for (const item of items) {
					const existingLinks = this.props.appState.actions.getLinksXyiconXyicon(item.id);

					for (const link of selectedOptions) {
						const existingLink = existingLinks.find((l) => l.link.toObjectId === link.xyicon.id || l.link.fromObjectId === link.xyicon.id);

						if (existingLink) {
							linkIdsToRemove.push(existingLink.link.id);
						}
					}
				}
			} else if (selectedAction === "overwrite") {
				for (const item of items) {
					let existingLinks = this.props.appState.actions.getLinksXyiconXyicon(item.id);

					for (const link of selectedOptions) {
						const existingLinkIndex = existingLinks.findIndex((l) => l.link.toObjectId === link.xyicon.id || l.link.fromObjectId === link.xyicon.id);
						const existingLink = existingLinks[existingLinkIndex]; // doesn't die if index is -1

						existingLinks = ArrayUtils.removeAtIndex(existingLinks, existingLinkIndex);

						if (!existingLink) {
							linksToAdd.push({
								fromXyiconID: item.id,
								toXyiconID: link.xyicon.id,
								toPortID: null,
								fromPortID: null,
								isEmbedded: false,
							});
						}
					}

					existingLinks.forEach((link) => linkIdsToRemove.push(link.link.id));
				}
			}

			if (linkIdsToRemove.length > 0) {
				await transport.requestForOrganization({
					url: "xyicons/deletelink",
					method: XHRLoader.METHOD_DELETE,
					params: {
						portfolioID: transport.appState.portfolioId,
						linkIDList: linkIdsToRemove,
					},
				});
			}

			if (linksToAdd.length > 0) {
				const linkParams: LinkXyiconRequest = {
					fromPortfolioID: transport.appState.portfolioId,
					toPortfolioID: transport.appState.portfolioId,
					xyiconLinkDetails: linksToAdd,
				};

				await transport.requestForOrganization({
					url: "xyicons/createlink",
					method: XHRLoader.METHOD_PUT,
					params: linkParams,
				});
			}

			this.setState({selectedOptions: [], selectedAction: "add", open: false});
		} else {
			KeyboardListener.getInstance().signals.up.remove(this.pressButton);
		}
	};

	private onBlur = (event?: MouseEvent) => {
		if (!(event.target instanceof Element) || !this.props.app.modalContainer.contains(ReactDOM.findDOMNode(event.target))) {
			this.onCancel();
		} else {
			FocusLoss.listen(this._floating.current, this.onBlur);
		}
	};

	private onCancel = () => {
		this.props.onOpen("");
		this.removeListeners();
	};

	private onActionChange = (action: ISelectOption) => {
		this.setState({
			selectedAction: action.id,
		});
	};

	private onOptionsChange = (options: ILookupFieldOption[]) => {
		this.setState({selectedOptions: options});
	};

	private removeListeners = () => {
		AppUtils.disableScrolling(false);
		FocusLoss.stopListen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.remove(this.pressButton);
	};

	private addListeners = () => {
		FocusLoss.listen(this._floating.current, this.onBlur);
		KeyboardListener.getInstance().signals.up.add(this.pressButton);
		AppUtils.disableScrolling(true);
	};

	private pressButton = (event: KeyboardEvent) => {
		massUpdatePressButton(event, false, this.onApply, this.onCancel, this.props);
	};

	public override componentDidUpdate(prevProps: IMassFieldLookupInputProps, prevState: IMassFieldLookupInputState) {
		if (!this.props.noFixedPosition && !prevState.open && this.state.open && this._element.current && this._floating.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._element.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const {open, items, field, noFixedPosition, app, selectableOptions, disabled} = this.props;
		const {selectedAction, transform} = this.state;
		const element = this._element;

		let allValuesMatch = true;

		let first = true;
		let firstValue;

		for (const item of items) {
			const existingLinks = this.props.appState.actions.getLinksXyiconXyicon(item.id);
			const selectedLookupFieldOptions = selectableOptions.filter((lookupFieldOption) => {
				for (const link of existingLinks) {
					if (link.object.id === lookupFieldOption.xyicon.id) {
						return true;
					}
				}
				return false;
			});

			let linkNames = "";

			selectedLookupFieldOptions.forEach((option) => (linkNames += `${option.value}\n`));

			if (first) {
				firstValue = linkNames;
				first = false;
			}
			if (linkNames !== firstValue) {
				allValuesMatch = false;
			}
		}

		let inlineStyle: React.CSSProperties = element && {
			transform: transform?.translate,
			position: noFixedPosition ? "absolute" : "fixed",
		};

		if (noFixedPosition && inlineStyle) {
			inlineStyle.transform = "";
		}

		return (
			<div className={ReactUtils.cls("MassFieldInput", {open})}>
				<Field
					key={field.refId}
					label={field.name}
					noWrap={true}
					icons={{preLabelIcon: "lookupField"}}
					tooltips={{preLabelIconTooltip: 'This is a "Look up" enabled field'}}
					disabled={disabled}
				>
					<div
						className={ReactUtils.cls("unfocused", {multiInput: allValuesMatch})}
						onClick={this.onOpenClick}
						ref={this._element}
					>
						{allValuesMatch ? (
							<MultiSelectInput
								key={field.refId}
								selected={this.props.selectedOptions}
								render={(obj) => (
									<div className="hbox alignCenter">
										{obj.value}&nbsp;<span style={{color: "#A0A0A0"}}>({obj.xyicon.refId})</span>
									</div>
								)}
								disabled={true}
							/>
						) : (
							<i>Multiple values</i>
						)}
					</div>
					{open && (
						<DomPortal
							destination={app.modalContainer}
							noPortal={noFixedPosition}
						>
							<div
								className={ReactUtils.cls("MassFieldInput__editArea", {[VerticalAlignment[transform?.vertical]]: transform})}
								style={inlineStyle}
								ref={this._floating}
							>
								<div className="header hbox alignCenter">
									<h4 className="flex_1">
										Editing multiple Values for <span>{`${this.props.field.name}`}</span>
									</h4>
									<IconButton
										icon="close"
										onClick={this.onCancel}
									/>
								</div>
								<div className="container">
									<Field label="Action">
										<SelectInput
											options={this._options}
											render={(option) => option.label}
											selected={this._options.find((option) => option.id === selectedAction)}
											onChange={this.onActionChange}
											onFocusLossForceBlur={true}
										/>
									</Field>
									<Field label="Value(s)">
										<MultiSelectInput
											key={field.refId}
											options={this.props.selectableOptions}
											selected={this.state.selectedOptions}
											render={(obj) => (
												<div className="hbox alignCenter">
													{obj.value}&nbsp;<span style={{color: "#A0A0A0"}}>({obj.xyicon.refId})</span>
												</div>
											)}
											onChange={this.onOptionsChange}
											onFocusLossForceBlur={true}
										/>
									</Field>
									<div className="editButtons">
										<IconButton
											className="apply"
											icon="apply"
											title="Apply"
											onClick={this.onApply}
										/>
										<IconButton
											className="cancel"
											icon="cancel"
											title="Cancel"
											onClick={this.onCancel}
										/>
									</div>
								</div>
							</div>
						</DomPortal>
					)}
				</Field>
			</div>
		);
	}
}

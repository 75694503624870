import {useEffect, useReducer} from "react";
import type {SpaceTool} from "../../logic3d/features/tools/Tools";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {ExtendedDistanceUnitName} from "../../logic3d/Constants";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {Color} from "../../../../../../generated/api/base";
import {UnitSelector} from "../../../../../widgets/input/clicktoedit/UnitSelector";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {PopupWindow} from "../../../../abstract/popups/PopupWindow";
import type {Markup} from "../../../../../../data/models/Markup";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {createToolButton} from "./ToolBarUtils";

interface IMeasureToolBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
}

export const MeasureToolBar = (props: IMeasureToolBarProps) => {
	const {setActiveTool, spaceViewRenderer, activeToolId} = props;
	const {removeAllTempMarkups} = spaceViewRenderer.markupManager;

	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const createToolBtn = (id: SpaceTool) => {
		return createToolButton(spaceViewRenderer, id, activeToolId, setActiveTool, spaceViewRenderer.measureToolColor);
	};

	const unit = spaceViewRenderer.measureToolUnit;
	const setUnit = (unitName: ExtendedDistanceUnitName) => {
		spaceViewRenderer.measureToolUnit = unitName;
		spaceViewRenderer.markupManager.updateAllTempMarkups();
		forceUpdate();
	};

	const onCloseClick = () => {
		removeAllTempMarkups();
		spaceViewRenderer.isMeasureToolBarOpen = false;
		setActiveTool("selection");
	};

	const onClearAllClick = () => {
		removeAllTempMarkups();
	};

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			switch (e.key) {
				case KeyboardListener.KEY_ESCAPE:
					if (PopupWindow.numberOfOpenPopups < 1) {
						spaceViewRenderer.markupManager.removeNewestTempMarkup();
						if (spaceViewRenderer.markupManager.tempMarkups.length === 0) {
							spaceViewRenderer.isMeasureToolBarOpen = false;
						}
					}
					break;
			}
		};

		KeyboardListener.getInstance().signals.down.add(onKeyDown);

		return () => {
			KeyboardListener.getInstance().signals.down.remove(onKeyDown);
		};
	}, [spaceViewRenderer]);

	useEffect(() => {
		return () => {
			removeAllTempMarkups();
		};
	}, [removeAllTempMarkups]);

	return (
		<div className="MeasureToolBar hbox alignCenter">
			{createToolBtn("tempMeasureLinearDistance")}
			{createToolBtn("tempMeasureRectArea")}
			{createToolBtn("tempMeasureNonLinearDistance")}
			{createToolBtn("tempMeasureIrregularArea")}
			<UnitSelector
				unit={unit}
				isDark={true}
				addFeetAndInches={true}
				onChange={setUnit}
			/>
			<ColorSelector
				color={spaceViewRenderer.measureToolColor}
				icon="paint-bucket"
				isTransparencyEnabled={false}
				horizontalAlignment={HorizontalAlignment.center}
				verticalAlignment={VerticalAlignment.topOuter}
				onColorChange={(newColor: Color) => {
					spaceViewRenderer.measureToolColor = newColor;
					for (const markup of spaceViewRenderer.markupManager.items.array as Markup3D[]) {
						const markupModel = markup.modelData as Markup;

						if (markupModel.isTemp) {
							markupModel.setColor(newColor.hex);
							markup.updateByModel(markupModel);
						}
					}
					forceUpdate();
				}}
				eyeDropperProps={spaceViewRenderer.eyeDropperProps}
			/>
			<IconButton
				icon="reDraw"
				title="Clear All"
				onClick={onClearAllClick}
			/>
			<IconButton
				icon="close"
				title="Close Measure Toolbar"
				onClick={onCloseClick}
			/>
		</div>
	);
};

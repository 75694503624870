import * as React from "react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {SpaceEditorMode} from "../../logic3d/renderers/SpaceViewRendererUtils";
import type {SetPinTool} from "../../logic3d/features/tools/SetPinTool";
import type {SelectionToolType, SpaceTool} from "../../logic3d/features/tools/Tools";
import {SpaceToolBarType} from "../../logic3d/features/tools/Tools";
import type {Color} from "../../../../../../generated/api/base";
import {PrimaryToolBar} from "./PrimaryToolBar";
import {MarkupToolBar} from "./MarkupToolBar";
import type {DockableTitle} from "./Dockable";
import {SpaceAlignToolBar} from "./SpaceAlignToolBar";
import {createToolButton} from "./ToolBarUtils";

interface ISpaceToolBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly mode: SpaceEditorMode;
	readonly setDockableOpen: (value: boolean, title?: DockableTitle) => void;
	readonly isCatalogOpen: boolean;
	readonly isBoundaryTypesWindowOpen: boolean;
	readonly isUnplottedXyiconsOpen: boolean;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
	readonly selectionToolType: SelectionToolType;
	readonly activeToolBar: SpaceToolBarType;
	readonly setActiveToolBar: (toolbar: SpaceToolBarType) => void;
}
export class SpaceToolBar extends React.Component<ISpaceToolBarProps> {
	private _primaryToolBarRef = React.createRef<PrimaryToolBar>();

	constructor(props: ISpaceToolBarProps) {
		super(props);

		this.updateActiveTool();
	}

	private openDefaultToolBar = () => {
		this.openToolBar(SpaceToolBarType.PrimaryToolBar);
	};

	private openMarkupToolBar = () => {
		this.openToolBar(SpaceToolBarType.MarkupToolBar);
	};

	private openSpaceAlignToolBar = () => {
		this.openToolBar(SpaceToolBarType.SpaceAlignToolBar);
	};

	private openToolBar(toolBar: SpaceToolBarType) {
		if (!this.props.activeToolId.includes("tempMeasure")) {
			this.props.setActiveTool(
				this.props.mode === SpaceEditorMode.ALIGN || toolBar === SpaceToolBarType.SpaceAlignToolBar ? "pan" : this.props.selectionToolType,
			);
		}
		this.props.setActiveToolBar(toolBar);
	}

	private createToolButton = (id: SpaceTool, color?: Color) => {
		return createToolButton(this.props.spaceViewRenderer, id, this.props.activeToolId, this.props.setActiveTool, color);
	};

	private updateActiveTool() {
		const toolManager = this.props.spaceViewRenderer.toolManager;
		const toolConfig = this.props.spaceViewRenderer.tools[this.props.activeToolId];

		if (toolManager.activeTool !== toolConfig.tool) {
			toolManager.activeTool?.deactivate();
			toolManager.activeTool = toolConfig.tool;
			toolManager.activeTool.activate();
		}
	}

	public get primaryToolBarRef() {
		return this._primaryToolBarRef;
	}

	private updateSetPinTool() {
		const setPinTool = this.props.spaceViewRenderer.tools.setPin.tool as SetPinTool;

		if (this.props.activeToolBar === SpaceToolBarType.SpaceAlignToolBar || this.props.mode === SpaceEditorMode.ALIGN) {
			setPinTool.addListeners();
		} else {
			setPinTool.removeListeners();
		}
	}

	public override componentDidMount() {
		this.props.setActiveTool(this.props.activeToolId);
		this.updateSetPinTool();
	}

	public override componentDidUpdate() {
		this.updateActiveTool();
		this.updateSetPinTool();
	}

	public override componentWillUnmount() {
		const toolManager = this.props.spaceViewRenderer.toolManager;

		toolManager.activeTool.deactivate();

		const setPinTool = this.props.spaceViewRenderer.tools.setPin.tool as SetPinTool;

		setPinTool.removeListeners();
	}

	public override render() {
		return (
			<>
				<PrimaryToolBar
					ref={this._primaryToolBarRef}
					active={this.props.activeToolBar === SpaceToolBarType.PrimaryToolBar}
					mode={this.props.mode}
					activeToolId={this.props.activeToolId}
					setActiveTool={this.props.setActiveTool}
					spaceViewRenderer={this.props.spaceViewRenderer}
					setDockableOpen={this.props.setDockableOpen}
					isCatalogOpen={this.props.isCatalogOpen}
					isBoundaryTypesWindowOpen={this.props.isBoundaryTypesWindowOpen}
					isUnplottedXyiconsOpen={this.props.isUnplottedXyiconsOpen}
					openMarkupToolBar={this.openMarkupToolBar}
					openSpaceAlignToolBar={this.openSpaceAlignToolBar}
					createToolButton={this.createToolButton}
					onToolClick={this.props.setActiveTool}
				/>
				{this.props.mode === SpaceEditorMode.NORMAL && (
					<MarkupToolBar
						active={this.props.activeToolBar === SpaceToolBarType.MarkupToolBar}
						activeToolId={this.props.activeToolId}
						openDefaultToolBar={this.openDefaultToolBar}
						createToolButton={this.createToolButton}
						onToolClick={this.props.setActiveTool}
						tools={this.props.spaceViewRenderer.tools}
						spaceViewRenderer={this.props.spaceViewRenderer}
					/>
				)}
				{this.props.mode === SpaceEditorMode.NORMAL && (
					<SpaceAlignToolBar
						active={this.props.activeToolBar === SpaceToolBarType.SpaceAlignToolBar}
						createToolButton={this.createToolButton}
						openDefaultToolBar={this.openDefaultToolBar}
					/>
				)}
			</>
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import {createRef} from "react";
import styled from "styled-components";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import type {AppState} from "../../../../../data/state/AppState";
import {User} from "../../../../../data/models/User";
import {XyiconFeature} from "../../../../../generated/api/base/models/XyiconFeature";
import {ToggleContainerStyled, ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {InitialsV5} from "../../../widgets/InitialsV5";
import type {SelectedItem} from "../../../widgets/SearchAddAndListItemsV5";
import {SearchAddAndListItemsV5} from "../../../widgets/SearchAddAndListItemsV5";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import SearchPlusIcon from "../../../icons/search-plus.svg?react";
import {colorPalette} from "../../../styles/colorPalette";

const UserGroupAvatarStyled = styled.div`
	.Initials {
		border-radius: 50%;
		width: 32px;
		height: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 8px;
	}
`;

export const UsersInThisGroupStyled = styled.div`
	.line {
		border-top: 2px solid ${colorPalette.gray.c300};
		margin-top: 0px;
		margin-bottom: 0px;
		margin-left: 16px;
		margin-right: 16px;
		transform: translate(0px, -8px);
	}

	.toggleContainer {
		padding-left: 16px;
		padding-right: 16px;
	}

	${ToggleContainerStyled} {
		padding-bottom: 16px;

		.children {
			padding: 0;
		}
	}

	${IconButtonStyled} {
		width: 32px;
		height: 32px;
		position: absolute;
		top: 250px;
		right: 18px;
	}
`;

interface IUserGroupUsersProps {
	readonly userGroup: UserGroup;
	readonly onChange?: () => void;
	readonly appState?: AppState;
}

interface IUserGroupUsersState {
	search: string;
	popupOpen: boolean;
	selectedItems: SelectedItem[];
}

@inject("appState")
@inject("transport")
@observer
export class UserGroupUsersV5 extends React.PureComponent<IUserGroupUsersProps, IUserGroupUsersState> {
	private parentRef = createRef<HTMLDivElement>();

	constructor(props: IUserGroupUsersProps) {
		super(props);
		this.state = {
			search: "",
			popupOpen: false,
			selectedItems: [],
		};
	}

	private onUserAdded = (user: User) => {
		this.props.userGroup.addUser(user.id, this.props.appState);
		this.props.onChange?.();
	};

	private onAddPopupOpen = () => {
		this.setState({popupOpen: true});
	};

	private onAddPopupClose = () => {
		this.setState({
			popupOpen: false,
			selectedItems: [],
		});
	};

	private onUserRemoved = (user: User) => {
		this.props.userGroup.removeUser(user.id, this.props.appState);
		this.props.onChange?.();
	};

	private onSearch = (value: string) => {
		this.setState({search: value});
	};

	private renderAddedListItemUser = (user: User) => {
		return (
			<>
				<UserGroupAvatarStyled>
					<div className="avatar">
						{user.profileFileName ? (
							<img
								src={user.profileFileName}
								alt={`${user.fullName} profile image`}
							/>
						) : (
							<InitialsV5 name={user.fullName || user.email} />
						)}
					</div>
				</UserGroupAvatarStyled>
				<div className="vbox">
					<div className="name">{user.fullName}</div>
					<div className="email">{user.email}</div>
				</div>
			</>
		);
	};

	public renderAddListItemUser = (user: User) => {
		return (
			<>
				<UserGroupAvatarStyled>
					<div className="avatar">
						{user.profileFileName ? (
							<img
								src={user.profileFileName}
								alt={`${user.fullName} profile image`}
							/>
						) : (
							<InitialsV5 name={user.fullName || user.email} />
						)}
					</div>
				</UserGroupAvatarStyled>
				<div className="vbox flex_1 labels">
					<div className="name">{user.fullName}</div>
					<div className="email">{user.email}</div>
				</div>
			</>
		);
	};

	private onUserSelect = (user: User) => {
		const {id, firstName} = user;

		this.setState((prevState) => ({
			selectedItems: [...prevState.selectedItems, {id, itemName: firstName, feature: XyiconFeature.User}],
		}));
	};

	private setSelectedItems = (items: SelectedItem[]) => {
		this.setState({selectedItems: items});
	};

	public override render() {
		const {userGroup, appState} = this.props;
		const {search} = this.state;

		const addedUsers = userGroup.userIds.map((userId) => appState.actions.findUser(userId)).filter((user) => !!user);

		let users = appState.actions.getList<User>(XyiconFeature.User).filter((user) => !!user);

		if (search) {
			users = users.filter((user) => User.search(user, search));
		}

		return (
			<UsersInThisGroupStyled>
				<hr className="line" />
				<ToggleContainerV5
					title="Users in this group"
					open={true}
					noPadding={true}
					className="toggleContainer"
				>
					<IconButtonV5
						IconComponent={SearchPlusIcon}
						onClick={this.onAddPopupOpen}
						divRef={this.parentRef}
					/>

					<SearchAddAndListItemsV5
						sections={[
							{
								items: users,
								feature: XyiconFeature.User,
								sortFunction: (a: User, b: User) => StringUtils.sortIgnoreCase(a.fullName, b.fullName),
								renderAddListItem: this.renderAddListItemUser,
								renderAddedListItem: this.renderAddedListItemUser,
								onItemAdd: this.onUserAdded,
								onItemSelect: this.onUserSelect,
								onItemRemove: this.onUserRemoved,
								lockItems: userGroup.data.isSystem,
							},
						]}
						popupLable="Add users to group"
						addedItems={addedUsers}
						search={search}
						onSearch={this.onSearch}
						prohibitSearch={userGroup.data.isSystem}
						isPopupOpen={this.state.popupOpen}
						parentRef={this.parentRef}
						onClose={this.onAddPopupClose}
						selectedItemList={this.state.selectedItems}
						setSelectedItems={this.setSelectedItems}
						onAddUserClick={this.onAddPopupOpen}
						buttonLabel="Add User"
					/>
				</ToggleContainerV5>
			</UsersInThisGroupStyled>
		);
	}
}

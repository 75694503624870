import type {IIconConfig} from "../../../modules/catalog/create/CatalogTypes";
import type {Catalog} from "../../../../data/models/Catalog";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {useAppStore} from "../../../../StateManager";
import {CatalogCreatePopupV5} from "./CatalogCreatePopupV5";

interface IEditedCatalog {
	config: IIconConfig;
	catalog: Catalog;
}

interface ICatalogCreateV5Props {
	readonly editedCatalogMaybe?: IEditedCatalog;
	readonly isOpen: boolean;
	readonly onClose: () => void;
}

const catalogMode: "create" | "edit" = "create";

export const CatalogCreateV5 = ({isOpen, onClose, editedCatalogMaybe}: ICatalogCreateV5Props) => {
	const appState = useAppStore((state) => state.appState);

	if (!isOpen) {
		return <></>;
	}

	let verticalAlignment: VerticalAlignment = VerticalAlignment.bottom;
	let horizontalAlignment: HorizontalAlignment = HorizontalAlignment.left;

	if (editedCatalogMaybe?.catalog) {
		verticalAlignment = VerticalAlignment.bottom;
		horizontalAlignment = HorizontalAlignment.outerRight;
	}

	if (appState.catalogEditorParentMaybe) {
		const rect = appState.catalogEditorParentMaybe.getBoundingClientRect();
		const rightEdge = rect.x + rect.width;

		const floatingWindowWidth = 370;
		if (rightEdge + floatingWindowWidth > window.innerWidth) {
			horizontalAlignment = HorizontalAlignment.outerLeft;
		}
	}

	if (!editedCatalogMaybe) {
		return (
			<CatalogCreatePopupV5
				onClose={onClose}
				selectedType={null}
				model={null}
				iconConfig={null}
				catalog={null}
				mode={catalogMode}
				label="Create Catalog Item"
				verticalAlignment={verticalAlignment}
				horizontalAlignment={horizontalAlignment}
			/>
		);
	} else if (editedCatalogMaybe?.catalog) {
		return (
			<CatalogCreatePopupV5
				onClose={onClose}
				selectedType={editedCatalogMaybe.catalog.type}
				model={editedCatalogMaybe.catalog.model}
				iconConfig={editedCatalogMaybe.config}
				catalog={editedCatalogMaybe.catalog}
				mode={catalogMode}
				label={<span title={editedCatalogMaybe.catalog.model}>Duplicate Catalog Item - {editedCatalogMaybe.catalog.model}</span>}
				verticalAlignment={verticalAlignment}
				horizontalAlignment={horizontalAlignment}
				style={{transform: "translateX(-35px)"}}
			/>
		);
	}

	return <></>;
};

import * as React from "react";
import {SliderV5} from "./SliderV5";

interface IHueSliderProps {
	readonly value: number;
	readonly onValueChange: (newValue: number) => void;
	readonly sliderWidth?: number;
}

/**
 * HueSlider's "value" property's range: [0 - 360]
 */
export const HueSliderV5 = (props: IHueSliderProps) => {
	const onValueChange = (newValue: number) => {
		props.onValueChange(newValue * 360);
	};

	return (
		<SliderV5
			value={props.value / 360}
			width={props.sliderWidth ?? 232}
			classNames="Hue"
			onValueChange={onValueChange}
			largeStepValue={0.1}
		/>
	);
};

import {useCallback, useEffect, useReducer, useRef, useState} from "react";
import {flushSync} from "react-dom";
import {Observer} from "mobx-react";
import type {IDropdownOption} from "../../../interaction/DropdownOptionsV5";
import type {Markup} from "../../../../../data/models/Markup";
import type {SelectionTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/SelectionTool";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {Xyicon3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {Color} from "../../../../../generated/api/base";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {Debouncer} from "../../../../../utils/function/Debouncer";
import {Permission} from "../../../../../generated/api/base";
import {THREEUtils} from "../../../../../utils/THREEUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {PointerDetector} from "../../../../../utils/interaction/PointerDetector";
import {ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {DropdownButtonV5} from "../../../interaction/DropdownButtonV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {Functions} from "../../../../../utils/function/Functions";
import {FindXyiconsWindowV5} from "../links/FindXyiconsWindowV5";
import {MarkupsWithCustomizableColor} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import ApplyIcon from "../../../icons/check.svg?react";
import CancelIcon from "../../../icons/xmark-large.svg?react";
import RedrawIcon from "../../../icons/boundary-redraw.svg?react";
import EditIcon from "../../../icons/markup-pencil.svg?react";
import SearchIcon from "../../../icons/search.svg?react";
import RotateIcon from "../../../icons/rotate-cw.svg?react";
import CopyIcon from "../../../icons/copy.svg?react";
import TextIcon from "../../../icons/markup-text.svg?react";
import LinkIcon from "../../../icons/link.svg?react";
import DeleteIcon from "../../../icons/trash.svg?react";
import MoreIcon from "../../../icons/dots-horizontal.svg?react";
import MergeIcon from "../../../icons/merge.svg?react";
import StampIcon from "../../../icons/stamp.svg?react";
import ScissorsIcon from "../../../icons/scissors.svg?react";
import UnplotIcon from "../../../icons/unplotted-xyicon.svg?react";
import FlipHorizontalIcon from "../../../icons/flip-horizontal.svg?react";
import FlipVerticalIcon from "../../../icons/flip-vertical.svg?react";
import InfoIcon from "../../../icons/info.svg?react";
import {notifyAboutInsufficientPermissions, onUnplotClick} from "../../ActionBarUtils";
import {MarkupTypeChangerV5} from "./MarkupTypeChangerV5";
import {SelectedObjectsBubble} from "./SelectedObjectsBubble";
import {SpaceActionBarStyled} from "./SpaceActionBar.styled";
import {MarkupTextModifierV5} from "./MarkupTextModifierV5";
import {ArrowHeadSizeChangerV5} from "./ArrowHeadSizeChangerV5";
import {LineThicknessChangerV5} from "./LineThicknessChangerV5";
import {FillButtonV5} from "./FillButtonV5";

interface ISpaceActionBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly isEditEnabled: boolean;
	readonly isInEditMode: boolean;
	readonly isLinkEnabled: boolean;
	readonly worldX: number;
	readonly worldY: number;
	readonly isDetailsPanelOpen: boolean;
	readonly onDeleteClick: () => void;
	readonly onRedrawClick: () => void;
	readonly onCancelEditClick: () => void;
	readonly onApplyEditClick: () => void;
	// eslint-disable-next-line react/no-unused-prop-types
	readonly openMergeBoundariesWindow: () => void;
	readonly toggleDetailsPanel: () => void;
}

const _markupColorChangeDebouncer: Debouncer = new Debouncer();

export const SpaceActionBarV5 = (props: ISpaceActionBarProps) => {
	const {spaceViewRenderer} = props;
	const {spaceItemController, actions} = spaceViewRenderer;
	const {selectedItems} = spaceItemController;
	const [isTextEditorOpen, setIsTextEditorOpen] = useState<boolean>(false);
	const [isInFindXyiconsMode, setIsInFindXyiconsMode] = useState<boolean>(false);
	const {linkManager} = spaceViewRenderer.toolManager;
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	//const _addTextRef = forwardRef<IconButton>();
	const _ref = useRef<HTMLDivElement>();

	const _isSingleMarkupSelected: boolean = spaceItemController.markupManager.selectedItems.length === 1 && selectedItems.length === 1;
	const _itemsWithoutPermissionCount: number = actions.getNumberOfSpaceItemsWithoutPermission(selectedItems, Permission.Update);

	const closeActionBar = () => {
		spaceItemController.closeActionBar();
	};

	const closePortSelector = () => {
		spaceItemController.closePortSelector();
	};

	const onLinkClick = () => {
		const xyicon = (selectedItems[0] as Xyicon3D).modelData as Xyicon;
		const ports = xyicon.ports;

		if (_itemsWithoutPermissionCount === 0) {
			if (selectedItems.length === 1 && ports.length > 0) {
				spaceItemController.openPortSelector(props.worldX, props.worldY, xyicon, ports, "from");
			} else {
				linkManager.enableLinkMode(null);
			}
			forceUpdate();

			spaceViewRenderer.inheritedMethods.setActiveTool("selection");
		} else {
			notifyAboutInsufficientPermissions(spaceViewRenderer);
		}
	};

	const onCancelLinkClick = useCallback(() => {
		linkManager.disableLinkMode();
		setIsInFindXyiconsMode(false);
		forceUpdate();
	}, [linkManager]);

	const onFindXyiconClick = () => {
		setIsInFindXyiconsMode(true);
	};

	const onFlipXClick = () => {
		spaceItemController.xyiconManager.flipXSelected();
	};

	const onFlipYClick = () => {
		spaceItemController.xyiconManager.flipYSelected();
	};

	const onAddTextClick = () => {
		setIsTextEditorOpen(true);
		spaceViewRenderer.inheritedMethods.setActiveTool("selection");
	};

	const onTextEditorClose = useCallback(() => {
		setIsTextEditorOpen(false);
	}, []);

	const onEditClick = () => {
		spaceItemController.switchEditMode(!props.isInEditMode);
	};

	const onRotateClick = () => {
		const {spaceItemController} = spaceViewRenderer;
		const {rotationIconManager} = spaceItemController;

		if (_itemsWithoutPermissionCount === 0) {
			rotationIconManager.update();
			closeActionBar();
			closePortSelector();
			spaceViewRenderer.inheritedMethods.setActiveTool("selection");
		} else {
			notifyAboutInsufficientPermissions(spaceViewRenderer);
		}
	};

	useEffect(() => {
		const onCameraMove = () => {
			const activeTool = spaceViewRenderer.toolManager.activeTool;

			if (activeTool.toolId !== "selection" || (activeTool.toolId === "selection" && !(activeTool as SelectionTool).isSomethingBeingTranslated)) {
				flushSync(forceUpdate);
			}
		};

		const onKeyUp = (event: KeyboardEvent) => {
			switch (event.key) {
				case KeyboardListener.KEY_ENTER:
					// if (_addTextRef.current)
					// {
					// 	onAddTextClick();
					// }
					break;
			}
		};

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(onCameraMove);
		spaceViewRenderer.signals.onCanvasResized.add(forceUpdate);
		KeyboardListener.getInstance().signals.up.add(onKeyUp);

		return () => {
			spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(onCameraMove);
			spaceViewRenderer.signals.onCanvasResized.remove(forceUpdate);
			KeyboardListener.getInstance().signals.up.remove(onKeyUp);
		};
	}, [forceUpdate, spaceViewRenderer, spaceViewRenderer.signals.onCanvasResized]);

	useEffect(() => {
		forceUpdate();
	}, [isInFindXyiconsMode]);

	return (
		<Observer>
			{() => {
				const {worldX} = props;
				const worldZ = spaceViewRenderer.spaceOffset.z;
				const areOnlyMarkupsSelected = selectedItems.length === spaceViewRenderer.markupManager.selectedItems.length;
				const firstColoredMarkup = spaceViewRenderer.markupManager.selectedItems.find((m: Markup3D) =>
					MarkupsWithCustomizableColor.includes(m.type),
				) as Markup3D;

				const singleMarkup = spaceItemController.markupManager.selectedItems[0] as Markup3D;
				const doesMarkupSupportText = singleMarkup?.doesSupportText;
				const doesSupportText = _isSingleMarkupSelected && doesMarkupSupportText;

				let {worldY} = props;

				if (
					spaceItemController.isInEditMode &&
					selectedItems.every((m) => m.spaceItemType === "markup" && (m as Markup3D).isTextOffsetHandlerVisible)
				) {
					worldY += 100 * spaceViewRenderer.correctionMultiplier.current;
				}
				const style = THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "top", _ref.current);

				return doesSupportText && isTextEditorOpen ? (
					<MarkupTextModifierV5
						barRef={_ref}
						style={style}
						spaceViewRenderer={spaceViewRenderer}
						markup3D={singleMarkup}
						onClose={onTextEditorClose}
					/>
				) : isInFindXyiconsMode ? (
					<FindXyiconsWindowV5
						divRef={_ref}
						onClose={onCancelLinkClick}
						style={style}
					/>
				) : (
					<SpaceActionBarStyled
						ref={_ref}
						className="SpaceActionBar"
						style={style}
						onContextMenu={PointerDetector.onContextMenu}
					>
						{props.isInEditMode ? (
							<>
								{spaceViewRenderer.boundaryManager.isInEditMode && (
									<IconButtonV5
										IconComponent={RedrawIcon}
										title="Redraw"
										onClick={props.onRedrawClick}
									/>
								)}
								<IconButtonV5
									IconComponent={ApplyIcon}
									title="Done"
									onClick={props.onApplyEditClick}
								/>
								<IconButtonV5
									IconComponent={CancelIcon}
									title="Cancel"
									onClick={props.onCancelEditClick}
								/>
							</>
						) : linkManager.isInLinkMode ? (
							<>
								<IconButtonV5
									IconComponent={SearchIcon}
									title="Find..."
									onClick={onFindXyiconClick}
								/>
								<IconButtonV5
									IconComponent={CancelIcon}
									title="Cancel"
									onClick={onCancelLinkClick}
								/>
							</>
						) : (
							<>
								<SelectedObjectsBubble selectedItems={selectedItems} />
								<IconButtonV5
									IconComponent={InfoIcon}
									title="Details"
									onClick={props.toggleDetailsPanel}
									isActive={props.isDetailsPanelOpen}
								/>
								<MarkupTypeChangerV5
									spaceViewRenderer={spaceViewRenderer}
									forceUpdate={forceUpdate}
								/>
								<IconButtonV5
									IconComponent={RotateIcon}
									title="Rotate"
									onClick={onRotateClick}
								/>
								<FillButtonV5 spaceViewRenderer={spaceViewRenderer} />
								<LineThicknessChangerV5
									spaceViewRenderer={spaceViewRenderer}
									items={selectedItems}
								/>
								<ArrowHeadSizeChangerV5 spaceViewRenderer={spaceViewRenderer} />
								{props.isEditEnabled && (
									<IconButtonV5
										IconComponent={EditIcon}
										title="Edit"
										onClick={onEditClick}
									/>
								)}
								{areOnlyMarkupsSelected && firstColoredMarkup && (
									<ColorSelectorV5
										title="Markup color"
										color={{hex: firstColoredMarkup.color, transparency: 0}}
										onColorChange={(newColor: Color) => {
											onMarkupColorChange(spaceViewRenderer, newColor);
											forceUpdate();
										}}
										isTransparencyEnabled={false}
										eyeDropperProps={spaceViewRenderer.eyeDropperProps}
										outerDivRef={_ref}
										horizontalAlignment={HorizontalAlignment.outerRight}
										verticalAlignment={VerticalAlignment.bottom}
									/>
								)}
								{props.isLinkEnabled && (
									<IconButtonV5
										IconComponent={LinkIcon}
										title="Link"
										onClick={onLinkClick}
									/>
								)}
								<IconButtonV5
									IconComponent={CopyIcon}
									title="Copy"
									onClick={spaceViewRenderer.copySelectedItemsToClipboard}
								/>
								{doesSupportText && (
									<IconButtonV5
										IconComponent={TextIcon}
										title="Add Text"
										onClick={onAddTextClick}

										/*ref={_addTextRef}*/
									/>
								)}
								{/* <IconButton icon="events" title="Events"  onClick={onEventsClick} /> */}
								{hasPermissionToDelete && (
									<IconButtonV5
										IconComponent={DeleteIcon}
										title="Delete"
										onClick={props.onDeleteClick}
									/>
								)}
								<DropdownButtonV5
									className="SpaceActionBarDropdown"
									button={
										<IconButtonV5
											IconComponent={MoreIcon}
											title="More Tools"
											onClick={Functions.emptyFunction}
										/>
									}
									options={getMoreOptions(props, onFlipXClick, onFlipYClick)}
								/>
							</>
						)}
					</SpaceActionBarStyled>
				);
			}}
		</Observer>
	);
};

const getMoreOptions = (props: ISpaceActionBarProps, onFlipXClick: () => void, onFlipYClick: () => void) => {
	const {spaceViewRenderer} = props;
	const {spaceItemController} = spaceViewRenderer;
	const areOnlyXyiconsSelected = spaceItemController.selectedItems.length === spaceViewRenderer.xyiconManager.selectedItems.length;

	const moreOptions: IDropdownOption[] = [
		{
			label: "Copy to Stamp",
			onClick: spaceViewRenderer.copySelectedItemsToStamp,
			IconComponent: StampIcon,
		},
		{
			label: "Cut",
			onClick: spaceViewRenderer.cutSelectedItemsToClipboard,
			IconComponent: ScissorsIcon,
		},
	];

	if (areOnlyXyiconsSelected) {
		moreOptions.push(
			{
				label: "Flip Horizontal",
				onClick: onFlipXClick,
				IconComponent: FlipHorizontalIcon,
			},
			{
				label: "Flip Vertical",
				onClick: onFlipYClick,
				IconComponent: FlipVerticalIcon,
			},
			{
				label: "Unplot",
				onClick: () => onUnplotClick(spaceViewRenderer),
				IconComponent: UnplotIcon,
			},
		);
	}

	const {selectedItems} = spaceItemController;

	if (selectedItems.length > 0 && selectedItems.length === spaceItemController.boundaryManager.selectedItems.length) {
		moreOptions.push({
			label: "Merge",
			onClick: props.openMergeBoundariesWindow,
			IconComponent: MergeIcon,
		});
	}

	return moreOptions;
};

const hasPermissionToDelete = (spaceViewRenderer: SpaceViewRenderer): boolean => {
	return spaceViewRenderer.actions.someSpaceItemsHaveGivenPermission(spaceViewRenderer.spaceItemController.selectedItems, Permission.Delete);
};

const onMarkupColorChange = (spaceViewRenderer: SpaceViewRenderer, newColor: Color) => {
	const selectedMarkups = (spaceViewRenderer.markupManager.selectedItems as Markup3D[]).filter((m) => MarkupsWithCustomizableColor.includes(m.type));

	for (const markup of selectedMarkups) {
		const modelData = markup.modelData as Markup;

		modelData.setColor(newColor.hex);
		markup.updateByModel(modelData);
	}

	_markupColorChangeDebouncer.debounce(() => {
		// Since we've already changed the modelData, and updated the model based on that,
		// it's necessary to pass "true" for the second (force) parameter here
		spaceViewRenderer.markupManager.updateItems(selectedMarkups, true);
	});
};

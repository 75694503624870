import * as React from "react";
import {inject} from "mobx-react";
import {runInAction} from "mobx";
import styled from "styled-components";
import type {PortTemplateDto, UpdateXyiconsModelRequest} from "../../../generated/api/base";
import {MappingType, XyiconFeature} from "../../../generated/api/base";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {AppState} from "../../../data/state/AppState";
import type {App} from "../../../App";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {IPortMapping, XyiconMappingInformation} from "../../../data/services/FeatureService";
import {TimeUtils} from "../../../utils/TimeUtils";
import type {Catalog} from "../../../data/models/Catalog";
import {Port} from "../../modules/catalog/port/Port";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import {PortsV5} from "../modules/spaceeditor/links/PortsV5";
import {SelectInputStyled, SelectInputV5} from "../input/select/SelectInputV5";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {ButtonV5} from "../button/ButtonV5";
import CloseIcon from "../icons/xmark-large.svg?react";
import ArrowIcon from "../icons/arrow.svg?react";
import {colorPalette} from "../styles/colorPalette";
import {FLEXCENTER, fontSize, zIndex} from "../styles/styles";
import {PopupHeaderStyled, NameStyled, PopupBodyStyled} from "../popup/PopupV5";
import {IconButtonV5} from "../interaction/IconButtonV5";

interface IPortMappingData {
	fromPortId: string;
	mappingType: MappingType;
	toPortId: string | null;
}

interface ISelectable {
	mappingType: MappingType;
	toPortId: string | null;
	label: string;
}

interface IConfirmXyiconModelChangesFormProps {
	readonly xyiconsToBeChanged: Xyicon[];
	readonly catalogIdToChangeTo: string;
	readonly onClose: () => void;

	readonly app?: App;
	readonly appState?: AppState;
}

interface IConfirmXyiconModelChangesFormState {
	isUpdating: boolean;
}

@inject("app")
@inject("appState")
export class ConfirmXyiconModelChangesFormV5 extends React.Component<IConfirmXyiconModelChangesFormProps, IConfirmXyiconModelChangesFormState> {
	private _isMounted: boolean = false;
	// This structure is easier to handle for rendering
	// but we have to convert it to XyiconMappingInformation in the end
	private _catalogGroups: {
		fromCatalogId: string;
		portMappingData: IPortMappingData[];
	}[] = [];

	private _selectables: ISelectable[] = [];

	constructor(props: IConfirmXyiconModelChangesFormProps) {
		super(props);
		this.state = {
			isUpdating: false,
		};
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.props.onClose();
				break;
		}
	};

	private convertPortMappingArrayToObject(portMappingArray: IPortMappingData[]) {
		const portMappingObject: IPortMapping = {};

		for (const pm of portMappingArray) {
			portMappingObject[pm.fromPortId] = {
				mappingType: pm.mappingType,
				port: pm.toPortId,
			};
		}

		return portMappingObject;
	}

	private getXyiconMappingInformation(): XyiconMappingInformation {
		const xyiconMappingInformation: XyiconMappingInformation = {};

		for (const catalogGroup of this._catalogGroups) {
			xyiconMappingInformation[catalogGroup.fromCatalogId] = {
				xyiconIDList: this.props.xyiconsToBeChanged.filter((xyicon) => xyicon.catalogId === catalogGroup.fromCatalogId).map((xyicon) => xyicon.id),
				portMapping: this.convertPortMappingArrayToObject(catalogGroup.portMappingData),
			};
		}

		return xyiconMappingInformation;
	}

	private onChangeClick = () => {
		this.setState({
			isUpdating: true,
		});
		const {xyiconsToBeChanged} = this.props;
		const params: UpdateXyiconsModelRequest = {
			portfolioID: this.props.appState.portfolioId,
			xyiconCatalogID: this.props.catalogIdToChangeTo,
			xyiconMappingInformation: this.getXyiconMappingInformation(),
		};

		runInAction(async () => {
			await TimeUtils.waitUpdate(this.props.appState.actions.updateXyiconsModel(params), this.props.app.notificationContainer);
			await this.props.app.spaceViewRenderer.xyiconManager.updateXyicons(xyiconsToBeChanged);

			if (this._isMounted) {
				this.props.onClose();
			}
		});
	};

	private getDescription() {
		return (
			<div className="description">
				<div className="title">Verify port remapping</div>
				<div className="darkSilverText">
					We attempted to remap ports between the current and new xyicon models automatically. Verify and change the mapping per model using the
					dropdown.
				</div>
			</div>
		);
	}

	private getRows() {
		const {actions} = this.props.appState;
		const targetCatalog = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		const elements: React.ReactNode[] = [];

		for (const catalogGroup of this._catalogGroups) {
			const {fromCatalogId} = catalogGroup;
			const catalog = actions.getFeatureItemById<Catalog>(fromCatalogId, XyiconFeature.XyiconCatalog);
			const fromPortsAsArray = Port.getPortsAsArray(catalog.portTemplate);

			const title = (
				<div className="threeColumns">
					<div>{catalog.model}</div>
					<div />
					<TargetCatalogStyled>{targetCatalog.model}</TargetCatalogStyled>
				</div>
			);

			elements.push(
				<ToggleContainerV5
					title={title}
					key={fromCatalogId}
					open={true}
				>
					<div className="threeColumns">
						{catalog.portTemplate.length > 0 && (
							<PortsV5
								item={catalog}
								isStructurallyEditable={true}
							/>
						)}
						<div className="selectors">
							{fromPortsAsArray.map((p: PortTemplateDto) => {
								return (
									<div
										className="row"
										key={p.id}
									>
										{p.children.length === 0 && <ArrowIcon />}
									</div>
								);
							})}
						</div>
						<div className="selectors">
							{fromPortsAsArray.map((p: PortTemplateDto) => {
								const portMappingData = catalogGroup.portMappingData.find((o) => o.fromPortId === p.id);

								return (
									<div
										className="row"
										key={p.id}
									>
										{p.children.length === 0 && (
											<div className="selectInput">
												<SelectInputV5
													render={(selectable) => selectable.label}
													options={this._selectables}
													sort={false}
													selected={
														this._selectables.find((p) => p.toPortId === portMappingData.toPortId && p.mappingType === portMappingData.mappingType) ||
														this._selectables[0] ||
														null
													}
													onChange={(value: ISelectable) => {
														portMappingData.toPortId = value.toPortId;
														portMappingData.mappingType = value.mappingType;
														this.forceUpdate();
													}}
													optionsZIndex={zIndex.popup + zIndex.contextOptions}
													isSameWidth={true}
													isConfirmXyiconModelChangesForm={true}
												/>
											</div>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</ToggleContainerV5>,
			);
		}

		return elements;
	}

	private getContent(): React.ReactNode {
		const {actions} = this.props.appState;
		const {xyiconsToBeChanged} = this.props;
		const catalogToChangeTo = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		// There are 4 scenarios mentions on ticket #2964
		// https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20October/S-74?workitem=2964

		const areThereLinkedXyiconsFromPorts = xyiconsToBeChanged.some((xyicon: Xyicon) =>
			actions
				.getLinksXyiconXyicon(xyicon.id)
				.some((l) => (l.link.fromObjectId === xyicon.id && l.link.fromPortId) || (l.link.toObjectId === xyicon.id && l.link.toPortId)),
		);

		// Scenario 1:
		// If the current model has linked xyicon/s to its ports AND the new model has a port template;
		if (areThereLinkedXyiconsFromPorts && catalogToChangeTo.portTemplate.length > 0) {
			return (
				<>
					{this.getDescription()}
					<div className="threeColumns">
						<div>Current model ports</div>
						<div />
						<TargetCatalogStyled>New model ports</TargetCatalogStyled>
					</div>
					{this.getRows()}
				</>
			);
		} else if (areThereLinkedXyiconsFromPorts) {
			// Scenario 2:
			// If the current model has linked xyicon/s to its ports AND the new model DOES NOT have a port structure - "Orphaned Xyicons"
			return (
				<>
					{this.getDescription()}
					{this.getRows()}
				</>
			);
		} else {
			// Scenario 3, and Scenario 4:
			// If the current model has linked xyicons without a port structure AND the new model HAVE OR DOES NOT HAVE a port structure
			return `You are about to change the model of ${xyiconsToBeChanged.length} xyicon(s). Do you wish to continue?`;
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

		const {actions} = this.props.appState;

		const targetCatalog = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		this._selectables = Port.getPortsAsArray(targetCatalog.portTemplate)
			.filter((p) => p.children.length === 0)
			.map((p) => ({
				label: Port.getFullLabelById(targetCatalog.portTemplate, p.id).join(" • "),
				toPortId: p.id,
				mappingType: MappingType.MapToPort,
			}));

		const additionalSelectables: ISelectable[] = [
			{
				label: "Break link",
				mappingType: MappingType.BreakLink,
				toPortId: null,
			},
			{
				label: "Convert to xyicon to xyicon link",
				mappingType: MappingType.ConvertToDirectLink,
				toPortId: null,
			},
		];

		this._selectables.push(...additionalSelectables);

		for (const xyicon of this.props.xyiconsToBeChanged) {
			if (!this._catalogGroups.some((o) => o.fromCatalogId === xyicon.catalogId)) {
				const catalog = actions.getFeatureItemById<Catalog>(xyicon.catalogId, XyiconFeature.XyiconCatalog);

				const portsAsArray = Port.getPortsAsArray(catalog.portTemplate);

				this._catalogGroups.push({
					fromCatalogId: xyicon.catalogId,
					portMappingData: portsAsArray
						.filter((p) => p.children.length === 0)
						.map((p: PortTemplateDto, index: number) => {
							const ret: IPortMappingData = {
								fromPortId: p.id,
								mappingType: MappingType.ConvertToDirectLink,
								toPortId: null,
							};

							if (index < this._selectables.length - additionalSelectables.length) {
								ret.toPortId = this._selectables[index].toPortId;
								ret.mappingType = MappingType.MapToPort;
							}

							return ret;
						}),
				});
			}
		}

		this.forceUpdate();
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		this._isMounted = false;
	}

	public override render() {
		const {app, xyiconsToBeChanged, appState} = this.props;

		const areThereLinkedXyiconsFromPorts = xyiconsToBeChanged.some((xyicon: Xyicon) =>
			appState.actions
				.getLinksXyiconXyicon(xyicon.id)
				.some((l) => (l.link.fromObjectId === xyicon.id && l.link.fromPortId) || (l.link.toObjectId === xyicon.id && l.link.toPortId)),
		);

		return (
			<DomPortal destination={app.modalContainer}>
				<ConfirmXyiconModelChangesFormWrapper
					className="ConfirmXyiconModelChangesFormWrapper"
					$hasLinkedXYicons={!areThereLinkedXyiconsFromPorts}
				>
					<div className="ConfirmXyiconModelChangesForm vbox">
						<PopupHeaderStyled className="header">
							<NameStyled className="name">Confirm Xyicon Model Changes</NameStyled>
							<IconButtonV5
								IconComponent={CloseIcon}
								onClick={this.props.onClose}
							/>
						</PopupHeaderStyled>

						<PopupBodyStyled className="body">
							{this.getContent()}
							<div className="buttonContainer hbox alignCenter justifyEnd">
								<ButtonV5
									label="Cancel"
									onClick={this.props.onClose}
									type="secondary"
								/>
								<ButtonV5
									label={this.state.isUpdating ? "Changing..." : "Change"}
									className="primary"
									onClick={this.onChangeClick}
									disabled={this.state.isUpdating}
								/>
							</div>
						</PopupBodyStyled>
					</div>
				</ConfirmXyiconModelChangesFormWrapper>
			</DomPortal>
		);
	}
}

const ConfirmXyiconModelChangesFormWrapper = styled.div<{$hasLinkedXYicons: boolean}>`
	${FLEXCENTER};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: ${zIndex.popup};
	background-color: rgba(0, 0, 0, 0.65);
	position: fixed;

	.PopupWindow {
		input {
			margin: initial;
		}
	}

	.ConfirmXyiconModelChangesForm {
		border-radius: 8px;
		width: ${({$hasLinkedXYicons}) => ($hasLinkedXYicons ? "440px" : "750px")};
		max-height: 800px;
		background: ${colorPalette.white};
		box-shadow: 0px 8px 8px 0px rgba(50, 50, 71, 0.08);

		.header {
			padding: ${({$hasLinkedXYicons}) => ($hasLinkedXYicons ? "8px" : "16px")};

			.name {
				line-height: 24px;
			}

			.IconButton {
				svg {
					height: 16px;
					width: 16px;
				}
			}
		}

		.body {
			max-height: 100%;
			overflow-y: auto;
			padding: ${({$hasLinkedXYicons}) => ($hasLinkedXYicons ? "8px" : "8px 16px")};
			padding-bottom: ${({$hasLinkedXYicons}) => ($hasLinkedXYicons ? "8px" : "16px")};
			gap: 24px;

			.description {
				.title {
					font-size: ${fontSize.lg};
					color: ${colorPalette.gray.c950};
					height: 24px;
					align-items: center;
					display: flex;
				}

				.darkSilverText {
					font-size: ${fontSize.md};
					color: ${colorPalette.gray.c700Dark};
					padding-top: 4px;
				}
			}

			.buttonContainer {
				gap: 16px;
			}

			.filter-title {
				font-size: ${fontSize.sm};
				color: #a9a3a3;
				text-transform: none;
			}

			.ToggleContainer {
				.children {
					padding-bottom: 0;
					margin-bottom: 0;
				}
			}

			.PortContainer {
				margin: 0;

				.PortComponent {
					&.leaf {
						.textContainer {
							&.locked {
								opacity: 1;
							}

							.TextInput {
								background: none;
							}
						}
					}
				}

				.PortComponent:last-child {
					margin-bottom: 0;
				}
			}

			.selectors {
				.row {
					margin: 15px 8px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;

					${SelectInputStyled} {
						width: 300px;
					}
				}

				.row:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
`;

const TargetCatalogStyled = styled.div`
	padding: "0 8px";
`;

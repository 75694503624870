import styled from "styled-components";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {Space} from "../../../../../data/models/Space";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {useAppStore} from "../../../../../StateManager";
import {XyiconFeature} from "../../../../../generated/api/base";
import {onWorkspaceViewSelect} from "../../../topbar/ViewTabsCommon";
import {SpaceCard} from "./SpaceCard";

interface ISpaceListProps {
	readonly spaces: Space[];
	readonly selectedSpace: Space;
	readonly onSpaceSelect: (selectedSpace: Space) => void;
	readonly onCloseSpaceContainer?: () => void;
}

export const SpaceList = (props: ISpaceListProps) => {
	const {spaces, selectedSpace, onSpaceSelect, onCloseSpaceContainer} = props;
	const appState = useAppStore((state) => state.appState);
	const viewForOpenSpaceSelector = useAppStore((state) => state.viewForOpenSpaceSelector);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);

	return (
		<SpaceListStyled>
			{spaces
				.toSorted((a: Space, b: Space) => StringUtils.sortIgnoreCase(a.name, b.name))
				.map((space: Space) => {
					return (
						<SpaceCard
							key={space.id}
							space={space}
							isSelected={space === selectedSpace}
							onClick={() => {
								const selectedView = viewForOpenSpaceSelector ?? appState.actions.getSelectedView(XyiconFeature.SpaceEditor);

								onWorkspaceViewSelect(selectedView);
								setViewForOpenSpaceSelector(null);
								if (space !== selectedSpace) {
									onSpaceSelect(space);
								}
								onCloseSpaceContainer?.();
							}}
						/>
					);
				})}
		</SpaceListStyled>
	);
};

const SpaceListStyled = styled.div`
	margin-top: 8px;
	border-radius: ${radius.md};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding: 16px;
	z-index: 1;
	overflow-y: auto;
	overflow-x: hidden;
	max-height: calc(100vh - 250px);
`;

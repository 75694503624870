import {IconButton} from "../../../../../widgets/button/IconButton";
import {ReactUtils} from "../../../../../utils/ReactUtils";

type LayerType = "hide" | "lock";

interface IHideAndLockProps {
	readonly type: LayerType;
	readonly active: boolean;
	readonly onChange: (active: boolean) => void;
	readonly isItForModifyAll?: boolean;
}

export const HideAndLock = ({type, active, isItForModifyAll, onChange}: IHideAndLockProps) => {
	const title = type === "hide" ? (active ? "Show" : "Hide") : active ? "Unlock Position" : "Lock Position";

	return (
		<div
			className={ReactUtils.cls("HideAndLock", {modifyAll: isItForModifyAll})}
			onClick={() => onChange(!active)}
		>
			<IconButton
				icon={`${type}${active ? "-active" : ""}`}
				title={title}
			/>
		</div>
	);
};

import * as React from "react";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Functions} from "../../../../utils/function/Functions";
import type {IBooleanFieldSettingsDefinition} from "../../../modules/settings/modules/field/datatypes/BooleanFieldSettings";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";

export interface IToggleSwitchProps {
	//label: string;
	readonly value: boolean;
	readonly onChange: (value: boolean) => void;
	readonly disabled?: boolean;
	readonly inline?: boolean;
	readonly dataTypeSettings?: IBooleanFieldSettingsDefinition;
	readonly noBooleanLabel?: boolean;
	readonly focused?: boolean;
	readonly onClick?: (e: React.MouseEvent) => void;
}

export class ToggleSwitch extends React.Component<IToggleSwitchProps> {
	public static readonly defaultProps: Partial<IToggleSwitchProps> = {
		noBooleanLabel: true,
	};

	private getButtonLabel() {
		const {value, dataTypeSettings} = this.props;

		let label = value ? "Yes" : "No";

		if (dataTypeSettings) {
			label = value ? dataTypeSettings.displayLabelForTrue : dataTypeSettings.displayLabelForFalse;
		}

		return label;
	}

	private onChange = (event: React.MouseEvent) => {
		const {onChange, onClick} = this.props;

		if (onChange && event.detail === 1) {
			event.stopPropagation();

			onChange(!this.props.value);
			onClick?.(event);
		}
	};

	private onDoubleClick = (event: React.MouseEvent) => {
		this.onChange(event);
	};

	private renderToggleSwitch = () => {
		const {value = false, disabled, inline, focused} = this.props;

		return (
			<div
				className={ReactUtils.cls("ToggleSwitch", {disabled, inline, focused})}
				onClick={this.onChange}
			>
				<input
					type="checkbox"
					checked={value}
					onChange={Functions.emptyFunction /* empty arrow function prevents react to cry for onChange handler */}
				/>
				<div className="slider" />
				<div className="circle" />
			</div>
		);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		const {onChange, value, focused} = this.props;

		if (focused && event.key === KeyboardListener.KEY_ENTER) {
			onChange(!value);
		}
	};

	public override componentDidMount(): void {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentWillUnmount(): void {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {inline, noBooleanLabel} = this.props;
		const booleanValue = this.getButtonLabel();

		return inline ? (
			<div className="hbox cellContent">
				<div
					className={ReactUtils.cls("value", {blank: booleanValue === ""})}
					onDoubleClick={this.onDoubleClick}
					onClick={this.onChange}
				>
					{booleanValue}
				</div>
				{this.renderToggleSwitch()}
			</div>
		) : (
			<div className={ReactUtils.cls("DetailsTabToggleSwitch flexCenter", {noBooleanLabel})}>
				{this.renderToggleSwitch()}
				{!noBooleanLabel && <div className="value">{booleanValue}</div>}
			</div>
		);
	}
}

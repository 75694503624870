import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IPopupWindowConfig} from "../../../../../../modules/abstract/popups/PopupWindow";
import {Report} from "../../../../../../../data/models/Report";
import type {ExportFormat} from "../../../../../../../data/exporters/BaseExporter";
import {PromptWindow} from "../../../../../../modules/abstract/popups/PromptWindow";
import {XyiconFeature, FieldDataType} from "../../../../../../../generated/api/base";
import type {AppState} from "../../../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {SearchFieldV5} from "../../../../../input/search/SearchFieldV5";
import {IconButtonV5} from "../../../../../interaction/IconButtonV5";
import XmarkLargeIcon from "../../../../../icons/xmark-large.svg?react";
import PenWithLineIcon from "../../../../../icons/pen-with-line.svg?react";
import DonwloadIcon from "../../../../../icons/download.svg?react";
import BackIcon from "../../../../../icons/arrow-back.svg?react";
import ChevronDownIcon from "../../../../../icons/chevron-down.svg?react";
import {SimpleTableV5} from "../../../../../abstract/table/SimpleTableV5";
import {DropdownButtonV5} from "../../../../../interaction/DropdownButtonV5";
import {DropDownTitleStyle, LineStyled, RunReportStyled} from "./RunReportPage.style";

interface IRunReportPageProps {
	readonly report?: Report;
	readonly onClose?: () => void;
	readonly onEditClick?: (report: Report) => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly isNavbarDocked?: boolean;
}

interface IRunReportPageState {
	search: string;
}

@inject("appState")
@inject("transport")
@observer
export class RunReportPageV5 extends React.Component<IRunReportPageProps, IRunReportPageState> {
	private _table = React.createRef<SimpleTableV5<string[]>>();
	private _editingCount = 0;

	constructor(props: IRunReportPageProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private getHeaders() {
		const reportData = this.props.report.reportData;

		if (reportData.length > 0) {
			const reportHeader = reportData[0];

			return reportHeader.map((title: string, index: number) => {
				return {
					id: String(index),
					title: title,
				};
			});
		} else {
			return [];
		}
	}

	private getFields = (row: string[]) => {
		const {report, appState} = this.props;

		return [...report.displayedFields, ...report.displayedLinkedFields]
			.map((fieldData, index) => {
				if (index < row.length) {
					const field = appState.actions.getFieldByRefId(fieldData.field);

					if (field) {
						if (field.dataType === FieldDataType.Numeric) {
							const cell = `${row[index] ?? ""}`;
							const values = cell.split("\n");

							return values?.map((v) => appState.actions.formatValue(v, fieldData.field) as string).join("\n");
						}
						let value = (appState.actions.formatValue(row[index], fieldData.field) as string) || row[index];

						if (field.dataType === FieldDataType.Boolean) {
							value = row[index];
						}

						return value;
					} else {
						return "";
					}
				}
				return null;
			})
			.filter((field) => field !== null);
	};

	private onEditClick = () => {
		this.props.onEditClick(this.props.report);
		this.props.report.startEditing();
		this._editingCount++;
	};

	private onSaveClick = () => {
		const {report, transport} = this.props;

		return transport.services.feature.updateReport(report);
	};

	private onSaveAsClick = async () => {
		const {report, transport} = this.props;
		const reportName = report.name;

		const placeholder = `${reportName} - copy`;

		const config: IPopupWindowConfig = {ok: "Save"};

		let name = await PromptWindow.open("Please enter the new name you want this to be saved with", `Save ${reportName} As`, placeholder, "", config);

		if (name !== null) {
			name = name || placeholder;
			const newReport = report.duplicate(name);

			await transport.services.feature.create(newReport.serializeData(), XyiconFeature.Report);
			this.props.onClose();
		}
	};

	private onRunClick = () => {
		const {report, transport} = this.props;

		transport.services.feature.runSavedReport(report);
	};

	private onExportClick = (format: ExportFormat) => {
		let data = this.props.report.reportData;

		if (this._table.current && data.length > 2) {
			const headers = data[0];

			data = [headers, ...this._table.current.sortData(data.slice(1))];
		}
		this.props.report.exportReport(format, data);
	};

	public override render() {
		const {report, onClose, appState} = this.props;
		const {search} = this.state;

		const data = report.reportData?.slice(1) || [];

		return (
			<RunReportStyled
				$isNavBarDocked={this.props.isNavbarDocked}
				className="runreport"
			>
				<div className="header">
					<div className="headerLabel">
						<IconButtonV5
							IconComponent={BackIcon}
							onClick={onClose}
							title="To Reports"
						/>
						<div className="headerName">{report.name}</div>

						<LineStyled></LineStyled>
						<SearchFieldV5
							value=""
							onInput={this.onSearchInput}
							minimized={true}
						/>
						<IconButtonV5
							IconComponent={PenWithLineIcon}
							onClick={this.onEditClick}
							label="Edit"
							className="editReportButton"
						/>
						<DropdownButtonV5
							button={
								<DropDownTitleStyle>
									<DonwloadIcon className="downloadIcon" />
									Download as Excel <ChevronDownIcon />
								</DropDownTitleStyle>
							}
							options={[
								{
									label: "Download as Excel",
									onClick: () => this.onExportClick("excel"),
								},
								{
									label: "Download as CSV",
									onClick: () => this.onExportClick("csv"),
								},
							]}
						/>
						<DropdownButtonV5
							className="savebutton"
							button={
								<DropDownTitleStyle>
									Save <ChevronDownIcon />
								</DropDownTitleStyle>
							}
							options={[
								...[
									{
										label: "Save",
										onClick: this.onSaveClick,
									},
								],
								...(Report.canCreateReports(appState)
									? [
											{
												label: "Save As",
												onClick: this.onSaveAsClick,
											},
										]
									: []),
								{
									label: "Run",
									onClick: this.onRunClick,
								},
							]}
						/>
					</div>
					<IconButtonV5
						onClick={this.props.onClose}
						IconComponent={XmarkLargeIcon}
					/>
				</div>
				<div className="RunReportTable">
					<SimpleTableV5
						ref={this._table}
						data={data}
						feature={XyiconFeature.Report}
						headers={this.getHeaders()}
						getFields={this.getFields}
						tableSearchQuery={search}
						checkboxColumn={false}
						loading={!report.reportLoaded}
						errorMessage={report.errorMessage}
						isCellContentsWrappingOn={true}
						isItemUnfocused={true}
					/>
				</div>
			</RunReportStyled>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {AppState} from "../../../../../../../../data/state/AppState";
import type {OutputDisplayField} from "../../../../../../../../generated/api/reports";
import {ReportType} from "../../../../../../../../generated/api/reports";
import {ReactUtils} from "../../../../../../../utils/ReactUtils";
import type {Report} from "../../../../../../../../data/models/Report";
import LinkIcon from "../../../../../../icons/link.svg?react";
import {TextInputV5} from "../../../../../../details/datatypes/TextInputV5";
import {ReportDisplayNamesStyled} from "./ReportDisplayNamesV5.style";

interface IReportDisplayNamesProps {
	readonly report: Report;
	readonly appState?: AppState;
}

interface IReportDisplayItem {
	displayField: OutputDisplayField;
	linkedField: boolean;
}

@inject("appState")
@observer
export class ReportDisplayNamesV5 extends React.Component<IReportDisplayNamesProps> {
	private getFieldName(refId: string) {
		const field = this.props.appState.actions.getFieldByRefId(refId);

		if (field) {
			return field.name;
		}

		return "";
	}

	private onDisplayFieldNameChange = (displayFieldItem: IReportDisplayItem, value: string) => {
		if (value) {
			displayFieldItem.displayField.displayName = value;
		}

		if (!value && displayFieldItem.linkedField) {
			displayFieldItem.displayField.displayName = `Linked ${this.getFieldName(displayFieldItem.displayField.field)}`;
		}
	};

	public override render() {
		const {report} = this.props;
		const items: IReportDisplayItem[] = [];

		report.displayedFields.forEach((df) =>
			items.push({
				displayField: df,
				linkedField: false,
			}),
		);

		if (report.type === ReportType.UserDefinedLinkedXyiconReport) {
			report.displayedLinkedFields.forEach((df) => {
				if (!df.displayName) {
					df.displayName = `Linked ${this.getFieldName(df.field)}`;
				}

				items.push({
					displayField: df,
					linkedField: true,
				});
			});
		}

		return (
			<ReportDisplayNamesStyled className="ReportDisplayNames">
				<div className="header hbox">
					<div style={{fontSize: "18px", fontWeight: 700, padding: "16px 0", marginLeft: "20px"}}>Field Name</div>
					<div className="gap" />
					<div style={{fontSize: "18px", fontWeight: 700, padding: "16px 0"}}>Display name in header</div>
				</div>
				<div className="content flex_1">
					{items.map((item, i) => (
						<div
							className="displayField"
							key={`${item.displayField.field}_${item.linkedField}`}
						>
							<div className="hbox alignCenter item flex_1">
								{item.linkedField && <LinkIcon color={"#9CD99E"} />}
								<div className={ReactUtils.cls("fieldName", {linkedField: item.linkedField})}>{this.getFieldName(item.displayField.field)}</div>
							</div>
							<div className="gap" />
							<div className={ReactUtils.cls("hbox alignCenter displayName", {linkedField: item.linkedField})}>
								{item.linkedField && <span>Linked</span>}
								<TextInputV5
									className="flex_1"
									value={item.displayField.displayName.replace("Linked ", "")}
									onInput={(value) => this.onDisplayFieldNameChange(item, value)}
									onChange={(value) => this.onDisplayFieldNameChange(item, value)}
								/>
							</div>
						</div>
					))}
				</div>
			</ReportDisplayNamesStyled>
		);
	}
}

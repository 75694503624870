import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {Field as FieldModel, IFormattingRule} from "../../../../../../data/models/field/Field";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";
import {FilterOperator} from "../../../../../../data/models/filter/operator/FilterOperator";
import {VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {ColorSelectorV5} from "../../../../colors/ColorSelectorV5";
import {SelectInputV5} from "../../../../input/select/SelectInputV5";
import {IconButtonV5} from "../../../../interaction/IconButtonV5";
import Delete from "../../../../icons/delete.svg?react";
import type {IBooleanFieldSettingsDefinition} from "../../../../details/BooleanFieldSettingsV5";
import {colorPalette} from "../../../../styles/colorPalette";
import {FieldElementsContainerStyled} from "../../../../details/Field.styled";
import {fontSize, fontWeight, radius} from "../../../../styles/styles";
import {FilterOperatorsV5} from "../../../../filter/operator/FilterOperatorsV5";
import type {IFilterOperatorConfig} from "../../../../../../data/models/filter/operator/FilterOperators";

interface ITextFormattingSettingsProps {
	readonly field: FieldModel;
	readonly onChange: () => void;
}

@observer
export class FormattingSettingsV5 extends React.Component<ITextFormattingSettingsProps> {
	private onRemoveRule = (rule: IFormattingRule) => {
		const {field, onChange} = this.props;
		const rules = field.formattingRules;

		if (rules) {
			const index = rules.indexOf(rule);

			if (index >= 0) {
				rules.splice(index, 1);
			}
		}

		onChange();
	};

	private getOperators = (field: FieldModel) => {
		// remove IS_ANY_OF and IS_NOT_ANY_OF operators
		const operators = FieldDataTypes.map[field.dataType].operators;

		return operators(field.dataTypeSettings).filter((o) => ![FilterOperator.IS_ANY_OF, FilterOperator.IS_NOT_ANY_OF].includes(o));
	};

	public override render() {
		const {field, onChange} = this.props;
		const rules = field.formattingRules || [];

		const isBoolean = field.dataType === FieldDataType.Boolean;

		return (
			<FormattingSettingsContainerStyled className="FormattingSettings">
				{rules.map((rule, index) => {
					const color = rule.color || "00ff00";
					const ParamControl = (FilterOperatorsV5.map[rule.operator] as IFilterOperatorConfig)?.control;

					return (
						<FormattingRuleContainerStyled
							key={index}
							className="FormattingRule"
						>
							<FormattingSettingsTextContentStyled>
								<span className="TextStyles">When Text</span>
								<div className="FormattingInputs">
									<SelectInputV5
										className="InputSelector"
										options={this.getOperators(field)}
										selected={rule.operator}
										isSameWidth={true}
										onChange={(operator) => {
											rule.operator = operator;
											onChange();
										}}
										render={(operation) => {
											let label = "";

											if (isBoolean) {
												const dataTypeSettings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

												switch (operation) {
													case FilterOperator.IS_TRUE:
														label = dataTypeSettings.displayLabelForTrue;
														break;
													case FilterOperator.IS_FALSE:
														label = dataTypeSettings.displayLabelForFalse;
														break;
												}
											}

											return label || FilterOperatorsV5.map[operation].label;
										}}
									/>
									{ParamControl && (
										<ParamControl
											param={rule.params}
											onChange={(value) => {
												rule.params = value;
												onChange();
											}}
										/>
									)}
								</div>
							</FormattingSettingsTextContentStyled>
							<div
								className="ColorSettingsContainer"
								style={{display: "flex"}}
							>
								<div className="ColorSettings">
									<span className="TextStyles">Set color to</span>
									<ColorSelectorV5
										title="Color"
										color={{hex: color, transparency: 0}}
										onColorChange={(color) => {
											rule.color = color.hex;
											onChange();
										}}
										isTransparencyEnabled={false}
										verticalAlignment={VerticalAlignment.top}
									/>
								</div>
								<IconButtonV5
									title="Delete"
									IconComponent={Delete}
									className="delete"
									onClick={(e) => this.onRemoveRule(rule)}
								/>
							</div>
						</FormattingRuleContainerStyled>
					);
				})}
			</FormattingSettingsContainerStyled>
		);
	}
}

const FormattingSettingsContainerStyled = styled.div`
	.FormattingInputs {
		display: flex;
		gap: 8px;
	}

	.ColorSettingsContainer {
		display: flex;
		justify-content: space-between;
	}

	.ColorSettings {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	.InputSelector {
		background-color: ${colorPalette.white};
		width: 165px;
	}

	.TextStyles {
		font-weight: ${fontWeight.normal};
		font-size: ${fontSize.md};
	}

	.InputText {
		width: 165px;
	}

	input {
		width: 165px;
		border: 1px solid ${colorPalette.gray.c300};
		border-radius: ${radius.sm};
		padding: 8px;

		&:focus-visible {
			outline: none;
		}
	}
`;

const FormattingRuleContainerStyled = styled.div`
	background-color: ${colorPalette.gray.c100};
	padding: 8px 16px 8px 16px;
	border-radius: ${radius.sm};
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 8px 0;

	${FieldElementsContainerStyled} {
		display: flex;
		flex-direction: column;
	}
`;

const FormattingSettingsTextContentStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

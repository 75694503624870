import * as React from "react";
import {inject, observer} from "mobx-react";
import {SelectSlider} from "../../../../widgets/input/selectslider/SelectSlider";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";

interface IGeneralPermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly transport?: TransportLayer;
}

@inject("transport")
@observer
export class GeneralPermissions extends React.Component<IGeneralPermissionsProps> {
	public static readonly generalPermissionOptions = [
		{
			id: Permission.View,
			label: "View",
		},
		{
			id: Permission.Update,
			label: "Update",
		},
		{
			id: Permission.Delete,
			label: "Delete",
		},
	];

	private onChange = (rowKeys: string[], value: Permission) => {
		const {permissionSet, transport} = this.props;

		permissionSet.portfolioPermission = value;
		transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
	};

	public override render() {
		//const {feature, appState} = this.props;

		return (
			<div className="container">
				<h4>General permissions</h4>
				<SelectSlider
					options={GeneralPermissions.generalPermissionOptions}
					rows={[
						{
							value: this.props.permissionSet.portfolioPermission,
							label: "",
						},
					]}
					onChange={this.onChange}
				/>
			</div>
		);
	}
}

import {useRef} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {zIndex} from "../styles/styles";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {useAppStore} from "../../../StateManager";

interface IInfoBubbleV5Props {
	readonly content: string | React.ReactNode;
	readonly isErrorMessage?: boolean;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly className?: string;
	readonly title?: string;
	readonly hide?: boolean;
	readonly useDomPortal?: boolean;
	readonly isErrorMessageRightAlign?: boolean;
}

export const InfoBubbleV5 = (props: IInfoBubbleV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const {
		isErrorMessage,
		content,
		divRef,
		style = {},
		title,
		className = "",
		hide = false,
		useDomPortal = true,
		isErrorMessageRightAlign = false,
	} = props;
	const floatingElementRef = useRef<HTMLDivElement>();

	const renderBubble = () => {
		return (
			<InfoBubbleStyled
				ref={divRef || floatingElementRef}
				style={style}
				className={ReactUtils.cls(`info-bubble, ${className || ""}`, {error: isErrorMessage})}
				$hide={hide}
				$isErrorMessageRightAlign={isErrorMessageRightAlign}
			>
				<div className="content vbox">
					{!!title?.length && <h4>{title}</h4>}
					{content}
				</div>
			</InfoBubbleStyled>
		);
	};

	if (useDomPortal) {
		return <DomPortal destination={appState.app.modalContainer}>{renderBubble()}</DomPortal>;
	}

	return renderBubble();
};

export const InfoBubbleStyled = styled.div<{$hide?: boolean; $isErrorMessageRightAlign?: boolean}>`
	z-index: ${zIndex.contextOptions + zIndex.popup};
	background: #37474f;
	box-shadow: 0px 4px 20px 0px #00000033;
	border-radius: 4px;
	font-size: 12px;
	line-height: 140%;
	position: absolute;
	width: max-content;
	max-width: 300px;
	color: white;
	border-left: none;
	white-space: initial;
	display: ${({$hide}) => ($hide ? "none" : "block")};

	&.error {
		border-color: #ff4136;
		color: #ff4136;
		background: white;
		border-left: #ff4136 4px solid;

		&::after {
			background: #ffffff;
		}
	}

	&.onPopup {
		z-index: ${zIndex.popup + zIndex.contextOptions};
	}

	&::after {
		content: "";
		background: #37474f;
		transform: translateX(-50%) rotate(45deg);
		position: absolute;
		bottom: -5px;
		width: 10px;
		height: 10px;
		left: ${(props) => (props.$isErrorMessageRightAlign ? "94%" : "50%")};
		&.left {
			left: calc(50% + 60px);
		}
	}

	&.bottomOuter::after {
		top: -5px;
		bottom: auto;
	}

	div {
		word-wrap: break-word;
		padding: 10px;

		h4 {
			font-size: 12px;
			font-weight: 700;
			margin-bottom: 10px;
		}
	}
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {computed, makeObservable} from "mobx";
import {WarningWindow} from "../../abstract/popups/WarningWindow";
import {PopupUtils} from "../../abstract/popups/PopupUtils";
import {ConfirmWindow} from "../../abstract/popups/ConfirmWindow";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {ImageUploadPreprocessor} from "../../../../utils/image/ImageUploadPreprocessor";
import {notify} from "../../../../utils/Notify";
import {XyiconFeature} from "../../../../generated/api/base";
import type {LibraryModel} from "../../../../data/models/LibraryModel";
import {LibraryModelType} from "../../../../data/models/LibraryModel";
import type {IError, TransportLayer} from "../../../../data/TransportLayer";
import {NotificationType} from "../../../notification/Notification";
import {THREEUtils} from "../../../../utils/THREEUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {ModelPreviewer} from "./ModelPreviewer";
import {LibraryItems} from "./LibraryItems";

export interface IIsSafeToDelete {
	inUseList: string[];
	safeToDeleteList: string[];
}

interface IGeometrySelectorProps {
	readonly transport?: TransportLayer;
	readonly standard: LibraryModel;
	readonly activeLibraryModel: LibraryModel;
	readonly setActiveLibraryModel: (libraryModel: LibraryModel) => void;
}

interface IGeometrySelectorState {
	selectedLibraryModels: LibraryModel[];
}

@inject("transport")
@observer
export class GeometrySelector extends React.Component<IGeometrySelectorProps, IGeometrySelectorState> {
	private _isDeletePopupWindowOpen: boolean = false;

	constructor(props: IGeometrySelectorProps) {
		super(props);
		makeObservable(this);
		this.state = {
			selectedLibraryModels: [this.props.activeLibraryModel],
		};
	}

	private isSafeToDelete = async (libraryModel?: LibraryModel) => {
		const {transport} = this.props;
		const {selectedLibraryModels} = this.state;
		const libraryModelIDList = libraryModel ? [libraryModel.id] : selectedLibraryModels.map((lM) => lM.id);

		const {result} = (await transport.requestForOrganization({
			url: "librarymodels/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				libraryModelIDList,
			},
		})) as {result: IIsSafeToDelete; error: IError};

		return result;
	};

	private onDeleteSelectedLibraryModelsClick = async () => {
		if (!this._isDeletePopupWindowOpen) {
			const {selectedLibraryModels} = this.state;

			const count = selectedLibraryModels.length;

			if (count > 0) {
				const {inUseList} = await this.isSafeToDelete();

				if (inUseList.length === 0) {
					this._isDeletePopupWindowOpen = true;
					const confirmed = await PopupUtils.getDeleteConfirmationPopup(XyiconFeature.LibraryModel, count);

					this._isDeletePopupWindowOpen = false;

					if (confirmed) {
						await this.deleteSelectedLibraryModels(selectedLibraryModels);
					}
				} else {
					await WarningWindow.open(`${inUseList.length} item(s) couldn't be deleted, because they're being used by other components.`);
				}
			}
		}
	};

	private onDeleteLibraryModelClick = async (libraryModel: LibraryModel) => {
		if (!this._isDeletePopupWindowOpen) {
			const selectedLibraryModels = [...this.state.selectedLibraryModels];

			const indexInSelectedModels = selectedLibraryModels.indexOf(libraryModel);

			const {inUseList} = await this.isSafeToDelete(libraryModel);

			if (!inUseList.includes(libraryModel.id)) {
				this._isDeletePopupWindowOpen = true;
				const confirmed = await ConfirmWindow.open("Are you sure you want to delete this model?");

				this._isDeletePopupWindowOpen = false;

				if (confirmed) {
					if (indexInSelectedModels > -1) {
						selectedLibraryModels.splice(indexInSelectedModels, 1);
					}

					this.setState({
						selectedLibraryModels: selectedLibraryModels.length ? selectedLibraryModels : [this.props.standard],
					});

					if (this.props.activeLibraryModel === libraryModel) {
						this.props.setActiveLibraryModel(this.props.standard);
					}

					await this.actions.deleteItems([libraryModel], XyiconFeature.LibraryModel);
				}
			} else {
				await WarningWindow.open("This item couldn't be deleted, because it is being used by other components.");
			}
		}
	};

	private async deleteSelectedLibraryModels(libraryModels: LibraryModel[]) {
		const newLibraryModels = [...this.libraryModels];

		for (let i = newLibraryModels.length - 1; i >= 0; --i) {
			const libraryModel = newLibraryModels[i];

			if (libraryModels.includes(libraryModel)) {
				newLibraryModels.splice(i, 1);
			}
		}

		this.onSetItemActive(this.props.standard);

		await this.actions.deleteItems(libraryModels, XyiconFeature.LibraryModel);
		await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
	}

	private onItemsSelected = (libraryModels: LibraryModel[]) => {
		this.setState({
			selectedLibraryModels: libraryModels,
		});
	};

	private onSetItemActive = (libraryModel: LibraryModel) => {
		this.setState({
			selectedLibraryModels: [libraryModel],
		});

		this.props.setActiveLibraryModel(libraryModel);
	};

	private onFileInputChange = async (file: File, keywords: string[]) => {
		const originalFileLocalUrl = await FileUtils.createURLFromFile(file);
		const originalGltf = await THREEUtils.loadGLTF(originalFileLocalUrl);

		URL.revokeObjectURL(originalFileLocalUrl);

		const simplifiedGltfAsBase64 = await THREEUtils.simplifyGltf(originalGltf);

		const fileSizeLimitInMB = 1;

		if (simplifiedGltfAsBase64.length > 1024 * 1024 * fileSizeLimitInMB * 1.33) {
			// base64 representations are roughly 33% larger than their original binary representations
			await WarningWindow.open(`Sorry, you can't upload glb files that are larger than ${fileSizeLimitInMB} MB`, "Error");
		} else {
			const thumbnailAsBase64 = await ImageUploadPreprocessor.getThumbnailFromGltf(simplifiedGltfAsBase64, "side");

			const createData = {
				fileName: file.name,
				file: simplifiedGltfAsBase64,
				keywords,
				thumbnail: thumbnailAsBase64,
				type: LibraryModelType.USER_UPLOADED_GLB,
				modelParameters: "",
			};

			try {
				const newLibraryModels = await this.props.transport.services.feature.create(createData, XyiconFeature.LibraryModel);
				const newLibraryModel = newLibraryModels[0] as LibraryModel;

				this.onSetItemActive(newLibraryModel);
			} catch (error) {
				notify(this.props.transport.appState.app.notificationContainer, {
					title: "Error",
					description: `LibraryModel not saved due to the following error: ${error}`,
					type: NotificationType.Error,
				});
			}
		}
	};

	private get actions() {
		return this.props.transport.appState.actions;
	}

	@computed
	private get libraryModels() {
		return this.actions.getList(XyiconFeature.LibraryModel) as LibraryModel[];
	}

	public override async componentDidMount() {
		await this.props.transport.services.feature.refreshList(XyiconFeature.LibraryModel);
		requestAnimationFrame(() => {
			this.setState({
				selectedLibraryModels: [this.props.activeLibraryModel],
			});
		});
	}

	public override render() {
		return (
			<div className="GeometrySelector hbox">
				<LibraryItems
					type="glb"
					libraryItems={[this.props.standard, ...this.libraryModels]}
					selectedLibraryItems={this.state.selectedLibraryModels}
					isDeleteSelectedDisabled={this.state.selectedLibraryModels.includes(this.props.standard)}
					onDeleteSelectedClick={this.onDeleteSelectedLibraryModelsClick}
					onDeleteClick={this.onDeleteLibraryModelClick}
					onFileInputChange={this.onFileInputChange}
					onItemsSelected={this.onItemsSelected}
					onSetItemActive={this.onSetItemActive}
				/>
				<ModelPreviewer libraryModel={this.props.activeLibraryModel} />
			</div>
		);
	}
}

import * as React from "react";
import type {SpaceTool} from "../../logic3d/features/tools/Tools";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {GestureDetectorReact} from "../../../../../interaction/GestureDetectorReact";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import type {Color} from "../../../../../../generated/api/base";
import {ColorUtils} from "../../../../../../utils/ColorUtils";

interface IToolData {
	readonly title: string; // the currently chosen submenuitem's title
	readonly icon: string; // the currently chosen submenuitem's icon
}

export interface IToolButtonProps extends IToolData {
	readonly active: boolean;
	readonly isBordered?: boolean;
	readonly toolId?: SpaceTool;
	readonly classNames?: string;
	readonly onClick: () => void;
	readonly color?: Color;
	readonly backgroundColor?: Color;
}

export class ToolButton extends React.Component<IToolButtonProps> {
	private onClick = (pointer: Pointer) => {
		this.props.onClick();
	};

	public override render() {
		const style: React.CSSProperties = {};
		const svgStyle: React.CSSProperties = {};
		const {icon, active, classNames, title, color, backgroundColor} = this.props;

		if (icon === "text") {
			style.padding = "14px";
		}

		if (backgroundColor) {
			style.backgroundColor = ColorUtils.hex2rgb(backgroundColor.hex, 1 - backgroundColor.transparency, "string") as string;
		}

		if (color) {
			svgStyle.fill = `#${color.hex}`;
		}

		return (
			<GestureDetectorReact
				onClick={this.onClick}
				key={this.props.toolId}
			>
				<div
					className={ReactUtils.cls(classNames || "", {
						btn: true,
						active,
						isBordered: this.props.isBordered,
					})}
					title={title}
					style={style}
				>
					<SVGIcon
						icon={icon}
						classNames={ReactUtils.cls({active: active && !this.props.isBordered})}
						style={svgStyle}
					/>
				</div>
			</GestureDetectorReact>
		);
	}
}

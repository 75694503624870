import * as React from "react";
import {observer} from "mobx-react";
import type {SpaceFile} from "../../../../../data/models/SpaceFile";
import type {View} from "../../../../../data/models/View";
import {XyiconFeature} from "../../../../../generated/api/base";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {CheckboxInputV5} from "../../../details/datatypes/CheckboxInputV5";
import {SaveToViewButtonV5} from "../../../abstract/view/SaveToViewButtonV5";
import {SpaceVersionSelectorV5} from "../SpaceVersionSelectorV5";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {LayerPanelStyled} from "./LayerPanel.styled";
import {LayerViewV5} from "./LayerViewV5";
import {MarkupColorFilterPanelV5} from "./MarkupColorFilterPanelV5";

export interface IOverlayPanelProps {
	readonly view: View;
	readonly spaceViewRenderer: SpaceViewRenderer;
}

interface ILayerPanelProps extends IOverlayPanelProps {
	readonly showNumbers: boolean;
}

export interface IOverlayPanelState {
	searchString: string;
}

@observer
export class LayerPanelV5 extends React.Component<ILayerPanelProps, IOverlayPanelState> {
	constructor(props: ILayerPanelProps) {
		super(props);

		this.state = {
			searchString: "",
		};
	}

	private get selectedSpaceEditorView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private get spaceEditorViewSettings() {
		return this.props.view.spaceEditorViewSettings;
	}

	private onBackgroundToggle = (toHidden: boolean) => {
		this.spaceEditorViewSettings.layers.background.isHidden = !toHidden;

		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.tileManager.container.visible = toHidden;
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onSpaceVersionChange = (spaceFile: SpaceFile) => {
		const space = spaceFile.parent;

		space.setSelectedSpaceFile(spaceFile);
		this.props.spaceViewRenderer.reloadTiles();
		this.forceUpdate();
	};

	private onSearchInputChange = (searchString: string) => {
		this.setState({
			searchString: searchString,
		});
	};

	private getSavedLayerSettings() {
		return this.props.view.getSavedSpaceEditorViewSettings()?.layers || this.props.view.getDefaultLayerSettings();
	}

	private getLayerSettings() {
		return this.props.view.spaceEditorViewSettings.layers;
	}

	private isResetButtonEnabled() {
		const layerSettings = this.getLayerSettings();

		return JSON.stringify(layerSettings) !== JSON.stringify(this.getSavedLayerSettings());
	}

	private onResetClick = () => {
		this.props.view.spaceEditorViewSettings.layers = this.getSavedLayerSettings();
		this.props.spaceViewRenderer.boundaryManager.onLayerSettingsModified();
		this.props.spaceViewRenderer.xyiconManager.onLayerSettingsModified();
		this.props.spaceViewRenderer.markupManager.onLayerSettingsModified();
		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.tileManager.container.visible = !this.props.view.spaceEditorViewSettings.layers.background.isHidden;
		}
	};

	public override componentDidMount() {
		const {view} = this.props;
		const spaceEditorViewSettings = JSON.stringify(view.spaceEditorViewSettings);

		view.applyData(view.data);
		view.setSpaceEditorViewSettings(JSON.parse(spaceEditorViewSettings));
	}

	public override render() {
		const {spaceViewRenderer} = this.props;

		const backgroundTitle = "Background Layer";

		const view = this.selectedSpaceEditorView;
		const hasChanges = view.hasUnsavedChanges("layers", false);

		return (
			<LayerPanelStyled>
				<div className="container">
					<div className="head">
						<SearchFieldV5
							className="SearchField"
							fullWidth
							value={this.state.searchString}
							onInput={this.onSearchInputChange}
						/>

						<div className="field hbox">
							{StringUtils.containsIgnoreCase(backgroundTitle, this.state.searchString) && (
								<>
									<div className="hbox checkbox">
										<CheckboxInputV5
											onChange={this.onBackgroundToggle}
											value={!this.spaceEditorViewSettings.layers.background.isHidden}
										/>
										<div className="title">{backgroundTitle}</div>
									</div>
									<SpaceVersionSelectorV5
										space={this.props.spaceViewRenderer.space}
										onVersionClick={this.onSpaceVersionChange}
									/>
								</>
							)}
						</div>
					</div>
					<div className="body">
						<ToggleContainerV5
							title="Boundaries"
							open={true}
						>
							<LayerViewV5
								view={this.props.view}
								searchString={this.state.searchString}
								spaceViewRenderer={spaceViewRenderer}
								showNumbers={this.props.showNumbers}
								activeSection="boundary"
							/>
						</ToggleContainerV5>
						<ToggleContainerV5
							title="Xyicons"
							open={true}
						>
							<LayerViewV5
								view={this.props.view}
								searchString={this.state.searchString}
								spaceViewRenderer={spaceViewRenderer}
								showNumbers={this.props.showNumbers}
								activeSection="xyicon"
							/>
						</ToggleContainerV5>
						<ToggleContainerV5
							title="Markups"
							open={true}
						>
							<MarkupColorFilterPanelV5
								spaceViewRenderer={spaceViewRenderer}
								view={this.props.view}
							/>
							<LayerViewV5
								view={this.props.view}
								searchString={this.state.searchString}
								spaceViewRenderer={spaceViewRenderer}
								showNumbers={this.props.showNumbers}
								activeSection="markup"
							/>
						</ToggleContainerV5>
					</div>
				</div>

				<div className="topButtons hbox">
					{hasChanges && (
						<ButtonV5
							type="secondary"
							label="Reset"
							disabled={!this.isResetButtonEnabled()}
							onClick={this.onResetClick}
						/>
					)}

					<SaveToViewButtonV5
						feature={XyiconFeature.SpaceEditor}
						viewChangeType="layers"
						className="borderButton"
						horizontalAlignment={HorizontalAlignment.right}
					/>
				</div>
			</LayerPanelStyled>
		);
	}
}

import {XyiconFeature} from "../../../generated/api/base";
import {featureTitles} from "../../../data/state/AppStateConstants";
import {ConfirmWindowV5} from "./ConfirmWindowV5";
import {ConfirmValueWindowV5} from "./ConfirmValueWindowV5";

export class PopupUtilsV5 {
	public static getDeleteConfirmationPopupV5(feature: XyiconFeature | XyiconFeature[], count: number) {
		if (Array.isArray(feature) || feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary || feature === XyiconFeature.Report) {
			return ConfirmWindowV5.open(`Are you sure you want to delete the selected ${count} item(s)?`);
		} else if (feature === XyiconFeature.Portfolio || feature === XyiconFeature.Space) {
			const featureName = featureTitles[feature] || "";
			const deleteModules = feature === XyiconFeature.Portfolio ? "Spaces, Xyicons, Boundaries and Markups" : "Xyicons, Boundaries and Markups";

			const popupText = `You are about to delete ${count === 1 ? `a ${featureName} item` : `${count} ${featureName} items`}. Deleting the selected 
				${count === 1 ? `${featureName} will also delete all the ${deleteModules} in this ${featureName}` : `${featureName}s will also delete all the ${deleteModules} in these ${featureName}s`}.<br><br>
				Use the text field below to confirm the number of items you want to delete.
				<br><br>
				Number of ${featureName} items to delete:<br>`;

			return ConfirmValueWindowV5.open(popupText, count);
		} else if (XyiconFeature.PermissionSet) {
			const featureName = featureTitles[feature] || "";

			const popupText = `You are about to delete ${count === 1 ? `a${featureName === "" ? "n" : ""} ${featureName} item` : `${count} ${featureName} items`}.<br>
				Use the text field below to confirm the number of items you want to delete.
				<br><br>
				Number of ${featureName} items to delete:<br>`;

			const title = "Please Confirm";

			return ConfirmValueWindowV5.open(popupText, count, title);
		} else {
			const featureName = featureTitles[feature] || "";

			const popupText = `You are about to delete ${count === 1 ? `a${featureName === "" ? "n" : ""} ${featureName} item` : `${count} ${featureName} items`}.<br>
				Use the text field below to confirm the number of items you want to delete.
				<br><br>
				Number of ${featureName} items to delete:<br>`;

			return ConfirmValueWindowV5.open(popupText, count);
		}
	}

	public static getFormulaChangeConfirmationPopupV5(feature: XyiconFeature) {
		const featureName = feature === XyiconFeature.Boundary ? "Boundaries" : `${featureTitles[feature]}s`;
		const popupText = `Changing the formula will update all effected ${featureName}. Do you wish to proceed?`;

		return ConfirmWindowV5.open(popupText, "Please Confirm", {ok: "Yes", cancel: "No"});
	}

	public static getFormulaDeleteConfirmationPopupV5(feature: XyiconFeature) {
		const featureName = feature === XyiconFeature.Boundary ? "Boundaries" : `${featureTitles[feature]}s`;
		const popupText = `Disabling the formula will clear related data from all ${featureName}`;

		return ConfirmWindowV5.open(popupText, "Please Confirm", {ok: "Yes", cancel: "No"});
	}

	public static getDeleteUserPopupV5(count: number) {
		const title = "Delete users from Organization";
		const popupText = `You are about to delete the selected users. Type ${count} to delete`;
		return ConfirmValueWindowV5.open(popupText, count, title);
	}

	public static getBreakLinksConfirmationPopupV5(count: number) {
		const title = "Confirm Link Break";
		const text = `You are about to break links with ${count} xyicons. Do you wish to continue?`;

		return ConfirmWindowV5.open(text, title, {ok: "Yes", cancel: "No"});
	}
}

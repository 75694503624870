import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SelectSliderV5} from "../../../details/SelectSliderV5";

const FieldPermissionsStyled = styled.div`
	.container {
		padding: 16px;

		&.paddingTop {
			padding-top: 98px;
		}

		h4 {
			font-size: 18px;
			margin-bottom: 20px;
		}

		.SearchField {
			width: 360px;
			margin-bottom: 30px;
		}
	}
`;

interface IFieldPermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IFieldPermissionState {
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@observer
export class FieldPermissionsV5 extends React.Component<IFieldPermissionsProps, IFieldPermissionState> {
	constructor(props: IFieldPermissionsProps) {
		super(props);
		this.state = {
			searchQuery: "",
		};
	}

	public override render() {
		const {permissionSet, feature, appState, transport} = this.props;
		const {searchQuery} = this.state;
		const rows = appState.fields[feature].slice().sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));

		return (
			<FieldPermissionsStyled>
				<div className={ReactUtils.cls("vbox container", {paddingTop: feature !== XyiconFeature.Portfolio})}>
					<h4>Field Permissions</h4>
					<SearchFieldV5
						className="findInput"
						value={searchQuery}
						onInput={(value) => this.setState({searchQuery: value})}
					/>
					<SelectSliderV5
						options={[
							{
								id: Permission.None,
								label: "Hide",
							},
							{
								id: Permission.View,
								label: "Read Only",
							},
							{
								id: Permission.Update,
								label: "Write",
							},
						]}
						rows={rows
							.filter((field) => StringUtils.containsIgnoreCase(field.name, searchQuery))
							.map((field) => {
								return {
									label: field.name,
									value:
										feature === XyiconFeature.Portfolio
											? permissionSet.getPortfolioFieldPermission(field.refId)
											: permissionSet.getFieldPermission(field.refId, feature),
								};
							})}
						onChange={(rowLabels: string[], value) => {
							rowLabels.forEach((label) => {
								const field = rows.find((row) => row.name === label);

								if (field) {
									if (feature === XyiconFeature.Portfolio) {
										permissionSet.setPortfolioFieldPermission(field.refId, value as Permission);
									} else {
										permissionSet.setFieldPermission(feature, field.refId, value as Permission);
									}
								}
							});

							transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
						}}
					/>
				</div>
			</FieldPermissionsStyled>
		);
	}
}

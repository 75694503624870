import * as React from "react";
import {observer} from "mobx-react";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ButtonV5} from "../../../button/ButtonV5";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {SaveToViewButtonV5} from "../../../abstract/view/SaveToViewButtonV5";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {ConditionalFormattingPanel} from "./ConditionalFormattingPanel.styled";
import type {IOverlayPanelProps, IOverlayPanelState} from "./LayersPanelV5";
import {ColorRulesV5} from "./ColorRulesV5";
import {InteractiveToggleContainerV5} from "./InteractiveToggleContainerV5";

@observer
export class ConditionalFormattingPanelV5 extends React.Component<IOverlayPanelProps, IOverlayPanelState> {
	constructor(props: IOverlayPanelProps) {
		super(props);

		this.state = {
			searchString: "",
		};
	}

	private get boundaryManager() {
		return this.props.spaceViewRenderer.boundaryManager;
	}

	private get xyiconManager() {
		return this.props.spaceViewRenderer.xyiconManager;
	}

	private onToggleColorRulesForBoundaries = () => {
		this.boundaryManager.formattingRules.enabled = !this.boundaryManager.formattingRules.enabled;
		this.boundaryManager.onFormattingRulesModified();
	};

	private onToggleColorRulesForXyicons = () => {
		this.xyiconManager.formattingRules.enabled = !this.xyiconManager.formattingRules.enabled;
		this.xyiconManager.onFormattingRulesModified();
	};

	private getSavedFormattingRules() {
		return this.props.view.getSavedSpaceEditorViewSettings()?.formattingRules || this.props.view.getDefaultFormattingRules();
	}

	private getFormattingRules() {
		return this.props.view.spaceEditorViewSettings.formattingRules;
	}

	private isResetButtonEnabled() {
		const layerSettings = this.getFormattingRules();

		return JSON.stringify(layerSettings) !== JSON.stringify(this.getSavedFormattingRules());
	}

	private onResetClick = () => {
		this.props.view.spaceEditorViewSettings.formattingRules = this.getSavedFormattingRules();
		this.props.spaceViewRenderer.boundaryManager.onFormattingRulesModified();
		this.props.spaceViewRenderer.xyiconManager.onFormattingRulesModified();
	};

	private get selectedSpaceEditorView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	public override render() {
		const view = this.selectedSpaceEditorView;
		const hasChanges = view.hasUnsavedChanges("conditional formatting", false);

		return (
			<ConditionalFormattingPanel>
				<div className="container">
					<div className="head">
						<SearchFieldV5
							className="SearchField"
							fullWidth
							value={this.state.searchString}
							onInput={(searchString: string) => this.setState({searchString: searchString})}
						/>
					</div>
					<div className="body">
						<InteractiveToggleContainerV5
							title="Boundaries"
							open={true}
							isEnabled={this.boundaryManager.formattingRules.enabled}
							onEnabledChange={this.onToggleColorRulesForBoundaries}
						>
							<ColorRulesV5
								spaceViewRenderer={this.props.spaceViewRenderer}
								activeSection="boundary"
								view={this.props.view}
								searchString={this.state.searchString}
							/>
						</InteractiveToggleContainerV5>
						<InteractiveToggleContainerV5
							title="Xyicons"
							open={true}
							isEnabled={this.xyiconManager.formattingRules.enabled}
							onEnabledChange={this.onToggleColorRulesForXyicons}
						>
							<ColorRulesV5
								spaceViewRenderer={this.props.spaceViewRenderer}
								activeSection="xyicon"
								view={this.props.view}
								searchString={this.state.searchString}
							/>
						</InteractiveToggleContainerV5>
					</div>
				</div>
				<div className="topButtons hbox">
					{hasChanges && (
						<ButtonV5
							type="secondary"
							label="Reset"
							disabled={!this.isResetButtonEnabled()}
							onClick={this.onResetClick}
						/>
					)}

					<SaveToViewButtonV5
						feature={XyiconFeature.SpaceEditor}
						viewChangeType="conditional formatting"
						className="borderButton"
						horizontalAlignment={HorizontalAlignment.right}
					/>
				</div>
			</ConditionalFormattingPanel>
		);
	}
}

import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import {InfoBubble} from "../../modules/abstract/common/infobutton/InfoBubble";

interface IContextOptionsProps {
	readonly className?: string;
	readonly style?: React.CSSProperties;
	readonly disabled?: boolean;
	readonly options: IContextOption[];
	readonly onSelect?: () => void;
	readonly divRef?: React.RefObject<HTMLDivElement>;
}

interface IContextOptionsState {
	itemIndex: number;
}

export interface IContextOption {
	label: string;
	onSelect: () => void;
	icon?: string;
	disabled?: boolean;
	infoTextWhenDisabled?: string;
	selected?: boolean;
	delay?: number;
}

export class ContextOptions extends React.PureComponent<IContextOptionsProps, IContextOptionsState> {
	private _timeOut: number = null;
	private _isMounted: boolean = null;

	constructor(props: IContextOptionsProps) {
		super(props);
		this.state = {
			itemIndex: -1,
		};
	}

	public override componentDidMount(): void {
		this._isMounted = true;
	}

	public override componentWillUnmount(): void {
		this._isMounted = false;
	}

	private onClick = (option: IContextOption, event: React.MouseEvent) => {
		if (!option.disabled) {
			event.stopPropagation();

			if (option.onSelect) {
				option.onSelect();
			}
			if (this.props.onSelect) {
				this.props.onSelect();
			}
		}
	};

	private onMouseOver = (itemIndex: number) => {
		if (this._timeOut) {
			clearTimeout(this._timeOut);
		}

		this._timeOut = window.setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					itemIndex,
				});
			}
		}, this.props.options[itemIndex]?.delay ?? 1000);
	};

	private onMouseLeave = () => {
		clearTimeout(this._timeOut);

		if (this._isMounted) {
			this.setState({
				itemIndex: -1,
			});
		}
	};

	public override render() {
		const {className, style, options} = this.props;

		return (
			<div
				style={style || null}
				className={ReactUtils.cls(className || "ContextOptions", {
					disabled: this.props.disabled,
				})}
				ref={this.props.divRef}
			>
				{options.map((option, index) => (
					<div
						key={index}
						className={ReactUtils.cls("option", {disabled: option.disabled, selected: option.selected})}
						onClick={(event) => this.onClick(option, event)}
						onMouseOver={() => this.onMouseOver(index)}
						onMouseLeave={this.onMouseLeave}
					>
						{option.disabled && this.state.itemIndex === index && option.infoTextWhenDisabled && <InfoBubble content={option.infoTextWhenDisabled} />}
						{option.label}
					</div>
				))}
			</div>
		);
	}
}

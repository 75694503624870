import {useAppStore} from "../../../StateManager";
import type {View} from "../../../data/models/View";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {UserGroup} from "../../../data/models/UserGroup";
import type {User} from "../../../data/models/User";
import {SharingPopupV5} from "./SharingPopupV5";
import {getLabelFromViewReportOrViewFolder} from "./SharingUtils";

interface IViewSharingPopupProps {
	readonly view: View;
	readonly onClose: () => void;
}

export const ViewSharingPopup = (props: IViewSharingPopupProps) => {
	const {view, onClose} = props;
	const appState = useAppStore((state) => state.appState);

	const onSaveChanges = async () => {
		view.applyData(view.getData());
		await appState.app.transport.services.view.update(view.getData());
		const selectedView = appState.app.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);

		if (selectedView?.id === view.id) {
			appState.actions.onViewSelected();
		}
	};

	const onAddClick = (id: string, permission: Permission.View | Permission.Update, feature: XyiconFeature.User | XyiconFeature.UserGroup) => {
		const item = appState.actions.getFeatureItemById<User | UserGroup>(id, feature);
		const sharingSettings = view.viewSharingSettings || [];
		const permissionType = permission == Permission.Update;

		if (feature === XyiconFeature.User) {
			sharingSettings.push({
				userID: item.id,
				userGroupID: null,
				canEditSharedView: permissionType,
			});
		} else if (feature === XyiconFeature.UserGroup) {
			sharingSettings.push({
				userID: null,
				userGroupID: item.id,
				canEditSharedView: permissionType,
			});
		}
	};

	const onDeleteUserGroup = (userGroup: UserGroup) => {
		const sharingSettings = view.viewSharingSettings;
		const index = sharingSettings?.findIndex((sh) => sh.userGroupID === userGroup.id);

		if (index >= 0) {
			sharingSettings.splice(index, 1);
		}

		return onSaveChanges();
	};

	const onDeleteUser = (user: User) => {
		const sharingSettings = view.viewSharingSettings;
		const index = sharingSettings?.findIndex((sh) => sh.userID === user.id);

		if (index >= 0) {
			sharingSettings.splice(index, 1);
		}

		const promiseSave = onSaveChanges();

		if (user.id === appState.user.id) {
			appState.actions.selectViewById(appState.actions.getViews(view.itemFeature)[0].id);
			onClose();
		}

		return promiseSave;
	};

	const onConfirmOwnershipChange = (sharing: ViewSharingSettingsData) => {
		const tempOwnerId = view.ownedBy;

		// set new view owner
		view.ownedBy = sharing.userID;

		// set editing permission to old owner
		const oldOwner = view.viewSharingSettings.find((sharing) => sharing.userID === tempOwnerId);

		if (oldOwner) {
			oldOwner.canEditSharedView = true;
		} else {
			const tempOwner = appState.actions.findUser(tempOwnerId);

			view.viewSharingSettings.push({
				userID: tempOwner.id,
				userGroupID: null,
				canEditSharedView: true,
			});
		}
	};

	const onSetUserGroupPermission = (permission: number, sharing: ViewSharingSettingsData) => {
		sharing.canEditSharedView = permission === Permission.Update;

		return onSaveChanges();
	};

	return (
		<SharingPopupV5
			onAddClick={onAddClick}
			onDeleteUserGroup={onDeleteUserGroup}
			onDeleteUser={onDeleteUser}
			onConfirmOwnershipChange={onConfirmOwnershipChange}
			setUserGroupPermission={onSetUserGroupPermission}
			onSaveChanges={onSaveChanges}
			ownedByUserId={view.ownedBy}
			itemLabel={getLabelFromViewReportOrViewFolder(view)}
			itemName={view.name}
			onClose={onClose}
			sharingSettings={view.viewSharingSettings}
			showShareOption={!view.isSystem}
		/>
	);
};

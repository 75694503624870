import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {App} from "../../../App";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import {XyiconFeature} from "../../../generated/api/base";
import type {IModel} from "../../../data/models/Model";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Boundary} from "../../../data/models/Boundary";
import type {Space} from "../../../data/models/Space";
import type {DocumentModel} from "../../../data/models/DocumentModel";
import type {Xyicon} from "../../../data/models/Xyicon";
import type {Catalog} from "../../../data/models/Catalog";
import type {Markup} from "../../../data/models/Markup";
import type {Markup3D} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {filterModels} from "../../../data/models/filter/Filter";
import {featureTitles} from "../../../data/state/AppStateConstants";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {NotificationType} from "../popup/NotificationV5";
import {notify} from "../../../utils/Notify";
import {BoundaryUtils} from "../../../data/models/BoundaryUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {BoundarySpaceMapSelectorV5} from "../spaceeditor/BoundarySpaceMapSelectorV5";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import InfoIcon from "../icons/circle-information.svg?react";
import PinIcon from "../icons/location-pin.svg?react";
import GridIcon from "../icons/list.svg?react";
import CardIcon from "../icons/card-layout.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {colorPalette} from "../styles/colorPalette";
import {ELLIPSIS, FlexCenterStyle, VerticalFlexStyle, fontSize, fontWeight, radius} from "../styles/styles";
import {CardLayoutToolTipV5} from "./CardLayoutToolTipV5";

interface ISearchFeatureBlockProps {
	readonly app?: App;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly feature: XyiconFeature;
	readonly featureItems: IModel[];
	readonly menuLabel: string;
	readonly queryString: string;
	readonly filteredLength: number;
	readonly itemsNumberWhenCollapsed: number;
	readonly itemsNumberWhenExpanded: number;
	readonly expanded: boolean;
	readonly startExpandedMode: (feature: XyiconFeature) => void;
	readonly closeSearchInput: () => void;
	readonly closePortfolioWideSearch: () => void;
}

interface ISearchFeatureBlockState {
	detailedBoundary: Boundary | null; // show spacemaps for this boundary (if not null)
	itemWithToolTipIndex: number; // -1 means tooltip shouldn't be displayed
	toolTipTransform: TransformObj | null;
}

@inject("app")
@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class SearchFeatureBlockV5 extends React.Component<ISearchFeatureBlockProps, ISearchFeatureBlockState> {
	private parentRefArray: HTMLDivElement[] = [];
	private _cardLayoutToolTipRef = React.createRef<HTMLDivElement>();
	private _timeoutId: number = null;
	private _block = React.createRef<HTMLDivElement>();

	constructor(props: ISearchFeatureBlockProps) {
		super(props);

		this.state = {
			detailedBoundary: null,
			itemWithToolTipIndex: -1,
			toolTipTransform: null,
		};
	}

	private onShowBoundarySpaceMaps = (boundary: Boundary) => {
		this.setState({
			detailedBoundary: boundary,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			detailedBoundary: null,
		});
	};

	private getIcon(featureItem: IModel) {
		const {feature} = this.props;

		if (feature === XyiconFeature.Space) {
			const item = featureItem as Space;

			return <img src={item.thumbnailFileURL} />;
		} else if ([XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(feature)) {
			const item = featureItem as DocumentModel;

			return <img src={this.props.transport.services.document.getThumbnailPath(item)} />;
		} else {
			const item = featureItem as Xyicon | Catalog | Boundary | Markup;

			return (
				<img
					src={item.thumbnail}
					style={{transform: (item as Xyicon).backgroundTransform || ""}}
				/>
			);
		}
	}

	private onPinClick = async (item: Boundary | Xyicon | Markup) => {
		this.props.closeSearchInput();
		const {appState, feature, app} = this.props;
		const spaceEditorItems = appState.actions.getList(feature);
		const selectedView = appState.actions.getSelectedView(XyiconFeature.SpaceEditor);

		if (item.ownFeature === XyiconFeature.Markup) {
			const isMarkupOnMountedSpace = app.spaceViewRenderer.space?.id === item.spaceId;
			const markup3D = isMarkupOnMountedSpace
				? (app.spaceViewRenderer.markupManager.getItemById(item.id) as Markup3D)
				: app.spaceViewRenderer.markupManager.createSpaceItem3DFromModel(item);

			if (!markup3D.isVisible) {
				markup3D.showLayerWithNotification();
			}
		} else {
			const filteredItems = filterModels(spaceEditorItems, selectedView.filters, appState, XyiconFeature.SpaceEditor);

			if (!filteredItems.includes(item as unknown as IModel)) {
				const name = featureTitles[feature];
				const nameWithLowerCase = StringUtils.decapitalize(name);

				notify(app.notificationContainer, {
					lifeTime: Infinity,
					type: NotificationType.Warning,
					title: `${name} is not visible in the active view.`,
					description: `The view's filter prevents the ${nameWithLowerCase} from displaying on the space. To make the ${nameWithLowerCase} visible, switch to another view or edit the active filter. Click the Edit Details button to update the ${nameWithLowerCase}'s fields.`,
					buttonLabel: "Edit Details",
					onActionButtonClick: () => app.spaceViewRenderer.inheritedMethods.selectItems([item], true),
				});
			}

			if (BoundaryUtils.doesHaveMultipleSpaceMaps(item)) {
				this.onShowBoundarySpaceMaps(item as Boundary);
				return;
			}
		}

		await appState.actions.navigateToSpaceItem(item, true);
		// this.props.closeSearchInput();
		app.spaceViewRenderer.inheritedMethods.selectItems([item], true);
	};

	private goToTableList(item: IModel) {
		const {navigation, app, feature, closeSearchInput} = this.props;

		const nav = navigation.menus.find((menu) => menu.feature === feature).nav;

		navigation.goApp(nav);

		// This should be after the navigation, otherwise you can see the previous module for a split second
		closeSearchInput();

		// setTimeout needed because moduleview might not exist yet
		setTimeout(() => {
			app.moduleViews[feature]?.selectItem(item);
		}, 200);
	}

	private getGoToTableListButton(item: IModel, listMode: "grid" | "card") {
		return (
			<IconButtonV5
				IconComponent={listMode === "grid" ? GridIcon : CardIcon}
				onClick={(event) => {
					event.stopPropagation(); // Don't select details
					this.goToTableList(item);
				}}
			/>
		);
	}

	private getPinButton(item: IModel) {
		return (
			<IconButtonV5
				IconComponent={PinIcon}
				onClick={(event: React.MouseEvent<HTMLDivElement>) => {
					event.stopPropagation(); // don't open the details panel when pin is clicked
					this.onPinClick(item as Xyicon | Boundary);
				}}
			/>
		);
	}

	private renderCustomRow(data: {key: string; value: string}) {
		const {queryString} = this.props;

		let html = "";

		if (data.value !== "") {
			html = data.key + (data.key !== "" ? ": " : "") + StringUtils.regexHighlight(data.value.toString(), queryString);
		}

		return (
			<div
				className="field"
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		);
	}

	private onExpand = () => {
		this.props.startExpandedMode(this.props.feature);
	};

	private isUnplotted(item: IModel) {
		return item.ownFeature === XyiconFeature.Xyicon && (item as Xyicon).isUnplotted;
	}

	private isEmbedded(item: IModel) {
		return item.ownFeature === XyiconFeature.Xyicon && (item as Xyicon).isEmbedded;
	}

	private onMouseOverItem = (itemIndex: number, item: IModel) => {
		clearTimeout(this._timeoutId);

		if (![XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(item.ownFeature)) {
			this._timeoutId = window.setTimeout(() => {
				if (itemIndex !== this.state.itemWithToolTipIndex) {
					this.setState({
						itemWithToolTipIndex: itemIndex,
					});
				}
			}, 1000);
		}
	};

	private onMouseLeaveItem = () => {
		clearTimeout(this._timeoutId);

		if (this.state.itemWithToolTipIndex !== -1) {
			this.setState({
				itemWithToolTipIndex: -1,
			});
		}
	};

	public override componentDidUpdate(prevProps: ISearchFeatureBlockProps, prevState: ISearchFeatureBlockState) {
		if (
			prevState.itemWithToolTipIndex === -1 &&
			this.state.itemWithToolTipIndex !== -1 &&
			this.props.feature !== XyiconFeature.Markup &&
			this.parentRefArray[this.state.itemWithToolTipIndex] &&
			this._cardLayoutToolTipRef.current
		) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this.parentRefArray[this.state.itemWithToolTipIndex],
					this._cardLayoutToolTipRef.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	}

	public override render() {
		const {
			featureItems,
			feature,
			expanded,
			itemsNumberWhenCollapsed,
			itemsNumberWhenExpanded,
			filteredLength,
			menuLabel,
			queryString,
			appState,
			app,
		} = this.props;

		const floatingElement = this._cardLayoutToolTipRef.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: `translate(${this.parentRefArray[this.state.itemWithToolTipIndex]?.getBoundingClientRect().x}px, ${this.state.toolTipTransform?.y + 10}px)`,
			maxWidth: this.parentRefArray[0]?.offsetWidth,
		};

		return (
			<SearchFeatureBlockStyled
				className={`SearchFeatureBlock ${featureTitles[feature]}`}
				data-featureid={feature}
				ref={this._block}
			>
				<h3 data-featureid={feature}>{menuLabel}</h3>
				<div className="items">
					{featureItems.map((item: IModel, index) => {
						const rows = appState.actions.getRowsForCardLayout(queryString, item);

						return (
							<div
								ref={(parentRef) => (this.parentRefArray[index] = parentRef)}
								className="moduleItem"
								key={item?.id ?? index}
								onClick={() => {
									let itemToOpen = item;
									let closeEverythingButDocuments = false;

									if ([XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument, XyiconFeature.Document].includes(feature)) {
										itemToOpen = (item as DocumentModel).parentElementMaybe;
										if (itemToOpen) {
											closeEverythingButDocuments = true;
										}
									}

									if (itemToOpen) {
										if (closeEverythingButDocuments) {
											appState.isDetailsTabBeingOpenByDocumentOfItem = true;
										}
										app.onDetailsClick(itemToOpen);
									}
								}}
								onMouseOver={() => this.onMouseOverItem(index, item)}
								onMouseLeave={this.onMouseLeaveItem}
							>
								<div className="icon">{this.getIcon(item)}</div>
								<div className="fields">
									<div
										className={ReactUtils.cls("featureId", {
											unplotted: this.isUnplotted(item),
											embedded: this.isEmbedded(item),
										})}
									>
										{this.renderCustomRow(rows[0])}
									</div>
									{this.renderCustomRow(rows[1])}
									{this.renderCustomRow(rows[2])}
									{this.state.detailedBoundary === item && (
										<BoundarySpaceMapSelectorV5
											item={item as Boundary}
											onClose={this.onHideBoundarySpaceMaps}
											closePortfolioWideSearch={this.props.closePortfolioWideSearch}
										/>
									)}
								</div>
								<div className="hoverButtons hbox">
									<IconButtonV5
										IconComponent={InfoIcon}
										onClick={Functions.emptyFunction}
									/>
									{([XyiconFeature.Boundary, XyiconFeature.Markup].includes(feature) ||
										(item.ownFeature === XyiconFeature.Xyicon && !this.isUnplotted(item))) &&
										this.getPinButton(item)}
									{![XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument, XyiconFeature.Markup].includes(
										feature,
									) && this.getGoToTableListButton(item, feature === XyiconFeature.Space ? "card" : "grid")}
								</div>
								{this.state.itemWithToolTipIndex === index &&
									[XyiconFeature.Space, XyiconFeature.XyiconCatalog, XyiconFeature.Xyicon, XyiconFeature.Boundary].includes(item.ownFeature) && (
										<DomPortal destination={app.modalContainer}>
											<CardLayoutToolTipV5
												item={item as Xyicon}
												divRef={this._cardLayoutToolTipRef}
												style={inlineStyle}
												className={ReactUtils.cls({left: this.state.toolTipTransform?.horizontal === HorizontalAlignment.left})}
												queryString={queryString}
											/>
										</DomPortal>
									)}
							</div>
						);
					})}
				</div>
				{
					<InfoBox>
						<p>
							Showing
							<span className="highLighted">
								{!expanded
									? ` ${filteredLength <= itemsNumberWhenCollapsed ? filteredLength : itemsNumberWhenCollapsed}`
									: ` ${filteredLength <= itemsNumberWhenExpanded ? filteredLength : itemsNumberWhenExpanded}`}
							</span>
							{filteredLength >= itemsNumberWhenCollapsed && (
								<span>
									{" "}
									of <span className="highLighted">{filteredLength}</span>
								</span>
							)}
						</p>
						{!expanded && filteredLength > itemsNumberWhenCollapsed && (
							<LinkButton
								onClick={this.onExpand}
								title="Show More"
							>
								Show More
							</LinkButton>
						)}
					</InfoBox>
				}
			</SearchFeatureBlockStyled>
		);
	}
}

const SearchFeatureBlockStyled = styled.div`
	${VerticalFlexStyle};
	gap: 8px;
	border-radius: ${radius.md};
	border: solid 1px transparent;
	padding: 8px;

	&:hover {
		border-color: ${colorPalette.gray.c300};
	}

	h3 {
		font-size: 24px;
		font-weight: ${fontWeight.bold};
	}

	.items {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

		.moduleItem {
			${FlexCenterStyle};
			gap: 8px;
			position: relative;
			padding: 8px;
			border-radius: ${radius.sm};
			cursor: pointer;

			&:hover {
				background-color: ${colorPalette.gray.c200Light};

				.hoverButtons {
					display: flex;
					align-items: center;

					.IconButton {
						box-sizing: border-box;
						border: solid 2px transparent;
						transition: ease-in-out 0.2s border-color;

						&:hover {
							border-color: ${colorPalette.gray.c950};
						}
					}
				}
			}

			.icon {
				width: 40px;
				min-width: 40px;
				height: 40px;

				img {
					width: 100%;
				}
			}

			.Initials {
				width: 40px;
				height: 40px;
				font-size: ${fontSize.lg};
				top: auto;
				right: auto;
				position: relative;
			}

			.fields {
				flex: 1;
				display: grid;
				gap: 2px;
				color: ${colorPalette.gray.c700Dark};

				b {
					color: ${colorPalette.gray.c950};
					font-weight: normal;
				}

				.featureId {
					${ELLIPSIS}

					.field {
						display: inline;
						font-size: ${fontSize.md};
						color: ${colorPalette.gray.c950};
					}

					&.unplotted {
						&::after {
							content: "(Unplotted)";
							font-weight: 200;
							margin-left: 4px;
							font-size: ${fontSize.md};
						}
					}

					&.embedded {
						&::after {
							content: "(Embedded)";
							font-weight: 200;
							margin-left: 4px;
							font-size: ${fontSize.md};
						}
					}
				}

				.field {
					font-size: ${fontSize.sm};
					${ELLIPSIS}
				}
			}

			.hoverButtons {
				background: ${colorPalette.gray.c200Light};
				display: none;
			}
		}
	}

	&.Document {
		.moduleItem:hover {
			border-color: ${colorPalette.gray.c200Light};
			cursor: default;
		}
	}
`;

const InfoBox = styled.div`
	${FlexCenterStyle};
	justify-content: flex-end;
	gap: 16px;

	p {
		font-size: ${fontSize.md};
	}
`;

const LinkButton = styled.div`
	color: ${colorPalette.primary.c500Primary};
	font-weight: ${fontWeight.bold};
	text-decoration: underline;
	cursor: pointer;
`;

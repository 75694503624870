import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IViewColumn, IViewSort} from "../../../../data/models/ViewUtils";
import {SortDirection} from "../../../../data/models/ViewUtils";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {fieldEquals} from "../../../../data/models/field/FieldUtils";
import type {AppState} from "../../../../data/state/AppState";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {compareTableValues, toggleTableSort} from "../../../widgets/table/TableUtils";
import {GridActionBarV5} from "../GridActionBarV5";
import type {IModel} from "../../../../data/models/Model";
import type {TypeFieldKind} from "../../../../data/services/TypeAndFieldServiceUtils";
import type {IActionBarItem} from "../ModuleViewV5";
import {getActionBarButtonsForTypeAndField} from "../../modules/ActionBarUtils";
import {TableV5} from "./TableV5";
import type {ITableProps} from "./TableV5";

interface IHeader {
	id: string;
	title: string;
}

interface ISimpleTableV5Props<T> extends Partial<ITableProps<T>> {
	readonly headers: IHeader[];
	readonly defaultSort?: IViewSort;
	readonly appState?: AppState;
	readonly tableSearchQuery?: string;
	readonly errorMessage?: string;
	readonly isItemUnfocused?: boolean;
	readonly kind?: TypeFieldKind;
}

interface ISimpleTableV5State {
	headerWidths: {[id: string]: number};
	sort: IViewSort;
}

@inject("appState")
@observer
export class SimpleTableV5<T> extends React.Component<ISimpleTableV5Props<T>, ISimpleTableV5State> {
	private _table = React.createRef<TableV5<T>>();

	constructor(props: ISimpleTableV5Props<T>) {
		super(props);
		this.state = {
			headerWidths: {},
			sort: props.defaultSort || {
				column: null,
				direction: SortDirection.ASC,
			},
		};
	}

	private getHeaders = () => {
		return this.props.headers.map(
			(header, index): Partial<IViewColumn> => ({
				title: header.title,
				width: this.state.headerWidths[header.title] || 180,
				field: header.id,
			}),
		);
	};

	private onHeaderWidthChange = (index: number, width: number, header: Partial<IViewColumn>) => {
		const newHeaderWidths = {...this.state.headerWidths};

		newHeaderWidths[header.title] = width;
		this.setState({headerWidths: newHeaderWidths});
	};

	private onToggleSort = (field: IFieldPointer) => {
		this.setState({sort: toggleTableSort(this.state.sort, field)});
	};

	private onSortAsc = (field: IFieldPointer) => {
		this.setState({sort: {column: field, direction: SortDirection.ASC}});
	};

	private onSortDesc = (field: IFieldPointer) => {
		this.setState({sort: {column: field, direction: SortDirection.DESC}});
	};

	private onClearSort = (field: IFieldPointer) => {
		this.setState({sort: {column: field, direction: null}});
	};

	private getFilteredData = (items: T[]) => {
		const {tableSearchQuery, getFields} = this.props;
		let filteredData = items;

		if (tableSearchQuery) {
			const searchStr = tableSearchQuery.toLowerCase();

			filteredData = filteredData.filter((item) => {
				const fields = getFields(item);

				for (const field of fields) {
					const fieldStr = field?.toString();

					if (StringUtils.containsIgnoreCase(fieldStr, searchStr)) {
						return true;
					}
				}
				return false;
			});
		}
		return filteredData;
	};

	public sortData(data: any[]) {
		const sort = this.state.sort;

		if (sort.column) {
			// TODO header.field? This should be field independent
			const headers = this.getHeaders();
			const index = headers.findIndex((header) => fieldEquals(header.field, sort.column));

			const isRefId = headers[index]?.field === "refId";

			if (isRefId) {
				return data.slice().sort((row1, row2) => {
					const value1 = StringUtils.refId2Number(this.props.getFields(row1)[index] as string);
					const value2 = StringUtils.refId2Number(this.props.getFields(row2)[index] as string);

					return sort.direction * compareTableValues(value1, value2);
				});
			} else {
				return data.slice().sort((row1, row2) => {
					const value1 = this.props.getFields(row1)[index];
					const value2 = this.props.getFields(row2)[index];

					return sort.direction * compareTableValues(value1, value2);
				});
			}
		}
		return data;
	}

	public goToItem(item: T) {
		this._table.current?.goToItem(item);
	}

	private clearFocusAndSelection = () => {
		this.props.onSelect([]);
		this.props.onFocus?.([]);
	};

	private get actionBarButtons(): IActionBarItem<IModel>[] {
		return getActionBarButtonsForTypeAndField(this.props.kind, this.props.feature, this.props.appState, this.clearFocusAndSelection);
	}

	private onActionBarDefaultClick = (item: IActionBarItem<IModel>) => {
		if (item.onClick) {
			item.onClick(this.props.selected as any);
		} else {
			console.warn("No handler and default onClick for actionBar", item);
		}
	};

	private onCloseGridActionBar = () => {
		return this.props.onSelect([]);
	};

	public override render() {
		const props = this.props as ITableProps<T>;
		const {actionBarButtons} = this;
		const {selected, feature} = props;
		const data = this.sortData(props.data);

		return (
			<>
				<TableV5
					ref={this._table as any}
					{...props}
					data={data}
					getHeaders={this.getHeaders}
					onHeaderWidthChange={this.onHeaderWidthChange}
					sorts={[this.state.sort]}
					onToggleSort={this.onToggleSort}
					moduleName={this.props.appState.selectedModuleTitle}
					searchedData={this.getFilteredData(data)}
					search={this.props.tableSearchQuery}
					onSortAsc={this.onSortAsc}
					onSortDesc={this.onSortDesc}
					onClearSort={this.onClearSort}
					isItemUnfocused={props.isItemUnfocused}
					isSimpleTable={true}
				/>
				{selected.length > 0 && actionBarButtons?.length > 0 && (
					<GridActionBarV5
						items={selected as IModel[]}
						feature={feature}
						buttons={actionBarButtons}
						defaultClick={this.onActionBarDefaultClick}
						onClose={this.onCloseGridActionBar}
						onSelect={this.props.onSelect as (items: IModel[]) => any}
					/>
				)}
			</>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SimpleTable} from "../../widgets/table/SimpleTable";
import type {View} from "../../../data/models/View";
import {SortDirection} from "../../../data/models/ViewUtils";
import {Splitter} from "../../widgets/splitter/Splitter";
import {DateFormatter} from "../../../utils/format/DateFormatter";
import {TabChild} from "../../widgets/tab/TabChild";
import {TabView} from "../../widgets/tab/TabView";
import type {IModel} from "../../../data/models/Model";
import {getModelId} from "../../../data/models/Model";
import {ReactUtils} from "../../utils/ReactUtils";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {XyiconFeature} from "../../../generated/api/base";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {AppState} from "../../../data/state/AppState";
import {Button} from "../../widgets/button/Button";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Report} from "../../../data/models/Report";
import type {User} from "../../../data/models/User";
import {SearchField} from "../../widgets/input/search/SearchField";
import type {UserGroup} from "../../../data/models/UserGroup";
import {ActionBar} from "./bars/ActionBar";
import {EmptyListView} from "./grid/EmptyListView";
import {DeleteUserAndTransfrOwnerShipPopup} from "./popups/DeleteUserAndTransfrOwnerShipPopup";
import {InfoBubble} from "./common/infobutton/InfoBubble";
import {DomPortal} from "./portal/DomPortal";
import {PopupUtils} from "./popups/PopupUtils";

interface ITableViewProps<T extends IModel> {
	readonly feature: XyiconFeature;
	readonly headers: {
		id: string;
		title: string;
	}[];
	readonly create: (onCreated: (id: string) => void, onCancel: () => void, creating: boolean) => React.ReactElement;
	readonly details: (selectedItems: T[]) => React.ReactElement;
	readonly defaultSort?: string;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ITableViewState {
	creating: boolean;
	selectedIds: string[];
	searchString: string;
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	userDeleteWindowOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class TableView<T extends IModel> extends React.Component<ITableViewProps<T>, ITableViewState> {
	private _isDeletePopupWindowOpen = false;
	private _timeOutId: number = null;
	private _table = React.createRef<SimpleTable<T>>();
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();

	constructor(props: ITableViewProps<T>) {
		super(props);
		this.state = {
			creating: false,
			selectedIds: [],
			searchString: "",
			isToolTipOpen: false,
			toolTipTransform: null,
			userDeleteWindowOpen: false,
		};
	}

	public selectItem(item: T) {
		this.setState({
			selectedIds: [item.id],
		});

		this._table.current?.goToItem(item);
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.setState({creating: false});
				break;
		}
	};

	private onAddClick = () => {
		this.setState({creating: true});
	};

	private onDeleteClick = async () => {
		const {appState, transport, feature} = this.props;
		const {selectedIds} = this.state;

		await transport.services.feature.refreshList(XyiconFeature.Report);

		const reportsOwnedBySelectedUsers =
			appState.actions.getList(XyiconFeature.Report).filter((report: Report) => selectedIds.find((id) => id === report.ownerUserId)) || [];
		const viewsOwnedBySelectedUsers: View[] = [];

		for (const viewId in appState.viewsMap) {
			const view = appState.actions.getViewById(viewId);

			if (selectedIds.find((id) => id === view.ownedBy)) {
				viewsOwnedBySelectedUsers.push(view);
			}
		}

		if (
			!this._isDeletePopupWindowOpen &&
			feature === XyiconFeature.User &&
			(reportsOwnedBySelectedUsers.length !== 0 || viewsOwnedBySelectedUsers.length !== 0)
		) {
			this.setState({
				userDeleteWindowOpen: true,
			});
		} else if (!this._isDeletePopupWindowOpen) {
			const {selectedIds} = this.state;
			const count = selectedIds.length;
			let confirmed = false;

			this._isDeletePopupWindowOpen = true;

			if (feature === XyiconFeature.User) {
				confirmed = await PopupUtils.getDeleteUserPopup(count);
			} else {
				confirmed = await PopupUtils.getDeleteConfirmationPopup(feature, count);
			}

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const items = selectedIds.map((id) => appState.actions.getFeatureItemById<T>(id, feature));

				if (feature === XyiconFeature.User) {
					appState.actions.deleteUsers(items as unknown as User[], null);
				} else {
					this.deleteItems(items);
				}
			}
		}
	};

	private deleteItems = async (items: T[]) => {
		const {appState, feature} = this.props;

		await appState.actions.deleteItems(items, feature);

		this.setState({
			selectedIds: [],
		});
	};

	private onCancelCreate = () => {
		this.setState({creating: false});
	};

	private onCreated = (createdId: string) => {
		this.setState({creating: false, selectedIds: [createdId]});
		// TODO scrollto there
	};

	private getFields = (item: T): (string | number)[] => {
		const {headers, appState} = this.props;

		return headers.map((header) => {
			// TODO
			const id = header.id;

			if (id === "lastModifiedAt") {
				return DateFormatter.format(item.lastModifiedAt);
			}
			if (id === "lastModifiedBy") {
				return appState.actions.renderName(item.lastModifiedBy);
			}
			if (["userIds", "portfolioIds"].includes(id)) {
				const feature = id === "userIds" ? XyiconFeature.User : XyiconFeature.Portfolio;

				return (item[id as keyof IModel] as Array<string>).filter((id) => appState.actions.getFeatureItemById(id, feature)).length;
			}
			return item[id as "name"];
		});
	};

	private onTableSearch = (value: string) => {
		this.setState({searchString: value.toLowerCase()});
	};

	private openTooltip = () => {
		if (this._timeOutId) {
			clearTimeout(this._timeOutId);
		}

		this._timeOutId = window.setTimeout(() => {
			this.setState({
				isToolTipOpen: true,
			});
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	private get modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		this.props.transport.services.feature.refreshList(this.props.feature);
	}

	public override componentDidUpdate = (prevProps: ITableViewProps<T>, prevState: ITableViewState) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.center,
				),
			});
		}
	};

	private closeUserDeletePopup = () => {
		this.setState({userDeleteWindowOpen: false});
	};

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {headers, create, details, defaultSort, appState, feature} = this.props;
		const {creating, searchString, isToolTipOpen, toolTipTransform, selectedIds, userDeleteWindowOpen} = this.state;

		const items = appState.actions.getList<T>(feature);

		// TODO
		const selectedItems = this.state.selectedIds.map((id) => items.find((item) => item.id === id)).filter((item) => !!item);

		const ownAccountSelected = selectedItems.includes(this.props.appState.user as unknown as T);
		const areThereAnySystemItemsInSelectedItems = selectedItems.some((item) => (item as unknown as UserGroup).isSystem);

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		return (
			<div className="UserPermissions flex_1">
				<div className={ReactUtils.cls("createPanel", {open: creating})}>{create(this.onCreated, this.onCancelCreate, creating)}</div>
				<Splitter className={ReactUtils.cls({blurred: creating})}>
					{/* This undefined is needed for the Splitter, in order to use the toggling function. */}
					{undefined}
					<div className="vbox flex_1 left">
						<div className="hbox alignCenter justifyEnd aboveTable">
							<SearchField
								value={searchString}
								placeholder={"Find ..."}
								onInput={this.onTableSearch}
							/>
							<ActionBar>
								<Button
									className="large gray"
									label="Create"
									title="Create"
									icon="add"
									onClick={this.onAddClick}
								/>
								<div
									ref={this._parent}
									onMouseLeave={this.closeTooltip}
									onMouseOver={this.openTooltip}
								>
									<Button
										className="large gray"
										label="Delete"
										title="Delete"
										icon="delete"
										onClick={this.onDeleteClick}
										disabled={selectedItems.length === 0 || ownAccountSelected || areThereAnySystemItemsInSelectedItems}
									/>
								</div>
							</ActionBar>
						</div>
						{items.length > 0 ? (
							<SimpleTable
								ref={this._table}
								data={items}
								headers={headers}
								getKey={getModelId}
								getFields={this.getFields}
								selected={selectedItems}
								onSelect={(selected) => {
									this.setState({selectedIds: selected.map((item) => item.id)});
								}}
								defaultSort={{
									column: defaultSort || "name",
									direction: SortDirection.ASC,
								}}
								tableSearchQuery={searchString}
							/>
						) : (
							<EmptyListView feature={feature} />
						)}
					</div>
					<div className="vbox SidePanel right">
						<TabView
							className="SidePanel"
							selectedTabId="details"
						>
							<TabChild
								id="details"
								icon="details"
								title="Details"
								label="Details"
							>
								{details(selectedItems)}
							</TabChild>
						</TabView>
					</div>
				</Splitter>
				{isToolTipOpen && ownAccountSelected && (
					<DomPortal destination={this.modalContainer}>
						<InfoBubble
							className="Delete"
							content="You cannot remove yourself from the organization."
							style={inlineStyle}
							divRef={this._floating}
						/>
					</DomPortal>
				)}
				{userDeleteWindowOpen && (
					<DeleteUserAndTransfrOwnerShipPopup
						selectedIds={selectedIds}
						deleteItems={this.deleteItems}
						closeUserDeletePopup={this.closeUserDeletePopup}
					/>
				)}
			</div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import {MathUtils} from "../../../utils/math/MathUtils";
import type {INotificationParams, INotificationProps} from "./NotificationV5";
import {NotificationV5} from "./NotificationV5";

interface IAppNotificationsProps {}

interface INotificationElementParams extends INotificationProps {
	guid: string;
	onClose: () => void;
}

interface IAppNotificationsState {
	activeNotifications: INotificationElementParams[];
}

const AppNotificationsV5Styled = styled.div`
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	margin-top: 20px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: initial;
	flex-direction: column;
`;

export class AppNotificationsV5 extends React.PureComponent<IAppNotificationsProps, IAppNotificationsState> {
	constructor(props: IAppNotificationsProps) {
		super(props);
		this.state = {
			activeNotifications: [],
		};
	}

	public notify(params: INotificationParams) {
		const guid = MathUtils.getNewRandomGUID();
		const extendedParams: INotificationElementParams = {
			...params,
			guid: guid,
			onClose: () => {
				this.setState((prevState: IAppNotificationsState) => ({
					activeNotifications: [...prevState.activeNotifications].filter((el) => el.guid !== guid),
				}));
			},
		};

		this.setState((prevState: IAppNotificationsState) => ({
			activeNotifications: [...prevState.activeNotifications, extendedParams],
		}));

		return extendedParams;
	}

	private getNotifications() {
		return this.state.activeNotifications.map((notificationParams: INotificationElementParams) => (
			<NotificationV5
				key={notificationParams.guid}
				lifeTime={notificationParams.lifeTime}
				title={notificationParams.title}
				description={notificationParams.description}
				link={notificationParams.link}
				type={notificationParams.type}
				className={notificationParams.className}
				cancelable={notificationParams.cancelable}
				buttonLabel={notificationParams.buttonLabel}
				style={notificationParams.style}
				onActionButtonClick={notificationParams.onActionButtonClick}
				onCloseViewNotification={notificationParams.onCloseViewNotification}
				onClose={notificationParams.onClose}
				backdropDisable={notificationParams.backdropDisable}
			/>
		));
	}

	public override render() {
		return <AppNotificationsV5Styled>{this.getNotifications()}</AppNotificationsV5Styled>;
	}
}

import styled from "styled-components";
import type {FunctionComponent, SVGProps} from "react";
import React, {createRef, useEffect, useState} from "react";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {Color} from "../../../generated/api/base";
import {ColorUtils} from "../../../utils/ColorUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import {useClickOutside} from "../utils";
import type {TTitle, TTitleAlignment} from "../modules/spaceeditor/toolbar/ToolButtonV5";
import {TitleStyled} from "../modules/spaceeditor/toolbar/ToolButtonV5";
import {ColorWindowV5} from "./ColorWindowV5";
import type {ColorWindowMode} from "./ColorWindowV5";
import type {IPropsForEyeDropper} from "./EyeDropperV5";
import type {IHSLColor} from "./AdvancedColorPanelV5";
import {EyeDropperIcon} from "./EyeDropperIconV5";

interface ColorSelectorProps {
	readonly title: string;
	readonly color: Color;
	readonly onColorChange: (newColor: Color) => void;
	readonly label?: string;
	readonly isTransparencyEnabled: boolean;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly offsetY?: number;
	readonly offsetX?: number;
	readonly eyeDropperProps?: IPropsForEyeDropper;
	readonly closeWindowOnSelect?: boolean;
	readonly outerDivRef?: React.RefObject<HTMLDivElement>;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement>>;
	readonly tooltipAlignment?: TTitleAlignment;
}

const hsl2hex = (hsl: IHSLColor): Color => {
	const hex = ColorUtils.hsl2hex(hsl.hue, hsl.saturation, hsl.lightness, "string") as string;

	return {
		hex: hex,
		transparency: hsl.transparency,
	};
};

const hex2hsl = (color: Color): IHSLColor => {
	const hsl = ColorUtils.hex2hsl(color.hex);

	return {
		hue: hsl.h,
		saturation: hsl.s,
		lightness: hsl.l,
		transparency: color.transparency,
	};
};

export const ColorSelectorV5 = ({
	horizontalAlignment = HorizontalAlignment.right,
	verticalAlignment = VerticalAlignment.bottom,
	offsetX = 0,
	offsetY = 0,
	isTransparencyEnabled = true,
	label = "",
	onColorChange,
	color,
	title,
	eyeDropperProps,
	closeWindowOnSelect,
	outerDivRef,
	IconComponent,
	tooltipAlignment = "top",
}: ColorSelectorProps) => {
	const [activeMode, setActiveMode] = useState<ColorWindowMode>("basic");
	const [isWindowOpen, setIsWindowOpen] = useState<boolean>(false);
	const [colorAsHSL, setColorAsHSL] = useState<IHSLColor>(hex2hsl(color));
	const [isEyeDropperActive, setIsEyeDropperActive] = useState<boolean>(false);
	const appState = useAppStore((state) => state.appState);

	const app = appState.app;
	const _element = createRef<HTMLDivElement>();
	const _floating = createRef<HTMLDivElement>();

	const closeWindow = () => {
		setActiveMode("basic");
		setIsWindowOpen(false);
	};

	const onWindowToggle = () => {
		setIsWindowOpen((oldValue) => !oldValue);
	};

	const onColorChangeLocal = (newColor: IHSLColor) => {
		const newColorAsHex = hsl2hex(newColor);
		setColorAsHSL(newColor);

		if (newColorAsHex.hex !== color.hex || newColorAsHex.transparency !== color.transparency) {
			onColorChange(hsl2hex(newColor));

			if (closeWindowOnSelect && activeMode === "basic" && !isEyeDropperActive) {
				closeWindow();
			}
		}
	};

	const onClickOutside = (event: MouseEvent) => {
		if (isEyeDropperActive && eyeDropperProps.elementForPointerEvents.contains(event.target as Node)) {
			return false;
		} else {
			closeWindow();
		}
	};

	useClickOutside([_element, _floating], onClickOutside);

	useEffect(() => {
		if (_element.current && _floating.current) {
			const newTransformObject = DomUtils.getFixedFloatingElementPosition(
				outerDivRef?.current || _element.current,
				_floating.current,
				verticalAlignment || VerticalAlignment.top,
				horizontalAlignment || HorizontalAlignment.outerRight,
				offsetY,
				offsetX,
			);

			const colorWindowStyle: Partial<CSSStyleDeclaration> = {
				display: "",
			};

			if (!isWindowOpen) {
				colorWindowStyle.display = "none";
			} else {
				let {x, y} = newTransformObject;

				if (horizontalAlignment === HorizontalAlignment.right) {
					const parentSize = _element.current.getBoundingClientRect();

					x += parentSize.width;
				}
				colorWindowStyle.transform = `translate(${x}px, ${y}px)`;
			}

			if (_floating.current.style.transform !== colorWindowStyle.transform) {
				_floating.current.style.transform = colorWindowStyle.transform;
			}

			if (_floating.current.style.display !== colorWindowStyle.display) {
				_floating.current.style.display = colorWindowStyle.display;
			}
		}
	});

	const cls = ReactUtils.cls("ColorSelector", {
		labeled: label != null,
		isActive: isWindowOpen,
	});

	const setEyeDropperActive = (value: boolean) => {
		eyeDropperProps?.onActivateStateChanged(value);
		setIsEyeDropperActive(value);
	};

	return (
		<>
			<ColorSelectorStyled
				ref={_element}
				className={cls}
				data-label={label}
				onClick={onWindowToggle}
				$title={title}
				$titleAlignment={tooltipAlignment}
			>
				{IconComponent ? <IconComponent color={`#${color.hex}`} /> : <EyeDropperIcon color={color} />}
			</ColorSelectorStyled>
			{isWindowOpen && (
				<DomPortal destination={app.modalContainer}>
					<ColorWindowV5
						divRef={_floating}
						activeMode={activeMode}
						setActiveMode={setActiveMode}
						onCloseClick={closeWindow}
						onColorChange={onColorChangeLocal}
						isTransparencyEnabled={isTransparencyEnabled}
						colorHSL={hsl2hex(colorAsHSL).hex !== color.hex ? hex2hsl(color) : colorAsHSL} // without this, the hue slider's draghandle jiggles as you move the draghandler on the color gradient, because hex2hsl is not bijective...
						eyeDropperProps={eyeDropperProps ? {...eyeDropperProps, onActivateStateChanged: setEyeDropperActive} : undefined}
					/>
				</DomPortal>
			)}
		</>
	);
};

export const ColorSelectorStyled = styled.div<TTitle>`
	cursor: pointer;
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${radius.sm};
	position: relative;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background-color: ${colorPalette.primary.c500Primary};
	}

	${TitleStyled}
`;

import * as React from "react";
import {observer} from "mobx-react";
import {MultiSelectInput} from "../../../multiselect/MultiSelectInput";
import type {IInputProps} from "../../FieldInput";
import type {IMultiSelectFieldSettingsDefinition} from "../../../../../modules/settings/modules/field/datatypes/MultiSelectFieldSettings";

@observer
export class MultiInput extends React.Component<IInputProps<IMultiSelectFieldSettingsDefinition>> {
	public override render() {
		let {value, disabled, onFocusLossForceBlur, inline, expand, focused} = this.props;
		const {dataTypeSettings, onChange, onClick} = this.props;

		if (!Array.isArray(value)) {
			value = [];
		}

		return (
			<MultiSelectInput
				options={dataTypeSettings.choiceList}
				selected={dataTypeSettings.choiceList.filter((c) => value.includes(c.value))}
				onChange={onChange}
				onClick={onClick}
				disabled={disabled}
				onFocusLossForceBlur={onFocusLossForceBlur}
				inline={inline}
				expand={expand}
				focused={focused}
				render={(o) => (typeof o === "string" ? o : o.value)}
			/>
		);
	}
}

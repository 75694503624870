import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IError, TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import {ReactUtils} from "../../utils/ReactUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {PassSecurityLevel, PasswordUtils} from "../../widgets/password/PasswordUtils";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {ButtonV5} from "../button/ButtonV5";
import {TextInputV5} from "../details/datatypes/TextInputV5";
import {PasswordInputV5} from "../input/password/PasswordInputV5";
import CheckCircleIcon from "../icons/check-circle.svg?react";
import {UserStaticPageWrapperStyled, FormContainerStyled, PasswordValidatorStyled} from "./UserStaticV5.Style";

interface IForgottenPasswordResetProps {
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
}

interface IForgottenPasswordResetStates {
	email: string;
	verificationEmailSent: boolean;
	tokenID: string;
	token: string;
	password: string;
	passwordConfirm: string;
	loading: boolean;
	verificatedUserEmail: string;
	verificationError: IError;
	verificationIsInProgress: boolean;
	verificationEmailError: boolean;
	resetInProgress: boolean;
}

@inject("navigation")
@inject("transport")
@observer
export class ForgottenPasswordResetV5 extends React.Component<IForgottenPasswordResetProps, IForgottenPasswordResetStates> {
	private _emailParent = React.createRef<HTMLInputElement>();
	private _email = React.createRef<HTMLDivElement>();

	constructor(props: IForgottenPasswordResetProps) {
		super(props);
		this.state = {
			email: "",
			verificationEmailSent: false,
			tokenID: "",
			token: "",
			password: "",
			passwordConfirm: "",
			loading: false,
			verificatedUserEmail: null,
			verificationError: null,
			verificationIsInProgress: false,
			verificationEmailError: false,
			resetInProgress: false,
		};
	}

	private onSubmit = async () => {
		this.setState({loading: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/forgottenpassword",
			method: XHRLoader.METHOD_POST,
			params: {email: this.state.email},
		});

		if (error) {
			this.setState({
				loading: false,
				verificationEmailError: true,
			});
		} else {
			this.setState({
				loading: false,
				verificationEmailSent: true,
			});
		}
	};

	private onChange = async () => {
		this.setState({loading: true});

		const {token, tokenID, password} = this.state;
		const {result, error} = await this.props.transport.request({
			url: "auth/resetforgottenpassword",
			method: XHRLoader.METHOD_POST,
			params: {
				tokenID: tokenID,
				token: token,
				newPassword: password,
			},
		});

		if (error) {
			console.log(error);
		} else if (result) {
			// We need to wait a bit in order to users can read the notification.
			// We wait randomly between 3 and 6 sec. (Random time is better, because of UX reasons)
			this.setState({resetInProgress: true});
			window.setTimeout(() => this.props.navigation.goToLogin(), Math.random() * 3000 + 3000);
		}
	};

	private onGoToLoginClick = () => {
		this.props.navigation.goToLogin();
	};

	private get passwordConfirmError() {
		return this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password ? "Passwords do not match." : "";
	}

	private get passwordStrengthMessage() {
		const password = this.state.password;

		let passwordStrengthMessage: string = "";

		if (password.length < 8 || !/[0-9]/.test(password) || !/[a-z]/.test(password) || !/[A-Z]/.test(password)) {
			passwordStrengthMessage = "Password does not meet criteria. Please try again.";
		}

		return passwordStrengthMessage;
	}

	private handleNewPasswordInput = (value: string) => {
		this.setState({
			password: value,
		});
	};

	private handlePasswordConfirm = (value: string) => {
		this.setState({
			passwordConfirm: value,
		});
	};

	private handleEmailInput = (value: string) => {
		this.setState({
			email: value,
			verificationEmailError: this.state.verificationEmailError && !value,
		});
	};

	private isInvalidEmail = () => {
		const email = this.state.email;
		const emailError = this.state.verificationEmailError;
		let isValid = true;

		if (email) {
			isValid = StringUtils.emailValidator(email);
		}

		if (emailError) {
			isValid = false;
		}

		return !isValid;
	};

	private renderContent() {
		const {password, passwordConfirm, verificatedUserEmail, verificationError, verificationEmailSent, email, loading, token, resetInProgress} =
			this.state;

		const emailInlineStyle: React.CSSProperties = {
			top: "-35px",
			left: "-40px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
		};

		const {passwordStrengthMessage} = this;

		if (verificatedUserEmail) {
			return (
				<>
					{loading && resetInProgress ? (
						<>
							<div className="description">
								Redirecting to Xyicon Login...
								<a
									className="textLink"
									onClick={this.onGoToLoginClick}
								>
									Click here
								</a>{" "}
								if not redirected.
							</div>
						</>
					) : (
						<>
							<div className="description-forgotpswd">Type in a new password to be able to use with Xyicon, Make sure its secure and memorable</div>

							<div className="inputGroup password">
								<div className="labelName">New Password</div>
								<PasswordInputV5
									value={password}
									onInput={this.handleNewPasswordInput}
									infoText={passwordStrengthMessage}
								/>
								{password !== "" && passwordStrengthMessage === "" && <CheckCircleIcon className="check-icon" />}
							</div>
							<div className="inputGroup password confirmPassword">
								<div className="labelName">Confirm Password</div>
								<PasswordInputV5
									value={passwordConfirm}
									onInput={this.handlePasswordConfirm}
									infoText={this.passwordConfirmError}
								/>
							</div>
							<PasswordValidatorStyled className="PasswordValidator">
								<div className="pwddRequirementsMessage">
									Password must be 8+ characters and contain at least one number, uppercase letter, and lowercase letter. Special characters are
									optional
								</div>
							</PasswordValidatorStyled>
						</>
					)}
				</>
			);
		} else if (verificationError) {
			return (
				<>
					<div className="fieldContainer vbox description">
						Registration link has expired. Please reach out to your administrator to request a new invite.<br></br>
						If you've already registered, simply proceed to the login page to access your account.<br></br>
						For any assistance, feel free to contact our support team at support@xyicon.com Thank you!<br></br>
						<br></br>
						<span style={{fontSize: "16px"}}>
							{" "}
							<a
								className="textLink"
								onClick={this.onGoToLoginClick}
							>
								Go to login page
							</a>
						</span>
					</div>
				</>
			);
		} else if (!token) {
			if (verificationEmailSent) {
				return (
					<div className="description-forgotpswd">
						An email has been sent to <span style={{color: "#363636", fontWeight: 700}}>{email}</span>, with instruction to reset your password.
					</div>
				);
			} else {
				return (
					<>
						<div className="description-forgotpswd">
							To reset your password, enter the email address you used to register with Xyicon.<br></br>
							<br></br>
							We will send a link to reset your password.
						</div>

						<div className="inputGroup">
							<div className="labelName">Email</div>
							<TextInputV5
								onInput={this.handleEmailInput}
								placeholder="example@xyicon.com"
								className={ReactUtils.cls({redBorder: this.isInvalidEmail()})}
								divRef={this._emailParent}
								getErrorMessage={() => (this.state.verificationEmailError || this.isInvalidEmail() ? "Invalid Email" : "")}
							/>
						</div>
					</>
				);
			}
		}
	}

	private verifyForgottenPasswordToken = async (tokenID: string, token: string) => {
		this.setState({verificationIsInProgress: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/verifyforgottenpasswordtoken",
			method: XHRLoader.METHOD_POST,
			params: {tokenID, token},
		});

		this.setState({
			verificatedUserEmail: result?.email,
			verificationError: error,
			verificationIsInProgress: false,
		});
	};

	private getHeaderContent = () => {
		const {verificatedUserEmail, verificationError, verificationEmailSent, loading, token, resetInProgress} = this.state;

		if (verificatedUserEmail) {
			return (
				<>
					{loading && resetInProgress ? (
						<>
							<SVGIcon
								classNames="xyicon-logo"
								icon="xyiconlogo"
							/>
							<div className="title">Password reset successful!</div>
						</>
					) : (
						<>
							<div className="title">Reset Password</div>
							<div className="desc">{verificatedUserEmail}</div>
						</>
					)}
				</>
			);
		} else if (verificationError) {
			return (
				<>
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<div className="title">Reset Password</div>
				</>
			);
		} else if (!token) {
			if (verificationEmailSent) {
				return (
					<>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className="title">Check Mail</div>
					</>
				);
			} else {
				return <div className="title">Forgot your password?</div>;
			}
		}
	};

	private onCancelClick = () => {
		this.props.navigation.goToLogin();
	};

	public override componentDidMount() {
		const href = location.href;
		const tokenID = ReactUtils.searchForParamInUrl("tokenid", href);
		const token = ReactUtils.searchForParamInUrl("token", href);

		if (tokenID && token) {
			this.setState({token, tokenID});
			this.verifyForgottenPasswordToken(tokenID, token);
		}
	}

	public override render() {
		const {
			verificatedUserEmail,
			verificationError,
			verificationEmailSent,
			loading,
			password,
			passwordConfirm,
			email,
			verificationIsInProgress,
			resetInProgress,
		} = this.state;

		if (verificationIsInProgress) {
			return (
				<UserStaticPageWrapperStyled className="UserStaticPageWrapper">
					<div className="preLoadingPage">
						<div className="vbox alignCenter">
							<LoaderIcon />
							<p>Verification in progress ...</p>
						</div>
					</div>
				</UserStaticPageWrapperStyled>
			);
		} else {
			return (
				<UserStaticPageWrapperStyled className="UserStaticPageWrapper">
					<div className={ReactUtils.cls("ForgottenPasswordReset UserStaticPage", {expiredForgottenPasswordReset: !!verificationError})}>
						<FormContainerStyled className="formContainer hbox alignCenter">
							{this.getHeaderContent()}
							<div className={ReactUtils.cls("fieldContainer vbox", {emailSent: verificationEmailSent})}>
								{this.renderContent()}
								<div className="hbox btns">
									{verificatedUserEmail && !resetInProgress && (
										<ButtonV5
											className="primary"
											label={loading ? "Changing" : "Change"}
											onClick={this.onChange}
											disabled={
												loading ||
												PasswordUtils.getPasswordSecurityLevel(password) < PassSecurityLevel.Weak ||
												!password ||
												!passwordConfirm ||
												password !== passwordConfirm
											}
										/>
									)}
									{!(verificatedUserEmail || verificationError) && !verificationEmailSent && !loading && (
										<ButtonV5
											className="secondary"
											label="Cancel"
											type="secondary"
											onClick={this.onCancelClick}
										/>
									)}
									{!(verificatedUserEmail || verificationError) && !verificationEmailSent && (
										<ButtonV5
											className="primary"
											label={loading ? "Submiting" : "Submit"}
											onClick={this.onSubmit}
											disabled={loading || !StringUtils.emailValidator(email)}
										/>
									)}
								</div>
							</div>
						</FormContainerStyled>
					</div>
				</UserStaticPageWrapperStyled>
			);
		}
	}
}

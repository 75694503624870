import * as React from "react";
import {inject} from "mobx-react";
import {runInAction} from "mobx";
import {Ports} from "../Ports";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import type {App} from "../../../../../../../App";
import type {AppState} from "../../../../../../../data/state/AppState";
import {DomPortal} from "../../../../portal/DomPortal";
import {IconButton} from "../../../../../../widgets/button/IconButton";
import {Button} from "../../../../../../widgets/button/Button";
import type {Catalog} from "../../../../../../../data/models/Catalog";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import {ToggleContainer} from "../../../../../../widgets/container/ToggleContainer";
import {Port} from "../../../../../catalog/port/Port";
import type {PortTemplateDto, UpdateXyiconsModelRequest} from "../../../../../../../generated/api/base";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import type {IPortMapping, XyiconMappingInformation} from "../../../../../../../data/services/FeatureService";
import {MappingType} from "../../../../../../../data/services/FeatureService";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";

interface IPortMappingData {
	fromPortId: string;
	mappingType: MappingType;
	toPortId: string | null;
}

interface ISelectable {
	mappingType: MappingType;
	toPortId: string | null;
	label: string;
}

interface IConfirmXyiconModelChangesFormProps {
	readonly xyiconsToBeChanged: Xyicon[];
	readonly catalogIdToChangeTo: string;
	readonly onClose: () => void;

	readonly app?: App;
	readonly appState?: AppState;
}

interface IConfirmXyiconModelChangesFormState {
	isUpdating: boolean;
}

@inject("app")
@inject("appState")
export class ConfirmXyiconModelChangesForm extends React.Component<IConfirmXyiconModelChangesFormProps, IConfirmXyiconModelChangesFormState> {
	private _isMounted: boolean = false;
	// This structure is easier to handle for rendering
	// but we have to convert it to XyiconMappingInformation in the end
	private _catalogGroups: {
		fromCatalogId: string;
		portMappingData: IPortMappingData[];
	}[] = [];

	private _selectables: ISelectable[] = [];

	constructor(props: IConfirmXyiconModelChangesFormProps) {
		super(props);
		this.state = {
			isUpdating: false,
		};
	}

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.props.onClose();
				break;
		}
	};

	private convertPortMappingArrayToObject(portMappingArray: IPortMappingData[]) {
		const portMappingObject: IPortMapping = {};

		for (const pm of portMappingArray) {
			portMappingObject[pm.fromPortId] = {
				mappingType: pm.mappingType,
				port: pm.toPortId,
			};
		}

		return portMappingObject;
	}

	private getXyiconMappingInformation(): XyiconMappingInformation {
		const xyiconMappingInformation: XyiconMappingInformation = {};

		for (const catalogGroup of this._catalogGroups) {
			xyiconMappingInformation[catalogGroup.fromCatalogId] = {
				xyiconIDList: this.props.xyiconsToBeChanged.filter((xyicon) => xyicon.catalogId === catalogGroup.fromCatalogId).map((xyicon) => xyicon.id),
				portMapping: this.convertPortMappingArrayToObject(catalogGroup.portMappingData),
			};
		}

		return xyiconMappingInformation;
	}

	private onChangeClick = () => {
		this.setState({
			isUpdating: true,
		});
		const {xyiconsToBeChanged} = this.props;
		const params: UpdateXyiconsModelRequest = {
			portfolioID: this.props.appState.portfolioId,
			xyiconCatalogID: this.props.catalogIdToChangeTo,
			xyiconMappingInformation: this.getXyiconMappingInformation(),
		};

		runInAction(async () => {
			await TimeUtils.waitUpdate(this.props.appState.actions.updateXyiconsModel(params), this.props.app.notificationContainer);
			await this.props.app.spaceViewRenderer.xyiconManager.updateXyicons(xyiconsToBeChanged);

			if (this._isMounted) {
				this.props.onClose();
			}
		});
	};

	private getDescription() {
		return (
			<div className="description">
				<strong>Verify port remapping</strong>
				<div className="darkSilverText">
					We attempted to remap ports between the current and new xyicon models automatically. Verify and change the mapping per model using the
					dropdown.
				</div>
			</div>
		);
	}

	private getRows() {
		const {actions} = this.props.appState;
		const targetCatalog = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		const elements: React.ReactNode[] = [];

		for (const catalogGroup of this._catalogGroups) {
			const {fromCatalogId} = catalogGroup;
			const catalog = actions.getFeatureItemById<Catalog>(fromCatalogId, XyiconFeature.XyiconCatalog);
			const fromPortsAsArray = Port.getPortsAsArray(catalog.portTemplate);

			const title = (
				<div className="twoColumns">
					<div>{catalog.model}</div>
					<div style={{marginLeft: "25px"}}>{targetCatalog.model}</div>
				</div>
			);

			elements.push(
				<ToggleContainer
					title={title}
					key={fromCatalogId}
					open={true}
				>
					<div className="twoColumns">
						{catalog.portTemplate.length > 0 && <Ports item={catalog} />}
						<div className="selectors">
							{fromPortsAsArray.map((p: PortTemplateDto) => {
								const portMappingData = catalogGroup.portMappingData.find((o) => o.fromPortId === p.id);

								return (
									<div
										className="row"
										key={p.id}
									>
										{p.children.length === 0 && (
											<SelectInput
												render={(selectable) => selectable.label}
												options={this._selectables}
												sort={false}
												selected={
													this._selectables.find((p) => p.toPortId === portMappingData.toPortId && p.mappingType === portMappingData.mappingType) ||
													this._selectables[0] ||
													null
												}
												onChange={(value: ISelectable) => {
													portMappingData.toPortId = value.toPortId;
													portMappingData.mappingType = value.mappingType;
													this.forceUpdate();
												}}
												optionsZIndex={999999 + 8500} // popup + context-options
												onFocusLossForceBlur={true}
											/>
										)}
									</div>
								);
							})}
						</div>
					</div>
				</ToggleContainer>,
			);
		}

		return elements;
	}

	private getContent(): React.ReactNode {
		const {actions} = this.props.appState;
		const {xyiconsToBeChanged} = this.props;
		const catalogToChangeTo = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		// There are 4 scenarios mentions on ticket #2964
		// https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20October/S-74?workitem=2964

		const areThereLinkedXyiconsFromPorts = xyiconsToBeChanged.some((xyicon: Xyicon) =>
			actions
				.getLinksXyiconXyicon(xyicon.id)
				.some((l) => (l.link.fromObjectId === xyicon.id && l.link.fromPortId) || (l.link.toObjectId === xyicon.id && l.link.toPortId)),
		);

		// Scenario 1:
		// If the current model has linked xyicon/s to its ports AND the new model has a port template;
		if (areThereLinkedXyiconsFromPorts && catalogToChangeTo.portTemplate.length > 0) {
			return (
				<>
					{this.getDescription()}
					<div className="twoColumns">
						<h4 style={{marginLeft: "16px"}}>Current model ports</h4>
						<h4>New model ports</h4>
					</div>
					{this.getRows()}
				</>
			);
		} else if (areThereLinkedXyiconsFromPorts) {
			// Scenario 2:
			// If the current model has linked xyicon/s to its ports AND the new model DOES NOT have a port structure - "Orphaned Xyicons"
			return (
				<>
					{this.getDescription()}
					{this.getRows()}
				</>
			);
		} else {
			// Scenario 3, and Scenario 4:
			// If the current model has linked xyicons without a port structure AND the new model HAVE OR DOES NOT HAVE a port structure
			return `You are about to change the model of ${xyiconsToBeChanged.length} xyicon(s). Do you wish to continue?`;
		}
	}

	public override componentDidMount() {
		this._isMounted = true;
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);

		const {actions} = this.props.appState;

		const targetCatalog = actions.getFeatureItemById<Catalog>(this.props.catalogIdToChangeTo, XyiconFeature.XyiconCatalog);

		this._selectables = Port.getPortsAsArray(targetCatalog.portTemplate)
			.filter((p) => p.children.length === 0)
			.map((p) => ({
				label: Port.getFullLabelById(targetCatalog.portTemplate, p.id).join(" • "),
				toPortId: p.id,
				mappingType: MappingType.MapToPort,
			}));

		const additionalSelectables: ISelectable[] = [
			{
				label: "Break link",
				mappingType: MappingType.BreakLink,
				toPortId: null,
			},
			{
				label: "Convert to xyicon to xyicon link",
				mappingType: MappingType.ConvertToDirectLink,
				toPortId: null,
			},
		];

		this._selectables.push(...additionalSelectables);

		for (const xyicon of this.props.xyiconsToBeChanged) {
			if (!this._catalogGroups.some((o) => o.fromCatalogId === xyicon.catalogId)) {
				const catalog = actions.getFeatureItemById<Catalog>(xyicon.catalogId, XyiconFeature.XyiconCatalog);

				const portsAsArray = Port.getPortsAsArray(catalog.portTemplate);

				this._catalogGroups.push({
					fromCatalogId: xyicon.catalogId,
					portMappingData: portsAsArray
						.filter((p) => p.children.length === 0)
						.map((p: PortTemplateDto, index: number) => {
							const ret: IPortMappingData = {
								fromPortId: p.id,
								mappingType: MappingType.ConvertToDirectLink,
								toPortId: null,
							};

							if (index < this._selectables.length - additionalSelectables.length) {
								ret.toPortId = this._selectables[index].toPortId;
								ret.mappingType = MappingType.MapToPort;
							}

							return ret;
						}),
				});
			}
		}

		this.forceUpdate();
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		this._isMounted = false;
	}

	public override render() {
		const {app} = this.props;

		return (
			<DomPortal destination={app.modalContainer}>
				<div className="ConfirmXyiconModelChangesFormWrapper">
					<div className="ConfirmXyiconModelChangesForm PopupWindow vbox">
						<div className="title hbox alignCenter">
							Confirm Xyicon Model Changes
							<IconButton
								className="closeBtn"
								icon="close"
								onClick={this.props.onClose}
								title="Close Window"
								disabled={this.state.isUpdating}
							/>
						</div>
						<div className="content">
							{this.getContent()}
							<div className="buttonContainer hbox alignCenter justifyEnd">
								<Button
									label="Cancel"
									onClick={this.props.onClose}
								/>
								<Button
									label={this.state.isUpdating ? "Changing..." : "Change"}
									className="primary"
									onClick={this.onChangeClick}
									disabled={this.state.isUpdating}
								/>
							</div>
						</div>
					</div>
				</div>
			</DomPortal>
		);
	}
}

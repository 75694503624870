import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IReactionDisposer} from "mobx";
import {observable, makeObservable, reaction} from "mobx";
import type {IModel} from "../../../data/models/Model";
import type {IFieldAdapter} from "../../../data/models/field/Field";
import type {IFilterRow, IFilterState} from "../../../data/models/filter/Filter";
import type {AppState} from "../../../data/state/AppState";
import {XyiconFeature} from "../../../generated/api/base";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import type {AdvancedFilterEditor} from "../../modules/abstract/filter/advanced/AdvancedFilterEditor";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {FilterSwitchV5} from "../widgets/filters/filterSwitch/FilterSwitchV5";
import {ButtonV5} from "../button/ButtonV5";
import {SaveToViewButtonV5} from "../abstract/view/SaveToViewButtonV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {AdvancedFilterEditorV5} from "./advance/AdvancedFilterEditorV5";
import {FilterEditorStyled} from "./FilterEditor.styled";
import {SimpleFilterEditorV5} from "./simple/SimpleFilterEditorV5";

interface IFilterEditorProps {
	readonly features: XyiconFeature[];
	readonly feature: XyiconFeature;
	readonly items: IModel[];
	readonly autoUpdate?: boolean;
	readonly loader?: boolean;
	readonly onSelect: (item: IModel[], disableTabChange?: boolean) => void;
	readonly appState?: AppState;
}

interface IFilterEditorState {
	applyingFilters: boolean;
	search: string;
}

@inject("appState")
@observer
export class FilterEditorV5 extends React.Component<IFilterEditorProps, IFilterEditorState> {
	private readonly _ref = React.createRef<AdvancedFilterEditorV5 & SimpleFilterEditorV5>();
	private _isMounted: boolean = false;
	private _reactionDisposer: IReactionDisposer | null = null;

	// These contain props.filters + potentially local filters that are not saved to view yet
	@observable
	private _localFilters: {
		simple: IFilterRow[];
		advanced: IFilterRow[];
	};

	constructor(props: IFilterEditorProps) {
		super(props);
		makeObservable(this);
		this.state = {
			applyingFilters: false,
			search: "",
		};
		const selectedView = this.selectedView;

		this._localFilters = {
			simple: ObjectUtils.deepClone(selectedView.filters.filters.simple),
			advanced: ObjectUtils.deepClone(selectedView.filters.filters.advanced),
		};
	}

	public reset = (viewFilters?: IFilterState) => {
		const selectedView = this.selectedView;
		const savedFilters = selectedView.getSavedFilters();

		viewFilters = viewFilters || savedFilters; // Note: could unify / simplify this

		this._localFilters.simple = ObjectUtils.deepClone(viewFilters.filters.simple);
		this._localFilters.advanced = ObjectUtils.deepClone(viewFilters.filters.advanced);

		const filters = selectedView.filters.type === "simple" ? this._localFilters.simple : this._localFilters.advanced;

		// If you click the toggle for advanced filtering, it's possible
		// that getFilterEditor will give you the "SimpleFilterEditor" (and vica versa),
		// while the data above already suggests it should be the advanced one

		const activeFilterEditor = this.getFilterEditor();

		if (activeFilterEditor?.type === selectedView.filters.type) {
			activeFilterEditor.resetTo(filters);
		}
	};

	public isResetButtonEnabled() {
		const selectedView = this.selectedView;

		const savedFilters = selectedView.getSavedFilters();

		const isSimple = selectedView.filters.type === "simple";
		const savedViewFilters = isSimple ? savedFilters.filters.simple : savedFilters.filters.advanced;
		const localFilters = isSimple ? this._localFilters.simple : this._localFilters.advanced;

		return JSON.stringify(localFilters) !== JSON.stringify(savedViewFilters);
	}

	private syncFilters = () => {
		if (this._isMounted) {
			this.turnOffViewWatcher();
			this.getFilterEditor()?.applyAll();
			this.turnOnViewWatcher();
			if (this.state.applyingFilters) {
				this.setState({
					applyingFilters: false,
				});
			}
		}
	};

	private syncLocalFilters = (filters: IFilterRow[]) => {
		this._localFilters.advanced = filters;
	};

	public applyAll = () => {
		const {onSelect, feature, appState} = this.props;

		if (this._isMounted) {
			this.setState({
				applyingFilters: true,
			});
		}

		// timeout needed otherwise the loader will not be shown as the UI freezes
		setTimeout(() => {
			this.syncFilters();
		}, 100);

		appState.tableComponent.current?.onPageChange(0);
		if (![XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog, XyiconFeature.Xyicon, XyiconFeature.Boundary].includes(feature)) {
			onSelect([]);
		}
	};

	private get selectedView() {
		return this.props.appState.actions.getSelectedView(this.props.feature);
	}

	private getFilterEditor() {
		return this._ref.current as AdvancedFilterEditor | SimpleFilterEditorV5;
	}

	private onChangeFilterType = (value: boolean) => {
		const newType = value ? "advanced" : "simple";
		const selectedView = this.selectedView;

		// Save props.filters to localFilters when switching, so the up to date values will be
		// passed to localFilters
		if (newType === "simple") {
			this._localFilters.simple = ObjectUtils.deepClone(selectedView.filters.filters.simple);
		} else {
			this._localFilters.advanced = ObjectUtils.deepClone(selectedView.filters.filters.advanced);
		}

		selectedView.filters.type = newType;
	};

	private onResetClick = () => {
		this.reset();
	};

	private onClearAllClick = () => {
		this.getFilterEditor()?.clearAll();
	};

	private onResetSearch = () => {
		this.onSearchInput("");
	};

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private renderResetButton(clearDisabled: boolean) {
		const resetEnabled = this.isResetButtonEnabled();
		const clearEnabled = !clearDisabled;

		if (resetEnabled && clearEnabled) {
			return (
				<>
					<ButtonV5
						label="Reset"
						type="secondary"
						disabled={!this.isResetButtonEnabled()}
						onClick={this.onResetClick}
					/>
				</>
			);
		}

		if (resetEnabled && !clearEnabled) {
			return (
				<ButtonV5
					type="secondary"
					label="Reset"
					onClick={this.onResetClick}
				/>
			);
		}

		return (
			<ButtonV5
				type="secondary"
				disabled={true}
				label="Reset"
				onClick={this.onResetClick}
			/>
		);
	}

	private getStringifiedFiltersForSelectedView = () => {
		return JSON.stringify(this.selectedView.filters);
	};

	private onViewFiltersChanged = () => {
		this.reset(this.selectedView.filters);
	};

	private turnOnViewWatcher() {
		this._reactionDisposer = reaction(this.getStringifiedFiltersForSelectedView, this.onViewFiltersChanged);
	}

	private turnOffViewWatcher() {
		this._reactionDisposer?.();
	}

	private fieldFilter = (field: IFieldAdapter) => {
		const refId = field.refId;

		if (
			refId.includes("versionName") ||
			refId.includes("issuanceDate") ||
			refId === `${XyiconFeature.Xyicon}/icon` ||
			refId === `${XyiconFeature.XyiconCatalog}/icon`
		) {
			return false;
		}
		return true;
	};

	public override componentDidMount() {
		this._isMounted = true;

		this.turnOnViewWatcher();
	}

	public override componentWillUnmount() {
		this.turnOffViewWatcher();

		this._isMounted = false;
	}

	public override render() {
		const {items, features, feature, autoUpdate} = this.props;
		const selectedView = this.selectedView;
		const filters = selectedView.filters;

		const appliedFilters = filters.type === "simple" ? filters.filters.simple : filters.filters.advanced;
		const localFilters = filters.type === "simple" ? this._localFilters.simple : this._localFilters.advanced;
		const clearDisabled = appliedFilters.length + localFilters.length === 0;
		const isAdvanced = filters.type === "advanced";
		const oldChanges = selectedView.getSavedDataForType("filters", false);
		const newChanges = selectedView.getNewDataForType("filters", false);

		let hasChanges = false;

		if (isAdvanced) {
			hasChanges = JSON.stringify(oldChanges?.filters?.advanced) !== JSON.stringify((newChanges as IFilterState)?.filters?.advanced);
		} else {
			hasChanges = JSON.stringify(oldChanges?.filters?.simple) !== JSON.stringify((newChanges as IFilterState)?.filters?.simple);
		}

		const showReset = hasChanges || JSON.stringify(appliedFilters) !== JSON.stringify(localFilters);

		return (
			<FilterEditorStyled className={ReactUtils.cls("", {isInSpaceEditor: feature === XyiconFeature.SpaceEditor})}>
				<div className="top-section">
					<div className="heading hbox">
						{!isAdvanced && (
							<SearchFieldV5
								value={this.state.search}
								onInput={this.onSearchInput}
							/>
						)}
						<FilterSwitchV5
							label={isAdvanced ? "Quick Filter" : "Advanced Filter"}
							value={isAdvanced}
							onChange={this.onChangeFilterType}
						/>
						{filters.type === "simple" && <div className="separator"></div>}
					</div>
					{filters.type === "advanced" ? (
						<AdvancedFilterEditorV5
							ref={this._ref}
							features={features}
							filters={appliedFilters}
							localFilters={localFilters}
							fieldFilter={this.fieldFilter}
							syncFilters={this.syncFilters}
							syncLocalFilters={this.syncLocalFilters}
						/>
					) : (
						<SimpleFilterEditorV5
							ref={this._ref}
							features={features}
							feature={feature}
							filters={appliedFilters}
							localFilters={localFilters}
							items={items}
							loader={this.props.loader}
							search={this.state.search}
							resetSearch={this.onResetSearch}
						/>
					)}
				</div>
				{!autoUpdate && (
					<div className="bottom-buttons hbox">
						{showReset && this.renderResetButton(clearDisabled)}
						{hasChanges && (
							<SaveToViewButtonV5
								feature={feature}
								viewChangeType="filters"
								className="borderButton"
							/>
						)}
						<ButtonV5
							label={this.state.applyingFilters ? "Applying" : "Apply"}
							disabled={this.state.applyingFilters || JSON.stringify(appliedFilters) === JSON.stringify(localFilters)}
							onClick={this.applyAll}
						/>
					</div>
				)}
			</FilterEditorStyled>
		);
	}
}

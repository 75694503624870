import {ConfirmWindow} from "./ui/modules/abstract/popups/ConfirmWindow";
import type {TransportLayer} from "./data/TransportLayer";
import {XyiconView} from "./ui/modules/xyicon/XyiconView";
import {PortfolioView} from "./ui/modules/portfolio/PortfolioView";
import {SpaceView} from "./ui/modules/space/SpaceView";
import {SettingsView} from "./ui/modules/settings/SettingsView";
import {CatalogView} from "./ui/modules/catalog/CatalogView";
import {XyiconFeature} from "./generated/api/base";
import {BoundaryView} from "./ui/modules/boundary/BoundaryView";
import {Signal} from "./utils/signal/Signal";
import {SpaceEditorView} from "./ui/modules/space/SpaceEditorView";
import {ReportView} from "./ui/modules/report/ReportView";
import {SpaceEditorViewV5} from "./ui/5.0/modules/spaceeditor/SpaceEditorViewV5";
import {PortfolioViewV5} from "./ui/5.0/modules/PortfolioViewV5";
import {SpaceViewV5} from "./ui/5.0/modules/SpaceViewV5";
import {CatalogViewV5} from "./ui/5.0/modules/CatalogViewV5";
import {XyiconViewV5} from "./ui/5.0/modules/XyiconViewV5";
import {BoundaryViewV5} from "./ui/5.0/modules/BoundaryViewV5";
import {ReportViewV5} from "./ui/5.0/modules/ReportViewV5";
import {ConfirmWindowV5} from "./ui/5.0/popup/ConfirmWindowV5";
import {SettingsViewV5} from "./ui/5.0/settings/SettingsViewV5";
import {NavigationEnum} from "./Enums";
import {HomeDashboard} from "./ui/5.0/modules/home/HomeDashboard";
import {NoViewsPage} from "./ui/5.0/navigation/NoViewsPage";

interface IUnsavedChanges {
	title: string;
	description: string;
	onSave: () => Promise<void>;
}

interface IMenu {
	label: string;
	nav: NavigationEnum;
	icon: string;
	component?: React.ComponentClass;
	componentV5?: React.FunctionComponent;
	hasSub: boolean;
	//section: "portfolio" | "modules";
	feature: XyiconFeature;
	admin?: boolean;
}
export class Navigation {
	public unsavedChanges: IUnsavedChanges | null = null;

	public signals = {
		navigate: new Signal(),
	};

	public previousHash = "";
	private _transport: TransportLayer;

	private _menus: IMenu[] = [
		{
			label: "Portfolios",
			nav: NavigationEnum.NAV_PORTFOLIOS,
			icon: "portfolio",
			component: PortfolioView,
			componentV5: PortfolioViewV5,
			hasSub: false,
			//section: "modules",
			feature: XyiconFeature.Portfolio,
		},
		{
			label: "Home",
			nav: NavigationEnum.NAV_HOME,
			icon: "home",
			component: null,
			componentV5: HomeDashboard,
			hasSub: false,
			feature: XyiconFeature.Portfolio, // ? What should we use here...
		},
		{
			label: "Spaces",
			nav: NavigationEnum.NAV_SPACES,
			icon: "spaces",
			component: SpaceView,
			componentV5: SpaceViewV5,
			hasSub: true,
			//section: "portfolio",
			feature: XyiconFeature.Space,
		},
		{
			label: "Catalog",
			nav: NavigationEnum.NAV_CATALOG,
			icon: "catalog",
			component: CatalogView,
			componentV5: CatalogViewV5,
			hasSub: true,
			//section: "modules",
			feature: XyiconFeature.XyiconCatalog,
		},
		{
			label: "Xyicons",
			nav: NavigationEnum.NAV_XYICONS,
			icon: "xyicons",
			component: XyiconView,
			componentV5: XyiconViewV5,
			hasSub: true,
			//section: "portfolio",
			feature: XyiconFeature.Xyicon,
		},
		{
			label: "Boundaries",
			nav: NavigationEnum.NAV_BOUNDARIES,
			icon: "boundaries",
			component: BoundaryView,
			componentV5: BoundaryViewV5,
			hasSub: true,
			//section: "portfolio",
			feature: XyiconFeature.Boundary,
		},
		{
			label: "Reports",
			nav: NavigationEnum.NAV_REPORTS,
			icon: "reports",
			component: ReportView,
			componentV5: ReportViewV5,
			hasSub: true,
			//section: "modules",
			feature: XyiconFeature.Report,
		},
	];

	constructor(transportLayer: TransportLayer) {
		this._transport = transportLayer;
	}

	private get _screens() {
		return [
			{
				nav: NavigationEnum.NAV_SPACE,
				label: "Space Viewer",
				component: SpaceEditorView,
				componentV5: SpaceEditorViewV5,
				feature: XyiconFeature.SpaceEditor,
			},
			{
				nav: NavigationEnum.NAV_SETTINGS,
				label: "Settings",
				component: SettingsView,
				componentV5: SettingsViewV5,
			},
			{
				nav: NavigationEnum.NAV_BLANK,
				label: "Blank",
				component: NoViewsPage,
				componentV5: NoViewsPage,
			},
		];
	}

	public async redirectAfterLogin() {
		await this._transport.initGeneralSettings();
		const authService = this._transport.services.auth;

		const hash = location.hash;

		if (hash === "" || hash === "#" || hash === "#/" || hash.indexOf("auth/login") > -1) {
			if (authService.pathToRedirect != null && authService.pathToRedirect != "") {
				//scope.$root.go(authService.pathToRedirect.state, authService.pathToRedirect.params);
				authService.pathToRedirect = null;
			} else if (authService.authData) {
				this._transport.appState.currentUIVersion === "5.0" ? this.goApp(NavigationEnum.NAV_HOME) : this.goApp(NavigationEnum.NAV_PORTFOLIOS);
			} else {
				this.goToLogin();
			}
		} else {
			this.go(hash);
			return hash;
		}
	}

	public goApp(mainNav: NavigationEnum, ...subNavs: string[]) {
		if (mainNav == NavigationEnum.NAV_SPACE) {
			const userId = this._transport.appState.user?.id;
			const orgId = this._transport.appState.organizationId;
			const lastViewedSpaceId = subNavs[0];
			const localStorageKeyForLastViewedSpace = this._transport.appState.getLocalStorageKeyForLastViewedSpace(userId, orgId);
			localStorage.setItem(localStorageKeyForLastViewedSpace, lastViewedSpaceId);
		}

		this.go(`app/${[mainNav, ...subNavs].filter((v) => !!v).join("/")}`);
	}

	public goToLogin() {
		this.go("auth/login");
	}

	public async go(hash: string) {
		if (this.unsavedChanges) {
			const ConfirmWindowComponent = this._transport.appState.currentUIVersion === "5.0" ? ConfirmWindowV5 : ConfirmWindow;

			const result = await ConfirmWindowComponent.open(this.unsavedChanges.description, this.unsavedChanges.title, {cancel: "Reset", ok: "Save"});

			switch (result) {
				case true:
					await this.unsavedChanges.onSave();
					break;
				case false:
					break;
				case null:
					// The user escaped, or closed the confirm window with "x"
					return;
			}
		}

		this.previousHash = location.hash;
		location.hash = hash;

		this.unsavedChanges = null;

		this.signals.navigate.dispatch();

		return location.hash;
	}

	public openInNewTab(url: string) {
		window.open(url, "_blank");
	}

	public reload(hash: string = "") {
		this.go(hash);
		location.reload();
	}

	public back() {
		window.history.back();
	}

	public getTitle(nav: string) {
		return (
			this._menus.filter((menu) => menu.nav === nav).map((menu) => menu.label)[0] ||
			this._screens.filter((screen) => screen.nav === nav).map((screen) => screen.label)[0] ||
			"Home"
		);
	}

	public getComponent(nav: NavigationEnum) {
		const isV5 = this._transport.appState.currentUIVersion === "5.0";
		const prop = isV5 ? "componentV5" : "component";

		return (
			this._menus.filter((menu) => menu.nav === nav).map((menu) => menu[prop])[0] ||
			this._screens.filter((screen) => screen.nav === nav).map((screen) => screen[prop])[0] ||
			null
		);
	}

	public getFeature(nav: string) {
		const menus = this._menus;
		const screens = this._screens;
		const currentMenuTitle = nav;
		const selectedMenu = menus.find((m) => m.label === currentMenuTitle) ?? screens.find((s) => s.label === currentMenuTitle);

		return selectedMenu?.feature ?? XyiconFeature.Portfolio;
	}

	public get menus() {
		return this._menus;
	}

	public get screens() {
		return this._screens;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled, {css} from "styled-components";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {AppState} from "../../../../data/state/AppState";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {XyiconFeature} from "../../../../generated/api/base";
import {Catalog} from "../../../../data/models/Catalog";
import {ReactUtils} from "../../../utils/ReactUtils";
import {SearchFieldV5} from "../../input/search/SearchFieldV5";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {InfoBubbleV5} from "../../button/InfoBubbleV5";
import ChevronDownIcon from "../../icons/chevron-down.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {ConfirmXyiconModelChangesFormV5} from "../ConfirmXyiconModelChangesFormV5";
import {FlexCenterStyle, fontSize, fontWeight, radius} from "../../styles/styles";
import {TextInputV5} from "./TextInputV5";

interface IXyiconModelFieldProps {
	readonly xyicons: Xyicon[];
	readonly item?: Xyicon;
	readonly appState?: AppState;
	readonly disabled: boolean;
	readonly inline?: boolean;
}

interface IXyiconModelFieldState {
	open: boolean;
	search: string;
	updating: boolean;
	xyiconsToBeChanged: Xyicon[];
	catalogIdToChangeTo: string;
	toolTipTransform: TransformObj;
	selectorTransform: TransformObj;
	isToolTipOpen: boolean;
}

@inject("appState")
@observer
export class XyiconModelFieldV5 extends React.Component<IXyiconModelFieldProps, IXyiconModelFieldState> {
	private _container = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _selector = React.createRef<HTMLDivElement>();
	private _timeoutId: number;

	constructor(props: IXyiconModelFieldProps) {
		super(props);
		this.state = {
			open: false,
			search: "",
			updating: false,
			xyiconsToBeChanged: [],
			catalogIdToChangeTo: "",
			toolTipTransform: null,
			selectorTransform: null,
			isToolTipOpen: false,
		};
	}

	private onEditClick = () => {
		AppUtils.disableScrolling(true);
		if (!this.props.disabled) {
			this.setState({
				open: !this.state.open,
			});
		}
	};

	private onKeyPressed = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				AppUtils.disableScrolling(false);
				if (this.state.open) {
					this.setState({open: false, search: ""});
				}
				break;
		}
	};

	private onSelectCatalog = (catalogId: string) => {
		const {xyicons, inline, item} = this.props;

		// in case of SpaceEditor table xyicon model change (xyicons is empty) or if some items are selected and other element's model is changed
		const xyiconsToCheck = inline && (xyicons.length === 0 || !xyicons.includes(item)) ? [item] : xyicons;
		const xyiconsToBeChanged = xyiconsToCheck.filter((xyicon) => xyicon.catalogId !== catalogId);

		this.setState({
			xyiconsToBeChanged: xyiconsToBeChanged,
			catalogIdToChangeTo: catalogId,
		});
	};

	private areAllModelsMatching() {
		const {xyicons} = this.props;
		const firstCatalogId = xyicons[0]?.catalogId;

		for (let i = 1; i < xyicons.length; ++i) {
			if (xyicons[i].catalogId !== firstCatalogId) {
				return false;
			}
		}

		return true;
	}

	private onFocusLoss = (e: MouseEvent) => {
		AppUtils.disableScrolling(false);

		let eventTargetInModalContainer = false;

		if (e.target instanceof Element) {
			eventTargetInModalContainer = this.props.appState.app.modalContainer.contains(e.target);

			if (!eventTargetInModalContainer && (this.state.open || this.state.search)) {
				this.setState({
					open: false,
					search: "",
				});
			}
		}

		return false;
	};

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private onCloseConfirmForm = () => {
		this.setState({
			xyiconsToBeChanged: [],
			catalogIdToChangeTo: "",
			open: false,
		});
	};

	private onMouseOver = (e: React.MouseEvent) => {
		const input = e.currentTarget.querySelector("input") as HTMLInputElement;

		if (input.scrollWidth > input.clientWidth) {
			if (this._timeoutId) {
				clearTimeout(this._timeoutId);
			}

			this._timeoutId = window.setTimeout(() => {
				this.setState({isToolTipOpen: true});
			}, 500);
		}
	};

	private onMouseLeave = () => {
		clearTimeout(this._timeoutId);
		this.setState({isToolTipOpen: false});
	};

	public override componentDidMount() {
		FocusLoss.listen(this._container.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.add(this.onKeyPressed);
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.remove(this.onKeyPressed);
	}

	public override componentDidUpdate(prevProps: Readonly<IXyiconModelFieldProps>, prevState: Readonly<IXyiconModelFieldState>): void {
		if (!prevState.open && this.state.open && this._container.current && this._selector.current) {
			this.setState({
				selectorTransform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._selector.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.center,
					3,
					0,
				),
			});
		}

		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._container.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					10,
					0,
					true,
				),
			});
		}
	}

	private onFieldTexInputClick = (e: React.MouseEvent) => {
		e.preventDefault();
	};

	public override render() {
		const {xyicons, appState, disabled, inline, item} = this.props;
		const {open, search, updating, isToolTipOpen, toolTipTransform, selectorTransform} = this.state;

		const selectorStyle: React.CSSProperties = this._selector && {
			transform: selectorTransform?.translate,
			position: "fixed",
			zIndex: 9000,
			minWidth: 300,
			width: this._container?.current?.getBoundingClientRect().width,
			maxWidth: 500,
		};

		const inlineStyle: React.CSSProperties = this._floating.current && {
			transform: toolTipTransform?.translate,
		};

		let catalogs = appState.actions.getList(XyiconFeature.XyiconCatalog) as Catalog[];

		const allModelsAreMatching = this.areAllModelsMatching();
		let matchingCatalog: Catalog;

		if (inline || allModelsAreMatching) {
			const firstXyicon = inline ? item : xyicons[0];

			catalogs = catalogs.filter((catalog) => {
				return catalog.id !== firstXyicon.catalogId && Catalog.search(catalog, search);
			});

			matchingCatalog = appState.actions.findInheritedObject(firstXyicon, firstXyicon.ownFeature) as Catalog;
		}

		return (
			<XyiconModelFieldStyled
				ref={this._container}
				className={ReactUtils.cls("XyiconModelField", {open, inline})}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
				$multipleValues={!matchingCatalog}
			>
				<div
					className={ReactUtils.cls("modelField", {disabled})}
					onClick={this.onEditClick}
				>
					<TextInputV5
						onClick={this.onFieldTexInputClick}
						value={matchingCatalog ? matchingCatalog.model : "Multiple values"}
						className={ReactUtils.cls({cellContent: inline})}
					/>
					{updating ? <span className="spinner" /> : !inline && xyicons.length === 1 && <ChevronDownIcon />}
				</div>
				{this.state.xyiconsToBeChanged.length > 0 && this.state.catalogIdToChangeTo && (
					<ConfirmXyiconModelChangesFormV5
						onClose={this.onCloseConfirmForm}
						xyiconsToBeChanged={this.state.xyiconsToBeChanged}
						catalogIdToChangeTo={this.state.catalogIdToChangeTo}
					/>
				)}
				{open && (
					<DomPortal destination={this.props.appState.app.modalContainer}>
						<XyiconModelFieldSelectorStyled
							className={ReactUtils.cls("XyiconModelField__selector", {inline})}
							style={selectorStyle}
							ref={this._selector}
						>
							<SearchFieldV5
								onInput={this.onSearchInput}
								value={search}
							/>
							<div className="list">
								{catalogs.length ? (
									catalogs
										.toSorted((a: Catalog, b: Catalog) => StringUtils.sortIgnoreCase(a.model, b.model))
										.filter((c: Catalog) => StringUtils.containsIgnoreCase(c.model, search))
										.map((catalog) => (
											<div
												key={catalog.id}
												className="listItem"
												onClick={(e) => this.onSelectCatalog(catalog.id)}
											>
												<img
													src={catalog.thumbnail}
													alt={`${catalog.id} thumbnail`}
												/>
												<div className="fields">
													{search.length !== 0 ? (
														<div
															className="model"
															dangerouslySetInnerHTML={{__html: StringUtils.regexHighlight(catalog.model, search)}}
														/>
													) : (
														<div className="model">{catalog.model}</div>
													)}
													<div className="type">{appState.actions.getTypeName(catalog.typeId)}</div>
												</div>
											</div>
										))
								) : (
									<>No results.</>
								)}
							</div>
						</XyiconModelFieldSelectorStyled>
					</DomPortal>
				)}
				{isToolTipOpen && matchingCatalog && !inline && (
					<InfoBubbleV5
						divRef={this._floating}
						content={matchingCatalog.model}
						style={inlineStyle}
						className="DeleteButtonToolTip"
					/>
				)}
			</XyiconModelFieldStyled>
		);
	}
}

export const XyiconModelFieldStyled = styled.div<{$multipleValues?: boolean}>`
	cursor: pointer;
	padding-right: 4px;
	border: 1px solid #c8c8c8;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	.modelField {
		& > input {
			pointer-events: none;
		}
	}

	.cellContent {
		background: none;

		&:hover {
			background: var(--inlineEditHover);
		}
	}

	&:not(.inline) {
		position: relative;
		width: 100%;
		left: -12px;

		.modelField {
			${FlexCenterStyle};

			&.disabled {
				outline: none;
			}

			input {
				pointer-events: none;
				border: none;
				flex: 1;

				${(props) => {
					if (props.$multipleValues) {
						return css`
							font-style: italic;
						`;
					}
				}}

				& + svg {
					margin-right: 8px;
				}
			}

			svg.icon {
				display: none;
				position: absolute;
				width: 16px;
				height: 16px;
				top: 50%;
				transform: translateY(-50%);
				right: 11px;
				fill: var(--icon);
			}

			.spinner {
				position: absolute;
				top: 11px;
				right: base.$xs;
				visibility: visible;
			}
		}

		> input {
			border: solid 1px transparent;
			font-weight: base.$font-thin;
			cursor: pointer;
		}

		&:hover {
			> input {
				border-color: var(--bg3);
			}
		}
	}

	&.open {
		> input {
			background: var(--bg3);
			border-radius: 4px;
		}

		> svg.icon {
			display: block;
		}

		.SearchField {
			svg.icon {
				display: block;
			}
		}
	}
`;

const XyiconModelFieldSelectorStyled = styled.div`
	background-color: ${colorPalette.white};
	padding: 4px;
	box-shadow:
		0px 8px 16px 0px rgba(50, 50, 71, 0.06),
		0px 8px 8px 0px rgba(50, 50, 71, 0.08);
	border-radius: 4px;

	.list {
		overflow-y: auto;
		background: ${colorPalette.white};
		max-height: 300px;
		overflow-x: hidden;
	}

	.listItem {
		display: grid;
		grid-template-columns: 38px 1fr;
		grid-gap: base.$sm;
		padding: 4px;
		height: auto;
		border-color: transparent;
		width: 100%;

		&:hover {
			background-color: ${colorPalette.gray.c200Light};
			border-radius: ${radius.sm};
		}

		.fields {
			min-width: 100%;
			padding-left: 8px;
			line-height: 16px;
			font-weight: ${fontWeight.normal};

			& > div {
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
			}
		}

		img {
			width: 38px;
			height: 38px;
		}

		.model {
			font-size: ${fontSize.md};
			color: ${colorPalette.gray.c950};
		}

		.type {
			font-size: ${fontSize.sm};
			letter-spacing: 0.0025em;
			color: ${colorPalette.gray.c700Dark};
		}

		&.hover {
			.model {
				color: ${colorPalette.white};
			}

			.email {
				color: ${colorPalette.primary.c200Light};
			}
		}
	}

	.SearchField {
		height: auto;
		margin: 0 0 2px;
		width: 100%;

		input {
			border-radius: 4px;
		}

		&:focus,
		&:active,
		&:hover {
			input {
				border-color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;

import type {MutableRefObject, ReactNode} from "react";
import {useEffect, useMemo, useRef, useState, Fragment} from "react";
import styled from "styled-components";
import {flushSync} from "react-dom";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {FlexCenterStyle} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {MathUtils} from "../../../utils/math/MathUtils";
import {ReactUtils} from "../../utils/ReactUtils";

const elementKeys = Array.from({length: 10}, () => MathUtils.getNewRandomGUID());

type TSplitterV5 = {
	readonly children: ReactNode[];
};

type TChildElementType = "dragger" | "real";

type TChildElement = {
	element: ReactNode;
	key: string;
	width: number;
	minWidth: number;
	type: TChildElementType;
	originalRatio?: number;
};

const getSumOfWidths = (children: Element[], type: "default" | "min"): number => {
	let sum = 0;

	for (const child of children) {
		const widthMaybe = parseInt(child.getAttribute(`data-${type}-width`));
		const width = isNaN(widthMaybe) ? 0 : widthMaybe;
		sum += width;
	}

	return sum;
};

const isStructureValid = (localStorageData: number[]) => {
	if (!localStorageData.every((v) => MathUtils.isValidNumber(v) && v >= 0)) {
		return false;
	}

	const sum = MathUtils.sum(localStorageData);
	if (sum > 1.001) {
		return false;
	}

	return true;
};

const _draggerWidth: number = 4;

const calculateMainPanelWidth = (splitterRef: MutableRefObject<HTMLDivElement>, children: any[]) => {
	return (splitterRef.current?.offsetWidth ?? 0) - Math.max(0, children.length - 1) * _draggerWidth;
};

export const SplitterV5Tamas = (props: TSplitterV5) => {
	const localStorageKey = "v5-splitter-grid-ratios";
	const localStorageData = localStorage.getItem(localStorageKey);
	const {children} = props;
	const [childrenState, setChildrenState] = useState<TChildElement[]>([]);
	const [grabbedDraggerIndex, setGrabbedDraggerIndex] = useState<number>(-1);
	const savedBodyCursor = useRef<string>("");
	const splitterRef = useRef<HTMLDivElement>(null);

	const structuredLocalStorageData = useMemo(() => (localStorageData ? (JSON.parse(localStorageData) as number[]) : []), [localStorageData]);

	const onPointerUp = () => {
		setChildrenState((prevChildren) => {
			const result = prevChildren.map((prevChild, i) => ({...prevChild, originalRatio: prevChild.width}));
			const validChildren = result.filter((child) => child.type === "real");

			for (let i = 0; i < validChildren.length; ++i) {
				const child = validChildren[i];

				const unclampedChildWidth = child.width;
				const clampedChildWidth = Math.max(child.width, child.minWidth / (splitterRef.current?.offsetWidth ?? 2000));
				const diff = clampedChildWidth - unclampedChildWidth;
				child.width = clampedChildWidth;
				child.originalRatio = child.width;

				if (diff > 0.01) {
					if (i < validChildren.length - 1) {
						const nextChild = validChildren[i + 1];
						nextChild.width -= diff;
						nextChild.originalRatio = nextChild.width;
					} else if (i > 0) {
						const prevChild = validChildren[i - 1];
						prevChild.width -= diff;
						prevChild.originalRatio = prevChild.width;
					}
				}
			}

			localStorage.setItem(localStorageKey, JSON.stringify(validChildren.map((vc) => vc.originalRatio)));

			return result;
		});
	};

	useEffect(() => {
		const onPointerMove = (pointer: Pointer) => {
			flushSync(() => {
				const draggerKey: number = parseInt(pointer.currentTarget.getAttribute("data-id"));

				setChildrenState((prevChildren) => {
					const mainPanelWidth = calculateMainPanelWidth(
						splitterRef,
						prevChildren.filter((c) => c?.type === "real"),
					);
					const movementInRatio = pointer.offsetX / mainPanelWidth;

					return prevChildren.map((prevChild, index) => {
						if (index === draggerKey - 1) {
							return {...prevChild, width: prevChild.originalRatio + movementInRatio};
						} else if (index === draggerKey + 1) {
							return {...prevChild, width: prevChild.originalRatio - movementInRatio};
						}

						return {...prevChild};
					});
				});
			});
		};

		const recalculateChildren = () => {
			const validChildren = children.filter((c) => !!c);
			const domChildren = Array.from(splitterRef.current?.children ?? []);
			const mainPanelFullWidth = splitterRef.current?.offsetWidth ?? 2000;

			const sumOfDefaultWidths = getSumOfWidths(domChildren, "default");
			const sumOfMinWidths = getSumOfWidths(domChildren, "min");

			let widthOffset = 0;

			const calculatedChildren: TChildElement[] = [];
			for (let i = 0; i < children.length; ++i) {
				const elementKey = elementKeys[i];
				const validChildMaybe = children[i];

				if (validChildMaybe) {
					let finalWidthRatio = 1;
					let minWidth = 0;

					if (validChildren.length > 1) {
						const indexOfValidChild = validChildren.findIndex((vc) => vc === validChildMaybe) * 2; // we have dragger elements between every valid children, so we need to multiply by 2;
						const domChildMaybe = domChildren[indexOfValidChild];
						const minWidthMaybe = parseInt(domChildMaybe?.getAttribute("data-min-width"));
						minWidth = isNaN(minWidthMaybe) ? 0 : minWidthMaybe;
						const minWidthRatio = minWidth / mainPanelFullWidth;

						const defaultWidthMaybe = parseInt(domChildMaybe?.getAttribute("data-default-width"));
						let defaultWidthRatio = MathUtils.isValidNumber(defaultWidthMaybe) ? defaultWidthMaybe / mainPanelFullWidth : 1 / validChildren.length;

						if (MathUtils.isValidNumber(defaultWidthMaybe)) {
							widthOffset += defaultWidthMaybe / mainPanelFullWidth - 1 / validChildren.length;
						} else {
							defaultWidthRatio -= widthOffset;
							widthOffset = 0;
						}

						finalWidthRatio = defaultWidthRatio;

						if (sumOfDefaultWidths > mainPanelFullWidth) {
							finalWidthRatio = minWidthRatio;
						}

						if (sumOfMinWidths > mainPanelFullWidth) {
							finalWidthRatio = 1 / validChildren.length;
						}
					}

					calculatedChildren.push({
						key: elementKey,
						element: validChildMaybe,
						minWidth,
						width: finalWidthRatio,
						type: "real",
						originalRatio: finalWidthRatio,
					});
				}
			}

			// If there are saved and valid sizes in localstorage and the real children size is the same
			if (isStructureValid(structuredLocalStorageData) && structuredLocalStorageData.length === calculatedChildren.length) {
				for (let i = 0; i < calculatedChildren.length; i++) {
					calculatedChildren[i].width = structuredLocalStorageData[i];
					calculatedChildren[i].originalRatio = calculatedChildren[i].width;
				}
			}

			let counter: number = 1;

			// insert dragger between every splitter item
			const finalChildren: TChildElement[] = calculatedChildren.flatMap((value, index, array) => {
				if (index !== array.length - 1) {
					const arr: TChildElement[] = [
						value,
						{
							key: `key_${index}`,
							element: (
								<PointerDetectorReact
									onDown={() => {
										setGrabbedDraggerIndex(index);
										savedBodyCursor.current = document.body.style.cursor;
										document.body.style.cursor = "col-resize";
									}}
									onMove={onPointerMove}
									onUp={(p: Pointer) => {
										onPointerUp();
										setGrabbedDraggerIndex(-1);
										document.body.style.cursor = savedBodyCursor.current;
									}}
								>
									<DraggerStyled
										data-id={counter}
										className={ReactUtils.cls({isActive: index === grabbedDraggerIndex})}
									/>
								</PointerDetectorReact>
							),
							width: _draggerWidth,
							minWidth: _draggerWidth,
							type: "dragger",
						},
					];

					counter += 2;

					return arr;
				}

				return value;
			});

			setChildrenState(finalChildren);

			if (domChildren.length !== finalChildren.length) {
				requestAnimationFrame(() => {
					recalculateChildren();
				});
			}
		};

		recalculateChildren();
	}, [children, structuredLocalStorageData, grabbedDraggerIndex]);

	return (
		<SplitterV5Styled
			style={{
				gridTemplateColumns: childrenState
					.map((c) => (c?.type === "real" ? `minmax(${c?.minWidth ?? 0}px, ${(c?.width ?? 0) * 100}%)` : `${_draggerWidth}px`))
					.join(" "),
			}}
			ref={splitterRef}
		>
			{childrenState.map((c, i) => (
				<Fragment key={c.key}>{c?.element}</Fragment>
			))}
		</SplitterV5Styled>
	);
};

const SplitterV5Styled = styled.div`
	display: grid;
	min-height: 0;
	flex: 1;

	.mainpanel {
		position: relative;
	}
`;

const DraggerStyled = styled.div`
	${FlexCenterStyle};
	width: 2px;
	background-color: ${colorPalette.gray.c300};
	cursor: col-resize;
	z-index: 1;

	&:hover,
	&.isActive {
		background-color: ${colorPalette.primary.c500Primary};
	}
`;

import {inject, observer} from "mobx-react";
import * as React from "react";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {FocusLoss} from "../../utils/ui/focus/FocusLoss";
import type {Boundary} from "../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../data/models/BoundarySpaceMap";
import {XyiconFeature} from "../../generated/api/base";
import type {AppState} from "../../data/state/AppState";
import {ReactUtils} from "../utils/ReactUtils";
import {SVGIcon} from "./button/SVGIcon";
import {IconButton} from "./button/IconButton";

interface IBoundarySpaceMapDataSelectorProps {
	readonly appState?: AppState;
	readonly item: Boundary;
	readonly onClose: () => void;
	readonly closePortfolioWideSearch?: () => void;
	readonly className?: string;
	readonly headerMinimized?: boolean;
}

@inject("appState")
@inject((props, stores, context) => props) // https://github.com/mobxjs/mobx-react/issues/232
@observer
export class BoundarySpaceMapSelector extends React.Component<IBoundarySpaceMapDataSelectorProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private getSpaceNameById(spaceId: string) {
		return this.props.appState.actions.getFeatureItemById(spaceId, XyiconFeature.Space).name || "";
	}

	private getRows() {
		return [...this.props.item.boundarySpaceMaps]
			.toSorted((a: BoundarySpaceMap, b: BoundarySpaceMap) => StringUtils.sortIgnoreCase(a.spaceName, b.spaceName))
			.map((boundarySpaceMap: BoundarySpaceMap, index: number) => (
				<div
					key={index}
					className="boundarySpaceMapRow hbox alignCenter"
					onClick={(event: React.MouseEvent<HTMLDivElement>) => {
						event.stopPropagation(); // workaround for this: in portfoliowidesearch, when you click this, its parent is also getting clicked, and the details panel opens
						this.props.appState.actions.navigateToSpaceItem(boundarySpaceMap, true);
						this.props.closePortfolioWideSearch?.();
					}}
				>
					<div
						className="thumbnail"
						style={{backgroundImage: `url(${boundarySpaceMap.thumbnail})`}}
					/>
					<div className="info vbox">{<div>{this.getSpaceNameById(boundarySpaceMap.spaceId)}</div>}</div>
					<SVGIcon
						icon="pin"
						classNames="pin"
					/>
				</div>
			));
	}

	private onClose = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		this.props.onClose();
	};

	public override componentDidMount() {
		FocusLoss.listen(this._ref.current, this.props.onClose);
	}

	public override componentWillUnmount() {
		FocusLoss.stopListen(this._ref.current, this.props.onClose);
	}

	public override render() {
		return (
			<div
				className={ReactUtils.cls(`BoundarySpaceMapSelector window vbox ${this.props.className || ""}`, {minimized: this.props.headerMinimized})}
				ref={this._ref}
			>
				<div className="header">
					<div className="title">Merged with this boundary...</div>
					<IconButton
						className="closeBtn"
						icon="close"
						onClick={this.onClose}
						title="Close Window"
					/>
				</div>
				<div className="boundaryContainer">{this.getRows()}</div>
			</div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import {ReactUtils} from "../../../utils/ReactUtils";

interface ITabChildProps {
	readonly id?: string;
	readonly label?: string;
	readonly title?: string;
	readonly active?: boolean;
	readonly icon?: string;
	readonly disabled?: boolean;
	readonly children: React.ReactNode;
	readonly className?: string;
}

export const TabChildV5 = (props: ITabChildProps) => {
	const {active = false, disabled = false, children = <></>} = props;

	return (
		<TabChildStyled
			key={props.id + props.label + props.title + props.icon}
			className={ReactUtils.cls(`${props.className ?? ""}`, {"DetailsTab active": active, disabled})}
		>
			{children}
		</TabChildStyled>
	);
};

const TabChildStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

import styled from "styled-components";
import {useAppStore} from "../../../StateManager";
import {featureTitles} from "../../../data/state/AppStateConstants";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {ButtonV5} from "../button/ButtonV5";
import {colorPalette} from "../styles/colorPalette";
import type {View} from "../../../data/models/View";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {FLEXCENTER, fontWeight} from "../styles/styles";

interface IEmptyListViewProps {
	readonly onAddClick?: () => void;
	readonly feature?: XyiconFeature;
	readonly text?: React.ReactNode;
	readonly isDetailsTab?: boolean;
}

export const EmptyListViewV5 = (props: IEmptyListViewProps) => {
	const appState = useAppStore((state) => state.appState);
	const {feature, onAddClick, text, isDetailsTab} = props;
	const moduleName = featureTitles[feature] || "";
	const views: View[] = (appState.actions.getViews(feature) ?? []).toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));
	const hasPermission = appState.actions.hasAnyTypeTheGivenPermissionInModule(feature, Permission.Update);

	return (
		<EmptyListViewStyled
			className="EmptyListView"
			$isDetailsTab={isDetailsTab}
		>
			<div className="content">
				{([XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog].includes(feature) || (feature === XyiconFeature.Space && !isDetailsTab)) &&
				views.length === 0 ? (
					<p className="description">{text || `No views to display..`}</p>
				) : (
					<>
						<div className="description">
							{feature === XyiconFeature.Space ? <p>No {moduleName || ""}s</p> : ""}
							{text || `There are no ${feature === XyiconFeature.Space ? "" : moduleName || ""} items to list.`}
							{onAddClick && <span>Start by adding your first.</span>}
						</div>
						{onAddClick && (
							<ButtonV5
								label="Create"
								disabled={!hasPermission}
								className={`${moduleName}`}
								onClick={onAddClick}
							/>
						)}
					</>
				)}
			</div>
		</EmptyListViewStyled>
	);
};

const EmptyListViewStyled = styled.div<{$isDetailsTab?: boolean}>`
	${FLEXCENTER};
	height: ${(props) => (props.$isDetailsTab ? "100%" : "100vh")};
	width: 304px;
	padding: 16px;
	margin: auto;

	.content {
		width: 100%;

		div.description {
			text-align: center;
			line-height: 24px;

			p {
				font-weight: ${fontWeight.bold};
				margin-bottom: 16px;
			}

			span {
				${FLEXCENTER};
				width: 100%;
			}
		}

		.Space {
			background-color: ${colorPalette.primary.c500Primary};
			color: ${colorPalette.white};
		}

		.Button {
			background-color: ${colorPalette.primary.c500Primary};
			color: ${colorPalette.white};
			margin-top: 16px;
			height: 40px;

			&.disabled {
				color: ${colorPalette.gray.c200Light};
				border-color: ${colorPalette.gray.c200Light};
			}

			svg {
				color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;

import * as React from "react";
import {ReactUtils} from "../../../../../utils/ReactUtils";

interface IMultiLineLabelProps {
	readonly value: string;
	readonly divRef: any;
	readonly height: number;
	readonly onLabelStateChange?: (value: boolean) => void;
}

interface IMultiLineLabelState {
	extended: boolean;
}

export class MultiLineLabel extends React.PureComponent<IMultiLineLabelProps, IMultiLineLabelState> {
	constructor(props: IMultiLineLabelProps) {
		super(props);
		this.state = {
			extended: false,
		};
	}

	private onClick = (e: React.MouseEvent) => {
		e.stopPropagation();
		this.props.onLabelStateChange(!this.state.extended);
		this.setState({extended: !this.state.extended});
	};

	public override render() {
		const {value, divRef, height} = this.props;
		const {extended} = this.state;
		const rows = height / 16; // 16 is the standard lineheight

		return value ? (
			<div className={ReactUtils.cls("MultiLineLabel", {extended})}>
				<div
					className="content"
					dangerouslySetInnerHTML={{__html: value}}
					ref={divRef}
				/>
				{rows > 5 && (
					<div
						className="button naked"
						onClick={this.onClick}
					>{`Show ${extended ? "less" : "more"}`}</div>
				)}
			</div>
		) : (
			""
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {IModel, ISpaceItemModel} from "../../../data/models/Model";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import type {Catalog} from "../../../data/models/Catalog";
import {CatalogIconType, XyiconFeature} from "../../../generated/api/base";
import type {IIconConfig} from "../../modules/catalog/create/CatalogTypes";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {AppState} from "../../../data/state/AppState";
import {zIndex} from "../styles/styles";
import {Panel} from "../abstract/Panel";
import {CatalogItemPanelV5} from "../abstract/view/catalog/CatalogItemPanelV5";
import {LoaderStyled, LoaderV5} from "../loader/LoaderV5";
import {DetailsTabV5} from "./DetailsTabV5";

const CatalogItemPanelShadowDivStyle: React.CSSProperties = {
	zIndex: zIndex.contextOptions,
};

const CatalogItemPanelStyle: React.CSSProperties = {
	zIndex: zIndex.contextOptions + 1,
};

interface IDetailsContainerProps {
	readonly items: IModel[];
	readonly transport?: TransportLayer;
	readonly onClose: (closeAll?: boolean) => void;
	readonly closeWideSearchPanel: () => void;
	readonly appState?: AppState;
}

interface IDetailsContainerState {
	isCatalogEditPanelOpen: boolean;
	iconConfig: IIconConfig;
	editedCatalog: Catalog;
	isPortTemplateEditorOpen: boolean;
	isLoadingCatalogEditor: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class DetailsContainerV5 extends React.Component<IDetailsContainerProps, IDetailsContainerState> {
	// They're on top of each other, so we don't need to render more than 2, even if we introduce an opening/closing animation in the future
	private readonly _numberOfItemsToRender: number = 1;

	constructor(props: IDetailsContainerProps) {
		super(props);

		this.state = {
			isCatalogEditPanelOpen: false,
			iconConfig: null,
			editedCatalog: null,
			isPortTemplateEditorOpen: false,
			isLoadingCatalogEditor: false,
		};
	}

	private onCloseClick = () => {
		this.props.onClose(false);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE && !this.state.isCatalogEditPanelOpen) {
			this.onCloseClick();
		}
	};

	private onCatalogIconClick = async () => {
		const {transport, items} = this.props;

		if (!this.state.isLoadingCatalogEditor) {
			this.setState({
				isLoadingCatalogEditor: true,
			});

			const catalogs = items.filter((item) => item.ownFeature === XyiconFeature.XyiconCatalog);
			const catalog = catalogs[0] as Catalog;

			const iconConfig = await transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				isCatalogEditPanelOpen: true,
				isLoadingCatalogEditor: false,
			});
		}
	};

	private closeCreatePanel = () => {
		this.setState({
			isCatalogEditPanelOpen: false,
			editedCatalog: null,
			iconConfig: null,
		});
	};

	private onPortTemplateEditorChange = (value: boolean) => {
		this.setState({isPortTemplateEditorOpen: value});
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentDidUpdate(prevProps: Readonly<IDetailsContainerProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (prevProps.items.length !== this.props.items.length) {
			this.props.appState.isDetailsContainerOpened = !!this.props.items.length;
			if (this.props.items.length === 0) {
				this.setState({isCatalogEditPanelOpen: false});
			}
		}
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {items, closeWideSearchPanel} = this.props;
		const {isCatalogEditPanelOpen, editedCatalog, iconConfig, isPortTemplateEditorOpen} = this.state;
		const itemsToRender = items.filter((item: IModel, index: number) => item && index >= items.length - this._numberOfItemsToRender);

		return (
			<>
				{items.length > 0 && (
					<ShadowDivStyled
						className="shadowDiv"
						onClick={this.onCloseClick}
					/>
				)}
				<Panel
					isOpen={items.length > 0}
					style={{zIndex: zIndex.overlayDetailsPanel, height: "100%"}}
				>
					{itemsToRender.map((spaceItemModel: ISpaceItemModel, index: number) => {
						const detailsTabProps = {
							items: [spaceItemModel],
							feature: spaceItemModel.ownFeature,
							features: [spaceItemModel.ownFeature],
							isPortTemplateEditorOpen: isPortTemplateEditorOpen,
							insideDetailsContainer: true,
							setPortTemplateEditorOpen: this.onPortTemplateEditorChange,
							closeWideSearchPanel: closeWideSearchPanel,
							onCatalogIconEditClick: this.onCatalogIconClick,
						};

						return (
							<React.Fragment key={spaceItemModel.id}>
								<DetailsTabV5
									{...detailsTabProps}
									onCloseOverlayedDetaislPanel={this.onCloseClick}
								/>
							</React.Fragment>
						);
					})}
				</Panel>
				{this.state.isLoadingCatalogEditor && (
					<ShadowDivStyled
						className="shadowDiv"
						style={CatalogItemPanelShadowDivStyle}
					>
						<LoaderV5 />
					</ShadowDivStyled>
				)}
				{isCatalogEditPanelOpen && editedCatalog && iconConfig && (
					<>
						<ShadowDivStyled
							className="shadowDiv"
							onClick={this.closeCreatePanel}
							style={CatalogItemPanelShadowDivStyle}
						/>
						<CatalogItemPanelV5
							onClose={this.closeCreatePanel}
							catalog={this.state.editedCatalog}
							iconConfig={this.state.iconConfig}
							mode="edit"
							shape={this.state.editedCatalog.iconCategory === CatalogIconType.ModelParameter ? "Custom Shape" : "Default Shape"}
							step={this.state.editedCatalog.iconCategory === CatalogIconType.ModelParameter ? "Item properties" : "Select model"}
							style={CatalogItemPanelStyle}
						/>
					</>
				)}
			</>
		);
	}
}

export const ShadowDivStyled = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: ${zIndex.shadowDiv};

	${LoaderStyled} {
		color: white;
	}
`;

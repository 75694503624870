import styled from "styled-components";
import {baseDistance, radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

export const AdvancedFilterField = styled.div`
	&.AdvancedFilterField {
		margin: 0px;
		padding: ${baseDistance.sm};
		background: ${colorPalette.gray.c100};
		border-radius: ${radius.sm};

		.criterias {
			display: flex;
			flex-direction: column;
			width: 100%;
			grid-gap: ${baseDistance.sm};

			.header {
				width: 64px;
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #c8c8c8;
				border-radius: 4px;
				font-size: 12px;
			}

			input {
				width: 100%;
				padding: 8px;
				border-radius: ${radius.sm};
				background: ${colorPalette.white};
			}

			span.delete {
				svg.icon {
					width: 10px;
					height: 10px;
				}
			}
		}

		.deleteColumn {
			align-items: center;
			display: flex;
			padding: 10px;

			span.delete {
				svg.icon {
					top: 0px;
					width: 12px;
					height: 12px;
				}
			}
		}
	}
`;

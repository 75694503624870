import * as React from "react";
import {DateTimeInput} from "../../../../../widgets/input/datetime/DateTimeInput";

interface ITimeParamProps {
	readonly param: string;
	onChange(value: string): void;
}

export class TimeParam extends React.Component<ITimeParamProps> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<DateTimeInput
				value={param || ""}
				defaultValue={param || ""}
				onChange={(value) => onChange(value)}
				format="time"
			/>
		);
	}
}

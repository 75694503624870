import * as React from "react";
import {inject, observer} from "mobx-react";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import type {AppState} from "../../../../../data/state/AppState";
import {SearchAddAndListItems} from "../../../../widgets/SearchAddAndListItems";
import {User} from "../../../../../data/models/User";
import {Initials} from "../../../../widgets/Initials";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {Button} from "../../../../widgets/button/Button";
import {XyiconFeature} from "../../../../../generated/api/base/models/XyiconFeature";

interface IUserGroupUsersProps {
	readonly userGroup: UserGroup;
	readonly onChange?: () => void;
	readonly appState?: AppState;
}

interface IUserGroupUsersState {
	search: string;
}

@inject("appState")
@observer
export class UserGroupUsers extends React.PureComponent<IUserGroupUsersProps, IUserGroupUsersState> {
	constructor(props: IUserGroupUsersProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private onUserAdded = (user: User) => {
		this.props.userGroup.addUser(user.id, this.props.appState);
		this.props.onChange?.();
	};

	private onUserRemoved = (user: User) => {
		this.props.userGroup.removeUser(user.id, this.props.appState);
		this.props.onChange?.();
	};

	private onSearch = (value: string) => {
		this.setState({search: value});
	};

	private renderAddedListItemUser = (user: User) => {
		return (
			<>
				<div className="avatar">
					{user.profileFileName ? (
						<img
							src={user.profileFileName}
							alt={`${user.fullName} profile image`}
						/>
					) : (
						<Initials name={user.fullName || user.email} />
					)}
				</div>
				<div className="vbox">
					<div className="name">{user.fullName}</div>
					<div className="email">{user.email}</div>
				</div>
			</>
		);
	};

	public renderAddListItemUser = (user: User) => {
		return (
			<>
				<div className="avatar">
					{user.profileFileName ? (
						<img
							src={user.profileFileName}
							alt={`${user.fullName} profile image`}
						/>
					) : (
						<Initials name={user.fullName || user.email} />
					)}
				</div>
				<div className="vbox flex_1 labels">
					<div className="name">{user.fullName}</div>
					<div className="email">{user.email}</div>
				</div>
				<Button
					label="Add"
					title="Add"
					className="primary rounded dark"
				/>
			</>
		);
	};

	public override render() {
		const {userGroup, appState} = this.props;
		const {search} = this.state;

		const addedUsers = userGroup.userIds.map((userId) => appState.actions.findUser(userId)).filter((user) => !!user);

		let users = appState.actions.getList<User>(XyiconFeature.User).filter((user) => !addedUsers.find((addedUser) => addedUser.id === user.id));

		if (search) {
			users = users.filter((user) => User.search(user, search));
		}

		return (
			<ToggleContainer
				title="Users in this group"
				open={true}
				noPadding={true}
			>
				<SearchAddAndListItems
					sections={[
						{
							items: users,
							feature: XyiconFeature.User,
							sortFunction: (a: User, b: User) => StringUtils.sortIgnoreCase(a.fullName, b.fullName),
							renderAddListItem: this.renderAddListItemUser,
							renderAddedListItem: this.renderAddedListItemUser,
							onItemAdd: this.onUserAdded,
							onItemRemove: this.onUserRemoved,
							lockItems: userGroup.data.isSystem,
						},
					]}
					searchTextPlaceholder="Search for username or email address to add to the group"
					addedItems={addedUsers}
					search={search}
					onSearch={this.onSearch}
					prohibitSearch={userGroup.data.isSystem}
				/>
			</ToggleContainer>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {App} from "../../../../../../App";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../../data/models/Catalog";
import type {Boundary} from "../../../../../../data/models/Boundary";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {Functions} from "../../../../../../utils/function/Functions";
import {PointerDetectorReact} from "../../../../../interaction/PointerDetectorReact";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import type {AppState} from "../../../../../../data/state/AppState";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {DomPortal} from "../../../../abstract/portal/DomPortal";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {Permission, XyiconFeature} from "../../../../../../generated/api/base";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {InfoBubble} from "../../../../abstract/common/infobutton/InfoBubble";
import type {TransformObj} from "../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {CardLayoutToolTip} from "./CardLayoutToolTip";

export interface ISpaceItemProps {
	readonly item: Catalog | Xyicon | Boundary | BoundarySpaceMap;
	readonly className?: string;
	readonly queryString: string;
	readonly onPointerDown: (pointer: Pointer) => void;
	readonly onPointerMove: (pointer: Pointer) => void;
	readonly onPointerUp: (pointer: Pointer) => void;
	readonly app?: App;
	readonly appState?: AppState;
}

interface ISpaceItemState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj | null;
}

@inject("app")
@inject("appState")
@observer
export class SpaceItem extends React.Component<ISpaceItemProps, ISpaceItemState> {
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;

	public static readonly defaultProps: Partial<ISpaceItemProps> = {
		className: "",
		onPointerDown: Functions.emptyFunction,
		onPointerMove: Functions.emptyFunction,
		onPointerUp: Functions.emptyFunction,
	};

	constructor(props: ISpaceItemProps) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
		};
	}

	private getEmbeddedCounter() {
		const item = this.props.item;

		if (item.ownFeature === XyiconFeature.Xyicon) {
			const embeddedNumber = item.embeddedXyicons.length;

			if (embeddedNumber > 0) {
				return <div className="embeddedCounter">{embeddedNumber}</div>;
			}
		}

		return null;
	}

	private getFavoriteIcon() {
		const item = this.props.item;

		if (item.ownFeature === XyiconFeature.XyiconCatalog && item.isFavorite) {
			return (
				<SVGIcon
					icon="dogear-favorite"
					classNames="dogear"
				/>
			);
		}

		return null;
	}

	public override componentDidUpdate(prevProps: ISpaceItemProps, prevState: ISpaceItemState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	}

	private openTooltip = () => {
		if (this._timeOutId) {
			clearTimeout(this._timeOutId);
		}

		this._timeOutId = window.setTimeout(() => {
			this.setState({
				isToolTipOpen: true,
			});
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	private renderCustomRow(data: {key: string; value: string}) {
		const {queryString} = this.props;

		let html = "";

		if (data.value !== "") {
			html = data.key + (data.key !== "" ? ": " : "") + StringUtils.regexHighlight(data.value, queryString);
		}

		return (
			<div
				className="typeName"
				dangerouslySetInnerHTML={{
					__html: html,
				}}
			/>
		);
	}

	private get userXyiconPermission() {
		const {appState, item} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	}

	private get hasSpaceItemPermission() {
		const {item} = this.props;

		return item.ownFeature === XyiconFeature.Boundary || this.userXyiconPermission > Permission.View;
	}

	public override componentWillUnmount() {
		clearTimeout(this._timeOutId);
	}

	public override render() {
		const {item, appState, app, queryString} = this.props;

		const thumbnailStyle: React.CSSProperties = {
			backgroundImage: `url('${item.thumbnail}')`,
			transform: (item as Xyicon).backgroundTransform || "",
		};

		const rows = appState.actions.getRowsForCardLayout(queryString, item);

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: this.state.toolTipTransform?.translate,
		};

		return (
			<PointerDetectorReact
				onDown={this.hasSpaceItemPermission && this.props.onPointerDown}
				onMove={this.hasSpaceItemPermission && this.props.onPointerMove}
				onUp={this.hasSpaceItemPermission && this.props.onPointerUp}
			>
				<div
					className={ReactUtils.cls(`SpaceItem hbox ${this.props.className || ""}`, {
						grabbable: this.props.onPointerMove !== Functions.emptyFunction && this.hasSpaceItemPermission,
						noPermission: !this.hasSpaceItemPermission,
					})}
					data-id={item.id}
					data-backgroundimage={thumbnailStyle.backgroundImage}
					ref={this._parent}
					onMouseOver={this.openTooltip}
					onMouseLeave={this.closeTooltip}
				>
					<div className="thumbnailContainer">
						<div
							className="thumbnail"
							style={thumbnailStyle}
						/>
						{this.getEmbeddedCounter()}
						{this.getFavoriteIcon()}
					</div>
					<div className="description vbox">
						<div className="typeName">{this.renderCustomRow(rows[0])}</div>
						{this.renderCustomRow(rows[1])}
						{this.renderCustomRow(rows[2])}
					</div>
					{this.state.isToolTipOpen && (
						<DomPortal destination={app.modalContainer}>
							{this.hasSpaceItemPermission ? (
								<CardLayoutToolTip
									item={this.props.item}
									divRef={this._floating}
									style={inlineStyle}
									className={ReactUtils.cls({left: this.state.toolTipTransform?.horizontal === HorizontalAlignment.left})}
									queryString={queryString}
								/>
							) : (
								<InfoBubble
									divRef={this._floating}
									content={
										<>
											<SVGIcon icon="locked" />
											<div>You do not have permission to use this catalog item.</div>
										</>
									}
									style={inlineStyle}
									className="SpaceItem"
								/>
							)}
						</DomPortal>
					)}
				</div>
			</PointerDetectorReact>
		);
	}
}

import type {Texture} from "three";
import {TextureLoader} from "three";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {XHRLoader} from "../../../../../../../utils/loader/XHRLoader";
import type {SupportedFontName} from "../../../../../../../data/state/AppStateTypes";
import type {IChar, IFontData} from "./TextUtils";
import {getScaleCorrection} from "./TextUtils";

const getKerningKey = (leftCharId: number, rightCharId: number) => `${leftCharId}_${rightCharId}`;

export class MSDFFont {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _name: SupportedFontName;
	private _fontData: IFontData;
	private _atlasScale: {
		x: number;
		y: number;
	};
	private _glyphs: Map<string, IChar> = new Map();
	private _kernings: Map<string, number> = new Map();
	private _textureAtlas: Texture;

	constructor(spaceViewRenderer: SpaceViewRenderer, name: SupportedFontName) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._name = name;
	}

	public init() {
		return new Promise<MSDFFont>(async (resolve, reject) => {
			this._fontData = await XHRLoader.loadAsync<IFontData>({
				url: `src/assets/fonts/MSDF/${this._name}-Regular-msdf.json`,
				method: XHRLoader.METHOD_GET,
				json: true,
			});

			this.parseFont(this._fontData);

			new TextureLoader().load(`src/assets/fonts/MSDF/${this._name}-Regular.png`, (texture: Texture) => {
				this._textureAtlas = texture;
				resolve(this);
			});
		});
	}

	public getLineHeight(fontSize: number) {
		const scaleCorretion = getScaleCorrection(fontSize, this._spaceViewRenderer);

		return this._fontData.common.lineHeight * scaleCorretion;
	}

	private parseFont(fontData: IFontData) {
		this._atlasScale = {
			x: fontData.common.scaleW,
			y: fontData.common.scaleH,
		};

		for (const char of fontData.chars) {
			if (!this._glyphs.get(char.char)) {
				this._glyphs.set(char.char, char);
			}
		}

		for (const kerning of fontData.kernings) {
			const kerningKey = getKerningKey(kerning.first, kerning.second);

			if (this._kernings.get(kerningKey) == undefined) {
				this._kernings.set(kerningKey, kerning.amount);
			}
		}
	}

	public getKerning(leftCharId: number, rightCharId: number) {
		return this._kernings.get(getKerningKey(leftCharId, rightCharId)) ?? 0;
	}

	public getGlyph(char: string) {
		let glyph = this._glyphs.get(char);

		if (!glyph) {
			console.warn(`Character is not part of the charset: ${char}, CharCode: ${char.charCodeAt(0)}`);
			glyph = this._glyphs.get("_");
		}

		return glyph;
	}

	public get fontData() {
		return this._fontData;
	}

	public get textureAtlas() {
		return this._textureAtlas;
	}

	public get atlasScale() {
		return this._atlasScale;
	}
}

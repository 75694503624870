import * as React from "react";
import {useEffect, useState} from "react";
import {runInAction} from "mobx";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {TimeUtils} from "../../../../../../utils/TimeUtils";

interface IToolTogglerProps {
	readonly icon: string;
	readonly title: string;
	readonly active: boolean;
	readonly setActive: (value: boolean) => void;
	readonly notificationNumber?: number;
}

export const ToolToggler = (props: IToolTogglerProps) => {
	const {active, notificationNumber, icon, title, setActive} = props;
	const [animate, setAnimate] = useState(false);

	const onClick = () => {
		toggle();
	};

	const toggle = () => {
		setActive(!active);
	};

	useEffect(() => {
		runInAction(async () => {
			setAnimate(true);
			await TimeUtils.wait(500);
			setAnimate(false);
		});
	}, [props.notificationNumber]);

	return (
		<div
			className={ReactUtils.cls(`btn ${title}`, {active: active})}
			title={title}
			data-title={title}
			onClick={onClick}
		>
			<SVGIcon icon={icon} />
			{MathUtils.isValidNumber(notificationNumber) && notificationNumber !== 0 && (
				<div className={ReactUtils.cls("notificationNumber", {animate})}>{notificationNumber}</div>
			)}
		</div>
	);
};

export class CreateSpaceUtils {
	public static triggerSpaceCreatePopup() {
		const createSpaceButton = document.querySelector('[data-cy="Button.CREATE"]') as HTMLElement;

		if (createSpaceButton) {
			createSpaceButton.click();
		} else {
			console.warn("No button with title Create Space");
		}
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IPopupWindowConfig} from "../../abstract/popups/PopupWindow";
import {IconButton} from "../../../widgets/button/IconButton";
import {Report} from "../../../../data/models/Report";
import {Button} from "../../../widgets/button/Button";
import {SimpleTable} from "../../../widgets/table/SimpleTable";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ReportPanel} from "../create/ReportPanel";
import {MultiActionButton} from "../../../widgets/button/multiaction/MultiActionButton";
import type {ExportFormat} from "../../../../data/exporters/BaseExporter";
import {PromptWindow} from "../../abstract/popups/PromptWindow";
import {XyiconFeature, Permission, FieldDataType} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {SearchField} from "../../../widgets/input/search/SearchField";

interface IRunReportPageProps {
	readonly report: Report;
	readonly onRunReport: (report: Report) => void;
	readonly onClose: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IRunReportPageState {
	search: string;
	editing: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class RunReportPage extends React.Component<IRunReportPageProps, IRunReportPageState> {
	private _table = React.createRef<SimpleTable<string[]>>();
	private _editingCount = 0;

	constructor(props: IRunReportPageProps) {
		super(props);
		this.state = {
			search: "",
			editing: false,
		};
	}

	private onSearchInput = (value: string) => {
		this.setState({
			search: value,
		});
	};

	private getHeaders() {
		const reportData = this.props.report.reportData;

		if (reportData.length > 0) {
			const reportHeader = reportData[0];

			return reportHeader.map((title: string, index: number) => {
				return {
					id: String(index),
					title: title,
				};
			});
		} else {
			return [];
		}
	}

	private getFields = (row: string[]) => {
		const {report, appState} = this.props;

		return [...report.displayedFields, ...report.displayedLinkedFields]
			.map((fieldData, index) => {
				if (index < row.length) {
					const field = appState.actions.getFieldByRefId(fieldData.field);

					if (field) {
						if (field.dataType === FieldDataType.Numeric) {
							const cell = `${row[index] ?? ""}`;
							const values = cell.split("\n");

							return values?.map((v) => appState.actions.formatValue(v, fieldData.field) as string).join("\n");
						}
						let value = (appState.actions.formatValue(row[index], fieldData.field) as string) || row[index];

						if (field.dataType === FieldDataType.Boolean) {
							value = row[index];
						}

						return value;
					} else {
						return "";
					}
				}
				return null;
			})
			.filter((field) => field !== null);
	};

	private onEditClick = () => {
		this.props.report.startEditing();
		this._editingCount++;

		this.setState({
			editing: true,
		});
	};

	private onCloseEdit = (reportId?: string) => {
		this.setState({
			editing: false,
		});

		if (!reportId) {
			// If reportId is not given, this is a cancel, otherwise it's a save / create
			this.props.report.cancelEditing();
		}
	};

	private onSaveClick = () => {
		const {report, transport} = this.props;

		return transport.services.feature.updateReport(report);
	};

	// Note little copy-paste from ReportWizard
	private onSaveAsClick = async () => {
		const {report, transport} = this.props;
		const reportName = report.name;

		const placeholder = `${reportName} - copy`;

		const config: IPopupWindowConfig = {ok: "Save"};

		let name = await PromptWindow.open("Please enter the new name you want this to be saved with", `Save ${reportName} As`, placeholder, "", config);

		if (name !== null) {
			name = name || placeholder;
			const newReport = report.duplicate(name);

			await transport.services.feature.create(newReport.serializeData(), XyiconFeature.Report);
			this.props.onClose();
		}
	};

	private onRunClick = () => {
		const {report, transport} = this.props;

		transport.services.feature.runSavedReport(report);
	};

	private onExportClick = (format: ExportFormat) => {
		let data = this.props.report.reportData;

		if (this._table.current && data.length > 2) {
			const headers = data[0];

			data = [headers, ...this._table.current.sortData(data.slice(1))];
		}
		this.props.report.exportReport(format, data, this.props.report);
	};

	public override render() {
		const {report, onClose, appState} = this.props;
		const {search, editing} = this.state;

		const data = report.reportData?.slice(1) || [];
		const permission = Report.getPermission(report, appState);

		return (
			<div className="RunReportPage">
				<div className="backHeader">
					<div className="hbox">
						<IconButton
							icon="arrow"
							className="back"
							onClick={onClose}
						/>
						<h4 className="title">Reports</h4>
					</div>
					<IconButton
						icon="close"
						onClick={onClose}
					/>
				</div>
				<div className="content vbox flex1">
					<div className="header hbox">
						<div className="title">
							{report.name}
							<span className="count">{report.reportLoaded ? `(${data.length} records)` : "(Loading...)"}</span>
						</div>
						<div className="buttons hbox">
							{!(permission < Permission.Update || !report.isUserDefined) && (
								<Button
									className="secondary"
									label="Edit"
									onClick={this.onEditClick}
								/>
							)}
							<MultiActionButton
								className="primary"
								options={[
									...(permission >= Permission.Update
										? [
												{
													id: "saveAndRun",
													label: "Save",
													onClick: this.onSaveClick,
												},
											]
										: []),
									...(Report.canCreateReports(appState)
										? [
												{
													id: "saveAs",
													label: "Save As",
													onClick: this.onSaveAsClick,
												},
											]
										: []),
									{
										id: "run",
										label: "Run",
										onClick: this.onRunClick,
									},
								]}
							/>
							<MultiActionButton
								className="primary"
								options={[
									{
										id: "downloadAsExcel",
										label: "Download as Excel",
										onClick: () => this.onExportClick("excel"),
									},
									{
										id: "downloadAsCsv",
										label: "Download as CSV",
										onClick: () => this.onExportClick("csv"),
									},
								]}
							/>
							<SearchField
								value={search}
								onInput={this.onSearchInput}
							/>
						</div>
					</div>
					<SimpleTable
						ref={this._table}
						data={data}
						headers={this.getHeaders()}
						getFields={this.getFields}
						tableSearchQuery={search}
						checkboxColumn={false}
						loading={!report.reportLoaded}
						errorMessage={report.errorMessage}
						isCellContentsWrappingOn={true}
					/>
				</div>

				<div className={ReactUtils.cls("createPanel", {open: editing})}>
					<ReportPanel
						key={this._editingCount} // Use key to reset state (index)
						report={report}
						onRunReport={this.props.onRunReport}
						onClose={this.onCloseEdit}
					/>
				</div>
			</div>
		);
	}
}

import styled from "styled-components";
import type {MouseEvent} from "react";
import LabelsIcon from "../../../icons/label-icon.svg?react";
import LayersIcon from "../../../icons/layer-icon.svg?react";
import FilterIcon from "../../../icons/filter.svg?react";
import FormattingIcon from "../../../icons/formatting-icon.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {FlexCenterStyle} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {TSpaceEditorPanelTypes} from "../SpaceEditorPanelV5";
import {Functions} from "../../../../../utils/function/Functions";

const panelTypes: TSpaceEditorPanelTypes[] = ["filter", "layers", "labels", "formatting"];

const getIconComponentByPanelType = (panelType: TSpaceEditorPanelTypes) => {
	switch (panelType) {
		case "filter":
			return FilterIcon;
		case "layers":
			return LayersIcon;
		case "labels":
			return LabelsIcon;
		case "formatting":
			return FormattingIcon;
	}
};

interface ISpaceViewBarProps {
	readonly onOpenPanelClick: (type: TSpaceEditorPanelTypes | null) => void;
	readonly activePanel: TSpaceEditorPanelTypes;
}

export const SpaceViewBarV5 = (props: ISpaceViewBarProps) => {
	const {activePanel, onOpenPanelClick} = props;

	return (
		<SpaceViewBarStyled>
			{panelTypes.map((panelType) => (
				<IconButtonV5
					key={panelType}
					isActive={activePanel === panelType}
					IconComponent={getIconComponentByPanelType(panelType)}
					onClick={Functions.emptyFunction}
					onMouseDown={(event: MouseEvent) => {
						event.stopPropagation();
						onOpenPanelClick(activePanel === panelType ? null : panelType);
					}}
				/>
			))}
		</SpaceViewBarStyled>
	);
};

const SpaceViewBarStyled = styled.div`
	${FlexCenterStyle};
	gap: 8px;
	position: absolute;
	z-index: 2;
	top: 16px;
	right: 16px;
	padding: 8px;
	background-color: ${colorPalette.white};
	border-radius: 8px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
`;

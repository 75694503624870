import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {Boundary} from "../../../../../../../data/models/Boundary";
import type {AppState} from "../../../../../../../data/state/AppState";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import type {Type} from "../../../../../../../data/models/Type";
import {ConfirmWindow} from "../../../../popups/ConfirmWindow";
import {Permission} from "../../../../../../../generated/api/base";
import {DefaultFieldsUtils} from "./DefaultFieldsUtils";

interface IBoundaryDefaultFieldsProps {
	readonly item: Boundary;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class BoundaryDefaultFields extends React.Component<IBoundaryDefaultFieldsProps> {
	private onTypeChange = async (type: Type) => {
		const {item, appState} = this.props;
		const {spaceViewRenderer} = appState.app;

		const title = "Confirm boundary type change";
		const message =
			"Once you change the boundary type, fields (and data) not assigned to the new boundary type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed) {
			DefaultFieldsUtils.changeTypeOfBoundary(item, type, spaceViewRenderer);
		}
	};

	private get hasPermission() {
		const {item, appState} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, item.ownFeature) >= Permission.Update;
	}

	public override render() {
		const {item, appState} = this.props;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field
					className="TypeSelector"
					label="Type"
				>
					<SelectInput
						options={this.props.appState.types[item.ownFeature]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						searchBar={true}
						onChange={this.onTypeChange}
						disabled={!this.hasPermission}
					/>
				</Field>
			</>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {ListBuilderInput} from "../../../../../widgets/input/listbuilder/ListBuilderInput";
import type {ISingleSelectFieldSettingsDefinition, ListObject} from "../../../../../../data/models/field/datatypes/SingleSelect";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

@observer
export class SingleSelectFieldSettings extends React.Component<IFieldDataTypeSettingsProps> {
	public override render() {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as ISingleSelectFieldSettingsDefinition;
		// Since the ListBuilderInput accepts an array as a list we need to convert the choiceList to an array of strings
		// Also when saving the data we need to convert the array of strings back to an array of objects

		const updateSingleSelectList = (value: ListObject[]) => {
			settings.choiceList = value;
			onChange();
		};

		return (
			<Field label="Items In Dropdown">
				<ListBuilderInput
					list={settings.choiceList}
					onChange={updateSingleSelectList}
				/>
			</Field>
		);
	}
}

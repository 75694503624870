import * as React from "react";
import {inject, observer} from "mobx-react";
import {TabChild} from "../../../../widgets/tab/TabChild";
import {TabView} from "../../../../widgets/tab/TabView";
import {Button} from "../../../../widgets/button/Button";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import type {Navigation} from "../../../../../Navigation";
import type {PortfolioPermissionSetsDto, UpdatePortfolioPermissionSetRequest} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {AppState} from "../../../../../data/state/AppState";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {SearchField} from "../../../../widgets/input/search/SearchField";
import {PopupUtils} from "../../../abstract/popups/PopupUtils";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {CreatePermissionSetPanel} from "./CreatePermissionSetPanel";
import {ModulePermissions} from "./ModulePermissions";
import {PermissionSetItem} from "./PermissionSetItem";

interface IPermissionSetsProps {
	readonly param2: string;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
}

interface IPermissionSetsState {
	creating: boolean;
	selectedId: string;
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PermissionSets extends React.Component<IPermissionSetsProps, IPermissionSetsState> {
	private _container = React.createRef<HTMLDivElement>();
	private _isDeletePopupWindowOpen = false;

	constructor(props: IPermissionSetsProps) {
		super(props);
		this.state = {
			creating: false,
			selectedId: null,
			searchQuery: "",
		};
	}

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.setState({creating: false});
				break;
		}
	};

	private onAddPermissionSetClick = () => {
		this.setState({creating: true});
	};

	private onChangeSettingsTab = (id: string) => {
		const parts = StringUtils.decomposeParts(location.hash).map((part) => part.value);

		parts[3] = id;
		const hash = parts.join("/");

		this.props.navigation.go(hash);
	};

	private onCloseCreate = (createdId?: string) => {
		this.setState({
			creating: false,
			selectedId: createdId || this.state.selectedId,
		});
	};

	private onSelect = (id: string) => {
		this.setState({
			selectedId: id,
		});
	};

	private onDeleteClick = async (id: string) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await PopupUtils.getDeleteConfirmationPopup(XyiconFeature.PermissionSet, 1);

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const item = this.props.appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

				this.deleteItems(item);
			}
		}
	};

	private async deleteItems(item: PermissionSet) {
		const items = [];

		items.push(item);

		try {
			await this.props.transport.appState.actions.deleteItems(items, XyiconFeature.PermissionSet);
		} catch (error) {
			console.log(error);
		}
	}

	private onPermissionSetSave = async (id: string, name: string) => {
		const permissionSet = this.props.appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

		try {
			permissionSet.name = name;

			const params: UpdatePortfolioPermissionSetRequest = {
				name: name,
				portfolioPermissionSetID: permissionSet.id,
				portfolioFieldPermissions: permissionSet.portfolioFieldPermissions,
				portfolioPermission: permissionSet.portfolioPermission,
				featurePermissions: permissionSet.featurePermissions,
			};

			const {result, error} = await this.props.transport.requestForOrganization<PortfolioPermissionSetsDto>({
				url: "portfoliopermissionsets/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			if (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	public override render() {
		const {param2, appState} = this.props;
		const {selectedId, searchQuery} = this.state;
		const activeTab = param2 || "types";

		const permissionSets = appState.actions.getList<PermissionSet>(XyiconFeature.PermissionSet);
		const selected = permissionSets.find((set) => set.id === selectedId);

		return (
			<div
				ref={this._container}
				className="PermissionSets"
			>
				<div className="hbox flex_1">
					<div className="setList">
						<h4>Permission sets</h4>
						<SearchField onInput={(value) => this.setState({searchQuery: value})} />
						<Button
							label="+"
							className="dashed"
							onClick={this.onAddPermissionSetClick}
						/>
						<div className="list">
							{permissionSets
								.filter((permissionSet) => StringUtils.containsIgnoreCase(permissionSet.name, searchQuery))
								.map((permissionSet) => (
									<PermissionSetItem
										key={permissionSet.id}
										id={permissionSet.id}
										selected={selectedId === permissionSet.id}
										onSelect={this.onSelect}
										onDelete={this.onDeleteClick}
										onSave={this.onPermissionSetSave}
									/>
								))}
						</div>
					</div>
					{selected && (
						<TabView
							className="settingsTab"
							selectedTabId={activeTab}
							onChangeSelectedTabId={this.onChangeSettingsTab}
						>
							<TabChild
								id="portfolios"
								label="Portfolios"
								title="Portfolios"
							>
								<ModulePermissions
									feature={XyiconFeature.Portfolio}
									permissionSet={selected}
								/>
							</TabChild>
							<TabChild
								id="spaces"
								label="Spaces"
								title="Spaces"
							>
								<ModulePermissions
									feature={XyiconFeature.Space}
									permissionSet={selected}
								/>
							</TabChild>
							<TabChild
								id="boundaries"
								label="Boundaries"
								title="Boundaries"
							>
								<ModulePermissions
									feature={XyiconFeature.Boundary}
									permissionSet={selected}
								/>
							</TabChild>
							<TabChild
								id="xyicons"
								label="Xyicons"
								title="Xyicons"
							>
								<ModulePermissions
									feature={XyiconFeature.Xyicon}
									permissionSet={selected}
								/>
							</TabChild>
						</TabView>
					)}
				</div>
				{
					<CreatePermissionSetPanel
						onClose={this.onCloseCreate}
						open={this.state.creating}
					/>
				}
			</div>
		);
	}
}

import {MathUtils} from "three";
import styled from "styled-components";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import ChevronLeftIcon from "../../icons/chevron-left.svg?react";
import ChevronsLeftIcon from "../../icons/chevrons-left.svg?react";
import ChevronRightIcon from "../../icons/chevron-right.svg?react";
import ChevronsRightIcon from "../../icons/chevrons-right.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {NumberInputV5} from "../../details/datatypes/numeric/NumberInputV5";
import {FlexCenterStyle, fontSize, fontWeight, radius} from "../../styles/styles";

interface ITableFooterProps {
	readonly startIndex: number;
	readonly lastIndex: number;
	readonly totalCount: number;
	readonly itemsPerPage: number;
	readonly currentPage: number;
	readonly onPageChange: (index: number) => void;
}

export const TableFooterV5 = (props: ITableFooterProps) => {
	const {startIndex, lastIndex, totalCount, itemsPerPage, currentPage} = props;

	const selectPage = (index: number) => {
		props.onPageChange(index);
	};

	const onChangePage = (value: number) => {
		const clampedValue = MathUtils.clamp(value, 1, numberOfPages);

		if (!isNaN(clampedValue) && clampedValue - 1 !== currentPage) {
			selectPage(clampedValue - 1);
		}
	};

	const numberOfPages = Math.ceil(totalCount / itemsPerPage);
	const firstPageIndex = 0;
	const lastPageIndex = numberOfPages - (numberOfPages === 0 ? 0 : 1);

	return (
		<TableFooterStyled className="TableFooter">
			<span>
				{`${totalCount === 0 ? 0 : startIndex + 1}-${totalCount === 0 ? 0 : lastIndex + 1}`}&nbsp;of&nbsp;
				<span data-cy="TotalTableRowCount">{`${totalCount}`}</span>&nbsp;Items
			</span>
			<div className="pagination">
				<span className="pages">
					Page&nbsp;{currentPage + 1}&nbsp;of&nbsp;<span data-cy="TotalTableRowCount">{numberOfPages}</span>
				</span>
				{currentPage > firstPageIndex && (
					<IconButtonV5
						IconComponent={ChevronsLeftIcon}
						onClick={() => selectPage(firstPageIndex)}
					/>
				)}
				{currentPage > firstPageIndex && (
					<IconButtonV5
						IconComponent={ChevronLeftIcon}
						onClick={() => selectPage(props.currentPage - 1)}
					/>
				)}
				<NumberInputV5
					className="pagenumber"
					min={firstPageIndex + 1}
					max={lastPageIndex + 1}
					decimals={0}
					value={currentPage + 1}
					onChange={(value) => onChangePage(value)}
					isUpdateOnEnter={true}
				/>
				{currentPage < lastPageIndex && (
					<IconButtonV5
						IconComponent={ChevronRightIcon}
						onClick={() => selectPage(props.currentPage + 1)}
					/>
				)}
				{currentPage < lastPageIndex && (
					<IconButtonV5
						IconComponent={ChevronsRightIcon}
						onClick={() => selectPage(lastPageIndex)}
					/>
				)}
			</div>
		</TableFooterStyled>
	);
};

const TableFooterStyled = styled.div`
	${FlexCenterStyle}
	justify-content: space-between;
	color: ${colorPalette.gray.c950};

	.pagination {
		display: flex;
		align-items: center;
		.pages {
			margin: 0 8px;
		}

		.IconButton {
			margin: 0 4px;
		}

		.pagenumber {
			${FlexCenterStyle}
			margin: 0 4px;
			width: 24px;
			height: 24px;
			border: 1px solid #1e88e5;
			border-radius: ${radius.sm};
			font-size: ${fontSize.sm};
			font-weight: ${fontWeight.bold};
			color: ${colorPalette.gray.c950};
			outline: none;
			justify-content: center;
			cursor: text;
			text-align: center;

			&:hover {
				color: ${colorPalette.gray.c950};
				background-color: ${colorPalette.gray.c200Light};
				border: none;
			}
		}
	}
`;

import * as React from "react";
import {observer} from "mobx-react";
import {observable, makeObservable} from "mobx";
import {ToggleContainerV5} from "../../widgets/ToggleContainerV5/ToggleContainerV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import type {IFilterRowFilter} from "../../../../data/models/filter/Filter";
import {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import type {FieldDataType} from "../../../../generated/api/base";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {IDateFieldFormat} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import {ReactUtils} from "../../../utils/ReactUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import type {IFilterOperatorConfigV5} from "../operator/FilterOperatorsV5";
import {FilterOperatorsV5} from "../operator/FilterOperatorsV5";
import {SpecialFilterField} from "./SpecialFilterField.styled";

interface ISpecialFilterFieldProps {
	readonly field: IFieldAdapter;
	readonly datatype: FieldDataType.DateTime;
	readonly fieldRefId: string;
	readonly filter: IFilterRowFilter;
	readonly title: string;
	readonly open: boolean;
	readonly onToggle: (fieldRefId: string, open: boolean) => void;
	readonly onChange: (filter: IFilterRowFilter) => void;
	//onChangeOperator: (fieldRefId: string, operator: FilterOperator) => void;
	//onChangeParam: (fieldRefId: string, param: any) => void;
	readonly onClear: (fieldRefId: string) => void;
	readonly hasFiltersApplied: boolean;
}

@observer
export class SpecialFilterFieldV5 extends React.Component<ISpecialFilterFieldProps> {
	@observable
	private _filter: IFilterRowFilter;

	constructor(props: ISpecialFilterFieldProps) {
		super(props);
		makeObservable(this);
	}

	public reset() {
		if (this._filter) {
			this._filter.value.operator = this.defaultOp;
			this._filter.value.param = undefined;
		}
	}

	private onClearClick = (event: React.MouseEvent) => {
		// Don't allow the toggle to happen at the same time
		event.stopPropagation();

		this.reset();
		this.props.onClear(this.props.fieldRefId);
	};

	private onToggle = (open: boolean) => {
		this.props.onToggle(this.props.fieldRefId, open);
	};

	private onChangeOperator = (operator: FilterOperator) => {
		const filter = this.getFilter();

		filter.value.operator = operator;

		this._filter.value.operator = operator;
		this._filter.value.param = ObjectUtils.deepClone(filter.value.param);

		this.props.onChange(filter);

		//this.props.onChangeOperator(this.props.fieldRefId, operator);
	};

	private onChangeParam = (param: any) => {
		const filter = this.getFilter();

		filter.value.param = ObjectUtils.deepClone(param);

		// also change this._filter to make sure its in sync
		if (this._filter) {
			this._filter.value.param = ObjectUtils.deepClone(param);
		}

		this.props.onChange(filter);

		//this.props.onChangeParam(this.props.fieldRefId, param);
	};

	private get defaultOp() {
		return this.props.field.dataTypeSettings?.format === IDateFieldFormat.TIME ? FilterOperator.IS_AT_TIME : FilterOperator.IS_EQUAL_TO_DATE;
	}

	private getFilter() {
		if (this.props.filter) {
			return this.props.filter;
		}

		if (!this._filter) {
			this._filter = {
				type: "filter",
				value: {
					field: this.props.fieldRefId,
					operator: this.defaultOp,
					param: undefined,
				},
			};
		}

		return this._filter;
	}

	private getChildren() {
		const {field} = this.props;
		const filter = this.getFilter();

		const operator = filter.value.operator;
		const ParamControl = (FilterOperatorsV5.map[operator] as IFilterOperatorConfigV5).control;

		return (
			<SpecialFilterField>
				<SelectInputV5
					options={FieldDataTypes.map[this.props.datatype].operators(field.dataTypeSettings)}
					selected={operator}
					onChange={this.onChangeOperator}
					render={(operation) => FilterOperatorsV5.map[operation].label}
				/>
				{ParamControl && (
					<ParamControl
						param={filter.value.param}
						onChange={this.onChangeParam}
					/>
				)}
			</SpecialFilterField>
		);
	}

	public override render() {
		const {hasFiltersApplied, title, open} = this.props;

		// There is a bit of duplication here from SimpleFilterField
		const toggleTitle = (
			<span className={ReactUtils.cls("FilterTitle", {applied: hasFiltersApplied})}>
				{hasFiltersApplied && (
					<IconButton
						icon="close"
						className="close"
						onClick={this.onClearClick}
					/>
				)}
				{title}
			</span>
		);

		return (
			<ToggleContainerV5
				title={toggleTitle}
				open={open}
				onToggleOpen={this.onToggle}
				className="SimpleFilterField"
				scrollIntoViewOnOpen={true}
			>
				{open && this.getChildren()}
			</ToggleContainerV5>
		);
	}
}

import * as React from "react";
import {observer, inject} from "mobx-react";
import {Permission, XyiconFeature} from "../../../../../../generated/api/base";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import type {PortTemplateDto} from "../../../../../../generated/api/base";
import {PortTemplateEditor} from "../../../../catalog/port/PortTemplateEditor";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {IconButton} from "../../../../../widgets/button/IconButton";
import type {IModel} from "../../../../../../data/models/Model";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {LocalStorageService} from "../../../../../../data/services/LocalStorageService";
import {PORTS_LAYOUT} from "../../../../catalog/port/Port";
import type {AppState} from "../../../../../../data/state/AppState";
import {PortLayoutType} from "./PortLayoutType";
import {Ports} from "./Ports";

interface IPortsSectionProps {
	readonly item: IModel;
	readonly feature: XyiconFeature;
	readonly setPortTemplateEditorOpen: (value: boolean) => void;
	readonly isPortTemplateEditorOpen: boolean;
	readonly saveStateToLocalStorage?: boolean;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

interface IPortsSectionState {
	layout: PortLayoutType;
}

@inject("appState")
@inject("transport")
@observer
export class PortsSection extends React.Component<IPortsSectionProps, IPortsSectionState> {
	private readonly _options = [
		{
			id: PortLayoutType.Icon,
			label: "Icon Layout",
			icon: "icon-layout",
		},
		{
			id: PortLayoutType.Card,
			label: "Card Layout",
			icon: "card-layout",
		},
	];

	private readonly _localStorageManager = new LocalStorageService(this.props.transport);

	constructor(props: IPortsSectionProps) {
		super(props);
		this.state = {
			layout: this._localStorageManager.get(`${PORTS_LAYOUT}-${this.props.appState.user.id}`) || PortLayoutType.Card,
		};
	}

	private get catalogPermission() {
		return this.props.appState.user?.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	}

	private onLabelChange = async (newValue: string, portId: string) => {
		if (this.props.feature === XyiconFeature.Xyicon) {
			const xyicon = this.props.item as Xyicon;

			xyicon.addPortData({
				id: portId,
				label: newValue,
			});

			await this.props.transport.requestForOrganization({
				url: "xyicons/updateportdata",
				method: XHRLoader.METHOD_POST,
				params: {
					xyiconID: xyicon.id,
					portfolioID: this.props.appState.portfolioId,
					portData: [
						{id: portId, label: newValue}, // send only the one that has changed
					],
				},
			});
		} else {
			// const port = Port.getPortByID(this._ports, portID) as PortTemplateDto;
			// port.label = newValue;
			// this._catalog.setPortTemplate(this._ports);
			// this.props.transport.requestForOrganization({
			// 	url: `xyiconcatalogs/updateporttemplate`,
			// 	method: XHRLoader.METHOD_POST,
			// 	params: {
			// 		xyiconCatalogID: this._catalog.id,
			// 		portTemplate: this._catalog.portTemplate
			// 	}
			// });
		}
	};

	private onEditPortTemplateClick = () => {
		this.props.setPortTemplateEditorOpen(true);
	};

	private closePortTemplateEditor = () => {
		this.props.setPortTemplateEditorOpen(false);
	};

	private onSavePortTemplate = async (portTemplate: PortTemplateDto[]) => {
		this.closePortTemplateEditor();

		await this.props.transport.updatePortTemplate(this.props.item as Catalog, portTemplate);
	};

	private getPortElements(catalog: Catalog) {
		const portTemplate = catalog.portTemplate;

		if (this.props.isPortTemplateEditorOpen) {
			return (
				<PortTemplateEditor
					portTemplate={portTemplate}
					onBackClick={this.closePortTemplateEditor}
					onSaveClick={this.onSavePortTemplate}
				/>
			);
		} else {
			const isCatalog = this.props.feature === XyiconFeature.XyiconCatalog;

			return isCatalog && portTemplate.length === 0 ? (
				<IconButton
					className="addPortTemplateButton"
					title="Add Port Template"
					icon="add"
					onClick={this.onEditPortTemplateClick}
					disabled={this.catalogPermission < Permission.Update}
				/>
			) : (
				<Ports
					item={this.props.item as Xyicon | Catalog}
					onLabelChange={this.props.feature === XyiconFeature.Xyicon ? this.onLabelChange : null}
					onEditPortTemplateClick={isCatalog ? this.onEditPortTemplateClick : null}
					layout={this.state.layout}
				/>
			);
		}
	}

	private renderPorts() {
		const {feature, item} = this.props;
		const catalog = feature === XyiconFeature.XyiconCatalog ? (item as Catalog) : (item as Xyicon).catalog;

		return this.getPortElements(catalog);
	}

	private onPortLayoutChange = (option: any) => {
		this._localStorageManager.set(`${PORTS_LAYOUT}-${this.props.transport.appState.user.id}`, option.id);

		this.setState({layout: option.id});
	};

	public override render() {
		const {feature, item} = this.props;

		const renderPorts = feature === XyiconFeature.XyiconCatalog ? true : (item as Xyicon).catalog?.portTemplate.length > 0;

		return (
			[XyiconFeature.Xyicon, XyiconFeature.XyiconCatalog].includes(feature) &&
			renderPorts && (
				<ToggleContainer
					title={this.props.feature === XyiconFeature.Xyicon ? "Ports" : "Port Template"}
					saveStateToLocalStorage={this.props.saveStateToLocalStorage}
					dropdown={
						this.props.feature === XyiconFeature.Xyicon
							? {
									selected: this._options.find((o) => o.id === this.state.layout),
									onChange: this.onPortLayoutChange,
									render: (option) => {
										return (
											<div className="hbox alignCenter">
												<SVGIcon icon={option.icon} />
												<div className="label flex_1">{option.label}</div>
												{option.id === this.state.layout && (
													<SVGIcon
														icon="checkmark"
														classNames="checkmark"
													/>
												)}
											</div>
										);
									},
									options: this._options,
								}
							: null
					}
				>
					{this.renderPorts()}
				</ToggleContainer>
			)
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import {DomPortal} from "../../abstract/portal/DomPortal";
import {CardLayoutToolTip} from "../../space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {LinkBreakers} from "../../space/spaceeditor/ui/actionbar/LinkBreakers";
import {PopupUtils} from "../../abstract/popups/PopupUtils";
import {PortLayoutType} from "../../abstract/sidepanel/tabs/details/PortLayoutType";
import {SpaceItemContainer} from "../../space/spaceeditor/ui/toolbar/SpaceItemContainer";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import {ContextOptions} from "../../../widgets/context/ContextOptions";
import type {Link} from "../../../../data/models/Link";
import {XyiconFeature} from "../../../../generated/api/base";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {ReactUtils} from "../../../utils/ReactUtils";

interface IPortComponentLinkProps {
	readonly xyicon: Xyicon;
	readonly layout: PortLayoutType;
	readonly isDraggingActive: boolean;
	readonly link: Link;
	readonly app?: App;
	readonly appState?: AppState;
}

interface IPortComponentLinkState {
	isToolTipOpen: boolean;
	isContextOptionOpen: boolean;
	toolTipTransform: TransformObj;
	contextTransform: TransformObj;
}

@inject("app")
@inject("appState")
@observer
export class PortComponentLink extends React.Component<IPortComponentLinkProps, IPortComponentLinkState> {
	private _parent = React.createRef<HTMLDivElement>();
	private _contextMenu = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _timeoutId: number = null;
	private _isMounted: boolean = false;
	private _isDeletePopupWindowOpen: boolean = false;

	public static readonly defaultProps: Partial<IPortComponentLinkProps> = {
		layout: PortLayoutType.Icon,
	};

	constructor(props: IPortComponentLinkProps) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			isContextOptionOpen: false,
			toolTipTransform: null,
			contextTransform: null,
		};
	}

	private openTooltip = () => {
		if (this._timeoutId) {
			clearTimeout(this._timeoutId);
		}

		this._timeoutId = window.setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					isToolTipOpen: true,
				});
			}
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeoutId);

		if (this._isMounted) {
			this.setState({
				isToolTipOpen: false,
			});
		}
	};

	public override componentDidMount() {
		this._isMounted = true;
	}

	public override componentDidUpdate(prevProps: IPortComponentLinkProps, prevState: IPortComponentLinkState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}

		if (!prevState.isContextOptionOpen && this.state.isContextOptionOpen && this._parent.current && this._contextMenu.current) {
			this.setState({
				contextTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._contextMenu.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.left,
				),
			});
		}
	}

	public override componentWillUnmount() {
		this._isMounted = false;
		FocusLoss.stopListen(this._contextMenu.current, this.closeContextMenu);
	}

	private onDelete = async () => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await PopupUtils.getDeleteConfirmationPopup(
				XyiconFeature.Xyicon,
				this.props.appState.actions.getNumberOfModels([this.props.xyicon]),
			);

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				await this.props.appState.actions.deleteSpaceItem(this.props.xyicon);
			}
		}
	};

	private onDetailsClick = () => {
		const {appState, xyicon} = this.props;

		appState.app.onDetailsClick(xyicon);
	};

	private onDeleteLink = () => {
		const {appState, link} = this.props;

		return LinkBreakers.breakLinks(appState.app.transport, [link.id]);
	};

	private openContextMenu = async (event: React.MouseEvent) => {
		event.preventDefault();

		if (this._isMounted) {
			this.setState({isContextOptionOpen: true});
		}

		await TimeUtils.waitForNextFrame();

		if (this._isMounted) {
			FocusLoss.stopListen(this._contextMenu.current, this.closeContextMenu);
			FocusLoss.listen(this._contextMenu.current, this.closeContextMenu);
		}
	};

	private closeContextMenu = () => {
		if (this._isMounted && this._contextMenu.current) {
			this.setState({isContextOptionOpen: false});
		}
	};

	public override render() {
		const {app, xyicon} = this.props;
		const floatingElement = this._floating.current;
		const {layout, isDraggingActive} = this.props;

		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: this.state.toolTipTransform?.translate,
			position: "fixed",
			zIndex: 8000,
		};

		const inlineStyleContextMenu: React.CSSProperties = this._contextMenu.current && {
			transform: this.state.contextTransform?.translate,
			position: "fixed",
			zIndex: 8000,
		};

		const options = [
			{
				label: "Break Link",
				onSelect: this.onDeleteLink,
			},
			{
				label: "Details",
				onSelect: this.onDetailsClick,
			},
			{
				label: "Delete",
				onSelect: this.onDelete,
			},
		];

		if (layout === PortLayoutType.Card) {
			return (
				<SpaceItemContainer
					item={this.props.xyicon}
					options={options}
					className={ReactUtils.cls("PortComponentLink", {visible: isDraggingActive})}
				/>
			);
		} else {
			return (
				<div
					className="PortComponentLink thumbnail"
					style={{backgroundImage: `url('${xyicon.thumbnail}')`, transform: xyicon.backgroundTransform}}
					onMouseOver={this.openTooltip}
					onMouseLeave={this.closeTooltip}
					onContextMenu={this.openContextMenu}
					ref={this._parent}
				>
					{this.state.isToolTipOpen && (
						<DomPortal destination={app.modalContainer}>
							<CardLayoutToolTip
								item={xyicon}
								divRef={this._floating}
								style={inlineStyle}
							/>
						</DomPortal>
					)}
					{this.state.isContextOptionOpen && (
						<DomPortal destination={app.modalContainer}>
							<ContextOptions
								options={[
									{
										label: "Break Link",
										onSelect: this.onDeleteLink,
									},
									{
										label: "Details",
										onSelect: this.onDetailsClick,
									},
									{
										label: "Delete",
										onSelect: this.onDelete,
									},
								]}
								onSelect={this.closeContextMenu}
								style={inlineStyleContextMenu}
								divRef={this._contextMenu}
							/>
						</DomPortal>
					)}
				</div>
			);
		}
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import {Field} from "../../../../widgets/form/field/Field";
import {CheckboxInput} from "../../../../widgets/input/checkbox/CheckboxInput";
import {IconButton} from "../../../../widgets/button/IconButton";
import {ObjectUtils} from "../../../../../utils/data/ObjectUtils";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SearchField} from "../../../../widgets/input/search/SearchField";
import type {AppState} from "../../../../../data/state/AppState";
import {FieldDataType} from "../../../../../generated/api/base";
import {AppFieldActions} from "../../../../../data/state/AppFields";
import {getFieldRefIdsWithHiddenBlanks} from "./SimpleFilterUtils";

interface IFilterFieldProps {
	readonly fieldRefId: string;
	readonly title: string;
	readonly values: string[];
	readonly checkedValues: string[];
	readonly open: boolean;
	readonly onToggle: (fieldRefId: string, open: boolean) => void;
	readonly onChange: (fieldRefId: string, value: string) => void;
	readonly onChangeAll: (fieldRefId: string, checked: boolean, values: any) => void;
	readonly onClear: (fieldRefId: string) => void;
	readonly hasFiltersApplied: boolean;
	readonly appState?: AppState;
}

interface IFilterFieldState {
	search: string;
	loadMoreCount: number;
}

@inject("appState")
export class SimpleFilterField extends React.Component<IFilterFieldProps, IFilterFieldState> {
	// As per https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20November/S-79?workitem=2369
	// Hide "Blank" checkboxes for these fields
	private _fieldRefIdsWithHiddenBlanks: Set<string> = getFieldRefIdsWithHiddenBlanks();
	private readonly rowPerPage: number = 500;

	constructor(props: IFilterFieldProps) {
		super(props);
		this.state = {
			search: "",
			loadMoreCount: 1,
		};
	}

	public override shouldComponentUpdate(nextProps: Readonly<IFilterFieldProps>, nextState: Readonly<IFilterFieldState>, nextContext: any): boolean {
		// This makes sure the component is only updated if it really changes
		return !ObjectUtils.compare(this.state, nextState) || !ObjectUtils.compare(this.props, nextProps);
	}

	private applySearch = (value: string) => {
		const {search} = this.state;

		return StringUtils.containsIgnoreCase(value, search);
	};

	private onClearClick = (event: React.MouseEvent) => {
		// Don't allow the toggle to happen at the same time
		event.stopPropagation();
		this.props.onClear(this.props.fieldRefId);
	};

	private onValueChange = (value: string) => {
		this.props.onChange(this.props.fieldRefId, value);
	};

	private onToggle = (open: boolean) => {
		this.props.onToggle(this.props.fieldRefId, open);
		this.setState({loadMoreCount: 1});
	};

	private onToggleAll = () => {
		const searchedValues = this.getSearchedValues();
		const value = this.allSearchedOptionsAreChecked(searchedValues);

		this.props.onChangeAll(this.props.fieldRefId, !value, searchedValues);
	};

	private getSearchedValues() {
		const {search} = this.state;
		const {values} = this.props;

		return search ? values.filter(this.applySearch) : values;
	}

	private onSearchChange = (value: string) => {
		this.setState({
			search: value,
			loadMoreCount: 1,
		});
	};

	private allSearchedOptionsAreChecked(searchedValues: string[]) {
		const {checkedValues} = this.props;
		const hasUncheckedSearchValue = searchedValues.some((v) => !checkedValues.includes(v));

		return !hasUncheckedSearchValue;
	}

	private columnContainerOnScroll(event: React.UIEvent<HTMLDivElement, UIEvent>) {
		const {scrollTop, offsetHeight, scrollHeight} = event.target as HTMLDivElement;
		const leewayBeforeBottom = 1000;

		if (scrollTop + offsetHeight >= scrollHeight - leewayBeforeBottom) {
			this.setState({
				loadMoreCount: this.state.loadMoreCount + 1,
			});
		}
	}

	private getChildren() {
		const {search, loadMoreCount} = this.state;
		const {values, checkedValues, fieldRefId, appState} = this.props;
		const searchedValues = this.getSearchedValues();

		const field = appState.actions.getFieldByRefId(fieldRefId);
		const isMultipleChoice = field.dataType === FieldDataType.MultipleChoiceList;

		const allSearchedValuesAreChecked = this.allSearchedOptionsAreChecked(searchedValues);

		return (
			<>
				<div className="hbox justifySpaceBetween">
					<div className="vbox">
						<Field label="All">
							<CheckboxInput
								disabled={values.length === 0}
								value={allSearchedValuesAreChecked}
								onChange={this.onToggleAll}
							/>
						</Field>
						{searchedValues.includes("") && !this._fieldRefIdsWithHiddenBlanks.has(field.refId) && (
							<Field label="Blanks">
								<CheckboxInput
									value={checkedValues.includes("")} // TODO
									onChange={(e) => this.onValueChange("")}
								/>
							</Field>
						)}
					</div>
					<SearchField
						placeholder="Find..."
						className="findInput"
						value={search}
						onInput={this.onSearchChange}
					/>
				</div>

				<div
					className={ReactUtils.cls("values", {multi: isMultipleChoice})}
					onScroll={(ev) => this.columnContainerOnScroll(ev)}
				>
					<div className={ReactUtils.cls("columns-container", {multiline: field.dataType === FieldDataType.MultiLineText})}>
						{searchedValues.length > 0 ? (
							searchedValues
								.sort((a, b) => {
									if (AppFieldActions.isRefId(field.refId)) {
										const a_num = StringUtils.refId2Number(a);
										const b_num = StringUtils.refId2Number(b);

										return a_num > b_num ? 1 : -1;
									}

									return StringUtils.sortIgnoreCase(a, b);
								})
								.slice(0, loadMoreCount * this.rowPerPage) // scroll pagination
								.map(
									(value) =>
										value && (
											// It's possible that 2011 and "2011" are both present in the array, but they're not interpreted as 2 different keys
											<Field
												key={`${typeof value}_${value}`}
												label={value}
											>
												<CheckboxInput
													value={checkedValues.includes(value)}
													onChange={(e) => this.onValueChange(value)}
												/>
											</Field>
										),
								)
						) : (
							<div className="noResult field">{`No results  ${search ? `found for the term "${search}".` : `found.`}`}</div>
						)}
					</div>
				</div>
			</>
		);
	}

	public override render() {
		const {title, open, appState} = this.props;

		const toggleTitle = (
			<span className={ReactUtils.cls("FilterTitle", {applied: this.props.hasFiltersApplied})}>
				{this.props.hasFiltersApplied && (
					<IconButton
						icon="close"
						className="close"
						onClick={this.onClearClick}
					/>
				)}
				{title}
			</span>
		);

		return (
			<ToggleContainer
				title={toggleTitle}
				open={open}
				onToggleOpen={this.onToggle}
				className="SimpleFilterField"
				scrollIntoViewOnOpen={true}
			>
				{open && this.getChildren()}
			</ToggleContainer>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import type {IDistanceUnit, DistanceUnitName} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldDataType} from "../../../../generated/api/base";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {IRealSize} from "../../../modules/settings/modules/type/form/RealSizeInput";
import {Field} from "../../../../data/models/field/Field";
import {UnitSelectorV5} from "../../details/UnitSelectorV5";
import {radius} from "../../styles/styles";
import {ClickToEditInputV5} from "./ClickToEditInputV5";

interface IClickToEditDistanceV5Props {
	readonly value: number;
	readonly unit: IDistanceUnit;
	readonly onChange: (size: IRealSize) => void;
	readonly getErrorMessage: (value: number) => string;
}

const ClickToEditDistanceV5Styled = styled.div`
	display: flex;
	gap: 8px;

	> div {
		width: 120px;

		&.RealSizeInputUnitSelector {
			border-radius: ${radius.sm};
		}
	}
`;

export const ClickToEditDistanceV5 = (props: IClickToEditDistanceV5Props) => {
	const {
		getErrorMessage = (value: any) =>
			MathUtils.isValidNumber(parseFloat(value)) && parseFloat(value) > 0 ? "" : "Please provide a valid, positive number",
	} = props;

	const onValueChange = (value: any) => {
		const errorMessageMaybe = getErrorMessage(value);
		if (!errorMessageMaybe) {
			props.onChange({
				value: value,
				unit: props.unit.name,
			});
		}
	};

	return (
		<ClickToEditDistanceV5Styled>
			<ClickToEditInputV5
				dataTypeSettings={Field.defaultSettings[FieldDataType.Numeric]}
				dataType={FieldDataType.Numeric}
				value={props.value}
				onChange={onValueChange}
				getErrorMessage={getErrorMessage}
			/>
			<UnitSelectorV5
				unit={props.unit.name}
				onChange={(unitName: DistanceUnitName) => props.onChange({value: props.value, unit: unitName})}
				className="RealSizeInputUnitSelector"
			/>
		</ClickToEditDistanceV5Styled>
	);
};

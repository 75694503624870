import type {RefObject} from "react";
import styled from "styled-components";
import {Functions} from "../../../../utils/function/Functions";
import {MathUtils} from "../../../../utils/math/MathUtils";

const validateValue = (value: boolean) => {
	return !!value;
};

interface ICheckboxInputProps {
	readonly value: boolean;
	readonly onChange?: (value: boolean) => void;
	readonly disabled?: boolean;
	readonly inputRef?: RefObject<HTMLInputElement>;
	readonly label?: string;
	readonly className?: string;
	readonly labelFirst?: boolean;
}

export const CheckboxInputV5 = (props: ICheckboxInputProps) => {
	const {value = false, onChange = Functions.emptyFunction, disabled, inputRef, className, label = "", labelFirst = false} = props;
	const _id = MathUtils.getNewRandomGUID();

	const triggerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (onChange) {
			const value = validateValue(event.currentTarget.checked);

			onChange(value);
		}
	};

	return (
		<CheckboxInputStyled
			className="CheckboxInput hbox alignCenter"
			data-cy={`CheckboxInput_for_${label}`}
			$reverse={labelFirst}
		>
			{label && <label htmlFor={_id}>{label}</label>}
			<input
				ref={inputRef}
				id={_id}
				checked={value}
				type="checkbox"
				onChange={triggerChange}
				disabled={disabled}
				className={className}
				value={value.toString()}
			/>
			<label htmlFor={_id} />
		</CheckboxInputStyled>
	);
};

export const CheckboxInputStyled = styled.div<{$reverse: boolean}>`
	display: flex;
	flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};

	label {
		cursor: pointer;
	}
`;

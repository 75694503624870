import * as React from "react";
import {observer} from "mobx-react";
import type {DocumentSectionItem} from "../DocumentSection";
import type {IContextOption} from "../../../../../../../widgets/context/ContextOptions";
import {LoaderIcon} from "../../../../../../../widgets/button/LoaderIcon";
import {DocumentListItem} from "./DocumentListItem";

interface IDocumentListViewProps {
	readonly documentItems: DocumentSectionItem[];
	readonly uploadingArraySize: number;
	readonly getOptions: (document: DocumentSectionItem) => IContextOption[];
}

@observer
export class DocumentListView extends React.Component<IDocumentListViewProps> {
	public override render() {
		const {documentItems, getOptions, uploadingArraySize} = this.props;
		const loaderArray: React.JSX.Element[] = [];

		for (let i = 0; i < uploadingArraySize; i++) {
			loaderArray.push(
				<div
					key={i}
					className="loaderText list vbox alignCenter justifyCenter"
				>
					<LoaderIcon />
					Uploading Document...
				</div>,
			);
		}

		return (
			<div className="DocumentListView">
				{documentItems.length === 0 && loaderArray.length === 0 ? (
					<div className="noDataSection">No Documents...</div>
				) : (
					documentItems.map((documentItem) => {
						const doc = documentItem.document;

						return (
							<DocumentListItem
								key={doc.id}
								documentItem={documentItem}
								getOptions={getOptions}
							/>
						);
					})
				)}
				{loaderArray}
			</div>
		);
	}
}

import type {Catalog} from "../../../../../../data/models/Catalog";
import type {AppState} from "../../../../../../data/state/AppState";
import {Permission, XyiconFeature} from "../../../../../../generated/api/base";
import {Functions} from "../../../../../../utils/function/Functions";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {OptionsButton} from "../../../../../widgets/button/options/OptionsButton";
import type {IContextOption} from "../../../../../widgets/context/ContextOptions";
import {getCatalogItemOptions} from "./CatalogItemOptions";

interface ISpaceItemActionButtons {
	readonly appState: AppState;
	readonly feature: XyiconFeature;
	readonly isFavorite?: boolean;
	readonly itemTypeId?: string;
	readonly isCrossPortfolioXyicon: boolean;
	readonly showDeleteButton: boolean;
	readonly showInfoButton: boolean;
	readonly onFavoriteClick?: () => void;
	readonly onUnFavoriteClick?: () => void;
	readonly onCreateUnplottedXyiconClick?: () => void;
	readonly onBreakLinkClick?: () => void;
	readonly onDetailsClick?: () => void;
	readonly deleteItem?: () => void;
	readonly onDuplicateCatalogClick?: (item: Catalog) => void;
	readonly onDeleteCatalogClick?: (item: Catalog) => void;
	readonly catalog?: Catalog;
	readonly options?: IContextOption[];
}

export const SpaceItemActionButtons = (props: ISpaceItemActionButtons) => {
	const {
		appState,
		feature,
		isFavorite,
		itemTypeId,
		catalog,
		isCrossPortfolioXyicon,
		showInfoButton,
		showDeleteButton,
		onBreakLinkClick,
		onDetailsClick,
		onFavoriteClick,
		onUnFavoriteClick,
		onCreateUnplottedXyiconClick,
		onDuplicateCatalogClick,
		onDeleteCatalogClick,
		deleteItem,
		options,
	} = props;

	const isSpaceEditorMounted = location.hash.includes("space/");
	const userCatalogPermission = appState.user.getOrganizationPermission(XyiconFeature.XyiconCatalog);
	const userXyiconPermission = appState.actions.getModuleTypePermission(itemTypeId, XyiconFeature.Xyicon);
	const hasSpaceItemPermission = feature === XyiconFeature.Boundary || userXyiconPermission > Permission.View;

	return (
		<div className="SpaceItemActionButtons buttonContainer hbox alignCenter">
			{!isSpaceEditorMounted && feature === XyiconFeature.XyiconCatalog && hasSpaceItemPermission && (
				<IconButton
					icon="add"
					title="Create unplotted xyicon"
					onClick={onCreateUnplottedXyiconClick}
				/>
			)}
			{feature === XyiconFeature.XyiconCatalog &&
				(isFavorite ? (
					<IconButton
						icon="unstar"
						title="Remove from favorites"
						onClick={onUnFavoriteClick}
					/>
				) : (
					<IconButton
						icon="star"
						title="Add to favorites"
						onClick={onFavoriteClick}
						strokeOnly={true}
					/>
				))}
			{options ? (
				<OptionsButton
					className="options flexCenter"
					options={options}
				/>
			) : (
				!isCrossPortfolioXyicon &&
				feature === XyiconFeature.XyiconCatalog &&
				userCatalogPermission > Permission.View &&
				hasSpaceItemPermission && (
					<OptionsButton
						className="options flexCenter"
						options={getCatalogItemOptions({app: appState.app, item: catalog, onDeleteCatalogClick, onDuplicateCatalogClick, userCatalogPermission})}
					/>
				)
			)}
			{onBreakLinkClick && onBreakLinkClick !== Functions.emptyFunction && hasSpaceItemPermission && (
				<IconButton
					icon="breakLinkClassic"
					title="Break Link"
					onClick={onBreakLinkClick}
				/>
			)}
			{showInfoButton && hasSpaceItemPermission && (
				<IconButton
					icon="details"
					title="Details"
					onClick={onDetailsClick}
				/>
			)}
			{showDeleteButton && deleteItem && userXyiconPermission === Permission.Delete && hasSpaceItemPermission && (
				<IconButton
					icon="delete"
					className="deleteButton flexCenter"
					title="Delete"
					onClick={deleteItem}
				/>
			)}
		</div>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Button} from "../../../../widgets/button/Button";
import {IconButton} from "../../../../widgets/button/IconButton";
import {Field} from "../../../../widgets/form/field/Field";
import type {AppState} from "../../../../../data/state/AppState";
import {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import {PortfolioGroupPortfolios} from "./PortfolioGroupPortfolios";

interface ICreatePortfolioGroupPanelProps {
	readonly onCancel: () => void;
	readonly onCreated: (id: string) => void;
	readonly creating: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePortfolioGroupPanel extends React.PureComponent<ICreatePortfolioGroupPanelProps> {
	private _portfolioGroup = this.createNewPortfolioGroup();

	private createNewPortfolioGroup() {
		return new PortfolioGroup({
			portfolioGroupID: "",
			portfolios: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	}

	private onCreateClicked = async () => {
		const data = {
			name: this._portfolioGroup.name,
			portfolioList: this._portfolioGroup.portfolioIds,
		};
		const models = await this.props.transport.services.feature.create(data, XyiconFeature.PortfolioGroup);

		this.props.onCreated(models?.[0]?.id);
		this._portfolioGroup = this.createNewPortfolioGroup();
		this.forceUpdate();
	};

	private isNameValid = (value: string) => {
		return this.props.appState.actions.isPortfolioGroupNameValid(value, this._portfolioGroup);
	};

	private getErrorMessage = (value: string) => {
		return !value.trim() ? "Name cannot be empty!" : this.isNameValid(value) ? "" : "Value not valid!";
	};

	private onInput = (value: string) => {
		this._portfolioGroup.name = value;
	};

	private onCancel = () => {
		this._portfolioGroup.name = "";
		this.props.onCancel();
	};

	public override render() {
		const portfolioGroup = this._portfolioGroup;
		const isValid = portfolioGroup.name && this.isNameValid(portfolioGroup.name);

		return (
			<div className="SidePanel CreatePortfolioGroupPanel">
				<div className="heading hbox createBox">
					<h4>Create New Portfolio Group</h4>
					<IconButton
						className="close"
						icon="close"
						onClick={this.onCancel}
					/>
				</div>
				<div className="sidePanelButtons hbox">
					<Button
						label="Cancel"
						onClick={this.onCancel}
					/>
					<Button
						label="Create"
						onClick={this.onCreateClicked}
						disabled={!isValid}
						className="primary"
					/>
				</div>
				<div className="container">
					<Field label="Group Name">
						<ClickToEditInput
							value={portfolioGroup.name}
							onLiveChange={this.onInput}
							getErrorMessage={this.getErrorMessage}
							noButtons={true}
							focused={this.props.creating}
						/>
					</Field>
				</div>
				<PortfolioGroupPortfolios portfolioGroup={portfolioGroup} />
			</div>
		);
	}
}

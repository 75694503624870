import React, {useRef} from "react";
import styled from "styled-components";
import type {IModel} from "../../../../data/models/Model";
import {PopupBodyStyled, PopupV5} from "../../popup/PopupV5";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {Boundary} from "../../../../data/models/Boundary";
import {Functions} from "../../../../utils/function/Functions";
import {SpaceItemContainerV5} from "../../spaceeditor/SpaceItemContainerV5";
import {CheckboxInputStyled, CheckboxInputV5} from "../../details/datatypes/CheckboxInputV5";
import {Flex, fontSize, fontWeight, radius} from "../../styles/styles";
import {useAppStore} from "../../../../StateManager";
import {IconButtonStyled, IconButtonV5} from "../../interaction/IconButtonV5";
import PinIcon from "../../icons/location-pin.svg?react";
import {colorPalette} from "../../styles/colorPalette";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {MarkupType, XyiconFeature} from "../../../../generated/api/base";
import type {Markup} from "../../../../data/models/Markup";
import {InitialsStyled, InitialsV5} from "../../widgets/InitialsV5";
import {SpaceItemContainerStyled} from "../../spaceeditor/SpaceItemContainer.Style";

interface IMoreIconsPopupV5Props {
	readonly items: IModel[];
	readonly parentRef: React.MutableRefObject<HTMLDivElement>;
	readonly verticalAlignment?: VerticalAlignment;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly onClose: () => void;
	readonly onClickCheckbox?: (items: IModel[]) => void;
}

export const MoreIconsPopupV5 = ({
	items,
	parentRef,
	verticalAlignment = VerticalAlignment.bottomOuter,
	horizontalAlignment = HorizontalAlignment.outerLeft,
	offsetX = 0,
	offsetY = 0,
	onClose,
	onClickCheckbox,
}: IMoreIconsPopupV5Props) => {
	const originalItems = useRef<IModel[]>(items);
	const appState = useAppStore((state) => state.appState);

	const onChangeCheckbox = (value: boolean, index: number) => {
		const clickedItem = originalItems.current[index];

		if (value) {
			onClickCheckbox?.([...items, clickedItem]);
		} else {
			onClickCheckbox?.(items.filter((i) => i.id !== clickedItem.id));
		}
	};

	const renderFirstLine = (item: IModel) => {
		switch (item.ownFeature) {
			case XyiconFeature.Portfolio:
			case XyiconFeature.Space:
			case XyiconFeature.XyiconCatalog:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return item.refId;
			case XyiconFeature.Markup:
				return "Markup";
		}

		return "";
	};

	const renderSecondLine = (item: IModel) => {
		switch (item.ownFeature) {
			case XyiconFeature.Portfolio:
			case XyiconFeature.Space:
			case XyiconFeature.XyiconCatalog:
			case XyiconFeature.Xyicon:
			case XyiconFeature.Boundary:
				return `Type: ${item.typeName}`;
			case XyiconFeature.Markup:
				return MarkupType[(item as Markup).type];
		}

		return "";
	};

	return (
		<MoreIconsPopupStyled
			parentRef={parentRef.current}
			noButtons={true}
			verticalAlignment={verticalAlignment}
			horizontalAlignment={horizontalAlignment}
			offsetX={offsetX}
			offsetY={offsetY}
			isSmallPopup={true}
			onClose={onClose}
		>
			<div className="moreIconsWindow">
				<div className="list">
					{originalItems.current.map((item: IModel, index: number) => {
						const checkboxValue = items.includes(item);

						const detailedItem = appState.actions.getTypeById(item?.typeId);
						const color = detailedItem?.settings.color.hex || "FFFFFF";

						return (
							<IconElementStyled key={item.id}>
								<CheckboxInputV5
									value={checkboxValue}
									onChange={(value) => onChangeCheckbox(value, index)}
								></CheckboxInputV5>
								{[XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(items[0].ownFeature) ? (
									<>
										<SpaceItemContainerV5
											item={item as Xyicon | Boundary}
											onPointerDown={Functions.emptyFunction}
											onPointerMove={Functions.emptyFunction}
											onPointerUp={Functions.emptyFunction}
											queryString={""}
											hideDragHandle={true}
										/>
										<IconButtonV5
											IconComponent={PinIcon}
											onClick={() => appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true)}
										/>
									</>
								) : (
									<>
										<InitialsV5
											item={item}
											color={color}
											name={detailedItem?.name}
											thumbnailSize={38}
											className={`MoreIcons ${item.ownFeature === XyiconFeature.XyiconCatalog ? "noClick" : ""} ${item.ownFeature === XyiconFeature.Portfolio ? "portfolioThumbnail" : ""}`}
										/>
										<DescriptionStyled>
											<div className="firstLine">{renderFirstLine(item)}</div>
											<div className="secondLine">{renderSecondLine(item)}</div>
										</DescriptionStyled>
									</>
								)}
							</IconElementStyled>
						);
					})}
				</div>
			</div>
		</MoreIconsPopupStyled>
	);
};

const MoreIconsPopupStyled = styled(PopupV5)`
	overflow: scroll;
	width: 296px;
	max-height: 700px;

	${PopupBodyStyled} {
		padding: 8px 0 0 8px;
	}
`;

const IconElementStyled = styled(Flex)`
	border-radius: ${radius.sm};
	align-items: center;
	height: 48px;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		${SpaceItemContainerStyled} {
			background-color: transparent;
		}

		${IconButtonStyled} {
			display: flex;
		}
	}

	${CheckboxInputStyled} {
		margin-left: 4px;
	}

	${InitialsStyled} {
		margin-left: 4px;
	}

	${IconButtonStyled} {
		margin-right: 8px;
		display: none;

		svg {
			width: 24px;
			height: 24px;
		}

		&:hover {
			border: 1px solid ${colorPalette.gray.c950};
		}
	}
`;

const DescriptionStyled = styled.div`
	padding-left: 8px;
	line-height: 16px;
	font-weight: ${fontWeight.normal};

	.firstLine {
		font-size: ${fontSize.md};
		color: ${colorPalette.gray.c950};
	}

	.secondLine {
		font-size: ${fontSize.sm};
		letter-spacing: 0.0025em;
		color: ${colorPalette.gray.c700Dark};
	}
`;

import type {TransportLayer} from "../TransportLayer";

export class LocalStorageService {
	private readonly _transportLayer: TransportLayer;

	constructor(transportLayer: TransportLayer) {
		this._transportLayer = transportLayer;
	}

	public setSplitterRatios(ratios: number[]) {
		this.set(this.getSplitterRatiosKey(), ratios);
	}

	public getSplitterRatios(): number[] {
		return this.get(this.getSplitterRatiosKey());
	}

	private getSplitterRatiosKey() {
		return "srv4-sidepanel-splitterratios";
	}

	public set(key: string, value: any) {
		try {
			const stringVal = JSON.stringify(value);

			localStorage.setItem(key, stringVal);
		} catch (error) {
			console.error(error);
		}
	}

	public get(key: string) {
		try {
			return JSON.parse(localStorage.getItem(key));
		} catch (error) {
			console.error(error);
		}
	}

	public remove(key: string) {
		localStorage.removeItem(key);
	}

	public clear() {
		localStorage.clear();
	}

	public getV5NavigationPanelDockedStateKey(userId: string = "") {
		return `v5-nav-docked-with-userid-${userId}`;
	}
}

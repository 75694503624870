import {forwardRef, useImperativeHandle, useState} from "react";
import styled from "styled-components";
import {LabelStyled, ToggleSwitchV5} from "../../../widgets/switch/ToggleSwitchV5";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import type {IMassInputV5Props} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {useAppStore} from "../../../../../StateManager";
import {ELLIPSIS} from "../../../styles/styles";
import type {IModel} from "../../../../../data/models/Model";
import {ConfirmWindowV5} from "../../../popup/ConfirmWindowV5";
import {TimeUtils} from "../../../../../utils/TimeUtils";
import {OptionSelectorInputStyled} from "./MassFieldInputV5";

interface IBoolOption {
	label: string;
	id: string;
}

export const MassFieldBooleanInputV5 = forwardRef((props: IMassInputV5Props, ref) => {
	const {field, onClose} = props;
	const appState = useAppStore((store) => store.appState);
	const [selectedOptionId, setSelectedOptionId] = useState<string>("all");
	const [selectedValue, setSelectedValue] = useState<boolean>(false);

	const boolOptions: IBoolOption[] = [
		{
			id: "all",
			label: "All",
		},
		{
			id: "false",
			label: field.dataTypeSettings.displayLabelForFalse,
		},
		{
			id: "true",
			label: field.dataTypeSettings.displayLabelForTrue,
		},
	];

	const onApply = async (items: IModel[]) => {
		const {field} = props;

		const itemsToUpdate = items.filter((item) => {
			const value = appState.actions.getOwnFieldValue(item, field.refId) as unknown as boolean;
			const boolLabel = appState.actions.formatValue(value, field.refId);

			if (selectedOptionId === "all") {
				return value !== selectedValue;
			} else {
				const boolOption = boolOptions.find((option) => option.id === selectedOptionId);

				return boolLabel === boolOption.label && value !== selectedValue;
			}
		});

		const confirmed = await ConfirmWindowV5.open(`${itemsToUpdate.length} of ${items.length} items will be updated. Continue?`, "Confirm Update", {
			ok: "Yes",
			cancel: "Cancel",
		});

		if (confirmed) {
			if (itemsToUpdate.length > 0) {
				await TimeUtils.waitUpdate(
					appState.actions.updateMassFields(itemsToUpdate, {
						[field.refId]: selectedValue,
					}),
					appState.app.notificationContainer,
				);
			}
			onClose();
		}
	};

	useImperativeHandle(ref, () => ({
		onApply,
	}));

	return (
		<OptionSelectorInputStyled>
			<SelectInputV5
				options={boolOptions}
				render={(option) => option.label}
				selected={boolOptions.find((option) => option.id === selectedOptionId)}
				onChange={(option) => setSelectedOptionId(option.id)}
			/>
			<ToggleSwitchStyled
				value={selectedValue}
				onChange={(value) => setSelectedValue(value)}
				dataTypeSettings={field.dataTypeSettings}
			/>
		</OptionSelectorInputStyled>
	);
});

const ToggleSwitchStyled = styled(ToggleSwitchV5)`
	display: flex;
	flex-wrap: nowrap;
	gap: 8px;
	flex-direction: row-reverse;
	justify-content: space-between;

	${LabelStyled} {
		${ELLIPSIS};
		max-width: 250px;
	}
`;

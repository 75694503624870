import styled from "styled-components";
import type {MouseEvent} from "react";
import CloseIcon from "../../../icons/xmark.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import type {SpaceItem} from "../../../../modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import {useAppStore} from "../../../../../StateManager";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import type {BoundarySpaceMap} from "../../../../../data/models/BoundarySpaceMap";

interface ISelectedObjectsBubbleProps {
	readonly selectedItems: SpaceItem[];
}

export const SelectedObjectsBubble = (props: ISelectedObjectsBubbleProps) => {
	const {selectedItems} = props;
	const appState = useAppStore((state) => state.appState);
	const sanitizedSelectedItems = ArrayUtils.removeDuplicates(
		selectedItems.map((item) => ((item.modelData as BoundarySpaceMap).isBoundarySpaceMap ? (item.modelData as BoundarySpaceMap).parent : item)),
	);

	if (sanitizedSelectedItems.length < 2) {
		return <></>;
	}

	const {spaceItemController} = appState.app.spaceViewRenderer;
	const onClick = () => selectedItems.forEach((item) => item.shake());

	return (
		<SelectedObjectsBubbleStyled onClick={onClick}>
			{sanitizedSelectedItems.length} selected
			<IconButtonV5
				IconComponent={CloseIcon}
				title="Close"
				onClick={(e: MouseEvent<HTMLDivElement>) => {
					e.stopPropagation();
					spaceItemController.deselectAll(true, true);
				}}
			/>
		</SelectedObjectsBubbleStyled>
	);
};

const SelectedObjectsBubbleStyled = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: ${radius.sm};
	background-color: ${colorPalette.primary.c500Primary};
	gap: 8px;
	padding-left: 8px;
`;

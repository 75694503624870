import {useReducer} from "react";
import styled from "styled-components";
import type {Markup} from "../../../../../data/models/Markup";
import type {ISizeRange} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupUtils} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import ArrowHeadIcon from "../../../icons/arrow-size.svg?react";
import {SelectInputStyled, SelectInputV5} from "../../../input/select/SelectInputV5";
import {MarkupsWithArrowHeads} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";

const _range: ISizeRange = {
	values: [10, 20, 30, 40, 50, 75, 100, 150, 200, 300, 500],
	default: MarkupUtils.defaultArrowHeadSize,
	min: 10,
	max: 500,
};

interface IArrowHeadSizeChangerProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

export const ArrowHeadSizeChangerV5 = (props: IArrowHeadSizeChangerProps) => {
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const _selectedItems = props.spaceViewRenderer.spaceItemController.selectedItems as Markup3D[];

	const onChange = (newValue: number) => {
		const markupsToUpdate: Markup3D[] = [];

		const markup3Ds = _selectedItems as Markup3D[];

		for (const markup3D of markup3Ds) {
			const markup = markup3D.modelData as Markup;
			const hasChanged = markup.arrowHeadSize !== newValue;

			if (hasChanged) {
				markup.setArrowHeadSize(newValue);
				markupsToUpdate.push(markup3D);
			}
		}

		for (const markup3D of markupsToUpdate) {
			markup3D.updateByModel(markup3D.modelData as Markup);
		}

		props.spaceViewRenderer.markupManager.updateItems(markupsToUpdate, true);

		forceUpdate();
	};

	const areOnlyArrowsSelected =
		_selectedItems.length > 0 && _selectedItems.every((item) => item.spaceItemType === "markup" && MarkupsWithArrowHeads.includes(item.type));

	return (
		areOnlyArrowsSelected && (
			<MarkupSelectInputStyled
				className="hbox"
				title="Change arrowhead size"
			>
				<SelectInputV5
					selected={(_selectedItems[0].modelData as Markup).arrowHeadSize}
					onChange={onChange}
					options={_range.values}
					sort={false}
					searchBarMode="always off"
					isSameWidth={true}
					IconComponent={ArrowHeadIcon}
					horizontalAlignment={HorizontalAlignment.center}
				/>
			</MarkupSelectInputStyled>
		)
	);
};

export const MarkupSelectInputStyled = styled.div`
	${SelectInputStyled} {
		svg {
			width: 16px;
			height: 16px;
		}
	}
`;

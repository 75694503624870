import styled from "styled-components";
import type {ReactNode} from "react";
import {LoaderIconV5} from "./LoaderIconV5";

interface ILodaderV5Props {
	readonly label?: ReactNode;
	readonly labelFirst?: boolean;
}

export const LoaderV5 = ({label = "Loading...", labelFirst = false}: ILodaderV5Props) => {
	return (
		<LoaderStyled $labelFirst={labelFirst}>
			<LoaderIconV5 />
			{label}
		</LoaderStyled>
	);
};

export const LoaderStyled = styled.div<{$labelFirst: boolean}>`
	display: flex;
	flex-direction: ${(props) => (props.$labelFirst ? "column-reverse" : "column")};
	justify-content: center;
	align-items: center;
	gap: 8px;
`;

import {Observer} from "mobx-react";
import type {IFontStyleSettings} from "../../../../../../data/models/ViewUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";

interface IFontStyleContainerProps {
	readonly fontStyleSettings: IFontStyleSettings;
	readonly onIsBoldChange: (newValue: boolean) => void;
	readonly onIsItalicChange: (newValue: boolean) => void;
	readonly onIsUnderlinedChange: (newValue: boolean) => void;
	readonly onClose?: () => void;
}

export const FontStyleContainer = (props: IFontStyleContainerProps) => {
	const {fontStyleSettings, onClose} = props;

	return (
		<Observer>
			{() => {
				return (
					<div className="FontStyleContainer hbox alignCenter">
						{onClose && (
							<IconButton
								icon="back"
								title="Back"
								onClick={onClose}
							/>
						)}
						<IconButton
							icon="bold"
							title="Bold"
							className={ReactUtils.cls("btn", {active: fontStyleSettings.isBold})}
							onClick={() => props.onIsBoldChange(!fontStyleSettings.isBold)}
						/>
						<IconButton
							icon="italic"
							title="Italic"
							className={ReactUtils.cls("btn", {active: fontStyleSettings.isItalic})}
							onClick={() => props.onIsItalicChange(!fontStyleSettings.isItalic)}
						/>
						<IconButton
							icon="underlined"
							title="Underline"
							className={ReactUtils.cls("btn", {active: fontStyleSettings.isUnderlined})}
							onClick={() => props.onIsUnderlinedChange(!fontStyleSettings.isUnderlined)}
						/>
					</div>
				);
			}}
		</Observer>
	);
};

import type {Scene, Texture, PlaneGeometry, PerspectiveCamera, OrthographicCamera} from "three";
import {Object3D, Mesh, Box3, Vector3, TextureLoader, ClampToEdgeWrapping} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {Constants} from "../Constants";
import {TileMaterial} from "../materials/TileMaterial";
import {BasicMaterial} from "../materials/BasicMaterial";
import {SpaceEditorMode} from "../renderers/SpaceViewRendererUtils";
import type {ThemeType} from "../../../../../ThemeType";
import {DebugInformation} from "../../../../../../utils/DebugInformation";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {SpaceFile} from "../../../../../../data/models/SpaceFile";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {PointDouble} from "../../../../../../generated/api/base";
import type {ITileData, IZoomInfo} from "../../../../../../offline_utils/utils/PDFRendererUtils";
import {OfflineTileRasterizer, OnlineTileFetcher} from "./TileRasterizer";
import type {ITileRasterizer} from "./TileRasterizer";

export class TileManager {
	private _container: Scene;
	private _planeGeometry: PlaneGeometry;
	private _tileRasterizer: ITileRasterizer;
	private _spaceViewRenderer: SpaceViewRenderer;
	private _baseNameOfContainers: string = "tileContainer_level_";
	private _backgroundTile: Mesh;
	private _zoomInfo: IZoomInfo[] = [];
	private _currentZoomLevel: number;
	private _currentlyVisibleContainer: Object3D;
	private _pivotContainer: Object3D;
	private _pdfURL: string;
	private _loadedTileIds: Set<string> =
		new Set(); /** It's faster than an array. Checking "this._loadedTileIds.has(tileId)" is done in one call, "this._loadedTileIds.includes(tileId)" must iterate through the whole array in some cases */
	private _inverted: boolean = false;
	private _useGradientBackground: boolean = DebugInformation.isLoggingEnabled;
	private _textureLoader: TextureLoader = new TextureLoader();
	private readonly _white: number = 0xffffff;

	private _dummyBox: Box3 = new Box3();
	private _dummyVectorMin: Vector3 = new Vector3();
	private _dummyVectorMax: Vector3 = new Vector3();
	private _timeoutId: number = 0;

	private _pointer: {
		startX: number;
		startY: number;
	} = null;

	private _dummyCamera: PerspectiveCamera | OrthographicCamera;

	private _grabbedBackgroundPos: PointDouble;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._planeGeometry = spaceViewRenderer.planeGeometry;
		this._container = spaceViewRenderer.tileScene;
		this._spaceViewRenderer = spaceViewRenderer;
	}

	// Used in align mode
	public onPointerDown(worldX: number, worldY: number) {
		this._pointer = {
			startX: worldX,
			startY: worldY,
		};

		const firstChildOfPivotContainer = this.firstChildOfPivotContainer;

		this._grabbedBackgroundPos = {
			x: firstChildOfPivotContainer.position.x,
			y: firstChildOfPivotContainer.position.y,
		};
	}

	public onPointerMove(worldX: number, worldY: number) {
		if (this._pointer) {
			const deltaX = worldX - this._pointer.startX;
			const deltaY = worldY - this._pointer.startY;

			const multiplicator = 1 / this._pivotContainer.scale.x;

			for (const child of this._pivotContainer.children) {
				THREEUtils.setPosition(child, this._grabbedBackgroundPos.x + deltaX * multiplicator, this._grabbedBackgroundPos.y + deltaY * multiplicator);
			}

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public onPointerUp() {
		this._pointer = null;
		this._grabbedBackgroundPos = null;
	}

	private initZoomInfo() {
		const isAlignMode = this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN;

		this._zoomInfo = THREEUtils.generateZoomInfo(
			this._spaceViewRenderer.spaceSize,
			this._spaceViewRenderer.spaceResolution,
			this._spaceViewRenderer.tileResolution,
			isAlignMode,
		);
		console.log(this._zoomInfo);
	}

	private initTileContainers(zoomInfo: IZoomInfo[]) {
		this._pivotContainer = new Object3D();
		this._pivotContainer.name = "pivot";

		if (this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN) {
			if (!this._container.children.includes(this._backgroundTile)) {
				THREEUtils.add(this._container, this._backgroundTile);
			}
		} else {
			// We need this, so we can move the white background with the rest of the tilecontainers together
			const backgroundWrapper = new Object3D();

			backgroundWrapper.name = "backgroundWrapper";
			THREEUtils.setPosition(backgroundWrapper, this._spaceViewRenderer.spaceOffset.x, this._spaceViewRenderer.spaceOffset.y);
			THREEUtils.add(backgroundWrapper, this._backgroundTile);
			THREEUtils.add(this._pivotContainer, backgroundWrapper);
			THREEUtils.add(this._container, this._pivotContainer);
		}

		for (let i = 0; i < zoomInfo.length; ++i) {
			const tileContainerAtThisLevel = new Object3D();

			tileContainerAtThisLevel.name = `${this._pdfURL}_${this._baseNameOfContainers}${i}`;

			tileContainerAtThisLevel.position.setX(this._spaceViewRenderer.spaceOffset.x);
			tileContainerAtThisLevel.position.setY(this._spaceViewRenderer.spaceOffset.y);

			if (this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN) {
				tileContainerAtThisLevel.visible = true;

				if (this._spaceViewRenderer.tileSetsFinished === 1) {
					THREEUtils.add(this._pivotContainer, tileContainerAtThisLevel);
					THREEUtils.add(this._container, this._pivotContainer);
				} else {
					THREEUtils.add(this._container, tileContainerAtThisLevel);
				}
			} else {
				tileContainerAtThisLevel.visible = false;
				THREEUtils.add(this._pivotContainer, tileContainerAtThisLevel);
			}
		}

		this.updateTileVisibility(0);
	}

	public clearAndInit() {
		THREEUtils.clearContainer(this._container);

		THREEUtils.setPosition(this._container, this.container.position.x, this._container.position.y, this._spaceViewRenderer.spaceOffset.z);

		const spaceSize = this._spaceViewRenderer.spaceSize;

		const isAlignMode = this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN;
		const backgroundTileOffset = {
			x: isAlignMode ? this._spaceViewRenderer.spaceOffset.x : 0,
			y: isAlignMode ? this._spaceViewRenderer.spaceOffset.y : 0,
		};

		(this._backgroundTile?.material as BasicMaterial)?.dispose();
		this._backgroundTile = new Mesh(this._planeGeometry, new BasicMaterial(this._white));
		this._backgroundTile.name = "backgroundTile";

		this._backgroundTile.scale.setX(spaceSize.width);
		this._backgroundTile.scale.setY(spaceSize.height);
		this._backgroundTile.position.setX(backgroundTileOffset.x + spaceSize.width / 2);
		this._backgroundTile.position.setY(backgroundTileOffset.y + spaceSize.height / 2);

		const selectedView = this._spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);

		if (this._spaceViewRenderer.mode === SpaceEditorMode.NORMAL && selectedView?.spaceEditorViewSettings?.layers?.background?.isHidden) {
			this._container.visible = false;
		} else {
			// if there's no view, or no layer setting is stored for this, we set it to true by default
			this._container.visible = true;
		}
	}

	public populateData(pdfURL: string) {
		this._currentZoomLevel = 0;
		this._pdfURL = pdfURL;

		this.initZoomInfo();
		this.initTileContainers(this._zoomInfo);

		this._tileRasterizer = this._spaceViewRenderer.areTilesAvailableOnline
			? new OnlineTileFetcher(this._spaceViewRenderer)
			: new OfflineTileRasterizer(this._spaceViewRenderer);

		return this._zoomInfo.length;
	}

	private changeVisibilityOfAllLevelsOfTiles(visible: boolean, n: number = this._pivotContainer.children.length) {
		n = Math.min(n, this._pivotContainer.children.length); // for safety
		for (let i = 1; i < n; ++i /** children[0] is the white background */) {
			this._pivotContainer.children[i].visible = visible;
		}
	}

	private hideAllLevels() {
		this.changeVisibilityOfAllLevelsOfTiles(false);
	}

	private showAllLevels() {
		this.changeVisibilityOfAllLevelsOfTiles(true);
	}

	public showLevelsFromZeroToN(n: number) {
		this.changeVisibilityOfAllLevelsOfTiles(true, n);
	}

	private getContainerByZoomLevel(zoomLevel: number = this._currentZoomLevel) {
		if (this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN) {
			const backgroundIndex = this._spaceViewRenderer.space.spaceFiles.findIndex((spaceFile: SpaceFile) => spaceFile.sourceFileURL === this._pdfURL);

			if (backgroundIndex === 0) {
				return this.firstChildOfPivotContainer;
			} else {
				return this.oldBackgroundContainer;
			}
		} else {
			const defaultIndex = zoomLevel + 1; // 0 is the plain white rectangle

			return this._pivotContainer.children[defaultIndex];
		}
	}

	public updateTileVisibility(level: number) {
		if ((this._spaceViewRenderer.activeCamera as OrthographicCamera).isOrthographicCamera) {
			if (this._spaceViewRenderer.mode !== SpaceEditorMode.ALIGN) {
				const previousZoomLevel = this._currentZoomLevel;

				this._currentZoomLevel = level;
				this._currentlyVisibleContainer = this.getContainerByZoomLevel();
				this.hideAllLevels();

				if (this._currentlyVisibleContainer) {
					this._currentlyVisibleContainer.visible = true;
				}

				if (previousZoomLevel !== this._currentZoomLevel) {
					this._spaceViewRenderer.needsRender = true;
				}
			}
		} else {
			this.showAllLevels();
		}
	}

	private updateTileVisibilityIfNecessary = () => {
		if (!this._tileRasterizer.isPending) {
			/**
			 * If there's no pending tiles, we limit the visibility of the lower-res layers
			 * that we enabled during the loading process with `showLevelsFromZeroToN`
			 */
			this.updateTileVisibility(this._currentZoomLevel);
		}
		this._spaceViewRenderer.needsRender = true;
	};

	public setPivot(worldX: number, worldY: number) {
		const pivotContainer = this._pivotContainer;
		const deltaX = worldX - pivotContainer.position.x;
		const deltaY = worldY - pivotContainer.position.y;
		const multiplicator = 1 / pivotContainer.scale.x;

		THREEUtils.setPosition(this._pivotContainer, worldX, worldY);

		for (const child of this._pivotContainer.children) {
			THREEUtils.setPosition(child, child.position.x - deltaX * multiplicator, child.position.y - deltaY * multiplicator);
		}
	}

	private getCroppedUV(zoomInfoObject: IZoomInfo, i: number, j: number) {
		const cropUV = [1, 1];

		if (i === zoomInfoObject.columns - 1) {
			const tilesWidth = zoomInfoObject.tileSize * zoomInfoObject.columns;
			const spaceWidth = this._spaceViewRenderer.spaceSize.x;
			const appendix = MathUtils.clamp((tilesWidth - spaceWidth) / zoomInfoObject.tileSize, Constants.EPSILON, 1 - Constants.EPSILON);

			cropUV[0] = 1 - appendix;
		}
		if (j === zoomInfoObject.rows - 1) {
			const tilesHeight = zoomInfoObject.tileSize * zoomInfoObject.rows;
			const spaceHeight = this._spaceViewRenderer.spaceSize.y;
			const appendix = MathUtils.clamp((tilesHeight - spaceHeight) / zoomInfoObject.tileSize, Constants.EPSILON, 1 - Constants.EPSILON);

			cropUV[1] = 1 - appendix;
		}

		return cropUV;
	}

	private getTileCoordsAtWorldCoordAtZoomLevel(worldPos: PointDouble, zoomLevel: number) {
		const spaceOffset = this._spaceViewRenderer.spaceOffset;
		const tileSize = this._zoomInfo[zoomLevel].tileSize;
		return {
			x: Math.floor((worldPos.x - spaceOffset.x) / tileSize),
			y: Math.floor((worldPos.y - spaceOffset.y) / tileSize),
		};
	}

	public getTileCoordsAtWorldCoord(worldPos: PointDouble) {
		const tileIds: PointDouble[] = [];

		for (let zoomLevel = 0; zoomLevel < this._zoomInfo.length; ++zoomLevel) {
			tileIds.push(this.getTileCoordsAtWorldCoordAtZoomLevel(worldPos, zoomLevel));
		}

		return tileIds;
	}

	public update() {
		/** Dynamically loads only the necessary tiles, based on the camera's position, frustum, and zoom level */

		/** Show all levels while loading, so we see the low-res version instead of the "whiteness" */

		const cameraControls = this._spaceViewRenderer.toolManager.cameraControls;

		cancelAnimationFrame(this._timeoutId);
		this._spaceViewRenderer.pdfRenderer.clearQueue();
		const zoomInfoObject = this._zoomInfo[this._currentZoomLevel];
		const isAlignMode = this._spaceViewRenderer.mode === SpaceEditorMode.ALIGN;
		const spaceOffset = this._spaceViewRenderer.spaceOffset;
		const targetTileCoords = this.getTileCoordsAtWorldCoordAtZoomLevel(
			{x: cameraControls.target.x.end, y: cameraControls.target.y.end},
			this._currentZoomLevel,
		);

		const gridDistanceLimit = isAlignMode
			? Number.MAX_SAFE_INTEGER
			: MathUtils.clamp(Math.floor(Math.max(window.innerWidth, window.innerHeight) / this._spaceViewRenderer.tileResolution), 1, 5);

		const minX = Math.max(0, targetTileCoords.x - gridDistanceLimit);
		const minY = Math.max(0, targetTileCoords.y - gridDistanceLimit);
		const maxX = Math.min(zoomInfoObject.columns - 1, targetTileCoords.x + gridDistanceLimit);
		const maxY = Math.min(zoomInfoObject.rows - 1, targetTileCoords.y + gridDistanceLimit);

		this._dummyCamera = cameraControls.activeCamera.clone();
		cameraControls.updateCameraPos(this._dummyCamera, cameraControls.target.x.end, cameraControls.target.y.end);
		cameraControls.updateFrustum(this._dummyCamera);

		const useGradientBackgroundEnabled = DebugInformation.isLoggingEnabled;

		if (useGradientBackgroundEnabled !== this._useGradientBackground) {
			this._useGradientBackground = useGradientBackgroundEnabled;
			this.updateGradientBackground();
		}

		let updateTileVisibilityWhileLoading = false;

		let tileId = "";

		for (let i = minX; i <= maxX; ++i) {
			for (let j = minY; j <= maxY; ++j) {
				tileId = `${this._currentZoomLevel}_${i}_${j}_${this._pdfURL}`;
				if (!this._loadedTileIds.has(tileId)) {
					this._dummyVectorMin.set(
						spaceOffset.x + i * zoomInfoObject.tileSize,
						spaceOffset.y + j * zoomInfoObject.tileSize,
						this._spaceViewRenderer.spaceOffset.z,
					);
					this._dummyVectorMax.set(
						spaceOffset.x + (i + 1) * zoomInfoObject.tileSize,
						spaceOffset.y + (j + 1) * zoomInfoObject.tileSize,
						this._spaceViewRenderer.spaceOffset.z,
					);
					this._dummyBox.set(this._dummyVectorMin, this._dummyVectorMax);

					if (isAlignMode || cameraControls.isBoxVisibleForCamera(this._dummyBox, false, this._dummyCamera)) {
						updateTileVisibilityWhileLoading = true;

						const promiseOrTileData = this._tileRasterizer.getTileUrl(tileId);

						this._tileRasterizer.numberOfPendingTiles++;

						const processTileData = (tileData: ITileData) => {
							const zoomInfoObject = tileData.zoomInfoObject;
							const tileId = tileData.tileId;

							this._loadedTileIds.add(tileId);

							const onFinish = () => {
								this._tileRasterizer.numberOfPendingTiles--;
								cancelAnimationFrame(this._timeoutId);
								this._timeoutId = requestAnimationFrame(this.updateTileVisibilityIfNecessary);
								if (!this._tileRasterizer.isPending) {
									this._spaceViewRenderer.onTileSetFinish();
								}
							};

							if (tileData.url) {
								/** Completely white textures are filtered out */
								// For some reason this seems to be faster / smoother than the canvasTexture -> renderer.initTexture method
								const texturePromise = this._textureLoader.loadAsync(tileData.url);

								texturePromise
									.then((texture: Texture) => {
										texture.wrapS = ClampToEdgeWrapping;
										texture.wrapT = ClampToEdgeWrapping;
										URL.revokeObjectURL(tileData.url);
										const componentsOfTileId = tileId.split("_");
										const zoomLevelOfTile = parseInt(componentsOfTileId[0]);
										const x = parseInt(componentsOfTileId[1]);
										const y = parseInt(componentsOfTileId[2]);
										const pdfURL = componentsOfTileId[3];

										if (pdfURL === this._pdfURL) {
											const container = this.getContainerByZoomLevel(zoomLevelOfTile);
											let customLineColor = null;

											if (isAlignMode) {
												const spaceFiles = this._spaceViewRenderer.space.spaceFiles;

												customLineColor = this._pdfURL === spaceFiles[0].sourceFileURL ? 0x00da00 : 0xda0000;
											}

											const cropUV = this.getCroppedUV(zoomInfoObject, i, j);

											const tileMaterial = new TileMaterial({
												map: texture,
												cropUV: cropUV,
												transparent: isAlignMode,
												inverted: this._inverted,
												customLineColor: customLineColor,
												useGradientBackground: this._useGradientBackground,
											});
											const mesh = new Mesh(this._planeGeometry, tileMaterial);

											mesh.scale.setX(zoomInfoObject.tileSize);
											mesh.scale.setY(zoomInfoObject.tileSize);

											mesh.position.setX(zoomInfoObject.tileSize / 2 + x * zoomInfoObject.tileSize);
											mesh.position.setY(zoomInfoObject.tileSize / 2 + y * zoomInfoObject.tileSize);
											mesh.name = tileId;

											THREEUtils.add(container, mesh);
										}
									})
									.catch((error: unknown) => {
										console.log(error);
									})
									.finally(onFinish);
							} else {
								onFinish();
							}
						};

						if (promiseOrTileData instanceof Promise) {
							promiseOrTileData.then(processTileData);
						} else {
							processTileData(promiseOrTileData);
						}
					}
				}
			}
		}

		if (updateTileVisibilityWhileLoading) {
			this.showAllLevels();
			// this.showLevelsFromZeroToN(cameraControls.getCurrentZoomLevel(cameraControls.cameraDistance.min / cameraControls.cameraDistance.end));
		}

		this._spaceViewRenderer.pdfRenderer.processQueue();
	}

	private setInvertColors(inverted: boolean) {
		this._container.traverse((node: Object3D) => {
			if (node instanceof Mesh) {
				const material = node.material;

				if (material instanceof TileMaterial) {
					material.setInvert(inverted);
				} else if (material instanceof BasicMaterial) {
					const newColor = inverted ? 0x000000 : this._white;

					material.setColor(newColor);
				}
			}
		});

		this._spaceViewRenderer.needsRender = true;
	}

	public updateTheme(theme: ThemeType) {
		this._inverted = theme === "dark";
		this.setInvertColors(this._inverted);
	}

	public updateGradientBackground() {
		this._container.traverse((node: Object3D) => {
			if (node instanceof Mesh) {
				const material = node.material;

				if (material instanceof TileMaterial) {
					material.setGradientBackground(this._useGradientBackground);
				}
			}
		});

		this._spaceViewRenderer.needsRender = true;
	}

	public deleteCachedTiles() {
		this._loadedTileIds.clear();
	}

	public clearData() {
		this.deleteCachedTiles();
		this._pdfURL = null;
	}

	//!!! Only valid if mode === SpaceEditorMode.ALIGN!!!
	public get oldBackgroundContainer() {
		return this._container.children[1];
	}

	public get pivotContainer() {
		return this._pivotContainer;
	}

	public get firstChildOfPivotContainer() {
		return this._pivotContainer.children[0];
	}

	public get zoomInfo() {
		return this._zoomInfo;
	}

	public get container() {
		return this._container;
	}

	public get currentZoomLevel() {
		return this._currentZoomLevel;
	}
}

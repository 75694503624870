import styled from "styled-components";
import {radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {getBackgroundColorForCard} from "../../../abstract/view/create/CardUtils";
import ListIcon from "../../../icons/list.svg?react";
import FrameIcon from "../../../icons/frame.svg?react";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {useAppStore} from "../../../../../StateManager";
import type {GridItem, GridItemSide, GridSide} from "./GridTypes";

interface IGridCardProps {
	readonly sideOfList: GridSide;
	readonly typeOfList: GridItem;
	readonly onClose: () => void;
}

export const GridCard = (props: IGridCardProps) => {
	const {sideOfList, typeOfList, onClose} = props;
	const appState = useAppStore((state) => state.appState);
	const {
		app: {graphicalTools},
	} = appState;
	const otherSide = sideOfList === "left" ? "right" : "left";
	const pluralItemName = typeOfList === "boundary" ? "boundaries" : "xyicons";

	const onClick = () => {
		const buttonValue: GridItemSide = `${sideOfList}-${typeOfList}`;
		if (graphicalTools.gridItemSide === buttonValue) {
			graphicalTools.gridItemSide = "";
		} else {
			graphicalTools.gridItemSide = buttonValue;
		}
		onClose();
	};

	return (
		<CardStyled onClick={onClick}>
			<BackgroundWrapper style={{flexDirection: sideOfList === "left" ? "row" : "row-reverse"}}>
				<BackgroundStyled $backgroundImage="table-background.webp">
					<OverlayStyled
						$backgroundColor={`${getBackgroundColorForCard(`${typeOfList} list`)}B2`}
						$roundedBorders={sideOfList}
					/>
					<ListIcon />
				</BackgroundStyled>
				<BackgroundStyled $backgroundImage="spaceeditor-background.webp">
					<OverlayStyled
						$backgroundColor={`${getBackgroundColorForCard("space")}B2`}
						$roundedBorders={otherSide}
					/>
					<FrameIcon />
				</BackgroundStyled>
			</BackgroundWrapper>
			<ButtonLabelStyled>{StringUtils.capitalize(sideOfList)} Side List</ButtonLabelStyled>
			<AdditionalTextStyled>
				{`Open a ${typeOfList} list to the ${sideOfList} side of the space.  This list will display ${pluralItemName} based on the filters applied to the Space.`}
			</AdditionalTextStyled>
		</CardStyled>
	);
};

const AdditionalTextStyled = styled.div`
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: ${colorPalette.gray.c700Dark};
`;

const ButtonLabelStyled = styled.div`
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
`;

const OverlayStyled = styled.div<{$backgroundColor: string; $roundedBorders: "left" | "right"}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.$backgroundColor};
	border-radius: ${(props) => (props.$roundedBorders === "left" ? `${radius.xl} 0 0 ${radius.xl}` : `0 ${radius.xl} ${radius.xl} 0`)};
`;

const BackgroundWrapper = styled.div`
	display: flex;
	border-radius: ${radius.xl};
	height: 144px;
	overflow: hidden;
`;

const BackgroundStyled = styled.div<{$backgroundImage: string}>`
	background-image: url(./src/assets/images/v5/views/${(props) => props.$backgroundImage});
	background-size: cover;
	flex: 1;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 64px;
		height: 64px;
		padding: 8px;
		background-color: ${colorPalette.white};
		border-radius: ${radius.md};
		z-index: 1;
		path {
			stroke-width: 1;
		}
	}
`;

const CardStyled = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 20px 0px #00000033;
	width: 272px;
	height: 280px;
	border-radius: ${radius.xl};
	padding: 16px;
	gap: 16px;
	cursor: pointer;
	outline: 4px solid transparent;

	&:hover {
		background-color: ${colorPalette.primary.c200Light};
		outline-color: ${colorPalette.primary.c500Primary};

		${ButtonLabelStyled} {
			color: ${colorPalette.primary.c500Primary};
		}
	}
`;

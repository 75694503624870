import type {MouseEvent} from "react";
import type {App} from "../../../App";
import type {Catalog} from "../../../data/models/Catalog";
import {Permission} from "../../../generated/api/base";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import FavIcon from "../icons/star.svg?react";
import PenWithLineIcon from "../icons/pen-with-line.svg?react";
import CloneIcon from "../icons/clone.svg?react";
import TrashIcon from "../icons/trash.svg?react";

interface IOptionsProps {
	app: App;
	item: Catalog;
	userCatalogPermission: Permission;
	isFavorite: boolean;
	onDuplicateCatalogClick: (item: Catalog) => void;
	onDeleteCatalogClick: (item: Catalog) => void;
	onFavoriteClick: (item: Catalog) => void;
	onUnFavoriteClick: (item: Catalog) => void;
}

export const getCatalogItemOptionsV5 = (props: IOptionsProps): IDropdownOption[] => {
	const {app, item, userCatalogPermission, onDuplicateCatalogClick, onDeleteCatalogClick, isFavorite} = props;
	const options: IDropdownOption[] = [
		{
			label: "Favorite",
			onClick: () => (isFavorite ? props.onUnFavoriteClick(item) : props.onFavoriteClick(item)),
			IconComponent: FavIcon,
			iconCustomStyle: {fill: isFavorite ? "#fc0" : ""},
		},
		{
			label: "Duplicate",
			onClick: (event: MouseEvent) => {
				app.transport.appState.catalogEditorParentMaybe = document.querySelector(`div[data-id="${item.id}"]`) as HTMLDivElement;
				onDuplicateCatalogClick(item);
			},
			IconComponent: CloneIcon,
		},
		{
			label: "Edit",
			onClick: () => app.onDetailsClick(item),
			IconComponent: PenWithLineIcon,
		},
	];

	if (userCatalogPermission === Permission.Delete) {
		options.push({
			label: "Delete",
			onClick: () => onDeleteCatalogClick(item),
			IconComponent: TrashIcon,
		});
	}

	return options;
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {EditableIcon} from "../../abstract/common/iconeditor/EditableIcon";
import {ColorSelector} from "../../abstract/common/colorselector/ColorSelector";
import type {IconLayer} from "../../abstract/common/iconeditor/IconEditor";
import {IconEditor} from "../../abstract/common/iconeditor/IconEditor";
import {Constants} from "../../space/spaceeditor/logic3d/Constants";
import {WarningWindow} from "../../abstract/popups/WarningWindow";
import {ConfirmWindow} from "../../abstract/popups/ConfirmWindow";
import {PopupUtils} from "../../abstract/popups/PopupUtils";
import {NoResultSearchView} from "../../abstract/grid/NoResultSearchView";
import {CatalogIconType, XyiconFeature, ImageType} from "../../../../generated/api/base";
import type {PointDouble, Color, DefaultIcon, DefaultIconInfo, SkinnedIconInfo, UploadedIcon} from "../../../../generated/api/base";
import {TextInput} from "../../../widgets/input/text/TextInput";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Button} from "../../../widgets/button/Button";
import type {IError, TransportLayer} from "../../../../data/TransportLayer";
import {ImageUtils} from "../../../../utils/image/ImageUtils";
import {ImageUploadPreprocessor} from "../../../../utils/image/ImageUploadPreprocessor";
import type {LibraryImage} from "../../../../data/models/LibraryImage";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {notify} from "../../../../utils/Notify";
import {NotificationType} from "../../../notification/Notification";
import type {Type} from "../../../../data/models/Type";
import {ColorUtils} from "../../../../utils/ColorUtils";
import {IconButton} from "../../../widgets/button/IconButton";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import type {Catalog} from "../../../../data/models/Catalog";
import type {SupportedFontName} from "../../../../data/state/AppStateTypes";
import type {IIsSafeToDelete} from "./GeometrySelector";
import type {IGlyph, IIconConfig} from "./CatalogTypes";
import {getCatalogIconColor} from "./defaults";
import {GoogleImagePanel} from "./GoogleImagePanel";
import {CatalogGlyphs} from "./CatalogGlyphs";
import {LibraryItems} from "./LibraryItems";
import {TextStyleModifier} from "./TextStyleModifier";

interface ICatalogItemEditorProps {
	readonly hidden?: boolean;
	readonly selectedType: Type;
	readonly iconConfig: IIconConfig;
	readonly catalog?: Catalog;
	readonly onBackClick?: () => void;
	readonly onSaveClick?: (iconConfig: IIconConfig, thumbnail: string) => void;
	readonly updateParent: () => void;
	readonly transport?: TransportLayer;
}

interface ICatalogItemEditorState {
	selectedImageSet: "Standard Images" | "Custom Images";
	isGoogleImagePanelOpen: boolean;
	selectedLayer: IconLayer;
	search: string;
	borderRadius: number;
	glyphBackgroundColor: Color;
	glyphIconColor: Color;
	glyphChildrenColors: Color[];
	selectedChildIndex: number;
	isIconColorChanged: boolean;
	fontColor: Color;
	innerPart: string; // selectedImageSet === "Standard" ? svg innerhtml : image url
	imageAspectRatio: number; // needed when selectedImageSet === "Custom"
	iconTranslate: PointDouble; // normalized to [0, 1]
	iconOrientation: number;
	scale: number;
	isFlippedX: boolean;
	isFlippedY: boolean;

	// label
	text: string;
	fontFamily: SupportedFontName;
	fontSize: number;
	isBold: boolean;
	isItalic: boolean;
	isUnderlined: boolean;
	textTranslate: PointDouble; // normalized to [0, 1]
	textOrientation: number;
	isTextBeingModified: boolean;
	horizontalAlignment?: HorizontalAlignment;

	// libraryImages
	selectedLibraryImages: LibraryImage[];
	activeLibraryImage: LibraryImage;

	// other
	isSaving: boolean;
}

@inject("transport")
@observer
export class CatalogItemEditor extends React.Component<ICatalogItemEditorProps, ICatalogItemEditorState> {
	private _libraryImagesRef = React.createRef<LibraryItems>();
	private _isDeletePopupWindowOpen: boolean = false;
	private readonly _defaultSelectedLayer: IconLayer = "Background";
	private _svgRef = React.createRef<SVGSVGElement>();
	private _colorEditPanelRef = React.createRef<HTMLDivElement>();
	private _selectedStandardImage: string; // svg innerHTML

	public static readonly defaultState: {
		borderRadius: number;
		glyphBackgroundColor: Color;
		glyphIconColor: Color;
		glyphChildrenColors: Color[];
		selectedChildIndex: number;
		fontColor: Color;
		iconTranslate: PointDouble;
		iconOrientation: number;
		scale: number;
		isFlippedX: boolean;
		isFlippedY: boolean;
		text: string;
		fontFamily: SupportedFontName;
		fontSize: 16;
		isBold: boolean;
		isItalic: boolean;
		isUnderlined: boolean;
		textTranslate: PointDouble;
		textOrientation: number;
		libraryImages: LibraryImage[];
		selectedLibraryImages: LibraryImage[];
		isSaving: boolean;
		horizontalAlignment: HorizontalAlignment;
	} = {
		borderRadius: 5,
		glyphBackgroundColor: {
			hex: `${ColorUtils.getHexStringFromNumber(Constants.DEFAULT_3D_COLOR)}`,
			transparency: 0,
		},
		glyphIconColor: getCatalogIconColor({hex: "000000", transparency: 0}),
		glyphChildrenColors: [],
		selectedChildIndex: -1,
		fontColor: {
			hex: "000000",
			transparency: 0,
		},
		iconTranslate: {
			x: 0.5,
			y: 0.5,
		},
		iconOrientation: 0,
		scale: 1,
		isFlippedX: false,
		isFlippedY: false,
		text: "",
		fontFamily: "Roboto",
		fontSize: 16,
		isBold: false,
		isItalic: false,
		isUnderlined: false,
		textTranslate: {
			x: 0.5,
			y: 0.5,
		},
		textOrientation: 0,
		libraryImages: [],
		selectedLibraryImages: [],
		isSaving: false,
		horizontalAlignment: HorizontalAlignment.center,
	};

	constructor(props: ICatalogItemEditorProps) {
		super(props);

		this.initState();
	}

	public initState() {
		const iconConfig = this.props.iconConfig;

		let newState: ICatalogItemEditorState = null;

		const isGoogleImagePanelOpenByDefault = false;

		if (iconConfig) {
			const isDefault = iconConfig.iconCategory === 0;

			const iconData = isDefault ? iconConfig.defaultIcon : iconConfig.uploadedIcon;

			let innerPart = isDefault ? iconConfig.innerPart || this.getInnerPartByDefaultImageId(iconConfig.defaultIcon.iconInfo.defaultImageID) : null;

			this._selectedStandardImage = innerPart;

			newState = {
				selectedImageSet: isDefault ? "Standard Images" : "Custom Images",
				isGoogleImagePanelOpen: isGoogleImagePanelOpenByDefault,
				selectedLayer: this._defaultSelectedLayer,
				search: "",
				innerPart,
				imageAspectRatio: 1,
				isTextBeingModified: false,
				borderRadius: iconData.borderRadius,
				glyphBackgroundColor: iconData.backgroundColor,
				glyphIconColor: (iconData.iconInfo as DefaultIconInfo).color || getCatalogIconColor(iconData.backgroundColor),
				glyphChildrenColors: this.props.catalog?.glyphChildrenColors ?? [],
				selectedChildIndex: -1,
				isIconColorChanged: !!this.props.catalog,
				scale: iconData.iconInfo.scale,
				isFlippedX: iconData.iconInfo.isFlippedX,
				isFlippedY: iconData.iconInfo.isFlippedY,
				fontColor: iconData.overlayText.fontColor,
				fontFamily: iconData.overlayText.fontFamily as SupportedFontName,
				fontSize: iconData.overlayText.fontSize,
				iconOrientation: iconData.iconInfo.orientation,
				iconTranslate: iconData.iconInfo.translate,
				text: iconData.overlayText.content,
				textOrientation: iconData.overlayText.orientation,
				textTranslate: iconData.overlayText.translate,
				isBold: iconData.overlayText.isBold,
				isUnderlined: iconData.overlayText.isUnderlined,
				isItalic: iconData.overlayText.isItalic,
				horizontalAlignment: HorizontalAlignment.center,
				selectedLibraryImages: [],
				activeLibraryImage: null,
				isSaving: false,
			};

			newState.glyphBackgroundColor = this.props.catalog ? newState.glyphBackgroundColor : this.getDefaultBackgroundColor();
			newState.glyphIconColor = newState.isIconColorChanged ? newState.glyphIconColor : getCatalogIconColor(newState.glyphBackgroundColor);

			if (!isDefault) {
				const libraryImage = this.actions.getFeatureItemById(
					(iconData.iconInfo as SkinnedIconInfo).libraryImageID,
					XyiconFeature.LibraryImage,
				) as LibraryImage;

				if (libraryImage) {
					innerPart = this.props.transport.getFullPathFromServer(`library/${libraryImage.fileName}`);
					newState.selectedLibraryImages = [libraryImage];
					newState.activeLibraryImage = libraryImage;
				}

				if (innerPart) {
					this.loadImg(innerPart, ImageType.PNG);
				}
			}
		} else {
			newState = {
				...CatalogItemEditor.defaultState,
				isGoogleImagePanelOpen: isGoogleImagePanelOpenByDefault,
				glyphBackgroundColor: this.getDefaultBackgroundColor(),
				isIconColorChanged: false,
				selectedImageSet: "Standard Images",
				selectedLayer: this._defaultSelectedLayer,
				search: "",
				innerPart: CatalogGlyphs.glyphs[0].innerPart,
				imageAspectRatio: 1,
				isTextBeingModified: false,
				activeLibraryImage: null,
			};
		}

		if (!this.state) {
			this.state = newState;
		} else {
			this.setState(newState);
		}
	}

	private onTextChange = (newText: string) => {
		this.setState({
			text: newText,
		});
	};

	private onSectionElementClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const newlySelectedSection = event.currentTarget.textContent as IconLayer;

		this.setState({
			selectedLayer: newlySelectedSection as IconLayer,
		});

		if (newlySelectedSection === "Text" && !this.state.text) {
			this.onTextChange("");
			this.setState({
				isTextBeingModified: true,
			});
		} else {
			this.setState({
				isTextBeingModified: false,
			});
		}
	};

	private onIsBoldChange = (value: boolean) => {
		this.setState({
			isBold: value,
		});
	};

	private onIsItalicChange = (value: boolean) => {
		this.setState({
			isItalic: value,
		});
	};

	private onIsUnderlinedChange = (value: boolean) => {
		this.setState({
			isUnderlined: value,
		});
	};

	private onFontColorChange = (newFontColor: Color) => {
		this.setState({
			fontColor: newFontColor,
		});
	};

	private onFontSizeChange = (newFontSize: number) => {
		this.setState({
			fontSize: newFontSize,
		});
	};

	private onFontFamilyChange = (newFontFamily: SupportedFontName) => {
		this.setState({
			fontFamily: newFontFamily,
		});
	};

	private onClearTextClick = () => {
		this.onTextChange("");
		this.setState({
			textOrientation: CatalogItemEditor.defaultState.textOrientation,
			textTranslate: CatalogItemEditor.defaultState.textTranslate,
			fontColor: CatalogItemEditor.defaultState.fontColor,
			fontFamily: CatalogItemEditor.defaultState.fontFamily,
			fontSize: CatalogItemEditor.defaultState.fontSize,
			isUnderlined: CatalogItemEditor.defaultState.isUnderlined,
			isBold: CatalogItemEditor.defaultState.isBold,
			isItalic: CatalogItemEditor.defaultState.isItalic,
			isTextBeingModified: true,
		});
	};

	private getImageSet = (event: React.MouseEvent<HTMLDivElement>) => {
		const newlySelectedImageSet = event.currentTarget.textContent as "Standard Images" | "Custom Images";

		this.setState({
			selectedImageSet: newlySelectedImageSet,
			selectedLayer: this._defaultSelectedLayer,
			search: "",
		});

		// Reset to default
		this.onImageDeleteClick();
	};

	private onStandardImageSetClick = (event: React.MouseEvent<HTMLDivElement>) => {
		this.getImageSet(event);

		this.setState({
			isGoogleImagePanelOpen: false,
			innerPart: this._selectedStandardImage,
		});

		this.props.updateParent();
	};

	private onCustomImageSetClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		this.getImageSet(event);

		this.setState({
			innerPart: "",
		});
		if (this.state.selectedLibraryImages[0]) {
			await this.onSetLibraryImageActive(this.state.selectedLibraryImages[0]);
		} else if (this.libraryImages[0]) {
			await this.onSetLibraryImageActive(this.libraryImages[0]);
		}

		this.props.updateParent();
	};

	private onFileInputChange = async (file: File, keywords: string[]) => {
		const thumbnailSize = Constants.RESOLUTION.XYICON;

		const uploadData = await ImageUploadPreprocessor.getImageDataForUpload(this.props.transport.appState.fonts, file, true, thumbnailSize);

		const createData = {
			imageType: uploadData.imageType,
			fileName: file.name,
			keywords: keywords,
			thumbnail: uploadData.thumbnail,
			originalImageData: uploadData.fullImageData,
		};

		try {
			(await this.props.transport.services.feature.create(createData, XyiconFeature.LibraryImage)) as LibraryImage[];
		} catch (error) {
			notify(this.props.transport.appState.app.notificationContainer, {
				title: "Error",
				description: `LibraryImage not saved due to the following error: ${error}`,
				type: NotificationType.Error,
			});
		}
	};

	private onSelectLibraryImages = (libraryImages: LibraryImage[]) => {
		this.setState({
			selectedLibraryImages: libraryImages,
			isGoogleImagePanelOpen: false,
		});
	};

	private onSetLibraryImageActive = async (libraryImage: LibraryImage) => {
		const fullImagePath = this.props.transport.getFullPathFromServer(`library/${libraryImage.fileName}`);

		await this.loadImg(fullImagePath, libraryImage.imageType);
		this.setState({
			selectedLibraryImages: [libraryImage],
			activeLibraryImage: libraryImage,
		});
	};

	private async loadImg(url: string, imageType: ImageType) {
		const img = await ImageUtils.loadImage(url);

		// If the dev tools is open, and the cache is disabled, the original image can take a visible amount of time (few hundred ms)
		// to reappear when the state changes, so as a workaround we create a base64 string from the image (png).
		// This way, the image doesn't reload (or at least the reload is not visible at all to the user)
		// NOTE: This conversion happens only on the client's machine, and it's really fast. The original image format stays the same,
		// so there's no need to worry about large filesizes, or slow download speeds because of this
		const innerPart = imageType === ImageType.SVG ? url : await ImageUtils.image2RasterBase64String(this.props.transport.appState.fonts, img);

		this.setState({
			innerPart: innerPart,
			imageAspectRatio: img.width / img.height,
		});
	}

	private getDefaultImageId() {
		const glyph = CatalogGlyphs.glyphs.find((glyph: IGlyph) => glyph.innerPart === this.state.innerPart);

		if (glyph) {
			return glyph.defaultImageID;
		}

		return null;
	}

	private getInnerPartByDefaultImageId(defaultImageId: string) {
		const glyph = CatalogGlyphs.glyphs.find((glyph: IGlyph) => glyph.defaultImageID === defaultImageId);

		if (glyph) {
			return glyph.innerPart;
		}

		return null;
	}

	private get activeLibraryImageId() {
		return this.state.activeLibraryImage.id;
	}

	public onSaveClick = async () => {
		this.setState({
			isSaving: true,
		});

		const compressedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(
			this.props.transport.appState.fonts,
			this._svgRef.current,
			this.state.selectedImageSet === "Standard Images",
		);

		const iconCoreConfig: DefaultIcon & UploadedIcon = {
			borderRadius: this.state.borderRadius,
			backgroundColor: this.state.glyphBackgroundColor,
			iconInfo: {
				defaultImageID: this.state.selectedImageSet === "Standard Images" ? this.getDefaultImageId() : null,
				libraryImageID: this.state.selectedImageSet === "Custom Images" ? this.activeLibraryImageId : null,
				color: this.state.glyphIconColor,
				translate: this.state.iconTranslate as {x: number; y: number},
				orientation: this.state.iconOrientation,
				scale: this.state.scale,
				isFlippedX: this.state.isFlippedX,
				isFlippedY: this.state.isFlippedY,
			},
			overlayText: {
				content: this.state.text,
				fontFamily: this.state.fontFamily,
				fontColor: this.state.fontColor,
				fontSize: this.state.fontSize,
				translate: this.state.textTranslate as {x: number; y: number},
				orientation: this.state.textOrientation,
				isBold: this.state.isBold,
				isItalic: this.state.isItalic,
				isUnderlined: this.state.isUnderlined,
			},
		};

		this.props.onSaveClick(
			{
				iconCategory: this.state.selectedImageSet === "Standard Images" ? CatalogIconType.Default : CatalogIconType.Uploaded,
				defaultIcon: this.state.selectedImageSet === "Standard Images" ? iconCoreConfig : null,
				uploadedIcon: this.state.selectedImageSet === "Custom Images" ? iconCoreConfig : null,
				modelParameters: null,
				innerPart: this.state.innerPart,
			},
			compressedImage,
		);
	};

	private isSafeToDelete = async (libraryImage?: LibraryImage) => {
		const {transport} = this.props;
		const {selectedLibraryImages} = this.state;
		const libraryImageIDList = libraryImage ? [libraryImage.id] : selectedLibraryImages.map((lI) => lI.id);

		const {result} = (await transport.requestForOrganization({
			url: "libraryimages/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				libraryImageIDList,
			},
		})) as {result: IIsSafeToDelete; error: IError};

		return result;
	};

	private onDeleteSelectedLibraryImagesClick = async () => {
		if (!this._isDeletePopupWindowOpen) {
			const {selectedLibraryImages} = this.state;

			const count = selectedLibraryImages.length;

			if (count > 0) {
				const {inUseList} = await this.isSafeToDelete();

				if (inUseList.length === 0) {
					this._isDeletePopupWindowOpen = true;
					const confirmed = await PopupUtils.getDeleteConfirmationPopup(XyiconFeature.LibraryImage, count);

					this._isDeletePopupWindowOpen = false;

					if (confirmed) {
						await this.deleteSelectedLibraryImages(selectedLibraryImages);
					}
				} else {
					await WarningWindow.open(`${inUseList.length} item(s) couldn't be deleted, because they're being used by other components.`);
				}
			}
		}
	};

	private onDeleteLibraryImageClick = async (libraryImage: LibraryImage) => {
		if (!this._isDeletePopupWindowOpen) {
			const selectedLibraryImages = [...this.state.selectedLibraryImages];

			const indexInSelectedImages = selectedLibraryImages.indexOf(libraryImage);

			const {inUseList} = await this.isSafeToDelete(libraryImage);

			if (!inUseList.includes(libraryImage.id)) {
				this._isDeletePopupWindowOpen = true;
				const confirmed = await ConfirmWindow.open("Are you sure you want to delete this image?");

				this._isDeletePopupWindowOpen = false;

				if (confirmed) {
					if (indexInSelectedImages > -1) {
						selectedLibraryImages.splice(indexInSelectedImages, 1);
					}

					this.setState({
						selectedLibraryImages: selectedLibraryImages,
						innerPart: "",
						activeLibraryImage: this.state.activeLibraryImage === libraryImage ? null : this.state.activeLibraryImage,
					});

					await this.actions.deleteItems([libraryImage], XyiconFeature.LibraryImage);
				}
			} else {
				await WarningWindow.open("This item couldn't be deleted, because it is being used by other components.");
			}
		}
	};

	private async deleteSelectedLibraryImages(libraryImages: LibraryImage[]) {
		this.setState({
			selectedLibraryImages: [],
			innerPart: "",
			activeLibraryImage: null,
		});

		await this.actions.deleteItems(libraryImages, XyiconFeature.LibraryImage);
	}

	private getFilteredElements(list: {keywords: string[]}[]) {
		return StringUtils.filterByKeywords(list, this.state.search);
	}

	private get actions() {
		return this.props.transport.appState.actions;
	}

	private onIconOrientationChange = (newOrientation: number) => this.setState({iconOrientation: newOrientation});
	private onIconTranslateChange = (newTranslate: PointDouble) => this.setState({iconTranslate: newTranslate});
	private onScaleChange = (newScale: number) => this.setState({scale: newScale});
	private onTextTranslateChange = (newTranslate: PointDouble) => this.setState({textTranslate: newTranslate});
	private onTextOrientationChange = (newOrientation: number) => this.setState({textOrientation: newOrientation});
	private onBorderRadiusChange = (newBorderRadius: number) => this.setState({borderRadius: newBorderRadius});
	private onTextBeingModifiedChange = (value: boolean) => this.setState({isTextBeingModified: value});
	private onSearchInput = (value: string) => this.setState({search: value});
	private onBackgroundColorChange = (newColor: Color) => this.setState({glyphBackgroundColor: newColor});
	private onFlipXChange = () => this.setState({isFlippedX: !this.state.isFlippedX});
	private onFlipYChange = () => this.setState({isFlippedY: !this.state.isFlippedY});
	private onSelectedChildIndexChange = (iconChildIndex: number) => {
		this.setState({selectedChildIndex: iconChildIndex});
	};

	private onGlyphIconColorChange = (newColor: Color) => {
		if (this.state.selectedChildIndex > -1) {
			const newArr = [...this.state.glyphChildrenColors];

			newArr[this.state.selectedChildIndex] = newColor;
			this.setState({
				glyphChildrenColors: newArr,
			});
		} else {
			this.setState({
				glyphIconColor: newColor,
				isIconColorChanged: true,
				glyphChildrenColors: [],
			});
		}
	};

	private onEditableIconClick = (innerPart: string) => {
		if (this._selectedStandardImage !== innerPart || this.state.innerPart !== innerPart) {
			this._selectedStandardImage = innerPart;
			this.setState({
				innerPart,
				activeLibraryImage: null,
				glyphChildrenColors: [],
			});
		}
	};

	private onImageDeleteClick = () => {
		this.setState({
			innerPart: CatalogGlyphs.glyphs[0]?.innerPart || "",
			activeLibraryImage: null,
			iconTranslate: {
				x: CatalogItemEditor.defaultState.iconTranslate.x,
				y: CatalogItemEditor.defaultState.iconTranslate.y,
			},
			iconOrientation: CatalogItemEditor.defaultState.iconOrientation,
			isFlippedX: CatalogItemEditor.defaultState.isFlippedX,
			isFlippedY: CatalogItemEditor.defaultState.isFlippedY,
		});
	};

	private getImageSetSelector() {
		return (
			<div className="hbox flexCenter radioButtons">
				<div
					className={ReactUtils.cls("radioButton", {active: this.state.selectedImageSet === "Standard Images"})}
					onClick={this.onStandardImageSetClick}
				>
					Standard Images
				</div>
				<div
					className={ReactUtils.cls("radioButton", {active: this.state.selectedImageSet === "Custom Images"})}
					onClick={this.onCustomImageSetClick}
				>
					Custom Images
				</div>
			</div>
		);
	}

	private getDefaultBackgroundColor() {
		const selectedTypeColor = this.props.selectedType?.settings?.color;

		return selectedTypeColor || CatalogItemEditor.defaultState.glyphBackgroundColor;
	}

	private onGoogleImageClick = () => {
		this.setState({
			isGoogleImagePanelOpen: true,
		});
	};

	private onGoogleImagePanelClose = () => {
		this.setState({
			isGoogleImagePanelOpen: false,
		});
	};

	private get libraryImages() {
		return this.actions.getList<LibraryImage>(XyiconFeature.LibraryImage);
	}

	private onTextAlignmentChange = (horizontalAlignment: HorizontalAlignment) => {
		this.setState({horizontalAlignment});
	};

	private getRightPanel() {
		const {
			isGoogleImagePanelOpen,
			selectedLayer,
			glyphBackgroundColor,
			isFlippedX,
			isFlippedY,
			selectedImageSet,
			glyphIconColor,
			glyphChildrenColors,
			isBold,
			isItalic,
			isUnderlined,
			fontColor,
			fontFamily,
			fontSize,
			horizontalAlignment,
			borderRadius,
			innerPart,
			imageAspectRatio,
			iconTranslate,
			iconOrientation,
			scale,
			text,
			textOrientation,
			textTranslate,
			isTextBeingModified,
			selectedChildIndex,
		} = this.state;

		const iconColor = glyphIconColor;
		const imageEditActiveColor = (selectedChildIndex > -1 && glyphChildrenColors[selectedChildIndex]) || iconColor;

		return (
			<>
				{isGoogleImagePanelOpen && (
					<GoogleImagePanel
						defaultSearchValue={this._libraryImagesRef.current?.state.search || ""}
						onCloseClick={this.onGoogleImagePanelClose}
						addImage={this.onFileInputChange}
					/>
				)}
				<div className={ReactUtils.cls("vbox editPanel", {hidden: isGoogleImagePanelOpen})}>
					<div className="hbox radioButtons justifyCenter">
						<div
							className={ReactUtils.cls("radioButton", {active: selectedLayer === "Background"})}
							onClick={this.onSectionElementClick}
						>
							Background
						</div>
						<div
							className={ReactUtils.cls("radioButton", {active: selectedLayer === "Image"})}
							onClick={this.onSectionElementClick}
						>
							Image
						</div>
						<div
							className={ReactUtils.cls("radioButton", {active: selectedLayer === "Text"})}
							onClick={this.onSectionElementClick}
						>
							Text
						</div>
					</div>
					<div className="previewer flex_1">
						<div
							className="iconEditPanelHeader hbox flexCenter"
							ref={this._colorEditPanelRef}
						>
							{selectedLayer === "Background" && (
								<ColorSelector
									title="Background Color"
									label="Background Color"
									color={glyphBackgroundColor}
									onColorChange={this.onBackgroundColorChange}
									changeAlignmentOnPositionCorrection={true}
									outerDivRef={this._colorEditPanelRef}
									horizontalAlignment={HorizontalAlignment.outerRight}
									verticalAlignment={VerticalAlignment.bottom}
								/>
							)}
							{selectedLayer === "Image" && (
								<>
									{this.state.selectedChildIndex === -1 && (
										<>
											<IconButton
												icon="flipX"
												title="Flip Horizontally"
												className={ReactUtils.cls("btn", {active: isFlippedX})}
												onClick={this.onFlipXChange}
											/>
											<IconButton
												icon="flipY"
												title="Flip Vertically"
												className={ReactUtils.cls("btn", {active: isFlippedY})}
												onClick={this.onFlipYChange}
											/>
										</>
									)}
									{selectedImageSet === "Standard Images" && (
										<ColorSelector
											title="Fill"
											horizontalAlignment={HorizontalAlignment.outerRight}
											verticalAlignment={VerticalAlignment.bottom}
											outerDivRef={this._colorEditPanelRef}
											color={imageEditActiveColor}
											onColorChange={this.onGlyphIconColorChange}
										/>
									)}
									{this.state.selectedChildIndex === -1 && selectedImageSet === "Standard Images" && (
										<IconButton
											icon="delete"
											className="btn"
											onClick={this.onImageDeleteClick}
										/>
									)}
								</>
							)}
							{selectedLayer === "Text" && (
								<TextStyleModifier
									isBold={isBold}
									isItalic={isItalic}
									isUnderlined={isUnderlined}
									fontColor={fontColor}
									fontFamily={fontFamily}
									fontSize={fontSize}
									onIsBoldChange={this.onIsBoldChange}
									onIsItalicChange={this.onIsItalicChange}
									onIsUnderlinedChange={this.onIsUnderlinedChange}
									onFontColorChange={this.onFontColorChange}
									onFontSizeChange={this.onFontSizeChange}
									onFontFamilyChange={this.onFontFamilyChange}
									onClearTextClick={this.onClearTextClick}
									onTextAlignmentChange={this.onTextAlignmentChange}
									horizontalAlignment={horizontalAlignment}
									fontStyleDropdownZIndex={10000}
								/>
							)}
						</div>
						<IconEditor
							selectedLayer={selectedLayer}
							backgroundColor={glyphBackgroundColor}
							iconColor={iconColor}
							iconColors={glyphChildrenColors}
							selectedChildIndex={selectedChildIndex}
							replaceColor={true}
							borderRadius={borderRadius}
							isSVG={selectedImageSet === "Standard Images"}
							innerPart={innerPart}
							imageAspectRatio={imageAspectRatio}
							onSelectedChildIndexChange={this.onSelectedChildIndexChange}
							iconTranslate={iconTranslate}
							iconOrientation={iconOrientation}
							onIconOrientationChange={this.onIconOrientationChange}
							scale={scale}
							isFlippedX={isFlippedX}
							isFlippedY={isFlippedY}
							onIconTranslateChange={this.onIconTranslateChange}
							onScaleChange={this.onScaleChange}
							text={text}
							textTranslate={textTranslate}
							textOrientation={textOrientation}
							onTextTranslateChange={this.onTextTranslateChange}
							onTextOrientationChange={this.onTextOrientationChange}
							onTextChange={this.onTextChange}
							onBorderRadiusChange={this.onBorderRadiusChange}
							fontSize={fontSize}
							fontFamily={fontFamily}
							fontColor={fontColor}
							isBold={isBold}
							isItalic={isItalic}
							isUnderlined={isUnderlined}
							horizontalAlignment={horizontalAlignment}
							isTextBeingModified={isTextBeingModified}
							onIsTextBeingModifiedChange={this.onTextBeingModifiedChange}
							svgRef={this._svgRef}
						/>
					</div>
				</div>
			</>
		);
	}

	public override componentDidMount() {
		return this.props.transport.services.feature.refreshList(XyiconFeature.LibraryImage);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ICatalogItemEditorProps>, nextContext: any): void {
		if (this.props.catalog !== nextProps.catalog) {
			this.setState({
				glyphChildrenColors: nextProps.catalog?.glyphChildrenColors ?? [],
			});
		}
	}

	public override render() {
		const {hidden, onBackClick, onSaveClick} = this.props;
		const {
			selectedImageSet,
			activeLibraryImage,
			isSaving,
			isIconColorChanged,
			glyphBackgroundColor,
			glyphIconColor,
			innerPart,
			imageAspectRatio,
			selectedLibraryImages,
			search,
		} = this.state;
		const {
			iconTranslate,
			iconOrientation,
			borderRadius,
			scale,
			isFlippedX,
			isFlippedY,
			text,
			textTranslate,
			textOrientation,
			fontSize,
			fontFamily,
			fontColor,
			isBold,
			isItalic,
			isUnderlined,
			horizontalAlignment,
		} = CatalogItemEditor.defaultState;

		const isValid =
			(selectedImageSet === "Standard Images" && !!this.getDefaultImageId()) || (selectedImageSet === "Custom Images" && !!activeLibraryImage);

		return (
			<div
				className={ReactUtils.cls("CatalogItemEditor vbox flex1", {hidden})}
				style={{minHeight: "100px"}}
			>
				{onBackClick && onSaveClick && (
					<div className="hbox header">
						<div className="sidePanelButtons">
							<Button
								label="Back"
								onClick={onBackClick}
							/>
							<Button
								label={isSaving ? "Saving..." : "Save"}
								onClick={this.onSaveClick}
								disabled={!isValid || isSaving}
							/>
						</div>
					</div>
				)}
				<div className="hbox panelContent flex1 height100">
					<div className="vbox glyphColumn flex1">
						{selectedImageSet === "Standard Images" ? (
							<>
								{this.getImageSetSelector()}
								<TextInput
									placeholder="Search..."
									onInput={this.onSearchInput}
								/>
								<div className="glyphContainer">
									{this.getFilteredElements(CatalogGlyphs.glyphs).map((glyph: IGlyph) => {
										const iconColor = glyphIconColor;

										return (
											<EditableIcon
												classNames={ReactUtils.cls("glyph button", {shadow: iconColor.hex === "000000"})}
												borderRadius={borderRadius}
												backgroundColor={glyphBackgroundColor}
												isIconColorChanged={isIconColorChanged}
												iconColor={iconColor}
												iconColors={[]}
												selectedChildIndex={-1}
												replaceColor={false}
												key={glyph.defaultImageID}
												outline={innerPart === glyph.innerPart}
												isSVG={selectedImageSet === "Standard Images"}
												innerPart={glyph.innerPart}
												imageAspectRatio={imageAspectRatio}
												onClick={this.onEditableIconClick}
												iconTranslate={iconTranslate}
												iconOrientation={iconOrientation}
												scale={scale}
												isFlippedX={isFlippedX}
												isFlippedY={isFlippedY}
												text={text}
												textTranslate={textTranslate}
												textOrientation={textOrientation}
												fontSize={fontSize}
												fontFamily={fontFamily}
												fontColor={fontColor}
												isBold={isBold}
												isItalic={isItalic}
												isUnderlined={isUnderlined}
												horizontalAlignment={horizontalAlignment}
												title={glyph.keywords.join(", ")}
											/>
										);
									})}
								</div>
								<div className="noResult">
									<NoResultSearchView search={search}></NoResultSearchView>
								</div>
							</>
						) : (
							<>
								{this.getImageSetSelector()}
								<LibraryItems
									ref={this._libraryImagesRef}
									onGoogleImageClick={this.onGoogleImageClick}
									type="image"
									libraryItems={this.libraryImages}
									selectedLibraryItems={selectedLibraryImages}
									onItemsSelected={this.onSelectLibraryImages}
									onSetItemActive={this.onSetLibraryImageActive}
									isDeleteSelectedDisabled={selectedLibraryImages.length === 0}
									onDeleteSelectedClick={this.onDeleteSelectedLibraryImagesClick}
									onDeleteClick={this.onDeleteLibraryImageClick}
									onFileInputChange={this.onFileInputChange}
								/>
							</>
						)}
					</div>
					{this.getRightPanel()}
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {flushSync} from "react-dom";
import {PointerDetectorReact} from "../../../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../../../utils/interaction/Pointer";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";

export interface ISliderProps {
	readonly value: number; // dragger's position: min when completely left, max when completely right
	readonly min?: number;
	readonly max?: number;
	readonly width?: number; // width of the slidertrack in px;
	readonly largeStepValue?: number; // while holding shift, it takes larger steps, so it's easier to find sweetspots
	readonly classNames?: string;
	readonly dataLabel?: string;
	readonly title?: string;
	readonly onValueChange: (newValue: number) => void;
	readonly onPointerUp?: () => void;
	readonly disabled?: boolean;
	readonly arrows?: boolean; // increase / decrease
	readonly children?: React.ReactNode;
}

/**
 * General purpose slider with linear scaling (arithmetic progression)
 */

export class Slider extends React.Component<ISliderProps> {
	private _min: number;
	private _max: number;

	private onPointerStart = (pointer: Pointer) => {
		this.updateValue(pointer);
	};

	private onPointerMove = (pointer: Pointer) => {
		this.updateValue(pointer);
	};

	private updateValue(pointer: Pointer) {
		const handlePos = MathUtils.clamp(pointer.localX, 0, this.props.width);
		let newValue = (handlePos / this.props.width) * (this._max - this._min) + this._min; // linear conversion from 0-1 range to min-max range

		if (this.props.largeStepValue > 0) {
			if (KeyboardListener.isShiftDown) {
				const roundedValue = Math.round(newValue / this.props.largeStepValue);

				newValue = roundedValue * this.props.largeStepValue;
			}
		}

		this.props.onValueChange(newValue);
	}

	private onLeftArrowClick = () => {
		const step = this.props.largeStepValue || (this._max - this._min) / 2;
		const newValue = MathUtils.clamp(this.props.value - step, this._min, this._max);

		flushSync(() => {
			this.props.onValueChange(newValue);
		});
		if (this.props.onPointerUp) {
			this.props.onPointerUp();
		}
	};

	private onRightArrowClick = () => {
		const step = this.props.largeStepValue || (this._max - this._min) / 2;
		const newValue = MathUtils.clamp(this.props.value + step, this._min, this._max);

		flushSync(() => {
			this.props.onValueChange(newValue);
		});
		if (this.props.onPointerUp) {
			this.props.onPointerUp();
		}
	};

	public override render() {
		this._min = this.props.min == null ? 0 : this.props.min;
		this._max = this.props.max == null ? 1 : this.props.max;

		const handlePos = `translateX(${((this.props.value - this._min) / (this._max - this._min)) * this.props.width}px)`;

		const sliderTrackStyle = {
			width: `${this.props.width}px`,
		};

		const slider = (
			<PointerDetectorReact
				parent={null}
				onDown={this.onPointerStart}
				onMove={this.onPointerMove}
				onUp={this.props.onPointerUp}
			>
				<div
					className={ReactUtils.cls(`sliderTrack ${this.props.classNames || ""}`, {disabled: this.props.disabled})}
					style={sliderTrackStyle}
					data-label={this.props.dataLabel}
					title={this.props.title}
				>
					<div
						className="dragHandle"
						style={{
							transform: `${handlePos} translateX(-50%) translateY(-50%)`,
						}}
					/>
					{this.props.children}
				</div>
			</PointerDetectorReact>
		);

		const decreaseTitle = this.props.title ? `Decrease ${this.props.title}` : "Decrease";
		const increaseTitle = this.props.title ? `Increase ${this.props.title}` : "Increase";

		return this.props.arrows ? (
			<div className={ReactUtils.cls("SliderContainer", {highPerformanceMode: false})}>
				<div
					className="leftArrow"
					title={decreaseTitle}
					onClick={this.onLeftArrowClick}
				/>
				{slider}
				<div
					className="rightArrow"
					title={increaseTitle}
					onClick={this.onRightArrowClick}
				/>
			</div>
		) : (
			slider
		);
	}
}

import type {Cell, Column, Row, Worksheet} from "exceljs";
import type {INumericFieldSettingsDefinition} from "../models/field/datatypes/Numeric";
import type {View} from "../models/View";
import type {IModel} from "../models/Model";
import {FileUtils} from "../../utils/file/FileUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {MathUtils} from "../../utils/math/MathUtils";
import {ImageUtils} from "../../utils/image/ImageUtils";
import {DateFormatter} from "../../utils/format/DateFormatter";
import type {XyiconFeature} from "../../generated/api/base";
import {FieldDataType} from "../../generated/api/base";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import type {Report} from "../models/Report";
import {REPORT_COUNT_FIELD} from "../state/AppFields";
import {BaseExporter} from "./BaseExporter";

interface IAdditionalInfo {
	"View Name": string;
	"Generated Date": string;
	"Exported By": string;
}

export class ExcelExporter extends BaseExporter {
	protected readonly _extension = "xlsx";
	// https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
	private readonly _defaultFontSize: number = 11;
	private readonly _defaultFontFamily: string = "Calibri";

	// https://docs.microsoft.com/en-us/office/troubleshoot/excel/determine-column-widths
	private readonly _defaultColumnWidth: number = 8.43;
	private readonly _maxColumnWidth: number = 40;

	// excel text size in excelcolumnsize / measured text with canvas 3d context in px -> concrete numbers are derived with measuring an example
	private readonly _excelToCtxRatio: number = 6.43 / 31.077;

	private addAdditionalInfo(workSheet: Worksheet, additionalInfo: IAdditionalInfo) {
		for (const key in additionalInfo) {
			workSheet.addRow([key, additionalInfo[key as keyof typeof additionalInfo]]);
		}
	}

	private formatColumns(workSheet: Worksheet, fieldRefIds: string[]) {
		for (let i = 0; i < fieldRefIds.length; ++i) {
			const fieldRefId = fieldRefIds[i];
			const field = this._appState.actions.getFieldByRefId(fieldRefId);

			if (field && fieldRefId !== REPORT_COUNT_FIELD) {
				if (field.dataType === FieldDataType.Numeric && field.dataTypeSettings) {
					const dataTypeSettings = field.dataTypeSettings as INumericFieldSettingsDefinition;
					const decimals = MathUtils.isValidNumber(dataTypeSettings?.decimals) ? dataTypeSettings.decimals : 0;

					let numFmt = `0${decimals === 0 ? "" : "."}${new Array(decimals + 1).join("0")}`;

					if (dataTypeSettings.formatting === "csv" || dataTypeSettings.formatting === "currency") {
						numFmt = `#,##${numFmt}`;

						if (dataTypeSettings.formatting === "currency") {
							numFmt = `$${numFmt}`;
						}
					}
					workSheet.getColumn(i + 1).numFmt = numFmt;
				}
			} else if (fieldRefId === REPORT_COUNT_FIELD) {
				let numFmt = "0";

				workSheet.getColumn(i + 1).numFmt = numFmt;
			}
		}
	}

	private addData<T extends IModel>(workSheet: Worksheet, view: View, items: T[]) {
		const fieldRefIds = this.getFieldRefIds(view).filter((f) => !f.includes("icon"));

		const headers = this.getHeaders(fieldRefIds);

		workSheet.addRow(headers);

		this.formatColumns(workSheet, fieldRefIds);

		for (const item of items) {
			const row: (string | number)[] = [];

			for (let i = 0; i < fieldRefIds.length; ++i) {
				const fieldRefId = fieldRefIds[i];
				const field = this._appState.actions.getFieldByRefId(fieldRefId);

				const fieldValues = this.getFieldData(item, fieldRefId).filter(StringUtils.isTruthy).flat();

				if (field.dataType === FieldDataType.Numeric && fieldValues.length === 1) {
					const fieldValueAsFloat = Number(this._appState.actions.getFieldValue(item, fieldRefId));

					row.push(isNaN(fieldValueAsFloat) ? fieldValues[0] : fieldValueAsFloat);
				} else {
					row.push(fieldValues.join("\n"));
				}
			}

			workSheet.addRow(row);
		}

		workSheet.columns.forEach((col, index) => {
			const field = this._appState.actions.getFieldByRefId(fieldRefIds[index]);

			if (field.dataType === FieldDataType.Numeric && (field.dataTypeSettings as INumericFieldSettingsDefinition).formatting === "percentage") {
				col.numFmt = "#.##%";
			}
		});
	}

	private formatSheet(workSheet: Worksheet, filterButton: boolean) {
		const filterButtonMargin = filterButton ? "ww" : "";

		workSheet.eachRow((row: Row, rowNumber: number) => {
			let maxNewLineCount = 0;

			row.eachCell((cell: Cell, colNumber: number) => {
				let newLineCount = cell.text.match(/\n/g)?.length || 0;

				maxNewLineCount = Math.max(maxNewLineCount, newLineCount);

				if (newLineCount > 0) {
					cell.alignment = {wrapText: true};
				}
			});

			if (maxNewLineCount > 0) {
				if (!MathUtils.isValidNumber(row.height)) {
					row.height = workSheet.properties.defaultRowHeight;
				}
				row.height *= maxNewLineCount + 1;
			}
		});

		workSheet.columns.forEach((col: Column, index: number) => {
			let maxCellWidth = this._defaultColumnWidth;

			col.eachCell((cell: Cell, rowNumber: number) => {
				const textToMeasure = `${cell.text ?? ""}${filterButtonMargin} `;

				maxCellWidth = Math.max(
					maxCellWidth,
					ImageUtils.getTextWidthInPx(textToMeasure, this._defaultFontFamily, this._defaultFontSize, cell.style.font?.bold ? "bold" : "normal") *
						this._excelToCtxRatio,
				);
			});

			col.width = Math.min(maxCellWidth, this._maxColumnWidth);
		});
	}

	protected async _exportView<T extends IModel>(view: View, items: T[]) {
		const ExcelJS = (await import(/* webpackChunkName: "exceljs" */ "exceljs")).default;

		const workBook = new ExcelJS.Workbook();
		const workSheet = workBook.addWorksheet();

		this.addAdditionalInfo(workSheet, {
			"View Name": view.name,
			"Generated Date": DateFormatter.format(new Date().toString()),
			"Exported By": this._appState.user.fullName,
		});

		// 2 empty lines
		workSheet.addRows([[], []]);

		this.addData(workSheet, view, items);

		this.formatSheet(workSheet, false);

		const fileName = this.getFileName(view);
		const buffer = (await workBook.xlsx.writeBuffer()) as Buffer;

		FileUtils.downloadFileGivenByData(buffer, fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
	}

	public async exportTable(data: (string | number)[][], fileName: string, feature: XyiconFeature, report?: Report) {
		let clonedData = ObjectUtils.deepClone(data) || [];

		const headers = clonedData[0]?.map((v) => `${v}`) || [];

		clonedData = clonedData.slice(1);

		const ExcelJS = (await import(/* webpackChunkName: "exceljs" */ "exceljs")).default;

		// https://stackoverflow.com/questions/68103638/exceljs-we-found-a-problem-with-some-content-in-filename-xlsx
		const fileNameWithoutForbiddenChars = fileName.replace(/\*|\?|\:|\\|\/|\[|\]|\<|\>|\||\"| /g, "_"); // cannot include any of the following characters: * ? : \ / [ ] < > | "

		// Also: `Worksheet name <worksheetname> exceeds 31 chars. This will be truncated`
		const workSheetName = fileNameWithoutForbiddenChars.substring(0, 31);

		const workBook = new ExcelJS.Workbook();
		const workSheet = workBook.addWorksheet(workSheetName);

		const fieldRefIds = report
			? [...report.displayedFields.map((f) => f.field), ...report.displayedLinkedFields.map((f) => f.field)]
			: headers.map((h) => this._appState.actions.getFieldByName(feature, h)).map((f) => f?.refId);

		this.formatColumns(workSheet, fieldRefIds);

		for (let j = 0; j < clonedData.length; ++j) {
			for (let i = 0; i < fieldRefIds.length; ++i) {
				const field = this._appState.actions.getFieldByRefId(fieldRefIds[i]);

				if (field) {
					if (field.dataType === FieldDataType.Numeric) {
						const cell = `${clonedData[j][i]}`;
						const values = cell.split("\n");

						if (values.length > 1) {
							clonedData[j][i] = values.map((v) => this._appState.actions.formatValue(v, field.refId) as string).join("\n");
						}
					}
				}
			}
		}

		const filterButton = true;

		workSheet.addTable({
			name: "Table",
			ref: "A1",
			columns: headers.map((h) => ({name: h, filterButton: filterButton})),
			rows: clonedData,
			style: {
				theme: "TableStyleMedium2",
				showRowStripes: true,
			},
		});

		this.formatSheet(workSheet, filterButton);
		const buffer = (await workBook.xlsx.writeBuffer()) as Buffer;

		FileUtils.downloadFileGivenByData(buffer, fileNameWithoutForbiddenChars, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
	}
}

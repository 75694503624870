import {computed, makeObservable, observable} from "mobx";
import type {User} from "@sentry/react";
import type {DashboardDto, DashboardType, WidgetDto} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import type {Organization} from "./Organization";
import type {IModel} from "./Model";

export class Dashboard implements IModel {
	private _appState: AppState;

	@observable
	private _data: DashboardDto;

	constructor(data: DashboardDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data: DashboardDto) {
		this._data = data;
	}

	@computed
	public get widgets(): WidgetDto[] {
		return this._data.widgets ?? [];
	}

	@computed
	public get id(): string {
		return this._data.dashboardID;
	}

	@computed
	public get refId(): string {
		return this._data.dashboardRefID;
	}

	@computed
	public get organizationId(): string {
		return this._data.organizationID;
	}

	@computed
	public get organization(): Organization {
		return this._appState.organizations.find((o) => o.id === this.organizationId);
	}

	@computed
	public get dashboardType(): DashboardType {
		return this._data.dashboardType;
	}

	@computed
	public get userId(): string {
		return this._data.userID;
	}

	@computed
	public get user(): User {
		return this._appState.actions.findUser(this.userId);
	}

	@computed
	public get data(): DashboardDto {
		return this._data;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IBasicPropsFor3DTweaking} from "../SpaceView";
import {ConfirmWindow} from "../../abstract/popups/ConfirmWindow";
import {isSpaceNamePresentInAnotherSpaceVersion, SpaceEditorMode} from "../spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import type {SpaceTool} from "../spaceeditor/logic3d/features/tools/Tools";
import {SpaceEditor} from "../spaceeditor/ui/SpaceEditor";
import {FileUtils} from "../../../../utils/file/FileUtils";
import {XyiconFeature} from "../../../../generated/api/base";
import {Space} from "../../../../data/models/Space";
import type {SpaceFileInsertionInfo} from "../../../../generated/api/base";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {Functions} from "../../../../utils/function/Functions";
import type {AppState} from "../../../../data/state/AppState";
import type {App} from "../../../../App";
import type {Type} from "../../../../data/models/Type";
import {PDFList} from "./PDFList";
import type {IOnePagePDFDoc} from "./CreateSpacePanel";

interface ISpaceConfirmSettingsProps extends IBasicPropsFor3DTweaking {
	readonly selectedVersion: SpaceVersion;
	readonly spaceType: Type;
	readonly pdfFiles: {
		originalFiles: File[];
		onePagePDFDocs: IOnePagePDFDoc[][]; // OriginalPDFFiles split by pages. pdfFiles.onePagePDFDocs[3] is an array of PDF documents that is generated by the pages of pdpfFiles.originalFiles[3]
	};
	readonly applySpaceUnitsPerMeterToAllSpaces: (spaceUnitsPerMeter: number) => void;
	readonly forceUpdate: () => void; // needed to update the "next" button (enabled/disabled)
	readonly appState?: AppState;
	readonly app?: App;
}

interface ISpaceConfirmSettingsState {
	activePDFFile: IOnePagePDFDoc;
	activeToolId: SpaceTool;
}

@inject("app")
@inject("appState")
@observer
export class SpaceConfirmSettings extends React.Component<ISpaceConfirmSettingsProps, ISpaceConfirmSettingsState> {
	constructor(props: ISpaceConfirmSettingsProps) {
		super(props);
		this.state = {
			activePDFFile: this.props.pdfFiles.onePagePDFDocs[0][0],
			activeToolId: "pan",
		};
	}

	private async loadSpace(space: Space) {
		await this.props.app.spaceViewRenderer.populateData(space);
	}

	private async loadActivePDF(newActivePDF: IOnePagePDFDoc = this.state.activePDFFile) {
		let space: Space;

		if (this.getMode(newActivePDF) === SpaceEditorMode.ALIGN) {
			const spaceName = newActivePDF.spaceName;
			const spaces = this.props.appState.actions.getList(XyiconFeature.Space) as Space[];

			space = spaces.find((space: Space) => space.name === spaceName);
		}

		const spaceFileData = {
			spaceFileID: null as string,
			sourceFile: await this.getActivePDFURL(newActivePDF),
			thumbnailFile: newActivePDF.thumbnail,
			settings: newActivePDF.insertionInfo ? {insertionInfo: newActivePDF.insertionInfo} : (null as {insertionInfo: SpaceFileInsertionInfo}),
			spaceVersionID: this.props.selectedVersion.id,
		};

		if (!space) {
			space = new Space(
				{
					spaceID: null,
					spaceRefID: null,
					spaceTypeID: this.props.spaceType.id,
					portfolioID: this.props.appState.portfolioId,
					name: newActivePDF.spaceName,
					unitsPerMeter: null,
					fieldData: null,
					settings: null,
					spaceFiles: [spaceFileData],
				},
				this.props.appState,
			);
		} else {
			space.addTemporarySpaceFile(spaceFileData);
		}

		if (newActivePDF.insertionInfo) {
			space.newestSpaceFile.setInsertionInfo(newActivePDF.insertionInfo);
		}

		await this.loadSpace(space);
	}

	private onSelect = async (originalPDFIndex: number, originalPageNumber: number) => {
		const setNewState = async () => {
			this.setActiveTool("pan");
			const newActivePDF = this.props.pdfFiles.onePagePDFDocs[originalPDFIndex][originalPageNumber];

			this.setState({
				activePDFFile: newActivePDF,
			});
			await this.loadActivePDF(newActivePDF);
		};

		if (this.state.activePDFFile.spaceId && !this.state.activePDFFile.isAlignmentConfirmed) {
			const confirmed = await ConfirmWindow.open("Are you sure you want to switch without confirming your alignment first?");

			if (confirmed) {
				await setNewState();
			}
		} else {
			await setNewState();
		}
	};

	private async getActivePDFURL(activePDFFile: IOnePagePDFDoc = this.state.activePDFFile) {
		return FileUtils.createURLFromData(await activePDFFile.doc.save());
	}

	private setScale = (spaceUnitsPerMeter: number, applyToAllSpaces: boolean = false) => {
		if (applyToAllSpaces) {
			this.props.applySpaceUnitsPerMeterToAllSpaces(spaceUnitsPerMeter);
		} else {
			this.state.activePDFFile.spaceUnitsPerMeter = spaceUnitsPerMeter;
			this.props.forceUpdate();
			this.forceUpdate();
		}
	};

	private confirmAlignment = (insertionInfo: SpaceFileInsertionInfo) => {
		const activePDFFile = this.state.activePDFFile;

		activePDFFile.insertionInfo = insertionInfo;
		activePDFFile.isAlignmentConfirmed = true;

		const space = this.props.appState.actions.getFeatureItemById(activePDFFile.spaceId, XyiconFeature.Space) as Space;

		space.newestSpaceFile.setInsertionInfo(insertionInfo);

		this.props.forceUpdate();
		this.forceUpdate();
	};

	private setActiveTool = (id: SpaceTool) => {
		this.setState({
			activeToolId: id,
		});
	};

	private getMode(activePDFFile: IOnePagePDFDoc = this.state.activePDFFile) {
		const spaceName = activePDFFile.spaceName;

		return isSpaceNamePresentInAnotherSpaceVersion(this.props.appState.actions, spaceName) ? SpaceEditorMode.ALIGN : SpaceEditorMode.SET_SCALE;
	}

	public override async componentDidMount() {
		await this.loadActivePDF();
	}

	public override render() {
		return (
			<div className="SpaceScaleSettings hbox">
				<PDFList
					selectedVersion={this.props.selectedVersion}
					pdfFiles={this.props.pdfFiles}
					mode="SetScaleOrAlign"
					activePDFFile={this.state.activePDFFile}
					onSelect={this.onSelect}
					forceUpdate={this.props.forceUpdate}
				/>
				<SpaceEditor
					mode={this.getMode()}
					setScale={this.setScale}
					confirmAlignment={this.confirmAlignment}
					setActiveTool={this.setActiveTool}
					activeToolId={this.state.activeToolId}
					selectionToolType="selection"
					onAddCatalogClick={Functions.emptyFunction}
					onDuplicateCatalogClick={Functions.emptyFunction}
					spaceViewShareClick={Functions.emptyFunction}
					onOpenSpaceToPDFExportPanel={Functions.emptyFunction}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {Portfolio} from "../../../data/models/Portfolio";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {INotificationParams} from "../../notification/Notification";
import {NotificationType} from "../../notification/Notification";
import type {IPortfolioDuplicationStatus} from "../../../data/signalr/SignalRListener";
import {notify} from "../../../utils/Notify";
import type {IModel} from "../../../data/models/Model";
import type {IDuplicationRequest} from "../../5.0/modules/ActionBarUtils";
import {CreatePortfolioPanel} from "./create/CreatePortfolioPanel";

export interface IPortfolioViewProps {
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
export class PortfolioView extends React.Component<IPortfolioViewProps> {
	private _moduleView = React.createRef<ModuleView>();
	private _duplicationRequests: IDuplicationRequest[] = [];

	private onDuplicatePortfolioClick = async (model: Portfolio) => {
		try {
			const result = await this.props.transport.duplicatePortfolio(model);

			this._duplicationRequests.push({requestId: result.requestID, portfolio: model, notification: null});
			this.portfolioDuplicationStatusReceivedSignal.add(this.onStatusUpdated);
		} catch (error) {
			console.error(error);
		}
	};

	private onStatusUpdated = (data: IPortfolioDuplicationStatus) => {
		const duplicationRequest = this._duplicationRequests.find((request) => request.requestId === data.requestID);

		if (duplicationRequest) {
			duplicationRequest.notification?.onClose();

			if (data.isCompleted) {
				this.portfolioDuplicationStatusReceivedSignal.remove(this.onStatusUpdated);
				this._duplicationRequests = this._duplicationRequests.filter((request) => request !== duplicationRequest);

				const notificationParams: INotificationParams = {
					type: NotificationType.Success,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio is Completed!`,
					lifeTime: Infinity,
				};

				if (data.isError) {
					notificationParams.title = `Duplicating ${duplicationRequest.portfolio.name} Portfolio Failed.`;
					notificationParams.type = NotificationType.Error;
					notificationParams.description = data.message;
				}

				duplicationRequest.notification = notify(this.props.appState.app.notificationContainer, notificationParams);
			} else {
				duplicationRequest.notification = notify(this.props.appState.app.notificationContainer, {
					type: NotificationType.Message,
					title: `Duplicating ${duplicationRequest.portfolio.name} Portfolio`,
					description: data.message,
					lifeTime: Infinity,
				});
			}
		}
	};

	private canDeletePortfolios = (selectedItems: IModel[]) => {
		const {actions, user} = this.props.appState;

		if (!user) {
			return false;
		}
		if (user.isAdmin) {
			return true;
		}

		return selectedItems.every((item: IModel) => {
			const permission = actions.getPortfolioPermission(item.id);

			return permission >= Permission.Delete;
		});
	};

	private isAdmin = () => {
		return this.props.appState.user?.isAdmin;
	};

	private get portfolioDuplicationStatusReceivedSignal() {
		return this.props.transport.signalR.listener.signals.portfolioDuplicationStatusReceived;
	}

	public override componentDidMount() {
		const {transport, appState} = this.props;
		const {actions} = appState;
		const portfolioId = transport.services.localStorage.get(actions.getKeyForLocalStorageSelectedPortfolio());
		const actionsCurrentPortfolio = actions.getCurrentPortfolio();
		const currentPortfolio = actions.getPortfolioById(portfolioId) ?? actionsCurrentPortfolio;

		if (currentPortfolio) {
			if (currentPortfolio.id !== actions.getCurrentPortfolio()?.id) {
				this.props.appState.app.transport.services.auth.switchPortfolio(currentPortfolio.id);
			}

			this._moduleView.current?.selectItem(currentPortfolio);

			this.props.transport.services.feature.refreshList(XyiconFeature.PortfolioDocument);
		}
	}

	public override render() {
		return (
			<ModuleView
				ref={this._moduleView}
				feature={XyiconFeature.Portfolio}
				actionBar={[
					{
						id: "import",
						title: "Update Portfolios with an Excel file",
						label: "Import",
						enabled: this.isAdmin,
					},
					{
						id: "add",
						title: "Create Portfolio Item",
						label: "Create",
						enabled: this.isAdmin,
					},
					{
						id: "duplicate",
						title: "Duplicate Portfolio Item",
						label: "Duplicate",
						enabled: (selectedItems) => selectedItems.length === 1 && this.isAdmin(),
					},
					{
						id: "delete",
						title: "Delete Portfolio Item",
						label: "Delete",
						enabled: (selectedItems) => selectedItems.length !== 0 && this.canDeletePortfolios(selectedItems),
					},
				]}
				create={(onClose) => <CreatePortfolioPanel onClose={onClose} />}
				maps={true}
				onDuplicate={this.onDuplicatePortfolioClick}
			/>
		);
	}
}

import React, {useCallback, useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react";
import styled, {css} from "styled-components";
import {XyiconFeature} from "../../../../../generated/api/base";
import {Type} from "../../../../../data/models/Type";
import type {Type as PanelType} from "../../../../../data/models/Type";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {notify} from "../../../../../utils/Notify";
import {NotificationType} from "../../../../notification/Notification";
import {useAppStore} from "../../../../../StateManager";
import {ButtonV5} from "../../../button/ButtonV5";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {TypeFormV5} from "./form/TypeFormV5";

interface ICreateTypePanelProps {
	readonly moduleFeature: XyiconFeature;
	readonly feature: XyiconFeature;
	readonly onCreated: (id: string) => void;
}

export const CreateTypePanelV5 = (props: ICreateTypePanelProps) => {
	const {feature, moduleFeature, onCreated} = props;
	const appState = useAppStore((state) => state.appState);
	const {
		app: {transport, navigation},
	} = appState;

	const panelTypeRef = useRef<PanelType>(new Type({feature}, appState));
	const [createClicked, setCreateClicked] = useState(false);

	const onCreateClicked = useCallback(async () => {
		if (!panelTypeRef.current.name) {
			return;
		}
		setCreateClicked(true);

		try {
			const {type} = await transport.services.typefield.createType(panelTypeRef.current);

			if (type) {
				resetCreateType();
				onCreated(type.id);
			}

			if ([XyiconFeature.Space, XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(moduleFeature)) {
				if (appState.lists[XyiconFeature.PermissionSet].array.length > 0) {
					const moduleName = featureTitles[moduleFeature];

					notify(appState.app.notificationContainer, {
						title: `Enable new ${moduleName} type in permission sets!`,
						type: NotificationType.Message,
						lifeTime: Infinity,
						description: `By default, new types are disabled (hidden) in all permission sets. Click Change Permissions to enable the new ${moduleName} type for your existing permission sets.`,
						buttonLabel: "Change Permissions",
						onActionButtonClick: () => {
							navigation.go("app/settings/permissionsets/portfolios");
						},
					});
				}
			}
		} catch (e) {
			console.error("Error on creating type", e);
		}
	}, [appState.app.notificationContainer, appState.lists, moduleFeature, navigation, onCreated, transport.services.typefield]);

	const onNameInput = (value: string) => {
		panelTypeRef.current.name = value.trim();
	};

	const resetCreateType = () => {
		panelTypeRef.current.name = "";
		setCreateClicked(false);
	};

	useEffect(() => {
		const onKeyUp = (event: KeyboardEvent) => {
			if (event.key === KeyboardListener.KEY_ENTER) {
				onCreateClicked();
			}
		};

		const addListeners = () => {
			KeyboardListener.getInstance().signals.up.add(onKeyUp);
		};

		const removeListeners = () => {
			KeyboardListener.getInstance().signals.up.remove(onKeyUp);
		};

		addListeners();

		return () => {
			removeListeners();
		};
	}, [onCreateClicked]);

	return (
		<Observer>
			{() => {
				return (
					<CreateTypePanelContainerStyled $feature={feature}>
						<TypeFormV5
							type={panelTypeRef.current}
							createPanelNameInput={onNameInput}
							isCreatingType={true}
						/>
						<ButtonV5
							label="Confirm"
							onClick={onCreateClicked}
							disabled={!panelTypeRef.current.name || createClicked}
							className="button"
						/>
					</CreateTypePanelContainerStyled>
				);
			}}
		</Observer>
	);
};

const CreateTypePanelContainerStyled = styled.div<{$feature: number}>`
	display: flex;
	flex-direction: column;
	gap: 24px;

	.label span {
		overflow: visible;
	}

	.button {
		width: 70px;
		margin-left: auto;
	}

	${(props) => {
		if (props.$feature === XyiconFeature.Portfolio || props.$feature === XyiconFeature.Xyicon) {
			return css`
				.element {
					min-width: 237px;
				}

				.label {
					width: 51px;
				}
			`;
		} else {
			return css`
				.label {
					display: flex;
					align-items: center;
					justify-content: flex-start;
					gap: 4px;
				}

				.span {
					display: flex;
					align-items: center;
				}
			`;
		}
	}}
	.element {
		margin: 0;
	}
`;

import styled from "styled-components";
import {useState} from "react";
import {MathUtils} from "../../../utils/math/MathUtils";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../utils/interaction/Pointer";
import type {IHSLColor} from "./AdvancedColorPanelV5";

interface ColorGradientProps {
	readonly color: IHSLColor;
	readonly onSLChange: (newSaturation: number, newLightness: number) => void;
	readonly onPointerUp: (pointer: Pointer) => void;
}

const sizeOfColorGradient = {
	width: 232, // derived from figma
	height: 128, // derived from figma
};

export const ColorGradientV5 = (props: ColorGradientProps) => {
	const size = sizeOfColorGradient;

	const [localX, setLocalX] = useState<number>(null);
	const [localY, setLocalY] = useState<number>(null);

	const updateValue = (pointer: Pointer) => {
		const handlePos = {
			x: MathUtils.clamp(pointer.localX, 0, size.width),
			y: MathUtils.clamp(pointer.localY, 0, size.height),
		};

		const newSLValues = {
			s: handlePos.y / size.height,
			l: handlePos.x / size.width,
		};

		setLocalX(handlePos.x);
		setLocalY(handlePos.y);

		props.onSLChange(newSLValues.s, newSLValues.l);
	};

	const onPointerStart = (pointer: Pointer) => {
		updateValue(pointer);
	};

	const onPointerMove = (pointer: Pointer) => {
		updateValue(pointer);
	};

	const gradientStyle = {
		backgroundImage: `linear-gradient(hsl(0, 0%, 50%), hsl(${props.color.hue}deg, 100%, 50%))`,
	};

	const handlePos = {
		x: localX ?? props.color.lightness * size.width,
		y: localY ?? props.color.saturation * size.height,
	};

	return (
		<PointerDetectorReact
			parent={null}
			onDown={onPointerStart}
			onMove={onPointerMove}
			onUp={props.onPointerUp}
		>
			<ColorGradientStyled className="ColorGradient">
				<div
					style={gradientStyle}
					className="gradientPanel"
				/>
				<div
					className="dragHandle"
					style={{
						transform: `translateX(${handlePos.x}px) translateY(${handlePos.y}px) translateX(-50%) translateY(-50%)`,
					}}
				/>
			</ColorGradientStyled>
		</PointerDetectorReact>
	);
};

const ColorGradientStyled = styled.div`
	position: relative;
	width: ${sizeOfColorGradient.width}px;
	height: ${sizeOfColorGradient.height}px;

	.gradientPanel {
		position: relative;
		cursor: pointer;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
		width: 100%;
		height: 100%;

		&::before {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to right, black, rgba(127, 127, 127, 0), white);
		}
	}

	.dragHandle {
		position: absolute;
		cursor: none;
		width: 10px;
		height: 10px;
		border-radius: 100%;
		border: 2px solid #ffffff;
		top: 0;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	}
`;

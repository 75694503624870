import {useRef} from "react";
import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import {SpaceCreatePopupV5} from "./space/SpaceCreatePopupV5";
import {getActionBarButtonsForFeature} from "./ActionBarUtils";

const OWN_FEATURE = XyiconFeature.Space;

export const SpaceViewV5 = () => {
	const appState = useAppStore((state) => state.appState);
	const createButtonRef = useRef<HTMLDivElement>();

	return (
		<ModuleViewV5
			feature={OWN_FEATURE}
			className="SpaceView"
			create={(onClose) => (
				<SpaceCreatePopupV5
					onClose={onClose}
					parentRef={createButtonRef}
				/>
			)}
			createButtonRef={createButtonRef}
			actionBar={getActionBarButtonsForFeature(OWN_FEATURE, appState)}
		/>
	);
};

import * as React from "react";
import {inject} from "mobx-react";
import type {IActionBarItem} from "../abstract/ModuleView";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import {Report} from "../../../data/models/Report";
import {ReportScope, ReportType} from "../../../generated/api/reports";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {App} from "../../../App";
import {notify} from "../../../utils/Notify";
import type {AppState} from "../../../data/state/AppState";
import {NotificationType} from "../../notification/Notification";
import {WarningWindow} from "../abstract/popups/WarningWindow";
import {DropdownButton} from "../../widgets/button/DropdownButton";
import {IconButton} from "../../widgets/button/IconButton";
import type {Portfolio} from "../../../data/models/Portfolio";
import {RunReportPage} from "./run/RunReportPage";
import {ReportPanel} from "./create/ReportPanel";

interface IReportViewProps {
	readonly transport?: TransportLayer;
	readonly app?: App;
	readonly appState?: AppState;
}

interface IReportViewState {
	editingReport: Report;
	runningReport: Report;
}

@inject("app")
@inject("appState")
@inject("transport")
export class ReportView extends React.Component<IReportViewProps, IReportViewState> {
	private readonly _moduleRef = React.createRef<ModuleView<Report>>();

	constructor(props: IReportViewProps) {
		super(props);
		this.state = {
			editingReport: new Report(this.props.appState),
			runningReport: null,
		};

		props.app.reportView = this;
	}

	private onRunReport = (report: Report) => {
		this.runReport([report]);
	};

	// Called when clicking on action bar and from TableRow
	public runReport = async (reports: Report[]) => {
		const {transport} = this.props;
		const report = reports[0];

		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList.length === 0) {
			await WarningWindow.open("You have to select a portfolio for the report to be able to run it!");
			return;
		}
		if (!this.hasAccessToRunReport(report)) {
			await WarningWindow.open("You do not have access to run this report.");
			return;
		}

		if (report.type === ReportType.UserDefinedReport || report.type === ReportType.UserDefinedLinkedXyiconReport) {
			// user defined report -> run it and open RunReport window
			this.setState({
				runningReport: report,
			});

			await this.props.transport.services.feature.runSavedReport(report);
		} else {
			// custom report -> download / email it

			const notification = notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Message,
				title: "One moment please, we are running your report",
				description: "You will receive a notification when your report is ready for download.",
			});

			await transport.services.feature.runSavedReport(report);

			if (report.deliveryMethod !== "Email") {
				await report.onLoaded();
				if (report.errorMessage) {
					notify(this.props.appState.app.notificationContainer, {
						type: NotificationType.Error,
						title: report.errorMessage,
					});
				} else {
					notification.onClose();
					notify(this.props.appState.app.notificationContainer, {
						lifeTime: Infinity,
						type: NotificationType.Message,
						title: `${report.name} is ready for download`,
						buttonLabel: "Download",
						onActionButtonClick: () => {
							report.downloadReport();
						},
					});
				}
			}
		}
	};

	private hasAccessToRunReport(report: Report) {
		const {transport, appState} = this.props;

		if (report.ownerUserId === appState.user?.id) {
			return true;
		}
		if (appState.user?.isAdmin) {
			return true;
		}

		const portfolioId = report.getPortfolio(transport.appState);
		const portfolio = appState.actions.getFeatureItemById<Portfolio>(portfolioId, XyiconFeature.Portfolio);

		// If portfolio is in the list of loaded portfolios -> user has access, otherwise not.
		return !!portfolio;
	}

	private onAddClick = (feature: XyiconFeature) => {
		this.setState({
			editingReport: Report.createNew(this.props.transport.appState, feature),
		});

		this._moduleRef.current?.switchToCreatingMode();
	};

	private onDuplicateClick = (report: Report) => {
		this.setState({
			editingReport: report.duplicate(),
		});
	};

	private onEditClick = (report: Report) => {
		report.startEditing();

		this.setState({
			editingReport: report,
		});
	};

	private onCloseReport = () => {
		this.setState({
			runningReport: null,
		});
	};

	// private onExport = async (report: Report, type: "excel" | "csv") =>
	// {
	// 	const {transport} = this.props;
	//
	// 	if (!report.reportLoaded)
	// 	{
	// 		await transport.services.feature.runSavedReport(report);
	// 		await report.onLoaded();
	// 	}
	// 	report.exportReport(type);
	// }

	private canEditReports = (reports: Report[]) => {
		const {appState} = this.props;

		for (const report of reports) {
			// cannot edit custom designed reports
			if (!report.isUserDefined) {
				return false;
			}

			// check permission
			const permission = Report.getPermission(report, appState);

			if (permission < Permission.Update) {
				return false;
			}
		}

		return true;
	};

	private canDeleteReports = (reports: Report[]) => {
		const {appState} = this.props;

		if (reports.length < 1) {
			return false;
		}

		for (const report of reports) {
			// cannot delete custom designed reports
			if (!report.isUserDefined) {
				return false;
			}

			// check permission
			const permission = Report.getPermission(report, appState);

			if (permission < Permission.Delete) {
				return false;
			}
		}

		return true;
	};

	private canCreateReports = () => {
		return Report.canCreateReports(this.props.appState);
	};

	private filterReports = (report: Report) => {
		// this hides reports that shouldn't be shown for the logged in user
		const permission = Report.getPermission(report, this.props.appState);

		return permission >= Permission.View;
	};

	private getActionBar() {
		const result: IActionBarItem<Report>[] = [
			{
				id: "play",
				title: "Run Report",
				label: "Run",
				onClick: this.runReport,
				enabled: (selectedItem) => selectedItem.length === 1,
			},
		];

		if (this.props.appState.user?.isAdmin) {
			result.push(
				{
					id: "add",
					title: "Create New Report",
					label: "Create",
					enabled: this.canCreateReports,
					componentFactory: (selectedItems: Report[]) => (
						<DropdownButton
							key="add"
							className="Button large gray"
							button={
								<div className="hbox alignCenter">
									<IconButton
										className="add"
										icon="add"
										title="Create New Report"
										disabled={!this.canCreateReports()}
									/>
									<div className="label">Create</div>
									<IconButton
										className="smallArrowDown"
										icon="down"
										title="Create New Report"
										disabled={!this.canCreateReports()}
									/>
								</div>
							}
							options={[
								{
									label: "Xyicon Report",
									onClick: () => this.onAddClick(XyiconFeature.Xyicon),
								},
								{
									label: "Boundary Report",
									onClick: () => this.onAddClick(XyiconFeature.Boundary),
								},
							]}
						/>
					),
				},
				{
					id: "duplicate",
					title: "Duplicate Report",
					label: "Duplicate",
					enabled: (reports: Report[]) => reports.length === 1 && this.canCreateReports() && reports[0].isUserDefined,
				},
				{
					id: "edit",
					title: "Edit Report",
					label: "Edit",
					enabled: (reports: Report[]) => reports.length === 1 && this.canEditReports(reports),
				},
				// Removed, see https://dev.azure.com/xyicon/SpaceRunner%20V4/_workitems/edit/1784
				// {
				// 	id: "download",
				// 	title: "Export Report",
				// 	enabled: (selectedItem) => selectedItem.length === 1,
				// 	componentFactory: (selectedItems: Report[]) =>
				// 		<DropdownButton
				// 			key="download"
				// 			button={
				// 				<div className="hbox">
				// 					<IconButton icon="download" title="Download Report" disabled={selectedItems.length !== 1}/>
				// 				</div>
				// 			}
				// 			options={[
				// 				{
				// 					label: "Download as an Excel",
				// 					onClick: () => this.onExport(selectedItems[0], "excel")
				// 				},
				// 				{
				// 					label: "Download as a CSV",
				// 					onClick: () => this.onExport(selectedItems[0], "csv")
				// 				}
				// 			]}
				// 		/>
				// },
				{
					id: "delete",
					title: "Delete Report",
					label: "Delete",
					enabled: this.canDeleteReports,
				},
			);
		}

		return result;
	}

	public override render() {
		const {runningReport} = this.state;

		return (
			<>
				<ModuleView
					ref={this._moduleRef}
					feature={XyiconFeature.Report}
					className="ReportView"
					viewBar={false}
					manageColumns={false}
					filterPermissions={this.filterReports}
					actionBar={this.getActionBar()}
					create={(onClose) => (
						<ReportPanel
							report={this.state.editingReport}
							onRunReport={this.onRunReport}
							onClose={(reportId?: string) => {
								onClose();
								if (!reportId) {
									// reportId is falsy when closing, truthy when saving / creating
									this.state.editingReport?.cancelEditing();
								}
							}}
						/>
					)}
					onDuplicate={this.onDuplicateClick}
					onEdit={this.onEditClick}
					emptyListText={
						this.props.appState.user?.isAdmin ? null : (
							<>
								No reports are shared.
								<br />
								Contact your Administrator for assistance.
							</>
						)
					}
					canDeleteReports={this.canDeleteReports}
				/>
				{runningReport && (
					<RunReportPage
						report={runningReport}
						onRunReport={this.onRunReport}
						onClose={this.onCloseReport}
					/>
				)}
			</>
		);
	}
}

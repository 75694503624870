import * as React from "react";
import {observer} from "mobx-react";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import type {IToolConfig, SpaceTool} from "../../logic3d/features/tools/Tools";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {MarkupManager} from "../../logic3d/managers/spaceitems/MarkupManager";
import type {Color} from "../../../../../../generated/api/base";
import {ToolGroup} from "./ToolGroup";
import {ToolButton} from "./ToolButton";
import type {IToolButtonProps} from "./ToolButton";

interface IMarkupToolBarProps {
	readonly active: boolean;
	readonly openDefaultToolBar: () => void;
	readonly tools: {
		[id in SpaceTool]: IToolConfig;
	};
	readonly activeToolId: SpaceTool;
	readonly createToolButton: (id: SpaceTool, color?: Color) => React.ReactElement<IToolButtonProps, string | React.JSXElementConstructor<any>>;
	readonly onToolClick: (id: SpaceTool) => void;
	readonly spaceViewRenderer: SpaceViewRenderer;
}
interface IMarkupToolBarState {
	activeColor: Color; // store the HSL values instead, because HSL-Hex conversion is injective, but the other way is not
}

@observer
export class MarkupToolBar extends React.Component<IMarkupToolBarProps, IMarkupToolBarState> {
	constructor(props: IMarkupToolBarProps) {
		super(props);
		this.state = {
			activeColor: {
				hex: this.props.spaceViewRenderer.markupManager?.markupColor || MarkupManager.defaultMarkupColor,
			},
		};
	}

	private onColorChange = (newColor: Color) => {
		this.props.spaceViewRenderer.markupManager.setColor(newColor.hex);
		this.setState({
			activeColor: newColor,
		});
	};

	public override render() {
		const {eyeDropperProps, markupManager} = this.props.spaceViewRenderer;
		const {createToolButton, openDefaultToolBar, onToolClick, activeToolId, tools, active} = this.props;
		const activeColor = this.state.activeColor;

		return (
			<div className={ReactUtils.cls({SpaceToolBar: true, Markup: true, collapsed: !active})}>
				<ToolButton
					icon="back"
					active={false}
					classNames="back"
					title="Back"
					onClick={openDefaultToolBar}
				/>
				{createToolButton("selection")}
				{createToolButton("pan")}
				{createToolButton("markupCloud", activeColor)}
				{createToolButton("markupCallout", activeColor)}

				<ToolGroup
					onClick={(id: SpaceTool) => onToolClick(id)}
					tools={tools}
					color={this.state.activeColor}
				>
					{createToolButton("markupArrow", activeColor)}
					{createToolButton("markupBidirectionalArrow", activeColor)}
					{createToolButton("markupLine", activeColor)}
					{createToolButton("markupDashedLine", activeColor)}
				</ToolGroup>

				<ToolGroup
					onClick={(id: SpaceTool) => onToolClick(id)}
					tools={tools}
					color={activeColor}
				>
					{createToolButton("markupPencil", activeColor)}
					{createToolButton("markupHighlight", activeColor)}
				</ToolGroup>

				{createToolButton("markupText", activeColor)}

				<ToolGroup
					onClick={(id: SpaceTool) => onToolClick(id)}
					tools={tools}
					color={activeColor}
				>
					{createToolButton("markupRectangle", activeColor)}
					{createToolButton("markupEllipse", activeColor)}
					{createToolButton("markupTriangle", activeColor)}
					{createToolButton("markupCross", activeColor)}
				</ToolGroup>

				<ToolGroup
					onClick={(id: SpaceTool) => onToolClick(id)}
					tools={tools}
					color={activeColor}
				>
					{createToolButton("measureLinearDistance", activeColor)}
					{createToolButton("measureRectArea", activeColor)}
					{createToolButton("measureNonLinearDistance", activeColor)}
					{createToolButton("measureIrregularArea", activeColor)}
				</ToolGroup>

				{/* {createToolButton("markupStamp", true)} */}
				{/* {createToolButton("markupPhoto", true)} */}

				<ColorSelector
					title="Markup Color"
					color={{hex: markupManager?.markupColor || MarkupManager.defaultMarkupColor, transparency: 0}}
					onColorChange={this.onColorChange}
					isTransparencyEnabled={false}
					offsetY={-200}
					eyeDropperProps={eyeDropperProps}
					icon="paint-bucket"
					classNames="btn"
					closeWindowOnSelect={true}
				/>
			</div>
		);
	}
}

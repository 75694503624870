import {styled} from "styled-components";
import type {MouseEvent} from "react";
import {cloneElement, useEffect, useRef, useState} from "react";
import {Observer} from "mobx-react";
import {useAppStore} from "../../../StateManager";
import {XyiconFeature} from "../../../generated/api/base";
import type {ExportFormat} from "../../../data/exporters/BaseExporter";
import {ExporterFactory} from "../../../data/exporters/ExporterFactory";
import {Functions} from "../../../utils/function/Functions";
import type {IModel} from "../../../data/models/Model";
import {HorizontalAlignment} from "../../../utils/dom/DomUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {ButtonV5} from "../button/ButtonV5";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import SlidersIcon from "../icons/sliders.svg?react";
import FilterIcon from "../icons/filter.svg?react";
import Columns3Icon from "../icons/columns-3.svg?react";
import DotsHorizontalIcon from "../icons/dots-horizontal.svg?react";
import ArrowDownToSquareIcon from "../icons/arrow-down-to-square.svg?react";
import FileArrowRightIcon from "../icons/file-arrow-right.svg?react";
import CloseIcon from "../icons/xmark.svg?react";
import {FlexCenter, FlexCenterStyle, fontWeight} from "../styles/styles";
import {ManageVersionSetsV5} from "../modules/space/versionset/ManageVersionSetsV5";
import {DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {FeatureImportPanelV5} from "../abstract/view/FeatureImportPanelV5";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {UnsavedLayoutV5} from "../abstract/table/UnsavedLayoutV5";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {SearchFieldStyled} from "../input/search/SearchField.styled";
import {colorPalette} from "../styles/colorPalette";
import {SelectInputStyled, SelectInputV5} from "../input/select/SelectInputV5";
import {FilterChangeNotificationV5} from "../viewbar/FilterChangeNotificationV5";
import {ManageColumnsPanelV5} from "../abstract/table/ManageColumnsPanelV5";
import type {GridItem, GridSide} from "../modules/spaceeditor/spaceselector/GridTypes";
import type {IDropdownOption, ISubOptionWrapper} from "../interaction/DropdownOptionsV5";
import {ViewSelectorV5} from "./ViewSelectorV5";

const ActionBarV5Styled = styled.div`
	height: 64px;
	align-items: center;
	padding: 0 16px;
	${FlexCenterStyle};
	justify-content: space-between;

	> ${FlexCenter} {
		> ${SearchFieldStyled}, > ${SelectInputStyled} {
			border: none;
		}
	}

	.moduleName {
		font-weight: ${fontWeight.bold};
	}
`;

const LineStyled = styled.div`
	height: 32px;
	width: 1px;
	background-color: #37474f;
	position: relative;
	left: 0;
	margin: 8px;
`;

const ExportDotsStyled = styled(IconButtonV5)`
	svg {
		width: 16px;
		height: 16px;
	}
`;

interface IActionBarV5Props {
	readonly feature: XyiconFeature;
	readonly items: IModel[];
	readonly createButtonRef: React.MutableRefObject<HTMLDivElement>;
	readonly manageColumnRef: React.MutableRefObject<HTMLDivElement>;
	readonly isSideGrid?: boolean;
	readonly filterCount?: number;
	readonly toggleFilterPanel: (e: React.MouseEvent) => void;
	readonly onSearchChange: (value: string) => void;
	readonly create: (onClose?: () => void) => React.ReactElement<any, string | React.JSXElementConstructor<any>>;
	readonly onCloseCreate: () => void;
	readonly onResetFilters: () => void;
	readonly searchString: string;
}

export const ActionBarV5 = (props: IActionBarV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const {toggleFilterPanel, feature, items, onSearchChange, isSideGrid, create, createButtonRef, manageColumnRef} = props;
	const [openManageVersionsPanel, setOpenManageVersionsPanel] = useState<boolean>(false);
	const [openManageColumns, setOpenManageColumns] = useState<boolean>(false);
	const [openImportPanel, setOpenImportPanel] = useState<boolean>(false);
	const [openCreatePopup, setOpenCreatePopup] = useState<boolean>(false);
	const [shouldManageColumnsButtonMoveIntoMeatballMenu, setShouldManageColumnsButtonMoveIntoMeatballMenu] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (ref.current) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					setShouldManageColumnsButtonMoveIntoMeatballMenu(entry.contentRect.width < 550);
				}
			});

			resizeObserver.observe(ref.current);

			return () => resizeObserver.disconnect();
		}
	}, []);

	const onExport = (type: ExportFormat) => {
		const exporter = ExporterFactory.createExporter(type, appState);

		if (exporter) {
			exporter.exportView(appState.actions.getViewById(appState.selectedViewId[feature]), items);
		}
	};

	const onImport = () => {
		setOpenImportPanel(true);
	};

	const onCloseCreate = () => {
		props.onCloseCreate?.();
		setOpenCreatePopup(false);
	};

	const areThereActiveFilters = props.filterCount > 0;

	const onResetFiltersClick = (event: MouseEvent) => {
		event.stopPropagation();
		props.onResetFilters();
	};

	const onCreateClick = () => {
		if (XyiconFeature.Xyicon === feature) {
			create();
		} else if (create) {
			setOpenCreatePopup(true);
		}
	};

	const dropDownOptions: (IDropdownOption | ISubOptionWrapper)[] = [
		{
			label: "Export",
			IconComponent: FileArrowRightIcon,
			options: [
				{
					onClick: () => onExport("excel"),
					label: "Export to Excel",
				},
				{
					onClick: () => onExport("csv"),
					label: "Export to CSV",
				},
			],
		},
		{
			label: "Import",
			onClick: onImport,
			IconComponent: ArrowDownToSquareIcon,
		},
	];

	if (shouldManageColumnsButtonMoveIntoMeatballMenu) {
		dropDownOptions.unshift({
			label: "Manage Columns",
			IconComponent: Columns3Icon,
			onClick: () => setOpenManageColumns(true),
		});
	}

	return (
		<Observer>
			{() => {
				const selectedView = appState.actions.getSelectedView(feature);
				return (
					<>
						<ActionBarV5Styled ref={ref}>
							<FlexCenter $gap="8px">
								{isSideGrid && (
									<SideBarGridItemTypeSelectorStyled>
										<SelectInputV5
											options={["Xyicon List", "Boundary List"]}
											selected={appState.app.graphicalTools.gridItemSide.toLowerCase().includes("boundary") ? "Boundary List" : "Xyicon List"}
											onChange={(option: string) => {
												const side: GridSide = appState.app.graphicalTools.gridItemSide.includes("left") ? "left" : "right";
												appState.app.graphicalTools.gridItemSide = option.toLowerCase().includes("boundary") ? `${side}-boundary` : `${side}-xyicon`;
											}}
										/>
									</SideBarGridItemTypeSelectorStyled>
								)}
								{[XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog, XyiconFeature.Space].includes(feature) && (
									<ViewSelectorV5 feature={feature} />
								)}
								{feature == XyiconFeature.Report && (
									<>
										<div className="moduleName">REPORTS</div>
										<LineStyled />
									</>
								)}
								<SearchFieldV5
									value={props.searchString}
									onInput={onSearchChange}
									minimized={true}
								/>
								{feature !== XyiconFeature.Report && !isSideGrid && (
									<FilterButtonStyled
										className={ReactUtils.cls("FilterButtonStyled", {isActive: areThereActiveFilters})}
										onClick={toggleFilterPanel}
									>
										<FilterIcon />
										<div className="label">{props.filterCount ? props.filterCount.toString() : "Filter"}</div>
										{areThereActiveFilters && (
											<IconButtonV5
												IconComponent={CloseIcon}
												onClick={onResetFiltersClick}
											/>
										)}
									</FilterButtonStyled>
								)}
								{feature === XyiconFeature.Space && (
									<ButtonV5
										onClick={() => setOpenManageVersionsPanel(true)}
										label="Manage Versions"
										type="tertiary"
									>
										<SlidersIcon />
									</ButtonV5>
								)}
								{isSideGrid && (
									<SideBarGridItemSideSelectorStyled>
										<SelectInputV5
											options={["Table Left", "Table Right"]}
											selected={appState.app.graphicalTools.gridItemSide.includes("left") ? "Table Left" : "Table Right"}
											onChange={(option: string) => {
												const gridItem: GridItem = appState.app.graphicalTools.gridItemSide.includes("boundary") ? "boundary" : "xyicon";
												const newGridSide: GridSide = option.toLowerCase().includes("left") ? "left" : "right";

												appState.app.graphicalTools.gridItemSide = `${newGridSide}-${gridItem}`;
											}}
										/>
									</SideBarGridItemSideSelectorStyled>
								)}
								{!shouldManageColumnsButtonMoveIntoMeatballMenu &&
									[XyiconFeature.Portfolio, XyiconFeature.XyiconCatalog, XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(feature) && (
										<ManageColumnButtonStyled className={ReactUtils.cls("ManageColumnStyled", {isActive: openManageColumns})}>
											<ButtonV5
												onClick={() => setOpenManageColumns(true)}
												label="Manage Columns"
												type="tertiary"
												ref={manageColumnRef}
											>
												<Columns3Icon />
											</ButtonV5>
										</ManageColumnButtonStyled>
									)}
								{feature != XyiconFeature.Report && (
									<DropdownButtonV5
										options={dropDownOptions}
										horizontalAlignment={HorizontalAlignment.left}
										button={
											<ExportDotsStyled
												IconComponent={DotsHorizontalIcon}
												onClick={Functions.emptyFunction}
											/>
										}
									/>
								)}
							</FlexCenter>
							<FlexCenter $gap="8px">
								<UnsavedLayoutV5 selectedView={selectedView} />
								<FilterChangeNotificationV5
									actions={appState.actions}
									feature={feature}
								/>
								{create && (
									<ButtonV5
										onClick={onCreateClick}
										label="CREATE"
										ref={createButtonRef}
									>
										<CirclePlusIcon />
									</ButtonV5>
								)}
								{isSideGrid && (
									<IconButtonV5
										IconComponent={CloseIcon}
										title="Close"
										onClick={() => {
											appState.app.graphicalTools.gridItemSide = "";
										}}
									/>
								)}
							</FlexCenter>
						</ActionBarV5Styled>
						{openManageVersionsPanel && <ManageVersionSetsV5 onClose={() => setOpenManageVersionsPanel(false)} />}
						{openManageColumns && (
							<ManageColumnsPanelV5
								feature={feature}
								view={selectedView}
								onClose={() => setOpenManageColumns(false)}
								parentRef={manageColumnRef}
							/>
						)}
						{openImportPanel && (
							<FeatureImportPanelV5
								onClose={() => setOpenImportPanel(false)}
								calledFromThisModule={props.feature}
							/>
						)}
						{openCreatePopup && create && cloneElement(create(onCloseCreate))}
					</>
				);
			}}
		</Observer>
	);
};

const SideBarGridItemTypeSelectorStyled = styled.div`
	text-transform: uppercase;
	font-weight: 700;
	white-space: nowrap;

	${SelectInputStyled} {
		border: none;
	}
`;

const SideBarGridItemSideSelectorStyled = styled.div`
	white-space: nowrap;

	${SelectInputStyled} {
		border: none;
	}
`;

const FilterButtonStyled = styled.div`
	${FlexCenterStyle};
	cursor: pointer;
	border-radius: 4px;
	height: 32px;
	padding: 8px;
	grid-gap: 8px;
	font-size: 14px;

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
		.label {
			font-weight: bold;
		}
	}

	${IconButtonStyled} {
		background-color: inherit;
		min-width: 16px;
		height: 16px;
		width: 16px;
		height: 16px;
		margin: 0;
		svg {
			display: flex;
			justify-content: center;
		}
	}
`;

const ManageColumnButtonStyled = styled.div`
	cursor: pointer;
	border-radius: 4px;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background-color: ${colorPalette.primary.c200Light};

		.Button {
			color: ${colorPalette.primary.c500Primary};
		}
	}
`;

import type {CSSProperties, ReactNode} from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled, SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {XyiconFeature} from "../../../../generated/api/base";
import {useAppStore} from "../../../../StateManager";
import {CatalogItemPanelV5} from "../../abstract/view/catalog/CatalogItemPanelV5";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {FieldV5} from "../../details/FieldV5";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";
import type {IIconConfig} from "../../../modules/catalog/create/CatalogTypes";
import type {Catalog} from "../../../../data/models/Catalog";
import {SingleLineInputStyled} from "../../details/datatypes/SingleLineInputV5";
import {SingleLineLabelStyled} from "../../details/datatypes/SingleLineLabelV5";
import {colorPalette} from "../../styles/colorPalette";
import {CreatePopupStyled} from "../CreatePopupStyled";
import {PopupHeaderStyled} from "../../popup/PopupV5";
import {FieldElementsContainerStyled} from "../../details/Field.styled";
import {ELLIPSIS} from "../../styles/styles";

interface ICatalogCreatePopupV5Props {
	readonly onClose: () => void;
	readonly parentRef?: HTMLDivElement;

	readonly selectedType?: Type;
	readonly model?: string;
	readonly label?: ReactNode;
	readonly verticalAlignment?: VerticalAlignment;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly offsetY?: number;
	readonly offsetX?: number;

	readonly iconConfig?: IIconConfig;
	readonly catalog?: Catalog;
	readonly mode?: "create" | "edit";
	readonly style?: CSSProperties;
}

const CatalogCreatePopupStyled = styled(CreatePopupStyled)<{$transformPos?: TransformObj}>`
	${SelectInputStyled}, ${SingleLineInputStyled}>input, ${SingleLineLabelStyled} {
		color: ${colorPalette.gray.c950};

		svg {
			flex-shrink: 0;
		}
	}
	visibility: visible;
	transform: ${(props) => props.$transformPos?.translate};

	${ClickToEditInputStyled} {
		.SingleLineLabel {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: unset;
			line-height: 16px;
		}
	}

	${PopupHeaderStyled} {
		span {
			${ELLIPSIS}
			display: block;
			line-height: 24px;
		}
	}

	${FieldElementsContainerStyled} {
		.label {
			max-width: 136px;
		}
	}
`;

export type ShapeType = "Default Shape" | "Custom Shape";

const geometries: ShapeType[] = ["Default Shape", "Custom Shape"];

export const CatalogCreatePopupV5 = ({
	parentRef,
	onClose,
	selectedType: selectedTypeProp,
	model,
	label,
	verticalAlignment = VerticalAlignment.bottomOuter,
	horizontalAlignment = HorizontalAlignment.right,
	offsetY = -16,
	offsetX = 24,
	iconConfig,
	catalog,
	mode,
	style = {},
}: ICatalogCreatePopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const parentElementMaybe = parentRef ?? appState.catalogEditorParentMaybe;
	const [selectedType, setSelectedType] = useState<Type>(selectedTypeProp);
	const [selectedGeometry, setSelectedGeometry] = useState<ShapeType>("Default Shape");
	const [createClicked, setCreateClicked] = useState<boolean>(false);

	const getDefaultModelName = (catalog: Catalog | undefined) => {
		let defaultModelName = catalog?.model || "";
		const isCloneMode = catalog && mode === "create";

		if (isCloneMode) {
			defaultModelName += " (Duplicate)";
		}

		return defaultModelName;
	};

	const [modelName, setModelName] = useState<string>(model ? getDefaultModelName(catalog) : "");

	const isModelNameValid = (modelName: string) => {
		if (!modelName.trim()) {
			return false;
		}

		return appState.actions.isModelValidForCatalog(modelName, null);
	};

	const getErrorMessage = (value: string) => {
		return !value.trim() ? "Name cannot be empty!" : isModelNameValid(value) ? "" : "Name must be unique!";
	};

	const types = appState.types[XyiconFeature.Xyicon];
	const isFormValid = selectedType?.id && isModelNameValid(modelName);

	useEffect(() => {
		return () => {
			appState.catalogEditorParentMaybe = null;
		};
	}, [appState]);

	return createClicked ? (
		<CatalogItemPanelV5
			onClose={onClose}
			shape={selectedGeometry}
			mode={mode}
			model={modelName}
			type={selectedType}
			iconConfig={iconConfig}
			catalog={catalog}
		/>
	) : (
		<CatalogCreatePopupStyled
			isSmallPopup={true}
			label={label}
			onClose={onClose}
			parentRef={parentElementMaybe}
			centerOnScreen={!parentElementMaybe}
			freezeRoot={!parentElementMaybe}
			buttonProps={{
				onClick: () => setCreateClicked(true),
				disabled: !isFormValid,
				label: "Create",
			}}
			offsetX={offsetX}
			offsetY={offsetY}
			verticalAlignment={verticalAlignment}
			horizontalAlignment={horizontalAlignment}
			style={style}
			width="368px"
		>
			<CreatePopupFieldStyled>
				<FieldV5
					label="Type"
					className="focused"
				>
					<SelectInputV5
						options={types}
						render={(type) => <span>{type ? type.name : ""}</span>}
						selected={selectedType}
						onChange={(type) => setSelectedType(type)}
						placeholder="Type"
						isSameWidth={true}
						dropdownFixedWidth="200px"
					/>
				</FieldV5>
				<FieldV5
					label="Model"
					className="focused"
				>
					<ClickToEditInputV5
						value={modelName}
						onLiveChange={(name) => setModelName(name)}
						getErrorMessage={getErrorMessage}
						placeholder="Catalog Model"
					/>
				</FieldV5>
				<FieldV5
					label="Geometry"
					className="focused"
				>
					<SelectInputV5
						options={geometries}
						selected={geometries.find((geometry) => geometry === selectedGeometry)}
						onChange={(geometry) => setSelectedGeometry(geometry)}
						fullWidth={true}
						isSameWidth={true}
					/>
				</FieldV5>
			</CreatePopupFieldStyled>
		</CatalogCreatePopupStyled>
	);
};

export class StringUtils {
	/**
	 * "/app/settings" -> "app/settings"
	 */
	private static removeInitialDash(value: string) {
		value = value || "";

		if (value.indexOf("/") === 0) {
			value = value.substring(1);
		}
		return value;
	}

	// TODO it could be better to convert the ids when loading the models, then it only needs to be done once?
	// "f12" -> 12
	public static refId2Number(refId: string): string | number {
		if (refId?.substr) {
			return Number(refId.substring(1));
		}
		return refId;
	}

	/**
	 * /app/:menu/:param1?
	 * /app/test/test2
	 * ->
	 * {
	 *     menu: "test",
	 *     param1: "test2"
	 * }
	 */
	public static findMatchForHash<T = any>(hash: string, pattern: string) {
		hash = StringUtils.removeInitialDash(hash);
		pattern = StringUtils.removeInitialDash(pattern);

		const params: any = {} as T;
		let matches = true;

		const hashParts = hash.split("/");
		const patternParts = StringUtils.decomposeParts(pattern);

		for (let i = 0; i < patternParts.length; ++i) {
			const patternPart = patternParts[i];
			const hashPart = hashParts[i];

			if (!patternPart.optional && !hashPart) {
				matches = false;
				break;
			}

			if (patternPart.isParam) {
				params[patternPart.value] = hashPart;
			} else {
				if (patternPart.value !== hashPart) {
					matches = false;
					break;
				}
			}
		}

		return {
			matches: matches,
			params: params,
		};
	}

	public static decomposeParts(pattern: string): {value: string; isParam: boolean; optional: boolean}[] {
		const parts = pattern.split("/");
		const result = [];

		for (const part of parts) {
			const {result: paramValue, removed: isParam} = StringUtils.removeCharacter(part, ":");
			const {result: value, removed: optional} = StringUtils.removeCharacter(paramValue, "?");

			result.push({
				value: value,
				isParam: isParam,
				optional: optional,
			});
		}

		return result;
	}

	private static removeCharacter(value: string, char: string) {
		const result = value.split(char).join("");

		return {
			result: result,
			removed: result.length !== value.length,
		};
	}

	public static trim(value: string) {
		value = value || "";
		if (value.trim) {
			return value.trim();
		}
		return value;
	}

	public static leftPad(value: number | string, width: number, char = "0"): string {
		const n: string = `${value}`;

		return n.length >= width ? n : new Array(width - n.length + 1).join(char) + n;
	}

	// https://stackoverflow.com/questions/3410464/how-to-find-indices-of-all-occurrences-of-one-string-in-another-in-javascript
	public static getIndicesOfSubstring(source: string, find: string) {
		if (!source) {
			return [];
		}
		// if find is empty string return all indexes.
		if (!find) {
			// or shorter arrow function:
			return source.split("").map((_, i) => i);
		}
		const result = [];

		for (let i = 0; i < source.length; ++i) {
			// If you want to search case insensitive use
			if (source.substring(i, i + find.length).toLowerCase() === find) {
				result.push(i);
			}
		}

		return result;
	}

	public static equalsIgnoreCase(a: string, b: string) {
		a = a || "";
		b = b || "";
		if (!a.toLowerCase) {
			return false;
		}
		if (!b.toLowerCase) {
			return false;
		}

		a = a.toLowerCase();
		b = b.toLowerCase();

		return a === b;
	}

	public static containsIgnoreCase(source: string, search: string) {
		source = source || "";
		search = search || "";
		if (!source.toLowerCase) {
			return false;
		}
		if (!search.toLowerCase) {
			return false;
		}

		source = source.toLowerCase();
		search = search.toLowerCase();

		return source.includes(search);
	}

	public static sortIgnoreCase(a: string, b: string): 0 | 1 | -1 {
		a = a || "";
		b = b || "";
		if (!a.toLowerCase) {
			return 0;
		}
		if (!b.toLowerCase) {
			return 0;
		}

		a = a.toLowerCase();
		b = b.toLowerCase();

		return a < b ? -1 : 1;
	}

	public static regexHighlight(string: string, queryStr: string): string {
		if (!string) {
			return string;
		} else if (queryStr) {
			// https://stackoverflow.com/questions/7313395/case-insensitive-replace-all
			const esc = queryStr.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
			const reg = new RegExp(esc, "ig");
			return string.replace(reg, (str: string) => (str ? `<b>${str}</b>` : ""));
		} else {
			return string;
		}
	}

	public static emailValidator(email: string): boolean {
		// http://emailregex.com/
		return !!email.match(
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
		);
	}

	public static isBlank(value: any): boolean {
		if (value === null) {
			return true;
		}
		if (value === undefined) {
			return true;
		}
		if (value === false) {
			return false;
		} // false is not a blank value!

		if (StringUtils.trim(value) === "") {
			return true;
		}

		return false;
	}

	public static filterByKeywords(list: {keywords: string[]}[], searchValue: string) {
		return list.filter((element: {keywords: string[]}) => {
			for (const keyword of element.keywords) {
				if (StringUtils.containsIgnoreCase(keyword, searchValue)) {
					return true;
				}
			}

			return false;
		});
	}

	public static capitalize(value: string = "") {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}

	public static decapitalize(value: string = "") {
		return value.charAt(0).toLowerCase() + value.slice(1);
	}

	public static lowerCase(value: string) {
		return value?.toLowerCase ? value.toLowerCase() : value;
	}

	public static isTruthy(value: any): boolean {
		return value || value === 0 || value === false;
	}

	public static formatPastedText(pastedText: string, pastedHTML: string) {
		pastedText = StringUtils.formatTableString(pastedText);

		pastedText = pastedText.replace(/(\t)+$/gm, ""); // removes empty tabs from end of text

		// Add GUIDs
		const guids: string[] = [];
		const itemIdString = 'item-id="';
		const guidLength = 36; // a GUID is 36 characters long
		const indicesOfGUIDs = StringUtils.getIndicesOfSubstring(pastedHTML, itemIdString);

		for (const index of indicesOfGUIDs) {
			const guid = pastedHTML.substring(index + itemIdString.length, index + itemIdString.length + guidLength);

			guids.push(guid);
		}

		pastedText = `GUID\t${pastedText}`;
		pastedText = pastedText.replace(/\n\n/g, "\n\nGUID\t");

		let guidIndex = 0;

		for (let i = 0; i < pastedText.length - 1; ++i) {
			if (pastedText[i - 1] !== "\n" && pastedText[i] === "\n" && pastedText[i + 1] !== "\n") {
				pastedText = `${pastedText.slice(0, i + 1)}${guids[guidIndex++]}\t${pastedText.slice(i + 1)}`;
			}
		}

		return pastedText;
	}

	// Used when an excel/sheet table of xyicons/boundaries/markups are getting pasted
	public static formatTableString(tableString: string) {
		const separator = "\t";
		tableString = tableString
			.replace(/\r\n/g, "\n") // replaces \r\n newlines (windows-style) with unix style new lines (\n)
			.replace(/(\n)+$/g, "");

		const blockStarters = [tableString.indexOf("Xyicon Model"), tableString.indexOf("Boundary Type"), tableString.indexOf("Markup Type")];

		const blocks: string[] = [];

		if (blockStarters[0] > -1) {
			let blockEndIndex = blockStarters[1] === -1 ? blockStarters[2] : blockStarters[1];

			blockEndIndex = blockEndIndex === -1 ? undefined : blockEndIndex;
			blocks.push(tableString.substring(blockStarters[0], blockEndIndex));
		}

		if (blockStarters[1] > -1) {
			let blockEndIndex = blockStarters[2];

			blockEndIndex = blockEndIndex === -1 ? undefined : blockEndIndex;
			blocks.push(tableString.substring(blockStarters[1], blockEndIndex));
		}

		if (blockStarters[2] > -1) {
			blocks.push(tableString.substring(blockStarters[2]));
		}

		// Terminology: one "block" contains one type of spaceitems: xyicons, boundaries, or markups
		const formattedBlocks: string[] = [];

		for (const block of blocks) {
			formattedBlocks.push(this.formatBlockOfString(block, separator));
		}

		return formattedBlocks.join("\n\n");
	}

	/**
	 * Replace \n with <br/> in multiline fields.
	 * The idea is that we count the separators within the headers, and if the new line character (\n) comes sooner than the last separator on the specific line,
	 * we assume it's a multiline field
	 * @param tableString
	 * @param separator "\t", ",", etc
	 */
	private static formatBlockOfString(tableString: string, separator: string) {
		let formattedString = tableString.replace(/\n+$/, ""); // removes the unnecessary new lines in the end of string

		let firstLineOfBlock: string = "";
		let separatorSumInHeader: number = 0;
		let separatorCounter: number = 0;
		let lineCounter: number = 0;

		const newLineReplacement = "<br/>";

		for (let i = 0; i < formattedString.length - 1; ++i) {
			if (i === 0) {
				firstLineOfBlock = formattedString.split("\n")[0];
				separatorSumInHeader = firstLineOfBlock.split(separator).length - 1;
			}

			if (formattedString[i] === separator) {
				separatorCounter++;
			} else if (formattedString[i] === "\n") {
				if (separatorCounter < separatorSumInHeader) {
					formattedString = `${formattedString.slice(0, i)}${newLineReplacement}${formattedString.slice(i + 1)}`;
					i += newLineReplacement.length - 1;
				} else {
					lineCounter++;
					if (formattedString[i + 1] === "\n") {
						const indexOfNextNewLine = formattedString.indexOf("\n", i + 2);

						i = Math.max(indexOfNextNewLine, i);
						separatorSumInHeader = formattedString.substring(i, indexOfNextNewLine).split(separator).length - 1;
					} else {
						separatorCounter = 0;
					}
				}
			}
		}

		return formattedString;
	}

	public static removeWhiteSpaces(text: string) {
		return text.trim().replace(/\s+/g, " ");
	}

	/**
	 * From:
	 * https://stackoverflow.com/questions/36288375/how-to-parse-csv-data-that-contains-newlines-in-field-using-javascript
	 *
	 * CSVToArray parses any String of Data including '\r' '\n' characters,
	 * and returns an array with the rows of data.
	 * @param {String} CSV_string - the CSV string you need to parse
	 * @param {String} delimiter - the delimeter used to separate fields of data
	 * @returns {Array} rows - rows of CSV where first row are column headers
	 */
	public static CSVToArray(CSV_string: string, delimiter: string = ",", maxColumns = Infinity): (string | number)[][] {
		try {
			delimiter = delimiter || ","; // user-supplied delimeter or default comma

			const pattern = new RegExp( // regular expression to parse the CSV values.
				// Delimiters:
				`(\\${delimiter}|\\r?\\n|\\r|^)` +
					// Quoted fields: "field",
					// it can possible be prefixed with an equal sign: \=?
					'(?:=?"([^"]*(?:""[^"]*)*)"|' +
					// Standard fields.
					`([^"\\${delimiter}\\r\\n]*))`,
				"gi",
			);

			const rows: (string | number)[][] = [[]]; // array to hold our data. First row is column headers.
			// array to hold our individual pattern matching groups:
			let matches: string[] | false = false; // false if we don't find any matches

			// Loop until we no longer find a regular expression match
			while ((matches = pattern.exec(CSV_string))) {
				var matched_delimiter = matches[1]; // Get the matched delimiter

				// Check if the delimiter has a length (and is not the start of string)
				// and if it matches field delimiter. If not, it is a row delimiter.
				if (matched_delimiter.length && matched_delimiter !== delimiter) {
					// Since this is a new row of data, add an empty row to the array.
					rows.push([]);
				}
				let matched_value;

				// Once we have eliminated the delimiter, check to see
				// what kind of value was captured (quoted or unquoted):
				if (matches[2]) {
					// found quoted value. unescape any double quotes.
					matched_value = matches[2].replace(new RegExp('""', "g"), '"');
				} else {
					// found a non-quoted value
					matched_value = matches[3];

					const matchedValueAsNumber = Number(matched_value);

					if (!isNaN(matchedValueAsNumber)) {
						matched_value = matchedValueAsNumber;
					}
				}
				// Now that we have our value string, let's add
				// it to the data array.

				const row = rows[rows.length - 1];

				if (row.length < maxColumns) {
					row.push(matched_value);
				}
			}
			return rows; // Return the parsed data Array
		} catch (e) {
			console.warn(e);
			return [];
		}
	}
}

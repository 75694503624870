import * as React from "react";
import {DateTimeInputV5} from "../../../input/datetime/DateTimeInputV5";
import {DateUtils} from "../../../../../utils/DateUtils";

interface IBetweenDateParamV5Props {
	readonly param: {from: string; to: string};
	onChange(value: {from: string; to: string}): void;
}

export class BetweenDateParamV5 extends React.Component<IBetweenDateParamV5Props> {
	private get _from() {
		return this.props.param?.from;
	}

	private get _to() {
		return this.props.param?.to;
	}

	private onEffect = () => {
		if (!this.props.param) {
			this.props.onChange({from: this._from, to: this._to});
		}
	};

	public override componentDidMount() {
		this.onEffect();
	}

	public override componentDidUpdate() {
		this.onEffect();
	}

	public override render() {
		const {onChange} = this.props;

		return (
			<DateTimeInputV5
				value={this._from || ""}
				valueToDate={this._to || ""}
				onChange={(value) => onChange(DateUtils.parseDateRange(value))}
				format="daterange"
			/>
		);
	}
}

import * as React from "react";
import {DateTimeInputV5} from "../../../input/datetime/DateTimeInputV5";

interface ITimeParamV5Props {
	readonly param: string;
	onChange(value: string): void;
}

export class TimeParamV5 extends React.Component<ITimeParamV5Props> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<DateTimeInputV5
				value={param || ""}
				onChange={(value) => onChange(value)}
				format="time"
			/>
		);
	}
}

import * as React from "react";
import type {IValueDidChange, Lambda} from "mobx";
import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {observe} from "mobx";
import {flushSync} from "react-dom";
import type {Space} from "../../../../data/models/Space";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {InvisibleLinkedXyiconType} from "../../../modules/space/spaceeditor/ui/actionbar/LinkedXyiconsWindow";
import type {PortTemplateDto, SpaceFileInsertionInfo} from "../../../../generated/api/base";
import type {DockableTitle} from "../../../modules/space/spaceeditor/ui/toolbar/Dockable";
import type {ISpaceItemModel} from "../../../../data/models/Model";
import type {AppState} from "../../../../data/state/AppState";
import type {Catalog} from "../../../../data/models/Catalog";
import type {SelectionToolType, SpaceTool} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {ThemeType} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import type {SpaceItem} from "../../../modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import type {IinheritedMethods} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {IBasicPropsFor3DTweaking} from "../../../modules/space/SpaceView";
import {ClipboardManager} from "../../../modules/space/spaceeditor/logic3d/managers/ClipboardManager";
import {SpaceEditorMode} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {SpaceToolBarType} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {Constants} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {Permission, XyiconFeature} from "../../../../generated/api/base";
import {NotificationType} from "../../../notification/Notification";
import {notify} from "../../../../utils/Notify";
import {WarningWindow} from "../../../modules/abstract/popups/WarningWindow";
import {PopupWindow} from "../../../modules/abstract/popups/PopupWindow";
import {Functions} from "../../../../utils/function/Functions";
import {XyiconSizeChanger} from "../../../modules/space/spaceeditor/ui/sceneoptions/XyiconSizeChanger";
import {SpaceContextMenu} from "../../../modules/space/spaceeditor/ui/actionbar/SpaceContextMenu";
import {SpaceAlignViewBarV5} from "../space/SpaceAlignViewBarV5";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";
import {NavigationEnum} from "../../../../Enums";
import {SpaceToolBarV5} from "./toolbar/SpaceToolBarV5";
import {SpaceControlBarV5} from "./spacecontrolbar/SpaceControlBarV5";
import {SpaceSelector} from "./spaceselector/SpaceSelector";
import {SpaceLoadingScreenV5} from "./SpaceLoadingScreenV5";
import {SpaceViewBarV5} from "./viewbar/SpaceViewBarV5";
import {DockableV5} from "./dockable/DockableV5";
import {SpaceActionBarV5} from "./spaceactionbar/SpaceActionBarV5";
import {LinkedXyiconsWindowV5} from "./links/LinkedXyiconsWindowV5";
import {LinkBreakersV5} from "./links/LinkBreakersV5";
import {PortSelectorV5} from "./links/PortSelectorV5";
import {MergeBoundariesWindowV5} from "./MergeBoundariesWindowV5";
import {MeasureToolBarV5} from "./spacecontrolbar/MeasureToolBarV5";
import type {TSpaceEditorPanelTypes} from "./SpaceEditorPanelV5";

interface ISpaceEditorProps extends IBasicPropsFor3DTweaking {
	readonly appState?: AppState;
	readonly mode: SpaceEditorMode;
	readonly thumbnail?: string;
	readonly isCatalogOpen?: boolean;
	readonly isCatalogDocked?: boolean;
	readonly isBoundaryTypesWindowOpen?: boolean;
	readonly isBoundaryTypesWindowDocked?: boolean;
	readonly isUnplottedXyiconsOpen?: boolean;
	readonly isUnplottedXyiconsDocked?: boolean;
	readonly activeToolId: SpaceTool;
	readonly selectionToolType: SelectionToolType;
	readonly activePanel?: TSpaceEditorPanelTypes;
	readonly isDetailsPanelOpen?: boolean;
	readonly setDockableDocked?: (value: boolean, title: DockableTitle) => void;
	readonly setDockableOpen?: (value: boolean, title?: DockableTitle) => void;
	readonly setScale: (spaceUnitsPerMeter: number, applyToAllSpaces?: boolean) => void;
	readonly confirmAlignment: (insertionInfo: SpaceFileInsertionInfo) => void;
	readonly setActiveTool: (id: SpaceTool) => void;
	readonly selectItems?: (spaceItems: ISpaceItemModel[], selectDetailsTab?: boolean, forceUpdate?: boolean, doubleClick?: boolean) => void;
	readonly focusItems?: (spaceItems: ISpaceItemModel[]) => void;
	readonly getFocusedItems: () => ISpaceItemModel[];
	readonly onAddCatalogClick: (event: React.MouseEvent) => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly toggleDetailsPanel: () => void;
	readonly onOpenPanelClick: (type: TSpaceEditorPanelTypes) => void;
}

interface ISpaceEditorState {
	actionBar: {
		isOpen: boolean;
		isLinkable: boolean /** If only xyicons and are selected */;
		isEditable: boolean /** If only one boundary, or one markup is selected */;
		isInEditMode: boolean;
		worldX: number;
		worldY: number;
	};
	portSelector: {
		isOpen: boolean;
		ports: PortTemplateDto[];
		worldX: number;
		worldY: number;
		item: Xyicon;
		type: "from" | "to";
	};
	contextMenu: {
		isOpen: boolean;
		worldX: number;
		worldY: number;
	};
	linkBreakers: {
		fromObjectIds: string[];
	};
	linkedXyicons: {
		fromObjectIds: string[];
		type: InvisibleLinkedXyiconType;
	};
	mergeBoundariesWindow: {
		isOpen: boolean;
	};
	activeToolBar: SpaceToolBarType;
}

@inject("appState")
@observer
export class SpaceEditorV5 extends React.Component<ISpaceEditorProps, ISpaceEditorState> {
	private _themeObserverDisposer: Lambda;
	private _canvasContainer = React.createRef<HTMLDivElement>();
	private _spaceToolBarRef = React.createRef<SpaceToolBarV5>();

	constructor(props: ISpaceEditorProps) {
		super(props);
		this.state = {
			actionBar: {
				isOpen: false,
				isLinkable: false,
				isEditable: false,
				isInEditMode: false,
				worldX: 0,
				worldY: 0,
			},
			portSelector: {
				isOpen: false,
				ports: [],
				worldX: 0,
				worldY: 0,
				item: null,
				type: "from",
			},
			contextMenu: {
				isOpen: false,
				worldX: 0,
				worldY: 0,
			},
			linkBreakers: {
				fromObjectIds: [],
			},
			linkedXyicons: {
				fromObjectIds: [],
				type: null,
			},
			mergeBoundariesWindow: {
				isOpen: false,
			},
			activeToolBar: SpaceToolBarType.PrimaryToolBar,
		};
	}

	private openPortSelector = (worldX: number, worldY: number, item: Xyicon, ports: PortTemplateDto[], type: "from" | "to") => {
		this.setState({
			portSelector: {
				isOpen: true,
				worldX: worldX,
				worldY: worldY,
				item: item,
				ports: ports,
				type: type,
			},
		});
	};

	private closePortSelector = () => {
		if (this.state.portSelector.isOpen) {
			this.setState({
				portSelector: {
					isOpen: false,
					worldX: 0,
					worldY: 0,
					item: null,
					type: "from",
					ports: [],
				},
			});
		}
	};

	private openContextMenu = (worldX: number, worldY: number) => {
		if (this.spaceViewRenderer.mode === SpaceEditorMode.NORMAL) {
			const {currentPointerWorld} = this.spaceViewRenderer.toolManager.activeTool;

			currentPointerWorld.x = worldX;
			currentPointerWorld.y = worldY;

			this.setState({
				contextMenu: {
					isOpen: true,
					worldX: worldX,
					worldY: worldY,
				},
			});
			this.closeActionBar();
		}
	};

	private isContextMenuOpen = () => {
		return this.state.contextMenu.isOpen;
	};

	private closeContextMenu = () => {
		if (this.state.contextMenu.isOpen) {
			this.setState({
				contextMenu: {
					isOpen: false,
					worldX: 0,
					worldY: 0,
				},
			});
		}
	};

	private onDeleteClick = () => {
		const {spaceItemController} = this.spaceViewRenderer;
		const selectedItems = spaceItemController.selectedItems;
		const {actions} = this.props.appState;

		const xyiconsWithoutPermissionCount = actions.getNumberOfSpaceItemsWithoutPermission(selectedItems, Permission.Delete);

		if (xyiconsWithoutPermissionCount === 0) {
			this.closeContextMenu();
			spaceItemController.onDeleteClick();
		} else {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Warning,
				title: "Action not allowed!",
				description: `${xyiconsWithoutPermissionCount} of ${selectedItems.length} objects you have selected do not have the required permissions to perform this action. To obtain permission, contact your organization's administrator.`,
				lifeTime: Infinity,
			});
		}
	};

	private openLinkBreakers = (fromObjectIds: string[]) => {
		this.setState({
			linkBreakers: {
				fromObjectIds: fromObjectIds,
			},
		});
	};

	private updateLinkBreakers = () => {
		if (this.state.linkBreakers.fromObjectIds.length > 0) {
			const fromObjectIds = this.state.linkBreakers.fromObjectIds;

			this.setState({
				linkBreakers: {
					fromObjectIds: fromObjectIds,
				},
			});
		}
	};

	private closeLinkBreakers = () => {
		if (this.state.linkBreakers.fromObjectIds.length > 0) {
			this.setState({
				linkBreakers: {
					fromObjectIds: [],
				},
			});
		}
	};

	private openLinkedXyiconsWindow = (fromObjectIds: string[], type: InvisibleLinkedXyiconType) => {
		this.setState({
			linkedXyicons: {
				fromObjectIds: fromObjectIds,
				type: type,
			},
		});
	};

	private closeLinkedXyiconsWindow = () => {
		if (this.state.linkedXyicons.fromObjectIds.length > 0) {
			this.setState({
				linkedXyicons: {
					fromObjectIds: [],
					type: null,
				},
			});
		}
	};

	private openMergeBoundariesWindow = () => {
		if (!this.state.mergeBoundariesWindow.isOpen) {
			this.setState({
				mergeBoundariesWindow: {
					isOpen: true,
				},
			});
		}
	};

	private closeMergeBoundariesWindow = () => {
		if (this.state.mergeBoundariesWindow.isOpen) {
			this.setState({
				mergeBoundariesWindow: {
					isOpen: false,
				},
			});
		}
	};

	private openActionBar = (worldX: number, worldY: number) => {
		const {spaceItemController} = this.props.spaceViewRenderer;
		const selectedItems = spaceItemController.selectedItems;
		const isEditable =
			selectedItems.length === 1 &&
			spaceItemController.boundaryManager.selectedItems.length + spaceItemController.markupManager.selectedItems.length === 1;
		const isLinkable = selectedItems.every((spaceItem: SpaceItem) => spaceItem.spaceItemType === "xyicon");

		const newActionbarState = {
			isOpen: true,
			isLinkable: isLinkable,
			isEditable: isEditable,
			isInEditMode: isEditable && this.state.actionBar.isInEditMode,
			worldX: worldX,
			worldY: worldY,
		};

		if (!ObjectUtils.compare(this.state.actionBar, newActionbarState)) {
			flushSync(() => {
				this.setState({
					actionBar: newActionbarState,
				});
			});
		}

		this.closeContextMenu();
	};

	private closeActionBar = () => {
		if (this.state.actionBar.isOpen) {
			flushSync(() => {
				this.setState({
					actionBar: {
						isOpen: false,
						isLinkable: false,
						isEditable: false,
						isInEditMode: false,
						worldX: 0,
						worldY: 0,
					},
				});
			});
		}
	};

	private setActiveToolBar = (toolBar: SpaceToolBarType) => {
		if (this.state.activeToolBar !== toolBar) {
			this.setState({
				activeToolBar: toolBar,
			});
		}
	};

	private onEditModeSwitched = (enabled: boolean) => {
		if (this.state.actionBar.isInEditMode !== enabled) {
			this.setState({
				actionBar: {
					...this.state.actionBar,
					isInEditMode: enabled,
				},
			});
		}

		if (enabled) {
			this.props.setActiveTool("selection");
		}
	};

	private onSwitchToTextEditMode = () => {
		// Hacky way to trigger a state change within a child component...
		const buttonMaybe = document.querySelector(`[data-title="Add Text"]`) as HTMLElement;

		if (buttonMaybe) {
			buttonMaybe.click();
		} else {
			console.warn("Add Text button not found...");
		}

		this.props.setActiveTool("selection");
	};

	private onExitTextEditMode = () => {
		//this._spaceActionBarRef.current?.onTextEditorClose();
	};

	private onRedrawClick = () => {
		this.spaceViewRenderer.spaceItemController.onRedrawClick();
	};

	private onCancelEditClick = () => {
		this.spaceViewRenderer.spaceItemController.onCancelEditClick();

		requestAnimationFrame(() => {
			if (this.state.actionBar.isInEditMode) {
				this.setState({
					actionBar: {
						...this.state.actionBar,
						isInEditMode: false,
					},
				});
			}
		});
	};

	private updateTheme = () => {
		this.spaceViewRenderer.updateTheme();
	};

	private get inheritedMethods(): IinheritedMethods {
		return {
			openActionBar: this.openActionBar,
			closeActionBar: this.closeActionBar,
			openPortSelector: this.openPortSelector,
			closePortSelector: this.closePortSelector,
			openContextMenu: this.openContextMenu,
			isContextMenuOpen: this.isContextMenuOpen,
			closeContextMenu: this.closeContextMenu,
			selectItems: this.props.selectItems ?? Functions.emptyFunction,
			focusItems: this.props.focusItems ?? Functions.emptyFunction,
			getFocusedItems: this.props.getFocusedItems,
			setScale: this.props.setScale,
			confirmAlignment: this.props.confirmAlignment,
			setActiveTool: this.props.setActiveTool,
			onPasteClick: this.onPasteClick,
			openLinkBreakers: this.openLinkBreakers,
			updateLinkBreakers: this.updateLinkBreakers,
			closeLinkBreakers: this.closeLinkBreakers,
			openLinkedXyiconsWindow: this.openLinkedXyiconsWindow,
			closeLinkedXyiconsWindow: this.closeLinkedXyiconsWindow,
			onEditModeSwitched: this.onEditModeSwitched,
			onSwitchToTextEditMode: this.onSwitchToTextEditMode,
			onExitTextEditMode: this.onExitTextEditMode,
		};
	}

	private initSpaceViewRenderer() {
		if (this._canvasContainer.current) {
			this._canvasContainer.current.appendChild(this.spaceViewRenderer.canvas);
			this.spaceViewRenderer.mount();
		}
	}

	private getNonDockedTitle(): DockableTitle {
		if (this.props.isCatalogOpen && !this.props.isCatalogDocked) {
			return "Catalog";
		} else if (this.props.isBoundaryTypesWindowOpen && !this.props.isBoundaryTypesWindowDocked) {
			return "Boundary";
		} else if (this.props.isUnplottedXyiconsOpen && !this.props.isUnplottedXyiconsDocked) {
			return "Unplotted Xyicons";
		} else {
			return null;
		}
	}

	private pasteFromClipboard = (event: ClipboardEvent) => {
		if (KeyboardListener.isEventTargetAnInput(event)) {
			// we don't override the default behaviour, let the users paste text into input, and textarea elements
		} else {
			this.paste(event.clipboardData.getData("text/plain"));
		}
	};

	private onPasteClick = async () => {
		if (navigator.clipboard.readText) {
			this.paste(await navigator.clipboard.readText());
		} else {
			await WarningWindow.open(
				`It looks like you're missing permissions to read the clipboard. Please try to allow the browser to access the clipboard.<br />
				Alternatively, try to use the <i>Ctrl+C</i> (for copy), and <i>Ctrl+V</i> (for paste) methods.
				${
					Constants.isFirefox
						? `<br /><br />If you're using Firefox, open up a new tab, type <i>about:config</i> as the url you'd like to visit,
				then change the following 3 settings from <i>false</i> to <i>true</i> :<br /><br />
				<div class="hbox justifyCenter"><img src="src/assets/images/spaceviewer/mozilla_firefox_asyncclipboard.png" alt="Firefox clipboard settings" /><div>`
						: ""
				}`,
			);
		}
	};

	private async paste(pastedString: string = "") {
		if (this.props.mode === SpaceEditorMode.NORMAL) {
			if (!ClipboardManager.isPasting && !this.spaceViewRenderer.spaceItemController.isAddingItemsToServer) {
				try {
					const pastedText = StringUtils.formatPastedText(pastedString, ClipboardManager.storedHTML);
					const actionType = ClipboardManager.action;

					await ClipboardManager.paste(pastedText, this.spaceViewRenderer, actionType);
					if (actionType === "CUT") {
						ClipboardManager.clearClipboard();
					}
				} catch (error) {
					console.warn(`Paste error: ${error}`);
				}
			} else {
				console.warn("You tried to paste items, while some other items were already being created.");
			}
		}
	}

	private onApplyEditClick = () => {
		if (this.spaceViewRenderer.spaceItemController.currentlyEditedItem?.isValid) {
			this.props.spaceViewRenderer.spaceItemController.switchEditMode(false);
		}
	};

	private keyDown = (event: KeyboardEvent) => {
		if (!ClipboardManager.getSelectedText()) {
			const spaceViewRenderer = this.spaceViewRenderer;

			switch (event.key) {
				case KeyboardListener.KEY_ESCAPE:
					if (!KeyboardListener.isEventTargetAnInput(event) && PopupWindow.numberOfOpenPopups < 1) {
						this.props.setActiveTool("selection");
					}
					break;
				case "c":
				case "C":
				case "d":
				case "D":
					if (KeyboardListener.isCtrlDown) {
						const isDuplicate = event.key.toLowerCase() === "d";

						if (isDuplicate) {
							event.preventDefault(); // ctrl+d => save to bookmark by default
						}
						spaceViewRenderer.copySelectedItemsToClipboard();
						if (isDuplicate) {
							this.onPasteClick();
						}
					}
					break;
				case "x":
				case "X":
					if (KeyboardListener.isCtrlDown) {
						spaceViewRenderer.cutSelectedItemsToClipboard();
					}
					break;
				case "s":
				case "S":
					if (KeyboardListener.isCtrlDown) {
						event.preventDefault(); // by default, the browser tries to save the html file
						spaceViewRenderer.copySelectedItemsToStamp();
					}
					break;
				case KeyboardListener.KEY_CTRL:
					this.forceUpdate();
					break;
			}
		}
	};

	private keyUp = (event: KeyboardEvent) => {
		const spaceViewRenderer = this.spaceViewRenderer;

		switch (event.key) {
			case KeyboardListener.KEY_DELETE:
				if (this.state.actionBar.isInEditMode) {
					spaceViewRenderer.boundaryManager.deleteSelectedVertex();
				} else if (spaceViewRenderer.spaceItemController.selectedItems.length > 0) {
					this.onDeleteClick();
				}
				break;
			case KeyboardListener.KEY_ESCAPE:
				if (this.state.actionBar.isInEditMode) {
					this.onCancelEditClick();
				}
				break;
			case KeyboardListener.KEY_ENTER:
				if (this.state.actionBar.isInEditMode) {
					this.onApplyEditClick();
				}
				break;
			case KeyboardListener.KEY_CTRL:
				this.forceUpdate();
				break;
		}
	};

	private onRealignSpaceClick = () => {
		this._spaceToolBarRef.current?.primaryToolBarRef.current?.openSpaceAlignToolBar();
	};

	public get spaceViewRenderer() {
		return this.props.appState.app.spaceViewRenderer;
	}

	private loadSpace(space: Space) {
		return this.props.spaceViewRenderer.populateData(space);
	}

	private updateSpace() {
		const space = this.props.spaceViewRenderer.transport.appState.space;

		if (space) {
			if (this.props.spaceViewRenderer.space?.id !== space.id) {
				return this.loadSpace(space);
			}
		}
	}

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.keyDown);
		KeyboardListener.getInstance().signals.up.add(this.keyUp);

		this.props.appState.app.graphicalTools.pdfRenderer.setProcessOrder("LIFO");
		this.initSpaceViewRenderer();
		this.updateSpace();

		const appState = this.props.appState;

		this._themeObserverDisposer = observe(appState, "theme", (changeValue: IValueDidChange<ThemeType>) => {
			this.updateTheme();
		});

		document.addEventListener("paste", this.pasteFromClipboard);
		FocusLoss.listen(document.body, ClipboardManager.onBlur);
		this.spaceViewRenderer.transport.signals.switchOrganization.add(ClipboardManager.onBlur);
	}

	public override componentDidUpdate(): void {
		this.updateSpace();
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.keyDown);
		KeyboardListener.getInstance().signals.up.remove(this.keyUp);

		document.removeEventListener("paste", this.pasteFromClipboard);
		this.spaceViewRenderer.unmount();
		FocusLoss.stopListen(document.body, ClipboardManager.onBlur);
		this.spaceViewRenderer.transport.signals.switchOrganization.remove(ClipboardManager.onBlur);
		ClipboardManager.onBlur();

		this._themeObserverDisposer?.();
		this._themeObserverDisposer = null;
	}

	public override render() {
		const spaceViewRenderer = this.spaceViewRenderer;
		const {actionBar, portSelector, contextMenu, linkBreakers, linkedXyicons, mergeBoundariesWindow} = this.state;

		this.spaceViewRenderer.setMode(this.props.mode);
		this.spaceViewRenderer.addInheritedMethods(this.inheritedMethods);

		const nonDockedTitle = this.getNonDockedTitle();

		const canvasContainerBorderStyle: CSSProperties =
			this.props.mode === SpaceEditorMode.NORMAL
				? {}
				: {
						border: `2px solid ${colorPalette.gray.c300}`,
						borderRadius: radius.sm,
					};

		return (
			<SpaceEditorStyled
				className="SpaceEditor"
				data-min-width={392}
			>
				{nonDockedTitle && (
					<DockableV5
						spaceViewRenderer={spaceViewRenderer}
						setDocked={this.props.setDockableDocked}
						setOpen={this.props.setDockableOpen}
						isDocked={false}
						title={nonDockedTitle}
						setActiveTool={this.props.setActiveTool}
						onAddCatalogClick={this.props.onAddCatalogClick}
						onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
						onCreateUnplottedXyicons={Functions.emptyFunction}
					/>
				)}
				<CanvasContainerStyled
					ref={this._canvasContainer}
					style={canvasContainerBorderStyle}
				>
					<SpaceToolBarV5
						ref={this._spaceToolBarRef}
						spaceViewRenderer={spaceViewRenderer}
						mode={this.props.mode}
						activeToolBar={this.state.activeToolBar}
						setActiveToolBar={this.setActiveToolBar}
						setDockableOpen={this.props.setDockableOpen}
						isCatalogOpen={this.props.isCatalogOpen}
						isBoundaryTypesWindowOpen={this.props.isBoundaryTypesWindowOpen}
						isUnplottedXyiconsOpen={this.props.isUnplottedXyiconsOpen}
						activeToolId={this.props.activeToolId}
						setActiveTool={this.props.setActiveTool}
						selectionToolType={this.props.selectionToolType}
					/>
					{this.spaceViewRenderer.isMeasureToolBarOpen && (
						<MeasureToolBarV5
							spaceViewRenderer={this.spaceViewRenderer}
							activeToolId={this.props.activeToolId}
							setActiveTool={this.props.setActiveTool}
						/>
					)}
					{this.props.mode === SpaceEditorMode.NORMAL && this.state.activeToolBar !== SpaceToolBarType.SpaceAlignToolBar && (
						<>
							<SpaceViewBarV5
								onOpenPanelClick={this.props.onOpenPanelClick}
								activePanel={this.props.activePanel}
							/>
							<div className="sceneOptions hidden">
								<XyiconSizeChanger spaceViewRenderer={this.spaceViewRenderer} />
							</div>
						</>
					)}
					{(this.props.mode === SpaceEditorMode.ALIGN || this.state.activeToolBar === SpaceToolBarType.SpaceAlignToolBar) && (
						<SpaceAlignViewBarV5 spaceViewRenderer={this.spaceViewRenderer} />
					)}
					{actionBar.isOpen && linkBreakers.fromObjectIds.length === 0 && !KeyboardListener.isCtrlDown && (
						<SpaceActionBarV5
							spaceViewRenderer={this.spaceViewRenderer}
							isEditEnabled={actionBar.isEditable}
							isInEditMode={actionBar.isInEditMode}
							isLinkEnabled={actionBar.isLinkable}
							worldX={actionBar.worldX}
							worldY={actionBar.worldY}
							onRedrawClick={this.onRedrawClick}
							onCancelEditClick={this.onCancelEditClick}
							onApplyEditClick={this.onApplyEditClick}
							onDeleteClick={this.onDeleteClick}
							openMergeBoundariesWindow={this.openMergeBoundariesWindow}
							toggleDetailsPanel={this.props.toggleDetailsPanel}
							isDetailsPanelOpen={this.props.isDetailsPanelOpen}
						/>
					)}
					{mergeBoundariesWindow.isOpen && <MergeBoundariesWindowV5 onClose={this.closeMergeBoundariesWindow} />}
					{contextMenu.isOpen && (
						<SpaceContextMenu
							spaceViewRenderer={spaceViewRenderer}
							worldX={contextMenu.worldX}
							worldY={contextMenu.worldY}
							onCopyClick={spaceViewRenderer.copySelectedItemsToClipboard}
							onCopyToStampClick={spaceViewRenderer.copySelectedItemsToStamp}
							onCutClick={spaceViewRenderer.cutSelectedItemsToClipboard}
							onDeleteClick={this.onDeleteClick}
							onPasteClick={this.onPasteClick}
							onRealignSpaceClick={this.onRealignSpaceClick}
							onClose={this.closeContextMenu}
						/>
					)}
					{portSelector.isOpen && (
						<PortSelectorV5
							spaceViewRenderer={spaceViewRenderer}
							worldX={portSelector.worldX}
							worldY={portSelector.worldY}
							item={portSelector.item}
							ports={portSelector.ports}
							type={portSelector.type}
						/>
					)}
					{linkBreakers.fromObjectIds.length > 0 && (
						<LinkBreakersV5
							spaceViewRenderer={spaceViewRenderer}
							fromObjectIds={linkBreakers.fromObjectIds}
						/>
					)}
					{linkedXyicons.fromObjectIds.length === 1 && ( // TODO: what should happen if there are more than 1 xyicons with off-space links? As a temporary solution we don't show any popups now
						<LinkedXyiconsWindowV5
							spaceViewRenderer={spaceViewRenderer}
							fromObjectId={linkedXyicons.fromObjectIds[0]}
							type={linkedXyicons.type}
							onClose={this.closeLinkedXyiconsWindow}
						/>
					)}
					{this.props.mode === SpaceEditorMode.NORMAL && this.state.activeToolBar !== SpaceToolBarType.SpaceAlignToolBar && (
						<SpaceSelector
							spaces={this.props.appState.actions.getList<Space>(XyiconFeature.Space)}
							selectedSpace={this.props.appState.space}
							onSpaceSelect={(space: Space) => this.props.appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, space.id)}
						/>
					)}
					{this.spaceViewRenderer.toolManager && spaceViewRenderer.toolManager.cameraControls && this.props.thumbnail && (
						<SpaceControlBarV5
							spaceViewRenderer={spaceViewRenderer}
							onRealignSpaceClick={this.onRealignSpaceClick}
							navigationBoxImgSrc={this.props.thumbnail}
						/>
					)}
				</CanvasContainerStyled>
				<SpaceLoadingScreenV5 spaceViewRenderer={spaceViewRenderer} />
			</SpaceEditorStyled>
		);
	}
}

const SpaceEditorStyled = styled.div`
	position: relative;
	width: 100%;
	overflow: hidden;
	display: flex;
	min-height: 0;
	height: 100%;

	canvas {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
`;

const CanvasContainerStyled = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	min-height: 0;
`;

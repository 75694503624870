import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IViewColumn} from "../../../data/models/ViewUtils";
import {SVGIcon} from "../button/SVGIcon";
import {BoundarySpaceMapSelector} from "../BoundarySpaceMapSelector";
import {CheckboxInput} from "../input/checkbox/CheckboxInput";
import type {App} from "../../../App";
import {ReactUtils} from "../../utils/ReactUtils";
import {Functions} from "../../../utils/function/Functions";
import {XyiconFeature} from "../../../generated/api/base";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import type {Boundary} from "../../../data/models/Boundary";
import type {Report} from "../../../data/models/Report";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../utils/interaction/Pointer";
import type {IFieldAdapter} from "../../../data/models/field/Field";
import type {IModel} from "../../../data/models/Model";
import type {Xyicon} from "../../../data/models/Xyicon";
import {BoundaryUtils} from "../../../data/models/BoundaryUtils";
import type {Catalog} from "../../../data/models/Catalog";
import {NavigationEnum} from "../../../Enums";
import {minHeaderWidth, minIconHeaderWidth} from "./TableConstants";
import {TableCell} from "./TableCell";

interface ITableRowProps<T> {
	readonly id: string | number;
	readonly item: T;
	readonly fields: IFieldAdapter[];
	readonly headers: Partial<IViewColumn>[];
	readonly index: number;
	readonly selected: boolean;
	readonly onSelect: (event: React.MouseEvent, item: T) => void;
	readonly isVisibleInSpaceEditor?: (item: T) => boolean;
	readonly showNotification?: (item: T) => void;
	readonly onDoubleClick?: (item: T) => void;
	readonly onCheckboxRowClick?: (event: React.MouseEvent, item: T) => void;
	readonly showQuickLinks: (e: React.MouseEvent, item: T, index: number) => void;
	readonly showCheckboxColumn: boolean;
	readonly quickLinksActive: boolean;
	readonly feature?: XyiconFeature;
	readonly navigation?: Navigation;
	readonly tableRowIcon?: boolean;
	readonly isCellContentsWrappingOn?: boolean;
	readonly onMouseMove?: (pointer: Pointer, row: TableRow<T>) => void;
	readonly selectedItems?: T[];
	readonly scrollTableToShowFullCell?: (e: React.MouseEvent) => void;

	readonly app?: App;
	readonly appState?: AppState;
}

type Highlight = "Top" | "Middle" | "Bottom" | "None";

interface ITableRowState {
	isBoundarySelectorOpen: boolean;
	highlight: Highlight; // Top/Bottom: Shows an empty row with dashed border on top/bottom of this one. Middle: show dashed border with blue background on this row
	isGreyedOut: boolean;
	isNotAllowed: boolean;
	dragNdrop: boolean;
}

@inject("app")
@inject("appState")
@inject("navigation")
@observer
export class TableRow<T> extends React.Component<ITableRowProps<T>, ITableRowState> {
	private readonly _hoverClassName = "hoverDashed";

	constructor(props: ITableRowProps<T>) {
		super(props);
		this.state = {
			isBoundarySelectorOpen: false,
			highlight: "None",
			isGreyedOut: false,
			isNotAllowed: false,
			dragNdrop: false,
		};
	}

	private onMouseDown = (event: React.MouseEvent) => {
		if (event.shiftKey) {
			event.preventDefault();
		}
	};

	private onMouseMove = (pointer: Pointer) => {
		this.props.onMouseMove?.(pointer, this);
	};

	private onClick = (event: React.MouseEvent) => {
		// Ignore double click events
		if (event.detail === 1) {
			this.props.onSelect(event, this.props.item);
		}
	};

	private onDoubleClick = (event: React.MouseEvent) => {
		const item: any = this.props.item;

		if (!item.isUnplotted) {
			this.props.onDoubleClick?.(item);
		}
	};

	private onShowBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: true,
		});
	};

	private onHideBoundarySpaceMaps = () => {
		this.setState({
			isBoundarySelectorOpen: false,
		});
	};

	private get isUnplotted() {
		const item = this.props.item as unknown as Xyicon;

		return item.isUnplotted;
	}

	private onItemActionClick = () => {
		const {item, feature, appState, navigation, isVisibleInSpaceEditor, showNotification} = this.props;

		if (!this.isUnplotted) {
			if ((item as unknown as IModel).ownFeature === XyiconFeature.Space) {
				navigation.goApp(NavigationEnum.NAV_SPACE, (item as unknown as IModel).id);
			} else {
				if (!isVisibleInSpaceEditor(item)) {
					showNotification(item);
				}
				if ((item as unknown as IModel).ownFeature === XyiconFeature.Xyicon && feature === XyiconFeature.Xyicon) {
					appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
				} else if ((item as unknown as IModel).ownFeature === XyiconFeature.Boundary && feature === XyiconFeature.Boundary) {
					if (BoundaryUtils.doesHaveMultipleSpaceMaps(item as unknown as IModel)) {
						this.onShowBoundarySpaceMaps();
					} else {
						appState.actions.navigateToSpaceItem(item as unknown as Xyicon, true);
					}
				}
			}
		}
	};

	private getIcon() {
		switch (this.props.feature) {
			case XyiconFeature.Space:
				return "open";
			case XyiconFeature.Xyicon:
				return "pin";
			case XyiconFeature.Boundary:
				return "pin";
			case XyiconFeature.Report:
				return "play";
			default:
				break;
		}
	}

	private onClickReport = () => {
		const report = this.props.item as unknown as Report;
		const reportView = this.props.app.reportView;

		reportView?.runReport([report]);
	};

	private onOpenContextMenu = (e: React.MouseEvent) => {
		const {item, index, showQuickLinks} = this.props;

		if (!this.isUnplotted) {
			showQuickLinks(e, item, index);
		} else {
			e.preventDefault();
		}
	};

	private onFavoriteClick = () => {
		return this.setFavorite(true);
	};

	private onUnFavoriteClick = () => {
		return this.setFavorite(false);
	};

	private setFavorite(value: boolean) {
		(this.props.item as Catalog).setFavorite(value);
	}

	private getRowIcon() {
		const {quickLinksActive} = this.props;
		const item = this.props.item as unknown as IModel;

		if (item.ownFeature === XyiconFeature.Portfolio) {
			return (
				<div
					className={ReactUtils.cls("quickLinksButton", {active: quickLinksActive})}
					onClick={this.onOpenContextMenu}
				>
					<div className="item" />
					<div className="item" />
					<div className="item" />
				</div>
			);
		} else if (item.ownFeature === XyiconFeature.Boundary || item.ownFeature === XyiconFeature.Xyicon || item.ownFeature === XyiconFeature.Space) {
			return (
				<div
					className={ReactUtils.cls("quickLinksButton link", {active: quickLinksActive, disabled: this.isUnplotted})}
					onClick={this.onItemActionClick}
					onContextMenu={this.onOpenContextMenu}
					title={
						item.ownFeature === XyiconFeature.Xyicon
							? this.isUnplotted
								? "Cannot open unplotted xyicons in the Space Editor"
								: "Open in Space Editor"
							: ""
					}
				>
					<SVGIcon icon={this.getIcon()} />
				</div>
			);
		} else if (item.ownFeature === XyiconFeature.XyiconCatalog) {
			const catalog = item as Catalog;

			return catalog.isFavorite ? (
				<div
					className="quickLinksButton favoriteCatalogItems link button"
					title="Remove from favorites"
					onClick={this.onUnFavoriteClick}
				>
					<SVGIcon
						classNames="favorite-unstar"
						icon="unstar"
					/>
					<SVGIcon
						classNames="favorite-star"
						icon="star"
					/>
				</div>
			) : (
				<div
					className="quickLinksButton favoriteCatalogItems link button"
					title="Add to favorites"
					onClick={this.onFavoriteClick}
				>
					<SVGIcon
						classNames="unfavorite-unstar"
						icon="unstar"
					/>
					<SVGIcon
						classNames="unfavorite-star"
						icon="star"
						strokeOnly={true}
					/>
				</div>
			);
		} else if (item.ownFeature === XyiconFeature.Report) {
			return (
				<div
					className={ReactUtils.cls("quickLinksButton link", {active: quickLinksActive})}
					onClick={this.onClickReport}
				>
					<SVGIcon icon={this.getIcon()} />
				</div>
			);
		}
	}

	private getEmptyRow() {
		return <div className={`tr ${this._hoverClassName} empty`} />;
	}

	public override render() {
		const {
			item,
			id,
			fields,
			headers,
			selected,
			showCheckboxColumn,
			tableRowIcon,
			isCellContentsWrappingOn,
			selectedItems,
			onCheckboxRowClick,
			appState,
			scrollTableToShowFullCell,
		} = this.props;

		const {isGreyedOut, isNotAllowed, dragNdrop, isBoundarySelectorOpen, highlight} = this.state;
		const highlightClassName = highlight === "Middle" ? this._hoverClassName : "";
		const model = item as unknown as IModel;

		return (
			<>
				{highlight === "Top" && this.getEmptyRow()}
				<PointerDetectorReact
					onHoverMove={this.onMouseMove}
					preventDefaultOnTouch={false}
					preventDefaultOnMouseMove={false}
					preventDefaultOnMouseUp={false}
				>
					<div
						data-key={id}
						onMouseDown={this.onMouseDown} // Option A to remove selection
						onClick={this.onClick}
						onDoubleClick={this.onDoubleClick}
						onContextMenu={this.onOpenContextMenu}
						className={ReactUtils.cls(`tr ${highlightClassName}`, {
							wrapped: isCellContentsWrappingOn,
							selected,
							isGreyedOut,
							isNotAllowed,
							dragNdrop,
							hasQuickLinks: tableRowIcon,
							lower: tableRowIcon,
						})}
					>
						{tableRowIcon && this.getRowIcon()}
						{showCheckboxColumn && (
							<div
								className="td"
								onClick={(event) => onCheckboxRowClick(event, item)}
								onDoubleClick={Functions.stopPropagation}
							>
								<CheckboxInput
									value={selected}
									disabled={true}
								/>
							</div>
						)}
						{isBoundarySelectorOpen && (
							<BoundarySpaceMapSelector
								item={item as unknown as Boundary}
								onClose={this.onHideBoundarySpaceMaps}
							/>
						)}
						{fields.map((field, col) => {
							const w = headers[col].field.includes("icon")
								? `${headers[col]?.width || minIconHeaderWidth}px`
								: `${Math.max(headers[col]?.width, minHeaderWidth) || minHeaderWidth}px`;
							const style: React.CSSProperties = {
								width: w,
								minWidth: w,
							};

							return (
								<TableCell
									key={col}
									field={appState.actions.getFieldByRefId(headers[col].field)}
									style={style}
									fieldValue={field}
									item={item as unknown as IModel}
									selectedItems={selectedItems as unknown as IModel[]}
									scrollTableToShowFullCell={scrollTableToShowFullCell}
								/>
							);
						})}
					</div>
				</PointerDetectorReact>
				{highlight === "Bottom" && this.getEmptyRow()}
			</>
		);
	}
}

import {FieldDataType} from "../../../../generated/api/base";
import {FieldDataTypes} from "../FieldDataTypes";
import type {IMultiSelectFieldSettingsDefinition} from "../../../../ui/modules/settings/modules/field/datatypes/MultiSelectFieldSettings";
import {MultiSelectFieldSettings} from "../../../../ui/modules/settings/modules/field/datatypes/MultiSelectFieldSettings";
import {MultiInput} from "../../../../ui/widgets/input/clicktoedit/datatypes/multiselect/MultiInput";
import {FilterOperator} from "../../filter/operator/FilterOperator";
import {MassFieldMultiInput} from "../../../../ui/modules/abstract/sidepanel/tabs/details/field/mass/datatypes/MassFieldMultiInput";
import {MultiInputV5} from "../../../../ui/5.0/details/datatypes/multi/MultiInputV5";
import {MassFieldMultiInputV5} from "../../../../ui/5.0/details/datatypes/mass/MassFieldMultiInputV5";
import {MultiSelectFieldSettingsV5} from "../../../../ui/5.0/details/datatypes/multi/MultiSelectFieldSettingsV5";
import type {ISingleSelectFieldSettingsDefinition} from "./SingleSelect";

// Handle if value is not an array
// Note: this is likely a migration/db issue which should be resolved,
// here we're making sure the app doesn't crash.
// Note2: we could do this when items are loaded, but the fields have to be loaded already for that to work.
const parseValue = (value: any) => {
	if (Array.isArray(value)) {
		return value;
	} else {
		if (value || value === 0 || value === false) {
			return [value];
		} else {
			return [];
		}
	}
};

export function configureMultiSelect() {
	FieldDataTypes.configure({
		id: FieldDataType.MultipleChoiceList,
		title: "Multi-select",
		settings: MultiSelectFieldSettings,
		settingsV5: MultiSelectFieldSettingsV5,
		input: MultiInput,
		inputV5: MultiInputV5,
		massInput: MassFieldMultiInput,
		massInputV5: MassFieldMultiInputV5,
		defaultSettings: {
			choiceList: [],
		} as IMultiSelectFieldSettingsDefinition,
		defaultSettingsV5: {
			choiceList: [],
		} as IMultiSelectFieldSettingsDefinitionV5,
		converter: parseValue,
		operatorsArray: [
			FilterOperator.CONTAINS,
			FilterOperator.DOES_NOT_CONTAIN,
			FilterOperator.IS_BLANK,
			FilterOperator.IS_NOT_BLANK,
			FilterOperator.IS_ANY_OF,
			FilterOperator.IS_NOT_ANY_OF,
		],
	});
}

export interface IMultiSelectOption {
	value: string;
	color: string;
}

export type IMultiSelectFieldSettingsDefinitionV5 = Omit<ISingleSelectFieldSettingsDefinition, "type">;

import * as React from "react";
import type {ISizeRange} from "../../../space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../space/spaceeditor/logic3d/Constants";
import {MathUtils} from "../../../../../utils/math/MathUtils";

export interface ISizeChangerProps {
	readonly disabled?: boolean;
	readonly value: number;
	readonly onChange: (newValue: number) => void;
	readonly range: ISizeRange;
}

const HiddenOptionValue = 1;

export class SizeChanger extends React.Component<ISizeChangerProps> {
	public static readonly defaultProps: Partial<ISizeChangerProps> = {
		range: Constants.SIZE.FONT,
	};

	private _input = React.createRef<HTMLInputElement>();
	private get _stepSize(): number {
		return this.props.value >= 100 ? 100 : 10;
	}

	/**
	 * Returns the index of the closest-smaller options from the selection set
	 */
	private getLowerIndexOfInterval = () => {
		const fontSizes = this.props.range.values;

		for (let i = 0; i < fontSizes.length - 1; ++i) {
			const rightSideElement = fontSizes[i + 1];

			if (this.props.value < rightSideElement) {
				return i;
			}
		}

		return fontSizes.length - 1;
	};

	private onDecreaseClick = () => {
		if (!this.props.disabled) {
			const fontSizes = this.props.range;

			const nextPotentialVal = this.props.value - this._stepSize;
			const maxIndex = fontSizes.values.length - 1;
			const maxOptionVal = fontSizes.values[maxIndex];

			const lowerIndex = this.getLowerIndexOfInterval();

			if (lowerIndex >= 0 && this.props.value !== fontSizes.values[0]) {
				if (maxOptionVal <= nextPotentialVal) {
					this.setNewSize(nextPotentialVal);
				} else if (nextPotentialVal < maxOptionVal && maxOptionVal < this.props.value) {
					this.setNewSize(fontSizes.values[lowerIndex]);
				} else if (this.props.value <= maxOptionVal) {
					let newIndex = lowerIndex;

					if (this.props.value === fontSizes.values[lowerIndex]) {
						--newIndex;
					}
					this.setNewSize(fontSizes.values[newIndex]);
				}
			}
		}
	};

	private onIncreaseClick = () => {
		if (!this.props.disabled) {
			const fontSizes = this.props.range;
			const maxIndex = fontSizes.values.length - 1;

			const lowerIndex = this.getLowerIndexOfInterval();

			if (lowerIndex >= maxIndex) {
				const nextSize = this.props.value + this._stepSize;

				this.setNewSize(nextSize);
			} else {
				let newIndex = lowerIndex + 1;

				if (this.props.value === fontSizes.values[newIndex]) {
					++newIndex;
				}
				this.setNewSize(fontSizes.values[newIndex]);
			}
		}
	};

	private updateInputValue(newValue: number) {
		this._input.current.value = newValue.toString();
	}

	private setNewSize = (newSize: number) => {
		if (!isNaN(newSize)) {
			newSize = MathUtils.clamp(newSize, this.props.range.min, this.props.range.max);
			if (newSize !== this.props.value) {
				this.props.onChange(newSize);
			}
			this.updateInputValue(newSize);
		} else {
			this.updateInputValue(this.props.value);
		}
	};

	private onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const newFontSize = parseFloat(event.currentTarget.value);

		this.setNewSize(newFontSize);
	};

	private onInputChange = (event: Event) => {
		/**
		 * For some reason, in react, `onChange=` seems to be triggered on every keypress.
		 * With this method, it"s only triggered when the input is finished (like pressing enter, or changing focus to another element)
		 */
		const inputElement = event.currentTarget as HTMLInputElement;
		const newFontSize = parseFloat(inputElement.value);

		this.setNewSize(newFontSize);
	};

	public override componentDidMount() {
		const inputElement = this._input.current;

		inputElement?.addEventListener("change", this.onInputChange);
	}

	public override componentWillUnmount() {
		const inputElement = this._input.current;

		inputElement.removeEventListener("change", this.onInputChange);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: ISizeChangerProps) {
		if (this._input.current) {
			if (Math.abs(parseFloat(this._input.current.value) - nextProps.value) > Constants.EPSILON) {
				this.updateInputValue(nextProps.value);
			}
		}
	}

	public override render() {
		const {range, value, disabled} = this.props;
		// Set selectValue to a hidden option's value to be able to select every visible option
		const selectValue = range.values.includes(value) ? value : HiddenOptionValue;

		return (
			<div className="SizeChanger">
				<div className="valueContainer">
					<input
						maxLength={`${range.max}`.length}
						ref={this._input}
						defaultValue={value.toString()}
						disabled={disabled}
					/>
					<select
						onChange={this.onSelectChange}
						value={selectValue}
						disabled={disabled}
						style={{color: disabled ? "white" : ""}}
					>
						{range.values.map((value, index) => (
							<option
								key={index}
								value={value}
							>
								{value}
							</option>
						))}
						<option
							value={HiddenOptionValue}
							className="hidden"
						></option>
					</select>
				</div>
				<div className="buttonContainer">
					<div
						title="Increase Font Size"
						onClick={this.onIncreaseClick}
					></div>
					<div
						title="Decrease Font Size"
						onClick={this.onDecreaseClick}
					></div>
				</div>
			</div>
		);
	}
}

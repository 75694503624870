import {XyiconFeature} from "../../../../generated/api/base";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import {ConfirmValueWindow} from "./ConfirmValueWindow";
import {ConfirmWindow} from "./ConfirmWindow";

export class PopupUtils {
	public static getDeleteConfirmationPopup(feature: XyiconFeature | XyiconFeature[], count: number) {
		if (Array.isArray(feature) || feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary || feature === XyiconFeature.Report) {
			return ConfirmWindow.open(`Are you sure you want to delete the selected ${count} item(s)?`);
		} else if (feature === XyiconFeature.Portfolio || feature === XyiconFeature.Space) {
			const featureName = featureTitles[feature] || "";
			const deleteModules = feature === XyiconFeature.Portfolio ? "Spaces, Xyicons, Boundaries and Markups" : "Xyicons, Boundaries and Markups";

			const popupText = `You are about to delete ${count === 1 ? `a ${featureName} item` : `${count} ${featureName} items`}. Deleting the selected 
				${count === 1 ? `${featureName} will also delete all the ${deleteModules} in this ${featureName}` : `${featureName}s will also delete all the ${deleteModules} in these ${featureName}s`}.<br><br>
				Use the text field below to confirm the number of items you want to delete.
				<br><br>
				Number of ${featureName} items to delete:<br>`;

			return ConfirmValueWindow.open(popupText, count);
		} else {
			const featureName = featureTitles[feature] || "";

			const popupText = `You are about to delete ${count === 1 ? `a${featureName === "" ? "n" : ""} ${featureName} item` : `${count} ${featureName} items`}.<br>
				Use the text field below to confirm the number of items you want to delete.
				<br><br>
				Number of ${featureName} items to delete:<br>`;

			return ConfirmValueWindow.open(popupText, count);
		}
	}

	public static getFormulaChangeConfirmationPopup(feature: XyiconFeature) {
		const featureName = feature === XyiconFeature.Boundary ? "Boundaries" : `${featureTitles[feature]}s`;
		const popupText = `Changing the formula will update all effected ${featureName}. Do you wish to proceed?`;

		return ConfirmWindow.open(popupText);
	}

	public static getFormulaDeleteConfirmationPopup(feature: XyiconFeature) {
		const featureName = feature === XyiconFeature.Boundary ? "Boundaries" : `${featureTitles[feature]}s`;
		const popupText = `Disabling the formula will clear related data from all ${featureName}`;

		return ConfirmWindow.open(popupText);
	}

	public static getDeleteUserPopup(count: number) {
		const title = "Delete users from Organization";
		const popupText = `You are about to delete the selected users. Type ${count} to delete`;

		return ConfirmValueWindow.open(popupText, count, title);
	}

	public static getBreakLinksConfirmationPopup(count: number) {
		const title = "Confirm Link Break";
		const text = `You are about to break links with ${count} xyicons. Do you wish to continue?`;

		return ConfirmWindow.open(text, title, {ok: "Yes", cancel: "No"});
	}
}

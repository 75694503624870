import type {Texture} from "three";
import {TextureLoader} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {DoubleColorIconMaterial} from "../materials/DoubleColorIconMaterial";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {XyiconTextureAtlas} from "./XyiconTextureAtlas";
import {TextureFactory} from "./TextureFactory";

export interface IMiscTextureObject {
	rotation: {
		svgFileContent: {
			outerHTML: string;
			innerHTML: string;
		};
		texture: Texture;
		material: DoubleColorIconMaterial;
	};
	link: DoubleColorIconMaterial;
	orangeFlag: Texture;
	redFlag: Texture;
	envMap: Texture;
}

export class TextureManager {
	private _textures: {
		xyiconAtlases: XyiconTextureAtlas[];
		counters: Texture[];
		misc: Promise<IMiscTextureObject>;
	};
	private _spaceViewRenderer: SpaceViewRenderer;
	private _transport: TransportLayer;
	private _textureFactory: TextureFactory;
	private _miscTextures: IMiscTextureObject;

	public static readonly _joinMark: ";"; /** Be sure that this is something that doesn't occur in any of the captions */

	constructor(spaceViewRenderer: SpaceViewRenderer, transport: TransportLayer) {
		/**
		 * Based on my tests, neither the FPS, nor the memory usage is affected if we create 512 res textures for xyicons.
		 * But, it makes xyicon resizing work a lot faster, since we don't have to recreate all the textures on size-change!
		 */
		this._spaceViewRenderer = spaceViewRenderer;
		this._textureFactory = new TextureFactory(this._spaceViewRenderer);
		this._transport = transport;

		this._textures = {
			xyiconAtlases: [],
			counters: [],
			misc: null,
		};
	}

	public initMiscTextures() {
		if (!this._textures.misc) {
			this._textures.misc = new Promise<IMiscTextureObject>(async (resolve, reject) => {
				const rotationIconURL = "src/assets/images/spaceviewer/rotation.svg";

				const [rotationTexture, svgFileContent, linkTexture, envMap] = await Promise.all([
					new TextureLoader().loadAsync(rotationIconURL),
					XHRLoader.loadAsync({url: rotationIconURL, json: false}),
					new TextureLoader().loadAsync("src/assets/images/spaceviewer/link.svg"),
					new TextureLoader().loadAsync("src/assets/textures/env/cinema_lobby_2k.webp"),
				]);

				envMap.flipY = false;

				const arr = svgFileContent.split("\n");

				arr.shift();
				arr.pop();
				const innerHTML = arr.join("\t");

				const rotationMaterial = new DoubleColorIconMaterial(rotationTexture, false);

				const miscTextureObj = {
					rotation: {
						svgFileContent: {
							outerHTML: svgFileContent,
							innerHTML: innerHTML,
						},
						texture: rotationTexture,
						material: rotationMaterial,
					},
					link: new DoubleColorIconMaterial(linkTexture, true),
					orangeFlag: this._textureFactory.createOrangeFlag(),
					redFlag: this._textureFactory.createRedFlag(),
					envMap: envMap,
				};

				this._miscTextures = miscTextureObj;

				resolve(miscTextureObj);
			});
		}

		return this._textures.misc;
	}

	public async initTextureAtlases(xyicons: Xyicon[]) {
		const catalogIDs: string[] = [];

		for (const xyicon of xyicons) {
			if (!catalogIDs.includes(xyicon.catalogId)) {
				catalogIDs.push(xyicon.catalogId);
			}
		}

		const maxElementCountPerAtlas = XyiconTextureAtlas.maxElementCount;

		const textureAtlasNeeded = Math.ceil(catalogIDs.length / maxElementCountPerAtlas);

		for (let i = 0; i < textureAtlasNeeded; ++i) {
			const textureAtlas = new XyiconTextureAtlas(this._transport, i);

			await textureAtlas.init(catalogIDs.slice(i * maxElementCountPerAtlas, (i + 1) * maxElementCountPerAtlas));
			this._textures.xyiconAtlases.push(textureAtlas);
		}

		return this._textures.xyiconAtlases;
	}

	/**
	 * Should be called if no more catalogicons can be placed inside the previous textureatlas
	 */
	public async initNewTextureAtlas(catalogId: string) {
		const textureAtlas = new XyiconTextureAtlas(this._transport, this._textures.xyiconAtlases.length);

		await textureAtlas.init([catalogId]);
		this._textures.xyiconAtlases.push(textureAtlas);

		return textureAtlas;
	}

	public async getTextureAtlasIdAndXYByCatalogId(catalogID: string) {
		for (const textureAtlas of this._textures.xyiconAtlases) {
			const xy = textureAtlas.getXYByCatalogId(catalogID);

			if (xy) {
				return {
					atlasId: textureAtlas.id,
					x: xy.x,
					y: xy.y,
					new: false,
				};
			}
		}

		// Doesn't exist yet in the atlases
		const newestXyiconAtlas = this._textures.xyiconAtlases[this._textures.xyiconAtlases.length - 1];

		const successful = newestXyiconAtlas && (await newestXyiconAtlas.putCatalogById(catalogID));

		if (successful) {
			const xy = newestXyiconAtlas.getXYByCatalogId(catalogID);

			return {
				atlasId: newestXyiconAtlas.id,
				x: xy.x,
				y: xy.y,
				new: true,
			};
		} else {
			const newTextureAtlas = await this.initNewTextureAtlas(catalogID);

			return {
				atlasId: newTextureAtlas.id,
				x: 0,
				y: 0,
				new: true,
			};
		}
	}

	public get xyiconAtlases() {
		return this._textures.xyiconAtlases;
	}

	public async initPDF(url: string, spaceWidth: number) {
		await this._spaceViewRenderer.pdfRenderer.init(url, spaceWidth);
	}

	private addCounter(counter: number): Texture {
		return this._textureFactory.createCounterTextureObject(counter);
	}

	public getCounterTexture(counter: number): Texture {
		if (!this._textures.counters[counter]) {
			this._textures.counters[counter] = this.addCounter(counter);
		}

		return this._textures.counters[counter];
	}

	public deleteCachedTextures() {
		for (const xyiconAtlas of this._textures.xyiconAtlases) {
			xyiconAtlas.dispose();
		}
		this._textures.xyiconAtlases.length = 0;
		this._textures.counters.length = 0;
	}

	public get miscTextures() {
		return this._miscTextures;
	}
}

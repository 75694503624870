import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import {Functions} from "../../../utils/function/Functions";
import {SVGIcon} from "./SVGIcon";

export interface IIconButtonProps {
	readonly className?: string;
	readonly style?: React.CSSProperties;
	readonly strokeOnly?: boolean;
	readonly icon?: string;
	readonly title?: string;
	readonly label?: string;
	readonly disabled?: boolean;
	readonly onClick?: (event?: React.MouseEvent) => void;
	readonly onMouseDown?: (event?: React.MouseEvent) => void;
	readonly onTouchStart?: (event?: React.TouchEvent) => void;
	readonly onMouseEnter?: (event?: React.MouseEvent) => void;
	readonly onMouseLeave?: (event?: React.MouseEvent) => void;
}

export class IconButton extends React.PureComponent<IIconButtonProps, {}> {
	public static readonly defaultProps: IIconButtonProps = {
		className: "",
		icon: "",
		onClick: Functions.emptyFunction,
	};

	private onClick = (event: React.MouseEvent) => {
		if (!this.props.disabled) {
			this.props.onClick(event);
		}
	};

	private onMouseDown = (event: React.MouseEvent) => {
		if (!this.props.disabled) {
			this.props.onMouseDown?.(event);
		}
	};

	private onTouchStart = (event: React.TouchEvent) => {
		if (!this.props.disabled) {
			this.props.onTouchStart?.(event);
		}
	};

	public override render() {
		const cls = ReactUtils.cls(`hbox ${this.props.className}`, {
			button: true,
			flexCenter: true,
			disabled: this.props.disabled,
		});

		const icon = this.props.icon || "";

		return (
			<span
				className={cls}
				style={this.props.style}
				onClick={this.onClick}
				title={this.props.title || ""}
				onMouseDown={this.onMouseDown}
				onTouchStart={this.onTouchStart}
				onMouseOver={this.props.onMouseEnter || Functions.emptyFunction}
				onMouseLeave={this.props.onMouseLeave || Functions.emptyFunction}
			>
				<SVGIcon
					icon={icon}
					classNames={icon}
					strokeOnly={this.props.strokeOnly}
				/>
				{this.props.label || ""}
			</span>
		);
	}
}

import React from "react";
import styled, {css} from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Button} from "../../widgets/button/Button";

interface ISettingOptionProps {
	readonly label: string;
	readonly children?: React.ReactElement<Button> | React.ReactElement<Button>[];
	readonly selected: boolean;
	readonly onClick: () => void;
	readonly group?: boolean;
	readonly isHelpHovered?: boolean;
}

export const SettingOption = (props: ISettingOptionProps) => {
	const {selected, group, label, children, isHelpHovered, onClick} = props;

	const handleOnClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		onClick();
	};

	return (
		<SettingOptionContainerStyled
			onClick={handleOnClick}
			className={ReactUtils.cls("SettingOption", {
				selected,
				group,
			})}
			$selected={selected}
			data-cy={`SettingOption.${label}`}
		>
			<SettingsLabelContentStyled
				$selected={selected}
				$isHelpHovered={isHelpHovered}
			>
				{label}
			</SettingsLabelContentStyled>
			{children}
		</SettingOptionContainerStyled>
	);
};

const SettingOptionContainerStyled = styled.div<{$selected: boolean}>`
	display: flex;
	flex-direction: column;
	cursor: pointer;
	width: 100%;
	${(props) => {
		if (props.$selected) {
			return css`
				background-color: #3495f0;
				border-radius: 4px;
				color: #ffffff;
				height: 32px;
				width: 168px;
				padding: 0px, 0px, 0px, 8px;
			`;
		}
	}}
`;

export const SettingsLabelContentStyled = styled.div<{$selected: boolean; $isHelpHovered: boolean}>`
	display: flex;
	align-items: center;
	padding: 4px;
	height: 32px;
	width: 100%;
	font-size: 14px;
	font-weight: 700;
	${SettingOptionContainerStyled}:not(.group) &:hover {
		width: 168px;
		border-radius: 4px;
		background: ${(props) => (props.$selected ? "#3495F0" : props.$isHelpHovered ? "#b7bdc0" : "#C8D4D9")};
	}
`;

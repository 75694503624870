import {css, styled} from "styled-components";
import type {CSSProperties, ReactNode} from "react";
import {useEffect, useRef, useState} from "react";
import {colorPalette} from "../styles/colorPalette";
import {FlexCenterStyle, VerticalFlexStyle, fontSize, fontWeight, radius, zIndex} from "../styles/styles";
import {useAppStore} from "../../../StateManager";
import XmarkLargeIcon from "../icons/xmark-large.svg?react";
import XmarkIcon from "../icons/xmark.svg?react";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../utils/dom/DomUtils";
import type {IButtonV5Props} from "../button/ButtonV5";
import {ButtonV5} from "../button/ButtonV5";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {IconButtonStyled, IconButtonV5} from "../interaction/IconButtonV5";
import {useClickOutside} from "../utils";

interface IButtonProps extends IButtonV5Props {
	hideIcon?: boolean;
}

interface IPopupV5Props {
	readonly label?: ReactNode;
	readonly buttonProps?: IButtonProps;
	readonly parentRef?: HTMLElement;
	readonly children: React.ReactNode;
	readonly verticalAlignment?: VerticalAlignment;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly centerOnScreen?: boolean;
	readonly noButtons?: boolean;
	readonly width?: string;
	readonly height?: string;
	readonly freezeRoot?: boolean;
	readonly className?: string;
	readonly offsetX?: number;
	readonly offsetY?: number;
	readonly isSmallPopup?: boolean;
	readonly versionNumber?: number;
	readonly closeOnClickOutside?: boolean;
	readonly onClose?: (e?: React.MouseEvent) => void;
	readonly style?: CSSProperties;
}

interface IPopupStyledProps {
	$center: boolean;
	$width: string;
	$height: string;
	$isSmallPopup: boolean;
}

export const PopupHeaderStyled = styled.div<{$isSmallPopup?: boolean}>`
	${FlexCenterStyle}
	justify-content: space-between;
	padding: ${(props) => (props.$isSmallPopup ? "8px" : "16px")};
`;

const PopupStyled = styled.div<IPopupStyledProps>`
	${VerticalFlexStyle};
	background-color: ${colorPalette.white};
	border-radius: ${(props) => (props.$isSmallPopup ? radius.md : radius.xl)};
	min-width: 368px;
	box-shadow: 0px 4px 20px 0px #00000033;
	margin: 16px;
	z-index: ${zIndex.createPanel + 1};
	position: absolute;
	visibility: hidden;

	${PopupHeaderStyled} {
		${IconButtonStyled} {
			height: 32px;
			min-width: 32px;

			svg {
				width: 24px;
				height: 24px;
			}
		}
	}

	${(props) => {
		if (props.$center) {
			return css`
				position: fixed;
				visibility: visible;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
			`;
		}
	}};

	&.SpaceCreation,
	&.ReportCreation {
		min-width: 810px;
		min-height: 600px;
		max-width: 1080px;
		max-height: 800px;
	}

	&.SettingsUserCreation {
		min-width: 528px;
		min-height: 528px;
		max-width: 528px;
		max-height: 528px;
		margin-left: 0px;
		visibility: visible;
	}

	&.PermissionSetCreation {
		min-width: 320px;
		min-height: 162px;
		max-width: 320px;
		max-height: 162px;
		position: absolute;
	}

	${(props) => css`
		width: ${props.$width || "auto"};
		height: ${props.$height || "auto"};
	`};

	&.SharingPopup {
		.header {
			padding: 8px;
		}

		.body {
			padding: 0 8px 8px 8px;
		}
	}
`;

export const NameStyled = styled.span`
	${FlexCenterStyle};
	font-size: ${fontSize.xl};
	font-weight: ${fontWeight.bold};
	color: ${colorPalette.gray.c950};
	height: 24px;
`;

export const PopupBodyStyled = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 16px 16px 16px;
	flex: 1;

	.threeColumns {
		display: grid;
		grid-template-columns: 0.5fr 0.65fr 1fr;
	}
`;

export const CreateButtonStyled = styled.div`
	margin-top: 24px;
	display: flex;
	justify-content: flex-end;

	&.button.popupButton {
		cursor: auto;

		.Button &:not(.disabled) {
			cursor: pointer;
		}
	}
`;

export const PopupV5 = (props: IPopupV5Props) => {
	const _floating = useRef<HTMLDivElement>();
	const appState = useAppStore((state) => state.appState);
	const {
		parentRef,
		verticalAlignment = VerticalAlignment.bottom,
		horizontalAlignment = HorizontalAlignment.outerRight,
		offsetY = -8,
		offsetX = 16,
		isSmallPopup = false,
		freezeRoot = false,
		buttonProps = {
			label: "Create",
			create: Functions.emptyFunction,
			onClick: Functions.emptyFunction,
			disabled: false,
			hideIcon: false,
		} as IButtonProps,
		className = "",
		noButtons = false,
		versionNumber = 0,
		closeOnClickOutside = true,
		centerOnScreen,
		width,
		height,
		label,
		onClose,
		children,
		style = {},
	} = props;
	const setIsRootFrozen = useAppStore((state) => state.setIsRootFrozen);
	const [transform, setTransform] = useState<TransformObj>(null);

	useEffect(() => {
		if (freezeRoot) {
			setIsRootFrozen(true);
		}

		if (parentRef && _floating.current) {
			setTransform(DomUtils.getFixedFloatingElementPosition(parentRef, _floating.current, verticalAlignment, horizontalAlignment, offsetY, offsetX));
		}

		return () => setIsRootFrozen(false);
	}, [horizontalAlignment, parentRef, verticalAlignment, freezeRoot, setIsRootFrozen, offsetY, offsetX, versionNumber]);

	useClickOutside([_floating], () => {
		if (closeOnClickOutside) {
			onClose?.();
		}
	});

	const popupStyle: CSSProperties = {
		visibility: centerOnScreen || (_floating.current && transform?.translate) ? "visible" : "hidden",
		...style,
	};

	if (!centerOnScreen && _floating.current && transform) {
		popupStyle.left = `${transform.x}px`;
		popupStyle.top = `${transform.y}px`;
	}

	return (
		<DomPortal destination={appState.app.modalContainer}>
			<PopupStyled
				ref={_floating}
				style={popupStyle}
				$center={centerOnScreen}
				$width={width}
				$height={height}
				className={className}
				$isSmallPopup={isSmallPopup}
			>
				{label && onClose && (
					<PopupHeaderStyled
						className="header"
						$isSmallPopup={isSmallPopup}
					>
						<NameStyled className="name">{label}</NameStyled>
						<IconButtonV5
							IconComponent={isSmallPopup ? XmarkIcon : XmarkLargeIcon}
							onClick={onClose}
						/>
					</PopupHeaderStyled>
				)}
				<PopupBodyStyled className="body">
					{children}
					{!noButtons &&
						!["SpaceCreation", "OrganizationCreation", "PermissionSetCreation", "ReportCreation", "ReportRun", "SettingsUserCreation"].includes(
							className,
						) && (
							<CreateButtonStyled className="button popupButton">
								<ButtonV5
									onClick={buttonProps.onClick}
									disabled={buttonProps.disabled}
									label={buttonProps.label}
								>
									{!buttonProps.hideIcon && <CirclePlusIcon />}
								</ButtonV5>
							</CreateButtonStyled>
						)}
				</PopupBodyStyled>
			</PopupStyled>
		</DomPortal>
	);
};

import {useRef, useState} from "react";
import styled from "styled-components";
import type {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {useClickOutside} from "../utils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {Functions} from "../../../utils/function/Functions";
import {ReactUtils} from "../../utils/ReactUtils";
import {TextAlignOptionsV5, getIconFromAlignments} from "./TextAlignOptionsV5";

interface ITextBoxAlignButtonProps {
	readonly horizontalAlignment: HorizontalAlignment;
	readonly verticalAlignment: VerticalAlignment;
	readonly onTextAlignmentChange: (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => void;
}

export const TextBoxAlignButtonV5 = (props: ITextBoxAlignButtonProps) => {
	const {horizontalAlignment, verticalAlignment, onTextAlignmentChange} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const _ref = useRef<HTMLDivElement>();

	useClickOutside([_ref], () => setIsOpen(false));

	const onTextAlignClick = () => {
		setIsOpen((o) => !o);
	};

	const ActiveIcon = getIconFromAlignments(horizontalAlignment, verticalAlignment);

	return (
		<TextBoxAlignButtonStyled
			ref={_ref}
			onClick={onTextAlignClick}
			className="button flexCenter textAlignButton"
		>
			<IconButtonV5
				IconComponent={ActiveIcon}
				onClick={Functions.emptyFunction}
				title="Align text..."
				className={ReactUtils.cls({isActive: isOpen})}
			/>
			<div className="arrowWrapper">
				<i className="arrowDown corner"></i>
			</div>
			{isOpen && (
				<TextAlignOptionsV5
					onChange={onTextAlignmentChange}
					activeAlignment={{horizontalAlignment, verticalAlignment}}
				/>
			)}
		</TextBoxAlignButtonStyled>
	);
};

const TextBoxAlignButtonStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

import * as React from "react";

import {IconButton} from "../../button/IconButton";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {Formatter} from "../../../../utils/format/Formatter";

interface INumberSliderProps {
	readonly value: number;
	readonly min: number;
	readonly max: number;
	readonly onChange: (value: number) => void;
	readonly time24hFormat?: boolean;
}

export const NumberSlider = (props: INumberSliderProps) => {
	const onDecrement = () => {
		updateValue(props.value - 1);
	};

	const onIncrement = () => {
		updateValue(props.value + 1);
	};

	const updateValue = (value: number) => {
		const {min, max} = props;

		value = MathUtils.mod(value - min, max - min + 1) + min;
		props.onChange(value);
	};

	const {value, time24hFormat} = props;
	const leftPadWith = time24hFormat ? 2 : 0;

	return (
		<div className="NumberSlider">
			<IconButton
				icon="angle_up"
				onClick={onIncrement}
			/>
			<div className="num actual">{Formatter.leftPad(value, leftPadWith)}</div>
			<IconButton
				icon="angle_down"
				onClick={onDecrement}
			/>
		</div>
	);
};

import * as React from "react";
import {PassSecurityLevel, PasswordUtils} from "./PasswordUtils";

interface IPasswordValidatorProps {
	readonly password: string;
}

export class PasswordValidator extends React.Component<IPasswordValidatorProps> {
	public override render() {
		const {password} = this.props;

		const passSecurityLevel = PasswordUtils.getPasswordSecurityLevel(password);
		let passSecurityLevelString: String;

		if (password == "") {
			passSecurityLevelString = "";
		} else if (passSecurityLevel == PassSecurityLevel.RequirementsNotMet) {
			passSecurityLevelString = "Requirements Not Met";
		} else {
			passSecurityLevelString = PassSecurityLevel[passSecurityLevel].toString();
		}

		return (
			<div className="PasswordValidator">
				<div className="securityLevel">
					<div className="bg">
						<div className={`level ${PassSecurityLevel[passSecurityLevel]}`} />
					</div>
				</div>
				<div className="levelMessage">
					<b>Password Strength: {passSecurityLevelString}</b>
					<br></br>
				</div>
				<div className="pwddRequirementsMessage">
					Your password must be 8+ characters, with at least 1 number, uppercase and lowercase letter. Special characters optional.
				</div>
			</div>
		);
	}
}

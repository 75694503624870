import styled from "styled-components";
import {useState} from "react";
import {getMassUpdateOptions} from "../../../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {useAppStore} from "../../../../../StateManager";
import {FieldV5} from "../../FieldV5";
import {ReactUtils} from "../../../../utils/ReactUtils";
import type {IModel} from "../../../../../data/models/Model";
import type {FieldIconName} from "../../../../modules/abstract/sidepanel/tabs/details/field/FieldInputUtils";
import {getIconComponentFromFieldIconName, getTooltipByIcon} from "../../../../modules/abstract/sidepanel/tabs/details/field/FieldInputUtils";
import type {IFieldAdapter} from "../../../../../data/models/field/Field";
import {colorPalette} from "../../../styles/colorPalette";
import {FlexCenterStyle} from "../../../styles/styles";
import {MassUpdatePopupV5} from "./MassUpdatePopupV5";

interface IMassFieldV5Props {
	readonly field: IFieldAdapter;
	readonly items: IModel[];
	readonly disabled: boolean;
	readonly icon: FieldIconName;
}

// TODO: spinner
// TODO: render the massField correctly

export const MassFieldV5 = (props: IMassFieldV5Props) => {
	const {field, items, disabled, icon} = props;
	const appState = useAppStore((store) => store.appState);

	const [open, setOpen] = useState<boolean>(false);

	const onFieldClick = () => {
		setOpen(!open);
	};

	const {allValuesMatch, firstValue} = getMassUpdateOptions(items, field, appState);

	return (
		<>
			<FieldV5
				key={field.refId}
				label={field.name}
				disabled={disabled}
				noWrap={true}
				icons={{preLabelIcon: getIconComponentFromFieldIconName(icon)}}
				tooltips={{
					labelTooltip: field.helperText,
					preLabelIconTooltip: getTooltipByIcon(icon),
				}}
			>
				<MassUpdateFieldValueStyled
					className={ReactUtils.cls("unfocused", {disabled})}
					onClick={onFieldClick}
				>
					{allValuesMatch ? firstValue : <i>Multiple values</i>}
					{/* {
						this.isAnyBeingUpdated &&
							<span className={ReactUtils.cls("spinner", {hasValidation: field.hasValidation})} />
					} */}
				</MassUpdateFieldValueStyled>
			</FieldV5>
			{open && (
				<MassUpdatePopupV5
					fieldRefId={field.refId}
					items={items}
					onClose={onFieldClick}
				/>
			)}
		</>
	);
};

const MassUpdateFieldValueStyled = styled.div`
	height: 32px;
	width: 100%;
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: 4px;
	${FlexCenterStyle}
	padding-left: 8px;

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

import * as React from "react";
import {inject} from "mobx-react";
import type {SpaceVersion} from "../../../../data/models/SpaceVersion";
import {Notification, NotificationType} from "../../../notification/Notification";
import type {App} from "../../../../App";
import type {PDFListItemMode} from "./PDFListItem";
import {PDFListItem} from "./PDFListItem";
import type {IOnePagePDFDoc} from "./CreateSpacePanel";

interface IPDFListProps {
	readonly selectedVersion: SpaceVersion;
	readonly pdfFiles: {
		originalFiles: File[];
		onePagePDFDocs: IOnePagePDFDoc[][]; // OriginalPDFFiles split by pages. pdfFiles.onePagePDFDocs[3] is an array of PDF documents that is generated by the pages of pdpfFiles.originalFiles[3]
	};
	readonly mode: PDFListItemMode;
	readonly activePDFFile?: IOnePagePDFDoc;
	readonly forceUpdate: () => void;
	readonly onSpaceNameChange?: (originalPDFIndex: number, originalPageNumber: number, newName: string) => void;
	readonly isSpaceNameAvailable?: (spaceName: string) => boolean;
	readonly onRotate?: (originalPDFIndex: number, originalPageNumber: number, deltaAngle: number) => void;
	readonly onDeleteClick?: (originalPDFIndex: number, originalPageNumber: number) => void;
	readonly onSelect?: (originalPDFIndex: number, originalPageNumber: number) => void;
	readonly uploadInfo?: string;
	readonly loadBarWidth?: number;
	readonly finalScreen?: boolean;
	readonly app?: App;
}

@inject("app")
export class PDFList extends React.Component<IPDFListProps> {
	// When the multiple paged pdfs are splitted by pages
	private getPDFList() {
		const array = [];
		let key = 0;

		for (let i = 0; i < this.props.pdfFiles.onePagePDFDocs.length; ++i) {
			const originalPDFFileName = this.props.pdfFiles.originalFiles[i].name;
			const onePagePDFDocs = this.props.pdfFiles.onePagePDFDocs[i];

			for (let j = 0; j < onePagePDFDocs.length; ++j) {
				const onePagePDFDoc = onePagePDFDocs[j];

				key++;

				array.push(
					<PDFListItem
						key={key}
						selectedVersion={this.props.selectedVersion}
						mode={this.props.mode}
						active={onePagePDFDoc === this.props.activePDFFile}
						originalPDFIndex={i}
						originalPageNumber={j}
						originalPDFFileName={originalPDFFileName}
						onePagePDFDoc={onePagePDFDoc}
						onSpaceNameChange={this.props.onSpaceNameChange}
						isSpaceNameAvailable={this.props.isSpaceNameAvailable}
						forceUpdate={this.props.forceUpdate}
						onRotate={this.props.onRotate}
						onDeleteClick={this.props.onDeleteClick}
						onSelect={this.props.onSelect}
					/>,
				);
			}
		}

		return array;
	}

	public override componentDidMount() {
		if (this.props.mode === "EditSpaceNames") {
			this.props.app.graphicalTools.pdfRenderer.setProcessOrder("FIFO");
		}
	}

	public override componentWillUnmount() {
		this.props.app.graphicalTools.pdfRenderer.setProcessOrder("LIFO");
	}

	public override render() {
		const htmlDescription = `
			<ul>
				<li><b>${this.getPDFList().length} Spaces</b> has been successfully created</li>
				<li>Added to version set - <b>${this.props.selectedVersion.name}</b></li>
			</ul>
			`;

		return (
			<div className="vbox PDFList">
				{this.props.mode === "EditSpaceNames" && (
					<div className="hbox">
						<div className="flex1"></div>
						<div className="flex1 darkSilverText center">Space Name</div>
						<div className="flex1 darkSilverText center">Version History</div>
					</div>
				)}
				{this.props.finalScreen ? (
					<Notification
						type={NotificationType.Success}
						title="Success"
						description={htmlDescription}
						lifeTime={Infinity}
						cancelable={false}
					/>
				) : (
					this.props.mode === "Confirm" &&
					this.props.uploadInfo && (
						<div className="vbox flexCenter uploadInfo">
							<div className="hbox progressBar">
								<div
									className="hbox loadingBar"
									style={{width: `${this.props.loadBarWidth || 0}%`}}
								/>
							</div>
							<p>{this.props.uploadInfo}</p>
						</div>
					)
				)}
				{this.getPDFList()}
			</div>
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {IToolTipRow} from "../toolbar/CardLayoutToolTip";
import {CardLayoutToolTip} from "../toolbar/CardLayoutToolTip";
import {ArrayUtils} from "../../../../../../utils/data/array/ArrayUtils";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";
import type {Boundary} from "../../../../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {App} from "../../../../../../App";
import type {AppState} from "../../../../../../data/state/AppState";
import {InfoButton} from "../../../../abstract/common/infobutton/InfoButton";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {Button} from "../../../../../widgets/button/Button";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import {DomPortal} from "../../../../abstract/portal/DomPortal";
import type {TransformObj} from "../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {zIndex} from "../../../../../5.0/styles/styles";

type Scope = "this space" | "other spaces"; // other spaces: on a different space, but still in the same portfolio

interface IMergeBoundariesWindowProps {
	readonly onClose: () => void;

	readonly app?: App;
	readonly appState?: AppState;
}

interface IMergeBoundariesWindowState {
	searchString: string;
	isSearchFocused: boolean;
	parentBoundary: Boundary; // aka. main boundary. Child boundaries will be merged into this one
	childBoundaries: Boundary[];
	isWaitingForResponse: boolean;
	hoveredBoundaryId: string;
	toolTipTransform: TransformObj | null;
}

@inject("app")
@inject("appState")
@observer
export class MergeBoundariesWindow extends React.Component<IMergeBoundariesWindowProps, IMergeBoundariesWindowState> {
	private _searchFieldWrapperRef = React.createRef<HTMLDivElement>();
	private _cardLayoutToolTipRef = React.createRef<HTMLDivElement>();
	private parentRefArray: {[key: string]: HTMLDivElement} = {};
	private _timeoutId: number = null;

	constructor(props: IMergeBoundariesWindowProps) {
		super(props);
		const selectedItems = this.props.app.spaceViewRenderer.boundaryManager.selectedItems;
		const parentBoundary = (selectedItems[0]?.modelData as BoundarySpaceMap).parent;
		// Don't add the same element twice (eg.: if selected boundaries have multiple boundaryspacemaps that belong to the same boundary)
		// [].slice(1) => [], so we don't have to check for special cases in this case
		const childBoundaries = ArrayUtils.removeDuplicates(
			selectedItems
				.slice(1)
				.map((spaceItem) => (spaceItem.modelData as BoundarySpaceMap).parent)
				.filter((boundary: Boundary) => boundary !== parentBoundary),
		);

		this.state = {
			searchString: "",
			isSearchFocused: false,
			parentBoundary: parentBoundary,
			childBoundaries: childBoundaries,
			isWaitingForResponse: false,
			hoveredBoundaryId: "",
			toolTipTransform: null,
		};
	}

	private onSearchChange = (searchString: string) => {
		this.setState({
			searchString: searchString,
		});
	};

	private renderCustomRow(data: IToolTipRow, searchString: string) {
		return (
			data && (
				<div
					className="field"
					dangerouslySetInnerHTML={{__html: `${data.key}${data.key ? ": " : ""}${StringUtils.regexHighlight(data.value, searchString)}`}}
				/>
			)
		);
	}

	private getHighlightedFields(boundary: Boundary, searchString: string) {
		const fields = this.props.appState.actions.getRowsForCardLayout(searchString, boundary);

		return (
			<div className="vbox description">
				<div
					className="field"
					dangerouslySetInnerHTML={{__html: StringUtils.regexHighlight(fields[0].value, searchString)}}
				/>
				{this.renderCustomRow(fields[1], searchString)}
				{this.renderCustomRow(fields[2], searchString)}
			</div>
		);
	}

	private onMouseOver = (refId: string) => {
		clearTimeout(this._timeoutId);

		this._timeoutId = window.setTimeout(() => {
			if (refId !== this.state.hoveredBoundaryId) {
				this.setState({
					hoveredBoundaryId: refId,
				});
			}
		}, 300);
	};

	private onMouseLeaveItem = () => {
		clearTimeout(this._timeoutId);

		if (this.state.hoveredBoundaryId !== "") {
			this.setState({hoveredBoundaryId: ""});
		}
	};

	private renderBoundaryItem(boundary: Boundary, searchString: string) {
		const {hoveredBoundaryId} = this.state;

		const floatingElement = this._cardLayoutToolTipRef.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: `translate(${this.parentRefArray[hoveredBoundaryId]?.getBoundingClientRect().x}px, ${this.state.toolTipTransform?.y - 10}px)`,
			maxWidth: this.parentRefArray[0]?.offsetWidth,
			zIndex: 90000000, // it must be above the MergeBoundaryWindow
		};

		return (
			boundary?.boundarySpaceMaps.size > 0 && (
				<div
					ref={(parentRef) => (this.parentRefArray[boundary.refId] = parentRef)}
					className={ReactUtils.cls("SpaceItem hbox", {active: this.state.childBoundaries.includes(boundary)})}
					onMouseOver={() => this.onMouseOver(boundary.refId)}
					onMouseLeave={this.onMouseLeaveItem}
				>
					<div
						className="thumbnail"
						style={{backgroundImage: `url('${boundary.thumbnail}')`}}
					/>
					{this.getHighlightedFields(boundary, searchString)}
					{hoveredBoundaryId === boundary.refId && (
						<DomPortal destination={this.props.app.modalContainer}>
							<CardLayoutToolTip
								item={boundary}
								divRef={this._cardLayoutToolTipRef}
								style={inlineStyle}
								queryString={searchString}
							/>
						</DomPortal>
					)}
				</div>
			)
		);
	}

	private getBoundarySection(scope: Scope, boundariesInSection: Boundary[]) {
		if (boundariesInSection.length === 0) {
			return false;
		}

		return (
			<div className="vbox">
				<p className="sectionTitle">{`Boundaries in ${scope}`}</p>
				<div className="vbox">
					{boundariesInSection.map((boundary: Boundary, index: number) => {
						return (
							<div
								className="hbox boundaryWrapper"
								key={index}
							>
								{this.renderBoundaryItem(boundary, this.state.searchString)}
								<div
									className="flexCenter addBtn boundaryActionBtn"
									onClick={() => {
										const childBoundaries = [...this.state.childBoundaries];

										if (!childBoundaries.includes(boundary)) {
											childBoundaries.push(boundary);
											this.setState({
												childBoundaries: childBoundaries,
											});
										}
									}}
								>
									Add
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	private getBoundaries() {
		const {searchString} = this.state;

		let boundariesToShow = this.props.appState.actions
			.getList<Boundary>(XyiconFeature.Boundary)
			.filter((boundary: Boundary) => this.state.parentBoundary.id !== boundary.id && !this.state.childBoundaries.includes(boundary));

		boundariesToShow = this.props.appState.actions
			.searchModels(boundariesToShow, searchString, XyiconFeature.Boundary)
			.sort((a: Boundary, b: Boundary) => StringUtils.sortIgnoreCase(a.refId, b.refId));

		// Split to 2 parts
		const boundariesInSameSpace = boundariesToShow.filter((boundary: Boundary) => boundary.spaceId === this.props.appState.space?.id);
		const boundariesInOtherSpaces = boundariesToShow.filter((boundary: Boundary) => boundary.spaceId !== this.props.appState.space?.id);

		return (
			boundariesInSameSpace.length + boundariesInOtherSpaces.length > 0 && (
				<div className="vbox">
					{this.getBoundarySection("this space", boundariesInSameSpace)}
					{this.getBoundarySection("other spaces", boundariesInOtherSpaces)}
				</div>
			)
		);
	}

	private onConfirmClick = async () => {
		this.setState({
			isWaitingForResponse: true,
		});

		const {parentBoundary, childBoundaries} = this.state;

		await this.props.appState.actions.mergeBoundaries(parentBoundary, childBoundaries);

		this.props.onClose();
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.props.onClose();
				break;
		}
	};

	private onSearchFocus = () => {
		this.setState({
			isSearchFocused: true,
		});
		FocusLoss.listen(this._searchFieldWrapperRef.current, this.onSearchBlur);
	};

	private onSearchBlur = () => {
		this.setState({
			isSearchFocused: false,
		});
		FocusLoss.stopListen(this._searchFieldWrapperRef.current, this.onSearchBlur);
	};

	private demoteParentBoundary = () => {
		if (this.state.childBoundaries.length > 0) {
			const childBoundaries = [...this.state.childBoundaries];
			const firstChild = childBoundaries.shift();

			childBoundaries.unshift(this.state.parentBoundary);

			this.setState({
				parentBoundary: firstChild,
				childBoundaries: childBoundaries,
			});
		}
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentDidUpdate(prevProps: IMergeBoundariesWindowProps, prevState: IMergeBoundariesWindowState) {
		if (
			prevState.hoveredBoundaryId === "" &&
			this.state.hoveredBoundaryId !== "" &&
			this.parentRefArray[this.state.hoveredBoundaryId] &&
			this._cardLayoutToolTipRef.current
		) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this.parentRefArray[this.state.hoveredBoundaryId],
					this._cardLayoutToolTipRef.current,
					VerticalAlignment.top,
					HorizontalAlignment.center,
				),
			});
		}
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		FocusLoss.stopListen(this._searchFieldWrapperRef.current, this.onSearchBlur);
	}

	public override render() {
		const inlineStyle: React.CSSProperties = this._searchFieldWrapperRef.current && {
			maxHeight: window.innerHeight - this._searchFieldWrapperRef.current.getBoundingClientRect().bottom - 10,
		};

		return (
			<div className="popupBackdrop flexCenter">
				<div className="MergeBoundariesWindow window vbox">
					<div className="header">
						<div className="title">Merging Boundaries</div>
						<IconButton
							className="closeBtn"
							icon="close"
							onClick={this.props.onClose}
							title="Close Window"
						/>
					</div>
					<div className="vbox">
						<div className="sectionTitle hbox">
							Main boundary
							<InfoButton
								bubbleText="This is the boundary to which the boundaries selected below will be merged."
								className="onPopup"
								style={{zIndex: zIndex.popup + 1}}
							/>
						</div>
						<div className="boundaryContainer vbox">
							<div className="hbox boundaryWrapper">
								{this.renderBoundaryItem(this.state.parentBoundary, "")}
								<IconButton
									icon="promote"
									title="Demote"
									className="demoteBtn boundaryActionBtn"
									disabled={this.state.childBoundaries.length === 0}
									onClick={this.demoteParentBoundary}
								/>
							</div>
						</div>
					</div>
					<div className="vbox">
						<div className="sectionTitle hbox">
							Select boundaries to merge
							<InfoButton
								bubbleText="Select the boundaries you want to merge to the Main Boundary. Once merged, the boundaries below will receive the Main Boundary's ID and fields."
								className="onPopup"
								style={{zIndex: zIndex.popup + 1}}
							/>
						</div>
						<div
							className="vbox searchWrapper"
							ref={this._searchFieldWrapperRef}
							onClick={this.onSearchFocus}
						>
							<SearchField
								className="findInput"
								value={this.state.searchString}
								onInput={this.onSearchChange}
							/>
							{this.state.isSearchFocused && (
								<div
									className="boundaryContainer vbox"
									style={inlineStyle}
									data-cy="mergeBoundaries"
								>
									{this.getBoundaries()}
								</div>
							)}
						</div>
						<div className="boundaryContainer vbox">
							{this.state.childBoundaries.map((boundary: Boundary, index: number) => {
								return (
									<div
										className="hbox boundaryWrapper"
										key={index}
									>
										{this.renderBoundaryItem(boundary, "")}
										<div className="hbox buttonContainer">
											<IconButton
												icon="promote"
												title="Promote"
												onClick={() => {
													const childBoundaries = [...this.state.childBoundaries];
													const indexOfBoundary = childBoundaries.indexOf(boundary);

													if (indexOfBoundary > -1) {
														childBoundaries.splice(indexOfBoundary, 1, this.state.parentBoundary);
														childBoundaries;
														this.setState({
															childBoundaries: childBoundaries,
															parentBoundary: boundary,
														});
													}
												}}
											/>
											<IconButton
												className="closeBtn"
												icon="close"
												title="Remove From List"
												onClick={() => {
													const childBoundaries = [...this.state.childBoundaries];
													const indexOfBoundary = childBoundaries.indexOf(boundary);

													if (indexOfBoundary > -1) {
														childBoundaries.splice(indexOfBoundary, 1);
														this.setState({
															childBoundaries: childBoundaries,
														});
													}
												}}
											/>
										</div>
									</div>
								);
							})}
						</div>
						<div className="mainButtonWrapper vbox">
							<div className="mainButtonContainer hbox">
								<Button
									label="Cancel"
									onClick={this.props.onClose}
								/>
								<Button
									className="primary"
									label={this.state.isWaitingForResponse ? "Merging Boundaries" : "Merge Boundaries"}
									disabled={!this.state.parentBoundary || this.state.childBoundaries.length === 0 || this.state.isWaitingForResponse}
									onClick={this.onConfirmClick}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {XyiconFeature} from "../../../../../../../../generated/api/base";
import {ReportScope} from "../../../../../../../../generated/api/reports";
import type {Report} from "../../../../../../../../data/models/Report";
import {ClickToEditInputV5} from "../../../../../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputV5} from "../../../../../../input/select/SelectInputV5";
import {FieldV5} from "../../../../../../details/FieldV5";
import {TextInputV5} from "../../../../../../details/datatypes/TextInputV5";
import {ReportScopeWidgetV5} from "../../../../../../details/ReportScopeWidgetV5";

interface IReportDetailsProps {
	readonly report: Report;
	readonly onChange?: () => void;
}

interface IModuleOption {
	id: XyiconFeature;
	label: string;
}

@observer
export class ReportDetailsV5 extends React.Component<IReportDetailsProps> {
	public static isValid(report: Report) {
		if (!report.name) {
			return false;
		}
		if (!(report.reportFeature == XyiconFeature.Xyicon || report.reportFeature == XyiconFeature.Boundary)) {
			return false;
		}
		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList.length === 0) {
			return false;
		}
		return true;
	}

	private _moduleOptions: IModuleOption[] = [
		{
			id: XyiconFeature.Xyicon,
			label: "Xyicon",
		},
		// {
		// 	id: XyiconFeature.XyiconCatalog,
		// 	label: "Catalog",
		// },
		{
			id: XyiconFeature.Boundary,
			label: "Boundary",
		},
	];

	private onChangeModule = (option: IModuleOption) => {
		if (
			(this.props.report.reportFeature == XyiconFeature.Xyicon || this.props.report.reportFeature == XyiconFeature.Boundary) &&
			option.id != this.props.report.reportFeature
		) {
			this.props.report.reportFeature = option.id;
			this.props.report.displayedFields = [];
			this.props.report.sortFields = [];
			this.props.report.portfolioIDList = [];
			this.props.report.name = "";
			this.props.report.description = "";
			this.props.report.scope = ReportScope.CurrentPortfolio;
			this.props.report.summarizeResults = false;
			this.props.report.type = null;
			this.props.report.displayedLinkedFields = [];
			this.props.report.filters.length = 0;
		}

		this.props.report.reportFeature = option.id;
		this.props.onChange?.();
	};

	private onChangeDescription = (value: string) => {
		this.props.report.description = value;
	};

	public override render() {
		const {report} = this.props;
		const isEditing = !!report.id;

		return (
			<div className="ReportDetails">
				<FieldV5 label="Report Name">
					<ClickToEditInputV5
						value={report.name}
						onChange={(value) => (report.name = value)}
						onLiveChange={(value) => (report.name = value)}
						placeholder={undefined}
					/>
				</FieldV5>
				<FieldV5
					label="Module"
					className="text"
				>
					<SelectInputV5
						sort={false}
						options={this._moduleOptions}
						render={(option) => option.label}
						selected={this._moduleOptions.find((option) => option.id === report.reportFeature)}
						onChange={this.onChangeModule}
						isSameWidth={true}
						disabled={isEditing}
					/>
				</FieldV5>
				<ReportScopeWidgetV5 report={report} />
				<FieldV5 label="Description">
					<TextInputV5
						value={report.description}
						onChange={this.onChangeDescription}
						isTextArea={true}
					/>
				</FieldV5>
			</div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import type {Color} from "../../../generated/api/base";
import {ColorUtils} from "../../../utils/ColorUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import {zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import CloseIcon from "../icons/xmark.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {BasicColorPanelV5} from "./BasicColorPanelV5";
import {AdvancedColorPanelV5} from "./AdvancedColorPanelV5";
import type {IHSLColor} from "./AdvancedColorPanelV5";
import type {IPropsForEyeDropper} from "./EyeDropperV5";

export type ColorWindowMode = "basic" | "advanced";

interface ColorWindowProps {
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly colorHSL: IHSLColor;
	readonly onColorChange: (newColor: IHSLColor) => void;
	readonly onCloseClick: () => void;
	readonly activeMode: ColorWindowMode;
	readonly setActiveMode: (mode: ColorWindowMode) => void;
	readonly isTransparencyEnabled: boolean;
	readonly eyeDropperProps?: IPropsForEyeDropper;
}

export const ColorWindowV5 = (props: ColorWindowProps) => {
	const {activeMode, setActiveMode} = props;

	const switchToBasicMode = () => {
		setActiveMode("basic");
	};

	const switchToAdvancedMode = () => {
		setActiveMode("advanced");
	};

	const onHexColorChange = (newColor: Color) => {
		const hsl = ColorUtils.hex2hsl(newColor.hex);

		props.onColorChange({
			hue: hsl.h,
			saturation: hsl.s,
			lightness: hsl.l,
			transparency: newColor.transparency,
		});

		props.onCloseClick();
	};

	const colorHSL = props.colorHSL;
	const hex = ColorUtils.hsl2hex(colorHSL.hue, colorHSL.saturation, colorHSL.lightness) as string;
	const colorHex = {
		hex: hex,
		transparency: colorHSL.transparency,
	};

	return (
		<ColorWindowStyled
			onMouseDown={Functions.stopPropagation} // to prevent Popups from closing because of "useClickOutSide", eg.: Create Panels with colorselector
			className={ReactUtils.cls("ColorWindow", {overflow: window.innerHeight < 600})}
			ref={props.divRef}
		>
			<Header className="header">
				Choose Color
				<CloseIcon onClick={props.onCloseClick} />
			</Header>
			{activeMode === "basic" && (
				<BasicColorPanelV5
					onColorChange={onHexColorChange}
					color={colorHex}
				/>
			)}
			{activeMode === "advanced" && (
				<AdvancedColorPanelV5
					onColorChange={props.onColorChange}
					color={props.colorHSL}
					isTransparencyEnabled={props.isTransparencyEnabled}
					eyeDropperProps={props.eyeDropperProps}
				/>
			)}
			{activeMode === "basic" ? (
				<ModeSwitcher onClick={switchToAdvancedMode}>Advanced</ModeSwitcher>
			) : (
				<ModeSwitcher onClick={switchToBasicMode}>Basic</ModeSwitcher>
			)}
		</ColorWindowStyled>
	);
};

const Header = styled.div`
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-weight: 700;
	svg {
		cursor: pointer;
	}
`;

const ModeSwitcher = styled.div`
	width: fit-content;
	margin-top: 10px;
	cursor: pointer;
	font-size: 12px;
	line-height: 16px;
	color: ${colorPalette.primary.c500Primary};
	text-decoration: underline;
`;

const ColorWindowStyled = styled.div`
	position: absolute;
	z-index: ${zIndex.popup};
	padding: 8px;
	border-radius: 8px;
	cursor: auto;
	background-color: var(--subMenuItemActiveBG);
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);

	&.overflow {
		height: 300px;
		overflow: auto;
	}
`;

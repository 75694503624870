import * as React from "react";
import styled from "styled-components";
import {ToggleSwitchFieldStyled, ToggleSwitchFieldV5} from "./ToggleSwitchFieldV5";

const SwitchListStyled = styled.div`
	padding-left: 8px;

	.SwitchList {
		display: flex;
		flex-direction: column;
		margin-bottom: 8px;

		.ToggleSwitchField .label {
			width: 150px;
		}

		${ToggleSwitchFieldStyled} {
			label {
				width: 216px;
			}
		}
	}
`;

interface ISwitchListProps {
	readonly list: {
		id: string;
		label: string;
		value: boolean;
		disabled?: boolean;
	}[];
	readonly onChange: (changes: ISwitchListChange[]) => void;
}

export interface ISwitchListChange {
	id: string;
	value: boolean;
}

export class SwitchListV5 extends React.PureComponent<ISwitchListProps> {
	private onChangeAll = (value: boolean) => {
		const changes = this.props.list
			.filter((item) => !item.disabled)
			.map((item) => ({
				id: item.id,
				value: value,
			}));

		this.props.onChange(changes);
	};

	private getAllValue() {
		const {list} = this.props;

		if (list.every((item) => item.disabled)) {
			return false;
		}

		return !list.filter((item) => !item.disabled).find((item) => !item.value);
	}

	public override render() {
		const {list, onChange} = this.props;

		return (
			<SwitchListStyled>
				<div className="SwitchList">
					<ToggleSwitchFieldV5
						label="All"
						value={this.getAllValue()}
						onChange={this.onChangeAll}
						disabled={list.every((item) => item.disabled)}
						labelFirst={true}
					/>
					{list.map((item, index) => (
						<ToggleSwitchFieldV5
							key={index}
							labelFirst={true}
							label={item.label}
							value={item.value}
							disabled={item.disabled}
							onChange={(value) => {
								onChange([{id: item.id, value: value}]);
							}}
						/>
					))}
				</div>
			</SwitchListStyled>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {SpaceEditorSettings} from "./SpaceEditorSettings";
import {NavigationBox} from "./NavigationBox";

interface ISpaceControlBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly navigationBoxImgSrc: string;
	readonly onRealignSpaceClick: () => void;
}

interface ISpaceControlBarState {
	isNavigationBoxOpen: boolean;
	isSettingsOpen: boolean;
}

@observer
export class SpaceControlBar extends React.Component<ISpaceControlBarProps, ISpaceControlBarState> {
	constructor(props: ISpaceControlBarProps) {
		super(props);

		this.state = {
			isNavigationBoxOpen: false,
			isSettingsOpen: false,
		};
	}

	private onNavigationIconClick = () => {
		const newValue = !this.state.isNavigationBoxOpen;

		this.setState({
			isNavigationBoxOpen: newValue,
			isSettingsOpen: false,
		});

		this.saveNavigationStateToLocalStorage(newValue);
	};

	private onMeasureToolIconClick = () => {
		this.props.spaceViewRenderer.isMeasureToolBarOpen = !this.props.spaceViewRenderer.isMeasureToolBarOpen;

		if (this.props.spaceViewRenderer.isMeasureToolBarOpen) {
			this.props.spaceViewRenderer.inheritedMethods.setActiveTool("tempMeasureLinearDistance");
		}
	};

	private onGearIconClick = () => {
		this.setState({
			isSettingsOpen: !this.state.isSettingsOpen,
			isNavigationBoxOpen: false,
		});
	};

	private getKeyForLocalStorage() {
		return "srv4-navigationbox-open";
	}

	private saveNavigationStateToLocalStorage(value: boolean) {
		this.props.spaceViewRenderer.transport.services.localStorage.set(this.getKeyForLocalStorage(), value);
	}

	private getNavigationStateFromLocalStorage() {
		const originalValue = this.props.spaceViewRenderer.transport.services.localStorage.get(this.getKeyForLocalStorage());

		if (ObjectUtils.isNullish(originalValue)) {
			return true;
		} else {
			return originalValue;
		}
	}

	private onSettingsClose = () => {
		this.setState({
			isSettingsOpen: false,
		});
	};

	private onChangeToOrthographic = () => {
		this.props.spaceViewRenderer.toolManager.cameraControls.changeToOrthographic();
	};

	private onChangeToPerspective = () => {
		this.props.spaceViewRenderer.toolManager.cameraControls.changeToPerspective();
	};

	public override componentDidMount() {
		const loadedValue = this.getNavigationStateFromLocalStorage();

		if (this.state.isNavigationBoxOpen !== loadedValue) {
			this.setState({
				isNavigationBoxOpen: loadedValue,
			});
		}
	}

	public override render() {
		const {spaceViewRenderer, navigationBoxImgSrc, onRealignSpaceClick} = this.props;
		const {isSettingsOpen, isNavigationBoxOpen} = this.state;
		const {isMeasureToolBarOpen} = this.props.spaceViewRenderer;

		return (
			<div className="SpaceControlBar hbox">
				<div
					className={ReactUtils.cls("cameraChanger perspective flexCenter", {
						selected: spaceViewRenderer.activeCameraType === "OrthographicCamera",
					})}
					onClick={this.onChangeToOrthographic}
				>
					2D
				</div>
				<div
					className={ReactUtils.cls("cameraChanger orthographic flexCenter", {
						selected: spaceViewRenderer.activeCameraType === "PerspectiveCamera",
					})}
					onClick={this.onChangeToPerspective}
				>
					3D
				</div>
				<IconButton
					icon="pinMap"
					title="Toggle Map"
					className={ReactUtils.cls({enabled: isNavigationBoxOpen})}
					onClick={this.onNavigationIconClick}
				/>
				<IconButton
					icon="ruler"
					title="Measure..."
					className={ReactUtils.cls({enabled: isMeasureToolBarOpen})}
					onClick={this.onMeasureToolIconClick}
				/>
				<IconButton
					icon="gear"
					title="Settings..."
					className={ReactUtils.cls({enabled: isSettingsOpen})}
					onClick={this.onGearIconClick}
				/>
				{isNavigationBoxOpen && (
					<NavigationBox
						spaceViewRenderer={spaceViewRenderer}
						navigationBoxImgSrc={navigationBoxImgSrc}
						onCloseClick={this.onNavigationIconClick}
					/>
				)}
				{isSettingsOpen && (
					<SpaceEditorSettings
						spaceViewRenderer={spaceViewRenderer}
						onRealignSpaceClick={onRealignSpaceClick}
						onClose={this.onSettingsClose}
						closeOnBlur={true}
					/>
				)}
			</div>
		);
	}
}

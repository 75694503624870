import styled, {css} from "styled-components";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {LoaderV5} from "../loader/LoaderV5";
import type {DocumentSectionItem} from "./DocumentSectionV5";
import type {TDocumentItemType} from "./DocumentItemV5";
import {DocumentItemV5} from "./DocumentItemV5";

interface IDocumentViewProps {
	readonly documentItems: DocumentSectionItem[];
	readonly uploadingArraySize: number;
	readonly type: TDocumentItemType;
	readonly getOptions: (document: DocumentSectionItem) => IDropdownOption[];
}

export const DocumentViewV5 = (props: IDocumentViewProps) => {
	const {documentItems, getOptions, uploadingArraySize} = props;
	const isDocumentListEmpty = documentItems.length === 0;
	const loaderArray: React.JSX.Element[] = [];

	for (let i = 0; i < uploadingArraySize; i++) {
		loaderArray.push(<LoaderV5 label="Uploading Document..." />);
	}

	return (
		<>
			{isDocumentListEmpty && loaderArray.length === 0 ? (
				<span>No Documents...</span>
			) : (
				<DocumentViewStyled
					$empty={isDocumentListEmpty}
					$type={props.type}
				>
					{documentItems.map((documentItem) => {
						const document = documentItem.document;

						return (
							<DocumentItemV5
								key={document.id}
								documentItem={documentItem}
								getOptions={getOptions}
								type={props.type}
							/>
						);
					})}
					{loaderArray}
				</DocumentViewStyled>
			)}
		</>
	);
};

const DocumentViewStyled = styled.div<{$empty: boolean; $type: TDocumentItemType}>`
	display: grid;
	${(props) => {
		if (props.$type === "tile") {
			return css`
				grid-template-columns: repeat(auto-fit, 144px);
				grid-auto-rows: 144px;
				gap: 16px;
				row-gap: 0;
			`;
		} else {
			return css`
				grid-auto-rows: auto;
				gap: 8px;
			`;
		}
	}};
`;

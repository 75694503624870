import {css, styled} from "styled-components";
import {useAppStore} from "../../StateManager";
import {colorPalette} from "./styles/colorPalette";
import {FlexCenterStyle} from "./styles/styles";

const MainStyled = styled.main<{$blured: boolean}>`
	${FlexCenterStyle}
	align-items: normal;
	background-color: ${colorPalette.white};
	flex: 1;
	min-height: 0;

	${(props) => {
		if (props.$blured) {
			return css`
				&::after {
					content: "";
					background: #00000082;
					position: absolute;
					width: 100%;
					height: 100%;
					z-index: 10;
				}
			`;
		}
	}}

	.sidePanelContainer {
		background-color: ${colorPalette.white};
		z-index: 1;
		min-height: 0;
		overflow-x: auto;
	}

	.Splitter {
		height: min-content;
		overflow: hidden;
		flex: 1;
		min-height: 0;

		.dragger {
			background-color: ${colorPalette.gray.c200Light};
			z-index: 2;
			position: relative;
			cursor: col-resize;

			.handler {
				background-color: ${colorPalette.blueGray.c200Light};
				color: ${colorPalette.blueGray.c500Primary};
				position: absolute;
				transform: translateY(-50%);
				top: 50%;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				cursor: pointer;
			}
		}
	}

	.SidePanel {
		.buttons {
			display: none;
		}
	}
`;

type TMain = {
	readonly view: React.JSX.Element;
};

export const Main = (props: TMain) => {
	const store = useAppStore();
	const {overlayedDetailedItems} = store.appState.app.state;

	const onClick = () => {
		if (overlayedDetailedItems.length > 0) {
			store.appState.app.setState({overlayedDetailedItems: []});
		}
	};

	return (
		<MainStyled
			className="appContent"
			$blured={store.appState.app.state.overlayedDetailedItems.length > 0}
			onClick={onClick}
		>
			{props.view}
		</MainStyled>
	);
};

import styled from "styled-components";
import {baseDistance, fontSize} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";

export const AdvancedFilterEditorStyled = styled.div`
	max-height: 528px;
	overflow-y: auto;

	&.AdvancedFilterEditor {
		.SelectInput {
			min-width: auto;
			height: 32px;
			border: none;
			background-color: ${colorPalette.white};

			.input {
				height: 32px;
			}
		}

		.btn-container {
			padding: ${baseDistance.sm};
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.clear-all {
				font-size: ${fontSize.md};
				color: ${colorPalette.gray.c400};
				cursor: pointer;
				padding-top: ${baseDistance.sm};
			}
		}
	}
`;

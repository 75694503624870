import type {CSSProperties} from "react";
import React, {useRef, useState} from "react";
import type {Link} from "../../../../../../data/models/Link";
import {XyiconFeature} from "../../../../../../generated/api/base";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {InfoBubble} from "../../../../abstract/common/infobutton/InfoBubble";
import {DomPortal} from "../../../../abstract/portal/DomPortal";
import {SpaceItemActionButtons} from "../toolbar/SpaceItemActionButtons";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {ContextOptions} from "../../../../../widgets/context/ContextOptions";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import {ConfirmWindow} from "../../../../abstract/popups/ConfirmWindow";
import {useClickOutside} from "../../../../../5.0/utils";
import {LinkBreakers} from "./LinkBreakers";

export interface ICrossPortfolioLinkData {
	link: Link;
	crossPortfolioXyiconId: string;
	otherPortfolioId: string;
	otherPortId: string;
	onePortId: string;
}

interface ICrossPortfolioXyiconProps {
	readonly transport: TransportLayer;
	readonly linkData: ICrossPortfolioLinkData;
	readonly showIconOnly?: boolean;
	readonly showBreakLinkButton: boolean;
	readonly showDeleteButton: boolean;
	readonly isOption?: boolean;
}

export const CrossPortfolioXyicon = (props: ICrossPortfolioXyiconProps) => {
	const transport = props.transport;
	const {linkData, showIconOnly, showBreakLinkButton, showDeleteButton, isOption} = props;
	const parentRef = useRef<HTMLDivElement>();
	const infoRef = useRef<HTMLDivElement>();
	const contextMenuRef = useRef<HTMLDivElement>();
	const [isInfoBubbleVisible, setIsInfoBubbleVisible] = useState<boolean>(false);
	const [isContextMenuOpen, setIsContextMenuOpen] = useState<boolean>(false);

	useClickOutside([parentRef, contextMenuRef], () => {
		setIsContextMenuOpen(false);
	});

	const onContextMenu = (event: React.MouseEvent) => {
		if (showIconOnly) {
			event.preventDefault();
			setIsContextMenuOpen(true);
		}
	};

	const infoText = `UUID: ${linkData.crossPortfolioXyiconId}`;

	const transformForInfoRef =
		parentRef.current &&
		infoRef.current &&
		DomUtils.getFixedFloatingElementPosition(parentRef.current, infoRef.current, VerticalAlignment.topOuter, HorizontalAlignment.center);

	const transformForContextMenuRef =
		parentRef.current &&
		contextMenuRef.current &&
		DomUtils.getFixedFloatingElementPosition(parentRef.current, contextMenuRef.current, VerticalAlignment.bottomOuter, HorizontalAlignment.center);

	const inlineStyleForInfoText: CSSProperties = {};

	if (transformForInfoRef) {
		inlineStyleForInfoText.transform = transformForInfoRef.translate;
		inlineStyleForInfoText.left = "0";
	}

	inlineStyleForInfoText.visibility = isInfoBubbleVisible ? "visible" : "hidden";

	const inlineStyleForContextMenu: CSSProperties = {};

	if (transformForContextMenuRef) {
		inlineStyleForContextMenu.transform = transformForContextMenuRef.translate;
		inlineStyleForContextMenu.left = "0";
	}

	inlineStyleForContextMenu.visibility = isContextMenuOpen ? "visible" : "hidden";

	const onBreakLinkClickMaybe = showBreakLinkButton ? () => LinkBreakers.breakLinks(transport, [linkData.link.id], true) : undefined;
	const onDeleteClickMaybe = showDeleteButton
		? async () => {
				const isConfirmed = await ConfirmWindow.open("Are you sure you want to delete the selected 1 item?", "Please confirm");

				if (isConfirmed) {
					return transport.requestForOrganization({
						url: "xyicons/delete?v=2.0",
						method: XHRLoader.METHOD_DELETE,
						params: {
							portfolioID: linkData.otherPortfolioId,
							xyiconIDList: [linkData.crossPortfolioXyiconId],
						},
					});
				}
			}
		: undefined;

	return (
		<div
			className={ReactUtils.cls("CrossPortfolioXyicon DraggableXyiconCatalogItem", {showIconOnly})}
			onMouseEnter={() => setIsInfoBubbleVisible(true)}
			onMouseLeave={() => setIsInfoBubbleVisible(false)}
			onContextMenu={onContextMenu}
		>
			<div
				className="SpaceItemContainer hbox"
				ref={parentRef}
			>
				<div className={ReactUtils.cls("SpaceItem hbox alignCenter", {showIconOnly})}>
					<div className="thumbnailContainer flexCenter">
						<SVGIcon
							icon="xyicons"
							style={{width: "28px", height: "28px", fill: "#9E9E9E"}}
						/>
					</div>
					{!showIconOnly && <div className="guid">{linkData.crossPortfolioXyiconId}</div>}
				</div>
				{!showIconOnly && (
					<SpaceItemActionButtons
						appState={transport.appState}
						feature={XyiconFeature.Xyicon}
						isCrossPortfolioXyicon={true}
						isFavorite={false}
						itemTypeId=""
						showDeleteButton={!isOption && showDeleteButton}
						showInfoButton={false}
						deleteItem={onDeleteClickMaybe}
						onBreakLinkClick={!isOption && onBreakLinkClickMaybe}
						options={
							isOption && [
								{
									label: "Break Link",
									onSelect: onBreakLinkClickMaybe,
								},
								{
									label: "Delete",
									onSelect: onDeleteClickMaybe,
								},
							]
						}
					/>
				)}
				{
					<DomPortal destination={transport.appState.app.modalContainer}>
						<InfoBubble
							content={infoText}
							style={inlineStyleForInfoText}
							divRef={infoRef}
						/>
					</DomPortal>
				}
				{
					<DomPortal destination={transport.appState.app.modalContainer}>
						<ContextOptions
							options={[
								{
									label: "Break Link",
									onSelect: onBreakLinkClickMaybe,
								},
								{
									label: "Delete",
									onSelect: onDeleteClickMaybe,
								},
							]}
							onSelect={() => setIsContextMenuOpen(false)}
							style={inlineStyleForContextMenu}
							divRef={contextMenuRef}
						/>
					</DomPortal>
				}
			</div>
		</div>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Report} from "../../../../../../data/models/Report";
import {ReportScope} from "../../../../../../generated/api/reports";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {AppState} from "../../../../../../data/state/AppState";
import {NotificationType} from "../../../../../notification/Notification";
import {notify} from "../../../../../../utils/Notify";
import {ButtonV5} from "../../../../button/ButtonV5";
import {StepIndicatorV5} from "../../../../abstract/view/StepIndicatorV5";
import {ReportColumnsV5} from "./pages/columns/ReportColumnsV5";
import {ReportDetailsV5} from "./pages/details/ReportDetailsV5";
import {ReportSortOrderV5} from "./pages/sortorder/ReportSortOrderV5";
import {ReportDisplayNamesV5} from "./pages/displaynames/ReportDisplayNamesV5";
import {ButtonContainerStyled, ReportWizardStyled} from "./ReportWizardV5.style";
import {ReportFilterV5} from "./pages/filters/ReportFilterV5";

interface IReportWizardV5Props {
	readonly report: Report;
	readonly onClose: (reportId?: string) => void;
	readonly onRunReport: (report: Report) => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IReportWizardV5State {
	stepIndex: number;
}

interface IReportWizardPage {
	title: string;
	stepTitle: string;
	component: React.ComponentClass<{report: Report}> & {isValid?(report: Report): boolean};
}

@inject("appState")
@inject("transport")
@observer
export class ReportWizardV5 extends React.Component<IReportWizardV5Props, IReportWizardV5State> {
	private _pages: IReportWizardPage[] = [
		{
			title: "Enter report details",
			stepTitle: "Enter details",
			component: ReportDetailsV5,
		},
		{
			title: "Select columns to be included",
			stepTitle: "Select columns",
			component: ReportColumnsV5,
		},
		{
			title: "Sort your columns",
			stepTitle: "Set sort order",
			component: ReportSortOrderV5,
		},
		{
			title: "Filter your data",
			stepTitle: "Apply filters",
			component: ReportFilterV5,
		},
		{
			title: "Customize column names",
			stepTitle: "Customize headings",
			component: ReportDisplayNamesV5,
		},
	];

	constructor(props: IReportWizardV5Props) {
		super(props);
		this.state = {
			stepIndex: 0,
		};
	}

	private onBackClick = () => {
		const {stepIndex} = this.state;

		if (stepIndex > 0) {
			this.setState({
				stepIndex: stepIndex - 1,
			});
		} else {
			this.props.report.cancelEditing();
			this.props.onClose();
		}
	};

	private onNextClick = () => {
		const {stepIndex} = this.state;

		this.setState({
			stepIndex: stepIndex + 1,
		});
	};

	private onSaveClick = async () => {
		if (!this.validateReport()) {
			return;
		}

		const {report, transport} = this.props;

		if (report.id) {
			await transport.services.feature.updateReport(report);
			this.props.onClose(report.id);
		} else {
			const reports = await transport.services.feature.create(report.serializeData(), XyiconFeature.Report);

			this.props.onClose(reports?.[0]?.id);
		}
	};

	private onSaveAndRunClick = async () => {
		if (!this.validateReport()) {
			return;
		}

		const {report, onClose, onRunReport, transport} = this.props;

		if (report.id) {
			await transport.services.feature.updateReport(report);
			onClose(report.id);
			onRunReport(report);
		} else {
			const reports = await transport.services.feature.create<Report>(report.serializeData(), XyiconFeature.Report);
			const firstReport = reports[0];

			if (firstReport) {
				onClose(firstReport.id);
				onRunReport(firstReport);
			}
		}
	};

	private validateReport() {
		const {report} = this.props;
		let errorMessage = "";

		if (!report.name) {
			errorMessage = "Please specify a name for your report!";
		}
		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList?.length < 1) {
			errorMessage = "Portfolio list cannot be empty!";
		}

		if (errorMessage) {
			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Error,
				title: "Warning",
				description: errorMessage,
				lifeTime: Infinity,
			});
		}

		return !errorMessage;
	}

	private onSelectStep = (index: number) => {
		const {report} = this.props;
		const {stepIndex} = this.state;

		if (stepIndex === 0 && !this._pages[stepIndex].component.isValid(report)) {
			return;
		}

		this.setState({stepIndex: index});
	};

	public override render() {
		const {report} = this.props;
		const {stepIndex} = this.state;

		const page = this._pages[stepIndex];
		const Component = page.component;
		const isValid = Component.isValid ? Component.isValid(report) : true;

		return (
			<ReportWizardStyled className="ReportWizard">
				<StepIndicatorV5
					steps={["Enter details", "Select columns", "Set sort order", "Apply filters", "Customize headings"]}
					currentStepIndex={stepIndex}
					onStepClick={this.onSelectStep}
				/>
				<div className="reportPage">
					<Component report={report} />
				</div>

				<ButtonContainerStyled>
					{stepIndex > 0 && (
						<ButtonV5
							className="secondary"
							type="secondary"
							label={"Back"}
							title={"Back"}
							onClick={this.onBackClick}
						/>
					)}
					{stepIndex < this._pages.length - 1 && (
						<ButtonV5
							className="secondary"
							type="secondary"
							label="Next"
							title="Next"
							onClick={this.onNextClick}
							disabled={!isValid}
						/>
					)}
					<ButtonV5
						className="primary"
						label="Save"
						title="Save"
						onClick={this.onSaveClick}
						disabled={!isValid}
					/>
					<ButtonV5
						className="primary"
						label="Save & Run"
						title="Save & Run"
						onClick={this.onSaveAndRunClick}
						disabled={!isValid}
					/>
				</ButtonContainerStyled>
			</ReportWizardStyled>
		);
	}
}

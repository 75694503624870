import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Navigation} from "../../Navigation";
import {IconButton} from "../widgets/button/IconButton";
import type {AppState} from "../../data/state/AppState";
import {ReactUtils} from "../utils/ReactUtils";
import type {TransportLayer} from "../../data/TransportLayer";
import {InfoBubble} from "../modules/abstract/common/infobutton/InfoBubble";
import {SideBarNavItem} from "./SideBarNavItem";
import {OrganizationSwitch} from "./organizationswitch/OrganizationSwitch";

interface ISideBarProps {
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly open: boolean;
	readonly onToggleNavigation?: () => void;
}

interface ISideBarState {
	isOrganizationSwitchOpen?: boolean;
	infoBubble: boolean;
}

@inject("navigation")
@inject("appState")
@inject("transport")
@observer
export class SideBar extends React.Component<ISideBarProps, ISideBarState> {
	constructor(props: ISideBarProps) {
		super(props);
		this.state = {
			isOrganizationSwitchOpen: false,
			infoBubble: false,
		};
	}

	public static get activeNav() {
		const hashArray = location.hash.split("/");

		if (hashArray.length > 1) {
			return hashArray[1]; // eg.: #app/space/9be6b0f4-57ec-455a-8318-11efeeba1051 -> space
		} else {
			return hashArray.pop() || "";
		}
	}

	private toggleOrganizationSwitch = () => {
		this.setState((prevState) => ({isOrganizationSwitchOpen: !prevState.isOrganizationSwitchOpen}));
	};

	public override componentDidUpdate(prevProps: Readonly<ISideBarProps>, prevState: Readonly<ISideBarState>, snapshot?: any): void {
		if (!this.props.open && prevProps.open) {
			// SideBar closed -> switch back to menu
			this.setState({isOrganizationSwitchOpen: false});
		}
	}

	public override render() {
		const {divRef, open, navigation, onToggleNavigation, appState} = this.props;
		const {infoBubble} = this.state;

		const activeNav = SideBar.activeNav;
		const orgName = appState.actions.getCurrentOrganizationName();

		const orgLogoUrl = this.props.transport.getCurrentOrganizationLogo();

		return (
			<div
				ref={divRef}
				className={ReactUtils.cls("SideBar", {open: open})}
			>
				<div className="navContainer">
					<nav className="modules">
						<ul data-cy="NavBarWrapper">
							{navigation.menus
								.filter((menu) => menu.component && (!menu.admin || appState.user?.isAdmin))
								.map((menu, index) => (
									<SideBarNavItem
										key={index}
										feature={menu.feature}
										menuNav={menu.nav}
										menuIcon={menu.icon}
										menuLabel={menu.label}
										activeNav={activeNav}
										onToggleNavigation={onToggleNavigation}
										className="SideBarNavItem"
									/>
								))}
						</ul>
					</nav>
				</div>
				<div className="orgBox">
					{orgLogoUrl ? (
						<div
							onMouseOver={() => this.setState({infoBubble: true})}
							onMouseLeave={() => this.setState({infoBubble: false})}
							className="orgLogo"
						>
							{infoBubble && <InfoBubble content={orgName} />}
							<img src={orgLogoUrl} />
						</div>
					) : (
						<div className="orgName hbox">{orgName}</div>
					)}
					<IconButton
						icon="switch"
						className="switchOrg"
						onClick={this.toggleOrganizationSwitch}
					/>
				</div>
				<OrganizationSwitch
					open={this.state.isOrganizationSwitchOpen}
					toggleOrganizationSwitch={this.toggleOrganizationSwitch}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {Type} from "../../../../../data/models/Type";
import type {IGlyph, IIconConfig} from "../../../../modules/catalog/create/CatalogTypes";
import type {Catalog} from "../../../../../data/models/Catalog";
import type {IError, TransportLayer} from "../../../../../data/TransportLayer";
import type {IconLayer} from "../../../../modules/abstract/common/iconeditor/IconEditor";
import type {Color, DefaultIcon, DefaultIconInfo, PointDouble, SkinnedIconInfo, UploadedIcon} from "../../../../../generated/api/base";
import {CatalogIconType, ImageType, XyiconFeature} from "../../../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {LibraryImage} from "../../../../../data/models/LibraryImage";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {getCatalogIconColor} from "../../../../modules/catalog/create/defaults";
import {CatalogGlyphs} from "../../../../modules/catalog/create/CatalogGlyphs";
import {ImageUploadPreprocessor} from "../../../../../utils/image/ImageUploadPreprocessor";
import {notify} from "../../../../../utils/Notify";
import {NotificationType} from "../../../../notification/Notification";
import {ImageUtils} from "../../../../../utils/image/ImageUtils";
import type {IIsSafeToDelete} from "../../../../modules/catalog/create/GeometrySelector";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {ColorSelectorStyled, ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import {TextStyleModifierStyled, TextStyleModifierV5} from "../../../text/TextStyleModifierV5";
import {NoResultSearchViewV5} from "../../table/NoResultSearchViewV5";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import FlipXIcon from "../../../icons/flip-horizontal.svg?react";
import FlipYIcon from "../../../icons/flip-vertical.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {FLEXCENTER, FlexCenterStyle, fontSize, fontWeight, radius} from "../../../styles/styles";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {WarningWindowV5} from "../../../popup/WarningWindowV5";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";
import {PopupUtilsV5} from "../../../popup/PopupUtilsV5";
import {TextAlignButtonStyled} from "../../../text/TextAlignButtonV5";
import type {SupportedFontName} from "../../../../../data/state/AppStateTypes";
import {LibraryItemsV5} from "./LibraryItemsV5";
import {IconEditorV5} from "./IconEditorV5";
import {EditableIconV5} from "./EditableIconV5";

interface ICatalogItemEditorProps {
	readonly selectedType: Type;
	readonly iconConfig: IIconConfig;
	readonly catalog?: Catalog;
	readonly onSaveClick?: (iconConfig: IIconConfig, thumbnail: string) => void;
	readonly updateParent: () => void;
	readonly transport?: TransportLayer;
}

interface ICatalogItemEditorState {
	selectedImageSet: "Standard Images" | "Custom Images";
	selectedLayer: IconLayer;
	search: string;
	borderRadius: number;
	glyphBackgroundColor: Color;
	glyphIconColor: Color;
	glyphChildrenColors: Color[];
	selectedChildIndex: number;
	isIconColorChanged: boolean;
	fontColor: Color;
	innerPart: string; // selectedImageSet === "Standard" ? svg innerhtml : image url
	imageAspectRatio: number; // needed when selectedImageSet === "Custom"
	iconTranslate: PointDouble; // normalized to [0, 1]
	iconOrientation: number;
	scale: number;
	isFlippedX: boolean;
	isFlippedY: boolean;

	// label
	text: string;
	fontFamily: SupportedFontName;
	fontSize: number;
	isBold: boolean;
	isItalic: boolean;
	isUnderlined: boolean;
	textTranslate: PointDouble; // normalized to [0, 1]
	textOrientation: number;
	isTextBeingModified: boolean;
	horizontalAlignment?: HorizontalAlignment;

	// libraryImages
	selectedLibraryImages: LibraryImage[];
	activeLibraryImage: LibraryImage;
}

@inject("transport")
@observer
export class CatalogItemEditorV5 extends React.Component<ICatalogItemEditorProps, ICatalogItemEditorState> {
	private _libraryImagesRef = React.createRef<LibraryItemsV5>();
	private _isDeletePopupWindowOpen: boolean = false;
	private readonly _defaultSelectedLayer: IconLayer = "Background";
	private _svgRef = React.createRef<SVGSVGElement>();
	private _colorEditPanelRef = React.createRef<HTMLDivElement>();
	private _selectedStandardImage: string; // svg innerHTML

	public static readonly defaultState: {
		borderRadius: number;
		glyphBackgroundColor: Color;
		glyphIconColor: Color;
		glyphChildrenColors: Color[];
		selectedChildIndex: number;
		fontColor: Color;
		iconTranslate: PointDouble;
		iconOrientation: number;
		scale: number;
		isFlippedX: boolean;
		isFlippedY: boolean;
		text: string;
		fontFamily: SupportedFontName;
		fontSize: 16;
		isBold: boolean;
		isItalic: boolean;
		isUnderlined: boolean;
		textTranslate: PointDouble;
		textOrientation: number;
		libraryImages: LibraryImage[];
		selectedLibraryImages: LibraryImage[];
		isSaving: boolean;
		horizontalAlignment: HorizontalAlignment;
	} = {
		borderRadius: 5,
		glyphBackgroundColor: {
			hex: `${ColorUtils.getHexStringFromNumber(Constants.DEFAULT_3D_COLOR)}`,
			transparency: 0,
		},
		glyphIconColor: getCatalogIconColor({hex: "000000", transparency: 0}),
		glyphChildrenColors: [],
		selectedChildIndex: -1,
		fontColor: {
			hex: "000000",
			transparency: 0,
		},
		iconTranslate: {
			x: 0.5,
			y: 0.5,
		},
		iconOrientation: 0,
		scale: 1,
		isFlippedX: false,
		isFlippedY: false,
		text: "",
		fontFamily: "Roboto",
		fontSize: 16,
		isBold: false,
		isItalic: false,
		isUnderlined: false,
		textTranslate: {
			x: 0.5,
			y: 0.5,
		},
		textOrientation: 0,
		libraryImages: [],
		selectedLibraryImages: [],
		isSaving: false,
		horizontalAlignment: HorizontalAlignment.center,
	};

	constructor(props: ICatalogItemEditorProps) {
		super(props);

		this.initState();
	}

	public initState() {
		const iconConfig = this.props.iconConfig;

		let newState: ICatalogItemEditorState = null;

		const isGoogleImagePanelOpenByDefault = false;

		if (iconConfig) {
			const isDefault = iconConfig.iconCategory === 0;

			const iconData = isDefault ? iconConfig.defaultIcon : iconConfig.uploadedIcon;

			let innerPart = isDefault ? iconConfig.innerPart || this.getInnerPartByDefaultImageId(iconConfig.defaultIcon.iconInfo.defaultImageID) : null;

			this._selectedStandardImage = innerPart;

			const newIconDataState = iconData
				? {
						borderRadius: iconData?.borderRadius,
						glyphBackgroundColor: iconData?.backgroundColor,
						glyphIconColor: (iconData?.iconInfo as DefaultIconInfo).color || getCatalogIconColor(iconData.backgroundColor),
						scale: iconData?.iconInfo.scale,
						isFlippedX: iconData?.iconInfo.isFlippedX,
						isFlippedY: iconData?.iconInfo.isFlippedY,
						fontColor: iconData?.overlayText.fontColor,
						fontFamily: iconData?.overlayText.fontFamily as SupportedFontName,
						fontSize: iconData?.overlayText.fontSize,
						iconOrientation: iconData?.iconInfo.orientation,
						iconTranslate: iconData?.iconInfo.translate,
						text: iconData?.overlayText.content,
						textOrientation: iconData?.overlayText.orientation,
						textTranslate: iconData?.overlayText.translate,
						isBold: iconData?.overlayText.isBold,
						isUnderlined: iconData?.overlayText.isUnderlined,
						isItalic: iconData?.overlayText.isItalic,
					}
				: CatalogItemEditorV5.defaultState;

			newState = {
				...newIconDataState,
				selectedImageSet: isDefault || !this.props.catalog ? "Standard Images" : "Custom Images", // no catalog => 'create' mode (not 'duplicate' or 'edit')
				selectedLayer: this._defaultSelectedLayer,
				search: "",
				innerPart,
				imageAspectRatio: 1,
				isTextBeingModified: false,
				glyphChildrenColors: this.props.catalog?.glyphChildrenColors ?? [],
				selectedChildIndex: -1,
				isIconColorChanged: !!this.props.catalog,
				horizontalAlignment: HorizontalAlignment.center,
				selectedLibraryImages: [],
				activeLibraryImage: null,
			};

			newState.glyphBackgroundColor = this.props.catalog ? newState.glyphBackgroundColor : this.getDefaultBackgroundColor();
			newState.glyphIconColor = newState.isIconColorChanged ? newState.glyphIconColor : getCatalogIconColor(newState.glyphBackgroundColor);

			if (iconConfig.iconCategory === CatalogIconType.Uploaded) {
				const libraryImage = this.actions.getFeatureItemById(
					(iconData.iconInfo as SkinnedIconInfo).libraryImageID,
					XyiconFeature.LibraryImage,
				) as LibraryImage;

				if (libraryImage) {
					innerPart = this.props.transport.getFullPathFromServer(`library/${libraryImage.fileName}`);
					newState.selectedLibraryImages = [libraryImage];
					newState.activeLibraryImage = libraryImage;
				}

				if (innerPart) {
					this.loadImg(innerPart, ImageType.PNG);
				}
			}
		} else {
			newState = {
				...CatalogItemEditorV5.defaultState,
				glyphBackgroundColor: this.getDefaultBackgroundColor(),
				isIconColorChanged: false,
				selectedImageSet: "Standard Images",
				selectedLayer: this._defaultSelectedLayer,
				search: "",
				innerPart: CatalogGlyphs.glyphs[0].innerPart,
				imageAspectRatio: 1,
				isTextBeingModified: false,
				activeLibraryImage: null,
			};
		}

		if (!this.state) {
			this.state = newState;
		} else {
			this.setState(newState);
		}
	}

	private onTextChange = (newText: string) => {
		this.setState({
			text: newText,
		});
	};

	private onSectionElementClick = (event: React.MouseEvent<HTMLDivElement>) => {
		const newlySelectedSection = event.currentTarget.textContent as IconLayer;

		this.setState({
			selectedLayer: newlySelectedSection as IconLayer,
		});

		if (newlySelectedSection === "Text" && !this.state.text) {
			this.onTextChange("");
			this.setState({
				isTextBeingModified: true,
			});
		} else {
			this.setState({
				isTextBeingModified: false,
			});
		}
	};

	private onIsBoldChange = (value: boolean) => {
		this.setState({
			isBold: value,
		});
	};

	private onIsItalicChange = (value: boolean) => {
		this.setState({
			isItalic: value,
		});
	};

	private onIsUnderlinedChange = (value: boolean) => {
		this.setState({
			isUnderlined: value,
		});
	};

	private onFontColorChange = (newFontColor: Color) => {
		this.setState({
			fontColor: newFontColor,
		});
	};

	private onFontSizeChange = (newFontSize: number) => {
		this.setState({
			fontSize: newFontSize,
		});
	};

	private onFontFamilyChange = (newFontFamily: SupportedFontName) => {
		this.setState({
			fontFamily: newFontFamily,
		});
	};

	private onClearTextClick = () => {
		this.onTextChange("");
		this.setState({
			textOrientation: CatalogItemEditorV5.defaultState.textOrientation,
			textTranslate: CatalogItemEditorV5.defaultState.textTranslate,
			fontColor: CatalogItemEditorV5.defaultState.fontColor,
			fontFamily: CatalogItemEditorV5.defaultState.fontFamily,
			fontSize: CatalogItemEditorV5.defaultState.fontSize,
			isUnderlined: CatalogItemEditorV5.defaultState.isUnderlined,
			isBold: CatalogItemEditorV5.defaultState.isBold,
			isItalic: CatalogItemEditorV5.defaultState.isItalic,
			isTextBeingModified: true,
		});
	};

	private getImageSet = (event: React.MouseEvent<HTMLDivElement>) => {
		const newlySelectedImageSet = event.currentTarget.textContent as "Standard Images" | "Custom Images";

		this.setState({
			selectedImageSet: newlySelectedImageSet,
			selectedLayer: this._defaultSelectedLayer,
			search: "",
		});

		// Reset to default
		this.onImageDeleteClick();
	};

	private onStandardImageSetClick = (event: React.MouseEvent<HTMLDivElement>) => {
		this.getImageSet(event);

		this.setState({
			innerPart: this._selectedStandardImage,
		});

		this.props.updateParent();
	};

	private onCustomImageSetClick = async (event: React.MouseEvent<HTMLDivElement>) => {
		this.getImageSet(event);

		this.setState({
			innerPart: "",
		});
		if (this.state.selectedLibraryImages[0]) {
			await this.onSetLibraryImageActive(this.state.selectedLibraryImages[0]);
		} else if (this.libraryImages[0]) {
			await this.onSetLibraryImageActive(this.libraryImages[0]);
		}

		this.props.updateParent();
	};

	private onFileInputChange = async (file: File, keywords: string[]) => {
		const thumbnailSize = Constants.RESOLUTION.XYICON;

		const uploadData = await ImageUploadPreprocessor.getImageDataForUpload(this.props.transport.appState.fonts, file, true, thumbnailSize);

		const createData = {
			imageType: uploadData.imageType,
			fileName: file.name,
			keywords: keywords,
			thumbnail: uploadData.thumbnail,
			originalImageData: uploadData.fullImageData,
		};

		try {
			(await this.props.transport.services.feature.create(createData, XyiconFeature.LibraryImage)) as LibraryImage[];
		} catch (error) {
			notify(this.props.transport.appState.app.notificationContainer, {
				title: "Error",
				description: `LibraryImage not saved due to the following error: ${error}`,
				type: NotificationType.Error,
				lifeTime: Infinity,
			});
		}
	};

	private onSelectLibraryImages = (libraryImages: LibraryImage[]) => {
		this.setState({
			selectedLibraryImages: libraryImages,
		});
	};

	private onSetLibraryImageActive = async (libraryImage: LibraryImage) => {
		const fullImagePath = this.props.transport.getFullPathFromServer(`library/${libraryImage.fileName}`);

		await this.loadImg(fullImagePath, libraryImage.imageType);
		this.setState({
			selectedLibraryImages: [libraryImage],
			activeLibraryImage: libraryImage,
		});
	};

	private async loadImg(url: string, imageType: ImageType) {
		const img = await ImageUtils.loadImage(url);

		// If the dev tools is open, and the cache is disabled, the original image can take a visible amount of time (few hundred ms)
		// to reappear when the state changes, so as a workaround we create a base64 string from the image (png).
		// This way, the image doesn't reload (or at least the reload is not visible at all to the user)
		// NOTE: This conversion happens only on the client's machine, and it's really fast. The original image format stays the same,
		// so there's no need to worry about large filesizes, or slow download speeds because of this
		const innerPart = imageType === ImageType.SVG ? url : await ImageUtils.image2RasterBase64String(this.props.transport.appState.fonts, img);

		this.setState({
			innerPart: innerPart,
			imageAspectRatio: img.width / img.height,
		});
	}

	private getDefaultImageId() {
		const glyph = CatalogGlyphs.glyphs.find((glyph: IGlyph) => glyph.innerPart === this.state.innerPart);

		if (glyph) {
			return glyph.defaultImageID;
		}

		return null;
	}

	private getInnerPartByDefaultImageId(defaultImageId: string) {
		const glyph = CatalogGlyphs.glyphs.find((glyph: IGlyph) => glyph.defaultImageID === defaultImageId);

		if (glyph) {
			return glyph.innerPart;
		}

		return null;
	}

	private get activeLibraryImageId() {
		return this.state.activeLibraryImage.id;
	}

	public onSaveClick = async () => {
		this.forceUpdate();

		const compressedImage = await ImageUploadPreprocessor.createCompressedImageFromSVG(
			this.props.transport.appState.fonts,
			this._svgRef.current,
			this.state.selectedImageSet === "Standard Images",
		);

		const iconCoreConfig: DefaultIcon & UploadedIcon = {
			borderRadius: this.state.borderRadius,
			backgroundColor: this.state.glyphBackgroundColor,
			iconInfo: {
				defaultImageID: this.state.selectedImageSet === "Standard Images" ? this.getDefaultImageId() : null,
				libraryImageID: this.state.selectedImageSet === "Custom Images" ? this.activeLibraryImageId : null,
				color: this.state.glyphIconColor,
				translate: this.state.iconTranslate as {x: number; y: number},
				orientation: this.state.iconOrientation,
				scale: this.state.scale,
				isFlippedX: this.state.isFlippedX,
				isFlippedY: this.state.isFlippedY,
			},
			overlayText: {
				content: this.state.text,
				fontFamily: this.state.fontFamily,
				fontColor: this.state.fontColor,
				fontSize: this.state.fontSize,
				translate: this.state.textTranslate as {x: number; y: number},
				orientation: this.state.textOrientation,
				isBold: this.state.isBold,
				isItalic: this.state.isItalic,
				isUnderlined: this.state.isUnderlined,
			},
		};

		this.props.onSaveClick(
			{
				iconCategory: this.state.selectedImageSet === "Standard Images" ? CatalogIconType.Default : CatalogIconType.Uploaded,
				defaultIcon: this.state.selectedImageSet === "Standard Images" ? iconCoreConfig : null,
				uploadedIcon: this.state.selectedImageSet === "Custom Images" ? iconCoreConfig : null,
				modelParameters: null,
				innerPart: this.state.innerPart,
			},
			compressedImage,
		);
	};

	private isSafeToDelete = async (libraryImage?: LibraryImage) => {
		const {transport} = this.props;
		const {selectedLibraryImages} = this.state;
		const libraryImageIDList = libraryImage ? [libraryImage.id] : selectedLibraryImages.map((lI) => lI.id);

		const {result} = (await transport.requestForOrganization({
			url: "libraryimages/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				libraryImageIDList,
			},
		})) as {result: IIsSafeToDelete; error: IError};

		return result;
	};

	private onDeleteSelectedLibraryImagesClick = async () => {
		if (!this._isDeletePopupWindowOpen) {
			const {selectedLibraryImages} = this.state;

			const count = selectedLibraryImages.length;

			if (count > 0) {
				const {inUseList} = await this.isSafeToDelete();

				if (inUseList.length === 0) {
					this._isDeletePopupWindowOpen = true;
					const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(XyiconFeature.LibraryImage, count);

					this._isDeletePopupWindowOpen = false;

					if (confirmed) {
						await this.deleteSelectedLibraryImages(selectedLibraryImages);
					}
				} else {
					await WarningWindowV5.open(`${inUseList.length} item(s) couldn't be deleted, because they're being used by other components.`);
				}
			}
		}
	};

	private async deleteSelectedLibraryImages(libraryImages: LibraryImage[]) {
		this.setState({
			selectedLibraryImages: [],
			innerPart: "",
			activeLibraryImage: null,
		});

		await this.actions.deleteItems(libraryImages, XyiconFeature.LibraryImage);
	}

	private getFilteredElements(list: {keywords: string[]}[]) {
		return StringUtils.filterByKeywords(list, this.state.search);
	}

	private get actions() {
		return this.props.transport.appState.actions;
	}

	private onIconOrientationChange = (newOrientation: number) => this.setState({iconOrientation: newOrientation});
	private onIconTranslateChange = (newTranslate: PointDouble) => this.setState({iconTranslate: newTranslate});
	private onScaleChange = (newScale: number) => this.setState({scale: newScale});
	private onTextTranslateChange = (newTranslate: PointDouble) => this.setState({textTranslate: newTranslate});
	private onTextOrientationChange = (newOrientation: number) => this.setState({textOrientation: newOrientation});
	private onTextBeingModifiedChange = (value: boolean) => this.setState({isTextBeingModified: value});
	private onSearchInput = (value: string) => this.setState({search: value});
	private onBackgroundColorChange = (newColor: Color) => this.setState({glyphBackgroundColor: newColor});
	private onFlipXChange = () => this.setState((prevState) => ({isFlippedX: !prevState.isFlippedX}));
	private onFlipYChange = () => this.setState((prevState) => ({isFlippedY: !prevState.isFlippedY}));
	private onSelectedChildIndexChange = (iconChildIndex: number) => {
		this.setState({selectedChildIndex: iconChildIndex});
	};

	private onGlyphIconColorChange = (newColor: Color) => {
		if (this.state.selectedChildIndex > -1) {
			const newArr = [...this.state.glyphChildrenColors];

			newArr[this.state.selectedChildIndex] = newColor;
			this.setState({
				glyphChildrenColors: newArr,
			});
		} else {
			this.setState({
				glyphIconColor: newColor,
				isIconColorChanged: true,
				glyphChildrenColors: [],
			});
		}
	};

	private onEditableIconClick = (innerPart: string) => {
		if (this._selectedStandardImage !== innerPart || this.state.innerPart !== innerPart) {
			this._selectedStandardImage = innerPart;
			this.setState({
				innerPart,
				activeLibraryImage: null,
				glyphChildrenColors: [],
			});
		}
	};

	private onImageDeleteClick = () => {
		this.setState({
			innerPart: CatalogGlyphs.glyphs[0]?.innerPart || "",
			activeLibraryImage: null,
			iconTranslate: {
				x: CatalogItemEditorV5.defaultState.iconTranslate.x,
				y: CatalogItemEditorV5.defaultState.iconTranslate.y,
			},
			iconOrientation: CatalogItemEditorV5.defaultState.iconOrientation,
			isFlippedX: CatalogItemEditorV5.defaultState.isFlippedX,
			isFlippedY: CatalogItemEditorV5.defaultState.isFlippedY,
		});
	};

	private getImageSetSelector() {
		return (
			<RadioButtonContainerStyled>
				<RadioButtonStyled
					className={ReactUtils.cls({active: this.state.selectedImageSet === "Standard Images"})}
					onClick={this.onStandardImageSetClick}
				>
					Standard Images
				</RadioButtonStyled>
				<RadioButtonStyled
					className={ReactUtils.cls({active: this.state.selectedImageSet === "Custom Images"})}
					onClick={this.onCustomImageSetClick}
				>
					Custom Images
				</RadioButtonStyled>
			</RadioButtonContainerStyled>
		);
	}

	private getDefaultBackgroundColor() {
		const selectedTypeColor = this.props.selectedType?.settings?.color;

		return selectedTypeColor || CatalogItemEditorV5.defaultState.glyphBackgroundColor;
	}

	private get libraryImages() {
		return this.actions.getList<LibraryImage>(XyiconFeature.LibraryImage);
	}

	private onTextAlignmentChange = (horizontalAlignment: HorizontalAlignment) => {
		this.setState({horizontalAlignment});
	};

	private getGlyphContainer = () => {
		const {
			iconTranslate,
			iconOrientation,
			borderRadius,
			scale,
			isFlippedX,
			isFlippedY,
			text,
			textTranslate,
			textOrientation,
			fontSize,
			fontFamily,
			fontColor,
			isBold,
			isItalic,
			isUnderlined,
			horizontalAlignment,
		} = CatalogItemEditorV5.defaultState;
		const {glyphIconColor, glyphBackgroundColor, isIconColorChanged, innerPart, selectedImageSet, imageAspectRatio} = this.state;

		return this.getFilteredElements(CatalogGlyphs.glyphs).map((glyph: IGlyph, index) => {
			const iconColor = glyphIconColor;
			const selected = this._selectedStandardImage ? this._selectedStandardImage === glyph.innerPart : index === 0;

			return (
				<EditableIconV5
					classNames={ReactUtils.cls("glyph button", {shadow: iconColor.hex === "000000", selected})}
					borderRadius={borderRadius}
					backgroundColor={glyphBackgroundColor}
					isIconColorChanged={isIconColorChanged}
					iconColor={iconColor}
					iconColors={[]}
					selectedChildIndex={-1}
					replaceColor={false}
					key={glyph.defaultImageID}
					outline={innerPart === glyph.innerPart}
					isSVG={selectedImageSet === "Standard Images"}
					innerPart={glyph.innerPart}
					imageAspectRatio={imageAspectRatio}
					onClick={this.onEditableIconClick}
					iconTranslate={iconTranslate}
					iconOrientation={iconOrientation}
					scale={scale}
					isFlippedX={isFlippedX}
					isFlippedY={isFlippedY}
					text={text}
					textTranslate={textTranslate}
					textOrientation={textOrientation}
					fontSize={fontSize}
					fontFamily={fontFamily}
					fontColor={fontColor}
					isBold={isBold}
					isItalic={isItalic}
					isUnderlined={isUnderlined}
					horizontalAlignment={horizontalAlignment}
				/>
			);
		});
	};

	public override componentDidMount() {
		return this.props.transport.services.feature.refreshList(XyiconFeature.LibraryImage);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ICatalogItemEditorProps>, nextContext: any): void {
		if (this.props.catalog !== nextProps.catalog) {
			this.setState({
				glyphChildrenColors: nextProps.catalog?.glyphChildrenColors ?? [],
			});
		}
	}

	public override render() {
		const {
			selectedImageSet,
			isIconColorChanged,
			glyphBackgroundColor,
			isTextBeingModified,
			glyphIconColor,
			innerPart,
			imageAspectRatio,
			selectedLibraryImages,
			search,
			selectedLayer,
			glyphChildrenColors,
			selectedChildIndex,
			isFlippedX,
			isFlippedY,
			isBold,
			isItalic,
			isUnderlined,
			fontColor,
			fontFamily,
			fontSize,
			horizontalAlignment,
			borderRadius,
			iconTranslate,
			iconOrientation,
			scale,
			text,
			textOrientation,
			textTranslate,
		} = this.state;

		const filteredElements = this.getFilteredElements(CatalogGlyphs.glyphs);

		const iconColor = glyphIconColor;
		const imageEditActiveColor = (selectedChildIndex > -1 && glyphChildrenColors[selectedChildIndex]) || iconColor;

		return (
			<PanelContentStyled>
				<SideContainerStyled>
					{this.getImageSetSelector()}
					{selectedImageSet === "Standard Images" ? (
						<>
							<SearchFieldInCatalogEditorStyled>
								<SearchFieldV5
									onInput={this.onSearchInput}
									value={search}
								/>
							</SearchFieldInCatalogEditorStyled>
							{search.length > 0 && filteredElements.length === 0 ? (
								<div className="noResult">
									<NoResultSearchViewV5 search={search}></NoResultSearchViewV5>
								</div>
							) : (
								<GlyphContainerStyled>{this.getGlyphContainer()}</GlyphContainerStyled>
							)}
						</>
					) : (
						<LibraryItemsV5
							ref={this._libraryImagesRef}
							type="image"
							libraryItems={this.libraryImages}
							selectedLibraryItems={selectedLibraryImages}
							onItemsSelected={this.onSelectLibraryImages}
							onSetItemActive={this.onSetLibraryImageActive}
							onDeleteSelectedClick={this.onDeleteSelectedLibraryImagesClick}
							onFileInputChange={this.onFileInputChange}
						/>
					)}
				</SideContainerStyled>
				<SideContainerStyled>
					<RadioButtonContainerStyled>
						<RadioButtonStyled
							className={ReactUtils.cls({active: selectedLayer === "Background"})}
							onClick={this.onSectionElementClick}
						>
							Background
						</RadioButtonStyled>
						<RadioButtonStyled
							className={ReactUtils.cls({active: selectedLayer === "Image"})}
							onClick={this.onSectionElementClick}
						>
							Image
						</RadioButtonStyled>
						<RadioButtonStyled
							className={ReactUtils.cls({active: selectedLayer === "Text"})}
							onClick={this.onSectionElementClick}
						>
							Text
						</RadioButtonStyled>
					</RadioButtonContainerStyled>
					<IconEditorButtonsStyled
						ref={
							this._colorEditPanelRef
						} /* $whiteColorSelector={selectedLayer === "Background" && glyphBackgroundColor.hex === "ffffff" || selectedLayer === "Image" && glyphIconColor.hex === "ffffff"} */
					>
						{selectedLayer === "Background" && (
							<div className="background-colorselector">
								<ColorSelectorV5
									title="Background Color"
									label="Background Color"
									color={glyphBackgroundColor}
									onColorChange={this.onBackgroundColorChange}
									outerDivRef={this._colorEditPanelRef}
									horizontalAlignment={HorizontalAlignment.right}
									verticalAlignment={VerticalAlignment.bottom}
									isTransparencyEnabled={true}
									offsetX={30}
								/>
							</div>
						)}
						{selectedLayer === "Image" && (
							<ImageModifierButtonContainerStyled>
								{selectedImageSet === "Standard Images" && (
									<ColorSelectorV5
										title="Fill"
										horizontalAlignment={HorizontalAlignment.outerRight}
										verticalAlignment={VerticalAlignment.bottom}
										outerDivRef={this._colorEditPanelRef}
										color={imageEditActiveColor}
										onColorChange={this.onGlyphIconColorChange}
										isTransparencyEnabled={true}
									/>
								)}
								{this.state.selectedChildIndex === -1 && (
									<>
										<IconButtonV5
											title="Flip Horizontally"
											isActive={isFlippedX}
											onClick={this.onFlipXChange}
											IconComponent={FlipXIcon}
										/>
										<IconButtonV5
											title="Flip Vertically"
											isActive={isFlippedY}
											onClick={this.onFlipYChange}
											IconComponent={FlipYIcon}
										/>
									</>
								)}
							</ImageModifierButtonContainerStyled>
						)}
						{selectedLayer === "Text" && (
							<TextStyleModifierV5
								isBold={isBold}
								isItalic={isItalic}
								isUnderlined={isUnderlined}
								fontColor={fontColor}
								fontFamily={fontFamily}
								fontSize={fontSize}
								onIsBoldChange={this.onIsBoldChange}
								onIsItalicChange={this.onIsItalicChange}
								onIsUnderlinedChange={this.onIsUnderlinedChange}
								onFontColorChange={this.onFontColorChange}
								onFontSizeChange={this.onFontSizeChange}
								onFontFamilyChange={this.onFontFamilyChange}
								onClearTextClick={this.onClearTextClick}
								onTextAlignmentChange={this.onTextAlignmentChange}
								horizontalAlignment={horizontalAlignment}
							/>
						)}
					</IconEditorButtonsStyled>
					<IconEditorV5
						selectedLayer={selectedLayer}
						backgroundColor={glyphBackgroundColor}
						iconColor={glyphIconColor}
						iconColors={glyphChildrenColors}
						selectedChildIndex={selectedChildIndex}
						replaceColor={true}
						borderRadius={borderRadius}
						isSVG={selectedImageSet === "Standard Images"}
						innerPart={innerPart}
						imageAspectRatio={imageAspectRatio}
						onSelectedChildIndexChange={this.onSelectedChildIndexChange}
						iconTranslate={iconTranslate}
						iconOrientation={iconOrientation}
						onIconOrientationChange={this.onIconOrientationChange}
						scale={scale}
						isFlippedX={isFlippedX}
						isFlippedY={isFlippedY}
						onIconTranslateChange={this.onIconTranslateChange}
						onScaleChange={this.onScaleChange}
						text={text}
						textTranslate={textTranslate}
						textOrientation={textOrientation}
						onTextTranslateChange={this.onTextTranslateChange}
						onTextOrientationChange={this.onTextOrientationChange}
						onTextChange={this.onTextChange}
						fontSize={fontSize}
						fontFamily={fontFamily}
						fontColor={fontColor}
						isBold={isBold}
						isItalic={isItalic}
						isUnderlined={isUnderlined}
						horizontalAlignment={horizontalAlignment}
						isTextBeingModified={isTextBeingModified}
						onIsTextBeingModifiedChange={this.onTextBeingModifiedChange}
						svgRef={this._svgRef}
						isIconColorChanged={isIconColorChanged}
					/>
				</SideContainerStyled>
			</PanelContentStyled>
		);
	}
}

export const PanelContentStyled = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SideContainerStyled = styled.div`
	width: 508px;
	height: 576px;
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: ${radius.sm};
`;

const ImageModifierButtonContainerStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 2px 8px;
	border: 1px solid ${colorPalette.gray.c200Light};
	border-radius: 8px;
`;

const SearchFieldInCatalogEditorStyled = styled.div`
	.SearchField {
		width: 460px;
		margin: 16px;
		margin-top: 0;
		height: 32px;

		svg {
			top: 8px;
		}
	}
`;

const RadioButtonContainerStyled = styled.div`
	padding: 16px;
	display: flex;
	justify-content: space-around;
`;

const RadioButtonStyled = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 8px;
	cursor: pointer;
	color: ${colorPalette.gray.c700Dark};
	height: 48px;
	font-size: ${fontSize.xl};
	font-weight: ${fontWeight.bold};
	border-bottom: 2px solid transparent;

	&.active {
		border-bottom-color: ${colorPalette.primary.c500Primary};
		color: ${colorPalette.gray.c950};
	}
`;

const GlyphContainerStyled = styled.div`
	overflow: scroll;
	display: grid;
	grid-template-columns: repeat(5, 80px);
	grid-auto-rows: 80px;
	grid-gap: 8px;
	padding: 0 16px;
	height: 432px;

	.glyph {
		width: 80px;
		height: 80px;
		${FLEXCENTER};

		svg {
			width: 72px;
			height: 72px;
		}

		&.selected {
			border: 4px solid ${colorPalette.primary.c500Primary};
		}
	}
`;

const IconEditorButtonsStyled = styled.div<{$whiteColorSelector?: boolean}>`
	${FlexCenterStyle};
	justify-content: center;
	margin-bottom: 16px;

	${TextStyleModifierStyled}, .flip {
		border: 1px solid ${colorPalette.gray.c300};
		border-radius: ${radius.md};
		padding: 3px 8px;
	}

	${TextStyleModifierStyled} {
		${SelectInputStyled} {
			color: ${colorPalette.gray.c950};
		}

		${ColorSelectorStyled} {
			&:hover {
				background-color: ${colorPalette.gray.c200Light};
			}

			&.isActive {
				background-color: ${colorPalette.primary.c500Primary};
				color: white;
			}
		}

		${TextAlignButtonStyled} {
			width: 32px;
			height: 32px;
			border-radius: 4px;

			&:hover {
				background-color: ${colorPalette.gray.c200Light};
			}

			&.active {
				background-color: ${colorPalette.primary.c500Primary};
				color: ${colorPalette.white};
			}
		}
	}

	.background-colorselector {
		width: 64px;
		height: 48px;
		border: 1px solid ${colorPalette.gray.c300};
		border-radius: ${radius.md};
		align-items: center;
		display: flex;
		justify-content: center;

		${ColorSelectorStyled} {
			&.isActive {
				background-color: transparent;
			}

			&:hover::after {
				left: auto;
				transform: translateX(10%);
				top: -25px;
			}
		}
	}

	.flip {
		${FlexCenterStyle};
		justify-content: center;
		padding: 3px 16px;
		position: relative;

		${ColorSelectorStyled} {
			&:hover::after {
				left: auto;
				transform: translateX(20%);
				top: -25px;
			}
		}
	}
`;

import {Fragment, useReducer, useState} from "react";
import type {MouseEvent} from "react";
import GlobeIcon from "../../icons/globe.svg?react";
import StarIcon from "../../icons/star.svg?react";
import UserIcon from "../../icons/user.svg?react";
import UsersIcon from "../../icons/users.svg?react";
import type {IDropdownOption} from "../../interaction/DropdownOptionsV5";
import {NavMenuStyled} from "../NavigationCommon";
import type {TMenuV5} from "../NavigationCommon";
import {NavigationMenuItem} from "../NavigationMenuItem";
import {useAppStore} from "../../../../StateManager";
import type {AppState} from "../../../../data/state/AppState";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {ViewPreferenceCategory} from "../../../../generated/api/base";
import type {IViewFolder} from "../../../../data/models/ViewUtils";
import {Functions} from "../../../../utils/function/Functions";
import type {View} from "../../../../data/models/View";
import FolderPlusIcon from "../../icons/folder-plus.svg?react";
import {getKeyForLocalStorageWorkspaceViewSections} from "./WorkspaceViewCommon";
import {WorkspaceViewContainer} from "./WorkspaceViewContainer";
import type {WorkspaceViewType} from "./WorkspaceViewCommon";

type WorkspaceMenuV5 = TMenuV5 & {
	readonly label: WorkspaceViewType;
};

const viewMenu: WorkspaceMenuV5[] = [
	{
		label: "Favorites",
		icon: StarIcon,
	},
	{
		label: "Global",
		icon: GlobeIcon,
	},
	{
		label: "My Views",
		icon: UserIcon,
	},
	{
		label: "Shared With Me",
		icon: UsersIcon,
	},
];

const getDropdownOptionsForSection = (sectionLabel: WorkspaceViewType, appState: AppState): IDropdownOption[] => {
	if (sectionLabel === "Favorites" || sectionLabel === "Global") {
		const options: IDropdownOption[] = [
			{
				label: "New Folder",
				onClick: () => {
					const newFolder: IViewFolder = {
						name: "New Folder",
						id: MathUtils.getNewRandomGUID(),
						isOpen: false,
						children: [],
						category: ViewPreferenceCategory.Folder,
					};

					if (sectionLabel === "Favorites") {
						const favoriteViews = [...appState.user.favoriteViews];

						favoriteViews.push(newFolder);

						return appState.user?.setFavoriteViews(favoriteViews);
					} else if (sectionLabel === "Global") {
						const globalViews = [...appState.organization.globalViews];

						globalViews.push(newFolder);

						return appState.organization?.setGlobalViews(globalViews);
					}
				},
				IconComponent: FolderPlusIcon,
			},
		];

		return options;
	}

	return [];
};

interface INavigationViewSectionProps {
	readonly onlyIcon: boolean;
	readonly isHome: boolean;
	readonly openCreateViewPopup: (type: WorkspaceViewType, viewFolderId: string) => void;
	readonly onViewItemShareClick: (viewItem: View | IViewFolder) => void;
}

export const NavigationViewSection = (props: INavigationViewSectionProps) => {
	const appState = useAppStore((state) => state.appState);
	const user = appState.user;
	const {openCreateViewPopup, onViewItemShareClick, onlyIcon, isHome} = props;
	const [floatingWorkspaceViews, setFloatingWorkspaceViews] = useState<WorkspaceViewType | null>(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const keyForLocalStorage = getKeyForLocalStorageWorkspaceViewSections(user.id);

	let openViewSpaceTypes: WorkspaceViewType[] = JSON.parse(localStorage.getItem(keyForLocalStorage)) || null;

	if (!openViewSpaceTypes) {
		openViewSpaceTypes = ["Favorites", "Global", "My Views", "Shared With Me"];
		localStorage.setItem(keyForLocalStorage, JSON.stringify(openViewSpaceTypes));
	}

	const onToggleSection = (section: WorkspaceViewType) => {
		const newOpenViewSpaceTypes = new Set([...openViewSpaceTypes]);

		if (newOpenViewSpaceTypes.has(section)) {
			newOpenViewSpaceTypes.delete(section);
		} else {
			newOpenViewSpaceTypes.add(section);
		}

		localStorage.setItem(keyForLocalStorage, JSON.stringify([...newOpenViewSpaceTypes]));
		forceUpdate();
	};

	return (
		<NavMenuStyled>
			{viewMenu.map((menu, i) => {
				const onAddView = (viewFolderId: string) => {
					openCreateViewPopup(menu.label, viewFolderId);
				};

				const isGlobal = menu.label === "Global";
				const onAddViewClick =
					menu.label === "Shared With Me" || (isGlobal && !user.isAdmin)
						? undefined
						: (event: MouseEvent) => {
								event.stopPropagation();
								onAddView("");
							};

				const onAddViewToFolderClick = onAddViewClick
					? (viewFolderId: string) => {
							onAddView(viewFolderId);
						}
					: undefined;

				const showFloatingMenu = onlyIcon && floatingWorkspaceViews === menu.label;

				return (
					<Fragment key={menu.label}>
						<NavigationMenuItem
							{...menu}
							onClick={() => {
								if (onlyIcon) {
									if (floatingWorkspaceViews === menu.label) {
										setFloatingWorkspaceViews(null);
									} else {
										setFloatingWorkspaceViews(menu.label);
									}
								} else {
									onToggleSection(menu.label);
								}
							}}
							onlyIcon={onlyIcon}
							small={!isHome}
							onPlusClick={onAddViewClick}
							dropdownOptions={getDropdownOptionsForSection(menu.label, appState)}
							floatingMenu={
								showFloatingMenu && (
									<WorkspaceViewContainer
										type={floatingWorkspaceViews}
										onAddClick={onAddViewToFolderClick}
										forceUpdateParent={forceUpdate}
										onShareClick={onViewItemShareClick}
									/>
								)
							}
							closeFloatingMenu={showFloatingMenu ? () => setFloatingWorkspaceViews(null) : Functions.emptyFunction}
						/>
						{!onlyIcon && openViewSpaceTypes.includes(menu.label) && (
							<WorkspaceViewContainer
								type={menu.label}
								onAddClick={onAddViewToFolderClick}
								forceUpdateParent={forceUpdate}
								onShareClick={onViewItemShareClick}
							/>
						)}
					</Fragment>
				);
			})}
		</NavMenuStyled>
	);
};

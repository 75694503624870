declare global {
	interface Element {
		//maybe this is good
		mozRequestFullScreen(): void;
		msRequestFullscreen(): void;
		ALLOW_KEYBOARD_INPUT: number;
		// requestFullscreen();
		webkitRequestFullscreen(): void;
		// ?webkitRequestFullscreen(allowKeyboardInput: number);
	}

	interface Document {
		fullscreenElement: Element;
		webkitFullscreenElement: Element;
		mozFullScreenElement: Element;
		msFullscreenElement: Element;

		//fullscreen: boolean;
		mozFullScreen: boolean;

		exitFullscreen(): void;
		webkitCancelFullScreen(): void;
		mozCancelFullScreen(): void;
		cancelFullScreen(): void;
		msExitFullscreen(): void;
	}
}

/**
 * Manages switching to and from fullscreen mode.
 */
export class FullscreenManager {
	public static target: HTMLElement = null;
	//public static allowKeyboardInput = false

	public static toggle(target?: HTMLElement, allowKeyBoardInput = false) {
		if (!FullscreenManager.isFullscreen()) {
			target = target || FullscreenManager.target;

			FullscreenManager.request(target, allowKeyBoardInput);
		} else {
			FullscreenManager.cancel();
		}
	}

	public static request(target?: HTMLElement, allowKeyboardInput = false) {
		target = target || document.documentElement;

		if (target.requestFullscreen) {
			target.requestFullscreen();
		} else if (target.webkitRequestFullscreen) {
			target.webkitRequestFullscreen();
		} else if (target.mozRequestFullScreen) {
			target.mozRequestFullScreen();
		} else if (target.msRequestFullscreen) {
			target.msRequestFullscreen();
		}

		FullscreenManager.target = target;
	}

	public static cancel() {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.webkitCancelFullScreen) {
			document.webkitCancelFullScreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.cancelFullScreen) {
			document.cancelFullScreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}

		this.target = null;
	}

	public static isFullscreen(): boolean {
		return !!document.fullscreenElement || !!document.webkitFullscreenElement || !!document.mozFullScreenElement || !!document.msFullscreenElement;

		//return !!this.target;
	}

	public static addChangeListener(listener: (event: Event) => void) {
		document.addEventListener("fullscreenchange", listener);
		document.addEventListener("webkitfullscreenchange", listener);
		document.addEventListener("mozfullscreenchange", listener);
	}

	public static removeChangeListener(listener: (event: Event) => void) {
		document.removeEventListener("fullscreenchange", listener);
		document.removeEventListener("webkitfullscreenchange", listener);
		document.removeEventListener("mozfullscreenchange", listener);
	}
}

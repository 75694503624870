import {useRef} from "react";
import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import {CatalogCreatePopupV5} from "./catalog/CatalogCreatePopupV5";
import {getActionBarButtonsForFeature} from "./ActionBarUtils";

const OWN_FEATURE = XyiconFeature.XyiconCatalog;

interface ICatalogViewV5Props {}

export const CatalogViewV5 = (props: ICatalogViewV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const createButtonRef = useRef<HTMLDivElement>();

	return (
		<ModuleViewV5
			feature={OWN_FEATURE}
			className="CatalogView"
			actionBar={getActionBarButtonsForFeature(OWN_FEATURE, appState)}
			create={(onClose) => (
				<CatalogCreatePopupV5
					onClose={() => {
						onClose();
					}}
					parentRef={createButtonRef.current}
					label="Create Catalog Item"
				/>
			)}
			createButtonRef={createButtonRef}
		/>
	);
};

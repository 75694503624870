import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import {ButtonV5} from "../../../button/ButtonV5";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {Navigation} from "../../../../../Navigation";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {PortfolioPermissionSetsDto, UpdatePortfolioPermissionSetRequest} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import {TabViewV5} from "../../../widgets/tab/TabViewV5";
import {TabChildV5} from "../../../abstract/table/TabChildV5";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {PopupUtilsV5} from "../../../popup/PopupUtilsV5";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {PopupHeaderStyled, PopupV5} from "../../../popup/PopupV5";
import {colorPalette} from "../../../styles/colorPalette";
import {CreatePopupFieldStyled} from "../../../popup/CreatePopupField.styled";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {CreatePermissionSetPanelV5} from "./CreatePermissionSetPanelV5";
import {ModulePermissionsV5} from "./ModulePermissionsV5";
import {PermissionSetItemV5} from "./PermissionSetItemV5";

const OrganizationSettingsStyled = styled.div`
	width: 100%;
	.PermissionSets {
		min-width: 1200px;
		overflow: hidden;

		.setList {
			min-width: 392px;
			max-width: 392px;
			padding: 10px 15px;
			border-right: 1px solid ${colorPalette.gray.c300};

			.setCreate {
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;

				h4 {
					margin-top: 10px;
					font-size: 18px;
				}
			}

			.list {
				overflow: auto;
				height: calc(100vh - 200px);
			}

			.SearchField {
				margin-bottom: 10px;
			}
		}

		.settingsTab {
			height: 100vh;
			width: 100%;

			.buttons {
				border-bottom: 1px solid ${colorPalette.gray.c300};
				background: white;
				min-height: 70px;
				align-items: center;
				padding-left: 16px;
				padding-right: 36px;
				display: flex;
				justify-content: start;

				.button {
					font-size: 18px;
					padding-right: 41px;
					box-sizing: border-box;
					background: none;
					color: ${colorPalette.gray.c700Dark};

					&:not(:last-child) {
						margin-right: 10px;
					}

					&.selected {
						color: ${colorPalette.primary.c500Primary};
						text-decoration: underline 3px;
						text-decoration-skip-ink: none;
						text-underline-position: under;
					}

					&:hover {
						color: ${colorPalette.primary.c500Primary};
					}
				}
			}
		}
	}

	${PopupHeaderStyled} {
		padding-bottom: 0;
	}
`;

interface IPermissionSetsPropsV5 {
	readonly param2: string;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
}

interface IPermissionSetsStateV5 {
	creating: boolean;
	selectedId: string;
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PermissionSetsV5 extends React.Component<IPermissionSetsPropsV5, IPermissionSetsStateV5> {
	private _container = React.createRef<HTMLDivElement>();
	private _isDeletePopupWindowOpen = false;
	private parentRef = React.createRef<HTMLDivElement>();

	constructor(props: IPermissionSetsPropsV5) {
		super(props);
		this.state = {
			creating: false,
			selectedId: null,
			searchQuery: "",
		};
	}

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.setState({creating: false});
				break;
		}
	};

	private onPopupOpen = () => {
		this.setState(
			{
				creating: true,
			},
			() => {
				console.log(this.state.creating);
			},
		);
	};

	private onPopupClose = () => {
		this.setState({
			creating: false,
		});
	};

	private onChangeSettingsTab = (id: string) => {
		const parts = StringUtils.decomposeParts(location.hash).map((part) => part.value);

		parts[3] = id;
		const hash = parts.join("/");

		this.props.navigation.go(hash);
	};

	private onCloseCreate = (createdId?: string) => {
		this.setState({
			creating: false,
			selectedId: createdId || this.state.selectedId,
		});
	};

	private onSelect = (id: string) => {
		this.setState({
			selectedId: id,
		});
	};

	private onDeleteClick = async (id: string) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(XyiconFeature.PermissionSet, 1);

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				const item = this.props.appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

				this.deleteItems(item);
			}
		}
	};

	private async deleteItems(item: PermissionSet) {
		const items = [];

		items.push(item);

		try {
			await this.props.transport.appState.actions.deleteItems(items, XyiconFeature.PermissionSet);
		} catch (error) {
			console.log(error);
		}
	}

	private onPermissionSetSave = async (id: string, name: string) => {
		const permissionSet = this.props.appState.actions.getFeatureItemById<PermissionSet>(id, XyiconFeature.PermissionSet);

		try {
			permissionSet.name = name;

			const params: UpdatePortfolioPermissionSetRequest = {
				name: name,
				portfolioPermissionSetID: permissionSet.id,
				portfolioFieldPermissions: permissionSet.portfolioFieldPermissions,
				portfolioPermission: permissionSet.portfolioPermission,
				featurePermissions: permissionSet.featurePermissions,
			};

			const {result, error} = await this.props.transport.requestForOrganization<PortfolioPermissionSetsDto>({
				url: "portfoliopermissionsets/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			});

			if (error) {
				console.error(error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	public override render() {
		const {param2, appState} = this.props;
		const {selectedId, searchQuery} = this.state;
		const activeTab = param2 || "types";

		const permissionSets = appState.actions.getList<PermissionSet>(XyiconFeature.PermissionSet);
		const selected = permissionSets.find((set) => set.id === selectedId);

		const {creating} = this.state;

		return (
			<OrganizationSettingsStyled>
				<div
					ref={this._container}
					className="PermissionSets"
				>
					<div className="hbox flex_1 ">
						<div className="setList">
							<div className="setCreate">
								<h4>Permission sets</h4>
								<ButtonV5
									label="CREATE"
									onClick={this.onPopupOpen}
									ref={this.parentRef}
								>
									<CirclePlusIcon />
								</ButtonV5>
							</div>

							<SearchFieldV5
								placeholder="Find..."
								className="findInput"
								value={searchQuery}
								fullWidth={true}
								onInput={(value) => this.setState({searchQuery: value})}
							/>

							<div className="list">
								{permissionSets
									.filter((permissionSet) => StringUtils.containsIgnoreCase(permissionSet.name, searchQuery))
									.map((permissionSet) => (
										<PermissionSetItemV5
											key={permissionSet.id}
											id={permissionSet.id}
											selected={selectedId === permissionSet.id}
											onSelect={this.onSelect}
											onDelete={this.onDeleteClick}
											onSave={this.onPermissionSetSave}
										/>
									))}
							</div>
						</div>
						{selected && (
							<TabViewV5
								className="settingsTab"
								selectedTabId={activeTab}
								onChangeSelectedTabId={this.onChangeSettingsTab}
							>
								<TabChildV5
									id="portfolios"
									label="Portfolios"
									title="Portfolios"
								>
									<ModulePermissionsV5
										feature={XyiconFeature.Portfolio}
										permissionSet={selected}
									/>
								</TabChildV5>
								<TabChildV5
									id="spaces"
									label="Spaces"
									title="Spaces"
								>
									<ModulePermissionsV5
										feature={XyiconFeature.Space}
										permissionSet={selected}
									/>
								</TabChildV5>
								<TabChildV5
									id="xyicons"
									label="Xyicons"
									title="Xyicons"
								>
									<ModulePermissionsV5
										feature={XyiconFeature.Xyicon}
										permissionSet={selected}
									/>
								</TabChildV5>
								<TabChildV5
									id="boundaries"
									label="Boundaries"
									title="Boundaries"
								>
									<ModulePermissionsV5
										feature={XyiconFeature.Boundary}
										permissionSet={selected}
									/>
								</TabChildV5>
							</TabViewV5>
						)}
					</div>
				</div>
				{this.state.creating && (
					<PopupV5
						onClose={this.onPopupClose}
						label="New Permission Set"
						parentRef={this.parentRef.current}
						className="PermissionSetCreation"
						width="400px"
						height="480px"
						freezeRoot={true}
						verticalAlignment={VerticalAlignment.bottomOuter}
						horizontalAlignment={HorizontalAlignment.right}
						isSmallPopup={true}
					>
						<CreatePopupFieldStyled>
							<CreatePermissionSetPanelV5 onClose={this.onCloseCreate} />
						</CreatePopupFieldStyled>
					</PopupV5>
				)}
			</OrganizationSettingsStyled>
		);
	}
}

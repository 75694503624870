import * as React from "react";
import {inject} from "mobx-react";
import {ColorSelector} from "../../abstract/common/colorselector/ColorSelector";
import {SizeChanger} from "../../abstract/common/sizechanger/SizeChanger";
import {TextGroupManager} from "../../space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import {TextAlignButton} from "../../space/spaceeditor/ui/actionbar/TextAlignButton";
import {TextBoxAlignButton} from "../../space/spaceeditor/ui/actionbar/TextBoxAlignButton";
import {FontStyleContainer} from "../../space/spaceeditor/ui/viewbar/FontStyleContainer";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {SelectInput} from "../../../widgets/input/select/SelectInput";
import type {Color} from "../../../../generated/api/base";
import type {App} from "../../../../App";
import type {SupportedFontName} from "../../../../data/state/AppStateTypes";

interface ITextStyleModifierProps {
	readonly isBold: boolean;
	readonly isItalic: boolean;
	readonly isUnderlined: boolean;
	readonly fontFamily: SupportedFontName;
	readonly fontSize: number;
	readonly fontColor: Color;

	readonly onIsBoldChange: (value: boolean) => void;
	readonly onIsItalicChange: (value: boolean) => void;
	readonly onIsUnderlinedChange: (value: boolean) => void;
	readonly onFontFamilyChange: (newFontFamily: SupportedFontName) => void;
	readonly onFontSizeChange: (newFontSize: number) => void;
	readonly onFontColorChange: (newFontColor: Color) => void;
	readonly onClearTextClick: () => void;
	readonly onTextBoxAlignmentChange?: (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => void;
	readonly onTextAlignmentChange?: (horizontalAlignment: HorizontalAlignment) => void;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly fontStyleDropdownZIndex?: number;
	readonly onClose?: () => void;
	readonly app?: App;
}

@inject("app")
export class TextStyleModifier extends React.Component<ITextStyleModifierProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private onIsBoldChange = () => {
		this.props.onIsBoldChange(!this.props.isBold);
	};

	private onIsItalicChange = () => {
		this.props.onIsItalicChange(!this.props.isItalic);
	};

	private onIsUnderlinedChange = () => {
		this.props.onIsUnderlinedChange(!this.props.isUnderlined);
	};

	public override render() {
		const {
			app,
			isBold,
			isItalic,
			isUnderlined,
			fontColor,
			fontFamily,
			fontSize,
			onFontSizeChange,
			onFontColorChange,
			onFontFamilyChange,
			onTextBoxAlignmentChange,
			onClearTextClick,
			horizontalAlignment,
			verticalAlignment,
			onTextAlignmentChange,
			fontStyleDropdownZIndex,
		} = this.props;
		const {eyeDropperProps} = app.spaceViewRenderer;

		return (
			<div
				className="TextStyleModifier hbox"
				ref={this._ref}
			>
				<FontStyleContainer
					fontStyleSettings={{isBold, isItalic, isUnderlined}}
					onIsBoldChange={this.onIsBoldChange}
					onIsItalicChange={this.onIsItalicChange}
					onIsUnderlinedChange={this.onIsUnderlinedChange}
					onClose={this.props.onClose}
				/>
				<SizeChanger
					value={fontSize}
					onChange={onFontSizeChange}
				/>
				<SelectInput
					onChange={onFontFamilyChange}
					options={TextGroupManager.supportedFontNames}
					selected={fontFamily}
					optionsZIndex={fontStyleDropdownZIndex ?? 8500}
				/>
				{onTextBoxAlignmentChange && (
					<TextBoxAlignButton
						horizontalAlignment={horizontalAlignment}
						verticalAlignment={verticalAlignment}
						onTextAlignmentChange={onTextBoxAlignmentChange}
					/>
				)}
				{onTextAlignmentChange && (
					<TextAlignButton
						horizontalAlignment={horizontalAlignment}
						onTextAlignmentChange={onTextAlignmentChange}
					/>
				)}
				<ColorSelector
					title="Text Color"
					color={fontColor}
					onColorChange={onFontColorChange}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.outerRight}
					verticalAlignment={VerticalAlignment.bottom}
					outerDivRef={this._ref}
					icon="text-color"
					classNames="text-colorselector"
				/>
				<div
					title="Clear Text"
					className="btn"
					onClick={onClearTextClick}
					style={{backgroundImage: "url(src/assets/images/spaceviewer/clearText.svg)"}}
				/>
			</div>
		);
	}
}

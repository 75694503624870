import * as React from "react";
import {NumberInputV5} from "../../../details/datatypes/numeric/NumberInputV5";

interface INumberParamV5Props {
	readonly param: number;
	onChange(value: number): void;
}

export class NumberParamV5 extends React.Component<INumberParamV5Props> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<NumberInputV5
				value={param || 0}
				onInput={(value) => onChange(value)}
			/>
		);
	}
}

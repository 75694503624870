import * as React from "react";
import {inject, observer} from "mobx-react";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {Space} from "../../../../../../data/models/Space";
import type {AppState} from "../../../../../../data/state/AppState";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {Button} from "../../../../../widgets/button/Button";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {MultiActionButton} from "../../../../../widgets/button/multiaction/MultiActionButton";
import {CheckboxInput} from "../../../../../widgets/input/checkbox/CheckboxInput";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {SpaceExportWindow} from "../../../../abstract/popups/SpaceExportWindow";
import {SpaceToPDFExporter} from "./SpaceToPDFExporter";
import type {SpaceToPDFExportType} from "./SpaceToPDFExporter";

interface ISpaceToPDFExportPanelProps {
	readonly appState?: AppState;
	readonly isOpen: boolean;
	readonly onClose: () => void;
}

interface ISpaceToPDFExportPanelState {
	checkedSpaceIds: Set<string>;
	searchString: string;
	isLoading: boolean;
	exportingProgress: number;
}

@inject("appState")
@observer
export class SpaceToPDFExportPanel extends React.Component<ISpaceToPDFExportPanelProps, ISpaceToPDFExportPanelState> {
	private _checkboxAll = React.createRef<HTMLInputElement>();

	constructor(props: ISpaceToPDFExportPanelProps) {
		super(props);

		this.state = {
			checkedSpaceIds: new Set(this.getAllSpaces().map((s) => s.id)),
			searchString: "",
			isLoading: false,
			exportingProgress: 0,
		};
	}

	private sortSpace = (a: Space, b: Space) => {
		return StringUtils.sortIgnoreCase(a.name, b.name);
	};

	private getAllSpaces() {
		return this.props.appState.actions.getList<Space>(XyiconFeature.Space).slice().sort(this.sortSpace);
	}

	private onSpaceToggle = (spaceId: string, isChecked: boolean) => {
		const newSet = new Set(this.state.checkedSpaceIds);

		if (isChecked) {
			newSet.add(spaceId);
		} else {
			newSet.delete(spaceId);
		}

		this.setState({
			checkedSpaceIds: newSet,
		});
	};

	private onExportAsSingleFileClick = async () => {
		const isExported = await this.exportSpacesAsPDF("SingleFile");

		if (isExported) {
			this.props.onClose();
		}
	};

	private onExportAsMultipleFilesClick = async () => {
		const isExported = await this.exportSpacesAsPDF("MultipleFiles");

		if (isExported) {
			this.props.onClose();
		}
	};

	private getExportWindowData = (type: SpaceToPDFExportType, spacesToExport: Space[]): [string[], string[], number] => {
		const {portfolioId, actions} = this.props.appState;

		if (type === "SingleFile") {
			return [[""], [actions.getPortfolioById(portfolioId).name], 1];
		} else {
			const placeHolders: string[] = [];
			const defaultValues: string[] = [];

			spacesToExport.forEach((space) => {
				placeHolders.push("");
				defaultValues.push(space.name);
			});

			return [placeHolders, defaultValues, spacesToExport.length];
		}
	};

	private async exportSpacesAsPDF(type: SpaceToPDFExportType) {
		const spacesToExport = this.getAllSpaces().filter((s) => this.state.checkedSpaceIds.has(s.id));
		const [placeHolders, defaultValues, fileCount] = this.getExportWindowData(type, spacesToExport);
		const names = await SpaceExportWindow.open("", "Export file as", placeHolders, defaultValues, fileCount);

		if (!names) {
			return false;
		}

		this.setState({
			isLoading: true,
		});

		if (type === "SingleFile") {
			const pdfExporter = new SpaceToPDFExporter(this.props.appState);

			await pdfExporter.export("entire", spacesToExport, names[0], this.onPDFExportProgress);
		} else {
			for (let i = 0; i < spacesToExport.length; ++i) {
				const space = spacesToExport[i];
				const pdfExporter = new SpaceToPDFExporter(this.props.appState);

				await pdfExporter.export("entire", [space], names[i]);
				this.onPDFExportProgress((i + 1) / spacesToExport.length);
			}
		}

		this.setState({
			isLoading: false,
			exportingProgress: 0,
		});

		return true;
	}

	private onPDFExportProgress = (progress: number) => {
		this.setState({
			exportingProgress: progress,
		});
	};

	private areAllSpacesChecked = () => {
		const checkBox = this._checkboxAll.current;
		const allSpaces = this.getAllSpaces().length;
		const allCheckedSpaces = this.state.checkedSpaceIds.size;

		if (checkBox) {
			checkBox.indeterminate = allCheckedSpaces > 0 && allSpaces !== allCheckedSpaces;
		}

		return allSpaces === allCheckedSpaces;
	};

	private onToggleAll = (value: boolean) => {
		const newSet = new Set<string>(value ? this.getAllSpaces().map((s) => s.id) : []);

		this.setState({
			checkedSpaceIds: newSet,
		});
	};

	private onSearchChange = (value: string) => {
		this.setState({
			searchString: value,
		});
	};

	private isExportDisabled() {
		return this.state.isLoading || this.state.checkedSpaceIds.size === 0;
	}

	private areCheckboxesDisabled() {
		return this.state.isLoading;
	}

	private getButtonLabel() {
		return this.state.isLoading ? `Exporting... ${Math.round(this.state.exportingProgress * 100)}%` : "Export as a Single File";
	}

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				if (this.props.isOpen) {
					this.props.onClose();
				}
				break;
		}
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
	}

	public override render() {
		const isExportDisabled = this.isExportDisabled();
		const areCheckboxesDisabled = this.areCheckboxesDisabled();
		const buttonLabel = this.getButtonLabel();

		const rowClassNames = ReactUtils.cls("item hbox alignCenter", {disabled: areCheckboxesDisabled});

		return (
			<>
				{this.props.isOpen && (
					<div
						className="shadowDiv"
						onClick={this.props.onClose}
					/>
				)}
				<div className={ReactUtils.cls("SpaceToPDFExportPanel SidePanel createPanel", {open: this.props.isOpen})}>
					<div className="heading hbox createBox">
						<h4 className="detailsTitle">{`Export Multiple Spaces from ${this.props.appState.actions.getCurrentPortfolioName()}`}</h4>
						<IconButton
							icon="close"
							title="Close Panel"
							className="close"
							onClick={this.props.onClose}
						/>
					</div>
					<div className="buttonWrapper hbox">
						<div className="flex_1"></div>
						<Button
							className="secondary"
							label="Cancel"
							onClick={this.props.onClose}
						/>
						<MultiActionButton
							className="secondary"
							disabled={isExportDisabled}
							options={[
								{
									id: "single",
									label: buttonLabel,
									onClick: this.onExportAsSingleFileClick,
									disabled: isExportDisabled,
								},
								{
									id: "multiple",
									label: "Export as Multiple Files",
									onClick: this.onExportAsMultipleFilesClick,
									disabled: isExportDisabled || this.state.checkedSpaceIds.size < 2,
								},
							]}
						/>
					</div>
					<SearchField
						placeholder="Find in List..."
						value={this.state.searchString}
						onInput={this.onSearchChange}
					/>
					<div className="spaceList">
						<div className={rowClassNames}>
							<CheckboxInput
								inputRef={this._checkboxAll}
								value={this.areAllSpacesChecked()}
								onChange={this.onToggleAll}
								disabled={this.state.isLoading}
							/>
							<div className="label">
								<strong>All</strong>
							</div>
						</div>
						{this.getAllSpaces().map(
							(space: Space) =>
								StringUtils.containsIgnoreCase(space.name, this.state.searchString) && (
									<div
										className={rowClassNames}
										key={space.id}
									>
										<CheckboxInput
											value={this.state.checkedSpaceIds.has(space.id)}
											onChange={(value: boolean) => this.onSpaceToggle(space.id, value)}
											disabled={this.state.isLoading}
										/>
										<div className="label">{space.name}</div>
									</div>
								),
						)}
					</div>
				</div>
			</>
		);
	}
}

import styled from "styled-components";
import {ELLIPSIS} from "../../styles/styles";

interface ISingleLineLabelProps {
	readonly value: string;
}

export const SingleLineLabelV5 = (props: ISingleLineLabelProps) => {
	return (
		<SingleLineLabelStyled
			className="SingleLineLabel"
			dangerouslySetInnerHTML={{__html: props.value || ""}}
		/>
	);
};

export const SingleLineLabelStyled = styled.div`
	display: flex;
	align-items: center;
	${ELLIPSIS}
	min-height: 16px;
`;

import * as React from "react";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import {HorizontalAlignment} from "../../../../../../utils/dom/DomUtils";
import {FocusLoss} from "../../../../../../utils/ui/focus/FocusLoss";

interface ITextAlignButtonProps {
	readonly horizontalAlignment: HorizontalAlignment;
	readonly onTextAlignmentChange: (horizontalAlignment: HorizontalAlignment) => void;
}

interface ITextAlignButtonState {
	isOpen: boolean;
}

const alignments = [HorizontalAlignment.left, HorizontalAlignment.center, HorizontalAlignment.right];

export class TextAlignButton extends React.Component<ITextAlignButtonProps, ITextAlignButtonState> {
	private _alignButton = React.createRef<HTMLSpanElement>();
	constructor(props: ITextAlignButtonProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	private onTextAlignClick = () => {
		this.setState({isOpen: !this.state.isOpen});

		FocusLoss.listen(this._alignButton.current, this.onFocusLoss);
	};

	private onFocusLoss = () => {
		this.setState({isOpen: false});

		FocusLoss.stopListen(this._alignButton.current, this.onFocusLoss);
	};

	public override render() {
		const {horizontalAlignment, onTextAlignmentChange} = this.props;
		const {isOpen} = this.state;

		return (
			<span
				ref={this._alignButton}
				onClick={this.onTextAlignClick}
				className="button flexCenter textAlignButton"
				title="Align text..."
			>
				<SVGIcon icon={`${HorizontalAlignment[horizontalAlignment]}-align`}></SVGIcon>
				<div className="arrowWrapper">
					<i className="arrowDown corner"></i>
				</div>
				{isOpen && (
					<div className="TextAlignOptions oneRow">
						{alignments.map((alignment, index) => (
							<div
								key={index}
								className="textAlignButton flexCenter"
								onClick={() => onTextAlignmentChange(alignment)}
							>
								<SVGIcon icon={`${HorizontalAlignment[alignment]}-align`} />
							</div>
						))}
					</div>
				)}
			</span>
		);
	}
}

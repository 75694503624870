import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {XyiconFeature} from "../../../../../generated/api/base";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SelectSliderContainerStyled} from "../../../details/SelectSliderV5";
import {GeneralPermissionsV5} from "./GeneralPermissionsV5";
import {TypePermissionsV5} from "./TypePermissionsV5";
import {FieldPermissionsV5} from "./FieldPermissionsV5";

const ModulePermissionsStyled = styled.div`
	.ModulePermissions {
		display: flex;
		width: 1240px;

		&.gapLeft {
			gap: 40px;
		}

		.flex_1 {
			max-width: 600px;

			&.general {
				border-right: solid 2px #eaeaea;
				max-width: 232px;
				padding: 16px;
				height: 100vh;
			}
		}

		.container {
			height: 90vh;
			overflow: hidden;

			${SelectSliderContainerStyled} {
				overflow-y: auto;
				overflow-x: hidden;
				margin-bottom: 80px;
			}
		}
	}

	@media only screen and (max-width: 1836px) {
		.ModulePermissions {
			display: block;
			height: 80vh;
			overflow-y: auto;

			.flex_1 {
				width: 600px;
				height: auto;
				margin-bottom: 40px;

				&.general {
					border-right: none;
					height: auto;
				}
			}

			> div {
				border: none;
			}

			.container {
				height: auto;
				overflow: hidden;

				${SelectSliderContainerStyled} {
					overflow-y: hidden;
					margin-bottom: 0px;
				}

				&.paddingTop {
					padding-top: 0px;
				}
			}
		}
	}
`;

interface IModulePermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly feature: XyiconFeature;
}

@observer
export class ModulePermissionsV5 extends React.Component<IModulePermissionsProps> {
	public override render() {
		const {permissionSet, feature} = this.props;

		return (
			<ModulePermissionsStyled>
				<div className={ReactUtils.cls("ModulePermissions", {gapLeft: feature !== XyiconFeature.Portfolio})}>
					<div className={`vbox flex_1 ${feature === XyiconFeature.Portfolio ? "general" : ""}`}>
						{feature === XyiconFeature.Portfolio ? (
							<GeneralPermissionsV5 permissionSet={permissionSet} />
						) : (
							<TypePermissionsV5
								permissionSet={permissionSet}
								feature={feature}
							/>
						)}
					</div>
					<div className="flex_1">
						<FieldPermissionsV5
							permissionSet={permissionSet}
							feature={feature}
						/>
					</div>
				</div>
			</ModulePermissionsStyled>
		);
	}
}

import type {Catalog} from "../../../data/models/Catalog";
import type {Xyicon} from "../../../data/models/Xyicon";
import {XyiconFeature} from "../../../generated/api/base";
import type {ISpaceItemContainerProps} from "../../modules/space/spaceeditor/ui/toolbar/SpaceItemContainer";
import {SpaceItemContainerV5} from "../spaceeditor/SpaceItemContainerV5";

interface IDraggableXyiconCatalogItemProps extends ISpaceItemContainerProps {
	readonly item: Catalog | Xyicon;
	readonly isSelected: boolean;
	readonly queryString: string;
}

export const DraggableXyiconCatalogItemV5 = (props: IDraggableXyiconCatalogItemProps) => {
	const {
		item,
		isSelected,
		onPointerDown,
		onPointerMove,
		onPointerUp,
		showInfoButton,
		onBreakLinkClick,
		onDuplicateCatalogClick,
		onCreateUnplottedXyiconClick,
		queryString,
	} = props;

	return (
		<SpaceItemContainerV5
			item={item}
			isSelected={isSelected}
			onPointerDown={onPointerDown}
			onPointerMove={onPointerMove}
			onPointerUp={onPointerUp}
			showInfoButton={showInfoButton}
			showDeleteButton={item.ownFeature === XyiconFeature.Xyicon}
			onBreakLinkClick={onBreakLinkClick}
			onDuplicateCatalogClick={onDuplicateCatalogClick}
			onCreateUnplottedXyiconClick={onCreateUnplottedXyiconClick}
			queryString={queryString}
		/>
	);
};

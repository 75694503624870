import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IError, TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {TextInput} from "../../widgets/input/text/TextInput";
import {Button} from "../../widgets/button/Button";
import {PasswordInput} from "../../widgets/input/pssword/PasswordInput";
import {ReactUtils} from "../../utils/ReactUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {PasswordValidator} from "../../widgets/password/PasswordValidator";
import {PassSecurityLevel, PasswordUtils} from "../../widgets/password/PasswordUtils";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {DomPortal} from "../abstract/portal/DomPortal";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {InfoBubble} from "../abstract/common/infobutton/InfoBubble";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";

interface IForgottenPasswordResetProps {
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly appState?: AppState;
}

interface IForgottenPasswordResetStates {
	email: string;
	verificationEmailSent: boolean;
	verificationEmailError: boolean;
	tokenID: string;
	token: string;
	password: string;
	passwordConfirm: string;
	loading: boolean;
	verificatedUserEmail: string;
	verificationError: IError;
	verificationIsInProgress: boolean;
	resetInProgress: boolean;
	passwordToolTipTransform: TransformObj;
	emailToolTipTransform: TransformObj;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class ForgottenPasswordReset extends React.Component<IForgottenPasswordResetProps, IForgottenPasswordResetStates> {
	private _passwordParent = React.createRef<HTMLInputElement>();
	private _password = React.createRef<HTMLDivElement>();
	private _emailParent = React.createRef<HTMLInputElement>();
	private _email = React.createRef<HTMLDivElement>();

	constructor(props: IForgottenPasswordResetProps) {
		super(props);
		this.state = {
			email: "",
			verificationEmailSent: false,
			verificationEmailError: false,
			tokenID: "",
			token: "",
			password: "",
			passwordConfirm: "",
			loading: false,
			verificatedUserEmail: null,
			verificationError: null,
			verificationIsInProgress: false,
			resetInProgress: false,
			passwordToolTipTransform: null,
			emailToolTipTransform: null,
		};
	}

	private onSubmit = async () => {
		this.setState({loading: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/forgottenpassword",
			method: XHRLoader.METHOD_POST,
			params: {email: this.state.email},
		});

		if (error) {
			this.setState({
				loading: false,
				verificationEmailError: true,
			});
		} else {
			this.setState({
				loading: false,
				verificationEmailSent: true,
			});
		}
	};

	private onChange = async () => {
		this.setState({loading: true});

		const {token, tokenID, password} = this.state;
		const {result, error} = await this.props.transport.request({
			url: "auth/resetforgottenpassword",
			method: XHRLoader.METHOD_POST,
			params: {
				tokenID: tokenID,
				token: token,
				newPassword: password,
			},
		});

		if (error) {
			console.log(error);
		} else if (result) {
			// We need to wait a bit in order to users can read the notification.
			// We wait randomly between 3 and 6 sec. (Random time is better, because of UX reasons)
			this.setState({resetInProgress: true});
			window.setTimeout(() => this.props.navigation.goToLogin(), Math.random() * 3000 + 3000);
		}
	};

	private onGoToLoginClick = () => {
		this.props.navigation.goToLogin();
	};

	private get passwordConfirmError() {
		return this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password ? "Password do not match." : "";
	}

	private handleNewPasswordInput = (value: string) => {
		this.setState({
			password: value,
		});
	};

	private handlePasswordConfirm = (value: string) => {
		this.setState({
			passwordConfirm: value,
		});
	};

	private handleEmailInput = (value: string) => {
		this.setState({
			email: value,
			verificationEmailError: this.state.verificationEmailError && !value,
		});
	};

	private isInvalidEmail = () => {
		const email = this.state.email;
		const emailError = this.state.verificationEmailError;

		let isValid = true;

		if (email) {
			isValid = StringUtils.emailValidator(email);
		}

		if (emailError) {
			isValid = false;
		}

		return !isValid;
	};

	public override componentDidUpdate = (prevProps: IForgottenPasswordResetProps, prevState: IForgottenPasswordResetStates) => {
		if (this.passwordConfirmError && !prevState.passwordConfirm) {
			this.setState({
				passwordToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._passwordParent.current,
					this._password.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
				),
			});
		}

		if (prevState.verificationEmailError !== this.state.verificationEmailError && this.state.verificationEmailError) {
			this.setState({
				emailToolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._emailParent.current,
					this._email.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.right,
				),
			});
		}
	};

	private renderContent() {
		const {
			password,
			passwordConfirm,
			verificatedUserEmail,
			verificationError,
			verificationEmailSent,
			email,
			loading,
			token,
			resetInProgress,
			verificationEmailError,
		} = this.state;

		const passwordInlineStyle: React.CSSProperties = {
			top: "5px",
			left: "-5px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: this.state.passwordToolTipTransform?.translate,
		};

		const emailInlineStyle: React.CSSProperties = {
			top: "8px",
			left: "-5px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: this.state.emailToolTipTransform?.translate,
		};

		if (verificatedUserEmail) {
			return (
				<>
					{loading && resetInProgress ? (
						<>
							<div className="description">
								Redirecting to Xyicon Login...
								<a
									className="textLink"
									onClick={this.onGoToLoginClick}
								>
									Click here
								</a>{" "}
								if not redirected.
							</div>
						</>
					) : (
						<>
							<div className="description-forgotpswd">Type in a new password to be able to use with Xyicon, Make sure its secure and memorable</div>

							<div className="inputGroup">
								<PasswordInput
									placeholder="Password"
									value={password}
									onInput={this.handleNewPasswordInput}
								/>
							</div>
							<div className="inputGroup password">
								<PasswordInput
									placeholder="Confirm Password"
									value={passwordConfirm}
									onInput={this.handlePasswordConfirm}
									divRef={this._passwordParent}
								/>
								{this.passwordConfirmError && (
									<div className="passworderror">
										<DomPortal destination={this.props.appState.app.modalContainer}>
											<div
												ref={this._password}
												className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
												style={passwordInlineStyle}
											>
												{<SVGIcon icon="info" />}
												<InfoBubble
													content={"Password do not match."}
													isErrorMessage={true}
													style={{width: "200px"}}
												/>
											</div>
										</DomPortal>
									</div>
								)}
							</div>
							<div className="inputGroup">
								<PasswordValidator password={password} />
							</div>
						</>
					)}
				</>
			);
		} else if (verificationError) {
			return (
				<>
					<div className="fieldContainer vbox description">
						Forgot password link has expired. Please request for new link through login page.<br></br>
						<br></br>
						<a
							className="textLink"
							onClick={this.onGoToLoginClick}
						>
							Go to login page
						</a>
					</div>
				</>
			);
		} else if (!token) {
			if (verificationEmailSent) {
				return (
					<div className="description-forgotpswd">
						An email has been sent to{" "}
						<span>
							<b>{email}</b>
						</span>
						, with instruction to reset your password.
					</div>
				);
			} else {
				return (
					<>
						<div className="description-forgotpswd">
							To reset your password, enter the email address you used to register with Xyicon.<br></br>
							<br></br>
							We will send a link to reset your password.
						</div>

						<div className="inputGroup">
							<TextInput
								onInput={this.handleEmailInput}
								placeholder="Email"
								className={ReactUtils.cls({redBorder: this.isInvalidEmail()})}
								divRef={this._emailParent}
							/>
							{verificationEmailError && (
								<DomPortal destination={this.props.appState.app.modalContainer}>
									<div
										ref={this._email}
										className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
										style={emailInlineStyle}
									>
										{<SVGIcon icon="info" />}
										<InfoBubble
											content={"Invalid Email."}
											isErrorMessage={true}
											style={{minWidth: "84px"}}
										/>
									</div>
								</DomPortal>
							)}
						</div>
					</>
				);
			}
		}
	}

	private verifyForgottenPasswordToken = async (tokenID: string, token: string) => {
		this.setState({verificationIsInProgress: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/verifyforgottenpasswordtoken",
			method: XHRLoader.METHOD_POST,
			params: {tokenID, token},
		});

		this.setState({
			verificatedUserEmail: result?.email,
			verificationError: error,
			verificationIsInProgress: false,
		});
	};

	private getHeaderContent = () => {
		const {verificatedUserEmail, verificationError, verificationEmailSent, loading, token, resetInProgress} = this.state;

		if (verificatedUserEmail) {
			return (
				<>
					{loading && resetInProgress ? (
						<>
							<SVGIcon
								classNames="xyicon-logo"
								icon="xyiconlogo"
							/>
							<div className="title">Password reset successful!</div>
						</>
					) : (
						<>
							<div className="title">Reset Password</div>
							<div className="desc">{verificatedUserEmail}</div>
						</>
					)}
				</>
			);
		} else if (verificationError) {
			return (
				<>
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<div className="title">Reset Password</div>
				</>
			);
		} else if (!token) {
			if (verificationEmailSent) {
				return (
					<>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className="title">Check Mail</div>
					</>
				);
			} else {
				return <div className="title">Forgot your password?</div>;
			}
		}
	};

	private onCancelClick = () => {
		this.props.navigation.goToLogin();
	};

	public override componentDidMount() {
		const href = location.href;
		const tokenID = ReactUtils.searchForParamInUrl("tokenid", href);
		const token = ReactUtils.searchForParamInUrl("token", href);

		if (tokenID && token) {
			this.setState({token, tokenID});
			this.verifyForgottenPasswordToken(tokenID, token);
		}
	}

	public override render() {
		const {
			verificatedUserEmail,
			verificationError,
			verificationEmailSent,
			loading,
			password,
			passwordConfirm,
			email,
			verificationIsInProgress,
			resetInProgress,
		} = this.state;

		if (verificationIsInProgress) {
			return (
				<div className="UserStaticPageWrapper">
					<div className="preLoadingPage">
						<div className="vbox alignCenter">
							<LoaderIcon />
							<p>Verification in progress ...</p>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="UserStaticPageWrapper">
					<div className={ReactUtils.cls("ForgottenPasswordReset UserStaticPage", {expiredForgottenPasswordReset: !!verificationError})}>
						<div className="formContainer hbox alignCenter">
							{this.getHeaderContent()}
							<div className={ReactUtils.cls("fieldContainer vbox", {emailSent: verificationEmailSent})}>
								{this.renderContent()}
								<div className="hbox btns">
									{verificatedUserEmail && !resetInProgress && (
										<Button
											className="primary"
											label="Change"
											onClick={this.onChange}
											loading={loading}
											disabled={
												PasswordUtils.getPasswordSecurityLevel(password) < PassSecurityLevel.Weak ||
												!password ||
												!passwordConfirm ||
												password !== passwordConfirm
											}
										/>
									)}
									{!(verificatedUserEmail || verificationError) && !verificationEmailSent && !loading && (
										<Button
											className="secondary"
											label="Cancel"
											onClick={this.onCancelClick}
										/>
									)}
									{!(verificatedUserEmail || verificationError) && !verificationEmailSent && (
										<Button
											className="primary"
											label="Submit"
											onClick={this.onSubmit}
											loading={loading}
											disabled={!StringUtils.emailValidator(email)}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

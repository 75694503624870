import * as React from "react";
import {inject, observer} from "mobx-react";
import {ImageUtils} from "../../../../utils/image/ImageUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {IconButton} from "../../../widgets/button/IconButton";
import {SearchField} from "../../../widgets/input/search/SearchField";
import type {AppState} from "../../../../data/state/AppState";
import {openKeywordsPopupForFile} from "./CatalogUtils";

interface IGoogleImagePanelProps {
	readonly appState?: AppState;
	readonly defaultSearchValue: string;
	readonly onCloseClick: () => void;
	readonly addImage: (file: File, keywords: string[]) => Promise<void>;
}

interface IGoogleImagePanelState {
	search: string;
	searchResult: IGoogleResponse | null;
}

interface IGoogleItem {
	kind: string;
	title: string;
	link: string;
	displayLink: string;
	snippet: string;
	mime: string;
	fileFormat: string;
	image: {
		width: number;
		height: number;
		byteSize: number;
		thumbnailLink: string;
		thumbnailWidth: number;
		thumbnailHeight: number;
	};
}

interface IGoogleResponse {
	items: IGoogleItem[];
}

@inject("appState")
@observer
export class GoogleImagePanel extends React.Component<IGoogleImagePanelProps, IGoogleImagePanelState> {
	private _timeoutId: number = 0;

	constructor(props: IGoogleImagePanelProps) {
		super(props);

		this.state = {
			search: this.props.defaultSearchValue || "",
			searchResult: null,
		};

		this.search(this.state.search);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: IGoogleImagePanelProps) {
		if (nextProps.defaultSearchValue !== this.state.search) {
			this.setState({
				search: nextProps.defaultSearchValue,
			});

			this.search(nextProps.defaultSearchValue);
		}
	}

	private onSearchInput = (searchValue: string) => {
		this.setState({
			search: searchValue,
		});

		this.search(searchValue);
	};

	private search(searchValue: string) {
		clearTimeout(this._timeoutId);

		if (searchValue) {
			this._timeoutId = window.setTimeout(async () => {
				const googleResponse = await XHRLoader.loadAsync<IGoogleResponse>({
					url: "https://www.googleapis.com/customsearch/v1",
					method: XHRLoader.METHOD_GET,
					params: {
						rights: "cc_publicdomain,cc_attribute,cc_nonderived",
						q: searchValue,
						cx: "008962485971625166487:6yuvs5ridps",
						searchType: "image",
						key: "AIzaSyCXqaHGgfTWGoz--edjJxJa3mkyO4wY9tY",
					},
				});

				this.setState({
					searchResult: googleResponse,
				});
			}, 500);
		} else {
			if (this.state.searchResult) {
				this.setState({
					searchResult: null,
				});
			}
		}
	}

	private getResults() {
		return this.state.searchResult?.items.map((item: IGoogleItem, index: number) => {
			return (
				<div
					key={index}
					className="thumbnail"
				>
					<img src={item.image.thumbnailLink} />
					<div className="overlay flexCenter">
						<div
							className="addToLib"
							onClick={async () => {
								let img: HTMLImageElement;

								try {
									// const blob = await XHRLoader.loadAsync<Blob>({
									// 	url: item.link,
									// 	method: XHRLoader.METHOD_GET,
									// 	withCredentials: true,
									// 	json: false
									// });

									img = await ImageUtils.loadImage(item.link);
								} catch (error) {
									console.warn(`Image at the following link can't be downloaded due to CORS policy: ${item.link} So we're using its thumbnail now.`);
								} finally {
									if (!img) {
										img = await ImageUtils.loadImage(item.image.thumbnailLink);
									}
									const toJPG = item.mime !== "image/png";
									const base64 = await ImageUtils.image2RasterBase64String(this.props.appState.fonts, img, Infinity, toJPG);
									const res = await fetch(base64);
									const blob = await res.blob();
									const file = new File([blob], item.title, {type: `image/${toJPG ? "jpeg" : "png"}`});

									await openKeywordsPopupForFile(file, "image", this.props.addImage, this.props.appState.currentUIVersion);
								}
							}}
						>
							Add to library
						</div>
					</div>
				</div>
			);
		});
	}

	public override render() {
		return (
			<div className="GoogleImagePanel vbox">
				<div className="hbox topPanel">
					Google image search
					<IconButton
						icon="close"
						title="Close"
						className="marginLeftAuto"
						onClick={this.props.onCloseClick}
					/>
				</div>
				<SearchField
					onInput={this.onSearchInput}
					value={this.state.search}
				/>
				<div className="googleImages">{this.getResults()}</div>
			</div>
		);
	}
}

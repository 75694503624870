import * as React from "react";
import {Functions} from "../../../../../../utils/function/Functions";
import {ReactUtils} from "../../../../../utils/ReactUtils";

interface IPortSelectAreaProps {
	readonly portId: string | null;
	readonly onClick: (portId: string) => void;
	readonly leaf: boolean;
}

export class PortSelectArea extends React.Component<IPortSelectAreaProps> {
	private onClick = () => {
		this.props.onClick(this.props.portId);
	};

	public override render() {
		return (
			<div
				className={ReactUtils.cls("PortSelectArea hbox alignCenter", {leaf: this.props.leaf})}
				style={{justifyContent: "flex-end"}}
				onClick={this.props.leaf ? this.onClick : Functions.emptyFunction}
			></div>
		);
	}
}

import * as React from "react";
import styled from "styled-components";
import {inject, observer} from "mobx-react";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import XmarkIcon from "../../../icons/xmark.svg?react";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {ImageUtils} from "../../../../../utils/image/ImageUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {openKeywordsPopupForFile} from "../../../../modules/catalog/create/CatalogUtils";
import type {AppState} from "../../../../../data/state/AppState";
import {ButtonV5} from "../../../button/ButtonV5";

interface IGoogleImagePanelProps {
	readonly appState?: AppState;
	readonly defaultSearchValue: string;
	readonly onCloseClick: () => void;
	readonly addImage: (file: File, keywords: string[]) => Promise<void>;
}

interface IGoogleImagePanelState {
	search: string;
	searchResult: IGoogleResponse | null;
	selectedItem: number;
	isLoading: boolean;
}

interface IGoogleItem {
	kind: string;
	title: string;
	link: string;
	displayLink: string;
	snippet: string;
	mime: string;
	fileFormat: string;
	image: {
		width: number;
		height: number;
		byteSize: number;
		thumbnailLink: string;
		thumbnailWidth: number;
		thumbnailHeight: number;
	};
}

interface IGoogleResponse {
	items: IGoogleItem[];
}

@inject("appState")
@observer
export class GoogleImagePanelV5 extends React.Component<IGoogleImagePanelProps, IGoogleImagePanelState> {
	private _timeoutId: number = 0;

	constructor(props: IGoogleImagePanelProps) {
		super(props);

		this.state = {
			search: this.props.defaultSearchValue || "",
			searchResult: null,
			selectedItem: null,
			isLoading: false,
		};

		this.search(this.state.search);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: IGoogleImagePanelProps) {
		if (nextProps.defaultSearchValue !== this.state.search) {
			this.setState({
				search: nextProps.defaultSearchValue,
			});

			this.search(nextProps.defaultSearchValue);
		}
	}

	private search(searchValue: string) {
		clearTimeout(this._timeoutId);

		if (searchValue) {
			this._timeoutId = window.setTimeout(async () => {
				const googleResponse = await XHRLoader.loadAsync<IGoogleResponse>({
					url: "https://www.googleapis.com/customsearch/v1",
					method: XHRLoader.METHOD_GET,
					params: {
						rights: "cc_publicdomain,cc_attribute,cc_nonderived",
						q: searchValue,
						cx: "008962485971625166487:6yuvs5ridps",
						searchType: "image",
						key: "AIzaSyCXqaHGgfTWGoz--edjJxJa3mkyO4wY9tY",
					},
				});

				this.setState({
					searchResult: googleResponse,
				});
			}, 500);
		} else {
			if (this.state.searchResult) {
				this.setState({
					searchResult: null,
				});
			}
		}
	}

	private getResults() {
		const {isLoading} = this.state;

		return this.state.searchResult?.items?.map((item: IGoogleItem, index: number) => {
			return (
				<div
					key={index}
					className={ReactUtils.cls("thumbnail", {selected: this.state.selectedItem === index})}
					onClick={() => this.setState({selectedItem: index})}
				>
					<img src={item.image.thumbnailLink} />
					<div className="overlay flexCenter">
						<ButtonV5
							label="Add to library"
							className="addToLib"
							onClick={async () => {
								this.setState({isLoading: true});
								let img: HTMLImageElement;

								try {
									img = await ImageUtils.loadImage(item.link);
								} catch (error) {
									console.warn(`Image at the following link can't be downloaded due to CORS policy: ${item.link} So we're using its thumbnail now.`);
								} finally {
									if (!img) {
										img = await ImageUtils.loadImage(item.image.thumbnailLink);
									}
									const toJPG = item.mime !== "image/png";
									const base64 = await ImageUtils.image2RasterBase64String(this.props.appState.fonts, img, Infinity, toJPG);
									const res = await fetch(base64);
									const blob = await res.blob();
									const file = new File([blob], item.title, {type: `image/${toJPG ? "jpeg" : "png"}`});

									await openKeywordsPopupForFile(file, "image", this.props.addImage, this.props.appState.currentUIVersion);
								}

								this.setState({isLoading: false});
							}}
							disabled={isLoading}
						/>
					</div>
				</div>
			);
		});
	}

	public override render() {
		const {isLoading} = this.state;

		return (
			<GoogleItemContainerStyled $disabled={isLoading}>
				<div className="GoogleImagePanel vbox">
					<div className="googleImages">{this.getResults()}</div>
					<IconButtonV5
						IconComponent={XmarkIcon}
						title="Close"
						className="marginLeftAuto"
						onClick={this.props.onCloseClick}
					/>
				</div>
			</GoogleItemContainerStyled>
		);
	}
}

const GoogleItemContainerStyled = styled.div<{$disabled?: boolean}>`
	.GoogleImagePanel {
		height: 416px;
		width: 430px;

		.googleImages {
			flex: 1;
			overflow-y: auto;

			.thumbnail {
				position: relative;
				display: inline-block;
				margin: 2px;
				height: 150px;
				border: 4px solid ${colorPalette.white};

				&.selected {
					border: 4px solid ${colorPalette.primary.c500Primary};
				}

				img {
					height: 143px;
					object-fit: cover;
				}

				.overlay {
					display: none;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: rgba(0, 0, 0, 0.6);

					.addToLib {
						text-align: center;
						pointer-events: ${(props) => (props.$disabled ? "none" : "auto")};
						cursor: ${(props) => (props.$disabled ? "default" : "pointer")};
						padding: 8px;
						color: white;
						font-size: 16px;
						background-color: ${colorPalette.primary.c500Primary};
						border-radius: ${radius.sm};
						border: none;
					}
				}

				&:hover {
					.overlay {
						display: flex;
					}
				}
			}
		}

		${IconButtonStyled} {
			transform: translate(440px, -384px);
			width: 32px;
			height: 32px;
		}
	}
`;

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {AppState} from "../../../../../data/state/AppState";
import {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import {XyiconFeature} from "../../../../../generated/api/base";
import {CreateUserGroupPanelStyled} from "../usergroups/CreateUserGroupPanelV5";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";

interface ICreatePortfolioGroupPanelProps {
	readonly onCreated: (id: string) => void;
	readonly creating: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ICreatePortfolioGroupPanelState {
	isCreating: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePortfolioGroupPanelV5 extends React.PureComponent<ICreatePortfolioGroupPanelProps, ICreatePortfolioGroupPanelState> {
	constructor(props: ICreatePortfolioGroupPanelProps) {
		super(props);
		this.state = {
			isCreating: false,
		};
	}

	private _portfolioGroup = this.createNewPortfolioGroup();

	private createNewPortfolioGroup() {
		return new PortfolioGroup({
			portfolioGroupID: "",
			portfolios: [],
			name: "",
			lastModifiedAt: "",
			lastModifiedBy: "",
		});
	}

	private onCreateClicked = async () => {
		const data = {
			name: this._portfolioGroup.name,
			portfolioList: this._portfolioGroup.portfolioIds,
		};

		try {
			this.setState({isCreating: true});
			const models = await this.props.transport.services.feature.create(data, XyiconFeature.PortfolioGroup);
			this.props.onCreated(models?.[0]?.id);
			this._portfolioGroup = this.createNewPortfolioGroup();
		} catch (e) {
			console.error(e);
		} finally {
			this.setState({isCreating: false});
		}
	};

	private checkIsButtonDisabled = () => {
		if (this._portfolioGroup.name.trim() === "" || !this.isNameValid(this._portfolioGroup.name) || this.state.isCreating) {
			return true;
		} else {
			return false;
		}
	};

	private isNameValid = (name: string) => {
		return this.props.appState.actions.isPortfolioGroupNameValid(name, this._portfolioGroup);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onInput = (value: string) => {
		this._portfolioGroup.name = value;
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ENTER && !this.checkIsButtonDisabled()) {
			this.onCreateClicked();
		}
	};

	private addListeners() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	private removeListeners() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override componentDidMount() {
		this.addListeners();
	}

	public override componentWillUnmount() {
		this.removeListeners();
	}

	public override render() {
		const portfolioGroup = this._portfolioGroup;
		const isButtonDisabled = this.checkIsButtonDisabled();

		return (
			<CreateUserGroupPanelStyled>
				<ClickToEditInputV5
					value={portfolioGroup.name}
					onLiveChange={this.onInput}
					getErrorMessage={this.getErrorMessage}
					placeholder="Name Value"
					focused={this.props.creating}
				/>
				<ButtonV5
					label="Confirm"
					title="Create"
					onClick={this.onCreateClicked}
					disabled={isButtonDisabled}
				/>
			</CreateUserGroupPanelStyled>
		);
	}
}

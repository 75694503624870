import styled from "styled-components";
import type {ExtendedDistanceUnitName} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldV5} from "../../../details/FieldV5";
import {ClickToEditDistanceV5} from "../../../input/clicktoedit/ClickToEditDistanceV5";
import {ClickToEditInputStyled} from "../../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputStyled} from "../../../input/select/SelectInputV5";
import {colorPalette} from "../../../styles/colorPalette";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {fontSize} from "../../../styles/styles";

interface IRealSize {
	value: number;
	unit: ExtendedDistanceUnitName;
}

interface IRealSizeInputProps {
	readonly size: IRealSize;
	readonly onChange: (size: IRealSize) => void;
	readonly label: string;
	readonly getErrorMessage?: (value: number) => string;
	readonly infoText?: string;
}

const RealSizeInputV5Styled = styled.div`
	.Field {
		.label {
			line-height: 24px;
			height: 24px;
			color: #000000;
			font-size: ${fontSize.lg};

			span {
				overflow: unset;
			}
		}

		.element {
			align-items: end;
			margin-right: 0;

			${ClickToEditInputStyled} .unfocused, ${SelectInputStyled} {
				color: ${colorPalette.gray.c950};
				width: 120px;
			}
		}
	}
`;

export const RealSizeInputV5 = (props: IRealSizeInputProps) => {
	const {
		getErrorMessage = (value: number) => (MathUtils.isValidNumber(value) && value > 0 ? "" : "Please provide a valid, positive number"),
		size,
		onChange,
		label,
		infoText,
	} = props;

	return (
		<RealSizeInputV5Styled className="RealSizeInput">
			<FieldV5
				label={label}
				tooltips={{postLabelIconTooltip: infoText}}
			>
				<ClickToEditDistanceV5
					value={size.value}
					unit={Constants.DISTANCE_UNITS[size.unit]}
					onChange={onChange}
					getErrorMessage={getErrorMessage}
				/>
			</FieldV5>
		</RealSizeInputV5Styled>
	);
};

import * as React from "react";
import type {IDistanceUnit, DistanceUnitName} from "../../../modules/space/spaceeditor/logic3d/Constants";
import {FieldDataType} from "../../../../generated/api/base";
import {MathUtils} from "../../../../utils/math/MathUtils";
import type {IRealSize} from "../../../modules/settings/modules/type/form/RealSizeInput";
import {Field} from "../../../../data/models/field/Field";
import {UnitSelector} from "./UnitSelector";
import {ClickToEditInput} from "./ClickToEditInput";

interface IClickToEditDistanceProps {
	readonly layoutType: "vbox" | "hbox";
	readonly value: number;
	readonly unit: IDistanceUnit;
	readonly onChange: (size: IRealSize) => void;
	readonly getErrorMessage: (value: any) => string;
	readonly optionsZIndex?: number;
}

export class ClickToEditDistance extends React.Component<IClickToEditDistanceProps> {
	public static readonly defaultProps: Partial<IClickToEditDistanceProps> = {
		getErrorMessage: (value: string) =>
			MathUtils.isValidNumber(parseFloat(value)) && parseFloat(value) > 0 ? "" : "Please provide a valid, positive number",
		layoutType: "vbox",
	};

	private onValueChange = (value: number) => {
		const errorMessageMaybe = this.props.getErrorMessage(value);
		if (!errorMessageMaybe) {
			this.props.onChange({
				value: value,
				unit: this.props.unit.name,
			});
		}
	};

	public override render() {
		return (
			<div className={`ClickToEditDistance ${this.props.layoutType}`}>
				<ClickToEditInput
					dataTypeSettings={Field.defaultSettings[FieldDataType.Numeric]}
					dataType={FieldDataType.Numeric}
					value={this.props.value}
					onChange={this.onValueChange}
					getErrorMessage={this.props.getErrorMessage}
					noButtons={true}
				/>
				<UnitSelector
					unit={this.props.unit.name}
					onChange={(unitName: DistanceUnitName) => this.props.onChange({value: this.props.value, unit: unitName})}
					optionsZIndex={this.props.optionsZIndex}
				/>
			</div>
		);
	}
}

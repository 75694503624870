import {useEffect, useRef, useState} from "react";
import type {ICaptionSettings} from "../../../../../data/models/ViewUtils";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../../modules/abstract/portal/DomPortal";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {IconButton} from "../../../../widgets/button/IconButton";
import {FloatingCaptionConfiguratorV5} from "./FloatingCaptionConfiguratorV5";

interface IIndividualCaptionStyleProps {
	readonly appState: AppState;
	readonly captionLabel: string;
	readonly fieldRefId: string;
	readonly captionSettings: ICaptionSettings;
	readonly onConfiguratorOpenChange?: (isOpen: boolean) => void;
}

export const IndividualCaptionStyleV5 = (props: IIndividualCaptionStyleProps) => {
	const parentRef = useRef<HTMLDivElement>();
	const floatingRef = useRef<HTMLDivElement>();
	const [isConfiguratorOpen, setIsConfiguratorOpen] = useState<boolean>(false);
	const [configuratorTransform, setConfiguratorTransform] = useState<TransformObj>(null);

	const onCloseClick = () => {
		setIsConfiguratorOpen(false);
		props.onConfiguratorOpenChange(false);
	};

	useEffect(() => {
		const updateTransform = () => {
			if (isConfiguratorOpen && parentRef?.current && floatingRef?.current) {
				const currentTransformStringified = JSON.stringify(configuratorTransform);
				const newTransform = DomUtils.getFixedFloatingElementPosition(
					parentRef.current,
					floatingRef.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.right,
				);
				const newTransformStringified = JSON.stringify(newTransform);

				if (currentTransformStringified !== newTransformStringified) {
					setConfiguratorTransform(newTransform);
				}
			}
		};

		updateTransform();

		const intervalId = window.setInterval(updateTransform, 0);

		return () => {
			window.clearInterval(intervalId);
		};
	}, [isConfiguratorOpen, configuratorTransform]);

	const onResetClick = () => {
		delete props.captionSettings.individualCaptionStyles[props.fieldRefId];
		props.appState.app.graphicalTools.spaceViewRenderer.actions.updateSpaceEditorCaptions(XyiconFeature.Boundary);
	};

	const individualCaptionStyle = props.captionSettings.individualCaptionStyles[props.fieldRefId];

	return (
		<>
			<div
				className={ReactUtils.cls("CaptionStyleButtonContainer hbox", {active: !!individualCaptionStyle})}
				ref={parentRef}
			>
				<IconButton
					icon="reset"
					title="Clear individual caption style"
					onClick={onResetClick}
					className={ReactUtils.cls("resetBtn", {active: !!individualCaptionStyle})}
				/>
				<IconButton
					icon="brush"
					title="Set individual caption style"
					onClick={() => {
						setIsConfiguratorOpen(!isConfiguratorOpen);
						props.onConfiguratorOpenChange?.(!isConfiguratorOpen);
					}}
					className={ReactUtils.cls({active: !!individualCaptionStyle})}
				/>
			</div>
			{isConfiguratorOpen && (
				<DomPortal destination={props.appState.app.modalContainer}>
					<FloatingCaptionConfiguratorV5
						divRef={floatingRef}
						transform={configuratorTransform}
						captionLabel={props.captionLabel}
						fieldRefId={props.fieldRefId}
						onCloseClick={onCloseClick}
						captionSettings={props.captionSettings}
						spaceViewRenderer={props.appState.app.graphicalTools.spaceViewRenderer}
					/>
				</DomPortal>
			)}
		</>
	);
};

import type {Box3} from "three";
import {PerspectiveCamera, Vector3, MathUtils as THREEMath, OrthographicCamera, Frustum, Matrix4} from "three";
import {makeObservable, observable} from "mobx";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import {Constants} from "../Constants";
import type {TileManager} from "../managers/TileManager";
import type {BoundarySpaceMap3D} from "../elements3d/BoundarySpaceMap3D";
import type {Xyicon3D} from "../elements3d/Xyicon3D";
import {ItemSelectionBox} from "../elements3d/ItemSelectionBox";
import type {Markup3D} from "../elements3d/markups/abstract/Markup3D";
import {SpaceEditorMode, notifyAboutViewFilteringOutItems} from "../renderers/SpaceViewRendererUtils";
import {BoundedConvergence} from "../../../../../../utils/animation/BoundedConvergence";
import {HTMLUtils} from "../../../../../../utils/HTML/HTMLUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {IPinchZoomGestureData} from "../../../../../../utils/interaction/gestures/PinchZoomGesture";
import {PinchZoomGesture} from "../../../../../../utils/interaction/gestures/PinchZoomGesture";
import type {PointerDetector} from "../../../../../../utils/interaction/PointerDetector";
import type {Pointer} from "../../../../../../utils/interaction/Pointer";
import {Signal} from "../../../../../../utils/signal/Signal";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {Easing} from "../../../../../../utils/animation/Convergence";
import type {Boundary} from "../../../../../../data/models/Boundary";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {Markup} from "../../../../../../data/models/Markup";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {PanAndZoomGestures} from "../../../../../../utils/interaction/gestures/PanAndZoomGestures";
import type {Dimensions, PointDouble} from "../../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../../generated/api/base";
import {DebugInformation} from "../../../../../../utils/DebugInformation";
import type {SpaceItem} from "../elements3d/SpaceItem";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {CameraScrollbars} from "./tools/CameraScrollbars";

interface DimensionsConvergence {
	x: BoundedConvergence;
	y: BoundedConvergence;
	z: BoundedConvergence;
}

export class PanCameraControls {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _cameraTarget: DimensionsConvergence;
	private _cameraTargetV3: Vector3 = new Vector3();
	private _pCamera: PerspectiveCamera;
	private _oCamera: OrthographicCamera;
	@observable
	private _activeCamera: PerspectiveCamera | OrthographicCamera;
	private _targetToCamera: Vector3 = new Vector3();
	private _frustum: Frustum = new Frustum();
	private _frustumMatix: Matrix4 = new Matrix4();
	private _tileManager: TileManager;

	private _pointerDetector: PointerDetector;
	private _pointerStart: {
		world: PointDouble;
		local: PointDouble;
	};
	private _isMiddleBtnDown: boolean = false;
	private _rightMouseButton = {
		timeStampOnDown: 0,
		shouldTriggerClickEvent: false,
		isPressed: false,
		threshold: {
			duration: 1000, // ms
			delta: 5, // px
		},
	};
	private _zoomSpeed = 1.1;
	private _zoomLevelCheckPoints: number[];
	private _cameraDistance: BoundedConvergence;
	private _panAndZoomGestures: PanAndZoomGestures;
	private _pinchZoomGesture: PinchZoomGesture;
	private _animatedZoom = true;
	private _pinchZoomData: {
		cameraZoomOnPinchStart: number;
	} = {
		cameraZoomOnPinchStart: null,
	};

	private _isSpaceInitialized = false;
	private _isActive: boolean = false;

	private _azimuthAngle: BoundedConvergence;
	private _polarAngle: BoundedConvergence;
	private _toX: Vector3 = new Vector3(1, 0, 0);
	private _toZ: Vector3 = new Vector3(0, 0, 1);

	public tilesNeedUpdate: boolean = false;
	private _tileLevelVisibilityNeedsUpdate: boolean = false;
	private _previousVisibleTileLevelNumber: number = 0;
	private _visibleTileLevelNumber: number = 0;

	private _cameraDataOnPointerDown: {
		target: PointDouble;
		cameraObject: PerspectiveCamera | OrthographicCamera;
		distanceFromTarget: number;
	};

	private _polarAngleOnPointerDown: number;
	private _azimuthAngleOnPointerDown: number;

	private _previousCursorStyle: string;

	// Below ones are needed for damping
	private _dampOnPointerUp: boolean = false;
	private _dampTimeoutId: number = 0;
	private _tileupdateTimeoutId: number = 0;
	// For some reason convergence.prevDeltaValue becomes 0 before the touchup event is called, so we save the proper prev values to these objects below
	private _prevTiltSpeed: PointDouble = {x: 0, y: 0};
	private _prevMoveSpeed: PointDouble = {x: 0, y: 0};

	private _movementUpdateShouldBeCalled: boolean = false;
	private _savedPointerObject: Pointer;

	private _scrollbars: CameraScrollbars;

	public signals = {
		cameraPropsChange: Signal.create(),
		cameraZoomChange: Signal.create(),
		cameraGrabbed: Signal.create<Pointer>(),
		cameraReleased: Signal.create<Pointer>(),
	};

	constructor(spaceViewRenderer: SpaceViewRenderer, pointerDetector: PointerDetector) {
		makeObservable(this);
		this._spaceViewRenderer = spaceViewRenderer;
		this._pointerDetector = pointerDetector;

		this._scrollbars = new CameraScrollbars(this);

		this._cameraDistance = new BoundedConvergence({
			start: 1,
			end: 1,
			min: 0,
			max: 1,
			easing: Easing.EASE_OUT,
			timeStampManager: this._spaceViewRenderer,
		});

		this._azimuthAngle = new BoundedConvergence({
			start: 0,
			end: 0,
			min: -Infinity,
			max: Infinity,
			easing: Easing.EASE_OUT,
			animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
			timeStampManager: this._spaceViewRenderer,
		});

		this._polarAngle = new BoundedConvergence({
			start: Constants.EPSILON,
			end: Constants.EPSILON,
			min: Constants.EPSILON,
			max: THREEMath.degToRad(80),
			easing: Easing.EASE_OUT,
			animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
			timeStampManager: this._spaceViewRenderer,
		});

		const canvas = this._spaceViewRenderer.canvas;

		this._oCamera = new OrthographicCamera(-canvas.width / 2, canvas.width / 2, canvas.height / 2, -canvas.height / 2, 0.1, 20);
		this._oCamera.up = this._toZ.clone();
		this._pCamera = new PerspectiveCamera(Constants.FOV, 1, 1, 200);
		this._pCamera.up = this._toZ.clone();
		this._pCamera.position.setZ(5);
		this._activeCamera = this._oCamera;
		this._cameraTarget = {
			x: new BoundedConvergence({
				start: this._activeCamera.position.x,
				end: this._activeCamera.position.x,
				min: -Infinity,
				max: Infinity,
				easing: Easing.EASE_OUT,
				animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
				timeStampManager: this._spaceViewRenderer,
			}),
			y: new BoundedConvergence({
				start: this._activeCamera.position.y,
				end: this._activeCamera.position.y,
				min: -Infinity,
				max: Infinity,
				easing: Easing.EASE_OUT,
				animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
				timeStampManager: this._spaceViewRenderer,
			}),
			z: new BoundedConvergence({
				start: 0,
				end: 0,
				min: -Infinity,
				max: Infinity,
				easing: Easing.EASE_OUT,
				animationDuration: Constants.DURATIONS.CAMERA_MOVEMENT,
				timeStampManager: this._spaceViewRenderer,
			}),
		};
		this._tileManager = spaceViewRenderer.tileManager;

		this._pinchZoomGesture = new PinchZoomGesture(this._pointerDetector);

		this._panAndZoomGestures = new PanAndZoomGestures({
			clickDistanceTolerance: 3,
			element: spaceViewRenderer.domElement,
			longTap: {
				enabled: true,
				timeout: 750,
			},
		});
	}

	private calculateClosestFullCircle() {
		return Math.round(this._azimuthAngle.value / (2 * Math.PI)) * 2 * Math.PI;
	}

	public activate() {
		if (!this._isActive) {
			this._pointerDetector.signals.down.add(this.onPointerDown);
			this._pointerDetector.signals.move.add(this.onPointerMove);
			this._pointerDetector.signals.up.add(this.onPointerUp);
			this.domElement.addEventListener("wheel", this.onMouseWheel, {passive: false});

			this._panAndZoomGestures.signals.longClick.add(this.onLongClick);

			this._pinchZoomGesture.signals.start.add(this.onStartPinchZoom);
			this._pinchZoomGesture.signals.update.add(this.onUpdatePinchZoom);
			this._pinchZoomGesture.signals.end.add(this.onEndPinchZoom);
			this._pinchZoomGesture.listen();
			this._spaceViewRenderer.signals.onBeforeRender.add(this.updatePointerMovement);
			this._isActive = true;
		}
	}

	public deactivate() {
		if (this._isActive) {
			this._pointerDetector.signals.down.remove(this.onPointerDown);
			this._pointerDetector.signals.move.remove(this.onPointerMove);
			this._pointerDetector.signals.up.remove(this.onPointerUp);
			this.domElement.removeEventListener("wheel", this.onMouseWheel);

			this._panAndZoomGestures.signals.longClick.remove(this.onLongClick);

			this._pinchZoomGesture.signals.start.remove(this.onStartPinchZoom);
			this._pinchZoomGesture.signals.update.remove(this.onUpdatePinchZoom);
			this._pinchZoomGesture.signals.end.remove(this.onEndPinchZoom);
			// this._pinchZoomGesture.endlisten()?;
			this._spaceViewRenderer.signals.onBeforeRender.remove(this.updatePointerMovement);
			this._isActive = false;
		}
	}

	/**
	 * MouseMove event can run a lot more frequently than the refresh rate of the user's display.
	 * We optimize this way: on mousemove, only the new mouse coordinates are saved, and the rest of the calculations are called only once / frame refresh
	 */
	private updatePointerMovement = () => {
		if (this._movementUpdateShouldBeCalled) {
			this._movementUpdateShouldBeCalled = false;
			if (KeyboardListener.isShiftDown && this._spaceViewRenderer.mode === SpaceEditorMode.NORMAL) {
				const deltaX = this._savedPointerObject.localX - this._savedPointerObject.startX;
				const deltaY = this._savedPointerObject.localY - this._savedPointerObject.startY;

				this.rotateCamera(deltaX, deltaY);

				this._dampOnPointerUp = true;
				clearTimeout(this._dampTimeoutId);
				this._dampTimeoutId = window.setTimeout(this.cancelDamping, 100);
			} else {
				if (this._cameraDistance.value !== this._cameraDataOnPointerDown.distanceFromTarget) {
					const camera = this._cameraDataOnPointerDown.cameraObject;

					this.updateCameraPos(camera, this._cameraDataOnPointerDown.target.x, this._cameraDataOnPointerDown.target.y);

					if (camera instanceof OrthographicCamera) {
						camera.zoom = this.cameraZoomValue;
						camera.updateProjectionMatrix();
					}
					THREEUtils.updateMatrices(camera);
				}
				const worldPos = THREEUtils.domCoordinatesToWorldCoordinates(
					this._savedPointerObject.localX,
					this._savedPointerObject.localY,
					this._spaceViewRenderer.spaceOffset.z,
					this.domElement,
					this._cameraDataOnPointerDown.cameraObject,
				);

				if (worldPos && this._pointerStart.world) {
					this.pan(this._pointerStart.world.x - worldPos.x, this._pointerStart.world.y - worldPos.y);

					this._dampOnPointerUp = true;
					clearTimeout(this._dampTimeoutId);
					this._dampTimeoutId = window.setTimeout(this.cancelDamping, 100);
				}
			}
		}
	};

	private onLongClick = (pointer: Pointer) => {
		if (pointer.originalEvent.type.includes("touch")) {
			this.onOpenContextMenu(pointer);
		}
	};

	private onPointerDown = (pointer: Pointer) => {
		this.pointerDown(pointer);
	};

	public pointerDown(pointer: Pointer, force: boolean = false) {
		if (KeyboardListener.isSpaceDown || KeyboardListener.isShiftDown || pointer.isMiddleClick || pointer.isRightClick || force) {
			this._previousCursorStyle = this.domElement.style.cursor;
			this.domElement.style.cursor = "grabbing";
			const worldPos = THREEUtils.domCoordinatesToWorldCoordinates(
				pointer.localX,
				pointer.localY,
				this._spaceViewRenderer.spaceOffset.z,
				this.domElement,
				this._activeCamera,
			);

			this._pointerStart = {
				world: worldPos,
				local: {
					x: pointer.localX,
					y: pointer.localY,
				},
			};
			this._cameraDataOnPointerDown = {
				target: {
					x: this._cameraTarget.x.value,
					y: this._cameraTarget.y.value,
				},
				distanceFromTarget: this._cameraDistance.value,
				cameraObject: this._activeCamera.clone() as PerspectiveCamera | OrthographicCamera,
			};

			this.signals.cameraGrabbed.dispatch(pointer);

			if (!KeyboardListener.isShiftDown) {
				this._cameraTarget.x.reset(this._cameraTarget.x.value, this._cameraTarget.x.value);
				this._cameraTarget.y.reset(this._cameraTarget.y.value, this._cameraTarget.y.value);
			}

			this._polarAngleOnPointerDown = this._polarAngle.value;
			this._azimuthAngleOnPointerDown = this._azimuthAngle.value;

			if (pointer.isNormalClick) {
				this._spaceViewRenderer.inheritedMethods.closeContextMenu();
			} else if (pointer.isMiddleClick) {
				this._isMiddleBtnDown = true;
			} else if (pointer.isRightClick) {
				this._rightMouseButton.isPressed = true;
				this._rightMouseButton.shouldTriggerClickEvent = true;
				this._rightMouseButton.timeStampOnDown = this._spaceViewRenderer.timeStamp;
			}
		}
	}

	private formatTileCoordsToString(tileCoords: PointDouble[]): string {
		let str = "";

		for (let i = 0; i < tileCoords.length; ++i) {
			str += `Zoom Level: ${i}, x: ${tileCoords[i].x}, y: ${tileCoords[i].y}\n`;
		}

		return str;
	}

	private onOpenContextMenu(pointer: Pointer) {
		const worldPos = THREEUtils.domCoordinatesToWorldCoordinates(
			pointer.localX,
			pointer.localY,
			this._spaceViewRenderer.spaceOffset.z,
			this.domElement,
			this._activeCamera,
		);

		if (worldPos) {
			console.log(`Space coords: x: ${worldPos.x}, y: ${worldPos.y}`);
			console.log(`Canvas coords: x: ${pointer.localX}, y: ${pointer.localY}`);
			console.log(`DOM coords: x: ${(pointer.originalEvent as MouseEvent).clientX}, y: ${(pointer.originalEvent as MouseEvent).clientY}`);

			if (DebugInformation.isLoggingEnabled) {
				console.log(`Tiles on this coordinate:`);
				console.log(this.formatTileCoordsToString(this._tileManager.getTileCoordsAtWorldCoord(worldPos)));
				console.log(`Current zoom level: ${this._tileManager.currentZoomLevel}`);
			}

			if (!this._spaceViewRenderer.ghostModeManager.isActive) {
				const spaceItemController = this._spaceViewRenderer.spaceItemController;

				const meshAtCoords = spaceItemController.getMeshAtCoords(pointer.localX, pointer.localY).meshAtCoords;
				const rightClickedSpaceItem = spaceItemController.getSpaceItemFromMeshAtCoords(meshAtCoords);

				if (rightClickedSpaceItem) {
					if (!spaceItemController.selectedItems.includes(rightClickedSpaceItem)) {
						spaceItemController.deselectAll();
						rightClickedSpaceItem.select();
						spaceItemController.updateBoundingBox();
						spaceItemController.updateDetailsPanel(true);
					}
				}
			}
			this._spaceViewRenderer.inheritedMethods.openContextMenu(worldPos.x, worldPos.y);
		}
	}

	private onPointerMove = (pointer: Pointer) => {
		this.pointerMove(pointer);
	};

	public pointerMove(pointer: Pointer) {
		if (!this._pointerStart) {
			return;
		}

		if (pointer.localX !== pointer.startX || pointer.localY !== pointer.startY) {
			if (
				this._rightMouseButton.shouldTriggerClickEvent &&
				(Math.abs(pointer.localX - pointer.startX) > this._rightMouseButton.threshold.delta ||
					Math.abs(pointer.localY - pointer.startY) > this._rightMouseButton.threshold.delta)
			) {
				this._rightMouseButton.shouldTriggerClickEvent = false;
			}

			this._savedPointerObject = pointer;
			this._movementUpdateShouldBeCalled = true;
		}
	}

	/**
	 * Delta is difference between pointernow and pointerstart, NOT the previous pointerpos
	 * @param deltaX
	 * @param deltaY
	 */
	public pan(deltaX: number, deltaY: number) {
		this.moveCameraTo(this._cameraDataOnPointerDown.target.x + deltaX, this._cameraDataOnPointerDown.target.y + deltaY);
	}

	public rotateCamera(deltaX: number, deltaY: number) {
		const coeff = 100;

		this._azimuthAngle.reset(this._azimuthAngleOnPointerDown - deltaX / coeff, this._azimuthAngleOnPointerDown - deltaX / coeff);
		this._polarAngle.reset(this._polarAngleOnPointerDown - deltaY / coeff, this._polarAngleOnPointerDown - deltaY / coeff);
		this.tilesNeedUpdate = true;
	}

	private onPointerUp = (pointer: Pointer) => {
		this._movementUpdateShouldBeCalled = false;
		if (!this._pointerStart) {
			return;
		}

		if (
			pointer.isRightClick &&
			this._rightMouseButton.shouldTriggerClickEvent &&
			this._spaceViewRenderer.timeStamp - this._rightMouseButton.timeStampOnDown < this._rightMouseButton.threshold.duration
		) {
			this.onOpenContextMenu(pointer);
		} else {
			if (this._dampOnPointerUp) {
				this._dampOnPointerUp = false;

				const convergenceX = KeyboardListener.isShiftDown ? this._azimuthAngle : this._cameraTarget.x;
				const convergenceY = KeyboardListener.isShiftDown ? this._polarAngle : this._cameraTarget.y;
				const speed = KeyboardListener.isShiftDown ? this._prevTiltSpeed : this._prevMoveSpeed;
				const speedAbs = THREEUtils.getLength(speed);

				if (MathUtils.isValidNumber(speedAbs) && speedAbs > 0) {
					const multiplicator = convergenceX.derivateAt0;

					// s = v * t => delta
					const time = Constants.DURATIONS.CAMERA_MOVEMENT;
					const delta = {
						x: (time * speed.x) / multiplicator,
						y: (time * speed.y) / multiplicator,
					};

					convergenceX.setEnd(convergenceX.value + delta.x);
					convergenceY.setEnd(convergenceY.value + delta.y);

					this.saveStateToLocalStorage();

					this.tilesNeedUpdate = true;

					this._spaceViewRenderer.needsRender = true;
				}
			}
		}

		this.domElement.style.cursor = this._previousCursorStyle;
		this._pointerStart = null;
		this.signals.cameraReleased.dispatch(pointer);

		if (pointer.isMiddleClick) {
			this._isMiddleBtnDown = false;
		}
		if (pointer.isRightClick) {
			this._rightMouseButton.isPressed = false;
			this._rightMouseButton.shouldTriggerClickEvent = false;
		}
	};

	private cancelDamping = () => {
		this._dampOnPointerUp = false;
	};

	private onStartPinchZoom = (zoomData: IPinchZoomGestureData) => {
		this._pinchZoomData.cameraZoomOnPinchStart = this.cameraZoomValue;

		this.pointerDown(zoomData.middlePointer, true);
	};

	private onUpdatePinchZoom = (zoomData: IPinchZoomGestureData) => {
		const newZoomLevel = this._pinchZoomData.cameraZoomOnPinchStart * (zoomData.distance / zoomData.startDistance);

		const cursorWorldPos = THREEUtils.domCoordinatesToWorldCoordinates(
			zoomData.middlePointer.localX,
			zoomData.middlePointer.localY,
			this._spaceViewRenderer.spaceOffset.z,
			this.domElement,
			this._activeCamera,
		);

		if (cursorWorldPos) {
			this.zoom(newZoomLevel);
		}

		this.onPointerMove(zoomData.middlePointer);
	};

	private onEndPinchZoom = (zoomData: IPinchZoomGestureData) => {
		this.onPointerUp(zoomData.middlePointer);
	};

	public focusOn(item: Boundary | BoundarySpaceMap | Xyicon | Markup, selectItem: boolean = true) {
		const itemsToFocusOn: (BoundarySpaceMap3D | Xyicon3D | Markup3D)[] = [];

		const isEmbeddedXyicon = item.ownFeature === XyiconFeature.Xyicon && item.isEmbedded;

		if ((item as BoundarySpaceMap).isBoundarySpaceMap) {
			const boundarySpaceMap3D = this._spaceViewRenderer.boundaryManager.items.array.find(
				(boundarySpaceMap: BoundarySpaceMap3D) => (boundarySpaceMap.modelData as BoundarySpaceMap).id === item.id,
			) as BoundarySpaceMap3D;

			if (boundarySpaceMap3D) {
				itemsToFocusOn.push(boundarySpaceMap3D);
			}
		} else if ((item as Boundary).isBoundary) {
			itemsToFocusOn.push(
				...(this._spaceViewRenderer.boundaryManager.items.array.filter((boundarySpaceMap: BoundarySpaceMap3D) => {
					const modelData = boundarySpaceMap.modelData as BoundarySpaceMap;

					if (this._spaceViewRenderer.space?.id) {
						return this._spaceViewRenderer.space.id === modelData.spaceId && modelData.parent.id === item.id;
					} else {
						return modelData.parent.id === item.id;
					}
				}) as BoundarySpaceMap3D[]),
			);
		} else if (item.ownFeature === XyiconFeature.Markup) {
			itemsToFocusOn.push(
				...(this._spaceViewRenderer.markupManager.items.array.filter((markup: Markup3D) => {
					if (this._spaceViewRenderer.space?.id) {
						return this._spaceViewRenderer.space.id === markup.modelData.spaceId && markup.data.id === item.id;
					} else {
						return markup.data.id === item.id;
					}
				}) as Markup3D[]),
			);
		} else {
			if (isEmbeddedXyicon && item.parentXyicon.id) {
				// move camera to its parent
				const parentItem3D = this._spaceViewRenderer.xyiconManager.getItemById(item.parentXyicon.id) as Xyicon3D;

				if (parentItem3D) {
					itemsToFocusOn.push(parentItem3D);
				}
			} else {
				const xyicon3D = this._spaceViewRenderer.xyiconManager.getItemById(item.id) as Xyicon3D;

				if (xyicon3D) {
					itemsToFocusOn.push(xyicon3D);
				}
			}
		}

		if (itemsToFocusOn.length > 0) {
			if (selectItem) {
				const currentlySelectedIds: string[] = this._spaceViewRenderer.spaceItemController.selectedItems
					.map((spaceItem: SpaceItem) => spaceItem.id)
					.toSorted(StringUtils.sortIgnoreCase);
				const newlySelectedIds: string[] = ((item as Boundary).isBoundary ? [...(item as Boundary).boundarySpaceMaps] : [item])
					.map((item) => item.id)
					.toSorted(StringUtils.sortIgnoreCase);

				if (!ObjectUtils.compare(currentlySelectedIds, newlySelectedIds)) {
					this._spaceViewRenderer.spaceItemController.deselectAll();
					for (const spaceItem3D of itemsToFocusOn) {
						if (isEmbeddedXyicon) {
							this._spaceViewRenderer.xyiconManager.getItemById(item.id).select(true, true);
						} else {
							spaceItem3D.select();
						}
					}
				}
			}

			if (isEmbeddedXyicon) {
				this._spaceViewRenderer.spaceItemController.closeActionBar();
			} else {
				this._spaceViewRenderer.spaceItemController.updateActionBar();
			}

			if (selectItem) {
				// Workaround to prevent problems when you select everything then select the same item as before
				// (react state only gets updated once per frame)
				requestAnimationFrame(() => {
					this._spaceViewRenderer.spaceItemController.updateDetailsPanel(selectItem);
				});
			}

			const animationDuration = this._cameraTarget.x.animationDuration;

			const onCameraMovementComplete = (value: number) => {
				this._cameraTarget.x.signals.onComplete.remove(onCameraMovementComplete);
				for (const itemToFocusOn of itemsToFocusOn) {
					itemToFocusOn.shake();
				}
				if (isEmbeddedXyicon) {
					this._spaceViewRenderer.inheritedMethods.openLinkedXyiconsWindow([(item as Xyicon).parentXyicon.id], "embedded");
				}
			};

			this._cameraTarget.x.signals.onComplete.add(onCameraMovementComplete);

			const bbox = new ItemSelectionBox();

			bbox.expandByBoundingBoxes(itemsToFocusOn.map((itemToFocusOn) => itemToFocusOn.boundingBox));

			const scale = bbox.scale;
			const pos = bbox.position;

			this._cameraTarget.x.setEnd(pos.x);
			this._cameraTarget.y.setEnd(pos.y);
			this._cameraTarget.z.setEnd(this._spaceViewRenderer.spaceOffset.z);

			const paddingMultiplicator = 1.2;

			const prefferedDistance = this.getDistanceToFitRectangle(scale.x * paddingMultiplicator, scale.y * paddingMultiplicator);
			const prefferedZoomLevel = this._cameraDistance.min / prefferedDistance;

			this.zoom(prefferedZoomLevel, undefined, true, animationDuration);

			setTimeout(() => {
				if (itemsToFocusOn.some((item) => !item.isVisible)) {
					notifyAboutViewFilteringOutItems(itemsToFocusOn, itemsToFocusOn[0].spaceItemType, this._spaceViewRenderer);
				}
			}, animationDuration);
		}
	}

	private initZoomLevels(numberOfZoomLevels: number) {
		this._zoomLevelCheckPoints = [];
		if (numberOfZoomLevels > 1) {
			const multiplicator = Math.pow(this._cameraDistance.max / this._cameraDistance.min, 1 / (numberOfZoomLevels - 1));

			this._zoomLevelCheckPoints.push(this.cameraZoomMin);
			for (let i = 1; i < numberOfZoomLevels - 1; ++i) {
				this._zoomLevelCheckPoints.push(this._zoomLevelCheckPoints[i - 1] * multiplicator);
			}
		}
		this._zoomLevelCheckPoints.push(1);
	}

	public getCurrentZoomLevel(cameraZoomValue: number) {
		let lowerBound = 0;
		let upperBound = this._zoomLevelCheckPoints[0];
		let val = 0;

		for (let i = 0; i < this._zoomLevelCheckPoints.length - 1; ++i) {
			lowerBound = this._zoomLevelCheckPoints[i];
			upperBound = this._zoomLevelCheckPoints[i + 1];
			if (lowerBound <= cameraZoomValue && cameraZoomValue <= upperBound) {
				val = i;
				break;
			}
		}

		const camToLower = cameraZoomValue / lowerBound;
		const upperToCam = upperBound / cameraZoomValue;

		return upperToCam < camToLower ? Math.min(val + 1, this._zoomLevelCheckPoints.length - 1) : val;
	}

	public setSize(canvasWidth: number, canvasHeight: number, spaceOffset: Vector3, numberOfZoomLevels: number) {
		this.resize(canvasWidth, canvasHeight, true);

		this.initZoomLevels(numberOfZoomLevels);

		this._azimuthAngle.reset();
		this._polarAngle.reset();

		this._cameraTarget.x.reset(this._cameraTarget.x.value, this._cameraTarget.x.value, spaceOffset.x, spaceOffset.x + this._spaceWidth);
		this._cameraTarget.y.reset(this._cameraTarget.y.value, this._cameraTarget.y.value, spaceOffset.y, spaceOffset.y + this._spaceHeight);
		this._cameraTarget.z.reset(this._cameraTarget.z.value, spaceOffset.z);

		requestAnimationFrame(() => {
			this.fitToScreen(undefined, false);
		});
	}

	private getDistanceToFitRectangle(rectWidth: number, rectHeight: number) {
		const canvasWidth = MathUtils.clamp(this._spaceViewRenderer.canvas.width, 1, 10000);
		const canvasHeight = MathUtils.clamp(this._spaceViewRenderer.canvas.height, 1, 10000);
		const vFOV = THREEMath.degToRad(Constants.FOV);
		const hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * this._pCamera.aspect);
		const rectRatio = rectWidth / rectHeight;
		const canvasAspectRatio = canvasWidth / canvasHeight;

		return rectRatio < canvasAspectRatio ? rectHeight / 2 / Math.tan(vFOV / 2) : rectWidth / 2 / Math.tan(hFOV / 2);
	}

	public resize(canvasWidth: number, canvasHeight: number, isInitial: boolean = false) {
		// Without these clamps, we can easily have NaN values
		canvasWidth = MathUtils.clamp(canvasWidth, 1, 10000);
		canvasHeight = MathUtils.clamp(canvasHeight, 1, 10000);

		const canvasAspectRatio = canvasWidth / canvasHeight;
		const spaceRatio = this._spaceWidth / this._spaceHeight;

		this._pCamera.aspect = canvasAspectRatio;
		this._pCamera.updateProjectionMatrix();

		if (Math.max(this._spaceWidth, this._spaceHeight) > 0 && this._tileManager.zoomInfo.length > 0) {
			const maxDistance = this.getDistanceToFitRectangle(this._spaceWidth, this._spaceHeight);
			const deepestZoomInfo = this._tileManager.zoomInfo[this._tileManager.zoomInfo.length - 1];
			const minDistance = Math.min(
				(this.getDistanceToFitRectangle(
					MathUtils.clamp(this.domElement.offsetWidth, 1, 10000),
					MathUtils.clamp(this.domElement.offsetHeight, 1, 10000),
				) *
					deepestZoomInfo.tileSize) /
					this._spaceViewRenderer.tileResolution,
				maxDistance,
			);

			const vFOV = THREEMath.degToRad(Constants.FOV);

			this._activeCamera.near = (minDistance * Math.cos(vFOV)) / 10;
			const diagonal = Math.sqrt(this._spaceWidth ** 2 + this._spaceHeight ** 2);

			this._activeCamera.far = Math.sqrt(maxDistance ** 2 + diagonal ** 2) * 1.2;
			this._activeCamera.updateProjectionMatrix();

			if (isInitial) {
				this._isSpaceInitialized = true;
				this._cameraDistance.reset(maxDistance, maxDistance, minDistance, maxDistance);
			} else {
				const currentDistance = minDistance / this._oCamera.zoom;

				this._cameraDistance.reset(currentDistance, currentDistance, minDistance, maxDistance, true);
			}

			const canvasToSpaceRatio =
				spaceRatio < canvasAspectRatio ? canvasHeight / Math.max(this._spaceHeight, 1) : canvasWidth / Math.max(this._spaceWidth, 1);

			const frustumSize = {
				width: (canvasWidth * this.cameraZoomMin) / canvasToSpaceRatio,
				height: (canvasHeight * this.cameraZoomMin) / canvasToSpaceRatio,
			};

			this._oCamera.left = -frustumSize.width / 2;
			this._oCamera.right = frustumSize.width / 2;
			this._oCamera.top = frustumSize.height / 2;
			this._oCamera.bottom = -frustumSize.height / 2;
			this._oCamera.zoom = this.cameraZoomValue > 1 ? minDistance / maxDistance : this.cameraZoomValue;
			this._oCamera.updateProjectionMatrix();
		}
	}

	public moveCameraTo(x: number, y: number, animated: boolean = false, animationDuration: number = this._cameraTarget.x.originalAnimationDuration) {
		if (animated) {
			this._cameraTarget.x.reset(this._cameraTarget.x.end, x, undefined, undefined, true, animationDuration);
			this._cameraTarget.y.reset(this._cameraTarget.y.end, y, undefined, undefined, true, animationDuration);
		} else {
			this._cameraTarget.x.reset(x, x);
			this._cameraTarget.y.reset(y, y);
		}

		this._tileManager.showLevelsFromZeroToN(Math.max(this._visibleTileLevelNumber, this._previousVisibleTileLevelNumber));

		const onCameraMovementComplete = () => {
			this._cameraTarget.x.signals.onComplete.remove(onCameraMovementComplete);
			this.tilesNeedUpdate = true;
			this._tileLevelVisibilityNeedsUpdate = true;
			this.updateTiles();
			this._spaceViewRenderer.needsRender = true;
		};

		this._cameraTarget.x.signals.onComplete.add(onCameraMovementComplete);

		this._spaceViewRenderer.needsRender = true;

		this.saveStateToLocalStorage();
	}

	public onMouseWheel = (event: WheelEvent) => {
		event.preventDefault();
		if (event.deltaY !== 0) {
			const cursorOffset = HTMLUtils.clientXYToOffsetXY(this.domElement, event.clientX, event.clientY);
			const cursorWorldPos = THREEUtils.domCoordinatesToWorldCoordinates(
				cursorOffset.x,
				cursorOffset.y,
				this._spaceViewRenderer.spaceOffset.z,
				this.domElement,
				this._activeCamera,
			);

			if (cursorWorldPos) {
				const direction = -Math.sign(event.deltaY);

				this.zoomToDirection(direction, cursorWorldPos);
			}
		}
	};

	public zoomToDirection(direction: number, pivot?: PointDouble) {
		/** direction should be either -1 or 1 */
		const currentZoomValue = this._cameraDistance.min / this._cameraDistance.end; // on animation end

		this.zoom(direction > 0 ? currentZoomValue * this._zoomSpeed : currentZoomValue / this._zoomSpeed, pivot);
	}

	public zoom(
		amount: number,
		pivot?: PointDouble,
		animatedZoom: boolean = this._animatedZoom,
		animationDuration: number = this._cameraDistance.originalAnimationDuration,
	) {
		const previousCameraDistance = this._cameraDistance.value;
		const newCameraZoomValue = this.cameraZoomValue;

		this._previousVisibleTileLevelNumber = this.getCurrentZoomLevel(newCameraZoomValue);
		const newCameraDistance = this._cameraDistance.min / amount;

		if (animatedZoom) {
			this._cameraDistance.setEnd(newCameraDistance, true, animationDuration);
		} else {
			this._cameraDistance.reset(newCameraDistance, newCameraDistance, undefined, undefined, true);
		}

		this._visibleTileLevelNumber = this.getCurrentZoomLevel(this._cameraDistance.min / this._cameraDistance.end);
		if (this._visibleTileLevelNumber !== this._previousVisibleTileLevelNumber) {
			this._tileManager.showLevelsFromZeroToN(Math.max(this._visibleTileLevelNumber, this._previousVisibleTileLevelNumber));
			this._tileLevelVisibilityNeedsUpdate = true;
		}
		this.tilesNeedUpdate = true;

		if (pivot) {
			const clampedNewCameraDistance = MathUtils.clamp(newCameraDistance, this._cameraDistance.min, this._cameraDistance.max);

			const targetToPivot = {
				x: pivot.x - this._cameraTarget.x.value,
				y: pivot.y - this._cameraTarget.y.value,
			};

			const coeff = (previousCameraDistance - clampedNewCameraDistance) / previousCameraDistance;

			const newTarget = {
				x: this._cameraTarget.x.value + targetToPivot.x * coeff,
				y: this._cameraTarget.y.value + targetToPivot.y * coeff,
			};

			if (animatedZoom) {
				this._cameraTarget.x.setEnd(newTarget.x, true, animationDuration);
				this._cameraTarget.y.setEnd(newTarget.y, true, animationDuration);
			} else {
				this._cameraTarget.x.reset(newTarget.x, newTarget.x, undefined, undefined, true);
				this._cameraTarget.y.reset(newTarget.y, newTarget.y, undefined, undefined, true);
			}
		}

		this.saveStateToLocalStorage();
	}

	private updateTiles() {
		if (this._zoomLevelCheckPoints) {
			if (this._tileLevelVisibilityNeedsUpdate) {
				this._tileManager.updateTileVisibility(this._visibleTileLevelNumber);
				this._tileLevelVisibilityNeedsUpdate = false;
			}
			this._tileManager.update();
			this.tilesNeedUpdate = false;
		}
	}

	private getLocalStorageKey(spaceId: string) {
		return `srv4-org-spaceeditor-space-${spaceId}-camera`;
	}

	private saveStateToLocalStorage() {
		const spaceId = this._spaceViewRenderer.space?.id;

		if (spaceId) {
			const stateToSave = {
				target: {
					x: this._cameraTarget.x.end,
					y: this._cameraTarget.y.end,
				},
				distance: this._cameraDistance.end,
			};

			this._spaceViewRenderer.transport.services.localStorage.set(this.getLocalStorageKey(spaceId), stateToSave);
		}
	}

	public loadStateFromLocalStorage() {
		const spaceId = this._spaceViewRenderer.space?.id;

		if (spaceId) {
			const stateToLoad = this._spaceViewRenderer.transport.services.localStorage.get(this.getLocalStorageKey(spaceId));

			if (stateToLoad) {
				this._cameraTarget.x.setEnd(stateToLoad.target.x);
				this._cameraTarget.y.setEnd(stateToLoad.target.y);
				this._cameraDistance.setEnd(stateToLoad.distance);
				this.tilesNeedUpdate = true;

				this._previousVisibleTileLevelNumber = this.getCurrentZoomLevel(this.cameraZoomValue);
				this._visibleTileLevelNumber = this.getCurrentZoomLevel(this._cameraDistance.min / this._cameraDistance.end);
				this._tileLevelVisibilityNeedsUpdate = true;
			}
		}
	}

	public fitToScreen(animated: boolean = this._animatedZoom, saveToLocalStorage: boolean = true) {
		this._previousVisibleTileLevelNumber = this.getCurrentZoomLevel(this.cameraZoomValue);

		const offset = this._spaceViewRenderer.spaceOffset;

		const newAzimuthAngle = this.calculateClosestFullCircle();

		if (animated) {
			this._cameraTarget.x.setEnd(offset.x + this._spaceWidth / 2, true);
			this._cameraTarget.y.setEnd(offset.y + this._spaceHeight / 2, true);
			this._cameraDistance.setEnd(this._cameraDistance.max, true, Constants.DURATIONS.CAMERA_MOVEMENT);
			this._azimuthAngle.setEnd(newAzimuthAngle, true);
			this._polarAngle.setEnd(Constants.EPSILON, true);
		} else {
			this._cameraTarget.x.reset(offset.x + this._spaceWidth / 2, offset.x + this._spaceWidth / 2);
			this._cameraTarget.y.reset(offset.y + this._spaceHeight / 2, offset.y + this._spaceHeight / 2);
			this._cameraDistance.reset(this._cameraDistance.max, this._cameraDistance.max);
			this._azimuthAngle.reset(newAzimuthAngle, newAzimuthAngle);
			this._polarAngle.reset(Constants.EPSILON, Constants.EPSILON);
		}

		this._visibleTileLevelNumber = this.getCurrentZoomLevel(this._cameraDistance.min / this._cameraDistance.end);
		if (this._visibleTileLevelNumber !== this._previousVisibleTileLevelNumber) {
			this._tileManager.showLevelsFromZeroToN(Math.max(this._visibleTileLevelNumber, this._previousVisibleTileLevelNumber));
			this._tileLevelVisibilityNeedsUpdate = true;
		}
		this.tilesNeedUpdate = true;

		if (saveToLocalStorage) {
			this.saveStateToLocalStorage();
		}
	}

	// Should be called only by SpaceViewRenderer's changeCameraType
	public changeCameraType(newCameraType: "perspective" | "orthographic") {
		const oldCamera = this._activeCamera;

		if (newCameraType === "perspective") {
			this._activeCamera = this._pCamera;
		} else {
			this._oCamera.zoom = this.cameraZoomValue;
			this._activeCamera = this._oCamera;
		}

		const hasChanged = oldCamera !== this._activeCamera;

		if (hasChanged) {
			this._activeCamera.far = oldCamera.far;
			this._activeCamera.near = oldCamera.near;
			this._activeCamera.updateProjectionMatrix();
			this.update(true);
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public updateCameraPos(
		camera: PerspectiveCamera | OrthographicCamera = this._activeCamera,
		targetX: number = this._cameraTarget.x.value,
		targetY: number = this._cameraTarget.y.value,
	) {
		this._targetToCamera
			.copy(this._toZ)
			.applyAxisAngle(this._toX, this._polarAngle.value)
			.applyAxisAngle(this._toZ, this._azimuthAngle.value)
			.normalize()
			// With orthocamera, we're looking from far away now, to ensure that the camera doesn't get inside the models, causing "invisible-models" problem
			.multiplyScalar(camera instanceof OrthographicCamera ? this._cameraDistance.max : this._cameraDistance.value);
		this._cameraTargetV3.set(targetX, targetY, this._cameraTarget.z.value);
		camera.position.copy(this._cameraTargetV3).add(this._targetToCamera);
	}

	public update(force: boolean = false) {
		const hasZoomChanged = this._cameraDistance.hasChangedSinceLastTick;

		const hasAnyCameraPropsChanged =
			this._cameraTarget.x.hasChangedSinceLastTick ||
			this._cameraTarget.y.hasChangedSinceLastTick ||
			this._cameraTarget.z.hasChangedSinceLastTick ||
			this._azimuthAngle.hasChangedSinceLastTick ||
			this._polarAngle.hasChangedSinceLastTick ||
			hasZoomChanged;

		if (hasAnyCameraPropsChanged || force) {
			this._tileManager.showLevelsFromZeroToN(Math.max(this._visibleTileLevelNumber, this._previousVisibleTileLevelNumber));
			this._prevTiltSpeed.x = this._azimuthAngle.prevDeltaValue / this._azimuthAngle.prevDeltaTime;
			this._prevTiltSpeed.y = this._polarAngle.prevDeltaValue / this._azimuthAngle.prevDeltaTime;
			this._prevMoveSpeed.x = this._cameraTarget.x.prevDeltaValue / this._cameraTarget.x.prevDeltaTime;
			this._prevMoveSpeed.y = this._cameraTarget.y.prevDeltaValue / this._cameraTarget.y.prevDeltaTime;
			if (this._polarAngle.hasChangedSinceLastTick) {
				const newCameraType = Math.abs(this._polarAngle.value - this._polarAngle.min) < Constants.EPSILON ? "orthographic" : "perspective";

				this._spaceViewRenderer.changeCameraType(newCameraType);
			}

			this.updateCameraPos();
			this._activeCamera.lookAt(this._cameraTarget.x.value, this._cameraTarget.y.value, this._cameraTarget.z.value);
			THREEUtils.updateMatrices(this._activeCamera);

			this._spaceViewRenderer.needsRender = true;

			if (hasZoomChanged) {
				if (this._activeCamera instanceof OrthographicCamera) {
					this._activeCamera.zoom = this.cameraZoomValue;
					this._activeCamera.updateProjectionMatrix();
				}
				this.signals.cameraZoomChange.dispatch();
			}

			if (hasAnyCameraPropsChanged) {
				this._scrollbars.update();
				this.signals.cameraPropsChange.dispatch();
			}
		} else if (this.tilesNeedUpdate) {
			cancelAnimationFrame(this._tileupdateTimeoutId);
			this._tileupdateTimeoutId = requestAnimationFrame(() => {
				this.updateTiles();
			});
			this.tilesNeedUpdate = false;
		}
	}

	public updateFrustum(camera = this._activeCamera) {
		this._frustum.setFromProjectionMatrix(this._frustumMatix.identity().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse));
	}

	public isPointVisibleForCamera(point: Dimensions) {
		this.updateFrustum();
		const pointAsVector3 = new Vector3(point.x, point.y, point.z);

		return this._frustum.containsPoint(pointAsVector3);
	}

	public isBoxVisibleForCamera(box: Box3, frustumNeedsUpdate: boolean = true, camera = this._activeCamera) {
		if (frustumNeedsUpdate) {
			this.updateFrustum(camera);
		}

		return this._frustum.intersectsBox(box);
	}

	public changeToOrthographic() {
		this._azimuthAngle.setEnd(this.calculateClosestFullCircle());
		this._polarAngle.setEnd(Constants.EPSILON);
	}

	public changeToPerspective() {
		this._azimuthAngle.setEnd(this.calculateClosestFullCircle() - Math.PI / 4);
		this._polarAngle.setEnd(Math.PI / 4);
	}

	private get _spaceWidth() {
		return this._spaceViewRenderer.spaceSize.x;
	}

	private get _spaceHeight() {
		return this._spaceViewRenderer.spaceSize.y;
	}

	public get scrollbars() {
		return this._scrollbars;
	}

	public get isSpaceInitialized() {
		return this._isSpaceInitialized;
	}

	public get cameraDistance() {
		return this._cameraDistance;
	}

	public get cameraTarget() {
		return this._cameraTarget;
	}

	public get domElement() {
		return this._spaceViewRenderer.domElement;
	}

	public get spaceViewRenderer() {
		return this._spaceViewRenderer;
	}

	// in worldpos
	public get target() {
		return {
			x: this._cameraTarget.x,
			y: this._cameraTarget.y,
		};
	}

	public get isPanning() {
		return this._isMiddleBtnDown || this._rightMouseButton.isPressed || this._pointerStart;
	}

	public get activeCamera() {
		return this._activeCamera;
	}

	public get cameraZoomValue() {
		return this._cameraDistance.min / this._cameraDistance.value;
	}

	public get cameraZoomMax() {
		return 1;
	}

	public get cameraZoomMin() {
		return this._cameraDistance.min / this._cameraDistance.max;
	}

	public get isCameraGrabbed() {
		return !!this._pointerStart;
	}

	public get azimuthAngle() {
		return this._azimuthAngle.value;
	}

	public get polarAngle() {
		return this._polarAngle.value;
	}
}

import * as React from "react";
import {NumberInput} from "../../../number/NumberInput";
import {IconButton} from "../../../../button/IconButton";
import type {IInputProps} from "../../FieldInput";
import type {INumericFieldSettingsDefinition} from "../../../../../../data/models/field/datatypes/Numeric";

export class NumericInput extends React.Component<IInputProps<INumericFieldSettingsDefinition>> {
	public override render() {
		const {onInput, onChange, onCancel, onApply, value, noButtons, inline, dataTypeSettings, caretPosition} = this.props;

		return (
			<div className="hbox field-input-container">
				<NumberInput
					value={value}
					onInput={onInput}
					onChange={onChange}
					decimals={dataTypeSettings.decimals}
					autoFocus={true}
					dataTypeSettings={dataTypeSettings}
					caretPosition={caretPosition}
				/>
				{!noButtons && (
					<>
						<IconButton
							icon="apply"
							title="Apply"
							onClick={onApply}
						/>
						<IconButton
							icon="cancel"
							title="Cancel"
							onClick={onCancel}
						/>
					</>
				)}
			</div>
		);
	}
}

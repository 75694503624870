import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {XyiconFeature} from "../../../generated/api/base";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {NavigationEnum} from "../../../Enums";
import {MyProfile} from "./myprofile/MyProfile";
import {OrganizationSettings} from "./organization/OrganizationSettings";
import {ModuleSettings} from "./modules/ModuleSettings";
import {UsersView} from "./permissions/users/UsersView";
import {PermissionSets} from "./permissions/permissionsets/PermissionSets";
import {UserGroupsView} from "./permissions/usergroups/UserGroupsView";
import {PortfolioGroupsView} from "./permissions/portfoliogroups/PortfolioGroupsView";
import {SettingOption} from "./SettingOption";

interface ISettingsViewProps {
	readonly param1?: string;
	readonly param2?: string;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly appState?: AppState;
}

interface ISettingsViewState {
	selectedParent: string;
	tree: ISetting[];
	matches?: boolean;
}

interface ISetting {
	id: string;
	label: string;
	component?: React.ComponentType<any>;
	props?: any;
	children?: ISetting[];
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class SettingsView extends React.Component<ISettingsViewProps, ISettingsViewState> {
	constructor(props: ISettingsViewProps) {
		super(props);
		this.state = {
			selectedParent: "",
			matches: window.matchMedia("(max-width: 1366px) and (max-height: 768px)").matches,
			tree: [
				{
					id: "personal",
					label: "My Profile",
					component: MyProfile,
				},
				{
					id: "organization",
					label: "Organization",
					component: OrganizationSettings,
				},
				{
					id: "permissions",
					label: "Permissions",
					children: [
						{
							id: "users",
							label: "Users",
							component: UsersView,
						},
						{
							id: "usergroups",
							label: "User Groups",
							component: UserGroupsView,
						},
						{
							id: "portfoliogroups",
							label: "Portfolio Groups",
							component: PortfolioGroupsView,
						},
						{
							id: "permissionsets",
							label: "Permission Sets",
							component: PermissionSets,
						},
					],
				},
				{
					id: "modules",
					label: "Modules",
					children: [
						{
							id: "portfolio",
							label: "Portfolio",
							component: ModuleSettings,
							props: {
								feature: XyiconFeature.Portfolio,
								icon: "portfolio",
							},
						},
						{
							id: "space",
							label: "Space",
							component: ModuleSettings,
							props: {
								feature: XyiconFeature.Space,
								icon: "spaces",
							},
						},
						{
							id: "catalog",
							label: "Catalog",
							component: ModuleSettings,
							props: {
								feature: XyiconFeature.XyiconCatalog,
								icon: "catalog",
							},
						},
						{
							id: "xyicon",
							label: "Xyicon",
							component: ModuleSettings,
							props: {
								feature: XyiconFeature.Xyicon,
								icon: "xyicons",
							},
						},
						{
							id: "boundary",
							label: "Boundary",
							component: ModuleSettings,
							props: {
								feature: XyiconFeature.Boundary,
								icon: "boundaries",
							},
						},
						// {
						// 	id: "event",
						// 	label: "Event",
						// 	component: ModuleSettings,
						// 	props: {
						// 		feature: XyiconFeature.Event
						// 	}
						// }
					],
				},
			],
		};
	}

	private changeHandler = (ev: MediaQueryListEvent) => this.setState({matches: ev.matches});

	public override componentDidMount() {
		window.matchMedia("(max-width: 1366px) and (max-height: 768px)").addEventListener("change", this.changeHandler);
	}

	public override componentWillUnmount() {
		window.matchMedia("(max-width: 1366px) and (max-height: 768px)").removeEventListener("change", this.changeHandler);
	}

	private onSelectOption = (id: string, parentId = "") => {
		if (parentId === "modules") {
			const parts = StringUtils.decomposeParts(location.hash);
			let settingsPart = "types";

			if (parts[3]?.value && parts[2]?.value !== "permissionsets") {
				settingsPart = parts[3]?.value;
			}
			this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, id, settingsPart);
		} else if (parentId === "permissions" && id === "permissionsets") {
			const parts = StringUtils.decomposeParts(location.hash);
			let settingsPart = "portfolios";

			if (parts[3]?.value && parts[2]?.value === "permissionsets") {
				settingsPart = parts[3]?.value;
			}
			this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, id, settingsPart);
		} else {
			this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, id);
		}
	};

	private searchComponent(id: string, params: any, settings?: ISetting[]): React.ReactElement<any, any> {
		settings = settings || this.state.tree;

		for (const setting of settings) {
			if (setting.id === id) {
				const Component = setting.component;
				const props = setting.props || {};

				if (Component) {
					return (
						<Component
							{...props}
							{...params}
						/>
					);
				}
			}

			if (setting.children) {
				const result = this.searchComponent(id, params, setting.children);

				if (result) {
					return result;
				}
			}
		}
	}

	private isOpen(settingOption: ISetting, active: string) {
		if (settingOption.id === active) {
			return true;
		}

		const children = settingOption.children;

		if (children) {
			for (const child of children) {
				if (child.id === active) {
					return true;
				}
			}
		}
		return false;
	}

	private parentSettingOptionClick(settingOption: ISetting) {
		const {matches: isMobileView, selectedParent} = this.state;

		if (!isMobileView) {
			// In web view, don't navigate to parent if childrens are available
			if (!settingOption.children?.length) {
				this.onSelectOption(settingOption.id);
			}
			return;
		}

		if (!settingOption.children?.length) {
			this.onSelectOption(settingOption.id);
		}

		if (!selectedParent || selectedParent !== settingOption.id) {
			this.setState({selectedParent: settingOption.id});
		} else {
			this.setState({selectedParent: ""});
		}
	}

	private isParentOpened(settingOption: ISetting) {
		const {matches: isMobileView, selectedParent} = this.state;

		if (!isMobileView) {
			return true;
		}
		return selectedParent === settingOption.id;
	}

	private showChildrenForWebView(settingOption: ISetting) {
		const {matches: isMobileView, selectedParent} = this.state;

		if (!settingOption.children?.length) {
			return false;
		}
		if (!isMobileView) {
			return true;
		}

		return selectedParent === settingOption.id;
	}

	public override render() {
		// Only supports one level of nesting now
		const selected = this.props.param1 || this.state.tree[0].id;
		const component = this.searchComponent(selected, {
			param2: this.props.param2,
		});

		const {appState} = this.props;
		const isUserAdmin = appState.user?.isAdmin;

		return (
			<div className="SettingsView">
				<div className="tab">
					{this.state.tree.map((settingOption, index) => {
						if ((!isUserAdmin && ["My Profile", "Organization"].includes(settingOption.label)) || isUserAdmin) {
							return (
								<div
									key={settingOption.id}
									className="SettingsContainer"
								>
									<SettingOption
										key={settingOption.id}
										label={settingOption.label}
										selected={selected === settingOption.id}
										onClick={() => this.parentSettingOptionClick(settingOption)}
										group={settingOption.children?.length > 0}
										isParentOpened={this.isParentOpened(settingOption)}
									>
										{this.showChildrenForWebView(settingOption) && (
											<div className="children">
												{settingOption.children.map((child, index) => (
													<SettingOption
														key={child.id}
														label={child.label}
														selected={selected === child.id}
														onClick={() => this.onSelectOption(child.id, settingOption.id)}
													/>
												))}
											</div>
										)}
									</SettingOption>
									{settingOption.children?.length > 0 && this.state.selectedParent === settingOption.id && (
										<div className="DropdownContent">
											{settingOption.children.map((child, index) => (
												<SettingOption
													key={child.id}
													label={child.label}
													selected={selected === child.id}
													onClick={() => {
														this.onSelectOption(child.id, settingOption.id);
														this.setState({selectedParent: ""});
													}}
												/>
											))}
										</div>
									)}
								</div>
							);
						}
					})}
				</div>
				<div className="content flex_1 vbox">{component}</div>
			</div>
		);
	}
}

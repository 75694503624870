import styled from "styled-components";

export const ColorRuleRowStyled = styled.div`
	.label {
		width: 104px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;

import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import {getActionBarButtonsForFeature} from "./ActionBarUtils";

const OWN_FEATURE = XyiconFeature.Boundary;

interface BoundaryViewProps {}

export const BoundaryViewV5 = (props: BoundaryViewProps) => {
	const appState = useAppStore((state) => state.appState);

	return (
		<ModuleViewV5
			feature={OWN_FEATURE}
			actionBar={getActionBarButtonsForFeature(OWN_FEATURE, appState)}
		/>
	);
};

import {Observer} from "mobx-react";
import type {XyiconFeature} from "../../../../../generated/api/base";
import type {Type} from "../../../../../data/models/Type";
import {useAppStore} from "../../../../../StateManager";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {SettingTableTabV5} from "../abstract/SettingTableTabV5";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {PopupV5} from "../../../popup/PopupV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {TypeDetailsTabV5} from "./TypeDetailsTabV5";
import {CreateTypePanelV5} from "./CreateTypePanelV5";

interface ITypeSettingsProps {
	readonly feature: XyiconFeature;
	readonly typesFeature: XyiconFeature;
	readonly createButtonParentRef: React.RefObject<HTMLDivElement>;
}

export const TypeSettingsV5 = (props: ITypeSettingsProps) => {
	const {feature, typesFeature, createButtonParentRef} = props;
	const appState = useAppStore((state) => state.appState);

	const columns = [
		{
			id: "name",
			title: "Name",
		},
		{
			id: "system",
			title: "System",
		},
		{
			id: "lastModifiedAt",
			title: "Last updated",
		},
	];

	const getFields = (type: Type) => {
		return columns.map((field) => {
			// TODO
			const id = field.id;

			switch (id) {
				case "system":
					return appState.actions.getBooleanFieldTitle(type.isSystem);
				case "lastModifiedAt":
					return DateFormatter.format(type.lastModifiedAt);
				default:
					return type[id as "name"];
			}
		});
	};

	return (
		<Observer>
			{() => {
				return (
					<SettingTableTabV5
						headers={columns}
						getFields={getFields}
						feature={feature}
						typesFeature={typesFeature}
						kind="types"
						right={(selected) => (
							<TypeDetailsTabV5
								selected={selected}
								fieldsFeature={feature}
							/>
						)}
						create={(onCancel, onCreated) => (
							<PopupV5
								label="New Type"
								noButtons={true}
								width="320px"
								freezeRoot={true}
								verticalAlignment={VerticalAlignment.bottomOuter}
								horizontalAlignment={HorizontalAlignment.right}
								onClose={onCancel}
								parentRef={createButtonParentRef.current}
								className="typeCreationPopup"
								isSmallPopup={true}
							>
								<CreateTypePanelV5
									feature={typesFeature}
									moduleFeature={feature}
									onCreated={onCreated}
								/>
							</PopupV5>
						)}
						emptyListText={`There are no ${featureTitles[feature]} Types to list.`}
					/>
				);
			}}
		</Observer>
	);
};

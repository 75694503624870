import * as React from "react";
import {inject, observer} from "mobx-react";
import {TabView} from "../../../widgets/tab/TabView";
import {TabChild} from "../../../widgets/tab/TabChild";
import type {XyiconFeature} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import {typesFeatures} from "../../../../data/state/AppStateConstants";
import type {Navigation} from "../../../../Navigation";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {NavigationSettings} from "./navigation/NavigationSettings";
import {TypeSettings} from "./type/TypeSettings";
import {FieldSettings} from "./field/FieldSettings";
import {LayoutSettings} from "./layout/LayoutSettings";

interface IModuleSettingsProps {
	readonly feature: XyiconFeature;
	readonly param2: string; // "types" | "fields" | etc
	readonly navigation?: Navigation;
	readonly appState?: AppState;
	readonly icon?: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class ModuleSettings extends React.Component<IModuleSettingsProps, {}> {
	private onChangeSettingsTab = (id: string) => {
		const parts = StringUtils.decomposeParts(location.hash).map((part) => part.value);

		parts[3] = id;
		const hash = parts.join("/");

		this.props.navigation.go(hash);
	};

	public override render() {
		const {feature, param2, icon, appState} = this.props;
		const typesFeature = typesFeatures[feature as XyiconFeature.XyiconCatalog] || feature;
		const activeTab = param2 || "types";

		return (
			// key makes sure that when the user switches to another feature, new component instance will be recreated
			// with a fresh state, so eg. when the user is creating a type, and switches to another feature,
			// the CreateTypePanel will not be visible anymore.
			<div
				key={feature}
				className="ModuleSettings"
			>
				<TabView
					className="settingsTab"
					selectedTabId={activeTab}
					onChangeSelectedTabId={this.onChangeSettingsTab}
					icon={icon}
				>
					<TabChild
						id="types"
						label="Types"
						title="Types"
					>
						<TypeSettings
							feature={feature}
							typesFeature={typesFeature}
						/>
					</TabChild>
					<TabChild
						id="fields"
						label="Fields"
						title="Fields"
					>
						<FieldSettings
							feature={feature}
							typesFeature={typesFeature}
						/>
					</TabChild>
					<TabChild
						id="layouts"
						label="Layouts"
						title="Layouts"
					>
						<LayoutSettings
							ref={appState.layoutSettings as React.Ref<LayoutSettings>}
							feature={feature}
						/>
					</TabChild>
					{/* <TabChild id="maps" label="Google Maps" title="Google Maps">
					</TabChild> */}
					<TabChild
						id="navigation"
						label="Navigation"
						title="Navigation"
					>
						<NavigationSettings feature={feature} />
					</TabChild>
				</TabView>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {OverlayPanel} from "../../../SpaceView";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {Constants} from "../../logic3d/Constants";
import {XyiconFeature} from "../../../../../../generated/api/base";
import {ViewSelect} from "../../../../../widgets/viewselect/ViewSelect";
import {DropdownButton} from "../../../../../widgets/button/DropdownButton";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {FileUtils} from "../../../../../../utils/file/FileUtils";
import type {AppState} from "../../../../../../data/state/AppState";
import {SpaceExportWindow} from "../../../../abstract/popups/SpaceExportWindow";
import {SpaceToPDFExporter} from "./SpaceToPDFExporter";
import {ViewComponentToggler} from "./ViewComponentToggler";

interface ISpaceViewBarProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly openOverlayerPanel: OverlayPanel;
	readonly isCaptionPanelOpen: boolean;
	readonly appState?: AppState;
	readonly onToggleLayerPanel: () => void;
	readonly onToggleCaptionPanel: () => void;
	readonly onToggleConditionalFormattingPanel: () => void;
	readonly spaceViewShareClick: (viewId: string) => void;
	readonly onOpenSpaceToPDFExportPanel: () => void;
}

interface ISpaceViewBarState {
	loadingViewId: string;
}

@inject("appState")
@observer
export class SpaceViewBar extends React.Component<ISpaceViewBarProps, ISpaceViewBarState> {
	private _spaceToPDFExporter: SpaceToPDFExporter;
	private _isPDFAreaDisabled: boolean = false;

	constructor(props: ISpaceViewBarProps) {
		super(props);
		this.state = {
			loadingViewId: "",
		};
	}

	private onShareViewClick = (viewId: string) => {
		const {appState} = this.props.spaceViewRenderer.transport;

		viewId = viewId || appState.selectedViewId[XyiconFeature.SpaceEditor];

		this.props.spaceViewShareClick(viewId);
	};

	private onExport = async (type: "png" | "jpg" | "pdf", area: "entire" | "visible", isMultiple: boolean) => {
		// getting the file name as a 1 element array
		const nameAsArray = !isMultiple && (await SpaceExportWindow.open("", "Export file as", [""], [this.props.spaceViewRenderer.space.name], 1));

		if (isMultiple || nameAsArray) {
			return new Promise<boolean>((resolve, reject) => {
				const {spaceViewRenderer} = this.props;

				switch (type) {
					case "png":
					case "jpg":
						const onSpaceRendered = () => {
							spaceViewRenderer.signals.onAfterRender.remove(onSpaceRendered);
							const data = spaceViewRenderer.canvas.toDataURL(`image/${type === "png" ? type : "jpeg"}`);

							FileUtils.downloadFileFromUrl(data, nameAsArray[0]);
							resolve(true);
						};

						spaceViewRenderer.signals.onAfterRender.add(onSpaceRendered);
						spaceViewRenderer.needsRender = true;
						break;
					case "pdf":
						if (isMultiple) {
							this.props.onOpenSpaceToPDFExportPanel();
						} else {
							if (!this._spaceToPDFExporter) {
								this._spaceToPDFExporter = new SpaceToPDFExporter(this.props.spaceViewRenderer.transport.appState);
							}
							const space = this.props.spaceViewRenderer.space;

							this._spaceToPDFExporter.export(area, [space], nameAsArray[0]);
						}
						break;
				}
			});
		}
	};

	private isPDFAreaDisabled() {
		return (
			this.props.spaceViewRenderer.activeCamera.type === "PerspectiveCamera" ||
			Math.abs(this.props.spaceViewRenderer.toolManager.cameraControls.azimuthAngle) > Constants.EPSILON
		);
	}

	private onCameraUpdate = () => {
		if (this._isPDFAreaDisabled !== this.isPDFAreaDisabled()) {
			this.forceUpdate();
		}
	};

	private onSelectView = (viewId: string) => {
		this.setState({loadingViewId: viewId});
		this.props.appState.tableComponent.current?._table.current?.scroll({left: 0});
	};

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.onCameraUpdate);
	}

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.onCameraUpdate);
	}

	public override render() {
		const {loadingViewId} = this.state;
		const actions = this.props.spaceViewRenderer.actions;
		const selectedView = actions.getSelectedView(XyiconFeature.SpaceEditor);

		const loadingView = actions.getViewById(loadingViewId);

		this._isPDFAreaDisabled = this.isPDFAreaDisabled();

		return (
			<div className="SpaceViewBar">
				<ViewSelect
					selected={loadingView ?? selectedView}
					onShareClick={this.onShareViewClick}
					onSelect={this.onSelectView}
				/>
				<ViewComponentToggler
					title="Layers"
					type="layers"
					isActive={this.props.openOverlayerPanel === "Layer"}
					onClick={this.props.onToggleLayerPanel}
				/>
				<ViewComponentToggler
					title="Formatting"
					type="conformatting"
					isActive={this.props.openOverlayerPanel === "ConditionalFormatting"}
					onClick={this.props.onToggleConditionalFormattingPanel}
				/>
				<ViewComponentToggler
					title="Captions"
					type="captions"
					isActive={this.props.isCaptionPanelOpen}
					onClick={this.props.onToggleCaptionPanel}
				/>
				<div className="exportOptions button">
					<DropdownButton
						button={
							<div className="hbox alignCenter">
								<IconButton
									className="exportViewButton"
									icon="export"
									title="Export"
								/>
								<div className="label">Export</div>
								<IconButton
									className="smallArrowDown"
									icon="down"
									title="Export"
								/>
							</div>
						}
						options={[
							{
								label: "Export to PNG",
								onClick: () => this.onExport("png", "visible", false),
							},
							{
								label: "Export to JPG",
								onClick: () => this.onExport("jpg", "visible", false),
							},
							{
								label: "Export to PDF",
								options: [
									{
										label: "Visible Area",
										infoText: this._isPDFAreaDisabled ? "This option is only available in 2D mode" : undefined,
										disabled: this._isPDFAreaDisabled,
										onClick: () => this.onExport("pdf", "visible", false),
									},
									{
										label: "Entire Space",
										onClick: () => this.onExport("pdf", "entire", false),
									},
									{
										label: "Multiple Spaces",
										onClick: () => this.onExport("pdf", "entire", true),
									},
								],
							},
						]}
					/>
				</div>
			</div>
		);
	}
}

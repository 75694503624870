import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../../../widgets/button/IconButton";
import {SelectInput} from "../../../../widgets/input/select/SelectInput";
import type {AppState} from "../../../../../data/state/AppState";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {PortfolioGroupPermission} from "../../../../../generated/api/base";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";

interface IPortfolioGroupPermissionViewProps {
	readonly permission: PortfolioGroupPermission;
	readonly exceptions: string[];
	readonly onRemove: (permission: PortfolioGroupPermission) => void;
	readonly onChange?: () => void;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class PortfolioGroupPermissionView extends React.PureComponent<IPortfolioGroupPermissionViewProps> {
	private onRemove = () => {
		this.props.onRemove(this.props.permission);
	};

	private onChangePortfolioGroup = (portfolioGroup: PortfolioGroup) => {
		this.props.permission.portfolioGroupID = portfolioGroup.id;
		this.props.onChange?.();
	};

	private onChangePermissionSet = (permissionSet: PermissionSet) => {
		this.props.permission.portfolioPermissionSetID = permissionSet.id;
		this.props.onChange?.();
	};

	public override render() {
		const {permission, exceptions, appState} = this.props;

		const permissionSets = appState.actions.getList<PermissionSet>(XyiconFeature.PermissionSet);
		const portfolioGroups = appState.actions.getList<PortfolioGroup>(XyiconFeature.PortfolioGroup);

		// Only list portfolios that haven't been added yet
		const options = portfolioGroups.filter((pg) => pg.id === permission.portfolioPermissionSetID || !exceptions.includes(pg.id));

		return (
			<div className="PortfolioGroupPermissionView AdvancedFilterField hbox">
				<div className="criterias">
					<SelectInput
						options={options}
						selected={portfolioGroups.find((pg) => pg.id === permission.portfolioGroupID)}
						onChange={this.onChangePortfolioGroup}
						render={(p) => p.name}
						placeholder="Portfolio Group"
					/>
					<SelectInput
						options={permissionSets}
						selected={permissionSets.find((ps) => ps.id === permission.portfolioPermissionSetID)}
						onChange={this.onChangePermissionSet}
						render={(ps) => ps.name}
						placeholder="Permission Set"
					/>
				</div>
				<IconButton
					title="Delete"
					icon="close"
					className="delete"
					onClick={this.onRemove}
				/>
			</div>
		);
	}
}

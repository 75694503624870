import {Observer} from "mobx-react";
import type {IFieldDataTypeSettingsProps} from "../../modules/settings/modules/field/datatypes/IFieldDataTypeSettingsProps";
import type {ISingleSelectFieldSettingsDefinition} from "../../../data/models/field/datatypes/SingleSelect";
import {ListBuilderInputV5} from "./ListBuilderInputV5";

export const SingleSelectFieldSettingsV5 = (props: IFieldDataTypeSettingsProps) => {
	const {field, onChange} = props;

	return (
		<Observer>
			{() => {
				const settings = field.dataTypeSettings as ISingleSelectFieldSettingsDefinition;
				const {choiceList, type} = settings;
				const dataType = field.dataType;

				const updateSingleSelectList = (value: ISingleSelectFieldSettingsDefinition) => {
					settings.choiceList = value.choiceList;
					settings.type = value.type;
					onChange();
				};

				return (
					<ListBuilderInputV5
						list={choiceList}
						onChange={updateSingleSelectList}
						displayFormatType={type}
						dataType={dataType}
					/>
				);
			}}
		</Observer>
	);
};

import * as React from "react";
import {inject} from "mobx-react";
import {SVGIcon} from "../../button/SVGIcon";
import type {IInputType} from "../text/TextInput";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {FieldDataType} from "../../../../generated/api/base";
import {ReactUtils} from "../../../utils/ReactUtils";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import type {AppState} from "../../../../data/state/AppState";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {SingleLineLabel} from "./datatypes/singleline/SingleLineLabel";
import {SingleLineInput} from "./datatypes/singleline/SingleLineInput";
import {FieldInputLabels, FieldInputs} from "./InputUtils";

interface IClickToEditInputProps {
	readonly className?: string;
	readonly dataTypeSettings?: any; // {decimals: number, formatting: "currency", maximum: number, minimum: number}
	readonly dataType?: FieldDataType;
	readonly value: any;
	readonly placeholder: any;
	readonly trimText?: boolean;
	readonly disabled?: boolean;
	readonly updating?: boolean;
	readonly hasValidation?: boolean;
	readonly focused?: boolean;
	readonly dateFormat?: string;
	readonly onFocusLossForceBlur?: boolean;
	readonly noButtons?: boolean;
	readonly inputType?: IInputType;
	readonly caretPosition?: number;
	readonly appState?: AppState;
	readonly onLiveChange?: (value: any) => void;
	readonly onCancelEdit?: () => void;
	readonly onChange: (value: any) => void;
	readonly getErrorMessage?: (value: any) => string;
	readonly showErrorOnlyIfEdited?: boolean;
	readonly onClick?: (event?: React.MouseEvent) => void;
	readonly onHover?: (over?: boolean) => void;
	readonly onBlur?: () => void;
}

interface IClickToEditInputState {
	editing: boolean;
	editingValue: any;
	inputHeight: number;
	labelScrollHeight: number;
	isLabelInSecondaryState: boolean;
	hasBeenEditedSinceSave: boolean;
}

@inject("appState")
export class ClickToEditInput extends React.Component<IClickToEditInputProps, IClickToEditInputState> {
	public static readonly defaultProps: Partial<IClickToEditInputProps> = {
		dataType: FieldDataType.SingleLineText,
		value: null, // without this, this.state.editingValue !== this.props.value is false, and we're updating the backend with the same nullish value unnecessarily
		trimText: true,
		getErrorMessage: () => "",
		showErrorOnlyIfEdited: true,
	};

	public _element = React.createRef<HTMLDivElement>();
	private _input = React.createRef<HTMLDivElement>();
	private _labelContent = React.createRef<HTMLDivElement>();

	constructor(props: IClickToEditInputProps) {
		super(props);
		this.state = {
			editing: this.props.focused,
			editingValue: this.props.value,
			inputHeight: null,
			isLabelInSecondaryState: false,
			labelScrollHeight: null,
			hasBeenEditedSinceSave: false,
		};
	}

	private get errorMessage() {
		return this.props.getErrorMessage(this.editingValue);
	}

	private onClick = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.refreshEditState();

		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.props.onClick?.(event);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.onCancelEdit();
				break;
			case KeyboardListener.KEY_ENTER:
				if (!(this.props.dataType === FieldDataType.MultiLineText && KeyboardListener.isShiftDown)) {
					this.onApplyEdit();
				}
				break;
		}
	};

	private onFocusLoss = () => {
		if (!this.onApplyEdit()) {
			this.onCancelEdit();
		}
	};

	private onCancelEdit = () => {
		this.cancelLocalEdit();
		this.props.onCancelEdit?.();
	};

	public cancelLocalEdit() {
		if (this.state.editing) {
			this.setState({
				editing: false,
				editingValue: this.props.value,
			});

			this.props.onLiveChange?.(this.props.value);
		}
	}

	private onApplyEdit = () => {
		const editing = this.state.editing;

		this.setState({
			editing: false,
		});

		const editingValue = this.editingValue;

		if (editing && !this.errorMessage && editingValue !== this.props.value) {
			this.props.onChange?.(editingValue);
			return true;
		}

		return false;
	};

	public get editingValue() {
		return this.trimValueIfNeeded(this.state.editingValue);
	}

	private onEditingInput = (value: any) => {
		const {onLiveChange} = this.props;
		this.setState({editingValue: value, hasBeenEditedSinceSave: true});

		onLiveChange?.(value);
	};

	private trimValueIfNeeded(editingValue: string | number) {
		if (this.props.trimText && typeof editingValue === "string") {
			editingValue = editingValue.trim();
		}

		return editingValue;
	}

	private onChange = (value: any) => {
		const newValue = this.trimValueIfNeeded(value);

		this.setState({editingValue: newValue});
		this.props.onChange(newValue);
	};

	private onBlur = () => {
		const {onBlur, value} = this.props;

		if (onBlur) {
			if (this.errorMessage) {
				this.setState({editingValue: value});
			} else {
				onBlur?.();
			}
		}
	};

	private onLabelStateChange = (value: boolean) => {
		this.setState({isLabelInSecondaryState: value});
	};

	private getLabel() {
		const {placeholder, dataType, dataTypeSettings, appState} = this.props;
		const {editingValue} = this.state;
		const errorMessage = this.errorMessage;
		let {value} = this.props;

		let LabelComponent = FieldInputLabels[dataType];

		LabelComponent = LabelComponent || SingleLineLabel;

		if (!value && placeholder) {
			value = placeholder;
		}

		const final = appState.actions.getURLsInTextInputs(value);

		return (
			<LabelComponent
				value={errorMessage ? editingValue : final}
				dataTypeSettings={dataTypeSettings}
				onLabelStateChange={this.onLabelStateChange}
				divRef={this._labelContent}
				height={this._labelContent.current?.scrollHeight || 0}
			/>
		);
	}

	private getEditInput() {
		const {dataType, dataTypeSettings, onFocusLossForceBlur, noButtons, inputType, caretPosition} = this.props;
		const {editingValue, inputHeight, isLabelInSecondaryState, labelScrollHeight} = this.state;

		let InputComponent = FieldInputs[dataType];

		InputComponent = InputComponent || SingleLineInput;
		return (
			<InputComponent
				value={editingValue ?? this.props.dateFormat}
				dataTypeSettings={dataTypeSettings}
				onInput={this.onEditingInput}
				onCancel={this.onCancelEdit}
				onApply={this.onApplyEdit}
				onChange={this.onChange}
				onBlur={this.onBlur}
				height={inputHeight}
				scrollHeight={labelScrollHeight}
				onFocusLossForceBlur={onFocusLossForceBlur}
				noButtons={noButtons}
				inputType={inputType}
				secondaryState={isLabelInSecondaryState}
				caretPosition={caretPosition}
				expand={isLabelInSecondaryState}
			/>
		);
	}

	private refreshEditState() {
		this.setState({
			editing: true,
			editingValue: this.props.value,
		});

		this.addListeners();
	}

	private refreshInputHeight() {
		const inputRef = this._input.current;
		const labelRef = this._labelContent.current;

		const newLabelScrollHeight = MathUtils.isValidNumber(labelRef?.scrollHeight) ? labelRef.scrollHeight + 1 : this.state.labelScrollHeight;

		if (labelRef && inputRef && (inputRef.offsetHeight !== this.state.inputHeight || newLabelScrollHeight !== this.state.labelScrollHeight)) {
			this.setState({
				inputHeight: inputRef.offsetHeight,
				labelScrollHeight: newLabelScrollHeight,
			});
		}
	}

	private onMouseOver = () => {
		this.props.onHover?.(true);
	};

	private onMouseLeave = () => {
		this.props.onHover?.(false);
	};

	private removeListeners() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		FocusLoss.stopListen(this._element.current, this.onFocusLoss);
	}

	private addListeners() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		FocusLoss.listen(this._element.current, this.onFocusLoss, null, "up");
	}

	public override componentDidMount() {
		this.refreshInputHeight();
		this.addListeners();
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IClickToEditInputProps>, nextContext: any): void {
		if (this.props.value !== nextProps.value) {
			this.setState({
				editingValue: nextProps.value,
			});

			if (!nextProps.onLiveChange) {
				this.setState({
					hasBeenEditedSinceSave: false,
				});
			}
		}
	}

	public override componentDidUpdate(prevProps: IClickToEditInputProps, prevState: IClickToEditInputState) {
		this.refreshInputHeight();

		if (prevProps.focused !== this.props.focused) {
			this.refreshEditState();
		}

		if (prevProps.focused && !this.props.focused) {
			this.onApplyEdit();
		}
	}

	public override componentWillUnmount() {
		this.onFocusLoss();
		this.removeListeners();
	}

	public override render() {
		const {className, disabled, updating, noButtons, hasValidation, showErrorOnlyIfEdited} = this.props;
		const {editing, hasBeenEditedSinceSave} = this.state;
		const errorMessage = this.errorMessage;
		const showErrorMessage = editing && (!showErrorOnlyIfEdited || (showErrorOnlyIfEdited && hasBeenEditedSinceSave)) && errorMessage && !disabled;

		return (
			<div
				ref={this._element}
				className={ReactUtils.cls(`ClickToEditInput relative ${className || ""}`, {
					error: showErrorMessage,
					editing,
					empty: !this.state.editingValue,
					infoBubbleAlignLeft: noButtons,
					noButtons,
				})}
				onMouseOver={this.onMouseOver}
				onMouseLeave={this.onMouseLeave}
			>
				{showErrorMessage && (
					<div className={ReactUtils.cls("infoIcon", {editing, left: noButtons})}>
						<SVGIcon icon="info" />
						<InfoBubble
							content={errorMessage}
							isErrorMessage={true}
						/>
					</div>
				)}
				{editing && !disabled ? (
					this.getEditInput()
				) : (
					<div
						className={ReactUtils.cls("unfocused", {disabled, updating})}
						onClick={this.onClick}
						ref={this._input}
					>
						{this.getLabel()}
					</div>
				)}
				{updating && <span className={ReactUtils.cls("spinner", {hasValidation})} />}
			</div>
		);
	}
}

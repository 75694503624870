import * as React from "react";
import {ColorSelector} from "../../abstract/common/colorselector/ColorSelector";
import type {IRealSize} from "../../settings/modules/type/form/RealSizeInput";
import {RealSizeInput} from "../../settings/modules/type/form/RealSizeInput";
import {Constants} from "../../space/spaceeditor/logic3d/Constants";
import type {Color, ModelParameters, Dimensions} from "../../../../generated/api/base";
import {ToggleSwitchField} from "../../../widgets/button/switch/ToggleSwitchField";
import type {LibraryModel} from "../../../../data/models/LibraryModel";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {ReactUtils} from "../../../utils/ReactUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {zIndex} from "../../../5.0/styles/styles";
import {ModelPreviewer} from "./ModelPreviewer";

type Dimension = "x" | "y" | "z";

interface IMeshTweakerProps {
	readonly hidden?: boolean;
	readonly libraryModel: LibraryModel;
	readonly modelParameters: ModelParameters;
	readonly onColorChange: (newColor: Color) => void;
	readonly onDimensionChange: (dimensions: Dimensions) => void;
}

interface IMeshTweakerState {
	dimensions: IRealSize[];
	doesMaintainProportions: boolean;
}

export class MeshTweaker extends React.Component<IMeshTweakerProps, IMeshTweakerState> {
	private _modelPreviewerRef = React.createRef<ModelPreviewer>();

	// Y and Z are swapped on purpose to be consistent with the space editor axes
	private _dimensionLabels: Dimension[] = ["x", "z", "y"];

	constructor(props: IMeshTweakerProps) {
		super(props);
		const defaultUnit = "inch";

		this.state = {
			dimensions: [
				{
					value: this.props.modelParameters.dimensions.x / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
				{
					value: this.props.modelParameters.dimensions.z / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
				{
					value: this.props.modelParameters.dimensions.y / Constants.DISTANCE_UNITS[defaultUnit].multiplicator,
					unit: defaultUnit,
				},
			],
			doesMaintainProportions: true,
		};
	}

	private getValueInMeters = (size: IRealSize) => {
		return size.value * Constants.DISTANCE_UNITS[size.unit].multiplicator;
	};

	private getDimensionTweakers() {
		return this._dimensionLabels.map((dimensionName: Dimension, index: number) => {
			const label = (dimensionName === "y" ? "z" : dimensionName === "z" ? "y" : "x").toUpperCase();

			return (
				<RealSizeInput
					key={dimensionName}
					label={label}
					size={{
						value: this.state.dimensions[index].value,
						unit: this.state.dimensions[index].unit,
					}}
					layoutType="hbox"
					optionsZIndex={zIndex.overlayDetailsPanel + 1}
					onChange={(size: IRealSize) => {
						const newDimensions = ObjectUtils.deepClone(this.state.dimensions);
						const newValueInMeters = this.getValueInMeters(size);
						const dimensionValues: Dimensions = {
							[this._dimensionLabels[0]]: this.getValueInMeters(this.state.dimensions[0]),
							[this._dimensionLabels[1]]: this.getValueInMeters(this.state.dimensions[1]),
							[this._dimensionLabels[2]]: this.getValueInMeters(this.state.dimensions[2]),
						};

						newDimensions[index].unit = size.unit;
						newDimensions[index].value = size.value;

						if (this.state.doesMaintainProportions) {
							const oldValueInMeters = this.getValueInMeters(this.state.dimensions[index]);
							const ratio = newValueInMeters / oldValueInMeters;

							for (let i = 0; i < this._dimensionLabels.length; ++i) {
								if (i !== index) {
									newDimensions[i].value *= ratio;
									dimensionValues[this._dimensionLabels[i]] = this.getValueInMeters(newDimensions[i]);
								}
							}
						}

						this.setState({dimensions: newDimensions});

						dimensionValues[dimensionName] = newValueInMeters;

						this.props.onDimensionChange(dimensionValues);
					}}
				/>
			);
		});
	}

	private onMaintainProportionsChanged = (value: boolean) => {
		this.setState({
			doesMaintainProportions: value,
		});
	};

	public get currentMesh() {
		return this._modelPreviewerRef.current?.mesh;
	}

	public override render() {
		const color = this.props.modelParameters.bodyColor;

		return (
			<div className={ReactUtils.cls("MeshTweaker hbox", {hidden: this.props.hidden})}>
				<div className="flex_1 vbox parameterContainer">
					<h4>Dimensions</h4>
					{this.getDimensionTweakers()}
					<ToggleSwitchField
						labelFirst={true}
						label="Maintain X, Y and Z proportions"
						value={this.state.doesMaintainProportions}
						onChange={this.onMaintainProportionsChanged}
					/>
				</div>
				<ModelPreviewer
					ref={this._modelPreviewerRef}
					color={color}
					libraryModel={this.props.libraryModel}
					dimensions={this.props.modelParameters.dimensions}
				>
					<ColorSelector
						label="Color"
						color={color}
						onColorChange={this.props.onColorChange}
						horizontalAlignment={HorizontalAlignment.outerRight}
						verticalAlignment={VerticalAlignment.bottom}
						changeAlignmentOnPositionCorrection={true}
					/>
				</ModelPreviewer>
			</div>
		);
	}
}

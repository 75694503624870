import * as React from "react";

import {IconButton} from "../button/IconButton";

interface IChartWidgetProps<T> {
	readonly children: React.ReactElement;
	readonly className?: string;
	readonly onExpand: () => void;
}

export class ChartWidget<T = {}> extends React.PureComponent<IChartWidgetProps<T>> {
	public override render() {
		return (
			<div className={`ChartWidget ${this.props.className || ""}`}>
				<IconButton
					className="expandButton"
					icon="enlarge"
					onClick={this.props.onExpand}
				/>
				{this.props.children}
			</div>
		);
	}
}

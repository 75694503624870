import styled from "styled-components";
import {useRef, useState} from "react";
import type {Space} from "../../../../../data/models/Space";
import ArrowDownIcon from "../../../icons/chevron-down.svg?react";
import ListIcon from "../../../icons/list.svg?react";
import {ELLIPSIS, FlexCenterStyle, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {VerticalLine} from "../../../widgets/VerticalLine";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import {useAppStore} from "../../../../../StateManager";
import {DropdownButtonStyled} from "../../../interaction/DropdownButtonV5";
import {SpaceList} from "./SpaceList";
import {SpaceExporter} from "./SpaceExporter";
import {GridSelector} from "./GridSelector";

interface ISpaceSelectorProps {
	readonly spaces: Space[];
	readonly selectedSpace: Space;
	readonly onSpaceSelect: (selectedSpace: Space) => void;
}

export const SpaceSelector = (props: ISpaceSelectorProps) => {
	const appState = useAppStore((state) => state.appState);
	const {selectedSpace, spaces, onSpaceSelect} = props;
	const wrapperRef = useRef<HTMLDivElement>();
	const [isSpaceContainerOpen, setIsSpaceContainerOpen] = useState<boolean>(false);
	const [isGridSelectorOpen, setIsGridSelectorOpen] = useState<boolean>(false);

	useClickOutside([wrapperRef], (event: MouseEvent) => {
		setIsSpaceContainerOpen(false);
		setIsGridSelectorOpen(false);
	});

	const onCurrentSpaceContainerClick = () => {
		setIsSpaceContainerOpen(!isSpaceContainerOpen);
		setIsGridSelectorOpen(false);
	};

	const onGridSelectorClick = () => {
		setIsGridSelectorOpen(!isGridSelectorOpen);
		setIsSpaceContainerOpen(false);
	};

	const onSpaceExporterClick = () => {
		setIsGridSelectorOpen(false);
		setIsSpaceContainerOpen(false);
	};

	const onCloseSpaceContainer = () => {
		setIsSpaceContainerOpen(false);
	};

	return (
		<SpaceSelectorWrapper ref={wrapperRef}>
			<SpaceSelectorStyled>
				<CurrentSpaceContainerStyled onClick={onCurrentSpaceContainerClick}>
					<CurrentSpaceStyled title={selectedSpace.name}>{selectedSpace.name}</CurrentSpaceStyled>
					<ArrowDownIcon style={{width: 16}} />
				</CurrentSpaceContainerStyled>
				<ButtonContainerStyled>
					<VerticalLine />
					{/*this will be implemented in future, as of 6407 commented/hide the find button*/}
					{/*<IconButtonV5
						IconComponent={SearchIcon}
						onClick={Functions.emptyFunction}
					/>*/}
					<IconButtonV5
						IconComponent={ListIcon}
						isActive={isGridSelectorOpen || !!appState.app.graphicalTools.gridItemSide}
						onClick={onGridSelectorClick}
					/>
					<SpaceExporter onClick={onSpaceExporterClick} />
				</ButtonContainerStyled>
			</SpaceSelectorStyled>
			{isSpaceContainerOpen && (
				<SpaceList
					spaces={spaces}
					onSpaceSelect={onSpaceSelect}
					selectedSpace={selectedSpace}
					onCloseSpaceContainer={onCloseSpaceContainer}
				/>
			)}
			{isGridSelectorOpen && <GridSelector onClose={() => setIsGridSelectorOpen(false)} />}
		</SpaceSelectorWrapper>
	);
};

const SpaceSelectorWrapper = styled.div`
	position: absolute;
	top: 16px;
	left: 16px;
	width: 288px;
	z-index: 1;
`;

const SpaceSelectorStyled = styled.div`
	background-color: ${colorPalette.white};
	height: 48px;
	padding-right: 8px;
	border-radius: ${radius.md};
	box-shadow: 0px 4px 8px 0px #00000080;
	${FlexCenterStyle};
	justify-content: space-between;
`;

const CurrentSpaceStyled = styled.div`
	color: ${colorPalette.gray.c700Dark};
	flex: 1;
	${ELLIPSIS}
`;

const CurrentSpaceContainerStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;
	height: 32px;
	width: 142px;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	padding: 8px;
	margin: 0 8px;
	cursor: pointer;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}
`;

const ButtonContainerStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	> ${IconButtonStyled} {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 32px;
		height: 32px;
		border-radius: ${radius.md};
	}

	${DropdownButtonStyled} {
		border-radius: ${radius.md};
	}
`;

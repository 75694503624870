import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";

export interface ITabChildProps {
	readonly id?: string;
	readonly label?: string;
	readonly title?: string;
	readonly icon?: string;
	readonly active?: boolean;
	readonly disabled?: boolean;
	readonly children: React.ReactNode;
}

export class TabChild extends React.Component<ITabChildProps> {
	public static readonly defaultProps: ITabChildProps = {
		active: false,
		children: <></>,
	};

	public override render() {
		return (
			<div
				key={this.props.id + this.props.label + this.props.title + this.props.icon}
				className={ReactUtils.cls({"DetailsTab active": this.props.active, disabled: this.props.disabled})}
			>
				{this.props.children}
			</div>
		);
	}
}

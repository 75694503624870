import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {IconButtonV5} from "../interaction/IconButtonV5";
import EyeIcon from "../icons/eye-alt.svg?react";
import CancelIcon from "../icons/xmark-large.svg?react";
import LockIcon from "../icons/lock.svg?react";
import UnlockIcon from "../icons/unlock.svg?react";
import {Functions} from "../../../utils/function/Functions";
import {baseDistance} from "../styles/styles";

type LayerType = "hide" | "lock";

interface IHideAndLockProps {
	readonly type: LayerType;
	readonly isActive: boolean;
	readonly onChange: (active: boolean) => void;
	readonly isItForModifyAll?: boolean;
}

export const HideAndLockV5 = ({type, isActive, isItForModifyAll, onChange}: IHideAndLockProps) => {
	const title = type === "hide" ? (isActive ? "Show" : "Hide") : isActive ? "Unlock Position" : "Lock Position";
	const IconComponent = type === "hide" ? (isActive ? EyeIcon : CancelIcon) : isActive ? LockIcon : UnlockIcon;

	return (
		<HideAndLockStyled
			className={ReactUtils.cls("HideAndLock", {modifyAll: isItForModifyAll})}
			onClick={() => onChange(!isActive)}
		>
			<IconButtonV5
				IconComponent={IconComponent}
				title={title}
				onClick={Functions.emptyFunction}
			/>
		</HideAndLockStyled>
	);
};

const HideAndLockStyled = styled.div`
	position: relative;
	padding-top: ${baseDistance.xs};
	cursor: pointer;
	// left is removed for label panel

	.IconButton {
		width: 24px;
		height: 24px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	& + .title {
		margin-left: base.$oh;
		font-size: base.$font-md;
		font-weight: base.$font-thin;
	}

	&.modifyAll {
		& + .title {
			font-weight: base.$font-normal;
		}

		.IconButton {
			width: 24px;
			height: 24px;

			svg {
				width: 16px;
				height: 16px;
			}
		}
	}

	&:hover {
		.button {
			color: base.$picton-blue;

			.icon.lock-active {
				fill: base.$picton-blue;
			}
		}
	}

	.button {
		color: var(--icon);

		.icon.hide {
			fill: base.$picton-blue;
		}
	}
`;

import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {ListBuilderInput} from "../../../../../widgets/input/listbuilder/ListBuilderInput";
import type {ListObject} from "../../../../../../data/models/field/datatypes/SingleSelect";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

@observer
export class MultiSelectFieldSettings extends React.Component<IFieldDataTypeSettingsProps> {
	public override render() {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as IMultiSelectFieldSettingsDefinition;

		const updateMultiSelectList = (value: ListObject[]) => {
			settings.choiceList = value;
			onChange();
		};

		return (
			<Field label="Items In Dropdown">
				<ListBuilderInput
					list={settings.choiceList}
					onChange={updateMultiSelectList}
				/>
			</Field>
		);
	}
}

export interface IMultiSelectFieldSettingsDefinition {
	choiceList: ListObject[];
}

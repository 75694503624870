import {Observer} from "mobx-react";
import {useCallback, useRef, useState} from "react";
import {User} from "../../../data/models/User";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Permission} from "../../../generated/api/base";
import {XyiconFeature} from "../../../generated/api/base";
import {UserGroup} from "../../../data/models/UserGroup";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {InitialsV5} from "../widgets/InitialsV5";
import {
	AddUserOrUserGroupStyled,
	DropdownSelectorStyled,
	NameContainerStyled,
	RowStyled,
	SectionTitle,
	SharingPanelStyled,
} from "../sharing/SharingPanel.styled";
import {useAppStore} from "../../../StateManager";
import {useClickOutside} from "../utils";
import {ButtonV5} from "../button/ButtonV5";
import {SelectSearchFieldV5} from "../input/search/SelectSearchFieldV5";

interface IAddUserOrUserGroupProps {
	readonly exceptions?: string[];
	readonly usersOnly?: boolean;
	readonly setSelectedItems: (items: any[]) => void;
	readonly onDeleteUser?: (user: User) => void;
	readonly onDeleteUserGroup?: (userGroup: UserGroup) => void;
	readonly getPermissionType?: (permission: Permission.View | Permission.Update) => void;
	readonly ownedByUserId: string;
	readonly selectedItemList?: any[];
}

export const AddUserOrUserGroupV5 = (props: IAddUserOrUserGroupProps) => {
	const appState = useAppStore((state) => state.appState);
	const {exceptions, usersOnly, ownedByUserId, selectedItemList, setSelectedItems, getPermissionType, onDeleteUser, onDeleteUserGroup} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const ref = useRef<HTMLDivElement>();

	const onToggleIsOpen = () => {
		setIsOpen((o) => !o);
	};

	const onClose = useCallback(() => {
		setIsOpen(false);
		setSearchString("");
	}, []);

	useClickOutside([ref], onClose);

	const onItemSelect = (id: string, itemName: string, feature: XyiconFeature) => {
		const isItemSelected = selectedItemList.some((item) => item.id === id);

		if (!isItemSelected) {
			setSelectedItems([...selectedItemList, {id, itemName, feature}]);
		}

		setSearchString("");
	};

	const getPermission = (value: Permission.View | Permission.Update) => {
		getPermissionType(value);
	};

	return (
		<Observer>
			{() => {
				const exceptionsWithCurrentUser = [...exceptions, appState.user?.id];

				let users = appState.actions.getList<User>(XyiconFeature.User);

				if (searchString) {
					users = users.filter((user) => User.search(user, searchString));
				}

				let sharedUsers: User[] = [];

				if (exceptionsWithCurrentUser?.length) {
					sharedUsers = users.filter((user) => exceptionsWithCurrentUser.includes(user.id));
				}

				let userGroups = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);

				if (searchString) {
					userGroups = userGroups.filter((userGroup) => UserGroup.search(userGroup, searchString));
				}

				let sharedUserGroups: UserGroup[] = [];

				if (exceptions?.length) {
					sharedUserGroups = userGroups.filter((userGroup) => exceptions.includes(userGroup.id));
				}

				return (
					<AddUserOrUserGroupStyled
						ref={ref}
						className={ReactUtils.cls("AddUserOrUserGroup", {isOpen})}
					>
						<SelectSearchFieldV5
							className="hbox flex_1"
							value={searchString}
							onInput={setSearchString}
							onClick={() => onToggleIsOpen()}
							selectedItems={selectedItemList}
							placeholder={!isOpen && selectedItemList.length == 0 ? "Enter user/group, comma separated" : " "}
							getPermissionType={getPermission}
							isDropdown={true}
						/>
						{(isOpen || searchString) && (
							<DropdownSelectorStyled
								className="AddUserOrUserGroup__selector vbox"
								ref={ref}
								$isDropdown={true}
							>
								<SharingPanelStyled $isDropdown={true}>
									{!usersOnly && userGroups.length !== 0 && (
										<div className="section borderBottom">
											<SectionTitle>User Groups</SectionTitle>
											{userGroups
												.toSorted((a: UserGroup, b: UserGroup) => StringUtils.sortIgnoreCase(a.name, b.name))
												.map((userGroup) => (
													<RowStyled
														key={userGroup.id}
														onClick={() => {
															if (!sharedUserGroups.some((sharedUserGroup) => sharedUserGroup.id === userGroup.id)) {
																onItemSelect(userGroup.id, userGroup.name, XyiconFeature.UserGroup);
															}
														}}
														className={ReactUtils.cls({selected: selectedItemList.some((item) => item.id === userGroup.id)})}
													>
														<div className="avatar">
															<InitialsV5
																name={userGroup.name}
																className="usergroup"
															/>
														</div>
														<NameContainerStyled>
															<div className="name">{userGroup.name}</div>
															<div className="counter">{userGroup.renderMemberCount()}</div>
														</NameContainerStyled>
														{sharedUserGroups.some((sharedUserGroup) => sharedUserGroup.id === userGroup.id) && (
															<ButtonV5
																label="Unshare"
																title="Unshare"
																className="unshare"
																onClick={(e) => onDeleteUserGroup(userGroup)}
															/>
														)}
													</RowStyled>
												))}
										</div>
									)}
									{users.length !== 0 && (
										<div className="section">
											<SectionTitle>Users</SectionTitle>
											{users
												.toSorted((a: User, b: User) => StringUtils.sortIgnoreCase(a.fullName, b.fullName))
												.map((user: User) => (
													<RowStyled
														key={user.id}
														className={ReactUtils.cls({
															unregistered: user.status === "invited",
															selected: selectedItemList.some((item) => item.id === user.id),
														})}
														onClick={() => {
															if (!sharedUsers.some((sharedUsers) => sharedUsers.id === user.id)) {
																onItemSelect(user.id, user.firstName, XyiconFeature.User);
															}
														}}
													>
														<div className="avatar">
															{user.profileFileName ? (
																<img
																	src={user.profileFileName}
																	alt={`${user.fullName} profile image`}
																/>
															) : (
																<InitialsV5
																	name={user.fullName || user.email}
																	className="users"
																/>
															)}
														</div>
														<NameContainerStyled>
															<div className="name">{user.status === "invited" ? "Unregistered User" : user.fullName}</div>
															<div className="email">{user.email}</div>
														</NameContainerStyled>
														{user.id !== ownedByUserId && sharedUsers.some((sharedUserGroup) => sharedUserGroup.id === user.id) && (
															<ButtonV5
																label="Unshare"
																title="Unshare"
																className="unshare"
																onClick={(e) => onDeleteUser(user)}
															/>
														)}
													</RowStyled>
												))}
										</div>
									)}
									{userGroups.length === 0 && users.length === 0 && (
										<div className="empty">We couldn't find a match. To invite this user to Xyicon, please contact your Administrator.</div>
									)}
								</SharingPanelStyled>
							</DropdownSelectorStyled>
						)}
					</AddUserOrUserGroupStyled>
				);
			}}
		</Observer>
	);
};

import * as React from "react";
import {observer} from "mobx-react";
import {SpaceEditorMode} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {SpaceTool} from "../../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {DockableTitle} from "../../../../modules/space/spaceeditor/ui/toolbar/Dockable";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {colorPalette} from "../../../styles/colorPalette";
import type {Color} from "../../../../../generated/api/base/models/Color";
import type {TTitleAlignment} from "./ToolButtonV5";
import {ToolButtonV5} from "./ToolButtonV5";
import {SpaceToolBarStyled} from "./SpaceToolBarStyled";
import {ToolTogglerV5} from "./ToolTogglerV5";
import {SpaceAlignToolBarV5} from "./SpaceAlignToolBarV5";

interface IPrimaryToolBarProps {
	readonly active: boolean;
	readonly mode: SpaceEditorMode;
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly setDockableOpen: (value: boolean, title: DockableTitle, onlyIfNotDocked?: boolean) => void;
	readonly isCatalogOpen: boolean;
	readonly isBoundaryTypesWindowOpen: boolean;
	readonly isUnplottedXyiconsOpen: boolean;
	readonly openMarkupToolBar: () => void;
	readonly openSpaceAlignToolBar: () => void;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
	readonly createToolButton: (id: SpaceTool, tooltipAlignment: TTitleAlignment, color?: Color) => React.JSX.Element;
}

@observer
export class PrimaryToolBarV5 extends React.Component<IPrimaryToolBarProps> {
	private onBoundaryClick = () => {
		if (this.props.isBoundaryTypesWindowOpen) {
			if (this.props.spaceViewRenderer.boundaryManager.typeId) {
				// Closing, and a boundary type is already selected
				this.props.setActiveTool("boundary");
			}
		}
		this.props.setDockableOpen(!this.props.isBoundaryTypesWindowOpen, "Boundary");
	};

	private onCatalogClick = () => {
		this.props.setDockableOpen(!this.props.isCatalogOpen, "Catalog");
	};

	private onUnplottedXyiconsClick = () => {
		this.props.setDockableOpen(!this.props.isUnplottedXyiconsOpen, "Unplotted Xyicons");
	};

	private closeDockable(onlyIfNotDocked: boolean = false) {
		if (this.props.isCatalogOpen) {
			this.props.setDockableOpen(false, "Catalog", onlyIfNotDocked);
		}
		if (this.props.isBoundaryTypesWindowOpen) {
			this.props.setDockableOpen(false, "Boundary", onlyIfNotDocked);
		}
		if (this.props.isUnplottedXyiconsOpen) {
			this.props.setDockableOpen(false, "Unplotted Xyicons", onlyIfNotDocked);
		}
	}

	private openMarkupToolBar = () => {
		this.closeDockable(true);
		this.props.openMarkupToolBar();
	};

	public openSpaceAlignToolBar = () => {
		this.closeDockable(true);
		this.props.openSpaceAlignToolBar();
	};

	private getNumberOfUnplottedXyicons() {
		return this.props.spaceViewRenderer.actions.getUnplottedXyicons().length;
	}

	private getToolBar() {
		switch (this.props.mode) {
			case SpaceEditorMode.NORMAL:
				return (
					<SpaceToolBarStyled
						className={ReactUtils.cls({SpaceToolBar: true, collapsed: !this.props.active})}
						$activeBackgroundColor={colorPalette.primary.c500Primary}
					>
						{this.props.createToolButton("selection", "right", null)}
						{this.props.createToolButton("pan", "right", null)}
						<ToolTogglerV5
							icon="boundary"
							title="Draw Boundary"
							active={this.props.isBoundaryTypesWindowOpen || this.props.activeToolId === "boundary"}
							setActive={this.onBoundaryClick}
						/>
						<ToolTogglerV5
							icon="catalog"
							title="Catalog Panel"
							active={this.props.isCatalogOpen}
							setActive={this.onCatalogClick}
						/>
						<ToolTogglerV5
							icon="unplotted"
							title="Unplotted Xyicons"
							active={this.props.isUnplottedXyiconsOpen}
							setActive={this.onUnplottedXyiconsClick}
							notificationNumber={this.getNumberOfUnplottedXyicons()}
						/>
						<ToolButtonV5
							icon="pen"
							title="Markup Tool"
							onClick={this.openMarkupToolBar}
							isActive={false}
						/>

						{/* <ToolToggler icon="helperLines" title="Helper Lines" active={this.state.isHelperLinesActive} setActive={this.setHelperLinesActive} /> */}
					</SpaceToolBarStyled>
				);
			case SpaceEditorMode.SET_SCALE:
				return (
					<SpaceToolBarStyled
						className={ReactUtils.cls({SpaceToolBar: true, collapsed: !this.props.active})}
						$activeBackgroundColor={colorPalette.primary.c500Primary}
					>
						{this.props.createToolButton("pan", "right")}
						{this.props.createToolButton("setScale", "right")}
					</SpaceToolBarStyled>
				);
			case SpaceEditorMode.ALIGN:
				return (
					<SpaceAlignToolBarV5
						active={this.props.active}
						createToolButton={this.props.createToolButton}
					/>
				);
		}
	}

	public override render() {
		return this.getToolBar();
	}
}

import {useState} from "react";
import {useAppStore} from "../../../../../StateManager";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import type {UpdateSpaceVersionRequest} from "../../../../../generated/api/base";
import type {SpaceVersion} from "../../../../../data/models/SpaceVersion";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {DateTimeInputV5} from "../../../input/datetime/DateTimeInputV5";
import {FieldV5} from "../../../details/FieldV5";
import {CreatePopupFieldStyled} from "../../../popup/CreatePopupField.styled";
import {CreatePopupStyled} from "../../CreatePopupStyled";

interface IVersionSetEditPopupV5Props {
	readonly onClose: () => void;
	readonly parentRef: HTMLDivElement;
	readonly versionSet: SpaceVersion;
}

export const VersionSetEditPopupV5 = (props: IVersionSetEditPopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const [name, setName] = useState<string>(props.versionSet.name);
	const [issuanceDate, setIssuanceDate] = useState<string>(DateFormatter.format(props.versionSet.date.toISOString(), "date"));
	const [saveClicked, setSaveClicked] = useState<boolean>(false);

	const onSaveClicked = async () => {
		const {versionSet} = props;

		setSaveClicked(true);

		versionSet.name = name;
		versionSet.date = new Date(issuanceDate);

		const params: UpdateSpaceVersionRequest = {
			portfolioID: appState.portfolioId,
			spaceVersionID: versionSet.id,
			name: name,
			issuanceDate: versionSet.date.toISOString(),
		};

		await appState.app.transport.requestForOrganization({
			url: "spaceversions/update",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		setSaveClicked(false);
		props.onClose();
	};

	const isFormValid = name && appState.actions.isVersionSetNameValid(name, props.versionSet.id);

	return (
		<CreatePopupStyled
			onClose={props.onClose}
			label="Edit Version Set"
			parentRef={props.parentRef}
			buttonProps={{
				onClick: onSaveClicked,
				disabled: saveClicked || !isFormValid,
				label: "Save",
				hideIcon: true,
			}}
		>
			<CreatePopupFieldStyled>
				<FieldV5
					label="Version Name"
					className="focused"
				>
					<ClickToEditInputV5
						value={name}
						onLiveChange={(name) => setName(name)}
						onChange={(name) => setName(name)}
						getErrorMessage={(name) => (appState.actions.isVersionSetNameValid(name, props.versionSet.id) ? "" : "Value needs to be unique!")}
						focused={true}
					/>
				</FieldV5>
				<FieldV5
					label="Issuance Date"
					className="focused"
				>
					<DateTimeInputV5
						value={issuanceDate}
						onChange={(date) => setIssuanceDate(date)}
						format="date"
					/>
				</FieldV5>
			</CreatePopupFieldStyled>
		</CreatePopupStyled>
	);
};

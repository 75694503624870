import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {Permission, XyiconFeature} from "../../../../../../generated/api/base";
import type {AppState} from "../../../../../../data/state/AppState";
import {SpaceItemContainer} from "./SpaceItemContainer";
import type {ISpaceItemContainerProps} from "./SpaceItemContainer";

interface IDraggableXyiconCatalogItemProps extends ISpaceItemContainerProps {
	readonly item: Catalog | Xyicon;
	readonly isSelected: boolean;
	readonly appState?: AppState;
	readonly queryString: string;
}

@inject("appState")
@observer
export class DraggableXyiconCatalogItem extends React.Component<IDraggableXyiconCatalogItemProps> {
	private get userXyiconPermission() {
		const {appState, item} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	}

	public override render() {
		const {
			item,
			isSelected,
			onPointerDown,
			onPointerMove,
			onPointerUp,
			showInfoButton,
			onBreakLinkClick,
			onDuplicateCatalogClick,
			onCreateUnplottedXyiconClick,
			queryString,
		} = this.props;

		return (
			<div
				className={ReactUtils.cls("DraggableXyiconCatalogItem", {selected: isSelected, noPermission: this.userXyiconPermission < Permission.Update})}
			>
				<SpaceItemContainer
					item={item}
					onPointerDown={onPointerDown}
					onPointerMove={onPointerMove}
					onPointerUp={onPointerUp}
					showInfoButton={showInfoButton}
					showDeleteButton={item.ownFeature === XyiconFeature.Xyicon}
					onBreakLinkClick={onBreakLinkClick}
					onDuplicateCatalogClick={onDuplicateCatalogClick}
					onCreateUnplottedXyiconClick={onCreateUnplottedXyiconClick}
					queryString={queryString}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../../../widgets/button/IconButton";
import {Field} from "../../../../widgets/form/field/Field";
import {Button} from "../../../../widgets/button/Button";
import type {AppState} from "../../../../../data/state/AppState";
import type {CreatePortfolioPermissionSetRequest} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {ClickToEditInput} from "../../../../widgets/input/clicktoedit/ClickToEditInput";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import {GeneralPermissions} from "./GeneralPermissions";

interface ICreatePermissionSetPanelProps {
	readonly onClose: (createdId?: string) => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly open?: boolean;
}

interface ICreatePermissionSetPanelState {
	name: string;
}

@inject("appState")
@inject("transport")
@observer
export class CreatePermissionSetPanel extends React.PureComponent<ICreatePermissionSetPanelProps, ICreatePermissionSetPanelState> {
	constructor(props: ICreatePermissionSetPanelProps) {
		super(props);
		this.state = {
			name: "",
		};
	}

	private onCreateClicked = async () => {
		const {name} = this.state;

		const data: CreatePortfolioPermissionSetRequest = {
			name: name,
			portfolioFieldPermissions: [],
			portfolioPermission: GeneralPermissions.generalPermissionOptions[0].id,
			featurePermissions: [],
		};
		const models = await this.props.transport.services.feature.create<PermissionSet>(data, XyiconFeature.PermissionSet);

		this.setState({name: ""});

		this.props.onClose(models?.[0]?.id);
	};

	private isValid = (value: string) => {
		return this.props.appState.actions.isPermissionSetNameValid(value, "");
	};

	private getErrorMessage = (value: string) => {
		return !value.trim() ? "Name cannot be empty!" : this.isValid(value) ? "" : "Value not valid!";
	};

	private onKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === "Enter") {
			this.onCreateClicked();
		}
	};

	private onTextInput = (value: string) => {
		this.setState({name: value});
	};

	private onCancel = () => {
		this.setState({name: ""});
		this.props.onClose();
	};

	public override render() {
		const {open} = this.props;
		const {name} = this.state;

		const isFormValid = name && this.isValid(name);

		return (
			<div
				className={ReactUtils.cls("createPanel SidePanel", {open})}
				onKeyDown={this.onKeyDown}
			>
				<div className="heading createBox hbox">
					<h4>Create Permission Set</h4>
					<IconButton
						className="closePanel"
						icon="close"
						onClick={this.onCancel}
					/>
				</div>
				<div className="sidePanelButtons">
					<Button
						label="Cancel"
						title="Cancel"
						onClick={this.onCancel}
					/>
					<Button
						label="Create"
						title="Create"
						onClick={this.onCreateClicked}
						disabled={!isFormValid}
						className="primary"
					/>
				</div>
				<div className="container">
					<Field
						label="Name"
						className="focused"
					>
						<ClickToEditInput
							value={name}
							onLiveChange={this.onTextInput}
							noButtons={true}
							getErrorMessage={this.getErrorMessage}
							focused={open}
						/>
					</Field>
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {SVGIcon} from "../button/SVGIcon";
import {ToggleSwitch} from "../button/switch/ToggleSwitch";
import {SelectInput} from "../input/select/SelectInput";
import {ReactUtils} from "../../utils/ReactUtils";
import {DomUtils, HorizontalAlignment} from "../../../utils/dom/DomUtils";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import {SideBar} from "../../sidebar/SideBar";
import {InfoButton} from "../../modules/abstract/common/infobutton/InfoButton";

const toggleContainerMainTitlesWithoutDocuments: Set<string> = new Set<string>([
	"Fields",
	"AssignByCatalog",
	"AssignByXyicon",
	"Spaces",
	"Links",
	"Ports",
	"Port Template",
]);

export interface IToggleContainerProps<T> {
	readonly title: React.ReactNode;
	readonly children: React.ReactNode;
	// Optional, if you want to manage state externally, or use as the default state:
	readonly open?: boolean;
	readonly onToggleOpen?: (value: boolean) => void;
	readonly onAddClicked?: () => void;
	readonly className?: string;
	readonly isEnableTogglerVisible?: boolean;
	readonly isEnabled?: boolean;
	readonly onEnabledChange?: (value: boolean) => void;
	readonly scrollIntoViewOnOpen?: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly saveStateToLocalStorage?: boolean;
	readonly hideTitle?: boolean;
	readonly dropdown?: {
		selected: T;
		options: T[];
		render: (item: T) => React.ReactNode;
		onChange: (option: T) => void;
	};
	readonly noPadding?: boolean;
	readonly helperText?: string;
}

interface IToggleContainerState {
	open: boolean;
	isEnabled: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class ToggleContainer<T = any> extends React.PureComponent<IToggleContainerProps<T>, IToggleContainerState> {
	private _container = React.createRef<HTMLDivElement>();

	constructor(props: IToggleContainerProps<T>) {
		super(props);
		const openByDefault = props.saveStateToLocalStorage ? this.getLocalStorageValueByKey(props.title) : !!props.open;

		this.state = {
			open: openByDefault,
			isEnabled: !!props.isEnabled,
		};

		if (this.props.saveStateToLocalStorage) {
			this.saveValueToLocalStorage(this.props.title, openByDefault);
		}
	}

	private getKeyForLocalStorage(organizationId: string, sectionTitle: string) {
		return `srv4-org-${organizationId}-feature-${SideBar.activeNav}-section-${sectionTitle}-state`;
	}

	private getLocalStorageValueByKey(sectionTitle: React.ReactNode): boolean {
		// sections are open by default
		const defaultValue = true;

		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const value = this.props.transport.services.localStorage.get(this.getKeyForLocalStorage(organizationId, sectionTitle.toString()));

			return value === false ? false : defaultValue;
		}

		return defaultValue;
	}

	private saveValueToLocalStorage(sectionTitle: React.ReactNode, value: boolean) {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const titleAsString = sectionTitle.toString();

			if (titleAsString) {
				this.props.transport.services.localStorage.set(this.getKeyForLocalStorage(organizationId, titleAsString), value);
			}
		}
	}

	public onToggleOpen = () => {
		const {onToggleOpen, open, scrollIntoViewOnOpen} = this.props;
		let newOpenValue = !open;

		if (onToggleOpen) {
			onToggleOpen(newOpenValue);
		} else {
			newOpenValue = !this.state.open;
			this.setState({
				open: newOpenValue,
			});
		}

		if (this.props.saveStateToLocalStorage) {
			this.saveValueToLocalStorage(this.props.title, newOpenValue);
		}

		if (scrollIntoViewOnOpen && newOpenValue) {
			// opening now -> scroll into view to make sure the opened content can be seen
			requestAnimationFrame(() => {
				DomUtils.scrollIntoViewIfNeeded(this._container.current);
			});
		}
	};

	private onAddClicked = (event: React.MouseEvent) => {
		event.stopPropagation();
		this.props.onAddClicked?.();
	};

	private updateOpenState() {
		if (this.props.appState.isDetailsTabBeingOpenByDocumentOfItem) {
			if (toggleContainerMainTitlesWithoutDocuments.has(this.props.title.toString())) {
				if (this.state.open) {
					this.setState({
						open: false,
					});
				}
			} else if (this.props.title.toString() === "Documents") {
				if (!this.state.open) {
					this.setState({
						open: true,
					});
				}
			}

			setTimeout(() => {
				if (this.props.appState.isDetailsTabBeingOpenByDocumentOfItem) {
					this.props.appState.isDetailsTabBeingOpenByDocumentOfItem = false;
				}
			}, 100);
		}
	}

	public override componentDidMount(): void {
		this.updateOpenState();
	}

	public override componentDidUpdate(prevProps: IToggleContainerProps<T>) {
		if (this.props.open !== prevProps.open) {
			this.setState({open: this.props.open});
		}

		this.updateOpenState();
	}

	public override render() {
		const {helperText, noPadding, className, hideTitle, dropdown, title, children, isEnableTogglerVisible, onAddClicked, onEnabledChange} =
			this.props;
		const open = this.props.onToggleOpen ? this.props.open : this.state.open;
		const isEnabled = this.props.onEnabledChange ? this.props.isEnabled : this.state.isEnabled;

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls("ToggleContainer", {[className]: className, open, noPadding})}
				data-cy={`ToggleContainer_${this.props.title}`}
			>
				{!hideTitle && (
					<h4
						className="title"
						onClick={this.onToggleOpen}
					>
						<span className="label hbox">
							{title}
							{helperText && (
								<InfoButton
									bubbleText={helperText}
									style={{top: -15, left: "auto"}}
								/>
							)}
						</span>
						{onAddClicked && (
							<span
								className="btn add"
								onClick={this.onAddClicked}
							>
								<SVGIcon
									icon="add"
									classNames="icon-add"
								/>
							</span>
						)}
						{!!isEnableTogglerVisible && (
							<ToggleSwitch
								value={isEnabled}
								onChange={onEnabledChange}
							/>
						)}
						{dropdown && (
							<SelectInput
								className="dropDownMenu"
								selected={dropdown.selected}
								onChange={dropdown.onChange}
								options={dropdown.options}
								render={dropdown.render}
								dropdownIcon="more-vertical"
								horizontalAlignment={HorizontalAlignment.center}
							/>
						)}
						<span
							className="btn toggleIndicator"
							style={{
								transition: "transform 0.2s ease",
								transform: `rotate(${open ? 180 : 0}deg)`,
							}}
						>
							<SVGIcon
								icon="angle_down"
								classNames="icon-angle_down"
							/>
						</span>
					</h4>
				)}
				<div
					className={ReactUtils.cls("children", {open})}
					data-cy="toggle-container"
				>
					{children}
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import type {AppState} from "../../../../../../../data/state/AppState";
import type {IModel} from "../../../../../../../data/models/Model";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import {Initials} from "../../../../../../widgets/Initials";
import type {Navigation} from "../../../../../../../Navigation";
import {NavigationEnum} from "../../../../../../../Enums";
import {MoreIconsWidget} from "./more/MoreIconsWidget";
import {XyiconModelField} from "./XyiconModelField";
import {maxNumberOfIcons, MultiDefaultFieldSortIcons, MultiDefaultFieldSortOrder} from "./MultiDefaultFieldUtils";

interface IMultiDefaultFieldsProps {
	readonly items: IModel[];
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
}

@inject("navigation")
@inject("appState")
@inject("transport")
@observer
export class MultiDefaultFields extends React.Component<IMultiDefaultFieldsProps> {
	private onClickIcon = (model: IModel) => {
		const {appState, transport, navigation} = this.props;

		if ([XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.Markup].includes(model.ownFeature)) {
			appState.actions.navigateToSpaceItemById(model.id, model.ownFeature as XyiconFeature.Xyicon | XyiconFeature.Boundary | XyiconFeature.Markup);
		}
		if (model.ownFeature === XyiconFeature.Portfolio) {
			transport.services.localStorage.set(appState.actions.getKeyForLocalStorageSelectedPortfolio(), model.id);
			transport.services.auth.switchPortfolio(model.id);
			navigation.goApp(NavigationEnum.NAV_SPACES);
		}
		if (model.ownFeature === XyiconFeature.Space) {
			navigation.goApp(NavigationEnum.NAV_SPACE, model.id);
		}
	};

	public override render() {
		const {items} = this.props;

		const allowedItems = items.filter((item) => MultiDefaultFieldSortOrder.includes(item.ownFeature));

		if (!allowedItems.length) {
			return null;
		}

		const sortedItems = allowedItems.sort(MultiDefaultFieldSortIcons);
		const xyicons = allowedItems.filter((item) => item.ownFeature === XyiconFeature.Xyicon) as Xyicon[];

		return (
			<>
				<div className="icons">
					{sortedItems.slice(0, maxNumberOfIcons).map((item: IModel, index: number) => {
						const detailedItem = this.props.appState.actions.getTypeById(item?.typeId);
						const color = detailedItem?.settings.color.hex || "FFFFFF";

						return (
							<div
								key={item.id}
								className="thumbnail"
								onClick={() => this.onClickIcon(item)}
							>
								<Initials
									item={item}
									color={color}
									name={detailedItem?.name}
									className={item.ownFeature === XyiconFeature.XyiconCatalog ? "noClick" : ""}
									thumbnailSize={50}
								/>
							</div>
						);
					})}
					{sortedItems.length > maxNumberOfIcons && (
						<MoreIconsWidget
							items={sortedItems}
							onClick={this.onClickIcon}
						/>
					)}
				</div>
				{xyicons.length > 0 && (
					<Field label="Model">
						<XyiconModelField
							xyicons={xyicons}
							disabled={false}
						/>
					</Field>
				)}
			</>
		);
	}
}

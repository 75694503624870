import * as React from "react";
import {NumberInput} from "../../../../../widgets/input/number/NumberInput";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";

export interface IExplicitDateParamProps {
	readonly param: {length: number; type: string};
	onChange(value: {length: number; type: string}): void;
}

// "Days" | "Weeks" | "Months"

export class ExplicitDateParam extends React.Component<IExplicitDateParamProps> {
	private get _length() {
		return this.props.param?.length || 1;
	}

	private get _type() {
		return this.props.param?.type || "Days";
	}

	private onEffect = () => {
		if (!this.props.param) {
			this.props.onChange({length: this._length, type: this._type});
		}
	};

	public override componentDidMount() {
		this.onEffect();
	}

	public override componentDidUpdate() {
		this.onEffect();
	}

	public override render() {
		const {onChange} = this.props;

		return (
			<>
				<NumberInput
					value={this._length}
					onInput={(value) => onChange({length: value, type: this._type})}
					decimals={0}
					step={1}
				/>
				<SelectInput
					selected={this._type}
					onChange={(value) => onChange({length: this._length, type: value})}
					options={["Days", "Weeks", "Months"]}
					searchBar={false}
				/>
			</>
		);
	}
}

import type {CSSProperties} from "styled-components";
import styled from "styled-components";
import {Observer} from "mobx-react";
import type {MouseEvent, RefObject} from "react";
import {useEffect, useReducer, useRef, useState} from "react";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {IModel} from "../../../../../data/models/Model";
import type {Catalog} from "../../../../../data/models/Catalog";
import type {ICreateUnplottedXyiconParam} from "../../../../modules/abstract/ModuleView";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import DockIcon from "../../../icons/dock.svg?react";
import UndockIcon from "../../../icons/undock.svg?react";
import CloseIcon from "../../../icons/xmark.svg?react";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import type {GridViewV5} from "../../../abstract/GridViewV5";
import {useClickOutside} from "../../../utils";
import {HTMLUtils} from "../../../../../utils/HTML/HTMLUtils";
import {ShadowDivStyled} from "../../../details/DetailsContainerV5";
import {PanelStyled} from "../../../abstract/Panel";
import {ToolButtonStyled} from "../toolbar/ToolButtonV5";
import {BoundaryTypeContainerV5} from "./BoundaryTypeContainerV5";
import {XyiconCatalogContainerV5} from "./XyiconCatalogContainerV5";

export type DockableTitle = "Catalog" | "Boundary" | "Unplotted Xyicons";

interface IDockableProps {
	readonly style?: CSSProperties;
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly gridView?: RefObject<GridViewV5<IModel>>;
	readonly setDocked: (value: boolean, title: DockableTitle) => void;
	readonly setOpen: (value: boolean, title: DockableTitle) => void;
	readonly isDocked: boolean;
	readonly title: DockableTitle;
	readonly setActiveTool: (id: string) => void;
	readonly onAddCatalogClick: (event: MouseEvent) => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
}

const _animationTime: number = 300;

export const DockableV5 = (props: IDockableProps) => {
	const {isDocked, title, setDocked, setOpen, spaceViewRenderer, setActiveTool, style} = props;
	const _previousTitleRef = useRef<DockableTitle>(null);
	const _elementRef = useRef<HTMLDivElement>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const onDockTogglerClick = () => {
		setDocked(!isDocked, title);
	};

	const onClose = () => {
		setIsOpen(false);
	};

	const onCloseClick = () => {
		onClose();
		if (isDocked) {
			setOpen(false, title);
		} else {
			setTimeout(() => {
				setOpen(false, title);
			}, _animationTime);
		}
	};

	const onClickOutside = () => {
		if (!isDocked) {
			onCloseClick();
		}
	};

	const onBoundaryTypeClick = (typeId: string) => {
		spaceViewRenderer.boundaryManager.setTypeId(typeId);
		setActiveTool("boundary");

		if (!isDocked) {
			onCloseClick();
		}
	};

	const fadeIn = (milliseconds: number) => {
		setTimeout(() => {
			forceUpdate();
		}, milliseconds);
	};

	const getElement = (renderTitle: DockableTitle) => {
		const {gridView, onAddCatalogClick, onDuplicateCatalogClick, onCreateUnplottedXyicons} = props;

		if (renderTitle === "Boundary") {
			return (
				<BoundaryTypeContainerV5
					spaceViewRenderer={spaceViewRenderer}
					onBoundaryTypeClick={onBoundaryTypeClick}
				/>
			);
		} else {
			return (
				<XyiconCatalogContainerV5
					spaceViewRenderer={spaceViewRenderer}
					gridView={gridView}
					renderTitle={renderTitle}
					onAddCatalogClick={onAddCatalogClick}
					onDuplicateCatalogClick={onDuplicateCatalogClick}
					onCreateUnplottedXyicons={onCreateUnplottedXyicons}
					isDocked={isDocked}
					isOpen={isOpen}
					onClose={onClose}
					onCloseClick={onCloseClick}
				/>
			);
		}
	};

	useEffect(() => {
		_previousTitleRef.current = title;
	});

	useClickOutside([_elementRef], onClickOutside, "mousedown", [
		`.${HTMLUtils.popupBackdropV5Class}`,
		`#${HTMLUtils.modalContainerId}`,
		`${ShadowDivStyled}`,
		`${ShadowDivStyled} + ${PanelStyled}`,
		`${ToolButtonStyled}[data-title="Draw Boundary"]`,
		`${ToolButtonStyled}[data-title="Catalog Panel"]`,
		`${ToolButtonStyled}[data-title="Unplotted Xyicons"]`,
	]);

	const dockTitle = isDocked ? `Undock ${title}` : `Dock ${title}`;

	if (_previousTitleRef.current == null) {
		_previousTitleRef.current = title;
		requestAnimationFrame(() => {
			setIsOpen(true);
		});
	}

	const hasChanged = _previousTitleRef.current !== title;

	if (hasChanged) {
		fadeIn(_previousTitleRef.current === null ? 0 : _animationTime);
	}

	const renderTitle = _previousTitleRef.current || title;
	const isVisible = isOpen && title === _previousTitleRef.current;

	return (
		<Observer>
			{() => {
				return (
					<DockableStyled
						ref={_elementRef}
						style={style ?? {}}
						className={ReactUtils.cls("Dockable", {docked: isDocked, visible: isVisible})}
						data-min-width={240}
						data-default-width={440}
					>
						<Header>
							<Title className="title">{renderTitle}</Title>
							<ButtonContainer>
								<IconButtonV5
									onClick={onDockTogglerClick}
									title={dockTitle}
									IconComponent={isDocked ? UndockIcon : DockIcon}
								/>
								<IconButtonV5
									onClick={onCloseClick}
									IconComponent={CloseIcon}
								/>
							</ButtonContainer>
						</Header>
						{getElement(renderTitle)}
					</DockableStyled>
				);
			}}
		</Observer>
	);
};

const DockableStyled = styled.div`
	position: absolute;
	top: 50%;
	left: 75px;
	min-width: 200px;
	width: 336px;
	display: flex;
	flex-direction: column;
	flex: 1;
	opacity: 0;
	z-index: 2;
	white-space: nowrap;
	background-color: ${colorPalette.white};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
	pointer-events: none;
	transform: translateY(-50%);
	transition: ease-in-out all 0.3s;

	&:not(.docked) {
		max-height: calc(100% - 200px);
		border-radius: ${radius.md};
	}

	&.visible {
		pointer-events: initial;
		opacity: 1;
	}

	&.docked {
		width: initial;
		position: relative;
		left: 0;
		top: 0;
		flex: 1;
		transform: initial;
		box-shadow: none;
		min-height: 0;
	}
`;

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
`;

const Title = styled.div`
	font-size: 18px;
	line-height: 24px;
	font-weight: 700;
`;

const ButtonContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

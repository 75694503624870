import {Observer} from "mobx-react";
import type {Catalog} from "../../../../data/models/Catalog";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import StarIcon from "../../icons/star.svg?react";
import StarFillIcon from "../../icons/star-fill.svg?react";
import {ReactUtils} from "../../../utils/ReactUtils";

interface ISetFavoriteButtonProps {
	readonly items: Catalog[];
	readonly setFavorite: (items: Catalog[], value: boolean) => void;
}

export const SetFavoriteButton = (props: ISetFavoriteButtonProps) => {
	const {items, setFavorite} = props;

	return (
		<Observer>
			{() => {
				const areAllFavorites = items.every((catalog) => catalog.isFavorite);

				return (
					<IconButtonV5
						className={ReactUtils.cls("SetFavoriteButton", {selected: areAllFavorites})}
						title={areAllFavorites ? "Remove from Favorites" : "Add to Favorites"}
						IconComponent={areAllFavorites ? StarFillIcon : StarIcon}
						onClick={() => setFavorite(items, !areAllFavorites)}
					/>
				);
			}}
		</Observer>
	);
};

import * as React from "react";
import {inject} from "mobx-react";
import {ScreenType} from "../ScreenType";
import {IconToggle} from "../../../widgets/button/IconToggle";
import {ViewSelect} from "../../../widgets/viewselect/ViewSelect";
import {DropdownButton} from "../../../widgets/button/DropdownButton";
import {IconButton} from "../../../widgets/button/IconButton";
import type {View} from "../../../../data/models/View";
import type {AppState} from "../../../../data/state/AppState";
import {XyiconFeature} from "../../../../generated/api/base";
import type {IModel} from "../../../../data/models/Model";
import type {ExportFormat} from "../../../../data/exporters/BaseExporter";
import {ExporterFactory} from "../../../../data/exporters/ExporterFactory";

interface IViewBarProps<T extends IModel> {
	readonly selectedView: View;
	readonly items: T[];
	readonly feature: XyiconFeature;
	readonly selectedScreen: ScreenType;
	readonly onSelectScreen: (id: ScreenType) => void;
	readonly onShareClick: (viewId: string) => void;
	readonly onSelect: (view: string) => void;
	readonly appState?: AppState;
}

@inject("appState")
export class ViewBar<T extends IModel> extends React.Component<IViewBarProps<T>> {
	private createToggle(id: ScreenType, className: string, title: string) {
		return (
			<IconToggle
				value={id === this.props.selectedScreen}
				onChange={() => this.props.onSelectScreen(id)}
				className={className}
				icon={id}
				title={title}
			/>
		);
	}

	private onExport = (type: ExportFormat) => {
		const exporter = ExporterFactory.createExporter(type, this.props.appState);

		if (exporter) {
			const {selectedView, items} = this.props;

			exporter.exportView(selectedView, items);
		}
	};

	public override render() {
		const {feature} = this.props;

		return (
			<div className="ViewBarModule barModule">
				<ViewSelect
					// feature={feature}
					selected={this.props.selectedView}
					onShareClick={this.props.onShareClick}
					onSelect={this.props.onSelect}
					// onNewClick={this.props.onNewClick}
					// onEditClick={this.props.onEditClick}
					// onShareClick={this.props.onShareClick}
				/>

				<div className="headerDivider" />
				{feature === XyiconFeature.Space && (
					<>
						{this.createToggle(ScreenType.CARD, "cardViewButton", "Card")}
						{this.createToggle(ScreenType.GRID, "gridViewButton", "Grid")}
						<div className="headerDivider" />
					</>
				)}
				<div className="exportOptions button">
					<DropdownButton
						button={
							<div className="hbox alignCenter">
								<IconButton
									className="exportViewButton"
									icon="export"
									title="Export"
								/>
								<div className="label">Export</div>
								<IconButton
									className="smallArrowDown"
									icon="down"
									title="Export"
								/>
							</div>
						}
						options={[
							{
								label: "Export to Excel",
								onClick: () => this.onExport("excel"),
							},
							// {
							// 	label: "Export to PDF",
							// 	onClick: () => this.onExport("pdf")
							// },
							{
								label: "Export to CSV",
								onClick: () => this.onExport("csv"),
							},
						]}
					/>
				</div>
			</div>
		);
	}
}

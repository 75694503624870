import * as React from "react";
import {inject, observer} from "mobx-react";
import {ToggleContainer} from "../../../../widgets/container/ToggleContainer";
import type {AppState} from "../../../../../data/state/AppState";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {SearchAddAndListItems} from "../../../../widgets/SearchAddAndListItems";
import {Initials} from "../../../../widgets/Initials";
import {Button} from "../../../../widgets/button/Button";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {Portfolio} from "../../../../../data/models/Portfolio";

interface IPortfolioGroupPortfoliosProps {
	readonly portfolioGroup: PortfolioGroup;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IPortfolioGroupPortfoliosState {
	search: string;
}

@inject("transport")
@inject("appState")
@observer
export class PortfolioGroupPortfolios extends React.PureComponent<IPortfolioGroupPortfoliosProps, IPortfolioGroupPortfoliosState> {
	constructor(props: IPortfolioGroupPortfoliosProps) {
		super(props);
		this.state = {
			search: "",
		};
	}

	private onPortfolioAdded = (portfolio: Portfolio) => {
		this.props.portfolioGroup.addPortfolio(portfolio.id);
		this.updateApi();
	};

	private onPortfolioRemoved = (portfolio: Portfolio) => {
		this.props.portfolioGroup.removePortfolio(portfolio.id);
		this.updateApi();
	};

	private updateApi() {
		const portfolioGroup = this.props.portfolioGroup;

		if (portfolioGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(portfolioGroup.id, XyiconFeature.PortfolioGroup, {
				portfolios: portfolioGroup.portfolioIds,
				name: portfolioGroup.name,
			});
		}
	}

	private renderAddedLisItem = (portfolio: Portfolio) => {
		return (
			<>
				<div className="avatar">
					<Initials name={portfolio.name} />
				</div>
				<div className="name">{portfolio.name}</div>
			</>
		);
	};

	private renderAddLisItem = (portfolio: Portfolio) => {
		return (
			<>
				<div className="avatar">
					<Initials name={portfolio.name} />
				</div>
				<div className="name">{portfolio.name}</div>
				<div className="flex_1" />
				<Button
					label="Add"
					title="Add"
					className="primary rounded dark"
				/>
			</>
		);
	};

	private onSearch = (value: string) => {
		this.setState({search: value});
	};

	private filterPortfolioList = (portfolio: Portfolio) => {
		const {refId, name} = portfolio;
		const {search} = this.state;

		let boolean = false;

		if (!search || StringUtils.containsIgnoreCase(refId, search) || StringUtils.containsIgnoreCase(name, search)) {
			boolean = true;
		}

		return boolean;
	};

	public override render() {
		const {search} = this.state;
		const {portfolioGroup, appState} = this.props;

		let addedPortfolios = portfolioGroup.portfolioIds
			.map((portfolioId) => appState.actions.getPortfolioById(portfolioId))
			.filter((portfolio) => !!portfolio);

		const portfolios = appState.actions
			.getList<Portfolio>(XyiconFeature.Portfolio)
			.filter((p) => !addedPortfolios.find((addedPortfolio) => addedPortfolio.id === p.id))
			.filter(this.filterPortfolioList);

		return (
			<ToggleContainer
				title="Portfolios in this group"
				open={true}
				noPadding={true}
			>
				<SearchAddAndListItems
					search={search}
					onSearch={this.onSearch}
					addedItems={addedPortfolios}
					sections={[
						{
							items: portfolios,
							feature: XyiconFeature.Portfolio,
							sortFunction: (a: Portfolio, b: Portfolio) => StringUtils.sortIgnoreCase(a.name, b.name),
							renderAddListItem: this.renderAddLisItem,
							renderAddedListItem: this.renderAddedLisItem,
							onItemAdd: this.onPortfolioAdded,
							onItemRemove: this.onPortfolioRemoved,
						},
					]}
					searchTextPlaceholder="Search portfolio name or portfolio ID to add to the group"
				/>
			</ToggleContainer>
		);
	}
}

import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {PortfolioGroup} from "../../../../../data/models/PortfolioGroup";
import type {AppState} from "../../../../../data/state/AppState";
import type {Portfolio} from "../../../../../data/models/Portfolio";
import {XyiconFeature} from "../../../../../generated/api/base";
import {InitialsV5} from "../../../widgets/InitialsV5";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import type {SelectedItem} from "../../../widgets/SearchAddAndListItemsV5";
import {SearchAddAndListItemsV5} from "../../../widgets/SearchAddAndListItemsV5";
import {UsersInThisGroupStyled} from "../usergroups/UserGroupUsersV5";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import SearchPlusIcon from "../../../icons/search-plus.svg?react";

interface IPortfolioGroupPortfoliosProps {
	readonly portfolioGroup: PortfolioGroup;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IPortfolioGroupPortfoliosState {
	search: string;
	popupOpen: boolean;
	selectedItems: SelectedItem[];
}

@inject("transport")
@inject("appState")
@observer
export class PortfolioGroupPortfoliosV5 extends React.PureComponent<IPortfolioGroupPortfoliosProps, IPortfolioGroupPortfoliosState> {
	private parentRef = React.createRef<HTMLDivElement>();

	constructor(props: IPortfolioGroupPortfoliosProps) {
		super(props);
		this.state = {
			search: "",
			popupOpen: false,
			selectedItems: [],
		};
	}

	private onAddPopupOpen = () => {
		this.setState({popupOpen: true});
	};

	private onAddPopupClose = () => {
		this.setState({
			popupOpen: false,
			selectedItems: [],
		});
	};

	private onPortfolioAdded = (portfolio: Portfolio) => {
		this.props.portfolioGroup.addPortfolio(portfolio.id);
		this.updateApi();
	};

	private onPortfolioRemoved = (portfolio: Portfolio) => {
		this.props.portfolioGroup.removePortfolio(portfolio.id);
		this.updateApi();
	};

	private updateApi() {
		const portfolioGroup = this.props.portfolioGroup;

		if (portfolioGroup.id) {
			// we're in edit mode
			this.props.transport.services.feature.update(portfolioGroup.id, XyiconFeature.PortfolioGroup, {
				portfolios: portfolioGroup.portfolioIds,
				name: portfolioGroup.name,
			});
		}
	}

	private renderAddedLisItem = (portfolio: Portfolio) => {
		return (
			<>
				<div
					className="avatar"
					style={{marginRight: "8px"}}
				>
					<InitialsV5 name={portfolio.name} />
				</div>
				<div className="name">{portfolio.name}</div>
			</>
		);
	};

	private renderAddLisItem = (portfolio: Portfolio) => {
		return (
			<>
				<div
					className="avatar"
					style={{marginRight: "8px"}}
				>
					<InitialsV5 name={portfolio.name} />
				</div>
				<div className="name">{portfolio.name}</div>
				<div className="flex_1" />
			</>
		);
	};

	private onSearch = (value: string) => {
		this.setState({search: value});
	};

	private filterPortfolioList = (portfolio: Portfolio) => {
		const {refId, name} = portfolio;
		const {search} = this.state;

		let boolean = false;

		if (!search || StringUtils.containsIgnoreCase(refId, search) || StringUtils.containsIgnoreCase(name, search)) {
			boolean = true;
		}

		return boolean;
	};

	private onPortfolioSelect = (portfolio: Portfolio) => {
		const {id, name} = portfolio;

		this.setState(
			(prevState) => ({
				selectedItems: [...prevState.selectedItems, {id, itemName: name, feature: XyiconFeature.Portfolio}],
			}),
			() => {
				this.setSelectedItems(this.state.selectedItems);
			},
		);
	};

	private setSelectedItems = (items: SelectedItem[]) => {
		this.setState({selectedItems: items});
	};

	public override componentDidMount() {
		const {selectedItems} = this.state;

		this.setSelectedItems(selectedItems);
	}

	public override render() {
		const {search} = this.state;
		const {portfolioGroup, appState} = this.props;

		let addedPortfolios = portfolioGroup.portfolioIds
			.map((portfolioId) => appState.actions.getPortfolioById(portfolioId))
			.filter((portfolio) => !!portfolio);

		const portfolios = appState.actions
			.getList<Portfolio>(XyiconFeature.Portfolio)
			.filter((portfolio) => !!portfolio)
			.filter(this.filterPortfolioList);

		return (
			<UsersInThisGroupStyled>
				<hr className="line" />
				<ToggleContainerV5
					title="Portfolios in this group"
					open={true}
					noPadding={true}
					className="toggleContainer"
				>
					<IconButtonV5
						IconComponent={SearchPlusIcon}
						onClick={this.onAddPopupOpen}
						divRef={this.parentRef}
					/>
					<SearchAddAndListItemsV5
						search={search}
						onSearch={this.onSearch}
						addedItems={addedPortfolios}
						sections={[
							{
								items: portfolios,
								feature: XyiconFeature.Portfolio,
								sortFunction: (a: Portfolio, b: Portfolio) => StringUtils.sortIgnoreCase(a.name, b.name),
								renderAddListItem: this.renderAddLisItem,
								renderAddedListItem: this.renderAddedLisItem,
								onItemAdd: this.onPortfolioAdded,
								onItemSelect: this.onPortfolioSelect,
								onItemRemove: this.onPortfolioRemoved,
							},
						]}
						popupLable="Add portfolios to group"
						isPopupOpen={this.state.popupOpen}
						parentRef={this.parentRef}
						onClose={this.onAddPopupClose}
						selectedItemList={this.state.selectedItems}
						setSelectedItems={this.setSelectedItems}
						onAddUserClick={this.onAddPopupOpen}
						buttonLabel="Add Portfolio"
					/>
				</ToggleContainerV5>
			</UsersInThisGroupStyled>
		);
	}
}

import {XyiconFeature, FieldDataType} from "../../generated/api/base";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import type {IFilterRow} from "../models/filter/Filter";
import type {IModel} from "../models/Model";
import type {IFieldAdapter, IFieldPointer} from "../models/field/Field";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {AppFieldActions} from "./AppFields";
import {inheritedFeatures, inheritedFeaturesForFilter} from "./AppStateConstants";
import type {AppState} from "./AppState";

export interface ICachedObject {
	version: number;
	values: IFilterValues;
	// we show a MultipleChoiceFieldMap if multiple items have different values for a given refId
	// eg.:
	// ["a", "b"] === ["a", "b"] -> not showing the field
	// ["a", "b"] !== ["a"] -> showing the field
	showMultipleChoiceFieldMap: IMultipleChoiceFieldMap;
}

export interface IFilterValues {
	[refId: string]: string[];
}

interface IMultipleChoiceFieldMap {
	[refId: string]: boolean;
}

export class FilterActions {
	private readonly _appState: AppState;
	private readonly _cached: {[key: string]: ICachedObject} = {};

	constructor(appState: AppState) {
		this._appState = appState;
	}

	public getFieldsForFilters(features: XyiconFeature[], moduleFeature?: XyiconFeature, multiplePortfolios?: boolean): IFieldAdapter[] {
		let result: IFieldAdapter[] = [];

		for (const feature of features) {
			const filterFeatures = inheritedFeaturesForFilter[feature as keyof typeof inheritedFeaturesForFilter] || [];

			if (multiplePortfolios && feature !== XyiconFeature.Portfolio) {
				// portfolioIDList/Organization scoped Reports can filter on Portfolio fields too
				// because multiple Portfolios are possible in that case.
				filterFeatures.push(XyiconFeature.Portfolio);
			}
			for (const inheritedFeature of filterFeatures) {
				const defaultFields = (this._appState.defaultFields[inheritedFeature] || []).filter((field) => {
					if (
						moduleFeature === XyiconFeature.SpaceEditor &&
						(field.refId === AppFieldActions.getRefId(XyiconFeature.Boundary, "lastModifiedBy") ||
							field.refId === AppFieldActions.getRefId(XyiconFeature.Boundary, "lastModifiedAt"))
					) {
						// In SpaceEditor module, only show xyicon lastModifiedBy,At, not boundary
						// TODO this could be cleaned up by using only moduleFeature instead of features array (?)
						return false;
					}
					if (field.feature === XyiconFeature.Portfolio) {
						return (
							field.refId === AppFieldActions.getRefId(XyiconFeature.Portfolio, "name") ||
							field.refId === AppFieldActions.getRefId(XyiconFeature.Portfolio, "type")
						);
					}
					return field.feature === feature || field.displayOnLinks;
				});

				result.push(...defaultFields);

				const customFields = (this._appState.fields[inheritedFeature] || []).filter((field) => field.feature === feature || field.displayOnLinks);

				result.push(...customFields);
			}
		}

		return ArrayUtils.uniqByKey(result, (field) => field.refId);
	}

	public updateCachedObject(
		items: IModel[],
		unfilteredItems: IModel[],
		features: XyiconFeature[],
		filters: IFilterRow[],
		fieldRefId: string,
		feature: XyiconFeature | null,
		spaceId = "",
	): void {
		const cached = this.getCached(features, filters, feature, spaceId);
		let multipleChoiceReference: string = undefined;

		let filteredItems = items;

		if (filters.some((filter) => filter.type === "filter" && filter.value.field === fieldRefId)) {
			filteredItems = unfilteredItems;
		}
		if (feature === XyiconFeature.SpaceEditor) {
			filteredItems = filteredItems.filter((item) => item.spaceId === spaceId);
		}

		if (!filteredItems.length) {
			return;
		}
		if (!cached.values || !cached.values[fieldRefId]) {
			return;
		}

		const field = this._appState.fieldsByRef[fieldRefId];
		const isMultipleChoice = field.dataType === FieldDataType.MultipleChoiceList;
		const fieldKeys: Set<string> = new Set();

		let showMultipleChoiceFieldMap: boolean;

		for (const item of filteredItems) {
			const values = this.getFilterValues(item, fieldRefId);

			for (const value of values) {
				if (value || (value as any) === 0) {
					// TODO note: is it possible that value is 0?
					fieldKeys.add(value);
				} else {
					// Blank value option
					fieldKeys.add("");
				}
			}

			// Used for multiple choice
			if (values.length === 0) {
				// Blank value option
				fieldKeys.add("");
			}

			if (isMultipleChoice && !showMultipleChoiceFieldMap) {
				// ["b", "a"] -> "a;b"
				const multipleChoiceValue = values.slice().sort(StringUtils.sortIgnoreCase).join(";");

				if (multipleChoiceReference === undefined) {
					// first item
					multipleChoiceReference = multipleChoiceValue;
				} else {
					if (multipleChoiceReference !== multipleChoiceValue) {
						showMultipleChoiceFieldMap = true;
					}
				}
			}
		}
		cached.values[fieldRefId] = Array.from(fieldKeys);
	}
	public getCachedObject(
		items: IModel[],
		unfilteredItems: IModel[],
		features: XyiconFeature[],
		filters: IFilterRow[],
		feature: XyiconFeature | null,
		spaceId = "",
	): ICachedObject {
		const cached = this.getCached(features, filters, feature, spaceId);
		const newVersion = this.getListVersions(features);

		if (!cached.values || newVersion > cached.version) {
			const unloadedLists = this.getFeaturesForVersions(features).filter((f) => !this._appState.lists[f].loaded);

			if (!items.length || unloadedLists.length > 0) {
				// Items not loaded yet.
				// Very important not to partially recalculated filters during when list are loaded one by one
				// because that is causing huge blocks on the main thread for bigger accounts.
				// (Eg. reload the app with xyicons grid being open for a project which has a lot of xyicons
				// -> filters are calculated 3 times, it takes ~15 second of UI blocking each time
				// With this unloadedList.length check, we reduce that to 1 time (only when all lists are loaded)
				//
				// TODO besides this, we could also make sure to set all lists in a mobx action at once, so they are
				// not triggering separate UI updates

				return {
					values: {},
					version: -1,
					showMultipleChoiceFieldMap: {},
				};
			}

			// Recalculate cache
			const fields = this.getFieldsForFilters(features, feature);
			const fieldKeys: {[key: string]: Set<string>} = {};
			const showMultipleChoiceFieldMap: {[refId: string]: boolean} = {};

			// As per https://dev.azure.com/xyicon/SpaceRunner%20V4/_sprints/taskboard/Product/SpaceRunner%20V4/2021%20November/S-79?workitem=2369
			// Add all boundary, and xyicon types, but only if we're in the spaceeditor
			if (feature === XyiconFeature.SpaceEditor) {
				for (const feat of features) {
					// Xyicons, and Boundaries
					const types = this._appState.actions.getTypesByFeature(feat);
					const typeFieldRefId = AppFieldActions.getRefId(feat, "type");

					fieldKeys[typeFieldRefId] = new Set();
					for (const type of types) {
						fieldKeys[typeFieldRefId].add(type.name);
					}
				}
			}

			// Add fields that have items associated with
			for (const field of fields) {
				let multipleChoiceReference: string = undefined;
				const isMultipleChoice = field.dataType === FieldDataType.MultipleChoiceList;

				let filteredItems = items;

				if (filters.some((filter) => filter.type === "filter" && filter.value.field === field.refId)) {
					filteredItems = unfilteredItems;
				}
				if (feature === XyiconFeature.SpaceEditor) {
					// If there's only one xyicon and one boundary in the active space, and you filter out all the boundaries based on their IDs
					// You'll see that the "Xyicon ID" section is gone too. To prevent this, we're using the "unfilteredItems" as the base array here
					filteredItems = unfilteredItems.filter((item) => item.spaceId === spaceId);
				}

				for (const item of filteredItems) {
					const values = this.getFilterValues(item, field.refId);

					fieldKeys[field.refId] = fieldKeys[field.refId] || new Set();

					for (const value of values) {
						if (value || (value as any) === 0) {
							// TODO note: is it possible that value is 0?
							fieldKeys[field.refId].add(value);
							// don't build the entire list here since it has a impact on the performance.
							// list will get populated when user open up a particular field in the filter.
							if (feature !== XyiconFeature.SpaceEditor && fieldKeys[field.refId].size >= 2) {
								break;
							}
						} else {
							// Blank value option
							fieldKeys[field.refId].add("");
						}
					}

					// Used for multiple choice
					if (values.length === 0) {
						// Blank value option
						fieldKeys[field.refId].add("");
					}

					if (isMultipleChoice && !showMultipleChoiceFieldMap[field.refId]) {
						// ["b", "a"] -> "a;b"
						const multipleChoiceValue = values.slice().sort(StringUtils.sortIgnoreCase).join(";");

						if (multipleChoiceReference === undefined) {
							// first item
							multipleChoiceReference = multipleChoiceValue;
						} else {
							if (multipleChoiceReference !== multipleChoiceValue) {
								showMultipleChoiceFieldMap[field.refId] = true;
							}
						}
					}

					// don't build the entire list here since it has a impact on the performance.
					// list will get populated when user open up a particular field in the filter.
					if (feature !== XyiconFeature.SpaceEditor && fieldKeys[field.refId].size >= 2) {
						break;
					}
				}
			}

			// Converts this:
			// {
			//   f35: {
			//      value1: true,
			//      value2: true
			//   }
			// }
			//
			// to this:
			// {
			//   f35: ["value1", "value2"]
			// }
			const fieldRefIds = Object.keys(fieldKeys);
			const values: IFilterValues = {};

			for (const refId of fieldRefIds) {
				values[refId] = Array.from(fieldKeys[refId]);
			}

			cached.values = values;
			cached.version = newVersion;
			cached.showMultipleChoiceFieldMap = showMultipleChoiceFieldMap;
		}

		return cached;
	}

	private getCached(features: XyiconFeature[], filters: IFilterRow[] = [], feature: XyiconFeature, spaceId: string) {
		const key = `${features.join("-") + JSON.stringify(filters) + this.getViewKey(feature)}-${spaceId}`;

		if (!this._cached[key]) {
			this._cached[key] = {
				version: -1,
				values: null,
				showMultipleChoiceFieldMap: {},
			};
		}
		return this._cached[key];
	}

	private getViewKey(feature: XyiconFeature) {
		// if feature is null, we're in view criteria -> no need to add view key
		if (feature) {
			const view = this._appState.actions.getSelectedView(feature);

			if (view) {
				return JSON.stringify(view.filters);
			}
		}

		return "";
	}

	// Returns the array of values used to display in the SimpleFilter option and
	// for applying the actual filters to filter through data.
	// Propagations are included because we want to be able to filter eg. xyicons on the
	// boundary fields that are propagated to them.
	public getFilterValues(item: IModel, refId: IFieldPointer): string[] {
		const actions = this._appState.actions;
		const field = actions.getFieldByRefId(refId);

		if (!field) {
			// field could have been deleted
			return [];
		}

		// This finds own values + inherited (eg. xyicon gets space field values)
		const value = actions.getFieldValue(item, field.refId);

		// TODO this is very similar to actions.renderValues()

		// Only add value if it's not null. If the value is null it means the model doesn't have this field mapped.
		// For example if a xyicon model is filtered on a boundary field with an "is blank" filter,
		// if we added null that would become "" due to formatValue() and that would cause the "is blank" filter to
		// become true.
		// No we don't add the null value, so the values array will only contain the boundary field value inherited
		// by the xyicon (if any)
		const values: (string | boolean)[] = value !== null ? [value] : [];

		const propagations = actions.getFieldPropagations(item, field);

		values.push(...propagations.map((p) => p.value));
		return values.flatMap((v) => actions.formatValue(v, field.refId));
	}

	public getListVersions(features: XyiconFeature[]): number {
		let result = 0;
		const versionFeatures = this.getFeaturesForVersions(features);

		for (const versionFeature of versionFeatures) {
			result += this._appState.lists[versionFeature].getVersion();
		}
		return result;
	}

	public getFeaturesForVersions(features: XyiconFeature[]): XyiconFeature[] {
		const result: XyiconFeature[] = [];

		for (const feature of features) {
			result.push(...inheritedFeatures[feature]);
		}

		if (!result.includes(XyiconFeature.Link)) {
			const featuresAffectedByLinks = [XyiconFeature.Xyicon, XyiconFeature.Boundary];

			if (features.some((f) => featuresAffectedByLinks.includes(f))) {
				result.push(XyiconFeature.Link);
			}
		}

		return result;
	}

	// public getFilterRefIds(values: IFilterValues)
	// {
	// 	const fieldRefIds = Object.keys(values);
	// 	// Only take fields where there are at least 2 possible values to choose from
	// 	// (if there is only one result or zero, we don't show that to the user because it's a pointless filter).
	// 	return fieldRefIds.filter(refId => values[refId]?.length >= 2);
	// }
}

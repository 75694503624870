import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {AppState} from "../../../data/state/AppState";
import type {DistanceUnitName, ExtendedDistanceUnitName} from "../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import type {BoundarySpaceMap3D} from "../../modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import {MathUtils} from "../../../utils/math/MathUtils";
import type {Xyicon3D} from "../../modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {SpaceItem} from "../../modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import {Permission} from "../../../generated/api/base";
import {ReactUtils} from "../../utils/ReactUtils";
import {ClickToEditInputV5} from "../input/clicktoedit/ClickToEditInputV5";
import {Functions} from "../../../utils/function/Functions";
import {ToggleContainerV5} from "../widgets/ToggleContainerV5/ToggleContainerV5";
import type {Markup3D} from "../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {IPropertyDetails, PropertyName, spaceItemForProperties} from "../../modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import {fontWeight} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {FieldV5} from "./FieldV5";
import {MassPropertyInputV5} from "./datatypes/mass/MassPropertyInputV5";
import {UnitSelectorV5} from "./UnitSelectorV5";
import {MarkupPropertiesV5} from "./MarkupPropertiesV5";
import {FieldStyled} from "./Field.styled";

interface IPropertiesSectionProps {
	readonly appState?: AppState;
	readonly saveStateToLocalStorage: boolean;
	readonly properties: IPropertyDetails[];
}

interface IPropertiesSectionState {
	updatingValue: PropertyName | "";
	distanceUnitName: DistanceUnitName;
}

const pzDzArray = ["pz", "dz"];

@inject("appState")
@observer
export class PropertiesSectionV5 extends React.Component<IPropertiesSectionProps, IPropertiesSectionState> {
	constructor(props: IPropertiesSectionProps) {
		super(props);
		this.state = {
			updatingValue: "",
			distanceUnitName: Constants.DISTANCE_UNITS.inch.name as DistanceUnitName,
		};
	}

	private getAreaSum(items: BoundarySpaceMap3D[]) {
		let areaSum = 0;

		for (const item of items) {
			areaSum += item.area;
		}

		const {spaceUnitsPerMeter} = this.props.appState.app.graphicalTools.spaceViewRenderer.space;

		return MathUtils.convertAreaFromSpaceUnit(areaSum, this.state.distanceUnitName, spaceUnitsPerMeter).toFixed(2);
	}

	private async onChangeItemProp(value: string, propName: PropertyName, item: spaceItemForProperties) {
		const {spaceOffsetInDistanceUnit, spaceSizeInDistanceUnit} = this.getSpaceInfo();
		let valueAsNumber = parseFloat(value);

		if (isNaN(valueAsNumber)) {
			valueAsNumber = Constants.EPSILON;
		}

		switch (propName) {
			case "px":
				valueAsNumber = MathUtils.clamp(valueAsNumber, spaceOffsetInDistanceUnit.x, spaceOffsetInDistanceUnit.x + spaceSizeInDistanceUnit.x);
				break;
			case "py":
				valueAsNumber = MathUtils.clamp(valueAsNumber, spaceOffsetInDistanceUnit.y, spaceOffsetInDistanceUnit.y + spaceSizeInDistanceUnit.y);
				break;
			case "dx":
				valueAsNumber = MathUtils.clamp(valueAsNumber, Constants.EPSILON, spaceSizeInDistanceUnit.x);
				break;
			case "dy":
				valueAsNumber = MathUtils.clamp(valueAsNumber, Constants.EPSILON, spaceSizeInDistanceUnit.y);
				break;
		}

		this.setState({updatingValue: propName});
		await this.props.appState.actions.updateProperties(valueAsNumber, propName, [item], this.state.distanceUnitName as DistanceUnitName);
		this.setState({updatingValue: ""});
	}

	private onChangeDistanceUnit = (value: ExtendedDistanceUnitName) => {
		this.setState({distanceUnitName: value as DistanceUnitName});
	};

	private getSpaceInfo() {
		const {spaceViewRenderer} = this.props.appState.app;

		if (spaceViewRenderer.isMounted) {
			const {space, spaceOffset, spaceSize} = spaceViewRenderer;
			const {distanceUnitName} = this.state;
			const spaceOffsetInDistanceUnit = {
				x: MathUtils.convertDistanceFromSpaceUnit(spaceOffset.x, distanceUnitName, space.spaceUnitsPerMeter),
				y: MathUtils.convertDistanceFromSpaceUnit(spaceOffset.y, distanceUnitName, space.spaceUnitsPerMeter),
			};

			const spaceSizeInDistanceUnit = {
				x: MathUtils.convertDistanceFromSpaceUnit(spaceSize.x, distanceUnitName, space.spaceUnitsPerMeter),
				y: MathUtils.convertDistanceFromSpaceUnit(spaceSize.y, distanceUnitName, space.spaceUnitsPerMeter),
			};

			return {
				spaceOffsetInDistanceUnit,
				spaceSizeInDistanceUnit,
			};
		}

		return null;
	}

	private getBubbleText(min: number, max: number) {
		return `Enter a value between ${min.toFixed(2)} and ${max.toFixed(2)}`;
	}

	private getSpaceItemValue(partName: string, partRef: string, spaceItem3D: spaceItemForProperties) {
		if (partName === "Position") {
			return spaceItem3D.position[partRef as "x" | "y"];
		} else if (partName === "Dimensions") {
			// only boundaries have dimension
			return spaceItem3D[partRef as keyof SpaceItem] as number;
		}
		//orientation
		return spaceItem3D[partRef as keyof SpaceItem] as number;
	}

	private getMarkupProps() {
		const {appState} = this.props;
		const spaceViewRenderer = appState.app.spaceViewRenderer;
		const selectedMarkups = spaceViewRenderer.spaceItemController.markupManager.selectedItems as Markup3D[];
		const allSelectedItems = spaceViewRenderer.spaceItemController.selectedItems;

		return (
			selectedMarkups.length > 0 &&
			allSelectedItems.length === selectedMarkups.length && (
				<MarkupPropertiesV5
					markups={selectedMarkups}
					spaceViewRenderer={spaceViewRenderer}
				></MarkupPropertiesV5>
			)
		);
	}

	private getFields() {
		const {properties, appState} = this.props;
		const {actions} = appState;
		const {spaceViewRenderer} = appState.app;

		const selectedBoundaries = spaceViewRenderer.spaceItemController.boundaryManager.selectedItems as BoundarySpaceMap3D[];
		const selectedXyicons = spaceViewRenderer.spaceItemController.xyiconManager.selectedItems as Xyicon3D[];
		const selectedMarkups = spaceViewRenderer.spaceItemController.markupManager.selectedItems as Markup3D[];
		const allItems = [...selectedXyicons, ...selectedBoundaries, ...selectedMarkups];

		const firstItem = allItems[0];

		const hasPermission = actions.someSpaceItemsHaveGivenPermission(allItems, Permission.Update);

		return properties.map((property) => {
			const isArea = property.name === "Area";

			return (
				<React.Fragment key={`${allItems.map((item) => item?.id).join("_")}_${property.name}`}>
					<FieldV5
						label={property.name}
						className={ReactUtils.cls({hasInfo: property.measure, PropertySet: isArea})}
					>
						{isArea && (
							<div className="hbox alignCenter width100">
								<ClickToEditInputV5
									value={this.getAreaSum(allItems.filter((item) => item.spaceItemType === "boundary") as BoundarySpaceMap3D[])}
									disabled={true}
									onChange={Functions.emptyFunction}
								/>
							</div>
						)}
					</FieldV5>
					{property.measure && (
						<UnitSelectorStyled
							unit={this.state.distanceUnitName}
							onChange={this.onChangeDistanceUnit}
						/>
					)}
					<PropertySetStyled>
						{property.parts.map((part) => {
							return allItems.length > 1 ? (
								<MassPropertyInputV5
									key={part.name}
									propertyName={property.name}
									propertyPartName={part.name}
									propertyRef={part.ref}
									propertyLabel={part.label}
									items={allItems}
									distanceUnitName={this.state.distanceUnitName}
									disabled={
										!hasPermission ||
										allItems.some((item) => (item.spaceItemType === "boundary" || item.spaceItemType === "markup") && pzDzArray.includes(part.name))
									}
									getSpaceItemValue={this.getSpaceItemValue}
								/>
							) : (
								firstItem && (
									<FieldV5
										label={part.label || part.name}
										className="coordinate"
										key={part.name}
									>
										<div className="hbox alignCenter width100">
											<ClickToEditInputV5
												value={
													actions.formatPropValue(
														this.getSpaceItemValue(property.name, part.ref, firstItem),
														part.name,
														firstItem,
														this.state.distanceUnitName,
													) || "0"
												}
												onChange={(value) => this.onChangeItemProp(value, part.name, firstItem)}
												updating={this.state.updatingValue === part.name}
												inputType="number"
												disabled={
													!hasPermission ||
													((firstItem.spaceItemType === "boundary" || firstItem.spaceItemType === "markup") && pzDzArray.includes(part.name))
												}
											/>
										</div>
									</FieldV5>
								)
							);
						})}
					</PropertySetStyled>
				</React.Fragment>
			);
		});
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	public override componentDidMount() {
		const {signals} = this.props.appState.app.spaceViewRenderer.spaceItemController;

		signals.itemsTranslated.add(this.forceUpdateArrow);
		signals.itemsRotated.add(this.forceUpdateArrow);
		signals.editedItemModified.add(this.forceUpdateArrow);
	}

	public override componentWillUnmount() {
		const {signals} = this.props.appState.app.spaceViewRenderer.spaceItemController;

		signals.itemsTranslated.remove(this.forceUpdateArrow);
		signals.itemsRotated.remove(this.forceUpdateArrow);
		signals.editedItemModified.remove(this.forceUpdateArrow);
	}

	public override render() {
		const {saveStateToLocalStorage} = this.props;

		return (
			<PropertiesSectionStyled
				title="Properties"
				className="Properties"
				saveStateToLocalStorage={saveStateToLocalStorage}
			>
				{this.getMarkupProps()}
				{this.getFields()}
			</PropertiesSectionStyled>
		);
	}
}

const PropertiesSectionStyled = styled(ToggleContainerV5)`
	> .children {
		gap: 0;

		> ${FieldStyled} .label {
			font-weight: ${fontWeight.bold};
			color: ${colorPalette.gray.c700Dark};
			margin-top: 16px;
		}
	}
`;

const PropertySetStyled = styled.div`
	display: flex;
	gap: 8px;
	flex-direction: column;

	${FieldStyled} {
		.element div {
			width: 100%;
		}
	}
`;

const UnitSelectorStyled = styled(UnitSelectorV5)`
	width: 150px;
	margin-bottom: 8px;
`;

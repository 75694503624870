import * as React from "react";
import {inject, observer} from "mobx-react";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {User} from "../../../data/models/User";
import type {UserGroup} from "../../../data/models/UserGroup";
import {AddUserOrUserGroupV5} from "../details/AddUserOrUserGroupV5";
import {InitialsV5} from "../widgets/InitialsV5";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {ShareOptionV5} from "../details/ShareOptionV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {ShareOption} from "../../modules/abstract/view/sharing/ShareOption";
import type {AppState} from "../../../data/state/AppState";
import {ConfirmWindowV5} from "../popup/ConfirmWindowV5";
import {PopupV5} from "../popup/PopupV5";
import {ButtonV5} from "../button/ButtonV5";
import type {ReportSharingSettingsData} from "../../../generated/api/reports";
import type {Collection} from "../../../data/models/abstract/Collection";
import {notify} from "../../../utils/Notify";
import {NotificationType} from "../../notification/Notification";
import {getShareOptionValue} from "./SharingUtils";
import {NameContainerStyled, RowStyled, SectionTitle, SharingPanelStyled, SharingStyled} from "./SharingPanel.styled";

export interface IGlobalViewFolderSharingSettingsData {
	userID?: string | null;
	userGroupID?: string | null;
}

interface IEditViewSharingProps {
	readonly onAddClick: (id: string, permission: Permission.View | Permission.Update, feature: XyiconFeature.User | XyiconFeature.UserGroup) => void;
	readonly onDeleteUserGroup: (userGroup: UserGroup) => Promise<any>;
	readonly onDeleteUser: (user: User) => Promise<any>;
	readonly onConfirmOwnershipChange: (sharing: ViewSharingSettingsData | ReportSharingSettingsData | IGlobalViewFolderSharingSettingsData) => void;
	readonly setUserGroupPermission: (
		permission: number,
		sharing: ViewSharingSettingsData | ReportSharingSettingsData | IGlobalViewFolderSharingSettingsData,
	) => void;
	readonly onSaveChanges: () => Promise<any>;
	readonly sharingSettings: ViewSharingSettingsData[] | ReportSharingSettingsData[] | IGlobalViewFolderSharingSettingsData[];
	readonly ownedByUserId: string;
	readonly itemLabel: "view" | "report" | "view folder";
	readonly itemName: string;
	readonly showShareOption: boolean;
	readonly onClose: () => void;
	readonly appState?: AppState;
}

interface SelectedItem {
	id: string;
	itemName: string;
	feature: XyiconFeature.User | XyiconFeature.UserGroup;
}

interface ISharingPopupState {
	selectedItems: SelectedItem[];
	permissionType: Permission.View | Permission.Update;
}

@inject("appState")
@observer
export class SharingPopupV5 extends React.Component<IEditViewSharingProps, ISharingPopupState> {
	private _isDeletePopupWindowOpen: boolean = false;

	constructor(props: IEditViewSharingProps) {
		super(props);
		this.state = {
			selectedItems: [],
			permissionType: null,
		};
	}

	private onDeleteUserGroup = async (userGroup: UserGroup) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				this.props.onDeleteUserGroup(userGroup);
			}
		}
	};

	private onDeleteUser = async (user: User) => {
		if (!this._isDeletePopupWindowOpen) {
			this._isDeletePopupWindowOpen = true;
			const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

			this._isDeletePopupWindowOpen = false;

			if (confirmed) {
				this.props.onDeleteUser(user);
			}
		}
	};

	private onShareClick = () => {
		this.state.selectedItems.forEach((item) => {
			this.props.onAddClick(item.id, this.state.permissionType, item.feature);

			notify(this.props.appState.app.notificationContainer, {
				type: NotificationType.Success,
				title: `"${item.itemName}" ${this.props.itemLabel} has been shared successfully!`,
				lifeTime: 10000,
			});
		});

		this.props.onSaveChanges();

		this.setState({
			selectedItems: [],
		});
	};

	private getShareOptionValue = (sharing: ViewSharingSettingsData | ReportSharingSettingsData) => {
		return getShareOptionValue(sharing, this.props.ownedByUserId);
	};

	private setUserPermission = async (permission: number, sharing: ViewSharingSettingsData | ReportSharingSettingsData) => {
		if (permission === ShareOption.OWNER) {
			if (!this._isDeletePopupWindowOpen) {
				this._isDeletePopupWindowOpen = true;
				const confirmed = await ConfirmWindowV5.open(
					`You will be removed as the owner of the "${this.props.itemName}" ${this.props.itemLabel} but will retain edit privileges. Do you want to continue?`,
					"Confirm Ownership Change",
					{ok: "Change Owner", cancel: "Cancel"},
				);

				this._isDeletePopupWindowOpen = false;

				if (confirmed) {
					this.props.onConfirmOwnershipChange(sharing);
				}
			}
		} else if (permission === Permission.Update) {
			if (this.props.itemLabel === "view") {
				(sharing as ViewSharingSettingsData).canEditSharedView = true;
			} else if (this.props.itemLabel === "report") {
				(sharing as ReportSharingSettingsData).canEditSharedReport = true;
			}
		} else if (permission === Permission.View) {
			if (this.props.itemLabel === "view") {
				(sharing as ViewSharingSettingsData).canEditSharedView = false;
			} else if (this.props.itemLabel === "report") {
				(sharing as ReportSharingSettingsData).canEditSharedReport = false;
			}
		}

		this.props.onSaveChanges();
	};

	private setSelectedItems = (items: []) => {
		this.setState({selectedItems: items});
	};

	private getPermission = (value: Permission.View | Permission.Update) => {
		this.setState({permissionType: value});
	};

	public override render() {
		const {appState, ownedByUserId, showShareOption, itemName, itemLabel, sharingSettings} = this.props;

		const users = appState.lists[XyiconFeature.User] as Collection<User>;
		const userGroups = appState.lists[XyiconFeature.UserGroup] as Collection<UserGroup>;

		const sharing = sharingSettings || [];
		const userGroupSharingList = sharing.filter((sh) => sh.userGroupID);
		const userSharingList = sharing.filter((sh) => sh.userID);

		const exceptionIds: string[] = [...userGroupSharingList.map((sh) => sh.userGroupID), ...userSharingList.map((sh) => sh.userID)];

		const itemOwnerMaybe: User | null = users?.getById(ownedByUserId);

		return (
			<PopupV5
				onClose={this.props.onClose}
				label={`Share ${StringUtils.capitalize(itemLabel)}: ${itemName}`}
				centerOnScreen={true}
				noButtons={true}
				className="SharingPopup"
				width="528px"
				height="528px"
				freezeRoot={true}
			>
				<SharingStyled>
					<div className="container">
						<AddUserOrUserGroupV5
							exceptions={exceptionIds}
							setSelectedItems={this.setSelectedItems}
							onDeleteUser={this.onDeleteUser}
							onDeleteUserGroup={this.onDeleteUserGroup}
							getPermissionType={this.getPermission}
							ownedByUserId={ownedByUserId}
							selectedItemList={this.state.selectedItems}
						/>
						<ButtonV5
							label="Share"
							title="Share"
							className="primary rounded dark"
							disabled={this.state.selectedItems.length === 0}
							onClick={this.onShareClick}
						/>
					</div>
					<SharingPanelStyled>
						<div className="borderBottom">
							<SectionTitle>User Groups</SectionTitle>
							{userGroupSharingList.find((sharing) => userGroups.getById(sharing.userGroupID)) &&
								userGroupSharingList
									.toSorted((a: ViewSharingSettingsData, b: ViewSharingSettingsData) =>
										StringUtils.sortIgnoreCase(userGroups.getById(a.userGroupID).name, userGroups.getById(b.userGroupID).name),
									)
									.map((sharing, index) => {
										const userGroup = userGroups.getById(sharing.userGroupID);

										if (!userGroups) {
											return null;
										}

										let hasUpdatePermission: boolean = false;

										if (itemLabel === "view" && (sharing as ViewSharingSettingsData).canEditSharedView) {
											hasUpdatePermission = true;
										}

										if (itemLabel === "report" && (sharing as ReportSharingSettingsData).canEditSharedReport) {
											hasUpdatePermission = true;
										}

										return (
											<RowStyled key={`${sharing.userGroupID}_${sharing.userID}`}>
												<div className="avatar">
													<InitialsV5
														name={userGroup.name}
														className="usergroup"
													/>
												</div>
												<NameContainerStyled>
													<div className="name">{userGroup.name}</div>
													<div className="counter">{userGroup.renderMemberCount()}</div>
												</NameContainerStyled>
												{appState.user?.isAdmin && !(this.props.itemLabel === "view folder" && userGroup.name === "Administrators") && (
													<ButtonV5
														label="Unshare"
														title="Unshare"
														className="unshare"
														onClick={() => this.onDeleteUserGroup(userGroup)}
													/>
												)}
												{showShareOption && (
													<ShareOptionV5
														value={hasUpdatePermission ? Permission.Update : Permission.View}
														onChange={(permission) => this.props.setUserGroupPermission(permission, sharing)}
														hasOwner={false}
														disabled={appState.user && !appState.user?.isAdmin}
													/>
												)}
											</RowStyled>
										);
									})}
						</div>
						<div>
							<SectionTitle>Users</SectionTitle>
							{itemOwnerMaybe && (
								<RowStyled className="item hbox">
									<div className="avatar">
										{itemOwnerMaybe?.profileFileName ? (
											<img
												src={itemOwnerMaybe.profileFileName}
												alt={`${itemOwnerMaybe?.fullName} profile image`}
											/>
										) : (
											<InitialsV5
												name={itemOwnerMaybe?.fullName || itemOwnerMaybe?.email}
												className="users"
											/>
										)}
									</div>
									<NameContainerStyled>
										<div className="name">{`${itemOwnerMaybe?.fullName}`}</div>
										<div className="email">{itemOwnerMaybe?.email}</div>
									</NameContainerStyled>
									<div className="sharedStyles"> Owner</div>
								</RowStyled>
							)}
							{userSharingList
								.toSorted((a: ViewSharingSettingsData, b: ViewSharingSettingsData) =>
									StringUtils.sortIgnoreCase(users.getById(a.userID)?.fullName, users.getById(b.userID)?.fullName),
								)
								.map((sharing, index) => {
									const user: User = users.getById(sharing.userID);

									if (!user || user.id === ownedByUserId) {
										return null;
									}

									return (
										<RowStyled
											key={`${sharing.userID}_${sharing.userGroupID}`}
											className={ReactUtils.cls("item hbox", {unregistered: (users.getById(sharing.userID) as User).status === "invited"})}
										>
											<div className="avatar">
												{user.profileFileName ? (
													<img
														src={user.profileFileName}
														alt={`${user.fullName} profile image`}
													/>
												) : (
													<InitialsV5
														name={user.fullName || user.email}
														className="users"
													/>
												)}
											</div>
											<NameContainerStyled>
												<div className="name">{user.fullName}</div>
												<div className="email">{user.email}</div>
											</NameContainerStyled>
											<ButtonV5
												label="Unshare"
												title="Unshare"
												className="unshare"
												onClick={() => this.onDeleteUser(user)}
											/>
											{showShareOption && (
												<ShareOptionV5
													value={this.getShareOptionValue(sharing)}
													onChange={(permission) => this.setUserPermission(permission, sharing)}
													hasOwner={itemLabel === "view" ? appState.user?.id === ownedByUserId : false}
												/>
											)}
										</RowStyled>
									);
								})}
						</div>
					</SharingPanelStyled>
				</SharingStyled>
			</PopupV5>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {ReactUtils} from "../../../utils/ReactUtils";
import {IconButton} from "../IconButton";
import type {IContextOption} from "../../context/ContextOptions";
import {ContextOptions} from "../../context/ContextOptions";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {TimeUtils} from "../../../../utils/TimeUtils";
import type {App} from "../../../../App";
import {MathUtils} from "../../../../utils/math/MathUtils";

interface IOptionsButtonProps {
	readonly className?: string;
	readonly disabled?: boolean;
	readonly options: IContextOption[];
	readonly onOpenChange?: (open: boolean) => void;
	readonly noFixedPosition?: boolean;
	readonly optionsZIndex?: number;
	readonly app?: App;
}

interface IOptionsButtonState {
	open: boolean;
	transform: TransformObj;
}

@inject("app")
@observer
export class OptionsButton extends React.PureComponent<IOptionsButtonProps, IOptionsButtonState> {
	private _container = React.createRef<HTMLDivElement>();
	private _contextMenu = React.createRef<HTMLDivElement>();
	private _mounted = false;

	constructor(props: IOptionsButtonProps) {
		super(props);
		this.state = {
			open: false,
			transform: null,
		};
	}

	private onClickMoreButton = async (event: React.MouseEvent) => {
		event.stopPropagation();
		this.setOpen(true);

		await TimeUtils.wait(100);
		FocusLoss.listen(this._contextMenu.current, this.onFocusLoss);
	};

	private onFocusLoss = (event?: MouseEvent) => {
		if (!this.props.noFixedPosition && !(event.target instanceof Window) && !this.props.app.modalContainer?.contains(event.target as Element)) {
			this.onClose();
		}

		FocusLoss.stopListen(this._contextMenu.current, this.onFocusLoss);
	};

	private onClose = () => {
		this.setOpen(false);
	};

	private setOpen(value: boolean) {
		if (this._mounted) {
			const {onOpenChange} = this.props;

			this.setState({open: value});
			if (onOpenChange) {
				onOpenChange(value);
			}
		}
	}

	public override componentDidMount() {
		this._mounted = true;
	}

	public override componentDidUpdate(prevProps: IOptionsButtonProps, prevState: IOptionsButtonState) {
		if (!this.props.noFixedPosition && !prevState.open && this.state.open && this._container.current && this._contextMenu.current) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._container.current, this._contextMenu.current)});
		}
	}

	public override componentWillUnmount() {
		this._mounted = false;
		FocusLoss.stopListen(this._contextMenu.current, this.onFocusLoss);
	}

	public override render() {
		const {options, noFixedPosition} = this.props;
		const inlineStyle: React.CSSProperties = {};

		if (MathUtils.isValidNumber(this.props.optionsZIndex)) {
			inlineStyle.zIndex = this.props.optionsZIndex;
		}

		const modalContainer = this.props.app.modalContainer;

		if (!noFixedPosition) {
			inlineStyle.transform = this.state.transform?.translate;
		}

		return (
			<div
				ref={this._container}
				className={ReactUtils.cls(`OptionsButton ${this.props.className || ""}`, {
					disabled: this.props.disabled,
					isOpen: this.state.open,
				})}
				onClick={this.onClickMoreButton}
			>
				<IconButton
					title="Options"
					icon="more-vertical"
				/>
				{this.state.open && (
					<DomPortal
						destination={modalContainer}
						noPortal={noFixedPosition}
					>
						<ContextOptions
							options={options}
							onSelect={this.onClose}
							style={inlineStyle}
							divRef={this._contextMenu}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

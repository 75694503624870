import styled, {css} from "styled-components";
import {ELLIPSIS, radius} from "../../styles/styles";
import {DropdownButtonStyled} from "../../interaction/DropdownButtonV5";
import {IconButtonStyled} from "../../interaction/IconButtonV5";
import {colorPalette} from "../../styles/colorPalette";

export type WorkspaceViewType = "Favorites" | "Global" | "My Views" | "Shared With Me";

const ViewElementStyledBackgroundStyle = css<{$isActive: boolean; $isHovered?: boolean}>`
	background-color: ${(props) => (props.$isActive ? colorPalette.primary.c200Light : props.$isHovered ? colorPalette.blueGray.c200Light : "initial")};
	color: ${(props) => (props.$isActive ? colorPalette.primary.c500Primary : "initial")};
	border-radius: 4px;

	&:hover {
		background-color: ${(props) => (props.$isActive ? colorPalette.primary.c200Light : colorPalette.blueGray.c200Light)};
		color: ${(props) => (props.$isActive ? colorPalette.primary.c500Primary : colorPalette.gray.c950)};
		${DropdownButtonStyled}, ${IconButtonStyled} {
			visibility: visible;
		}
	}
`;

export const ViewElementStyled = styled.div<{$isActive: boolean; $isHovered?: boolean}>`
	display: flex;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	height: 32px;
	cursor: pointer;
	gap: 4px;
	padding: 4px;
	padding-left: 16px;
	position: relative;
	border-radius: ${radius.sm};
	user-select: none;

	svg {
		width: 16px;
		height: 16px;
	}

	${DropdownButtonStyled}, ${IconButtonStyled} {
		display: flex;
		visibility: ${(props) => (props.$isActive ? "visible" : "hidden")};
		width: 24px;
		height: 24px;
		min-width: 24px;
		${IconButtonStyled} {
			width: 24px;
			height: 24px;
		}
	}

	& ~ .ReactSortable:not(:has(.ViewItem)) {
		${ViewElementStyledBackgroundStyle}
	}

	${ViewElementStyledBackgroundStyle}

	&.ViewItem.level1 {
		&:hover::before,
		&.isActive::before {
			content: "";
			background-color: ${colorPalette.blueGray.c200Light};
			width: 20px;
			height: 32px;
			position: absolute;
			left: -16px;
			border-radius: 4px;
		}

		&.isActive::before {
			background-color: ${colorPalette.primary.c200Light};
		}
	}

	.viewName {
		${ELLIPSIS}
	}
`;

export const getKeyForLocalStorageWorkspaceViewSections = (userId: string) => `srv5_${userId}_openWorkSpaceTypes`;

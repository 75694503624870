import * as React from "react";
import {inject} from "mobx-react";
import styled, {css} from "styled-components";
import type {AppState} from "../../../data/state/AppState";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {PointerDetector} from "../../../utils/interaction/PointerDetector";
import {MathUtils} from "../../../utils/math/MathUtils";
import {InfoBubbleV5} from "../button/InfoBubbleV5";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";

type IdType = string | number;

interface ISelectSliderProps {
	readonly options: ISelectSliderOption[];
	readonly rows: ISelectSliderRow[];
	readonly onChange: (rowKeys: string[], value: IdType) => void;
	readonly disabled?: boolean;
	readonly noButtons?: boolean;
	readonly appState?: AppState;
}

interface ISelectSliderState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	label: string;
	hoveredPermissionIndex: number;
	outlinedPermissionIndex: number;
}

interface ISelectSliderOption {
	id: IdType;
	label: string;
	tooltip?: string;
}

interface ISelectSliderRow {
	value: IdType;
	label: string;
	disabledOptionsList?: ISelectSliderOption[];
}

@inject("appState")
export class SelectSliderV5 extends React.Component<ISelectSliderProps, ISelectSliderState> {
	private _parentRefArray: HTMLDivElement[] = [];
	private _floating = React.createRef<HTMLDivElement>();
	private _sliderDiv = React.createRef<HTMLDivElement>();
	private _header = React.createRef<HTMLDivElement>();

	private _bar: HTMLDivElement;
	private _rowKey = "";
	private _lastDispatchedId: IdType = null;

	constructor(props: ISelectSliderProps) {
		super(props);

		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			label: "",
			hoveredPermissionIndex: -1,
			outlinedPermissionIndex: -1,
		};
	}

	private onMouseDown = (event: React.MouseEvent, rowKey: string) => {
		this._rowKey = rowKey;
		this._lastDispatchedId = null;

		this._bar = event.currentTarget as HTMLDivElement;
		this.updateSlider(event.nativeEvent);

		document.addEventListener("mousemove", this.onMouseMoveWhenSliderClicked);
		document.addEventListener("mouseup", this.onMouseUp);
	};

	private onMouseUp = () => {
		document.removeEventListener("mousemove", this.onMouseMoveWhenSliderClicked);
		document.removeEventListener("mouseup", this.onMouseUp);
	};

	private onMouseOverOption = (event: React.MouseEvent, label: string, index: number) => {
		this.setState({
			isToolTipOpen: true,
			label,
			hoveredPermissionIndex: index,
		});
	};

	private onMouseLeavePermissionOption = () => {
		if (this.isHeaderButtonEnabled) {
			this.setState({
				isToolTipOpen: false,
				label: "",
				hoveredPermissionIndex: -1,
			});
		}
	};

	private onMouseOverGreyBar = (event: React.MouseEvent, rowKey: any) => {
		this._bar = event.currentTarget as HTMLDivElement;
		this._rowKey = rowKey;
		document.addEventListener("mousemove", this.updateOutline);
		this.updateOutline(event.nativeEvent);
	};

	private onMouseLeaveGreyBar = () => {
		if (this.isHeaderButtonEnabled) {
			document.removeEventListener("mousemove", this.updateOutline);
			this.setState({outlinedPermissionIndex: -1});
		}
	};

	private onMouseMoveWhenSliderClicked = (event: MouseEvent) => {
		this.updateSlider(event);
	};

	private calculateSliderOptionIndex = (event: MouseEvent) => {
		const {options, rows} = this.props;
		const rowIndex = rows.findIndex((row) => row.label === this._rowKey);
		const disabledOptions = rows[rowIndex].disabledOptionsList || [];
		const rowOptions = options.filter((option) => !disabledOptions.map((o) => o.label).includes(option.label));

		const mousePos = PointerDetector.getLocalCoords(event, this._bar);

		const ratio = MathUtils.clamp(mousePos[0] / this._bar.clientWidth, 0, 1);
		const value = (rowOptions.length - 1) * ratio;

		const index = Math.round(value);

		return {index, option: rowOptions[index]};
	};

	private updateOutline = (event: MouseEvent) => {
		if (this.isHeaderButtonEnabled) {
			const {index} = this.calculateSliderOptionIndex(event);

			this.setState({outlinedPermissionIndex: index});
		}
	};

	private updateSlider(event: MouseEvent) {
		const {onChange} = this.props;
		const {option} = this.calculateSliderOptionIndex(event);

		if (option) {
			if (this._lastDispatchedId !== option.id) {
				this._lastDispatchedId = option.id;
				onChange([this._rowKey], option.id);
			}
		}
	}

	private onChangeAllElementPermission = (permission: IdType) => {
		const {rows, onChange} = this.props;
		const keys: string[] = [];

		rows.forEach((row) => {
			const disabledOptionIds = row.disabledOptionsList?.map((list) => list.id);

			if (!disabledOptionIds?.includes(permission)) {
				keys.push(row.label);
			}
		});
		onChange(keys, permission);
	};

	private onScroll = () => {
		const header = this._header.current;

		if (this._sliderDiv.current.scrollTop > 0) {
			header.classList.add("scrolled");
		} else {
			header.classList.remove("scrolled");
		}
	};

	private get _modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	private get isHeaderButtonEnabled() {
		const {rows, noButtons} = this.props;

		return rows.length !== 1 && !noButtons;
	}

	public override componentDidUpdate = (prevProps: ISelectSliderProps, prevState: ISelectSliderState) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parentRefArray[this.state.hoveredPermissionIndex] && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parentRefArray[this.state.hoveredPermissionIndex],
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
					5,
					0,
				),
			});
		}
	};

	public override componentDidMount() {
		this._sliderDiv.current.addEventListener("scroll", this.onScroll);
	}

	public override componentWillUnmount() {
		this._sliderDiv.current.removeEventListener("scroll", this.onScroll);
	}

	public override render() {
		const {options, rows, disabled} = this.props;
		const {isToolTipOpen, toolTipTransform, label, outlinedPermissionIndex} = this.state;

		const grid = {
			cols: `repeat(${options.length - 1}, 90px) 23px`,
			rows: `repeat(${rows.length}, 40px)`,
		};

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		return (
			<>
				<SelectSliderPermissionOptionsStyled
					$emptyLabel={rows.every((row) => !row.label)}
					style={{
						gridTemplateColumns: grid.cols,
					}}
					ref={this._header}
				>
					{options.map((option, j) => (
						<OptionsStyled
							$columnValue={j}
							key={j}
						>
							<OptionLabelStyled
								$outlined={outlinedPermissionIndex === j}
								$disabled={!this.isHeaderButtonEnabled}
								$sliderdisabled={disabled}
								ref={(parentRef) => (this._parentRefArray[j] = parentRef)}
								onClick={() => this.isHeaderButtonEnabled && this.onChangeAllElementPermission(option.id)}
								onMouseOver={(e: React.MouseEvent) => this.isHeaderButtonEnabled && this.onMouseOverOption(e, option.label, j)}
								onMouseLeave={this.onMouseLeavePermissionOption}
							>
								{option.label}
							</OptionLabelStyled>
						</OptionsStyled>
					))}
				</SelectSliderPermissionOptionsStyled>
				<SelectSliderContainerStyled
					ref={this._sliderDiv}
					$disabled={disabled}
				>
					<SelectSliderStyled
						$gridTemplateColumns={grid.cols}
						$gridTemplateRows={grid.rows}
					>
						{rows.map((row, i) => {
							const selectedIndex = Math.max(
								0,
								options.findIndex((option) => row.value === option.id),
							);
							const disabledOptions = row.disabledOptionsList || [];
							const rowOptions = options.filter((option) => !disabledOptions.map((o) => o.label).includes(option.label));

							return (
								<SelectSliderRowStyled key={i}>
									{row.label && <SelectSliderSliderNameStyled>{row.label}</SelectSliderSliderNameStyled>}
									<SliderContainerStyled style={{gridTemplateColumns: grid.cols}}>
										<GreyBarStyled
											$rowOptionLength={rowOptions.length}
											$gridRowValue={i}
											className="greyBar"
											onMouseDown={(e) => this.onMouseDown(e, row.label)}
											onMouseOver={(e) => this.isHeaderButtonEnabled && this.onMouseOverGreyBar(e, row.label)}
											onMouseLeave={this.onMouseLeaveGreyBar}
										/>
										<BlueBarStyled
											$selectedIndex={selectedIndex}
											$gridRowValue={i}
											className="blueBar"
										/>
										<KnobStyled
											$gridRowValue={i}
											$gridColumnValue={selectedIndex}
										/>
									</SliderContainerStyled>
								</SelectSliderRowStyled>
							);
						})}
					</SelectSliderStyled>
					{isToolTipOpen && (
						<InfoBubbleV5
							className="permissionInfoBubble"
							content={`Switch all to ${label}`}
							style={inlineStyle}
							divRef={this._floating}
						/>
					)}
				</SelectSliderContainerStyled>
			</>
		);
	}
}

export const SelectSliderPermissionOptionsStyled = styled.div<{$emptyLabel: boolean}>`
	display: grid;
	padding: 5px;
	justify-content: end;
	align-items: center;
	z-index: 1000;
	${(props) => {
		if (props.$emptyLabel) {
			return css`
				justify-content: start;
				padding-left: 10px;
			`;
		}
	}}
`;

const OptionsStyled = styled.span<{$columnValue: number}>`
	display: flex;
	align-items: center;
	justify-content: center;
	grid-row: 1;
	grid-column: ${(props) => props.$columnValue + 1};
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #363636;
	transform: translatex(-50%);
	text-align: center;
	height: 16px;
`;

const OptionLabelStyled = styled.div<{$outlined: boolean; $disabled: boolean; $sliderdisabled: boolean}>`
	padding: 5px;
	border: ${(props) => props.$outlined && props.$disabled && "1px solid #3495F0"};
	color: ${(props) => (props.$sliderdisabled ? "#C8C8C8" : "black")};
	${(props) => {
		if (!props.$disabled) {
			return css`
				&:hover {
					background-color: ${colorPalette.gray.c100};
					border-radius: ${radius.sm};
					color: black;
					cursor: pointer;
				}
			`;
		}
	}}
`;

export const SelectSliderContainerStyled = styled.div<{$disabled: boolean}>`
	display: flex;
	flex-direction: column;
	height: 100%;
	${(props) =>
		props.$disabled &&
		css`
			opacity: 0.5;
			pointer-events: none;
		`}
`;

const SelectSliderStyled = styled.div<{$gridTemplateColumns: string; $gridTemplateRows: string}>`
	display: block;
	height: 100%;
	grid-template-columns: ${(props) => props.$gridTemplateColumns};
	grid-template-rows: ${(props) => props.$gridTemplateRows};
`;

export const SelectSliderRowStyled = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 32px;
	padding: 8px 4px;
	border-radius: 4px;
	&:nth-child(2n) {
		background-color: ${colorPalette.gray.c100};
	}
`;

const SelectSliderSliderNameStyled = styled.div`
	min-width: 50px;
	word-break: break-word;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
`;

const SliderContainerStyled = styled.div`
	display: grid;
	position: relative;
	align-content: center;

	.greyBar,
	.blueBar {
		margin-left: -3px;
		margin-right: -3px;
		top: 6px;
		position: relative;
	}
`;

const GreyBarStyled = styled.div<{$rowOptionLength: number; $gridRowValue: number}>`
	height: 4px;
	background: #3495f0;
	border-radius: 2px;
	opacity: 0.2;

	cursor: pointer;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		left: -4px;
		right: -4px;
		top: -10px;
		bottom: -10px;
	}
	grid-row: ${(props) => props.$gridRowValue + 2};
	grid-column: ${(props) => `1 / ${props.$rowOptionLength}`};
`;

const BlueBarStyled = styled.div<{$selectedIndex: number; $gridRowValue: number}>`
	height: 4px;
	background: #3495f0;
	border-radius: 2px;
	pointer-events: none;
	width: ${(props) => (props.$selectedIndex === 0 ? "5px" : "")};
	grid-row: ${(props) => props.$gridRowValue + 2};
	grid-column: ${(props) => `1 / ${1 + props.$selectedIndex}`};
`;

const KnobStyled = styled.div<{$gridRowValue: number; $gridColumnValue: number}>`
	grid-row: ${(props) => props.$gridRowValue + 2};
	grid-column: ${(props) => `${1 + props.$gridColumnValue}`};
	width: 12px;
	height: 12px;
	background: #ffffff;
	border: 1px solid #ededed;
	box-sizing: border-box;
	box-shadow:
		0px 2px 2px rgba(0, 0, 0, 0.24),
		0px 0px 2px rgba(0, 0, 0, 0.12);
	border-radius: 12px;
	transform: translate(-6px, 1px);
	pointer-events: none;
`;

import {useCallback, useEffect, useReducer} from "react";
import styled from "styled-components";
import {Observer} from "mobx-react";
import {useAppStore} from "../../../StateManager";
import {XyiconFeature} from "../../../generated/api/base";
import {getActionBarButtonsForFeature} from "../modules/ActionBarUtils";
import type {IModel, ISpaceItemModel} from "../../../data/models/Model";
import type {Boundary} from "../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../data/models/BoundarySpaceMap";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {ModuleViewV5} from "./ModuleViewV5";

interface ISideGridProps {
	readonly itemFeature: XyiconFeature;
	readonly focusedItems: ISpaceItemModel[];
}

export const SideGrid = (props: ISideGridProps) => {
	const appState = useAppStore((state) => state.appState);
	const {spaceViewRenderer} = appState.app.graphicalTools;
	const {spaceItemController} = spaceViewRenderer;
	const {itemFeature, focusedItems} = props;
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const itemManager = spaceViewRenderer.getItemManager(itemFeature);

	const setSelectedItems = (selectedItems: IModel[]) => {
		spaceItemController.deselectAll(false, true);

		for (const selectedItem of selectedItems) {
			if (selectedItem.ownFeature === itemFeature) {
				if (itemFeature === XyiconFeature.Boundary) {
					for (const boundarySpaceMap of (selectedItem as Boundary).boundarySpaceMaps) {
						const item3D = itemManager.getItemById(boundarySpaceMap.id);
						item3D?.select();
					}
				} else {
					const item3D = itemManager.getItemById(selectedItem.id);
					item3D?.select(true, true);
				}
			}
		}

		spaceItemController.updateActionBar(true, true);
		forceUpdate();
	};

	const setFocusedHandler = useCallback(
		(focusedItems: ISpaceItemModel[]) => {
			spaceViewRenderer.inheritedMethods.focusItems(focusedItems);
		},
		[spaceViewRenderer],
	);

	useEffect(() => {
		setFocusedHandler([]);
	}, [itemFeature, setFocusedHandler]);

	const actionBarUpdatedSignal = spaceItemController.signals.actionBarUpdated;

	useEffect(() => {
		actionBarUpdatedSignal.add(forceUpdate);

		return () => {
			actionBarUpdatedSignal.remove(forceUpdate);
		};
	}, [actionBarUpdatedSignal]);

	return (
		<Observer>
			{() => {
				let selectedItems = itemManager.selectedItems.map((spaceItem) => spaceItem.modelData).filter((modelData) => !!modelData);

				if (itemFeature === XyiconFeature.Boundary) {
					selectedItems = ArrayUtils.removeDuplicates((selectedItems as BoundarySpaceMap[]).flatMap((b) => b.parent) as Boundary[]);
				}

				return (
					<SideGridStyled
						data-min-width={512}
						data-default-width={696}
					>
						<ModuleViewV5
							feature={itemFeature}
							actionBar={getActionBarButtonsForFeature(itemFeature, appState)}
							selectedItems={selectedItems}
							setSelectedItems={setSelectedItems}
							focusedItems={focusedItems}
							setFocusedItems={setFocusedHandler}
							manageDetailsPanel={false}
							isSideGrid={true}
						/>
					</SideGridStyled>
				);
			}}
		</Observer>
	);
};

const SideGridStyled = styled.div`
	position: relative;
`;

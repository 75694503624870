import {inject, observer} from "mobx-react";
import * as React from "react";
import type {TransportLayer} from "../../../../data/TransportLayer";
import type {Catalog} from "../../../../data/models/Catalog";
import type {Xyicon} from "../../../../data/models/Xyicon";
import type {AppState} from "../../../../data/state/AppState";
import {Permission, XyiconFeature, FieldDataType} from "../../../../generated/api/base";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {Functions} from "../../../../utils/function/Functions";
import {FieldV5} from "../../details/FieldV5";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {Type} from "../../../../data/models/Type";
import {ConfirmWindowV5} from "../../popup/ConfirmWindowV5";

interface IPortfolioDefaultFieldsProps {
	readonly item: Catalog;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
	readonly permission?: Permission;
}

interface IPortfolioDefaultFieldsState {
	updating: boolean;
}

@inject("transport")
@inject("appState")
@observer
export class CatalogDefaultFieldsV5 extends React.Component<IPortfolioDefaultFieldsProps, IPortfolioDefaultFieldsState> {
	constructor(props: IPortfolioDefaultFieldsProps) {
		super(props);
		this.state = {
			updating: false,
		};
	}

	private isNameValid = (value: string) => {
		return this.props.appState.actions.isModelValidForCatalog(value, this.props.item);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onModelChange = async (value: string) => {
		value = StringUtils.removeWhiteSpaces(value);
		const {item, transport} = this.props;

		item.setModel(value);

		this.setState({updating: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateFields([item], {model: value}), this.props.appState.app.notificationContainer);
		this.setState({updating: false});
	};

	private onTypeChange = async (type: Type) => {
		const {item} = this.props;
		const xyicons = this.props.appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((xyicon) => xyicon.model === item.model);

		const title = "Confirm Type Change";
		const message = `Once you change the catalog type, fields (and data) not assigned to the new catalog type will be removed. There are ${xyicons.length} xyicons that will be updated with the new catalog type and assigned fields.  Do you wish to continue?`;
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindowV5.open(message, title, config);

		if (confirmed) {
			item.typeId = type.id;
			const result = await this.props.transport.updateCatalogType(item);

			if (result) {
				xyicons.forEach((xyicon) => (xyicon.typeId = type.id));
			}
		}
	};

	public override render() {
		const {item, appState, permission} = this.props;
		const {updating} = this.state;

		return (
			<>
				<FieldV5 label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</FieldV5>
				<FieldV5
					className="TypeSelector"
					label="Type"
				>
					<SelectInputV5
						options={appState.types[XyiconFeature.Xyicon]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						onChange={this.onTypeChange}
						disabled={permission <= Permission.View}
						isSameWidth={true}
					/>
				</FieldV5>
				<FieldV5 label="Model">
					<ClickToEditInputV5
						value={item.model}
						onChange={this.onModelChange}
						dataType={FieldDataType.SingleLineText}
						updating={updating}
						disabled={permission <= Permission.View}
						onBlur={Functions.emptyFunction}
						getErrorMessage={this.getErrorMessage}
					/>
				</FieldV5>
			</>
		);
	}
}

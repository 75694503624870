import * as React from "react";
import {TextInputV5} from "../../../details/datatypes/TextInputV5";

interface IStringParamV5rops {
	readonly param: string;
	onChange(value: string): void;
	readonly onFocus?: (value: boolean) => void;
}

export class StringParamV5 extends React.Component<IStringParamV5rops> {
	private onFocus = () => {
		this.props.onFocus?.(true);
	};

	private onBlur = () => {
		this.props.onFocus?.(false);
	};

	public override render() {
		const {param, onChange} = this.props;

		return (
			<TextInputV5
				value={param || ""}
				onInput={(value) => onChange(value)}
				onClick={this.onFocus}
				onBlur={this.onBlur}
			/>
		);
	}
}

import {useEffect, useState} from "react";
import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import {getActionBarButtonsForFeature} from "./ActionBarUtils";

const OWN_FEATURE = XyiconFeature.Xyicon;

interface IXyiconViewProps {}

export const XyiconViewV5 = (props: IXyiconViewProps) => {
	const appState = useAppStore((state) => state.appState);
	const {organizationId, app} = appState;
	const [isCatalogOpen, setIsCatalogOpen] = useState<boolean>(false);
	const [isCatalogDocked, setIsCatalogDocked] = useState<boolean>(false);

	const setCatalogOpen = (isOpen: boolean) => {
		setIsCatalogOpen(isOpen);
	};

	const setCatalogDocked = (isDocked: boolean) => {
		setIsCatalogDocked(isDocked);
	};

	const getLocalStorageKey = (organizationId: string) => {
		return `srv4-org-${organizationId}-spaceeditor-xyiconview-dockables-open-state`;
	};

	useEffect(() => {
		const loadStateFromLocalStorage = () => {
			if (organizationId) {
				const savedDockedState = app.transport.services.localStorage.get(getLocalStorageKey(organizationId)) as {
					isCatalogOpen: boolean;
					isCatalogDocked: boolean;
				};

				if (savedDockedState) {
					setIsCatalogDocked(savedDockedState.isCatalogDocked);
					setIsCatalogOpen(savedDockedState.isCatalogOpen);
				}
			}
		};

		loadStateFromLocalStorage();
	}, [app.transport.services.localStorage, organizationId]);

	useEffect(() => {
		const saveStateToLocalStorage = () => {
			if (organizationId) {
				const stateToSave = {
					isCatalogDocked: isCatalogDocked,
					isCatalogOpen: isCatalogOpen && isCatalogDocked,
				};

				app.transport.services.localStorage.set(getLocalStorageKey(organizationId), stateToSave);
			}
		};

		saveStateToLocalStorage();
	}, [isCatalogDocked, isCatalogOpen, organizationId, app.transport.services.localStorage]);

	return (
		<ModuleViewV5
			feature={OWN_FEATURE}
			className="XyiconView"
			actionBar={getActionBarButtonsForFeature(OWN_FEATURE, appState)}
			isCatalogPanelOpen={isCatalogOpen}
			isCatalogPanelDocked={isCatalogDocked}
			setCatalogPanelOpen={setCatalogOpen}
			setCatalogPanelDocked={setCatalogDocked}
			create={(onClose) => {
				setIsCatalogOpen(true);

				return <></>;
			}}
		/>
	);
};

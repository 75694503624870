import type {Object3D, Material} from "three";
import {Group, Vector2, Mesh} from "three";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {ItemManager, SpaceItemType} from "../managers/spaceitems/ItemManager";
import type {ColorRuleCategory} from "../../ui/viewbar/ColorRules";
import {colorRuleCategories} from "../../ui/viewbar/ColorRules";
import {BasicMaterial} from "../materials/BasicMaterial";
import type {ILayerSection} from "../../ui/viewbar/LayerView";
import {getFormattingColor} from "../renderers/SpaceViewRendererUtils";
import type {IObjectWithRotationHandler} from "../managers/spaceitems/icons/RotationIconTypes";
import {GHOST_ID_POSTFIX} from "../managers/GhostModeConstants";
import type {ITextPart} from "../managers/MSDF/TextUtils";
import {Constants} from "../Constants";
import type {IBox2} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {IModel} from "../../../../../../data/models/Model";
import type {IXyiconMinimumData} from "../../../../../../data/models/Xyicon";
import type {IBoundaryMinimumData} from "../../../../../../data/models/Boundary";
import type {IMarkupMinimumData} from "../../../../../../data/models/Markup";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {Permission} from "../../../../../../generated/api/base";
import type {Dimensions, PointDouble} from "../../../../../../generated/api/base";
import {isUserTryingToPutItOnTopOfHoveredItems} from "../../ui/toolbar/EmbeddedUtils";
import type {Markup3D} from "./markups/abstract/Markup3D";
import {SpaceItemShakeManager} from "./SpaceItemShakeManager";

export type SpaceItemOpacityValue = 0.5 | 1;

export type ISpaceItemMinimumData = IXyiconMinimumData | IBoundaryMinimumData | IMarkupMinimumData;

export abstract class SpaceItem implements IObjectWithRotationHandler {
	protected static readonly COLOR_INTENSITY = {
		SELECTED: 0.627,
		HOVERED: 0.8,
		DESELECTED: 1,
	};
	protected _spaceViewRenderer: SpaceViewRenderer;
	protected _lastSavedOrientation: number = 0;
	protected _group: Group = new Group();
	protected _savedPosition: Dimensions = null; // used for translating to eliminate delta-related bugs (eg.: because of low framerate)
	protected abstract _color: number;
	protected abstract _modelData: IModel;
	protected _isSelected: boolean = false;
	protected _meshHeight: number = 0;
	protected readonly _zOffsetBase: number = 0.1;
	protected abstract _spaceItemType: SpaceItemType;
	protected _defaultOpacity: number = 1;
	private _opacityMultiplicator: SpaceItemOpacityValue = 1;
	private _rotationIconPos: Vector2 = new Vector2();
	private _isPositionLocked: boolean = false;
	private _isExcluded: boolean = false; // from view via criteria, or filter
	private _isDestroyed: boolean = false;
	private _shakeManager: SpaceItemShakeManager;
	public get isDestroyed() {
		return this._isDestroyed;
	}

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		this._spaceViewRenderer = spaceViewRenderer;
		this._shakeManager = new SpaceItemShakeManager(this._spaceViewRenderer, this);
	}

	public abstract position: Dimensions;
	public abstract scale: PointDouble;
	public abstract boundingBox: IBox2;

	/** The only scenario when we wouldn't want removeFromCollections to be true is when we delete everything
	 * Because we take care of removing everything after this from the collections with one call,
	 * since it's much faster then removing them one by one.
	 */
	protected abstract destroyCallback(notifyServer?: boolean, removeFromCollections?: boolean): void;
	public abstract rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble): void;
	public abstract stopRotating(): void;
	public abstract updateByModel(model: IModel): void;
	protected abstract applyOrientation(newOrientation: number): void;
	protected abstract setFormattingColor(colorRuleCategory: ColorRuleCategory, colorHex: string): void;

	public startRotating(pivot?: PointDouble): void {
		if (pivot) {
			// If we're rotating around a specific pivot point, we're also translating the center point of the object
			this.startTranslating();
		}
	}

	protected setOpacity(opacityMultiplicator: SpaceItemOpacityValue) {
		if (this._opacityMultiplicator !== opacityMultiplicator) {
			this._opacityMultiplicator = opacityMultiplicator;
			const newOpacityValue = this.opacity;

			this._group.traverse((object3D: Object3D) => {
				if (object3D instanceof Mesh) {
					const material = object3D.material as Material;

					if (material instanceof BasicMaterial) {
						// Save original value to userData
						if (material.userData.opacity == null) {
							material.userData.opacity = material.opacityValue;
						}

						material.setOpacity(material.userData.opacity * newOpacityValue);
					} else {
						if (material.opacity != null) {
							// Save original value to userData
							if (material.userData.opacity == null) {
								material.userData.opacity = material.opacity;
							}
							material.opacity = material.userData.opacity * newOpacityValue;
						}
					}
				}
			});
		}
	}

	public turnToSemiTransparent() {
		this.setOpacity(0.5);
	}

	public turnToOpaque() {
		this.setOpacity(1);
	}

	public setRotation(angle: number) {
		this._group.rotation.z = angle;
		THREEUtils.updateMatrices(this._group);
		this._spaceViewRenderer.needsRender = true;
	}

	public startTranslating() {
		if (!this.isPositionLocked && this.hasPermissionToMoveOrRotate) {
			this._savedPosition = {
				x: this._group.position.x,
				y: this._group.position.y,
				z: this._group.position.z,
			};
		}
	}

	protected get hasPermissionToMoveOrRotate(): boolean {
		if (!this._modelData || this._spaceItemType === "markup") {
			return true;
		}
		return this._spaceViewRenderer.actions.getModuleTypePermission(this._modelData.typeId, this._modelData.ownFeature) > Permission.View;
	}

	// Returns if it's been changed
	// x, and y is the difference between the currentpos and the savedPosition, NOT the previous position
	public translate(x: number, y: number, z: number, force: boolean = false): boolean {
		if (this._savedPosition) {
			if (force || (!this._shakeManager.isShaking && !this.isPositionLocked && this.hasPermissionToMoveOrRotate)) {
				// Only xyicons can be moved up/down on the Z axis individually.
				// The z position of the parent element of the other items (boundaries / markups) are set to the spaceOffset.z automatically
				// So the individual positions must remain 0
				const xyiconBaseZ = isUserTryingToPutItOnTopOfHoveredItems() ? 0 : this._savedPosition.z;
				const newZPos = this._spaceItemType === "xyicon" ? Math.max(xyiconBaseZ + z, this._spaceViewRenderer.spaceOffset.z) : 0;

				THREEUtils.setPosition(this._group, this._savedPosition.x + x, this._savedPosition.y + y, newZPos);

				this._spaceViewRenderer.needsRender = true;

				return true;
			}
		}

		return false;
	}

	public stopTranslating() {
		this._savedPosition = null;
	}

	public getRotationIconObject = (target: Object3D) => {
		const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
		const cameraZoomLevel = this._spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;

		const defaultWorldSize = {
			x: (Constants.SIZE.ROTATION_HANDLER_PX * correctionMultiplier) / cameraZoomLevel,
			y: (Constants.SIZE.ROTATION_HANDLER_PX * correctionMultiplier) / cameraZoomLevel,
		};

		this._rotationIconPos.set(this._group.position.x, this._group.position.y + (this.scale.y + defaultWorldSize.y) / 2);
		this._rotationIconPos.rotateAround(this._group.position as any as Vector2, this.orientation);

		target.position.set(this._rotationIconPos.x, this._rotationIconPos.y, 1 * correctionMultiplier);
		target.rotation.z = this.orientation;
		target.scale.set(defaultWorldSize.x, defaultWorldSize.y, 1);

		target.updateMatrix();

		return target;
	};

	public toggleSelection() {
		if (this._isSelected) {
			this.deselect();
		} else {
			this.select();
		}
	}

	public mouseOver() {
		if (!this._isSelected && !this._isDestroyed) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.HOVERED);
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public mouseOut() {
		if (!this._isSelected && !this._isDestroyed) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
			this._spaceViewRenderer.needsRender = true;
		}
	}

	// AKA. wiggle
	// Based on this example: https://www.w3schools.com/howto/howto_css_shake_image.asp
	public shake() {
		// Shake the item
		if (!this._isDestroyed) {
			this._shakeManager.shake();
		}
	}

	// Returns if selection has changed
	public select(updateSelectionBox: boolean = true, forceSelect: boolean = false): boolean {
		if ((!this._isSelected && this.isVisible && !(this as unknown as Markup3D).isTemp) || forceSelect) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.SELECTED);
			this._spaceViewRenderer.needsRender = true;
			this._isSelected = true;
			if (updateSelectionBox) {
				this.itemManager.updateSelectionBox();
			}

			return true;
		} else {
			return false;
		}
	}

	// Returns if selection has changed
	public deselect(updateSelectionBox: boolean = true): boolean {
		if (this._isSelected) {
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
			this._spaceViewRenderer.needsRender = true;
			this._isSelected = false;

			if (updateSelectionBox) {
				this.itemManager.updateSelectionBox();
			}

			return true;
		} else {
			return false;
		}
	}

	public get opacity() {
		return this._defaultOpacity * this._opacityMultiplicator;
	}

	public get isVisible() {
		return this._group.visible;
	}

	public get id() {
		return this._modelData?.id || "NoModelData...";
	}

	public get data() {
		return this._modelData;
	}

	/** The only scenario when we wouldn't want removeFromCollections to be true is when we delete everything
	 * Because we take care of removing everything after this from the collections with one call,
	 * since it's much faster then removing them one by one.
	 */
	public destroy(notifyServer?: boolean, removeFromCollections?: boolean) {
		this._isDestroyed = true;
		this.destroyCallback(notifyServer, removeFromCollections);
	}

	protected setPositionLocked(isPositionLocked: boolean) {
		this._isPositionLocked = isPositionLocked;
		if (!this.isVisible) {
			this.deselect();
		}
	}

	/** If an item is invisible, it's also locked */
	public get isPositionLocked() {
		if (this.id.includes(GHOST_ID_POSTFIX)) {
			return false;
		}

		return !this.isVisible || this._isPositionLocked;
	}

	protected abstract setGrayScaled(value: boolean): void;

	public setVisibility(visible: boolean) {
		// if it's excluded, we don't allow to turn it to true
		if (visible && this._isExcluded) {
			visible = false;
		}

		this._group.visible = visible;
		if (!this.isVisible) {
			this.deselect();
		}
	}

	public get isExcluded() {
		return this._isExcluded;
	}

	public addModelData(modelData: IModel) {
		this._modelData = modelData;
		this._group.name = `${modelData.id}`;
		this.onFormattingRulesModified();
	}

	// Only affects the data, not the visual appearence
	public applyModelData(modelData: IModel) {
		this._modelData.applyData(modelData);
		this._group.name = `${this._modelData.id}`;
		this.onFormattingRulesModified();
		this.onLayerSettingsModified();
	}

	public onLayerSettingsModified() {
		const layerSettings = this.layerSettings;

		if (layerSettings) {
			// Eg.: setscaleline doesn't have layersettings, as its type is null
			this.setVisibility(!layerSettings.isHidden);
			this.setPositionLocked(layerSettings.isPositionLocked);

			this._spaceViewRenderer.needsRender = true;
		}
	}

	public onFormattingRulesModified() {
		const formattingRules = this.formattingRules;

		if (formattingRules?.enabled) {
			if (this._modelData) {
				for (const colorRuleCategory of colorRuleCategories) {
					const fieldName = formattingRules[colorRuleCategory][this._modelData.typeName];
					const colorHex = this.getFormattingColor(fieldName);

					this.setFormattingColor(colorRuleCategory, colorHex);
				}
			}
		} else {
			for (const colorRuleCategory of colorRuleCategories) {
				this.setFormattingColor(colorRuleCategory, null);
			}
		}

		this._spaceViewRenderer.needsRender = true;
	}

	private getFormattingColor(fieldName: string) {
		return getFormattingColor(this._modelData, fieldName, this._spaceViewRenderer.actions);
	}

	public get modelData() {
		return this._modelData;
	}

	public get spaceItemType() {
		return this._spaceItemType;
	}

	protected getCaptionFieldValues(captionFields: IFieldAdapter[], modelData: IModel): ITextPart[] {
		return this._spaceViewRenderer.actions.getCaptionFieldValues(captionFields, modelData);
	}

	public abstract get type(): number | string;
	public abstract get orientation(): number;
	protected abstract setColor(color: number, intensity: number, save?: boolean): void;
	public abstract get itemManager(): ItemManager;

	public get spaceViewRenderer() {
		return this._spaceViewRenderer;
	}

	public get savedPosition() {
		return this._savedPosition;
	}

	public get isBeingMoved(): boolean {
		return !!this._savedPosition;
	}

	public get layerSettings(): ILayerSection {
		return this.itemManager.layerSettings.included[this.type];
	}

	public get formattingRules() {
		return this.itemManager.formattingRules;
	}

	public get typeName() {
		return this._modelData?.typeName;
	}

	public get group() {
		return this._group;
	}

	public get isSelected() {
		return this._isSelected;
	}
}

import {Component, createRef} from "react";
import {SVGIcon} from "../../../widgets/button/SVGIcon";
import {ResizeDetector} from "../../../../utils/resize/ResizeDetector";

interface IShortcutWindowProps {
	readonly onClose?: () => void;
}

interface IShortcutDescription {
	description: string;
	keys: string[];
}

const os = window.navigator.userAgent;

const altOrOption = os.match(/Mac/i) ? "Option" : "Alt";
const ctrlOrControl = os.match(/Mac/i) ? "Control" : "Ctrl";

export const shortcuts: IShortcutDescription[] = [
	{description: "Disable snap to grid and angle", keys: [altOrOption, "Drag"]},
	{description: "Hide captions", keys: ["C"]},
	{description: "Move the boundary line (edit mode)", keys: [ctrlOrControl, "Drag"]},
	{description: "Pan around the space", keys: ["Right click", "Drag"]},
	{description: "Pan around the space", keys: ["Middle click", "Drag"]},
	{description: "Pan around the space", keys: ["Space bar", "Drag"]},
	{description: "Stamp selection", keys: [ctrlOrControl, "S"]},
	{description: "Vertically move a Xyicon", keys: ["Z", "Drag"]},
	{description: "View in 3D", keys: ["Shift", "Drag"]},
];

export class ShortcutWindow extends Component<IShortcutWindowProps> {
	private _shortcutWindowRef = createRef<HTMLDivElement>();
	private _dragHandleRef = createRef<HTMLDivElement>();
	private _initPosX: number = 0; // initial position of the drag-handle when popup is opened
	private _initPosY: number = 0;
	private _actualPosX: number = 0; // saved when the user moves the popup
	private _actualPosY: number = 0;
	private _offsetX: number = 0; // resize width
	private _offsetY: number = 0;
	private _isDragIconGrabbed = false;
	private _resizeDetector: ResizeDetector;

	private onGrabDragHandle = () => {
		this._isDragIconGrabbed = true;

		// if the page was resized, the initPos needs to be updated
		this._initPosX -= this._offsetX;
		this._initPosY -= this._offsetY;
		this._offsetX = 0;
		this._offsetY = 0;
	};

	private onReleaseDragHandle = () => {
		if (this._isDragIconGrabbed) {
			this._isDragIconGrabbed = false;
		}
	};

	private onMoveWindow = (e: MouseEvent) => {
		if (this._isDragIconGrabbed) {
			this._shortcutWindowRef.current.style.transform = `translate(calc(${e.clientX - (this._initPosX - this._offsetX)}px - 50%), calc(${e.clientY - (this._initPosY - this._offsetY)}px - 50%))`;

			// save the actual position to calculate the difference when page is resized
			this._actualPosX = e.clientX;
			this._actualPosY = e.clientY;
		}
	};

	private calculateInitPosition = () => {
		const bbox = this._dragHandleRef.current.getBoundingClientRect();

		this._initPosX = (bbox.left + bbox.right) / 2;
		this._initPosY = (bbox.top + bbox.bottom) / 2;
	};

	private calculateOffset = () => {
		const bbox = this._dragHandleRef.current.getBoundingClientRect();
		const x = (bbox.left + bbox.right) / 2;
		const y = (bbox.top + bbox.bottom) / 2;

		// the amount of resize can be calculated from the difference of the last moved position (actualPos) and the position after resize (x,y)
		this._offsetX = this._actualPosX - x;
		this._offsetY = this._actualPosY - y;
	};

	public override componentDidMount(): void {
		document.addEventListener("mouseup", this.onReleaseDragHandle);
		document.addEventListener("mousemove", this.onMoveWindow);

		this.calculateInitPosition();

		this._resizeDetector = new ResizeDetector(document.body);
		this._resizeDetector.resize.add(this.calculateOffset, this);
	}

	public override componentWillUnmount(): void {
		document.removeEventListener("mouseup", this.onReleaseDragHandle);
		document.removeEventListener("mousemove", this.onMoveWindow);

		this._resizeDetector.resize.remove(this.calculateOffset, this);
		this._resizeDetector.dispose();
	}

	public override render() {
		const {onClose} = this.props;

		return (
			<div className="ShortcutWindow flexCenter">
				<div
					ref={this._shortcutWindowRef}
					className="PopupWindow"
				>
					<div className="title hbox alignCenter">
						<div
							ref={this._dragHandleRef}
							className="draghandle hbox alignCenter"
							onMouseDown={this.onGrabDragHandle}
						>
							<SVGIcon
								icon="drag"
								classNames="drag-icon"
							/>
						</div>
						<div className="content">Xyicon Keyboard Shortcuts</div>
						<div
							className="closeBtn"
							onClick={onClose}
						>
							<SVGIcon icon="close" />
						</div>
					</div>
					<div className="message">
						{shortcuts.map((shortcut, index) => {
							return (
								<div
									className="shortcut"
									key={index}
								>
									<span>{shortcut.description}</span>
									<div className="keys">
										{shortcut.keys.map((key, index) => (
											<div
												className="key"
												key={index}
											>
												{key}
											</div>
										))}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {ToggleSwitchField} from "../../../../widgets/button/switch/ToggleSwitchField";
import type {AppState} from "../../../../../data/state/AppState";
import {featureTitles} from "../../../../../data/state/AppStateConstants";
import {XyiconFeature, Permission} from "../../../../../generated/api/base";
import {SelectSlider} from "../../../../widgets/input/selectslider/SelectSlider";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {SearchField} from "../../../../widgets/input/search/SearchField";

interface ITypePermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly feature: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ITypePermissionState {
	searchQuery: string;
}

@inject("appState")
@inject("transport")
@observer
export class TypePermissions extends React.Component<ITypePermissionsProps, ITypePermissionState> {
	constructor(props: ITypePermissionsProps) {
		super(props);
		this.state = {
			searchQuery: "",
		};
	}

	public override render() {
		const {permissionSet, feature, appState, transport} = this.props;
		const {searchQuery} = this.state;
		const rows = appState.types[feature].slice().sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name));

		return (
			<div className="vbox container">
				<div className="permissionHeading">
					<ToggleSwitchField
						label={`Enable ${featureTitles[feature]} Module`}
						value={permissionSet.isAccessEnabled(feature)}
						onChange={(value) => {
							permissionSet.enableAccess(feature, value);
							transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
						}}
					/>
				</div>
				<h4>Type Permissions</h4>
				<SearchField
					value={searchQuery}
					onInput={(value) => this.setState({searchQuery: value})}
				/>
				<SelectSlider
					options={[
						{
							id: Permission.None,
							label: "Hide",
						},
						{
							id: Permission.View,
							label: "Show",
						},
						{
							id: Permission.Update,
							label: "Update",
						},
						{
							id: Permission.Delete,
							label: "Delete",
						},
					]}
					rows={rows
						.filter((type) => StringUtils.containsIgnoreCase(type.name, searchQuery))
						.map((type) => {
							return {
								label: type.name,
								value: permissionSet.getObjectPermission(type.id, feature),
							};
						})}
					onChange={(rowLabels: string[], value) => {
						rowLabels.forEach((label) => {
							const type = rows.find((row) => row.name === label);

							if (type) {
								permissionSet.setObjectPermission(feature, type.id, value as Permission);
							}
						});
						transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
					}}
				/>
			</div>
		);
	}
}

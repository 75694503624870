import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {Button} from "../../widgets/button/Button";

interface ILoginWindowProps {
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

interface ILoginWindowState {
	message: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class LoginWindow extends React.Component<ILoginWindowProps, ILoginWindowState> {
	private _submitted = false;

	constructor(props: ILoginWindowProps) {
		super(props);
		this.state = {
			message: "",
		};
	}

	public override componentDidMount() {
		window.addEventListener("hashchange", this.onHashChange);
	}

	public override componentWillUnmount() {
		window.removeEventListener("hashchange", this.onHashChange);
	}

	private onHashChange = () => {
		this.props.appState.isLoggingIn = false;
	};

	private onForgottenPasswordClick = () => {
		this.props.navigation.go("auth/forgotten_password");
	};

	public override render() {
		const message = this.state.message || this.props.appState.lastError || "";
		const {isLoggingIn} = this.props.appState;

		return (
			<div className="LoginWindow">
				<div className="formContainer hbox alignCenter">
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<form
						className="vbox alignCenter"
						role="form"
						name="formLogin"
						noValidate
						autoCapitalize="off"
						onSubmit={this.onSubmit}
					>
						<div className="inputGroup">
							<input
								id="userName"
								name="userName"
								type="text"
								className="Input"
								placeholder="Username"
							/>
						</div>
						<div className="inputGroup">
							<input
								id="pwInput"
								name="pwInput"
								type="password"
								className="Input"
								placeholder="Password"
								autoComplete="off"
							/>
							<p
								className="forgotPassword"
								onClick={this.onForgottenPasswordClick}
							>
								Forgot Password?
							</p>
						</div>
						<button
							name="submitButton"
							className="Button primary"
							type="submit"
							disabled={isLoggingIn}
						>
							{isLoggingIn ? <LoaderIcon /> : "Log in"}
						</button>
						<div className="errorMessage">{message}</div>
						<Button
							onClick={() => this.props.navigation.openInNewTab("https://support.xyicon.com/docs")}
							className="naked small helpbutton"
							icon="help"
							label="Help"
						/>
					</form>
				</div>
			</div>
		);
	}

	private onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
		event.preventDefault();

		this.props.appState.lastError = "";
		this.props.appState.isLoggingIn = true;

		if (this._submitted) {
			return;
		}

		this._submitted = true;

		this.setState({
			message: "",
		});

		const formData = new FormData(event.target);
		const user = formData.get("userName") as string;
		const password = formData.get("pwInput") as string;
		const {error} = await this.props.transport.services.auth.loginPassword(user, password);

		this._submitted = false;

		if (error) {
			this.setState({message: error});
			this.props.appState.isLoggingIn = false;
		} else {
			this.setState({message: ""});
			await this.props.navigation.redirectAfterLogin();
			this.props.transport.services.auth.loadSecondaryList();
		}
	};
}

import * as React from "react";
import {PointerDetectorReact} from "../../../../../../interaction/PointerDetectorReact";
import type {Pointer} from "../../../../../../../utils/interaction/Pointer";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import type {IHSLColor} from "./AdvancedColorPanel";

interface ColorGradientProps {
	readonly color: IHSLColor;
	readonly onSLChange: (newSaturation: number, newLightness: number) => void;
	readonly width: number; // css width of canvas
	readonly height: number; // css height of canvas
	readonly onPointerUp: (pointer: Pointer) => void;
}

export class ColorGradient extends React.Component<ColorGradientProps> {
	private onPointerStart = (pointer: Pointer) => {
		this.updateValue(pointer);
	};

	private onPointerMove = (pointer: Pointer) => {
		this.updateValue(pointer);
	};

	private updateValue(pointer: Pointer) {
		const handlePos = {
			x: MathUtils.clamp(pointer.localX, 0, this.props.width),
			y: MathUtils.clamp(pointer.localY, 0, this.props.height),
		};

		const newSLValues = {
			s: handlePos.y / this.props.height,
			l: handlePos.x / this.props.width,
		};

		this.props.onSLChange(newSLValues.s, newSLValues.l);
	}

	public override render() {
		const gradientStyle = {
			backgroundImage: `linear-gradient(hsl(0, 0%, 50%), hsl(${this.props.color.hue}deg, 100%, 50%))`,
			width: `${this.props.width}px`,
			height: `${this.props.height}px`,
		};

		const handlePos = {
			x: this.props.color.lightness * this.props.width,
			y: this.props.color.saturation * this.props.height,
		};

		return (
			<PointerDetectorReact
				parent={null}
				onDown={this.onPointerStart}
				onMove={this.onPointerMove}
				onUp={this.props.onPointerUp}
			>
				<div className="ColorGradient">
					<div
						style={gradientStyle}
						className="gradientPanel"
					/>
					<div
						className="dragHandle"
						style={{
							transform: `translateX(${handlePos.x}px) translateY(${handlePos.y}px) translateX(-50%) translateY(-50%)`,
						}}
					/>
				</div>
			</PointerDetectorReact>
		);
	}
}

import * as React from "react";
import {MathUtils as THREEMath} from "three";
import type {PointDouble, Color} from "../../../../../generated/api/base";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {TransformableSVGElementV5} from "./TransformableSVGElementV5";

interface IIconTextProps {
	readonly x: number;
	readonly y: number;
	readonly viewBoxSize: number;
	readonly text: string;
	readonly orientation: number;
	readonly color: Color;
	readonly fontFamily: string;
	readonly fontSize: number;
	readonly isBold: boolean;
	readonly isItalic: boolean;
	readonly isUnderlined: boolean;
	readonly borderColor: string;
	readonly parentRef: React.RefObject<SVGSVGElement>;
	readonly onTranslateChange?: (newDelta: PointDouble) => void;
	readonly onOrientationChange?: (newDelta: number) => void;
	readonly isSelected?: boolean; // if true, show border and rotation icon
	readonly horizontalAlignment?: HorizontalAlignment;
}

interface IIconTextState {
	offset: PointDouble;
	deltaOrientation: number;
}

export class IconTextV5 extends React.Component<IIconTextProps, IIconTextState> {
	private _textRef = React.createRef<SVGTextElement>();

	constructor(props: IIconTextProps) {
		super(props);
		this.state = {
			offset: {
				x: 0,
				y: 0,
			},
			deltaOrientation: 0,
		};
	}

	private onTranslateChange = (deltaOffset: PointDouble, propagate: boolean = false) => {
		if (propagate) {
			if (this.props.onTranslateChange) {
				this.props.onTranslateChange({
					x: this.props.x + this.state.offset.x,
					y: this.props.y + this.state.offset.y,
				});
				this.setState({
					offset: {
						x: 0,
						y: 0,
					},
				});
			}
		} else {
			this.setState({
				offset: deltaOffset,
			});
		}
	};

	private onOrientationChange = (deltaAngle: number, propagate: boolean = false) => {
		if (propagate) {
			if (this.props.onOrientationChange) {
				this.props.onOrientationChange(this.props.orientation + deltaAngle);
				this.setState({
					deltaOrientation: 0,
				});
			}
		} else {
			this.setState({
				deltaOrientation: deltaAngle,
			});
		}
	};

	private getTextAnchor = () => {
		const {horizontalAlignment} = this.props;

		switch (horizontalAlignment) {
			case HorizontalAlignment.left:
				return "start";
			case HorizontalAlignment.right:
				return "end";
			default:
				return "middle";
		}
	};

	private getAlignOffset = () => {
		const {viewBoxSize, horizontalAlignment} = this.props;
		const textOffset = (this._textRef.current?.getBBox().width / 2 / viewBoxSize) * 100;

		if (textOffset) {
			if (horizontalAlignment === HorizontalAlignment.left) {
				return -textOffset;
			}
			if (horizontalAlignment === HorizontalAlignment.right) {
				return textOffset;
			}
		}
		return 0;
	};

	public override componentDidMount(): void {
		this.forceUpdate();
	}

	public override render() {
		if (this.props.text) {
			const {
				x,
				y,
				orientation,
				onTranslateChange,
				isSelected,
				color,
				text,
				fontSize,
				viewBoxSize,
				parentRef,
				borderColor,
				fontFamily,
				isBold,
				isItalic,
				isUnderlined,
			} = this.props;
			const currentTranslate = {
				x: x + this.state.offset.x,
				y: y + this.state.offset.y,
			};

			const currentOrientation = orientation + this.state.deltaOrientation;

			const isTransformEnabled = onTranslateChange && isSelected;

			const fillColor = ColorUtils.hex2rgb(color.hex, 1 - color.transparency, "string") as string;

			const lines = text.split("\n");

			const linespacingRatio = (fontSize / viewBoxSize) * 100;

			return (
				<TransformableSVGElementV5
					isTransformEnabled={isTransformEnabled}
					componentRef={this._textRef}
					orientation={currentOrientation}
					lastSavedOrientation={orientation}
					pivotX={currentTranslate.x}
					pivotY={currentTranslate.y}
					scale={1}
					viewBoxSize={viewBoxSize}
					onTranslateChange={this.onTranslateChange}
					onOrientationChange={this.onOrientationChange}
					parentRef={parentRef}
					borderColor={borderColor}
				>
					<text
						textAnchor={this.getTextAnchor()}
						alignmentBaseline="middle"
						x={`${currentTranslate.x * 100}%`}
						y={`${currentTranslate.y * 100}%`}
						transform={`rotate(${THREEMath.radToDeg(currentOrientation)} ${currentTranslate.x * viewBoxSize} ${currentTranslate.y * viewBoxSize})`}
						fill={fillColor}
						fontSize={fontSize}
						fontFamily={fontFamily}
						fontWeight={isBold ? "bold" : "normal"}
						fontStyle={isItalic ? "italic" : "normal"}
						textDecoration={isUnderlined ? "underline" : "none"}
						ref={this._textRef}
					>
						{lines.map((line, index) => {
							return (
								<tspan
									key={index}
									x={`${currentTranslate.x * 100 + this.getAlignOffset()}%`}
									y={`${currentTranslate.y * 100 + (index - (lines.length - 1.5) / 2.0) * linespacingRatio}%`}
								>
									{line}
								</tspan>
							);
						})}
					</text>
				</TransformableSVGElementV5>
			);
		} else {
			return false;
		}
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {XyiconFeature} from "../../../generated/api/base";
import type {IModel} from "../../../data/models/Model";
import {getModelId} from "../../../data/models/Model";
import type {View} from "../../../data/models/View";
import type {AppState} from "../../../data/state/AppState";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {Signal} from "../../../utils/signal/Signal";
import type {IFieldPointer} from "../../../data/models/field/Field";
import type {IViewColumn} from "../../../data/models/ViewUtils";
import {getSortForField} from "../../../data/models/ViewUtils";
import {toggleTableSort} from "../../widgets/table/TableUtils";
import {InitialsV5} from "../widgets/InitialsV5";
import type {ManageColumnParam} from "../../widgets/table/TableHeaderDropDown";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import {EmptyListViewV5} from "../details/EmptyListViewV5";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import type {TableRowV5} from "./table/TableRowV5";
import {TableV5} from "./table/TableV5";
import {GridActionBarV5} from "./GridActionBarV5";
import type {IActionBarItem} from "./ModuleViewV5";

interface IGridViewProps<T extends IModel> {
	readonly feature: XyiconFeature;
	readonly items: T[];
	readonly selected: T[];
	readonly focused: T[];
	readonly view: View;
	readonly onFocus: (items: T[], isGoToItemNeeded?: boolean) => void;
	readonly onSelect: (items: T[]) => void;
	readonly onClick: (item: T) => void;
	readonly onDoubleClick: (item: T) => void;
	readonly onAddClick: () => void;
	readonly appState?: AppState;
	readonly checkboxColumn?: boolean;
	readonly search: string;
	readonly searching: boolean;
	readonly filterSuppression?: boolean;
	readonly tableRowIcon?: boolean;
	readonly isCellContentsWrappingOn?: boolean;
	readonly emptyListText?: React.ReactNode;
	readonly onManageColumns?: () => void;
	readonly actionBarButtons?: IActionBarItem<T>[];
	readonly actionBarDefaultClick: (item: IActionBarItem<T>) => void;
	readonly onCloseGridActionBar?: () => void;
	readonly onDuplicateClick?: (item?: T) => Promise<void>;
}

@inject("appState")
@observer
export class GridViewV5<T extends IModel> extends React.Component<IGridViewProps<T>> {
	private _lastTimeStampForSelectAndFocus: number = 0;
	public static readonly defaultProps: Partial<IGridViewProps<any>> = {
		checkboxColumn: true,
	};

	public signals = {
		onMouseMoveOnRow: Signal.create<Pointer, TableRowV5<T>>(),
	};

	private _lastFilteredData: T[];
	private _lastSelection: T[];
	public _lastItemCount: number;

	private onTableRowMouseMove = (pointer: Pointer, row: TableRowV5<T>) => {
		this.signals.onMouseMoveOnRow.dispatch(pointer, row);
	};

	private onHeaderWidthChange = (index: number, width: number) => {
		this.getView().resizeColumn(index, width, this.props.feature);
	};

	private onReorderColumn = (index: number, newIndex: number) => {
		this.getView().reorderColumn(index, newIndex, this.props.feature);
	};

	private onToggleSort = (field: IFieldPointer) => {
		const view = this.getView();

		const sort = getSortForField(view.sorts, field);

		field !== `${XyiconFeature.Xyicon}/icon` && field !== `${XyiconFeature.XyiconCatalog}/icon` && view.addSort(toggleTableSort(sort, field));
	};

	private filterHeaders = (columns: IViewColumn[]) => {
		return columns.filter((col) => !col.field.includes("versionName") && !col.field.includes("issuanceDate"));
	};

	private getHeaders = (): IViewColumn[] => {
		const {feature, appState} = this.props;
		const view = this.getView();

		if (view) {
			const columns = this.filterHeaders(view.getValidViewColumns(feature));

			return columns.map((column) => ({
				...column,
				title: appState.actions.getFieldTitle(column.field),
			}));
		}

		return [];
	};

	private getFields = (item: T, index?: number) => {
		const {appState} = this.props;
		const headers = this.getHeaders();

		return headers.map((column) => {
			return appState.actions.renderValue(item, column.field);
		});
	};

	private renderInitialComponent(item: T) {
		const itemType = this.props.appState.actions.getTypeById(item?.typeId);
		const color = itemType?.settings.color.hex || "FFFFFF";

		return (
			<InitialsV5
				item={item}
				color={color}
				name={itemType?.name}
				size={30}
			/>
		);
	}

	private getFieldsArray = (item: T, index?: number) => {
		const {actions} = this.props.appState;
		const headers = this.getHeaders();

		return headers.map((column) => {
			if (column.field.includes("icon")) {
				return this.renderInitialComponent(item);
			}
			// single value
			return actions.getValueFromPropagation(item, column.field) ?? actions.renderValue(item, column.field);
		});
	};

	private getSearchedItems = (items: T[]) => {
		const {search, searching, feature, appState} = this.props;

		if (searching) {
			// When searching, don't call searchModels as that takes long
			return [];
		}

		let result: T[];

		if (items.length > 300 && this._lastFilteredData && this.didSelectionChange() && this._lastItemCount === items.length) {
			// Most likely only the selection changed -> use lastFilteredData (it can be very expensive to call searchModels)
			result = this._lastFilteredData;
		} else {
			this._lastFilteredData = appState.actions.searchModelsCached(items, search, feature);
			result = this._lastFilteredData;
		}

		this._lastSelection = this.props.selected;
		return result;
	};

	private didSelectionChange() {
		if (this._lastSelection === undefined) {
			return true;
		} else {
			return !ArrayUtils.equals(this._lastSelection, this.props.selected);
		}
	}

	private getView(): View {
		const {view, appState, feature} = this.props;

		return view || appState.defaultViews[feature];
	}

	private onManageColumns = (param: ManageColumnParam) => {
		if (param.type === "manage") {
			this.props.onManageColumns();
		} else if (param.type === "hide") {
			const field = this.getHeaders()[param.index]?.field;

			if (field) {
				const {view} = this.props;

				view?.removeColumnsByRefId?.([field], this.props.feature);
			}
		}
	};

	private get table() {
		return this.props.appState.tableComponent.current;
	}

	private selectHandler = (items: T[]) => {
		this._lastTimeStampForSelectAndFocus = window.performance.now();

		return this.props.onSelect(items);
	};

	private focusHandler = (items: T[]) => {
		this._lastTimeStampForSelectAndFocus = window.performance.now();

		return this.props.onFocus(items);
	};

	public override componentDidMount() {
		const {selected, items} = this.props;

		if (selected.length > 0) {
			this.table?.goToItem(selected[0]);
		}

		this._lastItemCount = items.length;
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IGridViewProps<T>>, nextContext: any): void {
		const timeStampNow = window.performance.now();

		// Hacky workaround:
		// In this case, we assume that the selected / focused props changed from the outside, and not because the user clicked on a row in the table
		// This is useful in the case when we can select and focus items from the space editor as well as the grid next to it
		if (timeStampNow - this._lastTimeStampForSelectAndFocus > 1000) {
			if (!ObjectUtils.compare(this.props.focused, nextProps.focused) && nextProps.focused.length > 0) {
				this.table?.goToItem(nextProps.focused[nextProps.focused.length - 1]);
			} else if (!ObjectUtils.compare(this.props.selected, nextProps.selected) && nextProps.selected.length > 0) {
				this.table?.goToItem(nextProps.selected[nextProps.selected.length - 1]);
			}
		}
	}

	public override componentDidUpdate(prevProps: IGridViewProps<T>) {
		if (this.props.selected.length > 0 && (this.props.feature !== prevProps.feature || this._lastItemCount < this.props.items.length)) {
			this.table?.goToItem(this.props.selected[0]);
		}

		this._lastItemCount = prevProps.items.length;
	}

	public override render() {
		const {
			items,
			checkboxColumn,
			selected,
			focused,
			onClick,
			onDoubleClick,
			feature,
			tableRowIcon,
			onAddClick,
			search,
			searching,
			appState,
			filterSuppression,
			isCellContentsWrappingOn,
			actionBarButtons,
			actionBarDefaultClick,
			onCloseGridActionBar,
			onDuplicateClick,
		} = this.props;
		const view = this.getView();
		const data = appState.actions.sortItems(items, view);

		return (
			<>
				{items.length > 0 || filterSuppression || searching ? (
					<TableV5
						ref={appState.tableComponent as any}
						getHeaders={this.getHeaders}
						onHeaderWidthChange={this.onHeaderWidthChange}
						data={data}
						searchedData={this.getSearchedItems(data)}
						getKey={getModelId}
						getFields={this.getFields}
						getFieldsArray={this.getFieldsArray}
						selected={selected}
						focused={focused}
						onSelect={this.selectHandler}
						onFocus={this.focusHandler}
						onClick={onClick}
						onDoubleClick={onDoubleClick}
						onReorderColumn={this.onReorderColumn}
						checkboxColumn={checkboxColumn}
						sorts={view.sorts}
						view={view}
						onToggleSort={this.onToggleSort}
						moduleName={appState.selectedModuleTitle}
						feature={feature}
						tableRowIcon={tableRowIcon}
						search={search}
						searching={searching}
						filterSuppression={filterSuppression}
						isCellContentsWrappingOn={isCellContentsWrappingOn}
						onManageColumns={this.props.onManageColumns && this.onManageColumns}
						onTableRowMouseMove={this.onTableRowMouseMove}
						onDuplicateClick={onDuplicateClick}
					/>
				) : (
					<EmptyListViewV5
						onAddClick={onAddClick}
						feature={feature}
						text={this.props.emptyListText}
					/>
				)}
				{selected.length > 0 && actionBarButtons?.length > 0 && (
					<GridActionBarV5
						items={selected}
						feature={this.props.feature}
						buttons={actionBarButtons}
						defaultClick={actionBarDefaultClick}
						onClose={onCloseGridActionBar}
						onFocus={this.focusHandler}
						onSelect={this.selectHandler}
					/>
				)}
			</>
		);
	}
}

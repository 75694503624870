import type {ReactNode} from "react";
import {useEffect, useRef, useState} from "react";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {Functions} from "../../../utils/function/Functions";
import {ReactUtils} from "../../utils/ReactUtils";
import {useAppStore} from "../../../StateManager";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import CaretRightIcon from "../icons/caret-right.svg?react";
import {DropdownContentStyled, ProfileItemStyled} from "./TopBarDropdown.styled";

export interface IDropdownElement {
	icon?: ReactNode;
	title?: string;
	disabled?: boolean;
	label?: string;
	strokeOnly?: boolean;
	onMouseDown?: (event?: React.MouseEvent) => void;
	hasLineOnTop?: boolean;
	subElements?: IDropdownElement[];
	rightContent?: ReactNode;
}

interface ITopBarDropdownV5Props {
	readonly elements: IDropdownElement[];
	readonly parent: React.RefObject<HTMLDivElement> | React.RefObject<SVGSVGElement>;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
}

export const TopBarDropdownV5 = (props: ITopBarDropdownV5Props) => {
	const {horizontalAlignment = HorizontalAlignment.outerRight, verticalAlignment = VerticalAlignment.bottom, parent} = props;
	const _floating = useRef<HTMLDivElement>();
	const _subFloatings = useRef([]);
	const appState = useAppStore((state) => state.appState);
	const isInSettingsView = ["settings"].includes(appState.selectedMenu);
	const [transform, setTransform] = useState<TransformObj>(null);
	const [hovered, setHovered] = useState<boolean>(false);
	const {elements} = props;

	useEffect(() => {
		const parentElement = parent instanceof Element ? parent : parent.current;

		if (parentElement && _floating.current) {
			setTransform(
				DomUtils.getFixedFloatingElementPosition(parentElement as Element, _floating.current, verticalAlignment, horizontalAlignment, 10, 0),
			);
		}
	}, [horizontalAlignment, verticalAlignment, parent]);

	const inlineStyle: React.CSSProperties = _floating.current && {
		transform: transform?.translate,
		visibility: "visible",
	};

	return (
		<DropdownContentStyled
			ref={_floating}
			style={inlineStyle}
			className={ReactUtils.cls("dropdownContent", {[HorizontalAlignment[horizontalAlignment]]: true})}
		>
			{elements.map((element, index) => {
				const hasChildren = element.subElements?.length > 0;

				if (isInSettingsView && (element.label === "Settings" || element.label === "My profile")) {
					return null;
				}

				return (
					<ProfileItemStyled
						key={index}
						ref={(el) => (_subFloatings.current[index] = el)}
						className={ReactUtils.cls("profileItem", {lined: element.hasLineOnTop})}
						onMouseDown={element.onMouseDown}
						onTouchStart={Functions.preventDefault}
						onMouseOver={() => hasChildren && setHovered(true)}
						onMouseLeave={() => hasChildren && setHovered(false)}
					>
						{element.icon}
						<span>{element.label}</span>
						{hasChildren && <CaretRightIcon style={hovered ? {transform: "rotate(90deg)"} : {}} />}
						{hasChildren && <div className={ReactUtils.cls("arrow", {isOpen: hovered})} />}
						{hasChildren && hovered && (
							<DomPortal destination={appState.app.modalContainer}>
								<TopBarDropdownV5
									parent={_subFloatings.current[index]}
									elements={element.subElements}
									verticalAlignment={VerticalAlignment.bottom}
									horizontalAlignment={HorizontalAlignment.outerLeft}
								/>
							</DomPortal>
						)}
						{element.rightContent}
					</ProfileItemStyled>
				);
			})}
		</DropdownContentStyled>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../data/TransportLayer";
import type {Navigation} from "../Navigation";
import type {AppState, UIVersion} from "../data/state/AppState";
import type {UpdateUserProfileRequest} from "../generated/api/base";
import {XHRLoader} from "../utils/loader/XHRLoader";
import {DateUtils} from "../utils/DateUtils";
import {NavigationEnum} from "../Enums";
import type {IDropdownElementProps} from "./topbar/TopBarDropdown";
import {TopBarDropdown} from "./topbar/TopBarDropdown";

interface IUserProfileProps {
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly appState?: AppState;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class UserProfile extends React.Component<IUserProfileProps> {
	private _avatar = React.createRef<HTMLDivElement>();

	private onSettingsClick = () => {
		this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
	};

	private onMyProfileClick = () => {
		this.props.navigation.goApp(NavigationEnum.NAV_SETTINGS, "personal");
	};

	private onLogoutClick = () => {
		return this.props.transport.services.auth.logout();
	};

	private onUITogglerClick = () => {
		const {user, theme} = this.props.appState;

		const newVersion: UIVersion = "5.0";
		const date = DateUtils.stringify(new Date());
		const v5ToggleCount = user.preferences.generalSetting.v5ToggleCount + 1;

		this.props.appState.setUIVersion(newVersion);
		user.generalSetting.v5ToggleCount = v5ToggleCount;

		const params: UpdateUserProfileRequest = {
			firstName: user.firstName,
			lastName: user.lastName,
			username: user.username,
			phoneNumber: user.phone,
			countryCode: user.countryCode,
			userGeneralSetting: {
				timeZone: "GMT+2",
				theme: theme,
				language: "en",
				currentUiVersion: newVersion,
				lastToggleOnAt: date,
				v5ToggleCount: v5ToggleCount,
			},
		};

		return this.props.appState.app.transport.requestForOrganization<UpdateUserProfileRequest[]>({
			url: "users/updateuserprofile",
			method: XHRLoader.METHOD_POST,
			params: params,
		});
	};

	public override render() {
		const {appState} = this.props;
		const user = appState.user;
		const initials = user ? appState.actions.renderInitials(user.id) : "";

		const dropdownElements: IDropdownElementProps[] = [
			{
				icon: "user",
				onClick: this.onMyProfileClick,
				label: "My profile",
			},
			{
				icon: "settings",
				onClick: this.onSettingsClick,
				label: "Settings",
			},
		];

		if (this.props.appState.user?.email.endsWith("@xyicon.com")) {
			dropdownElements.push({
				icon: "palette",
				onClick: this.onUITogglerClick,
				label: "Xyicon v5.0",
				hasLineOnTop: true,
			});
		}

		dropdownElements.push({
			icon: "logout",
			onClick: this.onLogoutClick,
			label: "Logout",
		});

		return (
			<div
				className="avatar avatarColor dropdown"
				ref={this._avatar}
			>
				<span className="initials">{initials}</span>
				<TopBarDropdown
					elements={dropdownElements}
					parent={this._avatar}
				/>
			</div>
		);
	}
}

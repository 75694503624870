import type {Boundary} from "../../../../../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../../../../../data/models/BoundarySpaceMap";
import type {Type} from "../../../../../../../data/models/Type";
import type {UpdateBoundaryTypeDto} from "../../../../../../../generated/api/base";
import type {BoundarySpaceMap3D} from "../../../../../space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {SpaceViewRenderer} from "../../../../../space/spaceeditor/logic3d/renderers/SpaceViewRenderer";

export class DefaultFieldsUtils {
	public static readonly changeTypeOfBoundary = async (item: Boundary | BoundarySpaceMap, type: Type, spaceViewRenderer: SpaceViewRenderer) => {
		const boundaryItem: Boundary = (item as BoundarySpaceMap).isBoundarySpaceMap ? (item as BoundarySpaceMap).parent : (item as Boundary);
		const transport = spaceViewRenderer.transport;
		const prevTypeId = item.typeId;
		boundaryItem.typeId = type.id;

		const result = await transport.updateBoundaryType(boundaryItem);

		// If BE respond is not "OK", we revert to the previous type
		if (!result.boundaryIDList?.includes(boundaryItem.id)) {
			boundaryItem.typeId = prevTypeId;
		} else {
			return this.onTypeOfBoundaryChange(boundaryItem, result, spaceViewRenderer);
		}
	};

	public static readonly onTypeOfBoundaryChange = async (
		item: Boundary,
		updateBoundaryData: UpdateBoundaryTypeDto,
		spaceViewRenderer: SpaceViewRenderer,
	) => {
		item.applyData({
			boundaryTypeID: updateBoundaryData.boundaryTypeID,
			lastModifiedAt: updateBoundaryData.lastModifiedAt,
			lastModifiedBy: updateBoundaryData.lastModifiedBy,
		});
		if (spaceViewRenderer.isMounted) {
			const boundarySpaceMap3Ds = [...item.boundarySpaceMaps].map(
				(bsm) => spaceViewRenderer.boundaryManager.getItemById(bsm.id) as BoundarySpaceMap3D,
			);
			for (const boundarySpaceMap3D of boundarySpaceMap3Ds) {
				boundarySpaceMap3D?.updateByModel(boundarySpaceMap3D.modelData as BoundarySpaceMap);
			}

			await spaceViewRenderer.boundaryManager.captionManager.updateCaptions();
			await spaceViewRenderer.xyiconManager.captionManager.updateCaptions();
		}
	};
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IReactionDisposer} from "mobx";
import {observable, reaction, makeObservable} from "mobx";
import type {IModel} from "../../../../data/models/Model";
import type {FilterType, IFilterParam, IFilterRow, IFilterRowFilter} from "../../../../data/models/filter/Filter";
import {createFilterState} from "../../../../data/models/filter/Filter";
import {FieldDataType, XyiconFeature} from "../../../../generated/api/base";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import type {Space} from "../../../../data/models/Space";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {LogicalSeparator} from "../../../../data/models/filter/LogicalSeparator";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {ICachedObject, IFilterValues} from "../../../../data/state/FilterActions";
import {DebugInformation} from "../../../../utils/DebugInformation";
import {simpleFilterPositions} from "../../../modules/abstract/filter/simple/SimpleFilterPositions";
import {LoaderIconV5} from "../../loader/LoaderIconV5";
import {FilterOperatorsV5} from "../operator/FilterOperatorsV5";
import {getFieldRefIdsWithHiddenBlanks} from "../../../modules/abstract/filter/simple/SimpleFilterUtils";
import {SpecialFilterFieldV5} from "./SpecialFilterFieldV5";
import {SimpleFilterFieldV5} from "./SimpleFilterFieldV5";

interface ISimpleFilterEditorProps {
	readonly items: IModel[];
	readonly filters: IFilterRow[];
	readonly localFilters?: IFilterRow[];
	readonly features: XyiconFeature[];
	readonly feature: XyiconFeature;
	readonly loader?: boolean;
	readonly search?: string;
	readonly resetSearch?: () => void;

	readonly app?: App;
	readonly appState?: AppState;
}

interface ISimpleFilterEditorState {
	openFieldRefId: string;
}

const logId: string = "Building simple filter UI";

@inject("app")
@inject("appState")
@observer
export class SimpleFilterEditorV5 extends React.Component<ISimpleFilterEditorProps, ISimpleFilterEditorState> {
	// these field types use the SpecialFilterField component that allows to use special UI to filter data
	// instead of checking checkboxes of all the possible values.
	private static specialFieldTypes = [FieldDataType.DateTime];
	private _fieldRefIdsWithHiddenBlanks: Set<string> = getFieldRefIdsWithHiddenBlanks();

	private _isMounted: boolean = false;
	private _timeoutId: number;

	public readonly type: FilterType = "simple";

	private _filterComponents: {
		[refId: string]: React.RefObject<SimpleFilterFieldV5>;
	} = {};

	private _specialFilterComponents: {
		[refId: string]: React.RefObject<SpecialFilterFieldV5>;
	} = {};

	@observable
	private _spaceId = "";
	private _disposer: IReactionDisposer;

	constructor(props: ISimpleFilterEditorProps) {
		super(props);
		makeObservable(this);
		this.state = {
			openFieldRefId: "",
		};
	}

	private get _filters() {
		return this.props.localFilters;
	}

	private onSpaceChange = (space: Space) => {
		// This is a hack but it's very important:
		// without the artifical delay, getCachedObject is called when spaceId changes, but items is still the old value
		// causing the old items to be used in calculating the cache and the next time getCachedObject is called
		// with the new items the cache is already saved so it won't be calculated again
		// -> we have to make sure spaceId is changed AFTER the items
		// Other option would be to be able to know when the items change and only change the props.items reference in that case.
		setTimeout(() => {
			this._spaceId = space?.id || "";

			// Workaround for #2279: When the user switches to Space "B", ...,  rest of the Xyicon IDs from Space "B" should be displayed in unticked state
			if (this.props.app.spaceViewRenderer.isMounted) {
				this.props.app.spaceViewRenderer.spaceItemController.filterState = createFilterState();
			}
		}, 2000);
	};

	public resetTo(filters: IFilterRow[]) {
		if (!ObjectUtils.compare(this.props.filters, filters)) {
			ArrayUtils.replaceObservable(this.props.filters, filters);
		}

		this.resetSearch();
		this.resetMultiOperators();
		this.setState({
			openFieldRefId: "",
		});
	}

	public clearAll() {
		this._filters.length = 0;
		this.props.filters.length = 0;

		this.resetSearch();
		this.resetMultiOperators();
		this.setState({
			openFieldRefId: "",
		});
	}

	public applyAll = () => {
		this.resetSearch();
		this.resetMultiOperators();

		ArrayUtils.replaceObservable(this.props.filters, ObjectUtils.deepClone(this._filters));

		// We don't delete filters, if we did, the currently checked items wouldn't be shown.
		// this._filters = [];
		this.onToggle(this.state.openFieldRefId, false);
	};

	private resetSearch() {
		for (const refId in this._filterComponents) {
			const field = this._filterComponents[refId].current;

			this.props.resetSearch();
		}
	}

	private resetMultiOperators() {
		for (const refId in this._specialFilterComponents) {
			const field = this._specialFilterComponents[refId].current;

			field?.reset();
		}
	}

	private getCachedObject() {
		const {items, appState, features, filters, feature} = this.props;
		const unfilteredItems = features.map((f) => appState.actions.getList(f)).flat();

		return appState.actions.filterActions.getCachedObject(items, unfilteredItems, features, filters, feature, this._spaceId);
	}

	private getCheckedValues(fieldRefId: string, pFilters = this._filters) {
		const filters = pFilters.filter(
			(filter) =>
				filter.type === "filter" &&
				[FilterOperator.IS_ANY_OF, FilterOperator.IS_ONE_OF].includes(filter.value.operator) &&
				filter.value.field === fieldRefId,
		) as IFilterRowFilter[];
		let checkedValues: string[] = [];

		for (const filter of filters) {
			checkedValues = checkedValues.concat(filter.value.param as string[]);
		}
		return checkedValues;
	}

	private onFieldClear = (fieldRefId: string) => {
		this.removeFilter(fieldRefId, this.props.filters);
		this.removeFilter(fieldRefId, this._filters);

		// Close the cleared field (this is needed for MultilineField UX)
		if (this.state.openFieldRefId === fieldRefId) {
			this.setState({
				openFieldRefId: "",
			});
		}
	};

	private onToggleAllValues = (fieldRefId: string, checked: boolean, values: IFilterParam) => {
		const filter = this._filters.find((filter) => filter.type === "filter" && filter.value.field === fieldRefId) as IFilterRowFilter;

		if (checked) {
			// Check all was clicked

			if (filter?.value?.param?.length === 0 && values.length === this.getAllOptions(fieldRefId)?.length) {
				// check all was clicked and nothing is checked -> check all (remove the filter),
				// but only if there is no search currently, otherwise only check those that are visible
				this.removeFilter(filter?.value?.field);
			} else {
				// check all was clicked and there are some items checked -> update checked options
				// (this is possible when search is used)
				if (filter) {
					filter.value.param = values;
				} else {
					this.addFilter(fieldRefId, values);
				}
			}
		} else {
			if (filter) {
				filter.value.param.length = 0;
			} else {
				// When no filter is added yet, and all values are unchecked
				// -> add an empty filter
				const emptyArray: string[] = [];
				// Or if there's a hidden "Blank" option (like for refIds, or types, for example), then add the "blank" option.
				// This way it behaves exactly the same as if all the checkboxes were unchecked individually
				if (this._fieldRefIdsWithHiddenBlanks.has(fieldRefId)) {
					emptyArray.push("");
				}
				this.addFilter(fieldRefId, emptyArray);
			}
		}
	};

	private onToggleValue = (fieldRefId: string, value: string) => {
		let filter = this._filters.find((filter) => filter.type === "filter" && filter.value.field === fieldRefId) as IFilterRowFilter;

		if (filter) {
			const params = filter.value.param;
			const index = params.indexOf(value);

			if (index === -1) {
				params.push(value);
			} else {
				params.splice(index, 1);
				if (params.length === 0) {
					// no parameters -> this filter can be removed
					// #2343 - Update: not anymore, we allow an empty filter (same as when unchecking all)
					// this.removeFilter(filter);
				}
			}
		} else {
			// Previously:f
			// this.addFilter(fieldRefId, [value]);

			// #2343 if there is no filter, then all options are checked visually, but not in the data
			// and this is the first time we clicked on one of the options so we add the filter with all options except value
			const allOptions = this.getAllOptions(fieldRefId);
			const options = ArrayUtils.remove(allOptions, value);

			this.addFilter(fieldRefId, options);
		}

		this.removeFilterIfAllIsChecked(fieldRefId, filter);
	};

	// #2343
	// If all options are checked that's the same as if none of the options would be checked
	// -> remove filter
	private removeFilterIfAllIsChecked(fieldRefId: string, filter: IFilterRowFilter) {
		const allOptions = this.getAllOptions(fieldRefId);
		const allChecked = filter?.value?.param?.length === allOptions?.length;

		if (allChecked) {
			this.removeFilter(filter?.value?.field);
		}
	}

	private getAllOptions(fieldRefId: string) {
		return this.getCachedObject().values[fieldRefId];
	}

	private onMultilineFilterChange = (filter_: IFilterRowFilter) => {
		const isValid = FilterOperatorsV5.map[filter_.value.operator].validator(filter_.value.param);
		const filter = this.findFilter(filter_.value.field);

		if (isValid) {
			const operator = filter_.value.operator;
			const param = ObjectUtils.deepClone(filter_.value.param);

			if (!filter) {
				this.addFilter(filter_.value.field, param, operator);
			} else {
				filter.value.param = param;
				filter.value.operator = operator;
			}
		} else {
			if (filter) {
				this.removeFilter(filter?.value?.field);
			}
		}
	};

	private findFilter(refId: string, filters_: IFilterRow[] = this._filters) {
		const filters = filters_.filter((f) => f.type === "filter") as IFilterRowFilter[];

		return filters.find((f) => f.value.field === refId);
	}

	private addFilter(fieldRefId: IFieldPointer, param: IFilterParam, operator = FilterOperator.IS_ANY_OF): IFilterRowFilter {
		if (this._filters.length > 0) {
			this._filters.push({
				type: "separator",
				value: LogicalSeparator.AND,
			});
		}

		this._filters.push({
			type: "filter",
			value: {
				field: fieldRefId,
				operator: operator,
				param: param,
			},
		});

		// Very important: we have to return this._filters[x] instead of the new object before it's added to array (mobx)
		return this._filters[this._filters.length - 1] as IFilterRowFilter;
	}

	private removeFilter(fieldRefId: string, filters: IFilterRow[] = this._filters) {
		if (!fieldRefId) {
			return;
		}

		const clearedFilters = filters.filter((filter) => filter.type === "filter" && filter.value.field !== fieldRefId);

		// now we removed separators too, so we add those back in
		// SimpleFilter has 1 AND separator between each filter
		// (we could also keep separators but then it's possible there will be 2 separators next to each other)

		const newFilters: IFilterRow[] = [];

		for (let i = 0; i < clearedFilters.length - 1; ++i) {
			const filter = clearedFilters[i];

			newFilters.push(filter);
			newFilters.push({
				type: "separator",
				value: LogicalSeparator.AND,
			});
		}
		const lastFilter = clearedFilters[clearedFilters.length - 1];

		if (lastFilter) {
			newFilters.push(lastFilter);
		}

		ArrayUtils.copy(filters, newFilters);
	}

	private onToggle = (fieldRefId: string, open: boolean) => {
		const {items, appState, features, filters, feature} = this.props;

		if (feature !== XyiconFeature.SpaceEditor) {
			const unfilteredItems = features.map((f) => appState.actions.getList(f)).flat();

			appState.actions.filterActions.updateCachedObject(items, unfilteredItems, features, filters, fieldRefId, feature, this._spaceId);
		}

		this.setState({openFieldRefId: open ? fieldRefId : ""});
	};

	private filterSearch = (refId: IFieldPointer) => {
		const {appState, search} = this.props;
		const field = appState.actions.getFieldByRefId(refId);

		if (field) {
			return StringUtils.containsIgnoreCase(field.name, search);
		}
		return false;
	};

	private getFilterRefIds(cachedObject: ICachedObject) {
		const {filters, appState} = this.props;

		const values = cachedObject.values;
		const fieldRefIds = ArrayUtils.uniq([
			// already added filters (without this, empty result set would show no filters because values are empty)
			// We could consider using only this if result set is empty for efficiency?
			...filters.map((f: IFilterRowFilter) => f.value.field).filter((f) => f),
			// filters that have values
			...Object.keys(values),
		]);

		return fieldRefIds.filter((refId) => {
			const field = appState.actions.getFieldByRefId(refId);

			if (!field) {
				return false;
			}

			if (SimpleFilterEditorV5.specialFieldTypes.includes(field.dataType)) {
				const hasFilter = this.findFilter(field.refId, this.props.filters);

				if (hasFilter) {
					return true;
				}
			}

			// #2343: now we show all filters even if they have no checked options
			const hasCheckedOption = this.isFilterApplied(refId); //this.getCheckedValues(refId, filters).length >= 0;

			if (hasCheckedOption) {
				// Some options already checked for this filter -> show it
				return true;
			}

			if (field.dataType === FieldDataType.MultipleChoiceList) {
				// MultipleChoiceList fields are evaluated separately,
				// we show them if at least 2 objects have different values.
				return !!cachedObject.showMultipleChoiceFieldMap[refId];
			}

			// Use filter if it's already selected or if it has at least 2 options.
			return values[refId]?.length >= 2;
		});
	}

	private isFilterApplied(refId: string) {
		// TODO just check if refId is in this.props.filters?

		return !!this.findFilter(refId, this.props.filters);
		// this.getCheckedValues(refId, this.props.filters).length >= 0;
	}

	private renderField(fieldRefId: string, values: IFilterValues) {
		const {openFieldRefId} = this.state;
		const {filters, appState} = this.props;

		const field = appState.actions.getFieldByRefId(fieldRefId);

		if (SimpleFilterEditorV5.specialFieldTypes.includes(field.dataType)) {
			this._specialFilterComponents[fieldRefId] = this._specialFilterComponents[fieldRefId] || React.createRef<SpecialFilterFieldV5>();
			const filter = this.findFilter(fieldRefId);

			const props = {
				datatype: field.dataType as FieldDataType.DateTime,
				field,
				key: fieldRefId,
				fieldRefId: fieldRefId,
				filter,
				title: appState.actions.getFieldTitle(fieldRefId),
				onChange: this.onMultilineFilterChange,
				onClear: this.onFieldClear,
				open: openFieldRefId === fieldRefId,
				onToggle: this.onToggle,
				hasFiltersApplied: !!this.findFilter(fieldRefId, filters),
			};

			return (
				<SpecialFilterFieldV5
					{...props}
					ref={this._specialFilterComponents[fieldRefId] as React.RefObject<SpecialFilterFieldV5>}
				/>
			);
		} else {
			// If last applied filter is this -> use those saved values
			const options = values[fieldRefId] || [];

			// Add options that are checked (eg.: from previous space)
			let checkedValues = this.getCheckedValues(fieldRefId);

			for (const checkedValue of checkedValues) {
				if (!options.includes(checkedValue)) {
					options.push(checkedValue);
				}
			}

			this._filterComponents[fieldRefId] = this._filterComponents[fieldRefId] || React.createRef<SimpleFilterFieldV5>();

			// #2343 check all options if there is no filter
			const filter = this.findFilter(fieldRefId);

			if (!filter) {
				checkedValues = options.slice(0);
			}

			return (
				<SimpleFilterFieldV5
					ref={this._filterComponents[fieldRefId]}
					key={fieldRefId}
					fieldRefId={fieldRefId}
					title={appState.actions.getFieldTitle(fieldRefId)}
					values={options || checkedValues}
					checkedValues={checkedValues}
					onChange={this.onToggleValue}
					onChangeAll={this.onToggleAllValues}
					onClear={this.onFieldClear}
					open={openFieldRefId === fieldRefId}
					onToggle={this.onToggle}
					hasFiltersApplied={this.isFilterApplied(fieldRefId)}
				/>
			);
		}
	}

	private closeField = () => {
		const {openFieldRefId} = this.state;

		if (openFieldRefId) {
			this.setState({openFieldRefId: ""});
		}
	};

	public override UNSAFE_componentWillMount() {
		DebugInformation.start(logId);
	}

	public override componentDidMount() {
		const {appState, features} = this.props;

		this._isMounted = true;
		this._timeoutId = window.setTimeout(() => {
			if (this._isMounted) {
				requestAnimationFrame(() => {
					DebugInformation.end(logId);
				});
			}
		}, 200);

		if (this.props.feature === XyiconFeature.SpaceEditor) {
			this._spaceId = this.props.appState.space?.id || "";
			this._disposer = reaction(() => this.props.appState.space, this.onSpaceChange);
		}

		const collections = features.map((f) => appState.lists[f]).flat();

		collections.forEach((collection) => {
			collection.signals.itemsAdded.add(this.closeField);
		});
	}

	public override componentDidUpdate() {
		DebugInformation.end(logId);
	}

	public override componentWillUnmount() {
		const {appState, features} = this.props;

		clearTimeout(this._timeoutId);
		this._disposer?.();
		this._disposer = null;
		this._isMounted = false;

		const collections = features.map((f) => appState.lists[f]).flat();

		collections.forEach((collection) => {
			collection.signals.itemsAdded.remove(this.closeField);
		});
	}

	public override render() {
		DebugInformation.start(logId);
		const {feature, appState, search, loader} = this.props;

		let aboveFields: IFieldPointer[] = [];
		let belowFields: IFieldPointer[] = [];
		let values: IFilterValues;

		if (!loader) {
			const cachedObject = this.getCachedObject();

			values = cachedObject.values;

			const fieldRefIds = this.getFilterRefIds(cachedObject).filter((f) => !f.includes("icon"));

			const filteredFields = fieldRefIds.filter(this.filterSearch);

			[aboveFields, belowFields] = ArrayUtils.partition(filteredFields, (refId: string) => {
				const positions =
					simpleFilterPositions[
						feature as
							| XyiconFeature.Portfolio
							| XyiconFeature.XyiconCatalog
							| XyiconFeature.Space
							| XyiconFeature.SpaceEditor
							| XyiconFeature.Xyicon
							| XyiconFeature.Boundary
					];

				return positions?.includes(refId);
			});
		}

		return (
			<div className="SimpleFilterEditor">
				{loader ? (
					<LoaderIconV5 />
				) : (
					<>
						{aboveFields
							.sort((a, b) => {
								const positions =
									simpleFilterPositions[
										feature as
											| XyiconFeature.Portfolio
											| XyiconFeature.XyiconCatalog
											| XyiconFeature.Space
											| XyiconFeature.SpaceEditor
											| XyiconFeature.Xyicon
											| XyiconFeature.Boundary
									];

								if (positions) {
									const indexA = positions.indexOf(a);
									const indexB = positions.indexOf(b);

									return indexA - indexB;
								}
								console.warn(`SimpleFilterEditor sort: XyiconFeature not defined: ${feature}`);
								return 0;
							})
							.map((refId) => this.renderField(refId, values))}
						{belowFields.length > 0 && (
							<>
								{aboveFields.length > 0 && <div className="separator" />}
								{belowFields
									.sort((a, b) =>
										StringUtils.sortIgnoreCase(appState.actions.getFieldTitle(a).toLowerCase(), appState.actions.getFieldTitle(b).toLowerCase()),
									)
									.map((refId) => this.renderField(refId, values))}
							</>
						)}
						{aboveFields.length + belowFields.length === 0 && search && (
							<div className="noResult editor">No results found for the term "{search}".</div>
						)}
					</>
				)}
			</div>
		);
	}
}

import styled from "styled-components";
import {VerticalFlexStyle, baseDistance, fontSize, fontWeight, radius} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const ConditionalFormattingPanel = styled.div`
	${VerticalFlexStyle};
	min-height: 0;

	.formatting {
		padding-bottom: ${baseDistance.sm};
	}

	.container {
		.head {
			display: flex;
			padding-bottom: ${baseDistance.oh};
			flex-direction: column;
			gap: ${baseDistance.sm};

			.SearchField {
				width: 100%;
				border-radius: ${radius.sm};
			}
		}

		.body {
			height: calc(100vh - 329px);
			overflow-y: auto;
		}

		.ToggleContainer {
			&:not(.FieldSection) {
				border-top: none;
			}

			.filter-title {
				font-size: ${fontSize.lg};
				display: flex;
				align-items: center;
				color: ${colorPalette.primary.c500Primary};
				text-transform: uppercase;
				font-weight: ${fontWeight.bold};
				cursor: pointer;

				span.btn {
					fill: ${colorPalette.primary.c500Primary};
				}
			}

			.children {
				padding-left: ${baseDistance.sm};
			}
		}
	}

	.topButtons {
		display: flex;
		justify-content: end;
		gap: ${baseDistance.sm};
		padding: ${baseDistance.md} ${baseDistance.sm};
	}
`;

import {BasicColors} from "./BasicColors";
import {Formatter} from "./format/Formatter";

export interface IRGBObject {
	r: number;
	g: number;
	b: number;
	a?: number;
}

export class ColorUtils {
	public static rgb2hex(r: number, g: number, b: number, returnType: "number" | "string" = "number", addHash: boolean = false) {
		const hexNum = (r << 16) | (g << 8) | b;

		if (returnType === "string") {
			return ColorUtils.getHexStringFromNumber(hexNum, addHash);
		} else {
			return hexNum;
		}
	}

	/**
	 * 0x00ff00 = 65280 -> "#00ff00"
	 */
	public static getHexStringFromNumber(color: number, addHash = false): string {
		let cssColor = color.toString(16);

		// pad with 0s, in case color is "ff00" -> "00ff00"
		cssColor = Formatter.leftPad(cssColor, 6).toUpperCase();

		if (addHash) {
			cssColor = `#${cssColor}`;
		}

		return cssColor;
	}

	public static hex2Array(hex: string | number, opacity: number = 1): [number, number, number, number] {
		const {r, g, b, a} = ColorUtils.hex2rgb(hex, opacity, "RGBObject") as IRGBObject;

		return [r / 255, g / 255, b / 255, a];
	}

	/**
	 * https://stackoverflow.com/questions/12943774/hex-to-rgb-converter
	 * @param color
	 */
	public static hex2rgb(color: string | number, opacity: number = 1, returnType: "string" | "RGBObject" = "string"): string | IRGBObject {
		let r: string | number;
		let g: string | number;
		let b: string | number;

		if (typeof color === "string") {
			if (color.charAt(0) === "#") {
				color = color.substring(1);
			}
			if (color.length === 3) {
				color =
					color.substring(0, 1) +
					color.substring(0, 1) +
					color.substring(1, 2) +
					color.substring(1, 2) +
					color.substring(2, 3) +
					color.substring(2, 3);
			}
			r = color.charAt(0) + color.charAt(1);
			g = color.charAt(2) + color.charAt(3);
			b = color.charAt(4) + color.charAt(5);
			r = parseInt(r, 16);
			g = parseInt(g, 16);
			b = parseInt(b, 16);
		} else {
			r = (color >> 16) & 255;
			g = (color >> 8) & 255;
			b = color & 255;
		}

		if (returnType === "RGBObject") {
			return {
				r: r,
				g: g,
				b: b,
				a: opacity,
			};
		} else {
			return opacity === 1 ? `rgb(${r}, ${g}, ${b})` : `rgba(${r}, ${g}, ${b}, ${opacity})`;
		}
	}

	/**
	 * https://www.rapidtables.com/convert/color/rgb-to-hsl.html
	 * @param R [0 - 255]
	 * @param G [0 - 255]
	 * @param B [0 - 255]
	 *
	 * h [0 - 360]
	 * s [0 - 1]
	 * l [0 - 1]
	 *
	 * Sometimes, when a component (hue) is 0, actually they could be anything, due to hsl's characteristics.
	 * (eg.: when saturation is 0, or lightness is 0 or 1)
	 * These values become 0 as a convention
	 */
	public static rgb2hsl(R: number, G: number, B: number) {
		let r = R / 255;
		let g = G / 255;
		let b = B / 255;

		const cmin = Math.min(r, g, b);
		const cmax = Math.max(r, g, b);
		const delta = cmax - cmin;

		let h = 0;
		let s = 0;
		const l = (cmax + cmin) / 2;

		if (delta > 0) {
			if (0 < l && l < 1) {
				s = delta / (1 - Math.abs(2 * l - 1));

				if (cmax === r) {
					h = ((g - b) / delta) % 6;
				} else if (cmax === g) {
					h = (b - r) / delta + 2;
				} else if (cmax === b) {
					h = (r - g) / delta + 4;
				}

				h *= 60;

				if (h < 0) {
					h += 360;
				}

				h = h % 360;
			}
		}

		return {
			h: h,
			s: s,
			l: l,
		};
	}

	public static darkenColor(color: string, percent: number) {
		let R = parseInt(color.substring(0, 2), 16);
		let G = parseInt(color.substring(2, 4), 16);
		let B = parseInt(color.substring(4, 6), 16);

		R = Math.round(R - R * (percent / 100));
		G = Math.round(G - G * (percent / 100));
		B = Math.round(B - B * (percent / 100));

		R = R < 255 ? R : 255;
		G = G < 255 ? G : 255;
		B = B < 255 ? B : 255;

		const RR = R.toString(16).length == 1 ? `0${R.toString(16)}` : R.toString(16);
		const GG = G.toString(16).length == 1 ? `0${G.toString(16)}` : G.toString(16);
		const BB = B.toString(16).length == 1 ? `0${B.toString(16)}` : B.toString(16);

		return RR + GG + BB;
	}

	public static hex2hsl(hex: string | number) {
		const rgb = ColorUtils.hex2rgb(hex, 1, "RGBObject") as IRGBObject;

		return ColorUtils.rgb2hsl(rgb.r, rgb.g, rgb.b);
	}

	/**
	 *
	 * @param hue [0-360]
	 * @param saturation [0 - 1]
	 * @param lightness [0 - 1]
	 */
	public static hsl2hex(hue: number, saturation: number, lightness: number, returnType: "string" | "number" = "string") {
		const rgb = ColorUtils.hsl2rgb(hue, saturation, lightness);

		return ColorUtils.rgb2hex(rgb.r, rgb.g, rgb.b, returnType);
	}

	public static hsl2rgb(h: number, s: number, l: number) {
		const a = s * Math.min(l, 1 - l);

		const f = (n: number, k: number = (n + h / 30) % 12) => l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

		return {
			r: Math.round(f(0) * 255),
			g: Math.round(f(8) * 255),
			b: Math.round(f(4) * 255),
		};
	}

	public static hsl2array(h: number, s: number, l: number, opacity: number = 1): [number, number, number, number] {
		const {r, g, b} = this.hsl2rgb(h, s, l);

		return [r / 255, g / 255, b / 255, opacity];
	}

	public static hexColorByString(string: string) {
		const colors = BasicColors.getBasicColors();
		const finalColor = colors[[...string].map((s) => s.charCodeAt(0))?.reduce((acc, val) => acc + val) % colors.length];

		return finalColor?.hex || "1874CD";
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {Type} from "../../../../../../data/models/Type";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {App} from "../../../../../../App";
import type {AppState} from "../../../../../../data/state/AppState";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {AppActions} from "../../../../../../data/state/AppActions";
import {SelectInputV5} from "../../../../input/select/SelectInputV5";
import {ButtonV5} from "../../../../button/ButtonV5";
import ResetIcon from "../../../../icons/refresh-ccw.svg?react";

interface ICardLayout {
	"row 1": string;
	"row 2": string;
	"row 3": string;

	"hover row 1": string;
	"hover row 2": string;
	"hover row 3": string;
}

interface ICardLayoutEditorProps {
	readonly type: Type;
	readonly feature: XyiconFeature; // catalogs and xyicons share their types, but we still need to save different cardlayouts depending whether it's for catalog, or xyicon

	readonly app?: App;
	readonly appState?: AppState;
}

interface ISelectable {
	key: string; // we save this to the backend, eg.: f32, the refId of the field, or "name"
	display: string; // we show this to the user, eg.: "height" (name of a field), or eg.: "Portfolio Name" for built-in properties
}

const getDefaultCardLayout = (feature: XyiconFeature): ICardLayout => {
	switch (feature) {
		case XyiconFeature.Portfolio:
		case XyiconFeature.Space:
			return {
				"row 1": "refId",
				"row 2": "typeName",
				"row 3": "name",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.XyiconCatalog:
			return {
				"row 1": "model",
				"row 2": "typeName",
				"row 3": "",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.Xyicon:
			return {
				"row 1": "refId",
				"row 2": "model",
				"row 3": "typeName",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.Boundary:
			return {
				"row 1": "refId",
				"row 2": "typeName",
				"row 3": "",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.Document:
		case XyiconFeature.OrganizationDocument:
		case XyiconFeature.PortfolioDocument:
			return {
				"row 1": "fileName",
				"row 2": "fileType",
				"row 3": "lastModifiedAt",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.User:
			return {
				"row 1": "firstName",
				"row 2": "email",
				"row 3": "lastModifiedAt",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		case XyiconFeature.Markup:
			return {
				"row 1": "typeNameAsText",
				"row 2": "spaceName",
				"row 3": "textContent",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
		default:
			return {
				"row 1": "",
				"row 2": "",
				"row 3": "",
				"hover row 1": "",
				"hover row 2": "",
				"hover row 3": "",
			};
	}
};

// CardLayout key can be different from field refId
const compareKeyAndRefId = (key: string, refId: string) => {
	if (key.includes("ID") && refId.includes("refId")) {
		return true;
	}
	if (key.includes("Type") && refId.includes("type")) {
		return true;
	}
	if (key === "Model" && refId.includes("model")) {
		return true;
	}
	if (key.includes("Name") && refId.includes("name")) {
		return true;
	}
	return key === refId;
};

const getKeyDisplayPairsForCardLayout = (actions: AppActions, type: Type | null, feature: XyiconFeature) => {
	const fields: IFieldAdapter[] = actions.getAssignedAndInheritedFieldsFromLayout(type, feature);
	const selectableFields: ISelectable[] = fields
		.filter((f) => f.name !== "Icon")
		.sort((a, b) => StringUtils.sortIgnoreCase(a.name, b.name))
		.map((f) => ({key: f.refId, display: f.name}));

	switch (feature) {
		case XyiconFeature.Portfolio:
			return [
				empty,
				{key: "refId", display: "Portfolio ID"},
				{key: "typeName", display: "Portfolio Type"},
				{key: "name", display: "Portfolio Name"},
				...selectableFields,
			];
		case XyiconFeature.Space:
			return [
				empty,
				{key: "refId", display: "Space ID"},
				{key: "typeName", display: "Space Type"},
				{key: "name", display: "Space Name"},
				...selectableFields,
			];
		case XyiconFeature.XyiconCatalog:
			return [
				empty,
				{key: "model", display: "Model"},
				{key: "typeName", display: "Type"},
				{key: "refId", display: "Catalog ID"},
				...selectableFields,
			];
		case XyiconFeature.Xyicon:
			return [empty, {key: "refId", display: "Xyicon ID"}, {key: "model", display: "Model"}, {key: "typeName", display: "Type"}, ...selectableFields];
		case XyiconFeature.Boundary:
			return [empty, {key: "refId", display: "Boundary ID"}, {key: "typeName", display: "Boundary Type"}, ...selectableFields];
		case XyiconFeature.Document:
		case XyiconFeature.OrganizationDocument:
		case XyiconFeature.PortfolioDocument:
			return [
				empty,
				{key: "fileName", display: "File Name"},
				{key: "fileType", display: "Type"},
				{key: "lastModifiedAt", display: "Last updated"},
				...selectableFields,
			];
		case XyiconFeature.User:
			return [
				empty,
				{key: "firstName", display: "Name"},
				{key: "email", display: "Email"},
				{key: "lastModifiedAt", display: "Last Updated"},
				...selectableFields,
			];
		case XyiconFeature.Markup:
			return [
				empty,
				{key: "typeNameAsText", display: "Markup Type"},
				{key: "spaceName", display: "Space Name"},
				{key: "textContent", display: "Markup text"},
				...selectableFields,
			];
	}
};

const empty: ISelectable = {
	key: "",
	display: "Empty",
};

type CardLayoutLineKey = keyof ICardLayout;

@inject("app")
@inject("appState")
@observer
export class CardLayoutEditorV5 extends React.Component<ICardLayoutEditorProps> {
	private _defaultCardLayout = this.getDefaultCardLayout();

	private getSelectables(): ISelectable[] {
		const {actions} = this.props.appState;
		const {type, feature} = this.props;

		return getKeyDisplayPairsForCardLayout(actions, type, feature);
	}

	private getDefaultCardLayout(): ICardLayout {
		return getDefaultCardLayout(this.props.feature);
	}

	private onLineValueChange(key: CardLayoutLineKey, selectable: ISelectable) {
		this.props.type.settings.cardLayout[this.props.feature][key] = selectable.key;

		this.updateApi();
	}

	private async updateApi() {
		const {type} = this.props;

		if (type.id) {
			const services = this.props.app.transport.services;

			await services.typefield.updateType(type);
		}
	}

	private onResetClick(key: CardLayoutLineKey) {
		const defaultCardLayout = this._defaultCardLayout;

		this.props.type.settings.cardLayout[this.props.feature][key] = defaultCardLayout[key];
		this.updateApi();
	}

	private getRows(keys: CardLayoutLineKey[]) {
		const rows: React.JSX.Element[] = [];

		const {type, feature} = this.props;

		const selectables = this.getSelectables();
		const defaultCardLayout = this._defaultCardLayout;

		const selectablesWithoutEmpty = [...selectables];

		selectablesWithoutEmpty.shift();

		const prefixToRemove = "Hover ";

		for (const row of keys) {
			const label = row.includes(prefixToRemove) ? StringUtils.capitalize(row.substring(prefixToRemove.length)) : row;

			rows.push(
				<CardLayoutEditorFieldStyled key={row}>
					<CardLayoutEditorFieldLabelStyled>{StringUtils.capitalize(label)}</CardLayoutEditorFieldLabelStyled>
					<CardLayoutEditorFieldInputContainerStyed>
						<ButtonV5
							type="secondary"
							disabled={type.settings.cardLayout[feature][row] === defaultCardLayout[row]}
							onClick={() => this.onResetClick(row)}
							label=""
							className="button"
						>
							<ResetIcon />
						</ButtonV5>
						<SelectInputV5
							options={row === "row 1" ? selectablesWithoutEmpty : selectables}
							selected={
								selectables.find((s) => s.key === type.settings.cardLayout[feature][row]) ||
								selectables.find((s) => s.key === defaultCardLayout[row]) ||
								empty
							}
							onChange={(selectable: ISelectable) => this.onLineValueChange(row, selectable)}
							render={(selectable: ISelectable) => selectable.display}
							sort={false}
							isSameWidth={true}
							className="select-input"
						/>
					</CardLayoutEditorFieldInputContainerStyed>
				</CardLayoutEditorFieldStyled>,
			);
		}

		return rows;
	}

	public override render() {
		return (
			<CardLayoutEditorContainerStyled>
				<CardLatoutEditorCardTitleContainerStyled>
					<CardLayoutEditorCardTitleStyled>Card - Display Text</CardLayoutEditorCardTitleStyled>
					<CardLayoutEditorCardSubtitleStyled>These fields will be shown in the tooltip by default</CardLayoutEditorCardSubtitleStyled>
				</CardLatoutEditorCardTitleContainerStyled>
				{this.getRows(["row 1", "row 2", "row 3"])}
				<CardLayoutEditorTooltipContainerStyled>
					<CardLayoutEditorTooltipTitleStyled>Tooltip - Display Text</CardLayoutEditorTooltipTitleStyled>
					<CardLayoutEditorTooltipSubtitleStyled>Optional fields</CardLayoutEditorTooltipSubtitleStyled>
				</CardLayoutEditorTooltipContainerStyled>
				{this.getRows(["hover row 1", "hover row 2", "hover row 3"])}
			</CardLayoutEditorContainerStyled>
		);
	}
}

const CardLayoutEditorContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const CardLatoutEditorCardTitleContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
`;

const CardLayoutEditorCardTitleStyled = styled.div`
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
`;

const CardLayoutEditorCardSubtitleStyled = styled.div`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7b7b7b;
`;

const CardLayoutEditorTooltipContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
`;

const CardLayoutEditorTooltipTitleStyled = styled.div`
	font-size: 14px;
	font-weight: 700;
	line-height: 16px;
`;

const CardLayoutEditorTooltipSubtitleStyled = styled.div`
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
	color: #7b7b7b;
`;

const CardLayoutEditorFieldStyled = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	.button {
		border: none;
		margin-right: 8px;
		display: none;
	}

	&:hover {
		.button {
			display: flex;
		}
	}
`;

const CardLayoutEditorFieldLabelStyled = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	color: #363636;
`;

const CardLayoutEditorFieldInputContainerStyed = styled.div`
	display: flex;
	.select-input {
		min-width: 176px;
	}
`;

import type {RefObject} from "react";
import {PopupV5} from "../../popup/PopupV5";
import {CreatePopupFieldStyled} from "../../popup/CreatePopupField.styled";
import {CreateSpacePanelV5} from "./CreateSpacePanelV5";

interface ISpaceCreatePopupV5Props {
	readonly onClose: () => void;
	readonly parentRef: RefObject<HTMLDivElement>;
}

export const SpaceCreatePopupV5 = (props: ISpaceCreatePopupV5Props) => {
	return (
		<PopupV5
			onClose={props.onClose}
			label="Create a space"
			parentRef={props.parentRef.current}
			className="SpaceCreation"
			width="60%"
			height="80vh"
			centerOnScreen={true}
			freezeRoot={true}
		>
			<CreatePopupFieldStyled>
				<CreateSpacePanelV5 onClose={props.onClose} />
			</CreatePopupFieldStyled>
		</PopupV5>
	);
};

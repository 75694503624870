import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../widgets/form/field/Field";
import {ClickToEditInput} from "../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {IFieldDataTypeSettingsProps} from "./IFieldDataTypeSettingsProps";

export interface IBooleanFieldSettingsDefinition {
	displayLabelForTrue: string;
	displayLabelForFalse: string;
}

interface BooleanFieldSettingsState {
	valueWhenFalse: string;
	valueWhenTrue: string;
	errorFalseInput: boolean;
	errorTrueInput: boolean;
}

@observer
export class BooleanFieldSettings extends React.Component<IFieldDataTypeSettingsProps, BooleanFieldSettingsState> {
	constructor(props: IFieldDataTypeSettingsProps) {
		super(props);
		this.state = {
			valueWhenFalse: (this.props.field.dataTypeSettings as IBooleanFieldSettingsDefinition).displayLabelForFalse,
			valueWhenTrue: (this.props.field.dataTypeSettings as IBooleanFieldSettingsDefinition).displayLabelForTrue,
			errorFalseInput: false,
			errorTrueInput: false,
		};
	}

	public static getDerivedStateFromProps(props: IFieldDataTypeSettingsProps, state: BooleanFieldSettingsState) {
		const propSettings = props.field.dataTypeSettings as IBooleanFieldSettingsDefinition;

		if (propSettings.displayLabelForFalse !== state.valueWhenFalse || propSettings.displayLabelForTrue !== state.valueWhenTrue) {
			return {
				valueWhenFalse: propSettings.displayLabelForFalse,
				valueWhenTrue: propSettings.displayLabelForTrue,
			};
		}
		return null;
	}

	private onEditWhenFalse = (value: string) => {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

		if (!StringUtils.equalsIgnoreCase(value, this.state.valueWhenTrue)) {
			settings.displayLabelForFalse = value;
			this.setState({
				errorFalseInput: false,
				errorTrueInput: false,
			});
			onChange();
		} else {
			settings.displayLabelForFalse = "";
			this.setState({
				errorFalseInput: true,
				errorTrueInput: false,
			});
		}

		this.setState({valueWhenFalse: value});
	};

	private onEditWhenTrue = (value: string) => {
		const {field, onChange} = this.props;
		const settings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

		if (!StringUtils.equalsIgnoreCase(value, this.state.valueWhenFalse)) {
			settings.displayLabelForTrue = value;
			this.setState({
				errorFalseInput: false,
				errorTrueInput: false,
			});
			onChange();
		} else {
			settings.displayLabelForTrue = "";
			this.setState({
				errorFalseInput: false,
				errorTrueInput: true,
			});
		}

		this.setState({valueWhenTrue: value});
	};

	private getErrorMessageForTrue = (value: any) => {
		return this.state.errorTrueInput ? "Value must be unique" : "";
	};

	private getErrorMessageForFalse = (value: any) => {
		return this.state.errorFalseInput ? "Value must be unique" : "";
	};

	public override render() {
		const {valueWhenFalse, valueWhenTrue} = this.state;

		return (
			<>
				<Field label="Value when true">
					<ClickToEditInput
						value={valueWhenTrue}
						onLiveChange={this.onEditWhenTrue}
						onChange={this.onEditWhenTrue}
						getErrorMessage={this.getErrorMessageForTrue}
						className="infoBubbleAlignLeft"
						noButtons={true}
					/>
				</Field>
				<Field label="Value when false">
					<ClickToEditInput
						value={valueWhenFalse}
						onLiveChange={this.onEditWhenFalse}
						onChange={this.onEditWhenFalse}
						getErrorMessage={this.getErrorMessageForFalse}
						className="infoBubbleAlignLeft"
						noButtons={true}
					/>
				</Field>
			</>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import {ToggleContainer} from "../../../../../../widgets/container/ToggleContainer";
import type {AppState} from "../../../../../../../data/state/AppState";
import type {IModel} from "../../../../../../../data/models/Model";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {FileUtils} from "../../../../../../../utils/file/FileUtils";
import {IconButton} from "../../../../../../widgets/button/IconButton";
import {ReactUtils} from "../../../../../../utils/ReactUtils";
import type {IContextOption} from "../../../../../../widgets/context/ContextOptions";
import {isImage, isOpenableInNewTab} from "../../../../../../../data/models/FileType";
import type {IDocumentExplorerProps} from "../../../../../document/explorer/DocumentExplorer";
import {DocumentExplorer} from "../../../../../document/explorer/DocumentExplorer";
import type {App} from "../../../../../../../App";
import {LoaderIcon} from "../../../../../../widgets/button/LoaderIcon";
import {SearchField} from "../../../../../../widgets/input/search/SearchField";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";
import type {DocumentModel} from "../../../../../../../data/models/DocumentModel";
import {DocumentCardView} from "./card/DocumentCardView";
import {DocumentListView} from "./list/DocumentListView";

interface IDocumentSectionProps {
	readonly items: IModel[];
	readonly feature: XyiconFeature;
	readonly app?: App;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly saveStateToLocalStorage?: boolean;
	readonly toggleContainerOpen?: boolean;
}

interface IDocumentSectionState {
	search: string;
	listView: boolean;
	uploadArray: IDocumentUpload[];
}

interface IDocumentUpload {
	count: number;
	id: string;
}

export interface DocumentSectionItem {
	document: DocumentModel;
	inherited: boolean;
	allowDelete: boolean;
}

@inject("app")
@inject("appState")
@inject("transport")
@observer
export class DocumentSection extends React.Component<IDocumentSectionProps, IDocumentSectionState> {
	private static readonly allowedFeatures = [
		XyiconFeature.Portfolio,
		XyiconFeature.Space,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
	];

	private _documentItems: DocumentSectionItem[] = [];

	constructor(props: IDocumentSectionProps) {
		super(props);
		this.state = {
			search: "",
			listView: this.readListView(),
			uploadArray: [],
		};
	}

	private readListView() {
		const {appState, transport} = this.props;
		const organizationId = appState.organizationId;

		if (organizationId) {
			return Boolean(transport.services.localStorage.get(this.getKey(organizationId)));
		}
		return false;
	}

	private saveListView(value: boolean) {
		const {appState, transport} = this.props;
		const organizationId = appState.organizationId;

		if (organizationId) {
			transport.services.localStorage.set(this.getKey(organizationId), value);
		}
	}

	private getKey(organizationId: string = "") {
		return `srv4-org-${organizationId}-documentsection-listview`;
	}

	private getOptions = (docItem: DocumentSectionItem): IContextOption[] => {
		const document = docItem.document;
		const isPreviewSupported = isImage(document.fileExtension);
		const isOpenableInNewTabSupported = isOpenableInNewTab(document.fileExtension);

		return [
			isPreviewSupported && {
				label: "Preview",
				onSelect: () => this.onPreviewClick(document),
			},
			isOpenableInNewTabSupported && {
				label: "Open in a new tab",
				onSelect: () => this.openInNewTab(document),
			},
			{
				label: "Download",
				onSelect: () => this.onDownloadClick(document),
			},
			docItem.allowDelete && {
				label: "Delete",
				onSelect: () => this.onDeleteClick(document),
			},
		].filter((option) => !!option);
	};

	private onPreviewClick = (document: DocumentModel) => {
		const {app} = this.props;

		app.popups.open(DocumentExplorer, {document, documents: this._documentItems.map((docItem) => docItem.document)} as IDocumentExplorerProps);
	};

	private openInNewTab = (document: DocumentModel) => {
		const path = this.props.transport.services.document.getFilePath(document);

		window.open(path);
	};

	private onDownloadClick = (document: DocumentModel) => {
		this.props.transport.services.document.download(document);
	};

	private onDeleteClick = async (document: DocumentModel) => {
		const {transport, feature} = this.props;
		await transport.services.document.deleteDocument(document, feature);
	};

	private onAddClicked = () => {
		const {items, transport} = this.props;
		const array = [...this.state.uploadArray];

		FileUtils.openFileDialogue(true, null, (fileList: FileList) => {
			{
				const element = array.find((el) => el.id === items[0].id);

				if (element) {
					element.count += fileList.length;
				} else {
					array.push({id: items[0].id, count: fileList.length});
				}
			}

			this.setState({uploadArray: array});

			for (let i = 0; i < fileList.length; ++i) {
				const file = fileList[i];

				transport.services.document.createDocument(file, items[0]).then(() => {
					array[array.findIndex((i) => i.id === items[0].id)].count -= 1;
					const newArray = [...array];

					this.setState({uploadArray: newArray});
				});
			}
		});
	};

	private onSearchChange = (value: string) => {
		this.setState({search: value});
	};

	private onListViewClicked = () => {
		this.setState({listView: true});
		this.saveListView(true);
	};

	private onCardViewClicked = () => {
		this.setState({listView: false});
		this.saveListView(false);
	};

	public override componentDidMount() {
		this.props.transport.services.feature.refreshList(XyiconFeature.PortfolioDocument);
		this.props.transport.services.feature.refreshList(XyiconFeature.OrganizationDocument);
	}

	private getDocuments() {
		const {items, appState} = this.props;
		const {search} = this.state;

		// TODO optimize in case there'll be lots of documents
		const results: DocumentSectionItem[] = [];

		const allDocuments = [
			...appState.actions.getList<DocumentModel>(XyiconFeature.PortfolioDocument),
			...appState.actions.getList<DocumentModel>(XyiconFeature.OrganizationDocument),
		];

		allDocuments.forEach((doc) => {
			if (search) {
				if (!StringUtils.containsIgnoreCase(doc.fileName, search)) {
					return;
				}
			}

			let inherited = false;
			let isAttached = false;

			let usageCount = 0;

			for (const item of items) {
				if (item.ownFeature === XyiconFeature.Xyicon) {
					if (doc.isAttachedToObject(item.catalogId)) {
						inherited = true;
						usageCount++;
					}
				}

				if (doc.isAttachedToObject(item.id)) {
					isAttached = true;
					usageCount++;
				}
			}

			if (isAttached) {
				inherited = false;
			}

			if (isAttached || inherited) {
				results.push({
					document: doc,
					// We only allow deleting a document if it belong to one of the selected items and
					// it doesn't belong to more than one of the selected items
					inherited: inherited,
					allowDelete: !inherited && usageCount < 2,
				});
			}
		});

		return results;
	}

	public override render() {
		const {items, feature, appState, toggleContainerOpen} = this.props;
		const {listView, search, uploadArray} = this.state;
		const isLoading = !appState.lists[feature].loaded;
		const count = uploadArray.find((i) => i.id === items[0].id)?.count || 0;

		if (!DocumentSection.allowedFeatures.includes(feature)) {
			return null;
		}

		this._documentItems = this.getDocuments();

		return (
			<ToggleContainer
				className="DocumentSection"
				title="Documents"
				onAddClicked={items.length === 1 ? this.onAddClicked : null}
				saveStateToLocalStorage={this.props.saveStateToLocalStorage}
				open={toggleContainerOpen}
			>
				{!isLoading ? (
					<>
						<div className="hbox">
							<SearchField
								value={search}
								placeholder={"Find ..."}
								onInput={this.onSearchChange}
							/>
							<IconButton
								icon="grid"
								className={ReactUtils.cls({selected: !!listView})}
								onClick={this.onListViewClicked}
							/>
							<IconButton
								icon="card"
								className={ReactUtils.cls({selected: !listView})}
								onClick={this.onCardViewClicked}
							/>
						</div>
						{listView ? (
							<DocumentListView
								documentItems={this._documentItems}
								getOptions={this.getOptions}
								uploadingArraySize={count}
							/>
						) : (
							<DocumentCardView
								documentItems={this._documentItems}
								getOptions={this.getOptions}
								onPreviewClick={this.onPreviewClick}
								uploadingArraySize={count}
							/>
						)}
					</>
				) : (
					<div className="loaderText vbox alignCenter justifyCenter">
						<LoaderIcon />
						{"Loading Documents..."}
					</div>
				)}
			</ToggleContainer>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import type {User} from "../../../../../data/models/User";
import type {PortfolioGroupPermission, PortfolioPermission} from "../../../../../generated/api/base";
import {ArrayUtils} from "../../../../../utils/data/array/ArrayUtils";
import {ButtonV5} from "../../../button/ButtonV5";
import CirclePlusIcon from "../../../icons/circle-plus.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {InfoButtonStyled} from "../../../button/InfoButtonV5";
import {PortfolioPermissionViewV5} from "./PortfolioPermissionViewV5";
import {PortfolioGroupPermissionViewV5} from "./PortfolioGroupPermissionViewV5";

const PortfolioPermissionsViewStyled = styled.div`
	.FieldSection {
		&.open {
			.children.open {
				padding-top: 0px;
				padding-left: 16px;
				padding-right: 0px;
				padding-bottom: 0px;
			}
		}

		h4 {
			text-transform: capitalize;
			font-size: 14px;
			color: ${colorPalette.gray.c950};
			margin-left: 16px;

			.label {
				display: flex;
				line-height: 16px;

				svg {
					width: 16px;
					height: 16px;
					transform: translate(0, -3px);
				}

				svg path {
					stroke: ${colorPalette.blueGray.c500Primary};
				}

				${InfoButtonStyled} {
					margin-left: 4px;
					margin-top: 0px;
					margin-bottom: 0px;
					margin-right: 0px;
				}
			}
		}
	}

	.permission-title {
		display: flex;

		.label {
			text-transform: capitalize;
			font-size: 14px;
			color: ${colorPalette.gray.c950};
			margin-right: 8px;
		}

		svg {
			width: 16px;
			height: 16px;
			stroke: ${colorPalette.blueGray.c500Primary};
		}

		svg path {
			stroke: ${colorPalette.blueGray.c500Primary};
		}
	}

	.addButtonContainer {
		width: 100%;
		display: flex;
		justify-content: end;
	}

	.addButton {
		margin-top: 8px;
		right: 0px;
		width: fit-content;
		background-color: ${colorPalette.white};
		color: ${colorPalette.blueGray.c500Primary};
		border: 1px solid ${colorPalette.blueGray.c500Primary};
	}

	.addButton:hover {
		background-color: ${colorPalette.primary.c100};
	}
`;

interface IPortfolioPermissionsViewProps {
	readonly user: User;
	readonly onChange?: () => void;
}

@observer
export class PortfolioPermissionsViewV5 extends React.Component<IPortfolioPermissionsViewProps> {
	private onAddIndividualPortfolioPermission = () => {
		const p: PortfolioPermission = {
			portfolioPermissionSetID: "",
			portfolioID: "",
		};

		this.props.user.portfolioPermissionList.push(p);
	};

	private onAddPortfolioGroupPermission = () => {
		const p: PortfolioGroupPermission = {
			portfolioPermissionSetID: "",
			portfolioGroupID: "",
		};

		this.props.user.portfolioGroupPermissionList.push(p);
	};

	private onRemovePortfolioPermission = (permission: PortfolioPermission) => {
		const {user} = this.props;

		ArrayUtils.removeMutable(user.portfolioPermissionList, permission);

		this.props.onChange?.();
	};

	private onRemovePortfolioGroupPermission = (permission: PortfolioGroupPermission) => {
		const {user} = this.props;

		ArrayUtils.removeMutable(user.portfolioGroupPermissionList, permission);

		this.props.onChange?.();
	};

	private onChangePermissionView = () => {
		this.props.onChange?.();
	};

	public override render() {
		const {user} = this.props;

		const individuals = user.portfolioPermissionList;
		const groups = user.portfolioGroupPermissionList;

		const addedPortfolioIds = individuals.map((ps) => ps.portfolioID);
		const addedGroupIds = groups.map((ps) => ps.portfolioGroupID);

		return (
			<>
				<PortfolioPermissionsViewStyled>
					<ToggleContainerV5
						title="Individual Portfolios"
						open={true}
						className="FieldSection"
						helperText="Permissions of an individual portfolio take priority over permissions for portfolio groups. The first permission set on the list has the highest priority and priority reduces for all subsequent permission sets."
					>
						{individuals.map((p, i) => (
							<PortfolioPermissionViewV5
								key={i}
								permission={p}
								exceptions={addedPortfolioIds}
								onRemove={this.onRemovePortfolioPermission}
								onChange={this.onChangePermissionView}
							/>
						))}
						<div className="addButtonContainer">
							<ButtonV5
								label="ADD"
								onClick={this.onAddIndividualPortfolioPermission}
								className="addButton"
							>
								<CirclePlusIcon />
							</ButtonV5>
						</div>
					</ToggleContainerV5>

					<ToggleContainerV5
						title="Portfolio Group"
						open={true}
						className="FieldSection"
						helperText="Permissions of an individual portfolio take priority over permissions for portfolio groups. The first permission set on the list has the highest priority and priority reduces for all subsequent permission sets."
					>
						{groups.map((p, i) => (
							<PortfolioGroupPermissionViewV5
								key={i}
								permission={p}
								exceptions={addedGroupIds}
								onRemove={this.onRemovePortfolioGroupPermission}
								onChange={this.onChangePermissionView}
							/>
						))}
						<div className="addButtonContainer">
							<ButtonV5
								label="ADD"
								onClick={this.onAddPortfolioGroupPermission}
								className="addButton"
							>
								<CirclePlusIcon />
							</ButtonV5>
						</div>
					</ToggleContainerV5>
				</PortfolioPermissionsViewStyled>
			</>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {WarningWindow} from "../../../../abstract/popups/WarningWindow";
import type {IContextOption} from "../../../../../widgets/context/ContextOptions";
import {PopupUtils} from "../../../../abstract/popups/PopupUtils";
import {Functions} from "../../../../../../utils/function/Functions";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {XyiconFeature, Permission} from "../../../../../../generated/api/base";
import {SpaceItem} from "./SpaceItem";
import type {ISpaceItemProps} from "./SpaceItem";
import {SpaceItemActionButtons} from "./SpaceItemActionButtons";

export interface ISpaceItemContainerProps extends ISpaceItemProps {
	readonly showInfoButton: boolean;
	readonly showDeleteButton: boolean;
	readonly queryString: string;
	readonly className?: string;
	readonly options?: IContextOption[];
	readonly onBreakLinkClick?: () => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyiconClick: (catalog: Catalog) => void;
}

@inject("app")
@inject("appState")
@observer
export class SpaceItemContainer extends React.Component<ISpaceItemContainerProps> {
	public static readonly defaultProps: Partial<ISpaceItemContainerProps> = {
		showInfoButton: false,
		showDeleteButton: false,
		onDuplicateCatalogClick: Functions.emptyFunction,
		onBreakLinkClick: Functions.emptyFunction,
		onCreateUnplottedXyiconClick: Functions.emptyFunction,
		onPointerDown: Functions.emptyFunction,
		onPointerMove: Functions.emptyFunction,
		onPointerUp: Functions.emptyFunction,
	};

	private get userXyiconPermission() {
		const {appState, item} = this.props;

		return appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);
	}

	private onDuplicateCatalogClick = () => {
		const {onDuplicateCatalogClick, item} = this.props;

		onDuplicateCatalogClick?.(item as Catalog);
	};

	private deleteItem = async () => {
		const {item} = this.props;

		const count = this.props.appState.actions.getNumberOfModels([item]);
		const confirmed = await PopupUtils.getDeleteConfirmationPopup(item.ownFeature, count);

		if (confirmed) {
			await this.props.appState.actions.deleteSpaceItem(item);
		}
	};

	private onDeleteCatalogClick = async () => {
		const {app, item} = this.props;

		const isSafeToDeleteResponse = await app.transport.requestForOrganization({
			url: "xyiconcatalogs/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				xyiconCatalogIDList: [item.id],
			},
		});

		if (isSafeToDeleteResponse.result?.inUseList.length === 1) {
			await WarningWindow.open("You can't delete this item, because it's already used as xyicons.");
		} else {
			await this.deleteItem();
		}
	};

	private onDetailsClick = () => {
		const {item} = this.props;

		if (item.ownFeature !== XyiconFeature.XyiconCatalog) {
			this.props.app.onDetailsClick(item);
		}
	};

	private onCreateUnplottedXyiconClick = () => {
		this.props.onCreateUnplottedXyiconClick(this.props.item as Catalog);
	};

	private onFavoriteClick = () => {
		return this.setFavorite(true);
	};

	private onUnFavoriteClick = () => {
		return this.setFavorite(false);
	};

	private setFavorite(value: boolean) {
		return (this.props.item as Catalog).setFavorite(value);
	}

	private get hasSpaceItemPermission() {
		return this.props.item.ownFeature === XyiconFeature.Boundary || this.userXyiconPermission > Permission.View;
	}

	public override render() {
		const {item, className, options} = this.props;

		const isExternalXyicon = item.ownFeature === XyiconFeature.Xyicon && item.isExternal;
		const isUnplottedXyicon = item.ownFeature === XyiconFeature.Xyicon && item.isUnplotted;

		return (
			<div
				className={ReactUtils.cls(`SpaceItemContainer hbox alignCenter ${className || ""}`, {
					grabbable:
						!isExternalXyicon && this.props.onPointerDown && this.props.onPointerDown !== Functions.emptyFunction && this.hasSpaceItemPermission,
					noPermission: !this.hasSpaceItemPermission,
				})}
			>
				<SpaceItem
					item={item}
					queryString={this.props.queryString}
					onPointerDown={this.props.onPointerDown}
					onPointerMove={this.props.onPointerMove}
					onPointerUp={this.props.onPointerUp}
				/>
				<SpaceItemActionButtons
					appState={this.props.appState}
					deleteItem={this.deleteItem}
					catalog={this.props.item ? (this.props.item as Catalog) : undefined}
					feature={this.props.item.ownFeature}
					isCrossPortfolioXyicon={false}
					isFavorite={(this.props.item as Catalog).isFavorite}
					itemTypeId={this.props.item.typeId}
					onBreakLinkClick={this.props.onBreakLinkClick}
					onCreateUnplottedXyiconClick={this.onCreateUnplottedXyiconClick}
					onDeleteCatalogClick={this.onDeleteCatalogClick}
					onDetailsClick={this.onDetailsClick}
					onDuplicateCatalogClick={this.onDuplicateCatalogClick}
					onFavoriteClick={this.onFavoriteClick}
					onUnFavoriteClick={this.onUnFavoriteClick}
					showDeleteButton={this.props.showDeleteButton}
					showInfoButton={this.props.showInfoButton}
					options={options}
				/>
			</div>
		);
	}
}

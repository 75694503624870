import React, {useCallback, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {DateFormatter} from "../../../../utils/format/DateFormatter";
import {DateUtils} from "../../../../utils/DateUtils";
import {FieldDataType} from "../../../../generated/api/base";
import {IDateFieldFormat} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import {HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {AppUtils} from "../../../../utils/AppUtils";
import {ClickToEditInputStyled, ClickToEditInputV5} from "../clicktoedit/ClickToEditInputV5";
import {IconButtonStyled, IconButtonV5} from "../../interaction/IconButtonV5";
import CalendarIcon from "../../icons/calendar.svg?react";
import ClockThreeIcon from "../../icons/clock-three.svg?react";
import XmarkIcon from "../../icons/xmark.svg?react";
import {radius} from "../../styles/styles";
import {colorPalette} from "../../styles/colorPalette";
import {PopupV5} from "../../popup/PopupV5";
import {OptionStyled} from "../select/SelectInputV5";
import {TimePickerV5} from "./TimePickerV5";
import {DatePickerV5} from "./DatePickerV5";

interface DateTimeInputStyledProps {
	$inline: boolean;
}

interface pickersContainerStyledProps {
	format: IDateTimeInputFormat;
}

const InputIconsStyled = styled.div<{$inline: boolean; $hasValue: boolean}>`
	${(props) => {
		if (!props.$inline || props.$hasValue) {
			return css`
				position: absolute;
				right: 0;
				display: flex;
				gap: 8px;

				.IconButton {
					&:hover {
						background-color: ${props.$hasValue && props.$inline ? "transparent" : colorPalette.gray.c200Light};
					}
				}
			`;
		} else {
			return css`
				position: absolute;
				right: 0;
				left: 0;

				.IconButton {
					border: none;

					&:hover {
						background-color: transparent;
					}
				}
			`;
		}
	}}

	.IconButton {
		border-radius: ${radius.sm};

		svg {
			width: 16px;
			height: 16px;
			color: ${colorPalette.gray.c950};
		}

		&:hover {
			border: 2px solid ${colorPalette.gray.c950};
		}
	}
`;

export const DateTimeInputStyled = styled.div<DateTimeInputStyledProps>`
	position: relative;
	justify-content: space-between;
	width: 100%;
	height: ${({$inline}) => ($inline ? "28px" : "32px")};
	align-items: center;

	${InputIconsStyled} {
		visibility: hidden;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		${InputIconsStyled} {
			visibility: visible;

			${(props) => {
				if (!props.$inline) {
					return css`
						${IconButtonStyled} {
							background-color: ${colorPalette.gray.c200Light};
							height: 30px;

							&.cancel {
								width: 31px;
								right: 1px;
							}
						}
					`;
				}
			}}
		}
	}

	.unfocused {
		font-size: 14px;
		font-weight: ${({$inline}) => ($inline ? "400" : "300")};
		color: ${colorPalette.gray.c950};

		&.disabled {
			color: ${colorPalette.libraryColors.explosive};
		}
	}

	.dateLabel {
		min-height: 17px;
	}

	input {
		border: none;
		height: 32px;

		&:focus {
			background: transparent;
		}
	}

	${(props) => {
		if (props.$inline) {
			return css`
				${ClickToEditInputStyled} {
					.unfocused {
						height: 18px;
						min-height: 18px;
						border: none;
						padding: 0;
						text-align: center;
						position: relative;
						left: 4px;
						display: flex;

						&:hover {
							background-color: transparent;
						}

						.SingleLineLabel {
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
				}
			`;
		}
	}}
`;

const PickersContainerStyled = styled.div<pickersContainerStyledProps>`
	padding: 16px 0;

	.unfocused {
		box-sizing: border-box;
		padding: 10px;
	}

	.input {
		box-sizing: border-box;
		position: relative;
		padding-right: 30px;
		max-width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 300;
		font-size: 14px;
	}

	&.datetime,
	&.daterange {
		min-width: 375px;
	}

	&.date,
	&.time,
	&.timerange {
		min-width: 305px;
	}

	&.time {
		min-width: 250px;

		.pickerinputs {
			.title {
				width: 40px;
			}

			.row {
				.ClickToEditInputV5 {
					width: 100%;
				}
			}
		}
	}

	.title {
		//min-width: 100px;
		font-weight: 300;
	}
`;

const DateTimeInputPopupStyled = styled(PopupV5)`
	padding: 16px;
	margin: 0;

	.header {
		height: 32px;
		padding: 0;

		.name {
			margin: 0;
		}

		.cancel {
			margin: 0;
		}
	}

	.body {
		padding: 0;
		gap: 0;
	}
`;

const LineStyled = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colorPalette.gray.c300};
	margin: 16px 0;
`;

interface IDateInputV5Props {
	readonly value: string;
	readonly format: IDateTimeInputFormat;
	readonly disabled?: boolean;
	readonly valueToDate?: string;
	readonly onFocusLossForceBlur?: boolean;
	readonly focused?: boolean;
	readonly onChange: (value: string) => void;
	readonly onClick?: () => void;
	readonly onFocus?: (value: boolean) => void;
	readonly inline?: boolean;
}

type IDateTimeInputFormat = "" | "date" | "time" | "datetime" | "daterange" | "timerange";

export const DateTimeInputV5 = (props: IDateInputV5Props) => {
	const {onClick, focused, onFocus, format, disabled, inline, onFocusLossForceBlur} = props;

	const _container = useRef<HTMLDivElement>();
	const [isOpen, setIsOpen] = useState<boolean>(focused);
	const [value, setValue] = useState<string>(props.value);
	const [valueToDate, setValueToDate] = useState<string>("");
	const [includeTime, setIncludeTime] = useState<boolean>(false);
	const [isToDateActive, setIsToDateActive] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>("");
	const [recalculateFloatingPositionCount, setRecalculateFloatingPositionCount] = useState<number>(0);

	const closePicker = useCallback(() => {
		AppUtils.disableScrolling(false);

		setIsOpen(false);
		setErrorMessage("");
		onFocus?.(false);
	}, [onFocus]);

	const onFocusLoss = useCallback(
		(event: Event) => {
			let eventTargetInFloating = false; // True if target is within the floating (Popup) element
			let eventTargetIsOption = false; // True if target is a SelectInput's option

			if (event.target instanceof Element) {
				eventTargetIsOption = event.target.classList.contains(`${OptionStyled}`.replace(".", "")); // remove first .
			}

			if ((event && !eventTargetInFloating && !eventTargetIsOption) || onFocusLossForceBlur) {
				closePicker();
				AppUtils.disableScrolling(false);
				setIsOpen(false);
				setErrorMessage("");
				FocusLoss.stopListen(_container.current, onFocusLoss);
			} else {
				FocusLoss.listen(_container.current, onFocusLoss);
			}
		},
		[closePicker, onFocusLossForceBlur],
	);

	const onTogglePicker = useCallback(
		async (e?: React.MouseEvent) => {
			e?.stopPropagation();
			onClick?.();

			AppUtils.disableScrolling(true);

			setIsOpen(!isOpen);

			if (!isOpen) {
				await TimeUtils.wait(100);
				FocusLoss.listen(_container.current, onFocusLoss);
				setErrorMessage("");
			}
		},
		[isOpen, onClick, onFocusLoss],
	);

	const onInput = (value: string) => {
		if (isToDateActive) {
			setValueToDate(value);
		} else {
			setValue(value);
		}
	};

	const onClearClicked = () => {
		setValue("");
		setValueToDate("");

		props.onChange(null);
	};

	const onConfirmClicked = () => {
		const {format, onChange} = props;
		const from = DateUtils.parse(value);
		let date = "";

		if (format === "daterange" || format === "timerange") {
			const toDate = DateUtils.parse(valueToDate);
			const dateRange = {
				from: from,
				to: toDate,
			};

			date = DateUtils.stringifyDateRange(dateRange);
		} else {
			date = DateUtils.stringify(from);
		}

		onChange(date);
		closePicker();
	};

	const hideErrorMessage = () => {
		setErrorMessage("");
	};

	const validateDateInput = (value: string, format: IDateTimeInputFormat) => {
		const {onChange} = props;
		let inputValue = value;

		if (inputValue === "") {
			onChange(null);
			onTogglePicker();

			return;
		}

		// It's needed because of time only input validation. Eg.: "10:20" would be invalid without date data.
		const placeholderDate = "2021.01.01";

		if (format === "time") {
			inputValue = `${placeholderDate} ${value}`;
		} else if (format === "daterange" || format === "timerange") {
			const value = DateUtils.parseDateRange(inputValue);
			let from = value.from;
			let to = value.to;

			if (format === "timerange") {
				from = `${placeholderDate} ${from}`;
				to = `${placeholderDate} ${to}`;
			}

			if (DateUtils.dateValidation(from) && DateUtils.dateValidation(to)) {
				setValue(from);
				setValueToDate(to);
				onChange(inputValue);
			} else {
				FocusLoss.listen(_container.current, hideErrorMessage);
				setErrorMessage("Invalid format");
			}

			onTogglePicker();
			return;
		}

		if (DateUtils.dateValidation(inputValue)) {
			onChange(inputValue);
		} else {
			FocusLoss.listen(_container.current, hideErrorMessage);
			setErrorMessage("Invalid format");
		}

		onTogglePicker();
	};

	const getDisplayedDate = () => {
		const {format, value, valueToDate} = props;

		if (format === "daterange" || format === "timerange") {
			//const rangeFormat = format === "daterange" ? "date" : "time";

			let rangeFormat = IDateFieldFormat.DATE;

			if (format === "daterange" && includeTime) {
				rangeFormat = IDateFieldFormat.DATETIME;
			} else if (format === "timerange") {
				rangeFormat = IDateFieldFormat.TIME;
			}

			if (value === "" || valueToDate === "") {
				return "";
			}

			return `${DateFormatter.format(value, rangeFormat)} - ${DateFormatter.format(valueToDate, rangeFormat)}`;
		} else {
			return DateFormatter.format(value, format);
		}
	};

	useEffect(() => {
		if (focused) {
			setIsOpen(true);
		} else {
			setIsOpen(false);
		}
	}, [focused]);

	return (
		<DateTimeInputStyled
			ref={_container}
			className="DateTimeInput hbox"
			$inline={inline}
		>
			<ClickToEditInputV5
				value={getDisplayedDate()}
				onChange={(value) => validateDateInput(value, format)}
				dataTypeSettings={FieldDataType.DateTime}
				disabled={disabled}
				getErrorMessage={() => errorMessage}
				onClick={onTogglePicker}
				focused={focused}
				inline={inline}
			/>
			{!disabled && (
				<InputIconsStyled
					$inline={inline}
					$hasValue={!!value}
				>
					{(!inline || !value) && (
						<IconButtonV5
							IconComponent={format === "time" ? ClockThreeIcon : CalendarIcon}
							onClick={onTogglePicker}
						/>
					)}
					{(!inline || value) && (
						<IconButtonV5
							IconComponent={XmarkIcon}
							onClick={onClearClicked}
							className="cancel"
						/>
					)}
				</InputIconsStyled>
			)}
			{isOpen && (
				<DateTimeInputPopupStyled
					parentRef={_container.current}
					label={format === "time" || format === "timerange" ? "Time" : "Calendar"}
					onClose={closePicker}
					buttonProps={{
						hideIcon: true,
						label: "Confirm",
						onClick: onConfirmClicked,
					}}
					isSmallPopup={true}
					verticalAlignment={VerticalAlignment.bottomOuter}
					horizontalAlignment={HorizontalAlignment.right}
					offsetY={10}
					versionNumber={recalculateFloatingPositionCount}
				>
					<PickersContainerStyled
						className="pickersContainer"
						format={format}
					>
						{(format === "date" || format === "datetime") && (
							<DatePickerV5
								value={isToDateActive ? valueToDate : value}
								onChange={onInput}
								recalculatePosition={() => setRecalculateFloatingPositionCount(recalculateFloatingPositionCount + 1)}
							/>
						)}
						{format === "datetime" && <LineStyled />}
						{(format === "time" || format === "datetime") && (
							<TimePickerV5
								value={DateUtils.parse(isToDateActive ? valueToDate : value)}
								onChange={onInput}
								format={format}
							/>
						)}
					</PickersContainerStyled>
				</DateTimeInputPopupStyled>
			)}
		</DateTimeInputStyled>
	);
};

import * as React from "react";
import {markupTypeToMarkupToolName} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {MarkupType} from "../../../../../../generated/api/base";
import {MarkupsWithCustomText} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import {ReactUtils} from "../../../../../utils/ReactUtils";

interface IMarkupTypeOptionsProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly activeType: MarkupType;
	readonly onChange: (newType: MarkupType) => void;
}

export const MarkupTypeOptions = (props: IMarkupTypeOptionsProps) => {
	const typeOptions = [...MarkupsWithCustomText];

	const buttons = [];

	for (const typeOption of typeOptions) {
		const tools = props.spaceViewRenderer.tools;
		const toolName = markupTypeToMarkupToolName(typeOption);
		const tool = tools[toolName];

		buttons.push(
			<IconButton
				className={ReactUtils.cls("markupTypeOption", {active: typeOption === props.activeType})}
				key={toolName}
				icon={tool.icon}
				onClick={() => props.onChange(typeOption)}
				title={`${MarkupType[typeOption].replaceAll("_", " ")} Markup`}
			/>,
		);
	}

	return <div className="MarkupTypeOptions">{buttons}</div>;
};

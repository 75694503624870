import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {User} from "../../../../../data/models/User";
import type {AppState} from "../../../../../data/state/AppState";
import type {TransformObj} from "../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {ISwitchListChange} from "../../../../widgets/button/switch/SwitchList";
import {type ISelectSliderOption} from "../../../../widgets/input/selectslider/SelectSlider";
import type {UserDto} from "../../../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import type {UserGroup} from "../../../../../data/models/UserGroup";
import {ToggleSwitchFieldV5} from "../../../details/ToggleSwitchFieldV5";
import {SelectSliderV5} from "../../../details/SelectSliderV5";
import {ToggleContainerStyled, ToggleContainerV5} from "../../../widgets/ToggleContainerV5/ToggleContainerV5";
import {InfoBubbleV5} from "../../../button/InfoBubbleV5";
import {FieldV5} from "../../../details/FieldV5";
import {SwitchListV5} from "../../../details/SwitchListV5";
import {colorPalette} from "../../../styles/colorPalette";
import {PortfolioPermissionsViewV5} from "./PortfolioPermissionsViewV5";

const UserFormStyled = styled.div`
	padding-left: 16px;
	padding-right: 16px;

	.ToggleSwitchField {
		padding-top: 0;

		.toggleSwitchLabel {
			display: none;
		}
	}

	.PermissionOptions {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.SelectSlider .sliders .sliderRow {
		padding-left: 0px;
		padding-top: 0px;

		&:nth-child(2n + 1) {
			background-color: ${colorPalette.white};
		}

		.sliderName {
			font-size: 14px;
			text-decoration-line: underline;
			text-underline-position: under;
		}
	}

	.line {
		border-top: 2px solid ${colorPalette.gray.c300};
		margin-bottom: 16px;
	}

	${ToggleContainerStyled} {
		.children.open {
			padding-left: 0;
			padding-right: 0;
		}
	}
`;

interface IUserFormProps {
	readonly users: User[];
	readonly onChangeUserGroup?: (changes: ISwitchListChange[]) => void;
	readonly assignedUserGroups?: string[];
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly hideSystemUserGroups?: boolean;
}

interface IUserFormState {
	toolTipTransform: TransformObj;
	isToolTipOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class UserFormV5 extends React.Component<IUserFormProps, IUserFormState> {
	private _modalContainer = this.props.appState.app.modalContainer;
	private _parent = React.createRef<HTMLDivElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _isMounted: boolean = false;
	private _timeOutId: number = null;
	private _options: ISelectSliderOption[] = [
		{
			id: Permission.View,
			label: "View",
		},
		{
			id: Permission.Update,
			label: "Update",
		},
		{
			id: Permission.Delete,
			label: "Delete",
		},
	];

	constructor(props: IUserFormProps) {
		super(props);
		this.state = {
			toolTipTransform: null,
			isToolTipOpen: false,
		};
	}

	private onChangePermission = (rowKeys: string[], id: Permission) => {
		const user = this.props.users[0];

		if (rowKeys.includes("Catalog Module")) {
			user.setOrganizationPermission(XyiconFeature.XyiconCatalog, id);
		}

		this.updateApi();
	};

	private onChangeAdmin = async (value: boolean) => {
		const user = this.props.users[0];

		user.isAdmin = value;

		await this.updateApi();

		if (this._isMounted) {
			this.forceUpdate();
		}
	};

	private onChangeUserGroup = (changes: ISwitchListChange[]) => {
		const user = this.props.users[0];

		for (const change of changes) {
			const userGroup = this.props.appState.actions.getFeatureItemById<UserGroup>(change.id, XyiconFeature.UserGroup);

			if (userGroup) {
				if (change.value) {
					userGroup.addUser(user.id, this.props.appState);
				} else {
					userGroup.removeUser(user.id, this.props.appState);
				}
			}
		}

		this.updateApi();
	};

	private onPortfolioPermissionsChange = () => {
		return this.updateApi();
	};

	private async updateApi() {
		const user = this.props.users[0];
		const {appState, transport} = this.props;

		if (user.id) {
			// we're in edit mode
			user.updatePermissions();
			const res = await transport.services.feature.update(user.id, XyiconFeature.User, user.getUpdateData());

			const newUserGroupIds = (res as UserDto)?.userGroupIDList || [];
			const oldUserGroupIds = [...user.userGroupIds]; // clone this, because the function below can modify the array directly

			user.addOrRemoveUserFromUserGroups(oldUserGroupIds, "remove", appState);
			user.addOrRemoveUserFromUserGroups(newUserGroupIds, "add", appState);
		}
	}

	private openTooltip = () => {
		if (this._timeOutId) {
			clearTimeout(this._timeOutId);
		}

		this._timeOutId = window.setTimeout(() => {
			if (this._isMounted) {
				this.setState({
					isToolTipOpen: true,
				});
			}
		}, 1000);
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	public override componentDidMount(): void {
		this._isMounted = true;
	}

	public override componentDidUpdate = (prevProps: IUserFormProps, prevState: IUserFormState) => {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parent.current && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parent.current,
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	};

	public override componentWillUnmount(): void {
		this._isMounted = false;
	}

	public override render() {
		const {users, appState, assignedUserGroups, onChangeUserGroup, hideSystemUserGroups} = this.props;
		const {isToolTipOpen, toolTipTransform} = this.state;
		const user = users[0];
		const disabled = appState.user === user;
		if (!user) {
			return null;
		}

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		const userGroupList = appState.actions.getList<UserGroup>(XyiconFeature.UserGroup);
		const filteredUserGroupList = userGroupList.filter((userGroup) => !(hideSystemUserGroups && userGroup.data.isSystem));
		const switchList = filteredUserGroupList.map((userGroup) => ({
			id: userGroup.id,
			label: userGroup.name,
			value: assignedUserGroups ? assignedUserGroups.includes(userGroup.id) : userGroup.userIds.includes(user.id),
			disabled: userGroup.data.isSystem,
		}));

		return (
			<>
				<UserFormStyled>
					<>
						<FieldV5 label="Organization Admin">
							<ToggleSwitchFieldV5
								divRef={this._parent}
								label=""
								value={user.isAdmin}
								onChange={this.onChangeAdmin}
								disabled={disabled}
								onMouseLeave={disabled && this.closeTooltip}
								onMouseOver={disabled && this.openTooltip}
							/>
						</FieldV5>

						<SelectSliderV5
							options={this._options}
							rows={[
								{
									label: "Catalog Module",
									value: user.getOrganizationPermission(XyiconFeature.XyiconCatalog),
								},
							]}
							onChange={this.onChangePermission}
							disabled={user?.isAdmin}
						/>

						<hr className="line" />
					</>
					<ToggleContainerV5
						title="Portfolio Permissions"
						open={true}
					>
						{user.isAdmin ? (
							<p>An “organization administrator” will have the full access to all portfolios in this organization.</p>
						) : (
							<PortfolioPermissionsViewV5
								user={user}
								onChange={this.onPortfolioPermissionsChange}
							/>
						)}
					</ToggleContainerV5>
					<ToggleContainerV5
						title="User Groups"
						open={true}
					>
						<SwitchListV5
							list={switchList}
							onChange={onChangeUserGroup || this.onChangeUserGroup}
						/>
					</ToggleContainerV5>
					{isToolTipOpen && disabled && (
						<InfoBubbleV5
							className="UserForm"
							content="You cannot remove yourself as an organization administrator."
							style={inlineStyle}
							divRef={this._floating}
							hide={false}
						/>
					)}
				</UserFormStyled>
			</>
		);
	}
}

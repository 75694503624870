import type {Color} from "../../../generated/api/base";
import {BasicColors} from "../../../utils/BasicColors";
import {ColorPaletteV5} from "./ColorPaletteV5";

interface IBasicColorPanelProps {
	readonly color: Color;
	readonly onColorChange: (newColor: Color) => void;
}

export const BasicColorPanelV5 = (props: IBasicColorPanelProps) => {
	const {color} = props;

	const colorsOfPalette: Color[] = BasicColors.getBasicColorsV5();

	return (
		<ColorPaletteV5
			activeColor={color}
			colors={colorsOfPalette}
			onColorClick={props.onColorChange}
		/>
	);
};

import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import {SVGIcon} from "./SVGIcon";

export interface IToggleButtonProps {
	readonly label?: string;
	readonly icon?: string;
	readonly title?: string;
	readonly value: boolean;
	readonly disabled?: boolean;
	readonly className?: string;
	readonly render?: (props: IToggleButtonProps, state: IToggleButtonState, onClick: () => void) => React.ReactNode;
	readonly onChange: () => void;
}

interface IToggleButtonState {
	value: boolean;
}

export class ToggleButton extends React.Component<IToggleButtonProps, IToggleButtonState> {
	public static readonly defaultProps: Partial<IToggleButtonProps> = {
		className: "",
		disabled: false,
	};

	public static getDerivedStateFromProps(props: IToggleButtonProps, state: IToggleButtonState) {
		return {
			value: props.value,
		} as Partial<IToggleButtonState>;
	}

	constructor(props: IToggleButtonProps) {
		super(props);

		this.state = {
			value: this.props.value,
		};
	}

	private onClick = () => {
		this.props.onChange && this.props.onChange();
	};

	public override render() {
		if (this.props.render) {
			return this.props.render(this.props, this.state, this.onClick);
		} else {
			const cls = ReactUtils.cls(this.props.className, {
				button: true,
				selected: this.state.value,
				disabled: this.props.disabled,
			});

			return (
				<div
					className={cls}
					title={this.props.title}
					onClick={this.onClick}
				>
					{this.props.icon && (
						<SVGIcon
							icon={this.props.icon}
							classNames={this.props.icon}
						/>
					)}
					<div className="tabLabel">{this.props.label}</div>
				</div>
			);
		}
	}
}

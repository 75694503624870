import {HorizontalAlignment} from "../../../../../utils/dom/DomUtils";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {ColorRuleRowStyled} from "./ColorRuleRow.styled";

interface ColorRuleRowProps {
	readonly label: string;
	readonly options: string[];
	readonly onChangeIndicator: (fieldName: string) => void;
	readonly selectedIndicator: string;
	readonly onChangeHighlight: (fieldName: string) => void;
	readonly selectedHighlight: string;
	readonly render?: (item: any) => React.ReactNode;
}

export const ColorRuleRowV5 = (props: ColorRuleRowProps) => {
	const {render, label, options, onChangeIndicator, onChangeHighlight, selectedIndicator, selectedHighlight} = props;

	const indicatorProps = {sort: false, options, render, onChange: onChangeIndicator, selected: selectedIndicator};
	const highlightProps = {sort: false, options, render, onChange: onChangeHighlight, selected: selectedHighlight};

	return (
		<ColorRuleRowStyled className="ColorRuleRow hbox">
			<div
				className="label"
				title={label}
			>
				{label}
			</div>
			<>
				<SelectInputV5
					{...indicatorProps}
					className="SelectInput"
					isFixedWidth={true}
				/>
				<SelectInputV5
					{...highlightProps}
					className="SelectInput"
					isFixedWidth={true}
					horizontalAlignment={HorizontalAlignment.right}
				/>
			</>
		</ColorRuleRowStyled>
	);
};

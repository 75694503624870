import {Observer} from "mobx-react";
import {useAppStore} from "../../../StateManager";
import type {Catalog} from "../../../data/models/Catalog";
import {XyiconFeature} from "../../../generated/api/base";
import {Functions} from "../../../utils/function/Functions";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import type {ISpaceItemProps} from "../../modules/space/spaceeditor/ui/toolbar/SpaceItem";
import {SpaceItemActionButtonsV5} from "../toolbar/SpaceItemActionButtonsV5";
import {PopupUtilsV5} from "../popup/PopupUtilsV5";
import type {IDropdownOption} from "../interaction/DropdownOptionsV5";
import {WarningWindowV5} from "../popup/WarningWindowV5";
import {ReactUtils} from "../../utils/ReactUtils";
import {SpaceItemV5} from "./SpaceItemV5";
import {SpaceItemContainerStyled} from "./SpaceItemContainer.Style";

interface ISpaceItemContainerProps extends ISpaceItemProps {
	readonly isSelected?: boolean;
	readonly showInfoButton?: boolean;
	readonly showDeleteButton?: boolean;
	readonly queryString: string;
	readonly options?: IDropdownOption[];
	readonly hideDragHandle?: boolean;
	readonly onBreakLinkClick?: () => void;
	readonly onDuplicateCatalogClick?: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyiconClick?: (catalog: Catalog) => void;
}

export const SpaceItemContainerV5 = (props: ISpaceItemContainerProps) => {
	const appState = useAppStore((state) => state.appState);
	const {
		isSelected = false,
		item,
		options = [],
		queryString = "",
		showDeleteButton = false,
		showInfoButton = false,
		hideDragHandle,
		onDuplicateCatalogClick = Functions.emptyFunction,
		onBreakLinkClick = Functions.emptyFunction,
		onPointerDown = Functions.emptyFunction,
		onPointerMove = Functions.emptyFunction,
		onPointerUp = Functions.emptyFunction,
	} = props;

	const onCreateUnplottedXyiconClick = () => {
		props.onCreateUnplottedXyiconClick(props.item as Catalog);
	};

	const deleteItem = async () => {
		const count = appState.actions.getNumberOfModels([item]);
		const confirmed = await PopupUtilsV5.getDeleteConfirmationPopupV5(item.ownFeature, count);

		if (confirmed) {
			await appState.actions.deleteSpaceItem(item);
		}
	};

	const onDeleteCatalogClick = async () => {
		const {item} = props;

		const isSafeToDeleteResponse = await appState.app.transport.requestForOrganization({
			url: "xyiconcatalogs/issafetodelete",
			method: XHRLoader.METHOD_POST,
			params: {
				xyiconCatalogIDList: [item.id],
			},
		});

		if (isSafeToDeleteResponse.result?.inUseList.length === 1) {
			await WarningWindowV5.open("You can't delete this item, because it's already used as xyicons.");
		} else {
			await deleteItem();
		}
	};

	const onDetailsClick = () => {
		const {item} = props;

		if (item.ownFeature !== XyiconFeature.XyiconCatalog) {
			appState.app.onDetailsClick(item);
		}
	};

	const setFavorite = (value: boolean) => {
		return (props.item as Catalog).setFavorite(value);
	};

	const onFavoriteClick = () => setFavorite(true);
	const onUnFavoriteClick = () => setFavorite(false);

	return (
		<Observer>
			{() => {
				const isFavorite = (props.item as Catalog).isFavorite;

				return (
					<SpaceItemContainerStyled className={ReactUtils.cls({isSelected})}>
						<SpaceItemV5
							item={item}
							queryString={queryString}
							hideDragHandle={hideDragHandle}
							onPointerDown={onPointerDown}
							onPointerMove={onPointerMove}
							onPointerUp={onPointerUp}
						/>
						<SpaceItemActionButtonsV5
							deleteItem={deleteItem}
							catalog={item ? (item as Catalog) : undefined}
							feature={item.ownFeature}
							isCrossPortfolioXyicon={false}
							isFavorite={isFavorite}
							itemTypeId={item.typeId}
							onBreakLinkClick={onBreakLinkClick}
							onCreateUnplottedXyiconClick={onCreateUnplottedXyiconClick}
							onDeleteCatalogClick={onDeleteCatalogClick}
							onDetailsClick={onDetailsClick}
							onDuplicateCatalogClick={onDuplicateCatalogClick}
							onFavoriteClick={onFavoriteClick}
							onUnFavoriteClick={onUnFavoriteClick}
							showDeleteButton={showDeleteButton}
							showInfoButton={showInfoButton}
							options={options}
						/>
					</SpaceItemContainerStyled>
				);
			}}
		</Observer>
	);
};

import type {CSSProperties, RefObject} from "react";
import {Fragment, memo, useEffect, useMemo, useReducer, useRef, useState} from "react";
import styled from "styled-components";
import {Debouncer} from "../../../../../utils/function/Debouncer";
import {Xyicon} from "../../../../../data/models/Xyicon";
import {IconButtonStyled, IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark-large.svg?react";
import BackArrowIcon from "../../../icons/arrow-back.svg?react";
import LinkIcon from "../../../icons/link.svg?react";
import UnlinkIcon from "../../../icons/unlink.svg?react";
import InfoIcon from "../../../icons/circle-information.svg?react";
import type {XyiconDto} from "../../../../../generated/api/base";
import {XyiconFeature} from "../../../../../generated/api/base";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {SearchFieldV5} from "../../../input/search/SearchFieldV5";
import {colorPalette} from "../../../styles/colorPalette";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {FlexCenterStyle, VerticalFlexStyle, radius} from "../../../styles/styles";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Portfolio} from "../../../../../data/models/Portfolio";
import type {IXyiconLinkObject} from "../../../../../data/state/AppActions";
import {LinkBreakers} from "../../../../modules/space/spaceeditor/ui/actionbar/LinkBreakers";
import {SpaceItemStyled, SpaceItemV5} from "../../../spaceeditor/SpaceItemV5";
import {Functions} from "../../../../../utils/function/Functions";
import {useAppStore} from "../../../../../StateManager";
import {LoaderV5} from "../../../loader/LoaderV5";

type LinkButtonMode = "Link" | "Unlink";
type SearchScope = "current" | "other";

const listStep = 25;

interface IFindXyiconsInnerContentProps {
	readonly onClose: () => void;
}

const FindXyiconsInnerContent = memo((props: IFindXyiconsInnerContentProps) => {
	const appState = useAppStore((state) => state.appState);
	const {spaceViewRenderer} = appState.app;
	const {onClose} = props;
	const [numberOfXyiconsToShow, setNumberOfXyiconsToShow] = useState<number>(listStep);
	const [searchString, setSearchString] = useState<string>("");
	const [searchScope, setSearchScope] = useState<SearchScope>("current"); // portfolio(s)
	const [isLoadingCrossPortfolioXyicons, setIsLoadingCrossPortfolioXyicons] = useState<boolean>(false);
	const [crossPortfolioXyicons, setCrossPortfolioXyicons] = useState<Xyicon[]>([]);
	const debouncer = useRef<Debouncer>(new Debouncer(1500));

	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const linksUpdatedSignal = appState.app.transport.signalR.listener.signals.linksUpdated;

	useEffect(() => {
		const signal = linksUpdatedSignal;

		signal.add(forceUpdate);

		return () => {
			signal.remove(forceUpdate);
		};
	}, [linksUpdatedSignal]);

	const triggerCrossPortfolioSearch = async (searchStr: string) => {
		setSearchString(searchStr);

		if (searchStr) {
			setIsLoadingCrossPortfolioXyicons(true);
			const {result, error} = await appState.app.transport.requestForOrganization<XyiconDto[]>({
				url: "xyicons/search",
				method: XHRLoader.METHOD_GET,
				params: {
					xyiconRefIdSearchString: searchStr,
				},
			});

			setCrossPortfolioXyicons(result.map((x) => new Xyicon(x, appState)).filter((x) => x.portfolioId !== appState.portfolioId));
			setIsLoadingCrossPortfolioXyicons(false);
		} else {
			setCrossPortfolioXyicons([]);
		}
	};

	const onSearchInput = (searchStr: string) => {
		if (searchScope === "other") {
			debouncer.current.debounce(() => {
				return triggerCrossPortfolioSearch(searchStr);
			});
		} else {
			setSearchString(searchStr);
		}
	};

	const xyiconsFromCurrentPortfolio = useMemo(
		() => (searchScope === "current" ? getXyiconsFromCurrentPortfolio(spaceViewRenderer, searchString) : []),
		[searchScope, searchString, spaceViewRenderer],
	);

	const xyiconsToShow = searchScope === "current" ? xyiconsFromCurrentPortfolio : crossPortfolioXyicons;

	return (
		<>
			<HeaderStyled>
				<h3>Create a Link</h3>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={onClose}
				/>
			</HeaderStyled>
			<SearchFieldV5
				className="findInput"
				value={searchString}
				onInput={onSearchInput}
				autoFocus={true}
			/>
			{
				<ListContainerStyled>
					{searchScope === "current" ? (
						renderXyicons(xyiconsToShow, spaceViewRenderer, searchString, searchScope, numberOfXyiconsToShow)
					) : isLoadingCrossPortfolioXyicons ? (
						<LoaderV5 />
					) : (
						renderXyicons(xyiconsToShow, spaceViewRenderer, searchString, searchScope, numberOfXyiconsToShow)
					)}
					{numberOfXyiconsToShow < xyiconsToShow.length && !isLoadingCrossPortfolioXyicons && (
						<BottomButtonContainer>
							<Button onClick={() => setNumberOfXyiconsToShow((n) => n + listStep)}>Load more...</Button>
						</BottomButtonContainer>
					)}
				</ListContainerStyled>
			}
			{appState.user.isAdmin &&
				(searchScope === "current" ? (
					<BottomButtonContainer style={{borderTop: `solid 2px ${colorPalette.gray.c200Light}`}}>
						<p>Can't find what you are looking for?</p>
						<Button
							onClick={() => {
								setSearchScope("other");
								triggerCrossPortfolioSearch(searchString);
							}}
						>
							Search in Other Portfolios
						</Button>
					</BottomButtonContainer>
				) : (
					<BottomButtonContainer style={{borderTop: `solid 2px ${colorPalette.gray.c200Light}`}}>
						<Button
							onClick={() => {
								setSearchScope("current");
							}}
						>
							<BackArrowIcon /> Go back to Current Portfolio
						</Button>
					</BottomButtonContainer>
				))}
		</>
	);
});

interface IFindXyiconsWindowProps {
	readonly divRef: RefObject<HTMLDivElement>;
	readonly style: CSSProperties;
	readonly onClose: () => void;
}

export const FindXyiconsWindowV5 = (props: IFindXyiconsWindowProps) => {
	const {divRef, style, onClose} = props;
	const [isMounted, setIsMounted] = useState<boolean>(false);

	useEffect(() => {
		setIsMounted(true);
	}, []);

	return (
		<FindXyiconsWindowStyled
			ref={divRef}
			style={{...style, opacity: isMounted ? 1 : 0}}
		>
			<FindXyiconsInnerContent onClose={onClose} />
		</FindXyiconsWindowStyled>
	);
};

const ListContainerStyled = styled.div`
	${VerticalFlexStyle};
	flex: 1;
	gap: 8px;
	overflow: auto;
`;

const FindXyiconsWindowStyled = styled.div`
	${VerticalFlexStyle};
	gap: 8px;
	position: absolute;
	width: 420px;
	height: 540px;
	padding: 16px;
	background-color: ${colorPalette.white};
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
	z-index: 1;
	border-radius: 8px;
`;

const HeaderStyled = styled.div`
	${FlexCenterStyle};
	justify-content: space-between;

	h3 {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}

	svg {
		width: 16px;
		height: 16px;
	}
`;

const onLinkButtonClick = (spaceViewRenderer: SpaceViewRenderer, selectedXyicons: Xyicon[], xyicon: Xyicon, mode: LinkButtonMode) => {
	const appState = spaceViewRenderer.transport.appState;
	const spaceActionBarPos = spaceViewRenderer.spaceItemController.boundingBox.position;
	const {linkManager} = spaceViewRenderer.toolManager;

	if (mode === "Link") {
		linkManager.onEndXyiconClickInLinkMode(xyicon, spaceActionBarPos);
	} else {
		const linkObjectsToDelete = appState.actions.getLinksXyiconXyicon(xyicon.id).filter((o) => selectedXyicons.includes(o.object));
		const linkIdsToDelete: string[] = linkObjectsToDelete.map((l) => l.link.id);

		return LinkBreakers.breakLinks(appState.app.transport, linkIdsToDelete);
	}
};

const getLinkButtonLabel = (spaceViewRenderer: SpaceViewRenderer, selectedXyicons: Xyicon[], xyicon: Xyicon): LinkButtonMode => {
	const appState = spaceViewRenderer.transport.appState;
	const links = appState.actions.getLinksXyiconXyicon(xyicon.id);
	const areAlreadyLinked = selectedXyicons.every((x) => links.some((linkObject: IXyiconLinkObject) => linkObject.object === x));

	return areAlreadyLinked ? "Unlink" : "Link";
};

interface IXyiconGroupByPortfolio {
	portfolio: Portfolio;
	xyicons: Xyicon[];
}

const groupXyiconsByPortfolio = (xyicons: Xyicon[], spaceViewRenderer: SpaceViewRenderer): IXyiconGroupByPortfolio[] => {
	// group by portfolioIds
	const groups: {
		[portfolioId: string]: Xyicon[];
	} = {};

	for (const xyicon of xyicons) {
		if (!groups[xyicon.portfolioId]) {
			groups[xyicon.portfolioId] = [];
		}

		groups[xyicon.portfolioId].push(xyicon);
	}

	const actions = spaceViewRenderer.actions;

	const groupsArray: IXyiconGroupByPortfolio[] = [];

	for (const portfolioId in groups) {
		groupsArray.push({
			portfolio: actions.getPortfolioById(portfolioId),
			xyicons: groups[portfolioId].toSorted((a: Xyicon, b: Xyicon) => StringUtils.sortIgnoreCase(a.refId, b.refId)),
		});
	}

	return groupsArray.toSorted((a, b) => StringUtils.sortIgnoreCase(a.portfolio.name, b.portfolio.name));
};

const getFilteredGroups = (groups: IXyiconGroupByPortfolio[], numberOfXyiconsToShow: number): IXyiconGroupByPortfolio[] => {
	const filteredGroups: IXyiconGroupByPortfolio[] = [];
	let numberOfXyiconsInFilteredGroups = 0;

	for (const group of groups) {
		const maxNumberOfXyiconsToAdd = Math.max(0, numberOfXyiconsToShow - numberOfXyiconsInFilteredGroups);
		const filteredXyiconsInThisGroup = group.xyicons.slice(0, maxNumberOfXyiconsToAdd);

		group.xyicons = filteredXyiconsInThisGroup;
		numberOfXyiconsInFilteredGroups += filteredXyiconsInThisGroup.length;

		filteredGroups.push(group);

		if (numberOfXyiconsInFilteredGroups >= numberOfXyiconsToShow) {
			break;
		}
	}

	return filteredGroups;
};

const renderXyiconGroups = (
	xyiconsToRender: Xyicon[],
	selectedXyicons: Xyicon[],
	searchString: string,
	spaceViewRenderer: SpaceViewRenderer,
	numberOfXyiconsToShow: number,
) => {
	const groups = groupXyiconsByPortfolio(xyiconsToRender, spaceViewRenderer);
	const filteredGroups = getFilteredGroups(groups, numberOfXyiconsToShow);

	return filteredGroups.map((group: IXyiconGroupByPortfolio) => (
		<Fragment key={group.portfolio.name}>
			<PortfolioName>{group.portfolio.name}</PortfolioName>
			{renderXyiconBlock(group.xyicons, selectedXyicons, searchString, spaceViewRenderer)}
		</Fragment>
	));
};

const renderXyiconBlock = (
	xyicons: Xyicon[],
	selectedXyicons: Xyicon[],
	searchString: string,
	spaceViewRenderer: SpaceViewRenderer,
	numberOfXyiconsToShow?: number,
) => {
	const appState = spaceViewRenderer.transport.appState;
	const xyiconsToRender = xyicons.slice(0, numberOfXyiconsToShow);

	return xyiconsToRender.map((xyicon: Xyicon) => {
		const buttonLabel = getLinkButtonLabel(spaceViewRenderer, selectedXyicons, xyicon);

		return (
			<XyiconRowWrapper key={xyicon.refId}>
				<SpaceItemV5
					item={xyicon}
					queryString={searchString}
					hideDragHandle={true}
				/>
				<RowButtonWrapper>
					<IconButtonV5
						IconComponent={buttonLabel === "Link" ? LinkIcon : UnlinkIcon}
						title={buttonLabel}
						onClick={() => onLinkButtonClick(spaceViewRenderer, selectedXyicons, xyicon, buttonLabel)}
					/>
					<IconButtonV5
						IconComponent={InfoIcon}
						title="Details"
						onClick={() => appState.app.onDetailsClick(xyicon)}
					/>
				</RowButtonWrapper>
				{buttonLabel === "Unlink" && (
					<ShadowUnlinkButton>
						<IconButtonV5
							style={{opacity: 0.4, transform: "scale(.8)"}}
							IconComponent={LinkIcon}
							onClick={Functions.emptyFunction}
						/>
					</ShadowUnlinkButton>
				)}
			</XyiconRowWrapper>
		);
	});
};

const renderXyicons = (
	xyiconsToRender: Xyicon[],
	spaceViewRenderer: SpaceViewRenderer,
	searchString: string,
	searchScope: SearchScope,
	numberOfXyiconsToShow: number,
) => {
	if (xyiconsToRender.length > 0) {
		const selectedXyicons = spaceViewRenderer.xyiconManager.selectedItems.map((x) => x.modelData) as Xyicon[];

		return (
			<>
				<p style={{fontSize: 16}}>{xyiconsToRender.length} result(s) found</p>
				{searchScope === "current"
					? renderXyiconBlock(xyiconsToRender, selectedXyicons, searchString, spaceViewRenderer, numberOfXyiconsToShow)
					: renderXyiconGroups(xyiconsToRender, selectedXyicons, searchString, spaceViewRenderer, numberOfXyiconsToShow)}
			</>
		);
	} else if (searchString) {
		return <NoResults>No results found for the term "{searchString}"</NoResults>;
	}
};

const getXyiconsFromCurrentPortfolio = (spaceViewRenderer: SpaceViewRenderer, searchString: string) => {
	const appState = spaceViewRenderer.transport.appState;
	const selectedXyicons = spaceViewRenderer.xyiconManager.selectedItems.map((x) => x.modelData) as Xyicon[];
	const allXyiconsExceptSelected = appState.actions.getList<Xyicon>(XyiconFeature.Xyicon).filter((x) => !selectedXyicons.includes(x));

	const xyiconsToShow: Xyicon[] = appState.actions
		.searchModelsCached(allXyiconsExceptSelected, searchString, XyiconFeature.Xyicon)
		.toSorted((a: Xyicon, b: Xyicon) => StringUtils.sortIgnoreCase(a.refId, b.refId));

	return xyiconsToShow;
};

const PortfolioName = styled.div`
	margin-top: 8px;
	font-size: 14px;
	color: ${colorPalette.gray.c700Dark};
`;

const NoResults = styled.p`
	width: 100%;
	text-align: center;
	color: ${colorPalette.gray.c700Dark};
	${VerticalFlexStyle};
	flex: 1;
	justify-content: center;
`;

const RowButtonWrapper = styled.div`
	${FlexCenterStyle};
	gap: 8px;
	display: none;
`;

const ShadowUnlinkButton = styled.div``;

const XyiconRowWrapper = styled.div`
	${FlexCenterStyle};
	height: 48px;
	justify-content: space-between;
	border-radius: 4px;
	padding: 4px;
	padding-left: 8px;

	${SpaceItemStyled} {
		flex: 1;
		min-width: 0;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		${ShadowUnlinkButton} {
			display: none;
		}

		${RowButtonWrapper} {
			display: contents;
		}
	}

	${IconButtonStyled} {
		width: 32px;
		height: 32px;
		border-radius: ${radius.sm};

		&:hover {
			border: solid 1px ${colorPalette.gray.c950};
		}
	}

	${SpaceItemStyled} {
	}
`;

const Button = styled.div`
	${FlexCenterStyle};
	color: #1e88e5;
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	border-radius: ${radius.sm};
	gap: 8px;
`;

const BottomButtonContainer = styled.div`
	${VerticalFlexStyle};
	align-items: center;
	margin: 8px 0;
	padding-top: 8px;

	p {
		margin-top: 0;
		margin-bottom: 5px;
		color: ${colorPalette.gray.c700Dark};
	}
`;

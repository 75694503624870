import type {MeasureType} from "../../renderers/SpaceViewRendererUtils";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {Color, PointDouble} from "../../../../../../../generated/api/base";
import type {HorizontalAlignment, VerticalAlignment} from "../../../../../../../utils/dom/DomUtils";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import type {ICaptionStyle} from "../../../../../../../data/models/ViewUtils";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {SupportedFontName} from "../../../../../../../data/state/AppStateTypes";
import type {MSDFFont} from "./MSDFFont";

interface IPreprocessedLine {
	width: number;
	height: number;
	textContent: string;
	fontFamily: SupportedFontName;
	isBold: boolean;
	isItalic: boolean;
	isUnderlined: boolean;
	fontColor: Color;
	backgroundColor: Color;
	fontSize: number;
	scaleCorrection: number;
	quads: {
		glyph: IChar;
		position: PointDouble;
	}[];
}

export interface IChar {
	id: number;
	index: number;
	char: string;
	width: number;
	height: number;
	xoffset: number;
	yoffset: number;
	xadvance: number;
	chnl: number;
	x: number;
	y: number;
	page: number;
}

interface IKerning {
	first: number; // left char id
	second: number; // right char id
	amount: number;
}

interface IQuad {
	glyph: IChar;
	position: PointDouble;
}

interface ITextObject {
	position: PointDouble;
	orientation: number;
	textOffset?: PointDouble;
}

export interface IFontData {
	common: {
		lineHeight: number;
		base: number;
		scaleW: number;
		scaleH: number;
	};
	chars: IChar[];
	kernings: IKerning[];
}

export interface ITextPart {
	content: string;
	style?: Partial<ICaptionStyle>;
}

export interface IPreprocessableObjectWithText extends ICaptionStyle {
	text: ITextPart[];
}

export interface IObjectWithText extends IPreprocessableObjectWithText {
	text: ITextPart[];
	textHAlign: HorizontalAlignment;
	textVAlign: VerticalAlignment;
	position: PointDouble;
	orientation: number;
	textOffset?: PointDouble;
	textOrientation?: number;
	sizeOfGeneratedText: PointDouble;
	scale?: PointDouble; // the size of the object (markup), calculated from the bounding box. If ommitted, we're using the size of the generated text
	dimensionMultiplier?: number; // multiplier for the scale of the text, default is 1
	isCaption?: boolean;
	opacity: number;
	isVisible: boolean;
	textInstanceIds: number[];
	markedForDeletion?: boolean;
	measureType?: MeasureType;
	isTextInHtmlEditMode?: boolean;
	id: string;
}

export const getTextWorldPosition = (textObject: ITextObject): PointDouble => {
	const unrotatedOffset = textObject.textOffset || {x: 0, y: 0};
	const rotatedOffset = THREEUtils.getRotatedVertex(unrotatedOffset, textObject.orientation ?? 0, {x: 0, y: 0});

	const {position} = textObject;

	return {
		x: position.x + rotatedOffset.x,
		y: position.y + rotatedOffset.y,
	};
};

const SPACE_CHAR_SIZE: number = 10; // px

const textPartsToLines = (textParts: ITextPart[]): string[] => {
	return textParts.flatMap((textPart) => textPart.content.split("\n"));
};

export const textPartsToTextContent = (textParts: ITextPart[]): string => {
	return textParts.map((textPart) => textPart.content).join("\n");
};

export const getNumberOfChars = (objectsWithTexts: IPreprocessableObjectWithText[]): number => {
	let count = 0;

	for (const objectWithText of objectsWithTexts) {
		const lines = textPartsToLines(objectWithText.text);

		for (const line of lines) {
			count += line.length;
		}
	}

	return count;
};

export const textLabelToPreprocessableObjectWithText = (textParts: ITextPart[], captionSettings: ICaptionStyle): IPreprocessableObjectWithText => {
	return {
		...captionSettings,
		text: textParts,
	};
};

export const getScaleCorrection = (fontSize: number, spaceViewRenderer: SpaceViewRenderer): number => {
	const coeff = 0.0235; // got with trial and error. Needed to adjust this size to the html input element's fontsize
	const correctionMultiplier = spaceViewRenderer.correctionMultiplier.current;

	return fontSize * correctionMultiplier * coeff;
};

const wordWrapParagraph = (paragraph: string, font: MSDFFont, fontSize: number, maxWidth: number, spaceViewRenderer: SpaceViewRenderer): string[] => {
	if (MathUtils.isValidNumber(maxWidth) && maxWidth > 0) {
		const words = paragraph.split(" ");
		let currentLineWidth: number = 0;
		let output: string = "";

		const scaleCorrection = getScaleCorrection(fontSize, spaceViewRenderer);
		const spaceSize = SPACE_CHAR_SIZE * scaleCorrection;

		for (let word of words) {
			let subWordToFit = getSubStringToFitInMaxWidth(font, word, fontSize, maxWidth, spaceViewRenderer);
			let wordWidth = getWidthOfLine(font, word.trimEnd(), fontSize, spaceViewRenderer) + spaceSize;

			if (currentLineWidth + wordWidth > maxWidth) {
				word = word.trimEnd();
				if (currentLineWidth > 0) {
					output += "\n";
					currentLineWidth = 0;
				}

				while (wordWidth > maxWidth && word.length > 1) {
					output += word.substring(0, subWordToFit.length);
					word = word.substring(subWordToFit.length);
					subWordToFit = getSubStringToFitInMaxWidth(font, word, fontSize, maxWidth, spaceViewRenderer);
					wordWidth = getWidthOfLine(font, word, fontSize, spaceViewRenderer);

					output += "\n";
				}

				word = word.trimStart();
			}

			output += `${word} `;
			currentLineWidth += wordWidth;
		}

		output = output.trimEnd();

		return output.split("\n");
	} else {
		return [paragraph];
	}
};

const isCharASpace = (char: string): boolean => {
	const charCode = char.charCodeAt(0);

	return charCode === 32 || charCode === 160; // 32: space, 160: non-breaking space
};

export const getMaxFontSizeFromObjectWithText = (objectWithText: IObjectWithText): number => {
	let maxFontSize = -Infinity;

	for (const textPart of objectWithText.text) {
		if (textPart.style && maxFontSize < textPart.style.fontSize) {
			maxFontSize = textPart.style.fontSize;
		} else if (maxFontSize < objectWithText.fontSize) {
			maxFontSize = objectWithText.fontSize;
		}
	}

	if (!MathUtils.isValidNumber(maxFontSize)) {
		console.log(`The following object doesn't have a valid fontsize: `, objectWithText);
	}

	return maxFontSize;
};

export const getSumOfLineHeights = (lines: IPreprocessedLine[]): number => {
	let sum: number = 0;

	for (const line of lines) {
		sum += line.fontSize;
	}

	return sum;
};

export const preprocessObjectWithText = (
	objectWithText: IPreprocessableObjectWithText,
	fonts: {[key in SupportedFontName]: MSDFFont},
	spaceViewRenderer: SpaceViewRenderer,
	maxWidth: number = Infinity,
): {lines: IPreprocessedLine[]; size: PointDouble} => {
	const currentPos = {
		x: 0,
		y: 0,
	};

	const lines: IPreprocessedLine[] = [];

	let previousGlyph: IChar;
	let maxLineWidth = 0;
	const textGroupParagraphs = objectWithText.text;

	for (const paragraph of textGroupParagraphs) {
		const fontSize = paragraph.style?.fontSize ?? objectWithText.fontSize;
		const isBold = paragraph.style?.isBold ?? objectWithText.isBold;
		const isItalic = paragraph.style?.isItalic ?? objectWithText.isItalic;
		const isUnderlined = paragraph.style?.isUnderlined ?? objectWithText.isUnderlined;
		const fontColor = paragraph.style?.fontColor ?? objectWithText.fontColor;
		const fontBackgroundColor = paragraph.style?.backgroundColor ?? objectWithText.backgroundColor;
		const fontFamily: SupportedFontName = paragraph.style?.fontFamily ?? objectWithText.fontFamily;
		const font = fonts[fontFamily];
		const scaleCorrection = getScaleCorrection(fontSize, spaceViewRenderer);
		const lineHeight = font.getLineHeight(fontSize);

		// most of the time, it's just one line. It can be multiple lines when it's a multiline, or multiselect field, or something like that
		const textPartLines = paragraph.content.split("\n");

		for (const textPartLine of textPartLines) {
			const textGroupLines = wordWrapParagraph(textPartLine, font, fontSize, maxWidth, spaceViewRenderer);

			for (const lineOfText of textGroupLines) {
				currentPos.x = 0;
				previousGlyph = null;

				const quads: IQuad[] = [];
				let textContent: string = "";

				for (const char of lineOfText) {
					if (isCharASpace(char)) {
						currentPos.x += SPACE_CHAR_SIZE * scaleCorrection;
						previousGlyph = null;
						textContent += char;
					} else {
						const glyph = font.getGlyph(char);

						if (glyph) {
							textContent += glyph.char;

							const kerning = previousGlyph ? font.getKerning(previousGlyph.id, glyph.id) : 0;

							previousGlyph = glyph;
							quads.push({
								glyph: glyph,
								position: {
									x: currentPos.x + (glyph.xoffset + kerning) * scaleCorrection,
									y: currentPos.y,
								},
							});

							currentPos.x += (glyph.xadvance + kerning) * scaleCorrection;
						}
					}
				}

				const lineWidth = currentPos.x;

				lines.push({
					textContent,
					fontFamily,
					isBold,
					isItalic,
					isUnderlined,
					fontColor,
					backgroundColor: fontBackgroundColor,
					fontSize,
					quads,
					width: lineWidth,
					height: lineHeight,
					scaleCorrection,
				});
				maxLineWidth = Math.max(maxLineWidth, lineWidth);
				currentPos.y -= lineHeight;
			}
		}
	}

	return {
		size: {
			x: maxLineWidth,
			y: Math.abs(currentPos.y),
		},
		lines: lines,
	};
};

export const doesEveryLineHasTheSameBackground = (lines: IPreprocessedLine[]): boolean => {
	for (let i = 0; i < lines.length - 1; ++i) {
		if (JSON.stringify(lines[i].backgroundColor) !== JSON.stringify(lines[i + 1].backgroundColor)) {
			return false;
		}
	}

	return true;
};

const getWidthOfLine = (font: MSDFFont, line: string, fontSize: number, spaceViewRenderer: SpaceViewRenderer): number => {
	let previousGlyph: IChar = null;
	const scaleCorrection = getScaleCorrection(fontSize, spaceViewRenderer);
	let currentPos = 0;

	for (const char of line) {
		if (isCharASpace(char)) {
			currentPos += SPACE_CHAR_SIZE * scaleCorrection;
		} else {
			const glyph = font.getGlyph(char);

			if (glyph) {
				const kerning = previousGlyph ? font.getKerning(previousGlyph.id, glyph.id) : 0;

				previousGlyph = glyph;

				currentPos += (glyph.xadvance + kerning) * scaleCorrection;
			}
		}
	}

	return currentPos;
};

const getSubStringToFitInMaxWidth = (font: MSDFFont, text: string, fontSize: number, maxWidth: number, spaceViewRenderer: SpaceViewRenderer) => {
	let subString: string = "";

	for (let i = 1; i < text.length; ++i) {
		subString = text.substring(0, i);

		if (getWidthOfLine(font, subString, fontSize, spaceViewRenderer) > maxWidth) {
			break;
		}
	}

	return subString.substring(0, Math.max(subString.length, 1));
};

import * as React from "react";
import * as ReactDOM from "react-dom";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {IViewColumn, IViewSort} from "../../../../data/models/ViewUtils";
import {SortDirection} from "../../../../data/models/ViewUtils";
import type {IFieldPointer} from "../../../../data/models/field/Field";
import {XyiconFeature} from "../../../../generated/api/base";
import type {ManageColumnCallback} from "../../../widgets/table/TableHeaderDropDown";
import type {Pointer} from "../../../../utils/interaction/Pointer";
import type {View} from "../../../../data/models/View";
import type {App} from "../../../../App";
import type {AppState} from "../../../../data/state/AppState";
import type {IModel, ISpaceItemModel} from "../../../../data/models/Model";
import {Functions} from "../../../../utils/function/Functions";
import {ResizeDetector} from "../../../../utils/resize/ResizeDetector";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import {filterModels} from "../../../../data/models/filter/Filter";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {NotificationType} from "../../../notification/Notification";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import {DomUtils} from "../../../../utils/dom/DomUtils";
import {notify} from "../../../../utils/Notify";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";
import {LoaderStyled, LoaderV5} from "../../loader/LoaderV5";
import {TableFooterV5} from "./TableFooterV5";
import {QuickLinksV5} from "./QuickLinksV5";
import {TableRowV5} from "./TableRowV5";
import {TableHeadersV5} from "./TableHeadersV5";
import {NoResultFilterViewV5} from "./NoResultFilterViewV5";
import {NoResultSearchViewV5} from "./NoResultSearchViewV5";

export interface ITableProps<T> {
	readonly loading?: boolean;
	readonly errorMessage?: string;
	readonly getHeaders: () => Partial<IViewColumn>[]; // TODO view is not nice here
	readonly onHeaderWidthChange: (index: number, width: number, header: Partial<IViewColumn>) => void;
	readonly data: T[];
	readonly searchedData: T[];
	readonly getKey: (item: T, index: number) => string | number;
	readonly getFields: (item: T, index?: number) => (string | number)[];
	readonly getFieldsArray: (item: T, index?: number) => any;
	readonly selected?: T[];
	readonly focused?: T[];
	readonly onFocus?: (items: T[], isGoToItemNeeded?: boolean) => void;
	readonly onSelect: (items: T[]) => void;
	readonly onClick?: (item: T) => void;
	readonly onDoubleClick?: (item: T) => void;
	readonly onDuplicateClick?: (item: T) => void;
	readonly onReorderColumn?: (index: number, newIndex: number) => void;
	readonly checkboxColumn?: boolean;
	readonly onToggleSort: (column: IFieldPointer) => void;
	readonly moduleName?: string;
	readonly feature?: XyiconFeature;
	readonly tableRowIcon?: boolean;
	readonly search?: string;
	readonly searching?: boolean;
	readonly filterSuppression?: boolean;
	readonly isCellContentsWrappingOn?: boolean;
	readonly onManageColumns?: ManageColumnCallback;
	readonly onTableRowMouseMove?: (pointer: Pointer, row: TableRowV5<T>) => void;
	readonly onSortAsc?: (id: IFieldPointer) => void;
	readonly onSortDesc?: (id: IFieldPointer) => void;
	readonly onClearSort?: (id: IFieldPointer) => void;
	readonly sorts: IViewSort[];
	readonly view: View;
	readonly app?: App;
	readonly appState?: AppState;
	readonly isSimpleTable?: boolean;
	readonly isItemUnfocused?: boolean;
}

interface ITableState<T> {
	lastSelectedItem: T;
	currentPage: number;
	isQuickLinksOpen: boolean;
	quickLinkXPos: number;
	quickLinkYPos: number;
	quickLinkParentId: number;
}

const itemsPerPage: number = 100;

@inject("app")
@inject("appState")
@observer
export class TableV5<T> extends React.Component<ITableProps<T>, ITableState<T>> {
	public static readonly defaultProps = {
		checkboxColumn: true,
		sorts: [
			{
				column: "",
				direction: SortDirection.ASC,
			},
		],
		isSimpleTable: false,
		getKey: (item: IModel, index: number) => item?.id || index,
		getFilteredData: (items: IModel[]) => items,
		onSelect: Functions.emptyFunction,
	};

	private _header = React.createRef<HTMLDivElement>();
	public _body = React.createRef<HTMLTableSectionElement>();
	public _table = React.createRef<HTMLTableElement>();
	private _resizeDetector: ResizeDetector;
	private _isMounted = false;

	public container = React.createRef<HTMLDivElement>();

	private _hoveringModel: T;

	constructor(props: ITableProps<T>) {
		super(props);
		this.state = {
			lastSelectedItem: null,
			currentPage: 0,
			isQuickLinksOpen: false,
			quickLinkXPos: 0,
			quickLinkYPos: 0,
			quickLinkParentId: null,
		};
	}

	private updateQuickLinksStates = (x: number, y: number) => {
		this.setState({
			quickLinkXPos: x,
			quickLinkYPos: y,
			isQuickLinksOpen: true,
		});
	};

	private showQuickLinks = (e: React.MouseEvent, item: T, index: number) => {
		e.preventDefault();
		FocusLoss.listen(this.props.app.modalContainer, this.onTableBlur);
		this.setState({quickLinkParentId: index});

		if (this.state.isQuickLinksOpen) {
			this.hideQuickLinks();
		}

		let x = e.pageX;
		let y = e.pageY;

		this._hoveringModel = item;
		this.updateQuickLinksStates(x, y);
	};

	private scrollTableToShowFullCell = (e: React.MouseEvent) => {
		const table = this._table.current;

		if (table) {
			const tableBoundingBox = table.getBoundingClientRect();
			const divBoundingBox = e.currentTarget.getBoundingClientRect();
			const currentScrollValue = table.scrollLeft;
			const overFlow = divBoundingBox.right - tableBoundingBox.right;
			const underflow = divBoundingBox.left - tableBoundingBox.left;

			if (overFlow > 0) {
				table.scroll({left: currentScrollValue + overFlow});
			} else if (underflow < 0) {
				// add some px to be prettier
				table.scroll({left: currentScrollValue + underflow});
			}
			this.onTableScroll(e);
		}
	};

	private hideQuickLinks = () => {
		if (this.state.isQuickLinksOpen) {
			this.setState({
				isQuickLinksOpen: false,
			});
		}
	};

	private onTableScroll = (event?: React.UIEvent | ResizeDetector) => {
		// This runs when the table is scrolled horizontally.
		// We need to set the width manually for header + body, to make sure the vertical scrollbar is position properly.
		// See:
		// https://stackoverflow.com/questions/48217171/horizontal-and-vertical-scroll-able-table-in-bootstrap

		const table = this._table.current;

		if (table) {
			const header = this._header.current;
			const body = this._body.current;

			const w = table.offsetWidth + table.scrollLeft;

			header.style.width = `${w}px`;
			body.style.width = `${w}px`;
		}
	};

	private onTableBlur = () => {
		this.hideQuickLinks();
	};

	public async goToItem(item: any) {
		this.goToPage(item);
		await TimeUtils.waitForNextFrame();
		this.scrollToItemId(item.id); // TODO any?
	}

	private goToPage(item: any) {
		// go to the page where the item is
		const pageIndex = this.getPageIndex(item);

		this.setState({
			currentPage: pageIndex,
		});
	}

	public scrollToItemId(id: string) {
		if (this._isMounted) {
			const grid = ReactDOM.findDOMNode(this) as HTMLDivElement;

			if (grid) {
				const selectedRow = grid.querySelector(`*[data-key="${id}"]`) as HTMLDivElement;

				DomUtils.scrollIntoViewIfNeeded(selectedRow);
			}
		}
	}

	private getPageIndex(item: any) {
		const {data} = this.props;
		const filteredData = this.props.searchedData || data;

		const index = filteredData.indexOf(item);

		if (index === -1) {
			return 0;
		}

		return Math.floor(index / itemsPerPage);
	}

	private onAutoResizeColumn = (index: number, header: Partial<IViewColumn>) => {
		const container = this.container.current;

		if (!container) {
			return;
		}

		// Add a dummy table, measure the column width, remove dummy table
		const table = document.createElement("table");

		table.className = "Table";
		table.style.position = "absolute";
		const tbody = document.createElement("tbody");

		const firstTR = document.createElement("tr");

		firstTR.innerHTML = this.props.getHeaders()[index].title;
		tbody.appendChild(firstTR);

		for (const data of this.props.data) {
			const tr = document.createElement("tr");

			const fields = this.props.getFields(data, 0);
			const field = fields[index] || "";

			tr.innerHTML = field.toString();
			tbody.appendChild(tr);
		}

		table.appendChild(tbody);
		container.appendChild(table);

		const padding = 20;
		const iconSize = 18;
		const maxWidth = 700;
		const width = Math.min(maxWidth, firstTR.clientWidth + 2 * padding + iconSize);

		container.removeChild(table);

		this.props.onHeaderWidthChange(index, width, header);
	};

	private isAllSelected(filteredData: T[]) {
		return filteredData.length === this.props.selected?.length;
	}

	private onAllClicked = () => {
		const filteredData = this.props.searchedData || this.props.data || [];

		if (this.isAllSelected(filteredData)) {
			this.props.onSelect([]);
		} else {
			this.props.onSelect(filteredData);
		}

		this.hideQuickLinks();
	};

	private onCheckboxRowClick = (event: React.MouseEvent, item: T) => {
		event.stopPropagation();

		this.hideQuickLinks();

		const index = this.props.selected.indexOf(item);

		if (index === -1) {
			if (event.shiftKey) {
				this.onShiftClick(item);
			} else {
				const selected = [...this.props.selected, item];

				this.props.onSelect(selected);
			}
		} else {
			const selected = this.props.selected.slice(0);

			selected.splice(index, 1);
			this.props.onSelect(selected);
		}

		if (!event.shiftKey && !this.props.selected.includes(item)) {
			this.setState({
				lastSelectedItem: item,
			});
		}
	};

	private isVisibleInSpaceEditor = (item: T) => {
		const {appState, feature} = this.props;
		const spaceEditorItems = appState.actions.getList(feature);
		const selectedView = appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
		const filteredItems = filterModels(spaceEditorItems, selectedView.filters, appState, XyiconFeature.SpaceEditor);

		return filteredItems.includes(item as unknown as IModel);
	};

	private showNotification = (item: T) => {
		const {feature, onSelect, app} = this.props;
		const name = featureTitles[feature];
		const nameWithLowerCase = StringUtils.decapitalize(name);

		notify(this.props.app.notificationContainer, {
			lifeTime: Infinity,
			type: NotificationType.Warning,
			title: `${name} is not visible in the active view.`,
			description: `The view's filter prevents the ${nameWithLowerCase} from displaying on the space. To make the ${nameWithLowerCase} visible, switch to another view or edit the active filter. Click the Edit Details button to update the ${nameWithLowerCase}'s fields.`,
			buttonLabel: "Edit Details",
			onActionButtonClick: () => {
				const model = item as unknown as ISpaceItemModel;

				app.spaceViewRenderer.inheritedMethods.selectItems([model], true);
			},
		});
		onSelect([item]);
	};

	private onShiftClick = (item: T) => {
		const {data, selected, onSelect} = this.props;
		const lastIndex = data.indexOf(this.state.lastSelectedItem);
		const newIndex = data.indexOf(item);

		if (lastIndex > -1 && newIndex > -1) {
			if (selected?.includes(this.state.lastSelectedItem)) {
				const newSelection = [];
				const diff = newIndex - lastIndex;

				if (diff > 0) {
					for (let i = lastIndex; i <= newIndex; ++i) {
						newSelection.push(data[i]);
					}
				} else if (diff < 0) {
					for (let i = lastIndex; i >= newIndex; --i) {
						newSelection.push(data[i]);
					}
				}

				if (newSelection.length) {
					onSelect(newSelection);
				}
			} else {
				onSelect([item]);
			}
		}
	};

	private onFocusRow = (event: React.MouseEvent, item: T) => {
		const {focused = []} = this.props;
		const el = event.target as HTMLElement;
		const excludedCasses = ["quickLinksButton", "item", "label"];

		if (this.props.onClick) {
			this.props.onClick(item);
		}

		if (!excludedCasses.includes(el.className) || el.className.includes("disabled")) {
			const itemIndex = focused.indexOf(item);

			event.type === "click" && this.hideQuickLinks();

			if (event.metaKey || event.ctrlKey) {
				const newFocusedItems = focused.slice(0);
				let isGoToItemNeeded = false;

				if (itemIndex === -1) {
					newFocusedItems.push(item);
					isGoToItemNeeded = true;
				} else {
					newFocusedItems.splice(itemIndex, 1);
				}

				this.props.onFocus?.(newFocusedItems, isGoToItemNeeded);
			} else if (event.shiftKey && this.state.lastSelectedItem) {
				this.onShiftClick(item);

				// Option B to remove selection (Option A is used now)
				// document.getSelection().removeAllRanges();
			} else {
				const newFocusedItems: T[] = [];
				let isGoToItemNeeded = false;

				if (itemIndex === -1) {
					newFocusedItems.push(item);
					isGoToItemNeeded = true;
				} else {
					newFocusedItems.splice(itemIndex, 1);
				}

				this.props.onFocus?.(newFocusedItems, isGoToItemNeeded);
			}

			if (!event.shiftKey && !focused.includes(item)) {
				this.setState({
					lastSelectedItem: item,
				});
			}
		}
	};

	public onPageChange = (page: number) => {
		this.setState({currentPage: page});
		this._body.current?.scroll({top: 0});
	};

	private getPagedData(data: T[]) {
		const {currentPage} = this.state;

		let pagedData = data;
		let startIndex = 0;
		let lastIndex = data.length - 1;

		if (itemsPerPage > 0) {
			startIndex = currentPage * itemsPerPage;

			if (data.length !== 0) {
				lastIndex = startIndex + itemsPerPage - 1;
				lastIndex = Math.min(data.length - 1, lastIndex);
			} else {
				lastIndex = 0;
			}

			pagedData = data.slice(startIndex, lastIndex + 1);
		}

		return {
			pagedData,
			startIndex: startIndex,
			lastIndex: lastIndex,
		};
	}

	private onDuplicateClick = (item: T) => {
		this.setState({isQuickLinksOpen: false});
		this.props.onDuplicateClick?.(item);
	};

	public override componentDidMount(): void {
		const table = this._table.current;

		if (table) {
			this._resizeDetector = new ResizeDetector(table);
			this._resizeDetector.resize.add(this.onTableScroll, this);
		}

		this._isMounted = true;
	}

	public override componentDidUpdate(prevProps: ITableProps<T>) {
		const {search, view} = this.props;

		if (search !== prevProps.search || view !== prevProps.view) {
			this.setState({currentPage: 0});
		}
	}

	public override componentWillUnmount() {
		if (this._resizeDetector) {
			this._resizeDetector.resize.remove(this.onTableScroll, this);
			this._resizeDetector.dispose();
		}

		this._isMounted = false;
	}

	public override render() {
		let {getFieldsArray} = this.props;
		const {
			getKey,
			checkboxColumn,
			loading,
			searching,
			errorMessage,
			getFields,
			moduleName,
			tableRowIcon,
			filterSuppression,
			feature,
			view,
			onSortAsc,
			onSortDesc,
			onClearSort,
			sorts,
			isCellContentsWrappingOn,
			search,
			onDoubleClick,
			onTableRowMouseMove,
			searchedData,
			getHeaders,
			onToggleSort,
			onReorderColumn,
			onHeaderWidthChange,
			onManageColumns,
			focused,
			isItemUnfocused,
			isSimpleTable,
		} = this.props;
		const {isQuickLinksOpen, quickLinkXPos, quickLinkYPos, quickLinkParentId} = this.state;

		getFieldsArray = getFieldsArray || getFields;

		const data = this.props.data || [];
		const filteredData = searchedData || data;
		const {pagedData, startIndex, lastIndex} = this.getPagedData(filteredData);

		const selected = this.props.selected || [];
		const allSelected = this.isAllSelected(filteredData);

		const headers = getHeaders();

		return (
			<TableContainerStyled
				className={`tableContainer module-${moduleName}`}
				ref={this.container}
			>
				<div
					ref={this._table}
					className="Table"
					onScroll={this.onTableScroll}
				>
					<TableHeadersV5
						headerRef={this._header}
						headers={headers}
						checkboxColumn={checkboxColumn}
						allSelected={allSelected}
						onAllClicked={this.onAllClicked}
						onToggleSort={onToggleSort}
						onAutoResizeColumn={this.onAutoResizeColumn}
						onReorderColumn={onReorderColumn}
						onHeaderWidthChange={onHeaderWidthChange}
						tableRowIcon={tableRowIcon}
						onManageColumns={onManageColumns}
						feature={feature}
						sorts={sorts}
						view={view}
						onSortAsc={onSortAsc}
						onSortDesc={onSortDesc}
						onClearSort={onClearSort}
					/>
					<BodyStyled
						ref={this._body}
						className="body"
						id="body"
					>
						{errorMessage ? (
							<div className="tableLoader">
								<div className="message">{errorMessage}</div>
							</div>
						) : loading || searching ? (
							<LoaderV5
								labelFirst={true}
								label={searching ? "Searching..." : "Fetching data..."}
							/>
						) : pagedData.length === 0 && search?.length !== 0 ? (
							<NoResultSearchViewV5 search={search} />
						) : (
							filteredData.length === 0 && filterSuppression && <NoResultFilterViewV5 />
						)}
						<div className="tableWrapper">
							{pagedData.map((item, index) => {
								index = startIndex + index;
								const fields = getFieldsArray(item, index);
								const itemSelected = selected.includes(item);
								const itemFocused = isItemUnfocused ? null : focused?.includes(item);

								if (fields.length !== headers.length) {
									console.warn("Header and fields length don't match!");
								}

								const key = getKey(item, index);

								return (
									<TableRowV5
										key={key}
										item={item}
										id={key}
										fields={fields}
										headers={headers}
										index={index}
										selected={itemSelected}
										focused={itemFocused}
										onFocus={this.onFocusRow}
										isVisibleInSpaceEditor={this.isVisibleInSpaceEditor}
										showNotification={this.showNotification}
										onDoubleClick={onDoubleClick}
										showQuickLinks={this.showQuickLinks}
										showCheckboxColumn={checkboxColumn}
										quickLinksActive={isQuickLinksOpen && quickLinkParentId == index}
										onCheckboxRowClick={this.onCheckboxRowClick}
										feature={feature}
										tableRowIcon={tableRowIcon}
										isCellContentsWrappingOn={isCellContentsWrappingOn}
										onMouseMove={onTableRowMouseMove}
										focusedItems={focused}
										scrollTableToShowFullCell={this.scrollTableToShowFullCell}
										isSimpleTable={isSimpleTable}
									/>
								);
							})}
							{
								// We need to pass portfolioID property, because we have to know which portfolio
								// links should appear in the QuickLinks component
								isQuickLinksOpen && this.props.appState.selectedFeature !== XyiconFeature.Portfolio && (
									<QuickLinksV5
										xPos={quickLinkXPos}
										yPos={quickLinkYPos}
										hoveringModel={this._hoveringModel}
										onDuplicateClick={this.onDuplicateClick}
										feature={feature}
										isVisibleInSpaceEditor={this.isVisibleInSpaceEditor}
										showNotification={this.showNotification}
									/>
								)
							}
						</div>
					</BodyStyled>
				</div>
				<TableFooterV5
					itemsPerPage={itemsPerPage}
					totalCount={filteredData.length}
					startIndex={startIndex}
					lastIndex={lastIndex}
					currentPage={this.state.currentPage}
					onPageChange={this.onPageChange}
				/>
			</TableContainerStyled>
		);
	}
}

const TableContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	position: relative;
	justify-content: space-between;
	overflow: hidden;
	background: ${colorPalette.white};
	flex-direction: column;
	font-size: 14px;
	line-height: 16px;
	border-radius: ${radius.md};
	margin: 8px 16px;

	.head {
		&.tableRowIcon {
			.th:first-child {
				margin-left: 40px;
				margin-right: 7px;
			}
		}
	}

	.Table {
		overflow-x: scroll;
		overflow-y: hidden;
	}
`;

const BodyStyled = styled.div`
	flex: 1;
	overflow-x: hidden;
	overflow-y: auto;
	height: calc(100vh - 225px);
	${LoaderStyled} {
		margin: 16px 0;
	}

	.message {
		width: 100%;
		height: 100%;
		text-align: center;
		justify-content: center;
	}

	.tableWrapper {
		padding: 2px;
		padding-bottom: 4px;
	}
`;

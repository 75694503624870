import {Observer} from "mobx-react";
import {Markup} from "../../../../../data/models/Markup";
import type {Color} from "../../../../../generated/api/base";
import type {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {TextStyleModifierV5} from "../../../text/TextStyleModifierV5";
import {MarkupsWithCustomizableTextAlignment} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {onMarkupTextInputChange} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {SupportedFontName} from "../../../../../data/state/AppStateTypes";
import type {MarkupTextInputElementV5} from "./MarkupTextInputElementV5";

interface IMarkupTextActionBarProps {
	readonly inputElementRef: React.RefObject<MarkupTextInputElementV5>;
	readonly markup3D: Markup3D;
	readonly onClose: () => void;
}

export const MarkupTextActionBarV5 = (props: IMarkupTextActionBarProps) => {
	return (
		<Observer>
			{() => {
				const {inputElementRef, markup3D} = props;
				const _markup = markup3D.modelData as Markup;

				const onIsBoldChange = (value: boolean) => {
					_markup?.applyTextData({isBold: value});
				};

				const onIsItalicChange = (value: boolean) => {
					_markup?.applyTextData({isItalic: value});
				};

				const onIsUnderlinedChange = (value: boolean) => {
					_markup?.applyTextData({isUnderlined: value});
				};

				const onFontColorChange = (newColor: Color) => {
					_markup?.applyTextData({fontColor: newColor});
				};

				const onFontSizeChange = (newFontSize: number) => {
					_markup?.applyTextData({fontSize: newFontSize});
					onMarkupTextInputChange(markup3D, markup3D.textContent);
				};

				const onFontFamilyChange = (newFontFamily: SupportedFontName) => {
					_markup?.applyTextData({fontFamily: newFontFamily});
				};

				const onClearTextClick = () => {
					_markup?.applyTextData({content: ""});
					inputElementRef.current?.clearText();
				};

				const onTextAlignmentChange = (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => {
					_markup?.applyTextData({horizontalAlignment, verticalAlignment});
				};

				const text = _markup?.text ?? Markup.defaultText;

				return (
					<TextStyleModifierV5
						isBold={text.isBold}
						isItalic={text.isItalic}
						isUnderlined={text.isUnderlined}
						fontColor={text.fontColor}
						fontFamily={text.fontFamily as SupportedFontName}
						fontSize={text.fontSize}
						onIsBoldChange={onIsBoldChange}
						onIsItalicChange={onIsItalicChange}
						onIsUnderlinedChange={onIsUnderlinedChange}
						onFontColorChange={onFontColorChange}
						onFontSizeChange={onFontSizeChange}
						onFontFamilyChange={onFontFamilyChange}
						onClearTextClick={onClearTextClick}
						onTextBoxAlignmentChange={MarkupsWithCustomizableTextAlignment.includes(props.markup3D.type) ? onTextAlignmentChange : null}
						horizontalAlignment={text.horizontalAlignment}
						verticalAlignment={text.verticalAlignment}
						onClose={props.onClose}
						isSpaceBarActionButton={true}
					/>
				);
			}}
		</Observer>
	);
};

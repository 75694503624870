import styled, {css} from "styled-components";
import type {ISingleSelectFieldSettingsDefinition} from "../../../../data/models/field/datatypes/SingleSelect";
import {HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {FlexCenterStyle, radius} from "../../styles/styles";
import Flag from "../../icons/flag.svg?react";
import Circle from "../../icons/circle.svg?react";
import {ColorUtils} from "../../../../utils/ColorUtils";

export const PillStyled = styled.div<{$color?: string; $type?: string; $selected?: boolean; $inline?: boolean}>`
	${FlexCenterStyle};
	cursor: pointer;
	font-size: 14px;
	gap: 4px;

	${(props) => {
		const hslColor = ColorUtils.hex2hsl(props.$color);

		const nullLabelDefaultStyle = css`
			&.nullLabel {
				background-color: transparent;
				color: black;
				height: 24px;
				width: 100px;
			}
		`;

		switch (props.$type) {
			case "textOnly":
				return css`
					background-color: "#ffffff";
					color: ${props.$color === "FFFFFF" ? "#000000" : props.$color};
					margin-left: 4px;
					margin-right: 4px;
					${nullLabelDefaultStyle}
				`;
			case "pillColored":
				const pillColoredCommonStyles = css`
					background-color: ${props.$color ? props.$color : "#ffffff"};
					border-radius: 16px;
					padding: 4px;
					height: 24px;
					width: ${props.$inline ? "100%" : "fit-content"};
					margin-left: ${!props.$selected ? "4px" : ""};
					height: ${props.$selected ? "24px" : ""};
					${nullLabelDefaultStyle}
				`;

				if (hslColor.l >= 0.95) {
					return css`
						color: black;
						${pillColoredCommonStyles}
					`;
				} else {
					return css`
						color: white;
						${pillColoredCommonStyles}
					`;
				}
			case "pill":
				return css`
					border-radius: 16px;
					padding: 4px;
					height: 24px;
					margin-left: ${!props.$selected ? "4px" : ""};
					width: ${props.$inline ? "100%" : "fit-content"};
					margin-right: 4px;
					background-color: #ededed;
					${nullLabelDefaultStyle}

					svg {
						fill: ${props.$color ? props.$color : "#ffffff"};
						width: 8px;
						height: 8px;

						path {
							stroke: ${props.$color ? props.$color : "#ffffff"};
						}
					}
				`;
			case "flag":
				return css`
					margin-left: 4px;
					margin-right: 4px;
					gap: 4px;
					${nullLabelDefaultStyle}

					svg {
						fill: ${props.$color ? props.$color : "#ffffff"};
						width: 16px;
						height: 16px;

						path {
							stroke: ${props.$color ? props.$color : "#ffffff"};
						}
					}
				`;
			case "colorBar":
				return css`
					width: 100%;
					height: ${props.$selected ? "24px" : "40px"};
					border-radius: 2px;
					${nullLabelDefaultStyle}

					.colorline {
						background-color: ${props.$color ? props.$color : "#ffffff"};
						border-radius: 4px 0px 0px 4px;
						height: ${props.$selected ? "24px" : "40px"};
						width: 4px;
					}
				`;
			case "colorBarFill":
				const minimizedColor = ColorUtils.hex2rgb(props.$color, 0.3, "string") as string;

				const colorBarFilledCommonStyles = css`
					width: ${props.$selected && !props.$inline ? "fit-content" : "100%"};
					padding-right: ${props.$selected && !props.$inline ? "8px" : ""};
					height: ${props.$selected ? "24px" : "40px"};
					border-radius: 2px;
					background-color: ${minimizedColor};
					${nullLabelDefaultStyle}

					.colorline {
						background-color: ${props.$color ? props.$color : "#ffffff"};
						border-radius: 4px 0px 0px 4px;
						height: ${props.$selected ? "24px" : "40px"};
						width: 4px;
					}
				`;

				if (hslColor.l >= 0.95) {
					return css`
						color: black;
						${colorBarFilledCommonStyles}
					`;
				} else {
					return css`
						color: white;
						${colorBarFilledCommonStyles}
					`;
				}
			default:
				const defaultcommonStyles = css`
					padding: 4px;
					margin-left: 4px;
					margin-right: 4px;
					width: ${props.$selected && !props.$inline ? "fit-content" : "100%"};
					height: ${props.$selected && !props.$inline ? "24px" : "32px"};
					border-radius: ${radius.sm};
					background-color: ${props.$color ? props.$color : "#ffffff"};

					${props.$inline
						? css`
								&.nullLabel {
									background-color: white;
									color: black;
									border-radius: ${radius.sm};
									border: 1px solid #c8c8c8;
									height: 32px;
									display: flex;
									justify-content: center;
									align-items: center;
									width: 100%;
								}
							`
						: nullLabelDefaultStyle}
				`;

				if (hslColor.l >= 0.95 || props.$color === null || props.$color === "") {
					return css`
						color: black;
						${defaultcommonStyles}
					`;
				} else {
					return css`
						color: white;
						${defaultcommonStyles}
					`;
				}
		}
	}}
`;

export const renderIconComponent = (type: string) => {
	switch (type) {
		case "flag":
			return <Flag />;
		case "pill":
			return <Circle />;
		case "colorBar":
		case "colorBarFill":
			return <div className="colorline"></div>;
		default:
			return null;
	}
};

export const SingleSelectInputV5 = (props: IInputPropsV5<ISingleSelectFieldSettingsDefinition>) => {
	const {value, onChange, disabled, onFocusLossForceBlur, inline, focusLossStarterEvent, focused, isSameWidth, dropdownIcon} = props;
	const list = [...props.dataTypeSettings.choiceList] || [];
	const type = props.dataTypeSettings?.type;

	const selectedOption = list.find((item) => item.value === value);
	const selectedIsRemoved = value && !selectedOption;

	if (selectedIsRemoved) {
		list.push({value: value, color: ""});
	}

	let focus = focused;

	const onFocus = (value: boolean) => {
		if (!value) {
			focus = false;
		}
	};

	const onClick = () => {
		props.onClick?.();
	};

	const renderPill = (option: {color: string; value: string}) => {
		return (
			<PillStyled
				$color={option.color ? `#${option.color}` : ""}
				$type={type}
			>
				{renderIconComponent(type)}
				{option.value}
			</PillStyled>
		);
	};

	const renderSelectedOption = (selected: string | null) => {
		const optionsClone = list.slice();

		return (
			<>
				{optionsClone.map((option) => {
					if (typeof option === "object" && option !== null) {
						const result = renderColorValue(option, selected);

						if (result && result.value === selected) {
							return (
								<PillStyled
									$color={result.color ? `#${result.color}` : ""}
									$type={type}
									$inline={inline}
									$selected={true}
								>
									{renderIconComponent(type)}
									{selected}
								</PillStyled>
							);
						}
					}
					return null;
				})}
				{!selected && (
					<PillStyled
						className="nullLabel"
						$type={type}
						$inline={inline}
					>
						{"-"}
					</PillStyled>
				)}
			</>
		);
	};

	const renderColorValue = (option: {color: string; value: string}, selected: string) => {
		if (option.value === selected) {
			return {color: option.color, value: option.value};
		}
	};

	const fetchValueFromOption = (option: {color: string; value: string} | null) => {
		if (option === null) {
			onChange?.(null);
		} else {
			onChange?.(option.value);
		}
	};

	const renderOptionValue = (option: {color: string; value: string}) => {
		return option.value;
	};

	return (
		<SelectInputV5
			options={list}
			selected={value}
			type={type}
			disabled={disabled}
			nullOption={true}
			fullWidth={true}
			sort={false}
			focused={focus}
			onClick={onClick}
			onFocus={onFocus}
			horizontalAlignment={inline ? HorizontalAlignment.center : undefined}
			isSameWidth={isSameWidth}
			inline={inline}
			dropdownIcon={false}
			isSingleSelect={true}
			render={renderPill}
			onChange={fetchValueFromOption}
			dropdownMaxHeight={"208px"}
			renderOptionValue={renderOptionValue}
			renderSelectedWhenClosed={renderSelectedOption}
			nullLabel="-"
			closeIcon={true}
			stringifyOption={renderOptionValue}
			selectedIsRemoved={selectedIsRemoved}
		/>
	);
};

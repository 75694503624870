import * as React from "react";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";

interface ISingleLineLabelProps {
	readonly value: string;
}

export class UserLabel extends React.PureComponent<ISingleLineLabelProps> {
	public override render() {
		const {value} = this.props;

		// Converts user id -> user.fullName
		const formatted = FieldDataTypes.map[FieldDataType.User].formatter(value);

		return formatted || "";
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {computed, makeObservable} from "mobx";
import styled from "styled-components";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {Markup} from "../../../../../data/models/Markup";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {getDefaultMarkupFontSize} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupText.utils";
import {THREEUtils} from "../../../../../utils/THREEUtils";
import {MarkupSidePadding} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {ColorUtils} from "../../../../../utils/ColorUtils";
import {MarkupsWithTextOffset} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import {onMarkupTextInputChange} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";

interface IMarkupTextInputElementProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly markup3D: Markup3D;
}

@observer
export class MarkupTextInputElementV5 extends React.Component<IMarkupTextInputElementProps> {
	private _ref = React.createRef<HTMLDivElement>();

	constructor(props: IMarkupTextInputElementProps) {
		super(props);
		makeObservable(this);
	}

	@computed
	private get _text() {
		return this._markup?.text || Markup.defaultText;
	}

	private get _markup() {
		return this.props.markup3D.modelData as Markup;
	}

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private getVerticalAlignment() {
		const text = this._text;

		switch (text.verticalAlignment) {
			case VerticalAlignment.top:
				return "flex-start";
			case VerticalAlignment.center:
				return "center";
			case VerticalAlignment.bottom:
				return "flex-end";
		}
	}

	// https://stackoverflow.com/questions/4233265/contenteditable-set-caret-at-the-end-of-the-text-cross-browser
	private placeCaretAtEnd() {
		const el = this._ref.current;

		if (el) {
			el.focus();
			if (typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
				var range = document.createRange();

				range.selectNodeContents(el);
				range.collapse(false);
				var sel = window.getSelection();

				sel.removeAllRanges();
				sel.addRange(range);
			} else if (typeof (document.body as any).createTextRange != "undefined") {
				var textRange = (document.body as any).createTextRange();

				textRange.moveToElementText(el);
				textRange.collapse(false);
				textRange.select();
			}
		}
	}

	public onTextInputChange = () => {
		const {markup3D} = this.props;

		onMarkupTextInputChange(markup3D, this.innerText, this.forceUpdateArrow);
	};

	public clearText() {
		if (this._ref.current) {
			this._ref.current.innerText = "";
			this.onTextInputChange();
		}
	}

	public get innerText() {
		return this._ref.current?.innerText ?? "";
	}

	public override componentWillUnmount() {
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.forceUpdateArrow);
		spaceViewRenderer.signals.onCanvasResized.remove(this.forceUpdateArrow);
	}

	public override componentDidMount() {
		this.placeCaretAtEnd();
		const {spaceViewRenderer} = this.props;

		spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.forceUpdateArrow);
		spaceViewRenderer.signals.onCanvasResized.add(this.forceUpdateArrow);
		spaceViewRenderer.spaceItemController.markupTextManager.hideTextGroup([this.props.markup3D]);
		spaceViewRenderer.needsRender = true;

		const markupData = this.props.markup3D.modelData as Markup;

		if (markupData && !markupData.text.content) {
			markupData.applyTextData({
				fontSize: getDefaultMarkupFontSize(markupData.type, spaceViewRenderer.toolManager.cameraControls.cameraZoomValue),
			});

			this.forceUpdate();
		}
	}

	public override render() {
		const markup3D = this.props.markup3D;
		const worldPos = markup3D.getTextWorldPosition() || markup3D.position;
		const dimensions = markup3D.scale;

		const text = this._text;

		const style = THREEUtils.getStyleForFloatingUIElement(
			worldPos.x,
			worldPos.y,
			this.props.spaceViewRenderer.spaceOffset.z,
			this.props.spaceViewRenderer,
		) as React.CSSProperties;
		const correctionMultiplier = this.props.spaceViewRenderer.correctionMultiplier.current;

		if (!MarkupsWithTextOffset.includes(markup3D.type)) {
			style.width = `${dimensions.x / correctionMultiplier}px`;
		}
		style.minHeight = `${dimensions.y / correctionMultiplier}px`;

		style.textAlign = `${HorizontalAlignment[text.horizontalAlignment]}` as "left" | "center" | "right";
		const {cameraControls} = this.props.spaceViewRenderer.toolManager;

		style.transform += ` rotate(${cameraControls.azimuthAngle - markup3D.orientation - markup3D.textOrientation}rad) scale(${cameraControls.cameraZoomValue})`;

		style.color = ColorUtils.hex2rgb(text.fontColor.hex, 1 - text.fontColor.transparency, "string") as string;
		style.fontFamily = text.fontFamily;
		style.fontSize = `${text.fontSize}px`;
		style.lineHeight = style.fontSize;
		style.paddingLeft = `${MarkupSidePadding}px`;
		style.paddingRight = style.paddingLeft;

		if (text.isBold) {
			style.fontWeight = "bold";
		}
		if (text.isItalic) {
			style.fontStyle = "italic";
		}
		if (text.isUnderlined) {
			style.textDecoration = "underline";
		}

		style.justifyContent = this.getVerticalAlignment();

		return (
			<MarkupTextInputElementStyled
				ref={this._ref}
				contentEditable={true}
				suppressContentEditableWarning={true}
				className="MarkupTextInputElement vbox"
				style={style}
				onInput={this.onTextInputChange}
			>
				{text.content}
			</MarkupTextInputElementStyled>
		);
	}
}

const MarkupTextInputElementStyled = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	background: rgba(0, 0, 0, 0);
	outline: none;
	white-space: nowrap;
	overflow: hidden;
	cursor: text;
	white-space: pre-line;
	z-index: 1;
`;

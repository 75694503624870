import * as React from "react";
import {Functions} from "../../../../../utils/function/Functions";
import {FilterSwitch} from "./FilterSwitch.styled";

interface IToggleSwitchFieldProps {
	readonly value: boolean;
	readonly onChange: (value: boolean) => void;
	readonly label: string;
	readonly labelFirst?: boolean; // changes the order of toggler and label
	readonly classNames?: string;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly onMouseOver?: () => void;
	readonly onMouseLeave?: () => void;
}

export class FilterSwitchV5 extends React.Component<IToggleSwitchFieldProps> {
	private onLabelClick = (event: React.MouseEvent) => {
		this.props.onChange(!this.props.value);
	};

	public override render() {
		const {label, classNames, divRef, onMouseLeave, onMouseOver} = this.props;

		let style: React.CSSProperties = {};

		if (this.props.labelFirst) {
			style.flexDirection = "row-reverse";
			style.justifyContent = "flex-end";
		}

		return (
			<FilterSwitch
				ref={divRef}
				className={`FilterSwitch ${classNames || ""}`}
				style={style}
				onMouseOver={onMouseOver ? onMouseOver : Functions.emptyFunction}
				onMouseLeave={onMouseLeave ? onMouseLeave : Functions.emptyFunction}
			>
				<label
					className="label"
					onClick={this.onLabelClick}
				>
					{label}
				</label>
			</FilterSwitch>
		);
	}
}

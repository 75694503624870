import * as React from "react";
import type {ScreenType} from "../../modules/abstract/ScreenType";

interface IStackChildProps {
	readonly children: React.ReactNode;
	readonly id: ScreenType;
}

export class StackChild extends React.Component<IStackChildProps> {
	public override render() {
		return this.props.children ?? <React.Fragment key={this.props.id}></React.Fragment>;
	}
}

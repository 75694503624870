import {makeObservable, observable} from "mobx";
import {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import {CaptionManager} from "../MSDF/CaptionManager";
import type {
	BoundaryCreateDetail,
	BoundarySpaceMapDto,
	BoundaryUpdateDetail,
	CreateBoundaryRequest,
	PointDouble,
	UpdateBoundarySpaceMapRequest,
} from "../../../../../../../generated/api/base";
import {BoundaryGeometryType, XyiconFeature} from "../../../../../../../generated/api/base";
import {THREEUtils} from "../../../../../../../utils/THREEUtils";
import type {Boundary, IBoundaryMinimumData} from "../../../../../../../data/models/Boundary";
import type {BoundarySpaceMap} from "../../../../../../../data/models/BoundarySpaceMap";
import {XHRLoader} from "../../../../../../../utils/loader/XHRLoader";
import {ObjectUtils} from "../../../../../../../utils/data/ObjectUtils";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import {notify} from "../../../../../../../utils/Notify";
import {NotificationType} from "../../../../../../notification/Notification";
import {Collection} from "../../../../../../../data/models/abstract/Collection";
import type {IModel} from "../../../../../../../data/models/Model";
import {EditableItemManager} from "./EditableItemManager";

export class BoundaryManager extends EditableItemManager {
	private _isSnapToAngleActive: boolean;
	private _boundaryTypeId: string;
	@observable
	protected _items: Collection<BoundarySpaceMap3D> = new Collection();
	protected override _currentlyEditedItem: BoundarySpaceMap3D = null;
	private _captionManager: CaptionManager;
	public currentlyRedrawnBoundary: BoundarySpaceMap3D = null;

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		super(spaceViewRenderer, "boundary");
		makeObservable(this);
		this._captionManager = new CaptionManager(this._spaceViewRenderer, "boundary");
	}

	public override init() {
		super.init();

		THREEUtils.setPosition(this._container, this._container.position.x, this._container.position.y, this._spaceViewRenderer.spaceOffset.z);
	}

	private loadDataFromLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const savedValue = this._spaceViewRenderer.transport.services.localStorage.get(this.getKeyLocalStorageForSnapToAngle(organizationId));

			// We only set it to false if the saved value is false. If nothing is saved (value == null),
			// we set it to true by default
			this._isSnapToAngleActive = savedValue !== false;
		}
	}

	private saveDataToLocalStorage() {
		const organizationId = this._spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			this._spaceViewRenderer.transport.services.localStorage.set(this.getKeyLocalStorageForSnapToAngle(organizationId), this._isSnapToAngleActive);
		}
	}

	private getKeyLocalStorageForSnapToAngle(organizationId: string) {
		return `srv4-org-${organizationId}-snap-to-angle`;
	}

	public createSpaceItem3DFromModel(data: IBoundaryMinimumData) {
		const boundarySpaceMap3D = new BoundarySpaceMap3D(this._spaceViewRenderer, data.boundaryTypeId ?? data.typeId, data.fieldData);

		boundarySpaceMap3D.updateByModel(data, false);

		return boundarySpaceMap3D;
	}

	public setTypeId(typeId: string) {
		this._boundaryTypeId = typeId;
	}

	protected async addItems(items: BoundarySpaceMap3D[], addToServer: boolean = true) {
		for (const item of items) {
			item.finalize();
		}

		if (addToServer) {
			const appState = this._spaceViewRenderer.transport.appState;
			const spaceID = appState.space.id;
			const portfolioID = appState.portfolioId;

			const boundaryCreateDetail: BoundaryCreateDetail[] = [];

			for (const item of items) {
				const data = item.data;
				const boundingBox = item.boundingBox;

				boundaryCreateDetail.push({
					boundaryTypeID: data.boundaryTypeId,
					spaceID: spaceID,
					linkedXyiconList: item.getXyiconsWithOverlap(),
					linkedBoundarySpaceMapIDList: item
						.getRelatedBoundaries("child")
						.map((boundarySpaceMap3D: BoundarySpaceMap3D) => boundarySpaceMap3D.modelData.id),
					linkedParentBoundarySpaceMapIDList: item
						.getRelatedBoundaries("parent")
						.map((boundarySpaceMap3D: BoundarySpaceMap3D) => boundarySpaceMap3D.modelData.id),
					captionX: 0,
					captionY: 0,
					geometryType: BoundaryGeometryType.Polygon,
					geometry: {
						rectangleShape: null,
						circleShape: null,
						polygonShape: {
							vertices: data.geometryData,
						},
					},
					minX: boundingBox.min.x,
					maxX: boundingBox.max.x,
					minY: boundingBox.min.y,
					maxY: boundingBox.max.y,
					area: THREEUtils.calculateArea(data.geometryData),
					orientation: item.orientation,
					fieldValues: data.fieldData,
				});
			}

			if (boundaryCreateDetail.length > 0) {
				try {
					const createData: CreateBoundaryRequest = {
						portfolioID: portfolioID,
						boundaryCreateDetail,
					};

					const boundaryModels = await this._spaceViewRenderer.transport.services.feature.create<Boundary>(createData, XyiconFeature.Boundary);

					if (boundaryModels?.length === items.length) {
						for (let i = 0; i < boundaryModels.length; ++i) {
							const item = items[i];
							const boundaryModel = boundaryModels[i];
							const boundarySpaceMapModel = [...boundaryModel.boundarySpaceMaps][0];

							item.addModelData(boundarySpaceMapModel);
							this.triggerUpdateStateForFieldsWithValidation(boundaryCreateDetail[i], boundaryModel);
							boundarySpaceMapModel.setParent(boundaryModel);
							item.addCaption(this._captionManager.getActiveCaptionFields());
						}
					} else {
						for (const item of items) {
							item.destroy();
						}

						notify(this._spaceViewRenderer.transport.appState.app.notificationContainer, {
							title: "Error while creating boundaries",
							type: NotificationType.Error,
							description: "Sorry, we couldn't process your request",
						});
					}

					this._spaceViewRenderer.needsRender = true;
				} catch (error) {
					console.warn(`Boundary not saved: ${error}`);
				}
			}
		}

		this._items.addMultiple(items);

		this.captionManager.recreateGeometry();
	}

	public addItemsByModel(models: BoundarySpaceMap[]) {
		const boundarySpaceMaps: BoundarySpaceMap3D[] = [];

		for (const model of models) {
			const existingModelMaybe = this._items.getById(model.id);
			const boundarySpaceMap3D = existingModelMaybe ?? new BoundarySpaceMap3D(this._spaceViewRenderer, model.boundaryTypeId);

			boundarySpaceMap3D.updateByModel(model);
			if (!existingModelMaybe) {
				boundarySpaceMaps.push(boundarySpaceMap3D);
			}
		}

		this.add(boundarySpaceMaps, false);

		return boundarySpaceMaps;
	}

	public async initBoundaries(boundaries: Boundary[]) {
		this.loadDataFromLocalStorage();
		const spaceID = this._spaceViewRenderer.transport.appState.space?.id;

		if (spaceID) {
			await this._captionManager.init();

			const boundarySpaceMaps: BoundarySpaceMap[] = [];

			for (const boundary of boundaries) {
				for (const boundarySpaceMap of boundary.boundarySpaceMaps) {
					if (boundarySpaceMap.spaceId === spaceID) {
						boundarySpaceMaps.push(boundarySpaceMap);
					}
				}
			}

			this.addItemsByModel(boundarySpaceMaps);
		}
	}

	public async updateItems(items: BoundarySpaceMap3D[], force: boolean = false) {
		const boundarySpaceMaps = [...items]; // the original array can be modified (eg.: length = 0), and it can cause bugs if we don't clone the array like this
		const boundaryUpdateDetail: BoundaryUpdateDetail[] = [];

		const appState = this._spaceViewRenderer.transport.appState;
		const spaceID = appState.space.id;
		const portfolioId = appState.portfolioId;

		for (const item of boundarySpaceMaps) {
			const modelData = item.modelData as BoundarySpaceMap;
			const previousData = {
				geometry: {
					rectangleShape: null as {bottomLeft: PointDouble},
					circleShape: null as {center: PointDouble; radius: number},
					polygonShape: {
						vertices: modelData.geometryData,
					},
				},
				orientation: modelData.orientation,
			};

			const currentData = item.data;

			const dataToCompare = {
				geometry: {
					rectangleShape: null as {bottomLeft: PointDouble},
					circleShape: null as {center: PointDouble; radius: number},
					polygonShape: {
						vertices: currentData.geometryData,
					},
				},
				orientation: currentData.orientation,
			};

			const hasChanged = force || JSON.stringify(previousData) !== JSON.stringify(dataToCompare);

			if (hasChanged) {
				const boundingBox = item.boundingBox;

				const dataToSend: BoundaryUpdateDetail = {
					...dataToCompare,
					boundarySpaceMapID: modelData.id,
					spaceID: spaceID,
					minX: boundingBox.min.x,
					maxX: boundingBox.max.x,
					minY: boundingBox.min.y,
					maxY: boundingBox.max.y,
					area: THREEUtils.calculateArea(currentData.geometryData),
					linkedXyiconList: item.getXyiconsWithOverlap(),
					linkedBoundarySpaceMapIDList: item
						.getRelatedBoundaries("child")
						.map((boundarySpaceMap3D: BoundarySpaceMap3D) => boundarySpaceMap3D.modelData.id),
					linkedParentBoundarySpaceMapIDList: item
						.getRelatedBoundaries("parent")
						.map((boundarySpaceMap3D: BoundarySpaceMap3D) => boundarySpaceMap3D.modelData.id),
				};

				boundaryUpdateDetail.push(dataToSend);
			}
		}

		if (boundaryUpdateDetail.length > 0) {
			try {
				const params: UpdateBoundarySpaceMapRequest = {
					boundaryUpdateDetail: boundaryUpdateDetail,
					portfolioID: portfolioId,
				};

				const {result} = await this._spaceViewRenderer.transport.requestForOrganization<BoundarySpaceMapDto[]>({
					url: "boundaries/update",
					method: XHRLoader.METHOD_POST,
					params: params,
				});

				const updatedBoundaries: IModel[] = [];

				for (const newModelData of result) {
					const boundarySpaceMap3D = boundarySpaceMaps.find((item) => item.modelData.id === newModelData.boundarySpaceMapID);

					boundarySpaceMap3D.applyModelData(ObjectUtils.apply(JSON.parse(JSON.stringify((boundarySpaceMap3D.modelData as any).data)), newModelData));
					updatedBoundaries.push(boundarySpaceMap3D);
				}
				this.signals.itemsUpdate.dispatch(updatedBoundaries);
			} catch (error) {
				console.warn("Items couldn't be updated on the backend!");
				console.warn(error);
			}
		}
	}

	/**
	 * Hides captions of selected boundaries
	 */
	public hideCaptionsForSelected() {
		const selectedItems = this._selectedItems as BoundarySpaceMap3D[];

		for (const boundary of selectedItems) {
			boundary.hideCaption();
		}
		this._captionManager.hideTextGroup(selectedItems.filter(CaptionManager.filterVisibleCaptionedItems).map((b) => b.caption));
	}

	/**
	 * Shows captions of selected boundaries
	 */
	public showCaptionsForSelected() {
		const selectedItems = this._selectedItems as BoundarySpaceMap3D[];

		for (const boundary of selectedItems) {
			boundary.showCaption();
		}
		if (selectedItems.length > 0) {
			this._captionManager.updateTextTransformations();
		}
	}

	public hideCaptions() {
		this._captionManager.hide();
	}

	public showCaptions() {
		this._captionManager.show();
	}

	public override translateSelectedItems(x: number, y: number, z: number) {
		super.translateSelectedItems(x, y, z);
		this.hideCaptionsForSelected();
	}

	public override stopTranslatingSelectedItems() {
		super.stopTranslatingSelectedItems();
		this.showCaptionsForSelected();
	}

	public deleteSelectedVertex() {
		if (this._currentlyEditedItem) {
			this._currentlyEditedItem.deleteSelectedVertex();
		}
	}

	public onLinePointerDown(index: number, worldX: number, worldY: number, createVertexHere: boolean) {
		return this._currentlyEditedItem.onLinePointerDown(index, worldX, worldY, createVertexHere);
	}

	public onLinePointerMove(deltaX: number, deltaY: number) {
		this._currentlyEditedItem.onLinePointerMove(deltaX, deltaY);
	}

	public setSnapToAngle(active: boolean) {
		this._isSnapToAngleActive = active;
		this.saveDataToLocalStorage();
	}

	public override clear() {
		super.clear();
		this._captionManager.clear();
	}

	public get typeId() {
		return this._boundaryTypeId;
	}

	public get captionManager() {
		return this._captionManager;
	}

	public get isSnapToAngleActive() {
		return this._isSnapToAngleActive && !KeyboardListener.isAltDown;
	}
}

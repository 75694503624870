import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {IDateFieldSettingsDefinition} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {TextInputV5} from "./TextInputV5";

const getErrorMessage = (value: string): string => {
	return !value || StringUtils.emailValidator(value) ? "" : "Enter a valid Email!";
};

export const EmailInputV5 = (props: IInputPropsV5<IDateFieldSettingsDefinition>) => {
	const {value, onInput, onBlur, inline, disabled, noButtons, onChange, caretPosition, onClick} = props;

	return (
		<div className="hbox field-input-container">
			<TextInputV5
				value={value}
				onInput={onInput}
				onChange={onChange}
				onBlur={onBlur}
				caretPosition={caretPosition}
				autoFocus={true}
				disabled={disabled}
				inline={inline}
				getErrorMessage={getErrorMessage}
				onClick={onClick}
			/>
		</div>
	);
};

import {computed, observable, makeObservable} from "mobx";
import type {BoundarySpaceMapDto, PointDouble} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import {THREEUtils} from "../../utils/THREEUtils";
import {ImageUtils} from "../../utils/image/ImageUtils";
import type {IRGBObject} from "../../utils/ColorUtils";
import {ColorUtils} from "../../utils/ColorUtils";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {SpaceItemType} from "../../ui/modules/space/spaceeditor/logic3d/managers/spaceitems/ItemManager";
import {MathUtils} from "../../utils/math/MathUtils";
import type {Boundary, IBoundaryMinimumData} from "./Boundary";
import type {IEditableItemModel, ISpaceItemModel} from "./Model";

export class BoundarySpaceMap implements IEditableItemModel, ISpaceItemModel, IBoundaryMinimumData, BoundarySpaceMapDto {
	public readonly ownFeature = XyiconFeature.Boundary;

	@observable
	private _data: BoundarySpaceMapDto;
	private _parent: Boundary;

	constructor(data: BoundarySpaceMapDto, parent: Boundary) {
		makeObservable(this);
		this.setParent(parent);
		this.applyData(data);
	}

	public applyData(data: Partial<BoundarySpaceMapDto>) {
		this._data = {
			...this._data,
			...data,
		};
		const numberOfVertices = this._data.geometry?.polygonShape?.vertices?.length ?? 0;

		if (MathUtils.isValidNumber(numberOfVertices) && numberOfVertices < 3) {
			console.warn(
				`Boundary ${this._parent?.refId}'s space map (id: ${this._data.boundarySpaceMapID}) is not a valid polygon, it has ${numberOfVertices} vertices!`,
			);
		}
	}

	public setParent(parent: Boundary) {
		if (this._parent) {
			this._parent.boundarySpaceMaps.delete(this);
		}

		this._parent = parent;

		if (!this._parent.boundarySpaceMaps.has(this)) {
			this._parent.boundarySpaceMaps.add(this);
		}
	}

	@computed
	public get id() {
		return this._data.boundarySpaceMapID;
	}

	public setSpaceId(spaceId: string) {
		this._data.spaceID = spaceId;
	}

	@computed
	public get spaceId() {
		return this._data.spaceID;
	}

	@computed
	private get space() {
		return this._parent.appState.actions.getSpaceById(this._data.spaceID);
	}

	@computed
	public get spaceName() {
		return this.space?.name || "";
	}

	public get fieldData() {
		return this._parent.fieldData;
	}

	public setGeometryData(geometryData: PointDouble[]) {
		this._data.geometry.polygonShape.vertices = geometryData;
	}

	public setOrientation(orientation: number) {
		this._data.orientation = orientation;
	}

	public setPosition(newPos: PointDouble) {
		const center = this.position;
		const xDiff = newPos.x - center.x;
		const yDiff = newPos.y - center.y;

		const newGeometryData = this.geometryData.map((data) => {
			return {
				x: data.x + xDiff,
				y: data.y + yDiff,
			};
		});

		this.setGeometryData(newGeometryData);
	}

	public setDimensions(newDimensions: PointDouble) {
		const xMultiplier = newDimensions.x / this.dimension.x;
		const yMultiplier = newDimensions.y / this.dimension.y;

		const unrotatedVertices = THREEUtils.getRotatedVertices(this.geometryData, -this.orientation);

		const newUnrotatedGeometryData = unrotatedVertices.map((data) => {
			return {
				x: (data.x - this.positionX) * xMultiplier + this.positionX,
				y: (data.y - this.positionY) * yMultiplier + this.positionY,
			};
		});

		const newGeometryData = THREEUtils.getRotatedVertices(newUnrotatedGeometryData, this.orientation);

		this.setGeometryData(newGeometryData);
	}

	public get geometryData() {
		// switch (this._data.geometryType)
		// {
		// 	case BoundaryGeometryType.Polygon:
		// 		return [...this._data.geometry.polygonShape.vertices];
		// 	/*case BoundaryGeometryType.Circle:
		// 		return this._data.geometry.circleShape;*/
		// }
		return [...this._data.geometry.polygonShape.vertices];
	}

	public get scale() {
		let unrotatedGeometryData = THREEUtils.cloneGeometryData(this.geometryData);

		unrotatedGeometryData = THREEUtils.getRotatedVertices(unrotatedGeometryData, -this.orientation);
		const unrotatedBbox = THREEUtils.calculateBox(unrotatedGeometryData);
		return THREEUtils.getSizeOfBoundingBox2(unrotatedBbox);
	}

	public get type() {
		return this._parent.type;
	}

	public get typeId() {
		return this._parent.typeId;
	}

	public get typeName() {
		return this.type?.name || "";
	}

	public get parent() {
		return this._parent;
	}

	@computed
	public get dimension() {
		const unrotatedVertices = THREEUtils.getRotatedVertices(this.geometryData, -this.orientation);
		const bbox = THREEUtils.calculateBox(unrotatedVertices);

		return THREEUtils.getSizeOfBoundingBox2(bbox);
	}

	@computed
	public get dimensionX() {
		return this.dimension.x;
	}

	@computed
	public get dimensionY() {
		return this.dimension.y;
	}

	@computed
	public get dimensionZ() {
		// TODO
		return 0;
	}

	@computed
	public get position() {
		return {
			...THREEUtils.getCenterFromGeometryData(this.geometryData, this.orientation),
			z: 0,
		};
	}

	@computed
	public get positionX() {
		return this.position.x;
	}

	@computed
	public get positionY() {
		return this.position.y;
	}

	@computed
	public get positionZ() {
		return this.position.z;
	}

	@computed
	public get thumbnail() {
		const canvas = ImageUtils.canvas;
		const ctx = ImageUtils.ctx;

		const dimension = Constants.RESOLUTION.XYICON;

		canvas.width = canvas.height = dimension;

		ctx.clearRect(0, 0, canvas.width, canvas.height);

		const geometryData = this.geometryData;
		const canvasCoords = THREEUtils.spaceCoordsToThumbnailCoords(geometryData, dimension, 1 / dimension);

		ctx.beginPath();

		ctx.moveTo(canvasCoords[0].x, canvasCoords[0].y);

		for (let i = 1; i < canvasCoords.length; ++i) {
			ctx.lineTo(canvasCoords[i].x, canvasCoords[i].y);
		}

		ctx.closePath();

		const color = this._parent.color.hex;

		ctx.strokeStyle = `#${color}`;
		const {r, g, b, a} = ColorUtils.hex2rgb(color, 0.1, "RGBObject") as IRGBObject;

		ctx.fillStyle = `rgba(${r}, ${g}, ${b}, ${a})`;

		ctx.stroke();
		ctx.fill();

		return canvas.toDataURL();
	}

	public get refId() {
		return this._parent.refId;
	}

	public get boundaryTypeId() {
		return this.typeId;
	}

	public get orientation() {
		return this._data.orientation ?? 0;
	}

	public get isBoundarySpaceMap() {
		return true;
	}

	@computed
	public get data() {
		return this._data;
	}

	public get spaceItemType(): SpaceItemType {
		return "boundary";
	}
}

import * as React from "react";
import * as ReactDOM from "react-dom";

export interface IDomPortalProps {
	readonly destination: HTMLElement;
	readonly children: React.ReactNode;
	readonly noPortal?: boolean;
}

export class DomPortal extends React.Component<IDomPortalProps> {
	public static _body = document.body;

	public override render() {
		const {children, destination, noPortal} = this.props;

		return noPortal || !destination ? children : ReactDOM.createPortal(children, destination);
	}
}

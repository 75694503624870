import * as React from "react";
import {inject, observer} from "mobx-react";
import {Splitter} from "../../../../widgets/splitter/Splitter";
import type {IError, TransportLayer} from "../../../../../data/TransportLayer";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {Field} from "../../../../../data/models/field/Field";
import type {Type} from "../../../../../data/models/Type";
import type {AppState} from "../../../../../data/state/AppState";
import type {IModel} from "../../../../../data/models/Model";
import type {IHeader} from "../../../../widgets/table/SimpleTable";
import {SimpleTable} from "../../../../widgets/table/SimpleTable";
import {ActionBar} from "../../../abstract/bars/ActionBar";
import {ReactUtils} from "../../../../utils/ReactUtils";
import {SortDirection} from "../../../../../data/models/ViewUtils";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {WarningWindow} from "../../../abstract/popups/WarningWindow";
import {PopupUtils} from "../../../abstract/popups/PopupUtils";
import {Button} from "../../../../widgets/button/Button";
import {SearchField} from "../../../../widgets/input/search/SearchField";
import {EmptyListView} from "../../../abstract/grid/EmptyListView";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";

interface ISettingTableTabProps<T> {
	readonly headers: IHeader[];
	readonly feature: XyiconFeature;
	readonly typesFeature: XyiconFeature;
	readonly kind: "types" | "fields";
	readonly getFields: (item: T, index?: number) => (string | number)[];
	readonly right: (selected: T[]) => React.ReactNode;
	readonly create: (onCancel: () => void, onCreated: (id: string) => void) => React.ReactNode;
	readonly emptyListText?: string;
	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

interface ISettingTableTabState {
	selectedIds: string[];
	creating: boolean;
	didMount: boolean; // this is for opacity animation
	searchString: string;
	isLoading: boolean;
}

@inject("transport")
@inject("appState")
@observer
export class SettingTableTab<T extends IModel = Type | Field> extends React.Component<ISettingTableTabProps<T>, ISettingTableTabState> {
	// This is for actually checking whether the component is currently mounted
	private _isMounted: boolean = false;

	constructor(props: ISettingTableTabProps<T>) {
		super(props);
		this.state = {
			selectedIds: [],
			creating: false,
			didMount: false,
			searchString: "",
			isLoading: true,
		};
	}

	private getTypesFeature(props: ISettingTableTabProps<T>) {
		return props.kind === "types" ? props.typesFeature : props.feature;
	}

	private getPropType(props: ISettingTableTabProps<T>) {
		return props.kind === "types";
	}

	private onAddClicked = () => {
		this.setState({creating: true});
	};

	private onDeleteClicked = async () => {
		const {transport, kind} = this.props;
		const selectedIds = this.state.selectedIds;
		const count = selectedIds.length;
		const feature = this.getTypesFeature(this.props);
		const isTypeFeature = this.getPropType(this.props);

		let isSafeToDeleteResponse: {
			result: any;
			error: IError;
		} = {
			result: null,
			error: null,
		};
		if (isTypeFeature) {
			isSafeToDeleteResponse = await transport.requestForOrganization({
				url: "types/issafetodelete",
				method: XHRLoader.METHOD_POST,
				params: {
					featureTypeIDList: selectedIds,
					feature: feature,
				},
			});
		}

		if (isSafeToDeleteResponse?.result?.inUseList.length > 0) {
			await WarningWindow.open("You can't delete this type because it's currently being used.");
		} else {
			if (this.isDeleteAllowed(selectedIds)) {
				const confirmed = await PopupUtils.getDeleteConfirmationPopup(null, count);

				if (confirmed) {
					await transport.services.typefield.remove(kind, selectedIds, this.getTypesFeature(this.props));
					this.setState({selectedIds: []});
				}
			} else {
				await WarningWindow.open("You can't delete this type because it's currently being used.");
			}
		}
	};

	private initList() {
		const {transport} = this.props;
		const features = this.getListFeatures();

		const promises: Promise<IModel[]>[] = [];

		if (this.props.appState.portfolioId) {
			for (const feature of features) {
				promises.push(transport.services.feature.refreshList(feature));
			}
		}

		return Promise.all(promises);
	}

	private isDeleteAllowed(ids: string[]) {
		if (this.props.kind === "types") {
			const {appState} = this.props;

			const features = this.getListFeatures();

			for (const feature of features) {
				const list = appState.actions.getList(feature);

				for (const item of list) {
					if (item.typeId && ids.includes(item.typeId)) {
						return false;
					}
				}
			}
		}
		return true;
	}

	private getListFeatures() {
		const {typesFeature} = this.props;
		const features = [typesFeature];

		if (typesFeature === XyiconFeature.Xyicon) {
			features.push(XyiconFeature.XyiconCatalog); // Catalog items also use type feature
		}

		return features;
	}

	private onCancelCreate = () => {
		this.setState({creating: false});
	};

	private onCreated = (createdId: string) => {
		this.setState({creating: false, selectedIds: [createdId]});
		// TODO scrollto there
	};

	private onTableSearch = (value: string) => {
		this.setState({searchString: value.toLowerCase()});
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case KeyboardListener.KEY_ESCAPE:
				this.setState({creating: false});
				break;
			case KeyboardListener.KEY_DELETE:
				this.onDeleteClicked();
				break;
		}
	};

	public override async componentDidMount() {
		this._isMounted = true;
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		this.setState({selectedIds: []});

		window.setTimeout(() => {
			this.setState({didMount: true});
		}, 0);

		await this.initList();

		if (this._isMounted) {
			this.setState({
				isLoading: false,
			});
		}
	}

	public override componentWillUnmount() {
		this.setState({didMount: false});
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		this._isMounted = false;
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<ISettingTableTabProps<T>>, nextContext: any): void {
		if (nextProps.feature !== this.props.feature) {
			this.setState({selectedIds: []});
		}
	}

	public override render() {
		const {headers, getFields, feature, kind, appState, emptyListText} = this.props;
		const {selectedIds, didMount, creating, searchString} = this.state;

		const data =
			kind === "fields" ? (appState.fields[feature] as unknown as T[]) : (appState.types[this.getTypesFeature(this.props)] as unknown as T[]);

		const selected = selectedIds.map((id) => data.find((item) => item.id === id)).filter((item) => !!item);

		return (
			<div className={ReactUtils.cls("SettingsTableTab", {loaded: didMount})}>
				<div className={ReactUtils.cls("createPanel", {open: creating})}>{creating && this.props.create(this.onCancelCreate, this.onCreated)}</div>
				<Splitter className={ReactUtils.cls({blurred: creating})}>
					{/* This undefined is needed for the Splitter, in order to use the toggling function. */}
					{undefined}
					<div className="vbox flex_1 left">
						<div className="hbox alignCenter aboveTable">
							<SearchField
								value={searchString}
								placeholder={"Find ..."}
								onInput={this.onTableSearch}
							/>
							<ActionBar>
								<Button
									className="large gray"
									label="Create"
									title="Create"
									icon="add"
									onClick={this.onAddClicked}
								/>
								<Button
									className="large gray"
									label="Delete"
									title="Delete"
									icon="delete"
									onClick={this.onDeleteClicked}
									disabled={selected.length === 0 || this.state.isLoading}
								/>
							</ActionBar>
						</div>
						{data.length > 0 ? (
							<SimpleTable
								data={data}
								headers={headers}
								getFields={getFields}
								selected={selected}
								onSelect={(selected) => {
									this.setState({selectedIds: selected.map((item) => item.id)});
								}}
								defaultSort={{
									column: "name", // TODO
									direction: SortDirection.ASC,
								}}
								tableSearchQuery={searchString}
							/>
						) : (
							<EmptyListView text={emptyListText} />
						)}
					</div>
					<div className="vbox SidePanel right">{this.props.right(selected)}</div>
				</Splitter>
			</div>
		);
	}
}

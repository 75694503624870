import type {ComponentType} from "react";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import {
	isNotAnyOf,
	isAnyOf,
	contains,
	doesNotContain,
	isBlank,
	isEndingWith,
	isEqualTo_str,
	isGreaterThan,
	isGreaterThanOrEqualTo,
	isInBetween,
	isLessThan,
	isLessThanOrEqualTo,
	isNotBetween,
	isNotBlank,
	isNotEqualTo_str,
	isStartingWith,
	isNotEqualTo_num,
	isEqualTo_num,
	isEqualToDate,
	isLessThanDate,
	isGreaterThanDate,
	isInToday,
	isInYesterday,
	inThisWeek,
	inThisMonth,
	inLastWeek,
	inLastMonth,
	isAtTime,
	isBeforeTime,
	isAfterTime,
	isInBetweenDate,
	isNotBetweenDate,
	inTheLastExplicitDate,
	inTheNextExplicitDate,
	isBetweenTime,
	isTrue,
	isFalse,
} from "../../../../data/models/filter/operator/FilterOperatorMethods";
import type {IFilterOperatorMethod} from "../../../../data/models/filter/operator/FilterOperatorMethods";
import {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import {AnyParamV5} from "../advance/controls/AnyParamV5";
import {BetweenDateParamV5} from "../advance/controls/BetweenDateParamV5";
import {BetweenParamV5} from "../advance/controls/BetweenParamV5";
import {BetweenTimeParamV5} from "../advance/controls/BetweenTimeParamV5";
import {DateParamV5} from "../advance/controls/DateParamV5";
import {ExplicitDateParamV5} from "../advance/controls/ExplicitDateParamV5";
import {NumberParamV5} from "../advance/controls/NumberParamV5";
import {StringParamV5} from "../advance/controls/StringParamV5";
import {TimeParamV5} from "../advance/controls/TimeParamV5";

type FilterParamControlV5 = ComponentType<{
	param: any;
	onChange(param: any): void;
	onFocus?(value: boolean): void;
	field?: IFieldAdapter;
}>;

export interface IFilterOperatorConfigV5 {
	id: FilterOperator;
	label: string;
	method: IFilterOperatorMethod;
	control: FilterParamControlV5;
	validator: (param: any) => boolean;
}

const hasParam = (param: any) => param === 0 || !!param;
const alwaysTrue = () => true;

export class FilterOperatorsV5 {
	public static readonly map: {[type: string]: IFilterOperatorConfigV5} = {};
	public static readonly array: IFilterOperatorConfigV5[] = [];

	public static add(operator: FilterOperator, label: string, method: IFilterOperatorMethod, control?: FilterParamControlV5) {
		const config: IFilterOperatorConfigV5 = {
			id: operator,
			label: label,
			method: method,
			control: control,
			validator: control ? hasParam : alwaysTrue,
		};

		this.map[operator] = config;
		this.array.push(config);
	}
}

FilterOperatorsV5.add(FilterOperator.IS_BLANK, "Is Blank", isBlank);
FilterOperatorsV5.add(FilterOperator.IS_NOT_BLANK, "Is Not Blank", isNotBlank);
FilterOperatorsV5.add(FilterOperator.IS_EQUAL_TO_STR, "Is equal to", isEqualTo_str, StringParamV5);
FilterOperatorsV5.add(FilterOperator.IS_NOT_EQUAL_TO_STR, "Is not equal to", isNotEqualTo_str, StringParamV5);
FilterOperatorsV5.add(FilterOperator.CONTAINS, "Contains", contains, StringParamV5);
FilterOperatorsV5.add(FilterOperator.DOES_NOT_CONTAIN, "Does not contain", doesNotContain, StringParamV5);
FilterOperatorsV5.add(FilterOperator.IS_STARTING_WITH, "Is starting with", isStartingWith, StringParamV5);
FilterOperatorsV5.add(FilterOperator.IS_ENDING_WITH, "Is ending with", isEndingWith, StringParamV5);

FilterOperatorsV5.add(FilterOperator.IS_EQUAL_TO_NUM, "Is equal to", isEqualTo_num, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_NOT_EQUAL_TO_NUM, "Is not equal to", isNotEqualTo_num, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_LESS_THAN_NUM, "Is less than", isLessThan, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_NUM, "Is less than or equal to", isLessThanOrEqualTo, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_GREATER_THAN_NUM, "Is greater Than", isGreaterThan, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_NUM, "Is greater than or equal to", isGreaterThanOrEqualTo, NumberParamV5);
FilterOperatorsV5.add(FilterOperator.IS_IN_BETWEEN_NUM, "Is in between", isInBetween, BetweenParamV5);
FilterOperatorsV5.add(FilterOperator.IS_NOT_BETWEEN_NUM, "Is not between", isNotBetween, BetweenParamV5);

FilterOperatorsV5.add(FilterOperator.IS_EQUAL_TO_DATE, "On", isEqualToDate, DateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_LESS_THAN_DATE, "Before", isLessThanDate, DateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_DATE, "Is less than or equal to", isLessThanOrEqualTo, DateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_GREATER_THAN_DATE, "After", isGreaterThanDate, DateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_DATE, "Is greater than or equal to", isGreaterThanOrEqualTo, DateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_IN_BETWEEN_DATE, "Between", isInBetweenDate, BetweenDateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_NOT_BETWEEN_DATE, "Is not between", isNotBetweenDate, BetweenDateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_IN_THE_LAST_DATE, "In the last", inTheLastExplicitDate, ExplicitDateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_IN_THE_NEXT_DATE, "In the next", inTheNextExplicitDate, ExplicitDateParamV5);
FilterOperatorsV5.add(FilterOperator.IS_TODAY, "Today", isInToday);
FilterOperatorsV5.add(FilterOperator.IS_YESTERDAY, "Yesterday", isInYesterday);
FilterOperatorsV5.add(FilterOperator.IS_IN_THIS_WEEK, "This Week", inThisWeek);
FilterOperatorsV5.add(FilterOperator.IS_IN_THIS_MONTH, "This Month", inThisMonth);
FilterOperatorsV5.add(FilterOperator.IS_IN_LAST_WEEK, "Last Week", inLastWeek);
FilterOperatorsV5.add(FilterOperator.IS_IN_LAST_MONTH, "Last Month", inLastMonth);
FilterOperatorsV5.add(FilterOperator.IS_AT_TIME, "At", isAtTime, TimeParamV5);
FilterOperatorsV5.add(FilterOperator.IS_BEFORE_TIME, "Before", isBeforeTime, TimeParamV5);
FilterOperatorsV5.add(FilterOperator.IS_AFTER_TIME, "After", isAfterTime, TimeParamV5);
FilterOperatorsV5.add(FilterOperator.IS_BETWEEN_TIME, "Between", isBetweenTime, BetweenTimeParamV5);

FilterOperatorsV5.add(FilterOperator.IS_TRUE, "True", isTrue);
FilterOperatorsV5.add(FilterOperator.IS_FALSE, "False", isFalse);

FilterOperatorsV5.add(FilterOperator.IS_ONE_OF, "Is one of", isAnyOf); // this is an alias for "is any of"

FilterOperatorsV5.add(FilterOperator.IS_ANY_OF, "Is any of", isAnyOf, AnyParamV5);
FilterOperatorsV5.add(FilterOperator.IS_NOT_ANY_OF, "Is not any of", isNotAnyOf, AnyParamV5);

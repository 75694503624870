import {Observer} from "mobx-react";
import type {MouseEvent} from "react";
import {useEffect, useReducer, useRef, useState} from "react";
import styled from "styled-components";
import {ReactUtils} from "../../utils/ReactUtils";
import {useAppStore} from "../../../StateManager";
import ListIcon from "../icons/list.svg?react";
import FrameIcon from "../icons/frame.svg?react";
import CloseIcon from "../icons/xmark.svg?react";
import ChevronDownDouble from "../icons/chevron-down-double.svg?react";
import EyeIcon from "../icons/eye-alt.svg?react";
import EyeSlashIcon from "../icons/eye-slash-alt.svg?react";
import TrashIcon from "../icons/trash.svg?react";
import {XyiconFeature} from "../../../generated/api/base";
import {IconButtonV5, IconButtonStyled} from "../interaction/IconButtonV5";
import type {View} from "../../../data/models/View";
import {colorPalette} from "../styles/colorPalette";
import {ELLIPSIS, FLEXCENTER, radius, zIndex} from "../styles/styles";
import {useClickOutside} from "../utils";
import {ImageUtils} from "../../../utils/image/ImageUtils";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {
	EventNameForViewsChangeInLocalStorage,
	getActiveWorkspaceViewTabFromLocalStorage,
	getWorkspaceViewTabsFromLocalStorage,
	onWorkspaceViewClick,
	removeAllWorkspaceViewTabsExceptSelected,
	removeWorkspaceViewFromTabs,
} from "./ViewTabsCommon";

const horizontalGapSize = 8;
const maxTabWidth = 156;

interface IViewSimple {
	id: string;
	name: string;
	icon: "list" | "frame";
	isActive: boolean;
}

export const ViewTabsV5 = () => {
	const appState = useAppStore((state) => state.appState);
	const setViewForOpenSpaceSelector = useAppStore((state) => state.setViewForOpenSpaceSelector);
	const viewTabsWrapperRef = useRef<HTMLDivElement>();
	const dropDownRef = useRef<HTMLDivElement>();
	const chevronDownRef = useRef<HTMLDivElement>();
	const [, forceUpdate] = useReducer((x) => x + 1, 0);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [areTabsVisible, setAreTabsVisible] = useState<boolean>(true);
	const [maxNumberOfViewsInTopBar, setMaxNumberOfViewsInTopBar] = useState<number>(1);
	const [lastViewedSpaceName, setLastViewedSpaceName] = useState<string>("");
	const [dropDownMenuTransformObject, setDropDownMenuTransformObject] = useState<TransformObj>(null);
	const resizeObserver = useRef<ResizeObserver>(
		new ResizeObserver((entries: ResizeObserverEntry[]) => {
			forceUpdate();
		}),
	);
	const viewTabsInLocalStorage = getWorkspaceViewTabsFromLocalStorage(appState.user?.id, appState.organizationId);
	const activeViewTabInLocalStorage = getActiveWorkspaceViewTabFromLocalStorage(appState.user?.id, appState.organizationId);

	const renderViewTab = (viewSimple: IViewSimple, forcePutInBeginningOfList: boolean) => {
		const view = appState.actions.getViewById(viewSimple.id);
		const onCloseClick = (event: MouseEvent) => {
			event.stopPropagation();
			removeWorkspaceViewFromTabs(view, setViewForOpenSpaceSelector);
			forceUpdate();
		};

		return (
			<ViewTabStyled
				key={view.id}
				className={ReactUtils.cls({selected: viewSimple.isActive})}
				onClick={() => onWorkspaceViewClick(appState.actions.getViewById(view.id), setViewForOpenSpaceSelector, forcePutInBeginningOfList)}
				title={`${view.name}${viewSimple.icon === "frame" ? `\n${lastViewedSpaceName}` : ""}`}
				$showCloseButton={views.length > 1}
			>
				<IconStyled className="icon viewIcon">{viewSimple.icon === "frame" ? <FrameIcon /> : <ListIcon />}</IconStyled>
				<TabNameStyled>{view.name}</TabNameStyled>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={onCloseClick}
				/>
			</ViewTabStyled>
		);
	};

	const onToggleDropdown = () => {
		setIsDropdownOpen((o) => !o);
	};

	const onToggleTabsVisibility = () => {
		setAreTabsVisible((v) => !v);
		setIsDropdownOpen(false);
	};

	const onCloseOtherTabsClick = () => {
		removeAllWorkspaceViewTabsExceptSelected(appState.user?.id, appState.organizationId);
		forceUpdate();
	};

	useClickOutside(
		[dropDownRef, chevronDownRef],
		() => {
			setIsDropdownOpen(false);
		},
		"mouseup",
	);

	useEffect(() => {
		window.addEventListener(EventNameForViewsChangeInLocalStorage, forceUpdate);

		return () => {
			window.removeEventListener(EventNameForViewsChangeInLocalStorage, forceUpdate);
		};
	}, []);

	useEffect(() => {
		const currentResizeObserver = resizeObserver.current;

		if (viewTabsWrapperRef.current) {
			currentResizeObserver.observe(viewTabsWrapperRef.current);
		}

		return () => {
			currentResizeObserver.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewTabsWrapperRef.current]);

	useEffect(() => {
		forceUpdate();
	}, []);

	useEffect(() => {
		const renderLastViewedSpace = async () => {
			await appState.app.transport.services.feature.refreshList(XyiconFeature.Space);
			const userId = appState.user?.id;
			const orgId = appState.organizationId;
			const localStorageKeyForLastViewedSpace = appState.getLocalStorageKeyForLastViewedSpace(userId, orgId);
			const lastViewedSpaceId = localStorage.getItem(localStorageKeyForLastViewedSpace);
			const lastViewedSpace = lastViewedSpaceId ? appState.actions.getFeatureItemById(lastViewedSpaceId, XyiconFeature.Space) : null;

			if (lastViewedSpace && lastViewedSpace.name !== lastViewedSpaceName) {
				setLastViewedSpaceName(lastViewedSpace.name);
			}
		};

		renderLastViewedSpace();
	});

	useEffect(() => {
		if (isDropdownOpen && chevronDownRef.current && dropDownRef.current) {
			setDropDownMenuTransformObject(
				DomUtils.getFixedFloatingElementPosition(
					chevronDownRef.current,
					dropDownRef.current,
					VerticalAlignment.bottomOuter,
					HorizontalAlignment.right,
					5,
					5,
				),
			);
		}
	}, [isDropdownOpen]);

	const views: IViewSimple[] = [];
	views.push(
		...viewTabsInLocalStorage
			.map((viewTab) => appState.actions.getViewById(viewTab.viewId))
			.filter((v) => !!v)
			.map((view: View) => ({
				id: view.id,
				name: view.name,
				icon: (view.itemFeature === XyiconFeature.SpaceEditor ? "frame" : "list") as "frame" | "list",
				isActive: view.id === activeViewTabInLocalStorage?.viewId,
			})),
	);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (viewTabsWrapperRef.current) {
			const parentRect = viewTabsWrapperRef.current.getBoundingClientRect();
			const parentWidth = parentRect.width - 8; // dropdown button size
			const iconSize = 16;
			const gapSizes = 3 * 8;
			const paddingRightSize = 36;
			const tabOverHead = iconSize + gapSizes + paddingRightSize; // sizes without the text

			let sumOfChildrenWidth = 2 * horizontalGapSize; // padding on left and right
			let i = 0;

			while (i < views.length && sumOfChildrenWidth < parentWidth) {
				const viewTabTextSize = Math.min(ImageUtils.getTextWidthInPx(views[i++].name, "Inter", 14, 400), maxTabWidth - tabOverHead);

				sumOfChildrenWidth += viewTabTextSize + tabOverHead + horizontalGapSize;
			}

			const newMaxNumberOfViewsInTopBar = Math.max(1, sumOfChildrenWidth < parentWidth ? i : i - 1);

			if (newMaxNumberOfViewsInTopBar !== maxNumberOfViewsInTopBar) {
				setMaxNumberOfViewsInTopBar(newMaxNumberOfViewsInTopBar);
			}
		}
	});

	return (
		<Observer>
			{() => {
				const selectedFeature = appState.selectedFeature;

				if ([XyiconFeature.Boundary, XyiconFeature.Xyicon, XyiconFeature.SpaceEditor].includes(selectedFeature)) {
					if (views.length === 0) {
						const selectedView = appState.actions.getSelectedView(selectedFeature);

						onWorkspaceViewClick(selectedView, setViewForOpenSpaceSelector);
					}

					const viewsInTopBar = areTabsVisible ? views.slice(0, maxNumberOfViewsInTopBar) : [views.find((v) => v.isActive)];
					const viewsInDropdown = areTabsVisible ? views.slice(maxNumberOfViewsInTopBar) : views.filter((v) => !v.isActive);

					return (
						<ViewTabsWrapper ref={viewTabsWrapperRef}>
							<ViewTabsBarStyled className="ViewTabs">{viewsInTopBar.map((viewSimple) => renderViewTab(viewSimple, false))}</ViewTabsBarStyled>
							<RightSideStyled>
								{!areTabsVisible && (
									<IconButtonV5
										IconComponent={EyeIcon}
										onClick={onToggleTabsVisibility}
										title="Hidden Tabs: Click to Show"
										titleVerticalAlignment={VerticalAlignment.bottomOuter}
									/>
								)}
								<IconButtonV5
									IconComponent={ChevronDownDouble}
									onClick={onToggleDropdown}
									divRef={chevronDownRef}
									className={ReactUtils.cls({isOpen: isDropdownOpen})}
								/>
								{isDropdownOpen && (
									<DomPortal destination={appState.app.modalContainer}>
										<DropdownContentStyled
											ref={dropDownRef}
											style={{
												transform: dropDownMenuTransformObject?.translate || "none",
												position: "fixed",
											}}
										>
											{viewsInDropdown.length > 0 && (
												<>
													<ViewInsideDropdownContainer>
														{viewsInDropdown.map((viewSimple) => renderViewTab(viewSimple, true))}
													</ViewInsideDropdownContainer>
													<HorizontalLine />
												</>
											)}
											<ViewTabStyled onClick={onCloseOtherTabsClick}>
												<IconStyled>
													<TrashIcon />
												</IconStyled>
												<TabNameStyled>Close Other Tabs</TabNameStyled>
											</ViewTabStyled>
											<ViewTabStyled onClick={onToggleTabsVisibility}>
												<IconStyled>{areTabsVisible ? <EyeSlashIcon /> : <EyeIcon />}</IconStyled>
												<TabNameStyled>{areTabsVisible ? "Hide Tabs" : "Show Tabs"}</TabNameStyled>
											</ViewTabStyled>
										</DropdownContentStyled>
									</DomPortal>
								)}
							</RightSideStyled>
						</ViewTabsWrapper>
					);
				}

				return <></>;
			}}
		</Observer>
	);
};

const HorizontalLine = styled.div`
	border-top: 1px solid #c8c8c8;
`;

const dropdownViewNumberScrollThreshold = 10;
const viewTabHeight = 32;
const dropdownPadding = 8;
const dropdownGap = 8;

const ViewInsideDropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${dropdownGap}px;
	max-height: ${dropdownViewNumberScrollThreshold * viewTabHeight + (dropdownViewNumberScrollThreshold - 1) * dropdownGap}px;
	overflow-y: auto;
`;

const ViewTabStyled = styled.div<{$showCloseButton?: boolean}>`
	cursor: ${(props) => (props.$showCloseButton ? "pointer" : "default")};
	display: flex;
	align-items: center;
	min-height: ${viewTabHeight}px;
	padding: 0 ${horizontalGapSize}px;
	gap: ${horizontalGapSize}px;
	border-radius: ${radius.sm};
	position: relative;
	max-width: ${maxTabWidth}px;
	padding-right: 36px;

	${IconButtonStyled} {
		min-width: 20px;
		width: 20px;
		height: 20px;
		visibility: hidden;
		position: absolute;
	}

	svg {
		width: 16px;
		height: 16px;
	}

	&:hover {
		padding-right: 8px;
		background-color: ${colorPalette.gray.c200Light};
		${IconButtonStyled} {
			visibility: ${(props) => (props.$showCloseButton ? "visible" : "hidden")};
			pointer-events: ${(props) => (props.$showCloseButton ? "auto" : "none")};
			position: static;
			&:hover {
				background-color: ${colorPalette.gray.c400};
			}
		}

		&.selected {
			${IconButtonStyled} {
				&:hover {
					background-color: ${colorPalette.primary.c950};
				}
			}
		}
	}

	&.selected {
		background-color: ${colorPalette.primary.c500Primary};
		color: white;

		.icon {
			fill: white;
		}

		.closeIcon {
			background-color: ${colorPalette.gray.c700Dark};

			&:hover {
				background-color: ${colorPalette.primary.c800};
			}
		}

		&:hover {
			background-color: ${colorPalette.primary.c700Dark};
		}
	}
`;

const DropdownContentStyled = styled.div`
	z-index: ${zIndex.contextOptions};
	display: flex;
	flex-direction: column;
	position: absolute;
	color: #333;
	background-color: #ffffff;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	padding: ${dropdownPadding}px;
	border-radius: ${radius.md};
	gap: ${dropdownGap}px;
	${ViewTabStyled} {
		padding-right: 8px;
	}
`;

const RightSideStyled = styled.div`
	display: flex;
	align-items: center;
	position: relative;

	svg {
		width: 16px;
		height: 16px;
	}

	${DropdownContentStyled} {
		visibility: visible;
		min-width: initial;
	}
`;

const IconStyled = styled.div`
	min-width: 16px;
	width: 16px;
	height: 16px;
	${FLEXCENTER}
	fill: ${colorPalette.gray.c950};

	.icon {
		width: 12px;
		height: 12px;
		min-width: 12px;
		min-height: 12px;
	}

	&.closeIcon {
		position: absolute;
		right: 8px;
		border-radius: ${radius.xs};
		background-color: ${colorPalette.gray.c200Light};

		&:hover {
			background-color: ${colorPalette.gray.c300};
		}
	}
`;

const TabNameStyled = styled.span`
	font-size: 14px;
	line-height: 16px;
	${ELLIPSIS}
	flex: 1;
`;

const ViewTabsBarStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
	overflow-x: hidden;
`;

const ViewTabsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex: 1;
	padding: 0 8px;
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import {UserProfile} from "../UserProfile";
import {SVGIcon} from "../widgets/button/SVGIcon";
import {SelectInput} from "../widgets/input/select/SelectInput";
import {InfoButton} from "../modules/abstract/common/infobutton/InfoButton";
import {ShortcutWindow} from "../modules/abstract/popups/ShortcutWindow";
import {DomPortal} from "../modules/abstract/portal/DomPortal";
import type {AppState} from "../../data/state/AppState";
import {IconButton} from "../widgets/button/IconButton";
import {IconToggle} from "../widgets/button/IconToggle";
import type {Navigation} from "../../Navigation";
import {ReactUtils} from "../utils/ReactUtils";
import {XyiconFeature} from "../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../utils/dom/DomUtils";
import {TimeUtils} from "../../utils/TimeUtils";
import type {Space} from "../../data/models/Space";
import {NavigationEnum} from "../../Enums";
import {TopBarBackButton} from "./TopBarBackButton";
import {TopBarDropdown} from "./TopBarDropdown";
import {MainSearch} from "./MainSearch";

interface ITopNavigationProps {
	readonly appState?: AppState;
	readonly navigation?: Navigation;
	readonly navigationOpen: boolean;
	readonly onToggleNavigation: () => void;
}

interface ITopNavigationState {
	isHelpButtonClicked: boolean;
	isShortcutOpen: boolean;
}

enum DatabaseEnv {
	Dev = 1,
	Qa = 2,
	Prod = 3,
	"Dev Test" = 4,
}

@inject("navigation")
@inject("appState")
@observer
export class TopBar extends React.Component<ITopNavigationProps, ITopNavigationState> {
	private _hoveringModel = React.createRef<HTMLDivElement>();
	public readonly mainSearchRef = React.createRef<MainSearch>();
	public readonly _bubbleRef = React.createRef<HTMLDivElement>();

	constructor(props: ITopNavigationProps) {
		super(props);
		this.state = {
			isHelpButtonClicked: false,
			isShortcutOpen: false,
		};
	}

	private onToggleLiveChatBox = () => {
		const liveChat: HTMLDivElement = document.querySelector("#hubspot-messages-iframe-container.widget-align-left");

		liveChat?.classList.toggle("open");
	};

	private onShortCutClicked = async () => {
		if (!this.state.isShortcutOpen) {
			this.setState({isShortcutOpen: true});
		} else {
			this.setState({isShortcutOpen: false});
			await TimeUtils.waitForNextFrame();
			this.setState({isShortcutOpen: true});
		}
	};

	private onCloseShortcut = () => {
		this.setState({isShortcutOpen: false});
	};

	private onHelpClicked = () => {
		this.setState({isHelpButtonClicked: !this.state.isHelpButtonClicked});
	};

	private onFocusLoss = () => {
		this.setState({isHelpButtonClicked: false});
	};

	private onDocsClicked = () => {
		this.props.navigation.openInNewTab("https://support.xyicon.com");
	};

	private onFeatureClicked = () => {
		this.props.navigation.openInNewTab("https://support.xyicon.com/docs/submit-a-feature-request");
	};

	private onBugClicked = () => {
		this.props.navigation.openInNewTab("https://support.xyicon.com/docs/report-an-issue");
	};

	private get databaseEnv(): DatabaseEnv {
		let env = DatabaseEnv.Prod;
		const baseUrl = this.props.appState.app.config.api.base;

		if (baseUrl.includes("dev-be-core")) {
			env = DatabaseEnv["Dev Test"];
		} else if (baseUrl.includes("qa")) {
			env = DatabaseEnv.Qa;
		} else if (baseUrl.includes("dev")) {
			env = DatabaseEnv.Dev;
		}

		return env;
	}

	private getPills(): React.JSX.Element {
		const pillClass = "pill hbox justifyCenter alignCenter";
		const clsObj: Record<string, true> = {};

		clsObj[DatabaseEnv[this.databaseEnv].toLowerCase()] = true;

		const orgPillMaybe: React.JSX.Element | false = this.props.appState.organizationId.toUpperCase() === "439AC828-450B-4914-91F8-EC56A9F7E583" && (
			<div className={ReactUtils.cls(pillClass, {...clsObj, red: true})}>Prologis Dev Env</div>
		);

		const envPillMaybe: React.JSX.Element | false = [DatabaseEnv.Dev, DatabaseEnv.Qa, DatabaseEnv["Dev Test"]].includes(this.databaseEnv) && (
			<div className={ReactUtils.cls(pillClass, clsObj)}>{DatabaseEnv[this.databaseEnv].toUpperCase()}</div>
		);
		const pillText = this.props.appState.app.config.pill?.text;

		if (orgPillMaybe || envPillMaybe || pillText) {
			return (
				<div className="pillContainer hbox justifyCenter alignCenter">
					{orgPillMaybe}
					{envPillMaybe}
					{pillText && <div className={pillClass}>{pillText}</div>}
				</div>
			);
		}

		return <></>;
	}

	private onInfoClick = () => {
		const {app, space} = this.props.appState;

		if (space) {
			app.onDetailsClick(space);
		}
	};

	private get spaceInfo(): React.ReactNode {
		const {appState} = this.props;
		const {space} = appState;

		if (space) {
			const layout = appState.actions.getRowsForCardLayout("", space, true);

			return (
				<div>
					{layout.map((row, i) => {
						return (
							<React.Fragment key={i}>
								<b>{row.key}: </b>
								{row.value}
								<br />
							</React.Fragment>
						);
					})}
				</div>
			);
		}

		return undefined;
	}

	public override render() {
		const {appState, navigation, navigationOpen, onToggleNavigation} = this.props;
		const {isShortcutOpen} = this.state;
		const isSettingsPage = appState.selectedMenu === "settings";
		const space = this.props.appState.space;
		const spaceItems: {id: string; label: string}[] = appState.actions.getList<Space>(XyiconFeature.Space).map((item) => ({
			id: item.id,
			label: item.name,
		}));

		return (
			<header className={ReactUtils.cls({darkHeader: isSettingsPage, [DatabaseEnv[this.databaseEnv].toLowerCase()]: true})}>
				<div className="headerTitle">
					{isSettingsPage ? (
						<TopBarBackButton />
					) : (
						<IconToggle
							className="navButton"
							icon="hamburger"
							onChange={onToggleNavigation}
							value={navigationOpen}
						/>
					)}
					<span className="portfolioName">{isSettingsPage ? "Settings" : appState.actions.getCurrentPortfolioName()}</span>
					{space && (
						<div className="spaceSelector hbox">
							<SVGIcon icon="angle_right" />
							<SelectInput
								selected={spaceItems.find((item) => item.id === space?.id)}
								options={spaceItems}
								onChange={(option) => navigation.goApp(NavigationEnum.NAV_SPACE, option.id)}
								render={(option) => option.label}
							/>
							<InfoButton
								bubbleText={this.spaceInfo}
								verticalOpen={VerticalAlignment.bottomOuter}
								onClick={this.onInfoClick}
							/>
						</div>
					)}
				</div>
				{this.getPills()}
				<div className="headerActions">
					{!isSettingsPage && <MainSearch ref={this.mainSearchRef} />}
					<div
						ref={this._hoveringModel}
						className={ReactUtils.cls({open: this.state.isHelpButtonClicked})}
					>
						<IconButton
							className="headerButton supportBtn"
							icon="help"
							label="Help"
						/>
						<TopBarDropdown
							elements={[
								{
									icon: "livechat",
									onClick: this.onToggleLiveChatBox,
									label: "Live Chat",
								},
								{
									icon: "keyboard",
									onClick: this.onShortCutClicked,
									label: "Keyboard Shortcuts",
								},
								{
									icon: "docs",
									onClick: this.onDocsClicked,
									label: "Help Docs",
								},
								{
									icon: "feature",
									onClick: this.onFeatureClicked,
									label: "Suggest a Feature",
									strokeOnly: true,
								},
								{
									icon: "bug",
									onClick: this.onBugClicked,
									label: "Report a Problem",
								},
							]}
							horizontalAlignment={HorizontalAlignment.left}
							parent={this._hoveringModel}
							parentOnClick={this.onHelpClicked}
							parentFocusLoss={this.onFocusLoss}
						/>
					</div>
					<IconButton
						className="headerButton settingsButton"
						icon="settings"
						onClick={() => navigation.goApp(NavigationEnum.NAV_SETTINGS)}
						label="Settings"
					/>
					<UserProfile />
					{isShortcutOpen && (
						<DomPortal destination={document.body}>
							<ShortcutWindow onClose={this.onCloseShortcut} />
						</DomPortal>
					)}
				</div>
			</header>
		);
	}
}

import {observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {Xyicon3D} from "../../logic3d/elements3d/Xyicon3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {DraggableXyiconCatalogContainer} from "../toolbar/DraggableXyiconCatalogContainer";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import type {Portfolio} from "../../../../../../data/models/Portfolio";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {IXyiconLinkObject} from "../../../../../../data/state/AppActions";
import type {ICrossPortfolioLinkData} from "./CrossPortfolioXyicon";
import {CrossPortfolioXyicon} from "./CrossPortfolioXyicon";

export type InvisibleLinkedXyiconType = "embedded" | "external";

interface ICrossPortfolioLinkGroup {
	portfolio: Portfolio;
	data: ICrossPortfolioLinkData[];
}

interface ILinkedXyiconsWindowProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly fromObjectId: string;
	readonly type: InvisibleLinkedXyiconType;
	readonly onClose: () => void;
}

interface ILinkedXyiconsWindowState {
	isHidden: boolean;
}

@observer
export class LinkedXyiconsWindow extends React.Component<ILinkedXyiconsWindowProps, ILinkedXyiconsWindowState> {
	private _ref = React.createRef<HTMLDivElement>();

	constructor(props: ILinkedXyiconsWindowProps) {
		super(props);
		this.state = {
			isHidden: false,
		};
	}

	private hide = () => {
		if (!this.state.isHidden) {
			this.setState({
				isHidden: true,
			});
		}
	};

	private getGroupedCrossPortfolioLinks(crossPortfolioLinks: ICrossPortfolioLinkData[]): ICrossPortfolioLinkGroup[] {
		// group by portfolioIds
		const groups: {
			[portfolioId: string]: ICrossPortfolioLinkData[];
		} = {};

		for (const link of crossPortfolioLinks) {
			if (!groups[link.otherPortfolioId]) {
				groups[link.otherPortfolioId] = [];
			}

			groups[link.otherPortfolioId].push(link);
		}

		const {actions} = this.props.spaceViewRenderer;

		const groupsArray: {
			portfolio: Portfolio;
			data: ICrossPortfolioLinkData[];
		}[] = [];

		for (const portfolioId in groups) {
			groupsArray.push({
				portfolio: actions.getPortfolioById(portfolioId),
				data: groups[portfolioId],
			});
		}

		return groupsArray.toSorted((a, b) => StringUtils.sortIgnoreCase(a.portfolio.name, b.portfolio.name));
	}

	private getCrossPortfolioLinkElements(crossPortfolioLinkGroups: ICrossPortfolioLinkGroup[]) {
		// make react nodes from data
		const reactNodes: React.ReactNode[] = [];

		for (const group of crossPortfolioLinkGroups) {
			reactNodes.push(
				<React.Fragment key={group.portfolio.id}>
					{this.getPortfolioNameElement(group.portfolio.name)}
					<div className="glyphContainer">
						{group.data.map((o) => (
							<CrossPortfolioXyicon
								key={o.link.id}
								transport={this.props.spaceViewRenderer.transport}
								linkData={o}
								showBreakLinkButton={true}
								showDeleteButton={true}
							/>
						))}
					</div>
				</React.Fragment>,
			);
		}

		return reactNodes;
	}

	private getInPortfolioLinks() {
		const linkedXyicons = this.props.spaceViewRenderer.actions.getLinksXyiconXyicon(this.props.fromObjectId);

		if (this.props.type === "embedded") {
			return linkedXyicons.filter((l) => l.link.isEmbedded);
		} // type === "external"
		else {
			return linkedXyicons.filter((l) => l.object.isExternal || l.object.isUnplotted);
		}
	}

	private getCrossPortfolioLinks() {
		return this.props.spaceViewRenderer.transport.appState.actions.getCrossPortfolioLinksXyiconXyicon(this.props.fromObjectId);
	}

	private getPortfolioNameElement(portfolioName: string) {
		return <div className="portfolioName">{portfolioName}</div>;
	}

	private areThereMoreThanOneInARow(inPortfolioLinks: IXyiconLinkObject[], crossPortfolioLinkGroups: ICrossPortfolioLinkGroup[]): boolean {
		if (inPortfolioLinks.length > 1) {
			return true;
		}

		return crossPortfolioLinkGroups.some((g) => g.data.length > 1);
	}

	public override componentDidMount() {
		const {signals} = this.props.spaceViewRenderer.toolManager.cameraControls;

		signals.cameraPropsChange.add(this.props.onClose);
		// the initial position can be wrong (out of canvas), and it needs to be added to the dom in order to calculate its size. A refresh fixes this
		this.forceUpdate();
	}

	public override componentWillUnmount() {
		const {signals} = this.props.spaceViewRenderer.toolManager.cameraControls;

		signals.cameraPropsChange.remove(this.props.onClose);
	}

	public override render() {
		const {spaceViewRenderer, fromObjectId, type} = this.props;
		const fromObject = spaceViewRenderer.xyiconManager.getItemById(fromObjectId) as Xyicon3D;

		if (!fromObject) {
			// This can happen, if parent xyicon gets deleted while this component is open
			requestAnimationFrame(this.props.onClose);
			return null;
		}
		const fromPosition = fromObject.position;
		const fromObjectScale = fromObject.scale;

		fromPosition.y += fromObjectScale.y / 2;

		const inPortfolioLinks = this.getInPortfolioLinks();
		const xyiconLinkObjects = this.getInPortfolioLinks().map((l) => ({object: l.object, userData: l.link}));
		const crossPortfolioLinks = this.props.type === "external" ? this.getCrossPortfolioLinks() : [];
		const crossPortfolioLinkGroups = this.getGroupedCrossPortfolioLinks(crossPortfolioLinks);

		const numberOfAllLinksToShow = xyiconLinkObjects.length + crossPortfolioLinks.length;
		const trailingSForInPortfolioLinksMaybe = `${xyiconLinkObjects.length === 1 ? "" : "s"}`;
		const trailingSForAllExternalLinksMaybe = `${crossPortfolioLinks.length === 1 ? "" : "s"}`;

		return (
			numberOfAllLinksToShow > 0 && (
				<div
					ref={this._ref}
					className={ReactUtils.cls("LinkedXyiconsWindow window vbox", {hidden: this.state.isHidden})}
					style={{
						width: this.areThereMoreThanOneInARow(inPortfolioLinks, crossPortfolioLinkGroups) ? "" : "215px", // derived from scss - "normal" width is 430px
						...THREEUtils.getStyleForFloatingUIElement(
							fromPosition.x,
							fromPosition.y,
							spaceViewRenderer.spaceOffset.z,
							spaceViewRenderer,
							true,
							"top",
							this._ref.current,
						),
					}}
				>
					<div className="header">
						<div className="title">
							{type === "embedded" ? (
								<>
									{`${xyiconLinkObjects.length} item${trailingSForInPortfolioLinksMaybe} embedded in `}
									<strong>{`${(fromObject.modelData as Xyicon).refId}`}</strong>
								</>
							) : (
								<>
									<strong>{`${(fromObject.modelData as Xyicon).refId}`}</strong>
									{` is linked to ${numberOfAllLinksToShow} object${trailingSForAllExternalLinksMaybe} in other spaces`}
								</>
							)}
						</div>
						<IconButton
							className="closeBtn"
							icon="close"
							onClick={this.props.onClose}
							title="Close Window"
						/>
					</div>
					<XyiconContainer>
						{xyiconLinkObjects.length > 0 && (
							<>
								{this.props.type === "external" &&
									this.getPortfolioNameElement(
										`${this.props.spaceViewRenderer.transport.appState.actions.getCurrentPortfolioName()} (Current Portfolio)`,
									)}
								<div className="glyphContainer">
									<DraggableXyiconCatalogContainer
										spaceViewRenderer={spaceViewRenderer}
										feature={XyiconFeature.Xyicon}
										items={xyiconLinkObjects}
										onPointerMove={this.hide}
										onPointerUp={this.props.onClose}
									/>
								</div>
							</>
						)}
						{crossPortfolioLinks.length > 0 && this.getCrossPortfolioLinkElements(crossPortfolioLinkGroups)}
					</XyiconContainer>
				</div>
			)
		);
	}
}

const XyiconContainer = styled.div`
	overflow-y: auto;
	.glyphContainer {
		overflow-y: initial;
	}
`;

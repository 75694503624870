import {useRef} from "react";
import styled from "styled-components";
import {useAppStore} from "../../../StateManager";
import type {Color} from "../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import {TextGroupManager} from "../../modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import {ColorSelectorStyled, ColorSelectorV5} from "../colors/ColorSelectorV5";
import {SelectInputV5} from "../input/select/SelectInputV5";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import TextSizeIcon from "../icons/text-size.svg?react";
import EraserIcon from "../icons/eraser.svg?react";
import {IconButtonV5} from "../interaction/IconButtonV5";
import type {SupportedFontName} from "../../../data/state/AppStateTypes";
import {FontStyleContainerV5} from "./FontStyleContainerV5";
import {TextAlignButtonV5} from "./TextAlignButtonV5";
import {TextBoxAlignButtonV5} from "./TextBoxAlignButtonV5";
import {SizeChangerV5} from "./SizeChangerV5";

interface ITextStyleModifierProps {
	readonly isBold: boolean;
	readonly isItalic: boolean;
	readonly isUnderlined: boolean;
	readonly fontFamily: SupportedFontName;
	readonly fontSize: number;
	readonly fontColor: Color;

	readonly onIsBoldChange: (value: boolean) => void;
	readonly onIsItalicChange: (value: boolean) => void;
	readonly onIsUnderlinedChange: (value: boolean) => void;
	readonly onFontFamilyChange: (newFontFamily: SupportedFontName) => void;
	readonly onFontSizeChange: (newFontSize: number) => void;
	readonly onFontColorChange: (newFontColor: Color) => void;
	readonly onClearTextClick: () => void;
	readonly onTextBoxAlignmentChange?: (horizontalAlignment: HorizontalAlignment, verticalAlignment: VerticalAlignment) => void;
	readonly onTextAlignmentChange?: (horizontalAlignment: HorizontalAlignment) => void;
	readonly horizontalAlignment?: HorizontalAlignment;
	readonly verticalAlignment?: VerticalAlignment;
	readonly onClose?: () => void;
	readonly isSpaceBarActionButton?: boolean;
}

export const TextStyleModifierV5 = (props: ITextStyleModifierProps) => {
	const appState = useAppStore((state) => state.appState);
	const app = appState.app;

	const _ref = useRef<HTMLDivElement>();

	const onIsBoldChange = (value: boolean) => {
		props.onIsBoldChange(value);
	};

	const onIsItalicChange = (value: boolean) => {
		props.onIsItalicChange(value);
	};

	const onIsUnderlinedChange = (value: boolean) => {
		props.onIsUnderlinedChange(value);
	};

	const {
		isBold,
		isItalic,
		isUnderlined,
		fontColor,
		fontFamily,
		fontSize,
		onFontSizeChange,
		onFontColorChange,
		onFontFamilyChange,
		onTextBoxAlignmentChange,
		onClearTextClick,
		horizontalAlignment,
		verticalAlignment,
		onTextAlignmentChange,
		isSpaceBarActionButton,
	} = props;
	const {eyeDropperProps} = app.spaceViewRenderer;

	return (
		<TextStyleModifierStyled
			className="TextStyleModifier hbox"
			ref={_ref}
		>
			<FontStyleContainerV5
				fontStyleSettings={{isBold, isItalic, isUnderlined}}
				onIsBoldChange={onIsBoldChange}
				onIsItalicChange={onIsItalicChange}
				onIsUnderlinedChange={onIsUnderlinedChange}
				onClose={props.onClose}
			/>
			<SizeChangerV5
				value={fontSize}
				IconComponent={TextSizeIcon}
				range={Constants.SIZE.FONT}
				onChange={onFontSizeChange}
				isSpaceBarActionButton={isSpaceBarActionButton}
			/>
			<SelectInputV5
				onChange={onFontFamilyChange}
				options={TextGroupManager.supportedFontNames}
				selected={fontFamily}
			/>
			{onTextBoxAlignmentChange && (
				<TextBoxAlignButtonV5
					horizontalAlignment={horizontalAlignment}
					verticalAlignment={verticalAlignment}
					onTextAlignmentChange={onTextBoxAlignmentChange}
				/>
			)}
			{onTextAlignmentChange && (
				<TextAlignButtonV5
					horizontalAlignment={horizontalAlignment}
					onTextAlignmentChange={onTextAlignmentChange}
				/>
			)}
			<ColorSelectorV5
				title="Text Color"
				color={fontColor}
				onColorChange={onFontColorChange}
				eyeDropperProps={eyeDropperProps}
				horizontalAlignment={HorizontalAlignment.outerRight}
				verticalAlignment={VerticalAlignment.bottom}
				outerDivRef={_ref}
				isTransparencyEnabled={true}
				offsetX={8}
				offsetY={-8}
			/>
			<IconButtonV5
				IconComponent={EraserIcon}
				title="Clear"
				onClick={onClearTextClick}
			/>
		</TextStyleModifierStyled>
	);
};

export const TextStyleModifierStyled = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;

	${ColorSelectorStyled} {
		min-width: 32px;
		position: relative;
	}
`;

import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import type {IPopupWindowConfigV5} from "./PopupWindowV5";
import {PopupWindowV5} from "./PopupWindowV5";

export class ConfirmValueWindowV5 extends PopupWindowV5<boolean | null> {
	protected static override readonly _defaultConfig: IPopupWindowConfigV5 = {
		ok: "Delete",
		cancel: "Cancel",
		backdrop: false,
	};

	private _confirmValue: number | string;

	protected _okValue = true;
	protected _cancelValue = false;

	constructor(message: string, confirmValue: number | string, title: string, config: IPopupWindowConfigV5 = {}) {
		super({
			title: title,
			message: message,
			config: ObjectUtils.mergeConfig(ConfirmValueWindowV5._defaultConfig, config),
		});

		this._confirmValue = confirmValue;

		// <ConfirmValueInput
		// 	confirmValue={confirmNumber.toString()}
		// 	onChange={this.onInputFieldChange}
		// />

		this._additionalElements = document.createElement("input");
		(this._additionalElements as HTMLInputElement).placeholder = `${confirmValue}`;

		this._additionalElements.oninput = this.onInputFieldChange;

		requestAnimationFrame(() => {
			if (this._additionalElements?.parentElement) {
				this._additionalElements.focus();
			}
		});

		super.disableOkButton();
	}

	private onInputFieldChange = (event: Event) => {
		const input = event.currentTarget as HTMLInputElement;

		const confirmValue = `${this._confirmValue}`;

		let matchingCharacters = 0;

		for (let i = 0; i < confirmValue.length; ++i) {
			if (input.value[i] === confirmValue[i]) {
				matchingCharacters = i + 1;
			}
		}

		const matchingValue = input.value.substring(0, matchingCharacters);

		input.value = matchingValue;

		this.updateOkButton(input.value === confirmValue);
	};

	/**
	 * doesMatch: if the input field's value matches `confirmNumber`
	 */
	private updateOkButton(doesMatch: boolean) {
		if (doesMatch) {
			super.enableOkButton();
		} else {
			super.disableOkButton();
		}
	}

	public static open(message: string, confirmValue: number | string, title?: string, config?: IPopupWindowConfigV5) {
		if (!title) {
			title = "Confirm Delete";
		}
		return new ConfirmValueWindowV5(message, confirmValue, title, config).open() as Promise<boolean>;
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {DateTimeInput} from "../../../../../widgets/input/datetime/DateTimeInput";

interface IDateParamProps {
	readonly param: string;
	onChange(value: string): void;
}

@observer
export class DateParam extends React.Component<IDateParamProps> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<DateTimeInput
				value={param}
				defaultValue={param}
				onChange={onChange}
				format="datetime"
			/>
		);
	}
}

import {computed, observable, makeObservable} from "mobx";
import type {IModel} from "../Model";
import type {FieldDto, XyiconFeature} from "../../../generated/api/base";
import {FieldDataType} from "../../../generated/api/base";
import {ObjectUtils} from "../../../utils/data/ObjectUtils";
import type {FilterOperator} from "../filter/operator/FilterOperator";
import {Formatter} from "../../../utils/format/Formatter";
import {findFieldDataTypeKey} from "./FieldDataType";

export interface IFieldData extends FieldDto {
	feature: XyiconFeature;
	fieldID: string; // guid
	fieldRefID: string;
	name: string;
	displayOnLinks: boolean;
	hasFormula?: boolean; // for calculated fields
	formula?: string | null;
	isSystem: boolean;
	classes: string; // ?
	dataType: FieldDataType;
	dataTypeDefinition: IDataTypeDefinition;
	settings: string;
	linkedField?: boolean;
	isAssignedByModel?: boolean;
}

export interface IDefaultField extends IFieldAdapter {
	getterName: string;
	fixed?: boolean; // displayed at the top of the Details panel already (eg. PortfolioDefaultFields)
	default: true;
}

interface IDataTypeDefinition {
	[dataType: number]: any;
}

interface IFieldDataSettings {
	formattingRules: IFormattingRule[];
	helperText: string;
	fieldDescription: string;
}

export interface IFormattingRule {
	operator: FilterOperator;
	params: any;
	color: string;
}

// A structure which determines the field to select. It's not the field itself, but information about how to find the
// find in AppState.
// refId is not enough, in case the field is a default field, we need to know which feature it is from.
// The other option would be to have uniq refIds eg. "20/@type", "30/f4" or "20/default/type" or "portfoliotype"
export type IFieldPointer = string; // aka refId
// {
// 	refId: string;
// 	feature: XyiconFeature;
// 	default: boolean;
// }

export interface IFieldAdapter extends FieldDto {
	refId: string;
	name: string;
	dataType: FieldDataType;
	dataTypeSettings?: any;
	isNew?: boolean;
	feature: XyiconFeature;
	default: boolean; // aka hardcoded field
	displayOnLinks: boolean;
	unique?: boolean;
	hasFormula?: boolean;
	formula?: string | null;
	linkedField?: boolean;
	isAssignedByModel?: boolean;
	helperText?: string;
}

export class Field implements IModel, FieldDto {
	public static readonly defaultSettings: {
		[dataType: number]: any;
	} = {};

	public readonly default = false;
	public isNew = false;

	@observable
	private _data: IFieldData;

	@observable
	private _dataTypeSettings: {[dataType: number]: any} = {};

	@observable
	private _formattingRules: {[dataType: number]: IFormattingRule[]} = {};

	@observable
	private _helperText: string = "";

	@observable
	private _fieldDescription: string = null;

	constructor(data: IFieldData) {
		makeObservable(this);
		this.applyData(data);
	}

	public applyData(data: IFieldData) {
		this._data = data;
		if (this._data.dataTypeDefinition) {
			if (typeof this._data.dataTypeDefinition === "string") {
				try {
					const settings = JSON.parse(this._data.dataTypeDefinition); // as IDataTypeDefinition;
					const types = settings["types"];

					if (types) {
						// legacy
						this.applyDataSettings(settings["types"]);
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				this.applyDataSettings(this._data.dataTypeDefinition);
			}
		}
		if (!this._dataTypeSettings) {
			this.applyDataSettings(ObjectUtils.deepClone(Field.defaultSettings || {}));
		}
		this.initDataTypeSettings();

		if (this._data.settings) {
			try {
				const settings: IFieldDataSettings = typeof this._data.settings === "string" ? JSON.parse(this._data.settings) : this._data.settings;

				this._formattingRules[this.dataType] = settings.formattingRules;
				this._helperText = settings.helperText;
				this._fieldDescription = settings.fieldDescription;
			} catch (e) {
				console.warn(e);
			}
		}
	}

	private applyDataSettings(settings: any) {
		this._dataTypeSettings = this._dataTypeSettings || {};

		if (typeof settings === "object") {
			for (const key in settings) {
				if (isNaN(Number(key))) {
					// key is a string like "numeric"
					const capitalized = Formatter.capitalize(key);
					const fieldDataType: FieldDataType = FieldDataType[capitalized as keyof typeof FieldDataType];
					const definition = settings[key];

					if (definition) {
						this._dataTypeSettings[fieldDataType] = definition;
					}
				}
			}
		}
	}

	public serialize(isCreate: boolean = false): Partial<IFieldData> {
		const field = this;

		const dataTypeKey = Formatter.decapitalize(findFieldDataTypeKey(this.dataType));
		const dataTypeDefinition: IDataTypeDefinition = {
			[dataTypeKey]: this.dataTypeSettings,
		};

		const settings: IFieldDataSettings = {
			formattingRules: this.formattingRules,
			helperText: this.helperText,
			fieldDescription: this._fieldDescription,
		};

		const data: Partial<IFieldData> = {
			name: field.name,
			feature: field.feature,
			dataType: field.dataType,
			dataTypeDefinition: dataTypeDefinition,
			displayOnLinks: field.displayOnLinks,
			settings: JSON.stringify(settings),
			isSystem: false,
			isAssignedByModel: field.isAssignedByModel,
		};

		if (isCreate) {
			data.hasFormula = field.hasFormula && !!field.formula;
			data.formula = field.formula;
		}

		return data;
	}

	@computed
	public get id() {
		return this._data.fieldID ?? "";
	}

	@computed
	public get refId() {
		return this._data.fieldRefID ?? "";
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name ?? "";
	}

	public set formula(value: string | null) {
		this._data.formula = value;
	}

	@computed
	public get formula() {
		return this._data.formula;
	}

	public set hasFormula(value: boolean) {
		this._data.hasFormula = value;
	}

	@computed
	public get linkedField() {
		return this._data.linkedField;
	}

	public set linkedField(value: boolean) {
		this._data.linkedField = value;
	}

	@computed
	public get hasFormula() {
		return this._data.hasFormula;
	}

	public set displayOnLinks(value: boolean) {
		this._data.displayOnLinks = value;
	}

	@computed
	public get displayOnLinks() {
		return this._data.displayOnLinks;
	}

	public set dataType(value: FieldDataType) {
		this._data.dataType = value;
		this.initDataTypeSettings();
	}

	private initDataTypeSettings() {
		if (!this._dataTypeSettings[this.dataType]) {
			this._dataTypeSettings[this.dataType] = ObjectUtils.deepClone(Field.defaultSettings[this.dataType] || {});
		}
		const defaultSettings = Field.defaultSettings[this.dataType];

		if (defaultSettings) {
			for (const key in this._dataTypeSettings[this.dataType]) {
				const value = this._dataTypeSettings[this.dataType][key];

				if (value === undefined) {
					this._dataTypeSettings[this.dataType][key] = defaultSettings[key];
				}
			}
		}
	}

	@computed
	public get dataType() {
		return this._data.dataType;
	}

	@computed
	public get dataTypeSettings() {
		if (!this._dataTypeSettings) {
			this.initDataTypeSettings();
		}
		return this._dataTypeSettings[this.dataType];
	}

	public set formattingRules(value: IFormattingRule[]) {
		this._formattingRules[this.dataType] = value;
	}

	public get formattingRules() {
		if (!this._formattingRules[this.dataType]) {
			this._formattingRules[this.dataType] = [];
		}
		return this._formattingRules[this.dataType];
	}

	public set fieldDescription(value: string) {
		this._fieldDescription = value;
	}

	public get fieldDescription() {
		return this._fieldDescription;
	}

	@computed
	public get feature() {
		return this._data.feature;
	}

	@computed
	public get data() {
		return this._data;
	}

	@computed
	public get isAssignedByModel() {
		return this._data.isAssignedByModel ?? false;
	}

	public set isAssignedByModel(value: boolean) {
		this._data.isAssignedByModel = value;
	}

	@computed
	public get hasValidation(): boolean {
		return this._data.hasValidation;
	}

	@computed
	public get validation(): string {
		return this._data.validation ?? "";
	}

	@computed
	public get helperText() {
		return this._helperText;
	}

	public set helperText(value: string) {
		this._helperText = value;
	}
}

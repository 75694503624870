import * as React from "react";
import {ReactUtils} from "../../utils/ReactUtils";
import {SVGIcon} from "./SVGIcon";

export interface IButtonProps {
	readonly label?: string | number;
	readonly icon?: string;
	readonly title?: string;
	readonly onClick?: (event: React.MouseEvent) => void;
	readonly disabled?: boolean;
	readonly className?: string;
	readonly style?: React.CSSProperties;
	readonly loading?: boolean;
}

export class Button extends React.PureComponent<IButtonProps> {
	private onClick = (event: React.MouseEvent) => {
		if (!this.props.disabled && this.props.onClick) {
			this.props.onClick(event);
		}
	};

	public override render() {
		const {icon, label, className, style, loading, disabled, title} = this.props;

		return (
			<div
				className={ReactUtils.cls(className || "", {
					Button: true,
					disabled: disabled || loading,
					loading: loading,
				})}
				title={title || ""}
				data-cy={`Button.${label}`}
				style={style || null}
				onClick={this.onClick}
			>
				<span className="spinner" />
				{icon && <SVGIcon icon={icon} />}
				{!!label && <span className="label">{label}</span>}
			</div>
		);
	}
}

import {useRef, useState} from "react";
import type {Markup3D} from "../../logic3d/elements3d/markups/abstract/Markup3D";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../logic3d/elements3d/markups/abstract/MarkupUtils";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import type {MarkupType} from "../../../../../../generated/api/base";
import {MarkupsWithCustomText} from "../../logic3d/elements3d/markups/MarkupStaticElements";
import {useClickOutside} from "../../../../../5.0/utils";
import {MarkupTypeOptions} from "./MarkupTypeOptions";

interface IMarkupTypeChangerProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

export const MarkupTypeChanger = (props: IMarkupTypeChangerProps) => {
	const {spaceViewRenderer} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>();
	const _selectedItems = spaceViewRenderer.spaceItemController.selectedItems;

	useClickOutside([ref], () => {
		setIsOpen(false);
	});

	const onMarkupTypeClick = () => {
		setIsOpen((o) => !o);
	};

	const onMarkupTypeChange = (newType: MarkupType) => {
		return changeMarkupType(_selectedItems as Markup3D[], newType, spaceViewRenderer);
	};

	const getActiveIcon = () => {
		const firstSelectedObject = _selectedItems[0] as Markup3D;

		const toolName = markupTypeToMarkupToolName(firstSelectedObject?.type);
		const tool = spaceViewRenderer.tools[toolName];

		return tool.icon;
	};

	const areOnlySupportedTypesSelected = _selectedItems.every(
		(item) => item.spaceItemType === "markup" && MarkupsWithCustomText.includes((item as Markup3D).type),
	);

	const style: React.CSSProperties = {};

	return (
		areOnlySupportedTypesSelected &&
		_selectedItems.length === 1 && (
			<span
				onClick={onMarkupTypeClick}
				className="button flexCenter MarkupTypeChanger"
				title="Change Markup Type..."
				ref={ref}
			>
				<SVGIcon
					icon={getActiveIcon()}
					style={style}
				/>
				<div className="arrowWrapper">
					<i className="arrowDown corner"></i>
				</div>
				{isOpen && (
					<MarkupTypeOptions
						activeType={(_selectedItems[0] as Markup3D).type}
						spaceViewRenderer={spaceViewRenderer}
						onChange={onMarkupTypeChange}
					/>
				)}
			</span>
		)
	);
};

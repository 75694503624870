import * as React from "react";
import {ScriptLoader} from "../../../../utils/loader/ScriptLoader";
import {styles} from "./styles";
import {dispatchDetails} from "./data";

interface IMapsPanelProps {}

interface IMapsPanelState {
	stations: IStation[];
	markers: IMarker[];
}

interface IStation {
	address: string;
	customer_name: string;
	items_per_product: number;
	items_to_deliver: string;
	lat: number;
	lng: number;
}

interface IMarker {
	labelContent: string;
	setMap: (value: google.maps.Map) => void;
	setAnimation: (value: google.maps.Animation) => void;
}

let MarkerClusterer: any;
let MarkerWithLabel: any;

export class MapsPanel<T> extends React.Component<IMapsPanelProps, IMapsPanelState> {
	private _mapsContainer = React.createRef<HTMLDivElement>();
	private _map: google.maps.Map;

	private _markerClusterer: any; // typeof import("./cluster/MarkerClusterer").MarkerClusterer;

	private _firstMount = false;

	private _timeout = 0;
	private _queries: IStation[] = [];
	private _loadingQueries = new Map();

	constructor(props: IMapsPanelProps) {
		super(props);

		this.state = {
			stations: [],
			markers: [],
		};
	}

	public override async componentDidMount() {
		if (!this._firstMount) {
			this._firstMount = true;
			const container = this._mapsContainer.current;

			await this.loadScripts();

			this.populateData();
			this.initGoogleMaps(container);
			this.addMarkers();
			this.fitBounds();
		}
	}

	private populateData() {
		const addresses = dispatchDetails;
		const stations = [];

		for (const address of addresses) {
			if (!Array.isArray(address.items_to_deliver)) {
				if (address) {
					stations.push(address);
				}
			}
		}

		this.setState({stations: stations});
	}

	private async loadScripts() {
		const scripts = ["https://maps.googleapis.com/maps/api/js?key=AIzaSyClXnjgGlqwRNdhTwtneEVBWnltfAOErOY&libraries=places"];

		await ScriptLoader.loadScriptsInSequence(scripts);

		MarkerClusterer = (await import(/* webpackChunkName: "MarkerClusterer" */ "./cluster/MarkerClusterer")).MarkerClusterer;
		MarkerWithLabel = (await import(/* webpackChunkName: "MarkerClusterer" */ "./cluster/MarkerWithLabel")).MarkerWithLabel;
	}

	private initGoogleMaps(container: HTMLElement) {
		const mapCenter = {lat: 42.3601, lng: -71.0589};

		const mapOptions = {
			center: mapCenter,
			zoom: 14,
			minZoom: 3,
			maxZoom: 18,
			disableDefaultUI: false,
			mapTypeControl: true,
			mapTypeControlOptions: {
				style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
				position: google.maps.ControlPosition.TOP_CENTER,
			},
			zoomControl: true,
			zoomControlOptions: {
				position: google.maps.ControlPosition.RIGHT_BOTTOM,
			},
			scaleControl: true,
			streetViewControl: true,
			streetViewControlOptions: {
				position: google.maps.ControlPosition.RIGHT_BOTTOM,
			},
			fullscreenControl: true,
			fullscreenControlOptions: {
				position: google.maps.ControlPosition.RIGHT_BOTTOM,
			},
		};

		const service = new google.maps.DirectionsService();

		this._map = new google.maps.Map(container, mapOptions);
		const infoWindow = new google.maps.InfoWindow({
			pixelOffset: new google.maps.Size(-23, -10),
		});

		const marker_animation = google.maps.Animation.DROP;
		const styledMap = new google.maps.StyledMapType(styles, {name: "Styled Map"});

		this._map.mapTypes.set("map_style", styledMap);
		this._map.setMapTypeId("roadmap");
	}

	private addMarkers() {
		const iconSize = Math.sqrt(window.innerWidth) + 20;

		this.state.markers.forEach(function (marker) {
			// Clear out the old markers.
			marker.setMap(null);
		});

		this._markerClusterer = new MarkerClusterer(this._map, [], {
			imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
		});

		// For each place, get the icon, name and location.
		this.state.stations.forEach((station) => {
			this._queries.push(station);
			// const icon = {
			// 	url: "http://maps.google.com/mapfiles/marker.png",
			// 	labelOrigin: new google.maps.Point(50, 18),
			// 	size: new google.maps.Size(32, 32),
			// 	anchor: new google.maps.Point(16, 32)
			// };

			// Create a marker for each place.
			//var marker = new google.maps.Marker({
			//    map: map,
			//    //icon: icon,
			//   // icon: 'https://png.icons8.com/search',
			//    icon: '/Images/android-icon-36x36.png',
			//    title: station.customer_name,
			//    animation: marker_animation,
			//    position: { lat: station.lat, lng: station.lng }
			//}),
			//    width = window.innerWidth,
			//    height = window.innerHeight,
			//    offsetX = 0,
			//    offsetY = -height / 5;
			//const pictureLabel = document.createElement("img");
			//pictureLabel.src = "images/orderedList1.png";

			// const geocoder = new google.maps.Geocoder();
			// geocoder.geocode({
			// 		'address': station.address
			// 	},
			// 	(results, status) => {
			// 		console.log(status);
			// 		if (status === google.maps.GeocoderStatus.OK)
			// 		{
			// 			this.onAddressLoaded(station, results);
			// 		}
			// 	});
		});

		this.loadQueries();
	}

	private loadQueries() {
		//const station = this._queries[0];

		for (const station of this._queries) {
			if (!this._loadingQueries.get(station)) {
				this._loadingQueries.set(station, true);

				const geocoder = new google.maps.Geocoder();

				geocoder.geocode(
					{
						address: station.address,
					},
					(results, status) => {
						if (status === google.maps.GeocoderStatus.OK) {
							this.onStationAddressLoaded(station, results);
						} else {
							this._loadingQueries.delete(station);

							if (!(this._timeout > 0)) {
								this._timeout = window.setTimeout(() => {
									this._timeout = 0;
									this.loadQueries();
								}, 2000);
							}
						}
					},
				);
			}
		}
	}

	private onStationAddressLoaded = (station: IStation, results: google.maps.GeocoderResult[]) => {
		if (this._queries.indexOf(station) > -1) {
			this._queries.splice(this._queries.indexOf(station), 1);
			//this.loadQueries();
		}

		const marker: any = new MarkerWithLabel({
				//position: { lat: station.lat, lng: station.lng },
				position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()},
				icon: "/src/assets/images/maps/android-icon-36x36.png",
				draggable: true,
				raiseOnDrag: true,
				map: this._map,
				labelContent: station.customer_name,
				//labelContent: pictureLabel,
				labelAnchor: new google.maps.Point(-5, 25),
				labelClass: "labels", // the CSS class for the label
				labelStyle: {opacity: 0.75},
			}),
			width = window.innerWidth,
			height = window.innerHeight,
			offsetX = 0,
			offsetY = -height / 5;

		marker.addListener("click", () => {
			this.state.markers.forEach((marker) => {
				marker.setAnimation(null);
			});

			if (marker.getAnimation() !== null) {
				marker.setAnimation(null);
			} else {
				marker.setAnimation(google.maps.Animation.BOUNCE);
				this._map.panTo(marker.position);
				this._map.panBy(offsetX, offsetY);
			}
		});

		this.setState((prevState) => ({markers: [prevState.markers, marker]}));

		this._markerClusterer.addMarker(marker);

		google.maps.event.addListener(marker, "click", () => {
			this.log(station);
		});
		const iw1 = new google.maps.InfoWindow({
			content: '<h3 style="background-color: lightblue;border: green;border-style: dotted;" >Home For Sale</h3>',
		});

		google.maps.event.addListener(marker, "click", function () {
			iw1.open(this._map, this);
		});
	};

	private fitBounds() {
		const {stations} = this.state;
		const lngs = stations.map((station) => station.lng);
		const lats = stations.map((station) => station.lat);

		this._map.fitBounds({
			west: Math.min.apply(null, lngs),
			east: Math.max.apply(null, lngs),
			north: Math.min.apply(null, lats),
			south: Math.max.apply(null, lats),
		});
	}

	private log(station: IStation) {
		console.log(`${station.customer_name} has been clicked.`);
	}

	public override render() {
		return (
			<div className="MapsPanel">
				<div
					ref={this._mapsContainer}
					className="mapsContainer"
				/>
				<ul className="infoList">
					{this.state.stations.map((station, index) => (
						<li
							key={index}
							onClick={() => this.onClickStation(station)}
						>
							{station.customer_name}
						</li>
					))}
				</ul>
			</div>
		);
	}

	private onClickStation = (station: IStation) => {
		const name = station.customer_name.toLowerCase();

		for (const marker of this.state.markers) {
			if (marker.labelContent.toLowerCase() === name) {
				google.maps.event.trigger(marker, "click");
			}
		}
	};
}

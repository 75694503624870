import {observer} from "mobx-react";
import * as React from "react";
import {SaveToViewButton} from "../common/savetoview/SaveToViewButton";
import type {View} from "../../../../data/models/View";
import type {ViewChangeType} from "../../../../data/models/ViewUtils";

interface IUnsavedLayoutProps {
	readonly selectedView: View;
	readonly label?: boolean;
	readonly noIcon?: boolean;
}

@observer
export class UnsavedLayout extends React.Component<IUnsavedLayoutProps> {
	private getViewChangeType(): ViewChangeType | null {
		const typesToCheckChangesFor: ViewChangeType[] = ["columns", "column sorts"];

		for (const type of typesToCheckChangesFor) {
			const hasUnsavedData = this.props.selectedView.hasUnsavedChanges(type);

			if (hasUnsavedData) {
				return type;
			}
		}

		return null;
	}

	public override render() {
		const {selectedView, label, noIcon} = this.props;

		if (selectedView.id) {
			const viewChangeType = this.getViewChangeType();

			if (viewChangeType) {
				return (
					<>
						{label !== false && <div>Grid layout modified</div>}
						<SaveToViewButton
							feature={selectedView.itemFeature}
							viewChangeType={viewChangeType}
							noIcon={!!noIcon}
						/>
					</>
				);
			}
		}

		return null;
	}
}

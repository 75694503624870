import * as React from "react";
import {inject, observer} from "mobx-react";
import styled from "styled-components";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import type {User} from "../../../../../data/models/User";
import type {AppState} from "../../../../../data/state/AppState";
import {XHRLoader} from "../../../../../utils/loader/XHRLoader";
import {FieldV5} from "../../../details/FieldV5";
import {ButtonV5} from "../../../button/ButtonV5";
import {DateFormatter} from "../../../../../utils/format/DateFormatter";
import {DetailsTabV5HeaderStyles} from "../../../details/DetailsTabV5HeaderStyles";
import {FlexCenter, radius} from "../../../styles/styles";
import InfoIcon from "../../../icons/circle-information.svg?react";
import {ClickToEditInputV5} from "../../../input/clicktoedit/ClickToEditInputV5";
import {colorPalette} from "../../../styles/colorPalette";
import {FieldStyled} from "../../../details/Field.styled";
import {UserFormV5} from "./UserFormV5";

export const NodataUserDetailsTabStyled = styled.div`
	.noData {
		color: ${colorPalette.gray.c500Primary};
		text-align: center;
		min-width: 410px;
		position: absolute;
		font-size: 18px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -60px);
	}
`;

const UserDetailsTabStyled = styled.div`
	${DetailsTabV5HeaderStyles} {
		padding-top: 16px;
		border-bottom: none;
		padding-bottom: 2px;
		margin-bottom: 10px;
	}

	padding-left: 16px;
	padding-right: 16px;

	.disabledInputFeild {
		border: 1px solid ${colorPalette.gray.c300};
		border-radius: ${radius.sm};
		color: ${colorPalette.gray.c300};
	}

	${FieldStyled} {
		margin-bottom: 8px;
	}
`;

const UserDetailsTabButtonStyled = styled.div`
	.addButtonContainer {
		width: 100%;
		display: flex;
		justify-content: end;
	}

	.addButton {
		right: 0;
		width: fit-content;
		background-color: ${colorPalette.white};
		color: ${colorPalette.blueGray.c500Primary};
		border: 1px solid ${colorPalette.blueGray.c500Primary};
		margin-bottom: 10px;
	}

	.addButton:hover {
		background-color: ${colorPalette.primary.c100};
	}
`;

interface IUserDetailsTabProps {
	readonly users: User[];
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IUserDetailsTabStates {
	resendingInviteInProgress: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class UserDetailsTabV5 extends React.Component<IUserDetailsTabProps, IUserDetailsTabStates> {
	constructor(props: IUserDetailsTabProps) {
		super(props);
		this.state = {
			resendingInviteInProgress: false,
		};
	}

	private onResendInviteClick = async () => {
		this.setState({resendingInviteInProgress: true});

		const result = await this.props.transport.requestForOrganization({
			url: "users/resendinvite",
			method: XHRLoader.METHOD_POST,
			params: {
				userID: this.props.users[0].id,
			},
		});

		this.setState({resendingInviteInProgress: false});
	};

	public override render() {
		const {users, appState} = this.props;
		const user = users[0];

		return (
			<NodataUserDetailsTabStyled>
				{users.length === 0 && <NoDataStyled>Please select a User.</NoDataStyled>}
				{users.length === 1 && (
					<>
						<UserDetailsTabStyled>
							<DetailsTabV5HeaderStyles>
								<FlexCenter $gap="10px">
									<InfoIcon />
									<h2>Details</h2>
								</FlexCenter>
							</DetailsTabV5HeaderStyles>

							<div className="feildSet">
								<FieldV5 label="Name">
									<ClickToEditInputV5
										value={user.fullName}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Email">
									<ClickToEditInputV5
										value={user.email}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<UserDetailsTabButtonStyled>
									{appState.user?.isAdmin && user.status === "invited" && (
										<div className="addButtonContainer">
											<ButtonV5
												label={this.state.resendingInviteInProgress ? "Resending invite..." : "Resend Invite"}
												onClick={this.onResendInviteClick}
												className="addButton"
												disabled={this.state.resendingInviteInProgress}
											/>
										</div>
									)}
								</UserDetailsTabButtonStyled>

								<FieldV5 label="Status">
									<ClickToEditInputV5
										value={user.status}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>

								<FieldV5 label="Last Updated">
									<ClickToEditInputV5
										value={DateFormatter.format(user.lastModifiedAt)}
										disabled={true}
										className="disabledInputFeild"
									/>
								</FieldV5>
							</div>
						</UserDetailsTabStyled>
						<UserFormV5 users={users} />
					</>
				)}
			</NodataUserDetailsTabStyled>
		);
	}
}

export const NoDataStyled = styled.div`
	font-size: 18px;
	color: #c4c4c4;
	height: 80%;
	width: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
`;

import {useEffect, useRef} from "react";
import {Observer} from "mobx-react";
import {XyiconFeature} from "../../../generated/api/base";
import {ModuleViewV5} from "../abstract/ModuleViewV5";
import {useAppStore} from "../../../StateManager";
import {PortfolioCreatePopupV5} from "./portfolio/PortfolioCreatePopupV5";
import {getActionBarButtonsForFeature} from "./ActionBarUtils";

const OWN_FEATURE = XyiconFeature.Portfolio;

interface IPortfolioViewV5Props {}

export const PortfolioViewV5 = (props: IPortfolioViewV5Props) => {
	const _moduleView = useRef<ModuleViewV5>();
	const createButtonRef = useRef<HTMLDivElement>();
	const appState = useAppStore((state) => state.appState);
	const {transport} = appState.app;

	useEffect(() => {
		const {actions} = appState;

		const portfolioId = transport.services.localStorage.get(actions.getKeyForLocalStorageSelectedPortfolio());
		const currentPortfolio = actions.getPortfolioById(portfolioId) ?? actions.getCurrentPortfolio();

		if (currentPortfolio) {
			if (currentPortfolio.id !== actions.getCurrentPortfolio()?.id) {
				transport.services.auth.switchPortfolio(currentPortfolio.id);
			}

			_moduleView.current?.focusItem(currentPortfolio);

			appState.app.transport.services.feature.refreshList(XyiconFeature.PortfolioDocument);
		}
	}, [appState, transport.services.auth, transport.services.localStorage]);

	return (
		<Observer>
			{() => {
				/* const currentPortfolio = actions.getCurrentPortfolio();

					if (_moduleView.current?.state.focusedItems[0]?.id !== currentPortfolio?.id)
					{
						_moduleView.current?.focusItem(currentPortfolio);
					} */
				return (
					<ModuleViewV5
						ref={_moduleView}
						feature={OWN_FEATURE}
						actionBar={getActionBarButtonsForFeature(OWN_FEATURE, appState)}
						create={(onClose) => (
							<PortfolioCreatePopupV5
								onClose={() => {
									onClose();
								}}
								parentRef={createButtonRef}
							/>
						)}
						createButtonRef={createButtonRef}
					/>
				);
			}}
		</Observer>
	);
};

import styled from "styled-components";
import {Functions} from "../../../utils/function/Functions";
import {InfoButtonV5} from "../button/InfoButtonV5";
import {ToggleSwitchV5} from "../widgets/switch/ToggleSwitchV5";
import {fontSize} from "../styles/styles";

interface IToggleSwitchFieldProps {
	readonly value: boolean;
	readonly onChange: (value: boolean) => void;
	readonly label: string;
	readonly noBooleanLabel?: boolean;
	readonly disabled?: boolean;
	readonly labelFirst?: boolean; // changes the order of toggler and label
	readonly classNames?: string;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly bubbleText?: string;
	readonly onMouseOver?: () => void;
	readonly onMouseLeave?: () => void;
}

export const ToggleSwitchFieldV5 = (props: IToggleSwitchFieldProps) => {
	const {value, label, classNames, labelFirst, disabled, divRef, bubbleText, noBooleanLabel, onMouseLeave, onMouseOver, onChange} = props;

	let style: React.CSSProperties = {};

	if (labelFirst) {
		style.flexDirection = "row-reverse";
		style.justifyContent = "flex-end";
	}
	return (
		<ToggleSwitchFieldStyled
			ref={divRef}
			className={`ToggleSwitchField ${classNames || ""}`}
			style={style}
			onMouseOver={onMouseOver ? onMouseOver : Functions.emptyFunction}
			onMouseLeave={onMouseLeave ? onMouseLeave : Functions.emptyFunction}
		>
			<ToggleSwitchV5
				value={value}
				onChange={onChange}
				disabled={disabled ?? false}
				noLabel={noBooleanLabel}
				className="toggleSwitch"
			/>
			{label && <label>{label}</label>}
			{bubbleText && <InfoButtonV5 bubbleText={bubbleText} />}
		</ToggleSwitchFieldStyled>
	);
};

export const ToggleSwitchFieldStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	label {
		font-size: ${fontSize.lg};
	}
`;

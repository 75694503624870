import * as React from "react";
import styled from "styled-components";
import {flushSync} from "react-dom";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {Xyicon} from "../../../../../data/models/Xyicon";
import type {PortTemplateDto} from "../../../../../generated/api/base";
import {THREEUtils} from "../../../../../utils/THREEUtils";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import CloseIcon from "../../../icons/xmark.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {radius} from "../../../styles/styles";
import {PortContainerStyled, PortsV5} from "./PortsV5";
import {PortSelectAreaV5} from "./PortSelectAreaV5";

interface IPortSelectorProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly worldX: number;
	readonly worldY: number;
	readonly item: Xyicon;
	readonly ports: PortTemplateDto[];
	readonly type: "from" | "to";
}

const firstRowSize: number = 77; // px

export class PortSelectorV5 extends React.Component<IPortSelectorProps> {
	private _ref = React.createRef<HTMLDivElement>();

	private onCameraMove = () => {
		flushSync(() => {
			this.forceUpdate();
		});
	};

	public override componentWillUnmount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.remove(this.onCameraMove);
	}

	public override componentDidMount() {
		this.props.spaceViewRenderer.toolManager.cameraControls.signals.cameraPropsChange.add(this.onCameraMove);
		this.forceUpdate();
	}

	public override render() {
		const {worldX, worldY, spaceViewRenderer, ports, item, type} = this.props;
		const worldZ = spaceViewRenderer.spaceOffset.z;

		return (
			<PortSelectorWrapper
				ref={this._ref}
				className="PortSelector floatingDropdown vbox"
				style={THREEUtils.getStyleForFloatingUIElement(worldX, worldY, worldZ, spaceViewRenderer, true, "top", this._ref.current)}
			>
				<PortSelectorInnerPart
					spaceViewRenderer={spaceViewRenderer}
					item={item}
					ports={ports}
					type={type}
				/>
			</PortSelectorWrapper>
		);
	}
}

interface IPortSelectorInnerPartProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly item: Xyicon;
	readonly ports: PortTemplateDto[];
	readonly type: "from" | "to";
}

const PortSelectorInnerPart = React.memo((props: IPortSelectorInnerPartProps) => {
	const {spaceViewRenderer, item, ports, type} = props;
	const portContainerRef = React.useRef<HTMLDivElement>();
	const portContainerHeight = portContainerRef.current?.getBoundingClientRect().height ?? 0;
	const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

	React.useEffect(() => {
		if (portContainerHeight === 0) {
			forceUpdate();
		}
	}, [portContainerHeight]);

	const onPortSelected = (portId: string) => {
		const {linkManager} = spaceViewRenderer.toolManager;

		if (type === "from") {
			linkManager.enableLinkMode(portId);
		} else {
			linkManager.createLinks(portId);
			linkManager.disableLinkMode();
		}
	};

	const portDataToSelectArea = (portData: PortTemplateDto) => {
		return (
			<React.Fragment key={portData.id}>
				<PortSelectAreaV5
					onClick={onPortSelected}
					portId={portData.id}
					leaf={portData.children.length === 0}
				/>
				{portData.children.map(portDataToSelectArea)}
			</React.Fragment>
		);
	};

	return (
		<>
			<div className="hbox alignCenter header">
				Create a Link
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={spaceViewRenderer.toolManager.linkManager.disableLinkMode}
				/>
			</div>
			<div className="wrapper">
				<PortContainerStyled
					className="PortContainer"
					ref={portContainerRef}
				>
					<div
						className="vbox"
						style={{position: "relative", top: "7.5px", height: `${firstRowSize}px`}}
					>
						<div
							className="darkSilverText"
							style={{marginBottom: "12.5px"}}
						>
							Hover over the xyicon or a port to start creating a link.
						</div>
						<div
							className="firstRow hbox alignCenter"
							style={{marginBottom: "7.5px", marginTop: "7.36px"}}
						>
							<div
								className="thumbnail"
								style={{backgroundImage: `url('${item.thumbnail}')`, transform: item.backgroundTransform}}
							></div>
							<div className="bubble flexCenter">Link to Xyicon</div>
						</div>
					</div>
					<PortsV5 item={item} />
				</PortContainerStyled>
				<div
					className="portSelectAreaContainer"
					style={{height: `${portContainerHeight - 25}px`}}
				>
					<PortSelectAreaV5
						onClick={onPortSelected}
						portId={null}
						leaf={true}
					/>
					{ports.map(portDataToSelectArea)}
				</div>
			</div>
		</>
	);
});

const PortSelectorWrapper = styled.div`
	position: absolute;
	width: fit-content;
	min-width: 100px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
	left: 0;
	top: 0;
	max-width: initial;
	background: ${colorPalette.white};
	border-radius: ${radius.md};
	z-index: 1;
	.thumbnail {
		width: 38px;
		height: 38px;
	}

	.wrapper {
		position: relative;
		margin: 5px 0;
		padding: 0 15px;
		min-width: 200px;
		max-width: 475px;
		max-height: 342px;
		overflow: auto;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 8px;
		height: 48px;
		font-size: 18px;
		line-height: 24px;
		font-weight: bold;
		.button {
			margin-left: auto;
			margin-right: 8px;
		}
	}

	.PortContainer {
		.bubble {
			margin-left: 25px;
			background-color: ${colorPalette.primary.c500Primary};
			padding: 10px;
			color: white;
			animation: pulsate 1s ease-out 5;
		}

		.orphanedLinks {
			display: none;
		}
	}

	.PortContainer {
		margin: 0;
		.PortComponent {
			&.leaf {
				.textContainer {
					&.locked {
						opacity: 1;
					}
					&::after {
						filter: brightness(0) invert(1); // make the "lock" item white
					}
					.TextInput {
						background: none;
						color: white;
					}
					background-color: ${colorPalette.primary.c500Primary};
					animation: pulsate 1s ease-out 5;
				}
			}
		}
	}

	.portSelectAreaContainer {
		position: absolute;
		top: 33px;
		left: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		z-index: 1;

		.PortSelectArea {
			flex: 1;
			width: 100%;
			&:hover {
				&.leaf {
					cursor: pointer;
					background-color: rgba(52, 149, 240, 0.1);
				}
			}
		}
	}
`;

import type {IDateFieldSettingsDefinition} from "../../../modules/settings/modules/field/datatypes/DateFieldSettings";
import type {IInputPropsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {DateTimeInputV5} from "../../input/datetime/DateTimeInputV5";

export const DateInputV5 = (props: IInputPropsV5<IDateFieldSettingsDefinition>) => {
	const {value, onChange, dataTypeSettings, disabled, inline} = props;

	return (
		<DateTimeInputV5
			value={value}
			format={dataTypeSettings.format}
			disabled={disabled}
			onChange={onChange}
			inline={inline}
		/>
	);
};

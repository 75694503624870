import type {Report} from "../../../data/models/Report";
import type {View} from "../../../data/models/View";
import type {IViewFolder} from "../../../data/models/ViewUtils";
import type {ViewSharingSettingsData} from "../../../generated/api/base";
import {Permission, XyiconFeature} from "../../../generated/api/base";
import type {ReportSharingSettingsData} from "../../../generated/api/reports";
import {ShareOption} from "../../modules/abstract/view/sharing/ShareOption";

export const getLabelFromViewReportOrViewFolder = (item: View | Report | IViewFolder): "view" | "report" | "view folder" => {
	if ((item as View).ownFeature) {
		return `${(item as View).ownFeature === XyiconFeature.View ? "view" : "report"}`;
	} else {
		return "view folder";
	}
};

export const getShareOptionValue = (sharing: ViewSharingSettingsData | ReportSharingSettingsData, ownedByUserId: string) => {
	let permission = Permission.View;

	if (ownedByUserId === sharing.userID) {
		permission = ShareOption.OWNER;
	} else if ((sharing as ViewSharingSettingsData).canEditSharedView || (sharing as ReportSharingSettingsData).canEditSharedReport) {
		permission = Permission.Update;
	}

	return permission;
};

import styled from "styled-components";
import {flushSync} from "react-dom";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {MathUtils} from "../../../utils/math/MathUtils";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {ReactUtils} from "../../utils/ReactUtils";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";
import PlusIcon from "../icons/plus.svg?react";
import MinusIcon from "../icons/minus.svg?react";

export interface ISliderProps {
	readonly value: number; // dragger's position: min when completely left, max when completely right
	readonly min?: number;
	readonly max?: number;
	readonly width?: number; // width of the slidertrack in px;
	readonly largeStepValue?: number; // while holding shift, it takes larger steps, so it's easier to find sweetspots
	readonly classNames?: string;
	readonly dataLabel?: string;
	readonly title?: string;
	readonly onValueChange: (newValue: number) => void;
	readonly onPointerUp?: () => void;
	readonly disabled?: boolean;
	readonly arrows?: boolean; // increase / decrease
	readonly children?: React.ReactNode;
}

/**
 * General purpose slider with linear scaling (arithmetic progression)
 */

export const SliderV5 = (props: ISliderProps) => {
	const _min = props.min ?? 0;
	const _max = props.max ?? 1;

	const onPointerStart = (pointer: Pointer) => {
		updateValue(pointer);
	};

	const onPointerMove = (pointer: Pointer) => {
		updateValue(pointer);
	};

	const updateValue = (pointer: Pointer) => {
		const handlePos = MathUtils.clamp(pointer.localX, 0, props.width);
		let newValue = (handlePos / props.width) * (_max - _min) + _min; // linear conversion from 0-1 range to min-max range

		if (props.largeStepValue > 0) {
			if (KeyboardListener.isShiftDown) {
				const roundedValue = Math.round(newValue / props.largeStepValue);

				newValue = roundedValue * props.largeStepValue;
			}
		}

		props.onValueChange(newValue);
	};

	const onLeftArrowClick = () => {
		const step = props.largeStepValue || (_max - _min) / 2;
		const newValue = MathUtils.clamp(props.value - step, _min, _max);

		flushSync(() => {
			props.onValueChange(newValue);
		});
		if (props.onPointerUp) {
			props.onPointerUp();
		}
	};

	const onRightArrowClick = () => {
		const step = props.largeStepValue || (_max - _min) / 2;
		const newValue = MathUtils.clamp(props.value + step, _min, _max);

		flushSync(() => {
			props.onValueChange(newValue);
		});
		if (props.onPointerUp) {
			props.onPointerUp();
		}
	};

	const handlePos = `translateX(${((props.value - _min) / (_max - _min)) * props.width}px)`;

	const sliderTrackStyle = {
		width: `${props.width}px`,
	};

	const slider = (
		<PointerDetectorReact
			parent={null}
			onDown={onPointerStart}
			onMove={onPointerMove}
			onUp={props.onPointerUp}
		>
			<SliderTrack
				className={ReactUtils.cls(`sliderTrack ${props.classNames || ""}`, {disabled: props.disabled})}
				style={sliderTrackStyle}
				data-label={props.dataLabel}
				title={props.title}
			>
				<div
					className="dragHandle"
					style={{
						transform: `${handlePos} translateX(-50%) translateY(-1px)`,
					}}
				/>
				{props.children}
				<div className="slider"></div>
			</SliderTrack>
		</PointerDetectorReact>
	);

	const decreaseTitle = props.title ? `Decrease ${props.title}` : "Decrease";
	const increaseTitle = props.title ? `Increase ${props.title}` : "Increase";

	return props.arrows ? (
		<SliderContainerStyled className="SliderContainer">
			<MinusIcon
				title={decreaseTitle}
				onClick={onLeftArrowClick}
			/>
			{slider}
			<PlusIcon
				title={increaseTitle}
				onClick={onRightArrowClick}
			/>
		</SliderContainerStyled>
	) : (
		slider
	);
};

const SliderTrack = styled.div`
	border-radius: ${radius.md};
	height: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;

	-webkit-user-select: none; /* Chrome all / Safari all */
	-moz-user-select: none; /* Firefox all */
	-ms-user-select: none; /* IE 10+ */
	user-select: none;

	&.Hue {
		background-image: linear-gradient(
			to right,
			hsl(0, 100%, 50%) 0%,
			hsl(60, 100%, 50%) 16.6%,
			hsl(120, 100%, 50%) 33.3%,
			hsl(180, 100%, 50%) 50%,
			hsl(240, 100%, 50%) 66.6%,
			hsl(300, 100%, 50%) 83.3%,
			hsl(360, 100%, 50%) 100%
		);
	}

	&.Opacity {
		background-image: url(./src/assets/images/spaceviewer/checkeredPattern.svg);
		background-size: 10px 10px;
		background-repeat: repeat;
	}

	.dragHandle {
		position: relative;
		z-index: 1;
		width: 24px;
		height: 24px;
		background: ${colorPalette.gray.c200Light};
		border-radius: 100%;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
		&.disabled {
			opacity: 0.5;
		}
	}

	.slider {
		background-color: black;
		width: 228px;
	}
`;

const SliderContainerStyled = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	gap: 8px;

	.dragHandle {
		position: absolute;
		cursor: pointer;
		border-radius: 100%;
		width: 16px;
		height: 16px;
		background-color: ${colorPalette.primary.c500Primary};
	}

	& * {
		touch-action: none;
	}

	svg {
		width: 16px;
		height: 16px;
		margin: 2px;
		cursor: pointer;
	}

	.slider {
		border: 1px solid ${colorPalette.gray.c300};
	}
`;

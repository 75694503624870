import styled from "styled-components";
import type {CSSProperties} from "react";
import {PopupV5} from "../popup/PopupV5";
import {HorizontalAlignment, VerticalAlignment} from "../../../utils/dom/DomUtils";
import type {XyiconFeature} from "../../../generated/api/base";
import {useAppStore} from "../../../StateManager";
import type {IModel} from "../../../data/models/Model";
import FilterIcon from "../icons/filter-icon.svg?react";
import {FilterEditorV5} from "./FilterEditorV5";

interface IFilterEditorPopupV5Props {
	readonly feature: XyiconFeature;
	readonly items: IModel[];
	readonly isLoadingList: boolean;
	readonly onSelect: (items: IModel[]) => void;
	readonly onClose: () => void;
	readonly parentRef: HTMLElement;
}

export const FilterEditorPopupV5 = ({feature, parentRef, items, isLoadingList, onSelect, onClose}: IFilterEditorPopupV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const selectedView = appState.actions.getSelectedView(feature);
	const isAdvanced = selectedView.filters.type === "advanced";
	const label = isAdvanced ? "Advanced Filter" : "Filter";

	const style: CSSProperties = {
		width: "360px",
		maxHeight: "calc(100vh - 150px)",
	};

	if (isAdvanced) {
		style.width = "652px";
	}

	return (
		<PopupV5
			onClose={onClose}
			label={
				<TitleStyled>
					<FilterIcon />
					{label}
				</TitleStyled>
			}
			className="Filter"
			style={style}
			parentRef={parentRef}
			noButtons={true}
			verticalAlignment={VerticalAlignment.bottomOuter}
			horizontalAlignment={HorizontalAlignment.outerRight}
		>
			<FilterEditorV5
				features={[feature]}
				feature={feature}
				items={items}
				onSelect={onSelect}
				loader={isLoadingList}
			/>
		</PopupV5>
	);
};

const TitleStyled = styled.div`
	display: flex;
	align-items: center;
	gap: 4px;
`;

import * as React from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import type {Type} from "../../../../../data/models/Type";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {colorPalette} from "../../../styles/colorPalette";
import InfoIcon from "../../../icons/info.svg?react";
import {TypeFormV5} from "./form/TypeFormV5";

interface ITypeDetailsTabProps {
	readonly selected: Type[];
	readonly fieldsFeature: XyiconFeature;
}

export const TypeDetailsTabV5 = (props: ITypeDetailsTabProps) => {
	const {selected, fieldsFeature} = props;

	const type = selected[0];

	return (
		<Observer>
			{() => {
				return (
					<TypeDetailsTabContainerStyled>
						{selected.length === 0 && <TypeDetailsTabNoDataStyled className="noData">Please select a type.</TypeDetailsTabNoDataStyled>}
						{selected.length === 1 && (
							<>
								<TypeDetailsTabTitleStyled>
									<InfoIcon />
									<span>details</span>
								</TypeDetailsTabTitleStyled>
								<TypeFormV5
									type={type}
									fieldsFeature={fieldsFeature}
									avatar={true}
								/>
							</>
						)}
					</TypeDetailsTabContainerStyled>
				);
			}}
		</Observer>
	);
};

const TypeDetailsTabContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 16px;
`;

const TypeDetailsTabNoDataStyled = styled.div`
	font-size: 18px;
	color: ${colorPalette.libraryColors.explosive};
	height: 80%;
	width: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TypeDetailsTabTitleStyled = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 8px;

	svg {
		width: 18px;
		height: 18px;
		color: ${colorPalette.gray.c950};
		path {
			stroke-width: 2px;
		}
	}

	span {
		font-size: 16px;
		color: ${colorPalette.gray.c950};
		font-weight: 700;
		text-transform: uppercase;
	}
`;

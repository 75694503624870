import {inject, observer} from "mobx-react";
import * as React from "react";
import styled from "styled-components";
import type {PermissionSet} from "../../../../../data/models/permission/PermissionSet";
import type {TransportLayer} from "../../../../../data/TransportLayer";
import {Permission, XyiconFeature} from "../../../../../generated/api/base";
import {SelectSliderPermissionOptionsStyled, SelectSliderRowStyled, SelectSliderV5} from "../../../details/SelectSliderV5";

const GeneralPermissionsStyled = styled.div`
	.container {
		width: 200px;

		h4 {
			font-size: 18px;
			width: 200px;
		}

		${SelectSliderPermissionOptionsStyled} {
			margin-top: 16px;
			grid-template-columns: repeat(2, 80px) 23px !important;
			padding-left: 15px;
		}

		${SelectSliderRowStyled} {
			padding: 8px 10px;
		}
	}
`;

interface IGeneralPermissionsProps {
	readonly permissionSet: PermissionSet;
	readonly transport?: TransportLayer;
}

@inject("transport")
@observer
export class GeneralPermissionsV5 extends React.Component<IGeneralPermissionsProps> {
	public static generalPermissionOptions = [
		{
			id: Permission.View,
			label: "View",
		},
		{
			id: Permission.Update,
			label: "Update",
		},
		{
			id: Permission.Delete,
			label: "Delete",
		},
	];

	private onChange = (rowKeys: string[], value: Permission) => {
		const {permissionSet, transport} = this.props;

		permissionSet.portfolioPermission = value;
		transport.services.feature.update(permissionSet.id, XyiconFeature.PermissionSet, permissionSet.getData());
	};

	public override render() {
		return (
			<GeneralPermissionsStyled>
				<div className="container">
					<h4>General permissions</h4>
					<SelectSliderV5
						options={GeneralPermissionsV5.generalPermissionOptions}
						rows={[
							{
								value: this.props.permissionSet.portfolioPermission,
								label: "",
							},
						]}
						onChange={this.onChange}
					/>
				</div>
			</GeneralPermissionsStyled>
		);
	}
}

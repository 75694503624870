import type {Color} from "../../../generated/api/base";
import {ColorUtils} from "../../../utils/ColorUtils";

type EyeDropperIconProps = {
	readonly color: Color;
};

const pathD =
	"M16.6667 13.2C16.6667 15.851 14.5774 18 12 18C9.42271 18 7.33337 15.851 7.33337 13.2C7.33337 12.5635 7.45384 11.9559 7.67259 11.4C8.36488 9.6409 12 6 12 6C12 6 15.6352 9.6409 16.3275 11.4C16.5462 11.9559 16.6667 12.5635 16.6667 13.2Z";

export const EyeDropperIcon = (props: EyeDropperIconProps) => {
	const {
		color: {hex, transparency},
	} = props;
	const rgbColor = ColorUtils.hex2rgb(hex, 1 - transparency, "string"); // Ex: hexColor = "FF0000"

	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d={pathD}
				fill="url(#pattern0_23345_2148)"
				stroke="#363636"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d={pathD}
				fill={rgbColor as string}
				stroke="#363636"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<defs>
				<pattern
					id="pattern0_23345_2148"
					patternContentUnits="objectBoundingBox"
					width="1"
					height="1"
				>
					<use
						xlinkHref="#image0_23345_2148"
						transform="matrix(0.0200893 0 0 0.015625 -0.142857 0)"
					/>
				</pattern>
				<image
					id="image0_23345_2148"
					width="64"
					height="64"
					xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
				/>
			</defs>
		</svg>
	);
};

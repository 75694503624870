import styled from "styled-components";
import {IconButtonStyled} from "../interaction/IconButtonV5";
import {colorPalette} from "../styles/colorPalette";
import {radius} from "../styles/styles";

export const TextAlignOptionsStyled = styled.div`
	position: absolute;
	top: calc(100% + 8px);
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	padding: 8px;
	gap: 4px;
	border-radius: ${radius.md};
	background-color: ${colorPalette.white};
	box-shadow: 0px 4px 8px 0px #00000080;
	color: ${colorPalette.gray.c950};

	${IconButtonStyled} {
		&:hover {
			background-color: ${colorPalette.gray.c200Light};
		}
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}
`;

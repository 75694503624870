import * as React from "react";
import {observer} from "mobx-react";
import {Field} from "../../../../../../widgets/form/field/Field";
import {TextInput} from "../../../../../../widgets/input/text/TextInput";
import type {Report} from "../../../../../../../data/models/Report";
import {ReportScope} from "../../../../../../../generated/api/reports";
import {XyiconFeature} from "../../../../../../../generated/api/base";
import {ReportScopeWidget} from "./ReportScopeWidget";

interface IReportDetailsProps {
	readonly report: Report;
}

interface IModuleOption {
	id: XyiconFeature;
	label: string;
}

@observer
export class ReportDetails extends React.Component<IReportDetailsProps> {
	public static isValid(report: Report) {
		if (!report.name) {
			return false;
		}
		if (report.scope === ReportScope.PortfolioIDList && report.portfolioIDList.length === 0) {
			return false;
		}
		return true;
	}

	private _moduleOptions: IModuleOption[] = [
		{
			id: XyiconFeature.Xyicon,
			label: "Xyicon",
		},
		// {
		// 	id: XyiconFeature.XyiconCatalog,
		// 	label: "Catalog",
		// },
		{
			id: XyiconFeature.Boundary,
			label: "Boundary",
		},
	];

	private onChangeDescription = (value: string) => {
		this.props.report.description = value;
	};

	public override render() {
		const {report} = this.props;

		return (
			<div className="ReportDetails">
				<Field label="Report Name">
					<TextInput
						autoFocus={!report.name}
						value={report.name}
						onChange={(value) => (report.name = value)}
					/>
				</Field>
				<Field
					label="Module"
					className="text"
				>
					{this._moduleOptions.find((option) => option.id === report.reportFeature)?.label || ""}
					{/*<SelectInput*/}
					{/*	sort={false}*/}
					{/*	options={this._moduleOptions}*/}
					{/*	render={option => option.label}*/}
					{/*	selected={this._moduleOptions.find(option => option.id === report.reportFeature)}*/}
					{/*	onChange={this.onChangeModule}*/}
					{/*	alignment="bottom"*/}
					{/*/>*/}
				</Field>
				<ReportScopeWidget report={report} />
				<Field label="Description">
					<TextInput
						value={report.description}
						onChange={this.onChangeDescription}
						isTextArea={true}
					/>
				</Field>
			</div>
		);
	}
}

import {useRef, useState} from "react";
import styled from "styled-components";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {MarkupType} from "../../../../../generated/api/base";
import {changeMarkupType, markupTypeToMarkupToolName} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/MarkupUtils";
import type {Markup3D} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import {MarkupsWithCustomText} from "../../../../modules/space/spaceeditor/logic3d/elements3d/markups/MarkupStaticElements";
import type {ExtendedToolIconKey} from "../toolbar/ToolButtonV5";
import {ToolButtonStyled, ToolButtonV5} from "../toolbar/ToolButtonV5";
import {colorPalette} from "../../../styles/colorPalette";
import {useClickOutside} from "../../../utils";
import {MarkupTypeOptionsStyled, MarkupTypeOptionsV5} from "./MarkupTypeOptionsV5";

interface IMarkupTypeChangerProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly forceUpdate: () => void;
}

export const MarkupTypeChangerV5 = (props: IMarkupTypeChangerProps) => {
	const {spaceViewRenderer, forceUpdate} = props;
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>();

	useClickOutside([ref], () => {
		setIsOpen(false);
	});

	const selectedItems = spaceViewRenderer.spaceItemController.selectedItems;

	const onMarkupTypeClick = () => {
		setIsOpen((o) => !o);
	};

	const onMarkupTypeChange = async (newType: MarkupType) => {
		await changeMarkupType(selectedItems as Markup3D[], newType, spaceViewRenderer);
		forceUpdate();
	};

	const getActiveIcon = (): ExtendedToolIconKey => {
		const firstSelectedObject = selectedItems[0] as Markup3D;

		const toolName = markupTypeToMarkupToolName(firstSelectedObject?.type);
		const tool = spaceViewRenderer.tools[toolName];

		return tool.icon as ExtendedToolIconKey;
	};

	const areOnlySupportedTypesSelected = selectedItems.every(
		(item) => item.spaceItemType === "markup" && MarkupsWithCustomText.includes((item as Markup3D).type),
	);

	return (
		areOnlySupportedTypesSelected &&
		selectedItems.length === 1 && (
			<MarkupTypeChangerStyled
				ref={ref}
				className="button flexCenter MarkupTypeChanger"
			>
				<ToolButtonV5
					icon={getActiveIcon()}
					color={{hex: (selectedItems[0] as Markup3D).color}}
					onClick={onMarkupTypeClick}
					isActive={isOpen}
					title="Change Markup Type..."
					titleAlignment="top"
				/>
				{isOpen && (
					<MarkupTypeOptionsV5
						activeType={(selectedItems[0] as Markup3D).type}
						spaceViewRenderer={spaceViewRenderer}
						onChange={onMarkupTypeChange}
						activeColor={{hex: (selectedItems[0] as Markup3D).color}}
					/>
				)}
			</MarkupTypeChangerStyled>
		)
	);
};

const MarkupTypeChangerStyled = styled.div`
	${ToolButtonStyled} {
		&:hover {
			background-color: ${colorPalette.gray.c700Dark};
		}
		&.isActive {
			background-color: ${colorPalette.primary.c500Primary};
		}
	}

	${MarkupTypeOptionsStyled} {
		${ToolButtonStyled} {
			&:hover {
				background-color: ${colorPalette.gray.c200Light};
			}
			&.isActive {
				background-color: ${colorPalette.primary.c500Primary};
			}
		}
	}
`;

import {XyiconFeature} from "../../generated/api/base";

export type FeatureMap<T> = {
	[feature: number]: T;
};

export const typesFeatures: {[key in XyiconFeature]?: XyiconFeature} = {
	[XyiconFeature.XyiconCatalog]: XyiconFeature.Xyicon, // Catalog doesn't have types, the fields are mapped to Xyicon types
};

// This is used by settings UI when adding fields to a layout,
// and many other places in the app
export const inheritedFeatures: {[key in XyiconFeature]?: XyiconFeature[]} = {
	[XyiconFeature.Portfolio]: [XyiconFeature.Portfolio],
	[XyiconFeature.Space]: [XyiconFeature.Portfolio, XyiconFeature.Space],
	[XyiconFeature.XyiconCatalog]: [XyiconFeature.XyiconCatalog],
	[XyiconFeature.Boundary]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Space, // a boundary may be unplotted -> no space
		XyiconFeature.Boundary, // may be linked to other boundary
	],
	[XyiconFeature.Xyicon]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Space, // xyicon can be unplotted
		XyiconFeature.Boundary,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Xyicon, // may be linked to other xyicon
	],
	[XyiconFeature.SpaceEditor]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Space,
	],
	[XyiconFeature.Event]: [XyiconFeature.Event],
};

// Describes which features need to be loaded for a given feature
// Eg. when opening the Space module: Portfolio, Space and SpaceVersion lists need to be loaded.
export const loadingDependencyFeatures: {[key in XyiconFeature]?: XyiconFeature[]} = {
	[XyiconFeature.Portfolio]: [XyiconFeature.Portfolio],
	[XyiconFeature.Space]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Space,
		XyiconFeature.SpaceVersion, // SpaceFiles has references to spaceversions, so we have to make sure that spaceversions are loaded before spaces
	],
	[XyiconFeature.XyiconCatalog]: [XyiconFeature.XyiconCatalog],
	[XyiconFeature.Boundary]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Space, // a boundary may be unplotted -> no space
		XyiconFeature.Boundary, // may be linked to other boundary
		XyiconFeature.XyiconCatalog, // needed to be able to show xyicon icon on Details Panel of Boundary
		XyiconFeature.Link, // needed to be able to show connections between boundaries-boundaries, or boundaries-xyicons
		XyiconFeature.Xyicon, // may be linked to xyicon
	],
	[XyiconFeature.Xyicon]: [
		XyiconFeature.Portfolio,
		XyiconFeature.Space, // xyicon can be unplotted
		XyiconFeature.Boundary,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Xyicon, // may be linked to other xyicon
		XyiconFeature.SpaceVersion, // Without this, spaceversion fields in xyicon module are not shown
		XyiconFeature.Link, // needed to be able to show connections between xyicons-xyicons, or boundaries-xyicons
	],
	[XyiconFeature.SpaceEditor]: [
		XyiconFeature.Portfolio,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.SpaceVersion, // SpaceFiles has references to spaceversions, so we have to make sure that spaceversions are loaded before spaces
	],
	[XyiconFeature.Event]: [XyiconFeature.Event],
};

// Check getFieldsForFilters before modifying this.
export const inheritedFeaturesForFilter = {
	[XyiconFeature.Portfolio]: [XyiconFeature.Portfolio],
	[XyiconFeature.Space]: [XyiconFeature.Space],
	[XyiconFeature.XyiconCatalog]: [XyiconFeature.XyiconCatalog],
	[XyiconFeature.Boundary]: [XyiconFeature.Boundary, XyiconFeature.Space],
	[XyiconFeature.Xyicon]: [XyiconFeature.Portfolio, XyiconFeature.Space, XyiconFeature.Boundary, XyiconFeature.XyiconCatalog, XyiconFeature.Xyicon],
	[XyiconFeature.SpaceEditor]: [XyiconFeature.Xyicon, XyiconFeature.Boundary],
	[XyiconFeature.Event]: [XyiconFeature.Event],
};

export const featureTitles: {[key in XyiconFeature]?: string} = {
	[XyiconFeature.Portfolio]: "Portfolio",
	[XyiconFeature.Space]: "Space",
	[XyiconFeature.XyiconCatalog]: "Catalog",
	[XyiconFeature.Xyicon]: "Xyicon",
	[XyiconFeature.Boundary]: "Boundary",
	[XyiconFeature.Markup]: "Markup",
	[XyiconFeature.Document]: "Document",
	[XyiconFeature.OrganizationDocument]: "Document",
	[XyiconFeature.PortfolioDocument]: "Document",
	[XyiconFeature.User]: "User",
	[XyiconFeature.UserGroup]: "User Group",
	[XyiconFeature.PortfolioGroup]: "Portfolio Group",
};

export const featureTitlePlurals: {[key in XyiconFeature]?: string} = {
	[XyiconFeature.Portfolio]: "Portfolios",
	[XyiconFeature.Space]: "Spaces",
	[XyiconFeature.XyiconCatalog]: "Catalog",
	[XyiconFeature.Xyicon]: "Xyicons",
	[XyiconFeature.Boundary]: "Boundaries",
	[XyiconFeature.Markup]: "Markups",
	[XyiconFeature.Document]: "Documents",
	[XyiconFeature.OrganizationDocument]: "Documents",
	[XyiconFeature.PortfolioDocument]: "Documents",
	[XyiconFeature.User]: "Users",
	[XyiconFeature.Report]: "Reports",
};

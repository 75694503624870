import {Observer} from "mobx-react";
import styled from "styled-components";
import type {Field} from "../../../../../data/models/field/Field";
import type {XyiconFeature} from "../../../../../generated/api/base";
import InfoIcon from "../../../icons/info.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import {FieldFormV5} from "./FieldFormV5";

interface IFieldDetailsTabProps {
	readonly selected: Field[];
	readonly typesFeature: XyiconFeature;
}

export const FieldDetailsTabV5 = (props: IFieldDetailsTabProps) => {
	const {selected, typesFeature} = props;

	return (
		<Observer>
			{() => {
				const field = selected[0];

				return (
					<FieldDetailsContainerStyled>
						{selected.length === 0 && <FieldDetailsTabNoDataStyled>Please select a field.</FieldDetailsTabNoDataStyled>}
						{selected.length === 1 && (
							<>
								<FieldDetailsTabTitleStyled>
									<InfoIcon />
									<span>details</span>
								</FieldDetailsTabTitleStyled>
								<FieldFormV5
									field={field}
									typesFeature={typesFeature}
								/>
							</>
						)}
					</FieldDetailsContainerStyled>
				);
			}}
		</Observer>
	);
};

const FieldDetailsContainerStyled = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	gap: 16px;
`;

const FieldDetailsTabNoDataStyled = styled.div`
	font-size: 18px;
	color: #c4c4c4;
	height: 80%;
	width: 100%;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FieldDetailsTabTitleStyled = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	gap: 8px;

	svg {
		width: 18px;
		height: 18px;
		color: ${colorPalette.gray.c950};
		path {
			stroke-width: 2px;
		}
	}

	span {
		font-size: 16px;
		color: ${colorPalette.gray.c950};
		font-weight: 700;
		text-transform: uppercase;
	}
`;

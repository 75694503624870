import * as React from "react";
import {inject} from "mobx-react";
import {InfoBubble} from "../../modules/abstract/common/infobutton/InfoBubble";
import {ReactUtils} from "../../utils/ReactUtils";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {HorizontalAlignment, VerticalAlignment, DomUtils} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import type {AppState} from "../../../data/state/AppState";
import {SVGIcon} from "./SVGIcon";

export interface IDropdownOption {
	label: string;
	disabled?: boolean;
	infoText?: string;
	icon?: string;
	onClick: () => void;
}

export interface ISubOptionWrapper {
	label: string;
	disabled?: boolean;
	infoText?: string;
	icon?: string;
	options: (IDropdownOption | ISubOptionWrapper)[];
}

interface IDropdownOptionsProps {
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly style?: React.CSSProperties;
	readonly options: (IDropdownOption | ISubOptionWrapper)[];
	readonly appState?: AppState;
	readonly className?: string;
	readonly onClose: () => void;
}

interface IDropdownOptionsState {
	openOption: (IDropdownOption | ISubOptionWrapper) | null; // for multi-level dropdowns. If it has a value, this option is "open", so we show its children
	transform: TransformObj;
}

@inject("appState")
export class DropdownOptions extends React.Component<IDropdownOptionsProps, IDropdownOptionsState> {
	private _container = React.createRef<HTMLDivElement>();
	private _floatingElement = React.createRef<HTMLDivElement>();

	constructor(props: IDropdownOptionsProps) {
		super(props);

		this.state = {
			openOption: null,
			transform: null,
		};
	}

	public override componentDidUpdate(prevProps: IDropdownOptionsProps, prevState: IDropdownOptionsState) {
		if (JSON.stringify(prevState.openOption) !== JSON.stringify(this.state.openOption) && this._container.current && this._floatingElement.current) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._container.current,
					this._floatingElement.current,
					VerticalAlignment.bottom,
					HorizontalAlignment.outerRight,
					0,
					0,
					true,
				),
			});
		}
	}

	public override render() {
		const {style, divRef, options, className, onClose, appState} = this.props;

		return (
			<div
				className={`DropdownOptions ${className || ""}`}
				style={style || {}}
				ref={divRef}
			>
				{options.map((option, index) => {
					const hasSubOptions = Array.isArray((option as ISubOptionWrapper).options);
					const isSubMenuOpen = hasSubOptions && option === this.state.openOption;

					const inlineStyle: React.CSSProperties = this._floatingElement.current &&
						isSubMenuOpen && {
							transform: this.state.transform?.translate,
						};

					return (
						<div
							key={index}
							ref={this._container}
							className={ReactUtils.cls(`option`, {hasSubOptions, disabled: option.disabled, hasIcon: option.icon})}
							onMouseOver={() => {
								this.setState({openOption: option});
							}}
							onMouseDown={() => {
								if ((option as IDropdownOption).onClick && !option.disabled) {
									onClose();
									(option as IDropdownOption).onClick();
								}
							}}
						>
							{option.icon && (
								<SVGIcon
									classNames="label-icon"
									icon={option.icon}
								/>
							)}
							{option.label}
							{hasSubOptions && (
								<>
									<div style={{width: "20px"}}></div>
									<SVGIcon
										icon="right"
										classNames="right"
									/>
								</>
							)}
							{option.infoText && <InfoBubble content={option.infoText} />}
							{isSubMenuOpen && (
								<DomPortal destination={appState.app.modalContainer}>
									<DropdownOptions
										divRef={this._floatingElement}
										options={(option as ISubOptionWrapper).options}
										onClose={onClose}
										style={inlineStyle}
										className="sub"
									/>
								</DomPortal>
							)}
						</div>
					);
				})}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Navigation} from "../../../Navigation";
import type {AppState} from "../../../data/state/AppState";
import {ReactUtils} from "../../utils/ReactUtils";
import {XHRLoader} from "../../../utils/loader/XHRLoader";
import {LoaderIcon} from "../../widgets/button/LoaderIcon";
import {PassSecurityLevel, PasswordUtils} from "../../widgets/password/PasswordUtils";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import {ButtonV5} from "../button/ButtonV5";
import {PasswordInputV5} from "../input/password/PasswordInputV5";
import {TextInputV5} from "../details/datatypes/TextInputV5";
import CircleQuestionIcon from "../icons/circle-question.svg?react";
import CheckCircleIcon from "../icons/check-circle.svg?react";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {InfoButtonV5} from "../button/InfoButtonV5";
import {UserStaticPageWrapperStyled, FormContainerStyled, PasswordValidatorStyled} from "./UserStaticV5.Style";

interface INewUserRegistrationProps {
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly appState?: AppState;
}

interface INewUserRegistrationStates {
	tokenID: string;
	token: string;
	firstname: string;
	lastname: string;
	username: string;
	password: string;
	passwordConfirm: string;
	email: string;
	errorMessage: string;
	loading: boolean;
	registerInProgress: boolean;
	verificationInProgress: boolean;
	isUserTokenInvalid: boolean;
	passwordStrengthMessage: string;
}

@inject("navigation")
@inject("transport")
@inject("appState")
@observer
export class NewUserRegistrationV5 extends React.Component<INewUserRegistrationProps, INewUserRegistrationStates> {
	private _usernameParent = React.createRef<HTMLInputElement>();
	private _username = React.createRef<HTMLDivElement>();

	constructor(props: INewUserRegistrationProps) {
		super(props);
		this.state = {
			tokenID: "",
			token: "",
			firstname: "",
			lastname: "",
			username: "",
			password: "",
			passwordConfirm: "",
			email: "",
			errorMessage: "",
			loading: false,
			registerInProgress: false,
			verificationInProgress: false,
			isUserTokenInvalid: false,
			passwordStrengthMessage: "",
		};
	}

	private get passwordConfirmError() {
		return this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password ? "Passwords do not match." : "";
	}

	private handleFirstnameInput = (value: string) => {
		this.setState({
			firstname: value,
		});
	};

	private handleLastnameInput = (value: string) => {
		this.setState({
			lastname: value,
		});
	};

	private handleUsernameInput = (value: string) => {
		this.setState({
			username: value,
			errorMessage: "",
		});
	};

	private handleNewPasswordInput = (value: string) => {
		let passwordStrengthMessage;

		if (value.length < 8 || !/[0-9]/.test(value) || !/[a-z]/.test(value) || !/[A-Z]/.test(value)) {
			passwordStrengthMessage = "Password does not meet criteria. Please try again.";
		} else {
			passwordStrengthMessage = "";
		}

		this.setState({
			password: value,
			passwordStrengthMessage: passwordStrengthMessage,
		});
	};

	private handlePasswordConfirm = (value: string) => {
		this.setState({
			passwordConfirm: value,
		});
	};

	private onGoToLoginClick = () => {
		this.props.navigation.goToLogin();
	};

	private verifyNewUserToken = async (tokenID: string, token: string) => {
		this.setState({verificationInProgress: true});

		const {result, error} = await this.props.transport.request({
			url: "auth/verifynewusertoken",
			method: XHRLoader.METHOD_POST,
			params: {tokenID, token},
		});

		if (error) {
			this.setState({isUserTokenInvalid: true});
		} else {
			this.setState({
				email: result.email,
			});
		}

		window.setTimeout(
			() => {
				this.setState({verificationInProgress: false});
			},
			Math.random() * 2000 + 2000,
		);
	};

	private setupUserProfile = async () => {
		const {tokenID, token, firstname, lastname, password, email} = this.state;

		this.setState({loading: true});

		let username = this.state.username === "" ? email : this.state.username;

		const {result, error} = await this.props.transport.request({
			url: "auth/setupuserprofile",
			method: XHRLoader.METHOD_POST,
			params: {
				firstname,
				lastname,
				username,
				password,
				countryCode: "",
				phoneNumber: "",
				tokenID: tokenID,
				token: token,
				userGeneralSetting: {
					timeZone: "GMT+2",
					theme: this.props.appState.theme,
					language: "en",
				},
			},
		});

		this.setState({loading: false});

		if (error) {
			this.setState({errorMessage: error.ErrorMessage});
		} else {
			this.setState({registerInProgress: true});

			window.setTimeout(
				() => {
					this.setState({registerInProgress: false});
					this.props.navigation.goToLogin();
				},
				Math.random() * 3000 + 3000,
			);
		}
	};

	private shouldRegisterBtnEnabled = () => {
		const {token, tokenID, firstname, lastname, errorMessage, password, passwordConfirm, registerInProgress} = this.state;

		if (
			token &&
			tokenID &&
			password === passwordConfirm &&
			PasswordUtils.getPasswordSecurityLevel(password) > PassSecurityLevel.RequirementsNotMet &&
			!registerInProgress &&
			password.length <= 64 &&
			firstname &&
			lastname &&
			!errorMessage
		) {
			return true;
		} else {
			return false;
		}
	};

	public override componentDidMount() {
		const href = location.href;
		const tokenID = ReactUtils.searchForParamInUrl("tokenid", href);
		const token = ReactUtils.searchForParamInUrl("token", href);

		if (tokenID && token) {
			this.setState({token, tokenID});
			this.verifyNewUserToken(tokenID, token);
		}
	}

	public override componentWillUnmount() {
		this.props.transport.services.auth.logout();
	}

	private renderContent() {
		const {username, password, passwordConfirm, email, errorMessage, loading, registerInProgress, verificationInProgress, isUserTokenInvalid} =
			this.state;

		const usernameInlineStyle: React.CSSProperties = {
			top: "-40px",
			left: "-160px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
		};

		if (!isUserTokenInvalid) {
			if (registerInProgress) {
				return (
					<>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className="title">Registration was successful!</div>
						<div className="fieldContainer vbox description">
							<p>
								Redirecting to Xyicon Login...
								<a
									className="textLink"
									onClick={this.onGoToLoginClick}
								>
									Click here
								</a>{" "}
								if not redirected.
							</p>
						</div>
					</>
				);
			} else {
				return (
					<>
						<div className="welcomeMsg">Welcome to</div>
						<SVGIcon
							classNames="xyicon-logo"
							icon="xyiconlogo"
						/>
						<div className={ReactUtils.cls("fieldContainer vbox", {loading: verificationInProgress})}>
							<div className="inputGroup firstname">
								<div className="labelName">First Name</div>
								<TextInputV5
									onInput={this.handleFirstnameInput}
									placeholder="First Name"
								/>
							</div>
							<div className="inputGroup">
								<div className="labelName">Last Name</div>
								<TextInputV5
									onInput={this.handleLastnameInput}
									placeholder="Last Name"
								/>
							</div>
							<div className="inputGroup">
								<div className="labelName">Email</div>
								<TextInputV5
									className="email"
									value={email}
									disabled={true}
								/>
							</div>
							<div className="inputGroup">
								<span className="username">
									<div className="labelName">
										Username (Optional)
										<InfoButtonV5
											bubbleText={"Enter a username as an additional login ID to your email."}
											icon="info"
										/>
									</div>
									<TextInputV5
										onInput={this.handleUsernameInput}
										placeholder="Username (Optional)"
										value={username}
										divRef={this._usernameParent}
									/>
									{errorMessage && (
										<div className="usernameerror">
											<div
												ref={this._username}
												className={ReactUtils.cls("infoIcon editing errorInfo", {left: false})}
												style={usernameInlineStyle}
											>
												<InfoButtonV5
													bubbleText="Username exists. Please select a new one."
													icon="question-mark"
													isError={true}
												/>
											</div>
										</div>
									)}
								</span>
							</div>
							<div className="inputGroup password">
								<div className="labelName">Password</div>
								<PasswordInputV5
									value={password}
									onInput={this.handleNewPasswordInput}
									infoText={this.state.passwordStrengthMessage !== "" ? this.state.passwordStrengthMessage : ""}
								/>
								{password !== "" && this.state.passwordStrengthMessage === "" && <CheckCircleIcon className="check-icon" />}
							</div>
							<div className="inputGroup password confirmPassword">
								<div className="labelName">Confirm Password</div>
								<PasswordInputV5
									value={passwordConfirm}
									onInput={this.handlePasswordConfirm}
								/>
								{this.passwordConfirmError && (
									<InfoButtonV5
										bubbleText={"Passwords do not match."}
										icon="error"
										isError={true}
									/>
								)}
							</div>
							<PasswordValidatorStyled className="PasswordValidator">
								<div className="pwddRequirementsMessage">
									Password must be 8+ characters and contain at least one number, uppercase letter, and lowercase letter. Special characters are
									optional
								</div>
							</PasswordValidatorStyled>

							<ButtonV5
								className="primary confirm"
								label={loading ? "Registering..." : "Register"}
								disabled={loading || !this.shouldRegisterBtnEnabled()}
								onClick={this.setupUserProfile}
							/>
							<div className="helpbutton">
								<IconButtonV5
									title="Help"
									label="Help"
									IconComponent={CircleQuestionIcon}
									onClick={() => this.props.navigation.openInNewTab("https://support.xyicon.com/docs")}
								/>
							</div>
						</div>
					</>
				);
			}
		} else {
			return (
				<>
					<SVGIcon
						classNames="xyicon-logo"
						icon="xyiconlogo"
					/>
					<div className="title">Error</div>
					<div className="fieldContainer vbox description">
						Registration link has expired. Please reach out to your administrator to request a new invite.<br></br>
						If you've already registered, simply proceed to the login page to access your account.<br></br>
						For any assistance, feel free to contact our support team at support@xyicon.com Thank you!<br></br>
						<br></br>
						<span style={{fontSize: "16px"}}>
							{" "}
							<a
								className="textLink"
								onClick={this.onGoToLoginClick}
							>
								Go to login page
							</a>
						</span>
					</div>
				</>
			);
		}
	}

	public override render() {
		const {verificationInProgress, isUserTokenInvalid} = this.state;

		if (verificationInProgress) {
			return (
				<UserStaticPageWrapperStyled className="UserStaticPageWrapper">
					<div className="preLoadingPage">
						<LoaderIcon />
						<p>Verification in progress ...</p>
					</div>
				</UserStaticPageWrapperStyled>
			);
		} else {
			return (
				<UserStaticPageWrapperStyled className="UserStaticPageWrapper">
					<div className={ReactUtils.cls("UserStaticPage", {invalidLink: isUserTokenInvalid})}>
						<FormContainerStyled className="formContainer hbox alignCenter newuserreg">{this.renderContent()}</FormContainerStyled>
					</div>
				</UserStaticPageWrapperStyled>
			);
		}
	}
}

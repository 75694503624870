import * as React from "react";
import {Functions} from "../../../../utils/function/Functions";
import {InfoButton} from "../../../modules/abstract/common/infobutton/InfoButton";
import {ToggleSwitch} from "./ToggleSwitch";

interface IToggleSwitchFieldProps {
	readonly value: boolean;
	readonly onChange: (value: boolean) => void;
	readonly label: string;
	readonly disabled?: boolean;
	readonly labelFirst?: boolean; // changes the order of toggler and label
	readonly classNames?: string;
	readonly divRef?: React.RefObject<HTMLDivElement>;
	readonly bubbleText?: string;
	readonly noBooleanLabel?: boolean;
	readonly onMouseOver?: () => void;
	readonly onMouseLeave?: () => void;
}

export class ToggleSwitchField extends React.Component<IToggleSwitchFieldProps> {
	private onChange = (value: boolean) => {
		this.props.onChange(value);
	};

	public override render() {
		const {value, label, classNames, disabled, divRef, bubbleText, noBooleanLabel, onMouseLeave, onMouseOver} = this.props;

		let style: React.CSSProperties = {};

		if (this.props.labelFirst) {
			style.flexDirection = "row-reverse";
			style.justifyContent = "flex-end";
		}

		return (
			<div
				ref={divRef}
				className={`ToggleSwitchField ${classNames || ""}`}
				style={style}
				onMouseOver={onMouseOver ? onMouseOver : Functions.emptyFunction}
				onMouseLeave={onMouseLeave ? onMouseLeave : Functions.emptyFunction}
			>
				<ToggleSwitch
					value={value}
					onChange={this.onChange}
					disabled={disabled ?? false}
					noBooleanLabel={noBooleanLabel}
				/>
				<label className="label">{label}</label>
				{bubbleText && <InfoButton bubbleText={bubbleText} />}
			</div>
		);
	}
}

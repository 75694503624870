import styled from "styled-components";
import {baseDistance, fontSize, fontWeight} from "../../../styles/styles";
import {colorPalette} from "../../../styles/colorPalette";

export const ColorRules = styled.div`
	.flexCenter {
		display: flex;
		color: black;
	}

	.ruleIcons {
		width: 16px;
		height: 16px;
		border-radius: 1px;
		margin-right: 13px;

		&.indicator.Boundary,
		&.indicator.Xyicon {
			background: ${colorPalette.white};
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid ${colorPalette.primary.c500Primary};

			&::before {
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: ${colorPalette.primary.c500Primary};
				content: "";
				display: block;
			}
		}

		&.highlight.Boundary,
		&.highlight.Xyicon {
			background: ${colorPalette.primary.c500Primary};
		}
	}

	.ColorRuleRow {
		display: grid;
		grid-template-columns: 104px 128px 128px;
		grid-gap: ${baseDistance.sm};
		align-items: center;

		&.head {
			display: grid;
			grid-template-columns: 86px 128px 128px;
			grid-gap: ${baseDistance.sm};
		}

		&:not(:last-child) {
			margin-bottom: ${baseDistance.xs};
		}

		&:first-child {
			color: ${colorPalette.gray.c200Light};
		}

		&:nth-child(2) {
			margin-bottom: ${baseDistance.sm};

			.label {
				font-weight: ${fontWeight.normal};
			}
		}

		.label {
			font-size: ${fontSize.md};
			font-weight: ${fontWeight.thin};
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.SelectInput {
			margin: 0;
			height: 32px;
		}
	}
`;

import {observer} from "mobx-react";
import * as React from "react";
import type {XyiconFeature} from "../../../../../../generated/api/base";
import type {IModel} from "../../../../../../data/models/Model";
import type {AppActions} from "../../../../../../data/state/AppActions";
import type {SidePanel} from "../../../../abstract/sidepanel/SidePanel";
import {Button} from "../../../../../widgets/button/Button";

interface IFilterChangeNotificationProps {
	readonly actions: AppActions;
	readonly feature: XyiconFeature;
	readonly sidePanelRef: React.RefObject<SidePanel<IModel>>;
}

@observer
export class FilterChangeNotification extends React.Component<IFilterChangeNotificationProps> {
	private onResetFiltersClicked = () => {
		const sidePanel = this.props.sidePanelRef.current;

		if (sidePanel) {
			const selectedView = this.props.actions.getSelectedView(this.props.feature);

			// Reset local sidePanel filters
			sidePanel.filterEditor?.reset();

			// Reset view filters
			selectedView.resetFiltersToSaved();

			// Reset SpaceEditor filters
			this.props.actions.updateSpaceEditorFilterState(selectedView.filters);

			this.forceUpdate();
		}
	};

	private isResetButtonEnabled() {
		return !!this.props.sidePanelRef.current?.filterEditor?.isResetButtonEnabled();
	}

	public override render() {
		const isResetButtonEnabled = this.isResetButtonEnabled();

		return (
			isResetButtonEnabled && (
				<div className="FilterChangeNotification hbox alignCenter">
					<Button
						className="secondary"
						label="Reset filters"
						icon="reset"
						onClick={this.onResetFiltersClicked}
					/>
				</div>
			)
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import type {Field as FieldModel, IFormattingRule} from "../../../../../../data/models/field/Field";
import {Field} from "../../../../../widgets/form/field/Field";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import type {IFilterOperatorConfig} from "../../../../../../data/models/filter/operator/FilterOperators";
import {FilterOperators} from "../../../../../../data/models/filter/operator/FilterOperators";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {Button} from "../../../../../widgets/button/Button";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {ColorSelector} from "../../../../abstract/common/colorselector/ColorSelector";
import {ArrayUtils} from "../../../../../../utils/data/array/ArrayUtils";
import {FieldDataType} from "../../../../../../generated/api/base";
import {FilterOperator} from "../../../../../../data/models/filter/operator/FilterOperator";
import {VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import type {IBooleanFieldSettingsDefinition} from "./BooleanFieldSettings";

interface ITextFormattingSettingsProps {
	readonly field: FieldModel;
	readonly onChange: () => void;
}

@observer
export class FormattingSettings extends React.Component<ITextFormattingSettingsProps> {
	private onRemoveRule = (rule: IFormattingRule) => {
		const {field, onChange} = this.props;
		const rules = field.formattingRules;

		if (rules) {
			const index = rules.indexOf(rule);

			if (index >= 0) {
				rules.splice(index, 1);
			}
		}

		onChange();
	};

	private getOperators = (field: FieldModel) => {
		// remove IS_ANY_OF and IS_NOT_ANY_OF operators
		const operators = FieldDataTypes.map[field.dataType].operators;

		return operators(field.dataTypeSettings).filter((o) => ![FilterOperator.IS_ANY_OF, FilterOperator.IS_NOT_ANY_OF].includes(o));
	};

	private onAddRuleClick = () => {
		const {field, onChange} = this.props;

		const operator = FieldDataTypes.map[field.dataType].operators()[0];
		const newRules = ArrayUtils.add(field.formattingRules, {
			operator: operator,
			params: undefined,
			color: "00ff00",
		});

		field.formattingRules = newRules;

		onChange();
	};

	public override render() {
		const {field, onChange} = this.props;
		const rules = field.formattingRules || [];

		const isBoolean = field.dataType === FieldDataType.Boolean;
		const hasAddButton = !isBoolean || rules.length < 2;

		return (
			<div className="FormattingSettings">
				{rules.map((rule, index) => {
					const color = rule.color || "00ff00";
					const ParamControl = (FilterOperators.map[rule.operator] as IFilterOperatorConfig)?.control;

					return (
						<div
							key={index}
							className="FormattingRule"
						>
							<Field label="When text">
								<SelectInput
									options={this.getOperators(field)}
									selected={rule.operator}
									onChange={(operator) => {
										rule.operator = operator;
										onChange();
									}}
									render={(operation) => {
										let label = "";

										if (isBoolean) {
											const dataTypeSettings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

											switch (operation) {
												case FilterOperator.IS_TRUE:
													label = dataTypeSettings.displayLabelForTrue;
													break;
												case FilterOperator.IS_FALSE:
													label = dataTypeSettings.displayLabelForFalse;
													break;
											}
										}

										return label || FilterOperators.map[operation].label;
									}}
								/>
								{ParamControl && (
									<ParamControl
										param={rule.params}
										onChange={(value) => {
											rule.params = value;
											onChange();
										}}
									/>
								)}
							</Field>
							<Field label="Set color to">
								<ColorSelector
									title="color"
									color={{hex: color, transparency: 0}}
									onColorChange={(color) => {
										rule.color = color.hex;
										onChange();
									}}
									isTransparencyEnabled={false}
									verticalAlignment={VerticalAlignment.top}
								/>
							</Field>
							<IconButton
								title="Delete"
								icon="delete"
								className="delete"
								onClick={(e) => this.onRemoveRule(rule)}
							/>
						</div>
					);
				})}
				{hasAddButton && (
					<Button
						label="+"
						className="dashed"
						onClick={this.onAddRuleClick}
					/>
				)}
			</div>
		);
	}
}

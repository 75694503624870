import * as React from "react";
import {FieldDataTypes} from "../../../../../../data/models/field/FieldDataTypes";
import {FieldDataType} from "../../../../../../generated/api/base";
import type {Type} from "../../../../../../data/models/Type";

interface ITypeLabelProps {
	readonly value: Type;
}

export class TypeLabel extends React.PureComponent<ITypeLabelProps> {
	public override render() {
		const {value} = this.props;

		// Converts type id -> type name
		const formatted = FieldDataTypes.map[FieldDataType.Type].formatter(value);

		return formatted || "";
	}
}

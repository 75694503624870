import * as React from "react";

interface ISpaceThumbnailProps {
	readonly url: string;
	readonly spaceId: string;
	readonly onClick?: (spaceId: string) => void;
}

export class SpaceThumbnail extends React.Component<ISpaceThumbnailProps> {
	private onClick = () => {
		this.props.onClick?.(this.props.spaceId);
	};

	public override render() {
		return (
			<div
				className="SpaceThumbnail"
				style={{backgroundImage: `url(${this.props.url})`, cursor: "pointer"}}
				onClick={this.onClick}
			></div>
		);
	}
}

import styled from "styled-components";
import {radius} from "../../../styles/styles";
import ListIcon from "../../../icons/list.svg?react";
import FrameIcon from "../../../icons/frame.svg?react";
import {colorPalette} from "../../../styles/colorPalette";
import type {XyiconFeature} from "../../../../../generated/api/base";
import {useAppStore} from "../../../../../StateManager";
import {getBackgroundColorForCard, getFeatureTypesForCard, getTypeFromFeatureForCard} from "./CardUtils";

interface IViewTemplateCardProps {
	readonly feature: XyiconFeature;
	readonly isActive: boolean;
	readonly onClick: () => void;
	readonly isInFocus?: boolean;
}

export const ViewTemplateCard = (props: IViewTemplateCardProps) => {
	const {feature, onClick, isActive, isInFocus = false} = props;
	const appState = useAppStore((state) => state.appState);
	const type = getTypeFromFeatureForCard(feature);
	const featureTypes = getFeatureTypesForCard(appState, feature);

	const backgroundColor = getBackgroundColorForCard(type);
	const Icon = type === "space" ? FrameIcon : ListIcon;
	const bgImage = type === "space" ? "spaceeditor-background.webp" : "table-background.webp";

	return (
		<ViewTemplateCardStyled
			onClick={onClick}
			$isActive={isActive}
			$isInFocus={isInFocus}
		>
			<BackgroundContainerStyled
				$backgroundImage={bgImage}
				$isInFocus={isInFocus}
			>
				<OverlayStyled $backgroundColor={`${backgroundColor}B2`} />
				<Icon />
			</BackgroundContainerStyled>
			{!isInFocus && <TitleStyled>{type}</TitleStyled>}
			<FeatureTypesStyled>{featureTypes.map((type) => type.name).join(", ")}</FeatureTypesStyled>
		</ViewTemplateCardStyled>
	);
};

const FeatureTypesStyled = styled.div`
	font-size: 14px;
	line-height: 16px;
	color: ${colorPalette.gray.c700Dark};
	overflow: hidden;
	white-space: initial;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow-wrap: break-word;
`;

const TitleStyled = styled.div`
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
`;

const OverlayStyled = styled.div<{$backgroundColor: string}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: ${(props) => props.$backgroundColor};
	border-radius: ${radius.xl};
`;

const BackgroundContainerStyled = styled.div<{$backgroundImage: string; $isInFocus: boolean}>`
	position: relative;
	height: ${(props) => (props.$isInFocus ? "200px" : "144px")};
	display: flex;
	justify-content: center;
	align-items: center;
	background-image: url(./src/assets/images/v5/views/${(props) => props.$backgroundImage});
	background-size: cover;
	border-radius: ${radius.xl};

	svg {
		width: 64px;
		height: 64px;
		padding: 8px;
		background-color: ${colorPalette.white};
		border-radius: ${radius.md};
		z-index: 1;
		path {
			stroke-width: 1;
		}
	}
`;

const ViewTemplateCardStyled = styled.div<{$isActive: boolean; $isInFocus: boolean}>`
	cursor: ${(props) => (props.$isInFocus ? "" : "pointer")};
	padding: ${(props) => (props.$isInFocus ? "" : "16px")};
	border-radius: ${radius.xl};
	box-shadow: ${(props) => (props.$isInFocus ? "" : "0px 4px 20px 0px #00000033")};
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: ${(props) => (props.$isInFocus ? "" : "280px")};
	width: ${(props) => (props.$isInFocus ? "" : "280px")};
	outline: ${(props) => (props.$isActive ? `4px solid ${colorPalette.primary.c500Primary}` : "none")};
	background-color: ${(props) => (props.$isActive ? colorPalette.primary.c200Light : "")};
	${TitleStyled} {
		color: ${(props) => (props.$isActive ? colorPalette.primary.c500Primary : "")};
		text-transform: capitalize;
	}
`;

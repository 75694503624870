import * as React from "react";
import {inject, observer} from "mobx-react";
import {SVGIcon} from "../button/SVGIcon";
import type {Pointer} from "../../../utils/interaction/Pointer";
import {MathUtils} from "../../../utils/math/MathUtils";
import {PointerDetectorReact} from "../../interaction/PointerDetectorReact";
import {ReactUtils} from "../../utils/ReactUtils";
import type {TransportLayer} from "../../../data/TransportLayer";
import {KeyboardListener} from "../../../utils/interaction/key/KeyboardListener";

interface ISplitterProps {
	readonly children: React.ReactNode[];
	readonly className?: string;
	readonly transport?: TransportLayer;
	readonly onChange?: (ratios: number[]) => void;
}

interface ISplitterSizes {
	parentElementSize: number /** Without the draggers */;
	childElementRatios: number[] /** Sum should be always 1 */;
}

@inject("transport")
@observer
export class Splitter extends React.Component<ISplitterProps> {
	private _sizes: ISplitterSizes = {
		parentElementSize: null,
		childElementRatios: [],
	};
	private _splitterElement = React.createRef<HTMLDivElement>();
	private _children: React.ReactElement[];
	private _savedBodyCursor: string;
	private _dragger = React.createRef<HTMLDivElement>(); // one of the draggers. Needed to get the size of the dragger element
	private _draggerWidth: number;
	private _currentlyDraggedDragger: HTMLElement;
	private _dragIndex: number; // index of dragger that is currently dragged
	private _leftContainerOriginalWidth: number; // the width of the left container before we start dragging the dragger
	private _rightContainerOriginalWidth: number; // the width of the right container before we start dragging the dragger
	private _resizingIsActive: boolean = false;
	private _rightPanelSnappingWidths: number[] = [450, 840, 1225];
	private _snappingTolerance: number = 25;
	private _v5Grid: string = "1fr 5px 600px";

	private onResize = () => {
		console.log("resize");

		requestAnimationFrame(() => {
			this.onToggleSidePanel(this._sizes.childElementRatios.length - 2);
			this.forceUpdate();
		});
	};

	private calculateParentSize() {
		let parentSize = this._splitterElement.current.offsetWidth;

		if (this._children.length > 1 && this._dragger.current) {
			this._draggerWidth = this._draggerWidth || this.getWidth(this._dragger.current);
			parentSize = this.getWidth(this._splitterElement.current) - this._draggerWidth * Math.max(0, this._children.length - 1); // minus the draggers
		}

		this._sizes.parentElementSize = parentSize;
	}

	private calculateRatios() {
		if (this._splitterElement.current) {
			this.calculateParentSize();

			const childElementRatios = [];
			const children = this._splitterElement.current.children;

			if (children.length === 1) {
				childElementRatios.length = 0;
				childElementRatios.push(1);
			} else {
				for (let i = 0; i < children.length; i += 2) {
					const child = children[i] as HTMLElement;

					childElementRatios.push(this.getWidth(child) / this._sizes.parentElementSize);
				}
			}

			this._sizes.childElementRatios = childElementRatios;
		}
	}

	private getValidChildren = (props: ISplitterProps) => props.children.filter((element: React.ReactNode) => !!element);

	/**
	 *
	 * @param ratios The sum of ratios should be exactly 1
	 */
	public setRatios(ratios: number[], props: ISplitterProps = this.props, update: boolean = true) {
		const validChildren = this.getValidChildren(props);

		if (ratios.length !== validChildren.length) {
			if (validChildren.length === 3) {
				this.setDefaultRatiosFor3WaySplitter();
				return;
			}
		}

		if (ratios.length > 1) {
			this._sizes.childElementRatios = ratios;
		}

		if (update) {
			this.forceUpdate();
		}
	}

	private setDefaultRatiosFor3WaySplitter() {
		const last = this._sizes.childElementRatios[this._sizes.childElementRatios.length - 1] || 0.3;
		const first = 267 / 1912;
		const second = 1 - first - last;

		this._sizes.childElementRatios = [first, second, last];
	}

	private onRatiosChange() {
		this.props.onChange?.([...this._sizes.childElementRatios]);
	}

	private getMinWidth(width: number) {
		let minWidth = 0;

		if (width < 500) {
			minWidth = 500;
		}
		if (width < 400) {
			minWidth = 400;
		}
		if (width < 300) {
			minWidth = 300;
		}
		if (width < 200) {
			minWidth = 200;
		}

		return minWidth;
	}

	private onToggleSidePanel = (divKey: number) => {
		if (!this._resizingIsActive) {
			if (this._sizes.childElementRatios.length < 3) {
				this.calculateRatios();
			}

			const childRatios = (this._sizes.childElementRatios.length === 0 ? [0.3, 0.3, 0.3] : this._sizes.childElementRatios) ?? [0.3, 0.3, 0.3];
			const locallySavedRatios = this.props.transport.services.localStorage.getSplitterRatios() || [];
			const first = childRatios[0];
			const second = childRatios[1];
			const third = childRatios[2];
			const firstLs = locallySavedRatios[0] || 0.25;
			const secondLs = locallySavedRatios[1] || 0.25;
			const thirdLs = locallySavedRatios[2] || 0.25;

			let newRatio: number[] = [];

			if (!divKey) {
				// left dragger
				if (first === 0) {
					newRatio = [firstLs, secondLs, thirdLs];
				} else {
					newRatio = [0, first + second, third];
				}
			} else if (divKey === 2) {
				// right dragger
				if (childRatios.length === 2) {
					if (!second) {
						newRatio = [firstLs, secondLs];

						if (childRatios.length === 2 && locallySavedRatios.length === 3) {
							newRatio = [firstLs + secondLs, thirdLs];
						}
					} else {
						newRatio = [first + second, 0];
					}
				} else if (childRatios.length === 3) {
					if (!third) {
						newRatio = [firstLs, secondLs, thirdLs];
					} else {
						newRatio = [first, third + second, 0];
					}
				}
			}

			this.setRatios(newRatio, this.props, true);
		}
	};

	private createChildElements() {
		const children = this.props.children as React.ReactElement[];
		const validChildren = children.filter((element: React.ReactElement) => !!element);
		const childRatios = this._sizes.childElementRatios;
		const divs = [];

		for (let i = 0; i < children.length; ++i) {
			const container = children[i];

			if (container) {
				const validIndex = validChildren.indexOf(container);

				let style = {
					width: "",
				};

				if (validChildren.length === this._sizes.childElementRatios.length) {
					let childRatio = this._sizes.childElementRatios[validIndex];

					if (childRatio != null) {
						style.width = `${childRatio * this._sizes.parentElementSize}px`;
					}
				}

				const div =
					typeof container.type === "string" ? (
						React.cloneElement(container, {
							className: `${container.props.className} ${parseInt(style.width) < 450 ? "thin" : "wide"} width${this.getMinWidth(parseInt(style.width))}`,
							key: i * 2,
							style: style,
						})
					) : (
						<div
							className={`${parseInt(style.width) < 450 ? "thin" : "wide"}`}
							key={i * 2}
							style={style}
						>
							{container}
						</div>
					);

				divs.push(div);
			}
		}

		this._children = [];

		for (let i = 0; i < divs.length - 1; ++i) {
			const div = divs[i];
			const divKey = parseInt(div.key as string);
			const draggerKey = divKey + 1;
			const shouldRotateIcon = divKey === 0 ? !childRatios[0] : !!(childRatios.length === 2 ? childRatios[1] : childRatios[2]);

			this._children.push(div);

			this._children.push(
				<PointerDetectorReact
					onDown={this.onPointerDown}
					onMove={this.onPointerMove}
					onUp={this.onPointerUp}
					key={draggerKey}
				>
					<div
						className="dragger"
						ref={this._dragger}
					>
						<div
							className={ReactUtils.cls("handler", {firstdragger: divKey === 0})}
							onClick={(e) => this.onToggleSidePanel(divKey)}
						>
							<SVGIcon
								icon="angle_left"
								classNames={ReactUtils.cls({rotate: shouldRotateIcon})}
							/>
						</div>
					</div>
				</PointerDetectorReact>,
			);
		}

		this._children.push(divs[divs.length - 1]);

		if (validChildren.length !== this._sizes.childElementRatios.length) {
			if (validChildren.length === 3 && this._sizes.childElementRatios.length > 0) {
				this.setDefaultRatiosFor3WaySplitter();
				this.createChildElements();
			}
		}
	}

	private getIndexOfDragger(dragger: HTMLElement) {
		const gridElements = this._splitterElement.current.children;

		for (let i = 1; i < gridElements.length; i += 2) {
			if (gridElements[i] === dragger) {
				return i;
			}
		}

		return -1;
	}

	private onPointerDown = (pointer: Pointer) => {
		if (!(pointer.originalEvent.target as HTMLElement).className.includes("handler")) {
			this._savedBodyCursor = document.body.style.cursor;
			document.body.style.cursor = "ew-resize";

			this._currentlyDraggedDragger = pointer.currentTarget as HTMLElement;
			this._dragIndex = this.getIndexOfDragger(this._currentlyDraggedDragger);

			const containerIndex = Math.floor(this._dragIndex / 2);

			this._leftContainerOriginalWidth = this._sizes.childElementRatios[containerIndex] * this._sizes.parentElementSize;
			this._rightContainerOriginalWidth = this._sizes.childElementRatios[containerIndex + 1] * this._sizes.parentElementSize;
		}
	};

	private onPointerMove = (pointer: Pointer) => {
		this._resizingIsActive = true;

		if (this._currentlyDraggedDragger) {
			const containerIndex = Math.floor(this._dragIndex / 2);
			const offsetX = MathUtils.clamp(pointer.offsetX, -this._leftContainerOriginalWidth, this._rightContainerOriginalWidth);
			const snappingPoint = this.getASnappingPoint(pointer.pageX);
			let newLeftContainerWidth = this._leftContainerOriginalWidth + offsetX;
			let newRightContainerWidth = this._rightContainerOriginalWidth - offsetX;

			if (snappingPoint && this._children.length === 3 && !KeyboardListener.isAltDown) {
				newLeftContainerWidth = newLeftContainerWidth + newRightContainerWidth - snappingPoint;
				newRightContainerWidth = snappingPoint;
			}

			this._sizes.childElementRatios[containerIndex] = newLeftContainerWidth / this._sizes.parentElementSize;
			this._sizes.childElementRatios[containerIndex + 1] = newRightContainerWidth / this._sizes.parentElementSize;

			this.forceUpdate();
		}
	};

	private onPointerUp = (pointer: Pointer) => {
		if (this._resizingIsActive) {
			this.onRatiosChange();
		}

		document.body.style.cursor = this._savedBodyCursor;
		this._dragIndex = null;
		this._currentlyDraggedDragger = null;
		this._resizingIsActive = false;
	};

	private getWidth(element: HTMLElement) {
		return element.getBoundingClientRect().width;
	}

	private getASnappingPoint(x: number): number {
		for (let i = 0; i < this._rightPanelSnappingWidths.length; i++) {
			const sPoint = this._rightPanelSnappingWidths[i];
			const xDistanceFromRight = window.innerWidth - x;

			if (xDistanceFromRight >= sPoint - this._snappingTolerance && xDistanceFromRight <= sPoint + this._snappingTolerance) {
				return this._rightPanelSnappingWidths[i];
			}
		}

		return null;
	}

	public get ratios() {
		return [...this._sizes.childElementRatios];
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: ISplitterProps) {
		const numberOfCurrentValidChildren = this.getValidChildren(this.props).length;
		const numberOfNextValidChildren = this.getValidChildren(nextProps).length;

		if (numberOfCurrentValidChildren !== numberOfNextValidChildren) {
			this.setRatios([], nextProps, false);
			this.onRatiosChange();
		}
	}

	public override componentDidUpdate() {
		this.calculateRatios();
	}

	public override componentDidMount() {
		window.addEventListener("resize", this.onResize);
		this.calculateRatios();
	}

	public override componentWillUnmount() {
		window.removeEventListener("resize", this.onResize);
	}

	public setV5GridSizes() {
		const splitterWidth = document.documentElement.clientWidth - (this._sizes.childElementRatios.length - 1) * 5 - 64;

		this._v5Grid = this._sizes.parentElementSize
			? this._sizes.childElementRatios.map((cer) => `${cer * splitterWidth}px`).join(" 5px ")
			: "1fr 5px 600px";
	}

	public override render() {
		this.createChildElements();

		const {className} = this.props;

		return (
			<div
				ref={this._splitterElement}
				className={ReactUtils.cls("Splitter", {[className]: !!className})}
			>
				{this._children}
			</div>
		);
	}
}

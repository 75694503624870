import type {Lambda} from "mobx";
import * as React from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {observe, reaction} from "mobx";
import type {SpaceViewRenderer} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import type {SelectionToolType, SpaceTool} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import type {IIconConfig} from "../../../modules/catalog/create/CatalogTypes";
import type {Catalog} from "../../../../data/models/Catalog";
import type {ISpaceItemModel} from "../../../../data/models/Model";
import type {RescaleSpaceRequest, SpaceFileDto, SpaceFileInsertionInfo, UpdateSpaceFileRequest} from "../../../../generated/api/base";
import type {DockableTitle} from "../../../modules/space/spaceeditor/ui/toolbar/Dockable";
import type {IFilterState} from "../../../../data/models/filter/Filter";
import {arrayOfSelectionToolTypes} from "../../../modules/space/spaceeditor/logic3d/features/tools/Tools";
import {XyiconFeature} from "../../../../generated/api/base";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {XHRLoader} from "../../../../utils/loader/XHRLoader";
import {Functions} from "../../../../utils/function/Functions";
import {SpaceEditorMode} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRendererUtils";
import {VerticalFlexStyle} from "../../styles/styles";
import {DetailsTabV5} from "../../details/DetailsTabV5";
import {Panel} from "../../abstract/Panel";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {SideGrid} from "../../abstract/SideGrid";
import {SplitterV5Tamas} from "../../layout/SplitterV5Tamas";
import type {BoundarySpaceMap} from "../../../../data/models/BoundarySpaceMap";
import {CatalogCreateV5} from "../catalog/CatalogCreateV5";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import type {TSpaceEditorPanelTypes} from "./SpaceEditorPanelV5";
import {SpaceEditorPanelV5} from "./SpaceEditorPanelV5";
import {DockableV5} from "./dockable/DockableV5";
import {SpaceEditorV5} from "./SpaceEditorV5";
import type {GridItem, GridSide} from "./spaceselector/GridTypes";

interface IBasicPropsFor3DTweaking {
	readonly spaceViewRenderer: SpaceViewRenderer;
}

interface ISpaceViewState {
	focusedItems: ISpaceItemModel[];
	isCatalogOpen: boolean;
	isCatalogDocked: boolean;
	isBoundaryTypesWindowOpen: boolean;
	isBoundaryTypesWindowDocked: boolean;
	isUnplottedXyiconsOpen: boolean;
	isUnplottedXyiconsDocked: boolean;
	activeToolId: SpaceTool;
	selectionToolType: SelectionToolType;
	isCreatePanelOpen: boolean;
	editedCatalog: {
		config: IIconConfig;
		catalog: Catalog;
	};
	openedSpaceEditorPanelType: TSpaceEditorPanelTypes | null;
}
@observer
export class SpaceEditorViewV5 extends React.Component<IBasicPropsFor3DTweaking, ISpaceViewState> {
	private readonly _feature = XyiconFeature.SpaceEditor;
	private _requestAnimationFrameId: number = null;
	private _disposerForViewChangeListener: Lambda;
	private _disposerForFilterListener: Lambda;
	private _lastViewId: string = "";

	constructor(props: IBasicPropsFor3DTweaking) {
		super(props);

		this.state = {
			focusedItems: [],
			isCatalogOpen: false,
			isCatalogDocked: false,
			isBoundaryTypesWindowOpen: false,
			isBoundaryTypesWindowDocked: false,
			isUnplottedXyiconsOpen: false,
			isUnplottedXyiconsDocked: false,
			activeToolId: "selection",
			selectionToolType: "selection",
			isCreatePanelOpen: false,
			editedCatalog: null,
			openedSpaceEditorPanelType: null,
		};

		this.resetFilters();
	}

	private resetFilters() {
		const selectedView = this.selectedView;

		if (selectedView.id !== this._lastViewId) {
			// Selected view changed, or opening view the first time
			// -> copy filters from view
			this._lastViewId = selectedView.id;

			const savedFilters = selectedView.getSavedFilters();

			this.updateSpaceEditorFilterState(savedFilters);
			this.listenFilters();

			selectedView.setFilters(ObjectUtils.deepClone(savedFilters));
		}
	}

	private setDockableDocked = (value: boolean, title: DockableTitle) => {
		if (title === "Catalog") {
			this.setState({
				isCatalogDocked: value,
			});

			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Boundary") {
			this.setState({
				isBoundaryTypesWindowDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			} else if (value === this.state.isUnplottedXyiconsDocked) {
				this.setState({
					isUnplottedXyiconsOpen: false,
				});
			}
		} else if (title === "Unplotted Xyicons") {
			this.setState({
				isUnplottedXyiconsDocked: value,
			});

			if (value === this.state.isCatalogDocked) {
				this.setState({
					isCatalogOpen: false,
				});
			}
			if (value === this.state.isBoundaryTypesWindowDocked) {
				this.setState({
					isBoundaryTypesWindowOpen: false,
				});
			}
		}
	};

	private isDocked(title: DockableTitle) {
		if (title === "Catalog") {
			return this.state.isCatalogDocked;
		} else if (title === "Boundary") {
			return this.state.isBoundaryTypesWindowDocked;
		} else if (title === "Unplotted Xyicons") {
			return this.state.isUnplottedXyiconsDocked;
		}
	}

	private setDockableOpen = (value: boolean, title: DockableTitle, onlyIfNotDocked: boolean = false) => {
		const isDocked = this.isDocked(title);

		if ((onlyIfNotDocked && !isDocked) || !onlyIfNotDocked) {
			if (title === "Catalog") {
				this.setState({
					isCatalogOpen: value,
				});

				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isCatalogDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isCatalogDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Boundary") {
				this.setState({
					isBoundaryTypesWindowOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked === this.state.isBoundaryTypesWindowDocked) {
					this.setState({
						isUnplottedXyiconsOpen: false,
					});
				}
			} else if (title === "Unplotted Xyicons") {
				this.setState({
					isUnplottedXyiconsOpen: value,
				});

				if (value && this.state.isCatalogOpen && this.state.isCatalogDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isCatalogOpen: false,
					});
				}
				if (value && this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked === this.state.isUnplottedXyiconsDocked) {
					this.setState({
						isBoundaryTypesWindowOpen: false,
					});
				}
			}
		}
	};

	private setScale = async (spaceUnitsPerMeter: number) => {
		const {transport} = this.props.spaceViewRenderer;
		const {appState} = transport;
		const {space} = appState;
		const params: RescaleSpaceRequest = {
			spaceID: space.id,
			unitsPerMeter: spaceUnitsPerMeter,
			portfolioID: appState.portfolioId,
		};

		await transport.requestForOrganization<SpaceFileDto>({
			url: "spaces/rescale",
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		space.setSpaceUnitsPerMeter(spaceUnitsPerMeter);

		await this.props.spaceViewRenderer.refreshSpace(true);
	};

	private confirmAlignment = async (insertionInfo: SpaceFileInsertionInfo) => {
		const activePDFFile = this.props.spaceViewRenderer.space.selectedSpaceFile;

		activePDFFile.setInsertionInfo(insertionInfo);

		const params: UpdateSpaceFileRequest = {
			spaceFileList: [
				{
					spaceFileID: activePDFFile.id,
					spaceVersionID: activePDFFile.spaceVersionId,
					spaceID: activePDFFile.parent.id,
					settings: {
						insertionInfo: activePDFFile.insertionInfo,
					},
				},
			],
			portfolioID: activePDFFile.parent.portfolioId,
		};

		await this.props.spaceViewRenderer.transport.updateSpaceFiles(params);
		this.props.spaceViewRenderer.setInsertionInfo(activePDFFile.insertionInfo);

		this.forceUpdate();
	};

	private setActiveTool = (id: SpaceTool) => {
		const {spaceViewRenderer} = this.props;

		if (arrayOfSelectionToolTypes.includes(id as SelectionToolType)) {
			this.setSelectionToolType(id as SelectionToolType);
		}

		if (this.state.activeToolId !== id) {
			this.setState({
				activeToolId: id,
			});
		}

		if (id !== "selection") {
			spaceViewRenderer.spaceItemController.closeLinks();
			spaceViewRenderer.spaceItemController.deselectAll();
		}

		if (id !== "selection" && id !== "pan" && !id.includes("tempMeasure") && spaceViewRenderer.isMeasureToolBarOpen) {
			if (spaceViewRenderer.isMeasureToolBarOpen) {
				spaceViewRenderer.markupManager.removeAllTempMarkups();
				spaceViewRenderer.isMeasureToolBarOpen = false;
			}
		}
	};

	private setSelectionToolType = (type: SelectionToolType) => {
		if (type !== this.state.selectionToolType) {
			this.setState({
				selectionToolType: type,
			});
		}
	};

	private getDockedTitle(): DockableTitle {
		if (this.state.isCatalogOpen && this.state.isCatalogDocked) {
			return "Catalog";
		} else if (this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked) {
			return "Boundary";
		} else if (this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked) {
			return "Unplotted Xyicons";
		} else {
			return null;
		}
	}

	private onAddCatalogClick = (event: React.MouseEvent) => {
		if (event.target !== this.props.spaceViewRenderer.transport.appState.catalogEditorParentMaybe) {
			this.props.spaceViewRenderer.transport.appState.catalogEditorParentMaybe = event.currentTarget as HTMLDivElement;
		}

		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: null,
		});
	};

	private onDuplicateCatalogClick = async (catalog: Catalog) => {
		const iconConfig = await this.props.spaceViewRenderer.transport.getIconConfigOfCatalog(catalog);

		this.setState({
			isCreatePanelOpen: true,
			editedCatalog: {
				catalog: catalog,
				config: iconConfig,
			},
		});
	};

	private closeCreatePanel = () => {
		this.setState({
			isCreatePanelOpen: false,
			editedCatalog: null,
		});
	};

	private forceUpdateArrow = () => {
		this.forceUpdate();
	};

	private get selectedView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private stringifyFilters = () => {
		return JSON.stringify(this.selectedView.filters);
	};

	private onKeyboardPress = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE) {
			this.setState({
				focusedItems: [],
				openedSpaceEditorPanelType: null,
			});
		}
	};

	private startListening() {
		this._disposerForViewChangeListener = observe(
			this.props.spaceViewRenderer.transport.appState.selectedViewId,
			(this._feature as any).toString(),
			(a) => {
				this.resetFilters();
			},
		);

		this.listenFilters();

		KeyboardListener.getInstance().signals.down.add(this.onKeyboardPress);
	}

	private listenFilters() {
		this._disposerForFilterListener?.();
		this._disposerForFilterListener = reaction(this.stringifyFilters, () => {
			this.updateSpaceEditorFilterState(this.selectedView.filters);
		});
	}

	private stopListening() {
		this._disposerForViewChangeListener?.();
		this._disposerForViewChangeListener = null;

		this._disposerForFilterListener?.();
		this._disposerForFilterListener = null;

		KeyboardListener.getInstance().signals.down.remove(this.onKeyboardPress);
	}

	private saveStateToLocalStorage() {
		const organizationId = this.props.spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const stateToSave = {
				isBoundaryTypesWindowDocked: this.state.isBoundaryTypesWindowDocked,
				isBoundaryTypesWindowOpen: this.state.isBoundaryTypesWindowOpen && this.state.isBoundaryTypesWindowDocked,
				isCatalogDocked: this.state.isCatalogDocked,
				isCatalogOpen: this.state.isCatalogOpen && this.state.isCatalogDocked,
				isUnplottedXyiconsDocked: this.state.isUnplottedXyiconsDocked,
				isUnplottedXyiconsOpen: this.state.isUnplottedXyiconsOpen && this.state.isUnplottedXyiconsDocked,
			};

			this.props.spaceViewRenderer.transport.services.localStorage.set(this.getLocalStorageKey(organizationId), stateToSave);
		}
	}

	private getLocalStorageKey(organizationId: string) {
		return `srv4-org-${organizationId}-spaceeditor-spaceview-dockables-open-state`;
	}

	private loadStateFromLocalStorage() {
		const organizationId = this.props.spaceViewRenderer.transport.appState.organizationId;

		if (organizationId) {
			const savedDockedState = this.props.spaceViewRenderer.transport.services.localStorage.get(this.getLocalStorageKey(organizationId));

			if (savedDockedState) {
				this.setState({
					...savedDockedState,
				});
			}
		}
	}

	private updateSpaceEditorFilterState = (filters: IFilterState = this.selectedView.filters) => {
		cancelAnimationFrame(this._requestAnimationFrameId);
		this._requestAnimationFrameId = window.requestAnimationFrame(() => {
			this.props.spaceViewRenderer.actions.updateSpaceEditorFilterState(filters);
		});
	};

	private onSpaceEditorDidMount = () => {
		this.updateSpaceEditorFilterState();
		this.forceUpdate();
	};

	public override async componentDidMount() {
		this.startListening();

		this.loadStateFromLocalStorage();

		const {actions} = this.props.spaceViewRenderer.transport.appState;

		const features = actions.getLoadingDependencies(XyiconFeature.SpaceEditor);

		for (const feature of features) {
			await this.props.spaceViewRenderer.transport.services.feature.refreshList(feature);
		}
		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.add(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.add(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.add(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.add(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.add(this.forceUpdateArrow);

		// Add potential new types
		actions.getSelectedView(XyiconFeature.SpaceEditor).updateSpaceEditorViewSettings();

		// There's a chance we get to this line when the spaceeditor is already fully loaded,
		// So it can't hurt to call this function again, just to be sure
		this.onSpaceEditorDidMount();
		this.props.spaceViewRenderer.signals.spaceLoadReady.add(this.onSpaceEditorDidMount);
	}

	public override componentDidUpdate(prevProps: Readonly<IBasicPropsFor3DTweaking>, prevState: Readonly<ISpaceViewState>, snapshot?: any): void {
		const {app} = this.props.spaceViewRenderer.transport.appState;
		// Adjust docked state / sidegrid, because they're conflicting with each other, only one of them can be rendered at a time on a side
		if (prevState.isCatalogDocked && this.state.isCatalogDocked && app.graphicalTools.gridItemSide.includes("left")) {
			this.setState({
				isCatalogDocked: false,
				isCatalogOpen: false,
			});
		}

		if (
			app.graphicalTools.gridItemSide.includes("left") &&
			((!prevState.isCatalogDocked && this.state.isCatalogDocked) ||
				(!prevState.isBoundaryTypesWindowDocked && this.state.isBoundaryTypesWindowDocked))
		) {
			app.graphicalTools.gridItemSide = "";
		}

		if (prevState.isBoundaryTypesWindowDocked && this.state.isBoundaryTypesWindowDocked && app.graphicalTools.gridItemSide.includes("left")) {
			this.setState({
				isBoundaryTypesWindowDocked: false,
				isBoundaryTypesWindowOpen: false,
			});
		}

		if (
			(prevState.isCatalogDocked && this.state.isCatalogDocked && !prevState.isCatalogOpen && this.state.isCatalogOpen) ||
			(prevState.isBoundaryTypesWindowDocked &&
				this.state.isBoundaryTypesWindowDocked &&
				!prevState.isBoundaryTypesWindowOpen &&
				this.state.isBoundaryTypesWindowOpen)
		) {
			app.graphicalTools.gridItemSide = "";
		}

		this.saveStateToLocalStorage();
	}

	public override componentWillUnmount() {
		this.stopListening();

		const {xyiconManager, boundaryManager, markupManager} = this.props.spaceViewRenderer;

		xyiconManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsAdd.remove(this.forceUpdateArrow);
		markupManager.signals.itemsAdd.remove(this.forceUpdateArrow);

		xyiconManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		boundaryManager.signals.itemsRemove.remove(this.forceUpdateArrow);
		markupManager.signals.itemsRemove.remove(this.forceUpdateArrow);

		this.props.spaceViewRenderer.signals.spaceLoadReady.remove(this.onSpaceEditorDidMount);
	}

	private gridItemSide = (filterBySide: GridSide) => {
		const {gridItemSide} = this.props.spaceViewRenderer.transport.appState.app.graphicalTools;

		if (!gridItemSide) {
			return gridItemSide;
		}

		const splitted = gridItemSide.split("-");

		const side: GridSide = splitted[0] as GridSide;

		if (side !== filterBySide) {
			return "";
		}

		const item: GridItem = splitted[1] as GridItem;

		return (
			<SideGrid
				itemFeature={item === "xyicon" ? XyiconFeature.Xyicon : XyiconFeature.Boundary}
				focusedItems={this.state.focusedItems}
			/>
		);
	};

	private toggleDetailsPanel = () => {
		if (this.state.focusedItems.length > 0) {
			this.onCloseDetailsPanel();
		} else {
			const selectedItems = this.props.spaceViewRenderer.spaceItemController.selectedItems;
			this.onFocusItems(selectedItems.map((spaceItem) => spaceItem.modelData as ISpaceItemModel));
		}
	};

	private onCloseDetailsPanel = () => {
		this.setState({
			focusedItems: [],
		});
	};

	private onSelectItems = (spaceItems: ISpaceItemModel[]) => {
		const {spaceItemController} = this.props.spaceViewRenderer;
		const spaceItemIds = ArrayUtils.removeDuplicates(spaceItems.map((item) => item.id))
			.toSorted(StringUtils.sortIgnoreCase)
			.join();

		const currentSelectedItems = spaceItemController.selectedItems;
		const currentSelectedSpaceItemIds = ArrayUtils.removeDuplicates(currentSelectedItems.map((item) => item.id))
			.toSorted(StringUtils.sortIgnoreCase)
			.join();

		if (currentSelectedSpaceItemIds !== spaceItemIds) {
			spaceItemController.deselectAll(false, false, false);

			for (const spaceItem of spaceItems) {
				const finalSpaceItem = (spaceItem as BoundarySpaceMap).isBoundarySpaceMap ? (spaceItem as BoundarySpaceMap).parent : spaceItem;
				const itemManager = this.props.spaceViewRenderer.getItemManager(finalSpaceItem.ownFeature);

				if (itemManager) {
					const spaceItem3DMaybe = itemManager.getItemById(finalSpaceItem.id);
					if (spaceItem3DMaybe) {
						spaceItem3DMaybe.select();
					}
				}
			}

			for (const feature of [XyiconFeature.Xyicon, XyiconFeature.Boundary, XyiconFeature.Markup]) {
				const itemManager = this.props.spaceViewRenderer.getItemManager(feature);
				itemManager?.updateSelectionBox();
			}

			spaceItemController.updateBoundingBox();
			spaceItemController.updateActionBar(false, false);
		}
	};

	private onFocusItems = (spaceItems: ISpaceItemModel[]) => {
		const finalSpaceItems = ArrayUtils.removeDuplicates(
			spaceItems.map((item: ISpaceItemModel) =>
				(item as BoundarySpaceMap)?.isBoundarySpaceMap ? (item as BoundarySpaceMap).parent : item,
			) as ISpaceItemModel[],
		);
		this.setState({
			focusedItems: finalSpaceItems,
		});
	};

	private getFocusedItems = () => {
		return this.state.focusedItems;
	};

	private resetOpenedSpaceEditorPanelState = () => {
		this.setState({openedSpaceEditorPanelType: null});
	};

	public override render() {
		const space = this.props.spaceViewRenderer.transport.appState.space;
		const dockedTitle = this.getDockedTitle();
		const isDockedAndOpen = !!dockedTitle;

		if (space) {
			return (
				<SpaceEditorViewStyled>
					<SplitterV5Tamas>
						{isDockedAndOpen && (
							<DockableV5
								spaceViewRenderer={this.props.spaceViewRenderer}
								setDocked={this.setDockableDocked}
								setOpen={this.setDockableOpen}
								isDocked={isDockedAndOpen}
								title={dockedTitle}
								setActiveTool={this.setActiveTool}
								onAddCatalogClick={this.onAddCatalogClick}
								onDuplicateCatalogClick={this.onDuplicateCatalogClick}
								onCreateUnplottedXyicons={Functions.emptyFunction}
							/>
						)}
						{this.gridItemSide("left")}
						<SpaceEditorV5
							spaceViewRenderer={this.props.spaceViewRenderer}
							setDockableDocked={this.setDockableDocked}
							setDockableOpen={this.setDockableOpen}
							isCatalogOpen={this.state.isCatalogOpen}
							isBoundaryTypesWindowOpen={this.state.isBoundaryTypesWindowOpen}
							isUnplottedXyiconsOpen={this.state.isUnplottedXyiconsOpen}
							isCatalogDocked={this.state.isCatalogDocked}
							isBoundaryTypesWindowDocked={this.state.isBoundaryTypesWindowDocked}
							isUnplottedXyiconsDocked={this.state.isUnplottedXyiconsDocked}
							setScale={this.setScale}
							confirmAlignment={this.confirmAlignment}
							thumbnail={space.thumbnailFileURL}
							mode={SpaceEditorMode.NORMAL}
							activeToolId={this.state.activeToolId}
							setActiveTool={this.setActiveTool}
							selectionToolType={this.state.selectionToolType}
							selectItems={this.onSelectItems}
							focusItems={this.onFocusItems}
							getFocusedItems={this.getFocusedItems}
							onAddCatalogClick={this.onAddCatalogClick}
							onDuplicateCatalogClick={this.onDuplicateCatalogClick}
							toggleDetailsPanel={this.toggleDetailsPanel}
							onOpenPanelClick={(type) => this.setState({openedSpaceEditorPanelType: type})}
							activePanel={this.state.openedSpaceEditorPanelType}
							isDetailsPanelOpen={this.state.focusedItems.length > 0}
						/>
						{this.gridItemSide("right")}
					</SplitterV5Tamas>
					<CatalogCreateV5
						editedCatalogMaybe={this.state.editedCatalog}
						isOpen={this.state.isCreatePanelOpen}
						onClose={this.closeCreatePanel}
					/>
					<Panel isOpen={this.state.focusedItems.length > 0}>
						<DetailsTabV5
							items={this.state.focusedItems}
							feature={this.state.focusedItems[0]?.ownFeature}
							features={[XyiconFeature.Xyicon, XyiconFeature.Boundary]}
							isPortTemplateEditorOpen={false}
							setPortTemplateEditorOpen={Functions.emptyFunction}
							onCloseOverlayedDetaislPanel={this.onCloseDetailsPanel}
							onSelect={this.onSelectItems}
							onFocus={this.onFocusItems}
						/>
					</Panel>
					<SpaceEditorPanelV5
						openedSpaceEditorPanelType={this.state.openedSpaceEditorPanelType}
						onClose={this.resetOpenedSpaceEditorPanelState}
					/>
				</SpaceEditorViewStyled>
			);
		}

		// TODO loading?
		return null;
	}
}

const SpaceEditorViewStyled = styled.div`
	${VerticalFlexStyle}
	flex: 1;
	min-height: 0;
	position: relative;
`;

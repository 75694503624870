import * as React from "react";
import {inject} from "mobx-react";
import {IconButton} from "../widgets/button/IconButton";
import type {Navigation} from "../../Navigation";
import {NavigationEnum} from "../../Enums";

interface ITopBarBackButton {
	readonly navigation?: Navigation;
}

@inject("navigation")
export class TopBarBackButton extends React.Component<ITopBarBackButton> {
	private static defaultHash = `app/${NavigationEnum.NAV_PORTFOLIOS}`;
	private _previousHash = TopBarBackButton.defaultHash;

	private onBackClick = () => {
		this.props.navigation.go(this._previousHash);
	};

	public override componentDidMount() {
		// When the component is shown the first time (currently when we go to settings)
		// -> save where we came from
		this._previousHash = this.props.navigation.previousHash || TopBarBackButton.defaultHash;
		if (this._previousHash.indexOf("#app/settings") > -1) {
			this._previousHash = TopBarBackButton.defaultHash;
		}
	}

	public override render() {
		return (
			<IconButton
				icon="arrow"
				onClick={this.onBackClick}
			/>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import {AssignType} from "../../field/FieldForm";
import type {ISwitchListChange} from "../../../../../widgets/button/switch/SwitchList";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import type {AppState} from "../../../../../../data/state/AppState";
import type {FeatureMap} from "../../../../../../data/state/AppStateConstants";
import {ArrayUtils} from "../../../../../../utils/data/array/ArrayUtils";
import type {Type} from "../../../../../../data/models/Type";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import {XyiconFeature, FieldDataType} from "../../../../../../generated/api/base";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import type {ISelectSliderOption} from "../../../../../widgets/input/selectslider/SelectSlider";
import {SelectSlider} from "../../../../../widgets/input/selectslider/SelectSlider";
import {TypeFormHeader} from "./TypeFormHeader";
import {CardLayoutEditor} from "./CardLayoutEditor";

interface ITypeFormProps {
	readonly type: Type;
	readonly fieldsFeature?: XyiconFeature;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly avatar?: boolean;
	readonly createPanelNameInput?: (name: string) => void;
}

const headerComponents: FeatureMap<React.ComponentClass<{type: Type}>> = {
	[XyiconFeature.Boundary]: TypeFormHeader,
	[XyiconFeature.Space]: TypeFormHeader,
};

@inject("appState")
@inject("transport")
@observer
export class TypeForm extends React.Component<ITypeFormProps> {
	private _cardLayoutEditorRef = React.createRef<CardLayoutEditor>();
	public _assignedTypes: ISelectSliderOption[] = [
		{
			id: AssignType.Unassigned,
			label: AssignType[AssignType.Unassigned],
		},
		{
			id: AssignType.Assigned,
			label: AssignType[AssignType.Assigned],
		},
		this.props.fieldsFeature === XyiconFeature.Xyicon && {
			id: AssignType.Lookup,
			label: "Lookup Link",
			tooltip: "Lookup Link is only applicable for Text (Single Line) fields where Display On Links is enabled.",
		},
	].filter((type) => type);

	private onMappingChange = (changes: ISwitchListChange[]) => {
		const {type, fieldsFeature, appState} = this.props;

		const mapping = appState.typeFieldMapping[fieldsFeature];

		if (!mapping[type.id]) {
			mapping[type.id] = new Set();
		}
		const mappingForType = mapping[type.id];

		for (const change of changes) {
			const fieldId = change.id;

			if (change.value) {
				mappingForType.add(fieldId);
			} else {
				mappingForType.delete(fieldId);
			}
		}

		this.updateMapping();
		this._cardLayoutEditorRef.current?.forceUpdate();
	};

	private onSliderMappingChange = (labels: string[], value: AssignType) => {
		const {type, fieldsFeature, appState} = this.props;
		let list: {id: string; label: string; value: boolean}[] = [];

		type.settings.xyiconLookupLinkFieldList = type.settings.xyiconLookupLinkFieldList || [];

		if (appState.fields[fieldsFeature]) {
			list = appState.actions.getFieldsForType(type.id, fieldsFeature);
		}

		labels.forEach((label) => {
			const fieldItem = list.find((field) => field.label === label);
			const fieldObj = appState.actions.getFieldById(fieldItem.id);

			if (value === AssignType.Unassigned) {
				fieldItem.value = false;
			} else if (value === AssignType.Assigned) {
				fieldItem.value = true;
			} else if (value === AssignType.Lookup && fieldObj.displayOnLinks && fieldObj.dataType === FieldDataType.SingleLineText) {
				fieldItem.value = false;
			}

			if (value === AssignType.Lookup && fieldObj.displayOnLinks && fieldObj.dataType === FieldDataType.SingleLineText) {
				ArrayUtils.addMutable(type.settings.xyiconLookupLinkFieldList, fieldObj.id);
			} else {
				ArrayUtils.removeMutable(type.settings.xyiconLookupLinkFieldList, fieldObj.id);
			}

			this.props.transport.services.typefield.updateType(type);
		});
		this.onMappingChange(list);
	};

	private updateMapping() {
		const selected = this.props.type;

		if (selected) {
			const services = this.props.transport.services;

			services.typefield.applyMapping(this.props.fieldsFeature, selected.id);
		}
	}

	private getHeaderComponent() {
		const {type} = this.props;

		return headerComponents[type.feature] || TypeFormHeader;
	}

	public override render() {
		const {type, fieldsFeature, appState, avatar} = this.props;
		const editMode = !!type.id;
		const TypeFormHeader = this.getHeaderComponent();
		let list: {id: string; label: string; value: boolean}[] = [];

		if (appState.fields[fieldsFeature]) {
			list = appState.actions.getFieldsForType(type.id, fieldsFeature, true);
		}

		return (
			<>
				<div className={ReactUtils.cls("header", {noInitials: !avatar})}>
					<TypeFormHeader
						type={type}
						avatar={avatar}
						createPanelNameInput={this.props.createPanelNameInput}
					/>
				</div>
				{editMode && (
					<>
						<ToggleContainer
							title="Assigned Fields"
							open={true}
						>
							<SelectSlider
								options={this._assignedTypes}
								rows={list.map((f) => {
									let value = f.value ? AssignType.Assigned : AssignType.Unassigned;
									const fieldObj = appState.actions.getFieldById(f.id);
									let disabledOptionsList: ISelectSliderOption[] = [];

									if (fieldsFeature === XyiconFeature.Xyicon) {
										if (!fieldObj?.displayOnLinks || fieldObj.dataType !== FieldDataType.SingleLineText) {
											disabledOptionsList.push({id: AssignType.Lookup, label: this._assignedTypes.find((at) => at.id === AssignType.Lookup).label});
										}

										if (type.settings.xyiconLookupLinkFieldList?.includes(f.id)) {
											value = AssignType.Lookup;
										}
									}

									return {
										label: f.label,
										value,
										disabledOptionsList,
									};
								})}
								onChange={this.onSliderMappingChange}
							/>
						</ToggleContainer>
						<ToggleContainer
							title="Customize Card Layout"
							open={true}
						>
							<CardLayoutEditor
								type={type}
								feature={fieldsFeature}
								ref={this._cardLayoutEditorRef}
							/>
						</ToggleContainer>
					</>
				)}
			</>
		);
	}
}

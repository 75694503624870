import * as React from "react";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import type {Color} from "../../../../../../generated/api/base";
import {ColorUtils} from "../../../../../../utils/ColorUtils";
import {BasicColorPanel} from "./basicpanel/BasicColorPanel";
import type {IHSLColor} from "./advancedpanel/AdvancedColorPanel";
import {AdvancedColorPanel} from "./advancedpanel/AdvancedColorPanel";
import type {IPropsForEyeDropper} from "./EyeDropper";

interface ColorWindowProps {
	readonly divRef: React.RefObject<HTMLDivElement>;
	readonly colorHSL: IHSLColor;
	readonly onColorChange: (newColor: IHSLColor) => void;
	readonly onParentUpdate: () => void;
	readonly isTransparencyEnabled: boolean;
	readonly eyeDropperProps?: IPropsForEyeDropper;
}

interface ColorWindowState {
	activeMode: "basic" | "advanced";
}

export class ColorWindow extends React.Component<ColorWindowProps, ColorWindowState> {
	private static _lastUsedColors: Color[] = [
		{hex: "4CAF50", transparency: 0},
		{hex: "8BC34A", transparency: 0},
		{hex: "CDDC39", transparency: 0},
		{hex: "FFEB3B", transparency: 0},
		{hex: "FFC107", transparency: 0},
	];

	constructor(props: ColorWindowProps) {
		super(props);
		this.state = {
			activeMode: "basic",
		};
	}

	private switchToBasicMode = () => {
		this.setState({
			activeMode: "basic",
		});

		this.props.onParentUpdate();
	};

	private switchToAdvancedMode = () => {
		this.setState({
			activeMode: "advanced",
		});

		this.props.onParentUpdate();
	};

	private onHexColorChange = (newColor: Color) => {
		const hsl = ColorUtils.hex2hsl(newColor.hex);

		this.props.onColorChange({
			hue: hsl.h,
			saturation: hsl.s,
			lightness: hsl.l,
			transparency: newColor.transparency,
		});
	};

	private indexOfColorWithinLastUsedColors(hex: string, transparency: number) {
		for (let i = 0; i < ColorWindow._lastUsedColors.length; ++i) {
			const color = ColorWindow._lastUsedColors[i];

			if (color.hex === hex && color.transparency === transparency) {
				return i;
			}
		}

		return -1;
	}

	public override componentWillUnmount() {
		// We save the last used color to the "Last used" palette for every ColorWindow (static)
		const colorHSL = this.props.colorHSL;
		const colorAsHex = ColorUtils.hsl2hex(colorHSL.hue, colorHSL.saturation, colorHSL.lightness) as string;
		const colorIndex = this.indexOfColorWithinLastUsedColors(colorAsHex, colorHSL.transparency); // -1 if it's not included

		if (colorIndex > -1) {
			const existingColor = ColorWindow._lastUsedColors[colorIndex];

			// Remove from array
			ColorWindow._lastUsedColors.splice(colorIndex, 1);

			// Then put it to the first place
			ColorWindow._lastUsedColors.unshift(existingColor);
		} else {
			ColorWindow._lastUsedColors.pop();
			ColorWindow._lastUsedColors.unshift({
				hex: colorAsHex,
				transparency: colorHSL.transparency,
			});
		}
	}

	public override render() {
		const colorHSL = this.props.colorHSL;
		const hex = ColorUtils.hsl2hex(colorHSL.hue, colorHSL.saturation, colorHSL.lightness) as string;
		const colorHex = {
			hex: hex,
			transparency: colorHSL.transparency,
		};

		return (
			<div
				className={ReactUtils.cls("ColorWindow", {overflow: window.innerHeight < 600})}
				ref={this.props.divRef}
			>
				<div className="header">
					<div
						className={ReactUtils.cls({active: this.state.activeMode === "basic"})}
						onClick={this.switchToBasicMode}
					>
						Basic
					</div>
					<div
						className={ReactUtils.cls({active: this.state.activeMode === "advanced"})}
						onClick={this.switchToAdvancedMode}
					>
						Advanced
					</div>
				</div>
				{this.state.activeMode === "basic" && (
					<BasicColorPanel
						onColorChange={this.onHexColorChange}
						color={colorHex}
						isTransparencyEnabled={this.props.isTransparencyEnabled}
						lastUsedColors={ColorWindow._lastUsedColors}
						eyeDropperProps={this.props.eyeDropperProps}
					/>
				)}
				{this.state.activeMode === "advanced" && (
					<AdvancedColorPanel
						onColorChange={this.props.onColorChange}
						color={this.props.colorHSL}
						isTransparencyEnabled={this.props.isTransparencyEnabled}
						eyeDropperProps={this.props.eyeDropperProps}
					/>
				)}
			</div>
		);
	}
}

import type * as signalR from "@microsoft/signalr";
import type {Markup} from "../models/Markup";
import type {IModel} from "../models/Model";
import type {Xyicon} from "../models/Xyicon";
import type {BoundarySpaceMap} from "../models/BoundarySpaceMap";
import type {Boundary} from "../models/Boundary";
import {Link} from "../models/Link";
import type {Catalog} from "../models/Catalog";
import {featureTitles} from "../state/AppStateConstants";
import type {TransportLayer} from "../TransportLayer";
import {XyiconFeature, Permission} from "../../generated/api/base";
import type {
	ViewDto,
	BoundaryDto,
	BoundarySpaceMapDto,
	LibraryImageDto,
	LibraryModelDto,
	MarkupDto,
	XyiconDto,
	LinkBasicDto,
	SpaceDeleteDto,
	XyiconCatalogsUpdatePortDto,
	UpdateBoundaryTypeDto,
	MergedBoundaryDto,
} from "../../generated/api/base";
import type {ITypeData, Type} from "../models/Type";
import type {IFieldData} from "../models/field/Field";
import type {ILayoutDefinition} from "../../ui/modules/settings/modules/layout/LayoutSettings";
import type {SpaceViewRenderer} from "../../ui/modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import type {Xyicon3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {Report, IReportResult} from "../models/Report";
import type {XyiconManager} from "../../ui/modules/space/spaceeditor/logic3d/managers/spaceitems/XyiconManager";
import type {CaptionedItem} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/CaptionManager";
import {CaptionManager} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/CaptionManager";
import type {BoundarySpaceMap3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {Signal} from "../../utils/signal/Signal";
import {notify} from "../../utils/Notify";
import {NotificationType} from "../../ui/notification/Notification";
import type {INotificationElementParams} from "../../ui/notification/AppNotifications";
import type {ReportDto} from "../../generated/api/reports";
import {DefaultFieldsUtils} from "../../ui/modules/abstract/sidepanel/tabs/details/default/DefaultFieldsUtils";
import {TimeUtils} from "../../utils/TimeUtils";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";

type LinkUpdateType = "created" | "updated" | "deleted";

export interface IImportStatus {
	errorRows: number;
	importTerminated: boolean;
	isCompleted: boolean;
	message: string;
	processedRows: number;
	requestID: string;
	timeElapsed: number;
	totalRows: number;
	validRows: number;
}

export interface IPortfolioDuplicationStatus {
	isCompleted: boolean;
	isError: boolean;
	message: string;
	portfolioID: string;
	requestID: string;
	timeElapsed: number;
}

interface IDataValidationFailDto {
	feature: XyiconFeature;
	objectID: string;
	fieldRefID: string;
	revertValue: string;
}

interface IRefreshRequiredObject {
	count: number;
	feature: XyiconFeature;
	portfolioID: string;
}

type XyiconUpdateSettingsDto = {
	lastModifiedAt?: string;
	lastModifiedBy?: string;
	xyiconCatalogID?: string;
	xyiconID?: string;
	settings?: any;
};

type XyiconCatalogUpdateTypeDto = {
	lastModifiedAt?: string;
	lastModifiedBy?: string;
	xyiconCatalogID?: string;
	xyiconTypeID?: string;
};

/**
 * This class listens on signalR connection and applies incoming changes to the local data in AppState.
 */
export class SignalRListener {
	private readonly _transportLayer: TransportLayer;
	private readonly _spaceViewRenderer: SpaceViewRenderer;

	private readonly _validationRuleFailedNotifications: {
		[key: string]: INotificationElementParams;
	} = {};
	private _notifyRestartNotification: INotificationElementParams | null = null;
	private _featuresToRefresh: XyiconFeature[] = [];

	public signals = {
		importStatusReceived: Signal.create<IImportStatus>(),
		portfolioDuplicationStatusReceived: Signal.create<IPortfolioDuplicationStatus>(),
		linksUpdated: Signal.create<{links: LinkBasicDto[]; type: LinkUpdateType}>(),
		reportGenerated: Signal.create<IReportResult>(),
	};

	constructor(transportLayer: TransportLayer) {
		this._transportLayer = transportLayer;
		this._spaceViewRenderer = transportLayer.appState.app.spaceViewRenderer;
	}

	public addListeners(connection: signalR.HubConnection) {
		const appActions = this._transportLayer.appState.actions;

		// Supported events in ISpaceRunnerSignalRClient.cs:
		// https://dev.azure.com/xyicon/SpaceRunner%20V4/_git/SRV4Backend?path=%2FSource%2FSpaceRunner%20V4%20Backend%2FSpaceRunner.V4.Common%2FSignalR%2FISpaceRunnerSignalRClient.cs&_a=contents&version=GBdevelop

		connection.on("PortfolioCreated", (data) => appActions.addToList(data, XyiconFeature.Portfolio));
		connection.on("PortfoliosDeleted", this.onPortfoliosDeleted);
		connection.on("PortfolioFieldsUpdated", (fieldValues, ids) => this.onItemFieldsUpdated(fieldValues, ids, XyiconFeature.Portfolio));

		connection.on("SpaceFieldsUpdated", (fieldValues, ids) => this.onItemFieldsUpdated(fieldValues, ids, XyiconFeature.Space));
		connection.on("SpacesDeleted", (spaceList: SpaceDeleteDto[]) => appActions.onSpacesDeleted(spaceList));

		connection.on("LinksCreated", this.LinksCreated);
		connection.on("LinksUpdated", this.LinksUpdated);
		connection.on("LinksDeleted", this.LinksDeleted);

		connection.on("ViewCreated", this.ViewCreated);
		connection.on("ViewUpdated", this.ViewUpdated);
		connection.on("ViewsDeleted", this.ViewsDeleted);

		connection.on("FeatureTypeCreated", this.FeatureTypeCreated);
		connection.on("FeatureTypeUpdated", this.FeatureTypeUpdated);
		connection.on("FeatureTypesDeleted", this.FeatureTypesDeleted);

		connection.on("FieldCreated", this.FieldCreated);
		connection.on("FieldUpdated", this.FieldUpdated);
		connection.on("FieldDeleted", this.FieldDeleted);

		// connection.on("FieldToTypesMappingUpdated", this.FieldToTypesMappingUpdated);
		connection.on("TypeToFieldsMappingUpdated", this.TypeToFieldsMappingUpdated);
		// connection.on("FieldLayoutCreated", this.FieldLayoutCreated);
		connection.on("FieldLayoutUpdated", this.FieldLayoutUpdated);

		connection.on("MarkupCreated", (data: MarkupDto[]) => this.spaceItemsCreated(data, XyiconFeature.Markup));
		connection.on("MarkupDeleted", (markupIDs: string[]) => this.spaceItemsDeleted(markupIDs, XyiconFeature.Markup));
		connection.on("MarkupUpdated", (data: MarkupDto[]) => this.spaceItemUpdated(data, XyiconFeature.Markup));

		connection.on("XyiconCatalogCreated", (data) => appActions.addToList(data, XyiconFeature.XyiconCatalog));
		connection.on("XyiconCatalogUpdated", this.XyiconCatalogUpdated);
		connection.on("XyiconCatalogsDeleted", (ids) => appActions.applyDelete(ids, XyiconFeature.XyiconCatalog));
		connection.on("XyiconCatalogFieldsUpdated", (fieldValues, ids) => this.onItemFieldsUpdated(fieldValues, ids, XyiconFeature.XyiconCatalog));
		connection.on("XyiconCatalogUpdatedIcon", this.XyiconCatalogUpdatedIcon);
		connection.on("XyiconsModelUpdated", this.onXyiconsUpdated);
		connection.on("XyiconCatalogsTypeUpdated", this.onCatalogTypeUpdated);
		connection.on("XyiconCatalogPortUpdated", this.onCatalogPortUpdated);

		connection.on("XyiconsOrientationUpdated", (dataArray: any) => this.onXyiconsTransformationsUpdated(dataArray, "orientation"));
		connection.on("XyiconsPositionUpdated", (dataArray: any) => this.onXyiconsTransformationsUpdated(dataArray, "position"));
		connection.on("XyiconsDeleted", (xyiconIDs: string[]) => this.spaceItemsDeleted(xyiconIDs, XyiconFeature.Xyicon));
		connection.on("XyiconsCreated", (dataArray: XyiconDto[]) => this.spaceItemsCreated(dataArray, XyiconFeature.Xyicon));
		connection.on("XyiconsFieldsUpdated", (fieldValues, ids) => this.onItemFieldsUpdated(fieldValues, ids, XyiconFeature.Xyicon));
		connection.on("XyiconSettingsUpdated", this.onXyiconsUpdated);
		connection.on("XyiconsUnplotted", this.onXyiconsUnplotted);

		connection.on("BoundaryCreated", (data: BoundaryDto[]) => this.spaceItemsCreated(data, XyiconFeature.Boundary));
		connection.on("BoundaryUpdated", (data: BoundarySpaceMapDto[]) => this.spaceItemUpdated(data, XyiconFeature.Boundary));
		connection.on("BoundaryFieldsUpdated", (fieldValues, ids) => this.onItemFieldsUpdated(fieldValues, ids, XyiconFeature.Boundary));
		connection.on("BoundaryTypeUpdated", this.onBoundaryTypeUpdated);
		connection.on("BoundarySpaceMapDeleted", (boundarySpaceMapIds: string[]) => this.spaceItemsDeleted(boundarySpaceMapIds, XyiconFeature.Boundary));
		connection.on("BoundariesDeleted", this.boundariesDeleted);
		connection.on("BoundarySpaceMapLinkCreated", this.onBoundariesMerged);

		connection.on("NotifyDataImportStatus", this.notifyDataImportStatus);
		connection.on("NotifyRestartRequiredToObtainLargeUpdate", this.notifyRestartRequiredToObtainLargeUpdate);

		connection.on("NotifyDuplicatePortfolioStatus", this.notifyPortfolioDuplicationStatus);

		connection.on("LibraryModelCreated", this.onLibraryModelCreated);
		connection.on("LibraryModelUpdated", this.onLibraryModelUpdated);
		connection.on("LibraryModelDeleted", this.onLibraryModelDeleted);

		connection.on("LibraryImageCreated", this.onLibraryImageCreated);
		connection.on("LibraryImagesDeleted", this.onLibraryImageDeleted);

		connection.on("SpaceRescaled", this.spaceRescaled);

		connection.on("ReportGenerated", this.ReportGenerated);
		connection.on("ReportUpdated", this.onReportUpdated);

		connection.on("DataValidationFailed", this.onDataValidationFailed);
	}

	private getBoundarySpaceMapIdsFromBoundaryIds(boundaryIds: string[]): string[] {
		const boundaryIdsSet = new Set<string>(boundaryIds);
		const appState = this._transportLayer.appState;
		const boundaries = appState.actions
			.getList<Boundary>(XyiconFeature.Boundary)
			.filter((b) => b.portfolioId === appState.portfolioId && boundaryIdsSet.has(b.id));
		const boundarySpaceMaps = boundaries.flatMap((b) => [...b.boundarySpaceMaps]);

		return boundarySpaceMaps.map((b) => b.id);
	}

	private notifyDataImportStatus = (data: IImportStatus) => {
		this.signals.importStatusReceived.dispatch(data);
	};

	private notifyPortfolioDuplicationStatus = (data: IPortfolioDuplicationStatus) => {
		this.signals.portfolioDuplicationStatusReceived.dispatch(data);
	};

	private onLibraryModelCreated = (data: LibraryModelDto) => {
		this._spaceViewRenderer.actions.addToList(data, XyiconFeature.LibraryModel);
	};

	private onLibraryModelUpdated = (data: LibraryModelDto) => {
		// TODO
	};

	private onLibraryModelDeleted = (data: string[]) => {
		if (data.length > 0) {
			this._spaceViewRenderer.actions.applyDelete(data, XyiconFeature.LibraryModel);
		}
	};

	private onLibraryImageCreated = (data: LibraryImageDto) => {
		this._spaceViewRenderer.actions.addToList(data, XyiconFeature.LibraryImage);
	};

	private onLibraryImageDeleted = (data: string[]) => {
		if (data.length > 0) {
			this._spaceViewRenderer.actions.applyDelete(data, XyiconFeature.LibraryImage);
		}
	};

	private notifyRestartRequiredToObtainLargeUpdate = (data: IRefreshRequiredObject) => {
		if (data.portfolioID === this._transportLayer.appState.portfolioId || data.feature === XyiconFeature.Portfolio) {
			if (this._notifyRestartNotification) {
				this._notifyRestartNotification.onClose();
			}

			this._featuresToRefresh.push(data.feature);

			this._notifyRestartNotification = notify(this._transportLayer.appState.app.notificationContainer, {
				type: NotificationType.Warning,
				title: "Your Portfolio has changed. Select update to refresh your data",
				description: "",
				lifeTime: Infinity,
				buttonLabel: "Update",
				onActionButtonClick: async () => {
					const promises = this._featuresToRefresh.map((f) => this._transportLayer.services.feature.refreshList(f, true));

					this._featuresToRefresh.length = 0;
					await Promise.all(promises);

					if (this._spaceViewRenderer.isMounted) {
						await this._spaceViewRenderer.refreshSpaceItems();
					}
				},
			});
		}
	};

	private getSpaceItemModelById(id: string, feature: XyiconFeature): IModel {
		if (feature === XyiconFeature.Boundary) {
			const spaceItems = this._transportLayer.appState.actions.getList<Boundary>(feature);

			for (const spaceItem of spaceItems) {
				for (const boundarySpaceMap of spaceItem.boundarySpaceMaps) {
					if (boundarySpaceMap.id === id) {
						return boundarySpaceMap;
					}
				}
			}
		} else {
			return this._transportLayer.appState.actions.getFeatureItemById(id, feature);
		}

		return null;
	}

	private FieldLayoutUpdated = (data: {feature: XyiconFeature; fieldLayoutID: string; layoutDefinition: ILayoutDefinition}) => {
		this._transportLayer.appState.layouts[data.feature] = data.layoutDefinition;
		this._transportLayer.appState.layoutSettings?.current?.refreshLayout();
	};

	private TypeToFieldsMappingUpdated = (data: {[key: string]: string[]}, feature: XyiconFeature) => {
		const typeId = Object.keys(data)[0];
		const mapping = this._transportLayer.appState.typeFieldMapping[feature];
		const mappingForType = new Set<string>();

		for (const change of data[typeId]) {
			mappingForType.add(change);
		}

		mapping[typeId] = mappingForType;
	};

	private FieldCreated = (data: IFieldData) => {
		this._transportLayer.services.typefield.createModel(data, "fields");
	};

	private FieldUpdated = (data: IFieldData) => {
		const field = this._transportLayer.appState.actions.getFieldById(data.fieldID);

		if (field) {
			field.applyData(data);
		}
	};

	private FieldDeleted = (data: {deletedList: string[]; feature: XyiconFeature}) => {
		this._transportLayer.services.typefield.applyDelete(data.deletedList, "fields", data.feature);
	};

	// usually works
	private FeatureTypeCreated = (data: ITypeData) => {
		this._transportLayer.services.typefield.createModel(data, "types");
	};

	// rarely works
	private FeatureTypeUpdated = (data: ITypeData) => {
		const type = this._transportLayer.appState.actions.getTypeById(data.featureTypeID);

		if (type) {
			type.applyData(data);
		}
	};

	// never works
	private FeatureTypesDeleted = (ids: string[], feature: XyiconFeature) => {
		this._transportLayer.services.typefield.applyDelete(ids, "types", feature);
	};

	private onPortfoliosDeleted = (ids: string[]) => {
		const portfolioName = this._transportLayer.appState.actions.getCurrentPortfolioName();
		const activePortfolioId = this._transportLayer.appState.portfolioId;
		const isActivePortfolio = !!ids.includes(activePortfolioId);

		this._transportLayer.appState.actions.applyDelete(ids, XyiconFeature.Portfolio);
		this._transportLayer.appState.actions.onDeletePortfolio(portfolioName, isActivePortfolio);

		if (isActivePortfolio) {
			this._transportLayer.services.localStorage.set("isActivePortfolioDeleted", true);
		}
	};

	private onItemFieldsUpdated = (fieldValues: {[refId: string]: any}, ids: string[], feature: XyiconFeature) => {
		for (const id of ids) {
			const item = this._transportLayer.appState.actions.getFeatureItemById(id, feature);

			if (item) {
				// Apply field values TODO duplicate
				for (const refId in fieldValues) {
					item.fieldData[refId] = fieldValues[refId];

					this._transportLayer.appState.itemFieldUpdateManager.removeItemFieldUpdateFromUpdateList(item.id, refId, feature);
				}
				item.applyFieldValues?.(fieldValues);
			}
		}

		// space update
		this._transportLayer.appState.actions.updateConditionalFormattingForFeature(feature);
		this._transportLayer.appState.actions.updateSpaceEditorCaptions(feature);
	};

	private updateSpaceItemsOnLinkChange(link: Link) {
		const ret: (BoundarySpaceMap3D | Xyicon3D)[] = [];
		const fromItem =
			this.getSpaceItemModelById(link.fromObjectId, XyiconFeature.Boundary) || this.getSpaceItemModelById(link.fromObjectId, XyiconFeature.Xyicon);

		if (fromItem) {
			const fromItem3D = this._spaceViewRenderer.getItemManager(fromItem.ownFeature).getItemById(fromItem.id) as CaptionedItem;

			if (fromItem3D) {
				fromItem3D.onFormattingRulesModified();
				ret.push(fromItem3D);
			}
		}

		const toItem =
			this.getSpaceItemModelById(link.toObjectId, XyiconFeature.Boundary) || this.getSpaceItemModelById(link.toObjectId, XyiconFeature.Xyicon);

		if (toItem) {
			const toItem3D = this._spaceViewRenderer.getItemManager(toItem.ownFeature).getItemById(toItem.id) as CaptionedItem;

			if (toItem3D) {
				toItem3D.onFormattingRulesModified();
				ret.push(toItem3D);
			}
		}

		return ret;
	}

	private onLinksUpdated(linkDataArray: LinkBasicDto[], type: "created" | "updated" | "deleted") {
		const itemsAffected: CaptionedItem[] = [];

		for (const linkData of linkDataArray) {
			const link = this._transportLayer.appState.actions.getFeatureItemById<Link>(linkData.linkID, XyiconFeature.Link) || new Link(linkData);

			this._spaceViewRenderer.xyiconManager.updateEmbeddedDetailsOfXyicon(link, type);
			const items = this.updateSpaceItemsOnLinkChange(link);

			for (const item of items) {
				if (!itemsAffected.includes(item)) {
					itemsAffected.push(item);
				}
			}
		}

		this.updateCaptionsAndLinkIcons(itemsAffected);

		this._transportLayer.appState.actions.updateSpaceEditorFilterState();

		this.signals.linksUpdated.dispatch({links: linkDataArray, type});
	}

	private LinksCreated = (data: LinkBasicDto[]) => {
		this.addLinks(data);

		this.onLinksUpdated(data, "created");
	};

	private LinksUpdated = (data: LinkBasicDto[]) => {
		this.deleteLinks(data);
		this.addLinks(data);

		this.onLinksUpdated(data, "updated");
	};

	private LinksDeleted = (data: LinkBasicDto[]) => {
		this.deleteLinks(data);

		this.onLinksUpdated(data, "deleted");
	};

	private deleteLinks(data: LinkBasicDto[]) {
		const appState = this._transportLayer.appState;

		if (Array.isArray(data)) {
			if (data.length < 10) {
				for (const link of data) {
					appState.lists[XyiconFeature.Link].deleteById(link.linkID);
				}
			} else {
				// many items -> faster to delete all in one go
				appState.lists[XyiconFeature.Link].deleteByIds(data.map((link) => link.linkID));
			}
		}
	}

	private addLinks(data: LinkBasicDto[]) {
		const {actions} = this._transportLayer.appState;

		for (const link of data) {
			if (!actions.getFeatureItemById<Link>(link.linkID, XyiconFeature.Link)) {
				actions.addToList(link, XyiconFeature.Link);
			}
		}
	}

	private async updateCaptionsAndLinkIcons(affectedItems: CaptionedItem[]) {
		const xyicons = affectedItems.filter((item) => item.spaceItemType === "xyicon") as Xyicon3D[];
		const boundarySpaceMaps = affectedItems.filter((item) => item.spaceItemType === "boundary") as BoundarySpaceMap3D[];

		await this._spaceViewRenderer.xyiconManager.captionManager.updateCaptions(xyicons);
		await this._spaceViewRenderer.boundaryManager.captionManager.updateCaptions(boundarySpaceMaps);

		const linkIconManagerNeedsUpdate = affectedItems.length > 0;

		if (linkIconManagerNeedsUpdate) {
			this._spaceViewRenderer.spaceItemController.linkIconManager.update();
			this._spaceViewRenderer.spaceItemController.updateActionBar(undefined, false);
		}
	}

	private ViewCreated = (data: ViewDto) => {
		const newView = this._transportLayer.services.view.createView(data, false);
		const permission = newView.getPermission();

		if (permission >= Permission.View) {
			// add to list
			this._transportLayer.services.view.createView(data, true);
			this._transportLayer.appState.user?.updateViewFolderStructures();
		}
	};

	private ViewUpdated = (data: ViewDto) => {
		const actions = this._transportLayer.appState.actions;
		const view = actions.getViewById(data.viewID);

		if (view) {
			view.applyData(data);
			const permission = view.getPermission();

			if (permission >= Permission.View) {
				// We already applied the data to get the proper permissions
			} else {
				// Delete view locally (if it has been unshared)
				this.ViewsDeleted([view.id]);
			}
		} else {
			// Create view locally (if it has been shared)
			this.ViewCreated(data);
		}
	};

	private ViewsDeleted = (ids: string[]) => {
		this._transportLayer.services.view.applyDelete(ids);
		this._transportLayer.appState.user?.updateViewFolderStructures();
	};

	private XyiconCatalogUpdated = () => {
		// TODO
	};

	private XyiconCatalogUpdatedIcon = () => {
		// TODO
	};

	private onXyiconsUpdated = (dataArray: XyiconUpdateSettingsDto[]) => {
		const xyicons: Xyicon[] = [];

		for (const xyiconData of dataArray) {
			const xyicon = this._transportLayer.appState.actions.getFeatureItemById<Xyicon>(xyiconData.xyiconID, XyiconFeature.Xyicon);

			xyicon.applyData(xyiconData);
			xyicons.push(xyicon);
		}

		return this._spaceViewRenderer.xyiconManager.updateXyicons(xyicons);
	};

	private onCatalogPortUpdated = (data: XyiconCatalogsUpdatePortDto) => {
		const catalog = this._transportLayer.appState.actions.getFeatureItemById<Catalog>(data.xyiconCatalogID, XyiconFeature.XyiconCatalog);

		if (catalog) {
			catalog.lastModifiedAt = data.lastModifiedAt;
			catalog.lastModifiedBy = data.lastModifiedBy;
			catalog.setPortTemplate(data.portTemplate);
		}
	};

	private onBoundaryTypeUpdated = (data: UpdateBoundaryTypeDto) => {
		const type: Type | null = this._transportLayer.appState.actions.getTypeById(data.boundaryTypeID);

		if (type) {
			for (const boundaryID of data.boundaryIDList) {
				const boundary = this._transportLayer.appState.actions.getFeatureItemById<Boundary>(boundaryID, XyiconFeature.Boundary);

				if (boundary) {
					DefaultFieldsUtils.onTypeOfBoundaryChange(boundary, data, this._spaceViewRenderer);
				}
			}
		}
	};

	private onCatalogTypeUpdated = (dataArray: XyiconCatalogUpdateTypeDto[]) => {
		for (const catalogData of dataArray) {
			const catalog = this._transportLayer.appState.actions.getFeatureItemById<Catalog>(catalogData.xyiconCatalogID, XyiconFeature.XyiconCatalog);

			if (catalog) {
				catalog.lastModifiedAt = catalogData.lastModifiedAt;
				catalog.lastModifiedBy = catalogData.lastModifiedBy;
				catalog.typeId = catalogData.xyiconTypeID;
			}
		}
	};

	private async onXyiconsTransformationsUpdated(
		dataArray: {xyiconID: string; orientation: number; iconX: number; iconY: number; iconZ: number; spaceID: string}[],
		type: "orientation" | "position",
	) {
		const captionedXyicons: Xyicon3D[] = [];
		let captionsNeedToBeRecreated: boolean = false;
		const {xyiconManager} = this._spaceViewRenderer;

		const updatedXyicons: IModel[] = [];

		for (const data of dataArray) {
			const xyicon = this.getSpaceItemModelById(data.xyiconID, XyiconFeature.Xyicon) as Xyicon;

			if (xyicon) {
				const hasChanged =
					(type === "orientation" && xyicon.orientation !== data.orientation) ||
					(type === "position" &&
						(xyicon.iconX !== data.iconX || xyicon.iconY !== data.iconY || xyicon.iconZ !== data.iconZ || xyicon.spaceId !== data.spaceID));

				if (hasChanged) {
					updatedXyicons.push(xyicon);
					xyicon.applyData(ObjectUtils.apply(xyicon.data, data));
					let spaceItem = xyiconManager.updateByModel(xyicon) as Xyicon3D;

					if (!spaceItem && xyicon.spaceId === this._spaceViewRenderer.space?.id && this._spaceViewRenderer.isMounted) {
						spaceItem = (await xyiconManager.addItemsByModel([xyicon]))[0];
						(spaceItem as CaptionedItem).addCaption(xyiconManager.captionManager.getActiveCaptionFields());
						captionsNeedToBeRecreated = true;
					}

					if (spaceItem && CaptionManager.filterVisibleCaptionedItems(spaceItem)) {
						captionedXyicons.push(spaceItem);
					}
				}
			}
		}
		xyiconManager.signals.itemsUpdate.dispatch(updatedXyicons);

		if (type === "position") {
			this._spaceViewRenderer.spaceItemController.linkIconManager.update();
		}

		if (captionedXyicons.length > 0) {
			const {captionManager} = xyiconManager;

			if (captionsNeedToBeRecreated) {
				captionManager.recreateGeometry();
			}
			captionManager.updateCaptionPositions(captionedXyicons);
		}
	}

	private onXyiconsUnplotted = (dataArray: {unplottedXyiconID: string}[]) => {
		const xyiconFeature = XyiconFeature.Xyicon;
		const xyiconManager = this._spaceViewRenderer.xyiconManager;

		const xyiconsToUnplot: Xyicon3D[] = [];

		for (const data of dataArray) {
			const xyiconModel = this.getSpaceItemModelById(data.unplottedXyiconID, xyiconFeature) as Xyicon;

			if (xyiconModel) {
				xyiconModel.unplot();
				const spaceItem = xyiconManager.getItemById(xyiconModel.id) as Xyicon3D;

				if (spaceItem) {
					xyiconsToUnplot.push(spaceItem);
				}
			}
		}

		xyiconManager.captionManager.hideTextGroup(
			xyiconsToUnplot.filter(CaptionManager.filterVisibleCaptionedItems).map((item: CaptionedItem) => item.caption),
			true,
		);

		for (const xyicon of xyiconsToUnplot) {
			xyicon.destroy(false);
		}
	};

	private deleteSpaceItemsFromActiveSpace(spaceItemIdsToDelete: string[], feature: XyiconFeature) {
		return this._transportLayer.appState.actions.deleteSpaceItemsFromActiveSpace(spaceItemIdsToDelete, feature);
	}

	private boundariesDeleted = (boundaryIds: string[]) => {
		const uniqueBoundaryIds = ArrayUtils.removeDuplicates(boundaryIds);
		this.deleteSpaceItemsFromActiveSpace(this.getBoundarySpaceMapIdsFromBoundaryIds(uniqueBoundaryIds), XyiconFeature.Boundary);
		this._transportLayer.appState.actions.applyDelete(uniqueBoundaryIds, XyiconFeature.Boundary);
	};

	private onBoundariesMerged = async (data: MergedBoundaryDto) => {
		// When you merge 2 boundaryspacemaps together, and one of them loses all of its boundaryspacemaps, it gets deleted
		// We want this delay because it's not determined whether this one gets triggered first, or the "boundariesDeleted" method
		await TimeUtils.wait(500);

		const appState = this._transportLayer.appState;
		const actions = appState.actions;
		const spaceViewRenderer = appState.app.spaceViewRenderer;
		const spaceEditorSelectedItemIds: {
			xyicons: string[];
			boundarySpaceMaps: string[];
			markups: string[];
		} = {
			xyicons: [],
			boundarySpaceMaps: [],
			markups: [],
		};

		if (spaceViewRenderer.isMounted) {
			spaceEditorSelectedItemIds.xyicons.push(...spaceViewRenderer.xyiconManager.selectedItems.map((item) => item.id));
			spaceEditorSelectedItemIds.boundarySpaceMaps.push(...spaceViewRenderer.boundaryManager.selectedItems.map((item) => item.id));
			spaceEditorSelectedItemIds.markups.push(...spaceViewRenderer.markupManager.selectedItems.map((item) => item.id));
		}

		// There's a scenario when there are multiple boundaryspacemaps for a boundary, and only some of them are affected by the merge.
		// NOTE: This is not a real scenario, as per a UI limitation, users are only allow to merge "full" boundaries, not the individual boundaryspacemaps!
		//
		// But in this theoretical case, we don't delete the boundary (because there are still some boundaryspacemaps inside of it)
		// If all the boundaryspacemaps of a boundary is merged into another boundary, we need to delete the boundary, because
		// no boundary can exist without any boundaryspacemaps
		const boundarySpaceMaps = data.childBoundarySpaceMapIDList.map((id) => appState.boundarySpaceMaps.getById(id));

		const boundaryParents: {
			boundaryParent: Boundary;
			affectedBoundarySpaceMaps: BoundarySpaceMap[];
		}[] = [];

		for (const boundarySpaceMap of boundarySpaceMaps) {
			const boundary = boundarySpaceMap.parent;

			const objectMaybe = boundaryParents.find((o) => o.boundaryParent === boundary);
			if (objectMaybe) {
				objectMaybe.affectedBoundarySpaceMaps.push(boundarySpaceMap);
			} else {
				boundaryParents.push({
					boundaryParent: boundary,
					affectedBoundarySpaceMaps: [boundarySpaceMap],
				});
			}
		}

		for (const object of boundaryParents) {
			if (object.boundaryParent.boundarySpaceMaps.size === object.affectedBoundarySpaceMaps.length) {
				// No boundary can exist without any boundaryspacemaps
				actions.applyDelete([object.boundaryParent.id], XyiconFeature.Boundary);
			}
		}

		const parentBoundary = actions.getFeatureItemById<Boundary>(data.parentBoundaryID, XyiconFeature.Boundary);
		if (parentBoundary) {
			const affectedBoundarySpaceMapIds = [...data.childBoundarySpaceMapIDList, ...[...parentBoundary.boundarySpaceMaps].map((bsm) => bsm.id)];
			const childBoundarySpaceMapIdSet = new Set(affectedBoundarySpaceMapIds);
			const boundarySpaceMaps: BoundarySpaceMap[] = appState.boundarySpaceMaps.array.filter(
				(bsm) => !bsm.id.includes("ghost") && childBoundarySpaceMapIdSet.has(bsm.id),
			);

			for (const boundarySpaceMap of boundarySpaceMaps) {
				boundarySpaceMap.setParent(parentBoundary);
			}

			// SpaceEditor
			if (spaceViewRenderer.isMounted && parentBoundary.spaceId === spaceViewRenderer.space?.id) {
				const newBoundarySpaceMap3Ds = spaceViewRenderer.boundaryManager.addItemsByModel([...parentBoundary.boundarySpaceMaps]);

				for (const newBoundarySpaceMap of newBoundarySpaceMap3Ds) {
					newBoundarySpaceMap.select();
				}
				spaceViewRenderer.spaceItemController.updateActionBar();
				actions.updateSpaceEditorCaptions(XyiconFeature.Boundary);
			}
		}
	};

	private spaceItemsDeleted(spaceItemIds: string[], feature: XyiconFeature) {
		this.deleteSpaceItemsFromActiveSpace(spaceItemIds, feature);
		this._transportLayer.appState.actions.applyDelete(spaceItemIds, feature);
	}

	private spaceItemUpdated(dataArray: MarkupDto[] | BoundarySpaceMapDto[], feature: XyiconFeature) {
		const itemManager = this._spaceViewRenderer.getItemManager(feature);

		const updatedItems: IModel[] = [];

		for (const data of dataArray) {
			const spaceItemModel = this.getSpaceItemModelById(
				XyiconFeature.Markup === feature ? (data as MarkupDto).markupID : (data as BoundarySpaceMapDto).boundarySpaceMapID,
				feature,
			) as BoundarySpaceMap | Markup;

			updatedItems.push(spaceItemModel);
			if (this._transportLayer.appState.space?.id === data.spaceID) {
				if (spaceItemModel) {
					const oldData = JSON.stringify(spaceItemModel.data);

					spaceItemModel.applyData(data);
					const newData = JSON.stringify(spaceItemModel.data);
					const isSame = oldData === newData;

					if (!isSame) {
						const spaceItem = itemManager.updateByModel(spaceItemModel);

						if (!spaceItem && spaceItemModel.spaceId === this._spaceViewRenderer.space?.id && this._spaceViewRenderer.isMounted) {
							itemManager.addItemsByModel([spaceItemModel]);
						}
					}
				}
			} else {
				const spaceItem = itemManager.getItemById(spaceItemModel.id);

				if (spaceItem) {
					spaceItem.destroy(false);
				}
			}
		}

		itemManager.signals.itemsUpdate.dispatch(updatedItems);

		if (feature === XyiconFeature.Boundary) {
			const captionedBoundaries = this._spaceViewRenderer.boundaryManager.items.array.filter(CaptionManager.filterVisibleCaptionedItems);

			if (captionedBoundaries) {
				this._spaceViewRenderer.boundaryManager.captionManager.updateCaptions();
			}
		}

		if (feature === XyiconFeature.Markup) {
			this._spaceViewRenderer.spaceItemController.markupTextManager.recreateGeometry();
		}
	}

	private async spaceItemsCreated(data: XyiconDto[] | BoundaryDto[] | MarkupDto[], feature: XyiconFeature) {
		if (!this._spaceViewRenderer.isMounted) {
			// in Xyicon grid view when unplotted or embedded xyicons are created only the addToList is needed
			data.forEach((d) => this._transportLayer.appState.actions.addToList(d, feature));
		} else {
			// this code only creates elements in spaceEditor (in xyicon grid view appState.space?.id !== itemSpaceID)
			// in spaceEditor spaceItems need to be created
			const itemManager = this._spaceViewRenderer.getItemManager(feature);

			const modelsToAdd: IModel[] = [];

			for (const item of data) {
				const itemSpaceID =
					(item as BoundaryDto).boundarySpaceMaps?.length > 0 ? (item as BoundaryDto).boundarySpaceMaps[0].spaceID : (item as XyiconDto).spaceID;

				if (this._transportLayer.appState.space?.id === itemSpaceID) {
					const spaceItemModel = this._transportLayer.appState.actions.addToList(item, feature);
					let modelToAdd = spaceItemModel;

					if ((spaceItemModel as Boundary).isBoundary) {
						const boundary = spaceItemModel as Boundary;

						[...boundary.boundarySpaceMaps][0].setParent(boundary);
						modelToAdd = [...boundary.boundarySpaceMaps][0];
					}
					modelsToAdd.push(modelToAdd);
				}
			}

			const spaceItems = await itemManager.addItemsByModel(modelsToAdd);

			if (spaceItems.length > 0 && (feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary)) {
				const captionManager = (itemManager as XyiconManager).captionManager;

				for (const spaceItem of spaceItems) {
					(spaceItem as CaptionedItem).addCaption(captionManager.getActiveCaptionFields());
				}
				captionManager.recreateGeometry();

				if (feature === XyiconFeature.Xyicon) {
					this._spaceViewRenderer.xyiconManager.captionManager.updateCaptionPositions(
						(spaceItems as Xyicon3D[]).filter(CaptionManager.filterVisibleCaptionedItems),
					);
				}
			}
		}
	}

	private spaceRescaled = (data: {spaceID: string; unitsPerMeter: number}) => {
		const space = this._transportLayer.appState.actions.getSpaceById(data.spaceID);

		if (space) {
			space.setSpaceUnitsPerMeter(data.unitsPerMeter);

			if (space.id === this._spaceViewRenderer.space?.id) {
				this._spaceViewRenderer.refreshSpace();
			}
		}
	};

	private ReportGenerated = (result: IReportResult) => {
		const reports = this._transportLayer.appState.actions.getList<Report>(XyiconFeature.Report);

		for (const report of reports) {
			const fileFormat = StringUtils.lowerCase(report.deliveryFormat || "csv");
			const filePath = this._transportLayer.getReportFilePath(result.requestID, fileFormat);

			if (report.completeRequest(result, filePath)) {
				break;
			}
		}

		this.signals.reportGenerated.dispatch(result);
	};

	private onReportUpdated = (data: ReportDto) => {
		const report = this._spaceViewRenderer.actions.getFeatureItemById<Report>(data.reportID, XyiconFeature.Report);

		if (report) {
			report.setSharingSettings(data.reportSharingSettings);
		}
	};

	private onDataValidationFailed = (data: IDataValidationFailDto) => {
		const {actions} = this._transportLayer.appState;
		const field = actions.getFieldByRefId(data.fieldRefID);
		const item = actions.getFeatureItemById(data.objectID, data.feature);

		if (field && item) {
			const featureName = featureTitles[data.feature].toLowerCase();
			const {appState} = this._transportLayer;
			const {app} = appState;
			const notificationKey = appState.itemFieldUpdateManager.getKeyForItemFieldUpdate(data.objectID, data.fieldRefID, data.feature);

			if (this._validationRuleFailedNotifications[notificationKey]) {
				this._validationRuleFailedNotifications[notificationKey].onClose();
				delete this._validationRuleFailedNotifications[notificationKey];
			}

			const notification = notify(app.notificationContainer, {
				type: NotificationType.Error,
				title: "Validation Rule Failed",
				description: `The ${field.name} value is invalid for ${featureName} ${item.refId}. The original value has been restored. Click Edit Details to navigate to ${item.refId} and retry the update.`,
				lifeTime: Infinity,
				buttonLabel: "Edit Details",
				onActionButtonClick: () => {
					notification.onClose();
					delete this._validationRuleFailedNotifications[notificationKey];
					app.onDetailsClick(item);
				},
			});

			this._validationRuleFailedNotifications[notificationKey] = notification;

			const fieldValues = {
				[field.refId]: data.revertValue,
			};

			this.onItemFieldsUpdated(fieldValues, [data.objectID], data.feature);
		}
	};

	public closeAllValidationRuleNotifications() {
		for (const key in this._validationRuleFailedNotifications) {
			this._validationRuleFailedNotifications[key].onClose();
			delete this._validationRuleFailedNotifications[key];
		}
	}

	public closeValidationRulesAssociatedWithItem(itemId: string) {
		// key === `${itemId}_${fieldRefId}_${feature}`

		for (const key in this._validationRuleFailedNotifications) {
			const keyParts = key.split("_");

			if (itemId === keyParts[0]) {
				this._validationRuleFailedNotifications[key].onClose();
				delete this._validationRuleFailedNotifications[key];
			}
		}
	}

	public closeValidationRulesAssociatedWithField(fieldRefId: string) {
		// key === `${itemId}_${fieldRefId}_${feature}`

		for (const key in this._validationRuleFailedNotifications) {
			const keyParts = key.split("_");

			if (fieldRefId === keyParts[1]) {
				this._validationRuleFailedNotifications[key].onClose();
				delete this._validationRuleFailedNotifications[key];
			}
		}
	}

	public get validationRuleFailedNotifications() {
		return this._validationRuleFailedNotifications;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Report} from "../../../../../../../data/models/Report";
import type {OutputDisplayField} from "../../../../../../../generated/api/reports";
import {ReportType} from "../../../../../../../generated/api/reports";
import type {AppState} from "../../../../../../../data/state/AppState";
import {TextInput} from "../../../../../../widgets/input/text/TextInput";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";
import {ReactUtils} from "../../../../../../utils/ReactUtils";

interface IReportDisplayNamesProps {
	readonly report: Report;
	readonly appState?: AppState;
}

interface IReportDisplayNamesState {}

interface IReportDisplayItem {
	displayField: OutputDisplayField;
	linkedField: boolean;
}

@inject("appState")
@observer
export class ReportDisplayNames extends React.Component<IReportDisplayNamesProps, IReportDisplayNamesState> {
	private getFieldName(refId: string) {
		const field = this.props.appState.actions.getFieldByRefId(refId);

		if (field) {
			return field.name;
		}

		return "";
	}

	private onDisplayFieldNameChange = (displayFieldItem: IReportDisplayItem, value: string) => {
		if (value) {
			displayFieldItem.displayField.displayName = value;
		}

		if (!value && displayFieldItem.linkedField) {
			displayFieldItem.displayField.displayName = `Linked ${this.getFieldName(displayFieldItem.displayField.field)}`;
		}
	};

	public override render() {
		const {report} = this.props;
		const items: IReportDisplayItem[] = [];

		report.displayedFields.forEach((df) =>
			items.push({
				displayField: df,
				linkedField: false,
			}),
		);

		if (report.type === ReportType.UserDefinedLinkedXyiconReport) {
			report.displayedLinkedFields.forEach((df) => {
				if (!df.displayName) {
					df.displayName = `Linked ${this.getFieldName(df.field)}`;
				}

				items.push({
					displayField: df,
					linkedField: true,
				});
			});
		}

		return (
			<div className="ReportDisplayNames">
				<div className="header hbox">
					<h4 className="flex_1">Field Name</h4>
					<div className="gap" />
					<h4 className="flex_1">Display name in header</h4>
				</div>
				<div className="content flex_1">
					{items.map((item, i) => (
						<div
							className="displayField"
							key={i}
						>
							<div className="hbox alignCenter item flex_1">
								{item.linkedField && <SVGIcon icon="dol-field" />}
								{this.getFieldName(item.displayField.field)}
							</div>
							<div className="gap" />
							<div className={ReactUtils.cls("hbox alignCenter displayName", {linkedField: item.linkedField})}>
								{item.linkedField && <span>Linked</span>}
								<TextInput
									className="flex_1"
									key={i * 2}
									value={item.displayField.displayName.replace("Linked ", "")}
									onInput={(value) => this.onDisplayFieldNameChange(item, value)}
									onChange={(value) => this.onDisplayFieldNameChange(item, value)}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {Space} from "../../../../../../../data/models/Space";
import type {AppState} from "../../../../../../../data/state/AppState";
import {FieldDataType, XyiconFeature, Permission} from "../../../../../../../generated/api/base";
import {ClickToEditInput} from "../../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import type {Type} from "../../../../../../../data/models/Type";
import {ConfirmWindow} from "../../../../popups/ConfirmWindow";
import {Functions} from "../../../../../../../utils/function/Functions";

interface ISpaceDefaultFieldsProps {
	readonly item: Space;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ISpaceDefaultFieldsState {
	updating: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class SpaceDefaultFields extends React.Component<ISpaceDefaultFieldsProps, ISpaceDefaultFieldsState> {
	constructor(props: ISpaceDefaultFieldsProps) {
		super(props);
		this.state = {
			updating: false,
		};
	}

	private isNameValid = (name: string) => {
		return this.props.appState.actions.isSpaceNameValid(name, this.props.item);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Value needs to be unique!";
	};

	private onNameChange = async (value: string) => {
		const {item, transport} = this.props;

		if (this.isNameValid) {
			item.name = value;
			this.setState({updating: true});
			await TimeUtils.waitUpdate(transport.appState.actions.updateFields([item], {name: value}), this.props.appState.app.notificationContainer);
			this.setState({updating: false});
		}
	};

	private onChange = async (type: Type) => {
		const {item} = this.props;

		const title = "Confirm Space Type Change";
		const message = "Once you change the space type, fields (and data) not assigned to the new space type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed) {
			item.setSpaceType(type.id);
			await this.props.transport.updateSpaceType(item);
		}
	};

	private get spacePermission() {
		return this.props.appState.actions.getModuleTypePermission(this.props.item.typeId, this.props.item.ownFeature);
	}

	public override render() {
		const {item, appState} = this.props;
		const {updating} = this.state;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field
					className="TypeSelector"
					label="Type"
				>
					<SelectInput
						options={this.props.appState.types[XyiconFeature.Space]}
						render={(item) => item.name}
						selected={item.type}
						searchBar={true}
						onChange={this.onChange}
						disabled={this.spacePermission < Permission.Update}
					/>
				</Field>
				<Field label="Space Name">
					<ClickToEditInput
						value={item.name}
						onChange={this.onNameChange}
						dataType={FieldDataType.SingleLineText}
						updating={updating}
						onBlur={Functions.emptyFunction}
						getErrorMessage={this.getErrorMessage}
						disabled={this.spacePermission < Permission.Update}
						noButtons={true}
					/>
				</Field>
			</>
		);
	}
}

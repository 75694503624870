import * as React from "react";
import {inject, observer} from "mobx-react";
import {action} from "mobx";
import type {IFieldAdapter, IFieldPointer} from "../../../../data/models/field/Field";
import {FieldDataTypes} from "../../../../data/models/field/FieldDataTypes";
import type {IFilterRow, FilterType, IFilterRowFilter} from "../../../../data/models/filter/Filter";
import {LogicalSeparator} from "../../../../data/models/filter/LogicalSeparator";
import {LogicalSeparators} from "../../../../data/models/filter/LogicalSeparators";
import type {FilterOperator} from "../../../../data/models/filter/operator/FilterOperator";
import type {AppState} from "../../../../data/state/AppState";
import type {XyiconFeature} from "../../../../generated/api/base";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../../utils/data/array/ArrayUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {ButtonV5} from "../../button/ButtonV5";
import {SelectInputV5} from "../../input/select/SelectInputV5";
import CirclePlusIcon from "../../icons/circle-plus.svg?react";
import {ReactUtils} from "../../../utils/ReactUtils";
import {Functions} from "../../../../utils/function/Functions";
import {AdvancedFilterFieldV5} from "./AdvancedFilterFieldV5";
import {AdvancedFilterEditorStyled} from "./AdvancedFilterEditor.styled";

interface IAdvancedFilterEditorProps {
	readonly autoUpdate?: boolean;
	readonly features: XyiconFeature[];
	readonly filters: IFilterRow[];
	readonly localFilters?: IFilterRow[];
	readonly operatorFilter?: (operator: FilterOperator) => boolean;
	readonly fieldFilter?: (field: IFieldAdapter) => boolean;
	readonly syncFilters?: (isLoaderNeeded?: boolean) => void;
	readonly syncLocalFilters?: (filters: IFilterRow[]) => void;
	readonly multiplePortfolios?: boolean;
	readonly appState?: AppState;
	readonly linkedFields?: boolean;
}

@inject("appState")
@observer
export class AdvancedFilterEditorV5 extends React.Component<IAdvancedFilterEditorProps> {
	public readonly type: FilterType = "advanced";

	@action
	public onAddRow = () => {
		const rows = this.filters;
		const field = this.getFields()[0];

		if (rows.length > 0) {
			rows.push({
				type: "separator",
				value: LogicalSeparator.AND,
			});
		}

		const operatorFilter = this.props.operatorFilter;

		rows.push({
			type: "filter",
			value: {
				field: field.refId,
				operator: FieldDataTypes.map[field.dataType]
					.operators(field.dataTypeSettings)
					.sort(StringUtils.sortIgnoreCase) // SelectInput orders by sort
					.find((op) => (operatorFilter ? operatorFilter(op) : true)),
				param: undefined,
			},
		});
	};

	private getFields() {
		const {fieldFilter, features, multiplePortfolios, appState} = this.props;
		let fields = appState.actions.filterActions.getFieldsForFilters(features, undefined, multiplePortfolios) || [];

		if (fieldFilter) {
			fields = fields.filter(fieldFilter);
		}
		return fields;
	}

	private getFieldRefIds(): IFieldPointer[] {
		return this.getFields().map((f) => f.refId);
	}

	private onChangeField = (row: IFilterRowFilter, fieldPointer: IFieldPointer) => {
		const field = this.props.appState.actions.getFieldByRefId(fieldPointer);

		if (field) {
			row.value.field = fieldPointer;
			// Reset operator to the first available value for this dataType
			let operators = FieldDataTypes.map[field.dataType].operators(field.dataTypeSettings);

			if (this.props.operatorFilter) {
				operators = operators.filter(this.props.operatorFilter);
			}
			this.onChangeOperator(row, operators[0]);
		}
	};

	private onChangeOperator = (row: IFilterRowFilter, operator: FilterOperator) => {
		row.value.operator = operator;
		row.value.param = undefined;
	};

	private onChangeParams = (row: IFilterRowFilter, param: any) => {
		row.value.param = param;
	};

	private onRemoveField = (index: number) => {
		if (index >= 0) {
			this.filters.splice(index, 1);
		}

		// remove next separator if any
		const nextSeparator = this.filters[index];

		if (nextSeparator?.type === "separator") {
			this.filters.splice(index, 1);
		}

		// remove any last separators (normally this shouldn't run)
		while (this.filters[this.filters.length - 1]?.type === "separator") {
			this.filters.pop();
		}
	};

	public clearAll = () => {
		this.filters.length = 0;
		this.props.syncFilters?.();
	};

	public resetTo(filters: IFilterRow[]) {
		if (!ObjectUtils.compare(this.filters, filters)) {
			this.props.syncLocalFilters?.(filters);
		}

		if (!ObjectUtils.compare(this.props.filters, filters)) {
			ArrayUtils.copy(this.props.filters, filters);
		}
	}

	public applyAll() {
		ArrayUtils.replaceObservable(this.props.filters, ObjectUtils.deepClone(this.filters));
	}

	private get filters() {
		const {autoUpdate, localFilters, filters} = this.props;

		return autoUpdate ? filters : localFilters;
	}

	override componentDidMount(): void {
		if (this.props.filters.length === 0) {
			this.onAddRow();
		}
	}

	public override render() {
		const {features, filters: filtersFromProps} = this.props;
		const fieldRefIds = this.getFieldRefIds();
		const clearButtonDisabled = filtersFromProps.length === 0;

		return (
			<AdvancedFilterEditorStyled className="AdvancedFilterEditor">
				{this.filters.map((row, index) => {
					if (row.type === "filter") {
						return (
							<AdvancedFilterFieldV5
								key={index}
								fieldRefIds={fieldRefIds}
								filter={row.value}
								onChangeField={(field) => this.onChangeField(row, field)}
								onChangeOperator={(operator) => this.onChangeOperator(row, operator)}
								onChangeParams={(params) => this.onChangeParams(row, params)}
								onRemove={() => this.onRemoveField(index)}
								operatorFilter={this.props.operatorFilter}
								linkedFields={this.props.linkedFields}
							>
								<div>
									{this.filters[index - 1]?.type === "separator" ? (
										<SelectInputV5
											key={index - 1}
											options={LogicalSeparators.array}
											render={(separator) => separator.label}
											selected={LogicalSeparators.array.find((separator) => separator.id === this.filters[index - 1].value)}
											onChange={(value) => {
												this.filters[index - 1].value = value.id;
												this.forceUpdate();
											}}
											className="SelectInput"
											dropdownIcon={false}
										/>
									) : (
										<span>Where</span>
									)}
								</div>
							</AdvancedFilterFieldV5>
						);
					}
				})}
				{
					<div className="btn-container">
						{this.filters.length > 0 && (
							<ButtonV5
								label="Clear All"
								className={ReactUtils.cls("naked", {disabled: clearButtonDisabled})}
								onClick={clearButtonDisabled ? Functions.emptyFunction : this.clearAll}
								type="tertiary"
							/>
						)}
						<ButtonV5
							label="ADD"
							type="secondary"
							onClick={this.onAddRow}
						>
							<CirclePlusIcon />{" "}
						</ButtonV5>
					</div>
				}
			</AdvancedFilterEditorStyled>
		);
	}
}

import {runInAction} from "mobx";
import {isDefaultField} from "../models/field/FieldUtils";
import type {Space} from "../models/Space";
import type {IFilterState} from "../models/filter/Filter";
import type {Xyicon} from "../models/Xyicon";
import type {BoundarySpaceMap} from "../models/BoundarySpaceMap";
import type {Markup} from "../models/Markup";
import type {Link} from "../models/Link";
import type {User, ExternalUser} from "../models/User";
import type {UserGroup} from "../models/UserGroup";
import type {PermissionSet} from "../models/permission/PermissionSet";
import type {PortfolioGroup} from "../models/PortfolioGroup";
import type {Boundary} from "../models/Boundary";
import type {IResponse} from "../TransportLayer";
import type {View} from "../models/View";
import type {ICaptionSettings, IFormattingRuleSet, IViewColumn, IViewSort} from "../models/ViewUtils";
import {MarkupType, XyiconFeature, Permission, FieldDataType} from "../../generated/api/base";
import type {IEditableItemModel, IModel} from "../models/Model";
import type {Type} from "../models/Type";
import {FieldDataTypes} from "../models/field/FieldDataTypes";
import type {
	UpdateXyiconsModelRequest,
	DeletedUsersDto,
	DeletedUserInfoDto,
	SpaceDeleteDto,
	SpaceVersionsDeleteDto,
	MergeBoundaryRequest,
	MergedBoundaryDto,
} from "../../generated/api/base";
import {FeatureService} from "../services/FeatureService";
import {FormatUtils} from "../../utils/FormatUtils";
import type {Portfolio} from "../models/Portfolio";
import type {Field as FieldModel, IFieldAdapter, IFieldPointer, Field} from "../models/field/Field";
import {StringUtils} from "../../utils/data/string/StringUtils";
import type {IFilterOperatorConfig} from "../models/filter/operator/FilterOperators";
import {FilterOperators} from "../models/filter/operator/FilterOperators";
import {WarningWindow} from "../../ui/modules/abstract/popups/WarningWindow";
import type {Catalog} from "../models/Catalog";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {ReportSortDirection, type Report} from "../models/Report";
import {compareTableValues} from "../../ui/widgets/table/TableUtils";
import type {BoundarySpaceMap3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {NotificationType} from "../../ui/notification/Notification";
import {notify} from "../../utils/Notify";
import type {IBooleanFieldSettingsDefinition} from "../../ui/modules/settings/modules/field/datatypes/BooleanFieldSettings";
import {MathUtils} from "../../utils/math/MathUtils";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import type {SpaceItem} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/SpaceItem";
import type {Xyicon3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {DistanceUnitName} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import {THREEUtils} from "../../utils/THREEUtils";
import type {ILayerColumn, ILayerSettings} from "../../ui/modules/space/spaceeditor/ui/viewbar/LayerView";
import type {IGeoLocation} from "../../ui/widgets/input/clicktoedit/datatypes/geolocation/GeoLocationLabel";
import {compareKeyAndRefId, getDefaultCardLayout} from "../../ui/modules/settings/modules/type/form/CardLayoutEditor";
import type {IToolTipRow} from "../../ui/modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {addCommasToMultiSelectFieldValues, CardLayoutToolTip} from "../../ui/modules/space/spaceeditor/ui/toolbar/CardLayoutToolTip";
import {filterModels} from "../models/filter/Filter";
import {textPartsToTextContent} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/TextUtils";
import type {ITextPart, IObjectWithText} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/TextUtils";
import type {Markup3D} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";
import type {EditableSpaceItem} from "../../ui/modules/space/spaceeditor/logic3d/elements3d/EditableSpaceItem";
import type {INotificationParams} from "../../ui/notification/Notification";
import {FilterOperator} from "../models/filter/operator/FilterOperator";
import type {Collection} from "../models/abstract/Collection";
import type {ICrossPortfolioLinkData} from "../../ui/modules/space/spaceeditor/ui/actionbar/CrossPortfolioXyicon";
import {NavigationEnum} from "../../Enums";
import type {PropertyName, spaceItemForProperties} from "../../ui/modules/abstract/sidepanel/tabs/details/field/mass/IMassInput";
import type {CaptionedItem} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/CaptionManager";
import {CaptionManager} from "../../ui/modules/space/spaceeditor/logic3d/managers/MSDF/CaptionManager";
import type {BoundaryManager} from "../../ui/modules/space/spaceeditor/logic3d/managers/spaceitems/BoundaryManager";
import type {XyiconManager} from "../../ui/modules/space/spaceeditor/logic3d/managers/spaceitems/XyiconManager";
import {onWorkspaceViewClick} from "../../ui/5.0/topbar/ViewTabsCommon";
import {inheritedFeatures, loadingDependencyFeatures} from "./AppStateConstants";
import type {AppState} from "./AppState";
import {AppFieldActions} from "./AppFields";
import {FilterActions} from "./FilterActions";

export interface IXyiconLinkObject {
	link: Link;
	object: Xyicon;
}

export interface IFieldPropagation {
	value: string | boolean;
	model: IModel;
}

export interface IXyiconPropertyRequest {
	portfolioID: string;
	spaceID: string;
	list: IXyiconPositionList[] | IXyiconOrientationList[];
}

interface IXyiconPositionList {
	xyiconID: string;
	iconX: number;
	iconY: number;
	iconZ: number;
	linkedBoundarySpaceMapList: string[];
}

interface IXyiconOrientationList {
	xyiconID: string;
	orientation: number;
}

export class AppActions {
	private readonly _appState: AppState;
	public readonly filterActions: FilterActions;

	constructor(appState: AppState) {
		this._appState = appState;
		this.filterActions = new FilterActions(this._appState);
	}

	public getLayout(feature: XyiconFeature) {
		return this._appState.layouts[feature];
	}

	public isFieldAssignedToItem(field: {refId: string}, item: IModel) {
		const fieldObject = this.getFieldByRefId(field.refId);
		const fieldPropagations = this.getFieldPropagations(item, this.getFieldByRefId(field.refId));

		if (fieldPropagations.length > 0 || fieldObject.default) {
			return true;
		}
		const assignedFields = this.getFieldsForType(item.typeId, item.ownFeature).filter((f) => f.value);
		const assignedFieldRefIds = assignedFields.map((f) => this.getFieldById(f.id).refId);

		return assignedFieldRefIds.some((refId) => refId === field.refId);
	}

	public isFieldValidationRuleAvailableInOrganization(feature: XyiconFeature): boolean {
		const supportedOrganizations = ["Prologis"];

		return [XyiconFeature.Boundary, XyiconFeature.Xyicon].includes(feature) && supportedOrganizations.includes(this._appState.organization?.name);
	}

	// Returns all the fields for the item in the parameter that the user can see in the
	// "Fields" section in the details panel, regardless of their values, or if they're inherited or not
	public getAssignedFieldsFromLayoutForItem(item: IModel) {
		const fieldsFromLayout = this.getFieldsFromLayout(item.ownFeature);
		const fields: IFieldAdapter[] = fieldsFromLayout.filter((f) => this.isFieldAssignedToItem(f, item));

		return fields;
	}

	private getFieldsFromLayout(feature: XyiconFeature): IFieldAdapter[] {
		const fields: IFieldAdapter[] = [];

		const layoutSections = this.getLayout(feature)?.sections || [];

		for (const section of layoutSections) {
			for (const field of section.fields) {
				if (!fields.some((f) => f.refId === field.id)) {
					fields.push(this.getFieldByRefId(field.id));
				}
			}
		}

		return fields;
	}

	public getAssignedAndInheritedFieldsFromLayout(type: Type | null, fieldsFeature: XyiconFeature): IFieldAdapter[] {
		if (type) {
			const fieldsFromLayout = this.getFieldsFromLayout(fieldsFeature);
			const unassignedFieldRefIds = this.getFieldsForType(type.id, fieldsFeature)
				.filter((f) => !f.value)
				.map((f) => this.getFieldById(f.id).refId);

			return fieldsFromLayout.filter((f) => !unassignedFieldRefIds.includes(f.refId));
		}

		return [];
	}

	public getAssignedFieldsFromLayoutForItems(items: IModel[]) {
		let assignedFields: IFieldAdapter[] = [];

		for (const item of items) {
			assignedFields.push(...this.getAssignedFieldsFromLayoutForItem(item));
		}
		assignedFields = ArrayUtils.removeDuplicates(assignedFields);

		return assignedFields;
	}

	public getHumanFriendlyFieldValueForItem(item: IModel, field: IFieldAdapter, addPropagatedValuesIfOwnValueIsEmpty: boolean = true): string {
		let fieldValue: string | boolean = "";

		if (this.isFieldAssignedToItem(field, item as Xyicon | BoundarySpaceMap)) {
			fieldValue = this.getFieldValue(item, field.refId);

			if (addPropagatedValuesIfOwnValueIsEmpty && (fieldValue === "" || fieldValue === null || fieldValue === undefined)) {
				fieldValue = this.getFieldPropagations(item, field)
					.map((obj) => obj.value)
					.join("\n");
			}

			// TODO: call actions.renderValue instead?

			if ([FieldDataType.User, FieldDataType.Type, FieldDataType.DateTime].includes(field.dataType)) {
				// Converts guid -> name (eg.: username, space type)
				fieldValue = FieldDataTypes.map[field.dataType].formatter(fieldValue);
			}

			if (Array.isArray(fieldValue)) {
				fieldValue = fieldValue.join("\n");
			}

			if (typeof fieldValue === "boolean") {
				const dataTypeSettings = field.dataTypeSettings as IBooleanFieldSettingsDefinition;

				fieldValue = fieldValue ? dataTypeSettings.displayLabelForTrue : dataTypeSettings.displayLabelForFalse;
			} else if (typeof fieldValue === "number") {
				fieldValue = MathUtils.isValidNumber(fieldValue) ? fieldValue : "";
			} else if (typeof fieldValue === "string") {
				fieldValue = fieldValue.replace(/\n+$/g, ""); // remove the newlines from the end of the string --> This is very important! It can cause various bugs in copy-paste if we don't do this
				fieldValue = fieldValue.replace(/\n/g, "<br>");
			}
		}

		return fieldValue;
	}

	public selectView(view: View) {
		if (this._appState.selectedViewId[view.itemFeature] !== view.id) {
			// Don't select the view again if not needed, as it can trigger some heavy filtering calculations on space items
			// And to make things even worse, it can result in faulty filters, for example in the following scenario:
			// Go to spaceeditor in V5
			// Open the sidegrid for xyicons
			// Apply a filter for xyicons
			// In the sidegrid, click on the "locate" button for a xyicon
			// Note that all the other xyicons have became visible as well, despite they're filtered out
			// It's because the "layer" settings are applied to the scene, but the filters are not, in this case...
			this._appState.selectedViewId[view.itemFeature] = view.id;
			this.selectViewById(view.id);
		}
	}

	public getAllViews() {
		const allViews: View[] = [];

		for (const feature in this._appState.views) {
			allViews.push(...this._appState.views[feature]);
		}

		return allViews;
	}

	public getViews(feature: XyiconFeature) {
		return this._appState.views[feature];
	}

	public selectViewById(viewId: string) {
		const view = this.getViewById(viewId);

		if (view) {
			this._appState.selectedViewId[view.itemFeature] = viewId;
			this.pushToTouchedViews(viewId);
			this._appState.app.transport.services.localStorage.set(
				this._appState.getKeyForLocalStorageView(this._appState.organizationId, view.itemFeature),
				view.id,
			);
		}
		// this needs to be outside the "if" above in case the default view is selected
		this.onViewSelected();
	}

	public getKeyForLocalStorageSelectedPortfolio() {
		return `srv4-org-${this._appState.organizationId}-selectedportfolio`;
	}

	private pushToTouchedViews(viewId: string) {
		const touchedViews = this._appState.touchedViews;
		const index = touchedViews.indexOf(viewId);

		if (index > -1) {
			touchedViews.splice(index, 1);
		}
		touchedViews.push(viewId);
	}

	// opens up the space that contains the item, and zooms to the item defined by its id
	public navigateToSpaceItemById(
		itemId: string,
		feature: XyiconFeature.Xyicon | XyiconFeature.Boundary | XyiconFeature.Markup,
		selectItem: boolean = false,
	) {
		const item = this.getBoundarySpaceMapById(itemId) || (this.getFeatureItemById(itemId, feature) as Xyicon | Boundary | BoundarySpaceMap | Markup);

		if (item) {
			this.navigateToSpaceItem(item, selectItem);
		} else {
			console.warn(`Item not found: ${itemId}`);
		}
	}

	public navigateToSpaceItem(item: Boundary | BoundarySpaceMap | Xyicon | Markup, selectItem: boolean = false) {
		const selectedSpaceEditorView = this.getSelectedView(XyiconFeature.SpaceEditor);
		onWorkspaceViewClick(selectedSpaceEditorView);

		return new Promise<void>((resolve, reject) => {
			const {spaceViewRenderer} = this._appState.app;

			if (item.spaceId === this._appState.space?.id) {
				spaceViewRenderer.toolManager.cameraControls.focusOn(item, selectItem);
				resolve();
			} else {
				const {spaceLoadStarted, spaceLoadReady} = spaceViewRenderer.signals;

				const focusOnItem = () => {
					spaceLoadReady.remove(focusOnItem);
					spaceViewRenderer.toolManager.cameraControls.focusOn(item, selectItem);
					resolve();
				};

				spaceLoadReady.add(focusOnItem);

				let spaceLoadStartedCounter = 0;
				const handleSpaceLoadStart = (spaceId: string) => {
					spaceLoadStartedCounter++;
					if (spaceLoadStartedCounter > 1) {
						spaceLoadStarted.remove(handleSpaceLoadStart);
						spaceLoadReady.remove(focusOnItem);
					}
				};

				spaceLoadStarted.add(handleSpaceLoadStart);
				this._appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, item.spaceId);
			}
		});
	}

	public getCaptionFieldValues(captionFields: IFieldAdapter[], modelData: IModel): ITextPart[] {
		if (!modelData) {
			return [];
		}

		const modelD = (modelData as BoundarySpaceMap).isBoundarySpaceMap ? (modelData as BoundarySpaceMap).parent : modelData;
		const itemType = modelData.ownFeature === XyiconFeature.Boundary ? "boundary" : "xyicon";
		const individualCaptionStyles =
			this.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings.captions[itemType].individualCaptionStyles ?? {};

		return captionFields
			.map((captionField) => ({
				content: this.renderValues(modelD, captionField.refId)
					.flat(2)
					.filter((val) => !!val)
					.toSorted(StringUtils.sortIgnoreCase)
					.join("\n"), // flat(2) -> for inherited multiselect, and things like that
				style: individualCaptionStyles[captionField.refId] ?? {},
			}))
			.filter((textPart) => !!textPart.content);
	}

	public async updateSpaceEditorCaptions(feature: XyiconFeature) {
		const spaceViewRenderer = this._appState.app.spaceViewRenderer;

		if (spaceViewRenderer.isMounted) {
			if (feature === XyiconFeature.Xyicon || feature === XyiconFeature.Boundary) {
				if (feature === XyiconFeature.Boundary) {
					await spaceViewRenderer.boundaryManager.captionManager.updateCaptions();
				}
				// boundary captions can affect xyicons, but not the other way around
				await spaceViewRenderer.xyiconManager.captionManager.updateCaptions();
			}
		}
	}

	public updateSpaceEditorFilterState(filterState?: IFilterState) {
		const spaceViewRenderer = this._appState.app.spaceViewRenderer;

		if (spaceViewRenderer.isMounted) {
			spaceViewRenderer.spaceItemController.updateFilterState(filterState, true);
		}
	}

	public onViewSelected() {
		const spaceViewRenderer = this._appState.app.spaceViewRenderer;

		if (spaceViewRenderer.isMounted) {
			const {xyiconManager, boundaryManager, markupManager} = spaceViewRenderer;

			const spaceEditorViewSettings = this.getSelectedView(XyiconFeature.SpaceEditor).spaceEditorViewSettings;
			const {background} = spaceEditorViewSettings.layers;

			spaceViewRenderer.tileManager.container.visible = !background.isHidden;

			xyiconManager.onFormattingRulesModified();
			xyiconManager.onLayerSettingsModified();

			boundaryManager.onFormattingRulesModified();
			boundaryManager.onLayerSettingsModified();

			markupManager.onLayerSettingsModified();
		}
	}

	public getList<T extends IModel>(feature: XyiconFeature): T[] {
		return this._appState.lists[feature].array;
	}

	public getLinkFromMsdfTextInstanceId(objectWithText: IObjectWithText, instanceId: number): string | null {
		const textContent = textPartsToTextContent(objectWithText.text);
		const matchAll = this.getStartIndexAndContentOfUrlsInText(textContent);
		const visibleCharactersInTextContent = textContent.replace(/\s+/g, ""); // Remove all spaces, new lines, etc

		// Adjust indices for the stripped text
		let lastStartIndex = 0;

		for (const match of matchAll) {
			match.start = visibleCharactersInTextContent.indexOf(match.content, lastStartIndex);
			lastStartIndex = match.start + 1;
		}

		const instanceIdRangeOfText = {
			first: objectWithText.textInstanceIds[0],
			last: objectWithText.textInstanceIds[objectWithText.textInstanceIds.length - 1],
		};
		const indexOfCharacter = instanceId - instanceIdRangeOfText.first;
		const matchAtIndex = matchAll.find((match) => match.start <= indexOfCharacter && indexOfCharacter < match.start + match.content.length);

		if (matchAtIndex) {
			return matchAtIndex.content;
		}

		return null;
	}

	public get URLRegex() {
		return /(((\b(https?):\/\/)|(\bwww\.))[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
	}

	public getStartIndexAndContentOfUrlsInText(text: string): {start: number; content: string}[] {
		const ret: {start: number; content: string}[] = [];

		const urlRegex = this.URLRegex;

		const matchAll = [...text.matchAll(urlRegex)];

		for (const match of matchAll) {
			ret.push({
				start: match.index,
				content: match[0],
			});
		}

		return ret;
	}

	public getURLsInTextInputs(value: any) {
		const regex = this.URLRegex;

		let match = ArrayUtils.removeDuplicates(value?.match?.(regex));
		let final = value;

		match?.forEach((url: string) => {
			const newTabURL = url.includes("//") ? url : `//${url}`;

			final = final.replaceAll(url, `<a href=${newTabURL} target="_BLANK">${url}</a>`);
		});

		return final;
	}

	public async deleteUsers(users: User[], newOwner: User) {
		const transport = this._appState.app.transport;
		const {result, error} = await transport.requestForOrganization<DeletedUsersDto>({
			url: "users/delete",
			method: XHRLoader.METHOD_DELETE,
			params: {
				userIDList: users.map((user) => ({userIDToBeDeleted: user.id, newSharedObjectOwnerUserID: newOwner?.id})),
			},
		});

		const deletedList = result?.deletedUserList.map((item: DeletedUserInfoDto) => item.userID);

		this.checkResultOfDelete(users, deletedList, XyiconFeature.User);

		return {result, error};
	}

	public onDeletePortfolio(portfolioName: string, isActivePortfolio: boolean) {
		const deletePortolfioNotificationParams: INotificationParams = {
			type: NotificationType.Message,
			title: `${portfolioName} Portfolio has been deleted.`,
			lifeTime: Infinity,
			cancelable: false,
			buttonLabel: "Go to Portfolios",
			backdropDisable: true,
			onActionButtonClick: () => this.goToPortfolios(),
			description: "Go to Portfolios will take you to the Portfolios module.",
		};

		if (isActivePortfolio && this._appState.selectedFeature !== XyiconFeature.Portfolio && portfolioName) {
			notify(this._appState.app.notificationContainer, deletePortolfioNotificationParams);
		}
	}

	public goToPortfolios() {
		this._appState.app.navigation.goApp(NavigationEnum.NAV_PORTFOLIOS);
		const portfolioList = this.getList(XyiconFeature.Portfolio);
		const currentView = this.getSelectedView(XyiconFeature.Portfolio);
		const filteredItems = filterModels(portfolioList, currentView.filters, this._appState, XyiconFeature.Portfolio);
		const transport = this._appState.app.transport;

		if (filteredItems.length >= 1) {
			transport.services.auth.switchPortfolio(filteredItems[0].id);
		} else if (portfolioList.length >= 1) {
			transport.services.auth.switchPortfolio(portfolioList[0].id);
		} else {
			transport.appState.actions.clearPortfolioLists();
		}
	}

	public async deleteItems<T extends IModel>(items: T[], feature: XyiconFeature, apiNameAddition: "" | "boundaryspacemaps" = "") {
		const transport = this._appState.app.transport;
		const result = await transport.services.feature.deleteItems(items, feature, apiNameAddition);

		this.checkResultOfDelete(items, result?.deletedList, feature);

		if (apiNameAddition === "boundaryspacemaps") {
			for (const item of items as unknown as BoundarySpaceMap[]) {
				item.parent.removeBoundarySpaceMaps([item]);
			}
		}

		switch (feature) {
			case XyiconFeature.Space:
				{
					const deletedList = result as SpaceDeleteDto[];

					this.onSpacesDeleted(deletedList);
				}
				break;
			case XyiconFeature.SpaceVersion:
				{
					const deletedList = result as SpaceVersionsDeleteDto[];

					const spaceVersionIds = deletedList.map((d) => d.spaceVersionID);

					this.applyDelete(spaceVersionIds, XyiconFeature.SpaceVersion);

					const spaces = this.getList<Space>(XyiconFeature.Space);

					for (const space of spaces) {
						const spaceFilesToKeep = space.spaceFiles.filter((sF) => !spaceVersionIds.includes(sF.spaceVersionId));

						space.applyData({...space.data, spaceFiles: spaceFilesToKeep.map((sF) => sF.data)});
					}
				}
				break;
			case XyiconFeature.Portfolio:
				{
					if (this.getList<Portfolio>(XyiconFeature.Portfolio).length < 1) {
						this.clearPortfolioLists();
					}
				}
				break;
		}

		return result;
	}

	private async checkResultOfDelete<T extends IModel>(items: T[], deletedList: string[], feature: XyiconFeature) {
		if (deletedList) {
			this.applyDelete(deletedList, feature);

			if (items.length !== deletedList.length) {
				const itemsNotDeleted: T[] = [];

				for (const item of items) {
					if (!deletedList.includes(item?.id)) {
						itemsNotDeleted.push(item);
					}
				}

				if (itemsNotDeleted.length > 0) {
					await WarningWindow.open(`${itemsNotDeleted.length} item(s) couldn't be deleted, because they're being used by other components.`);
				}
			}
		}
	}

	public clearPortfolioLists() {
		const portfolioLists = [
			XyiconFeature.Xyicon,
			XyiconFeature.Space,
			XyiconFeature.SpaceVersion,
			XyiconFeature.Markup,
			XyiconFeature.Boundary,
			XyiconFeature.Link,
		];

		for (const feature of portfolioLists) {
			this._appState.lists[feature].clear();
		}

		this._appState.app.transport.services.feature.clearPromises();
		this._appState.portfolioId = "";
	}

	public getUnplottedXyicons() {
		// Unplotted xyicons don't have spaceIds
		return this.getList(XyiconFeature.Xyicon).filter((xyicon: Xyicon) => !xyicon.spaceId && !xyicon.isEmbedded);
	}

	public getCurrentPortfolio() {
		return this.getPortfolioById(this._appState.portfolioId);
	}

	public getCurrentOrganization() {
		return this._appState.organizations.find((organization) => organization.id === this._appState.organizationId);
	}

	public getCurrentPortfolioName() {
		const portfolio = this.getCurrentPortfolio();
		return portfolio ? portfolio.name : "";
	}

	public getCurrentOrganizationOwner() {
		const organization = this.getCurrentOrganization();

		return organization ? this.renderName(organization.ownerUserID) : "";
	}

	public getCurrentOrganizationName() {
		const organization = this.getCurrentOrganization();

		return organization ? organization.name : "";
	}

	public getCurrentOrganizationId() {
		const organization = this.getCurrentOrganization();

		return organization ? organization.id : "";
	}

	public getCurrentOrganizationSettings() {
		const organization = this.getCurrentOrganization();

		if (organization) {
			return organization.settings;
		}
		return null;
	}

	public getBoundarySpaceMapById(id: string) {
		return this._appState.boundarySpaceMaps.getById(id) || null;
	}

	public getFeatureItemById<T extends IModel>(id: string, feature: XyiconFeature): T {
		return this._appState.lists[feature].getById(id);
	}

	public getSpaceById(id: string) {
		return this.getFeatureItemById(id, XyiconFeature.Space) as Space;
	}

	public getPortfolioById(id: string) {
		return this.getFeatureItemById(id, XyiconFeature.Portfolio) as Portfolio;
	}

	public addToList(data: any, feature: XyiconFeature) {
		const item = FeatureService.createModel(data, feature, this._appState);

		if (item) {
			const list = this._appState.lists[feature];

			if (list) {
				list.add(item);
			}
		}

		return item;
	}

	public applyDelete(ids: string[], feature: XyiconFeature) {
		this._appState.lists[feature].deleteByIds(ids);

		for (const id of ids) {
			this._appState.app.transport.signalR.listener.closeValidationRulesAssociatedWithItem(id);
		}
	}

	public onSpacesDeleted(spaceList: SpaceDeleteDto[]) {
		for (const deleteData of spaceList) {
			this.applyDelete(deleteData.boundaryIDList, XyiconFeature.Boundary);
			this.applyDelete(deleteData.xyiconIDList, XyiconFeature.Xyicon);
			this.applyDelete(deleteData.markupIDList, XyiconFeature.Markup);
			this.applyDelete([deleteData.spaceID], XyiconFeature.Space);
		}
	}

	public getSelectedView(feature: XyiconFeature) {
		// Find the selected view
		const selectedViewId = this._appState.selectedViewId[feature];

		if (selectedViewId) {
			const view = this.getViewById(selectedViewId);

			if (view) {
				return view;
			}
		}

		// Pick the All view or the first view
		const views = this._appState.views[feature];

		if (views) {
			const allView = views.find((view) => view.name.indexOf("All") === 0);
			const view = allView || (views.length >= 1 ? views[0] : null);

			if (view) {
				return view;
			}
		}

		// Fall back to the default system view
		let defaultView = this._appState.defaultViews[feature];

		if (!defaultView) {
			this._appState.initAdditionalDefaultViews();
			defaultView = this._appState.defaultViews[feature];
		}

		if (defaultView) {
			return defaultView;
		}
		console.warn("view not found!");
		return null;
	}

	// public getAllFieldsForFeature(feature: XyiconFeature)
	// {
	// 	const appState = this._appState;
	// 	let fields = appState.defaultFields[feature] || [];
	//
	// 	const features = fieldsByFeature[feature];
	// 	for (const feature of features)
	// 	{
	// 		const featureFields = appState.fields[feature];
	// 		if (featureFields)
	// 		{
	// 			fields = fields.concat(featureFields);
	// 		}
	// 	}
	// 	return fields;
	// }

	// public belongsToOtherFeature(refId: IFieldPointer, feature: XyiconFeature)
	// {
	// 	const field = this.getFieldByRefId(refId);
	// 	if (field)
	// 	{
	// 		return field.feature !== feature;
	// 	}
	// 	return false;
	// }

	public isFieldDefault(refId: IFieldPointer) {
		const field = this.getFieldByRefId(refId);

		if (field) {
			return field.default;
		}
		return false;
	}

	public isFieldShownForFeature(field: IFieldAdapter, feature: XyiconFeature) {
		const isOwnField = field.feature === feature;

		if (isOwnField) {
			return true;
		} else {
			return field.displayOnLinks && this.getInheritedFeatures(feature).includes(field.feature);
		}
	}

	public isFieldValidForFeature(field: IFieldAdapter, feature: XyiconFeature) {
		if (!field) {
			// field has been removed since it had been added to the view
			return false;
		}

		// field could have been set to "displayOnLinks = false" since it had been added to the view
		return this.isFieldShownForFeature(field, feature);
	}

	public getDynamicFieldPropagations(item: IModel, field: IFieldAdapter): {value: string; model: IModel}[] {
		if (!field.displayOnLinks || field.default) {
			return null;
		}

		if (item.ownFeature === XyiconFeature.Xyicon && field.feature === XyiconFeature.Xyicon) {
			const values: {value: string; model: IModel}[] = [];

			this.getLinksXyiconXyicon(item.id).forEach((link) => {
				const value = this.getFieldValue(link.object, field.refId);

				if (this.isNonBlankValue(value, field)) {
					values.push({value, model: link.object});
				}
			});

			return values.length ? values : null;
		}

		if (item.ownFeature === XyiconFeature.Xyicon && field.feature === XyiconFeature.Boundary) {
			const values: {value: string; model: IModel}[] = [];

			this.getLinksXyiconBoundary(item.id).forEach((link) => {
				const value = this.getFieldValue(link.object, field.refId);

				if (this.isNonBlankValue(value, field)) {
					values.push({value, model: link.object});
				}
			});

			return values.length ? values : null;
		}

		if (item.ownFeature === XyiconFeature.Boundary && field.feature === XyiconFeature.Boundary) {
			const values: {value: string; model: IModel}[] = [];
			const finalItem: Boundary = ((item as BoundarySpaceMap).isBoundarySpaceMap ? (item as BoundarySpaceMap).parent : item) as Boundary;

			this.getLinksBoundaryBoundary(finalItem.id).forEach((link) => {
				const value = this.getFieldValue(link.object, field.refId);

				if (this.isNonBlankValue(value, field)) {
					values.push({value, model: link.object});
				}
			});

			return values.length ? values : null;
		}

		return null;
	}

	public getFieldRefIdsForType(typeId: string, feature: XyiconFeature) {
		let result: string[] = [];

		if (typeId) {
			const fields = this.getFieldsForType(typeId, feature).filter((field) => field.value);

			if (fields) {
				result = fields
					.map((field) => {
						const f = this.getFieldById(field.id);

						return f ? f.refId : "";
					})
					.filter((f) => !!f);
			}
		}
		return result;
	}

	public getFieldById(id: string) {
		return this._appState.fieldsById[id] || null;
	}

	public getFieldByRefId = (refId: IFieldPointer) => {
		if (!refId || !refId.indexOf) {
			return null;
		}

		return this._appState.fieldsByRef[refId] || null;
	};

	public getCatalogById = (id: string): Catalog | null => {
		if (!id) {
			return null;
		}

		return this._appState.lists[XyiconFeature.XyiconCatalog].getById(id);
	};

	public getRefIdName(feature: XyiconFeature) {
		const refId = AppFieldActions.getRefId(feature, "refId");
		const field = this.getFieldByRefId(refId);

		return field?.name || "ID";
	}

	// public getFieldAdapterByPointer(fieldPointer: IFieldPointer): IFieldAdapter
	// {
	// 	if (!fieldPointer || !fieldPointer.indexOf)
	// 	{
	// 		return null;
	// 	}
	//
	// 	const field = this._appState.fieldsByRef[fieldPointer];
	//
	// 	// TODO optimize
	// 	const isDefault = fieldPointer.indexOf("/") > -1;
	// 	if (!isDefault)
	// 	{
	// 		return this.getFieldByRefId(fieldPointer);
	// 	}
	// 	else
	// 	{
	// 		const feature = Number(fieldPointer.split("/")[0]);
	// 		const defaultFields = this._appState.defaultFields[feature] || [];
	// 		return defaultFields.find(f => f.refId === fieldPointer);
	// 	}
	// }

	// public getFieldAdapterByRefId(fieldRefId: string, feature: XyiconFeature): IFieldAdapter
	// {
	// 	const field = this.getFieldByRefId(fieldRefId);
	// 	if (field)
	// 	{
	// 		return field;
	// 	}
	// 	else
	// 	{
	// 		return (this._appState.defaultFields[feature] || []).find(f => f.refId === fieldRefId);
	// 	}
	// }

	// Same as getFieldValue but also returns propagate field values
	public getFieldValueProp(item: IModel, refId: IFieldPointer): any {
		const field = this.getFieldByRefId(refId);

		if (field) {
			if (item.ownFeature === field.feature) {
				return this.getOwnFieldValue(item, refId);
			}

			const inherited = this.getInheritedFieldValue(item, field);

			if (inherited !== null && inherited !== undefined) {
				return inherited;
			}

			const propagations = this.getDynamicFieldPropagations(item, field);

			if (propagations) {
				const firstPropagation = propagations[0];

				if (firstPropagation) {
					return this.formatValue(firstPropagation.value, field.refId);
				}
			}
		}
	}

	/**
	 * Return can be array, string, object, etc
	 * This is not the formatted string value.
	 */
	public getFieldValue(item: IModel, refId: IFieldPointer): string | null {
		const field = this.getFieldByRefId(refId);

		if (this.isFieldHiddenByMasking(item, field)) {
			return undefined;
		}

		if (field) {
			// Boolean type field's value is undefined when permission is hidden or the toggler was never used,
			// so boolean parser's return value is false (!!param), so permission check for this type is needed to handle hidden field
			if (field.dataType === FieldDataType.Boolean) {
				const isHidden = !this._appState.user?.isAdmin && this.getFieldPermission(field, [item]) < Permission.View;

				if (isHidden) {
					return undefined;
				}
			}
			if (item.ownFeature === field.feature) {
				return this.getOwnFieldValue(item, refId);
			} else {
				return this.getInheritedFieldValue(item, field);
			}
		}
	}

	public hasOwnFieldValue(item: IModel, fieldPointer: IFieldPointer): boolean {
		const field = this.getFieldByRefId(fieldPointer);

		if (field) {
			if (item.ownFeature === field.feature) {
				if (isDefaultField(field)) {
					// not really used as we're only using this function for custom fields (so far)
					return !ObjectUtils.isNullish(item[field.getterName as keyof IModel]);
				} else {
					const fieldRefIds = this.getFieldRefIdsForType(item.typeId, item.ownFeature);

					return fieldRefIds.includes(fieldPointer);

					// Previously:
					// return if there is any non-nullish value in fieldData,
					// but this doesn't work well because fieldData[refId] can be nullish unless
					// its ever edited by a user, and we want to show blank in case the item has
					// editable field values;
					// return !ObjectUtils.isNullish(item.fieldData[field.refId]);
				}
			}
		}
		return false;
	}

	public getOwnFieldValue(item: IModel, fieldPointer: IFieldPointer): string | null {
		const field = this.getFieldByRefId(fieldPointer);

		if (field) {
			if (item.ownFeature === field.feature) {
				if (isDefaultField(field)) {
					return (item[field.getterName as keyof IModel] as string) || "";
				} else {
					const value = item.fieldData[field.refId];

					// Ensure type has this field mapped
					if (!this.isFieldMappedToType((field as Field).id, item.typeId, field.feature)) {
						return null;
					}

					return this.convertValue(value, fieldPointer);
				}
			}
		}
		return null;
	}

	public getTypesByFeature(feature: XyiconFeature) {
		return this._appState.types[feature] || [];
	}

	public getFieldPropagations(item: IModel, field: IFieldAdapter): IFieldPropagation[] {
		const finalItem = (item as BoundarySpaceMap).isBoundarySpaceMap ? (item as BoundarySpaceMap).parent : item;
		const result: IFieldPropagation[] = [];

		const inheritedPropagation = this.getInheritedFieldPropagation(finalItem, field);

		if (inheritedPropagation) {
			result.push(inheritedPropagation);
		}
		const dynamicPropagations = this.getDynamicFieldPropagations(finalItem, field);

		if (dynamicPropagations) {
			result.push(...dynamicPropagations);
		}

		return result;
	}

	// Eg.: finds field value for the Xyicon's Portfolio object, if item is a Xyicon fieldRefId refers to a portfolio field
	private getInheritedFieldValue(item: IModel, field: IFieldAdapter): string | null {
		const inheritedObject = this.findInheritedObject(item, field.feature);

		if (inheritedObject) {
			return this.getFieldValue(inheritedObject, field.refId);
		}
		return null;
	}

	private getInheritedFieldPropagation(item: IModel, field: IFieldAdapter): {value: string | boolean; model: IModel} {
		const inheritedObject = this.findInheritedObject(item, field.feature);

		if (inheritedObject) {
			const value = this.getOwnFieldValue(inheritedObject, field.refId);
			const hasValue = this.isNonBlankValue(value, field);

			if (hasValue) {
				return {
					value,
					model: inheritedObject,
				};
			}
		}
		return null;
	}

	private isNonBlankValue(value: any, field: IFieldAdapter) {
		// Note: we could just check the value itself if it's an array, without checking field.dataType
		// but this allows more refined control based on the field
		if (field.dataType === FieldDataType.SingleChoiceList || field.dataType === FieldDataType.MultipleChoiceList) {
			const count = value?.length;

			return count > 0;
		}
		return !StringUtils.isBlank(value);
	}

	public findInheritedObject(item: IModel, feature: XyiconFeature) {
		if (feature === XyiconFeature.Xyicon || feature === XyiconFeature.XyiconCatalog) {
			if (item.catalogId) {
				return this.getFeatureItemById(item.catalogId, XyiconFeature.XyiconCatalog);
			}
		} else if (feature === XyiconFeature.Portfolio) {
			if (item.portfolioId) {
				return this.getFeatureItemById(item.portfolioId, XyiconFeature.Portfolio);
			}
		} else if (feature === XyiconFeature.Space) {
			if (item.spaceId) {
				return this.getFeatureItemById(item.spaceId, XyiconFeature.Space);
			}
		}
		return null;
	}

	public getBoundariesBySpace(spaceId: string) {
		const boundariesInPortfolio = this.getList(XyiconFeature.Boundary) as Boundary[];

		return boundariesInPortfolio.filter(
			(boundary: Boundary) => !![...boundary.boundarySpaceMaps].find((boundarySpaceMap: BoundarySpaceMap) => boundarySpaceMap.spaceId === spaceId),
		);
	}

	public getXyiconsBySpace(spaceId: string) {
		const xyiconsInPortfolio = this.getList(XyiconFeature.Xyicon) as Xyicon[];

		return xyiconsInPortfolio.filter((xyicon: Xyicon) => xyicon.spaceId === spaceId);
	}

	public getMarkupsBySpace(spaceId: string) {
		const markupsInPortfolio = this.getList(XyiconFeature.Markup); //  all the markups in the portfolio

		return markupsInPortfolio.filter((markup: Markup) => markup.spaceId === spaceId) as Markup[];
	}

	public getCrossPortfolioLinksXyiconXyicon(xyiconId: string): ICrossPortfolioLinkData[] {
		if (!this._appState.user?.isAdmin) {
			return [];
		}

		const result: ICrossPortfolioLinkData[] = [];
		const links = this._appState.lists[XyiconFeature.Link] as Collection<Link>;

		const fromLinks = links.map.fromObjectId?.[xyiconId];
		const toLinks = links.map.toObjectId?.[xyiconId];

		fromLinks?.forEach((link) => {
			if (link.toPortfolioId !== this._appState.portfolioId) {
				result.push({
					link,
					crossPortfolioXyiconId: link.toObjectId,
					otherPortfolioId: link.toPortfolioId,
					otherPortId: link.toPortId,
					onePortId: link.fromPortId,
				});
			}
		});
		toLinks?.forEach((link) => {
			if (link.fromPortfolioId !== this._appState.portfolioId) {
				result.push({
					link,
					crossPortfolioXyiconId: link.fromObjectId,
					otherPortfolioId: link.fromPortfolioId,
					otherPortId: link.fromPortId,
					onePortId: link.toPortId,
				});
			}
		});

		return result;
	}

	// Returns with xyicon-xyicon links from the same portfolio
	public getLinksXyiconXyicon(xyiconId: string): IXyiconLinkObject[] {
		const result: IXyiconLinkObject[] = [];
		const xyicons = this._appState.lists[XyiconFeature.Xyicon] as Collection<Xyicon>;
		const links = this._appState.lists[XyiconFeature.Link] as Collection<Link>;

		const fromLinks = links.map.fromObjectId?.[xyiconId];
		const toLinks = links.map.toObjectId?.[xyiconId];

		fromLinks?.forEach((link) => {
			const otherXyicon = xyicons.getById(link.toObjectId);

			if (otherXyicon) {
				result.push({link, object: otherXyicon});
			}
		});
		toLinks?.forEach((link) => {
			const otherXyicon = xyicons.getById(link.fromObjectId);

			if (otherXyicon) {
				result.push({link, object: otherXyicon});
			}
		});

		return result;
	}

	public getLinksXyiconBoundary(xyiconId: string): {link: Link; object: Boundary}[] {
		const result: {link: Link; object: Boundary}[] = [];
		const boundaryIds = new Set<string>();

		const links = this._appState.lists[XyiconFeature.Link];

		const fromLinks = links.map.fromObjectId?.[xyiconId];
		const toLinks = links.map.toObjectId?.[xyiconId];

		fromLinks?.forEach((link) => {
			// we're searching for the boundaries that match that
			const boundary = this.findBoundaryForBoundarySpaceMap(link.toObjectId);

			if (boundary && !boundaryIds.has(boundary.id)) {
				boundaryIds.add(boundary.id);
				result.push({link, object: boundary});
			}
		});
		toLinks?.forEach((link) => {
			// we're searching for the boundaries that match that
			const boundary = this.findBoundaryForBoundarySpaceMap(link.fromObjectId);

			if (boundary && !boundaryIds.has(boundary.id)) {
				boundaryIds.add(boundary.id);
				result.push({link, object: boundary});
			}
		});

		return result;
	}

	public getLinksXyiconsForBoundary(boundaryId: string): {link: Link; object: Xyicon}[] {
		const result: IXyiconLinkObject[] = [];
		const xyiconIds = new Set<string>();

		const boundary = this.getFeatureItemById<Boundary>(boundaryId, XyiconFeature.Boundary);

		if (boundary) {
			const links = this._appState.lists[XyiconFeature.Link];
			const xyicons = this._appState.lists[XyiconFeature.Xyicon];

			boundary.boundarySpaceMaps.forEach((boundarySpaceMap) => {
				const fromLinks = links.map.fromObjectId?.[boundarySpaceMap.id];
				const toLinks = links.map.toObjectId?.[boundarySpaceMap.id];

				fromLinks?.forEach((link) => {
					const xyicon = xyicons.getById(link.toObjectId);

					if (xyicon && !xyiconIds.has(xyicon.id)) {
						xyiconIds.add(boundary.id);
						result.push({link, object: xyicon});
					}
				});
				toLinks?.forEach((link) => {
					const xyicon = xyicons.getById(link.fromObjectId);

					if (xyicon && !xyiconIds.has(xyicon.id)) {
						xyiconIds.add(boundary.id);
						result.push({link, object: xyicon});
					}
				});
			});
		}

		return result;
	}

	public getLinksBoundaryBoundary(boundaryId: string, bidirectional: boolean = false): {link: Link; object: Boundary}[] {
		const result: {link: Link; object: Boundary}[] = [];
		const boundaryIds = new Set<string>();

		const boundaryA = this.getFeatureItemById<Boundary>(boundaryId, XyiconFeature.Boundary);
		const boundarySpaceMapIds = [...(boundaryA?.boundarySpaceMaps ?? [])].map((bsm: BoundarySpaceMap) => bsm.id);

		if (boundarySpaceMapIds) {
			for (const boundarySpaceMapId of boundarySpaceMapIds) {
				if (bidirectional) {
					const fromLinks = this._appState.lists[XyiconFeature.Link].map.fromObjectId?.[boundarySpaceMapId];

					fromLinks?.forEach((link) => {
						const boundary = this.findBoundaryForBoundarySpaceMap(link.toObjectId);

						if (boundary) {
							if (!boundaryIds.has(boundary.id)) {
								boundaryIds.add(boundary.id);
								result.push({link, object: boundary});
							}
						}
					});
				}

				const toLinks = this._appState.lists[XyiconFeature.Link].map.toObjectId?.[boundarySpaceMapId];

				toLinks?.forEach((link) => {
					const boundary = this.findBoundaryForBoundarySpaceMap(link.fromObjectId);

					if (boundary) {
						if (!boundaryIds.has(boundary.id)) {
							boundaryIds.add(boundary.id);
							result.push({link, object: boundary});
						}
					}
				});
			}
		}

		return result;
	}

	private findBoundaryForBoundarySpaceMap(boundarySpaceMapId: string): Boundary {
		const boundarySpaceMap = this._appState.boundarySpaceMaps.getById(boundarySpaceMapId);

		return boundarySpaceMap?.parent || null;
	}

	public renderValue(item: IModel, refId: IFieldPointer): string {
		const value = this.getFieldValue(item, refId);

		// Decision: if value is null / undefined:
		// should we return "" or let this.formatValue decide? (eg. boolean)

		const formattedValue = this.formatValue(value, refId);

		if (Array.isArray(formattedValue)) {
			return formattedValue.join("\n");
		} else {
			return formattedValue;
		}
	}

	public renderValues(item: IModel, refId: IFieldPointer): any[] {
		// is it always string[] ?
		const field = this.getFieldByRefId(refId);
		const values = this.getValueForFormatting(item, field);

		return values.map((value) => this.formatValue(value, field.refId));
	}

	public formatValue(value: any, fieldPointer: IFieldPointer): string | string[] {
		const field = this.getFieldByRefId(fieldPointer);

		if (field && field.dataType) {
			const fieldDataTypeConfig = FieldDataTypes.map[field.dataType];

			if (fieldDataTypeConfig) {
				const formatter = fieldDataTypeConfig.formatter;

				if (formatter) {
					return formatter(value, field.dataTypeSettings) || "";
				}
			}
		}

		return value ?? "";
	}

	public formatValueByDataType(value: any, dataType: FieldDataType, dataTypeSettings?: any) {
		const fieldDataTypeConfig = FieldDataTypes.map[dataType];

		if (fieldDataTypeConfig) {
			const formatter = fieldDataTypeConfig.formatter;

			if (formatter) {
				return formatter(value, dataTypeSettings) || "";
			}
		}

		return value ?? "";
	}

	private convertValue(value: string, fieldPointer: IFieldPointer) {
		const field = this.getFieldByRefId(fieldPointer);

		if (field) {
			const fieldDataType = FieldDataTypes.map[field.dataType];

			if (fieldDataType) {
				const converter = fieldDataType.converter;

				if (converter) {
					return converter(value);
				}
			}
		}
		return value ?? "";
	}

	private getValueForFormatting(model: IModel, field: IFieldAdapter) {
		const ownValue = this.getOwnFieldValue(model, field.refId);
		const propagations = this.getFieldPropagations(model, field);

		return [ownValue, ...propagations.map((v) => v.value)];
	}

	public getFormattingColor(model: IModel, fieldRefId: IFieldPointer): string | null {
		const field = this.getFieldByRefId(fieldRefId) as Field;

		if (field) {
			const rules = field.formattingRules;

			if (rules) {
				const values = this.getValueForFormatting(model, field);

				for (let i = 0; i < rules.length; ++i) {
					const rule = rules[i];
					const config: IFilterOperatorConfig = FilterOperators.map[rule.operator];

					for (const value of values) {
						const result = config.method(value, rule.params, field);

						if (result) {
							return rule.color;
						}
					}
				}
			}
		}

		// Nor matching rule found
		return null;
	}

	private updateFieldValuesForItem(item: IModel, fieldData: {[refId: string]: any}) {
		for (const refId in fieldData) {
			item.fieldData[refId] = fieldData[refId];
		}
	}

	private getCurrentFieldValuesForItem(item: IModel, fieldData: {[refId: string]: any}) {
		const itemFieldData: {[refId: string]: any} = {};

		for (const refId in fieldData) {
			itemFieldData[refId] = item.fieldData[refId];
		}

		return itemFieldData;
	}

	public async updateFields(items: IModel[], fieldData: {[refId: string]: any}): Promise<IResponse> {
		const previousFieldValues: {[refId: string]: any}[] = [];

		const filteredItems = items.filter((item, index) => {
			previousFieldValues[index] = this.getCurrentFieldValuesForItem(item, fieldData);
			this.updateFieldValuesForItem(item, fieldData);

			const newFieldValue = this.getCurrentFieldValuesForItem(item, fieldData);
			const hasChanged = JSON.stringify(previousFieldValues[index]) !== JSON.stringify(newFieldValue);

			if (hasChanged) {
				for (const refId in fieldData) {
					const {validationRuleFailedNotifications} = this._appState.app.transport.signalR.listener;
					const notificationKey = this._appState.itemFieldUpdateManager.getKeyForItemFieldUpdate(item.id, refId, item.ownFeature);

					if (validationRuleFailedNotifications[notificationKey]) {
						validationRuleFailedNotifications[notificationKey].onClose();
						delete validationRuleFailedNotifications[notificationKey];
					}

					this._appState.itemFieldUpdateManager.addItemFieldUpdateToUpdateList(item.id, refId, item.ownFeature);
				}
			}

			return hasChanged;
		});

		if (filteredItems.length > 0) {
			const response = await this._appState.app.transport.services.feature.updateMassFields(filteredItems, fieldData);

			const {result, error} = response;

			if (result) {
				// Is it possible that result.fieldValues !== fieldData?
				filteredItems.forEach((item) => {
					if (result.fieldValues) {
						this.updateFieldValuesForItem(item, result.fieldValues);
					}
					item.applyUpdate?.(result);
					this.updateSpaceEditorCaptions(item.ownFeature);
					this.updateConditionalFormattingForFeature(item.ownFeature);
					this.updateSpaceEditorFilterState();

					for (const refId in fieldData) {
						const field = this.getFieldByRefId(refId);

						// The ones with validation need to be handled with the help of SignalR
						if (field && !field.hasValidation) {
							this._appState.itemFieldUpdateManager.removeItemFieldUpdateFromUpdateList(item.id, refId, item.ownFeature);
						}
					}
				});
			}
			if (error) {
				filteredItems.forEach((item, index) => {
					this.updateFieldValuesForItem(item, previousFieldValues[index]);
				});
			}

			return response;
		}

		return {
			result: "",
			error: null,
		};
	}

	public async updateMassFields(items: IModel[], fieldData: {[refId: string]: any}) {
		runInAction(() => {
			for (const item of items) {
				this.updateFieldValuesForItem(item, fieldData);
			}
		});

		let isSomeBoundary = false;
		let isSomeXyicon = false;

		const response = await this._appState.app.transport.services.feature.updateMassFields(items, fieldData);
		const {result, error} = response;

		if (result) {
			runInAction(() => {
				for (const item of items) {
					if (item.ownFeature === XyiconFeature.Boundary) {
						isSomeBoundary = true;
					} else if (item.ownFeature === XyiconFeature.Xyicon) {
						isSomeXyicon = true;
					}
					item.applyUpdate?.(result);
				}
			});

			if (isSomeBoundary) {
				this.updateSpaceEditorCaptions(XyiconFeature.Boundary);
				this.updateConditionalFormattingForFeature(XyiconFeature.Boundary);
			}
			if (isSomeXyicon) {
				// if we called it with XyiconFeature.Boundary above, there's no need to update it again,
				// because it updates the xyicon captions as well,
				// because boundary fields can cascade down to xyicons
				if (!isSomeBoundary) {
					this.updateSpaceEditorCaptions(XyiconFeature.Xyicon);
				}
				this.updateConditionalFormattingForFeature(XyiconFeature.Xyicon);
			}
		}

		return response;
	}

	public updateProperties(value: number, propName: PropertyName, spaceItems: SpaceItem[], distanceUnit: DistanceUnitName) {
		const {spaceViewRenderer} = this._appState.app;
		const notificationParams = {
			title: "The object you are editing is not in the field of view.",
			description: "Click Locate to bring your object into focus.",
			type: NotificationType.Message,
			buttonLabel: "Locate",
			lifeTime: Infinity,
		};

		const boundarySpaceMap3Ds: BoundarySpaceMap3D[] = [];
		const xyicon3Ds: Xyicon3D[] = [];
		const markup3Ds: Markup3D[] = [];

		if ((propName === "pz" && value < 0) || isNaN(value)) {
			value = 0;
		}

		spaceItems.forEach((spaceItem) => {
			const model = spaceItem.modelData as Xyicon;

			if (spaceItem.spaceItemType === "xyicon") {
				if (propName === "o") {
					// change rotation
					const degree = 360 - value; // value is given CW, but we need CCW
					const rad = MathUtils.clampRadianBetween0And2PI(degree * MathUtils.DEG2RAD);

					model.setOrientation(rad);
				} else if (["px", "py", "pz"].includes(propName)) {
					// change position
					const correctedValue = MathUtils.convertDistanceToSpaceUnit(value, distanceUnit, model.space.spaceUnitsPerMeter);

					model.setPosition(
						propName === "px" ? correctedValue : model.iconX,
						propName === "py" ? correctedValue : model.iconY,
						propName === "pz" ? correctedValue : model.iconZ,
					);

					if (!spaceViewRenderer.toolManager.cameraControls.isPointVisibleForCamera(model.position)) {
						notify(this._appState.app.notificationContainer, {
							...notificationParams,
							onActionButtonClick: () => this.navigateToSpaceItemById(model.id, model.ownFeature),
						});
					}
				}

				spaceItem.updateByModel(model as IEditableItemModel & Xyicon);
				xyicon3Ds.push(spaceItem as Xyicon3D);
			} else if (spaceItem.spaceItemType === "boundary") {
				// Boundaries
				const model = (spaceItem.modelData as BoundarySpaceMap).parent;
				const boundarySpaceMap = spaceItem.modelData as BoundarySpaceMap;

				if (propName === "o") {
					// change rotation
					const degree = 360 - value; // value is given CW, but we need CCW
					const rad = MathUtils.clampRadianBetween0And2PI(degree * MathUtils.DEG2RAD);

					const newGeometryData = THREEUtils.getRotatedVertices(boundarySpaceMap.geometryData, rad - boundarySpaceMap.orientation);

					boundarySpaceMap.setGeometryData(newGeometryData);
					boundarySpaceMap.setOrientation(rad);
				} else if (["px", "py", "pz"].includes(propName)) {
					// change position
					const correctedValue = MathUtils.convertDistanceToSpaceUnit(value, distanceUnit, model.space.spaceUnitsPerMeter);

					boundarySpaceMap.setPosition({
						x: propName === "px" ? correctedValue : boundarySpaceMap.positionX,
						y: propName === "py" ? correctedValue : boundarySpaceMap.positionY,
					});

					if (!spaceViewRenderer.toolManager.cameraControls.isPointVisibleForCamera(boundarySpaceMap.position)) {
						notify(this._appState.app.notificationContainer, {
							...notificationParams,
							onActionButtonClick: () => this.navigateToSpaceItemById(boundarySpaceMap.id, boundarySpaceMap.ownFeature),
						});
					}
				} // change dimension
				else {
					// if dimension was set to a negative value, it will return with the original width / height
					if (value > 0) {
						const correctedValue = MathUtils.convertDistanceToSpaceUnit(value, distanceUnit, model.space.spaceUnitsPerMeter);

						const dimension = boundarySpaceMap.dimension;

						boundarySpaceMap.setDimensions({
							x: propName === "dx" ? correctedValue : dimension.x,
							y: propName === "dy" ? correctedValue : dimension.y,
						});
					}
				}

				if (spaceItem) {
					(spaceItem as BoundarySpaceMap3D).updateByModel(boundarySpaceMap as IEditableItemModel & BoundarySpaceMap);
					boundarySpaceMap3Ds.push(spaceItem as BoundarySpaceMap3D);
				}
			} else {
				// Markup
				const model = spaceItem.modelData as Markup;

				if (propName === "o") {
					// change rotation
					const degree = 360 - value; // value is given CW, but we need CCW
					const rad = MathUtils.clampRadianBetween0And2PI(degree * MathUtils.DEG2RAD);

					const newGeometryData = THREEUtils.getRotatedVertices(model.geometryData, rad - model.orientation);

					// Callout markup needs this to change the point of the arrow
					if (model.type === MarkupType.Callout) {
						const newTargetData = THREEUtils.getRotatedVertex(model.target, rad - model.orientation, model.position);

						model.setTarget(newTargetData);
					}

					model.setGeometryData(newGeometryData);
					model.setOrientation(rad);
				} else if (["px", "py", "pz"].includes(propName)) {
					// change position
					const correctedValue = MathUtils.convertDistanceToSpaceUnit(value, distanceUnit, model.space.spaceUnitsPerMeter);
					const oldModelPosition = ObjectUtils.deepClone(model.position);
					const oldModelTarget = ObjectUtils.deepClone(model.target);

					model.setPosition({
						x: propName === "px" ? correctedValue : model.positionX,
						y: propName === "py" ? correctedValue : model.positionY,
					});

					// Callout markup needs this to change the point of the arrow
					if (model.type === MarkupType.Callout) {
						const deltaX = model.position.x - oldModelPosition.x;
						const deltaY = model.position.y - oldModelPosition.y;

						const newTargetX = oldModelTarget.x + deltaX;
						const newTargetY = oldModelTarget.y + deltaY;

						model.setTarget({x: newTargetX, y: newTargetY});
					}

					if (!spaceViewRenderer.toolManager.cameraControls.isPointVisibleForCamera(model.position)) {
						notify(this._appState.app.notificationContainer, {
							...notificationParams,
							onActionButtonClick: () => this.navigateToSpaceItemById(model.id, model.ownFeature),
						});
					}
				}

				(spaceItem as Markup3D).updateByModel(model as EditableSpaceItem & Markup);
				markup3Ds.push(spaceItem as Markup3D);
			}
		});

		const promises = [
			spaceViewRenderer.boundaryManager.updateItems(boundarySpaceMap3Ds, true),
			spaceViewRenderer.xyiconManager.updateItems(xyicon3Ds, true),
			spaceViewRenderer.markupManager.updateItems(markup3Ds, true),
		];

		const {spaceItemController} = spaceViewRenderer;

		if (["px", "py", "pz"].includes(propName)) {
			spaceItemController.rotationIconManager.clear();
		}

		spaceItemController.updateActionBar();
		spaceItemController.updateBoundingBox();
		spaceItemController.rotationIconManager.updateTransformations();
		spaceItemController.boundaryManager.captionManager.updateCaptions();
		spaceItemController.xyiconManager.captionManager.updateCaptions();

		return Promise.all(promises);
	}

	public formatPropValue(value: number, propName: PropertyName, item: spaceItemForProperties, distanceUnit: DistanceUnitName) {
		if (propName === "o") {
			// saved orientation is CCW, but we need CW value
			return MathUtils.clampDegreeBetween0And360(360 - value * MathUtils.RAD2DEG);
		} else {
			const spaceUnitsPerMeter = (item.modelData as Xyicon | Boundary).space?.spaceUnitsPerMeter;
			const retVal = spaceUnitsPerMeter ? MathUtils.convertDistanceFromSpaceUnit(value, distanceUnit, spaceUnitsPerMeter) : 0;

			return MathUtils.setPrecision(retVal, 2);
		}
	}

	public async updateXyiconsModel(params: UpdateXyiconsModelRequest) {
		const response = await this._appState.app.transport.services.feature.updateXyiconModel(params);
		const {result, error} = response;

		if (result) {
			for (const data of result) {
				const xyicon = this.getFeatureItemById<Xyicon>(data.xyiconID, XyiconFeature.Xyicon);

				xyicon?.applyData(data);
			}
		}
		return response;
	}

	public async updateReport(report: Report) {
		const {result, error} = await this._appState.app.transport.services.feature.updateReport(report);

		if (result) {
			report.applyData(result);
		}
		return {result, error};
	}

	public updateConditionalFormattingForFeature(feature: XyiconFeature) {
		const {spaceViewRenderer} = this._appState.app;

		if (spaceViewRenderer.isMounted) {
			spaceViewRenderer.getItemManager(feature)?.onFormattingRulesModified();
		}
	}

	public getFieldTitle(fieldPointer: IFieldPointer) {
		const field = this.getFieldByRefId(fieldPointer);

		if (field) {
			return field.name || "";
		}
		return "";
	}

	// TODO Is this a good place here?
	public getBooleanFieldTitle(value: boolean) {
		return value ? "Yes" : "No";
	}

	public getValueFromPropagation(item: IModel, columnRefId: IFieldPointer) {
		const field = this.getFieldByRefId(columnRefId);

		if (field) {
			const propagations = this.getDynamicFieldPropagations(item, field);

			if (propagations) {
				const result = propagations.map((propagation) => this.formatValue(propagation.value, field.refId));

				// Add first element to the beginning
				const ownValue = this.renderValue(item, field.refId);

				if (!StringUtils.isBlank(ownValue)) {
					result.unshift(ownValue);
				}

				return result;
			}
		}

		return null;
	}

	private getFilteredInheritedArray(row: IModel, columnRefId: IFieldPointer) {
		return ([this.getValueFromPropagation(row, columnRefId)].flat(Infinity) as string[]).filter((v) => v != null);
	}

	private getSortValue(row1: IModel, row2: IModel, columnRefId: IFieldPointer) {
		const field = this.getFieldByRefId(columnRefId);

		if (!field) {
			return ["", ""];
		}
		const value1 = this.getFilteredInheritedArray(row1, columnRefId);
		const value2 = this.getFilteredInheritedArray(row2, columnRefId);
		const length1 = value1.length;
		const length2 = value2.length;

		if (length1 !== 0 || length2 !== 0) {
			const inheritedArray1 = value1;
			const inheritedArray2 = value2;

			const minArrayLength = Math.min(inheritedArray1.length, inheritedArray2.length);

			if (length2 !== 0 && length1 !== 0) {
				for (let i = 0; i < minArrayLength; i++) {
					if (inheritedArray1[i] !== inheritedArray2[i]) {
						return [inheritedArray1[i], inheritedArray2[i]];
					}
				}
				// if every checked element is the same until the min(length1,length2), then sort by the length
				return [inheritedArray1.length, inheritedArray2.length];
			} else if (length2 === 0) {
				return [inheritedArray1[0], this.getFieldValue(row2, columnRefId)];
			}
			return [this.getFieldValue(row1, columnRefId), inheritedArray2[0]];
		}

		// numeric or date or time dataType, compare them as they saved
		if ([FieldDataType.Numeric, FieldDataType.DateTime].includes(field.dataType)) {
			return [this.getFieldValue(row1, columnRefId), this.getFieldValue(row2, columnRefId)];
		}
		// if the dataType is IP, then split the IP address and compare the sections as numbers
		if (field.dataType === FieldDataType.IPAddress) {
			const ip1 = this.getFieldValue(row1, columnRefId);
			const ip2 = this.getFieldValue(row2, columnRefId);

			// if the datatype is IP and inherited DOL, the value can be null, so can't split
			if (ip1 && ip2) {
				const ip1SplitAsIPV4 = ip1.split(".");
				const ip2SplitAsIPV4 = ip2.split(".");

				if (ip1SplitAsIPV4.length > 1 && ip2SplitAsIPV4.length > 1) {
					for (let i = 0; i < ip1SplitAsIPV4.length; i++) {
						if (ip1SplitAsIPV4[i] !== ip2SplitAsIPV4[i]) {
							return [parseInt(ip1SplitAsIPV4[i]), parseInt(ip2SplitAsIPV4[i])];
						}
					}
				}
			}
			return [ip1, ip2];
		}
		// if the dataType is GeoLocation, the value is an object, compare the 'lat' or the 'long' prop
		if (field.dataType === FieldDataType.GeoLocation) {
			const geoLoc1 = this.getFieldValue(row1, columnRefId) as unknown as IGeoLocation;
			const geoLoc2 = this.getFieldValue(row2, columnRefId) as unknown as IGeoLocation;

			return geoLoc1.lat !== geoLoc2.lat ? [geoLoc1.lat, geoLoc2.lat] : [geoLoc1.long, geoLoc2.long];
		}
		return [this.renderValue(row1, columnRefId), this.renderValue(row2, columnRefId)];
	}

	public getRowsForCardLayout(queryString: string, model: IModel, disableCustomRows: boolean = false) {
		const item = model as Boundary | Xyicon | Catalog | BoundarySpaceMap | Space;

		const type = this.getTypeById(item.typeId);
		const cardLayout = type?.settings.cardLayout?.[item.ownFeature] || getDefaultCardLayout(item.ownFeature);

		const rows: IToolTipRow[] = [];

		rows.push(CardLayoutToolTip.getKeyAndValue(cardLayout["row 1"], this, item, true));
		rows.push(CardLayoutToolTip.getKeyAndValue(cardLayout["row 2"], this, item, true));
		rows.push(CardLayoutToolTip.getKeyAndValue(cardLayout["row 3"], this, item, true));

		if (disableCustomRows) {
			return rows;
		}

		// remove rows from customRows
		let customRows = this.searchModelCustomFields(item, queryString, item.ownFeature).filter((cRow) =>
			rows.every((ogRow) => !compareKeyAndRefId(ogRow.key, cRow.refId) || ogRow.value !== this.renderValue(item, cRow.refId)),
		);

		rows[0].key = "";

		// Replace the 2nd and 3rd hardcoded Row with searched custom Rows (if there are any)
		if (customRows[0]) {
			if (!rows[1].value.toLowerCase().includes(queryString)) {
				rows[1] = {
					key: customRows[0].name,
					value: addCommasToMultiSelectFieldValues(this.renderValue(item, customRows[0].refId), customRows[0]),
				};
			} else {
				rows[1].key = "";
			}

			if (customRows[1] && !rows[2].value.toLowerCase().includes(queryString)) {
				rows[2] = {
					key: customRows[1].name,
					value: addCommasToMultiSelectFieldValues(this.renderValue(item, customRows[0].refId), customRows[0]),
				};
			} else {
				// if customRows.length === 1, rows[1] and rows[2] can be the same field's data
				if (rows[1].key === rows[2].key && rows[1].value === rows[2].value) {
					rows[2].value = "";
				}

				rows[2].key = "";
			}
		} else {
			rows[1].key = "";
			rows[2].key = "";
		}

		return rows;
	}

	public sortItems<T extends IModel>(items: T[], view: View) {
		let sortedItems: T[] = [...items];
		const columnFields = view.getAllColumns(items[0]?.ownFeature).map((c) => c.field);
		const sorts = (view?.sorts || []).filter((s) => columnFields.includes(s.column));

		for (let i = sorts.length - 1; i >= 0; i--) {
			const sort = sorts[i];

			if (sort.column) {
				const idColumns = AppFieldActions.isRefId(sort.column);

				if (idColumns) {
					// Handle id columns by removing prefix
					// id column: "f13" -> 13
					sortedItems = sortedItems.slice().sort((row1, row2) => {
						const value1 = StringUtils.refId2Number(this.renderValue(row1, sort.column));
						const value2 = StringUtils.refId2Number(this.renderValue(row2, sort.column));

						return sort.direction * compareTableValues(value1, value2);
					});
				} else {
					sortedItems = sortedItems.slice().sort((row1, row2) => {
						const [value1, value2] = this.getSortValue(row1, row2, sort.column);

						return sort.direction * compareTableValues(value1, value2);
					});
				}
			}
		}
		return sortedItems;
	}

	public getSortsForFeature = (view: View, feature: XyiconFeature): IViewSort[] => {
		if (view) {
			const sorts = view.sorts;
			let columns: IViewColumn[] = [];
			const allColumns = view.getColumnsToCompareWithoutWidth();

			if (sorts && feature) {
				if (view.itemFeature === XyiconFeature.SpaceEditor) {
					columns = allColumns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary] as IViewColumn[];
				} else {
					columns = allColumns as IViewColumn[];
				}

				return sorts.filter((s) => {
					const field = this.getFieldByRefId(s.column);

					return columns?.find((col) => col?.field === field?.refId);
				});
			}
		}
		return [];
	};

	private isFieldMappedToType(fieldId: string, typeId: string, feature: XyiconFeature) {
		const appState = this._appState;

		const mapping = appState.typeFieldMapping[feature];
		const mappingForType = mapping[typeId];

		if (mappingForType) {
			return mappingForType.has(fieldId);
		}
		return false;
	}

	private _typesAndFieldsCache: {
		[key: string]: {
			[hash: string]: {id: string; label: string; value: boolean}[];
		};
	} = {};

	public getFieldsForType(typeId: string, feature: XyiconFeature, forceRefresh = false) {
		if (!typeId) {
			return [];
		}

		const type = this.getTypeById(typeId);

		if (!type) {
			console.warn(`Type with id ${typeId} doesn't exist. Please report this to the developers.`);
			return [];
		}

		const appState = this._appState;
		const mapping = appState.typeFieldMapping[feature];
		const mappingForType = mapping[typeId];
		const lookupLinkFieldsIds = type.settings?.xyiconLookupLinkFieldList || [];
		const featureFields = appState.fields[feature];

		// The key is used to decide whether we already have a cached version
		const key = `${typeId}_${feature}`;
		// The hash is used to make sure we're recalculating the array if the data changes
		const hash = `_${mappingForType?.size ?? 0}_${lookupLinkFieldsIds.length}_${featureFields.length}_${appState.lists[feature].getVersion()}`;

		if (!this._typesAndFieldsCache[key] || forceRefresh || Object.keys(this._typesAndFieldsCache[key])[0] !== hash) {
			this._typesAndFieldsCache[key] = {};

			const fields = featureFields
				.filter((field) => !lookupLinkFieldsIds.includes(field.id))
				.map((field) => {
					const value = mappingForType ? mappingForType.has(field.id) : false;

					return {
						id: field.id,
						label: field.name,
						value: value,
					};
				});

			// Lookup fields
			const lookupFields = featureFields
				.filter((field) => lookupLinkFieldsIds.includes(field.id) && fields.every((f) => f.id !== field.id))
				.map((field) => ({
					id: field.id,
					label: field.name,
					value: true,
				}));

			fields.push(...lookupFields);
			// End of lookup fields

			this._typesAndFieldsCache[key][hash] = fields.sort((fieldA, fieldB) => StringUtils.sortIgnoreCase(fieldA.label, fieldB.label));
		}

		return this._typesAndFieldsCache[key][hash];
	}

	public getFieldsByFeature(sourceFeature: XyiconFeature, inherited = false): IFieldAdapter[] {
		const result: IFieldAdapter[] = [];
		const features = inherited ? this.getInheritedFeatures(sourceFeature) : [sourceFeature];

		for (const feature of features) {
			const defaultFields = (this._appState.defaultFields[feature] || []).filter((field) => field.feature === sourceFeature || field.displayOnLinks);

			result.push(...defaultFields);
			result.push(...(this._appState.fields[feature] || []).filter((field) => sourceFeature === feature || field.displayOnLinks));
		}
		return result;
	}

	public getFieldByName(sourceFeature: XyiconFeature, fieldName: string, inherited: boolean = false): IFieldAdapter {
		return this.getFieldsByFeature(sourceFeature, inherited).find((f) => f.name === fieldName && f.feature === sourceFeature);
	}

	public getInheritedFeatures(feature: XyiconFeature) {
		return inheritedFeatures[feature] || [feature];
	}

	// Returns the features for a given feature that may need to be refreshed
	// as the param feature is using data from them.
	// Used in ModuleView.refreshList
	public getLoadingDependencies(feature: XyiconFeature) {
		return loadingDependencyFeatures[feature] || [feature];
	}

	public getFieldsByFeatures(sourceFeatures: XyiconFeature[], inherited = false): IFieldAdapter[] {
		const result: IFieldAdapter[] = [];

		for (const feature of sourceFeatures) {
			result.push(...this.getFieldsByFeature(feature, inherited));
		}

		return result;
	}

	public getTypesForField(field: FieldModel, typesFeature: XyiconFeature) {
		const appState = this._appState;
		//const typesFeature = typesFeatures[field.feature] || field.feature;

		const featureTypes = appState.types[typesFeature];
		const mapping = appState.typeFieldMapping[field.feature];

		const types = featureTypes.map((type) => {
			const mappingForType = mapping[type.id];
			const value = mappingForType ? mappingForType.has(field.id) : false;

			return {
				id: type.id,
				label: type.name,
				value: value,
			};
		});

		return types.sort((typeA, typeB) => StringUtils.sortIgnoreCase(typeA.label, typeB.label));
	}

	public getTypeById(id: string): Type | null {
		return this._appState.typesById[id] || null;
	}

	public getTypeName(typeId: string): string {
		return this.getTypeById(typeId)?.name || "";
	}

	public getViewById(id: string) {
		return this._appState.viewsMap[id] || null;
	}

	// Cached search

	private readonly _modelCache = new Map<IModel, string[]>();
	private _modelCacheVersion = -1;
	private getModelVersions() {
		let result = 0;

		[
			XyiconFeature.Portfolio,
			XyiconFeature.XyiconCatalog,
			XyiconFeature.Xyicon,
			XyiconFeature.Space,
			XyiconFeature.Boundary,
			XyiconFeature.Markup,
			XyiconFeature.Link,
			XyiconFeature.User,
			XyiconFeature.SpaceVersion,
		].forEach((version) => {
			result += this._appState.lists[version].getVersion();
		});

		return result;
	}

	public areModelsCached() {
		return this._modelCacheVersion === this.getModelVersions();
	}

	public searchModelsCached<T extends IModel>(models: T[], findString: string, feature: XyiconFeature, limit = Infinity): T[] {
		if (!findString) {
			return models;
		}

		const modelVersions = this.getModelVersions();

		if (modelVersions > this._modelCacheVersion) {
			// cache is outdated -> clear it
			this._modelCache.clear();
		}
		this._modelCacheVersion = modelVersions;

		// const t = window.performance.now();

		const result: T[] = [];

		findString = findString.toLowerCase();
		const fields = this.getFieldsByFeature(feature, true).filter((field) => !field.refId.includes("icon"));

		outer: for (let i = 0; i < models.length; ++i) {
			const model = models[i];

			const cache = this._modelCache.get(model);

			if (cache) {
				if (cache.some((value) => value.includes(findString))) {
					result.push(model);
				}
			} else {
				const cache = [] as string[];

				this._modelCache.set(model, cache);

				let modelAdded = false;

				for (const field of fields) {
					// TODO skip if model.fieldData doesn't contain anything
					let values = this.renderValues(model, field.refId) as string[];

					// values looks like this in case of multiselect: [["a", "b"]]
					if (values.flat) {
						values = values.flat();
					}

					for (const value of values) {
						const lowerCaseValue = value?.toLowerCase?.();

						if (lowerCaseValue) {
							cache.push(lowerCaseValue);
						}
					}

					for (const value of values) {
						if (value !== undefined && value.toLowerCase && value.toLowerCase().includes(findString)) {
							if (!modelAdded) {
								result.push(model);
							}

							if (--limit === 0) {
								break outer;
							}
							modelAdded = true;
							//continue outer;
						}
					}
				}
			}
		}

		// console.log("search", window.performance.now() - t);

		return result;
	}

	public searchModels<T extends IModel>(models: T[], findString: string, feature: XyiconFeature, limit = Infinity): T[] {
		if (!findString) {
			return models;
		}

		const result: T[] = [];

		findString = findString.toLowerCase();
		const fields = this.getFieldsByFeature(feature, true);

		outer: for (let i = 0; i < models.length; ++i) {
			const model = models[i];

			for (const field of fields) {
				// TODO skip if model.fieldData doesn't contain anything
				let values = this.renderValues(model, field.refId);

				// values looks like this in case of multiselect: [["a", "b"]]
				if (values.flat) {
					values = values.flat();
				}

				for (const value of values) {
					if (value !== undefined && value.toLowerCase && value.toLowerCase().includes(findString)) {
						result.push(model);

						if (--limit === 0) {
							break outer;
						}
						continue outer;
					}
				}
			}
		}

		return result;
	}

	public isFieldHiddenByMasking(item: IModel, field: IFieldAdapter) {
		if (field) {
			// catalog only exists if item is Xyicon or Catalog
			const catalog = this.getCatalogById((item as Xyicon).catalogId || item.id);
			const isVisible =
				field.feature === XyiconFeature.Xyicon
					? catalog?.xyiconVisibleFields.includes(field.refId)
					: catalog?.catalogVisibleFields.includes(field.refId);

			// hidden: xyicon/catalog + assigned by model + selectSlider is unassigned
			return catalog && field.isAssignedByModel && !isVisible;
		}
		return true;
	}

	public searchModelCustomFields(model: IModel, findString: string, feature: XyiconFeature): IFieldAdapter[] {
		const result: IFieldAdapter[] = [];

		if (!findString) {
			return result;
		}

		const fields = this.getFieldsByFeature(feature, true).filter((f) => f.name !== "Icon");

		for (const field of fields) {
			const isFieldHidden = this.isFieldHiddenByMasking(model, field);
			const value = field && isFieldHidden ? "" : this.renderValue(model, field.refId);

			if (value !== undefined && value.toLowerCase && value.toLowerCase().includes(findString.toLowerCase())) {
				result.push(field);
			}
		}

		return result;
	}

	public renderInitials(userId: string) {
		const user = this.findUser(userId);

		if (user) {
			return FormatUtils.formatInitials(user);
		}
		return "";
	}

	public findUser(userId: string): User {
		const user = this.getFeatureItemById<User>(userId, XyiconFeature.User);

		if (!user) {
			return this.getFeatureItemById<ExternalUser>(userId, XyiconFeature.ExternalUser);
		}

		return user;
	}

	public findUserGroup(userGroupId: string): UserGroup {
		return this.getFeatureItemById<UserGroup>(userGroupId, XyiconFeature.UserGroup);
	}

	public renderName(userId: string) {
		const user = this.findUser(userId);

		if (user) {
			return user.fullName;
		}
		return "";
	}

	public isNameValidForView(value: string, feature: XyiconFeature, viewId?: string) {
		if (!value.trim()) {
			return false;
		}

		return this.getViews(feature).every((v: View) => v.id === viewId || !StringUtils.equalsIgnoreCase(v.name, value));
	}

	public isPortfolioNameValid(name: string, id?: string) {
		if (!name.trim()) {
			return false;
		}

		const portfolios = this.getList(XyiconFeature.Portfolio);

		return !portfolios.some((p) => p && p.id !== id && StringUtils.equalsIgnoreCase(p.name, name));
	}

	public isVersionSetNameValid(name: string, id: string) {
		if (!name.trim()) {
			return false;
		}

		const versions = this.getList(XyiconFeature.SpaceVersion);

		return !versions.some((v) => v && v.id !== id && StringUtils.equalsIgnoreCase(v.name, name));
	}

	public isModelValidForCatalog(value: string, catalog: Catalog) {
		if (!value) {
			return false;
		}

		return this.getList<Catalog>(XyiconFeature.XyiconCatalog).every(
			(c: Catalog) => c.id === catalog?.id || !StringUtils.equalsIgnoreCase(c.model, value),
		);
	}

	public getIndividualPortfolioPermissionSet(portfolioId: string): PermissionSet | null {
		const user = this._appState.user;

		if (!user) {
			return null;
		}

		const portfolioPermission = user.portfolioPermissionList.find((permission) => permission.portfolioID === portfolioId);
		const permissionSetId = portfolioPermission?.portfolioPermissionSetID;
		return this.getFeatureItemById<PermissionSet>(permissionSetId, XyiconFeature.PermissionSet);
	}

	public getPortfolioGroupPermissionSets(portfolioId: string): PermissionSet[] {
		const permissionSets = [] as PermissionSet[];
		const user = this._appState.user;

		if (!user) {
			return [];
		}

		user.portfolioGroupPermissionList.forEach((portfolioGroupPermission) => {
			const permissionSet = this.getFeatureItemById<PermissionSet>(portfolioGroupPermission.portfolioPermissionSetID, XyiconFeature.PermissionSet);
			const group = this.getFeatureItemById<PortfolioGroup>(portfolioGroupPermission.portfolioGroupID, XyiconFeature.PortfolioGroup);

			if (permissionSet && group.portfolioIds.includes(portfolioId)) {
				permissionSets.push(permissionSet);
			}
		});

		return permissionSets;
	}

	public getPortfolioPermission(portfolioId: string): Permission {
		const user = this._appState.user;

		if (!user) {
			return Permission.None;
		}

		if (user.isAdmin) {
			return Permission.Delete;
		}

		let permission = Permission.None;

		const individualPermissionSet = this.getIndividualPortfolioPermissionSet(portfolioId);

		if (individualPermissionSet) {
			permission = individualPermissionSet.portfolioPermission;
		} else {
			const groupPermissionSet = this.getPortfolioGroupPermissionSets(portfolioId)[0];

			permission = groupPermissionSet?.portfolioPermission;
		}

		return permission;
	}

	public getModuleTypePermission(typeId: string, feature: XyiconFeature) {
		if (!this._appState.user) {
			return Permission.None;
		}

		if (this._appState.user.isAdmin) {
			return Permission.Delete;
		}

		let typePermission: Permission = Permission.None;

		const portfolioId = this._appState.portfolioId;

		const individualPermissionSet = this.getIndividualPortfolioPermissionSet(portfolioId);

		if (individualPermissionSet) {
			const featurePermission = individualPermissionSet.featurePermissions.find((fP) => fP.feature === feature);

			typePermission = individualPermissionSet.getObjectPermission(typeId, feature);
			if (featurePermission && !featurePermission.canAccess) {
				typePermission = typePermission > Permission.None ? Permission.View : Permission.None;
			}
		} else {
			const groupPermissionSet = this.getPortfolioGroupPermissionSets(portfolioId)[0];

			if (groupPermissionSet) {
				const featurePermission = groupPermissionSet.featurePermissions.find((fP) => fP.feature === feature);
				const permission = groupPermissionSet.getObjectPermission(typeId, feature);

				if (featurePermission && !featurePermission.canAccess) {
					typePermission = permission > Permission.None ? Permission.View : Permission.None;
				}
				typePermission = permission;
			}
		}

		return typePermission;
	}

	// checks all Types in a Feature, if any of them has the given permission, returns true
	public hasAnyTypeTheGivenPermissionInModule(feature: XyiconFeature, permission: Permission) {
		if (!this._appState.user) {
			return false;
		}

		if (this._appState.user.isAdmin) {
			return true;
		}

		const portfolioId = this._appState.portfolioId;

		let hasPermission: boolean;

		const individualPermissionSet = this.getIndividualPortfolioPermissionSet(portfolioId);

		if (individualPermissionSet) {
			const featurePermission = individualPermissionSet.featurePermissions.find((fP) => fP.feature === feature);

			hasPermission = featurePermission?.objectPermissions.some((oP) => oP.permission >= permission);

			if (featurePermission && !featurePermission.canAccess) {
				hasPermission = permission === Permission.View;
			}
		} else {
			const groupPermissionSet = this.getPortfolioGroupPermissionSets(portfolioId)[0];

			if (groupPermissionSet) {
				const featurePermission = groupPermissionSet.featurePermissions.find((fP) => fP.feature === feature);

				hasPermission = featurePermission?.objectPermissions.some((oP) => oP.permission >= permission);

				if (featurePermission && !featurePermission.canAccess) {
					hasPermission = permission === Permission.View;
				}
			}
		}

		return hasPermission;
	}

	private getSpaceItemHaveGivenPermission(spaceItem: SpaceItem, permission: Permission) {
		if (["xyicon", "boundary"].includes(spaceItem.spaceItemType)) {
			const item = spaceItem.modelData;

			return this.getModuleTypePermission(item.typeId, item.ownFeature) >= permission;
		} else {
			return true;
		}
	}

	public someSpaceItemsHaveGivenPermission(items: SpaceItem[], permission: Permission): boolean {
		return items.some((spaceItem: SpaceItem) => this.getSpaceItemHaveGivenPermission(spaceItem, permission));
	}

	public filterSpaceItemsForPermission(items: SpaceItem[], permission: Permission): SpaceItem[] {
		return items.filter((spaceItem: SpaceItem) => this.getSpaceItemHaveGivenPermission(spaceItem, permission));
	}

	public getNumberOfSpaceItemsWithoutPermission = (items: SpaceItem[], permission: Permission) => {
		let xyiconsWithNoPermissionCount = 0;

		items.forEach((spaceItem: SpaceItem) => {
			if (["xyicon", "boundary"].includes(spaceItem?.spaceItemType)) {
				const item = spaceItem.modelData;

				if (item && this.getModuleTypePermission(item.typeId, item.ownFeature) < permission) {
					xyiconsWithNoPermissionCount++;
				}
			}
		});

		return xyiconsWithNoPermissionCount;
	};

	/**
	 * Returns the field's permission on the added item (by type). If more items were added, the highest permission will be returned.
	 * Individual portfolio permission gets priority over groups. If more group permissions are existing, the highest permission will be returned.
	 * Includes the admin right check (returns Delete if the user is admin). Checks if the user is undefined or not.
	 *
	 * @param   field  IFieldAdapter type (not only the refId, but the Field).
	 * @param   items  [item] or items as array.
	 * @returns Returns a Permission (None, View, Update, Delete)
	 */
	public getFieldPermission(field: IFieldAdapter, items?: IModel[]): Permission {
		if (!this._appState.user) {
			return Permission.None;
		}

		if (this._appState.user.isAdmin) {
			return Permission.Delete;
		}

		const {feature, refId} = field;

		const portfolioID = this._appState.portfolioId;
		const groupPermissionSet = this.getPortfolioGroupPermissionSets(portfolioID)[0];
		const individualPermissionSet = this.getIndividualPortfolioPermissionSet(portfolioID);

		let permission = Permission.None;

		if (feature === XyiconFeature.Portfolio) {
			if (items.length > 0) {
				const allPortfolioPermission = items.map((portfolio) => {
					const pGroupPermissionSet = this.getPortfolioGroupPermissionSets(portfolio.id)[0];
					const pIndividualPermissionSet = this.getIndividualPortfolioPermissionSet(portfolio.id);

					let portfolioPermission = Permission.None;

					if (pIndividualPermissionSet) {
						const generalPermission = pIndividualPermissionSet.portfolioPermission;
						const fieldPermission = pIndividualPermissionSet.getPortfolioFieldPermission(refId);

						portfolioPermission = Math.min(fieldPermission, generalPermission);
					} else {
						if (pGroupPermissionSet) {
							const generalP = pGroupPermissionSet.portfolioPermission;
							const fieldP = pGroupPermissionSet.getPortfolioFieldPermission(refId);

							portfolioPermission = Math.min(generalP, fieldP);
						}
					}

					return portfolioPermission;
				});

				permission = Math.max(...allPortfolioPermission);
			} else {
				if (individualPermissionSet) {
					permission = individualPermissionSet.getPortfolioFieldPermission(refId);
				} else {
					if (groupPermissionSet) {
						permission = groupPermissionSet.getPortfolioFieldPermission(refId);
					}
				}
			}

			// Note that GeneralPermissions override field permissions
			// https://web.microsoftstream.com/video/0461a4a5-586f-48e0-9011-2ed2a91b4243?st=1288
		} else if (feature === XyiconFeature.XyiconCatalog) {
			return this._appState.user.getOrganizationPermission(XyiconFeature.XyiconCatalog);
		} else {
			if (items?.length > 0) {
				const allItemPermission = items.map((item) => {
					let itemPermission = Permission.None;

					// when an item gets inherited DOL field from another module
					// we should display it disabled
					if (field.displayOnLinks && field.feature !== item.ownFeature) {
						return Permission.View;
					}

					if (individualPermissionSet) {
						const typePermission = individualPermissionSet.getObjectPermission(item.typeId, feature);
						const fieldPermission = individualPermissionSet.getFieldPermission(refId, feature);

						itemPermission = Math.min(typePermission, fieldPermission);
					} else {
						if (groupPermissionSet) {
							const typePermission = groupPermissionSet.getObjectPermission(item.typeId, feature);
							const fieldPermission = groupPermissionSet.getFieldPermission(refId, feature);

							itemPermission = Math.min(typePermission, fieldPermission);
						}
					}

					return itemPermission;
				});

				permission = Math.max(...allItemPermission);
			} else {
				if (individualPermissionSet) {
					permission = individualPermissionSet.getFieldPermission(refId, feature);
				} else {
					if (groupPermissionSet) {
						permission = groupPermissionSet.getFieldPermission(refId, feature);
					}
				}
			}
		}

		return permission;
	}

	public deleteSpaceItemsFromActiveSpace(spaceItemIdsToDelete: string[], feature: XyiconFeature) {
		const spaceViewRenderer = this._appState.app.spaceViewRenderer;

		if (this._appState.space?.id) {
			const itemManager = spaceViewRenderer.getItemManager(feature);

			let linksNeedUpdate = false;
			const deletedModels: IModel[] = [];
			const deletedSpaceItems: SpaceItem[] = [];
			const unselectedItems: SpaceItem[] = [];

			for (const spaceItemId of spaceItemIdsToDelete) {
				const spaceItem = itemManager.getItemById(spaceItemId);

				if (spaceItem) {
					if (spaceItem.spaceItemType === "xyicon") {
						if ((spaceItem as Xyicon3D).linkInstanceId > -1) {
							linksNeedUpdate = true;
						}
					}

					deletedSpaceItems.push(spaceItem);
					deletedModels.push(spaceItem.modelData);
					if (spaceItem.isSelected) {
						spaceItem.deselect();
						unselectedItems.push(spaceItem);
					}
				}
			}

			if ([XyiconFeature.Xyicon, XyiconFeature.Boundary].includes(feature)) {
				(itemManager as XyiconManager | BoundaryManager).captionManager.hideTextGroup(
					(deletedSpaceItems as CaptionedItem[]).filter(CaptionManager.filterVisibleCaptionedItems).map((item) => item.caption),
					true,
				);
			}

			for (const spaceItem of deletedSpaceItems) {
				spaceItem.destroy(false);
			}

			if (unselectedItems.length > 0) {
				spaceViewRenderer.spaceItemController.updateActionBar(false, true);
			}

			if (deletedModels.length > 0) {
				itemManager.signals.itemsRemove.dispatch(deletedModels);
			}

			if (linksNeedUpdate) {
				spaceViewRenderer.spaceItemController.linkIconManager.update();
			}

			itemManager.updateSelectionBox();
			spaceViewRenderer.spaceItemController.updateBoundingBox();
			spaceViewRenderer.spaceItemController.updateFilterState();
		}
	}

	public async mergeBoundaries(parentBoundary: Boundary, childBoundaries: Boundary[], showNotification: boolean = true) {
		if (!parentBoundary || childBoundaries?.length < 1) {
			console.warn("Attempted to merge invalid boundary data. This shouldn't have happened...");
			return;
		}

		const boundarySpaceMapsToMerge: BoundarySpaceMap[] = [];
		let firstChildBoundaryRefId: string = null;

		for (const childBoundary of childBoundaries) {
			const childBoundarySpaceMaps = [...childBoundary.boundarySpaceMaps];

			boundarySpaceMapsToMerge.push(...childBoundarySpaceMaps);

			for (const boundarySpaceMap of childBoundarySpaceMaps) {
				if (!firstChildBoundaryRefId) {
					firstChildBoundaryRefId = boundarySpaceMap.refId;
				}
			}
		}

		const params: MergeBoundaryRequest = {
			childBoundarySpaceMapIDList: boundarySpaceMapsToMerge.map((boundarySpaceMap: BoundarySpaceMap) => boundarySpaceMap.id),
			parentBoundaryID: parentBoundary.id,
			portfolioID: this._appState.portfolioId,
		};

		const {result, error} = await this._appState.app.transport.requestForOrganization<MergedBoundaryDto>({
			url: "boundaries/merge",
			method: XHRLoader.METHOD_POST,
			params,
		});

		if (error) {
			notify(this._appState.app.notificationContainer, {
				title: "Error",
				type: NotificationType.Error,
				description: "Boundaries couldn't be merged. Please refresh your browser, and try again.",
			});
		} else {
			// Re-add merged boundaries, so they're refreshed correctly
			// (boundary type, height, color, etc is much easier and safer to update this way)
			// this.onBoundariesMerged(result);
			// SignalR is also called for the entity that has triggered it, so we shouldn't call "onBoundariesMerged" again!
		}

		const isMultipleChild = boundarySpaceMapsToMerge.length > 1;

		if (showNotification) {
			notify(this._appState.app.notificationContainer, {
				title: "Boundaries merged successfully",
				description: `${isMultipleChild ? `${boundarySpaceMapsToMerge.length} boundaries were` : `${firstChildBoundaryRefId} was`} merged to ${parentBoundary.refId}`,
				type: NotificationType.Success,
			});
		}
	}

	public isTypeNameValid(name: string, type: Type) {
		if (!name.trim()) {
			return false;
		}

		const otherFieldsWithSameName = this._appState.types[type.feature].find((t) => t.id !== type.id && StringUtils.equalsIgnoreCase(t.name, name));

		return !otherFieldsWithSameName;
	}

	public isFieldNameValid(name: string, field: FieldModel) {
		if (!name.trim()) {
			return false;
		}

		const otherFieldsWithSameName = this._appState.fields[field.feature].find((f) => f.id !== field.id && StringUtils.equalsIgnoreCase(f.name, name));

		return !otherFieldsWithSameName;
	}

	public isSpaceNameValid(name: string, space: Space) {
		if (!name.trim()) {
			return false;
		}

		const spaces = this._appState.lists[XyiconFeature.Space].array;

		return !spaces.some((s) => s && s.id !== space.id && StringUtils.equalsIgnoreCase(s.name, name));
	}

	public isUserGroupNameValid(name: string, userGroup: UserGroup) {
		if (!name.trim()) {
			return false;
		}

		const userGroups = this._appState.lists[XyiconFeature.UserGroup].array;

		return !userGroups.some((uG) => uG && uG.id !== userGroup.id && StringUtils.equalsIgnoreCase(uG.name, name));
	}

	public isPortfolioGroupNameValid(name: string, portfolioGroup: PortfolioGroup) {
		if (!name.trim()) {
			return false;
		}

		const portfolioGroups = this._appState.lists[XyiconFeature.PortfolioGroup].array;

		return !portfolioGroups.some((pG) => pG && pG.id !== portfolioGroup.id && StringUtils.equalsIgnoreCase(pG.name, name));
	}

	public isPermissionSetNameValid(name: string, id: string) {
		if (!name.trim()) {
			return false;
		}

		const permissionSets = this._appState.lists[XyiconFeature.PermissionSet].array;

		return !permissionSets.some((pS) => pS && pS.id !== id && StringUtils.equalsIgnoreCase(pS.name, name));
	}

	public doesXyiconHaveLinkIcon = (xyicon: Xyicon): boolean => {
		return (
			this.getLinksXyiconXyicon(xyicon.id).filter((l) => !l.link.isEmbedded).length + this.getCrossPortfolioLinksXyiconXyicon(xyicon.id).length > 0
		);
	};

	// Adding embedded xyicons and boundaryspacemaps to the result
	public getNumberOfModels(items: IModel[]) {
		let numberOfAffectedItems = 0;

		for (const item of items) {
			if (item.ownFeature === XyiconFeature.Xyicon) {
				const embeddedXyicons = (item as Xyicon).embeddedXyicons;
				const embeddedXyiconsThatAreNotSelected = embeddedXyicons.filter((x) => !items.includes(x as IModel));

				numberOfAffectedItems += 1 + embeddedXyiconsThatAreNotSelected.length;
			} else if ((item as Boundary).isBoundary) {
				numberOfAffectedItems += (item as Boundary).boundarySpaceMaps.size;
			} else {
				++numberOfAffectedItems;
			}
		}

		return numberOfAffectedItems;
	}

	// Adding embedded xyicons to the result
	public getNumberOfSpaceItems(items: SpaceItem[]) {
		let numberOfEmbeddedXyicons = 0;

		for (const item of items) {
			if (item.spaceItemType === "xyicon") {
				const xyicon = item.modelData as Xyicon;

				numberOfEmbeddedXyicons += xyicon.embeddedXyicons.length;
			}
		}

		return items.length + numberOfEmbeddedXyicons;
	}

	public async deleteSpaceItem(item: IModel) {
		const {xyiconManager} = this._appState.app.spaceViewRenderer;
		const xyicon3D = xyiconManager.getItemById(item.id) as Xyicon3D;

		if (xyicon3D) {
			xyicon3D.destroy(false);
		}

		await this.deleteItems([item], item.ownFeature);

		if (item.ownFeature === XyiconFeature.Xyicon) {
			const parentXyicon = (item as Xyicon).parentXyicon;

			if (parentXyicon) {
				const parentXyicon3D = xyiconManager.getItemById(parentXyicon.id) as Xyicon3D;

				if (parentXyicon3D) {
					parentXyicon3D.updateCounter();
				}
			}
		}
	}

	public getDefaultFormattingRuleSet(feature: XyiconFeature): IFormattingRuleSet {
		const types = this.getTypesByFeature(feature);

		const indicator: {
			[key: string]: string;
		} = {};

		const highlight: {
			[key: string]: string;
		} = {};

		for (const type of types) {
			indicator[type.name] = null;
			highlight[type.name] = null;
		}

		return {
			enabled: true,
			indicator: indicator,
			highlight: highlight,
		};
	}

	public getDefaultLayerSettings(feature: XyiconFeature): ILayerSettings {
		if (feature === XyiconFeature.Markup) {
			const defaultHiddenValue = Constants.DEFAULT_VALUE_FOR_INCLUDED_HIDE;
			const defaultLockedValue = Constants.DEFAULT_VALUE_FOR_INCLUDED_POSITION_LOCK;

			const defaultProps = {
				isHidden: defaultHiddenValue,
				isPositionLocked: defaultLockedValue,
			};

			return {
				included: {
					[MarkupType.Cloud]: {
						title: MarkupType[MarkupType.Cloud],
						...defaultProps,
					},
					[MarkupType.Arrow]: {
						title: MarkupType[MarkupType.Arrow],
						...defaultProps,
					},
					[MarkupType.Bidirectional_Arrow]: {
						title: MarkupType[MarkupType.Bidirectional_Arrow],
						...defaultProps,
					},
					[MarkupType.Line]: {
						title: MarkupType[MarkupType.Line],
						...defaultProps,
					},
					[MarkupType.Dashed_Line]: {
						title: MarkupType[MarkupType.Dashed_Line],
						...defaultProps,
					},
					[MarkupType.Pencil_Drawing]: {
						title: MarkupType[MarkupType.Pencil_Drawing],
						...defaultProps,
					},
					[MarkupType.Highlight_Drawing]: {
						title: MarkupType[MarkupType.Highlight_Drawing],
						...defaultProps,
					},
					[MarkupType.Text_Box]: {
						title: MarkupType[MarkupType.Text_Box],
						...defaultProps,
					},
					[MarkupType.Rectangle]: {
						title: MarkupType[MarkupType.Rectangle],
						...defaultProps,
					},
					[MarkupType.Ellipse]: {
						title: MarkupType[MarkupType.Ellipse],
						...defaultProps,
					},
					[MarkupType.Triangle]: {
						title: MarkupType[MarkupType.Triangle],
						...defaultProps,
					},
					[MarkupType.Cross]: {
						title: MarkupType[MarkupType.Cross],
						...defaultProps,
					},
					[MarkupType.Linear_Distance]: {
						title: MarkupType[MarkupType.Linear_Distance],
						...defaultProps,
					},
					[MarkupType.Rectangle_Area]: {
						title: MarkupType[MarkupType.Rectangle_Area],
						...defaultProps,
					},
					[MarkupType.Nonlinear_Distance]: {
						title: MarkupType[MarkupType.Nonlinear_Distance],
						...defaultProps,
					},
					[MarkupType.Irregular_Area]: {
						title: MarkupType[MarkupType.Irregular_Area],
						...defaultProps,
					},
					// [MarkupType.Stamp]: {
					// 	title: MarkupType[MarkupType.Stamp],
					// 	...defaultProps
					// },
					// [MarkupType.Photo]: {
					// 	title: MarkupType[MarkupType.Photo],
					//  ...defaultProps
					// }
					[MarkupType.Callout]: {
						title: MarkupType[MarkupType.Callout],
						...defaultProps,
					},
				},
			};
		} else {
			const types = this.getTypesByFeature(feature);

			const defaultValueForIncludedLock = Constants.DEFAULT_VALUE_FOR_INCLUDED_POSITION_LOCK;
			const defaultValueForIncludedHide = Constants.DEFAULT_VALUE_FOR_INCLUDED_HIDE;

			const included: ILayerColumn = {};

			for (const type of types) {
				included[type.name] = {
					title: type.name,
					isHidden: defaultValueForIncludedHide,
					isPositionLocked: defaultValueForIncludedLock,
				};
			}

			return {
				included: included,
			};
		}
	}

	public getDefaultCaptionSettings(): ICaptionSettings {
		return {
			checkList: [],
			individualCaptionStyles: {},
			fontColor: {
				hex: "000000",
				transparency: 0,
			},
			backgroundColor: {
				hex: "ECEFF0",
				transparency: 0,
			},
			fontSize: Constants.SIZE.FONT.default,
			fontFamily: "Roboto",
			isBold: true,
			isItalic: false,
			isUnderlined: false,
		};
	}

	public getBooleanFieldCustomLabelSettingsByReportSortDirection(field: IFieldAdapter, direction: ReportSortDirection): string {
		if (field.dataType !== FieldDataType.Boolean) {
			return direction;
		} else {
			const booleanLabels = Object.values(field.dataTypeSettings) as string[];
			const sortedBooleanLabels = booleanLabels.sort((a: string, b: string) => StringUtils.sortIgnoreCase(a, b));

			return ReportSortDirection.ASC === direction ? sortedBooleanLabels[0] : sortedBooleanLabels[1];
		}
	}

	public getBooleanFieldCustomLabelSettingsByFilterOperator(field: IFieldAdapter, filterOperator: FilterOperator): string {
		if (field.dataType !== FieldDataType.Boolean) {
			return filterOperator;
		} else {
			const operatorIsTrue = filterOperator === FilterOperator.IS_TRUE;
			const settings = field.dataTypeSettings;

			return operatorIsTrue ? settings.displayLabelForTrue : settings.displayLabelForFalse;
		}
	}
}

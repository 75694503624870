import * as React from "react";
import {inject, observer} from "mobx-react";
import * as ReactDOM from "react-dom";
import {FocusLoss} from "../../../utils/ui/focus/FocusLoss";
import type {IContextOption} from "../context/ContextOptions";
import {ContextOptions} from "../context/ContextOptions";
import type {TransformObj} from "../../../utils/dom/DomUtils";
import {DomUtils} from "../../../utils/dom/DomUtils";
import {DomPortal} from "../../modules/abstract/portal/DomPortal";
import {TimeUtils} from "../../../utils/TimeUtils";
import type {App} from "../../../App";

interface ITableHeaderDropDownProps {
	readonly open: boolean;
	readonly onOpenChange: (openValue: boolean, hoverValue?: boolean) => void;
	readonly dropdownOptions: IContextOption[];
	readonly app?: App;
}

interface ITableHeaderDropDownState {
	transform: TransformObj;
}

type ManageColumnType = "manage" | "hide";
export type ManageColumnParam = {
	type: ManageColumnType;
	index: number;
};
export type ManageColumnCallback = (param: ManageColumnParam) => void;

@inject("app")
@observer
export class TableHeaderDropDown extends React.Component<ITableHeaderDropDownProps, ITableHeaderDropDownState> {
	private readonly _container = React.createRef<HTMLDivElement>();
	private readonly _contextOptions = React.createRef<HTMLDivElement>();

	constructor(props: ITableHeaderDropDownProps) {
		super(props);
		this.state = {
			transform: null,
		};
	}

	private onArrowClick = async (event: React.MouseEvent) => {
		// Stop column sort toggle
		event.stopPropagation();

		this.props.onOpenChange(!this.props.open);

		await TimeUtils.wait(100);
		FocusLoss.listen(this._container.current, this.onFocusLoss);
	};

	public override componentWillUnmount(): void {
		FocusLoss.stopListen(this._container.current, this.onFocusLoss);
	}

	private onFocusLoss = (event?: MouseEvent) => {
		if (!(event.target instanceof Element) || !this.props.app.modalContainer.contains(ReactDOM.findDOMNode(event.target))) {
			this.onClose();
		} else {
			FocusLoss.listen(this._container.current, this.onFocusLoss);
		}
	};

	private onClose = () => {
		this.props.onOpenChange(false, false);
	};

	private onSelectAnyOption = () => {
		this.props.onOpenChange(false, false);
	};

	public override componentDidUpdate(prevProps: ITableHeaderDropDownProps) {
		if (!prevProps.open && this.props.open && this._container.current && this._contextOptions.current) {
			this.setState({transform: DomUtils.getFixedFloatingElementPosition(this._container.current, this._contextOptions.current)});
		}
	}

	public override render() {
		const {open, app, dropdownOptions} = this.props;
		const {transform} = this.state;
		const inlineStyle: React.CSSProperties = {
			transform: transform?.translate,
			position: "fixed",
			zIndex: 8000,
		};

		return (
			<div
				ref={this._container}
				className="TableHeaderDropDown"
				onClick={this.onArrowClick}
			>
				<span
					ref={this._contextOptions}
					className="icon"
				/>
				{open && (
					<DomPortal destination={app.modalContainer}>
						<ContextOptions
							style={inlineStyle}
							className="ModuleDropDown ContextOptions"
							onSelect={this.onSelectAnyOption}
							options={dropdownOptions}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

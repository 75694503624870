import * as React from "react";
import {inject} from "mobx-react";
import {flushSync} from "react-dom";
import {SVGIcon} from "../../button/SVGIcon";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import {MathUtils} from "../../../../utils/math/MathUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import type {INumericFieldSettingsDefinition} from "../../../../data/models/field/datatypes/Numeric";
import {InfoBubble} from "../../../modules/abstract/common/infobutton/InfoBubble";
import {ReactUtils} from "../../../utils/ReactUtils";
import type {AppState} from "../../../../data/state/AppState";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../modules/abstract/portal/DomPortal";
import {ObjectUtils} from "../../../../utils/data/ObjectUtils";

interface INumberInputProps {
	readonly min?: number;
	readonly max?: number;
	readonly value?: number;
	readonly onInput?: (value: number) => void;
	readonly onChange?: (value: number) => void;
	readonly decimals?: number;
	readonly step?: number;
	readonly disabled?: boolean;
	readonly className?: string;
	readonly autoFocus?: boolean;
	readonly dataTypeSettings?: INumericFieldSettingsDefinition;
	readonly noButtons?: boolean;
	readonly inline?: boolean;
	readonly caretPosition?: number;
	readonly appState?: AppState;
}

interface INumberInputState {
	stringValue: string;
	propsValue: number;
	editingValue: string;
	errorMessage: string;
	toolTipTransform: TransformObj;
}

@inject("appState")
export class NumberInput extends React.Component<INumberInputProps, INumberInputState> {
	public static readonly defaultProps: INumberInputProps = {
		decimals: 2,
		step: 0.1,
		className: "number",
	};

	private _requestAnimationFrameId: number = -1;
	private _ref = React.createRef<HTMLInputElement>();
	private _floating = React.createRef<HTMLDivElement>();
	private _toolTip = React.createRef<HTMLDivElement>();

	private _lastValidNumber: number;
	private _isValueChanged = false;
	private _isEscPressed = false;
	private _lastClickedElement: HTMLElement = null;

	public static getDerivedStateFromProps(props: INumberInputProps, state: INumberInputState) {
		// if props.value changed from its previous value -> update state.value
		if (props.value !== state.propsValue) {
			const value = Number(props.value);

			return {
				stringValue: NumberInput.getStringValue(value, props.decimals),
				propsValue: props.value === undefined ? null : props.value, // we need null to update field value to empty, undefined is not working
				editingValue: isNaN(value) ? null : value,
			};
		}
		return null;
	}

	constructor(props: INumberInputProps) {
		super(props);
		const value = Number(props.value) || undefined;

		this._lastValidNumber = value;

		this.state = {
			stringValue: NumberInput.getStringValue(value, props.decimals),
			propsValue: value,
			editingValue: null,
			errorMessage: "",
			toolTipTransform: null,
		};
	}

	protected static getStringValue(value: number, decimals: number) {
		if (value !== 0 && (value === undefined || isNaN(value) || !value)) {
			return "";
		}
		return value.toFixed(decimals);
	}

	private onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		const regex = this.props.dataTypeSettings?.formatting === "percentage" ? /^\d*\.?\d*%?$/ : /^\d*\.?\d*$/;
		const errorMessage = !inputValue || regex.test(inputValue) ? "" : "Enter a Numeric value!";

		this.setState({
			stringValue: inputValue,
			editingValue: inputValue,
			errorMessage,
		});

		const valueAsNumber = this.getNumber(inputValue);

		this.props.onInput?.(valueAsNumber);

		this._isValueChanged = true;
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		let direction = 0;

		switch (event.key) {
			case KeyboardListener.KEY_DOWN:
				direction = -1;
				break;
			case KeyboardListener.KEY_UP:
				direction = 1;
				break;
			case KeyboardListener.KEY_ESCAPE:
				this._isEscPressed = true;
				break;
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event.currentTarget.value);
				event.currentTarget.select();
				break;
		}

		if (direction !== 0) {
			event.preventDefault();
			this.step(direction);
			this._isValueChanged = true;
		}
	};

	private step(direction: number) {
		const stepSize = direction * this.props.step;
		const newValue = this.validateValue(this._lastValidNumber + stepSize);

		this.props.onInput?.(newValue);
		this.props.onChange?.(newValue);
	}

	private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		const hasSelection = target.selectionEnd - target.selectionStart !== 0;

		if (!hasSelection) {
			target.select();
		}
	};

	private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;

		if (StringUtils.trim(value) === "") {
			this.triggerChange(undefined);
		} else {
			if (this._lastClickedElement?.title !== "Cancel") {
				const float = parseFloat(value);
				const validated = this.validateValue(float);
				const stringVal = NumberInput.getStringValue(validated, this.props.decimals);

				this.triggerChange(`${stringVal}${this.isTherePercentageChar(value) ? "%" : ""}`); // parseFloat removes the "%" from the input we need to put it back
			}
		}
	};

	private isTherePercentageChar = (value: string) => {
		return value && this.props.dataTypeSettings?.formatting === "percentage" && value.includes("%");
	};

	private triggerChange(inputValue: string) {
		const {onChange, onInput} = this.props;
		let value = this.getNumber(inputValue);

		this.setState({stringValue: inputValue});

		if (onChange) {
			if (isNaN(value) && value !== undefined) {
				value = this._lastValidNumber;
			}

			const divident = this.isTherePercentageChar(inputValue) ? 100 : 1;
			const dividedValue = value === null ? null : value / divident; // if a user wants to delete the value and leave it empty, we need to send null (value / divident) becomes 0 instead of null

			if (dividedValue !== this._lastValidNumber && this._isValueChanged && this.state.propsValue !== dividedValue) {
				onChange(dividedValue);
				this._lastValidNumber = dividedValue;
				this._isValueChanged = false;
			}
		}

		if (onInput && !onChange) {
			onInput(value);
		}
	}

	private checkForCancelButton = (event: MouseEvent) => {
		// hacky solution for the cancel button. If the cancel button is being clicked
		// the onBlur is the first running function. We need to decide whether it really blurred (should save)
		// or cancel was hit (should not save)
		this._lastClickedElement = event.target as HTMLElement;
	};

	protected getNumber(valueString: string) {
		if (StringUtils.trim(valueString) === "") {
			// Note: very important to return null instead of undefined.
			// undefined doesn't work because in XHRLoader JSON.stringify converts {f35: undefined} -> {}
			// causing a bug that when a numberinput is cleared it's not saved.
			return null;
		}

		let value = parseFloat(valueString);

		value = this.validateValue(value);

		return value;
	}

	protected validateValue(value: number) {
		return MathUtils.clamp(value, this.props.min, this.props.max);
	}

	private updateTooltipPosition = () => {
		const input = this._ref.current;
		const infoBubble = this._floating.current;

		if (this.state.errorMessage && input && infoBubble) {
			const newToolTipTransform = DomUtils.getFixedFloatingElementPosition(input, infoBubble, VerticalAlignment.bottom, HorizontalAlignment.right);

			if (!ObjectUtils.compare(this.state.toolTipTransform, newToolTipTransform)) {
				flushSync(() =>
					this.setState({
						toolTipTransform: newToolTipTransform,
					}),
				);
			}
		}

		this._requestAnimationFrameId = requestAnimationFrame(this.updateTooltipPosition);
	};

	private getTranslateY = (toolTipTransform: string) => {
		if (!toolTipTransform) {
			return null;
		} else {
			const match = toolTipTransform.match(/translate\([^,]+,\s*([^\)]+)\)/);
			return match ? parseFloat(match[1]) : null;
		}
	};

	public override componentDidMount() {
		const {autoFocus, caretPosition} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			input.focus();
		}

		if (caretPosition !== undefined && input.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		this.updateTooltipPosition();
		document.addEventListener("mousedown", this.checkForCancelButton);
	}

	public override componentDidUpdate = (prevProps: INumberInputProps, prevState: INumberInputState) => {
		const input = this._ref.current;

		if (input && !prevProps.autoFocus && this.props.autoFocus) {
			input.focus();
		}
	};

	public override componentWillUnmount() {
		if (!this._isEscPressed && this._lastClickedElement?.title !== "Cancel") {
			// onBlur doesn't get triggered when you click out of the input
			this.triggerChange(this.state.stringValue);
			this.setState({editingValue: null});
		}

		cancelAnimationFrame(this._requestAnimationFrameId);
		document.removeEventListener("mousedown", this.checkForCancelButton);
	}

	public override render() {
		const {className, disabled, noButtons, appState, inline} = this.props;
		const {errorMessage, toolTipTransform, editingValue, stringValue} = this.state;

		const translateY = this.getTranslateY(toolTipTransform?.translate);

		const inlineStyle: React.CSSProperties = this._floating.current && {
			top: inline ? "-45px" : "-42px",
			left: inline ? "-190px" : "-180px",
			width: "15px",
			position: "absolute",
			zIndex: "9999",
			transform: toolTipTransform?.translate,
			visibility: translateY <= 231 ? "hidden" : "visible",
		};

		return (
			<>
				<input
					ref={this._ref}
					className={className}
					value={editingValue ?? stringValue}
					disabled={disabled}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					onInput={this.onInput}
					onKeyDown={this.onKeyDown}
				/>
				{errorMessage && (
					<div
						ref={this._floating}
						className={ReactUtils.cls("infoIcon editing", {left: noButtons})}
					>
						{!inline && (
							<div ref={this._toolTip}>
								<SVGIcon icon="info" />
							</div>
						)}
						<DomPortal destination={appState.app.modalContainer}>
							<InfoBubble
								content={errorMessage}
								isErrorMessage={true}
								className={className}
								divRef={this._toolTip}
								style={inlineStyle}
							/>
						</DomPortal>
					</div>
				)}
			</>
		);
	}
}

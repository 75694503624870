import type {IMultiSelectFieldSettingsDefinitionV5} from "../../../../../data/models/field/datatypes/MultiSelect";
import type {IInputPropsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {MultiSelectInputV5} from "./MultiSelectInputV5";

export const MultiInputV5 = (props: IInputPropsV5<IMultiSelectFieldSettingsDefinitionV5>) => {
	const {dataTypeSettings, onChange, onClick, disabled, inline, focused} = props;
	let {value} = props;

	if (!Array.isArray(value)) {
		value = [];
	}

	return (
		<MultiSelectInputV5
			options={dataTypeSettings.choiceList}
			selected={dataTypeSettings.choiceList.filter((c) => value.includes(c.value))}
			onChange={onChange}
			onClick={onClick}
			disabled={disabled}
			inline={inline}
			focused={focused}
			render={(o) => (typeof o === "string" ? o : o.value)}
		/>
	);
};

import * as React from "react";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";

interface ColorInputProps {
	readonly hex: string;
	readonly onHexInputChange: (hexValue: string) => void;
	readonly onTransparencyInputChange: (newValue: number) => void;
	readonly isTransparencyEnabled: boolean;
	readonly transparency?: number;
}

export class ColorInput extends React.Component<ColorInputProps> {
	private _hexInput = React.createRef<HTMLInputElement>();
	private _transparencyInput = React.createRef<HTMLInputElement>();

	public override componentDidMount() {
		/**
		 * For some reason, in react, `onChange=` seems to be triggered on every keypress.
		 * With this method, it's only triggered when the input is finished (like pressing enter, or changing focus to another element)
		 */
		this._hexInput.current.addEventListener("change", this.onHexInputChange);
		if (this._transparencyInput.current) {
			this._transparencyInput.current.addEventListener("change", this.onTransparencyInputChange);
		}
	}

	public override componentWillUnmount() {
		this._hexInput.current.removeEventListener("change", this.onHexInputChange);
	}

	private onHexInputChange = (event: Event) => {
		const inputElement = event.currentTarget as HTMLInputElement;

		const hexValue = inputElement.value.toUpperCase();

		// https://stackoverflow.com/questions/8027423/how-to-check-if-a-string-is-a-valid-hex-color-representation
		const isValidHex = /^([A-F0-9]{6}|[A-F0-9]{3})$/.test(hexValue);

		if (isValidHex) {
			this.props.onHexInputChange(hexValue);
		}
	};

	private onTransparencyInputChange = (event: Event) => {
		const inputElement = event.currentTarget as HTMLInputElement;
		let newValue = parseFloat(inputElement.value);

		if (!isNaN(newValue)) {
			newValue = MathUtils.clamp(newValue, 0, 100) / 100;

			this.props.onTransparencyInputChange(newValue);
		}
	};

	public override componentDidUpdate(prevProps: ColorInputProps) {
		this._hexInput.current.value = prevProps.hex;

		if (this._transparencyInput.current) {
			this._transparencyInput.current.value = Math.round(this.props.transparency * 100).toString();
		}
	}

	public override render() {
		return (
			<div className="ColorInput hbox alignCenter">
				<span>#</span>
				<input
					ref={this._hexInput}
					maxLength={6}
					className="hexInput"
					defaultValue={this.props.hex}
				/>
				<input
					ref={this._transparencyInput}
					maxLength={3}
					defaultValue={Math.round(this.props.transparency * 100).toString()}
					disabled={!this.props.isTransparencyEnabled}
				/>
				<span>%</span>
			</div>
		);
	}
}

import type {ICaptionSettings} from "../../../../../data/models/ViewUtils";
import type {Color} from "../../../../../generated/api/base";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../utils/dom/DomUtils";
import {Constants} from "../../../../modules/space/spaceeditor/logic3d/Constants";
import {TextGroupManager} from "../../../../modules/space/spaceeditor/logic3d/managers/MSDF/TextGroupManager";
import type {SpaceViewRenderer} from "../../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {ColorSelectorV5} from "../../../colors/ColorSelectorV5";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {FontStyleContainerV5} from "../../../text/FontStyleContainerV5";
import FontIcon from "../../../icons/font-icon.svg?react";
import {FieldV5} from "../../../details/FieldV5";
import type {SupportedFontName} from "../../../../../data/state/AppStateTypes";

interface ICaptionSettingsProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly isLoading: boolean;
	readonly captionSettings: ICaptionSettings;
	readonly onCaptionFontFamilyChange: (newFontFamily: SupportedFontName) => void;
	readonly onCaptionFontSizeChange: (newFontSize: number) => void;
	readonly onCaptionBackgroundColorChange: (newColor: Color) => void;
	readonly onCaptionFontColorChange: (newColor: Color) => void;
	readonly onCaptionIsBoldChange: (isBold: boolean) => void;
	readonly onCaptionIsItalicChange: (isItalic: boolean) => void;
	readonly onCaptionIsUnderlinedChange: (isUnderlined: boolean) => void;
}

export const CaptionSettingsV5 = (props: ICaptionSettingsProps) => {
	const {captionSettings, isLoading} = props;
	const {eyeDropperProps} = props.spaceViewRenderer;

	return (
		<div className="CaptionSettings">
			<FieldV5 label="Font Style">
				<FontStyleContainerV5
					fontStyleSettings={captionSettings}
					onIsBoldChange={props.onCaptionIsBoldChange}
					onIsItalicChange={props.onCaptionIsItalicChange}
					onIsUnderlinedChange={props.onCaptionIsUnderlinedChange}
				/>
			</FieldV5>
			<FieldV5 label="Font Size">
				<SelectInputV5
					disabled={isLoading}
					selected={captionSettings.fontSize}
					onChange={props.onCaptionFontSizeChange}
					options={Constants.SIZE.FONT.values}
					sort={false}
					searchBarMode="always off"
					dropdownIcon={false}
					isSameWidth={true}
				/>
			</FieldV5>
			<FieldV5 label="Font Family">
				<SelectInputV5
					onChange={props.onCaptionFontFamilyChange}
					options={TextGroupManager.supportedFontNames}
					selected={captionSettings.fontFamily}
					searchBarMode="always off"
					dropdownIcon={false}
				/>
			</FieldV5>
			<FieldV5 label="Font Color">
				<ColorSelectorV5
					title="Font Color"
					color={captionSettings.fontColor}
					onColorChange={props.onCaptionFontColorChange}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					isTransparencyEnabled={true}
					verticalAlignment={VerticalAlignment.topOuter}
					IconComponent={FontIcon}
				/>
			</FieldV5>
			<FieldV5 label="Fill Color">
				<ColorSelectorV5
					title="Fill Color"
					color={captionSettings.backgroundColor}
					onColorChange={props.onCaptionBackgroundColorChange}
					isTransparencyEnabled={true}
					eyeDropperProps={eyeDropperProps}
					horizontalAlignment={HorizontalAlignment.center}
					verticalAlignment={VerticalAlignment.topOuter}
				/>
			</FieldV5>
		</div>
	);
};

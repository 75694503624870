import {MathUtils} from "../math/MathUtils";
import {Constants} from "../../ui/modules/space/spaceeditor/logic3d/Constants";
import type {FontObjectType, SupportedFontName} from "../../data/state/AppStateTypes";

/**
 *
 * ^ y
 * |
 * |
 * x-----> x
 *
 */
export class ImageUtils {
	private static _canvas: HTMLCanvasElement;
	private static _ctx: CanvasRenderingContext2D;

	public static get canvas() {
		if (!ImageUtils._canvas) {
			ImageUtils._canvas = document.createElement("canvas");
		}

		return ImageUtils._canvas;
	}

	public static get ctx() {
		if (!ImageUtils._ctx) {
			// Canvas2D: Multiple readback operations using getImageData are faster with the willReadFrequently attribute set to true. See: https://html.spec.whatwg.org/multipage/canvas.html#concept-canvas-will-read-frequently
			ImageUtils._ctx = ImageUtils.canvas.getContext("2d", {willReadFrequently: true});
		}

		return ImageUtils._ctx;
	}

	public static isCanvasCompletelyWhite(ctx: CanvasRenderingContext2D) {
		const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;

		for (let i = 0; i < imageData.length; i += 4) {
			const r = imageData[i];
			const g = imageData[i + 1];
			const b = imageData[i + 2];
			const a = imageData[i + 3];

			const isWhite = r === 255 && g === 255 && b === 255;
			const isTransparent = a === 0;

			if (!isTransparent && !isWhite) {
				return false;
			}
		}

		return true;
	}

	public static getTextWidthInPx(text: string, fontFamily: string, fontSize: number, fontWeight: number | "normal" | "bold" = "normal") {
		const ctx = this.ctx;

		ctx.font = `${fontWeight} ${fontSize}px ${fontFamily}`;

		return ctx.measureText(text).width;
	}

	public static readonly clearFillStyle = "rgba(255, 255, 255, 0.004)"; // 1 / 255 ~ 0.004

	/**
	 * By default, we have a strange, thin, black border around the texture's non-transparent parts (probably it's due to the canvas' premultiplied-alpha).
	 * There are some workarounds to solve this, one of the simplest is this.
	 */
	public static removeBlackBorders(ctx: CanvasRenderingContext2D) {
		ctx.fillStyle = ImageUtils.clearFillStyle;
		ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
	}

	public static svgText2Base64String(svgOuterHTML: string) {
		return `${Constants.SVG_BASE64_PREFIX}${window.btoa(svgOuterHTML)}`;
	}

	public static embedFontIntoSvgIfNecessary(svgOuterHTML: string, fonts: FontObjectType) {
		let imageWithEmbeddedFonts = svgOuterHTML;

		if (!imageWithEmbeddedFonts.includes("@font-face")) {
			for (const fontName in fonts) {
				// As of 2024-07-09, it's not possible for a catalog, or any svg image to have multiple fontfamilies simultaneously
				// If that changes, we'll need a smarter logic here
				if (imageWithEmbeddedFonts.includes(`font-family="${fontName}"`)) {
					imageWithEmbeddedFonts = imageWithEmbeddedFonts.replace(
						`">`,
						`"><defs><style>@font-face{font-family:${fontName};src: url(data:application/octet-stream;base64,${fonts[fontName as SupportedFontName].base64});}</style></defs>`,
					);
					break;
				}
			}
		}

		return imageWithEmbeddedFonts;
	}

	public static async image2RasterBase64String(
		fonts: FontObjectType,
		image: HTMLImageElement,
		maxDimension: number = Infinity,
		toJPG: boolean = false,
		quality: number = 0.78,
	) {
		let finalImage = image;

		if (image.src.startsWith("data:image/svg+xml")) {
			const newSrc = this.embedFontIntoSvgIfNecessary(image.src, fonts);
			finalImage = await ImageUtils.loadImage(newSrc);
		}

		this.canvas.width = finalImage.width;
		this.canvas.height = finalImage.height;

		const maxOriginalDimension = Math.max(this.canvas.width, this.canvas.height);

		if (!maxDimension) {
			maxDimension = maxOriginalDimension;
		}

		let newWidth = this.canvas.width;
		let newHeight = this.canvas.height;

		if (maxOriginalDimension > maxDimension) {
			const aspectRatio = this.canvas.width / this.canvas.height;

			if (aspectRatio > 1) {
				newWidth = maxDimension;
				newHeight = newWidth / aspectRatio;
			} else {
				newHeight = maxDimension;
				newWidth = newHeight * aspectRatio;
			}
		}

		this.canvas.width = newWidth;
		this.canvas.height = newHeight;

		this.ctx.drawImage(finalImage, 0, 0, newWidth, newHeight);

		return this.canvas.toDataURL(toJPG ? "image/jpeg" : "image/png", quality);
	}

	public static async loadImages(urls: string[]) {
		const images: HTMLImageElement[] = [];

		for (const url of urls) {
			images.push(await this.loadImage(url));
		}

		return images;
	}

	public static loadImage(url: string) {
		return new Promise<HTMLImageElement>((resolve, reject) => {
			const img = document.createElement("img");

			img.crossOrigin = "anonymous";
			img.onload = () => {
				resolve(img);
			};
			img.onerror = (error: string) => {
				reject(error);
			};

			img.src = url;
		});
	}

	private static calcProjectedRectSizeOfRotatedRect(size: {width: number; height: number}, rad: number) {
		const {width, height} = size;

		const rectProjectedWidth = Math.abs(width * Math.cos(rad)) + Math.abs(height * Math.sin(rad));
		const rectProjectedHeight = Math.abs(width * Math.sin(rad)) + Math.abs(height * Math.cos(rad));

		return {
			width: rectProjectedWidth,
			height: rectProjectedHeight,
		};
	}

	public static flipImage(imageUrl: string, flipHorizontally: boolean, flipVertically: boolean): Promise<string> {
		const img = document.createElement("img");

		img.crossOrigin = "anonymous";
		img.src = imageUrl;

		return new Promise((resolve, reject) => {
			img.onload = () => {
				const canvas = this.canvas;
				const ctx = this.ctx;

				ctx.save();

				canvas.width = img.width;
				canvas.height = img.height;

				if (flipHorizontally) {
					ctx.translate(img.width, 0);
					ctx.scale(-1, 1);
				}

				if (flipVertically) {
					ctx.translate(0, img.height);
					ctx.scale(1, -1);
				}

				ctx.drawImage(img, 0, 0);

				const base64Image = canvas.toDataURL("image/png");

				ctx.restore();

				resolve(base64Image);
			};

			img.onerror = reject;
		});
	}

	/**
	 * https://stackoverflow.com/questions/17411991/html5-canvas-rotate-image
	 * https://jsfiddle.net/casamia743/xqh48gno/
	 * angle in degrees
	 * returns base64 png
	 */
	public static rotateImage(image: HTMLImageElement, angle: number, filter: string = "") {
		const boundaryRad = Math.atan(image.width / image.height);
		const degree = MathUtils.clampDegreeBetween0And360(angle);

		const rad = MathUtils.DEG2RAD * degree;

		const {width, height} = ImageUtils.calcProjectedRectSizeOfRotatedRect(
			{
				width: image.width,
				height: image.height,
			},
			rad,
		);

		ImageUtils.canvas.width = width;
		ImageUtils.canvas.height = height;

		const ctx = ImageUtils.ctx;

		ctx.save();

		const sinHeight = image.height * Math.abs(Math.sin(rad));
		const cosHeight = image.height * Math.abs(Math.cos(rad));
		const cosWidth = image.width * Math.abs(Math.cos(rad));
		const sinWidth = image.width * Math.abs(Math.sin(rad));

		// Workaround no longer needed (yOrigin can stay 0 instead of 1), once this PR gets merged: https://github.com/Hopding/pdf-lib/pull/502
		let xOrigin: number = 0;
		let yOrigin: number = 0;

		if (rad < boundaryRad) {
			xOrigin = Math.min(sinHeight, cosWidth);
		} else if (rad < Math.PI / 2) {
			xOrigin = Math.max(sinHeight, cosWidth);
		} else if (rad < Math.PI / 2 + boundaryRad) {
			xOrigin = width;
			yOrigin = Math.min(cosHeight, sinWidth);
		} else if (rad < Math.PI) {
			xOrigin = width;
			yOrigin = Math.max(cosHeight, sinWidth);
		} else if (rad < Math.PI + boundaryRad) {
			xOrigin = Math.max(sinHeight, cosWidth);
			yOrigin = height;
		} else if (rad < (Math.PI / 2) * 3) {
			xOrigin = Math.min(sinHeight, cosWidth);
			yOrigin = height;
		} else if (rad < (Math.PI / 2) * 3 + boundaryRad) {
			yOrigin = Math.max(cosHeight, sinWidth);
		} else if (rad < Math.PI * 2) {
			yOrigin = Math.min(cosHeight, sinWidth);
		}

		ctx.translate(xOrigin, yOrigin);
		ctx.rotate(rad);
		ImageUtils.removeBlackBorders(ctx);
		const savedFilter = ctx.filter;

		ctx.filter = filter;
		ctx.drawImage(image, 0, 0, image.width, image.height);
		ctx.restore();
		ctx.filter = savedFilter;

		return ImageUtils.canvas.toDataURL();
	}

	public static hasTransparentPixels(image: HTMLImageElement): boolean {
		const canvas = this.canvas;
		const ctx = this.ctx;

		canvas.width = image.width;
		canvas.height = image.height;

		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.drawImage(image, 0, 0);

		const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

		for (let i = 0; i < imageData.length; i += 4) {
			// const r = imageData[i];
			// const g = imageData[i + 1];
			// const b = imageData[i + 2];
			const a = imageData[i + 3];

			if (a < 255) {
				return true;
			}
		}

		return false;
	}
}

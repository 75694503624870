import * as React from "react";
import {Functions} from "../../../../utils/function/Functions";
import {MathUtils} from "../../../../utils/math/MathUtils";

export interface ICheckboxInputProps {
	readonly value: boolean;
	readonly onChange?: (value: boolean) => void;
	readonly disabled?: boolean;
	readonly inputRef?: React.RefObject<HTMLInputElement>;
	readonly label?: string;
	readonly className?: string;
}

export class CheckboxInput extends React.Component<ICheckboxInputProps> {
	public static readonly defaultProps: ICheckboxInputProps = {
		value: false,
		onChange: Functions.emptyFunction /* empty arrow function prevents react to cry for onChange handler */,
	};

	private _id = MathUtils.getNewRandomGUID();

	private onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.triggerChange(event);
	};

	private triggerChange(event: React.ChangeEvent<HTMLInputElement>) {
		if (this.props.onChange) {
			const value = this.validateValue(event.currentTarget.checked);

			this.props.onChange(value);
		}
	}

	private validateValue(value: boolean) {
		return !!value;
	}

	public override render() {
		const {value, disabled, inputRef, className} = this.props;

		return (
			<div
				className="CheckboxInput hbox alignCenter"
				data-cy={`CheckboxInput_for_${this.props.label}`}
			>
				<input
					ref={inputRef}
					id={this._id}
					checked={value}
					type="checkbox"
					onChange={this.onChange}
					disabled={disabled}
					className={className}
				/>
				<label htmlFor={this._id} />
			</div>
		);
	}
}

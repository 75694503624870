import type {FieldIconName} from "../FieldInputUtils";
import type {IModel} from "../../../../../../../../data/models/Model";
import type {IFieldAdapter} from "../../../../../../../../data/models/field/Field";
import type {AppState} from "../../../../../../../../data/state/AppState";
import {StringUtils} from "../../../../../../../../utils/data/string/StringUtils";
import {KeyboardListener} from "../../../../../../../../utils/interaction/key/KeyboardListener";
import type {Xyicon3D} from "../../../../../../space/spaceeditor/logic3d/elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../../../../../../space/spaceeditor/logic3d/elements3d/BoundarySpaceMap3D";
import type {Markup3D} from "../../../../../../space/spaceeditor/logic3d/elements3d/markups/abstract/Markup3D";

export interface IMassInputProps {
	field: IFieldAdapter;
	items: IModel[];
	open: boolean;
	onOpen: (fieldRefId: string) => void;
	numberOfSelectedItems?: number;
	icon?: FieldIconName;
	disabled?: boolean;
}

export interface IOption {
	id: "all" | "blank" | string;
	label: string;
	value?: any;
}

interface MassUpdateOptionReturnValues {
	options: IOption[];
	allValuesMatch: boolean;
	firstValue: string;
}

export interface IMassInputV5Props {
	ref: any;
	field: IFieldAdapter;
	items: IModel[];
	selectItemsCount?: number;
	onApply?: (items: IModel[]) => void;
	onClose: () => void;
}

export function getItemsToCheck(items: IModel[], field: IFieldAdapter, appState: AppState) {
	const {actions} = appState;

	return items.filter((item) => {
		const isHidden = actions.isFieldHiddenByMasking(item, field);
		const isAssigned = actions.isFieldAssignedToItem(field, item);

		return !isHidden && isAssigned;
	});
}

export function getMassUpdateOptions(items: IModel[], field: IFieldAdapter, appState: AppState, needOptions = false): MassUpdateOptionReturnValues {
	const options: IOption[] = [
		{
			id: "all",
			label: "(All Values)",
		},
	];

	let allValuesMatch = true;
	let blankAdded = false;

	let first = true;
	let firstValue: string;

	for (const item of items) {
		const hasValue = appState.actions.hasOwnFieldValue(item, field.refId);

		if (!hasValue) {
			continue;
		}

		// Note: if this is changed to getValue, then firstValue and option.label needs to be formatted to string
		// to avoid crashes from React.
		const value = appState.actions.renderValue(item, field.refId);

		if (first) {
			firstValue = value;
			first = false;
		}

		if (value !== firstValue) {
			allValuesMatch = false;
		}

		if (needOptions) {
			if (StringUtils.isBlank(value)) {
				if (!blankAdded) {
					options.push({
						id: "blank",
						label: "(Blank Values)",
					});
					blankAdded = true;
				}
			} else {
				const id = `field-${value}`;

				if (!options.some((option) => option.id === id)) {
					options.push({
						id,
						label: value,
						value: appState.actions.getFieldValue(item, field.refId),
					});
				}
			}
		}
	}

	return {options, allValuesMatch, firstValue};
}

export function massUpdatePressButton(
	event: KeyboardEvent,
	needItemForApply: boolean,
	onApply: (items: IModel[]) => Promise<void>,
	onCancel: () => void,
	props: IMassInputProps & {appState?: AppState},
) {
	const {items, field, appState} = props;

	switch (event.key) {
		case KeyboardListener.KEY_ENTER:
			onApply(needItemForApply ? getItemsToCheck(items, field, appState) : undefined);
			break;
		case KeyboardListener.KEY_ESCAPE:
			onCancel();
			break;
	}
}

// Properties

export type spaceItemForProperties = Xyicon3D | BoundarySpaceMap3D | Markup3D;

export interface IPropertyDetails {
	name: string;
	measure: boolean;
	parts: {
		label?: string;
		name: PropertyName;
		ref: string;
	}[];
}

export type PropertyName = "px" | "py" | "pz" | "dx" | "dy" | "dz" | "o" | "area";

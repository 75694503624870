import styled from "styled-components";
import {ColorUtils} from "../../../utils/ColorUtils";
import type {IPropsForEyeDropper} from "./EyeDropperV5";
import {EyeDropperV5} from "./EyeDropperV5";
import {ColorGradientV5} from "./ColorGradientV5";
import {HueSliderV5} from "./HueSliderV5";
import {OpacitySlider} from "./OpacitySlider";
import {ColorInputV5} from "./ColorInputV5";

export interface IHSLColor {
	/** HexToHSL is not an injective function: hue value can be anything when the input is a monochrome color
	 * In this case, we don't want the hue level to change, so we show the last valid hue value instead */
	hue: number; // [0 - 360]
	saturation: number; // [0 - 1]
	lightness: number; // [0 - 1]
	transparency: number; // [0 - 1]
}

interface IAdvancedColorPanelProps {
	readonly color: IHSLColor;
	readonly onColorChange: (newColor: IHSLColor) => void;
	readonly isTransparencyEnabled: boolean;
	readonly eyeDropperProps?: IPropsForEyeDropper;
}

export const AdvancedColorPanelV5 = (props: IAdvancedColorPanelProps) => {
	const onSLChange = (newSaturation: number, newLightness: number) => {
		props.onColorChange({
			hue: props.color.hue,
			saturation: newSaturation,
			lightness: newLightness,
			transparency: props.color.transparency,
		});
	};

	const onHueChange = (newValue: number) => {
		props.onColorChange({
			hue: newValue,
			saturation: props.color.saturation,
			lightness: props.color.lightness,
			transparency: props.color.transparency,
		});
	};

	const onOpacityChange = (newValue: number) => {
		props.onColorChange({
			hue: props.color.hue,
			saturation: props.color.saturation,
			lightness: props.color.lightness,
			transparency: 1 - newValue,
		});
	};

	const onHexChange = (newHexValue: string) => {
		const hslColor = ColorUtils.hex2hsl(newHexValue);

		props.onColorChange({
			hue: hslColor.h,
			saturation: hslColor.s,
			lightness: hslColor.l,
			transparency: props.color.transparency,
		});
	};

	const onDispatchColorChange = () => {
		props.onColorChange({
			hue: props.color.hue,
			saturation: props.color.saturation,
			lightness: props.color.lightness,
			transparency: props.color.transparency,
		});
	};

	const {color, eyeDropperProps, isTransparencyEnabled} = props;
	const hex = ColorUtils.hsl2hex(props.color.hue, props.color.saturation, props.color.lightness) as string;

	return (
		<AdvancedColorPanelStyled className="ColorPanelAdvanced">
			<ColorGradientV5
				color={color}
				onSLChange={onSLChange}
				onPointerUp={onDispatchColorChange}
			/>
			<SliderContainer>
				<HueSliderV5
					value={color.hue}
					onValueChange={onHueChange}
				/>
				{isTransparencyEnabled && (
					<OpacitySlider
						colorHex={hex}
						value={1 - color.transparency}
						onValueChange={onOpacityChange}
					/>
				)}
			</SliderContainer>
			<BottomRow>
				{eyeDropperProps && (
					<EyeDropperV5
						{...eyeDropperProps}
						onColorClick={onHexChange}
					/>
				)}
				<ColorInputV5
					hex={hex}
					onHexInputChange={onHexChange}
					opacity={1 - color.transparency}
					isTransparencyEnabled={isTransparencyEnabled}
					onOpacityInputChange={onOpacityChange}
				/>
			</BottomRow>
		</AdvancedColorPanelStyled>
	);
};

const SliderContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 12px;
`;

const AdvancedColorPanelStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
`;

const BottomRow = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 32px;
`;

import type {INumericFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/Numeric";
import type {IFieldDataTypeSettingsProps} from "../../../../modules/settings/modules/field/datatypes/IFieldDataTypeSettingsProps";
import {SelectInputV5} from "../../../input/select/SelectInputV5";
import {FieldV5} from "../../FieldV5";
import {NumberInputV5} from "./NumberInputV5";

const _options: {
	id: "general" | "csv" | "currency" | "percentage";
	label: string;
}[] = [
	{
		id: "general",
		label: "General",
	},
	{
		id: "csv",
		label: "Comma separated values",
	},
	{
		id: "currency",
		label: "Currency",
	},
	// Temporary disable until BA team decides how it should work
	// {
	// 	id: "percentage",
	// 	label: "Percentage"
	// }
];

export const NumericFieldSettingsV5 = (props: IFieldDataTypeSettingsProps) => {
	const {field, onChange} = props;

	const onInput = (value: number) => {
		const settings = field.dataTypeSettings as INumericFieldSettingsDefinition;

		settings.decimals = settings?.formatting === "percentage" && settings.decimals !== value ? value + 2 : value;
		onChange();
	};

	const settings = field.dataTypeSettings as INumericFieldSettingsDefinition;

	return (
		<>
			<FieldV5 label="Formatting">
				<SelectInputV5
					options={_options}
					selected={_options.find((option) => option.id === settings.formatting)}
					onChange={(option) => {
						settings.formatting = option.id;
						onChange();
					}}
					render={(option) => option.label}
					isSameWidth={true}
				/>
			</FieldV5>
			<FieldV5
				label="Decimal Places"
				className="decimals"
			>
				<NumberInputV5
					value={settings?.formatting === "percentage" ? settings.decimals - 2 : settings.decimals}
					onInput={onInput}
					decimals={0}
					min={0}
					max={10}
				/>
			</FieldV5>
		</>
	);
};

import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../widgets/button/IconButton";
import {ReactUtils} from "../utils/ReactUtils";
import {SVGIcon} from "../widgets/button/SVGIcon";
import type {IModel, ISpaceItemModel} from "../../data/models/Model";
import {KeyboardListener} from "../../utils/interaction/key/KeyboardListener";
import {XyiconFeature} from "../../generated/api/base";
import type {Catalog} from "../../data/models/Catalog";
import type {TransportLayer} from "../../data/TransportLayer";
import type {AppState} from "../../data/state/AppState";
import type {IIconConfig} from "./catalog/create/CatalogTypes";
import {CatalogItemPanel} from "./catalog/create/CatalogItemPanel";
import {DetailsTab} from "./abstract/sidepanel/tabs/details/DetailsTab";

interface IDetailsContainerProps {
	readonly items: IModel[];
	readonly onClose: (closeAll?: boolean) => void;
	readonly closeWideSearchPanel: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IDetailsContainerState {
	isCatalogEditPanelOpen: boolean;
	iconConfig: IIconConfig;
	editedCatalog: Catalog;
	isPortTemplateEditorOpen: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class DetailsContainer extends React.Component<IDetailsContainerProps, IDetailsContainerState> {
	// They're on top of each other, so we don't need to render more than 2, even if we introduce an opening/closing animation in the future
	private readonly _numberOfItemsToRender: number = 1;
	private _isLoadingCatalogEditor = false;
	public _detailsTabRef = React.createRef<DetailsTab>();

	// Adds new item to the existing array, then they're rendered on each other

	constructor(props: IDetailsContainerProps) {
		super(props);

		this.state = {
			isCatalogEditPanelOpen: false,
			iconConfig: null,
			editedCatalog: null,
			isPortTemplateEditorOpen: false,
		};
	}

	private onCloseClick = () => {
		this.props.onClose(false);
	};

	private onKeyUp = (event: KeyboardEvent) => {
		if (event.key === KeyboardListener.KEY_ESCAPE && !this.state.isCatalogEditPanelOpen) {
			this.onCloseClick();
		}
	};

	private onCatalogIconClick = async () => {
		const {transport, items} = this.props;

		if (!this._isLoadingCatalogEditor) {
			this._isLoadingCatalogEditor = true;

			const catalogs = items.filter((item) => item.ownFeature === XyiconFeature.XyiconCatalog);
			const catalog = catalogs[0] as Catalog;

			const iconConfig = await transport.getIconConfigOfCatalog(catalog);

			this.setState({
				editedCatalog: catalog,
				iconConfig: iconConfig,
				isCatalogEditPanelOpen: true,
			});

			this._isLoadingCatalogEditor = false;
		}
	};

	private onPortTemplateEditorChange = (value: boolean) => {
		this.setState({isPortTemplateEditorOpen: value});
	};

	public override componentDidMount() {
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
	}

	public override componentDidUpdate(prevProps: Readonly<IDetailsContainerProps>, prevState: Readonly<{}>, snapshot?: any): void {
		if (prevProps.items.length !== this.props.items.length) {
			this.props.appState.isDetailsContainerOpened = !!this.props.items.length;
			if (this.props.items.length === 0) {
				this.setState({isCatalogEditPanelOpen: false});
			}
		}
	}

	public override componentWillUnmount() {
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
	}

	public override render() {
		const {items, closeWideSearchPanel} = this.props;
		const {isCatalogEditPanelOpen, editedCatalog, iconConfig, isPortTemplateEditorOpen} = this.state;
		const itemsToRender = items.filter((item: IModel, index: number) => item && index >= items.length - this._numberOfItemsToRender);

		return (
			<>
				{items.length > 0 && (
					<div
						className="shadowDiv"
						onClick={this.onCloseClick}
					/>
				)}
				<div className={ReactUtils.cls(`DetailsContainer createPanel SidePanel infoPanel`, {open: items.length > 0})}>
					{itemsToRender.map((spaceItemModel: ISpaceItemModel, index: number) => {
						const detailsTabProps = {
							ref: this._detailsTabRef,
							items: [spaceItemModel],
							feature: spaceItemModel.ownFeature,
							features: [spaceItemModel.ownFeature],
							isPortTemplateEditorOpen: isPortTemplateEditorOpen,
							insideDetailsContainer: true,
							setPortTemplateEditorOpen: this.onPortTemplateEditorChange,
							closeWideSearchPanel: closeWideSearchPanel,
							onCatalogIconEditClick: this.onCatalogIconClick,
						};

						return (
							<React.Fragment key={spaceItemModel.id}>
								<div className="heading createBox hbox">
									<div className="detailsHeader">
										<SVGIcon
											classNames="headerInfoIcon"
											icon="details"
										></SVGIcon>
										<h4>Details</h4>
									</div>
									<IconButton
										className="closePanel"
										icon="close"
										title="Close Layers"
										onClick={this.onCloseClick}
									/>
								</div>
								<DetailsTab {...detailsTabProps} />
							</React.Fragment>
						);
					})}
				</div>
				<div className={ReactUtils.cls("createPanel detailsPopup", {open: isCatalogEditPanelOpen})}>
					{isCatalogEditPanelOpen && (
						<CatalogItemPanel
							key={"asd"}
							onClose={() => {
								this.setState({isCatalogEditPanelOpen: false});
							}}
							iconConfig={iconConfig}
							catalog={editedCatalog}
							mode="edit"
						/>
					)}
				</div>
			</>
		);
	}
}

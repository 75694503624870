import * as React from "react";
import {observable, makeObservable, computed} from "mobx";
import type {Markup} from "../models/Markup";
import type {Boundary} from "../models/Boundary";
import type {SpaceVersion} from "../models/SpaceVersion";
import type {LibraryImage} from "../models/LibraryImage";
import type {Link} from "../models/Link";
import type {LibraryModel} from "../models/LibraryModel";
import {configurePhone} from "../models/field/datatypes/Phone";
import {configureIP} from "../models/field/datatypes/IP";
import {configureEmail} from "../models/field/datatypes/Email";
import {configureMultiSelect} from "../models/field/datatypes/MultiSelect";
import {configureSingleSelect} from "../models/field/datatypes/SingleSelect";
import {configureMultiLine} from "../models/field/datatypes/MultiLine";
import {configureSingleLine} from "../models/field/datatypes/SingleLine";
import {configureNumeric} from "../models/field/datatypes/Numeric";
import {configureDate} from "../models/field/datatypes/Date";
import {configureBoolean} from "../models/field/datatypes/Boolean";
import type {IModel} from "../models/Model";
import type {Space} from "../models/Space";
import type {ExternalUser, User} from "../models/User";
import type {Portfolio} from "../models/Portfolio";
import type {Xyicon} from "../models/Xyicon";
import {View} from "../models/View";
import {XyiconFeature} from "../../generated/api/base";
import type {Type} from "../models/Type";
import type {Field, IDefaultField, IFieldAdapter} from "../models/field/Field";
import type {ITypeFieldMapping} from "../models/TypeFieldMapping";
import type {Catalog} from "../models/Catalog";
import type {ILayoutDefinition, LayoutSettings} from "../../ui/modules/settings/modules/layout/LayoutSettings";
import type {App} from "../../App";
import type {Organization} from "../models/Organization";
import {Collection} from "../models/abstract/Collection";
import type {PortfolioGroup} from "../models/PortfolioGroup";
import type {UserGroup} from "../models/UserGroup";
import type {PermissionSet} from "../models/permission/PermissionSet";
import type {BoundarySpaceMap} from "../models/BoundarySpaceMap";
import type {DocumentModel} from "../models/DocumentModel";
import {configureUser} from "../models/field/datatypes/User";
import {configureType} from "../models/field/datatypes/Type";
import type {Report} from "../models/Report";
import type {ThemeType} from "../../ui/ThemeType";
import {configureGeoLocation} from "../../ui/widgets/input/clicktoedit/datatypes/geolocation/GeoLocationLabel";
import type {Table} from "../../ui/widgets/table/Table";
import {FieldDataTypes} from "../models/field/FieldDataTypes";
import type {LayoutSettingsV5} from "../../ui/5.0/settings/modules/layout/LayoutSettingsV5";
import {NavigationEnum} from "../../Enums";
import type {Dashboard} from "../models/Dashboard";
import {AppFieldActions} from "./AppFields";
import {AppActions} from "./AppActions";
import {ItemFieldUpdateManager} from "./ItemFieldUpdateManager";
import type {FeatureMap} from "./AppStateConstants";
import type {FontObjectType} from "./AppStateTypes";

type IdMap<T> = {
	[id: string]: T;
};

export type UIVersion = "4.0" | "5.0";

export class AppState {
	@observable
	public catalogEditorParentMaybe: HTMLDivElement = null;

	@observable
	public user: User | null;

	@observable
	public organizationId: string = "";

	@observable
	public organizations: Organization[] = [];

	@observable
	public readonly lists: {
		[key in XyiconFeature]?: Collection<any>;
	} = {
		[XyiconFeature.Portfolio]: new Collection<Portfolio>(),
		[XyiconFeature.PortfolioGroup]: new Collection<PortfolioGroup>(),
		[XyiconFeature.XyiconCatalog]: new Collection<Catalog>(),
		[XyiconFeature.Dashboard]: new Collection<Dashboard>(),
		[XyiconFeature.LibraryImage]: new Collection<LibraryImage>(),
		[XyiconFeature.LibraryModel]: new Collection<LibraryModel>(),
		[XyiconFeature.User]: new Collection<User>(),
		[XyiconFeature.ExternalUser]: new Collection<ExternalUser>(),
		[XyiconFeature.UserGroup]: new Collection<UserGroup>(),
		[XyiconFeature.PermissionSet]: new Collection<PermissionSet>(),
		[XyiconFeature.OrganizationDocument]: new Collection<DocumentModel>(),
		// portfolio dependent lists:
		[XyiconFeature.Xyicon]: new Collection<Xyicon>(),
		[XyiconFeature.Space]: new Collection<Space>(),
		[XyiconFeature.SpaceVersion]: new Collection<SpaceVersion>(),
		[XyiconFeature.Markup]: new Collection<Markup>(),
		[XyiconFeature.Boundary]: new Collection<Boundary>(),
		[XyiconFeature.PortfolioDocument]: new Collection<DocumentModel>(),
		[XyiconFeature.Report]: new Collection<Report>(),
		[XyiconFeature.Link]: new Collection<Link>([], ["fromObjectId", "toObjectId"]),
	};

	@observable
	public boundarySpaceMaps = new Collection<BoundarySpaceMap>();

	@observable
	public types: FeatureMap<Type[]> = {
		[XyiconFeature.Portfolio]: [],
		[XyiconFeature.Xyicon]: [],
		[XyiconFeature.Boundary]: [],
		[XyiconFeature.Space]: [],
	};

	public typesById: IdMap<Type> = {};
	public fieldsById: IdMap<Field> = {}; // only Field has id
	public fieldsByRef: IdMap<IFieldAdapter> = {}; // FieldAdapter has only refId
	public viewsMap: IdMap<View> = {};

	@observable
	public fields: FeatureMap<Field[]> = {
		[XyiconFeature.Portfolio]: [],
		[XyiconFeature.XyiconCatalog]: [],
		[XyiconFeature.Xyicon]: [],
		[XyiconFeature.Space]: [],
		[XyiconFeature.Boundary]: [],
	};

	@observable
	public typeFieldMapping: FeatureMap<ITypeFieldMapping> = {
		[XyiconFeature.Portfolio]: {},
		[XyiconFeature.Xyicon]: {},
		[XyiconFeature.XyiconCatalog]: {},
		[XyiconFeature.Space]: {},
		[XyiconFeature.Boundary]: {},
	};

	@observable
	public views: FeatureMap<View[]> = {
		[XyiconFeature.Portfolio]: [],
		[XyiconFeature.Xyicon]: [],
		[XyiconFeature.Boundary]: [],
		[XyiconFeature.XyiconCatalog]: [],
		[XyiconFeature.Space]: [],
		[XyiconFeature.SpaceEditor]: [],
		[XyiconFeature.Report]: [],
	};

	// TODO should be less error prone -> auto create all default / inherited fields?
	public defaultViews: FeatureMap<View> = {
		[XyiconFeature.Portfolio]: View.createDefault(["refId", "name", "type"], XyiconFeature.Portfolio, this),
		[XyiconFeature.Xyicon]: View.createDefault(["refId", "type"], XyiconFeature.Xyicon, this),
		[XyiconFeature.Boundary]: View.createDefault(["refId", "name", "type"], XyiconFeature.Boundary, this),
		[XyiconFeature.XyiconCatalog]: View.createDefault(["refId", "type"], XyiconFeature.XyiconCatalog, this),
		[XyiconFeature.Space]: View.createDefault(["refId", "name", "type", "versionName"], XyiconFeature.Space, this),
		// [XyiconFeature.SpaceEditor]: View.createDefault(["refId", "name", "type"], XyiconFeature.SpaceEditor, this), // Add this separately, because it needs the appState to be fully initialized
		[XyiconFeature.Report]: View.createDefault(
			["refId", "name", "reportFeatureTitle", "scopeTitle", "category", "description", "lastModifiedBy", "lastModifiedAt"],
			XyiconFeature.Report,
			this,
			[90, 200, 80, 135, 100, 300, 136, 155],
		),
	};

	@observable
	public touchedViews: string[] = [];

	@observable
	public selectedViewId: FeatureMap<string> = {
		[XyiconFeature.Portfolio]: "",
		[XyiconFeature.Xyicon]: "",
		[XyiconFeature.Boundary]: "",
		[XyiconFeature.XyiconCatalog]: "",
		[XyiconFeature.Space]: "",
		[XyiconFeature.SpaceEditor]: "",
		[XyiconFeature.Report]: "",
	};

	@observable
	public layouts: FeatureMap<ILayoutDefinition> = {};

	@observable
	public space: Space;

	@observable
	public readonly icons = {
		status: "",
		result: [] as Xyicon[],
	};

	public fonts: FontObjectType = {} as unknown as any;

	// TODO add # prefix for default fields?

	@observable
	public readonly defaultFields: FeatureMap<IDefaultField[]> = {
		[XyiconFeature.Portfolio]: [],
		[XyiconFeature.XyiconCatalog]: [],
		[XyiconFeature.Space]: [],
		[XyiconFeature.SpaceEditor]: [],
		[XyiconFeature.Boundary]: [],
		[XyiconFeature.Xyicon]: [],
		[XyiconFeature.Report]: [],
	};

	@observable
	public lastError = "";

	@observable
	public theme: ThemeType = "light";

	@observable
	public selectedMenu: NavigationEnum = NavigationEnum.NAV_PORTFOLIOS;

	@observable
	public portfolioId: string = "";

	@observable
	public selectedFieldInputRefId: string = "";

	@observable
	public isDetailsContainerOpened: boolean = false;

	@observable
	public table = React.createRef<HTMLTableElement>();

	@observable
	public tableComponent = React.createRef<Table<IModel>>(); // ref to the Table component (only in grid view, not in settings)

	@observable
	public layoutSettings = React.createRef<LayoutSettings | LayoutSettingsV5>(); // ref to the Table component (only in grid view, not in settings)

	@observable
	public isDetailsTabBeingOpenByDocumentOfItem: boolean = false; // if true -> the default state of ToggleContainers within the DetailsTab should be false, except for the Document section

	@observable
	public isLoggingIn: boolean = false;

	private static instance: AppState;
	private readonly _actions: AppActions;
	private readonly _itemFieldUpdateManager: ItemFieldUpdateManager = new ItemFieldUpdateManager();

	private _app: App;

	constructor(app: App) {
		makeObservable(this);
		this._app = app;
		if (AppState.instance) {
			console.warn("AppState already created!");
		}
		AppState.instance = this;

		this._actions = new AppActions(this);

		const fieldActions = new AppFieldActions(this);

		fieldActions.createDefaultFields();

		this.reconfigureFieldTypes();
	}

	private reconfigureFieldTypes() {
		FieldDataTypes.clear();

		// Needed for formatting in Table
		configureBoolean();
		configureDate();
		configureNumeric();
		configureSingleLine();
		configureMultiLine();
		configureSingleSelect();
		configureMultiSelect();
		configureEmail();
		configureIP();
		configurePhone();
		configureGeoLocation();
		configureUser(this);
		configureType(this);
	}

	@computed
	public get selectedModuleTitle(): string {
		return this._app.navigation.getTitle(this.selectedMenu);
	}

	@computed
	public get selectedFeature(): XyiconFeature {
		const menus = this.app.navigation.menus;
		const screens = this.app.navigation.screens;
		const currentMenu = this.selectedMenu;
		const selectedMenu = menus.find((m) => m.nav === currentMenu) ?? screens.find((s) => s.nav === currentMenu);

		return selectedMenu?.feature ?? null;
	}

	public initAdditionalDefaultViews() {
		if (!this.defaultViews[XyiconFeature.SpaceEditor]) {
			this.defaultViews[XyiconFeature.SpaceEditor] = View.createDefault(["refId", "name", "type"], XyiconFeature.SpaceEditor, this);
		}
	}

	public selectViewForFeatureAfterLogin(features: XyiconFeature[]) {
		if (this.organizationId) {
			for (const feature of features) {
				// If the local storage has a last used view for a given module use that
				const localStorageViewId = this._app.transport.services.localStorage.get(this.getKeyForLocalStorageView(this.organizationId, feature)) || "";

				if (localStorageViewId) {
					this.selectedViewId[feature] = localStorageViewId;
				} else {
					// Otherwise, if the local storage doesn't have a last used view for a given module,
					// load the default view defined under the default views for the same organization for the module.
					const organizationSettings = this.actions.getCurrentOrganizationSettings();
					const organizationViewId = organizationSettings?.defaultViews?.[feature];

					if (organizationViewId) {
						this.selectedViewId[feature] = organizationViewId;
					}
				}

				// Check if selected view exists
				if (!this._actions.getViewById(this.selectedViewId[feature])) {
					// selected view doesn't exist for some reason -> select the first view if there is at least one view
					const views = this.views[feature];
					const firstView = views?.length ? views[0] : null;

					if (firstView) {
						this.selectedViewId[feature] = firstView.id;
					} else {
						// No views for this feature, select the default view (which has id = "")
						this.selectedViewId[feature] = this.defaultViews[feature]?.id || "";
					}
				}
			}
		}
	}

	public getKeyForLocalStorageView(organizationId: string, feature: XyiconFeature) {
		return `srv4-org-${organizationId}-${this.user?.id}-view-${feature}`;
	}

	public getLocalStorageKeyForLastViewedSpace(userId: string, orgId: string) {
		return `last_viewed_space_${userId}_${orgId}`;
	}

	public setUIVersion(version: UIVersion) {
		if (this.currentUIVersion !== version) {
			localStorage.setItem("ui-version", version);

			if (this.user?.generalSetting) {
				this.user.generalSetting.currentUiVersion = version;
			}
			this._app.forceUpdate();
		}
	}

	public get currentUIVersion(): UIVersion {
		let ret = (this.user?.generalSetting.currentUiVersion as UIVersion) || (localStorage.getItem("ui-version") as UIVersion);
		return ret === "5.0" ? "5.0" : "4.0";
	}

	public get actions() {
		return this._actions;
	}

	@computed
	public get itemFieldUpdateManager() {
		return this._itemFieldUpdateManager;
	}

	@computed
	public get organization(): Organization | undefined {
		return this.organizations.find((o) => o.id === this.organizationId);
	}

	public get app() {
		return this._app;
	}
}

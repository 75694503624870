import {getCaptionFontConfig} from "../../renderers/SpaceViewRendererUtils";
import type {PointDouble} from "../../../../../../../generated/api/base";
import type {HorizontalAlignment, VerticalAlignment} from "../../../../../../../utils/dom/DomUtils";
import type {SupportedFontName} from "../../../../../../../data/state/AppStateTypes";
import type {CaptionedItem} from "./CaptionManager";
import type {IObjectWithText, ITextPart} from "./TextUtils";

export abstract class Caption implements IObjectWithText {
	protected _parent: CaptionedItem;
	private _isVisible: boolean = true;
	private _text: ITextPart[] = [];

	protected _position: PointDouble = {x: null, y: null};

	public readonly opacity: number;
	public readonly textHAlign: HorizontalAlignment;
	public readonly textVAlign: VerticalAlignment;
	public readonly textInstanceIds: number[] = [];

	public scale: PointDouble = null;
	public sizeOfGeneratedText: PointDouble = null;

	public readonly isCaption = true;
	public readonly id: string;

	constructor(parent: CaptionedItem) {
		this._parent = parent;
		this.id = `${parent.modelData?.id ?? "Undefined"}_caption`;

		const captionFontConfig = getCaptionFontConfig();

		this.opacity = captionFontConfig.opacity;
		this.textHAlign = captionFontConfig.textHAlign;
		this.textVAlign = captionFontConfig.textVAlign;
	}

	public updateText(textParts: ITextPart[]) {
		this._text = textParts;
		this.scale = null; // needs to be recalculated
	}

	public abstract setToDefaultPosition(): void;

	public show() {
		this._isVisible = true;
	}

	public hide() {
		this._isVisible = false;
	}

	public get parent() {
		return this._parent;
	}

	public get position() {
		return this._position;
	}

	public get text() {
		return this._text;
	}

	public get isVisible() {
		return this._isVisible && this._parent.isVisible;
	}

	private get _captionManager() {
		return this._parent.itemManager.captionManager;
	}

	public get fontSize() {
		return this._captionManager.captionFontSize;
	}

	public get fontColor() {
		return this._captionManager.captionFontColor;
	}

	public get fontFamily(): SupportedFontName {
		return this._captionManager.captionFontFamily;
	}

	public get isBold(): boolean {
		return this._captionManager.captionIsBold;
	}

	public get isItalic(): boolean {
		return this._captionManager.captionIsItalic;
	}

	public get isUnderlined(): boolean {
		return this._captionManager.captionIsUnderlined;
	}

	public get backgroundColor() {
		return this._captionManager.captionBackgroundColor;
	}

	public get orientation() {
		return 0;
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Lambda} from "mobx";
import {observe} from "mobx";
import {EmptyListView} from "../../../grid/EmptyListView";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import type {XyiconCatalogSettingsModel, XyiconCatalogUpdateSettingsDto} from "../../../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../../../generated/api/base";
import type {TransportLayer} from "../../../../../../data/TransportLayer";
import type {IModel} from "../../../../../../data/models/Model";
import {Initials} from "../../../../../widgets/Initials";
import type {AppState} from "../../../../../../data/state/AppState";
import type {FeatureMap} from "../../../../../../data/state/AppStateConstants";
import {featureTitles} from "../../../../../../data/state/AppStateConstants";
import type {ISectionData, ISectionField} from "../../../../settings/modules/layout/LayoutSettings";
import type {Catalog} from "../../../../../../data/models/Catalog";
import type {Space} from "../../../../../../data/models/Space";
import type {Navigation} from "../../../../../../Navigation";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {Report} from "../../../../../../data/models/Report";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {ObjectUtils} from "../../../../../../utils/data/ObjectUtils";
import {ArrayUtils} from "../../../../../../utils/data/array/ArrayUtils";
import {LoaderIcon} from "../../../../../widgets/button/LoaderIcon";
import {TimeUtils} from "../../../../../../utils/TimeUtils";
import type {Field as FieldModel} from "../../../../../../data/models/field/Field";
import {Field} from "../../../../../widgets/form/field/Field";
import type {Xyicon} from "../../../../../../data/models/Xyicon";
import {MultiSelectInput} from "../../../../../widgets/input/multiselect/MultiSelectInput";
import type {Boundary} from "../../../../../../data/models/Boundary";
import {DebugInformation} from "../../../../../../utils/DebugInformation";
import {AppUtils} from "../../../../../../utils/AppUtils";
import type {ISelectSliderOption} from "../../../../../widgets/input/selectslider/SelectSlider";
import {SelectSlider} from "../../../../../widgets/input/selectslider/SelectSlider";
import {AssignType} from "../../../../settings/modules/field/FieldForm";
import {XHRLoader} from "../../../../../../utils/loader/XHRLoader";
import {MassInputs} from "../../../../../widgets/input/clicktoedit/InputUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import {SideBar} from "../../../../../sidebar/SideBar";
import {KeyboardListener} from "../../../../../../utils/interaction/key/KeyboardListener";
import type {UserGroup} from "../../../../../../data/models/UserGroup";
import {NavigationEnum} from "../../../../../../Enums";
import type {BoundarySpaceMap} from "../../../../../../data/models/BoundarySpaceMap";
import {getFieldIconAndInheritance} from "./field/FieldInputUtils";
import {LookupUtils} from "./LookupUtils";
import {MassFieldLookupInput} from "./field/mass/datatypes/MassFieldLookupInput";
import {PropertiesSection} from "./PropertiesSection";
import {MarkupDefaultFields} from "./default/MarkupDefaultFields";
import {SpaceThumbnail} from "./default/spacethumbnail/SpaceThumbnail";
import {PortsSection} from "./PortsSection";
import {LinksSection} from "./LinksSection";
import {SpaceDefaultFields} from "./default/SpaceDefaultFields";
import {CatalogDefaultFields} from "./default/CatalogDefaultFields";
import {BoundaryDefaultFields} from "./default/BoundaryDefaultFields";
import {MultiDefaultFields} from "./default/MultiDefaultFields";
import {PortfolioDefaultFields} from "./default/PortfolioDefaultFields";
import {ReportDefaultFields} from "./default/ReportDefaultFields";
import {XyiconDefaultFields} from "./default/XyiconDefaultFields";
import {DocumentSection} from "./documents/DocumentSection";
import {MassFieldInput} from "./field/mass/MassFieldInput";
import {SingleFieldInput} from "./field/single/SingleFieldInput";
import {ReportScopeSection} from "./report/ReportScopeSection";
import {ReportSharingSection} from "./report/ReportSharingSection";
import type {IPropertyDetails} from "./field/mass/IMassInput";

export interface ILookupFieldOption {
	value: string;
	xyicon: Xyicon;
}

interface IDetailsTabProps<T extends IModel = IModel> {
	readonly items: T[];
	readonly onSelect?: (item: T[]) => void;
	readonly feature: XyiconFeature;
	readonly features: XyiconFeature[];
	readonly isPortTemplateEditorOpen: boolean;
	readonly setPortTemplateEditorOpen: (value: boolean) => void;
	readonly onCatalogIconEditClick?: (catalog: Catalog) => void;
	readonly closeWideSearchPanel?: () => void;
	readonly noInitials?: boolean;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly navigation?: Navigation;
	readonly insideDetailsContainer?: boolean;
}

interface IDetailsTabState<T extends IModel = IModel> {
	hoveringModel: T;
	openFieldRefId: string;
	isSpaceListLoading: boolean;
	headerIsAnchored: boolean;
	headerMinimized: boolean;
	focusedFieldParentSectionLabel: string;
}

const defaultFieldComponents: FeatureMap<React.ComponentClass<{item: IModel; permission?: Permission}>> = {
	[XyiconFeature.Portfolio]: PortfolioDefaultFields,
	[XyiconFeature.XyiconCatalog]: CatalogDefaultFields,
	[XyiconFeature.Space]: SpaceDefaultFields,
	[XyiconFeature.Boundary]: BoundaryDefaultFields,
	[XyiconFeature.Xyicon]: XyiconDefaultFields,
	[XyiconFeature.Report]: ReportDefaultFields,
	[XyiconFeature.Markup]: MarkupDefaultFields,
};

const lookupPrefix = "lookup-";
const logId: string = "Rendering DetailsTab UI";

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class DetailsTab<T extends IModel = IModel> extends React.Component<IDetailsTabProps<T>, IDetailsTabState<T>> {
	private _previousItems: T[] = [];
	private _ref = React.createRef<HTMLDivElement>();
	private _disposer: Lambda;
	private _sectionFields: string[] = [];
	private _disposerForPortfolioIdListener: Lambda | null = null;
	private _sectionFieldsRefArray: {label: string; ref: React.RefObject<ToggleContainer>}[] = [];
	private _onMouseDownTargetElement: Element = null;
	private _headerRef = React.createRef<HTMLDivElement>();

	public _assignedTypes: ISelectSliderOption[] = [
		{
			id: AssignType.Unassigned,
			label: AssignType[AssignType.Unassigned],
		},
		{
			id: AssignType.Assigned,
			label: AssignType[AssignType.Assigned],
		},
	];

	public static getDerivedStateFromProps(props: IDetailsTabProps, state: IDetailsTabState) {
		// if selection changed:
		// forget openFieldRefId if it's not in current fields anymore or if there is 1 or no items selected
		const items = DetailsTab.getSanitizedItems(props.items ?? []);

		if (items?.length < 2 || !DetailsTab.getFieldRefIds(props).includes(state.openFieldRefId)) {
			return {
				openFieldRefId: "",
			} as IDetailsTabState;
		}

		return null;
	}

	private static getFieldRefIds(props: IDetailsTabProps, feature?: XyiconFeature) {
		const {items, appState} = props;

		const fieldRefIdsForType: string[] = [];

		for (const item of items) {
			if (item?.typeId) {
				const newRefIds = appState.actions.getFieldRefIdsForType(item.typeId, feature ?? item.ownFeature);

				for (const refId of newRefIds) {
					if (!fieldRefIdsForType.includes(refId)) {
						fieldRefIdsForType.push(refId);
					}
					if (LookupUtils.isLookupField(appState.actions.getFieldByRefId(refId) as FieldModel, item, appState.actions)) {
						fieldRefIdsForType.push(`${lookupPrefix}${refId}`);
					}
				}
			}
		}

		return fieldRefIdsForType;
	}

	constructor(props: IDetailsTabProps<T>) {
		super(props);
		this.state = {
			isSpaceListLoading: true,
			hoveringModel: null,
			openFieldRefId: "",
			headerIsAnchored: false,
			headerMinimized: true,
			focusedFieldParentSectionLabel: "",
		};
	}

	private static getSanitizedItems(items: IModel[]): IModel[] {
		// Don't add the same element twice
		// Eg.: 2 boundaryspacemaps are selected, but their parent is the same boundary
		return ArrayUtils.removeDuplicates(
			items.map((item) => ((item as BoundarySpaceMap).isBoundarySpaceMap ? (item as BoundarySpaceMap).parent : item)),
		);
	}

	private get items(): T[] {
		return DetailsTab.getSanitizedItems(this.props.items as IModel[]) as T[];
	}

	private renderDefaultFields() {
		const DefaultFields = defaultFieldComponents[this.props.feature];

		if (DefaultFields) {
			const item = this.items[0];

			return <DefaultFields item={item} />;
		}
		return null;
	}

	private renderInitialComponent(feature: XyiconFeature) {
		const {appState, onCatalogIconEditClick} = this.props;

		const item = this.items[0];
		const itemType = appState.actions.getTypeById(item?.typeId);
		const color = itemType?.settings.color.hex || "FFFFFF";

		return (
			<Initials
				item={item}
				color={color}
				name={itemType?.name}
				onCatalogIconEditClick={onCatalogIconEditClick}
				onSpaceIconClick={this.onSpaceClick}
				headerMinimized={this.state.headerIsAnchored}
			/>
		);
	}

	private onSelectPropagatedSource = (model: IModel) => {
		if (this.props.features.includes(model.ownFeature)) {
			this.props.onSelect?.([model] as T[]);
		}
	};

	private onHoverPropagatedValue = (model: T) => {
		this.setState({hoveringModel: model});
	};

	private onOpenMultiInput = (refId: string) => {
		this.setState({openFieldRefId: refId});
	};

	private getLayoutSections() {
		const {feature, features} = this.props;

		if (this.items.length > 1 && features.length > 1) {
			return [...this.getSections(XyiconFeature.Boundary), ...this.getSections(XyiconFeature.Xyicon)];
		} else {
			return this.getSections(feature);
		}
	}

	private getSections(feature: XyiconFeature) {
		const layout = this.props.appState.layouts[feature];
		const sections: ISectionData[] = layout ? layout.sections : [];

		return sections.map((section) => {
			return {
				feature: feature,
				section: section,
			};
		});
	}

	private getAssignedList(feature: XyiconFeature) {
		const {appState} = this.props;

		if (!this.items[0] || !appState.user?.isAdmin || this.props.feature !== XyiconFeature.XyiconCatalog) {
			return [];
		}

		return DetailsTab.getFieldRefIds(this.props, feature).filter((f) => this.props.appState.actions.getFieldByRefId(f)?.isAssignedByModel);
	}

	private getSliderRowsFromFieldList(list: string[], items: T[], feature: XyiconFeature) {
		const {appState} = this.props;
		const catalogs = items as IModel[] as Catalog[];
		// mapping through all the items and get the necessary data, don't need to run this for every refId
		const dataArray = catalogs.map((cat) => {
			const visibleFields = (feature === XyiconFeature.Xyicon ? cat.xyiconVisibleFields : cat.catalogVisibleFields) as string[];
			const assignByModelList = appState.actions
				.getFieldRefIdsForType(cat.typeId, feature)
				.filter((f) => appState.actions.getFieldByRefId(f).isAssignedByModel);

			return {visibleFields, assignByModelList};
		});

		return list.map((refId) => {
			const field = appState.actions.getFieldByRefId(refId);
			const isFieldUnassignedToAnyItem = dataArray.some((el) => el.assignByModelList.includes(refId) && !el.visibleFields.includes(refId));

			return {
				label: field.name,
				value: isFieldUnassignedToAnyItem ? AssignType.Unassigned : AssignType.Assigned,
				disabledOptionsList: [],
			};
		});
	}

	private onSliderChange = async (fieldNames: string[], value: AssignType, feature: XyiconFeature) => {
		const {appState} = this.props;
		const {items} = this;
		// the visible sliders are the first item's assigned list
		// we need to change the same fields in other Catalog items (if they have it)
		const fieldRefIdsToChange = fieldNames.map((rowKey) => appState.actions.getFieldByName(feature, rowKey).refId);
		const updatedXyiconCatalogs: XyiconCatalogSettingsModel[] = [];

		items.forEach((item: IModel) => {
			fieldRefIdsToChange.forEach((fieldRefId) => {
				const visibleFields =
					feature === XyiconFeature.XyiconCatalog ? (item as Catalog).catalogVisibleFields : (item as Catalog).xyiconVisibleFields;
				const assignByModelList = appState.actions
					.getFieldRefIdsForType(item.typeId, feature)
					.filter((f) => appState.actions.getFieldByRefId(f).isAssignedByModel);

				if (assignByModelList.includes(fieldRefId)) {
					if (value === AssignType.Unassigned) {
						ArrayUtils.removeMutable(visibleFields, fieldRefId);
					} else {
						ArrayUtils.addMutable(visibleFields, fieldRefId);
					}
				}
			});

			updatedXyiconCatalogs.push({xyiconCatalogID: item.id, settings: (item as Catalog).settings});
		});

		const {result, error} = await this.props.transport.requestForOrganization<XyiconCatalogUpdateSettingsDto>({
			url: "xyiconcatalogs/updatesettings",
			method: XHRLoader.METHOD_POST,
			params: {
				updatedXyiconCatalogs,
			},
		});

		if (error) {
			console.warn(error);
		}
	};

	private getProperties(item: Xyicon | Boundary) {
		const properties: IPropertyDetails[] = [];

		properties.push({
			name: "Position",
			measure: true,
			parts: [
				{
					name: "px",
					label: "X",
					ref: "x",
				},
				{
					name: "py",
					label: "Y",
					ref: "y",
				},
				{
					name: "pz",
					label: "Z",
					ref: "z",
				},
			],
		});

		if (item.ownFeature === XyiconFeature.Boundary) {
			properties.push(
				{
					name: "Dimensions",
					measure: true,
					parts: [
						{
							name: "dx",
							label: "X",
							ref: "dimensionX",
						},
						{
							name: "dy",
							label: "Y",
							ref: "dimensionY",
						},
						{
							name: "dz",
							label: "Z",
							ref: "dimensionZ",
						},
					],
				},
				{
					name: "Area",
					measure: false,
					parts: [],
				},
			);
		}

		properties.push({
			name: "Rotation",
			measure: false,
			parts: [
				{
					name: "o",
					label: "Z",
					ref: "orientation",
				},
			],
		});

		return properties;
	}

	private onLookupLinkedFieldsClick = (value: string) => {
		this.props.appState.selectedFieldInputRefId = value || "";
	};

	private renderFields() {
		const {appState, feature, transport, closeWideSearchPanel} = this.props;
		const {items} = this;
		const {openFieldRefId, focusedFieldParentSectionLabel} = this.state;
		const actions = appState.actions;
		const firstItem = items[0];
		const multiSelection = items.length > 1;
		const fieldRefIdsForType = DetailsTab.getFieldRefIds(this.props);
		const sections = this.getLayoutSections();

		this._sectionFields.length = 0;
		this._sectionFieldsRefArray.length = 0;

		return sections.map((sectionData, index) => {
			// Only display fields that are assigned to the given type, unless they are inherited or default fields
			// Inherited fields don't need to be mapped to the selected items type.
			// Examples for inherited fields:
			// - xyicons inherit portfolio fields, in this case if the portfolio fields are added in the layout definition
			// they are displayed, regardless of the type of the selected item.
			// - linked fields

			const section = sectionData.section;
			const sectionFields = section.fields.filter((sectionField: ISectionField) => {
				const field = actions.getFieldByRefId(sectionField.id);

				if (!field) {
					// field not found (may have been deleted)
					return false;
				}

				const isDefault = field.default;
				// no need to check permission if the field is default
				const hasPermission = isDefault ? true : actions.getFieldPermission(field, items) !== Permission.None;

				if (hasPermission) {
					// is this needed?
					if (field.refId.includes("versionName") || field.refId.includes("issuanceDate")) {
						return false;
					}

					const isMappedToType = fieldRefIdsForType.includes(field.refId);

					if (multiSelection) {
						if (isDefault || field.feature !== sectionData.feature || field.hasFormula) {
							// No default, calculated or inherited fields in multi selection
							return false;
						}
						return isMappedToType && items.some((item) => !actions.isFieldHiddenByMasking(item, field));
					} else {
						if (!isDefault && field.feature === feature) {
							// Custom fields that belong to this feature will be shown if:
							// - the type is mapped correctly OR
							// - there are propagated non-null values from linked objects (eg. boundary - boundary)
							return (
								(isMappedToType && !actions.isFieldHiddenByMasking(firstItem, field)) || actions.getDynamicFieldPropagations(firstItem, field)?.length
							);
						} else {
							// field is default or belongs to another feature
							return actions.isFieldShownForFeature(field, feature) && (isDefault || actions.getFieldPropagations(firstItem, field)?.length);
						}
					}
				} else {
					return false;
				}
			});

			if (sectionFields.length > 0) {
				// Check if this ToggleContainer should be in collapsed state because of tabbing in a collapsed fieldSection
				const collapsed = focusedFieldParentSectionLabel === section.label && !this.getSectionFieldParentSectionStatusByLabel(section.label);
				const ref = React.createRef<ToggleContainer>();

				this._sectionFieldsRefArray.push({label: section.label, ref});

				return (
					<ToggleContainer
						key={`${items.map((item) => item?.id).join("_")}_${section.label}_${index}`}
						title={section.label}
						saveStateToLocalStorage={true}
						className={ReactUtils.cls("FieldSection", {collapsed})}
						ref={ref}
					>
						{sectionFields.map((sectionField: ISectionField) => {
							const field = actions.getFieldByRefId(sectionField.id);
							const elements: React.ReactElement[] = [];
							const lookupLinkFields: T[] = [];
							const notLookupLinkFields: T[] = [];
							// If only 1 item is selected, then no need to calculate the permission for 'disabled' prop. This variable is enough to pass
							const shouldFieldBeDisabledByPermission = actions.getFieldPermission(field, items) < Permission.Update;

							items.forEach((item) => {
								LookupUtils.isLookupField(field, item, actions) ? lookupLinkFields.push(item) : notLookupLinkFields.push(item);
							});

							if (lookupLinkFields.length > 0) {
								// This is a lookup field!
								const lookupFieldOptions = LookupUtils.getLookupFieldOptions(appState, field, lookupLinkFields[0]);
								const selectedLookupFieldOptions = LookupUtils.getSelectedLookupFieldOptions(appState, lookupFieldOptions, lookupLinkFields[0]);
								const onChange = LookupUtils.getOnChangeForLookup(transport, appState, lookupFieldOptions, lookupLinkFields[0]);
								const prefixedRefId = `${lookupPrefix}${field.refId}`;

								// If notLookupLinkFields.length === 0, the shouldMassInputBeDisabled === shouldFieldBeDisabledByPermission.
								// Only need to calculate fieldPermission (disability) again, if there's lookup and non-lookup fields too
								const shouldMassInputBeDisabled =
									multiSelection &&
									(notLookupLinkFields.length > 0
										? actions.getFieldPermission(field, lookupLinkFields) < Permission.Update
										: shouldFieldBeDisabledByPermission);

								if (!shouldFieldBeDisabledByPermission && !field.hasFormula && !field.default && field.feature === feature) {
									ArrayUtils.addMutable(this._sectionFields, field.refId);
								}

								elements.push(
									!multiSelection ? (
										<Field
											key={field.refId + field.name}
											label={field.name}
											disabled={shouldFieldBeDisabledByPermission}
											noWrap={false}
											icons={{preLabelIcon: "lookupField"}}
											tooltips={{
												preLabelIconTooltip:
													"This field's value is inherited from a linked object. A lookup field allows you to create or break links from within it.",
											}}
											className={ReactUtils.cls({calculated: field.hasFormula})}
										>
											<MultiSelectInput
												key={field.refId}
												options={lookupFieldOptions}
												selected={selectedLookupFieldOptions}
												render={(obj) => (
													<div className="hbox alignCenter">
														{obj.value}&nbsp;<span style={{color: "#A0A0A0"}}>({obj.xyicon.refId})</span>
													</div>
												)}
												onChange={onChange}
												disabled={shouldFieldBeDisabledByPermission}
												focused={
													appState.selectedFieldInputRefId === field.refId && appState.isDetailsContainerOpened === this.props.insideDetailsContainer
												}
												onClick={() => this.onLookupLinkedFieldsClick(field.refId)}
											/>
										</Field>
									) : (
										<MassFieldLookupInput
											key={prefixedRefId}
											field={field}
											prefixedRefId={prefixedRefId}
											items={lookupLinkFields}
											open={openFieldRefId === prefixedRefId}
											onOpen={this.onOpenMultiInput}
											selectableOptions={lookupFieldOptions}
											selectedOptions={selectedLookupFieldOptions}
											disabled={shouldMassInputBeDisabled}
										/>
									),
								);
							}

							if (notLookupLinkFields.length > 0) {
								const MassInputComponent = MassInputs[field.dataType] || MassFieldInput;
								const {icon, isXyiconXyiconLink, isInheritedFromBoundary} =
									!multiSelection && getFieldIconAndInheritance(field, firstItem, sectionData.feature, actions);

								// If lookupLinkFields.length === 0, the shouldMassInputBeDisabled === shouldFieldBeDisabledByPermission.
								// Only need to calculate fieldPermission (disability) again, if there's lookup and non-lookup fields too
								const shouldMassInputBeDisabled =
									multiSelection &&
									(lookupLinkFields.length > 0
										? actions.getFieldPermission(field, notLookupLinkFields) < Permission.Update
										: shouldFieldBeDisabledByPermission);

								if (!shouldFieldBeDisabledByPermission && !field.hasFormula && !field.default && field.feature === feature) {
									ArrayUtils.addMutable(this._sectionFields, field.refId);
								}

								elements.push(
									multiSelection ? (
										<MassInputComponent
											key={field.refId}
											field={field}
											items={notLookupLinkFields}
											open={openFieldRefId === field.refId}
											onOpen={this.onOpenMultiInput}
											numberOfSelectedItems={items.length}
											icon={icon}
											disabled={shouldMassInputBeDisabled}
										/>
									) : (
										<SingleFieldInput
											key={field.refId}
											item={firstItem}
											field={field}
											className={ReactUtils.cls({inherited: icon === "dol-field"})}
											fieldRefIdsForType={fieldRefIdsForType}
											noWrap={sectionField.noWrap}
											feature={feature}
											hoveringModel={this.state.hoveringModel}
											onHoverPropagatedValue={this.onHoverPropagatedValue}
											onSelectPropagatedSource={this.onSelectPropagatedSource}
											disabled={shouldFieldBeDisabledByPermission}
											icon={icon}
											isXyiconXyiconLink={isXyiconXyiconLink}
											isInheritedFromBoundary={isInheritedFromBoundary}
											closeWideSearchPanel={closeWideSearchPanel}
										/>
									),
								);
							}

							return elements;
						})}
					</ToggleContainer>
				);
			}
			return null;
		});
	}

	private getFieldInputRefId = (offset: number): string => {
		const {appState} = this.props;

		let index = this._sectionFields.indexOf(appState.selectedFieldInputRefId) + offset;

		if (index === this._sectionFields.length) {
			index = 0;
		} else if (index === -1) {
			// This is happening if the user is shift+tab at the first index
			index = this._sectionFields.length - 1;
		}

		if (appState.selectedFieldInputRefId) {
			return this._sectionFields.at(index) || "";
		}
	};

	private getSectionFieldParentSectionByRefId = (refId: string): ISectionData => {
		const sections = this.getLayoutSections();

		return sections.find((section) => section.section.fields.find((f) => f.id === refId)).section;
	};

	private getSectionFieldParentSectionStatusByLabel = (label: string): boolean => {
		return localStorage.getItem(`srv4-org-${this.props.appState.organizationId}-feature-${SideBar.activeNav}-section-${label}-state`) === "true";
	};

	private onKeyDown = (e: KeyboardEvent) => {
		const {appState} = this.props;
		const {focusedFieldParentSectionLabel} = this.state;
		const sections = this.getLayoutSections();
		const isTargetGeoLatInput = (e.target as HTMLInputElement).classList.contains("geoLat");

		let targetElement: Element = e.target as Element;

		const realParentId = targetElement.parentElement.dataset?.realparentid;

		if (realParentId) {
			targetElement = document.getElementById(realParentId);
		}

		const isTargetInDetailsContainer = document.querySelector(".DetailsContainer").contains(targetElement);

		if (
			!!this.props.insideDetailsContainer === isTargetInDetailsContainer &&
			isTargetInDetailsContainer === appState.isDetailsContainerOpened &&
			!isTargetGeoLatInput &&
			(!!appState.selectedFieldInputRefId || focusedFieldParentSectionLabel !== "")
		) {
			AppUtils.disableScrolling(false);

			let nextFieldInputRefId = "";
			let isOffsetNegative = 1;
			let focusedFieldParentSectionLabelVar = "";

			if (e.key === KeyboardListener.KEY_TAB) {
				e.preventDefault();

				if (e.shiftKey) {
					isOffsetNegative *= -1;
				}

				nextFieldInputRefId = this.getFieldInputRefId(isOffsetNegative);

				if (focusedFieldParentSectionLabel !== "") {
					const nextSectionFields = sections.at(
						sections.indexOf(sections.find((s) => s.section.label === focusedFieldParentSectionLabel)) + isOffsetNegative,
					).section.fields;

					nextFieldInputRefId = nextSectionFields[nextSectionFields.length - 2].id;
				} else {
					const nextFieldParentSection = this.getSectionFieldParentSectionByRefId(nextFieldInputRefId);
					const nextFieldParentSectionLabel = nextFieldParentSection.label;
					const isNextFieldsParentSectionOpened = this.getSectionFieldParentSectionStatusByLabel(nextFieldParentSectionLabel);

					if (!isNextFieldsParentSectionOpened) {
						focusedFieldParentSectionLabelVar = nextFieldParentSectionLabel;
						nextFieldInputRefId = "";
					}
				}

				appState.selectedFieldInputRefId = nextFieldInputRefId;
			} else if ((e.code === KeyboardListener.CODE_SPACE || e.key === KeyboardListener.KEY_ENTER) && focusedFieldParentSectionLabel !== "") {
				e.preventDefault();

				// Open closed section
				this._sectionFieldsRefArray.find((s) => s.label === focusedFieldParentSectionLabel)?.ref.current.onToggleOpen();

				// Select the first field
				nextFieldInputRefId = sections.find((s) => s.section.label === focusedFieldParentSectionLabel).section.fields[0].id;

				appState.selectedFieldInputRefId = nextFieldInputRefId;
			}

			this.setState({
				focusedFieldParentSectionLabel: focusedFieldParentSectionLabelVar,
			});
		}
	};

	private onMouseDown = (e: MouseEvent) => {
		// This is needed to check if the user clicks out of the field, or just selecting the fieldValue and ends the selection out of the input
		this._onMouseDownTargetElement = e.target as Element;
	};

	private onDocumentClick = (e: MouseEvent) => {
		// This selector only selects the already focused field, when clicking into it
		// This is needed to prevent focusloss when clicking into the edited field
		const currentlyEditedFieldInput = document.querySelector(".ClickToEditInput.editing");
		const eventTargetInModalContainer = this.props.appState.app.modalContainer.contains(e.target as Element);
		// const singleSelectClick = this._onMouseDownTargetElement?.className === "list-search FindInList"; // only singleSelect field
		// const otherFieldClickOut = (this._onMouseDownTargetElement !== e.target && (currentlyEditedFieldInput && !currentlyEditedFieldInput.contains(e.target as Element)));

		const hasMouseDownHappenOnCurrentlyEditedFieldInput = currentlyEditedFieldInput?.contains(this._onMouseDownTargetElement);

		// singleSelect works differently from other fields, it should be blurred and selectedFieldInputRefId should be set to ""
		if (e.target instanceof Element && !eventTargetInModalContainer && !hasMouseDownHappenOnCurrentlyEditedFieldInput) {
			this.props.appState.selectedFieldInputRefId = "";
		}
	};

	private getPermissionToSeeReportSharing = () => {
		const {appState} = this.props;
		const {items} = this;
		const {user, actions} = appState;
		const report = items[0] as IModel as Report;

		if (user?.isAdmin) {
			return true;
		}

		return report.sharingSettings.some((sharing) => {
			const canEdit = sharing.canEditSharedReport;

			if (sharing.userID === user?.id && canEdit) {
				return true;
			}

			const userGroup = actions.getFeatureItemById<UserGroup>(sharing.userGroupID, XyiconFeature.UserGroup);

			if (userGroup?.userIds.includes(user?.id) && canEdit) {
				return true;
			}
			return false;
		});
	};

	public override async componentDidMount() {
		// Also refresh when active portfolio changes
		this._disposerForPortfolioIdListener = observe(this.props.appState, "portfolioId", async () => {
			this.props.transport.services.feature.refreshList(XyiconFeature.Link);
			this.setState({isSpaceListLoading: true});

			try {
				await this.refreshSpaces();
			} catch (error) {
				console.warn(error);
			}

			this.setState({isSpaceListLoading: false});
		});

		// Refresh lists on mount (if needed)
		this.props.transport.services.feature.refreshList(XyiconFeature.Link);
		await this.refreshSpaces();
		this.setState({isSpaceListLoading: false});

		this._ref.current.addEventListener("scroll", this.onScroll);
		document.addEventListener("keydown", this.onKeyDown);
		document.addEventListener("mousedown", this.onMouseDown);
		document.addEventListener("click", this.onDocumentClick);
	}

	private onScroll = () => {
		const {headerIsAnchored} = this.state;
		const limit = 0;

		if (this._ref.current.scrollTop > limit && !headerIsAnchored) {
			this.setState({headerIsAnchored: true});
		} else if (this._ref.current.scrollTop <= limit && headerIsAnchored) {
			this.setState({headerIsAnchored: false, headerMinimized: true});
		}
	};

	public override componentWillUnmount() {
		this._disposer?.();
		this._disposer = null;

		document.removeEventListener("keydown", this.onKeyDown);
		document.removeEventListener("click", this.onDocumentClick);
		document.removeEventListener("mousedown", this.onMouseDown);
		this._disposerForPortfolioIdListener?.();
		this._disposerForPortfolioIdListener = null;
		this._ref.current.removeEventListener("scroll", this.onScroll);

		this.props.appState.selectedFieldInputRefId = "";
	}

	private onSpaceClick = (spaceId: string) => {
		this.props.navigation.goApp(NavigationEnum.NAV_SPACE, spaceId);
	};

	private getThumbnail(item: Space, index: number) {
		const fullURL = item.thumbnailFileURL;

		return (
			<div
				className="spaceThumb"
				key={index}
			>
				<div className="headline">{item.name}</div>
				{
					<SpaceThumbnail
						url={fullURL}
						spaceId={item.id}
						onClick={this.onSpaceClick}
					/>
				}
			</div>
		);
	}

	private refreshSpaces = async () => {
		if (this.props.feature === XyiconFeature.Portfolio) {
			(await this.props.transport.services.feature.refreshList(XyiconFeature.Space)) as Space[];
		}
	};

	private onAddClick = async () => {
		this.props.navigation.go("app/spaces");

		//This is an ugly hack to open the "create spaces panel"
		//TODO: make not hacky solution
		await TimeUtils.wait(1000);

		const createSpaceButton = document.querySelector('[title="Create Space"]') as HTMLElement;

		if (createSpaceButton) {
			createSpaceButton.click();
		} else {
			console.warn("No button with title Create Space");
		}
	};

	public override componentDidUpdate() {
		// scroll to the top if the selection changes
		const {items} = this;
		if (!ObjectUtils.compare(this._previousItems, items)) {
			if (this._ref.current?.parentElement?.scrollTop) {
				this._ref.current.parentElement.scrollTop = 0;
			}
			this._previousItems = items;

			DebugInformation.end(logId);
		}
	}

	public renderDefaultHeaderDiv(anchoredOne: boolean) {
		const {feature, noInitials} = this.props;
		const {headerIsAnchored, headerMinimized} = this.state;
		const {items} = this;

		return (
			<div
				className={ReactUtils.cls("header", {
					noInitials,
					[featureTitles[feature]]: true,
					visible: headerIsAnchored,
					anchored: anchoredOne,
					minimized: anchoredOne && headerMinimized && headerIsAnchored,
				})}
				ref={this._headerRef}
			>
				{items.length === 1 ? (
					<>
						{this.renderDefaultFields()}
						{!noInitials && this.renderInitialComponent(feature)}
					</>
				) : (
					<>
						<h4>
							<span className="multiSelectHeader">{items.length} Objects selected</span>
						</h4>
						<MultiDefaultFields items={items} />
					</>
				)}
				{anchoredOne && (
					<IconButton
						className="toggler"
						icon="angle_down"
						onClick={() => this.setState((prevState) => ({headerMinimized: !prevState.headerMinimized}))}
					/>
				)}
			</div>
		);
	}

	public override render() {
		DebugInformation.start(logId);
		const {feature, appState, insideDetailsContainer} = this.props;
		const spaces = appState.actions.getList<Space>(XyiconFeature.Space);
		const {spaceViewRenderer} = appState.app;
		const {items} = this;
		const firstItem = items[0];

		const catalogFieldList = this.getAssignedList(XyiconFeature.XyiconCatalog);
		const xyiconFieldList = this.getAssignedList(XyiconFeature.Xyicon);

		return (
			<>
				<div
					className={ReactUtils.cls("DetailsTab", {multiSelect: items.length > 1})}
					ref={this._ref}
				>
					{items.length === 0 && <div className="noData">{"Please select one or more objects to display details."}</div>}
					{items.length > 0 && (
						<>
							{this.renderDefaultHeaderDiv(true)}
							{this.renderDefaultHeaderDiv(false)}
						</>
					)}
					{items.length > 0 && ![XyiconFeature.Report, XyiconFeature.Markup].includes(feature) && (
						<ToggleContainer
							title="Fields"
							saveStateToLocalStorage={true}
						>
							{this.renderFields()}
						</ToggleContainer>
					)}
					{items.length === 1 && catalogFieldList.length > 0 && (
						<ToggleContainer
							title="Assign Fields by Catalog Model"
							saveStateToLocalStorage={true}
						>
							<SelectSlider
								options={this._assignedTypes}
								rows={this.getSliderRowsFromFieldList(catalogFieldList, items, XyiconFeature.XyiconCatalog)}
								onChange={(fieldNames: string[], value: AssignType) => this.onSliderChange(fieldNames, value, XyiconFeature.XyiconCatalog)}
							></SelectSlider>
						</ToggleContainer>
					)}
					{items.length === 1 && xyiconFieldList.length > 0 && (
						<ToggleContainer
							title="Assign Fields by Xyicon Model"
							saveStateToLocalStorage={true}
						>
							<SelectSlider
								options={this._assignedTypes}
								rows={this.getSliderRowsFromFieldList(xyiconFieldList, items, XyiconFeature.Xyicon)}
								onChange={(fieldNames: string[], value: AssignType) => this.onSliderChange(fieldNames, value, XyiconFeature.Xyicon)}
							></SelectSlider>
						</ToggleContainer>
					)}
					{items.length === 1 && (
						<>
							{feature === XyiconFeature.Portfolio && (
								<ToggleContainer
									title="Spaces"
									saveStateToLocalStorage={true}
								>
									{this.state.isSpaceListLoading ? (
										<div className="loadingSpaces">
											<LoaderIcon />
											<span className="loadText">Loading spaces...</span>
										</div>
									) : spaces.length === 0 ? (
										<div className="noDataSection">
											<EmptyListView
												onAddClick={this.onAddClick}
												feature={XyiconFeature.Space}
											/>
										</div>
									) : (
										<div className="spaceThumbs vbox">
											{spaces
												.toSorted((a: Space, b: Space) => StringUtils.sortIgnoreCase(a.name, b.name))
												.map((item, index) => {
													return this.getThumbnail(item, index);
												})}
										</div>
									)}
								</ToggleContainer>
							)}
							<LinksSection
								item={firstItem}
								feature={feature}
								saveStateToLocalStorage={true}
							/>
							{feature !== XyiconFeature.Report && (
								<PortsSection
									item={firstItem}
									feature={feature}
									isPortTemplateEditorOpen={this.props.isPortTemplateEditorOpen}
									setPortTemplateEditorOpen={this.props.setPortTemplateEditorOpen}
									saveStateToLocalStorage={true}
								/>
							)}
						</>
					)}
					{items.length > 0 && (
						<DocumentSection
							items={items}
							feature={feature}
							saveStateToLocalStorage={true}
						/>
					)}
					{items.length === 1 && feature === XyiconFeature.Report && this.getPermissionToSeeReportSharing() && (
						<>
							<ReportScopeSection report={firstItem as IModel as Report} />
							<ReportSharingSection report={firstItem as IModel as Report} />
						</>
					)}
					{items.length > 0 &&
						spaceViewRenderer.isMounted &&
						!insideDetailsContainer &&
						items.every(
							(item) =>
								(item.ownFeature === XyiconFeature.Xyicon && !(item as IModel as Xyicon).isEmbedded) ||
								item.ownFeature === XyiconFeature.Boundary ||
								item.ownFeature === XyiconFeature.Markup,
						) && (
							<PropertiesSection
								saveStateToLocalStorage={true}
								properties={this.getProperties(firstItem as IModel as Xyicon)} // doesn't get dimension if both are selected because the order depends on SpaceItemController selectedItems getter
							/>
						)}
				</div>
			</>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {Portfolio} from "../../../../../../../data/models/Portfolio";
import {Field} from "../../../../../../widgets/form/field/Field";
import type {TransportLayer} from "../../../../../../../data/TransportLayer";
import {ClickToEditInput} from "../../../../../../widgets/input/clicktoedit/ClickToEditInput";
import {FieldDataType, Permission} from "../../../../../../../generated/api/base";
import type {AppState} from "../../../../../../../data/state/AppState";
import {TimeUtils} from "../../../../../../../utils/TimeUtils";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import type {Type} from "../../../../../../../data/models/Type";
import {ConfirmWindow} from "../../../../popups/ConfirmWindow";
import {Functions} from "../../../../../../../utils/function/Functions";

interface IPortfolioDefaultFieldsProps {
	readonly item: Portfolio;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IPortfolioDefaultFieldsState {
	updating: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class PortfolioDefaultFields extends React.Component<IPortfolioDefaultFieldsProps, IPortfolioDefaultFieldsState> {
	constructor(props: IPortfolioDefaultFieldsProps) {
		super(props);
		this.state = {
			updating: false,
		};
	}

	private onNameChange = async (value: string) => {
		const {item, transport} = this.props;

		item.name = value;

		this.setState({updating: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateFields([item], {name: value}), transport.appState.app.notificationContainer);
		this.setState({updating: false});
	};

	private isNameValid = (name: string) => {
		const {appState, item} = this.props;

		return appState.actions.isPortfolioNameValid(name, item.id);
	};

	private getErrorMessage = (name: string) => {
		return !name.trim() ? "Name cannot be empty!" : this.isNameValid(name) ? "" : "Name needs to be unique!";
	};

	private onTypeChange = async (type: Type) => {
		const {item} = this.props;

		const title = "Confirm Portfolio Type Change";
		const message =
			"Once you change the portfolio type, fields (and data) not assigned to the new portfolio type will be removed. Do you wish to continue?";
		const config = {
			ok: "Change",
			cancel: "Cancel",
		};

		const confirmed = await ConfirmWindow.open(message, title, config);

		if (confirmed) {
			item.typeId = type.id;
			await this.props.transport.updatePortfolioType(item);
		}
	};

	private get permission() {
		return this.props.appState.actions.getPortfolioPermission(this.props.item.id);
	}

	public override render() {
		const {item, appState} = this.props;
		const {updating} = this.state;

		return (
			<>
				<Field label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</Field>
				<Field
					className="TypeSelector"
					label="Type"
				>
					<SelectInput
						options={this.props.appState.types[item.ownFeature]}
						render={(item) => item.name}
						selected={appState.actions.getTypeById(item.typeId)}
						searchBar={true}
						onChange={this.onTypeChange}
						disabled={this.permission < Permission.Update}
					/>
				</Field>
				<Field label="Portfolio Name">
					<ClickToEditInput
						value={item.name}
						onChange={this.onNameChange}
						getErrorMessage={this.getErrorMessage}
						onBlur={Functions.emptyFunction}
						dataType={FieldDataType.SingleLineText}
						updating={updating}
						disabled={this.permission < Permission.Update}
						noButtons={true}
					/>
				</Field>
			</>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import {DateTimeInputV5} from "../../../input/datetime/DateTimeInputV5";

interface IDateParamV5Props {
	readonly param: string;
	onChange(value: string): void;
}

@observer
export class DateParamV5 extends React.Component<IDateParamV5Props> {
	public override render() {
		const {param, onChange} = this.props;

		return (
			<DateTimeInputV5
				value={param}
				onChange={onChange}
				format="datetime"
			/>
		);
	}
}

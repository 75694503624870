import * as React from "react";
import {inject, observer} from "mobx-react";
import type {FieldIconName} from "../FieldInputUtils";
import {getTooltipByIcon} from "../FieldInputUtils";
import type {AppState} from "../../../../../../../../data/state/AppState";
import {featureTitles} from "../../../../../../../../data/state/AppStateConstants";
import type {TransportLayer} from "../../../../../../../../data/TransportLayer";
import {Field} from "../../../../../../../widgets/form/field/Field";
import {XyiconFeature, FieldDataType} from "../../../../../../../../generated/api/base";
import {FieldInput} from "../../../../../../../widgets/input/clicktoedit/FieldInput";
import type {IFieldAdapter, IFieldPointer} from "../../../../../../../../data/models/field/Field";
import type {IModel, ISpaceItemModel} from "../../../../../../../../data/models/Model";
import {TimeUtils} from "../../../../../../../../utils/TimeUtils";
import type {IFieldPropagation, IXyiconLinkObject} from "../../../../../../../../data/state/AppActions";
import {XHRLoader} from "../../../../../../../../utils/loader/XHRLoader";
import type {Boundary} from "../../../../../../../../data/models/Boundary";
import type {Link} from "../../../../../../../../data/models/Link";
import {DomPortal} from "../../../../../portal/DomPortal";
import type {Xyicon} from "../../../../../../../../data/models/Xyicon";
import {IconButton} from "../../../../../../../widgets/button/IconButton";
import {filterModels} from "../../../../../../../../data/models/filter/Filter";
import {notify} from "../../../../../../../../utils/Notify";
import {NotificationType} from "../../../../../../../notification/Notification";
import {StringUtils} from "../../../../../../../../utils/data/string/StringUtils";
import type {TransformObj} from "../../../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import {CardLayoutToolTip} from "../../../../../../space/spaceeditor/ui/toolbar/CardLayoutToolTip";

interface ISingleFieldInputState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	itemIndex: number;
}

interface ISingleFieldInputProps<T extends IModel> {
	readonly item: T;
	readonly field: IFieldAdapter;
	readonly fieldRefIdsForType: IFieldPointer[];
	readonly noWrap: boolean;
	readonly feature: XyiconFeature;
	readonly hoveringModel: T;
	readonly disabled?: boolean;
	readonly icon?: FieldIconName;
	readonly className?: string;
	readonly isXyiconXyiconLink?: boolean;
	readonly isInheritedFromBoundary?: boolean;
	readonly onHoverPropagatedValue: (model: IModel) => void;
	readonly onSelectPropagatedSource: (model: IModel) => void;
	readonly closeWideSearchPanel?: () => void;

	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

@inject("appState")
@inject("transport")
@observer
export class SingleFieldInput<T extends IModel> extends React.Component<ISingleFieldInputProps<T>, ISingleFieldInputState> {
	private _fieldInputRef = React.createRef<FieldInput>();

	private _cardLayoutToolTipRef = React.createRef<HTMLDivElement>();
	private parentRefArray: HTMLDivElement[] = [];

	constructor(props: ISingleFieldInputProps<T>) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			itemIndex: -1,
		};
	}

	private onInputChange = async (value: string | number) => {
		const {item, field} = this.props;
		const {actions} = this.props.appState;

		await TimeUtils.waitUpdate(
			actions.updateFields([item], {
				[field.refId]: value,
			}),
			this.props.appState.app.notificationContainer,
		);
	};

	private getLink(propagation: IFieldPropagation) {
		let links: ({link: Link; object: Boundary} | IXyiconLinkObject)[] = [];

		if (propagation.model.ownFeature === XyiconFeature.Xyicon && this.props.item.ownFeature === XyiconFeature.Xyicon) {
			links = this.props.appState.actions.getLinksXyiconXyicon(this.props.item.id);
		} else if (propagation.model.ownFeature === XyiconFeature.Boundary && this.props.item.ownFeature === XyiconFeature.Boundary) {
			links = this.props.appState.actions.getLinksBoundaryBoundary(this.props.item.id);
		} else {
			links = this.props.appState.actions.getLinksXyiconBoundary(this.props.item.id);
		}

		return links.find((l) => l.object.refId === propagation.model.refId);
	}

	private onDetailsClick = (propagation: IFieldPropagation) => {
		this.props.appState.app.onDetailsClick?.(propagation.model);
	};

	private isVisibleInSpaceEditor = (item: IModel) => {
		const {appState} = this.props;
		const spaceEditorItems = appState.actions.getList(item.ownFeature);
		const selectedView = appState.actions.getSelectedView(XyiconFeature.SpaceEditor);
		const filteredItems = filterModels(spaceEditorItems, selectedView.filters, appState, XyiconFeature.SpaceEditor);

		return filteredItems.includes(item as unknown as IModel);
	};

	private onPinClick = (propagation: IFieldPropagation) => {
		const {feature, appState} = this.props;
		const link = this.getLink(propagation);

		this.props.appState.actions.navigateToSpaceItem(link.object, true);
		if (!this.isVisibleInSpaceEditor(propagation.model)) {
			const name = featureTitles[feature];
			const nameWithLowerCase = StringUtils.decapitalize(name);

			notify(this.props.appState.app.notificationContainer, {
				lifeTime: Infinity,
				type: NotificationType.Warning,
				title: `${name} is not visible in the active view.`,
				description: `The view's filter prevents the ${nameWithLowerCase} from displaying on the space. To make the ${nameWithLowerCase} visible, switch to another view or edit the active filter. Click the Edit Details button to update the ${nameWithLowerCase}'s fields.`,
				buttonLabel: "Edit Details",
				onActionButtonClick: () => appState.app.spaceViewRenderer.inheritedMethods.selectItems([propagation.model as ISpaceItemModel], true),
			});
		}

		this.props.closeWideSearchPanel?.();
	};

	private onBreakLinkClick = async (propagation: IFieldPropagation) => {
		const {transport} = this.props;
		const link = this.getLink(propagation);

		await transport.requestForOrganization({
			url: "xyicons/deletelink",
			method: XHRLoader.METHOD_DELETE,
			params: {
				portfolioID: transport.appState.portfolioId,
				linkIDList: [link.link.id],
			},
		});
	};

	private onMouseLeave = () => {
		this.setState({itemIndex: -1, isToolTipOpen: false});
	};

	public override UNSAFE_componentWillReceiveProps(nextProps: ISingleFieldInputProps<T>) {
		if (nextProps.item !== this.props.item) {
			const clickToEditRef = this._fieldInputRef.current?.clickToEditRef.current;
			const editingValue = clickToEditRef?.editingValue;

			if (clickToEditRef?.state.editing) {
				this.onInputChange?.(editingValue);
				clickToEditRef?.cancelLocalEdit();
			}
		}
	}

	public override componentDidUpdate(prevProps: ISingleFieldInputProps<T>, prevState: ISingleFieldInputState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this.parentRefArray[this.state.itemIndex] && this._cardLayoutToolTipRef.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this.parentRefArray[this.state.itemIndex],
					this._cardLayoutToolTipRef.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.center,
				),
			});
		}
	}

	public override render() {
		const {
			item,
			field,
			fieldRefIdsForType,
			noWrap,
			feature,
			appState,
			hoveringModel,
			disabled,
			icon,
			className,
			onHoverPropagatedValue,
			onSelectPropagatedSource,
			isInheritedFromBoundary,
			isXyiconXyiconLink,
		} = this.props;
		const {isToolTipOpen, toolTipTransform, itemIndex} = this.state;
		const actions = appState.actions;

		const value = actions.getOwnFieldValue(item, field.refId);

		const isUpdating =
			appState.itemFieldUpdateManager.isItemFieldBeingUpdated(item.id, field.refId, feature) && field.dataType !== FieldDataType.MultipleChoiceList;

		// Default fields and inherited fields are not editable
		const shouldBeDisabled = disabled || field.feature !== feature || field.default || field.hasFormula;
		const propagations = actions.getFieldPropagations(item, field);

		// Show input if feature is the same and type mapping matches or default
		// Note is there a way showInput will become false here?
		// Note for the above question: if the Input field is an inherited DOL field

		const showOwnInput =
			field.feature === feature && !actions.isFieldHiddenByMasking(item, field) && (fieldRefIdsForType.includes(field.refId) || field.default);

		const inlineStyle: React.CSSProperties = this._cardLayoutToolTipRef && {
			position: "absolute",
			top: 0,
			left: 0,
			transform: toolTipTransform?.translate,
		};

		return (
			<Field
				key={field.refId}
				label={field.name}
				disabled={shouldBeDisabled || isUpdating}
				noWrap={noWrap}
				className={className || ""}
				icons={{preLabelIcon: icon || ""}}
				tooltips={{
					labelTooltip: field.helperText,
					preLabelIconTooltip: getTooltipByIcon(icon),
				}}
			>
				<>
					{/* //Please do not delete it, it is for debugging. It shows the field's refid. */}
					{/* <div style={{position: "absolute", top: "-3px", left: "-30px", fontSize: "10px", fontWeight: 700}}>{this.props.field.refId}, {shouldBeDisabled ? "⛔" : "✔"}</div> */}
					{showOwnInput && (
						<FieldInput
							refId={shouldBeDisabled ? undefined : field.refId}
							ref={this._fieldInputRef}
							dataType={field.dataType}
							dataTypeSettings={field.dataTypeSettings}
							value={value}
							onChange={this.onInputChange}
							disabled={shouldBeDisabled}
							hasValidation={field.hasValidation}
							updating={isUpdating}
							noButtons={true}
						/>
					)}
					{propagations?.map((propagation, index) => (
						<div
							key={`${field.fieldID || field.refId} ${index}`}
							className="SpaceItemContainer hbox alignCenter"
							ref={(parentRef) => (this.parentRefArray[index] = parentRef)}
							onMouseOver={() => this.setState({itemIndex: index, isToolTipOpen: true})}
							onMouseLeave={this.onMouseLeave}
						>
							<FieldInput
								refId={field.fieldID || field.refId}
								dataType={field.dataType}
								dataTypeSettings={field.dataTypeSettings}
								value={propagation.value}
								disabled={true}
								className={`clickable ${hoveringModel === propagation.model ? "hovering" : ""}`}
								onClick={() => {
									onSelectPropagatedSource(propagation.model);
								}}
								onHover={(over = true) => {
									const model: T = over ? (propagation.model as T) : null;

									onHoverPropagatedValue(model);
								}}
							/>
							{icon && (
								<div className="buttonContainer hbox alignCenter">
									{isXyiconXyiconLink && (
										<IconButton
											icon="breakLinkClassic"
											title="Break Link"
											onClick={() => this.onBreakLinkClick(propagation)}
										/>
									)}
									{(isXyiconXyiconLink || isInheritedFromBoundary) && (
										<IconButton
											icon="pin"
											className="pinButton flexCenter"
											title="Open in Space Editor"
											onClick={() => this.onPinClick(propagation)}
											disabled={(propagation.model as Xyicon).isUnplotted}
										/>
									)}
									<IconButton
										icon="details"
										title="Details"
										onClick={() => this.onDetailsClick(propagation)}
									/>
								</div>
							)}
							{isToolTipOpen && itemIndex === index && (
								<DomPortal destination={this.props.appState.app.modalContainer}>
									<CardLayoutToolTip
										item={propagation.model as Xyicon}
										divRef={this._cardLayoutToolTipRef}
										style={inlineStyle}
									/>
								</DomPortal>
							)}
						</div>
					))}
				</>
			</Field>
		);
	}
}

import * as React from "react";
import {Slider} from "../../slider/Slider";

interface ITransparencySliderProps {
	readonly value: number;
	readonly labeled?: boolean;
	readonly color: string;
	readonly onValueChange: (newValue: number) => void;
	readonly disabled?: boolean;
	readonly sliderWidth?: number;
}

export class TransparencySlider extends React.Component<ITransparencySliderProps> {
	private onValueChange = (newValue: number) => {
		this.props.onValueChange(newValue);
	};

	public override render() {
		const sliderWidth = this.props.sliderWidth || 138;

		const colorStyle = {
			backgroundImage: `linear-gradient(to right, #${this.props.color}, rgba(0, 0, 0, 0))`,
			width: sliderWidth,
		};

		/** `after` represent the after pseudoclass, but that cannot change color dynamically from javascript, so this is a workaround for that */

		return (
			<div className="TransparencySlider">
				<Slider
					value={this.props.value}
					width={sliderWidth}
					largeStepValue={0.1}
					dataLabel={this.props.labeled ? "Transparency" : ""}
					classNames="Transparency"
					onValueChange={this.onValueChange}
					disabled={this.props.disabled}
				>
					<div
						className="after"
						style={colorStyle}
					/>
				</Slider>
			</div>
		);
	}
}

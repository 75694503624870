import styled from "styled-components";
import {InfoButtonV5} from "../../button/InfoButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import {radius} from "../../styles/styles";

interface ILinksWidgetProps {
	readonly title: string;
	readonly links: {
		caption: string;
		url: string;
		tooltip: string;
	}[];
}

export const LinksWidget = (props: ILinksWidgetProps) => {
	const {title, links} = props;

	return (
		<NewFeaturesStyled>
			<TitleStyled>{title}</TitleStyled>
			<ListStyled>
				{links.map((item, index) => (
					<div key={index}>
						<ListItemStyled>
							<a href={item.url}>{item.caption}</a>
							<InfoButtonV5
								icon="info"
								bubbleText={item.tooltip}
							/>
						</ListItemStyled>
					</div>
				))}
			</ListStyled>
		</NewFeaturesStyled>
	);
};

const ListItemStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	text-decoration-line: underline;
	text-underline-position: under;
	font-size: 16px;
	line-height: 24px;
	color: ${colorPalette.gray.c950};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
		border-radius: ${radius.sm};
	}

	a {
		color: inherit;
	}
`;

const ListStyled = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
`;

const TitleStyled = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-weight: 700;
	color: ${colorPalette.primary.c500Primary};
`;

const NewFeaturesStyled = styled.div`
	height: 100%;
	width: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {App} from "../../../../../App";
import {TableViewV5} from "../../../abstract/TableViewV5";
import {XyiconFeature} from "../../../../../generated/api/base";
import type {User} from "../../../../../data/models/User";
import type {IActionBarItem} from "../../../abstract/ModuleViewV5";
import DeleteIcon from "../../../icons/delete.svg?react";
import type {AppState} from "../../../../../data/state/AppState";
import {UserDetailsTabV5} from "./UserDetailsTabV5";
import {CreateUserPanelV5} from "./CreateUserPanelV5";

interface IUsersProps {
	readonly app?: App;
	readonly appState?: AppState;
}

@inject("app")
@inject("appState")
@observer
export class UsersViewV5 extends React.Component<IUsersProps> {
	private _headers = [
		{
			id: "fullName",
			title: "Name",
		},
		{
			id: "email",
			title: "Email",
		},
		{
			id: "status",
			title: "Status",
		},
		{
			id: "lastModifiedAt",
			title: "Last Updated",
		},
	];

	private _tableView = React.createRef<TableViewV5<User>>();

	public override componentDidMount() {
		this.props.app.moduleViews[XyiconFeature.User] = this._tableView.current;
	}

	private canDeleteUser = (users: User[]) => {
		if (users.length < 1) {
			return false;
		}

		const ownAccountSelected = users.includes(this.props.appState.user);

		console.log(ownAccountSelected);

		if (ownAccountSelected) {
			return false;
		}

		return true;
	};

	private getActionBar = () => {
		const result: IActionBarItem<User>[] = [
			{
				id: "delete",
				title: "Delete Users",
				label: "Delete",
				IconComponent: DeleteIcon,
				isVisible: () => true,
				isEnabled: this.canDeleteUser,
			},
		];

		return result;
	};

	public override render() {
		return (
			<TableViewV5
				ref={this._tableView}
				ownFeature={XyiconFeature.User}
				headers={this._headers}
				create={(onCreated, onCancel, creating) => (
					<CreateUserPanelV5
						onCancel={onCancel}
						onCreated={onCreated}
						creating={creating}
					/>
				)}
				details={(focused: User[]) => <UserDetailsTabV5 users={focused} />}
				actionBar={this.getActionBar()}
				canDeleteUser={this.canDeleteUser}
			/>
		);
	}
}

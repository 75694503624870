import * as React from "react";
import {NumberInputV5} from "../../../details/datatypes/numeric/NumberInputV5";

interface IBetweenParamV5Props {
	readonly param: {min: number; max: number};
	onChange(value: {min: number; max: number}): void;
}

export class BetweenParamV5 extends React.Component<IBetweenParamV5Props> {
	public override render() {
		const {param, onChange} = this.props;
		const min = param?.min || 0;
		const max = param?.max || 0;

		return (
			<>
				<NumberInputV5
					value={min}
					onInput={(value) => onChange({min: value, max})}
				/>
				<NumberInputV5
					value={max}
					onInput={(value) => onChange({min, max: value})}
				/>
			</>
		);
	}
}

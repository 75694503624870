import type {FunctionComponent, SVGProps} from "react";
import {useCallback, useEffect, useRef} from "react";
import styled from "styled-components";
import {MathUtils} from "../../../utils/math/MathUtils";
import type {ISizeRange} from "../../modules/space/spaceeditor/logic3d/Constants";
import {Constants} from "../../modules/space/spaceeditor/logic3d/Constants";
import {SelectInputStyled, SelectInputV5} from "../input/select/SelectInputV5";
import {colorPalette} from "../styles/colorPalette";
import {HorizontalAlignment} from "../../../utils/dom/DomUtils";
import {ReactUtils} from "../../utils/ReactUtils";

interface ISizeChangerProps {
	readonly disabled?: boolean;
	readonly value: number;
	readonly IconComponent?: FunctionComponent<SVGProps<SVGSVGElement> & {title?: string}>;
	readonly onChange: (newValue: number) => void;
	readonly range: ISizeRange;
	readonly isSpaceBarActionButton?: boolean;
}

const HiddenOptionValue = 1;

export const SizeChangerV5 = (props: ISizeChangerProps) => {
	const {range = Constants.SIZE.FONT, onChange, disabled, value, IconComponent, isSpaceBarActionButton} = props;

	const _inputRef = useRef<HTMLInputElement>();

	const updateInputValue = useCallback((newValue: number) => {
		_inputRef.current.value = newValue.toString();
	}, []);

	const setNewSize = useCallback(
		(newSize: number) => {
			if (!isNaN(newSize)) {
				newSize = MathUtils.clamp(newSize, range.min, range.max);
				if (newSize !== value) {
					onChange(newSize);
				}
				updateInputValue(newSize);
			} else {
				updateInputValue(value);
			}
		},
		[onChange, updateInputValue, range.min, range.max, value],
	);

	useEffect(() => {
		const onInputChange = (event: Event) => {
			/**
			 * For some reason, in react, `onChange=` seems to be triggered on every keypress.
			 * With this method, it"s only triggered when the input is finished (like pressing enter, or changing focus to another element)
			 */
			const inputElement = event.currentTarget as HTMLInputElement;
			const newFontSize = parseFloat(inputElement.value);

			setNewSize(newFontSize);
		};

		const inputElement = _inputRef.current;
		inputElement?.addEventListener("change", onInputChange);

		return () => {
			inputElement?.removeEventListener("change", onInputChange);
		};
	}, [setNewSize]);

	useEffect(() => {
		if (_inputRef.current) {
			if (Math.abs(parseFloat(_inputRef.current.value) - value) > Constants.EPSILON) {
				updateInputValue(value);
			}
		}
	}, [value, updateInputValue]);

	// Set selectValue to a hidden option's value to be able to select every visible option
	const selectValue = range.values.includes(value) ? value : HiddenOptionValue;

	return (
		<SizeChangerStyled className="SizeChanger">
			{IconComponent && <IconComponent />}
			<input
				maxLength={`${range.max}`.length}
				ref={_inputRef}
				defaultValue={value.toString()}
				disabled={disabled}
				className={ReactUtils.cls({isSpaceBarActionButton})}
			/>
			<SelectInputV5
				options={range.values}
				selected={selectValue}
				onChange={setNewSize}
				sort={false}
				searchBarMode="always off"
				className="dropdownOnly"
				horizontalAlignment={HorizontalAlignment.center}
			/>
		</SizeChangerStyled>
	);
};

export const SizeChangerStyled = styled.div`
	height: 32px;
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 1px solid ${colorPalette.gray.c300};
	border-radius: 4px;
	font-size: 14px;
	padding-left: 8px;
	padding-right: 4px;
	gap: 8px;

	> svg:first-of-type {
		width: 16px;
		height: 16px;
	}

	input {
		background-color: inherit;
		color: ${colorPalette.gray.c950};
		border: none;
		width: 28px;

		&:focus {
			outline: none;
			border: none;
		}

		&.isSpaceBarActionButton {
			color: white;
		}
	}

	${SelectInputStyled} {
		&.dropdownOnly {
			width: 24px;
			height: 24px;
			padding: 0;
			margin: 0;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;

			span {
				display: none;
			}
		}
	}
`;

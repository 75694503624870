import styled from "styled-components";
import {FlexCenterStyle, radius} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {IconButtonStyled} from "../interaction/IconButtonV5";

const ButtonContainerStyled = styled.div`
	position: absolute;
	right: -100%;
	height: 100%;
	width: fit-content;
	pointer-events: none;
	transition: all 0.2s ease-out;
	opacity: 0;
	display: flex;
	align-items: center;
	padding-right: 5px;
	${IconButtonStyled} {
		width: 32px;
		height: 32px;
		border-radius: ${radius.sm};
		&:hover {
			border: 1px solid #5e5e5e;
		}
	}
`;

export const SpaceItemContainerStyled = styled.div`
	${FlexCenterStyle};
	border-radius: 6px;
	flex: 1;
	position: relative;
	overflow: hidden;
	padding: 4px;

	.SpaceItemActionButtons {
		display: none;
	}

	&:hover {
		background-color: ${colorPalette.gray.c200Light};

		.SpaceItemActionButtons {
			display: flex;
			align-content: center;
		}

		${ButtonContainerStyled} {
			right: 0;
			pointer-events: initial;
			opacity: 1;
			transition: ease-in-out opacity 0.2s;
		}

		.buttonContainer {
			right: 0;
			pointer-events: initial;
			opacity: 1;
			transition: ease-in-out opacity 0.2s;
			position: absolute;
		}

		.DropDownButton {
			opacity: 1;
		}
	}

	&.isSelected {
		background-color: ${colorPalette.primary.c200Light};
	}

	.DropDownButton {
		opacity: 0;
	}
`;

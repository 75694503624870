import {Catalog} from "../models/Catalog";
import {Space} from "../models/Space";
import {Markup} from "../models/Markup";
import {Boundary} from "../models/Boundary";
import {Xyicon} from "../models/Xyicon";
import {SpaceVersion} from "../models/SpaceVersion";
import {LibraryImage} from "../models/LibraryImage";
import type {AppState} from "../state/AppState";
import type {FeatureMap} from "../state/AppStateConstants";
import {Link} from "../models/Link";
import {LibraryModel} from "../models/LibraryModel";
import type {IError, TransportLayer} from "../TransportLayer";
import {XHRCancelToken, XHRLoader} from "../../utils/loader/XHRLoader";
import {Portfolio} from "../models/Portfolio";
import type {
	DeleteBoundaryRequest,
	DeleteBoundarySpaceMapRequest,
	DeleteDashboardRequest,
	DeleteDocumentsRequest,
	DeleteLibraryImagesRequest,
	DeleteLibraryModelsRequest,
	DeleteMarkupRequest,
	DeletePortfolioGroupRequest,
	DeletePortfolioPermissionSetRequest,
	DeletePortfolioRequest,
	DeleteSpaceRequest,
	DeleteSpaceVersionRequest,
	DeleteUserGroupRequest,
	DeleteXyiconCatalogRequest,
	DeleteXyiconRequest,
	PortfolioDto,
	UpdateBoundaryFieldsRequest,
	UpdateSpaceFieldsRequest,
	UpdateXyiconFieldsRequest,
	UpdateXyiconsModelRequest,
	XyiconCatalogMappingInfo,
	XyiconDto,
} from "../../generated/api/base";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "../models/Model";
import type {IDefaultGlyphData} from "../../ui/modules/catalog/create/CatalogTypes";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {PortfolioGroup} from "../models/PortfolioGroup";
import {UserGroup} from "../models/UserGroup";
import {ExternalUser, User} from "../models/User";
import {PermissionSet} from "../models/permission/PermissionSet";
import {DocumentModel} from "../models/DocumentModel";
import {Report} from "../models/Report";
import type {
	DeleteReportRequest,
	GetReportArchiveDownloadRequest,
	GetReportArchiveRequest,
	InvokeReportRequest,
	InvokeSavedReportDto,
	InvokeUnsavedReportDto,
	InvokeUnsavedReportRequest,
	ReportArchiveDto,
	ReportDto,
	UpdateReportRequest,
} from "../../generated/api/reports";
import {Dashboard} from "../models/Dashboard";

const apiNames: FeatureMap<string> = {
	[XyiconFeature.Portfolio]: "portfolios",
	[XyiconFeature.PortfolioGroup]: "portfoliogroups",
	[XyiconFeature.XyiconCatalog]: "xyiconcatalogs",
	[XyiconFeature.Xyicon]: "xyicons",
	[XyiconFeature.Space]: "spaces",
	[XyiconFeature.SpaceVersion]: "spaceversions",
	[XyiconFeature.Markup]: "markups",
	[XyiconFeature.Boundary]: "boundaries",
	[XyiconFeature.LibraryImage]: "libraryimages",
	[XyiconFeature.LibraryModel]: "librarymodels",
	[XyiconFeature.Dashboard]: "dashboards",
	[XyiconFeature.UserGroup]: "usergroups",
	[XyiconFeature.User]: "users",
	[XyiconFeature.ExternalUser]: "users/external",
	[XyiconFeature.PermissionSet]: "portfoliopermissionsets",
	[XyiconFeature.Document]: "documents",
	[XyiconFeature.PortfolioDocument]: "documents/portfolio",
	[XyiconFeature.OrganizationDocument]: "documents/organization",
	[XyiconFeature.Report]: "reports",
	[XyiconFeature.Link]: "links",
};

export enum MappingType {
	ConvertToDirectLink = 1,
	ConvertToEmbeddedLink = 2, // we removed this option from the UI with #3146
	MapToPort = 3,
	BreakLink = -1,
}

export interface IPortMapping {
	[fromPortId: string]: {
		mappingType: MappingType;
		port: string | null;
	};
}

export type XyiconMappingInformation = Record<string, XyiconCatalogMappingInfo> | null;

export class FeatureService {
	private _transport: TransportLayer;
	private _promises: {
		[key in XyiconFeature]?: Promise<IModel[]>;
	} = {};
	private _dependencyFeaturesToLoad: XyiconFeature[] = [];
	private _cancelTokens = new Set<XHRCancelToken>();

	constructor(transportLayer: TransportLayer) {
		this._transport = transportLayer;
	}

	public static getApiNameForFeature(feature: XyiconFeature, isRefreshList?: boolean) {
		const apiNameFeature =
			!isRefreshList && [XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(feature) ? XyiconFeature.Document : feature;
		const apiName = apiNames[apiNameFeature];

		if (!apiName) {
			throw new Error(`Feature not defined! ${feature}`);
		}
		return apiName;
	}

	public async getArchiveReports(reportID: string) {
		const params: GetReportArchiveRequest = {
			organizationID: this._transport.appState.organizationId,
			userID: this._transport.appState.user.id,
			portfolioID: this._transport.appState.portfolioId,
			reportID: reportID,
		};

		const {result, error} = await this._transport.requestForOrganization(
			{
				url: "reports/archive/all",
				method: XHRLoader.METHOD_POST,
				params: params,
			},
			true,
		);

		return result;
	}

	public async downloadArchiveReports(report: ReportArchiveDto) {
		const params: GetReportArchiveDownloadRequest = {
			organizationID: this._transport.appState.organizationId,
			userID: this._transport.appState.user.id,
			reportArchiveIDList: [report.reportArchiveID],
		};

		const {result, error} = await this._transport.requestForOrganization(
			{
				url: "reports/archive/download",
				method: XHRLoader.METHOD_POST,
				params: params,
			},
			true,
		);

		return result;
	}

	public async create<T extends IModel = IModel>(data: any, feature: XyiconFeature): Promise<T[]> {
		const name = FeatureService.getApiNameForFeature(feature);
		const {result, error} = await this._transport.requestForOrganization<T[]>(
			{
				url: `${name}/create`,
				method: XHRLoader.METHOD_PUT,
				params: data,
			},
			feature === XyiconFeature.Report,
		);

		const items: T[] = [];

		if (error) {
			console.log("Error creating error", error);
		} else {
			if (Array.isArray(result)) {
				for (const element of result) {
					items.push(this._actions.addToList(element, feature) as T);
				}
			} else {
				items.push(this._actions.addToList(result, feature) as T);
			}
		}

		return items;
	}

	public async update(id: string, feature: XyiconFeature, value: any, fieldRefId?: string) {
		const name = FeatureService.getApiNameForFeature(feature);
		const params = this.createUpdateParams(id, fieldRefId, value, feature);

		const {result, error} = await this._transport.requestForOrganization({
			url: `${name}/update`,
			method: XHRLoader.METHOD_POST,
			params: params,
		});

		return result;
	}

	private createUpdateParams(id: string, fieldRefId: string, value: any, feature: XyiconFeature) {
		const appState = this._transport.appState;
		const spaceId = appState.space ? appState.space.id : "";

		switch (feature) {
			case XyiconFeature.Portfolio:
				return {
					fieldValues: {
						[fieldRefId]: value,
					},
					portfolioIDList: [id],
				};

			case XyiconFeature.Markup:
				return ObjectUtils.apply(
					{
						markupID: id,
						spaceID: spaceId,
						portfolioID: appState.portfolioId,
					},
					value,
				);

			case XyiconFeature.Boundary:
				return ObjectUtils.apply(
					{
						boundarySpaceMapID: id,
						portfolioID: appState.portfolioId,
						spaceID: spaceId,
					},
					value,
				);

			case XyiconFeature.PermissionSet:
				return {
					portfolioPermissionSetID: id,
					...value,
				};

			case XyiconFeature.PortfolioGroup:
				return {
					portfolioGroupID: id,
					...value,
				};

			case XyiconFeature.UserGroup:
				return {
					userGroupID: id,
					...value,
				};

			case XyiconFeature.User:
				return {
					userID: id,
					...value,
				};

			default:
				console.warn("Feature not found!");
				return null;
		}
	}

	public updateMassFields(items: IModel[], fieldData: {[refId: string]: any}) {
		return this._updateFields(items, fieldData);

		// const promises: Promise<any>[] = [];
		//
		// const itemsByFeatures: FeatureMap<IModel[]> = {};
		// for (const item of items)
		// {
		// 	itemsByFeatures[item.ownFeature] = itemsByFeatures[item.ownFeature] || [];
		// 	itemsByFeatures[item.ownFeature].push(item);
		// }
		//
		// for (const featureString in itemsByFeatures)
		// {
		// 	const items = itemsByFeatures[featureString];
		// 	promises.push(this._updateFields(items, fieldData));
		// }
		//
		// return Promise.all(promises);
	}

	private _updateFields(items: IModel[], fieldData: {[refId: string]: any}) {
		const feature = items[0]?.ownFeature;

		if (!feature) {
			throw new Error("Feature not defined for item!");
		}

		const name = FeatureService.getApiNameForFeature(feature);

		const idListKey = {
			[XyiconFeature.Portfolio]: "portfolioIDList",
			[XyiconFeature.Boundary]: "boundaryIDList",
			[XyiconFeature.XyiconCatalog]: "xyiconCatalogIDList",
			[XyiconFeature.Xyicon]: "xyiconIDList",
			[XyiconFeature.Space]: "spaceIDList",
		}[feature as XyiconFeature.Portfolio | XyiconFeature.XyiconCatalog | XyiconFeature.Space | XyiconFeature.Xyicon | XyiconFeature.Boundary];

		if (!idListKey) {
			throw new Error("Feature not defined for updateFields!");
		}

		const params: UpdateXyiconFieldsRequest | UpdateSpaceFieldsRequest | UpdateBoundaryFieldsRequest = {
			fieldValues: fieldData,
			[idListKey]: items.map((item) => item.id),
		};

		if (feature !== XyiconFeature.Portfolio && !params.portfolioID) {
			for (const item of items) {
				if (item.portfolioId) {
					params.portfolioID = item.portfolioId;
					break;
				}
			}
		}
		if (!params.portfolioID) {
			params.portfolioID = this._transport.appState.portfolioId;
		}

		return this._transport.requestForOrganization({
			url: `${name}/updatefields`,
			method: XHRLoader.METHOD_POST,
			params: params,
		});
	}

	public updateXyiconModel(params: UpdateXyiconsModelRequest) {
		return this._transport.requestForOrganization<XyiconDto[]>({
			url: `${FeatureService.getApiNameForFeature(XyiconFeature.Xyicon)}/updatemodel`,
			method: XHRLoader.METHOD_POST,
			params: params,
		});
	}

	public updateReport(report: Report) {
		const params: UpdateReportRequest = {
			reportID: report.id,
			...report.serializeData(),
		};

		return this._transport.requestForOrganization<ReportDto>(
			{
				url: "reports/update",
				method: XHRLoader.METHOD_POST,
				params: params,
			},
			true,
		);
	}

	public async saveAndRunReport(report: Report) {
		report.clearReport();
		await this.updateReport(report);
		await this.runReport(report);
	}

	public async runSavedReport(report: Report) {
		report.clearReport();

		const params: InvokeReportRequest = {
			reportID: report.id,
			userID: this._transport.appState.user.id,
			portfolioID: report.getPortfolio(this._transport.appState),
		};

		const {result, error} = await this._transport.requestForOrganization<InvokeSavedReportDto>(
			{
				url: "reports/invokereport",
				method: XHRLoader.METHOD_POST,
				params: params,
			},
			true,
		);

		if (result) {
			report.addRequestID(result.requestID);
		}

		return result;
	}

	public async runReport(report: Report) {
		report.clearReport();

		const params: InvokeUnsavedReportRequest = {
			reportID: report.id,
			organizationID: this._transport.appState.organizationId,
			portfolioID: report.portfolioIDList[0] || this._transport.appState.portfolioId,
			...report.serializeData(),
		};

		const {result, error} = await this._transport.requestForOrganization<InvokeUnsavedReportDto>(
			{
				url: "reports/invokeunsavedreport",
				method: XHRLoader.METHOD_POST,
				params: params,
			},
			true,
		);

		report.addRequestID(result.requestID);

		return result;
	}

	// Should be called only by AppActions.deleteItems
	public async deleteItems<T>(items: T[], feature: XyiconFeature, apiNameAddition: "" | "boundaryspacemaps") {
		const name = FeatureService.getApiNameForFeature(feature);
		const params = this.createDeleteParams(items, feature, apiNameAddition);

		const {result} = await this._transport.requestForOrganization(
			{
				url: `${name}/delete${apiNameAddition}`,
				method: XHRLoader.METHOD_DELETE,
				params: params,
			},
			feature === XyiconFeature.Report,
		);

		return result;
	}

	private createDeleteParams(items: any[], feature: XyiconFeature, apiNameAddition: "" | "boundaryspacemaps") {
		const ids = items.map((item) => item.id);
		const portfolioId = this._transport.appState.portfolioId;

		switch (feature) {
			case XyiconFeature.Portfolio:
				return {
					portfolioIDList: ids,
				} as DeletePortfolioRequest;

			case XyiconFeature.XyiconCatalog:
				return {
					xyiconCatalogIDList: ids,
				} as DeleteXyiconCatalogRequest;

			case XyiconFeature.Dashboard:
				return {
					dashboardIDList: ids,
				} as DeleteDashboardRequest;

			case XyiconFeature.Space:
				return {
					portfolioID: portfolioId,
					spaceIDList: ids,
				} as DeleteSpaceRequest;

			case XyiconFeature.SpaceVersion:
				return {
					portfolioID: portfolioId,
					spaceVersionIDList: ids,
				} as DeleteSpaceVersionRequest;

			case XyiconFeature.Markup:
				return {
					spaceID: this._transport.appState.space.id,
					portfolioID: portfolioId,
					markupIDList: ids,
				} as DeleteMarkupRequest;

			case XyiconFeature.Xyicon:
				return {
					xyiconIDList: ids,
					portfolioID: portfolioId,
				} as DeleteXyiconRequest;

			case XyiconFeature.Boundary:
				return apiNameAddition === ""
					? ({
							boundaryIDList: ids,
							portfolioID: portfolioId,
						} as DeleteBoundaryRequest)
					: ({
							boundarySpaceMapIDList: ids,
							portfolioID: portfolioId,
						} as DeleteBoundarySpaceMapRequest);

			case XyiconFeature.LibraryImage:
				return {
					libraryImageIDList: ids,
				} as DeleteLibraryImagesRequest;

			case XyiconFeature.LibraryModel:
				return {
					libraryModelIDList: ids,
				} as DeleteLibraryModelsRequest;

			case XyiconFeature.PortfolioGroup:
				return {
					portfolioGroupIDList: ids,
				} as DeletePortfolioGroupRequest;

			case XyiconFeature.UserGroup:
				return {
					userGroupIDList: ids,
				} as DeleteUserGroupRequest;

			case XyiconFeature.PermissionSet:
				return {
					portfolioPermissionSetIDList: ids,
				} as DeletePortfolioPermissionSetRequest;

			case XyiconFeature.Document:
			case XyiconFeature.OrganizationDocument:
			case XyiconFeature.PortfolioDocument:
				return {
					documentIDList: ids,
				} as DeleteDocumentsRequest;

			case XyiconFeature.Report:
				return {
					reportIDList: ids,
					userID: this._transport.appState.user?.id, // this is actually not needed only according to swagger
				} as DeleteReportRequest;

			default:
				console.warn("Feature not found!");
				return null;
		}
	}

	public clearPromises() {
		this._promises = {};
		this._dependencyFeaturesToLoad.length = 0;

		// Cancel all ongoing XHRs, to avoid synch related issues:
		// - when switching portfolios quickly, earlier requests might complete before later ones,
		// causing inconsistent data
		// - these ongoing requests are not useful for the new portfolio anyway
		this._cancelTokens.forEach((cancelToken) => {
			cancelToken.cancel();
		});
		this._cancelTokens.clear();
	}

	private async loadDependencies(feature: XyiconFeature): Promise<void> {
		// load dependencies, handle circular dependencies

		const featuresToLoad = this._actions.getLoadingDependencies(feature).filter((ft: XyiconFeature) => {
			return ft !== feature && !this._transport.appState.lists[ft].loaded;
		});

		const promises = [];

		for (const featureToLoad of featuresToLoad) {
			if (!this._dependencyFeaturesToLoad.includes(featureToLoad)) {
				// Don't change the order of this, otherwise you can end up in an endless loop
				this._dependencyFeaturesToLoad.push(featureToLoad);
				promises.push(this.refreshList(featureToLoad));
			}
		}

		await Promise.all(promises);
	}

	public refreshList<T extends IModel = any>(feature: XyiconFeature, force = false): Promise<IModel[]> {
		if (force) {
			this._promises[feature] = undefined;
			this._transport.appState.lists[feature].clear();
		}

		if (!this._promises[feature]) {
			this._promises[feature] = new Promise<IModel[]>(async (resolve, reject) => {
				await this.loadDependencies(feature);

				const appState = this._transport.appState;
				const collection = appState.lists[feature];

				if (collection.loaded) {
					this.onListLoaded(feature);
					resolve(collection.array);
				} else {
					const organizationID = this._transport.services.auth.authData.organizationID;

					if (organizationID) {
						const name = FeatureService.getApiNameForFeature(feature, true);

						// For some requests, portfolioId would not be needed (usergroups/all, users/all)
						// TODO
						const global = [
							XyiconFeature.Document,
							XyiconFeature.OrganizationDocument,
							XyiconFeature.User,
							XyiconFeature.UserGroup,
							XyiconFeature.PortfolioGroup,
							XyiconFeature.Report,
							XyiconFeature.XyiconCatalog,
							XyiconFeature.Portfolio,
							XyiconFeature.Dashboard,
						].includes(feature);

						const portfolioId = appState.portfolioId;

						if (!global && !portfolioId) {
							// Not global list and portfolioId is not set -> can't retrieve list
							collection.loaded = true;
							resolve(undefined);
							return;
						}

						const cancelToken = new XHRCancelToken();

						this._cancelTokens.add(cancelToken);
						const {result: dataArray, error} = await this._transport.get<PortfolioDto[] | IDefaultGlyphData[]>({
							url: `${name}/all`,
							params: {
								OrganizationID: organizationID,
								...(global ? undefined : {PortfolioID: portfolioId}),
								// for reports
								UserID: appState.user?.id,
							},
							report: feature === XyiconFeature.Report,
							cancelToken: cancelToken,
						});

						this._cancelTokens.delete(cancelToken);

						const array: T[] = [];

						if (dataArray) {
							// Do not call collection.loaded yet because FilterActions will get confused
							// (collection is loaded but no data yet causing a performance problem, where filters
							// are being calculated twice on app load during initial list loadings).
							// (Other way to fix performance issue is to check collection.version too in FilterActions).
							// collection.loaded = true;

							for (const data of dataArray) {
								const item = FeatureService.createModel(data, feature, appState) as T;

								if (item) {
									array.push(item);
								}
							}
							// This sets collection.loaded = true
							collection.replaceByArray(array);
						}

						this.onListLoaded(feature);
						if (dataArray) {
							resolve(collection.array);
						} else {
							reject({
								result: error as IError,
							});
						}
					}
				}
			});
		}

		return this._promises[feature];
	}

	private onListLoaded = (feature: XyiconFeature) => {
		if (feature === XyiconFeature.User) {
			const appState = this._transport.appState;
			const collection = appState.lists[feature];

			appState.user = collection.getById(this._transport.services.auth.authData.userID);
		}
	};

	public static createModel(data: any, feature: XyiconFeature, appState: AppState): IModel {
		if (!data) {
			console.warn("Invalid data!");
			return null;
		}
		switch (feature) {
			case XyiconFeature.Portfolio:
				return new Portfolio(data, appState);
			case XyiconFeature.XyiconCatalog:
				return new Catalog(data, appState);
			case XyiconFeature.Dashboard:
				return new Dashboard(data, appState);
			case XyiconFeature.Space:
				return new Space(data, appState);
			case XyiconFeature.SpaceVersion:
				return new SpaceVersion(data);
			case XyiconFeature.Markup:
				return new Markup(data, appState);
			case XyiconFeature.Boundary:
				return new Boundary(data, appState);
			case XyiconFeature.Xyicon:
				return new Xyicon(data, appState);
			case XyiconFeature.LibraryImage:
				return new LibraryImage(data, appState);
			case XyiconFeature.LibraryModel:
				return new LibraryModel(data, appState);
			case XyiconFeature.PortfolioGroup:
				return new PortfolioGroup(data);
			case XyiconFeature.UserGroup:
				return new UserGroup(data);
			case XyiconFeature.User:
				return new User(data, appState);
			case XyiconFeature.ExternalUser:
				return new ExternalUser(data, appState);
			case XyiconFeature.PermissionSet:
				return new PermissionSet(data);
			case XyiconFeature.Document:
			case XyiconFeature.OrganizationDocument:
			case XyiconFeature.PortfolioDocument:
				return new DocumentModel(data, feature, appState);
			case XyiconFeature.Report:
				return new Report(appState, data);
			case XyiconFeature.Link:
				return new Link(data);

			default:
				console.warn("Feature not found!");
				return null;
		}
	}

	private get _actions() {
		return this._transport.appState.actions;
	}
}

import * as React from "react";

export interface ISingleLineLabelProps {
	readonly value: string;
}

export class SingleLineLabel extends React.PureComponent<ISingleLineLabelProps> {
	public override render() {
		const {value} = this.props;

		return (
			<div
				className="SingleLineLabel"
				dangerouslySetInnerHTML={{__html: value || ""}}
			/>
		);
	}
}

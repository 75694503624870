import * as React from "react";
import {inject, observer} from "mobx-react";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {FieldDataType} from "../../../../generated/api/base";
import type {AppState} from "../../../../data/state/AppState";
import {featureTitles} from "../../../../data/state/AppStateConstants";
import type {Report} from "../../../../data/models/Report";
import {TimeUtils} from "../../../../utils/TimeUtils";
import {FieldV5} from "../../details/FieldV5";
import {ClickToEditInputV5} from "../../input/clicktoedit/ClickToEditInputV5";

interface IReportDefaultFieldsProps {
	readonly item: Report;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface IReportDefaultFieldsState {
	updatingName: boolean;
	updatingDescription: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class ReportDefaultFieldsV5 extends React.Component<IReportDefaultFieldsProps, IReportDefaultFieldsState> {
	constructor(props: IReportDefaultFieldsProps) {
		super(props);
		this.state = {
			updatingName: false,
			updatingDescription: false,
		};
	}

	private onNameChange = async (value: string) => {
		const {item, transport} = this.props;

		item.name = value;

		this.setState({updatingName: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateReport(item), this.props.appState.app.notificationContainer);
		this.setState({updatingName: false});
	};

	private onDescriptionChange = async (value: string) => {
		const {item, transport} = this.props;

		item.description = value;

		this.setState({updatingDescription: true});
		await TimeUtils.waitUpdate(transport.appState.actions.updateReport(item), this.props.appState.app.notificationContainer);
		this.setState({updatingDescription: false});
	};

	public override render() {
		const {item, appState} = this.props;
		const {updatingName, updatingDescription} = this.state;

		return (
			<>
				<FieldV5 label={appState.actions.getRefIdName(item.ownFeature)}>{item.refId}</FieldV5>
				<FieldV5 label="Name">
					<ClickToEditInputV5
						value={item.name}
						onChange={this.onNameChange}
						dataType={FieldDataType.SingleLineText}
						updating={updatingName}
					/>
				</FieldV5>
				<FieldV5 label="Category">{item.category}</FieldV5>
				<FieldV5 label="Module">{featureTitles[item.reportFeature]}</FieldV5>
				<FieldV5 label="Description">
					<ClickToEditInputV5
						dataType={FieldDataType.MultiLineText}
						onChange={this.onDescriptionChange}
						value={item.description}
						updating={updatingDescription}
					/>
				</FieldV5>
			</>
		);
	}
}

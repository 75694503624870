import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../widgets/button/IconButton";
import {ReactUtils} from "../utils/ReactUtils";
import type {AppState} from "../../data/state/AppState";
import type {TransportLayer} from "../../data/TransportLayer";
import {XyiconFeature} from "../../generated/api/base";
import type {IModel} from "../../data/models/Model";
import type {Navigation} from "../../Navigation";
import {DomUtils} from "../../utils/dom/DomUtils";
import {LoaderIcon} from "../widgets/button/LoaderIcon";
import {StringUtils} from "../../utils/data/string/StringUtils";
import type {Markup} from "../../data/models/Markup";
import type {DocumentModel} from "../../data/models/DocumentModel";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import {SearchFeatureBlock} from "./SearchFeatureBlock";

interface IPortfolioWideSearchProps {
	readonly query: string;
	readonly loading: boolean;
	readonly appState?: AppState;
	readonly navigation?: Navigation;
	readonly transport?: TransportLayer;
	readonly closeSearchInput: () => void;
}

interface IPortfolioWideSearchState {
	expandedMode: boolean;
	expandedSearchFeatureBlock: XyiconFeature;
	visibleSearchBlocks: XyiconFeature[];
	itemsLoaded: boolean;
}

@inject("appState")
@inject("transport")
@inject("navigation")
@observer
export class PortfolioWideSearch extends React.Component<IPortfolioWideSearchProps, IPortfolioWideSearchState> {
	private _timeoutId: number = null;
	private static filteredFeatures: XyiconFeature[] = [
		XyiconFeature.Xyicon,
		XyiconFeature.Boundary,
		XyiconFeature.Markup,
		XyiconFeature.Space,
		XyiconFeature.XyiconCatalog,
		XyiconFeature.OrganizationDocument,
		XyiconFeature.PortfolioDocument,
	];
	private static readonly itemsNumberWhenCollapsed = 12;
	private static readonly ItemsNumberWhenExpanded = 99;

	private _element = React.createRef<HTMLDivElement>();
	private _viewport: VisualViewport = window.visualViewport;

	private _lastResult = {
		query: "",
		result: [] as IModel[],
	};

	constructor(props: IPortfolioWideSearchProps) {
		super(props);
		this.state = {
			//detailedItems: [],
			expandedMode: false,
			expandedSearchFeatureBlock: null,
			visibleSearchBlocks: [],
			itemsLoaded: false,
		};
	}

	private loadFeatureItems = () => {
		const featureService = this.props.transport.services.feature;

		Promise.all(PortfolioWideSearch.filteredFeatures.map((feature) => featureService.refreshList(feature))).then(() =>
			this.setState({itemsLoaded: true}),
		);
	};

	private getFilteredList(): IModel[] {
		const {query, appState} = this.props;

		if (query.length !== 0) {
			if (this._lastResult.query === query) {
				// Don't search again if query didn't change
				return this._lastResult.result;
			}

			const result: IModel[] = [];

			const featuresToSearch = [XyiconFeature.Xyicon, XyiconFeature.XyiconCatalog, XyiconFeature.Boundary, XyiconFeature.Space];

			for (const feature of featuresToSearch) {
				const array = appState.actions.getList(feature);
				const matching = appState.actions.searchModelsCached(array, query, feature, PortfolioWideSearch.ItemsNumberWhenExpanded);

				result.push(...matching);
			}

			appState.actions.getList<Markup>(XyiconFeature.Markup).forEach((markup) => {
				if (StringUtils.containsIgnoreCase(markup.textContent, query) || StringUtils.containsIgnoreCase(markup.typeName, query)) {
					result.push(markup);
				}
			});

			const portfolioDocuments = appState.actions.getList<DocumentModel>(XyiconFeature.PortfolioDocument).filter((d) => d.parentElementMaybe);
			const orgDocuments = appState.actions
				.getList<DocumentModel>(XyiconFeature.OrganizationDocument)
				.filter(
					(d) =>
						(d.documentMap[0].feature === XyiconFeature.XyiconCatalog ||
							(d.documentMap[0].feature === XyiconFeature.Portfolio && d.data.portfolioID === appState.portfolioId)) &&
						d.parentElementMaybe,
				);

			const allDocuments = [...portfolioDocuments, ...orgDocuments];

			allDocuments.forEach((doc: DocumentModel) => {
				if (StringUtils.containsIgnoreCase(doc.fileName, query)) {
					result.push(doc);
				}
			});

			this._lastResult.query = query;
			this._lastResult.result = result;

			return result;
		} else {
			return [];
		}
	}

	private leaveExpandedMode = () => {
		this.setState({
			expandedMode: false,
			expandedSearchFeatureBlock: null,
		});
	};

	private startExpandedMode(item: XyiconFeature) {
		this.setState({
			expandedMode: true,
			expandedSearchFeatureBlock: item,
		});
	}

	private getFeatureMenuLabel(feature: XyiconFeature) {
		// TODO Documents are missing in Navigation
		if ([XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument, XyiconFeature.Document].includes(feature)) {
			return "Documents";
		} else if (feature === XyiconFeature.Markup) {
			return "Markups";
		}

		return this.props.navigation.menus.find((menu) => menu.feature === feature).label;
	}

	private scrollToFeature(feature: XyiconFeature) {
		const featureBlocks = document.querySelectorAll(".SearchFeatureBlock .name");
		const divToScroll = Array.from(featureBlocks).find((node: HTMLElement) => node.dataset.featureid === feature.toString());

		if (divToScroll) {
			DomUtils.scrollIntoViewIfNeeded(divToScroll as HTMLElement);
		}
	}

	private isElementInViewport(el: HTMLDivElement) {
		const element = el.getBoundingClientRect();

		// We need to check only the vieport first 1/3 (or at least 300px)
		let viewportBottom = this._viewport.height * (1 / 3);

		viewportBottom = viewportBottom < 300 ? 300 : viewportBottom;

		return element.bottom < this._viewport.height && element.top > this._viewport.offsetTop;
	}

	private updateVisibleSearchBlocks = () => {
		const featureSearchBlocks = this._element.current?.childNodes as NodeList;
		const visibleBlocks: XyiconFeature[] = [];

		featureSearchBlocks?.forEach((block: HTMLDivElement) => {
			if (this.isElementInViewport(block)) {
				if (block.dataset.featureid) {
					visibleBlocks.push(parseInt(block.dataset.featureid));
				}
			}
		});

		const blocks1 = this.state.visibleSearchBlocks.toString();
		const blocks2 = visibleBlocks.toString();

		// Avoid unnecessary re-render (which can be very expensive here)
		if (blocks1 !== blocks2) {
			this.setState({visibleSearchBlocks: visibleBlocks});
		}
	};

	private scrollHandle = (e: React.UIEvent) => {
		this.updateVisibleSearchBlocks();
	};

	private filterFilteredListByFeature(filteredList: IModel[], feature: XyiconFeature) {
		return filteredList.filter((item: IModel) => {
			let qf = feature;
			if (qf === XyiconFeature.Document) {
				return [XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(item.ownFeature);
			}

			return item.ownFeature === qf;
		});
	}

	public override componentDidMount() {
		// Reset lastResult when component is opened to refresh cache when data changes
		// (If data changes when component is currently opened, that's still an issue which
		// we could consider solving by listening on changes on lists.)

		this._lastResult = {
			query: "",
			result: [] as any[],
		};
		this.loadFeatureItems();
	}

	public override componentDidUpdate(prevProps: IPortfolioWideSearchProps, prevState: IPortfolioWideSearchState) {
		if (this.props.query !== prevProps.query) {
			this.props.appState.app.onDetailsContainerClose();

			clearTimeout(this._timeoutId);
			// Wait for loading search groups
			this._timeoutId = window.setTimeout(this.updateVisibleSearchBlocks, 1000);
		}
	}

	public override render() {
		const {expandedMode, expandedSearchFeatureBlock, itemsLoaded} = this.state;
		const {loading, query} = this.props;

		// Important not to call the heavy getFilteredList yet
		const filteredList = loading || !itemsLoaded ? [] : this.getFilteredList();

		const isFilteredListEmpty = filteredList.length === 0;
		const isQueryEmpty = query.length === 0;
		const isEmptyScreen = !isQueryEmpty && isFilteredListEmpty;

		const passiveLinkLabels = ArrayUtils.removeDuplicates(
			PortfolioWideSearch.filteredFeatures
				.filter((feature: XyiconFeature) => !filteredList.find((item: IModel) => item.ownFeature === feature))
				.map((feature) => this.getFeatureMenuLabel(feature)),
		);

		const sectionFeatures: XyiconFeature[] = [
			...PortfolioWideSearch.filteredFeatures.filter(
				(f) => ![XyiconFeature.Document, XyiconFeature.OrganizationDocument, XyiconFeature.PortfolioDocument].includes(f),
			),
			XyiconFeature.Document,
		];

		return (
			<div
				className="PortfolioWideSearch"
				onScroll={this.scrollHandle}
			>
				<div className="header hbox">
					<div className="hbox">
						{expandedMode ? (
							<>
								<IconButton
									icon="arrow"
									className="back"
									onClick={this.leaveExpandedMode}
								/>
								<h4>All Results</h4>
							</>
						) : (
							<h4 className="title">Portfolio Search</h4>
						)}
					</div>
					<IconButton
						icon="close"
						onClick={this.props.closeSearchInput}
					/>
				</div>
				<div className="container hbox">
					{!isFilteredListEmpty ? (
						<>
							<div
								className="moduleGroups"
								ref={this._element}
							>
								{sectionFeatures.map((queriedFeature: XyiconFeature, index: number) => {
									const arrayWithItems = this.filterFilteredListByFeature(filteredList, queriedFeature);

									if ((expandedMode && expandedSearchFeatureBlock === queriedFeature) || (!expandedMode && arrayWithItems.length !== 0)) {
										return (
											<SearchFeatureBlock
												key={index}
												feature={queriedFeature}
												queryString={query}
												featureItems={arrayWithItems.slice(
													0,
													!expandedMode ? PortfolioWideSearch.itemsNumberWhenCollapsed : PortfolioWideSearch.ItemsNumberWhenExpanded,
												)}
												startExpandedMode={(feature: XyiconFeature) => this.startExpandedMode(feature)}
												filteredLength={arrayWithItems.length}
												itemsNumberWhenCollapsed={PortfolioWideSearch.itemsNumberWhenCollapsed}
												itemsNumberWhenExpanded={PortfolioWideSearch.ItemsNumberWhenExpanded}
												expanded={expandedMode && queriedFeature === expandedSearchFeatureBlock}
												menuLabel={this.getFeatureMenuLabel(queriedFeature)}
												closeSearchInput={this.props.closeSearchInput}
												closePortfolioWideSearch={this.props.closeSearchInput}
											/>
										);
									}
								})}
							</div>
							{!expandedMode && (
								<div className="moduleLinks">
									<div className="activeLinks hbox">
										<div className="scrollColumn" />
										<ul>
											{sectionFeatures.map((feature: XyiconFeature, index: number) => {
												const arrayWithItems = this.filterFilteredListByFeature(filteredList, feature);

												if (arrayWithItems.length > 0) {
													return (
														<li
															key={index}
															onClick={() => this.scrollToFeature(feature)}
															className={ReactUtils.cls("hbox", {visible: this.state.visibleSearchBlocks.includes(feature)})}
														>
															<div className="featureTitle">{this.getFeatureMenuLabel(feature)}</div>
															<div className="counter hbox alignCenter justifyCenter">{arrayWithItems.length}</div>
														</li>
													);
												}
											})}
										</ul>
									</div>
									{passiveLinkLabels.length !== 0 && <div className="separator" />}
									<div className="passiveLinks hbox">
										<div className="scrollColumn">
											<ul>
												{passiveLinkLabels.map((label: string, index: number) => {
													return (
														<li
															key={label}
															className="hbox"
														>
															<div className="featureTitle">{label}</div>
															<div className="counter hbox alignCenter justifyCenter">0</div>
														</li>
													);
												})}
											</ul>
										</div>
									</div>
								</div>
							)}
						</>
					) : loading || !itemsLoaded ? (
						<div className="flex_1 vbox alignCenter justifyCenter">
							<LoaderIcon />
							<div className="message">Searching...</div>
						</div>
					) : (
						<div className="empty vbox alignCenter">
							{isEmptyScreen ? <p>{`No results found for the term "${query}"`}</p> : <p>Start typing to search in Portfolio</p>}
						</div>
					)}
				</div>
			</div>
		);
	}
}

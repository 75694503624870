import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldAdapter} from "../../../../data/models/field/Field";
import {FieldDataType, XyiconFeature} from "../../../../generated/api/base";
import type {IModel} from "../../../../data/models/Model";
import type {AppState} from "../../../../data/state/AppState";
import type {TransportLayer} from "../../../../data/TransportLayer";
import {LookupUtils} from "../../../modules/abstract/sidepanel/tabs/details/LookupUtils";
import {MultiSelectInputV5} from "../../details/datatypes/multi/MultiSelectInputV5";
import {FieldInlineInputsV5, FieldInputsV5} from "../../../widgets/input/clicktoedit/InputUtils";
import {SingleLineInputV5} from "../../details/datatypes/SingleLineInputV5";
import {ButtonV5} from "../../button/ButtonV5";
import {ReactUtils} from "../../../utils/ReactUtils";
import {StringUtils} from "../../../../utils/data/string/StringUtils";
import {findPhoneParts} from "../../details/datatypes/PhoneInputV5";

interface ITableCellValueProps {
	readonly field: IFieldAdapter;
	readonly value: any;
	readonly item: IModel;
	readonly isEditable: boolean;
	readonly isUpdating: boolean;
	readonly disabled: boolean;
	readonly fieldTypesWithDropdown: FieldDataType[];
	readonly selectedItems: IModel[];
	readonly className?: string;
	readonly inEditMode?: boolean;
	readonly caretPosition?: number;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
	readonly onBlur?: () => void;
	readonly onInput?: (value: string) => void;
	readonly onChange?: (value: string) => void;
	readonly onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

interface ITableCellValueState {
	expand: boolean;
}

@inject("appState")
@inject("transport")
@observer
export class TableCellValueV5 extends React.Component<ITableCellValueProps, ITableCellValueState> {
	private _tableCell = React.createRef<HTMLDivElement>();

	constructor(props: ITableCellValueProps) {
		super(props);
		this.state = {
			expand: false,
		};
	}

	private splitCellContent = (value: string) => {
		if (typeof value === "string") {
			if (value.includes(";")) {
				return value.split(";");
			} else if (value.includes("\n")) {
				return value.split("\n");
			}
		}

		return value;
	};

	private renderField(fieldValue: any) {
		const {item, field} = this.props;

		let result: any;

		if (typeof fieldValue === "number" && isNaN(fieldValue)) {
			result = "";
		} else {
			if (!fieldValue && item.ownFeature === XyiconFeature.User) {
				result = "Not Specified";
			} else if (field?.dataType === FieldDataType.PhoneNumber) {
				const no = findPhoneParts(fieldValue);
				result = no.phone ? (
					<div>
						<p style={{display: "inline", color: "#9E9E9E"}}>{no.countryCode}</p>
						<p style={{display: "inline", marginLeft: "10px"}}>{no.phone}</p>
					</div>
				) : null;
			} else {
				result = fieldValue;
			}
		}

		if (result === "" || result === undefined) {
			// This is needed because of some strange css bug in tablecells (vertical misalignments) when there is nothing in the div.
			result = " ";
		}

		return result;
	}

	private renderFieldArray(fields: any[]) {
		return fields
			.slice()
			.flat()
			.sort((fieldA, fieldB) => {
				let flag = 0;

				if (this.props.field?.dataType === FieldDataType.MultipleChoiceList) {
					flag = StringUtils.sortIgnoreCase(this.renderField(fieldA), this.renderField(fieldB));
				}

				return flag;
			})
			.map((field, i) => {
				if (this.state.expand || i < 5) {
					return (
						<div
							key={field?.refId || field?.id || i}
							className="tdRow"
						>
							{this.renderField(field)}
						</div>
					);
				}
			});
	}

	private getButtonLabel(cellOwnValue: string | string[]) {
		const {expand} = this.state;
		const {field} = this.props;
		let label = "";

		if (expand) {
			label = "See less";
		} else {
			if (field?.dataType !== FieldDataType.MultiLineText) {
				label = `${cellOwnValue.length - 10} More`;
			} else {
				label = "See more...";
			}
		}

		return label;
	}

	private expandCell = (e: React.MouseEvent) => {
		e.stopPropagation();
		this.setState((prevState) => ({expand: !prevState.expand}));
	};

	private getLookupField = () => {
		const {appState, field, item, transport} = this.props;
		const lookupFieldOptions = LookupUtils.getLookupFieldOptions(appState, field, item);
		const selectedLookupFieldOptions = LookupUtils.getSelectedLookupFieldOptions(appState, lookupFieldOptions, item);
		const onChange = LookupUtils.getOnChangeForLookup(transport, appState, lookupFieldOptions, item);

		return (
			<MultiSelectInputV5
				inline={true}
				key={field.refId}
				options={lookupFieldOptions}
				selected={selectedLookupFieldOptions}
				render={(o) => <div className="hbox alignCenter">{o.value}</div>}
				onChange={onChange}
			/>
		);
	};

	private get InputComponent() {
		const {field, item} = this.props;

		let InputComponent = FieldInlineInputsV5[field?.dataType] || FieldInputsV5[field?.dataType] || SingleLineInputV5;

		if (field?.refId.includes("model") && item.ownFeature === XyiconFeature.Xyicon) {
			InputComponent = FieldInlineInputsV5[FieldDataType.Type];
		}

		return InputComponent;
	}

	private get isXyiconBoundaryInheritedField() {
		const {field, item} = this.props;

		return item.ownFeature === XyiconFeature.Xyicon && field?.feature === XyiconFeature.Boundary;
	}

	private get isInputComponentBooleanField() {
		const {field, appState, item} = this.props;
		const isFieldHidden = appState.actions.isFieldHiddenByMasking(item, field);

		return (
			field?.dataType === FieldDataType.Boolean &&
			!isFieldHidden &&
			(appState.actions.getFieldRefIdsForType(item.typeId, item.ownFeature).includes(field.refId) || this.isXyiconBoundaryInheritedField)
		);
	}

	private get isDropdownInputComponent() {
		const {fieldTypesWithDropdown, field, item} = this.props;

		return fieldTypesWithDropdown.includes(field?.dataType) || (field?.refId.includes("model") && XyiconFeature.Xyicon === item.ownFeature);
	}

	public override render() {
		const {
			field,
			appState,
			item,
			value,
			isEditable,
			isUpdating,
			className,
			onClick,
			onBlur,
			onChange,
			onInput,
			selectedItems,
			disabled,
			inEditMode,
			caretPosition,
		} = this.props;
		const {expand} = this.state;
		const InputComponent = this.InputComponent;

		const isLookup = field && LookupUtils.isLookupField(field, item, appState.actions) && isEditable;
		const cellContent = this.splitCellContent(value);
		const isArray = Array.isArray(cellContent);
		const isContentOverflow = (isArray && cellContent?.length > 10) || this._tableCell.current?.scrollHeight > 195;
		const multilineOverflow = field?.dataType === FieldDataType.MultiLineText && cellContent?.length > 5;

		return (
			<div
				ref={this._tableCell}
				className={className}
				onClick={onClick}
			>
				{isLookup ? (
					this.getLookupField()
				) : (isEditable && (inEditMode || this.isDropdownInputComponent)) || this.isInputComponentBooleanField ? (
					<InputComponent
						value={value}
						dataTypeSettings={field?.dataTypeSettings}
						onInput={onInput}
						onChange={onChange}
						onBlur={onBlur}
						noButtons={true}
						inline={true}
						expand={expand}
						focusLossStarterEvent="down"
						item={item}
						scrollHeight={this._tableCell.current?.scrollHeight}
						secondaryState={expand}
						caretPosition={caretPosition}
						selectedItems={selectedItems}
						disabled={disabled}
					/>
				) : (
					<div
						className={ReactUtils.cls("cellContent hbox alignCenter", {
							overflowhidden: isContentOverflow && !expand,
							empty: !value,
							disabled: !isEditable || isUpdating,
						})}
					>
						<span>{isArray && field ? this.renderFieldArray(cellContent) : this.renderField(cellContent)}</span>
						{isUpdating && (
							<>
								&nbsp;
								<span className={ReactUtils.cls("spinner", {hasValidation: field.hasValidation, visible: isUpdating})} />
							</>
						)}
					</div>
				)}
				{(isContentOverflow && cellContent.length > 10) ||
					(multilineOverflow && (
						<ButtonV5
							label={this.getButtonLabel(cellContent)}
							className="naked"
							onClick={this.expandCell}
						/>
					))}
			</div>
		);
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {
	BeforeCapture,
	DraggableLocation,
	DraggableProvided,
	DraggableStateSnapshot,
	DragStart,
	DragUpdate,
	DropResult,
	ResponderProvided,
} from "@hello-pangea/dnd";
import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import {TabChild} from "../tab/TabChild";
import {TabView} from "../tab/TabView";
import {SVGIcon} from "../button/SVGIcon";
import {SearchField} from "../input/search/SearchField";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {IClsObj} from "../../utils/ReactUtils";
import {ReactUtils} from "../../utils/ReactUtils";
import type {AppState} from "../../../data/state/AppState";
import {featureTitles} from "../../../data/state/AppStateConstants";
import {IconButton} from "../button/IconButton";
import type {XyiconFeature} from "../../../generated/api/base";
import {ArrayUtils} from "../../../utils/data/array/ArrayUtils";
import type {IFieldAdapter} from "../../../data/models/field/Field";
import {fieldEquals} from "../../../data/models/field/FieldUtils";
import {ToggleContainer} from "../container/ToggleContainer";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {LINKED_FIELD_STRING, REPORT_COUNT_FIELD} from "../../../data/state/AppFields";
import type {IFieldColumn} from "../../../data/models/Report";
import {Report} from "../../../data/models/Report";
import {MathUtils} from "../../../utils/math/MathUtils";

interface IColumnEditorProps {
	readonly report?: Report;
	readonly title?: string;
	readonly leftHeader?: string;
	readonly rightHeader?: string;
	readonly feature: XyiconFeature;
	readonly linkedXyiconFieldsOn?: boolean;
	readonly addColumns: (fields: IFieldColumn[], index?: number, linkedField?: boolean) => void;
	readonly removeColumns: (fields: IFieldColumn[], linkedField?: boolean) => void;
	readonly reorderColumn: (fromIndex: number | number[], toIndex: number, linkedField?: boolean) => void;
	readonly getColumnRefIds: () => string[];
	readonly getLinkedFieldsColumnRefIds?: () => string[];
	readonly getFieldClassNames?: (refId: string) => IClsObj;

	readonly filterColumn?: (columnRefId: string) => boolean;
	readonly defaultOpenFeature?: XyiconFeature;

	readonly transport?: TransportLayer;
	readonly appState?: AppState;
}

interface IColumnEditorState {
	searchHidden: string;
	searchVisible: string;
	selected: IFieldColumn[];
	isDragging: boolean;
	isDraggingFromRight: boolean;
	openedFeatures: XyiconFeature[];
	tempOpenedFeature: XyiconFeature[];
	mounted: boolean;
	prohibitedDropDisplayFieldsArea: boolean;
	prohibitedDropDisplayLinkedFieldsArea: boolean;
	selectedTabId: string;
}

type IGroupedColumns = {
	feature: XyiconFeature;
	fieldColumns: IFieldColumn[];
};

@inject("appState")
@inject("transport")
@observer
export class ColumnEditor extends React.Component<IColumnEditorProps, IColumnEditorState> {
	private _lastSelectedColumn: {field: IFieldAdapter; isCtrlPressed: boolean} = {field: null, isCtrlPressed: false};

	constructor(props: IColumnEditorProps) {
		super(props);
		this.state = {
			searchHidden: "",
			searchVisible: "",
			selected: [],
			isDraggingFromRight: false,
			openedFeatures: [this.props.feature],
			tempOpenedFeature: [],
			mounted: false,
			prohibitedDropDisplayFieldsArea: false,
			prohibitedDropDisplayLinkedFieldsArea: false,
			selectedTabId: "0",
			isDragging: false,
		};
	}

	public override componentDidUpdate(prevProps: IColumnEditorProps) {
		if (prevProps.linkedXyiconFieldsOn !== this.props.linkedXyiconFieldsOn) {
			this.setState({selected: []});

			if (prevProps.linkedXyiconFieldsOn) {
				this.onChangeSelectedTabId("0");
			}
		}
	}

	private getColumns() {
		const {getColumnRefIds, getLinkedFieldsColumnRefIds, filterColumn, feature, appState} = this.props;

		const columnRefIds = getColumnRefIds();
		const columnLinkedFieldsRefIds = getLinkedFieldsColumnRefIds?.() || [];

		const visibleColumns: IFieldColumn[] = columnRefIds
			.map((refId) => ({
				field: appState.actions.getFieldByRefId(refId),
				linkedField: false,
			}))
			.filter((columnField) => {
				if (!columnField.field) {
					return false;
				}
				// Note: not too nice to put it here, but we have to allow the report count field to be
				// enabled as an exception
				if (columnField.field.refId.includes("versionName") || columnField.field.refId.includes("issuanceDate")) {
					return false;
				}
				const isCountField = columnField.field?.refId === REPORT_COUNT_FIELD;
				const isValid = isCountField || appState.actions.isFieldValidForFeature(columnField.field, feature);

				return isValid && this.searchFilter(columnField.field, this.state.searchVisible);
			});

		const visibleLinkedFieldsColumns: IFieldColumn[] = columnLinkedFieldsRefIds
			.map((refId) => ({
				field: appState.actions.getFieldByRefId(refId),
				linkedField: true,
			}))
			.filter((columnField) => {
				// Note: not too nice to put it here, but we have to allow the report count field to be
				// enabled as an exception
				const isCountField = columnField.field?.refId === REPORT_COUNT_FIELD;
				const isValid = isCountField || appState.actions.isFieldValidForFeature(columnField.field, feature);

				return isValid && this.searchFilter(columnField.field, this.state.searchVisible);
			});

		const hiddenColumns: IGroupedColumns[] = [];
		const hiddenLinkedFieldsColumns: IGroupedColumns[] = [];
		const groups = appState.actions.getInheritedFeatures(feature);

		groups.forEach((groupFeature: XyiconFeature) => {
			hiddenColumns.push({
				feature: groupFeature,
				fieldColumns: [
					...appState.defaultFields[groupFeature].map((f) => ({field: f, linkedField: false})),
					...appState.fields[groupFeature].map((f) => ({field: f, linkedField: false})),
				].filter((fieldColumn) => {
					if (!appState.actions.isFieldValidForFeature(fieldColumn.field, feature)) {
						return false;
					}

					if (fieldColumn.field.refId.includes("versionName") || fieldColumn.field.refId.includes("issuanceDate")) {
						return false;
					}

					return (
						!columnRefIds.find((columnRefIds) => fieldEquals(columnRefIds, fieldColumn.field.refId)) && // fields that are not added already
						this.searchFilter(fieldColumn.field, this.state.searchHidden) &&
						(!filterColumn || filterColumn(fieldColumn.field.refId))
					);
				}),
			});

			if (this.props.linkedXyiconFieldsOn) {
				hiddenLinkedFieldsColumns.push({
					feature: groupFeature,
					fieldColumns: [
						...appState.defaultFields[groupFeature].map((f) => ({field: f, linkedField: true})),
						...appState.fields[groupFeature].map((f) => ({field: f, linkedField: true})),
					].filter((fieldColumn) => {
						if (!appState.actions.isFieldValidForFeature(fieldColumn.field, feature)) {
							return false;
						}

						return (
							!columnLinkedFieldsRefIds.find((columnRefIds) => fieldEquals(columnRefIds, fieldColumn.field.refId)) && // fields that are not added already
							this.searchFilter(fieldColumn.field, this.state.searchHidden) &&
							(!filterColumn || filterColumn(fieldColumn.field.refId))
						);
					}),
				});
			}
		});

		return {hiddenColumns, hiddenLinkedFieldsColumns, visibleColumns, visibleLinkedFieldsColumns};
	}

	private searchFilter(field: IFieldAdapter, searchText: string) {
		if (searchText) {
			const fieldName = field?.name.toLowerCase() || "";

			return fieldName.includes(searchText.toLowerCase());
		}

		return true;
	}

	private onSearchHiddenChange = (value: string) => {
		const {hiddenColumns} = this.getColumns();
		const {searchHidden, openedFeatures, tempOpenedFeature} = this.state;

		let features: XyiconFeature[] = [];

		ArrayUtils.copy(features, openedFeatures);

		if (!searchHidden && value) {
			this.setState({tempOpenedFeature: features});
		} else if (searchHidden && !value) {
			this.setState({
				openedFeatures: tempOpenedFeature,
				tempOpenedFeature: [],
			});
		}

		if (value) {
			hiddenColumns.forEach((col) =>
				this.onToggle(!!col.fieldColumns.find((fieldColumn) => StringUtils.containsIgnoreCase(fieldColumn.field.name, value)), col.feature),
			);
		}

		this.updateSelection(value);
		this.setState({searchHidden: value});
	};

	private onSearchVisibleChange = (value: string) => {
		this.updateSelection(value);
		this.setState({searchVisible: value});
	};

	private updateSelection(searchText: string) {
		const {selected} = this.state;

		if (selected.length > 0) {
			// Remove items from selection that don't match the filter
			const newSelected = selected.filter((f) => this.searchFilter(f.field, searchText));

			this.setState({selected: newSelected});
		}
	}

	private onShiftClick = (
		newIndex: number,
		lastIndex: number,
		selected: IFieldColumn[],
		allColumns: IFieldColumn[],
		clickedColumn: IFieldColumn,
		isAbleToSelectAll: boolean,
	) => {
		if (selected?.some((col) => col.field === this._lastSelectedColumn.field)) {
			let newSelection = this._lastSelectedColumn.isCtrlPressed ? [...selected] : [];
			const diff = newIndex - lastIndex;

			if (diff > 0) {
				for (let i = lastIndex; i <= newIndex; ++i) {
					newSelection.push(allColumns[i]);
				}
			} else if (diff < 0) {
				for (let i = lastIndex; i >= newIndex; --i) {
					newSelection.push(allColumns[i]);
				}
			}

			newSelection = ArrayUtils.removeDuplicates(newSelection);

			if (!isAbleToSelectAll && newSelection.length === allColumns.length) {
				return; // Don't allow selecting all visible columns
			}

			if (newSelection.length) {
				this.setState({selected: newSelection});
			}
		} else {
			this.setState({selected: [clickedColumn]});
		}
	};

	private onColumnClick = (selectedFieldColumn: IFieldColumn, event: React.MouseEvent) => {
		const {selected} = this.state;
		const selectedField = selected.find((f) => Report.isFieldColumnEquals(selectedFieldColumn, f));
		const {visibleColumns, visibleLinkedFieldsColumns, hiddenColumns, hiddenLinkedFieldsColumns} = this.getColumns();
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const allHiddenColumns = [
			...hiddenColumns.map((group) => group.fieldColumns.toSorted(this.sortFieldColumns)).flat(),
			...hiddenLinkedFieldsColumns.flatMap((group) => group.fieldColumns).toSorted(this.sortFieldColumns),
		];

		if (event.ctrlKey || event.metaKey) {
			this._lastSelectedColumn = {field: selectedFieldColumn.field, isCtrlPressed: true};

			if (selectedField) {
				// remove from selection
				this.setState({selected: ArrayUtils.remove(selected, selectedField)});
			} else if (selected.length > 0) {
				if (!!selected[0].linkedField === !!selectedFieldColumn?.linkedField) {
					// add to selection (only if the selected fields are equivalent with linkedField)
					const newSelection = [...selected, selectedFieldColumn];
					const newVisibleSelection = newSelection.filter((f) => allVisibleColumns.some((visible) => Report.isFieldColumnEquals(visible, f)));
					const allVisibleColumnsAreSelected = !selected[0].linkedField
						? visibleColumns.length === newVisibleSelection.length
						: visibleLinkedFieldsColumns.length === newVisibleSelection.length;

					if (allVisibleColumnsAreSelected) {
						// Don't allow selecting all visible columns
						return;
					}

					this.setState({selected: newSelection});
				}
			} else {
				this.setState({selected: [selectedFieldColumn]});
			}
		} else if (event.shiftKey) {
			const lastIndexVisible = allVisibleColumns.findIndex((col) => col.field === this._lastSelectedColumn.field);
			const newIndexVisible = allVisibleColumns.findIndex((col) => col.field === selectedFieldColumn.field);

			const lastIndexHidden = allHiddenColumns.findIndex((col) => col.field === this._lastSelectedColumn.field);
			const newIndexHidden = allHiddenColumns.findIndex((col) => col.field === selectedFieldColumn.field);

			if (lastIndexVisible > -1 && newIndexVisible > -1) {
				this.onShiftClick(newIndexVisible, lastIndexVisible, selected, allVisibleColumns, selectedFieldColumn, false);
			} else if (lastIndexHidden > -1 && newIndexHidden > -1) {
				this.onShiftClick(newIndexHidden, lastIndexHidden, selected, allHiddenColumns, selectedFieldColumn, true);
			}
		} else {
			this._lastSelectedColumn = {field: selectedFieldColumn.field, isCtrlPressed: false};

			if (allVisibleColumns.length === 1 && allVisibleColumns[0].field.refId === selectedFieldColumn.field.refId) {
				// Don't allow selecting all visible columns
				return;
			}

			this.setState({selected: [selectedFieldColumn]});
		}
	};

	private flattenColumns(groups: IGroupedColumns[]) {
		const list: IFieldColumn[] = [];

		groups.forEach((group) => {
			list.push(...group.fieldColumns);
		});

		return list;
	}

	private onMoveRightClick = () => {
		const {addColumns} = this.props;
		const {hiddenColumns, hiddenLinkedFieldsColumns} = this.getColumns();
		const allHiddenColumns = [...hiddenColumns, ...hiddenLinkedFieldsColumns];
		const selected = this.flattenColumns(allHiddenColumns).filter((fieldColumn) => this.isSelected(fieldColumn));

		if (selected.length > 0 && this.isSelectedFieldColumnsAreHomogene()) {
			addColumns(selected, null, selected[0].linkedField);
			this.setState({selected: []});
		}
	};

	private onMoveLeftClick = () => {
		const {removeColumns} = this.props;
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const selected = allVisibleColumns.filter((fieldColumn) => this.isSelected(fieldColumn));

		if (selected.length > 0 && this.isSelectedFieldColumnsAreHomogene()) {
			removeColumns(selected, selected[0].linkedField);
			this.setState({selected: []});
		}
	};

	private isSelected(fieldColumn: IFieldColumn) {
		return !!this.state.selected.find((fc) => Report.isFieldColumnEquals(fc, fieldColumn));
	}

	private hasSelection(fieldColumns: IFieldColumn[]) {
		return this.getSelected(fieldColumns).length > 0;
	}

	private getSelected(fieldColumns: IFieldColumn[]) {
		return fieldColumns.filter((fc) => this.isSelected(fc));
	}

	private upButtonAllowed() {
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const selected = allVisibleColumns.filter((fieldColumn) => this.isSelected(fieldColumn));

		if (selected.length < 1) {
			return;
		}

		const fields = selected;

		if (!fields.some((field) => field.linkedField)) {
			return visibleColumns.indexOf(fields[0]) > 0;
		}

		if (fields.every((field) => field.linkedField)) {
			return visibleLinkedFieldsColumns.indexOf(fields[0]) > 0;
		}

		return false;
	}

	private downButtonAllowed() {
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const selected = allVisibleColumns.filter((fieldColumn) => this.isSelected(fieldColumn));

		if (selected.length < 1) {
			return;
		}

		const fields = selected;

		if (!fields.some((field) => field.linkedField)) {
			return visibleColumns.indexOf(fields[fields.length - 1]) < visibleColumns.length - 1;
		}

		if (fields.every((field) => field.linkedField)) {
			return visibleLinkedFieldsColumns.indexOf(fields[fields.length - 1]) < visibleLinkedFieldsColumns.length - 1;
		}

		return false;
	}

	private onMoveUpClicked = () => {
		this.onMoveSelected(-1);
	};

	private onMoveToTopClicked = () => {
		this.onMoveSelected(-Infinity);
	};

	private onMoveDownClicked = () => {
		this.onMoveSelected(1);
	};

	private onMoveToBottomClicked = () => {
		this.onMoveSelected(Infinity);
	};

	private onMoveSelected = (offset: number) => {
		const {reorderColumn} = this.props;
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const selected = this.getSelected(allVisibleColumns);

		if (selected) {
			if (!selected.every((f) => f.linkedField)) {
				const index = selected.map((s) => ({fc: s, i: visibleColumns.indexOf(s)})).toSorted((a, b) => a.i - b.i)[0].i;
				let targetIndex = index + offset;

				if (offset === -Infinity) {
					targetIndex = 0;
				}
				if (offset === Infinity) {
					targetIndex = visibleColumns.length - selected.length;
				}

				reorderColumn(
					selected.map((col) => visibleColumns.indexOf(col)),
					targetIndex,
				);
			} else {
				const index = selected.map((s) => ({fc: s, i: visibleLinkedFieldsColumns.indexOf(s)})).toSorted((a, b) => a.i - b.i)[0].i;
				let targetIndex = index + offset;

				if (offset === -Infinity) {
					targetIndex = 0;
				}
				if (offset === Infinity) {
					targetIndex = visibleLinkedFieldsColumns.length - selected.length;
				}

				reorderColumn(
					selected.map((col) => visibleLinkedFieldsColumns.indexOf(col)),
					targetIndex,
					true,
				);
			}
		}
	};

	private renderSelectionCount(snapshot: DraggableStateSnapshot) {
		const shouldShowSelection = snapshot.isDragging && this.state.selected.length > 1;

		return shouldShowSelection && <div className="selectionCount">{this.state.selected.length}</div>;
	}

	private renderField(col: IFieldColumn, provided: DraggableProvided, snapshot: DraggableStateSnapshot) {
		const {getFieldClassNames} = this.props;
		const isSelected = this.isSelected(col);

		return (
			<div
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				className={ReactUtils.cls("column", {
					semiHidden: this.state.isDragging && !snapshot.isDragging && isSelected,
					linkedField: col.linkedField,
					selected: isSelected,
					...getFieldClassNames?.(col.field.refId),
				})}
				onClick={(event) => this.onColumnClick(col, event)}
			>
				{col.linkedField && <SVGIcon icon="dol-field" />}
				<div className="name">{col.field.name}</div>
				{this.renderSelectionCount(snapshot)}
			</div>
		);
	}

	private onToggle = (value: boolean, feature: XyiconFeature) => {
		const openedFeatures = this.state.openedFeatures;

		if (value) {
			ArrayUtils.addMutable(openedFeatures, feature);
		} else {
			ArrayUtils.removeMutable(openedFeatures, feature);
		}

		this.setState({openedFeatures: openedFeatures});
	};

	public override componentDidMount() {
		this.setState({mounted: true});
	}

	private onBeforeCapture = (initial: BeforeCapture) => {
		//Please do not delete it, this is fordebugging purposes.
		//console.log(`onBeforeCapture, draggableId: ${initial.draggableId}`)

		const {draggableId} = initial;
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const isOnRightSide = [...visibleColumns, ...visibleLinkedFieldsColumns].find((fieldColumn) => {
			const refId = fieldColumn.field.refId;

			return draggableId.replace(LINKED_FIELD_STRING, "") === refId && fieldColumn.linkedField === draggableId.includes(LINKED_FIELD_STRING);
		});

		if (isOnRightSide) {
			this.setState({isDraggingFromRight: true});
		}
	};

	private onDragStart = (initial: DragStart) => {
		const {source, draggableId} = initial;
		if (!this.state.isDragging) {
			this.setState({
				isDragging: true,
			});
		}

		//Please do not delete it, this is fordebugging purposes.
		//console.log(`onDragStart, source: ${source.droppableId}, draggableId: ${draggableId}`)

		const draggingItemRefId = draggableId.replace(LINKED_FIELD_STRING, "");
		const isLinkedField = draggableId.includes(LINKED_FIELD_STRING);

		if (!this.state.selected.some((f) => f.field.refId === draggingItemRefId && f.linkedField === isLinkedField)) {
			// dragged item is not in selection -> clear selection to contain only the dragged item
			this.setState({
				selected: [
					{
						field: this.props.appState.actions.getFieldByRefId(draggingItemRefId),
						linkedField: isLinkedField,
					},
				],
			});
		}

		this.forceUpdate();
	};

	private onDragUpdate = (initial: DragUpdate, provided: ResponderProvided) => {
		const {source, destination, draggableId} = initial;
		//Please do not delete it, this is fordebugging purposes.
		//console.log(`onDragUpdate, source: ${source.droppableId} | ${source.index}, destination: ${destination?.droppableId} | ${destination?.index}, draggableId: ${draggableId}`)

		this.setProhibititedDropArea(source, destination);
	};

	private handleReorder = (result: DropResult, selected: IFieldColumn[], columns: IFieldColumn[], isLinked = false) => {
		const {source, destination} = result;

		if (selected.length > 1) {
			const fromIndices = selected.map((col) => columns.indexOf(col)).toSorted((a, b) => a - b);
			let destinationIndex = destination.index;
			if (destinationIndex > fromIndices[0]) {
				destinationIndex -= fromIndices.length - 1;
			}
			destinationIndex = MathUtils.clamp(destinationIndex, 0, columns.length - 1);

			this.props.reorderColumn(fromIndices, destinationIndex, isLinked);

			this.setState({selected: []});
		} else {
			this.props.reorderColumn(source.index, destination.index, isLinked);
		}
	};

	private onDragEnd = (result: DropResult) => {
		if (this.state.isDragging) {
			this.setState({
				isDragging: false,
			});
		}
		const {visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const {source, destination} = result;
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const selected = this.getSelected(allVisibleColumns);

		// Depending on the ID needed to get the correct item to update we may need to change the <Draggable />s id
		// and retrieve it with string manipulation e.g. "draggableId.split("-")[i]"

		//Please do not delete it, this is fordebugging purposes.
		//console.log(`onDragEnd, source: ${source.droppableId}, destination: ${destination?.droppableId}, draggableId: ${draggableId}`)

		if (destination !== null) {
			// Drag from right to left, reset item to original category
			if (source.droppableId === "visible-columns" && destination.droppableId === "drop-zone") {
				const selectedAndVisible = this.state.selected.filter((f) => visibleColumns.some((c) => c.field.refId === f.field.refId));

				this.props.removeColumns(selectedAndVisible);
				this.setState({selected: []});
			}

			if (source.droppableId === `visible-${LINKED_FIELD_STRING}columns` && destination.droppableId === "drop-zone") {
				const selectedAndVisible = this.state.selected.filter((f) => visibleLinkedFieldsColumns.some((c) => c.field.refId === f.field.refId));

				this.props.removeColumns(selectedAndVisible, true);
				this.setState({selected: []});
			}

			if (source.droppableId.includes("hidden") && !source.droppableId.includes("linkedFields") && destination.droppableId === "visible-columns") {
				const selectedAndHidden = this.state.selected.filter((f) => !visibleColumns.some((c) => c.field.refId === f.field.refId));

				this.props.addColumns(selectedAndHidden, destination.index);
				this.setState({selected: []});
			}

			if (source.droppableId.includes("hidden-linkedFields") && destination.droppableId === `visible-${LINKED_FIELD_STRING}columns`) {
				const selectedAndHidden = this.state.selected.filter((f) => !visibleLinkedFieldsColumns.some((c) => c.field.refId === f.field.refId));

				this.props.addColumns(selectedAndHidden, destination.index, true);
				this.setState({selected: []});
			}

			// Reorder visible items (right column)
			if (source.droppableId === "visible-columns" && destination.droppableId === "visible-columns") {
				this.handleReorder(result, selected, visibleColumns, false);
			}

			if (source.droppableId === `visible-${LINKED_FIELD_STRING}columns` && destination.droppableId === `visible-${LINKED_FIELD_STRING}columns`) {
				this.handleReorder(result, selected, visibleLinkedFieldsColumns, true);
			}
		}

		this.resetProhibitedAreas();

		// Reset hover
		this.setState({
			isDraggingFromRight: false,
		});
	};

	private setProhibititedDropArea(source: DraggableLocation, destination: DraggableLocation) {
		let prohibitedDropDisplayFieldsArea = false;
		let prohibitedDropDisplayLinkedFieldsArea = false;

		if (
			source.droppableId.includes("hidden") &&
			!source.droppableId.includes("linkedFields") &&
			destination?.droppableId === `visible-${LINKED_FIELD_STRING}columns`
		) {
			prohibitedDropDisplayLinkedFieldsArea = true;
		}

		if (source.droppableId.includes("linkedFields") && destination?.droppableId === "visible-columns") {
			prohibitedDropDisplayFieldsArea = true;
		}

		this.setState({prohibitedDropDisplayFieldsArea, prohibitedDropDisplayLinkedFieldsArea});
	}

	private resetProhibitedAreas() {
		this.setState({
			prohibitedDropDisplayFieldsArea: false,
			prohibitedDropDisplayLinkedFieldsArea: false,
		});
	}

	private onChangeSelectedTabId = (id: string) => {
		this.setState({
			selectedTabId: id,
			selected: [],
		});
	};

	private sortFieldColumns = (a: IFieldColumn, b: IFieldColumn) => {
		if (a.field.name === "ID") {
			return -1;
		}
		if (b.field.name === "ID") {
			return 1;
		}

		return StringUtils.sortIgnoreCase(a.field.name, b.field.name);
	};

	private isSelectedFieldColumnsAreHomogene() {
		const {selected} = this.state;

		return [...new Set(selected.map((f) => f.linkedField))].length === 1;
	}

	public override render() {
		const {title, leftHeader, rightHeader, defaultOpenFeature, linkedXyiconFieldsOn} = this.props;

		const {
			searchHidden,
			searchVisible,
			isDraggingFromRight,
			openedFeatures,
			prohibitedDropDisplayLinkedFieldsArea,
			prohibitedDropDisplayFieldsArea,
			selectedTabId,
		} = this.state;

		const {hiddenColumns, hiddenLinkedFieldsColumns, visibleColumns, visibleLinkedFieldsColumns} = this.getColumns();
		const allHidenColumns = [...hiddenColumns, ...hiddenLinkedFieldsColumns];
		const allVisibleColumns = [...visibleColumns, ...visibleLinkedFieldsColumns];
		const allVisibleColumnsAreSelected = allVisibleColumns.length === this.getSelected(allVisibleColumns).length;
		const onlyOneVisibleColumns = allVisibleColumns.length === 1;

		return (
			<div className="ColumnEditor">
				{title && <h4>{title}</h4>}
				<DragDropContext
					onBeforeCapture={this.onBeforeCapture}
					onDragStart={this.onDragStart}
					onDragUpdate={this.onDragUpdate}
					onDragEnd={this.onDragEnd}
				>
					<div
						className="hbox manage-cols-box"
						data-cy="manageColumns"
					>
						<div className="vbox col">
							<label>{leftHeader}</label>
							<SearchField
								placeholder="Find..."
								value={searchHidden}
								onInput={this.onSearchHiddenChange}
							/>
							<div className={ReactUtils.cls("border", {linkedFields: linkedXyiconFieldsOn})}>
								<TabView
									selectedTabId={selectedTabId}
									onChangeSelectedTabId={this.onChangeSelectedTabId}
								>
									<TabChild
										label="Xyicon Fields"
										title="Xyicon Fields"
									>
										{!isDraggingFromRight ? (
											hiddenColumns.map((group) => (
												<Droppable
													key={`${featureTitles[group.feature]}`}
													droppableId={`hidden-${featureTitles[group.feature]}`}
													type="COLUMN"
												>
													{(provided) => (
														<div
															{...provided.droppableProps}
															ref={provided.innerRef}
														>
															<ToggleContainer
																key={group.feature}
																title={featureTitles[group.feature]}
																open={(!this.state.mounted && defaultOpenFeature === group.feature) || openedFeatures.includes(group.feature)}
																onToggleOpen={(value) => this.onToggle(value, group.feature)}
															>
																{
																	// First field always must be the ID, then alphabetical-order
																	group.fieldColumns.toSorted(this.sortFieldColumns).map((col, i) => (
																		<Draggable
																			key={col.field.refId}
																			draggableId={col.field.refId}
																			index={i}
																		>
																			{(provided, snapshot) => this.renderField(col, provided, snapshot)}
																		</Draggable>
																	))
																}
																{provided.placeholder}
															</ToggleContainer>
														</div>
													)}
												</Droppable>
											))
										) : (
											<div className="dropArea">
												<Droppable
													key="drop-zone"
													droppableId="drop-zone"
													type="COLUMN"
												>
													{(provided) => (
														<div
															style={{height: "100%", width: "100%"}}
															{...provided.droppableProps}
															ref={provided.innerRef}
														>
															Drop columns here
															{provided.placeholder}
														</div>
													)}
												</Droppable>
											</div>
										)}
									</TabChild>
									{linkedXyiconFieldsOn && (
										<TabChild
											label="Linked Xyicon Fields"
											title="Linked Xyicon Fields"
										>
											{!isDraggingFromRight ? (
												hiddenLinkedFieldsColumns.map((group) => (
													<Droppable
														key={`linkedFields-${featureTitles[group.feature]}`}
														droppableId={`hidden-linkedFields-${featureTitles[group.feature]}`}
														type="COLUMN"
													>
														{(provided) => (
															<div
																{...provided.droppableProps}
																ref={provided.innerRef}
															>
																<ToggleContainer
																	key={group.feature}
																	title={featureTitles[group.feature]}
																	open={(!this.state.mounted && defaultOpenFeature === group.feature) || openedFeatures.includes(group.feature)}
																	onToggleOpen={(value) => this.onToggle(value, group.feature)}
																>
																	{group.fieldColumns.toSorted(this.sortFieldColumns).map((col, i) => (
																		<Draggable
																			key={`${LINKED_FIELD_STRING}${col.field.refId}`}
																			draggableId={`${LINKED_FIELD_STRING}${col.field.refId}`}
																			index={i}
																		>
																			{(provided, snapshot) => this.renderField(col, provided, snapshot)}
																		</Draggable>
																	))}
																	{provided.placeholder}
																</ToggleContainer>
															</div>
														)}
													</Droppable>
												))
											) : (
												<div className="dropArea">
													<Droppable
														key="drop-zone"
														droppableId="drop-zone"
														type="COLUMN"
													>
														{(provided) => (
															<div
																style={{height: "100%", width: "100%"}}
																{...provided.droppableProps}
																ref={provided.innerRef}
															>
																Drop columns here
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</div>
											)}
										</TabChild>
									)}
								</TabView>
							</div>
						</div>
						<div className="vbox flexCenter col">
							<IconButton
								icon="move-right"
								className="move-right"
								disabled={!this.hasSelection(this.flattenColumns(allHidenColumns))}
								onClick={this.onMoveRightClick}
							/>
							<IconButton
								icon="move-right"
								className="move-left"
								disabled={
									!this.hasSelection(allVisibleColumns) ||
									// don't allow moving all visible to the left
									allVisibleColumnsAreSelected
								}
								onClick={this.onMoveLeftClick}
							/>
						</div>
						<div className="vbox col">
							<label>{rightHeader}</label>
							<SearchField
								placeholder="Find..."
								value={searchVisible}
								onInput={this.onSearchVisibleChange}
							/>
							<div className="border">
								<Droppable
									droppableId="visible-columns"
									type="COLUMN"
								>
									{(provided) => (
										<div
											{...provided.droppableProps}
											ref={provided.innerRef}
										>
											<ToggleContainer
												title="Xyicon Fields"
												open={true}
												className={ReactUtils.cls("group", {prohibited: prohibitedDropDisplayFieldsArea})}
												hideTitle={!linkedXyiconFieldsOn}
											>
												{visibleColumns
													.filter((col) => col.field.refId !== REPORT_COUNT_FIELD || !linkedXyiconFieldsOn)
													.map((col, i) => (
														<Draggable
															key={`visible-${col.field.refId}`}
															draggableId={col.field.refId}
															index={i}
															isDragDisabled={allVisibleColumnsAreSelected || onlyOneVisibleColumns}
														>
															{(provided, snapshot) => this.renderField(col, provided, snapshot)}
														</Draggable>
													))}
												{provided.placeholder}
											</ToggleContainer>
										</div>
									)}
								</Droppable>
								{linkedXyiconFieldsOn && (
									<Droppable
										droppableId="visible-linkedFields-columns"
										type="COLUMN"
									>
										{(provided) => (
											<div
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												<ToggleContainer
													title="Linked Xyicon Fields"
													open={true}
													className={ReactUtils.cls("group", {prohibited: prohibitedDropDisplayLinkedFieldsArea})}
												>
													{visibleLinkedFieldsColumns.map((col, i) => (
														<Draggable
															key={`visible-linkedFields-${col.field.refId}`}
															draggableId={`linkedFields-${col.field.refId}`}
															index={i}
															isDragDisabled={allVisibleColumnsAreSelected || onlyOneVisibleColumns}
														>
															{(provided, snapshot) => this.renderField(col, provided, snapshot)}
														</Draggable>
													))}
													{provided.placeholder}
												</ToggleContainer>
											</div>
										)}
									</Droppable>
								)}
							</div>
						</div>
						<div className="vbox flexCenter col">
							<IconButton
								icon="tothetop"
								className="tothetop"
								disabled={!this.upButtonAllowed()}
								onClick={this.onMoveToTopClicked}
							/>
							<IconButton
								icon="angle_up"
								className="angle-up"
								disabled={!this.upButtonAllowed()}
								onClick={this.onMoveUpClicked}
							/>
							<IconButton
								icon="angle_up"
								className="angle-down"
								disabled={!this.downButtonAllowed()}
								onClick={this.onMoveDownClicked}
							/>
							<IconButton
								icon="tothetop"
								className="tothebottom"
								disabled={!this.downButtonAllowed()}
								onClick={this.onMoveToBottomClicked}
							/>
						</div>
					</div>
				</DragDropContext>
			</div>
		);
	}
}

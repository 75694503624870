import {Observer} from "mobx-react";
import type {IMultiSelectFieldSettingsDefinitionV5} from "../../../../../data/models/field/datatypes/MultiSelect";
import type {IFieldDataTypeSettingsProps} from "../../../../modules/settings/modules/field/datatypes/IFieldDataTypeSettingsProps";
import {ListBuilderInputV5} from "../../ListBuilderInputV5";

export const MultiSelectFieldSettingsV5 = (props: IFieldDataTypeSettingsProps) => {
	return (
		<Observer>
			{() => {
				const {field, onChange} = props;
				const settings = field.dataTypeSettings as IMultiSelectFieldSettingsDefinitionV5;

				const updateMultiSelectList = (value: IMultiSelectFieldSettingsDefinitionV5) => {
					settings.choiceList = value.choiceList;
					onChange();
				};

				return (
					<ListBuilderInputV5
						list={settings.choiceList}
						onChange={updateMultiSelectList}
					/>
				);
			}}
		</Observer>
	);
};

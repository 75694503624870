import * as React from "react";
import {observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {SpaceEditorMode} from "../../logic3d/renderers/SpaceViewRendererUtils";
import type {SelectionToolType, SpaceTool} from "../../logic3d/features/tools/Tools";
import {ToolButton} from "./ToolButton";
import {ToolToggler} from "./ToolToggler";
import type {DockableTitle} from "./Dockable";
import {SpaceAlignToolBar} from "./SpaceAlignToolBar";

interface IPrimaryToolBarProps {
	readonly active: boolean;
	readonly mode: SpaceEditorMode;
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly setDockableOpen: (value: boolean, title: DockableTitle, onlyIfNotDocked?: boolean) => void;
	readonly isCatalogOpen: boolean;
	readonly isBoundaryTypesWindowOpen: boolean;
	readonly isUnplottedXyiconsOpen: boolean;
	readonly openMarkupToolBar: () => void;
	readonly openSpaceAlignToolBar: () => void;
	readonly activeToolId: SpaceTool;
	readonly setActiveTool: (id: SpaceTool) => void;
	readonly createToolButton: (id: SpaceTool) => React.JSX.Element;
	readonly onToolClick: (id: SpaceTool) => void;
}

@observer
export class PrimaryToolBar extends React.Component<IPrimaryToolBarProps> {
	private onBoundaryClick = () => {
		if (this.props.isBoundaryTypesWindowOpen) {
			if (this.props.spaceViewRenderer.boundaryManager.typeId) {
				// Closing, and a boundary type is already selected
				this.props.setActiveTool("boundary");
			}
		}
		this.props.setDockableOpen(!this.props.isBoundaryTypesWindowOpen, "Boundary");
	};

	private onCatalogClick = () => {
		this.props.setDockableOpen(!this.props.isCatalogOpen, "Catalog");
	};

	private onUnplottedXyiconsClick = () => {
		this.props.setDockableOpen(!this.props.isUnplottedXyiconsOpen, "Unplotted Xyicons");
	};

	private closeDockable(onlyIfNotDocked: boolean = false) {
		if (this.props.isCatalogOpen) {
			this.props.setDockableOpen(false, "Catalog", onlyIfNotDocked);
		}
		if (this.props.isBoundaryTypesWindowOpen) {
			this.props.setDockableOpen(false, "Boundary", onlyIfNotDocked);
		}
		if (this.props.isUnplottedXyiconsOpen) {
			this.props.setDockableOpen(false, "Unplotted Xyicons", onlyIfNotDocked);
		}
	}

	private openMarkupToolBar = () => {
		this.closeDockable(true);
		this.props.openMarkupToolBar();
	};

	public openSpaceAlignToolBar = () => {
		this.closeDockable(true);
		this.props.openSpaceAlignToolBar();
	};

	private onSelectionSharedToolClick = (id: SelectionToolType) => {
		this.closeDockable(true);
		this.props.onToolClick(id);
	};

	private getNumberOfUnplottedXyicons() {
		return this.props.spaceViewRenderer.actions.getUnplottedXyicons().length;
	}

	private getToolBar() {
		switch (this.props.mode) {
			case SpaceEditorMode.NORMAL:
				return (
					<div className={ReactUtils.cls({SpaceToolBar: true, collapsed: !this.props.active})}>
						{this.props.createToolButton("selection")}
						{this.props.createToolButton("pan")}
						<ToolToggler
							icon="boundary"
							title="Draw Boundary"
							active={this.props.isBoundaryTypesWindowOpen || this.props.activeToolId === "boundary"}
							setActive={this.onBoundaryClick}
						/>
						<ToolToggler
							icon="catalog"
							title="Catalog Panel"
							active={this.props.isCatalogOpen}
							setActive={this.onCatalogClick}
						/>
						<ToolToggler
							icon="unplotted"
							title="Unplotted Xyicons"
							active={this.props.isUnplottedXyiconsOpen}
							setActive={this.onUnplottedXyiconsClick}
							notificationNumber={this.getNumberOfUnplottedXyicons()}
						/>
						<ToolButton
							icon="pencil"
							title="Markup Tool"
							onClick={this.openMarkupToolBar}
							active={false}
						/>

						{/* <ToolToggler icon="helperLines" title="Helper Lines" active={this.state.isHelperLinesActive} setActive={this.setHelperLinesActive} /> */}
					</div>
				);
			case SpaceEditorMode.SET_SCALE:
				return (
					<div className={ReactUtils.cls({SpaceToolBar: true, collapsed: !this.props.active})}>
						{this.props.createToolButton("pan")}
						{this.props.createToolButton("setScale")}
					</div>
				);
			case SpaceEditorMode.ALIGN:
				return (
					<SpaceAlignToolBar
						active={this.props.active}
						createToolButton={this.props.createToolButton}
					/>
				);
		}
	}

	public override render() {
		return this.getToolBar();
	}
}

import * as React from "react";
import {inject} from "mobx-react";
import {ModuleView} from "../abstract/ModuleView";
import {XyiconFeature, Permission} from "../../../generated/api/base";
import type {AppState} from "../../../data/state/AppState";
import type {TransportLayer} from "../../../data/TransportLayer";
import type {Xyicon} from "../../../data/models/Xyicon";

export interface IXyiconViewProps {
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}
interface IXyiconViewState {
	isCatalogOpen: boolean;
	isCatalogDocked: boolean;
}

@inject("appState")
@inject("transport")
export class XyiconView extends React.Component<IXyiconViewProps, IXyiconViewState> {
	constructor(props: IXyiconViewProps) {
		super(props);
		this.state = {
			isCatalogOpen: false,
			isCatalogDocked: false,
		};
	}

	private canDelete(selectedItems: Xyicon[]) {
		if (!this.props.appState.user) {
			return false;
		}
		if (this.props.appState.user.isAdmin) {
			return true;
		}

		return selectedItems.every((item) => {
			const permission = this.props.appState.actions.getModuleTypePermission(item.typeId, XyiconFeature.Xyicon);

			return permission >= Permission.Delete;
		});
	}

	private setCatalogOpen = (isOpen: boolean) => {
		this.setState({
			isCatalogOpen: isOpen,
		});
	};

	private setCatalogDocked = (isDocked: boolean) => {
		this.setState({
			isCatalogDocked: isDocked,
		});
	};

	private saveStateToLocalStorage() {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const stateToSave = {
				isCatalogDocked: this.state.isCatalogDocked,
				isCatalogOpen: this.state.isCatalogOpen && this.state.isCatalogDocked,
			};

			this.props.transport.services.localStorage.set(this.getLocalStorageKey(organizationId), stateToSave);
		}
	}

	private getLocalStorageKey(organizationId: string) {
		return `srv4-org-${organizationId}-spaceeditor-xyiconview-dockables-open-state`;
	}

	private loadStateFromLocalStorage() {
		const organizationId = this.props.appState.organizationId;

		if (organizationId) {
			const savedDockedState = this.props.transport.services.localStorage.get(this.getLocalStorageKey(organizationId)) as IXyiconViewState | null;

			if (savedDockedState) {
				this.setState({
					...savedDockedState,
				});
			}
		}
	}

	public override componentDidMount() {
		this.loadStateFromLocalStorage();
	}

	public override componentDidUpdate() {
		this.saveStateToLocalStorage();
	}

	public override render() {
		return (
			<ModuleView
				feature={XyiconFeature.Xyicon}
				className="XyiconView"
				actionBar={[
					{
						id: "import",
						title: "Update Xyicons with an Excel file",
						label: "Import",
					},
					{
						id: "add",
						title: "Create Xyicon",
						label: "Create",
					},
					{
						id: "delete",
						title: "Delete Xyicon",
						label: "Delete",
						enabled: (selectedItems: Xyicon[]) => selectedItems.length > 0 && this.canDelete(selectedItems),
					},
				]}
				isCatalogPanelOpen={this.state.isCatalogOpen}
				isCatalogPanelDocked={this.state.isCatalogDocked}
				setCatalogPanelOpen={this.setCatalogOpen}
				setCatalogPanelDocked={this.setCatalogDocked}
			/>
		);
	}
}

import * as React from "react";
import {observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import type {Catalog} from "../../../../../../data/models/Catalog";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {IconButton} from "../../../../../widgets/button/IconButton";
import type {IModel} from "../../../../../../data/models/Model";
import type {GridView} from "../../../../abstract/grid/GridView";
import type {ICreateUnplottedXyiconParam} from "../../../../abstract/ModuleView";
import {XyiconCatalogContainer} from "./XyiconCatalogContainer";
import {BoundaryTypeContainer} from "./BoundaryTypeContainer";

export type DockableTitle = "Catalog" | "Boundary" | "Unplotted Xyicons";

interface IDockableProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly gridView?: React.RefObject<GridView<IModel>>;
	readonly setDocked: (value: boolean, title: DockableTitle) => void;
	readonly setOpen: (value: boolean, title: DockableTitle) => void;
	readonly isDocked: boolean;
	readonly title: DockableTitle;
	readonly setActiveTool: (id: string) => void;
	readonly onAddCatalogClick: () => void;
	readonly onDuplicateCatalogClick: (catalog: Catalog) => void;
	readonly onCreateUnplottedXyicons: (params: ICreateUnplottedXyiconParam[]) => Promise<void> | void;
}

interface IDockableState {
	open: boolean;
}

@observer
export class Dockable extends React.Component<IDockableProps, IDockableState> {
	private readonly _animationTime: number = 300;

	private _previousTitle: DockableTitle = null;
	private _element = React.createRef<HTMLDivElement>();

	constructor(props: IDockableProps) {
		super(props);
		this.state = {
			open: false,
		};
	}

	private onDockTogglerClick = () => {
		this.props.setDocked(!this.props.isDocked, this.props.title);
	};

	private close = () => {
		this.setState({
			open: false,
		});
	};

	public onCloseClick = () => {
		this.close();
		setTimeout(() => {
			this.props.setOpen(false, this.props.title);
		}, this._animationTime);
	};

	private onBoundaryTypeClick = (typeId: string) => {
		this.props.spaceViewRenderer.boundaryManager.setTypeId(typeId);
		this.props.setActiveTool("boundary");

		if (!this.props.isDocked) {
			this.onCloseClick();
		}
	};

	private fadeIn(milliseconds: number) {
		setTimeout(() => {
			this.forceUpdate();
		}, milliseconds);
	}

	private getElement(renderTitle: DockableTitle) {
		if (renderTitle === "Boundary") {
			return (
				<BoundaryTypeContainer
					spaceViewRenderer={this.props.spaceViewRenderer}
					onBoundaryTypeClick={this.onBoundaryTypeClick}
				/>
			);
		} else {
			return (
				<XyiconCatalogContainer
					spaceViewRenderer={this.props.spaceViewRenderer}
					gridView={this.props.gridView}
					renderTitle={renderTitle}
					onAddCatalogClick={this.props.onAddCatalogClick}
					onDuplicateCatalogClick={this.props.onDuplicateCatalogClick}
					onCreateUnplottedXyicons={this.props.onCreateUnplottedXyicons}
					isDocked={this.props.isDocked}
					isOpen={this.state.open}
					close={this.close}
					onCloseClick={this.onCloseClick}
				/>
			);
		}
	}

	public override componentDidUpdate() {
		this._previousTitle = this.props.title;
	}

	public override render() {
		const dockTitle = this.props.isDocked ? `Undock ${this.props.title}` : `Dock ${this.props.title}`;

		if (this._previousTitle == null) {
			this._previousTitle = this.props.title;
			requestAnimationFrame(() => {
				this.setState({
					open: true,
				});
			});
		}
		const hasChanged = this._previousTitle !== this.props.title;

		if (hasChanged) {
			this.fadeIn(this._previousTitle === null ? 0 : this._animationTime);
		}

		const renderTitle = this._previousTitle || this.props.title;

		return (
			<div
				ref={this._element}
				className={ReactUtils.cls("Dockable vbox flex_1", {
					docked: this.props.isDocked,
					visible: this.state.open && this.props.title === this._previousTitle,
				})}
			>
				<div className="header">
					<div className="title">{renderTitle}</div>
					<div
						className="dockBtn"
						onClick={this.onDockTogglerClick}
						title={dockTitle}
					>
						<div className="container">
							<div />
							<div />
							<div />
						</div>
					</div>
					<IconButton
						className="closeBtn"
						icon="close"
						onClick={this.onCloseClick}
						title={`Close ${renderTitle}`}
					/>
				</div>
				{this.getElement(renderTitle)}
			</div>
		);
	}
}

import {useEffect, useReducer, useRef, useState} from "react";
import {Observer} from "mobx-react";
import styled from "styled-components";
import {StringUtils} from "../../../utils/data/string/StringUtils";
import {View} from "../../../data/models/View";
import {useClickOutside} from "../utils";
import {notify} from "../../../utils/Notify";
import {useAppStore} from "../../../StateManager";
import type {IViewStatus} from "../../../StateManager";
import type {INotificationProps} from "../popup/NotificationV5";
import {NotificationType} from "../popup/NotificationV5";
import {baseDistance, ELLIPSIS, fontSize, radius, zIndex} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {ConfirmWindowV5} from "../popup/ConfirmWindowV5";
import type {XyiconFeature} from "../../../generated/api/base";
import GlobeIcon from "../icons/globe.svg?react";
import UserIcon from "../icons/user.svg?react";
import UsersIcon from "../icons/users.svg?react";
import ChevronDownIcon from "../icons/chevron-down.svg?react";
import CirclePlusIcon from "../icons/circle-plus.svg?react";
import DotsHorizontalIcon from "../icons/dots-horizontal.svg?react";
import {TextInputV5} from "../details/datatypes/TextInputV5";
import {DropdownButtonStyled, DropdownButtonV5} from "../interaction/DropdownButtonV5";
import {SearchFieldV5} from "../input/search/SearchFieldV5";
import {IconButtonV5} from "../interaction/IconButtonV5";
import {ViewSharingPopup} from "../sharing/ViewSharingPopup";
import {ReactUtils} from "../../utils/ReactUtils";
import {SearchFieldStyled} from "../input/search/SearchField.styled";

interface IViewSelectorV5Props {
	readonly feature: XyiconFeature;
}

export const ViewSelectorV5 = (props: IViewSelectorV5Props) => {
	const {feature} = props;
	const appState = useAppStore((state) => state.appState);
	const setViewNotificationStatus = useAppStore((state) => state.setViewNotificationStatus);
	const viewNotificationStatus = useAppStore((state) => state.viewNotificationStatus);
	const [search, setSearch] = useState<string>("");
	const selectedViewRef = useRef<HTMLDivElement>();
	const viewPopupRef = useRef<HTMLDivElement>();
	const viewInEditModeRef = useRef<HTMLDivElement>();
	const [isOpenSelectView, setIsOpenSelectView] = useState<boolean>(false);
	const [viewInEditMode, setViewInEditMode] = useState<View | null>(null);
	const [viewItemForSharePanel, setViewItemForSharePanel] = useState<View | null>(null);
	const [, forceUpdate] = useReducer((x) => x + 1, 0);

	const onChangeView = (view: View) => {
		appState.actions.selectView(view);

		if (viewInEditMode) {
			setViewInEditMode(null);
		}
	};

	useClickOutside([selectedViewRef, viewPopupRef], () => {
		setIsOpenSelectView(false);
	});

	useClickOutside([viewInEditModeRef], () => {
		setViewInEditMode(null);
	});

	const views = appState.actions.getViews(feature).toSorted((a: View, b: View) => StringUtils.sortIgnoreCase(a.name, b.name));

	useEffect(() => {
		let extendedparams: INotificationProps = {
			type: NotificationType.Message,
			title: `You don't have any views`,
			lifeTime: Infinity,
			description: "You can request views to be shared with you directly from an admin, or create one yourself",
			buttonLabel: "Create",
			onActionButtonClick: openSelectView,
			onCloseViewNotification: closeSelectView,
		};

		let notification: INotificationProps | undefined;

		if (views.length === 0) {
			if (viewNotificationStatus[feature as keyof IViewStatus]) {
				notification = notify(appState.app.notificationContainer, extendedparams);
			}
		}

		return () => {
			if (notification) {
				notification.onClose();
			}
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [views]);

	const onDuplicateClick = async (view: View) => {
		if (view && !viewInEditMode) {
			const duplicate = await view.duplicate();

			if (duplicate) {
				setViewInEditMode(duplicate);
			}
		}
	};

	const onDeleteClick = async (view: View) => {
		const confirmed = await ConfirmWindowV5.open("Are you sure you want to delete the selected 1 item?");

		if (confirmed) {
			await appState.app.transport.services.view.delete(view.id);
			forceUpdate();
		}
	};

	const onCloseSharePanel = () => {
		setViewItemForSharePanel(null);
	};

	const onRenameClick = (view: View) => {
		setViewInEditMode(view);
	};

	const onMarkAsGlobalClick = async (view: View) => {
		await appState.app.transport.services.view.markViewAsGlobal(view);
		forceUpdate();
	};

	const getOptions = (view: View) => {
		const user = appState.user;
		const sharedWithMe = view.ownedBy !== user?.id && !view.isGlobal;
		const sharedWithMeWithEdit = sharedWithMe && view?.getPermission(user.id) > 1;
		const userIsOwner = view?.ownedBy === user.id && !view.isGlobal;
		const global = view.isGlobal;
		const options = [];

		const onShareClick = () => {
			setViewItemForSharePanel(view);
			setIsOpenSelectView(false);
		};

		if (userIsOwner || sharedWithMeWithEdit) {
			options.push({
				label: "Share",
				onClick: onShareClick,
			});
		}

		if (userIsOwner) {
			options.push({
				label: "Change Owner",
				onClick: onShareClick,
			});
		}

		if (user.isAdmin && (userIsOwner || sharedWithMeWithEdit)) {
			options.push({
				label: "Mark as Global View",
				onClick: () => onMarkAsGlobalClick(view),
			});
		}

		if (userIsOwner || sharedWithMeWithEdit || (global && user.isAdmin)) {
			options.push({
				label: "Rename",
				onClick: () => onRenameClick(view),
			});
		}

		if (userIsOwner || (global && user.isAdmin)) {
			options.push({
				label: "Delete",
				onClick: () => onDeleteClick(view),
			});
		}

		options.push({
			label: "Duplicate",
			onClick: () => onDuplicateClick(view),
		});

		return options;
	};

	const openSelectView = () => {
		setIsOpenSelectView(true);
		onNewViewClick();
	};

	const closeSelectView = () => {
		setViewNotificationStatus(feature, false);
	};

	const onRenameApply = async (value: string, view: View) => {
		const isNameValid = appState.actions.isNameValidForView(value, feature, view.id);

		if (isNameValid) {
			if (value !== view.name) {
				view.name = value;
				setViewInEditMode(null);
				await appState.app.transport.services.view.update(view.getData());
			}
		}
	};

	const onNewViewClick = async (event?: {stopPropagation: () => void}) => {
		event?.stopPropagation();

		const baseName = "New View";
		const view = View.createNew(props.feature, baseName, appState);

		const actions = appState.actions;
		let counter = 1;

		while (!actions.isNameValidForView(view.name, feature, view.id)) {
			view.name = `${baseName} (${counter++})`;
		}

		const {result: viewData} = await appState.app.transport.services.view.create(view.getData(), props.feature);

		// Select the newly created view
		const newView = appState.actions.getViewById(viewData.viewID);

		onChangeView(newView);
		setViewInEditMode(newView);

		setTimeout(() => {
			const objDiv = document.getElementById("DropdownOptionsV5");

			if (objDiv) {
				objDiv.scrollTop = objDiv.scrollHeight;
			}
		});
	};

	const selectedView = appState.actions.getSelectedView(feature);

	const renderView = (view: View) => {
		const user = appState.user;
		const userIsOwner = view?.ownedBy === user.id;
		const IconComponent = view?.isGlobal ? GlobeIcon : userIsOwner ? UserIcon : UsersIcon;

		if (view === viewInEditMode) {
			const getErrorMessage = (viewName: string) => {
				let errorMessage: string = "";

				if (!viewName.trim()) {
					errorMessage = "View name cannot be empty!";
				} else if (!appState.actions.isNameValidForView(viewName, feature, view.id)) {
					errorMessage = "View name needs to be unique!";
				}

				return errorMessage;
			};

			return (
				<ViewRowStyled
					key={view.id}
					ref={viewInEditModeRef}
					style={{paddingRight: "0"}}
				>
					<IconComponent />
					<TextInputV5
						style={{color: colorPalette.primary.c500Primary, borderColor: colorPalette.primary.c500Primary}}
						value={view.name}
						onChange={(value) => {
							onRenameApply(value, view);
						}}
						getErrorMessage={getErrorMessage}
						autoFocus={true}
					/>
				</ViewRowStyled>
			);
		}

		return (
			<ViewRowStyled
				key={view.id}
				onClick={() => appState.actions.selectView(view)}
				className={ReactUtils.cls({isActive: selectedView === view})}
			>
				<div className="title">
					<IconComponent />
					<div className="viewName">{view.name}</div>
				</div>
				<DropdownButtonV5
					button={<DotsHorizontalIcon />}
					options={getOptions(view)}
					optionsZIndex={zIndex.contextOptions + 1}
				/>
			</ViewRowStyled>
		);
	};

	return (
		<Observer>
			{() => {
				const selectedView = appState.actions.getSelectedView(feature);
				const filteredViews = views.filter((v: View) => StringUtils.containsIgnoreCase(v.name, search));

				return (
					<>
						<ViewSelectorStyled>
							<ViewRowStyled
								ref={selectedViewRef}
								onClick={() => setIsOpenSelectView((o) => !o)}
							>
								<div>{selectedView?.name ?? ""}</div>
								<ChevronDownIcon />
							</ViewRowStyled>
							{isOpenSelectView && (
								<ViewListPopupStyled ref={viewPopupRef}>
									<ViewListPopupHeaderStyled>
										<SearchFieldV5
											value={search}
											onInput={setSearch}
										/>
										<IconButtonV5
											title="Create new View"
											IconComponent={CirclePlusIcon}
											onClick={onNewViewClick}
										/>
									</ViewListPopupHeaderStyled>
									<ViewListStyled>
										{filteredViews.length === 0 && <NoViewsStyled>No views to display</NoViewsStyled>}
										{filteredViews.map((v) => renderView(v))}
									</ViewListStyled>
								</ViewListPopupStyled>
							)}
						</ViewSelectorStyled>
						{viewItemForSharePanel && (
							<ViewSharingPopup
								view={viewItemForSharePanel}
								onClose={onCloseSharePanel}
							/>
						)}
					</>
				);
			}}
		</Observer>
	);
};

const NoViewsStyled = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
`;

const ViewListPopupHeaderStyled = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
	gap: 8px;
	align-items: center;

	${SearchFieldStyled} {
		width: 100%;
	}
`;

const ViewListPopupStyled = styled.div`
	width: 280px;
	height: 400px;
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-top: 8px;
	background: ${colorPalette.white};
	border-radius: ${radius.sm};
	z-index: 2;
	padding: 8px;
	box-shadow: 0px 4px 8px 0px #00000033;
`;

const ViewRowStyled = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 32px;
	gap: ${baseDistance.xs};
	cursor: pointer;
	padding: 8px;
	border-radius: ${radius.sm};

	&:hover {
		background-color: ${colorPalette.gray.c200Light};
	}

	&.isActive {
		background-color: ${colorPalette.primary.c200Light};
		color: ${colorPalette.primary.c500Primary};
	}

	.title {
		max-width: 280px;
		display: flex;
		align-items: center;
		gap: 8px;
		line-height: 20px;

		> svg {
			min-width: 24px;
		}

		.viewName {
			${ELLIPSIS};
		}
	}

	input {
		border: 1px solid ${colorPalette.primary.c500Primary};
		color: ${colorPalette.primary.c500Primary};
	}

	${DropdownButtonStyled} {
		margin-left: auto;
	}
`;

const ViewListStyled = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	max-height: 400px;
	overflow-y: auto;

	${ViewRowStyled} {
		height: 40px;
		font-size: 16px;
		line-height: 24px;

		.viewName {
			width: 170px;
		}
	}

	${DropdownButtonStyled} {
		width: 32px;
		height: 32px;
	}
`;

const ViewSelectorStyled = styled.div`
	font-size: ${fontSize.md};

	> ${ViewRowStyled} {
		svg {
			margin-left: 4px;
			width: 18px;
			height: 18px;
		}
	}
`;

import type {SpaceViewRenderer} from "../../../renderers/SpaceViewRenderer";
import type {MeasureType} from "../../../renderers/SpaceViewRendererUtils";
import type {MarkupCallout} from "../MarkupCallout";
import {TextGroupManager} from "../../../managers/MSDF/TextGroupManager";
import type {SpaceTool} from "../../../features/tools/Tools";
import type {Markup} from "../../../../../../../../data/models/Markup";
import type {PointDouble} from "../../../../../../../../generated/api/base";
import {MarkupType} from "../../../../../../../../generated/api/base";
import {MathUtils} from "../../../../../../../../utils/math/MathUtils";
import {THREEUtils} from "../../../../../../../../utils/THREEUtils";
import {TimeUtils} from "../../../../../../../../utils/TimeUtils";
import {MarkupsWithCustomizableFillOpacity} from "../MarkupStaticElements";
import type {IPreprocessableObjectWithText} from "../../../managers/MSDF/TextUtils";
import {preprocessObjectWithText} from "../../../managers/MSDF/TextUtils";
import {Constants} from "../../../Constants";
import {ObjectUtils} from "../../../../../../../../utils/data/ObjectUtils";
import {HorizontalAlignment, VerticalAlignment} from "../../../../../../../../utils/dom/DomUtils";
import type {SupportedFontName} from "../../../../../../../../data/state/AppStateTypes";
import type {Markup3D} from "./Markup3D";

export interface IMarkupConfig {
	strokeColor?: string;
	strokeOpacity?: number;
	fill?: boolean;
	fillOpacity?: FillOpacity;
	dashSize?: number;
	gapSize?: number;
	measureType?: MeasureType;
	lineWidth?: number;

	// We should ignore the ones below this comment when saving to db, as they're for internal use only
	useSegments?: boolean;
	isHighLight?: boolean;

	// For temporary measure markups, which are not saved into the db
	isTemp?: boolean;
}

export type FillOpacity = 0 | 0.1 | 1;

export class MarkupUtils {
	public static getDefaultFillOpacityForType(type: MarkupType): FillOpacity {
		switch (type) {
			case MarkupType.Cloud:
			case MarkupType.Text_Box:
			case MarkupType.Callout:
				return 0;
			case MarkupType.Irregular_Area:
			case MarkupType.Rectangle_Area:
				return 0.1;
			default:
				return 1;
		}
	}
	public static readonly defaultLineThickness: number = 2; // px
	public static readonly defaultArrowHeadSize: number = 50; // px
}

export const isMarkupFilteredOutByColor = (hiddenMarkupColors: Set<string>, markup: Markup3D | Markup) => {
	return hiddenMarkupColors.has(getMarkupColorForColorFiltering(markup));
};

export const getMarkupColorForColorFiltering = (markup: Markup3D | Markup): string =>
	markup.type === MarkupType.Text_Box ? markup.fontColor.hex : markup.color;

export const TempMarkupLineWidth = 3;

export const getDefaultTargetForMarkupCallout = (
	geometryData: PointDouble[],
	fontFamily: SupportedFontName,
	spaceViewRenderer: SpaceViewRenderer,
): PointDouble => {
	const cameraZoomValueMaybe = spaceViewRenderer.toolManager.cameraControls.cameraZoomValue;
	const cameraZoomValue = MathUtils.isValidNumber(cameraZoomValueMaybe) ? cameraZoomValueMaybe : 1;
	const correctionMultiplierMaybe = spaceViewRenderer.correctionMultiplier.current;
	const correctionMultiplier = MathUtils.isValidNumber(correctionMultiplierMaybe) ? correctionMultiplierMaybe : 0.005;
	const defaultSize = TextGroupManager.getDefaultMarkupTextBoxSize(MarkupType.Callout, fontFamily, cameraZoomValue, correctionMultiplier);

	const targetOffset: PointDouble = {
		x: defaultSize.x * 0.5,
		y: defaultSize.y,
	};

	const leftCenter = {
		x: Math.min(geometryData[0].x, geometryData[1].x),
		y: (geometryData[0].y + geometryData[1].y) / 2,
	};

	return {
		x: leftCenter.x - targetOffset.x,
		y: leftCenter.y - targetOffset.y,
	};
};

export const changeMarkupType = async (markup3Ds: Markup3D[], newType: MarkupType, spaceViewRenderer: SpaceViewRenderer) => {
	const markupManager = spaceViewRenderer.markupManager;
	const markupsToUpdate: Markup[] = [];
	const emptyTextBoxMarkups = markup3Ds.filter((m) => newType === MarkupType.Text_Box && m.textContent.length === 0);

	spaceViewRenderer.inheritedMethods.onExitTextEditMode();

	await TimeUtils.waitForNextFrame();
	await TimeUtils.waitForNextFrame();

	for (const markup3D of markup3Ds) {
		const hasChanged = markup3D.type !== newType;
		const markup = markup3D.modelData as Markup;

		if (hasChanged) {
			if (newType === MarkupType.Callout) {
				markup.setTarget(getDefaultTargetForMarkupCallout(markup.geometryData, "Roboto", spaceViewRenderer));
			}

			markup.setType(newType);
			markup.setFillTransparency(
				MarkupsWithCustomizableFillOpacity.includes(markup3D.type) ? markup.fillTransparency : 1 - MarkupUtils.getDefaultFillOpacityForType(newType),
			);
			markupsToUpdate.push(markup);

			markup3D.destroy(false);
		}
	}

	const markup3DArray = markupManager.addItemsByModel(markupsToUpdate);

	for (const markup3D of markup3DArray) {
		markup3D.select();
	}

	spaceViewRenderer.spaceItemController.updateDetailsPanel();

	await markupManager.updateItems(markup3DArray, true);

	if (emptyTextBoxMarkups.length === 1 && markup3Ds.length === 1) {
		spaceViewRenderer.inheritedMethods.onSwitchToTextEditMode();
	}
};

export const markupTypeToMarkupToolName = (markupType: MarkupType): SpaceTool => {
	switch (markupType) {
		case MarkupType.Arrow:
			return "markupArrow";
		case MarkupType.Bidirectional_Arrow:
			return "markupBidirectionalArrow";
		case MarkupType.Callout:
			return "markupCallout";
		case MarkupType.Cloud:
			return "markupCloud";
		case MarkupType.Cross:
			return "markupCross";
		case MarkupType.Dashed_Line:
			return "markupDashedLine";
		case MarkupType.Ellipse:
			return "markupEllipse";
		case MarkupType.Highlight_Drawing:
			return "markupHighlight";
		case MarkupType.Irregular_Area:
			return "measureIrregularArea";
		case MarkupType.Line:
			return "markupLine";
		case MarkupType.Linear_Distance:
			return "measureLinearDistance";
		case MarkupType.Nonlinear_Distance:
			return "measureNonLinearDistance";
		case MarkupType.Pencil_Drawing:
			return "markupPencil";
		case MarkupType.Rectangle:
			return "markupRectangle";
		case MarkupType.Rectangle_Area:
			return "measureRectArea";
		case MarkupType.Text_Box:
			return "markupText";
		case MarkupType.Triangle:
			return "markupTriangle";
		default:
			return "markupCloud";
	}
};

export const getArrowPointsForMarkupCallout = (markup: MarkupCallout, useSavedTarget: boolean = false) => {
	const {position} = markup;

	const target = useSavedTarget ? (markup?.modelData as Markup)?.savedSettingsData?.target || markup.target : markup.target;

	const corners = markup.get4CornersAsWorldVertices();
	const potentialStartingPoints: PointDouble[] = [];

	for (let i = 0; i < corners.length; ++i) {
		const nextIndex = (i + 1) % corners.length;
		const middleOfLineSegment: PointDouble = {
			x: (corners[i].x + corners[nextIndex].x) / 2,
			y: (corners[i].y + corners[nextIndex].y) / 2,
		};

		potentialStartingPoints.push(middleOfLineSegment);
	}

	const potentialElbowPoints: PointDouble[] = potentialStartingPoints.map((v) => ({
		x: v.x + (v.x - position.x) * 0.5,
		y: v.y + (v.y - position.y) * 0.5,
	}));

	let minIndex: number = 0;
	let minDistance: number = Infinity;

	for (let i = 0; i < potentialStartingPoints.length; ++i) {
		const dist = THREEUtils.calculateDistance([potentialStartingPoints[i], target]);

		if (dist < minDistance) {
			minDistance = dist;
			minIndex = i;
		}
	}

	const startPos = potentialStartingPoints[minIndex];
	const elbowPos = potentialElbowPoints[minIndex];

	return {
		startPos,
		elbowPos,
		target,
	};
};

export function calculateArrowHead(a: PointDouble, b: PointDouble, arrowHeadSize: number, correctionMultiplier: number) {
	const localAtoLocalB = {
		x: b.x - a.x,
		y: b.y - a.y,
	};

	const normal = {
		x: -localAtoLocalB.y,
		y: localAtoLocalB.x,
	};

	const multiplicator = arrowHeadSize * correctionMultiplier;

	const bc = THREEUtils.multiplyByScalar(
		THREEUtils.normalize({
			x: normal.x - localAtoLocalB.x,
			y: normal.y - localAtoLocalB.y,
		}),
		multiplicator,
	);

	const bd = THREEUtils.multiplyByScalar(
		THREEUtils.normalize({
			x: -normal.x - localAtoLocalB.x,
			y: -normal.y - localAtoLocalB.y,
		}),
		multiplicator,
	);

	return {
		bc: bc,
		bd: bd,
	};
}

export function getUnrotatedCornersFromAB(a: PointDouble, b: PointDouble): PointDouble[] {
	// Without this, markup cloud curves are messed up
	return [
		{
			//bottom left, ccw
			x: Math.min(a.x, b.x),
			y: Math.min(a.y, b.y),
		},
		{
			x: Math.max(a.x, b.x),
			y: Math.min(a.y, b.y),
		},
		{
			x: Math.max(a.x, b.x),
			y: Math.max(a.y, b.y),
		},
		{
			x: Math.min(a.x, b.x),
			y: Math.max(a.y, b.y),
		},
	];
}

export const onMarkupTextInputChange = (markup3D: Markup3D, innerText: string, callback?: () => void) => {
	if (markup3D.type === MarkupType.Text_Box || markup3D.type === MarkupType.Callout) {
		const {fontFamily, fontSize, fontColor, isBold, isItalic, isUnderlined, spaceViewRenderer} = markup3D;
		const modelData = markup3D.modelData as Markup;

		const tObject: IPreprocessableObjectWithText = {
			text: [{content: innerText}],
			fontSize,
			fontFamily,
			fontColor,
			isBold,
			isItalic,
			isUnderlined,
		};
		const widthNeedsUpdate = !modelData.isSizeFixed;
		const preprocessedTObject = preprocessObjectWithText(
			tObject,
			TextGroupManager.fonts,
			spaceViewRenderer,
			modelData.isSizeFixed ? markup3D.scale.x : undefined,
		);
		const textObjectSize = preprocessedTObject.size;

		const correctionMultiplier = spaceViewRenderer.correctionMultiplier.current;
		const markupScale = markup3D.scale;
		const margin = 20 * (fontSize / Constants.SIZE.FONT.default) * correctionMultiplier;

		for (const line of tObject.text) {
			line.content = line.content.replace(/\n\n+$/g, "\n");
		}

		const newHeight = modelData.isSizeFixed
			? preprocessedTObject.size.y
			: ((tObject.text[0]?.content.match(/\n/g) || "").length + 1) * TextGroupManager.getLineHeight(fontFamily, fontSize);
		const heightNeedsUpdate = true;

		if (widthNeedsUpdate || heightNeedsUpdate) {
			const geometryData = ObjectUtils.deepClone(markup3D.data.geometryData);

			if (widthNeedsUpdate) {
				const hSortedVertices = [...geometryData].sort((a: PointDouble, b: PointDouble) => a.x - b.x);
				const differenceX = textObjectSize.x + margin - markupScale.x;

				switch (markup3D.textHAlign) {
					case HorizontalAlignment.left:
						hSortedVertices[hSortedVertices.length - 1].x += differenceX;
						break;
					case HorizontalAlignment.center:
						hSortedVertices[0].x -= differenceX / 2;
						hSortedVertices[hSortedVertices.length - 1].x += differenceX / 2;
						break;
					case HorizontalAlignment.right:
						hSortedVertices[0].x -= differenceX;
						break;
				}
			}
			if (heightNeedsUpdate) {
				const vSortedVertices = [...geometryData].sort((a: PointDouble, b: PointDouble) => a.y - b.y);
				const differenceY = newHeight - markupScale.y;

				switch (markup3D.textVAlign) {
					case VerticalAlignment.bottom:
						vSortedVertices[vSortedVertices.length - 1].y += differenceY;
						break;
					case VerticalAlignment.center:
						vSortedVertices[vSortedVertices.length - 1].y += differenceY / 2;
						vSortedVertices[0].y -= differenceY / 2;
						break;
					case VerticalAlignment.top:
						vSortedVertices[0].y -= differenceY;
						break;
				}
			}

			markup3D.updateGeometry(geometryData, false, false);
			markup3D.updateCenter();
			markup3D.itemManager.updateSelectionBox();
			spaceViewRenderer.spaceItemController.updateActionBar();
			callback?.();
		}
	}
};

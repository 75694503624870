import {computed, observable, makeObservable} from "mobx";
import {XyiconFeature, Permission} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import type {ExportFormat} from "../exporters/BaseExporter";
import {ExporterFactory} from "../exporters/ExporterFactory";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import {AppFieldActions, REPORT_COUNT_FIELD} from "../state/AppFields";
import {MathUtils} from "../../utils/math/MathUtils";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {FileUtils} from "../../utils/file/FileUtils";
import {StringUtils} from "../../utils/data/string/StringUtils";
import {DateFormatter} from "../../utils/format/DateFormatter";
import type {
	OutputDataSortField,
	OutputDisplayField,
	ReportDataFilterParameter,
	ReportDto,
	ReportSharingSettingsDto,
	UpdateReportRequest,
} from "../../generated/api/reports";
import {ReportScope, ReportType} from "../../generated/api/reports";
import {LogicalSeparator} from "./filter/LogicalSeparator";
import {FilterOperator} from "./filter/operator/FilterOperator";
import {findOrGroups} from "./filter/Filter";
import type {IFilterRow, IFilterRowFilter} from "./filter/Filter";
import type {IFieldAdapter, IFieldPointer} from "./field/Field";
import type {IModel} from "./Model";

export interface IFieldColumn {
	field: IFieldAdapter;
	linkedField: boolean;
}

export enum ReportSortDirection {
	ASC = "ascending",
	DESC = "descending",
}

export interface IReportResult {
	downloadFileName: string;
	requestID: string;
	organizationID: string;
	isSuccess: boolean;
	errorMessage: string;
}

const defaultColumns: {[feature: number]: IFieldPointer[]} = {
	[XyiconFeature.Xyicon]: [
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "type"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "model"),
	],
	[XyiconFeature.Boundary]: [AppFieldActions.getRefId(XyiconFeature.Boundary, "refId"), AppFieldActions.getRefId(XyiconFeature.Boundary, "type")],
};

export class Report implements IModel, ReportDto {
	private static fieldMapping = {
		[XyiconFeature.Xyicon]: {
			// own (xyicon) fields
			XyiconRefID: AppFieldActions.getRefId(XyiconFeature.Xyicon, "refId"),
			"XyiconType.Name": AppFieldActions.getRefId(XyiconFeature.Xyicon, "type"),
			"XyiconCatalog.Model": AppFieldActions.getRefId(XyiconFeature.Xyicon, "model"),
			LastModifiedAt: AppFieldActions.getRefId(XyiconFeature.Xyicon, "lastModifiedAt"),
			LastModifiedBy: AppFieldActions.getRefId(XyiconFeature.Xyicon, "lastModifiedBy"),

			// inherited
			"Portfolio.Name": AppFieldActions.getRefId(XyiconFeature.Portfolio, "name"),
			"Space.Name": AppFieldActions.getRefId(XyiconFeature.Space, "name"),
		},
		[XyiconFeature.XyiconCatalog]: {
			XyiconCatalogRefID: AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "refId"),
			"XyiconType.Name": AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "type"),
			"XyiconCatalog.Model": AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "model"),
			// "UpdatedAt"           : AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "lastModifiedAt"),
			// "UpdatedBy"           : AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "lastModifiedBy"),
		},
		[XyiconFeature.Boundary]: {
			BoundaryRefID: AppFieldActions.getRefId(XyiconFeature.Boundary, "refId"),
			"BoundaryType.Name": AppFieldActions.getRefId(XyiconFeature.Boundary, "type"),
			// inherited
			"Portfolio.Name": AppFieldActions.getRefId(XyiconFeature.Portfolio, "name"),
			"Space.Name": AppFieldActions.getRefId(XyiconFeature.Space, "name"),
		},
	};

	private static operatorMapping = {
		"=": FilterOperator.IS_EQUAL_TO_STR,
		"!=": FilterOperator.IS_NOT_EQUAL_TO_STR,
		">": FilterOperator.IS_GREATER_THAN_NUM,
		">=": FilterOperator.IS_GREATER_THAN_OR_EQUAL_TO_NUM,
		"<": FilterOperator.IS_LESS_THAN_NUM,
		"<=": FilterOperator.IS_LESS_THAN_OR_EQUAL_TO_NUM,
		sw: FilterOperator.IS_STARTING_WITH,
		ew: FilterOperator.IS_ENDING_WITH,
		c: FilterOperator.CONTAINS,
		"!c": FilterOperator.DOES_NOT_CONTAIN,
		bk: FilterOperator.IS_BLANK,
		"!bk": FilterOperator.IS_NOT_BLANK,
		any: FilterOperator.IS_ANY_OF,
		"!any": FilterOperator.IS_NOT_ANY_OF,
	};

	// refId -> fieldName
	public static serializeFieldName(refId: string, report: Report, appState: AppState): string {
		if (refId === REPORT_COUNT_FIELD) {
			return REPORT_COUNT_FIELD;
		}

		const field = appState.actions.getFieldByRefId(refId);

		if (field) {
			if (field.default) {
				const mapping =
					Report.fieldMapping[report.reportFeature as XyiconFeature.Xyicon | XyiconFeature.XyiconCatalog | XyiconFeature.Boundary] ||
					Report.fieldMapping[XyiconFeature.Xyicon];

				for (const name in mapping) {
					const ref = mapping[name as keyof typeof mapping];

					if (refId === ref) {
						return name;
					}
				}
			} else {
				// if (report.reportFeature !== field.feature)
				// {
				// 	const featurePrefix = {
				// 		[XyiconFeature.Portfolio] : "Portfolio",
				// 		[XyiconFeature.Space]     : "Space",
				// 		[XyiconFeature.XyiconCatalog]   : "Catalog",
				// 		[XyiconFeature.Boundary]  : "Boundary",
				// 		[XyiconFeature.Xyicon]    : "Xyicon",
				// 		[XyiconFeature.Document]  : "Document",
				// 		[XyiconFeature.User]      : "User",
				// 	}[field.feature] || field.feature;
				//
				// 	return `${featurePrefix}.FieldData[${field.refId}]`;
				// }
				// else
				// {
				return `FieldData[${field.refId}]`;
				// }
			}
		}

		return "";
	}

	// fieldName -> refId
	private static deserializeFieldName(name: string, report: Report): string {
		if (name === REPORT_COUNT_FIELD) {
			return REPORT_COUNT_FIELD;
		}

		if (name.includes("FieldData[")) {
			// custom field
			const regExp = /FieldData\[(\w+)\]/; // FieldData[refId]

			try {
				const refId = name.match(regExp)?.[1];

				return refId || "";
			} catch (e) {
				console.warn("Report::deserializeFieldName error", e);
			}
		} else {
			const mapping =
				Report.fieldMapping[report.reportFeature as XyiconFeature.Xyicon | XyiconFeature.XyiconCatalog | XyiconFeature.Boundary] ||
				Report.fieldMapping[XyiconFeature.Xyicon];

			//?
			return mapping[name as keyof typeof mapping] || "";
		}

		return "";
	}

	private static deserializeOperator(operator: string, value: any): FilterOperator {
		if (operator === "=") {
			if (value === true) {
				return FilterOperator.IS_TRUE;
			} else if (value === false) {
				return FilterOperator.IS_FALSE;
			}
		}
		return Report.operatorMapping[operator as keyof typeof Report.operatorMapping];
	}

	public static serializeOperator(operator: FilterOperator): string {
		if (operator === FilterOperator.IS_TRUE || operator === FilterOperator.IS_FALSE) {
			return "=";
		}

		for (const key in Report.operatorMapping) {
			const value = Report.operatorMapping[key as keyof typeof Report.operatorMapping];

			if (value === operator) {
				return key;
			}
		}
	}

	public static serializeFilterParam(param: any, operator: FilterOperator) {
		if (operator === FilterOperator.IS_TRUE) {
			return true;
		}
		if (operator === FilterOperator.IS_FALSE) {
			return false;
		}
		if ([FilterOperator.IS_BLANK, FilterOperator.IS_NOT_BLANK].includes(operator)) {
			return "";
		}
		return param;
	}

	public static canCreateReports(appState: AppState) {
		return !!appState.user?.isAdmin;
	}

	/**
	 * Returns the permission for the logged in user on the parameter report.
	 */
	public static getPermission(report: Report, appState: AppState): Permission {
		const user = appState.user;

		if (!user) {
			return Permission.None;
		}

		if (user.isAdmin && user.id === report.ownerUserId) {
			// admins can delete their own reports (and update, view)
			return Permission.Delete;
		}

		const userGroupIdsForLoggedInUser = user.userGroupIds;
		// If it's not their own project, or the user is not admin -> decide using the sharing

		let permission: Permission = Permission.None;

		for (const sharing of report.sharingSettings) {
			if (
				sharing.userID === user.id ||
				(sharing.userGroupID && userGroupIdsForLoggedInUser.some((userGroupId) => userGroupId === sharing.userGroupID))
			) {
				const p = sharing.canEditSharedReport && user.isAdmin ? Permission.Update : Permission.View;

				if (p > permission) {
					permission = p;

					// if (permission === Permission.Delete)
					// {
					// 	// We found the highest permission
					// 	break;
					// }
				}
			}
		}

		return permission;
	}

	public readonly ownFeature = XyiconFeature.Report;

	public isDuplicate = false;
	private _savedState: ReportDto;

	@observable
	private _data: ReportDto;

	private _fullData: ReportDto;

	@observable
	private _scope: ReportScope;

	@observable
	private _portfolioIDList: string[] = [];

	@observable
	private _displayFields: OutputDisplayField[] = [];

	@observable
	private _displayedLinkedFields: OutputDisplayField[] = [];

	@observable
	private _summarizeResults: boolean = false;

	@observable
	private _sortFields: OutputDataSortField[] = [];

	@observable
	private _sortLinkedFields: OutputDataSortField[] = [];

	@observable
	private _filters: IFilterRow[] = [];

	@observable
	private _linkedFilters: IFilterRow[] = [];

	private _requestID: string = "";
	private _filePath: string = "";
	private _filePromise: Promise<void>;

	@observable
	private _result: IReportResult;

	@observable
	private _reportData: (string | number)[][] = [];

	@observable
	private _reportLoaded: boolean = false;
	private _loadResolves: Function[] = [];

	private readonly _appState: AppState;

	public static createNew(appState: AppState, feature: XyiconFeature = XyiconFeature.Xyicon) {
		const report = new Report(appState, {feature: feature});

		report.initDisplayFields();
		return report;
	}

	constructor(
		appState: AppState,
		data_: ReportDto = {
			feature: XyiconFeature.Xyicon,
		},
	) {
		makeObservable(this);
		this._appState = appState;
		this.copyFromData(data_);
	}

	public copyFromData(data_: ReportDto) {
		const {definition, ...data} = data_;

		this._data = data;
		this._fullData = data_;

		this._data.description = this._data.description || "";
		this._data.reportSharingSettings = this._data.reportSharingSettings || [];

		try {
			this._portfolioIDList = definition?.entryPointParameters?.parameters?.portfolioIDList || [];
		} catch (e) {
			console.warn(e);
		}

		try {
			this._displayFields = this.parseDisplayFields(definition?.leftConfiguration.outputDataDisplayedFields);
			this._displayedLinkedFields = this.parseDisplayFields(definition?.rightConfiguration.outputDataDisplayedFields);
		} catch (e) {
			console.warn(e);
		}

		try {
			this.parseFilters(definition?.leftConfiguration.dataFilterParameters);
			this.parseFilters(definition?.rightConfiguration.dataFilterParameters, true);
		} catch (e) {
			console.warn(e);
		}

		try {
			this._summarizeResults = !!definition?.summarizeResults;
		} catch (e) {
			console.warn(e);
		}

		try {
			this._sortFields = this.parseSortFields(definition?.leftConfiguration.outputDataSortFields);
			this._sortLinkedFields = this.parseSortFields(definition?.rightConfiguration.outputDataSortFields);
		} catch (e) {
			console.warn(e);
		}

		try {
			this._scope = definition?.scope || ReportScope.CurrentPortfolio;
		} catch (e) {
			console.warn(e);
		}
	}

	public applyData(data: ReportDto) {
		this._data.lastModifiedBy = data.lastModifiedBy;
		this._data.lastModifiedAt = data.lastModifiedAt;
	}

	public getPortfolio(appState: AppState) {
		if (this.scope === ReportScope.PortfolioIDList) {
			return this._portfolioIDList[0];
		}
		return appState.portfolioId;
	}

	// We want to be able to revert changes if the editing is canceled
	// Other option would be to create a temporary Report clone.
	public startEditing() {
		this._savedState = {
			...this._data, // need refId, etc.
			...ObjectUtils.deepClone(this.serializeData()),
		};
	}

	public cancelEditing() {
		if (this._savedState) {
			this.copyFromData(this._savedState);
			this._savedState = null;
		}
	}

	private parseDisplayFields(displayFields: OutputDisplayField[]) {
		displayFields = displayFields || [];

		return displayFields.map((displayField) => ({
			...displayField,
			field: Report.deserializeFieldName(displayField.field, this),
		}));
	}

	// See serializeFilter for more on structure.
	// - filter is an "or" group or a filter
	// - it can have filter rows or "and" groups which can have filter rows
	private parseFilters(pFilter: ReportDataFilterParameter, linkedFields?: boolean) {
		if (!linkedFields) {
			this._filters = [];
		} else {
			this._linkedFilters = [];
		}

		if (pFilter) {
			if (!pFilter.logic) {
				// filter is a simple filter
				const f = this.parseFilter(pFilter);

				this.addFilter(f, null, linkedFields);
			} else {
				pFilter.filters.forEach((filter, index) => {
					if (filter.logic === "and") {
						// "and" group
						for (let i = 0; i < filter.filters.length; ++i) {
							const filterData = filter.filters[i];
							const f = this.parseFilter(filterData);

							this.addFilter(f, i === filter.filters.length - 1 ? LogicalSeparator.OR : LogicalSeparator.AND, linkedFields);
						}
					} else {
						if (filter.field) {
							const f = this.parseFilter(filter);

							this.addFilter(f, pFilter.logic === "or" ? LogicalSeparator.OR : LogicalSeparator.AND, linkedFields);
						}
					}
				});
			}

			// remove last filter if it's a separator
			if (this._filters[this._filters.length - 1]?.type === "separator") {
				this._filters.pop();
			}

			// remove last filter if it's a separator
			if (this._linkedFilters[this._linkedFilters.length - 1]?.type === "separator") {
				this._linkedFilters.pop();
			}
		}
	}

	private parseFilter(filter: ReportDataFilterParameter): IFilterRowFilter {
		const operator = Report.deserializeOperator(filter.operator, filter.value);

		if (!operator) {
			// No matching operator supported in front-end
			return null;
		}

		return {
			value: {
				field: Report.deserializeFieldName(filter.field, this),
				operator: operator,
				param: filter.value,
			},
			type: "filter",
		};
	}

	private addFilter(filter: IFilterRowFilter, separator?: LogicalSeparator, linkedFields?: boolean) {
		// filter might be null for custom reports
		if (filter) {
			let filters: IFilterRow[] = this._filters;

			if (linkedFields) {
				filters = this._linkedFilters;
			}

			filters.push(filter);
			if (separator) {
				filters.push({
					type: "separator",
					value: separator,
				});
			}

			if (linkedFields) {
				this._linkedFilters = filters;
			} else {
				this._filters = filters;
			}
		}
	}

	private parseSortFields(sortFields: OutputDataSortField[]): OutputDataSortField[] {
		sortFields = sortFields || [];

		return sortFields.map((sortField) => ({
			direction: sortField.direction,
			name: Report.deserializeFieldName(sortField.name, this),
		}));
	}

	private serializeDisplayFields(appState: AppState, linkedFields?: boolean) {
		let displayFields = this._displayFields;

		if (linkedFields) {
			displayFields = this._displayedLinkedFields;
		}

		if (this.type === ReportType.UserDefinedLinkedXyiconReport && this.summarizeResults && !linkedFields) {
			displayFields = ArrayUtils.remove(
				displayFields,
				displayFields.find((df) => df.field === REPORT_COUNT_FIELD),
			);
		}

		return displayFields
			.map((displayField) => {
				const obj: OutputDisplayField = {...displayField};

				if (!obj.displayName) {
					const f = appState.actions.getFieldByRefId(obj.field);

					if (f) {
						obj.displayName = f.name;
					}
				}
				obj.field = Report.serializeFieldName(displayField.field, this, appState);

				return obj;
			})
			.filter((f) => !!f.field);
	}

	private serializeSortFields(appState: AppState, linkedFields?: boolean) {
		let sortFields = this._sortFields;

		if (linkedFields) {
			sortFields = this._sortLinkedFields;
		}

		return sortFields
			.map((sortField) => {
				const obj: OutputDataSortField = {...sortField};

				obj.name = Report.serializeFieldName(sortField.name, this, appState);
				return obj;
			})
			.filter((f) => !!f.name);
	}

	/**
	 * The advanced filter UI supports a logical operator between each filter, eg.:
	 * - filter1
	 * - AND
	 * - filter2
	 * - OR
	 * - filter3
	 * => This is interpreted by giving precedence to AND groups,  like this:
	 * filter1 OR (filter2 AND filter3)
	 *
	 * The report api accepts the above structure like this:
	 *
	 * {
	 *   "Logic": "or",
	 *   "Filters": [
	 *     {
	 *       "Field": "xyicon.XyiconType.Name", // filter1
	 *       "Operator": "=",
	 *       "Value": "Cabling"
	 *     },
	 *     {
	 *       "Logic": "and",
	 *       "Filters": [
	 *         {
	 *           "Field": "xyicon.XyiconCatalog.Model", // filter2
	 *           "Operator": "=",
	 *           "Value": "A"
	 *         },
	 *         {
	 *           "Field": "xyicon.XyiconCatalog.Model", // filter 3
	 *           "Operator": "=",
	 *           "Value": "A"
	 *         }
	 *       ]
	 *     }
	 *   ]
	 * }
	 *
	 * *Note: it's not possible by the advanced filter UI to define this: (filter 1 OR filter2) AND filter3.
	 */
	private serializeFilters(linkedFields?: boolean): ReportDataFilterParameter {
		let filters = this._filters;

		if (linkedFields) {
			filters = this._linkedFilters;
		}

		if (filters.length === 0) {
			return null;
		}

		const orGroups = findOrGroups(filters);

		return {
			logic: "or",
			field: null,
			operator: null,
			value: null,
			filters: orGroups.map((orGroup) => this.serializeOrGroup(orGroup)),
		};
	}

	private serializeOrGroup(orGroup: IFilterRowFilter[]): ReportDataFilterParameter {
		if (orGroup.length > 1) {
			return {
				filters: orGroup.map((filter: IFilterRowFilter) => this.serializeFilter(filter)),
				logic: "and",
				field: null,
				operator: null,
				value: null,
			};
		} else {
			// Only one filter -> serialize the filter itself
			return this.serializeFilter(orGroup[0]);
		}
	}

	private serializeFilter(filter: IFilterRowFilter): ReportDataFilterParameter {
		return {
			field: Report.serializeFieldName(filter.value.field, this, this._appState),
			operator: Report.serializeOperator(filter.value.operator),
			value: Report.serializeFilterParam(filter.value.param, filter.value.operator),
			filters: [],
			logic: null,
		};
	}

	public addRequestID(requestID: string) {
		this._requestID = requestID;
		this._result = null;
	}

	public clearReport() {
		this._reportData = [];
		this._reportLoaded = false;
		this._result = null;
	}

	public completeRequest(result: IReportResult, filePath: string) {
		if (this._requestID && this._requestID === result.requestID) {
			this._requestID = "";
			this._result = result;
			if (result.isSuccess) {
				if (this.deliveryMethod !== "Email") {
					if (this._filePath !== filePath) {
						this._filePath = filePath;

						if (this.type !== ReportType.CustomReport) {
							this._filePromise = new Promise<void>((resolve, reject) => {
								XHRLoader.load({
									url: this._filePath,
									json: false,
									onComplete: (xhr) => {
										const result = xhr.result;

										this.parseCSV(result);
										resolve();
									},
									onFail: () => {
										reject();
									},
								});
							});
						} else {
							this.completeLoad();
						}
					}
				}
			} else {
				console.warn("Report.isSuccess is not true. Something went wrong?");
				this.completeLoad();
			}

			return true;
		}

		return false;
	}

	private parseCSV(text: string) {
		const startLine = this.scope === ReportScope.Organization ? 2 : 3;

		// backend returns at least 2 columns, so we have to suppress the second if needed...
		let columns = this._displayFields.length;

		if (this.type === ReportType.UserDefinedLinkedXyiconReport) {
			columns += this._displayedLinkedFields.length;
		}

		if (this.summarizeResults) {
			columns++;
		}

		const arr = text.replace(/\r\n/g, "\n").split("\n").slice(startLine);

		this._reportData = StringUtils.CSVToArray(arr.join("\n"), ",", columns);

		this.completeLoad();
	}

	private completeLoad() {
		this._reportLoaded = true;
		this._loadResolves.forEach((c) => c());
		this._loadResolves.length = 0;
	}

	public exportReport(format: ExportFormat, data?: (string | number)[][], report?: Report) {
		data = data || this.reportData;
		const exporter = ExporterFactory.createExporter(format, this._appState);
		const fileName = `${this.name} ${DateFormatter.timeStampForDownload()}.${exporter.extension}`;

		exporter?.exportTable(data, fileName, this._data.feature as unknown as XyiconFeature, report);
	}

	public downloadReport() {
		if (this._filePath) {
			const fileName = this._result?.downloadFileName || `${this.name} ${DateFormatter.timeStampForDownload()}.${this.deliveryFormat || "csv"}`;

			FileUtils.downloadFileFromUrl(this._filePath, fileName);
		}
	}

	public duplicate(name?: string) {
		const data = this.serializeData();

		data.reportID = "";
		data.name = name || `${data.name} (Duplicate)`;
		data.ownerUserID = this._appState.user.id;

		const report = new Report(this._appState, data);

		report.isDuplicate = true;

		report.resetSharingSettings();

		return report;
	}

	@computed
	public get id() {
		return this._data.reportID;
	}

	@computed
	public get itemFeature() {
		return this._data.feature;
	}

	@computed
	public get refId() {
		return this._data.reportRefID;
	}

	@computed
	public get name() {
		return this._data.name ?? "";
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get category() {
		return this.isUserDefined ? "User Created" : "Custom Designed";
	}

	public get isUserDefined() {
		return this._data.type !== ReportType.CustomReport;
	}

	@computed
	public get type() {
		return this._data.type;
	}

	public set type(value: ReportType) {
		this._data.type = value;
	}

	@computed
	public get reportFeature() {
		return this._data.feature as unknown as XyiconFeature;
	}

	public set reportFeature(value: XyiconFeature) {
		if (value === XyiconFeature.XyiconCatalog) {
			this.scope = ReportScope.Organization;
		}

		this._data.feature = value;

		// When switching from xyicon to boundary during creation
		this.initDisplayFields();
	}

	@computed
	public get reportFeatureTitle() {
		return XyiconFeature[this._data.feature];
	}

	private initDisplayFields() {
		const defaultFields = defaultColumns[this.reportFeature];

		if (defaultFields) {
			this._displayFields = [];
			this.addDisplayedFields(defaultFields);
			this.addDisplayedFields(defaultFields, null, true);
		}
	}

	@computed
	public get scope(): ReportScope {
		return this._scope;
	}

	public set scope(value: ReportScope) {
		if (this._scope !== value) {
			this._scope = value;
			if (this._scope === ReportScope.PortfolioIDList && this._portfolioIDList.length === 0) {
				if (this._appState.portfolioId) {
					this._portfolioIDList.push(this._appState.portfolioId);
				}
			}
		}
	}

	@computed
	public get scopeTitle(): String {
		return ReportScope[this._scope];
	}

	@computed
	public get portfolioIDList() {
		return this._portfolioIDList;
	}

	public set portfolioIDList(value: string[]) {
		this._portfolioIDList = value;
	}

	public addDisplayedFields(fieldRefIds: IFieldPointer[], index?: number, linkedFields?: boolean) {
		let displayFields = this._displayFields;

		if (linkedFields) {
			displayFields = this._displayedLinkedFields;
		}

		for (let i = 0; i < fieldRefIds.length; i++) {
			let fieldDefaultDisplayName = "";

			if (linkedFields) {
				const field = this._appState.actions.getFieldByRefId(fieldRefIds[i]);

				if (field && field.name) {
					fieldDefaultDisplayName = `Linked ${field.name}`;
				} else {
					fieldDefaultDisplayName = `Linked field ${i + 1}`;
				}
			} else {
				fieldDefaultDisplayName = "";
			}

			//const fieldKey = Report.getFieldKey(field);
			if (!displayFields.some((f) => f.field === fieldRefIds[i])) {
				if (MathUtils.isWholeNum(index)) {
					displayFields.splice(index, 0, {
						field: fieldRefIds[i],
						displayName: fieldDefaultDisplayName,
						format: null,
					});
				} else {
					displayFields.push({
						field: fieldRefIds[i],
						displayName: fieldDefaultDisplayName,
						format: null,
					});
				}
			}
		}

		if (linkedFields) {
			this._displayedLinkedFields = displayFields;
		} else {
			this._displayFields = displayFields;
		}
	}

	public removeDisplayedFields(fieldColumns: IFieldColumn[], linkedFields?: boolean) {
		let displayFields = this._displayFields;

		if (linkedFields) {
			displayFields = this._displayedLinkedFields;
		}

		displayFields = displayFields.filter((f) => !fieldColumns.some((fieldColumn) => f.field === fieldColumn.field.refId));

		if (linkedFields) {
			this._displayedLinkedFields = displayFields;
		} else {
			this._displayFields = displayFields;
		}
	}

	public reorderDisplayedFields(fromIndex: number | number[], toIndex: number, linkedFields?: boolean) {
		let displayFields = this._displayFields;

		if (linkedFields) {
			displayFields = this._displayedLinkedFields;
		}

		if (Array.isArray(fromIndex)) {
			const columns = displayFields;
			let reordered: OutputDisplayField[] = null;
			let pickedColumns: OutputDisplayField[] = [];
			let arrayWithoutPickedColumns: OutputDisplayField[] = [];

			const itemBeforeToIndex: OutputDisplayField = columns.at(toIndex - 1);

			for (let i = 0; i < columns.length; i++) {
				if (fromIndex.includes(i)) {
					pickedColumns.push(columns[i]);
				} else {
					arrayWithoutPickedColumns.push(columns[i]);
				}
			}

			const newToIndex = toIndex === 0 ? 0 : arrayWithoutPickedColumns.findIndex((col) => col.field === itemBeforeToIndex.field) + 1;

			reordered = [...arrayWithoutPickedColumns.slice(0, newToIndex), ...pickedColumns, ...arrayWithoutPickedColumns.slice(newToIndex)];
			displayFields = reordered;
		} else {
			displayFields = ArrayUtils.move(displayFields, fromIndex, toIndex);
		}

		if (linkedFields) {
			this._displayedLinkedFields = displayFields;
		} else {
			this._displayFields = displayFields;
		}
	}

	public serializeData(): UpdateReportRequest {
		const appState = this._appState;

		let portfolioIDList: string[] = [];

		if (this.scope === ReportScope.PortfolioIDList) {
			portfolioIDList = this.portfolioIDList.slice();
		}
		if (this.scope === ReportScope.CurrentPortfolio) {
			portfolioIDList = [appState.portfolioId];
		}

		const result: UpdateReportRequest = {
			...this._fullData,
			userID: appState.user.id,
			ownerUserID: this.ownerUserId,
			name: this.name,
			description: this.description,
			feature: this.reportFeature,
			type: this.type || ReportType.UserDefinedReport,
			deliveryMethod: this.deliveryMethod || "Download",
			deliveryFormat: this.deliveryFormat || "csv",
			definition: {
				...this._fullData?.definition,
				scope: this.scope,
				summarizeResults: this._summarizeResults,
				entryPointParameters: {
					...this._fullData?.definition?.entryPointParameters,
					parameters: {
						portfolioIDList: portfolioIDList,
					},
				},
				leftConfiguration: {
					dataFilterParameters: this.serializeFilters(),
					outputDataDisplayedFields: this.serializeDisplayFields(appState),
					outputDataFilterParameters: [],
					outputDataSortFields: this.serializeSortFields(appState),
				},
				rightConfiguration: {
					dataFilterParameters: this.serializeFilters(true),
					outputDataDisplayedFields: this.serializeDisplayFields(appState, true),
					outputDataFilterParameters: [],
					outputDataSortFields: this.serializeSortFields(appState, true),
				},
			},
			reportSharingSettings: this.sharingSettings || [],
		};

		return result;
	}

	@computed
	public get reportData() {
		return this._reportData;
	}

	@computed
	public get reportLoaded() {
		return this._reportLoaded;
	}

	public onLoaded() {
		return new Promise<void>((resolve) => {
			if (this._reportLoaded) {
				resolve();
			} else {
				this._loadResolves.push(resolve);
			}
		});
	}

	public set displayedFields(value: OutputDisplayField[]) {
		this._displayFields = value;
	}

	public set displayedLinkedFields(value: OutputDisplayField[]) {
		this._displayedLinkedFields = value;
	}

	@computed
	public get displayedFields() {
		return this._displayFields;
	}

	@computed
	public get displayedLinkedFields() {
		return this._displayedLinkedFields;
	}

	public set summarizeResults(value: boolean) {
		this._summarizeResults = value;

		if (this._summarizeResults) {
			// id columns need to be removed from displayedFields
			this.displayedFields = this.displayedFields.filter((f) => {
				const field = this._appState.actions.getFieldByRefId(f.field);

				if (field?.unique) {
					return false;
				}
				return true;
			});

			this.displayedLinkedFields = this.displayedLinkedFields.filter((f) => {
				const field = this._appState.actions.getFieldByRefId(f.field);

				if (field?.unique) {
					return false;
				}
				return true;
			});

			// Add sort column to displayedFields
			if (!this.displayedFields.some((f) => f.field === REPORT_COUNT_FIELD)) {
				this.displayedFields.push({
					field: REPORT_COUNT_FIELD,
					displayName: "Count",
					format: null,
				});
			}

			if (!this.displayedLinkedFields.some((f) => f.field === REPORT_COUNT_FIELD)) {
				this.displayedLinkedFields.push({
					field: REPORT_COUNT_FIELD,
					displayName: "Linked Fields Count",
					format: null,
				});
			}
		} else {
			// Remove sort column from displayedFields
			this.displayedFields = this.displayedFields.filter((f) => f.field !== REPORT_COUNT_FIELD);
			this.displayedLinkedFields = this.displayedLinkedFields.filter((f) => f.field !== REPORT_COUNT_FIELD);
		}
	}

	@computed
	public get summarizeResults() {
		return this._summarizeResults;
	}

	public set sortFields(value: OutputDataSortField[]) {
		this._sortFields = value;
	}

	public set sortLinkedFields(value: OutputDataSortField[]) {
		this._sortLinkedFields = value;
	}

	@computed
	public get sortFields() {
		return this._sortFields;
	}

	@computed
	public get sortLinkedFields() {
		return this._sortLinkedFields;
	}

	public removeSortField(sortField: OutputDataSortField, linkedFields?: boolean) {
		if (!linkedFields) {
			this._sortFields = ArrayUtils.remove(this._sortFields, sortField);
		} else {
			this._sortLinkedFields = ArrayUtils.remove(this._sortLinkedFields, sortField);
		}
	}

	@computed
	public get filters() {
		return this._filters;
	}

	@computed
	public get linkedFilters() {
		return this._linkedFilters;
	}

	@computed
	public get deliveryMethod() {
		return this._data.deliveryMethod;
	}

	@computed
	public get deliveryFormat() {
		return this._data.deliveryFormat;
	}

	@computed
	public get description() {
		return this._data.description;
	}

	public set description(value: string) {
		this._data.description = value;
	}

	@computed
	public get sharingSettings() {
		return this._data.reportSharingSettings;
	}

	public setSharingSettings(value: ReportSharingSettingsDto[]) {
		this._data.reportSharingSettings.length = 0;
		this._data.reportSharingSettings.push(...value);
	}

	public removeUserFromSharing(userId: string) {
		const indexOfUser = this._data.reportSharingSettings.findIndex((sh) => sh.userID === userId);

		if (indexOfUser !== -1) {
			this._data.reportSharingSettings.splice(indexOfUser, 1);
		}
	}

	public removeUserGroupFromSharing(userGroupId: string) {
		const indexOfUserGroup = this._data.reportSharingSettings.findIndex((sh) => sh.userGroupID === userGroupId);

		if (indexOfUserGroup !== -1) {
			this._data.reportSharingSettings.splice(indexOfUserGroup, 1);
		}
	}

	public resetSharingSettings() {
		this._data.reportSharingSettings = [];
	}

	@computed
	public get ownerUserId() {
		return this._data.ownerUserID || this._appState.user.id;
	}

	public setOwnerUserId(userId: string) {
		this._data.ownerUserID = userId;
	}

	@computed
	public get lastModifiedBy() {
		return this._data.lastModifiedBy;
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get errorMessage() {
		return this._result?.errorMessage || "";
	}

	public static isFieldColumnEquals(fc1: IFieldColumn, fc2: IFieldColumn) {
		return fc1.field.refId === fc2.field.refId && !!fc1.linkedField === !!fc2.linkedField;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import {TextInput} from "../../../text/TextInput";
import type {IInputProps} from "../../FieldInput";
import {ReactUtils} from "../../../../../utils/ReactUtils";

export const MultiLineInput = (props: IInputProps) => {
	const {value, onInput, onBlur, onChange, scrollHeight, inline, expand, caretPosition} = props;
	const inlineStyle: React.CSSProperties = {};

	if (scrollHeight) {
		inlineStyle.height = scrollHeight + (inline ? 0 : scrollHeight > 90 ? 40 : 20);
	}

	if (inline && !expand) {
		inlineStyle.maxHeight = 88;
	}

	if (!inline && !expand) {
		inlineStyle.maxHeight = 90;
	}

	return (
		<div className={ReactUtils.cls("vbox multiline", {expand, inline})}>
			<TextInput
				value={value}
				onInput={onInput}
				onBlur={onBlur}
				onChange={onChange}
				isTextArea={true}
				autoFocus={true}
				style={inlineStyle}
				caretPosition={caretPosition}
			/>
		</div>
	);
};

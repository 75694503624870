import type {IReactionDisposer} from "mobx";
import {computed, observable, makeObservable, reaction, runInAction} from "mobx";
import {ArrayUtils} from "../../utils/data/array/ArrayUtils";
import {XyiconFeature, Permission, ViewPreferenceCategory} from "../../generated/api/base";
import type {AppState} from "../state/AppState";
import type {ILayerColumn, ILayerSettings} from "../../ui/modules/space/spaceeditor/ui/viewbar/LayerView";
import type {ViewDto} from "../../generated/api/base";
import {ObjectUtils} from "../../utils/data/ObjectUtils";
import {colorRuleCategories} from "../../ui/modules/space/spaceeditor/ui/viewbar/ColorRules";
import {AppFieldActions} from "../state/AppFields";
import {MathUtils} from "../../utils/math/MathUtils";
import type {IFieldPointer} from "./field/Field";
import {createFilterState} from "./filter/Filter";
import type {IFilter, IFilterRow, IFilterState} from "./filter/Filter";
import type {IModel} from "./Model";
import {FilterOperator} from "./filter/operator/FilterOperator";
import {doesItemExistInViewFolderStructure, removeElementFromViewFolderStructureById, getItemByIdInViewFolderStructure} from "./ViewUtils";
import type {ViewFolderStructure, ICaptionConfig, IFormattingRuleSet, IViewColumn, IViewSort, ViewChangeType} from "./ViewUtils";

interface ISpaceEditorViewSettings {
	layers: {
		background: {
			isHidden: boolean;
		};
		boundary: ILayerSettings;
		xyicon: ILayerSettings;
		markup: ILayerSettings;
		hiddenMarkupColors: string[]; // hex values that are hidden, eg.: ["FF0000", "0DE288"]
	};
	formattingRules: {
		boundary: IFormattingRuleSet;
		xyicon: IFormattingRuleSet;
	};
	captions: ICaptionConfig;
}

interface IViewAdditionalDetails {
	settings?: {
		splitterRatios: number[];
	};
	spaceEditorViewSettings?: ISpaceEditorViewSettings;
}

type SpaceEditorColumns = {
	[XyiconFeature.Xyicon]: IViewColumn[];
	[XyiconFeature.Boundary]: IViewColumn[];
};

const defaultColumns: {[feature: number]: IFieldPointer[]} = {
	[XyiconFeature.Portfolio]: [
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "name"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "type"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "lastModifiedBy"),
		AppFieldActions.getRefId(XyiconFeature.Portfolio, "lastModifiedAt"),
	],
	[XyiconFeature.XyiconCatalog]: [
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "refId"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "type"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "model"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "lastModifiedBy"),
		AppFieldActions.getRefId(XyiconFeature.XyiconCatalog, "lastModifiedAt"),
	],
	[XyiconFeature.Space]: [
		AppFieldActions.getRefId(XyiconFeature.Space, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Space, "name"),
		AppFieldActions.getRefId(XyiconFeature.Space, "type"),
		AppFieldActions.getRefId(XyiconFeature.Space, "versionName"),
		AppFieldActions.getRefId(XyiconFeature.Space, "issuanceDate"),
		AppFieldActions.getRefId(XyiconFeature.Space, "lastModifiedBy"),
		AppFieldActions.getRefId(XyiconFeature.Space, "lastModifiedAt"),
	],
	[XyiconFeature.Boundary]: [
		AppFieldActions.getRefId(XyiconFeature.Boundary, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Boundary, "type"),
		AppFieldActions.getRefId(XyiconFeature.Boundary, "lastModifiedBy"),
		AppFieldActions.getRefId(XyiconFeature.Boundary, "lastModifiedAt"),
	],
	[XyiconFeature.Xyicon]: [
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "refId"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "type"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "model"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "lastModifiedBy"),
		AppFieldActions.getRefId(XyiconFeature.Xyicon, "lastModifiedAt"),
	],
};

const layerFeaturesToSyncWithFilters: {
	feature: XyiconFeature.Boundary | XyiconFeature.Xyicon;
	name: "boundary" | "xyicon";
}[] = [
	{
		feature: XyiconFeature.Boundary,
		name: "boundary",
	},
	{
		feature: XyiconFeature.Xyicon,
		name: "xyicon",
	},
];

export class View implements IModel {
	private _disposerForLayerListener: IReactionDisposer | null = null;
	private _disposerForFilterListener: IReactionDisposer | null = null;

	public static createNew(feature: XyiconFeature, name: string, appState: AppState) {
		const view = new View(
			{
				name,
				viewID: "",
				ownerUserID: appState.user?.id || "",
				feature: feature,
			},
			appState,
		);

		if (feature === XyiconFeature.SpaceEditor) {
			const defaultCols = defaultColumns[XyiconFeature.Xyicon];

			if (defaultCols) {
				view.addColumns(defaultCols, undefined, XyiconFeature.Xyicon);
			}
			const defaultColsBoundary = defaultColumns[XyiconFeature.Boundary];

			if (defaultColsBoundary) {
				view.addColumns(defaultColsBoundary, undefined, XyiconFeature.Boundary);
			}
		} else {
			const defaultCols = defaultColumns[feature];

			if (defaultCols) {
				view.addColumns(defaultCols, undefined, undefined);
			}
		}

		return view;
	}

	public static createDefault(refIds: string[], feature: XyiconFeature, appState: AppState, sizes: {[key: number]: number} = {}) {
		const data: ViewDto = {
			viewID: "",
			name: View.getDefaultViewName(feature),
			feature: feature,
		};

		const view = new View(data, appState);

		if (feature === XyiconFeature.SpaceEditor) {
			view.addColumns(defaultColumns[XyiconFeature.Xyicon], undefined, XyiconFeature.Xyicon);
			view.addColumns(defaultColumns[XyiconFeature.Boundary], undefined, XyiconFeature.Boundary);
		} else {
			view._columns = refIds.map((refId, index) => ({
				field: `${feature}/${refId}`,
				feature: feature,
				default: true,
				width: sizes[index] ?? 100,
			}));
		}

		return view;
	}

	private static getDefaultColumns(feature: XyiconFeature) {
		const refIds = defaultColumns[feature];

		return refIds.map((refId, index) => ({
			field: refId,
			feature: feature,
			default: true,
			width: 100,
		}));
	}

	private static getDefaultViewName(feature: XyiconFeature) {
		switch (feature) {
			case XyiconFeature.Portfolio:
				return "Portfolios";
			case XyiconFeature.Space:
				return "Spaces";
			case XyiconFeature.SpaceEditor:
				return "Space Editor";
			case XyiconFeature.XyiconCatalog:
				return "Catalogs";
			case XyiconFeature.Xyicon:
				return "Xyicons";
			case XyiconFeature.Boundary:
				return "Boundaries";
			case XyiconFeature.Event:
				return "Events";
		}
		return "All";
	}

	@observable
	private _data: ViewDto;

	// With this we can compare our current data and the one that's already saved on the backend
	@observable
	private _savedData: string = null;

	@observable
	private _columns: IViewColumn[] = [];

	// SpaceEditor has 2 columns so it uses this instead of columns.
	@observable
	private _spaceEditorColumns: SpaceEditorColumns = {
		[XyiconFeature.Xyicon]: [] as IViewColumn[],
		[XyiconFeature.Boundary]: [] as IViewColumn[],
	};

	@observable
	private _sorts: IViewSort[] = [];

	@observable
	private readonly _filters = createFilterState();

	public readonly ownFeature = XyiconFeature.View;

	private _splitterRatios: number[];

	@observable
	private _spaceEditorViewSettings: ISpaceEditorViewSettings = null;

	private readonly _appState: AppState;

	public get appState() {
		return this._appState;
	}

	constructor(data: ViewDto, appState: AppState) {
		makeObservable(this);
		this._appState = appState;
		this.applyData(data);
	}

	public applyData(data: ViewDto) {
		if (this._savedData === JSON.stringify(data)) {
			return;
		}

		try {
			if (data.columns) {
				if (data.feature === XyiconFeature.SpaceEditor) {
					const spaceEditorColumns = JSON.parse(data.columns);

					if (spaceEditorColumns && !Array.isArray(spaceEditorColumns)) {
						this._spaceEditorColumns = spaceEditorColumns;
					}
					//
					if (!(this._spaceEditorColumns[XyiconFeature.Xyicon]?.length > 0)) {
						this._spaceEditorColumns[XyiconFeature.Xyicon] = View.getDefaultColumns(XyiconFeature.Xyicon);
					}
					if (!(this._spaceEditorColumns[XyiconFeature.Boundary]?.length > 0)) {
						this._spaceEditorColumns[XyiconFeature.Boundary] = View.getDefaultColumns(XyiconFeature.Boundary);
					}

					// for savedData to work
					data.columns = JSON.stringify(this._spaceEditorColumns);
				} else {
					this._columns = JSON.parse(data.columns);
				}
			}
		} catch (e) {
			console.warn(e);
		}

		try {
			if (data.sorts) {
				this._sorts = JSON.parse(data.sorts);
			}
		} catch (e) {
			console.warn(e);
		}

		try {
			if (data.filters) {
				const filters: IFilterState = JSON.parse(data.filters);

				if (filters.type && filters.filters) {
					this._filters.type = filters.type;
					this._filters.filters = filters.filters;
				}
			}
		} catch (e) {
			console.warn(e);
		}

		try {
			let additionalDetails: IViewAdditionalDetails = {};

			if (data.additionalDetails) {
				additionalDetails = JSON.parse(data.additionalDetails);

				if (additionalDetails) {
					if (additionalDetails.settings?.splitterRatios?.length >= 2) {
						this.splitterRatios = additionalDetails.settings.splitterRatios;
					}
					if (additionalDetails.spaceEditorViewSettings) {
						this._spaceEditorViewSettings = additionalDetails.spaceEditorViewSettings;

						this.updateSpaceEditorViewSettings();
					}
				}
			}

			if (data.feature === XyiconFeature.SpaceEditor) {
				if (!this._spaceEditorViewSettings) {
					this._spaceEditorViewSettings = {
						layers: this.getDefaultLayerSettings(),
						formattingRules: this.getDefaultFormattingRules(),
						captions: this.getDefaultCaptionConfig(),
					};
				}
				if (!this._spaceEditorViewSettings.layers.hiddenMarkupColors) {
					this._spaceEditorViewSettings.layers.hiddenMarkupColors = [];
				}

				const defaultCaptionSettings = this._appState.actions.getDefaultCaptionSettings();

				for (const key in this._spaceEditorViewSettings.captions) {
					const captionSettings = this._spaceEditorViewSettings.captions[key as keyof ICaptionConfig];

					if (!captionSettings.fontFamily) {
						captionSettings.fontFamily = defaultCaptionSettings.fontFamily;
					}

					if (captionSettings.isBold == null) {
						captionSettings.isBold = defaultCaptionSettings.isBold;
					}

					if (captionSettings.isItalic == null) {
						captionSettings.isItalic = defaultCaptionSettings.isItalic;
					}

					if (captionSettings.isUnderlined == null) {
						captionSettings.isUnderlined = defaultCaptionSettings.isUnderlined;
					}
				}

				additionalDetails.spaceEditorViewSettings = this._spaceEditorViewSettings;
			}

			data.additionalDetails = JSON.stringify(additionalDetails);
		} catch (e) {
			console.warn(e);
		}

		try {
			if (!Array.isArray(data.viewSharingSettings)) {
				data.viewSharingSettings = [];
			}
		} catch (e) {
			console.warn(e);
		}

		data.isFavorite = data.isFavorite ?? false;

		// has to be done after data is set
		this._data = data;
		this._savedData = JSON.stringify(this._data);

		if (this._data.feature === XyiconFeature.SpaceEditor) {
			// 2-way data binding between layers and "type" in simple filters
			this.connectLayersToFilters();
			this.connectFiltersToLayers();

			this.onSpaceEditorTypeFiltersUpdated(); // for outdated data, we use the "filters" as base
		}
	}

	private stringifyTypeFiltersForLayerSync = (): string => {
		return JSON.stringify(
			this._filters.filters.simple
				.map((f) => f.value as IFilter)
				.filter((f) => f.field === `${XyiconFeature.Boundary}/type` || f.field === `${XyiconFeature.Xyicon}/type`),
		);
	};

	private stringifyLayersForFilterSync = (): string => {
		const layerSections: {
			feature: XyiconFeature.Boundary | XyiconFeature.Xyicon;
			layerColumn: ILayerColumn;
		}[] = layerFeaturesToSyncWithFilters.map((lf) => ({
			feature: lf.feature,
			layerColumn: this._spaceEditorViewSettings.layers[lf.name].included,
		}));

		return JSON.stringify(layerSections);
	};

	private onSpaceEditorTypeFiltersUpdated = () => {
		// Update layers
		const typeFilters: IFilter[] = JSON.parse(this.stringifyTypeFiltersForLayerSync());

		const {layers} = this._spaceEditorViewSettings;

		runInAction(() => {
			for (const layerFeature of layerFeaturesToSyncWithFilters) {
				const typeFilter = typeFilters.find((t) => t.field === `${layerFeature.feature}/type`);

				const newIncludedObject: ILayerColumn = {};

				for (const key in layers[layerFeature.name].included) {
					newIncludedObject[key] = {
						...layers[layerFeature.name].included[key],
						isHidden: !!typeFilter && !(typeFilter.param as string[]).includes(key),
					};
				}

				if (!ObjectUtils.compare(layers[layerFeature.name].included, newIncludedObject)) {
					layers[layerFeature.name].included = newIncludedObject;
				}
			}
		});
	};

	private onLayersUpdated = () => {
		// Update filters
		const layers: {
			feature: XyiconFeature.Boundary | XyiconFeature.Xyicon;
			layerColumn: ILayerColumn;
		}[] = JSON.parse(this.stringifyLayersForFilterSync());

		runInAction(() => {
			const featureTypes = layerFeaturesToSyncWithFilters.map((l) => `${l.feature}/type`);
			const newSimpleFilters: IFilterRow[] = this._filters.filters.simple.filter((f) => !featureTypes.includes((f.value as IFilter).field));

			for (const layer of layers) {
				const filterRow: IFilterRow = {
					type: "filter",
					value: {
						field: `${layer.feature}/type`,
						operator: FilterOperator.IS_ANY_OF,
						param: [],
					},
				};

				const newParam: string[] = [];

				let isThereAtLeastOneHidden = false;

				for (const key in layer.layerColumn) {
					if (!layer.layerColumn[key].isHidden) {
						newParam.push(key);
					} else {
						isThereAtLeastOneHidden = true;
					}
				}

				if (!isThereAtLeastOneHidden) {
					newParam.length = 0;
				}

				filterRow.value.param = newParam;

				if (newParam.length > 0 || isThereAtLeastOneHidden) {
					newSimpleFilters.push(filterRow);
				}
			}

			if (!ObjectUtils.compare(this._filters.filters.simple, newSimpleFilters)) {
				this._filters.filters.simple = newSimpleFilters;
			}
		});
	};

	private connectLayersToFilters() {
		this._disposerForLayerListener?.();
		this._disposerForLayerListener = reaction(this.stringifyTypeFiltersForLayerSync, this.onSpaceEditorTypeFiltersUpdated);
	}

	private connectFiltersToLayers() {
		this._disposerForFilterListener?.();
		this._disposerForFilterListener = reaction(this.stringifyLayersForFilterSync, this.onLayersUpdated);
	}

	private updateData<T>(oldSet: {[key: string]: T}, newSet: {[key: string]: T}) {
		// Add ones that didn't exist at save-time
		for (const key in newSet) {
			if (typeof oldSet[key] === "undefined") {
				oldSet[key] = newSet[key];
			}
		}

		// Remove ones that don't exist now
		for (const key in oldSet) {
			if (typeof newSet[key] === "undefined") {
				delete oldSet[key];
			}
		}
	}

	private updateLayerColumnWithNewTypes(oldLayerColumn: ILayerColumn, currentDefaultLayerColumn: ILayerColumn) {
		this.updateData(oldLayerColumn, currentDefaultLayerColumn);
	}

	private updateLayerSettingsWithNewTypes(oldLayerSettings: ILayerSettings, currentDefaultLayerSettings: ILayerSettings) {
		this.updateLayerColumnWithNewTypes(oldLayerSettings.included, currentDefaultLayerSettings.included);
	}

	private updateFormattingRulesWithNewTypes(oldFormattingRuleSet: IFormattingRuleSet, currentDefaultFormattingRuleSet: IFormattingRuleSet) {
		for (const colorRuleCategory of colorRuleCategories) {
			const oldFormattingRules = oldFormattingRuleSet[colorRuleCategory];
			const currentFormattingRules = currentDefaultFormattingRuleSet[colorRuleCategory];

			this.updateData(oldFormattingRules, currentFormattingRules);
		}
	}

	private updateCaptionSettings(captionConfig: ICaptionConfig, currentBoundaryRefIds: Set<string>, currentXyiconRefIds: Set<string>) {
		const currentRefIds = {
			boundary: currentBoundaryRefIds,
			xyicon: currentXyiconRefIds,
		};

		for (const configKey in captionConfig) {
			const typedConfigKey = configKey as keyof ICaptionConfig;

			// Remove ones that don't exist anymore
			captionConfig[typedConfigKey].checkList = captionConfig[typedConfigKey].checkList.filter((refId) => currentRefIds[typedConfigKey].has(refId));

			if (!captionConfig[typedConfigKey].individualCaptionStyles) {
				captionConfig[typedConfigKey].individualCaptionStyles = {};
			} else {
				for (const stylesKey in captionConfig[typedConfigKey].individualCaptionStyles) {
					if (!currentRefIds[typedConfigKey].has(stylesKey)) {
						delete captionConfig[typedConfigKey].individualCaptionStyles[stylesKey];
					}
				}
			}

			// We renamed "background" with "backgroundColor" at this point
			const oldBackgroundColor = (this._spaceEditorViewSettings.captions[typedConfigKey] as any).background;

			if (typeof oldBackgroundColor === "object" && !this._spaceEditorViewSettings.captions[typedConfigKey].backgroundColor) {
				this._spaceEditorViewSettings.captions[typedConfigKey].backgroundColor = oldBackgroundColor;
			}
		}
	}

	private updateSpaceVersionSettings() {
		const background = this._spaceEditorViewSettings.layers.background as any;

		if (background?.spaceVersionNames) {
			delete background.spaceVersionNames;
		}
		if (background?.spaceVersionName) {
			delete background.spaceVersionName;
		}
	}

	/**
	 * To be compatible with new types, fields, views, that didn't exist at save-time
	 */
	public updateSpaceEditorViewSettings() {
		const actions = this._appState.actions;

		this.updateLayerSettingsWithNewTypes(this._spaceEditorViewSettings.layers.boundary, actions.getDefaultLayerSettings(XyiconFeature.Boundary));
		this.updateLayerSettingsWithNewTypes(this._spaceEditorViewSettings.layers.xyicon, actions.getDefaultLayerSettings(XyiconFeature.Xyicon));
		this.updateLayerSettingsWithNewTypes(this._spaceEditorViewSettings.layers.markup, actions.getDefaultLayerSettings(XyiconFeature.Markup));

		this.updateFormattingRulesWithNewTypes(
			this._spaceEditorViewSettings.formattingRules.boundary,
			actions.getDefaultFormattingRuleSet(XyiconFeature.Boundary),
		);
		this.updateFormattingRulesWithNewTypes(
			this._spaceEditorViewSettings.formattingRules.xyicon,
			actions.getDefaultFormattingRuleSet(XyiconFeature.Xyicon),
		);

		const boundaryFields = actions.getFieldsByFeature(XyiconFeature.Boundary, true);
		const xyiconFields = actions.getFieldsByFeature(XyiconFeature.Xyicon, true);

		this.updateCaptionSettings(
			this._spaceEditorViewSettings.captions,
			new Set(boundaryFields.map((f) => f.refId)),
			new Set(xyiconFields.map((f) => f.refId)),
		);

		this.updateSpaceVersionSettings();
	}

	@computed
	public get additionalDetails(): IViewAdditionalDetails {
		return {
			settings: {
				splitterRatios: this.splitterRatios || [],
			},
			spaceEditorViewSettings: this._spaceEditorViewSettings,
		};
	}

	// These are not actively in use now anymore, we're saving SidePanel width in localStorage
	public set splitterRatios(value: number[]) {
		this._splitterRatios = value;
	}

	public get splitterRatios() {
		return this._splitterRatios;
	}

	public setSpaceEditorViewSettings(settings: ISpaceEditorViewSettings) {
		this._spaceEditorViewSettings = settings;
	}

	@computed
	public get spaceEditorViewSettings() {
		return this._spaceEditorViewSettings;
	}

	private clone() {
		const data = this.getData(); //ObjectUtils.deepClone(this._data);

		data.viewID = "";
		// As per #5104, if you clone a global view, the clone shouldn't be global by default

		return new View({...data, isGlobal: false, ownerUserID: this._appState.user.id, viewSharingSettings: []}, this._appState);
	}

	public cloneForEdit() {
		const data = this.getData();
		return new View(data, this._appState);
	}

	public getDefaultLayerSettings() {
		const {actions} = this._appState;

		return {
			background: {
				isHidden: false,
			},
			boundary: {
				...actions.getDefaultLayerSettings(XyiconFeature.Boundary),
			},
			xyicon: {
				...actions.getDefaultLayerSettings(XyiconFeature.Xyicon),
			},
			markup: actions.getDefaultLayerSettings(XyiconFeature.Markup),
			hiddenMarkupColors: [] as string[],
		};
	}

	public getDefaultFormattingRules() {
		const {actions} = this._appState;

		return {
			boundary: actions.getDefaultFormattingRuleSet(XyiconFeature.Boundary),
			xyicon: actions.getDefaultFormattingRuleSet(XyiconFeature.Xyicon),
		};
	}

	public getDefaultCaptionConfig() {
		const {actions} = this._appState;

		return {
			boundary: actions.getDefaultCaptionSettings(),
			xyicon: actions.getDefaultCaptionSettings(),
		};
	}

	public getData(): ViewDto {
		const view = this;
		const filters = ObjectUtils.deepClone(view.filters);

		const serializedFilters = JSON.stringify(filters);
		const additionalDetails = view.additionalDetails;

		const columns = view.itemFeature === XyiconFeature.SpaceEditor ? view._spaceEditorColumns : view._columns;

		return {
			...this._data,
			viewID: view.id,
			name: view.name,
			isFavorite: view.isFavorite,
			showOnNavigation: view.showOnNavigation,
			columns: JSON.stringify(columns),
			sorts: JSON.stringify(view.sorts),
			filters: serializedFilters,
			additionalDetails: JSON.stringify(additionalDetails),
			ownerUserID: view.ownedBy,
			feature: view.itemFeature,
			viewSharingSettings: view.viewSharingSettings,
			isGlobal: view.isGlobal,
		};
	}

	public getSavedData(): ViewDto {
		return JSON.parse(this._savedData);
	}

	@computed
	public get id() {
		return this._data.viewID;
	}

	public set name(value: string) {
		this._data.name = value;
	}

	@computed
	public get name() {
		return this._data.name ?? "";
	}

	@computed
	public get lastModifiedAt() {
		return this._data.lastModifiedAt;
	}

	@computed
	public get isSystem() {
		// TODO: Replace this with this._data.viewType === ViewType.DefaultGlobal, when the BE is ready for it
		return (this._data as any).isSystem;
	}

	public setFavorite(value: boolean, preferredViewFolderId?: string) {
		const user = this._appState.user;
		const {favoriteViews} = user;

		if (value) {
			if (!doesItemExistInViewFolderStructure(favoriteViews, this.id)) {
				let finalFolderToSaveViewFoldersTo: ViewFolderStructure = favoriteViews;

				if (preferredViewFolderId) {
					const preferredViewFolder = getItemByIdInViewFolderStructure(favoriteViews, preferredViewFolderId);

					if (preferredViewFolder?.category === ViewPreferenceCategory.Folder) {
						finalFolderToSaveViewFoldersTo = preferredViewFolder.children;
					}
				}

				finalFolderToSaveViewFoldersTo.push({
					id: this.id,
					category: ViewPreferenceCategory.View,
				});
			}
		} else {
			removeElementFromViewFolderStructureById(favoriteViews, this.id);
		}

		return this._appState.user?.setFavoriteViews(favoriteViews);
	}

	@computed
	public get isFavorite(): boolean {
		return doesItemExistInViewFolderStructure(this._appState.user?.favoriteViews || [], this.id);
	}

	@computed
	public get isGlobal(): boolean {
		return this._data.isGlobal ?? false;
	}

	public set isGlobal(value: boolean) {
		this._data.isGlobal = value;
	}

	public duplicate = async (onBeforeSendRequest?: () => Promise<void>, onAfterResponseReceived?: (() => Promise<void>) | (() => void)) => {
		const {appState} = this;
		const view = this;

		if (view) {
			const duplicate = view.clone();

			do {
				duplicate.name += " (Duplicate)";
			} while (!appState.actions.isNameValidForView(duplicate.name, duplicate.itemFeature, duplicate.id));

			if (onBeforeSendRequest) {
				await onBeforeSendRequest();
			}

			const {result: viewData} = await appState.app.transport.services.view.create(duplicate.getData(), view.itemFeature);

			if (onAfterResponseReceived) {
				await onAfterResponseReceived();
			}

			if (viewData?.viewID) {
				const newView = appState.actions.getViewById(viewData.viewID);

				if (newView) {
					appState.actions.selectViewById(newView.id);

					return newView;
				}
			}
		}
	};

	public set showOnNavigation(value: boolean) {
		this._data.showOnNavigation = value;
	}

	@computed
	public get showOnNavigation() {
		return this._data.showOnNavigation;
	}

	public set ownedBy(value: string) {
		this._data.ownerUserID = value;
	}

	@computed
	public get ownedBy() {
		return this._data.ownerUserID;
	}

	public applyColumnsData(columns: string) {
		try {
			this._columns = JSON.parse(columns);
		} catch (e) {
			console.warn(e);
		}
	}

	public addColumns(fieldRefIds: IFieldPointer[], index: number, feature: XyiconFeature) {
		const testElement = document.createElement("span");

		document.body.appendChild(testElement);

		testElement.style.fontSize = "14px";
		testElement.style.fontWeight = "400";
		testElement.style.font = "Roboto, sans-serif";
		testElement.style.visibility = "hidden";
		testElement.style.whiteSpace = "no-wrap";
		testElement.style.position = "absolute";

		const newColumns = fieldRefIds.map((refId) => {
			const fieldName = this._appState.actions.getFieldByRefId(refId).name;

			testElement.innerHTML = fieldName;

			// clientWidth + 10 label padding + 20 + 5 div padding + 15 unknown
			return {
				field: refId,
				title: "",
				width: Math.min(testElement.clientWidth + 51, 500),
			} as IViewColumn;
		});

		document.body.removeChild(testElement);

		const columns =
			this.itemFeature === XyiconFeature.SpaceEditor
				? this._spaceEditorColumns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary]
				: this._columns;

		if (MathUtils.isWholeNum(index)) {
			columns.splice(index, 0, ...newColumns);
		} else {
			columns.push(...newColumns);
		}
	}

	public removeColumnsByRefId(fieldRefIds: IFieldPointer[], feature: XyiconFeature) {
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			this._spaceEditorColumns[feature as XyiconFeature.Xyicon | XyiconFeature.Boundary] = this._spaceEditorColumns[
				feature as XyiconFeature.Xyicon | XyiconFeature.Boundary
			].filter((column: IViewColumn) => !fieldRefIds.includes(column.field));
		} else {
			this._columns = this._columns.filter((column) => !fieldRefIds.includes(column.field));
		}
	}

	public resizeColumn(index: number, width: number, feature: XyiconFeature) {
		const columns = this.getValidViewColumns(feature);

		columns[index].width = width;
	}

	public reorderColumn(fromIndex: number | number[], toIndex: number, feature: XyiconFeature) {
		if (fromIndex === toIndex || (Array.isArray(fromIndex) && fromIndex[0] === toIndex)) {
			// Nothing to do, we should place the items in the same position as they were dragged from
			return;
		}

		const columns = this.getValidViewColumns(feature);
		let reordered: IViewColumn[] = columns;

		if (Array.isArray(fromIndex)) {
			const fromIndexSorted = fromIndex.toSorted((a, b) => a - b);
			const itemsToMove: IViewColumn[] = [];

			for (const fIndex of fromIndexSorted) {
				itemsToMove.push(reordered[fIndex]);
				reordered[fIndex] = null;
			}

			reordered = reordered.filter((c) => c).toSpliced(toIndex, 0, ...itemsToMove);
		} else {
			reordered = ArrayUtils.move(reordered, fromIndex, toIndex);
		}

		this.setColumns(reordered, feature);
	}

	public setColumns(columns: IViewColumn[], feature: XyiconFeature) {
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			if (feature !== XyiconFeature.Xyicon && feature !== XyiconFeature.Boundary) {
				throw new Error("Invalid feature");
			}
			this._spaceEditorColumns[feature] = columns;
		} else {
			this._columns = columns;
		}
	}

	public setSerializedColumns(serializedColumns: string) {
		const columns = JSON.parse(serializedColumns);

		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			this._spaceEditorColumns = columns;
		} else {
			this._columns = columns;
		}
	}

	public getSerializedColumns(): string {
		return JSON.stringify(this.getColumnsToCompare());
	}

	public setSorts(sorts: IViewSort[]) {
		this._sorts = sorts;
	}

	public addSort(sort: IViewSort) {
		if (sort.column) {
			const changingSortIndex = this._sorts.findIndex((s) => s.column === sort.column);

			if (sort.direction !== null) {
				if (changingSortIndex > -1) {
					this._sorts[changingSortIndex].direction = sort.direction;
				} else {
					this._sorts.push(sort);
				}
			} else {
				this._sorts = ArrayUtils.removeAtIndex(this._sorts, changingSortIndex); // safe if sort is not element of this._sorts
			}
		}
	}

	@computed
	public get sorts(): IViewSort[] {
		return this._sorts;
	}

	public getColumnsToCompare() {
		return this.itemFeature === XyiconFeature.SpaceEditor ? this._spaceEditorColumns : this._columns;
	}

	public getSavedColumnsWithoutWidth() {
		const columns = this.getSavedColumns();

		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			const spaceEditorColumns = columns as SpaceEditorColumns;

			return {
				[XyiconFeature.Xyicon]: spaceEditorColumns[XyiconFeature.Xyicon].map(this.resetColumnWidthForCompare),
				[XyiconFeature.Boundary]: spaceEditorColumns[XyiconFeature.Boundary].map(this.resetColumnWidthForCompare),
			};
		} else {
			return (columns as IViewColumn[]).map(this.resetColumnWidthForCompare);
		}
	}

	public getColumnsToCompareWithoutWidth() {
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			return {
				[XyiconFeature.Xyicon]: this._spaceEditorColumns[XyiconFeature.Xyicon].map(this.resetColumnWidthForCompare),
				[XyiconFeature.Boundary]: this._spaceEditorColumns[XyiconFeature.Boundary].map(this.resetColumnWidthForCompare),
			};
		} else {
			return this._columns.map(this.resetColumnWidthForCompare);
		}
	}

	public getColumnsToCompareWithWidth() {
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			return {
				[XyiconFeature.Xyicon]: this._spaceEditorColumns[XyiconFeature.Xyicon].map(this.resetColumnWidthForCompare),
				[XyiconFeature.Boundary]: this._spaceEditorColumns[XyiconFeature.Boundary].map(this.resetColumnWidthForCompare),
			};
		} else {
			return this._columns.slice();
		}
	}

	// Returns all columns, possible invalid ones (where the field has been deleted since).
	// Use getValidViewColumns() to get the valid columns.
	public getAllColumns(feature: XyiconFeature): IViewColumn[] {
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			let result = this._spaceEditorColumns[(feature as XyiconFeature.Boundary) || XyiconFeature.Xyicon];

			if (!result) {
				result = this._spaceEditorColumns[XyiconFeature.Xyicon];
				throw new Error("Wrong feature supplied!");
			}
			return result;
		} else {
			return this._columns;
		}
	}

	// Returns the visible and valid columns of a view:
	// - have existing fields (field might have been deleted after it has been added to the view)
	// - fields that belong to this feature or inherited and displayOnLinks is still true (could have been turned off
	// since the field had been added to the view)
	public getValidViewColumns(feature: XyiconFeature) {
		const actions = this._appState.actions;

		return this.getAllColumns(feature).filter((column) => {
			const field = actions.getFieldByRefId(column.field);
			// Note: using this.feature (View's own feature) instead of the param (which is only applicable for spaceeditor views)!
			const featureToCheckValidity = this.itemFeature === XyiconFeature.SpaceEditor ? feature : this.itemFeature;

			return (
				actions.isFieldValidForFeature(field, featureToCheckValidity) &&
				!(column.field.includes("versionName") || column.field.includes("issuanceDate"))
			);
		});
	}

	@computed
	public get itemFeature() {
		return this._data.feature;
	}

	@computed
	public get viewSharingSettings() {
		return this._data.viewSharingSettings;
	}

	public resetViewSharingSettings() {
		this._data.viewSharingSettings = [];
	}

	private getSavedColumns(): IViewColumn[] | SpaceEditorColumns {
		const savedData = this.getSavedData();

		if (savedData.columns) {
			const result = JSON.parse(savedData.columns);

			if (result) {
				return result;
			}
		}
		if (this.itemFeature === XyiconFeature.SpaceEditor) {
			return {
				[XyiconFeature.Xyicon]: [],
				[XyiconFeature.Boundary]: [],
			};
		} else {
			return [];
		}
	}

	public getSavedFilters(): IFilterState {
		const savedData = this.getSavedData();

		return JSON.parse(savedData.filters || null) || createFilterState();
	}

	private getSavedColumnSorts() {
		const savedData = this.getSavedData();

		return JSON.parse(savedData.sorts || "[]");
	}

	public getSavedSpaceEditorViewSettings(): ISpaceEditorViewSettings {
		const savedData = this.getSavedData();

		try {
			const additionalDetails = JSON.parse(savedData.additionalDetails || null) as IViewAdditionalDetails;

			return additionalDetails?.spaceEditorViewSettings ?? null;
		} catch (e) {
			console.error(e);
		}
		return null;
	}

	public getSavedDataForType(viewChangeType: ViewChangeType, manageColumns = false) {
		switch (viewChangeType) {
			case "captions":
				return this.getSavedSpaceEditorViewSettings()?.captions || this.getDefaultCaptionConfig();
			case "columns":
				return manageColumns ? this.getSavedColumnsWithoutWidth() : this.getSavedColumns();
			case "column sorts":
				return this.getSavedColumnSorts();
			case "conditional formatting":
				return this.getSavedSpaceEditorViewSettings()?.formattingRules || this.getDefaultFormattingRules();
			case "filters":
				return this.getSavedFilters();
			case "layers":
				return this.getSavedSpaceEditorViewSettings()?.layers || this.getDefaultLayerSettings();
		}
	}

	public getNewDataForType(viewChangeType: ViewChangeType, manageColumns = false) {
		switch (viewChangeType) {
			case "captions":
				return this.spaceEditorViewSettings?.captions;
			case "columns":
				return manageColumns ? this.getColumnsToCompareWithoutWidth() : this.getColumnsToCompare();
			case "column sorts":
				return this._sorts || null;
			case "conditional formatting":
				return this.spaceEditorViewSettings?.formattingRules;
			case "filters":
				return this.filters;
			case "layers":
				return this.spaceEditorViewSettings?.layers;
		}
	}

	private resetColumnWidthForCompare(column: IViewColumn): IViewColumn {
		return {
			...column,
			width: null,
		};
	}

	public hasUnsavedChanges(viewChangeType: ViewChangeType, manageColumns = false) {
		return (
			JSON.stringify(this.getSavedDataForType(viewChangeType, manageColumns)) !==
			JSON.stringify(this.getNewDataForType(viewChangeType, manageColumns))
		);
	}

	public getPermission(userId?: string): Permission {
		userId = userId || this._appState.user?.id;

		if (!userId) {
			return Permission.None;
		}

		if (userId === this.ownedBy) {
			return Permission.Delete;
		}

		let currentPermission = Permission.None;

		const user = this._appState.actions.findUser(userId);
		const userGroups = user?.userGroupIds || [];
		const settings = this.viewSharingSettings.filter((s) => s.userID === userId || (s.userGroupID && userGroups.includes(s.userGroupID)));

		for (const setting of settings) {
			if (setting) {
				const permission = setting.canEditSharedView ? Permission.Update : Permission.View;

				if (currentPermission < permission) {
					currentPermission = permission;
				}
			}
		}

		return currentPermission;
	}

	public resetFiltersToSaved() {
		const savedFilters = this.getSavedFilters();

		this._filters.type = savedFilters.type;
		this._filters.filters.simple = savedFilters.filters.simple;
		this._filters.filters.advanced = savedFilters.filters.advanced;
	}

	public setFilters(value: IFilterState) {
		this._filters.type = value.type;
		this._filters.filters = value.filters;
	}

	@computed
	public get filters() {
		return this._filters;
	}

	@computed
	public get data() {
		return this._data;
	}
}

import styled from "styled-components";
import {VerticalFlexStyle} from "../styles/styles";
import {colorPalette} from "../styles/colorPalette";
import {SelectInputStyled} from "../input/select/SelectInputV5";

export const DetailsTabV5HeaderStyles = styled.div`
	${VerticalFlexStyle};
	gap: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	border-bottom: 2px solid ${colorPalette.gray.c200Light};

	h2 {
		flex: 1;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		text-transform: uppercase;
	}

	.Initials {
		position: relative;
		top: 0;
		left: 0;
		width: 48px;
		height: 48px;
		font-size: 24px;

		.boundaryThumbnail {
			height: 100%;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.Field {
		.element {
			margin-right: 0;
			width: 178px;
		}

		&.space {
			.label {
				width: 140px;
			}
			.element {
				width: 158px;
			}
		}

		.SingleLineLabel {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			white-space: unset;
			line-height: 16px;
		}

		${SelectInputStyled} {
			svg {
				flex-shrink: 0;
			}
		}
	}

	.thumbnail:has(.SpaceThumbnail) {
		width: 80px;
		height: 80px;
		border: 1px solid ${colorPalette.gray.c300};
	}

	.SpaceThumbnail {
		width: 80px;
		height: 80px;
	}
`;

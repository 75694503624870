import type {InstancedBufferGeometry, InstancedBufferAttribute, Material, Texture} from "three";
import {Mesh, Object3D, Color, MeshBasicMaterial, Vector2} from "three";
import type {PlaneGeometry} from "three/src/geometries/PlaneGeometry.js";
import type {XyiconManager} from "../managers/spaceitems/XyiconManager";
import {Constants} from "../Constants";
import type {TextureManager, IMiscTextureObject} from "../managers/TextureManager";
import type {SpaceViewRenderer} from "../renderers/SpaceViewRenderer";
import type {BasicMaterial} from "../materials/BasicMaterial";
import type {SpaceItemType} from "../managers/spaceitems/ItemManager";
import type {ColorRuleCategory} from "../../ui/viewbar/ColorRules";
import {IndicatorMaterial} from "../materials/IndicatorMaterial";
import {XyiconCaption} from "../managers/MSDF/XyiconCaption";
import {
	getEmbeddedCounterPosition,
	getEmbeddedCounterScale,
	getLinkIconPosition,
	getXyiconHighlightScale,
	getXyiconIndicatorPosition,
	getXyiconIndicatorScale,
} from "../renderers/SpaceViewRendererUtils";
import type {IBox3} from "../../../../../../utils/THREEUtils";
import {THREEUtils} from "../../../../../../utils/THREEUtils";
import {MathUtils} from "../../../../../../utils/math/MathUtils";
import {Convergence, Easing} from "../../../../../../utils/animation/Convergence";
import type {Dimensions, PointDouble} from "../../../../../../generated/api/base";
import {CatalogIconType, XyiconFeature} from "../../../../../../generated/api/base";
import type {Catalog} from "../../../../../../data/models/Catalog";
import type {IFieldAdapter} from "../../../../../../data/models/field/Field";
import type {Xyicon, IXyiconMinimumData} from "../../../../../../data/models/Xyicon";
import {getDefaultInsertionInfo} from "../../../../catalog/create/CatalogTypes";
import type {BoundarySpaceMap3D} from "./BoundarySpaceMap3D";
import {SpaceItem} from "./SpaceItem";
import type {SpaceItemOpacityValue} from "./SpaceItem";
import {LeaderLine} from "./LeaderLine";

/** Represents the xyicon 3D object and its accessories: caption, rotating handler, counter, flag, ... */
export class Xyicon3D extends SpaceItem {
	private _xyiconMesh: Object3D;
	private _meshSize: Convergence;
	private _caption: XyiconCaption;
	private _captionLeaderLine: LeaderLine;
	private _counterMesh: Mesh;
	private _counterMaterial: MeshBasicMaterial;
	private _indicatorMesh: Mesh;
	private _indicatorMaterial: IndicatorMaterial;
	private _highlightMesh: Mesh;
	private _highlightMaterial: IndicatorMaterial;
	private _linkIconPos: Vector2 = new Vector2();
	private _flagMesh: Mesh;
	private _flagMaterial: BasicMaterial;
	private _xyiconManager: XyiconManager;
	private _planeGeometry: PlaneGeometry;
	private _textureManager: TextureManager;

	private _miscTextures: IMiscTextureObject;

	private _instancedMeshId: number;
	private _instanceId: number;
	public linkInstanceId: number = -1;

	protected _color: number;
	protected _modelData: Xyicon;
	protected _spaceItemType: SpaceItemType = "xyicon";
	private _isAnotherXyiconBeingHovered: boolean = false; // trying to embed something into this xyicon
	private _typeName: string;

	private _geometryScale: Dimensions;
	private _counter: number = 0;

	private readonly _meshSizeMultiplicator: number = 1;

	/**
	 * _group: Group of xyicon. Includes the caption, and the captionleaderline.
	 * When the user is dragging a xyicon, you should change the position of this group, because the caption's position should change accordingly.
	 * However, when a user rotates a xyicon, you shouldn't rotate the caption, only the xyicon mesh, and the rotation handler
	 */

	constructor(
		spaceViewRenderer: SpaceViewRenderer,
		instancedMeshId: number,
		instanceId: number,
		xyiconData: IXyiconMinimumData,
		color: number = 0xffffff,
		defaultOpacity: number = 1,
	) {
		super(spaceViewRenderer);

		this._instancedMeshId = instancedMeshId;
		this._instanceId = instanceId;
		this._xyiconManager = spaceViewRenderer.xyiconManager;
		this._planeGeometry = spaceViewRenderer.planeGeometry;
		this._textureManager = this._xyiconManager.textureManager;
		this._miscTextures = this._spaceViewRenderer.textureManager.miscTextures;
		this._color = color;

		this.instancedMesh.geometry.computeBoundingBox();
		const bbox = this.instancedMesh.geometry.boundingBox;

		this._geometryScale = THREEUtils.getSizeOfBoundingBox3(bbox);

		this._meshSize = new Convergence({
			start: this._meshSizeMultiplicator,
			end: this._meshSizeMultiplicator,
			easing: Easing.EASE_OUT,
			animationDuration: Constants.DURATIONS.DEFAULT_ANIMATION,
			triggerRender: true,
			timeStampManager: this._spaceViewRenderer,
		});

		this._meshSize.signals.onUpdate.add(this.onSizeChange);

		const {actions} = this._spaceViewRenderer.transport.appState;
		const catalog = actions.getFeatureItemById(xyiconData.catalogId, XyiconFeature.XyiconCatalog) as Catalog;

		this._typeName = actions.getTypeName(catalog.xyiconTypeId);

		this._group.position.setX(xyiconData.iconX);
		this._group.position.setY(xyiconData.iconY);
		this._group.position.setZ(xyiconData.iconZ ?? 0);
		THREEUtils.updateMatrices(this._group);

		this._xyiconMesh = new Object3D();
		this._xyiconMesh.name = "xyicon mesh";
		this._group.name = "xyicon group";
		this._xyiconMesh.userData.spaceItem = this._group.userData.spaceItem = this;

		this.addModelData(xyiconData as Xyicon);
		const insertionInfo = this._modelData.catalog?.insertionInfo ?? getDefaultInsertionInfo();

		this._xyiconMesh.position.set(insertionInfo.offsetX, insertionInfo.offsetY, insertionInfo.offsetZ);
		this._group.rotation.z = xyiconData.orientation;

		THREEUtils.add(this._group, this._xyiconMesh);
		this.updateFlip();

		this._lastSavedOrientation = this._group.rotation.z;

		//THREEUtils.add(this._xyiconManager.container, this._group);

		// this.updateFlag(xyicon.openEventCount, xyicon.hasPriorityEvents);

		this.updateCounter();

		this.onLayerSettingsModified();
		this.onFormattingRulesModified();

		if (xyiconData.parentXyicon) {
			this.setVisibility(false);
		}

		this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
		this._defaultOpacity = defaultOpacity;
		this.setOpacity(1);

		this._spaceViewRenderer.needsRender = true;
	}

	protected setFormattingColor(colorRuleCategory: ColorRuleCategory, colorHex: string) {
		const formatColor = colorHex != null ? parseInt(`0x${colorHex}`) : null;
		const isColorFormatValid = formatColor != null && this.itemManager.areFormattingRulesEnabled;

		if (colorRuleCategory === "highlight") {
			if (isColorFormatValid) {
				const colorObject = {
					hex: colorHex,
					transparency: IndicatorMaterial.TRANSPARENCY.highlight,
				};

				if (!this._highlightMaterial) {
					this._highlightMaterial = new IndicatorMaterial(colorObject);
					this._highlightMesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._highlightMaterial);
					this.updateHighlightMesh();
				} else {
					this._highlightMaterial.setColor(colorObject);
				}

				if (!this._highlightMesh.parent) {
					THREEUtils.add(this._group, this._highlightMesh);
				}
				this.addGroupToSceneIfNeeded();
			} else {
				this._highlightMesh?.parent?.remove(this._highlightMesh);
			}
		} // indicator
		else {
			if (isColorFormatValid) {
				const colorObject = {
					hex: colorHex,
					transparency: IndicatorMaterial.TRANSPARENCY.indicator,
				};

				if (!this._indicatorMaterial) {
					this._indicatorMaterial = new IndicatorMaterial(colorObject);
					this._indicatorMesh = new Mesh(this._spaceViewRenderer.planeGeometry, this._indicatorMaterial);
					this.updateIndicatorMeshPosition();
					const scale = getXyiconIndicatorScale(this._xyiconMesh.scale.x, this._spaceViewRenderer.correctionMultiplier.current);

					this._indicatorMesh.scale.set(scale, scale, scale);
				} else {
					this._indicatorMaterial.setColor(colorObject);
				}

				if (!this._indicatorMesh.parent) {
					THREEUtils.add(this._group, this._indicatorMesh);
				}
				this.addGroupToSceneIfNeeded();
			} else {
				this._indicatorMesh?.parent?.remove(this._indicatorMesh);
			}
		}

		// Workaround for z-issues
		if (this._highlightMesh?.parent) {
			THREEUtils.renderToTop(this.instancedMesh);
		} else if (this._counterMesh?.parent || this._indicatorMesh?.parent) {
			THREEUtils.renderToTop(this._group);
		}

		this._spaceViewRenderer.needsRender = true;
	}

	public updateFlag(openEventCount: number, hasPriorityEvents: boolean) {
		if (openEventCount > 0) {
			// const texture = hasPriorityEvents ? this._miscTextures.redFlag : this._miscTextures.orangeFlag;

			// if (this._flagMesh) {
			// 	this._flagMesh.visible = true;
			// 	this._flagMaterial.map = texture;
			// } else {
			// 	this._flagMaterial = new BasicMaterial({map: texture, transparent: true});
			// 	this._flagMesh = new Mesh(this._planeGeometry, this._flagMaterial);
			// 	THREEUtils.add(this._intersectables, this._flagMesh);
			// }

			this.updateFlagMesh();
		} else {
			if (this._flagMesh) {
				this._flagMesh.visible = false;
			}
		}
	}

	public updateCounter() {
		const counter = this._modelData.embeddedXyicons.length;

		if (counter !== this._counter) {
			this._counter = counter;
			if (counter > 0) {
				const texture = this._textureManager.getCounterTexture(counter);

				if (this._counterMesh) {
					this._counterMesh.visible = true;
					(this._counterMaterial as MeshBasicMaterial).map = texture;
				} else {
					this._counterMaterial = new MeshBasicMaterial({map: texture, transparent: true, depthTest: true, alphaTest: 0.1});
					this._counterMesh = new Mesh(this._planeGeometry, this._counterMaterial);
					this._counterMesh.name = "embeddedIcon";
				}

				this._counterMaterial.userData.imageSize = {
					x: texture["resolution" as keyof Texture].x,
					y: texture["resolution" as keyof Texture].y,
				};

				if (!this._counterMesh.parent) {
					THREEUtils.add(this._group, this._counterMesh);
				}

				this.addGroupToSceneIfNeeded();

				this.updateCounterMesh();
			} else {
				if (this._counterMesh) {
					this._counterMesh.visible = false;
				}
			}

			this._spaceViewRenderer.needsRender = true;
		}
	}

	private updateFlagMesh() {
		if (this._flagMesh) {
			this.updateFlagMeshSize();
			this.updateFlagMeshPosition();
		}
	}

	private updateFlagMeshSize() {
		// if (this._flagMesh)
		// {
		// 	const flagToXyiconRatio = 32 / 81; // we created the size with the default xyicon size (81) in mind. 32 was the height of the flag
		// 	const imageRatio = this._flagMaterial.map.image.width / this._flagMaterial.map.image.height;
		// 	this._flagMesh.scale.setX(this._xyiconMesh.scale.x * imageRatio * flagToXyiconRatio);
		// 	this._flagMesh.scale.setY(this._xyiconMesh.scale.y * flagToXyiconRatio);
		// }
	}

	private updateFlagMeshPosition() {
		if (this._flagMesh) {
			const xyiconWidth = Math.abs(this._xyiconMesh.scale.x);
			const xyiconHeight = Math.abs(this._xyiconMesh.scale.y);

			const offsetRatio = Constants.SIZE.XYICON / xyiconWidth;

			this._flagMesh.position.setX((xyiconWidth - this._flagMesh.scale.x) / 2 + 10 * offsetRatio);
			this._flagMesh.position.setY((xyiconHeight - this._flagMesh.scale.y) / 2);
		}
	}

	private updateCounterMesh() {
		if (this._counterMesh) {
			this.updateCounterMeshSize();
			this.updateCounterMeshPosition();
			THREEUtils.updateMatrices(this._counterMesh);
		}
	}

	private updateIndicatorMesh() {
		if (this._indicatorMesh) {
			this.updateIndicatorMeshPosition();
			THREEUtils.updateMatrices(this._indicatorMesh);
		}
	}

	private updateHighlightMesh() {
		if (this._highlightMesh) {
			this._highlightMesh.position.setZ(MathUtils.getRandomBetweenMinAndMax(0.1, 1) * this._spaceViewRenderer.correctionMultiplier.current);
			this.updateHighlightMeshScale();
			THREEUtils.updateMatrices(this._highlightMesh);
		}
	}

	private updateCounterMeshSize() {
		const counterToXyiconRatio =
			getEmbeddedCounterScale(this._xyiconMesh.scale.x, this._spaceViewRenderer.correctionMultiplier.current) * Math.abs(this._xyiconMesh.scale.x); // we created the size with the default xyicon size (81) in mind. 32 was the height of the counter
		const {x, y} = this._counterMaterial.userData.imageSize;
		const imageRatio = x / y;

		const flipScale = this.flipScale;

		this._counterMesh.scale.setX(imageRatio * counterToXyiconRatio * flipScale.x);
		this._counterMesh.scale.setY(counterToXyiconRatio * flipScale.y);
	}

	private updateCounterMeshPosition() {
		const pos = getEmbeddedCounterPosition(this.scale, this._spaceViewRenderer.correctionMultiplier.current, this._zOffsetBase);

		this._counterMesh.position.set(pos.x, pos.y, pos.z);
	}

	private updateHighlightMeshScale() {
		const highlightScale = getXyiconHighlightScale(this.scale);

		this._highlightMesh.scale.set(highlightScale, highlightScale, highlightScale);
	}

	private updateIndicatorMeshPosition() {
		const {x, y, z} = getXyiconIndicatorPosition(this.scale, this._spaceViewRenderer.correctionMultiplier.current);

		this._indicatorMesh.position.set(x, y, z);
	}

	public addGroupToSceneIfNeeded() {
		if (!this._group.parent) {
			THREEUtils.addFront(this._xyiconManager.container, this._group);
		}
	}

	public addCaption(captionFields: IFieldAdapter[]) {
		if (!this._caption) {
			this._caption = new XyiconCaption(this);
		}
		const captionFieldValues = this.getCaptionFieldValues(captionFields, this._modelData);

		this._caption.updateText(captionFieldValues);

		if (captionFieldValues.length === 0) {
			this.removeCaption();
		}

		this._spaceViewRenderer.needsRender = true;
	}

	public override setVisibility(visible: boolean) {
		if (this._modelData?.isEmbedded) {
			visible = false;
		}
		super.setVisibility(visible);
		if (this.isVisible) {
			this._caption?.show();
			this._captionLeaderLine?.show();
		} else {
			this._caption?.hide();
			this._captionLeaderLine?.hide();
		}
		this.setSize(this._meshSizeMultiplicator, 1, false);
	}

	public updateCaptionLeaderLine() {
		if (this._caption) {
			const captionPos = this._caption.position;

			if (this._captionLeaderLine) {
				this._captionLeaderLine.update(captionPos.x, captionPos.y);
			} else {
				this._captionLeaderLine = new LeaderLine(
					this._spaceViewRenderer,
					this._group,
					captionPos.x,
					captionPos.y,
					this._spaceViewRenderer.markupScene,
				);
			}

			if (!this._xyiconManager.captionManager.instancedMesh?.visible || !this._caption.isVisible) {
				this._captionLeaderLine.hide();
			}
		}
	}

	public hideCaption() {
		this._caption?.hide();
		this._captionLeaderLine?.hide();
	}

	public showCaption() {
		this._caption?.show();
	}

	protected setColor(color: number, intensity: number) {
		const colorObject = new Color(color);

		colorObject.multiplyScalar(intensity);

		const colorAttrib = (this.instancedMesh.geometry as InstancedBufferGeometry).attributes.color as InstancedBufferAttribute;

		THREEUtils.setAttributeXYZ(colorAttrib, this._instanceId, colorObject.r, colorObject.g, colorObject.b);
		// this._counterMaterial?.color.set(color);
		// this._counterMaterial?.color.multiplyScalar(intensity);
		// this._flagMaterial?.color.set(color);
		// this._flagMaterial?.color.multiplyScalar(intensity);
	}

	protected override setOpacity(opacityMultiplicator: SpaceItemOpacityValue) {
		super.setOpacity(opacityMultiplicator);

		const opacityAttribute = (this.instancedMesh.geometry as InstancedBufferGeometry).attributes.opacity as InstancedBufferAttribute;

		THREEUtils.setAttributeX(opacityAttribute, this._instanceId, this.opacity);

		if (this.linkInstanceId > -1) {
			const {linkIconManager} = this._spaceViewRenderer.spaceItemController;
			const linkIconOpacityAttribute = (linkIconManager.icons.geometry as InstancedBufferGeometry).attributes.opacity as InstancedBufferAttribute;

			THREEUtils.setAttributeX(linkIconOpacityAttribute, this.linkInstanceId, opacityMultiplicator);
		}

		this._highlightMaterial?.setOpacity((1 - IndicatorMaterial.TRANSPARENCY.highlight) * opacityMultiplicator);
		this._indicatorMaterial?.setOpacity((1 - IndicatorMaterial.TRANSPARENCY.indicator) * opacityMultiplicator);
	}

	private saveOrientation() {
		if (this._lastSavedOrientation !== this._group.rotation.z) {
			this._lastSavedOrientation = this._group.rotation.z;
		}
	}

	public override setRotation(angle: number) {
		this._group.rotation.z = angle;
		this.updateInstancedMeshMatrix();
		this._spaceViewRenderer.needsRender = true;
	}

	// deltaAngle is compared to this._lastSavedOrientation, not the previous angle!
	public override rotateWithHandlerByDelta(deltaAngle: number, pivot?: PointDouble) {
		if (this.hasPermissionToMoveOrRotate) {
			this.turnToSemiTransparent();
			this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);

			this._group.rotation.z = MathUtils.calculateNewOrientation(this._lastSavedOrientation, deltaAngle, pivot ? false : undefined);
			if (pivot && this._savedPosition && !this.isPositionLocked) {
				const {x, y} = THREEUtils.getRotatedVertex(this._savedPosition, deltaAngle, pivot);

				this._group.position.setX(x);
				this._group.position.setY(y);
			}

			this.updateInstancedMeshMatrix();
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public stopRotating() {
		this.turnToOpaque();
		this.setColor(this._color, SpaceItem.COLOR_INTENSITY.SELECTED);
		this.saveOrientation();
		this.stopTranslating(); // yes, if it's rotated around a specific pivot point, it can be translated as well, so we must call this here

		this._spaceViewRenderer.needsRender = true;
	}

	// Used for initializing the orientation. DON'T UPDATE THE SERVER FROM THIS, IT CAN CREATE AN INFINITE LOOP
	public applyOrientation(newOrientation: number) {
		if (this._group.rotation.z !== newOrientation) {
			this._group.rotation.z = this._lastSavedOrientation = newOrientation;
			this.updateInstancedMeshMatrix();
			this._spaceViewRenderer.needsRender = true;
		}
	}

	public override translate(x: number, y: number, z: number, force: boolean = false) {
		const hasChanged = super.translate(x, y, z, force);

		if (hasChanged) {
			this.updateInstancedMeshMatrix();
		}

		return hasChanged;
	}

	public setPosition(x: number, y: number, z: number) {
		THREEUtils.setPosition(this._group, x, y, z);
		this.updateInstancedMeshMatrix();

		this._spaceViewRenderer.needsRender = true;
	}

	private increaseSizeForEmbedding() {
		this.setSize(this._meshSizeMultiplicator, 1.5);
	}

	public setSizeToNormal() {
		this.setSize(this._meshSizeMultiplicator);
	}

	public override mouseOver(isAnotherXyiconBeingHovered: boolean = false) {
		super.mouseOver();

		if (!this._isAnotherXyiconBeingHovered && isAnotherXyiconBeingHovered) {
			this._isAnotherXyiconBeingHovered = true;
			this.increaseSizeForEmbedding();
		}
	}

	public override mouseOut() {
		super.mouseOut();

		if (this._isAnotherXyiconBeingHovered) {
			this._isAnotherXyiconBeingHovered = false;
			this.setSizeToNormal();
		}
	}

	private updateInstancedMeshMatrix() {
		if (!this.isDestroyed) {
			this._xyiconMesh.updateMatrix();
			this._group.updateMatrix();
			this._group.updateMatrixWorld();
			const instancedMesh = this.instancedMesh;

			if (instancedMesh) {
				instancedMesh.setMatrixAt(this._instanceId, this._xyiconMesh.matrixWorld);
				// Hack to prevent computing the same thing over and over again when multiple xyicons are modified at once
				const keyForAnimationFrameId = "computeBoundingSphereRequestAnimationFrameId";

				cancelAnimationFrame(instancedMesh.userData[keyForAnimationFrameId]);
				instancedMesh.userData[keyForAnimationFrameId] = window.requestAnimationFrame(() => {
					// https://discourse.threejs.org/t/raycast-fails-after-modifying-the-instance-matrices/53791
					instancedMesh.computeBoundingSphere();
				});
				instancedMesh.instanceMatrix.needsUpdate = true;
			} else {
				console.warn(`InstancedMesh doesn't exist for xyicon ${this._modelData?.refId}. Please report this to the developers.`);
			}

			if (this.linkInstanceId > -1) {
				const {linkIconManager} = this._spaceViewRenderer.spaceItemController;

				linkIconManager.icons.setMatrixAt(this.linkInstanceId, this.getLinkIconObject(linkIconManager.dummyObject).matrix);
				linkIconManager.icons.instanceMatrix.needsUpdate = true;
			}
		}
	}

	public updateByModel(model: Xyicon) {
		this._group.position.set(model.iconX, model.iconY, model.iconZ);
		THREEUtils.updateMatrices(this._group);

		this.applyOrientation(model.orientation);
		this.addModelData(model);
		this.updateInstancedMeshMatrix();

		if (this._isSelected) {
			this.itemManager.updateSelectionBox();
			this._spaceViewRenderer.spaceItemController.updateActionBar();
		}

		this._spaceViewRenderer.needsRender = true;
	}

	/**
	 * Returns an array of BoundarySpaceMapIDs, which elements have overlap with this xyicon's position
	 */
	public getBoundarySpaceMapsWithOverlap(): string[] {
		const boundarySpaceMap3DArray: string[] = [];
		const selfPosition = this.position;

		for (const boundarySpaceMap3D of this._spaceViewRenderer.boundaryManager.items.array as BoundarySpaceMap3D[]) {
			if (boundarySpaceMap3D.isPointInside(selfPosition)) {
				boundarySpaceMap3DArray.push(boundarySpaceMap3D.modelData.id);
			}
		}

		return boundarySpaceMap3DArray;
	}

	private removeCaption() {
		this._caption?.updateText([]);
		if (this._captionLeaderLine?.line) {
			this._captionLeaderLine.line.geometry.dispose();
			(this._captionLeaderLine.line.material as Material).dispose();
			this._captionLeaderLine.line.parent?.remove(this._captionLeaderLine.line);
		}
		this._caption = null;
		this._captionLeaderLine = null;
	}

	private disposeGroup() {
		this.removeCaption();
		THREEUtils.clearContainer(this._group);
		this._group.parent?.remove(this._group);
		this._counterMesh = null;
		this._counterMaterial = null;
	}

	public override destroy(notifyServer?: boolean) {
		this.onSizeChange(0); // secures the link symbol to be hidden until it's deleted (it gets deleted when the server's response arrives)
		super.destroy(notifyServer);
	}

	/** The only scenario when we wouldn't want removeFromCollections to be true is when we delete everything
	 * Because we take care of removing everything after this from the collections with one call,
	 * since it's much faster then removing them one by one.
	 */
	protected destroyCallback(notifyServer: boolean = false, removeFromCollections: boolean = true) {
		// DON'T DISPOSE THE MAP WITHOUT THINKING TWICE: OTHER XYICONS MIGHT USE IT
		if (removeFromCollections) {
			this.disposeGroup();
			THREEUtils.removeItemFromInstancedMesh(this.instancedMesh, this._instanceId);
			for (const item of [...this._xyiconManager.items.array, ...this._spaceViewRenderer.ghostModeManager.ghostXyicons] as Xyicon3D[]) {
				if (item._instancedMeshId === this._instancedMeshId && item._instanceId > this._instanceId) {
					item.decreaseInstanceId();
				}
			}
			this._xyiconManager.deleteItem(this, notifyServer);
			this._spaceViewRenderer.needsRender = true;
		}

		this._meshSize.removeListeners();
	}

	public setSize(newSize: number, multiplier: number = 1, animated: boolean = true) {
		const visibilityMultiplier = this.isVisible ? newSize : 0;
		const multipliedMeshHeight = visibilityMultiplier * multiplier; // we don't want to store the multiplied value, because it would create bigger and bigger xyicons that way

		if (animated) {
			this._meshSize.setEnd(multipliedMeshHeight);
		} else {
			this._meshSize.reset(multipliedMeshHeight, multipliedMeshHeight);
		}
	}

	private onSizeChange = (newSize: number) => {
		const flipScale = this.flipScale;

		this._xyiconMesh.scale.set(newSize * flipScale.x, newSize * flipScale.y, newSize);
		this.updateCounterMesh();
		this.updateIndicatorMesh();
		this.updateHighlightMesh();
		this.updateFlagMesh();
		this.updateInstancedMeshMatrix();
	};

	public updateFlip() {
		const flipScale = this.flipScale;

		this._xyiconMesh.scale.set(this._meshSizeMultiplicator * flipScale.x, this._meshSizeMultiplicator * flipScale.y, this._meshSizeMultiplicator);

		const isFlipped = (this.instancedMesh.geometry as InstancedBufferGeometry).attributes.isFlipped as InstancedBufferAttribute;

		THREEUtils.setAttributeXY(isFlipped, this._instanceId, this.isFlippedX ? 1.0 : 0.0, this.isFlippedY ? 1.0 : 0.0);
		this.updateInstancedMeshMatrix();
	}

	protected setGrayScaled(value: boolean) {
		const isGrayScaledAttrib = (this.instancedMesh.geometry as InstancedBufferGeometry).attributes.isGrayScaled as InstancedBufferAttribute;

		THREEUtils.setAttributeX(isGrayScaledAttrib, this._instanceId, value ? 1.0 : 0.0);
	}

	public getLinkIconObject = (target: Object3D) => {
		const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;

		const counterSizeInPx = 29; // we created the size with the default xyicon size (81) in mind. 29 was the height of the icon
		const linkScaleValue = Math.abs(this._xyiconMesh.scale.x) * counterSizeInPx * correctionMultiplier;

		const scale = this.scale;

		const linkIconOffset = getLinkIconPosition(scale, correctionMultiplier, this._zOffsetBase);

		this._linkIconPos.set(this._group.position.x + linkIconOffset.x, this._group.position.y + linkIconOffset.y);
		this._linkIconPos.rotateAround(this._group.position as unknown as Vector2, this.orientation);

		const offset = this._zOffsetBase * correctionMultiplier;

		target.position.set(this._linkIconPos.x, this._linkIconPos.y, this.position.z + scale.z + offset);
		target.rotation.z = this.orientation;

		target.scale.set(linkScaleValue, linkScaleValue, linkScaleValue);

		target.updateMatrix();

		return target;
	};

	public get isFlippedX() {
		return !!this._modelData?.settings?.isFlippedX;
	}

	public get isFlippedY() {
		return !!this._modelData?.settings?.isFlippedY;
	}

	private get flipScale(): PointDouble {
		if (this._modelData?.iconCategory === CatalogIconType.ModelParameter) {
			return {
				x: this.isFlippedX ? -1 : 1,
				y: this.isFlippedY ? -1 : 1,
			};
		}

		return {
			x: 1,
			y: 1,
		};
	}

	public get conditionalFormattingElements() {
		return {
			indicatorMesh: this._indicatorMesh,
			indicatorMaterial: this._indicatorMaterial,
			highlightMesh: this._highlightMesh,
			highlightMaterial: this._highlightMaterial,
		};
	}

	public get instancedMesh() {
		return this._xyiconManager.getInstancedMeshById(this._instancedMeshId);
	}

	public get instancedMeshId() {
		return this._instancedMeshId;
	}

	/**
	 * Used when another xyicon is deleted. We must shift everything related to the instancedMesh
	 * Removing and shifting the values is handled in THREEUtils.removeItemFromInstancedMesh
	 */
	public decreaseInstanceId() {
		--this._instanceId;
		this.setColor(this._color, SpaceItem.COLOR_INTENSITY.DESELECTED);
	}

	public get parentXyicon() {
		return this._modelData?.parentXyicon || null;
	}

	public get instanceId() {
		return this._instanceId;
	}

	public get position() {
		return {
			x: this._group.position.x,
			y: this._group.position.y,
			z: this._group.position.z,
		};
	}

	public get scale() {
		const bbox = this.unrotatedBoundingBox;

		return {
			x: bbox.max.x - bbox.min.x,
			y: bbox.max.y - bbox.min.y,
			z: bbox.max.z - bbox.min.z,
		};
	}

	public get orientation() {
		return this._group.rotation.z;
	}

	private getUnrotatedBoundingBox(meshSize: number): IBox3 {
		const offset = this._modelData?.catalog?.insertionInfo ?? getDefaultInsertionInfo();
		const offsetSize: Dimensions = {
			x: Math.abs(offset.offsetX),
			y: Math.abs(offset.offsetY),
			z: Math.abs(offset.offsetZ),
		};

		return {
			min: {
				x: -offsetSize.x + this._group.position.x - (this._geometryScale.x * meshSize) / 2,
				y: -offsetSize.y + this._group.position.y - (this._geometryScale.y * meshSize) / 2,
				z: -offsetSize.z + this._group.position.z - (this._geometryScale.z * meshSize) / 2,
			},
			max: {
				x: offsetSize.x + this._group.position.x + (this._geometryScale.x * meshSize) / 2,
				y: offsetSize.y + this._group.position.y + (this._geometryScale.y * meshSize) / 2,
				z: offsetSize.z + this._group.position.z + (this._geometryScale.z * meshSize) / 2,
			},
		};
	}

	public get unrotatedBoundingBox(): IBox3 {
		return this.getUnrotatedBoundingBox(this._meshSize.value);
	}

	public get boundingBoxOnMeshSizeEnd(): IBox3 {
		return THREEUtils.getRotatedBox3(this.getUnrotatedBoundingBox(this._meshSize.end), this.orientation);
	}

	public get boundingBox() {
		return THREEUtils.getRotatedBox3(this.unrotatedBoundingBox, this.orientation);
	}

	public get counterMesh() {
		return this._counterMesh;
	}

	public get xyiconMesh() {
		return this._xyiconMesh;
	}

	public get meshSize() {
		return this._meshSize;
	}

	public get caption() {
		return this._caption;
	}

	public get captionLeaderLine() {
		return this._captionLeaderLine;
	}

	public get itemManager() {
		return this._xyiconManager;
	}

	public get type() {
		return this._typeName;
	}
}

import * as React from "react";
import styled from "styled-components";
import {TextInputV5} from "../../details/datatypes/TextInputV5";
import HideIcon from "../../icons/hide.svg?react";
import UnhideIcon from "../../icons/unhide.svg?react";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {FlexCenterStyle} from "../../styles/styles";

interface IPasswordInputProps {
	readonly value?: string;
	readonly onInput: (val: string) => void;
	readonly infoText?: string;
	readonly placeholder?: string;
	readonly divRef?: React.RefObject<HTMLDivElement>;
}

interface IPasswordInputState {
	hidePassword: boolean;
}

export class PasswordInputV5 extends React.Component<IPasswordInputProps, IPasswordInputState> {
	private _ref = React.createRef<HTMLInputElement | HTMLTextAreaElement>();
	constructor(props: IPasswordInputProps) {
		super(props);

		this.state = {
			hidePassword: true,
		};
	}

	private toggleHiding = () => {
		this.setState((prevState) => ({hidePassword: !prevState.hidePassword}));
	};

	public override render() {
		const {value, placeholder, infoText, divRef} = this.props;
		const {hidePassword} = this.state;

		return (
			<PasswordInputStyled className="PasswordInput hbox alignCenter">
				<TextInputV5
					value={value}
					onInput={(val) => this.props.onInput(val)}
					inputType={hidePassword ? "password" : "text"}
					placeholder={placeholder}
					getErrorMessage={() => infoText}
					divRef={(divRef as React.RefObject<HTMLInputElement>) ?? (this._ref as React.RefObject<HTMLInputElement>)}
				/>
				<IconButtonV5
					IconComponent={hidePassword ? HideIcon : UnhideIcon}
					onClick={this.toggleHiding}
					className="hideUnhideIcon"
				/>
			</PasswordInputStyled>
		);
	}
}

const PasswordInputStyled = styled.div`
	${FlexCenterStyle}
	position: relative;

	input {
		width: 240px;
	}

	.hideUnhideIcon {
		right: 0;
		position: absolute;
	}

	.infoIcon {
		right: 35px;
	}
`;

import type {INumericFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/Numeric";
import {IconButtonV5} from "../../../interaction/IconButtonV5";
import ApplyIcon from "../../../icons/check.svg?react";
import CancelIcon from "../../../icons/xmark-large.svg?react";
import type {IInputPropsV5} from "../../../../widgets/input/clicktoedit/InputUtils";
import {NumberInputV5} from "./NumberInputV5";

export const NumericInputV5 = (props: IInputPropsV5<INumericFieldSettingsDefinition>) => {
	const {onInput, onChange, onCancel, onApply, value, noButtons, dataTypeSettings, caretPosition} = props;

	return (
		<div className="hbox field-input-container">
			<NumberInputV5
				value={value}
				onInput={onInput}
				onChange={onChange}
				decimals={dataTypeSettings.decimals}
				autoFocus={true}
				dataTypeSettings={dataTypeSettings}
				caretPosition={caretPosition}
			/>
			{!noButtons && (
				<>
					<IconButtonV5
						IconComponent={ApplyIcon}
						title="Apply"
						onClick={onApply}
					/>
					<IconButtonV5
						IconComponent={CancelIcon}
						title="Cancel"
						onClick={onCancel}
					/>
				</>
			)}
		</div>
	);
};

import * as React from "react";
import {observer} from "mobx-react";
import type {XyiconManager} from "../../logic3d/managers/spaceitems/XyiconManager";
import type {BoundaryManager} from "../../logic3d/managers/spaceitems/BoundaryManager";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {getFeatureBySpaceItemType} from "../../logic3d/renderers/SpaceViewRendererUtils";
import {SelectInput} from "../../../../../widgets/input/select/SelectInput";
import type {Type} from "../../../../../../data/models/Type";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {Field} from "../../../../../../data/models/field/Field";
import {featureTitles} from "../../../../../../data/state/AppStateConstants";
import type {View} from "../../../../../../data/models/View";
import {ColorRuleRow} from "./ColorRuleRow";

interface ColorRulesProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly view: View;
	readonly activeSection: "boundary" | "xyicon";
	readonly searchString: string;
}

export type ColorRuleCategory = "indicator" | "highlight";
export const colorRuleCategories: ColorRuleCategory[] = ["indicator", "highlight"];

@observer
export class ColorRules extends React.Component<ColorRulesProps> {
	private readonly _noneFieldName: string = "None";

	private get feature() {
		return getFeatureBySpaceItemType(this.props.activeSection);
	}

	private get itemManager() {
		return this.props.spaceViewRenderer.getItemManager(this.feature) as XyiconManager | BoundaryManager;
	}

	private get formattingRules() {
		return this.props.view.spaceEditorViewSettings.formattingRules[this.props.activeSection];
	}

	private onFieldChange(type: Type | "All", colorRuleCategory: ColorRuleCategory, fieldName: string) {
		const {spaceViewRenderer} = this.props;

		const formattingRules = this.formattingRules[colorRuleCategory];

		const feature = this.feature;

		if (type === "All") {
			const appState = spaceViewRenderer.transport.appState;
			const featureTypes = appState.types[feature];

			if (featureTypes) {
				if (fieldName !== "") {
					for (const type of featureTypes) {
						formattingRules[type.name] = fieldName;
					}
				}
			}
		} else {
			formattingRules[type.name] = fieldName;
		}

		this.itemManager.onFormattingRulesModified();
	}

	private getSelectedForAll(colorRuleCategory: ColorRuleCategory) {
		const {spaceViewRenderer} = this.props;
		const appState = spaceViewRenderer.transport.appState;
		const feature = this.feature;
		const featureTypes = appState.types[feature];
		const formattingRules = this.formattingRules[colorRuleCategory];

		if (featureTypes.length === 0) {
			return "";
		} else {
			let firstFieldName = formattingRules[featureTypes[0].name];
			let areAllTheSame = true;

			for (let i = 1; i < featureTypes.length; ++i) {
				const type = featureTypes[i];
				const field = formattingRules[type.name];

				if (firstFieldName != field) {
					areAllTheSame = false;
					break;
				}

				firstFieldName = field;
			}

			if (firstFieldName == null) {
				firstFieldName = this._noneFieldName;
			}

			return areAllTheSame ? firstFieldName : "";
		}
	}

	private renderTable() {
		const {spaceViewRenderer} = this.props;
		const appState = spaceViewRenderer.transport.appState;
		const actions = appState.actions;
		const feature = this.feature;
		const featureTypes = appState.types[feature];
		const formattingRules = this.formattingRules;

		const allTitle = "All";
		const fields = actions.getFieldsByFeature(feature) as Field[];
		const filteredFieldNames = fields
			.filter((field: Field) => field.formattingRules?.length > 0)
			.map((field) => field.name)
			.sort(StringUtils.sortIgnoreCase);

		const noneFieldName = this._noneFieldName;

		return (
			<div className="vbox">
				<div className="ColorRuleRow head hbox">
					<div></div>
					<div className="flexCenter">
						<div className={`ruleIcons indicator ${featureTitles[feature]}`}></div>
						Indicator
					</div>
					<div className="flexCenter">
						<div className={`ruleIcons highlight ${featureTitles[feature]}`}></div>
						Highlight
					</div>
				</div>
				{StringUtils.containsIgnoreCase(allTitle, this.props.searchString) && (
					<div className="ColorRuleRow hbox">
						<div className="label">{allTitle}</div>
						<SelectInput
							sort={false}
							options={["", noneFieldName, ...filteredFieldNames]}
							onChange={(fieldName: string) => this.onFieldChange(allTitle, "indicator", fieldName)}
							selected={this.getSelectedForAll("indicator")}
						/>
						<SelectInput
							sort={false}
							options={["", noneFieldName, ...filteredFieldNames]}
							onChange={(fieldName: string) => this.onFieldChange(allTitle, "highlight", fieldName)}
							selected={this.getSelectedForAll("highlight")}
						/>
					</div>
				)}
				{featureTypes
					.filter((type: Type) => StringUtils.containsIgnoreCase(type.name, this.props.searchString))
					.sort((a: Type, b: Type) => StringUtils.sortIgnoreCase(a.name, b.name))
					.map((type: Type, index: number) => {
						return (
							<ColorRuleRow
								key={index}
								label={type.name}
								options={[noneFieldName, ...filteredFieldNames]}
								onChangeIndicator={(fieldName: string) => this.onFieldChange(type, "indicator", fieldName)}
								selectedIndicator={formattingRules.indicator[type.name] || noneFieldName}
								onChangeHighlight={(fieldName: string) => this.onFieldChange(type, "highlight", fieldName)}
								selectedHighlight={formattingRules.highlight[type.name] || noneFieldName}
								render={(fieldName: string) => (fieldName == null ? "None" : fieldName)}
							/>
						);
					})}
			</div>
		);
	}

	public override render() {
		return <div className="ColorRules vbox">{this.renderTable()}</div>;
	}
}

import * as React from "react";
import {ClickToEditDistance} from "../../../../../widgets/input/clicktoedit/ClickToEditDistance";
import type {ExtendedDistanceUnitName} from "../../../../space/spaceeditor/logic3d/Constants";
import {Constants} from "../../../../space/spaceeditor/logic3d/Constants";
import {Field} from "../../../../../widgets/form/field/Field";
import {MathUtils} from "../../../../../../utils/math/MathUtils";

export interface IRealSize {
	value: number;
	unit: ExtendedDistanceUnitName;
}

interface IRealSizeInputProps {
	readonly size: IRealSize;
	readonly onChange: (size: IRealSize) => void;
	readonly label: string;
	readonly validateValue: (value: number) => boolean;
	readonly infoText?: string;
	readonly layoutType?: "vbox" | "hbox";
	readonly optionsZIndex?: number;
}

export class RealSizeInput extends React.Component<IRealSizeInputProps> {
	public static readonly defaultProps: Partial<IRealSizeInputProps> = {
		validateValue: (value: number) => MathUtils.isValidNumber(value) && value > 0,
		layoutType: "vbox",
	};

	private getErrorMessage = (value: any) => {
		return this.props.validateValue(parseFloat(value)) ? "" : "Please provide a valid, positive number!";
	};

	public override render() {
		const size = this.props.size;

		return (
			<Field
				label={this.props.label}
				tooltips={{postLabelIconTooltip: this.props.infoText}}
				icons={{postLabelIcon: this.props.infoText ? "info" : undefined}}
			>
				<ClickToEditDistance
					value={size.value}
					unit={Constants.DISTANCE_UNITS[size.unit]}
					onChange={this.props.onChange}
					getErrorMessage={this.getErrorMessage}
					layoutType={this.props.layoutType}
					optionsZIndex={this.props.optionsZIndex}
				/>
			</Field>
		);
	}
}

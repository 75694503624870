import styled, {css} from "styled-components";
import {memo, useRef, useState} from "react";
import {Observer} from "mobx-react";
import {FlexCenter} from "../../styles/styles";
import {IconButtonV5} from "../../interaction/IconButtonV5";
import {colorPalette} from "../../styles/colorPalette";
import FilterIcon from "../../../5.0/icons/filter-icon.svg?react";
import LabelsIcon from "../../../5.0/icons/label-icon.svg?react";
import FormattingIcon from "../../../5.0/icons/formatting-icon.svg?react";
import LayersIcon from "../../../5.0/icons/layer-icon.svg?react";
import CloseIcon from "../../../5.0/icons/xmark.svg?react";
import {XyiconFeature} from "../../../../generated/api/base";
import {Functions} from "../../../../utils/function/Functions";
import {useAppStore} from "../../../../StateManager";
import {FilterEditorV5} from "../../filter/FilterEditorV5";
import type {SpaceViewRenderer} from "../../../modules/space/spaceeditor/logic3d/renderers/SpaceViewRenderer";
import {useClickOutside} from "../../utils";
import {LayerPanelV5} from "./viewbar/LayersPanelV5";
import {ConditionalFormattingPanelV5} from "./viewbar/ConditionalFormattingPanelV5";
import {CaptionPanelV5} from "./viewbar/CaptionPanelV5";

interface IOverlayPanelProps {
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly openedSpaceEditorPanelType: TSpaceEditorPanelTypes | null;
	readonly onConfiguratorOpenChange?: (isOpen: boolean) => void;
}

const OverlayPanel = memo((props: IOverlayPanelProps) => {
	return (
		<Observer>
			{() => {
				const {spaceViewRenderer, openedSpaceEditorPanelType, onConfiguratorOpenChange} = props;
				const filteredItems = spaceViewRenderer.spaceItemController.getFilteredItems();
				const selectedView = spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);

				switch (openedSpaceEditorPanelType) {
					case "filter":
						return (
							<FilterEditorV5
								features={[XyiconFeature.Xyicon, XyiconFeature.Boundary]}
								feature={XyiconFeature.SpaceEditor}
								items={filteredItems}
								onSelect={Functions.emptyFunction}
							/>
						);
					case "layers":
						return (
							<LayerPanelV5
								spaceViewRenderer={spaceViewRenderer}
								showNumbers={true}
								view={selectedView}
							/>
						);
					case "labels":
						return (
							<CaptionPanelV5
								spaceViewRenderer={spaceViewRenderer}
								view={selectedView}
								onConfiguratorOpenChange={onConfiguratorOpenChange}
							/>
						);
					case "formatting":
						return (
							<ConditionalFormattingPanelV5
								spaceViewRenderer={spaceViewRenderer}
								view={selectedView}
							/>
						);
					default:
						return null;
				}
			}}
		</Observer>
	);
});

export type TSpaceEditorPanelTypes = "formatting" | "layers" | "labels" | "filter";

interface ISpaceEditorPanelV5Props {
	readonly openedSpaceEditorPanelType: TSpaceEditorPanelTypes | null;
	readonly onClose: () => void;
}

export const SpaceEditorPanelV5 = ({openedSpaceEditorPanelType, onClose}: ISpaceEditorPanelV5Props) => {
	const appState = useAppStore((state) => state.appState);
	const {spaceViewRenderer} = appState.app;
	const ref = useRef<HTMLDivElement>();
	const [isConfiguratorOpen, setIsConfiguratorOpen] = useState<boolean>(false);

	useClickOutside([ref], () => {
		if (!isConfiguratorOpen) {
			onClose();
		}
	});

	const handleConfiguratorOpenChange = (isOpen: boolean) => {
		setIsConfiguratorOpen(isOpen);
	};

	const getPanelIcon = () => {
		switch (openedSpaceEditorPanelType) {
			case "filter":
				return <FilterIcon />;
			case "layers":
				return <LayersIcon />;
			case "formatting":
				return <FormattingIcon />;
			case "labels":
				return <LabelsIcon />;
			default:
				return null;
		}
	};

	return (
		<SpaceEditorPanelContainerStyled
			$isOpen={!!openedSpaceEditorPanelType}
			ref={ref}
		>
			<FlexCenter style={{justifyContent: "space-between", color: colorPalette.primary.c500Primary}}>
				<FlexCenter $gap="4px">
					{getPanelIcon()}
					<h4>{openedSpaceEditorPanelType?.toUpperCase()}</h4>
				</FlexCenter>
				<IconButtonV5
					IconComponent={CloseIcon}
					onClick={onClose}
				/>
			</FlexCenter>
			<OverlayPanel
				spaceViewRenderer={spaceViewRenderer}
				openedSpaceEditorPanelType={openedSpaceEditorPanelType}
				onConfiguratorOpenChange={handleConfiguratorOpenChange}
			/>
		</SpaceEditorPanelContainerStyled>
	);
};

const SpaceEditorPanelContainerStyled = styled.div<{$isOpen: boolean}>`
	display: flex;
	flex-direction: column;
	min-height: 0;
	width: 408px;
	position: absolute;
	right: 16px;
	bottom: 16px;
	height: calc(100vh - 163px);
	border-radius: 16px;
	transform: translateX(calc(100% + 16px));
	transition: ease-in-out transform 0.2s;
	background-color: white;
	z-index: 9;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.5);
	padding: 8px;
	gap: 16px;

	${(props) =>
		props.$isOpen &&
		css`
			transform: translateX(0);
		`}
`;

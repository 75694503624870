import * as React from "react";
import {observer} from "mobx-react";
import type {SpaceViewRenderer} from "../../logic3d/renderers/SpaceViewRenderer";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {ToggleContainer} from "../../../../../widgets/container/ToggleContainer";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import {SearchField} from "../../../../../widgets/input/search/SearchField";
import type {View} from "../../../../../../data/models/View";
import {XyiconFeature} from "../../../../../../generated/api/base";
import type {SpaceFile} from "../../../../../../data/models/SpaceFile";
import {SpaceVersionSelector} from "../../../../abstract/card/SpaceVersionSelector";
import {Button} from "../../../../../widgets/button/Button";
import {SaveToViewButton} from "../../../../abstract/common/savetoview/SaveToViewButton";
import {TabChild} from "../../../../../widgets/tab/TabChild";
import {TabView} from "../../../../../widgets/tab/TabView";
import {MarkupColorFilterPanel} from "./MarkupColorFilterPanel";
import {HideAndLock} from "./HideAndLock";
import {LayerView} from "./LayerView";

export interface IOverlayPanelProps {
	readonly view: View;
	readonly spaceViewRenderer: SpaceViewRenderer;
	readonly onToggle: () => void;
}

interface ILayerPanelProps extends IOverlayPanelProps {
	readonly showNumbers: boolean;
}

export interface IOverlayPanelState {
	isOpen: boolean;
	searchString: string;
}

@observer
export class LayerPanel extends React.Component<ILayerPanelProps, IOverlayPanelState> {
	constructor(props: ILayerPanelProps) {
		super(props);

		this.state = {
			isOpen: false,
			searchString: "",
		};
	}

	private get selectedSpaceEditorView() {
		return this.props.spaceViewRenderer.actions.getSelectedView(XyiconFeature.SpaceEditor);
	}

	private get spaceEditorViewSettings() {
		return this.props.view.spaceEditorViewSettings;
	}

	private onBackgroundToggle = (toHidden: boolean) => {
		this.spaceEditorViewSettings.layers.background.isHidden = toHidden;

		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.tileManager.container.visible = !toHidden;
			this.props.spaceViewRenderer.needsRender = true;
		}
	};

	private onSpaceVersionChange = (spaceFile: SpaceFile) => {
		const space = spaceFile.parent;

		space.setSelectedSpaceFile(spaceFile);
		this.props.spaceViewRenderer.reloadTiles();
	};

	private onSearchInputChange = (searchString: string) => {
		this.setState({
			searchString: searchString,
		});
	};

	private getSavedLayerSettings() {
		return this.props.view.getSavedSpaceEditorViewSettings()?.layers || this.props.view.getDefaultLayerSettings();
	}

	private getLayerSettings() {
		return this.props.view.spaceEditorViewSettings.layers;
	}

	private isResetButtonEnabled() {
		const layerSettings = this.getLayerSettings();

		return JSON.stringify(layerSettings) !== JSON.stringify(this.getSavedLayerSettings());
	}

	private onResetClick = () => {
		this.props.view.spaceEditorViewSettings.layers = this.getSavedLayerSettings();
		this.props.spaceViewRenderer.boundaryManager.onLayerSettingsModified();
		this.props.spaceViewRenderer.xyiconManager.onLayerSettingsModified();
		this.props.spaceViewRenderer.markupManager.onLayerSettingsModified();
		if (this.selectedSpaceEditorView === this.props.view) {
			this.props.spaceViewRenderer.tileManager.container.visible = !this.props.view.spaceEditorViewSettings.layers.background.isHidden;
		}
	};

	public override componentDidMount() {
		const {view} = this.props;
		const spaceEditorViewSettings = JSON.stringify(view.spaceEditorViewSettings);

		view.applyData(view.data);
		view.setSpaceEditorViewSettings(JSON.parse(spaceEditorViewSettings));
		this.setState({
			isOpen: true,
		});
	}

	public override render() {
		const {spaceViewRenderer} = this.props;

		const backgroundTitle = "Background Layer";

		return (
			<div className={ReactUtils.cls("LayerPanel overlayPanel", {open: this.state.isOpen})}>
				<TabView
					selectedTabId="layers"
					onClose={this.props.onToggle}
				>
					<TabChild
						id="layers"
						icon="layers"
						title="Layers"
						label="Layers"
					>
						<div className="topButtons hbox">
							<SaveToViewButton
								feature={XyiconFeature.SpaceEditor}
								viewChangeType="layers"
							/>
							<div className="flex_1" />
							<Button
								className="secondary"
								label="Reset"
								disabled={!this.isResetButtonEnabled()}
								onClick={this.onResetClick}
								icon="reset"
							/>
						</div>
						<div className="container">
							<div className="head">
								<div className="field hbox alignCenter">
									{StringUtils.containsIgnoreCase(backgroundTitle, this.state.searchString) && (
										<>
											<div className="hbox alignCenter">
												<HideAndLock
													active={this.spaceEditorViewSettings.layers.background.isHidden}
													type="hide"
													onChange={this.onBackgroundToggle}
												/>
												<div className="title">{backgroundTitle}</div>
											</div>
											<SpaceVersionSelector
												item={this.props.spaceViewRenderer.space}
												onChoose={this.onSpaceVersionChange}
											/>
										</>
									)}
									<SearchField
										value={this.state.searchString}
										onInput={this.onSearchInputChange}
									/>
								</div>
							</div>
							<ToggleContainer
								title="Boundaries"
								open={true}
							>
								<LayerView
									view={this.props.view}
									searchString={this.state.searchString}
									spaceViewRenderer={spaceViewRenderer}
									showNumbers={this.props.showNumbers}
									activeSection="boundary"
								/>
							</ToggleContainer>
							<ToggleContainer
								title="Xyicons"
								open={true}
							>
								<LayerView
									view={this.props.view}
									searchString={this.state.searchString}
									spaceViewRenderer={spaceViewRenderer}
									showNumbers={this.props.showNumbers}
									activeSection="xyicon"
								/>
							</ToggleContainer>
							<ToggleContainer
								title="Markups"
								open={true}
							>
								<MarkupColorFilterPanel
									spaceViewRenderer={spaceViewRenderer}
									view={this.props.view}
								/>
								<LayerView
									view={this.props.view}
									searchString={this.state.searchString}
									spaceViewRenderer={spaceViewRenderer}
									showNumbers={this.props.showNumbers}
									activeSection="markup"
								/>
							</ToggleContainer>
						</div>
					</TabChild>
				</TabView>
			</div>
		);
	}
}

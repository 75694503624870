import {MathUtils} from "../math/MathUtils";
import {BrowserWindow} from "../device/BrowserWindow";

type Alignment = "top" | "bottom";

export enum VerticalAlignment {
	top = 0,
	bottom = 1,
	center = 2,
	topOuter = 3,
	bottomOuter = 4,
}

export enum HorizontalAlignment {
	left = 5,
	center = 6,
	right = 7,
	outerRight = 8,
	outerLeft = 9,
}

export type TransformObj = {
	translate: string;
	x: number;
	y: number;
	vertical: VerticalAlignment;
	horizontal: HorizontalAlignment;
};

declare global {
	interface Element {
		// Not standard
		scrollIntoViewIfNeeded?: (opt_center?: boolean) => void;
	}
}

export class DomUtils {
	public static getDivByDataId(dataId: string, parent: HTMLElement): HTMLDivElement {
		return parent.querySelector(`div[data-id="${dataId}"]`);
	}

	// TODO move into encapsulated (ElementTransform) class
	public static translate(element: HTMLElement, x = 0, y = 0) {
		this.transform(element, `translate(${x}px, ${y}px)`);
	}

	public static translate3d(element: HTMLElement, x = 0, y = 0, z = 0) {
		this.transform(element, `translate3d(${x}px, ${y}px, ${z}px)`);
	}

	public static transform(element: HTMLElement, transform: string) {
		element.style.transform = transform;
	}

	public static getTransform(element: HTMLElement) {
		const computedStyle = getComputedStyle(element);
		let result = computedStyle.transform;

		if (result === "none") {
			result = "";
		}

		return result;
	}

	public static getCoordinates(element: HTMLElement, relativeParent?: HTMLElement, result: [number, number] = [0, 0]) {
		const clientRect = element.getBoundingClientRect();

		let left = clientRect.left + BrowserWindow.getScrollX();
		let top = clientRect.top + BrowserWindow.getScrollY();

		if (relativeParent) {
			const parentCoordinates = this.getCoordinates(relativeParent);

			left -= parentCoordinates[0];
			top -= parentCoordinates[1];
		}

		result[0] = left;
		result[1] = top;

		return result;
	}

	public static calculateAlignment(target: HTMLElement, defaultAlignment?: Alignment): Alignment {
		if (defaultAlignment) {
			return defaultAlignment;
		} else {
			const rect = target.getBoundingClientRect();

			let direction: Alignment = "bottom";

			if (rect.y > window.innerHeight / 2) {
				// this component is in the bottom half of the screen -> open upwards
				direction = "top";
			}

			return direction;
		}
	}

	public static getFixedFloatingElementPosition(
		parentElement: Element,
		floatingElement: Element,
		verticalAlignment: VerticalAlignment = VerticalAlignment.bottomOuter,
		horizontalAlignment: HorizontalAlignment = HorizontalAlignment.left,
		offsetY: number = 5,
		offsetX: number = 5,
		changeAlignmentOnPositionCorrection: boolean = false,
	): TransformObj {
		if (!parentElement || !floatingElement) {
			console.warn("Element doesn't exist for DomUtils.getFixedFloatingElementPosition");
			return;
		}

		const getPosCalc = (alignment: VerticalAlignment | HorizontalAlignment) => {
			switch (alignment) {
				case VerticalAlignment.center:
					return parent.top + parent.height / 2 - floating.height / 2;
				case VerticalAlignment.top:
					return parent.bottom - floating.height - offsetY;
				case VerticalAlignment.topOuter:
					return parent.top - floating.height - offsetY;
				case VerticalAlignment.bottom:
					return parent.top + offsetY;
				case VerticalAlignment.bottomOuter:
					return parent.top + parent.height + offsetY;
				case HorizontalAlignment.left:
					return parent.left + offsetX;
				case HorizontalAlignment.right:
					return parent.left - (floating.width - parent.width) - offsetX;
				case HorizontalAlignment.center:
					return parent.left + parent.width / 2 - floating.width / 2 + offsetX;
				case HorizontalAlignment.outerLeft:
					return parent.x - floating.width - offsetX;
				case HorizontalAlignment.outerRight:
					return parent.right + offsetX;
				default:
					break;
			}
		};

		const parent = parentElement.getBoundingClientRect();
		const floating = floatingElement.getBoundingClientRect();
		const marginFromEdge = 5;

		let finalVerticalAlignment: VerticalAlignment = verticalAlignment;
		let finalHorizontalAlignment: HorizontalAlignment = horizontalAlignment;

		let x = getPosCalc(horizontalAlignment);
		let y = getPosCalc(verticalAlignment);

		// Corrigating floating element position if it would be out of the screen in two different ways.
		if (changeAlignmentOnPositionCorrection) {
			// If floating element would be out of screen on the top
			if (y < marginFromEdge) {
				finalVerticalAlignment = verticalAlignment === VerticalAlignment.top ? VerticalAlignment.bottom : VerticalAlignment.bottomOuter;
			}

			// ... bottom
			if (y + floating.height > window.innerHeight - marginFromEdge) {
				finalVerticalAlignment = verticalAlignment === VerticalAlignment.bottom ? VerticalAlignment.top : VerticalAlignment.topOuter;
			}

			// ... left
			if (x < marginFromEdge) {
				finalHorizontalAlignment = horizontalAlignment === HorizontalAlignment.right ? HorizontalAlignment.right : HorizontalAlignment.outerRight;
			}

			// ... right
			if (x + floating.width > window.innerWidth - marginFromEdge) {
				finalHorizontalAlignment = finalHorizontalAlignment === HorizontalAlignment.left ? HorizontalAlignment.left : HorizontalAlignment.outerLeft;
			}

			x = getPosCalc(finalHorizontalAlignment);
			y = getPosCalc(finalVerticalAlignment);
		} else {
			y = MathUtils.clamp(y, marginFromEdge, Math.max(marginFromEdge, window.innerHeight - floating.height - marginFromEdge));
			x = MathUtils.clamp(x, marginFromEdge, Math.max(marginFromEdge, window.innerWidth - floating.width - marginFromEdge));
		}

		return {
			translate: `translate(${x}px, ${y}px)`,
			x,
			y,
			vertical: finalVerticalAlignment,
			horizontal: finalHorizontalAlignment,
		};
	}

	public static addScriptTag(url: string, onLoaded?: () => void) {
		const script = document.createElement("script");

		if (onLoaded) {
			script.addEventListener("load", onLoaded);
		}

		script.src = url;

		document.body.appendChild(script);

		return script;
	}

	/**
	 * Make sure the element is added to the DOM before calling this.
	 */
	public static fitToScreen(element: HTMLElement, pos?: [number, number], pWindow?: Window) {
		const clientRect = element.getBoundingClientRect();

		pos = pos || [clientRect.left, clientRect.top];
		pWindow = pWindow || window;

		const body = pWindow.document.body;

		let x = pos[0];
		let y = pos[1];
		const w = element.offsetWidth;
		const h = element.offsetHeight;

		const fullWidth = body.clientWidth;
		const fullHeight = body.clientHeight;

		// If element's bottom wouldn't fit the screen -> move it up by it's height.
		if (y + h > fullHeight) {
			y -= h;
		}

		// If element's right wouldn't fit the screen -> move it to the right edge of the screen.
		if (x + w > fullWidth) {
			x = fullWidth - w;
		}

		this.translate(element, x, y);
	}

	public static scrollIntoViewIfNeeded(element: HTMLElement) {
		if (element?.scrollIntoViewIfNeeded) {
			element.scrollIntoViewIfNeeded();
		} else {
			element?.scrollIntoView();
		}
	}

	public static detach(element: HTMLElement | null) {
		if (element && element.parentNode) {
			element.parentNode.removeChild(element);

			return true;
		}

		return false;
	}

	public static up(element: HTMLElement, test: (el: HTMLElement) => boolean): HTMLElement | null {
		if (test(element)) {
			return element;
		}

		if (element.parentNode instanceof HTMLElement) {
			// This loops until the root HTMLHTMLElement tag (the parent of body)
			return this.up(element.parentNode, test);
		}

		return null;
	}

	public static flyTo(toX: number, toY: number, el: HTMLElement, duration: number, arc: boolean) {
		const animKeyFramesX: Keyframe[] = [{left: `${toX}px`}];
		const animTimingX: KeyframeAnimationOptions = {
			duration,
			iterations: 1,
			easing: "ease-in-out",
		};
		const animKeyFramesY: Keyframe[] = [{top: `${toY}px`}];
		const animTimingY: KeyframeAnimationOptions = {
			duration,
			iterations: 1,
		};

		if (arc) {
			animTimingY.easing = "cubic-bezier(.25, .48, .55, .77)";
		}

		el.animate(animKeyFramesX, animTimingX);
		el.animate(animKeyFramesY, animTimingY);

		return Promise.all(el.getAnimations().map((anim) => anim.finished));
	}
}

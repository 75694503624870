import * as React from "react";
import {inject} from "mobx-react";
import type {IBasicPropsFor3DTweaking} from "../../../SpaceView";
import type {Type} from "../../../../../../data/models/Type";
import {ReactUtils} from "../../../../../utils/ReactUtils";
import {Initials} from "../../../../../widgets/Initials";
import {XyiconFeature, Permission} from "../../../../../../generated/api/base";
import {StringUtils} from "../../../../../../utils/data/string/StringUtils";
import type {TransformObj} from "../../../../../../utils/dom/DomUtils";
import {DomUtils, HorizontalAlignment, VerticalAlignment} from "../../../../../../utils/dom/DomUtils";
import {DomPortal} from "../../../../abstract/portal/DomPortal";
import {InfoBubble} from "../../../../abstract/common/infobutton/InfoBubble";
import {SVGIcon} from "../../../../../widgets/button/SVGIcon";
import type {AppState} from "../../../../../../data/state/AppState";

interface IBoundaryTypeContainerProps extends IBasicPropsFor3DTweaking {
	readonly onBoundaryTypeClick: (typeId: string) => void;
	readonly appState?: AppState;
}

interface IBoundaryTypeContainerState {
	isToolTipOpen: boolean;
	toolTipTransform: TransformObj;
	content: React.ReactNode;
}

@inject("appState")
export class BoundaryTypeContainer extends React.Component<IBoundaryTypeContainerProps, IBoundaryTypeContainerState> {
	private _parents: HTMLDivElement[] = [];
	private _floating = React.createRef<HTMLDivElement>();
	private _timeOutId: number = null;
	private _currentIndex: number = -1;

	constructor(props: IBoundaryTypeContainerProps) {
		super(props);
		this.state = {
			isToolTipOpen: false,
			toolTipTransform: null,
			content: "",
		};
	}

	private openTooltip = (event: React.MouseEvent, hasPermission: boolean, index: number, name: string) => {
		const isOverflowing = event.currentTarget.scrollWidth > event.currentTarget.clientWidth;

		if (!hasPermission || isOverflowing) {
			if (this._timeOutId) {
				clearTimeout(this._timeOutId);
			}

			this._timeOutId = window.setTimeout(() => {
				this._currentIndex = index;
				this.setState({
					isToolTipOpen: true,
					content: !hasPermission ? (
						<>
							<SVGIcon icon="locked" />
							<div>You do not have permission to use this boundary.</div>
						</>
					) : (
						name
					),
				});
			}, 1000);
		}
	};

	private closeTooltip = () => {
		clearTimeout(this._timeOutId);

		this.setState({
			isToolTipOpen: false,
		});
	};

	private getBoundaryList = () => {
		const {actions} = this.props.appState;

		return actions
			.getTypesByFeature(XyiconFeature.Boundary)
			.slice()
			.filter((a: Type) => actions.getModuleTypePermission(a.id, a.feature) !== Permission.None)
			.sort((a: Type, b: Type) => {
				const aHasPermission = actions.getModuleTypePermission(a.id, a.feature) >= Permission.Update;
				const bHasPermission = actions.getModuleTypePermission(b.id, b.feature) >= Permission.Update;

				if (aHasPermission && !bHasPermission) {
					return -1;
				} else if (!aHasPermission && bHasPermission) {
					return 1;
				} else {
					return StringUtils.sortIgnoreCase(a.name, b.name);
				}
			});
	};

	private onClick = (id: string, hasPermission: boolean) => {
		if (hasPermission) {
			this.props.onBoundaryTypeClick(id);
			this.forceUpdate();
		}
	};

	private get modalContainer() {
		return this.props.appState.app.modalContainer;
	}

	public override componentDidUpdate(prevProps: IBoundaryTypeContainerProps, prevState: IBoundaryTypeContainerState) {
		if (!prevState.isToolTipOpen && this.state.isToolTipOpen && this._parents[this._currentIndex] && this._floating.current) {
			this.setState({
				toolTipTransform: DomUtils.getFixedFloatingElementPosition(
					this._parents[this._currentIndex],
					this._floating.current,
					VerticalAlignment.topOuter,
					HorizontalAlignment.left,
				),
			});
		}
	}

	public override componentWillUnmount() {
		clearTimeout(this._timeOutId);
	}

	public override render() {
		const {spaceViewRenderer} = this.props;
		const {isToolTipOpen, toolTipTransform, content} = this.state;

		const floatingElement = this._floating.current;
		const inlineStyle: React.CSSProperties = floatingElement && {
			transform: toolTipTransform?.translate,
		};

		const boundaryTypeList = this.getBoundaryList();

		return (
			<div className="BoundaryTypeContainer vbox">
				{boundaryTypeList.map((type: Type, index: number) => {
					const currentTypeId = spaceViewRenderer.boundaryManager.typeId;
					const typeId = type.id;
					const currentPermission = spaceViewRenderer.actions.getModuleTypePermission(typeId, XyiconFeature.Boundary);
					const hasPermission = currentPermission >= Permission.Update;

					return (
						<div
							ref={(divRef) => (this._parents[index] = divRef)}
							className={ReactUtils.cls("hbox alignCenter", {active: typeId === currentTypeId, noPermission: !hasPermission})}
							onClick={() => this.onClick(typeId, hasPermission)}
							onMouseOver={(event) => this.openTooltip(event, hasPermission, index, type.name)}
							onMouseLeave={this.closeTooltip}
							key={index}
						>
							<Initials
								name={type.name}
								color={type.settings.color.hex}
							/>
							<div className="BoundaryTypeName">{type.name}</div>
						</div>
					);
				})}
				{isToolTipOpen && (
					<DomPortal destination={this.modalContainer}>
						<InfoBubble
							divRef={this._floating}
							content={content}
							style={inlineStyle}
							className={ReactUtils.cls("SpaceItem", {nameToolTip: typeof content === "string"})}
						/>
					</DomPortal>
				)}
			</div>
		);
	}
}

import type {IError, TransportLayer} from "../TransportLayer";
import type {AccessTokenDetailsDto, SignInRequest, OrganizationDto, SwitchSignInRequest, UserDto} from "../../generated/api/base";
import {XHRLoader} from "../../utils/loader/XHRLoader";
import {Cookies} from "../../utils/device/Cookies";
import {Organization} from "../models/Organization";
import {XyiconFeature} from "../../generated/api/base";
import {NavigationEnum} from "../../Enums";
import {StringUtils} from "../../utils/data/string/StringUtils";
import type {Space} from "../models/Space";
import {FileUtils} from "../../utils/file/FileUtils";
import type {SupportedFontName} from "../state/AppStateTypes";

type FontPromiseType = {
	arrayBuffer: Promise<ArrayBuffer>;
	base64: Promise<string>;
};

export class AuthService {
	public static readonly loginConst = {
		clientId: "SRV4Developer",
		clientSecret: "Je6DqN0pmFNijjdyLAm34G552bcRfSR80jbsM5vv",
		cookieName: "authorizationData",
		permissionCacheCookie: "permissionCache",
	};

	public pathToRedirect = "";

	private _transport: TransportLayer;
	private _authData: AccessTokenDetailsDto = {};

	private _currentlySwitching = false;
	private _nextPortfolioId = "";
	private _nextSwitchResolve: () => void;

	constructor(transport: TransportLayer) {
		this._transport = transport;
	}

	public async logout() {
		await this._transport.signalR.stop();

		// If the current access token is still valid, tell the server to delete it - but we aren't very concerned with the result.
		// NOTE: We have to add the auth header here because the access_token will be null by the time the authInterceptorService is invoked.
		const authData = this._authData;

		if (authData.expiresAt && new Date(authData.expiresAt).getTime() > Date.now()) {
			const headers = {
				"Content-Type": "application/json",
				Authorization: `${authData.tokenType} ${authData.accessToken}`,
			};

			try {
				await this._transport.request({
					url: "auth/signout",
					headers: headers,
					method: XHRLoader.METHOD_POST,
				});
			} catch (error) {
				console.warn(error);
			}
		}

		this.cleanAuth();

		// after logout, redirect to login page
		location.reload();
	}

	public loginPassword(user: string, password: string) {
		let result = {
			error: "",
		};

		try {
			this.cleanAuth();

			const loginData: SignInRequest = {
				grantType: "password",
				username: user,
				password: password,
				clientID: this.loginConst.clientId,
				clientSecret: this.loginConst.clientSecret,
			};

			return this.login(loginData);
		} catch (error) {
			result.error = this.getErrorMessage(error);
		}

		return result;
	}

	private getErrorMessage(error: any) {
		const err = error?.result as IError;

		return err?.ErrorMessage || "Unknown error.";
	}

	private loginToken(refreshToken: string) {
		const loginData = this.getLoginTokenData(refreshToken);

		return this.login(loginData);
	}

	private getLoginTokenData(refreshToken: string) {
		const loginData: SignInRequest = {
			grantType: "refresh_token",
			refreshToken: refreshToken,
			clientID: this.loginConst.clientId,
			clientSecret: this.loginConst.clientSecret,
		};

		return loginData;
	}

	private async login(loginData: SignInRequest) {
		const result = {
			permissions: null as {},
			error: "",
		};

		try {
			const signInResult = await this.signIn(loginData);

			this.updateTokens(signInResult);
			this._transport.appState.portfolioId = this._authData.portfolioID;

			await this.loadInitialData();
			this.loginComplete();
		} catch (error) {
			result.error = this.getErrorMessage(error);
		}

		return result;
	}

	private async signIn(loginData: SignInRequest) {
		const signInResult = await XHRLoader.loadAsync<AccessTokenDetailsDto>({
			url: `${this._transport.apiUrl}/auth/signin`,
			method: XHRLoader.METHOD_POST,
			params: loginData,
			json: true,
		});

		// If there are no portfolios in this organization, the backend sends a "valid" guid full of 0s, like below:
		// In this case, we replace that with an empty string instead, so it's easier to check
		if (signInResult.portfolioID === "00000000-0000-0000-0000-000000000000") {
			signInResult.portfolioID = "";
		}

		return signInResult;
	}

	private async initOrganizations() {
		const {result: organizationDataArray, error} = await this._transport.get<OrganizationDto[]>({url: "organizations/all"});

		this._transport.appState.organizationId = this._authData.organizationID;
		this._transport.appState.organizations = [];
		for (const organizationData of organizationDataArray) {
			const organization = new Organization(organizationData, this._transport.appState);

			this._transport.appState.organizations.push(organization);
		}

		if (!this._transport.appState.organizationId) {
			throw new Error("Organization not found!");
		}
	}

	public tryAutoLogin() {
		const localCredential: AccessTokenDetailsDto = this._localStorage.get(this.loginConst.cookieName);

		if (this.isValidCredential(localCredential)) {
			this._authData.refreshToken = localCredential.refreshToken;
			return this.loginToken(localCredential.refreshToken);
		} else {
			return {
				error: "Couldn't log in",
			};
		}
	}

	public async switchPortfolio(portfolioId: string, force = false) {
		if (!force && portfolioId === this._transport.appState.portfolioId) {
			return;
		}

		this._transport.signalR.listener.closeAllValidationRuleNotifications();

		// Only allow one switch request to be ongoing at any time (causes api errors otherwise)
		if (this._currentlySwitching) {
			// there is one request currently, save the next portfolioId
			this._nextPortfolioId = portfolioId;
			return new Promise<void>((resolve) => {
				this._nextSwitchResolve = resolve;
			});
		} else {
			// Clear lists synchronously (immediately), otherwise this can be called after the list is loaded actually
			// TODO Note: what if the list is loaded before portfolio switch is complete?
			// This is now done before setting appState.portfolioId as that can be observed (eg. DetailsTab)
			this._actions.clearPortfolioLists();

			this._currentlySwitching = true;
			this._transport.appState.portfolioId = portfolioId;
			this._transport.services.localStorage.set(this._transport.appState.actions.getKeyForLocalStorageSelectedPortfolio(), portfolioId);

			try {
				this._transport.signalR.stop();

				const params: SwitchSignInRequest = {
					portfolioID: portfolioId,
				};

				const {result, error} = await this._transport.requestForOrganization<AccessTokenDetailsDto>({
					url: "auth/switch",
					method: XHRLoader.METHOD_POST,
					params: params,
					json: true,
				});

				// TODO handle status 401
				if (!result) {
					this.logout();
					return;
				}

				this.updateTokens(result);
			} catch (e) {
				console.warn(e?.message || e);
				// TODO try again if not successful
			}

			this._currentlySwitching = false;

			// After portfolio switch, signalR needs to be restarted
			this._transport.signalR.start(this._authData.accessToken);

			// Handle the latest switchPortfolio() call that arrived during the api request
			if (this._nextPortfolioId && this._nextPortfolioId !== this._transport.appState.portfolioId) {
				this.switchPortfolio(this._nextPortfolioId).then(() => {
					this._nextSwitchResolve?.();
					this._nextSwitchResolve = null;
				});
				this._nextPortfolioId = "";
			}

			if (this._transport.appState.selectedFeature === XyiconFeature.SpaceEditor && this._transport.appState.currentUIVersion === "5.0") {
				await this._transport.appState.app.transport.services.feature.refreshList(XyiconFeature.Space, true);

				const spaceList = this._transport.appState.app.transport.appState.actions
					.getList(XyiconFeature.Space)
					.toSorted((a: Space, b: Space) => StringUtils.sortIgnoreCase(a.name, b.name));
				const spaceItem = spaceList[0];

				if (spaceItem) {
					this._transport.appState.app.transport.appState.app.navigation.goApp(NavigationEnum.NAV_SPACE, spaceItem.id);
				} else {
					this._transport.appState.app.transport.appState.app.navigation.goApp(NavigationEnum.NAV_SPACES);
				}
			}
		}
	}

	public async switchOrganization(organizationId: string) {
		try {
			const params: SwitchSignInRequest = {
				organizationID: organizationId,
			};

			const {result, error} = await this._transport.request<AccessTokenDetailsDto>({
				url: "auth/switch",
				method: XHRLoader.METHOD_POST,
				params: params,
				json: true,
			});

			const savedPortfolioId = this._transport.services.localStorage.get(this._actions.getKeyForLocalStorageSelectedPortfolio());

			if (savedPortfolioId) {
				result.portfolioID = savedPortfolioId;
			}

			if (result) {
				this.updateTokens(result);
				this._transport.signals.switchOrganization.dispatch();
			}
		} catch (e) {
			console.warn(e);
			// TODO try again if not successful
		}
	}

	private isValidCredential(credential: AccessTokenDetailsDto) {
		let isValid = false;

		if (credential && credential.refreshToken && credential.accessToken && credential.expiresAt) {
			//const accessTokenExpiration = new Date(credential.expiresAt);
			//if (accessTokenExpiration.getTime() > Date.now())
			{
				isValid = true;
			}
		}

		return isValid;
	}

	private saveCookie() {
		if (this._authData.accessToken) {
			Cookies.setCookie("BearerToken", this._authData.accessToken);
		} else {
			Cookies.eraseCookie("BearerToken");
		}

		this._localStorage.set(this.loginConst.cookieName, this._authData);
	}

	private loginComplete() {
		this._transport.signalR.start(this._authData.accessToken);

		setTimeout(() => {
			this.refreshToken();
		}, this.calculateRefreshDelay());
	}

	private async loadInitialData() {
		// Load users first to be able to determine if user is admin (signin doesn't contain this information)
		await this.loadUsers();

		const hash = location.hash;
		const pageUrl: NavigationEnum = hash?.split("/")[1] as NavigationEnum;

		await Promise.all([this.loadFields(), this.loadTypes(), this.initOrganizations()]);

		// layouts should only be loaded after fields are loaded
		// views should only be loaded after types are loaded
		// eg.: spaceeditorviews refer to types to migrate layers/filters, like removing type-related rules for types that don't exist anymore
		// Organizations should be loader before views, because viewfolderstructure uses the currently used organization id as a key in its data structure

		await Promise.all([this.loadViews(), this.loadLayouts(), this.loadLists()]);
	}

	public loadSecondaryList() {
		// The list loaded here is loaded soon after logging in.
		// It's not required before logging in, but useful to load right after login

		// Xyicon, Catalog are loaded so Settings / Catalog field delete can check if the field is used before delete

		return Promise.all([
			this.loadFonts(),
			this._transport.services.feature.refreshList(XyiconFeature.XyiconCatalog),
			this._transport.services.feature.refreshList(XyiconFeature.Xyicon),
			this._transport.services.feature.refreshList(XyiconFeature.Boundary),
			this._transport.services.feature.refreshList(XyiconFeature.PortfolioDocument),
			this._transport.services.feature.refreshList(XyiconFeature.OrganizationDocument),
			this._transport.services.feature.refreshList(XyiconFeature.Dashboard),
		]);
	}

	private async loadUsers() {
		await Promise.all([
			this._transport.services.feature.refreshList(XyiconFeature.User, true),
			this._transport.services.feature.refreshList(XyiconFeature.ExternalUser, true),
		]);

		if (!this._transport.appState.user && this._authData.userID) {
			this._transport.appState.user = this._actions.findUser(this._authData.userID);
		}

		const currentUser = this._transport.appState.user;

		if (currentUser) {
			const {result, error} = await this._transport.requestForOrganization<UserDto>({
				url: "currentuser",
				method: XHRLoader.METHOD_GET,
			});

			currentUser.applyData(result);
		} else {
			console.warn(`No user is found with the following id: ${this._authData.userID}`);
		}
	}

	private loadTypes() {
		return this._transport.services.typefield.populateFullList("types");
	}

	private loadFields() {
		return this._transport.services.typefield.populateFullList("fields");
	}

	private loadLayouts() {
		return Promise.all([this._transport.services.typefield.refreshMapping(), this._transport.services.fieldLayout.refreshLayouts()]);
	}

	private async loadFonts() {
		const fontNamesAndFiles: {
			[key in SupportedFontName]: string;
		} = {
			Arial: "Arial-Regular",
			Georgia: "Georgia-Regular",
			OpenSans: "OpenSans-Regular",
			Roboto: "Roboto-Regular",
			Lobster: "Lobster-Regular",
			Caveat: "Caveat-Regular",
			SourceCodePro: "SourceCodePro-Regular",
		};

		const fonts: {
			[key in SupportedFontName]: FontPromiseType;
		} = {
			Arial: {
				arrayBuffer: null,
				base64: null,
			},
			Georgia: {
				arrayBuffer: null,
				base64: null,
			},
			OpenSans: {
				arrayBuffer: null,
				base64: null,
			},
			Roboto: {
				arrayBuffer: null,
				base64: null,
			},
			Lobster: {
				arrayBuffer: null,
				base64: null,
			},
			Caveat: {
				arrayBuffer: null,
				base64: null,
			},
			SourceCodePro: {
				arrayBuffer: null,
				base64: null,
			},
		};

		const fontNames = Object.keys(fontNamesAndFiles) as SupportedFontName[];

		for (const fontName of fontNames) {
			fonts[fontName].arrayBuffer = FileUtils.fetchFileAsArrayBuffer(`src/assets/fonts/${fontName}/${fontNamesAndFiles[fontName]}.ttf`);
		}

		const appState = this._transport.appState;

		for (const fontName of fontNames) {
			appState.fonts[fontName] = {} as unknown as any;
			appState.fonts[fontName].arrayBuffer = await fonts[fontName].arrayBuffer;
			appState.fonts[fontName].base64 = FileUtils.arrayBuffer2Base64(appState.fonts[fontName].arrayBuffer);
		}

		return appState.fonts;
	}

	private loadLists() {
		const lists: Promise<any>[] = [
			this._transport.services.feature.refreshList(XyiconFeature.Portfolio),
			this._transport.services.feature.refreshList(XyiconFeature.PortfolioGroup),
			this._transport.services.feature.refreshList(XyiconFeature.PermissionSet),
			this._transport.services.feature.refreshList(XyiconFeature.UserGroup),
		];

		return Promise.all(lists);
	}

	public loadViews() {
		return this._transport.services.view.loadAllViews();
	}

	private refreshToken = async () => {
		if (this._transport.appState.user) {
			try {
				const loginData = this.getLoginTokenData(this._authData.refreshToken);
				const result = await this.signIn(loginData);

				if (result && result.refreshToken) {
					this.updateTokens(result);

					this._transport.signalR.start(this._authData.accessToken);

					setTimeout(this.refreshToken, this.calculateRefreshDelay());
					console.log("token refreshed");
				} else {
					await this.logout();
				}
			} catch (e) {
				await this.logout();
			}
		}
	};

	private updateTokens(authData: AccessTokenDetailsDto) {
		this._authData = authData;

		const refreshTokenExpiresAtDate = new Date(new Date().getTime() + authData.expiresIn * 1000);

		this._localStorage.set("refreshToken", authData.refreshToken);
		this._localStorage.set("refreshTokenExpiresAt", this.convertDate(refreshTokenExpiresAtDate));

		this._authData.refreshToken = authData.refreshToken;
		this._authData.accessToken = authData.accessToken;
		this._authData.portfolioID = authData.portfolioID;

		this.saveCookie();
	}

	private getRefreshTokenExpiresAt() {
		return this._localStorage.get("refreshTokenExpiresAt");
	}

	private convertDate(date: Date) {
		return date.toISOString();
	}

	private calculateRefreshDelay() {
		// To disable token refresh:
		// if(1) return 99999999999999999;

		let result = 0.5 * 60 * 1000; // 0.5 minute default

		const refreshTokenAt = this.getRefreshTokenExpiresAt();

		if (refreshTokenAt) {
			const refreshTokenDate = new Date(refreshTokenAt);
			const refreshTokenTime = refreshTokenDate.getTime();

			if (!isNaN(refreshTokenTime)) {
				const diff = refreshTokenTime - new Date().getTime();
				const twoMinutesInMs = 2 * 60 * 1000;

				result = Math.max(10000, diff - twoMinutesInMs);
			}
		}

		console.log(`refreshing in:  ${Math.round(result / 1000)} seconds.`);
		return result;
	}

	public cleanAuth() {
		this._localStorage.remove(this.loginConst.cookieName);
		this._localStorage.remove(this.loginConst.permissionCacheCookie);
		Cookies.eraseCookie("BearerToken");

		this._localStorage.remove("refreshToken");
		this._localStorage.remove("refreshTokenExpiresAt");

		this._authData = {};
		this._transport.appState.user = null;
	}

	private get _localStorage() {
		return this._transport.services.localStorage;
	}

	private get _actions() {
		return this._transport.appState.actions;
	}

	public get authData() {
		return this._authData;
	}

	public get loginConst() {
		return this._transport.config.app || AuthService.loginConst;
	}
}

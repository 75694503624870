import type {InstancedMesh, Mesh, Object3D} from "three";
import {observable, makeObservable} from "mobx";
import type {SpaceViewRenderer} from "../../renderers/SpaceViewRenderer";
import type {GrabbableCorner} from "../../elements3d/GrabbableCorner";
import type {SpaceItem} from "../../elements3d/SpaceItem";
import type {Xyicon3D} from "../../elements3d/Xyicon3D";
import type {BoundarySpaceMap3D} from "../../elements3d/BoundarySpaceMap3D";
import type {Markup3D} from "../../elements3d/markups/abstract/Markup3D";
import {BoundingBox} from "../../elements3d/BoundingBox";
import {LinkLineManager} from "../LinkLineManager";
import {SnapToGridManager} from "../SnapToGridManager";
import {MarkupTextManager} from "../MSDF/MarkupTextManager";
import {CaptionCollisionSolver} from "../../features/CaptionCollisionSolver";
import type {Xyicon} from "../../../../../../../data/models/Xyicon";
import type {Boundary} from "../../../../../../../data/models/Boundary";
import type {Markup} from "../../../../../../../data/models/Markup";
import type {ISpaceItemModel} from "../../../../../../../data/models/Model";
import type {BoundarySpaceMap} from "../../../../../../../data/models/BoundarySpaceMap";
import type {IFilterState} from "../../../../../../../data/models/filter/Filter";
import {createFilterState, filterModels} from "../../../../../../../data/models/filter/Filter";
import type {Dimensions, PointDouble, PortTemplateDto} from "../../../../../../../generated/api/base";
import {XyiconFeature, Permission} from "../../../../../../../generated/api/base";
import {Signal} from "../../../../../../../utils/signal/Signal";
import {KeyboardListener} from "../../../../../../../utils/interaction/key/KeyboardListener";
import {PopupUtils} from "../../../../../abstract/popups/PopupUtils";
import {DebugInformation} from "../../../../../../../utils/DebugInformation";
import {MathUtils} from "../../../../../../../utils/math/MathUtils";
import {PopupUtilsV5} from "../../../../../../5.0/popup/PopupUtilsV5";
import {LinkIconManager} from "./icons/LinkIconManager";
import {RaycasterManager} from "./RaycasterManager";
import {RotationIconManager} from "./icons/RotationIconManager";
import {MarkupManager} from "./MarkupManager";
import {BoundaryManager} from "./BoundaryManager";
import {XyiconManager} from "./XyiconManager";

/**
 * This class contains the managers for items that can appear on the spaceeditor
 */

export class SpaceItemController {
	private _spaceViewRenderer: SpaceViewRenderer;
	private _xyiconManager: XyiconManager;
	private _boundaryManager: BoundaryManager;
	private _markupManager: MarkupManager;
	private _boundingBox: BoundingBox;
	private _isTranslating: boolean = false;
	private _rotationIconManager: RotationIconManager;
	private _linkIconManager: LinkIconManager;
	private _linkLineManager: LinkLineManager;
	private _raycasterManager: RaycasterManager;
	private _snapToGridManager: SnapToGridManager;
	private _savedCursorPos: PointDouble = {x: null, y: null};
	private _savedBBoxPos: PointDouble = {x: null, y: null};
	private _delta: Dimensions = {x: 0, y: 0, z: 0};
	private _markupTextManager: MarkupTextManager;
	private _captionCollisionSolver: CaptionCollisionSolver;
	private _previousSelectedItemIds: string = "";

	@observable
	public filterState: IFilterState = createFilterState();

	private readonly _directions: {[key: string]: PointDouble} = {
		[KeyboardListener.KEY_ARROW_UP]: {x: 0, y: 1},
		[KeyboardListener.KEY_ARROW_RIGHT]: {x: 1, y: 0},
		[KeyboardListener.KEY_ARROW_DOWN]: {x: 0, y: -1},
		[KeyboardListener.KEY_ARROW_LEFT]: {x: -1, y: 0},
	};
	private _isPopupOpen: boolean = false;
	public signals = {
		filterUpdated: Signal.create(),
		itemsTranslated: Signal.create(),
		itemsRotated: Signal.create(),
		editedItemModified: Signal.create(),
		actionBarUpdated: Signal.create(),
	};

	constructor(spaceViewRenderer: SpaceViewRenderer) {
		makeObservable(this);
		this._spaceViewRenderer = spaceViewRenderer;

		this._rotationIconManager = new RotationIconManager(this._spaceViewRenderer);
		this._linkIconManager = new LinkIconManager(this._spaceViewRenderer);
		this._linkLineManager = new LinkLineManager(this._spaceViewRenderer);
		this._raycasterManager = new RaycasterManager(this._spaceViewRenderer);
		this._snapToGridManager = new SnapToGridManager(this._spaceViewRenderer);

		this._markupManager = new MarkupManager(this._spaceViewRenderer);
		this._xyiconManager = new XyiconManager(this._spaceViewRenderer);
		this._boundaryManager = new BoundaryManager(this._spaceViewRenderer);

		this._captionCollisionSolver = new CaptionCollisionSolver(this._spaceViewRenderer);

		this._boundingBox = new BoundingBox(this._spaceViewRenderer);
	}

	private get _inheritedMethods() {
		return this._spaceViewRenderer.inheritedMethods;
	}

	public async init(xyicons: Xyicon[], boundaries: Boundary[], markups: Markup[]) {
		this._snapToGridManager.init();
		this._spaceViewRenderer.toolManager.cameraControls.scrollbars.init();
		this._rotationIconManager.init();
		this._linkIconManager.init();
		this._boundingBox.initZIndex();
		this._markupManager.init();
		this._xyiconManager.init();
		this._boundaryManager.init();

		await this.populateData(xyicons, boundaries, markups);

		this._markupManager.signals.itemsRemove.add(this.onMarkupsRemoved);

		this._linkIconManager.update();

		KeyboardListener.getInstance().signals.down.add(this.onKeyDown);
		KeyboardListener.getInstance().signals.up.add(this.onKeyUp);
		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.add(this.onCameraZoomChange);
		this.onCameraZoomChange();
	}

	private onMarkupsRemoved = (markups: Markup[]) => {
		this._markupTextManager.recreateGeometry();
	};

	private onKeyDown = (event: KeyboardEvent) => {
		switch (event.key) {
			case "c":
				if (!event.ctrlKey) {
					this._boundaryManager.hideCaptions();
					this._xyiconManager.hideCaptions();
				}
				break;
		}
	};

	private onKeyUp = (event: KeyboardEvent) => {
		switch (event.key) {
			case "c":
				this._xyiconManager.showCaptions();
				this._boundaryManager.showCaptions();
				break;
		}

		const directionNames = [
			KeyboardListener.KEY_ARROW_UP,
			KeyboardListener.KEY_ARROW_RIGHT,
			KeyboardListener.KEY_ARROW_DOWN,
			KeyboardListener.KEY_ARROW_LEFT,
		];
		const selectedItems = this.selectedItems;

		if (directionNames.includes(event.key) && selectedItems.length > 0) {
			const correctionMultiplier = this._spaceViewRenderer.correctionMultiplier.current;
			const cameraZoomFactor = this._spaceViewRenderer.currentZoomValue;
			const multiplier = (correctionMultiplier * (KeyboardListener.isShiftDown ? 10 : 1)) / cameraZoomFactor;

			const finalDirection = {...this._directions[event.key]};

			finalDirection.x *= multiplier;
			finalDirection.y *= multiplier;

			const wasSnapToGridActive = this._snapToGridManager.isActive;

			this._snapToGridManager.deactivate();

			const bboxPos = this._boundingBox.position;

			this.startTranslatingSelectedItems(bboxPos.x, bboxPos.y);
			this.translateSelectedItems(finalDirection.x, finalDirection.y, 0);
			this.stopTranslatingSelectedItems();

			if (wasSnapToGridActive) {
				this._snapToGridManager.activate();
			}
		}
	};

	private onCameraZoomChange = () => {
		this._rotationIconManager.updateTransformations();
		this._raycasterManager.updateLineThreshold();
	};

	public getMeshAtCoords(domX: number, domY: number, intersectables?: Object3D[], excludedSpaceItemIds: string[] = []) {
		return this._raycasterManager.getMeshAtCoords(domX, domY, intersectables, excludedSpaceItemIds);
	}

	public clearData() {
		this.filterState = createFilterState();
		this.deselectAll();
		this._xyiconManager.clear();
		this._boundaryManager.clear();
		this._markupManager.clear();
		this._rotationIconManager.clear();

		KeyboardListener.getInstance().signals.down.remove(this.onKeyDown);
		KeyboardListener.getInstance().signals.up.remove(this.onKeyUp);
		this._markupManager.signals.itemsRemove.remove(this.onMarkupsRemoved);
		this._spaceViewRenderer.toolManager.cameraControls.signals.cameraZoomChange.remove(this.onCameraZoomChange);
		this._markupTextManager?.dispose();
	}

	private async populateData(xyicons: Xyicon[], boundaries: Boundary[], markups: Markup[]) {
		const xyiconLogId = "Putting xyicons on the space";
		const boundaryLogId = "Putting boundaries on the space";
		const markupLogId = "Putting markups on the space";

		DebugInformation.start(xyiconLogId);
		await this._xyiconManager.initXyicons(xyicons);
		DebugInformation.end(xyiconLogId);

		DebugInformation.start(boundaryLogId);
		await this._boundaryManager.initBoundaries(boundaries);
		DebugInformation.end(boundaryLogId);

		DebugInformation.start(markupLogId);
		this._markupManager.initMarkups(markups);
		await this.loadMarkupTexts();
		DebugInformation.end(markupLogId);
	}

	private async loadMarkupTexts() {
		this._markupTextManager?.clear();
		this._markupTextManager = new MarkupTextManager(this._spaceViewRenderer);
		await this._markupTextManager.init();
	}

	public deselectAll(updateUI: boolean = true, closeEmbeddedWindow: boolean = true, unfocusAll: boolean = true) {
		this._xyiconManager.deselectAll();
		this._boundaryManager.deselectAll();
		this._markupManager.deselectAll();
		if (unfocusAll) {
			this._inheritedMethods.focusItems([]);
		}
		this._rotationIconManager.removeIcons();
		this._boundingBox.reset();

		if (updateUI) {
			this.closeActionBar();
			this.closePortSelector();
			this.closeContextMenu();
			this.closeLinks();
			if (closeEmbeddedWindow) {
				this.closeEmbeddedWindow();
			}
			this.updateDetailsPanel(true);
		}
	}

	public getSpaceItemFromMeshAtCoords(meshAtCoords: Mesh | InstancedMesh): Xyicon3D | BoundarySpaceMap3D | Markup3D {
		return this._raycasterManager.getSpaceItemFromMeshAtCoords(meshAtCoords) as Xyicon3D | BoundarySpaceMap3D | Markup3D;
	}

	/**
	 * Saves current pos of selected items
	 * xy: current worldpos of cursor
	 */
	public startTranslatingSelectedItems(startX: number, startY: number) {
		this._isTranslating = true;
		this._rotationIconManager.removeIcons();
		this._xyiconManager.startTranslatingSelectedItems();
		this._boundaryManager.startTranslatingSelectedItems();
		this._markupManager.startTranslatingSelectedItems();
		this.updateBoundingBox();
		this._savedCursorPos.x = startX;
		this._savedCursorPos.y = startY;

		const bbox = this._boundingBox.boundingBox;

		// we agreed that we should snap the top left corner of the selectionbox, not the center
		this._savedBBoxPos.x = bbox.min.x;
		this._savedBBoxPos.y = bbox.max.y;

		this.closeEmbeddedWindow();

		if (this._markupManager.selectedItems.length > 0) {
			this._markupTextManager.updateTextTransformations();
		}
	}

	/**
	 * // world coordinates, measured from pointerStart (NOT from the last pointerposition) -> this way it's more reliable
	 * @param x
	 * @param y
	 */
	public translateSelectedItems(x: number, y: number, z: number) {
		this._delta.x = x;
		this._delta.y = y;
		this._delta.z = z;

		if (this._snapToGridManager.isActive) {
			const cursorPosToBBoxPos = {
				x: this._savedBBoxPos.x - this._savedCursorPos.x,
				y: this._savedBBoxPos.y - this._savedCursorPos.y,
			};

			const finalPos = this._snapToGridManager.getUpdatedCoords(
				this._savedCursorPos.x + x + cursorPosToBBoxPos.x,
				this._savedCursorPos.y + y + cursorPosToBBoxPos.y,
			);

			this._delta.x = finalPos.x - this._savedBBoxPos.x;
			this._delta.y = finalPos.y - this._savedBBoxPos.y;
		}

		// Z translate is only supported for xyicons at the moment
		this._xyiconManager.translateSelectedItems(this._delta.x, this._delta.y, this._delta.z);
		this._boundaryManager.translateSelectedItems(this._delta.x, this._delta.y, 0);
		this._markupManager.translateSelectedItems(this._delta.x, this._delta.y, 0);
		this._spaceViewRenderer.needsRender = true;
		this.closeActionBar();
		this.closePortSelector();
		this.closeContextMenu();
		this.updateBoundingBox();

		if (this._markupManager.selectedItems.length > 0) {
			this._markupTextManager.updateTextTransformations();
		}

		this.signals.itemsTranslated.dispatch();
	}

	public stopTranslatingSelectedItems() {
		if (this._isTranslating) {
			this._xyiconManager.stopTranslatingSelectedItems();
			this._boundaryManager.stopTranslatingSelectedItems();
			this._markupManager.stopTranslatingSelectedItems();
			this._isTranslating = false;

			if (this._delta.x !== 0 || this._delta.y !== 0) {
				this.fixCaptionCollisionsForSelected();
			}
		}
	}

	public updateActionBar(selectDetailsTab: boolean = true, updateDetailsPanel: boolean = true) {
		const selectedItems = this.selectedItems;

		if (!this._spaceViewRenderer.toolManager.isInLinkMode && selectedItems.length > 0) {
			if (!this._rotationIconManager.isRotationModeOn) {
				const isAllEmbedded = selectedItems.every(
					(spaceItem: SpaceItem) => spaceItem.spaceItemType === "xyicon" && (spaceItem.modelData as Xyicon).isEmbedded,
				);

				if (isAllEmbedded) {
					this.closeActionBar();
				} else {
					this.updateBoundingBox();

					const worldX = this._boundingBox.position.x;
					const worldY = this._boundingBox.boundingBox.max.y;

					// adding 15 + 15 px additional height
					const additionalHeight = 30 * this._spaceViewRenderer.correctionMultiplier.current;

					const hasPermissionToOpenActionBar = this._spaceViewRenderer.actions.someSpaceItemsHaveGivenPermission(selectedItems, Permission.Update);

					if (hasPermissionToOpenActionBar) {
						this._inheritedMethods.openActionBar(worldX, worldY + additionalHeight);
						this.signals.actionBarUpdated.dispatch();
					}
				}
			}
		} else {
			this.closeActionBar();
			if (!this._spaceViewRenderer.toolManager.linkManager.isInLinkMode) {
				this.closePortSelector();
			}
			this._boundingBox.reset();
		}

		if (updateDetailsPanel) {
			this.updateDetailsPanel(selectDetailsTab);
		}
	}

	public openPortSelector(worldX: number, worldY: number, item: Xyicon, ports: PortTemplateDto[], type: "from" | "to") {
		this._inheritedMethods.openPortSelector(worldX, worldY, item, ports, type);
	}

	public closeActionBar() {
		this._inheritedMethods?.closeActionBar();
		this.signals.actionBarUpdated.dispatch();
	}

	public closeContextMenu() {
		this._inheritedMethods?.closeContextMenu();
	}

	public closePortSelector() {
		this._inheritedMethods?.closePortSelector();
	}

	public openLinks(fromObjectIds: string[], changeSelection: boolean) {
		if (changeSelection) {
			this.deselectAll();
			const xyiconsToSelect = fromObjectIds.map((fromObjectId: string) => this._xyiconManager.getItemById(fromObjectId) as Xyicon3D);

			for (const xyiconToSelect of xyiconsToSelect) {
				if (xyiconToSelect?.isVisible) {
					xyiconToSelect.select();
				}
			}

			this.updateDetailsPanel(true);
		}
		this._inheritedMethods?.openLinkBreakers(fromObjectIds);
		this._inheritedMethods?.openLinkedXyiconsWindow(fromObjectIds, "external");
	}

	public updateLinkBreakers() {
		this._inheritedMethods?.updateLinkBreakers();
	}

	public closeLinks() {
		this._inheritedMethods?.closeLinkBreakers();
	}

	public updateEmbeddedWindow(fromObjectId: string) {
		this._inheritedMethods?.openLinkedXyiconsWindow([fromObjectId], "embedded");
	}

	private closeEmbeddedWindow() {
		this._inheritedMethods?.closeLinkedXyiconsWindow();
	}

	public updateDetailsPanel(selectDetailsTab?: boolean, shouldFocusItem?: boolean) {
		if (this._inheritedMethods?.selectItems) {
			const selectedItems = this.selectedItems;
			const selectedItemIds = selectedItems.map((item) => item.id).join();
			const selectedItemModels: ISpaceItemModel[] = selectedItems.map((item: SpaceItem) => item.modelData) as ISpaceItemModel[];
			const filteredSelectedItemModels = selectedItemModels.filter((model) => !!model);

			if (selectedItemModels.length !== filteredSelectedItemModels.length) {
				console.warn("Some selected items are missing modelData. Please report this to the developers.");
			}
			const forceUpdate = this._previousSelectedItemIds !== selectedItemIds;

			this._inheritedMethods.selectItems(filteredSelectedItemModels, selectDetailsTab, forceUpdate);
			if (shouldFocusItem) {
				this._inheritedMethods.focusItems(filteredSelectedItemModels);
			}
			this._previousSelectedItemIds = selectedItemIds;
		}
	}

	public updateBoundingBox() {
		const xyiconBBox = this._xyiconManager.selectionBox.boundingBox;
		const boundaryBBox = this._boundaryManager.selectionBox.boundingBox;
		const markupBBox = this._markupManager.selectionBox.boundingBox;

		this._boundingBox.setFromBoundingBoxes([xyiconBBox, boundaryBBox, markupBBox]);

		if (this.isInEditMode) {
			this._boundingBox.hide();
		} else {
			this._boundingBox.show();
		}
	}

	private getNumberOfSelectedItems() {
		const {selectedItems} = this;

		let numberOfEmbeddedXyicons = 0;

		for (const selectedItem of selectedItems) {
			if (selectedItem.spaceItemType === "xyicon") {
				const xyicon = selectedItem.modelData as Xyicon;

				numberOfEmbeddedXyicons += xyicon.embeddedXyicons.length;
			}
		}

		return selectedItems.length + numberOfEmbeddedXyicons;
	}

	public onDeleteClick = async () => {
		if (!this._isPopupOpen) {
			const numberOfSelectedItems = this.getNumberOfSelectedItems();

			if (numberOfSelectedItems > 0) {
				this._isPopupOpen = true;

				const getDeleteConfirmationPopup =
					this._spaceViewRenderer.transport.appState.currentUIVersion == "5.0"
						? PopupUtilsV5.getDeleteConfirmationPopupV5
						: PopupUtils.getDeleteConfirmationPopup;

				const confirmed = await getDeleteConfirmationPopup(
					[XyiconFeature.Xyicon, XyiconFeature.Boundary, XyiconFeature.Markup],
					numberOfSelectedItems,
				);

				this._isPopupOpen = false;
				if (confirmed) {
					this.deleteSelectedItems();
					this.closeActionBar();
					this.closePortSelector();
				}
			}
		}
	};

	private async deleteSelectedItems() {
		const linksNeedUpdate = this._xyiconManager.selectedItems.some((xyicon: Xyicon3D) => xyicon.linkInstanceId > -1);

		const promises = [
			this._xyiconManager.deleteSelectedItems(),
			this._boundaryManager.deleteSelectedItems(),
			this._markupManager.deleteSelectedItems(),
		];

		this._boundingBox.reset();
		this.updateDetailsPanel(true);

		await Promise.all(promises);
		if (linksNeedUpdate) {
			this._linkIconManager.update();
		}
	}

	public selectItemsInsideRectangle(smallerX: number, smallerY: number, largerX: number, largerY: number) {
		this._xyiconManager.selectItemsInsideRectangle(smallerX, smallerY, largerX, largerY);
		this._boundaryManager.selectItemsInsideRectangle(smallerX, smallerY, largerX, largerY);
		this._markupManager.selectItemsInsideRectangle(smallerX, smallerY, largerX, largerY);

		this.updateDetailsPanel(true);

		// to render on top
		this.updateMarkupTextsForSelected();
	}

	private updateMarkupTextsForSelected() {
		if (this._markupManager.selectedItems.length > 0) {
			this._markupTextManager.updateTextTransformations();
		}
	}

	public startRotatingSelectedItems(pivot?: PointDouble) {
		this._xyiconManager.startRotatingSelectedItems(pivot);
		this._boundaryManager.startRotatingSelectedItems(pivot);
		this._markupManager.startRotatingSelectedItems(pivot);
		this._xyiconManager.hideCaptionsForSelected();
		this._boundaryManager.hideCaptionsForSelected();
	}

	/**
	 * Rotates selected items
	 * @param deltaAngle delta angle in radian, related to the starting angle, NOT the previous angle
	 */
	public rotateSelectedItems(delta: number, pivot?: PointDouble) {
		const newDelta = pivot ? MathUtils.calculateNewOrientation(0, delta) : delta;

		this._xyiconManager.rotateSelectedItems(newDelta, pivot);
		this._boundaryManager.rotateSelectedItems(newDelta, pivot);
		this._markupManager.rotateSelectedItems(newDelta, pivot);

		if (pivot) {
			this._boundingBox.rotate(newDelta);
		} else {
			this._boundingBox.hide();
		}

		this._rotationIconManager.updateTransformations();
		this.updateMarkupTextsForSelected();
		this.signals.itemsRotated.dispatch();
	}

	public stopRotatingSelectedItems() {
		if (this._rotationIconManager.isRotationModeOn) {
			this._xyiconManager.showCaptionsForSelected();
			this._boundaryManager.showCaptionsForSelected();
			this._xyiconManager.stopRotatingSelectedItems();
			this._boundaryManager.stopRotatingSelectedItems();
			this._markupManager.stopRotatingSelectedItems();
			this.fixCaptionCollisionsForSelected();
			this._boundingBox.stopRotation();
			this.updateMarkupTextsForSelected();
			this.updateBoundingBox();
			this.updateActionBar();

			if ((this.currentlyEditedItem as Markup3D)?.isTextOffsetHandlerVisible) {
			} else {
				this._rotationIconManager.update();
			}
		}
	}

	private fixCaptionCollisionsForSelected() {
		if (this._xyiconManager.selectedItems.length + this._boundaryManager.selectedItems.length > 0) {
			const selectionBox = this._boundingBox;
			const scale = selectionBox.scale;
			const maxRadius = Math.max(scale.x, scale.y) + this._xyiconManager.xyiconSize * 10;
			const center = selectionBox.position;

			this._captionCollisionSolver.updateCaptionsInARadius(center.x, center.y, maxRadius);
		}
	}

	private getEditedItemsManager() {
		return this._boundaryManager.selectedItems.length === 1 ? this._boundaryManager : (this._markupManager as BoundaryManager | MarkupManager);
	}

	public switchEditMode(value: boolean) {
		this.getEditedItemsManager().switchEditMode(value);
		if (value) {
			this._boundingBox.hide();
		} else {
			this.updateBoundingBox();
			this._boundingBox.show();
			this.fixCaptionCollisionsForSelected();
		}
		this._inheritedMethods?.onEditModeSwitched(value);
	}

	public onRedrawClick() {
		const {currentlyEditedItem} = this._boundaryManager;

		if (currentlyEditedItem) {
			this._boundaryManager.currentlyRedrawnBoundary = currentlyEditedItem as BoundarySpaceMap3D;
			const modelData = currentlyEditedItem.modelData as BoundarySpaceMap;

			currentlyEditedItem.destroy(false);
			this._boundaryManager.setTypeId(modelData.typeId);
			this._inheritedMethods?.setActiveTool("boundary");
			this._boundaryManager.switchEditMode(false);
			this.closeActionBar();
		}
	}

	public onCancelEditClick() {
		this.getEditedItemsManager().cancelEditMode();
		this.updateMarkupTextsForSelected();
		this._boundingBox.show();
		this.signals.editedItemModified.dispatch();
	}

	public onGrabbableCornerPointerDown(grabbableCorner: GrabbableCorner) {
		this._boundaryManager.onGrabbableCornerPointerDown(grabbableCorner);
		this._markupManager.onGrabbableCornerPointerDown(grabbableCorner);
	}

	public onGrabbableCornerPointerMove(deltaX: number, deltaY: number) {
		this._boundaryManager.onGrabbableCornerPointerMove(deltaX, deltaY);
		this._markupManager.onGrabbableCornerPointerMove(deltaX, deltaY);
		if (this._markupManager.isInEditMode) {
			this._markupTextManager.updateTextTransformations();
		}
		this.signals.editedItemModified.dispatch();
	}

	public onGrabbableCornerPointerUp() {
		this._boundaryManager.onGrabbableCornerPointerUp();
		this._markupManager.onGrabbableCornerPointerUp();
	}

	private isModelInSpace = (spaceItem: ISpaceItemModel) => spaceItem.spaceId === this._spaceViewRenderer.space?.id;

	public getFilteredItems(): ISpaceItemModel[] {
		const {appState} = this._spaceViewRenderer.transport;
		const {actions} = appState;

		let items: ISpaceItemModel[] = [];

		if (this._spaceViewRenderer.space) {
			// #2697
			// Instead of the ItemManager.items, use the lists in the appState to fetch these items,
			// otherwise the cache values for filters will be wrong, because it's possible that the appState list
			// is already updated, but the "itemmanager.items" array is not updated yet (async)

			const boundaries: Boundary[] = actions
				.getList(XyiconFeature.Boundary)
				.filter((boundary: Boundary) => [...boundary.boundarySpaceMaps].some(this.isModelInSpace)) as Boundary[];

			const xyicons: Xyicon[] = actions.getList(XyiconFeature.Xyicon).filter(this.isModelInSpace) as Xyicon[];
			const markups: Markup[] = actions.getList(XyiconFeature.Markup).filter(this.isModelInSpace) as Markup[];

			items.push(...boundaries, ...xyicons);

			// Apply local filters
			items = filterModels(items, this.filterState, appState, XyiconFeature.SpaceEditor);

			items.push(...markups);
		}

		return items;
	}

	public async updateFilterState(filterState?: IFilterState, updateCaptions: boolean = true) {
		if (filterState) {
			this.filterState = filterState;
		}
		const filteredItems = new Set(this.getFilteredItems());

		const excludableItems = [...this._boundaryManager.items.array, ...this._xyiconManager.items.array];

		for (const item of excludableItems) {
			const itemModel = item.spaceItemType === "boundary" ? (item.modelData as BoundarySpaceMap).parent : (item.modelData as ISpaceItemModel);

			item.setVisibility(!item.layerSettings.isHidden && filteredItems.has(itemModel));
		}

		if (updateCaptions) {
			await this._boundaryManager.captionManager.updateCaptions();
			await this._xyiconManager.captionManager.updateCaptions();
		}

		this.updateActionBar(false, false);
		if (this._rotationIconManager.isRotationModeOn) {
			this._rotationIconManager.update();
		}

		this.signals.filterUpdated.dispatch();
		this._spaceViewRenderer.needsRender = true;
	}

	public get isAddingItemsToServer() {
		const {xyiconManager, boundaryManager, markupManager} = this;

		return xyiconManager.isAddingItemsToServer || boundaryManager.isAddingItemsToServer || markupManager.isAddingItemsToServer;
	}

	public get isInEditMode() {
		return this._boundaryManager.isInEditMode || this._markupManager.isInEditMode;
	}

	public get currentlyEditedItem() {
		if (this._boundaryManager.isInEditMode) {
			return this._boundaryManager.currentlyEditedItem;
		} else if (this._markupManager.isInEditMode) {
			return this._markupManager.currentlyEditedItem;
		} else {
			return null;
		}
	}

	public get xyiconManager() {
		return this._xyiconManager;
	}

	public get boundaryManager() {
		return this._boundaryManager;
	}

	public get markupManager() {
		return this._markupManager;
	}

	public get managers() {
		return [this._xyiconManager, this._boundaryManager, this._markupManager];
	}

	public get rotationIconManager() {
		return this._rotationIconManager;
	}

	public get linkIconManager() {
		return this._linkIconManager;
	}

	public get linkLineManager() {
		return this._linkLineManager;
	}

	public get snapToGridManager() {
		return this._snapToGridManager;
	}

	public get boundingBox() {
		return this._boundingBox;
	}

	public get markupTextManager() {
		return this._markupTextManager;
	}

	public get captionCollisionSolver() {
		return this._captionCollisionSolver;
	}

	public get selectedItems() {
		return [...this._xyiconManager.selectedItems, ...this._boundaryManager.selectedItems, ...this._markupManager.selectedItems];
	}
}

import * as React from "react";
import {inject, observer} from "mobx-react";
import type {IFieldColumn, Report} from "../../../../../../../data/models/Report";
import {ReportSortDirection} from "../../../../../../../data/models/Report";
import type {OutputDataSortField} from "../../../../../../../generated/api/reports";
import {ReportType} from "../../../../../../../generated/api/reports";
import {Button} from "../../../../../../widgets/button/Button";
import {SelectInput} from "../../../../../../widgets/input/select/SelectInput";
import type {AppState} from "../../../../../../../data/state/AppState";
import {IconButton} from "../../../../../../widgets/button/IconButton";
import {FieldDataType} from "../../../../../../../generated/api/base";
import {AppFieldActions} from "../../../../../../../data/state/AppFields";
import type {IFieldAdapter} from "../../../../../../../data/models/field/Field";
import {SVGIcon} from "../../../../../../widgets/button/SVGIcon";
import {StringUtils} from "../../../../../../../utils/data/string/StringUtils";

interface IReportSortOrderProps {
	readonly report: Report;
	readonly appState?: AppState;
}

@inject("appState")
@observer
export class ReportSortOrder extends React.Component<IReportSortOrderProps> {
	public static isFieldSupported(field: IFieldAdapter): boolean {
		// Temporarily disable date fields until backend api supports it (except lastModifiedAt which is supported)
		if (field?.dataType === FieldDataType.DateTime && !AppFieldActions.isLastModifiedAt(field.refId)) {
			return false;
		}
		return true;
	}

	private _sortDirections = [
		{
			id: ReportSortDirection.ASC,
			label: "Ascending",
		},
		{
			id: ReportSortDirection.DESC,
			label: "Descending",
		},
	];

	private onAddClicked = () => {
		const firstField = this.getFields()[0];

		if (firstField) {
			(this.props.report.sortFields as (OutputDataSortField & {linkedField?: boolean})[]).push({
				name: firstField.field.refId,
				direction: ReportSortDirection.ASC,
				linkedField: false,
			});
		}
	};

	private onRemove = (sortField: OutputDataSortField, linkedField?: boolean) => {
		this.props.report.removeSortField(sortField, linkedField);
	};

	private getFields() {
		const {report, appState} = this.props;
		const fieldColumns: IFieldColumn[] = report.displayedFields
			.map((fieldColumn) => ({
				field: appState.actions.getFieldByRefId(fieldColumn.field),
				linkedField: false,
			}))
			.filter((f) => f.field)
			.sort((a, b) => StringUtils.sortIgnoreCase(appState.actions.getFieldTitle(a.field.refId), appState.actions.getFieldTitle(b.field.refId)))
			.filter((fieldColumn) => {
				if (!fieldColumn.field) {
					return false;
				}

				return ReportSortOrder.isFieldSupported(fieldColumn.field);
			});

		if (report.type !== ReportType.UserDefinedLinkedXyiconReport) {
			return fieldColumns;
		}

		return [
			...fieldColumns,
			...report.displayedLinkedFields
				.map((f) => ({
					field: appState.actions.getFieldByRefId(f.field),
					linkedField: true,
				}))
				.sort((a, b) => StringUtils.sortIgnoreCase(appState.actions.getFieldTitle(a.field.refId), appState.actions.getFieldTitle(b.field.refId)))
				.filter((fieldColumn) => {
					if (!fieldColumn.field) {
						return false;
					}

					return ReportSortOrder.isFieldSupported(fieldColumn.field);
				}),
		];
	}

	private renderFieldOption = (fieldColumn: IFieldColumn) => {
		return (
			<div className="hbox alignCenter">
				{fieldColumn.linkedField && <SVGIcon icon="dol-field" />}
				<span>{this.props.appState.actions.getFieldTitle(fieldColumn.field.refId)}</span>
			</div>
		);
	};

	private onSelectSortField = (sortField: OutputDataSortField & {linkedField?: boolean}, selectedField: IFieldColumn, linkedField?: boolean) => {
		const {report} = this.props;
		let tempSortField: OutputDataSortField & {linkedField?: boolean} = null;

		if (!linkedField && !selectedField.linkedField) {
			sortField.name = selectedField.field.refId;
			sortField.linkedField = false;
		} else if (!!linkedField && !!selectedField.linkedField) {
			sortField.name = selectedField.field.refId;
			sortField.linkedField = true;
		} else if (!linkedField && !!selectedField.linkedField) {
			tempSortField = {
				name: selectedField.field.refId,
				linkedField: true,
				direction: sortField.direction,
			};

			report.removeSortField(sortField);
			report.sortLinkedFields.push(tempSortField);
		} else if (!!linkedField && !selectedField.linkedField) {
			tempSortField = {
				name: selectedField.field.refId,
				linkedField: false,
				direction: sortField.direction,
			};

			report.removeSortField(sortField, true);
			report.sortFields.push(tempSortField);
		}
	};

	private renderOption = (
		appState: AppState,
		fieldColumns: IFieldColumn[],
		sortField: OutputDataSortField,
		option: {id: ReportSortDirection; label: string},
	) => {
		let label = option.label;

		if (!this.props.report.summarizeResults && sortField.name === "ResultCount") {
			this.onRemove(sortField);
		}

		const field = fieldColumns.find((fc) => fc.field.refId === sortField.name).field;

		if (field.dataType === FieldDataType.Boolean) {
			label = appState.actions.getBooleanFieldCustomLabelSettingsByReportSortDirection(field, option.id);
		}

		return label;
	};

	public override render() {
		const {report, appState} = this.props;
		const fieldColumns = this.getFields();

		return (
			<div className="ReportSortOrder">
				<h4>Add fields to sort by</h4>
				{report.sortFields.map((sortField, index) => (
					<div
						key={index}
						className="sortField"
					>
						<SelectInput
							sort={false}
							options={fieldColumns}
							selected={fieldColumns.find((f) => f.field.refId === sortField.name && !!f.linkedField === false)}
							onChange={(fieldColumn) => this.onSelectSortField(sortField, fieldColumn)}
							render={this.renderFieldOption}
							stringifyOption={(fieldColumn) => fieldColumn.field.name}
						/>
						<SelectInput
							className="sort"
							options={this._sortDirections}
							selected={this._sortDirections.find((option) => option.id === sortField.direction)}
							onChange={(option) => (sortField.direction = option.id)}
							render={(option) => this.renderOption(appState, fieldColumns, sortField, option)}
						/>
						<IconButton
							title="Delete"
							icon="delete"
							className="delete"
							onClick={() => {
								this.onRemove(sortField);
							}}
						/>
					</div>
				))}
				{report.sortLinkedFields.map((sortField, index) => (
					<div
						key={index}
						className="sortField"
					>
						<SelectInput
							sort={false}
							options={fieldColumns}
							selected={fieldColumns.find((f) => f.field.refId === sortField.name && !!f.linkedField === true)}
							onChange={(fieldColumn) => this.onSelectSortField(sortField, fieldColumn, true)}
							render={this.renderFieldOption}
							stringifyOption={(fieldColumn) => fieldColumn.field.name}
						/>
						<SelectInput
							className="sort"
							options={this._sortDirections}
							selected={this._sortDirections.find((option) => option.id === sortField.direction)}
							onChange={(option) => (sortField.direction = option.id)}
							render={(option) => this.renderOption(appState, fieldColumns, sortField, option)}
						/>
						<IconButton
							title="Delete"
							icon="delete"
							className="delete"
							onClick={() => {
								this.onRemove(sortField, true);
							}}
						/>
					</div>
				))}
				<Button
					label="+"
					className="dashed"
					onClick={this.onAddClicked}
				/>
			</div>
		);
	}
}

import * as React from "react";
import {MathUtils as THREEMath} from "three";
import {RotationChanger} from "../../../space/spaceeditor/logic3d/features/RotationChanger";
import type {Pointer} from "../../../../../utils/interaction/Pointer";
import {PointerDetectorReact} from "../../../../interaction/PointerDetectorReact";
import {HTMLUtils} from "../../../../../utils/HTML/HTMLUtils";
import type {PointDouble} from "../../../../../generated/api/base";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {Functions} from "../../../../../utils/function/Functions";

interface ITransformableSVGElementProps {
	readonly isTransformEnabled: boolean;
	readonly componentRef: React.RefObject<SVGGElement | SVGTextElement>;
	readonly orientation: number;
	readonly lastSavedOrientation: number;
	readonly pivotX: number; // normalized to [0, 1]
	readonly pivotY: number; // normalized to [0, 1]
	readonly scale: number;
	readonly viewBoxSize: number;
	readonly borderColor: string;
	readonly parentRef: React.RefObject<SVGSVGElement>;
	readonly onTranslateChange: (deltaOffset: PointDouble, propagate?: boolean) => void;
	readonly onOrientationChange: (deltaAngle: number, propagate?: boolean) => void;
	readonly children: React.ReactNode;
}

export class TransformableSVGElement extends React.Component<ITransformableSVGElementProps> {
	private _rectRef = React.createRef<SVGRectElement>();
	private _rotationRef = React.createRef<SVGPathElement>();
	private _rotationAreaRef = React.createRef<SVGCircleElement>();
	private _rotationChanger: RotationChanger;
	private _size: PointDouble;
	private _latestDeltaAngle: number = 0;

	constructor(props: ITransformableSVGElementProps) {
		super(props);
		this._size = {
			x: 0,
			y: 0,
		};
		this._rotationChanger = new RotationChanger(this);
	}

	private updateSize(el: Element) {
		const size = HTMLUtils.getSize(el);

		this._size = {
			x: size.width,
			y: size.height,
		};
	}

	private getDelta(pointer: Pointer) {
		// Related to the startpos, NOT the previous pos
		return {
			x: (pointer.localX - pointer.startX) / this._size.x,
			y: (pointer.localY - pointer.startY) / this._size.y,
		};
	}

	private onPointerDown = (pointer: Pointer) => {
		if (!this._rotationChanger.isRotating) {
			this.updateSize(this.props.parentRef.current);
		}
	};

	private onPointerMove = (pointer: Pointer) => {
		if (!this._rotationChanger.isRotating) {
			const delta = this.getDelta(pointer);

			this.props.onTranslateChange(delta);
		}
	};

	private onPointerUp = (pointer: Pointer) => {
		if (!this._rotationChanger.isRotating) {
			const delta = this.getDelta(pointer);

			this.props.onTranslateChange(delta, true);
		}
	};

	public startRotatingSelectedItems = Functions.emptyFunction;

	public rotateSelectedItems = (deltaAngle: number) => {
		this._latestDeltaAngle = MathUtils.calculateNewOrientation(this.props.lastSavedOrientation, deltaAngle) - this.props.lastSavedOrientation;
		this.props.onOrientationChange(this._latestDeltaAngle);
	};

	public stopRotatingSelectedItems = () => {
		this.props.onOrientationChange(this._latestDeltaAngle, true);
	};

	private onPointerDownOnRotationIcon = (pointer: Pointer) => {
		const parent = this.props.parentRef.current;

		if (parent) {
			this.updateSize(parent);
			this._rotationChanger.startRotating(pointer.localX, pointer.localY, this.props.pivotX * this._size.x, this.props.pivotY * this._size.y);
		}
	};

	private onPointerMoveOnRotationIcon = (pointer: Pointer) => {
		this._rotationChanger.updateXY(pointer.localX, pointer.localY);
	};

	private onPointerUpOnRotationIcon = (pointer: Pointer) => {
		this._rotationChanger.stopRotating();
	};

	private updateBorder() {
		const component = this.props.componentRef.current;
		const rect = this._rectRef.current;

		if (component && rect) {
			const pivot = {
				x: this.props.pivotX * this.props.viewBoxSize,
				y: this.props.pivotY * this.props.viewBoxSize,
			};

			const rotate = `rotate(${THREEMath.radToDeg(this.props.orientation)} ${pivot.x} ${pivot.y})`;
			const rectBBox = component.getBBox();

			rectBBox.width *= this.props.scale;
			rectBBox.height *= this.props.scale;
			const rectX = pivot.x - rectBBox.width / 2;
			const rectY = pivot.y - rectBBox.height / 2;

			rect.setAttribute("x", `${rectX}`);
			rect.setAttribute("y", `${rectY}`);
			rect.setAttribute("width", rectBBox.width.toString());
			rect.setAttribute("height", rectBBox.height.toString());
			rect.setAttribute("transform", rotate);

			const rotation = this._rotationRef.current;
			const rotationIconBBox = rotation.getBBox();
			const cx = rectX + rectBBox.width / 2 - rotationIconBBox.width / 2;
			const cy = rectY - rotationIconBBox.height;

			rotation.setAttribute("transform", `${rotate} translate(${cx} ${cy})`);

			this._rotationAreaRef.current.setAttribute("transform", `${rotate} translate(${cx} ${cy})`);
		}
	}

	public override componentDidUpdate() {
		this.updateBorder();
	}

	public override componentDidMount() {
		this.updateBorder();
	}

	private getGElement() {
		const {borderColor} = this.props;
		const strokeWidth = 0.5;

		return (
			<g>
				{this.props.children}
				{this.props.isTransformEnabled && (
					<>
						<rect
							data-transformhelper={true}
							ref={this._rectRef}
							fill="none"
							stroke={borderColor}
							strokeWidth={strokeWidth}
						/>
						<path
							data-transformhelper={true}
							ref={this._rotationRef}
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.34691 1.375C3.972775 0.84664 3.408245 0.55 2.75 0.55C1.534975 0.55 0.55 1.534975 0.55 2.75C0.55 3.965025 1.534975 4.95 2.75 4.95C3.965025 4.95 4.95 3.965025 4.95 2.75H5.5C5.5 4.268785 4.268785 5.5 2.75 5.5C1.231215 5.5 0 4.268785 0 2.75C0 1.231215 1.231215 0 2.75 0C3.521165 0 4.200595 0.325191 4.675 0.899345V0H5.225V1.925H3.3V1.375H4.34691Z"
							fill={borderColor}
						/>
						<PointerDetectorReact
							onDown={this.onPointerDownOnRotationIcon}
							onMove={this.onPointerMoveOnRotationIcon}
							onUp={this.onPointerUpOnRotationIcon}
							parent={this.props.parentRef.current}
						>
							<circle
								data-transformhelper={true}
								ref={this._rotationAreaRef}
								fill="rgba(0,0,0,0)"
								cx="2.5"
								cy="2.5"
								r="5"
								style={{cursor: "pointer"}}
							/>
						</PointerDetectorReact>
					</>
				)}
			</g>
		);
	}

	public override render() {
		const gElement = this.getGElement();

		return this.props.isTransformEnabled ? (
			<PointerDetectorReact
				onDown={this.onPointerDown}
				onMove={this.onPointerMove}
				onUp={this.onPointerUp}
				element={this.props.parentRef.current}
			>
				{gElement}
			</PointerDetectorReact>
		) : (
			gElement
		);
	}
}

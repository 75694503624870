import * as React from "react";
import {observer, inject} from "mobx-react";
import {Draggable, Droppable} from "@hello-pangea/dnd";
import {PortLayoutType} from "../../abstract/sidepanel/tabs/details/PortLayoutType";
import type {IPortMap} from "../../abstract/sidepanel/tabs/details/Ports";
import {ReactUtils} from "../../../utils/ReactUtils";
import {ToggleSwitchField} from "../../../widgets/button/switch/ToggleSwitchField";
import {IconButton} from "../../../widgets/button/IconButton";
import type {AppState} from "../../../../data/state/AppState";
import {LinkType, XyiconFeature} from "../../../../generated/api/base";
import type {Xyicon} from "../../../../data/models/Xyicon";
import {Functions} from "../../../../utils/function/Functions";
import {FocusLoss} from "../../../../utils/ui/focus/FocusLoss";
import type {TransformObj} from "../../../../utils/dom/DomUtils";
import {DomUtils, VerticalAlignment, HorizontalAlignment} from "../../../../utils/dom/DomUtils";
import type {PortDataDto, PortTemplateDto} from "../../../../generated/api/base";
import {ClickToEditInput} from "../../../widgets/input/clicktoedit/ClickToEditInput";
import type {Link} from "../../../../data/models/Link";
import {KeyboardListener} from "../../../../utils/interaction/key/KeyboardListener";
import type {Catalog} from "../../../../data/models/Catalog";
import {CrossPortfolioXyicon} from "../../space/spaceeditor/ui/actionbar/CrossPortfolioXyicon";
import {PortComponentLink} from "./PortComponentLink";
import {DRAGGABLE_ID_SEPARATOR, FULL_LIST} from "./Port";
import type {Port} from "./Port";

interface IPortComponentProps {
	readonly id: string;
	readonly selectedId: string;
	readonly item?: Catalog | Xyicon;
	readonly label: string;
	readonly isReadOnly: boolean;
	readonly children: PortTemplateDto[];
	readonly isStructurallyEditable: boolean; // structurally editable (while creating a catalogitem). If false, we can still edit the label names in the details panel for example
	readonly portMap: IPortMap;
	readonly labelOverrides: PortDataDto[];
	readonly appState?: AppState;
	readonly lastChildHasChild: boolean; // needed for styling
	readonly feature: XyiconFeature; // Xyicon or Catalog
	readonly layout: PortLayoutType;
	readonly grabbedItemDraggableId: string;
	readonly onClick: (id: string) => void;
	readonly onDeleteClick: (id: string) => void;
	readonly onAddChildClick: (id: string) => void;
	readonly onLabelChange: (newLabel: string, portID: string) => void;
	readonly onReadonlyChange: (value: boolean) => void;
	readonly onEnterKeyPress?: () => void;
}

interface IPortComponentState {
	fullList: boolean;
	width: number;
	transform: TransformObj;
	newLabel: string;
}

@inject("appState")
@observer
export class PortComponent extends React.Component<IPortComponentProps, IPortComponentState> {
	private readonly _container = React.createRef<HTMLDivElement>();
	private readonly _thumbnailContainer = React.createRef<HTMLDivElement>();
	private _isMounted = false;
	private _inputRef = React.createRef<ClickToEditInput>();

	constructor(props: IPortComponentProps) {
		super(props);
		this.state = {
			fullList: false,
			width: 0,
			transform: null,
			newLabel: (props.labelOverrides.find((portLabelData: PortDataDto) => portLabelData.id === props.id)?.label || props.label) ?? "",
		};
	}

	public static readonly defaultProps: Partial<IPortComponentProps> = {
		isStructurallyEditable: true,
		selectedId: null,
		item: null,
		portMap: {},
		labelOverrides: [],
		layout: PortLayoutType.Icon,
		onClick: Functions.emptyFunction,
		onDeleteClick: Functions.emptyFunction,
		onAddChildClick: Functions.emptyFunction,
		onReadonlyChange: Functions.emptyFunction,
	};

	private _resizeObserver: ResizeObserver;

	public override componentDidUpdate(prevProps: IPortComponentProps, prevState: IPortComponentState) {
		if (prevProps.layout !== this.props.layout) {
			this.closeFullListContainer();
		}

		if (!!prevProps.grabbedItemDraggableId && !this.props.grabbedItemDraggableId) {
			this.closeFullListContainer();
		}

		if (
			!prevState.fullList &&
			this.state.fullList &&
			this.props.layout === PortLayoutType.Card &&
			this._thumbnailContainer.current &&
			this._container.current
		) {
			this.setState({
				transform: DomUtils.getFixedFloatingElementPosition(
					this._thumbnailContainer.current,
					this._container.current,
					VerticalAlignment.top,
					HorizontalAlignment.left,
					10,
				),
			});
		}
	}

	private onClick = () => {
		this.props.onClick(this.props.id);
	};

	private onAddChildClick = () => {
		this.props.onAddChildClick(this.props.id);
	};

	private onDeleteClick = () => {
		this.props.onDeleteClick(this.props.id);
	};

	private getChildren() {
		const {
			children,
			selectedId,
			isStructurallyEditable,
			portMap,
			feature,
			onClick,
			onDeleteClick,
			onAddChildClick,
			onLabelChange,
			onReadonlyChange,
			labelOverrides,
			layout,
			grabbedItemDraggableId,
			item,
		} = this.props;

		if (children.length > 0) {
			return (
				<div className={ReactUtils.cls("childrenContainer", {lastChildHasChild: this.props.lastChildHasChild})}>
					{children.map((port: Port, index: number) => {
						const id = port.id;
						const lastChildHasChild = index === children.length - 1 ? port.children.length > 0 : false;

						return (
							<PortComponent
								key={id}
								id={id}
								item={item}
								selectedId={selectedId}
								label={port.label}
								isReadOnly={port.isReadOnly}
								isStructurallyEditable={isStructurallyEditable}
								portMap={portMap}
								feature={feature}
								children={port.children ? [...port.children] : []}
								onClick={onClick}
								onDeleteClick={onDeleteClick}
								onAddChildClick={onAddChildClick}
								onLabelChange={onLabelChange}
								onReadonlyChange={onReadonlyChange}
								labelOverrides={labelOverrides}
								lastChildHasChild={lastChildHasChild}
								layout={layout}
								grabbedItemDraggableId={grabbedItemDraggableId}
							/>
						);
					})}
				</div>
			);
		} else {
			return null;
		}
	}

	private onClickShowMore = () => {
		if (this._isMounted) {
			this.setState({fullList: true});
		}

		if (this.props.layout === PortLayoutType.Card) {
			requestAnimationFrame(() => {
				FocusLoss.listen(this._container?.current, this.closeFullListContainer);
			});
		}
	};

	private closeFullListContainer = (event?: React.MouseEvent | Event) => {
		if (this.state.fullList && this._isMounted && !this.props.appState.app.modalContainer?.contains(event?.target as Element)) {
			this.setState({fullList: false});
		}
	};

	private renderPortObjects(portObjects: {xyiconId: string; link: Link}[], maxItemNumber: number, variant: "base" | "more") {
		const {appState} = this.props;
		const {fullList} = this.state;

		const crossPortfolioLinks = this.props.item ? this.props.appState.actions.getCrossPortfolioLinksXyiconXyicon(this.props.item.id) : [];

		return portObjects.map((portObject: {xyiconId: string; link: Link}, index: number) => {
			const basicCondition = variant === "base" ? index < maxItemNumber : index >= maxItemNumber;

			if (basicCondition || (fullList && this.props.layout === PortLayoutType.Icon)) {
				const xyicon = appState.actions.getFeatureItemById<Xyicon>(portObject.xyiconId, XyiconFeature.Xyicon);

				if (xyicon) {
					let dragId = `${portObject.link?.id}${DRAGGABLE_ID_SEPARATOR}${portObject.xyiconId}${DRAGGABLE_ID_SEPARATOR}${this.props.id}`;

					if (variant === "more") {
						dragId += FULL_LIST;
					}

					return (
						<Draggable
							key={dragId}
							draggableId={dragId}
							index={index}
							isDragDisabled={!portObject.link?.id}
						>
							{(provided, snapshot) => (
								<div
									{...provided.draggableProps}
									{...provided.dragHandleProps}
									ref={provided.innerRef}
									className={ReactUtils.cls("link", {dragging: snapshot.isDragging})}
								>
									<PortComponentLink
										xyicon={xyicon}
										layout={this.props.layout}
										isDraggingActive={dragId === this.props.grabbedItemDraggableId}
										link={portObject.link}
									/>
								</div>
							)}
						</Draggable>
					);
				} else {
					const isCrossPortfolioLink = portObject.link.fromType === LinkType.Xyicon && portObject.link.toType === LinkType.Xyicon;

					if (isCrossPortfolioLink) {
						const linkData = crossPortfolioLinks.find((o) => o.link.id === portObject.link.id);

						if (linkData) {
							return (
								<div
									className="link"
									key={linkData.link.id}
								>
									<CrossPortfolioXyicon
										transport={this.props.appState.app.transport}
										linkData={linkData}
										showIconOnly={this.props.layout === PortLayoutType.Icon}
										showBreakLinkButton={true}
										showDeleteButton={true}
										isOption={true}
									/>
								</div>
							);
						}
					}
					return false;
				}
			}

			if (variant === "base") {
				if (!fullList && index === maxItemNumber) {
					return (
						<div
							key={portObject.link.id}
							className="more"
							onClick={this.onClickShowMore}
						>
							{`${portObjects.length - maxItemNumber} more...`}
						</div>
					);
				}
			}
		});
	}

	private getLinkedXyicons() {
		const {portMap, id, label, layout} = this.props;
		const portObjects = portMap[id] || [];
		const {fullList, width, transform} = this.state;

		let inlineStyle: React.CSSProperties = this._thumbnailContainer && {
			left: transform?.x || 0,
			top: transform?.y || 0,
		};

		let maxItemNumber = Math.floor(width / 45);

		if (layout === PortLayoutType.Card) {
			maxItemNumber = Math.floor(width / 200);
		}

		return (
			<>
				{fullList && layout === PortLayoutType.Card && (
					<div
						className="fullListContainer vbox"
						ref={this._container}
						style={inlineStyle}
					>
						<div className="header hbox alignCenter">
							<h4>{`${label} has ${portObjects.length - maxItemNumber} more linked xyicons`}</h4>
							<IconButton
								icon="close"
								onClick={this.closeFullListContainer}
							/>
						</div>
						<Droppable
							droppableId={`${id}${FULL_LIST}`}
							direction="horizontal"
							isDropDisabled={this.props.children.length > 0}
						>
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									className="cardsContainer"
								>
									{this.renderPortObjects(portObjects, maxItemNumber, "more")}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</div>
				)}
				<Droppable
					droppableId={`${id}`}
					direction="horizontal"
					isDropDisabled={this.props.children.length > 0}
				>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							className={ReactUtils.cls("droparea flex_1", {
								active: snapshot.isDraggingOver,
								cardLayout: layout === PortLayoutType.Card,
							})}
						>
							<div className="hbox alignCenter thumbnailWrapper flex_1">
								<div
									className="hbox alignCenter thumbnailContainer flex_1"
									ref={this._thumbnailContainer}
									style={{width: 100}}
								>
									{this.renderPortObjects(portObjects, maxItemNumber, "base")}
									{provided.placeholder}
								</div>
							</div>
						</div>
					)}
				</Droppable>
			</>
		);
	}

	private onLabelChange = (newValue: string) => {
		this.setState({newLabel: newValue});
	};

	private onKeyDown = (event: KeyboardEvent) => {
		const {onLabelChange, id} = this.props;

		if (event.key === KeyboardListener.KEY_ENTER) {
			onLabelChange(this.state.newLabel, id);
		}
	};

	private onFocusLoss = () => {
		this.props.onLabelChange(this.state.newLabel, this.props.id);
		FocusLoss.stopListen(this._inputRef.current._element.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.remove(this.onKeyDown);
	};

	private addFocusLossListener = () => {
		FocusLoss.listen(this._inputRef.current._element.current, this.onFocusLoss);
		KeyboardListener.getInstance().signals.up.add(this.onKeyDown);
	};

	private onClickToEditClick = () => {
		this.onClick();
		this.addFocusLossListener();
	};

	public override componentDidMount() {
		this._isMounted = true;

		if (this._thumbnailContainer.current) {
			this._resizeObserver = new ResizeObserver((entries) => {
				this.setState({width: entries[0].contentRect.width});
			});

			this._resizeObserver.observe(this._thumbnailContainer.current);
		}
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<IPortComponentProps>, nextContext: any): void {
		if (JSON.stringify(this.props.labelOverrides) !== JSON.stringify(nextProps.labelOverrides)) {
			this.setState({
				newLabel: (nextProps.labelOverrides.find((portLabelData: PortDataDto) => portLabelData.id === nextProps.id)?.label || nextProps.label) ?? "",
			});
		}
	}

	public override componentWillUnmount() {
		this._isMounted = false;

		if (this._thumbnailContainer.current) {
			this._resizeObserver.unobserve(this._thumbnailContainer.current);
		}
	}

	public override render() {
		const selected = this.props.selectedId === this.props.id;
		const label = this.props.labelOverrides.find((portLabelData: PortDataDto) => portLabelData.id === this.props.id)?.label || this.props.label;

		return (
			<>
				<div
					className={ReactUtils.cls("PortComponent hbox", {
						selected: selected,
						leaf: this.props.children.length === 0,
						locked: this.props.isReadOnly,
					})}
				>
					<div className="hbox linkedXyicons alignCenter flex_1">
						<div
							className="textContainer display_flex alignCenter"
							onClick={this.onClick}
						>
							<ClickToEditInput
								ref={this._inputRef}
								disabled={this.props.isReadOnly || !this.props.onLabelChange}
								value={label}
								onLiveChange={this.onLabelChange}
								onClick={this.onClickToEditClick}
								className="TextInput"
								noButtons={true}
							/>
						</div>
						{!this.props.isStructurallyEditable && this.getLinkedXyicons()}
					</div>
					{selected && (
						<>
							<div className="interactionsDiv hbox">
								<ToggleSwitchField
									value={this.props.isReadOnly}
									label="Read only"
									labelFirst={true}
									onChange={this.props.onReadonlyChange}
								/>
								<IconButton
									className="viewButton"
									title="Delete"
									icon="delete"
									onClick={this.onDeleteClick}
								/>
							</div>
							<IconButton
								className="viewButton add"
								title="Add Child"
								icon="add"
								onClick={this.onAddChildClick}
							/>
						</>
					)}
				</div>
				{this.getChildren()}
			</>
		);
	}
}

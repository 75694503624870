import * as React from "react";
import {inject} from "mobx-react";
import type {INumericFieldSettingsDefinition} from "../../../../../data/models/field/datatypes/Numeric";
import {KeyboardListener} from "../../../../../utils/interaction/key/KeyboardListener";
import {StringUtils} from "../../../../../utils/data/string/StringUtils";
import {MathUtils} from "../../../../../utils/math/MathUtils";
import {ReactUtils} from "../../../../utils/ReactUtils";

interface INumberInputProps {
	readonly min?: number;
	readonly max?: number;
	readonly value?: number;
	readonly onInput?: (value: number) => void;
	readonly onChange?: (value: number) => void;
	readonly decimals?: number;
	readonly step?: number;
	readonly disabled?: boolean;
	readonly className?: string;
	readonly autoFocus?: boolean;
	readonly dataTypeSettings?: INumericFieldSettingsDefinition;
	readonly caretPosition?: number;
	readonly isUpdateOnEnter?: boolean;
}

interface INumberInputState {
	stringValue: string;
	propsValue: number;
	editingValue: string;
}

@inject("appState")
export class NumberInputV5 extends React.Component<INumberInputProps, INumberInputState> {
	public static readonly defaultProps: INumberInputProps = {
		decimals: 2,
		step: 0.1,
		className: "number",
	};

	private _ref = React.createRef<HTMLInputElement>();
	private _floating = React.createRef<HTMLDivElement>();

	private _lastValidNumber: number;
	private _isValueChanged = false;
	private _isEscPressed = false;
	private _lastClickedElement: HTMLElement = null;

	constructor(props: INumberInputProps) {
		super(props);
		const value = Number(props.value) || undefined;

		this._lastValidNumber = value;

		this.state = {
			stringValue: NumberInputV5.getStringValue(value, props.decimals),
			propsValue: value,
			editingValue: null,
		};
	}

	protected static getStringValue(value: number, decimals: number) {
		if (value !== 0 && (value === undefined || isNaN(value) || !value)) {
			return "";
		}
		return value.toFixed(decimals);
	}

	private getErrorMessage = (value: string) => {
		const regex = this.props.dataTypeSettings?.formatting === "percentage" ? /^\d*\.?\d*%?$/ : /^\d*\.?\d*$/;

		return !value || regex.test(value) ? "" : "Enter a Numeric value!";
	};

	private onInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;

		this.setState({
			stringValue: inputValue,
			editingValue: inputValue,
		});

		const valueAsNumber = this.getNumber(inputValue);

		this.props.onInput?.(valueAsNumber);

		this._isValueChanged = true;
	};

	private onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		let direction = 0;

		switch (event.key) {
			case KeyboardListener.KEY_DOWN:
				direction = -1;
				break;
			case KeyboardListener.KEY_UP:
				direction = 1;
				break;
			case KeyboardListener.KEY_ESCAPE:
				this._isEscPressed = true;
				break;
			case KeyboardListener.KEY_ENTER:
				this.triggerChange(event.currentTarget.value);
				event.currentTarget.select();
				break;
		}

		if (direction !== 0) {
			event.preventDefault();
			this.step(direction);
			this._isValueChanged = true;
		}
	};

	private step(direction: number) {
		const stepSize = direction * this.props.step;
		const newValue = this.validateValue(this._lastValidNumber + stepSize);

		this.props.onInput?.(newValue);
		this.props.onChange?.(newValue);
	}

	private onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
		const target = event.currentTarget;
		const hasSelection = target.selectionEnd - target.selectionStart !== 0;

		if (!hasSelection) {
			target.select();
		}
	};

	private onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;

		if (StringUtils.trim(value) === "") {
			this.triggerChange(undefined);
		}
		if (this.props.isUpdateOnEnter) {
			this.setState({
				editingValue: `${this.props.value}`,
			});
		} else {
			if (this._lastClickedElement?.title !== "Cancel") {
				const float = parseFloat(value);
				const validated = this.validateValue(float);
				const stringVal = NumberInputV5.getStringValue(validated, this.props.decimals);

				this.triggerChange(`${stringVal}${this.isTherePercentageChar(value) ? "%" : ""}`); // parseFloat removes the "%" from the input we need to put it back
			}
		}
	};

	private isTherePercentageChar = (value: string) => {
		return value && this.props.dataTypeSettings?.formatting === "percentage" && value.includes("%");
	};

	private triggerChange(inputValue: string) {
		const {onChange, onInput} = this.props;
		let value = this.getNumber(inputValue);

		this.setState({stringValue: inputValue});

		if (onChange) {
			const errorMessage = this.getErrorMessage(inputValue);
			if (errorMessage || (isNaN(value) && value !== undefined)) {
				value = this._lastValidNumber;
			}

			const divident = this.isTherePercentageChar(inputValue) ? 100 : 1;
			const dividedValue = value === null ? null : value / divident; // if a user wants to delete the value and leave it empty, we need to send null (value / divident) becomes 0 instead of null

			if (dividedValue !== this._lastValidNumber && this._isValueChanged && this.state.propsValue !== dividedValue) {
				onChange(dividedValue);
				this._lastValidNumber = dividedValue;
				this._isValueChanged = false;
			} else if (errorMessage) {
				this.setState({
					editingValue: `${this.props.value}`,
				});
			}
		}

		if (onInput && !onChange) {
			onInput(value);
		}
	}

	private checkForCancelButton = (event: MouseEvent) => {
		// hacky solution for the cancel button. If the cancel button is being clicked
		// the onBlur is the first running function. We need to decide whether it really blurred (should save)
		// or cancel was hit (should not save)
		this._lastClickedElement = event.target as HTMLElement;
	};

	protected getNumber(valueString: string) {
		if (StringUtils.trim(valueString) === "") {
			// Note: very important to return null instead of undefined.
			// undefined doesn't work because in XHRLoader JSON.stringify converts {f35: undefined} -> {}
			// causing a bug that when a numberinput is cleared it's not saved.
			return null;
		}

		let value = parseFloat(valueString);

		value = this.validateValue(value);

		return value;
	}

	protected validateValue(value: number) {
		return MathUtils.clamp(value, this.props.min, this.props.max);
	}

	public override UNSAFE_componentWillReceiveProps(nextProps: Readonly<INumberInputProps>, nextContext: any): void {
		if (nextProps.value !== this.state.propsValue || this.props.decimals !== nextProps.decimals) {
			const value = Number(nextProps.value);

			this.setState({
				stringValue: NumberInputV5.getStringValue(value, nextProps.decimals ?? this.props.decimals),
				propsValue: nextProps.value === undefined ? null : nextProps.value, // we need null to update field value to empty, undefined is not working
				editingValue: isNaN(value) ? null : `${value}`,
			});
		}
	}

	public override componentDidMount() {
		const {autoFocus, caretPosition} = this.props;
		const input = this._ref.current;

		if (autoFocus) {
			input.focus();
		}

		if (caretPosition !== undefined && input.setSelectionRange) {
			input.focus();
			input.setSelectionRange(caretPosition, caretPosition);
		}

		document.addEventListener("mousedown", this.checkForCancelButton);
	}

	public override componentWillUnmount() {
		if (!this._isEscPressed && this._lastClickedElement?.title !== "Cancel") {
			// onBlur doesn't get triggered when you click out of the input
			this.triggerChange(this.state.stringValue);
			this.setState({editingValue: null});
		}

		document.removeEventListener("mousedown", this.checkForCancelButton);
	}

	public override render() {
		const {className, disabled} = this.props;
		const {editingValue, stringValue} = this.state;

		return (
			<input
				ref={this._ref}
				className={ReactUtils.cls(className, {error: !!this.getErrorMessage(this.state.editingValue)})}
				value={editingValue ?? stringValue}
				disabled={disabled}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onInput={this.onInput}
				onKeyDown={this.onKeyDown}
			/>
		);
	}
}

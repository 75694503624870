import * as React from "react";
import {inject, observer} from "mobx-react";
import {IconButton} from "../../widgets/button/IconButton";
import {TextInput} from "../../widgets/input/text/TextInput";
import {SVGIcon} from "../../widgets/button/SVGIcon";
import type {AppState} from "../../../data/state/AppState";
import {ReactUtils} from "../../utils/ReactUtils";
import type {Organization} from "../../../data/models/Organization";
import type {TransportLayer} from "../../../data/TransportLayer";
import {StringUtils} from "../../../utils/data/string/StringUtils";

interface ISwitchOrganizationProps {
	readonly open?: boolean;
	readonly toggleOrganizationSwitch?: () => void;
	readonly appState?: AppState;
	readonly transport?: TransportLayer;
}

interface ISwitchOrganizationState {
	query: string;
}

@inject("appState")
@inject("transport")
@observer
export class OrganizationSwitch extends React.Component<ISwitchOrganizationProps, ISwitchOrganizationState> {
	constructor(props: ISwitchOrganizationProps) {
		super(props);

		this.state = {
			query: "",
		};
	}

	private onClickOrganization = async (org: Organization) => {
		this.props.appState.app.setState({wait: true});
		await this.props.transport.services.auth.switchOrganization(org.id);
		//this.props.toggleOrganizationSwitch();
	};

	public override render() {
		const {appState, open, toggleOrganizationSwitch} = this.props;
		const organizations = appState.organizations;
		const query = this.state.query.toLowerCase() || "";

		return (
			<div className={ReactUtils.cls("OrganizationSwitch", {open: open})}>
				<IconButton
					icon="arrow"
					className="goBack"
					onClick={toggleOrganizationSwitch}
				/>
				{open && (
					<TextInput
						placeholder="Search Organization..."
						onInput={(value) => this.setState({query: value})}
						autoFocus={true}
					/>
				)}
				<div className="organizationsList">
					<ul>
						{organizations
							.filter((org) => StringUtils.containsIgnoreCase(org.name, query))
							.sort((orgA, orgB) => StringUtils.sortIgnoreCase(orgA.name, orgB.name))
							.map((org, key) => (
								<li
									key={key}
									className={ReactUtils.cls("OrganizationItem", {selected: org.id === appState.organizationId})}
									onClick={() => this.onClickOrganization(org)}
								>
									<SVGIcon icon="checkmark" />
									<h2>{org.name}</h2>
								</li>
							))}
					</ul>
				</div>
			</div>
		);
	}
}
